import React from 'react';
import {connect} from 'react-redux';
import {DatePicker as DatePickerComponent} from 'antd';
import 'antd/lib/date-picker/style/index.css';
import moment from 'moment';
import client from '../../../actions/client'

class DatepickerCustom extends React.Component {

    constructor(props) {
        super(props);

        this.dateFormat = 'DD/M/YYYY';

        this.state = {
            showTime: false
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        let dateFormat = this.props.settings ? this.props.settings.dateFormat : this.dateFormat;
        this.dateFormat = dateFormat;

         if (this.props.field.settings) {
            if (this.props.field.settings.showTime) {
                this.dateFormat = this.dateFormat + ' HH:mm';
                this.setState({showTime: true});
            }
            if (this.props.field.settings.format) {
                this.dateFormat = this.props.field.settings.format;
            }    
        }
    }

    onChange(date) {
       let value = null;
        if (date) {            
            value = moment(date).format(this.dateFormat);
            this.props.input.onChange(value);
            
        } else {
            this.props.input.onChange(null);
        }
        if (this.props.field.hasOwnProperty('connectedFields')) {
            let addVal = {};
            let jsonData = [];
            if (this.props.field.connectedFields.hasOwnProperty('dependency')) {
                this.props.field.connectedFields.dependency.map(field => {
                    addVal[field] = this.props.formData[field];
                })
            }
            addVal['body'] = moment(date).format(this.dateFormat);
            jsonData.push(JSON.stringify(addVal));

            let fields = this.props.field.connectedFields.fields;
            client.post(this.props.field.connectedFields.optionsUrl, addVal)
                    .then(response => response.data)
                    .then(response => {
                        fields.map(field => {
                            if (typeof response[field] !== 'undefined' && response[field] && response[field].length > 0) {
                                let i;
                                for (i = 0; i < response[field].length; i++) {
                                    this.props.change(field, response[field] ? response[field] : null);
                                }
                            }
                        }
                        )
                    })
        }
     
        if (this.props.field.hasOwnProperty('fieldDuration') && this.props.formData && this.props.formData[this.props.field.fieldDuration]) {          
           let duration = this.props.field.typesDuration[this.props.formData[this.props.field.fieldDuration]];
            if(value && duration){
                var dateNew = moment(value, this.dateFormat)
                    .add(duration, 'minutes')
                    .format(this.dateFormat)
                this.props.change(this.props.field.fieldDepend, dateNew)
            }
        }
    }

    render() {       
        
        return <DatePickerComponent 
                    size="large"
                    style={{width: "100%"}}
                    showTime = {this.state.showTime}  
                    onChange={this.onChange}
                    disabled = {this.props.field.disabled}
                    format={this.dateFormat}
                    selected={this.props.input.value ? (moment(this.props.input.value).format(this.dateFormat)) : null}
                    defaultValue={this.props.input.value ? moment(this.props.input.value)  : null} 
                    value={this.props.input.value ? moment(this.props.input.value, this.dateFormat)  : null}                    
                    />
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DatepickerCustom);
