import React from 'react';
import { connect } from 'react-redux';
import { plainTranslate } from '../../common/translate';
import { onUploadImages, onDeleteImage } from '../../../actions/actions';
import FileSaver from 'file-saver';
import './image.css';
import 'antd/lib/upload/style/index.css';
import { withRouter } from 'react-router-dom'
import client from '../../../actions/client'
import { Upload, Modal } from 'antd';
import { DownOutlined, PlusOutlined } from '@ant-design/icons'

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class Images extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            previewVisible: false,
            previewImage: '',
            fileList: [],
            images: [],
            progress: 0,
        };

        this.uploadImages = this.uploadImages.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePreview = this.handlePreview.bind(this);
        this.onChangeLink = this.onChangeLink.bind(this);
        this.addNewLink = this.addNewLink.bind(this);
        this.removeLink = this.removeLink.bind(this);
    }

    handleCancel() {
        this.setState({ previewVisible: false });
    }

    handleChange({ fileList }) {
        this.setState({
            fileList: [...fileList],
        });
    }

    handlePreview(file) {
        if (file.originFileObj) {
            file.preview = getBase64(file.originFileObj)
                .then(preview => {
                    this.setState({
                        previewImage: preview,
                        previewVisible: true,
                    });
                });
        }
        else {
            client.post(file.url)
                .then(response => response.blob())
                .then(blob => {
                    file.preview = getBase64(blob)
                        .then(preview => {
                            this.setState({
                                previewImage: preview,
                                previewVisible: true,
                            });
                        });
                });
        }
    };

    handleDownload(file) {
        FileSaver.saveAs(file.originFileObj || file.url);
    }

    uploadImages(options) {
        const { onSuccess, onError, file, onProgress } = options;
        let list = new DataTransfer();
        list.items.add(file);
        let files = list.files;

        const config = {
            headers: { "content-type": "multipart/form-data" },
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                this.setState({ progress: percent });
                if (percent === 100) {
                    setTimeout(() => this.setState({ progress: 0 }), 1000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            }
        };
        this.props.onUploadImages(this.props.formId, this.props.match.params.db, this.props.field.id, this.props.field.folder ? this.props.field.folder : 'system', files, config)
            .then(response => {
                if (response.status !== 'error') {
                    this.props.value = this.state.images.concat(response);
                    console.log('this.props.value[this.props.field.id]', this.props.value);
                    this.setState({ images: this.state.images.concat(response) })
                    onSuccess(response[0].id);
                }
            })
            .catch(err => {
                console.error("Eroor: ", err);
                onError({ err });
            });

    };

    handleDelete(file) {
        this.props.onDeleteImage(file.id, this.props.meta.form, this.props.field.id);
    }

    onChangeLink(e, type, key) {
        const name = (this.props.field.settings && this.props.field.settings.name) ? this.props.field.settings.name : 'imageLinks';
        this.props.change(name + '[' + key + '][' + type + ']', e.target.value);
    }

    addNewLink() { 
        const name = (this.props.field.settings && this.props.field.settings.name) ? this.props.field.settings.name : 'imageLinks';
        let imageLinks = this.props.value;
        let newLink = { link: '' };
        imageLinks.push(newLink);
        console.log(imageLinks)
        this.setState({
            imageLinks
        });
    }

    removeLink(key) {
        const name = (this.props.field.settings && this.props.field.settings.name) ? this.props.field.settings.name : 'imageLinks';
        let imageLinks = this.props.value;
        imageLinks.splice(key, 1)
        this.setState({
            imageLinks
        });
    }

    componentDidMount() {
        if (this.props.value && this.props.value.length) {
            this.setState({
                fileList: this.props.value.map(file => {
                    return {
                        ...file,
                        uid: file.id,
                        response: file.id,
                        status: 'done',
                    };
                }),
                images:  this.props.value,
            });
        }
    }

    render() {
        const { previewVisible, previewImage, fileList } = this.state;
        const name = (this.props.field.settings && this.props.field.settings.name) ? this.props.field.settings.name : 'imageLinks';
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">{plainTranslate(this.props.locale, 'Upload')}</div>
            </div>
        );

        return (
            <React.Fragment>
                <div className="col-md-6">
                    <div className="clearfix">
                        <Upload
                            multiple
                            customRequest={this.uploadImages}
                            onRemove={this.handleDelete}
                            listType="picture-card"
                            defaultFileList={fileList}
                            fileList={fileList}
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            onDownload={this.handleDownload}
                        >
                            {fileList.length > 0 ? null : uploadButton}
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                            <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </div>
                </div>
                {this.props.field && this.props.field.settings && this.props.field.settings.links &&
                    <div className="col-md-6">
                        <React.Fragment>
                            {this.props.value && this.props.value.length > 0 && this.props.value.map((link, key) =>
                                <React.Fragment>
                                    <input className="form-control form-imageLinks" key={key + 'link'} value={link.link} onChange={(e) => this.onChangeLink(e, 'link', key)} />
                                    <i className="fa fa-trash link-icon" aria-hidden="true" onClick={() => this.removeLink(key)} title={plainTranslate(this.props.locale, 'Remove link')}></i>
                                    <br />
                                </React.Fragment>
                            )}
                              <button className="button-outline grey"  style={{margin: '0'}} onClick={this.addNewLink}>{plainTranslate(this.props.locale, 'Add new link')}</button>
                        </React.Fragment>
                    </div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onUploadImages: (formId, db, id, folder, files) => dispatch(onUploadImages(formId, db, id, folder, files)),
    onDeleteImage: (id, formId, fieldId) => dispatch(onDeleteImage(id, formId, fieldId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Images));
