import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Translate, {plainTranslate} from '../common/translate';
import { Row, Col, Form, Input } from 'antd';
import TextFieldGroup from '../common/TextFieldGroup';
import client from '../../actions/client'
import showAToast from '../common/showAToast'
var loadjs = require('loadjs');

class ResetPassword extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      tokens: null
    };   
  }

  componentDidMount(){
    window.onSubmit = this.onSubmit
    loadjs('https://www.google.com/recaptcha/api.js');
    document.documentElement.style.backgroundColor = "#fbfaff";
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/'+this.props.match.params.db+'/dashboard');
    }
  }

  componentDidUpdate(prevProps){
    loadjs('https://www.google.com/recaptcha/api.js');
}

  onSubmit = (token) => { 
    setTimeout(
      () => {
        this.setState({
          tokens: token
        }, this.formRef.current.submit())     
      }, 
      1000
    );      
}

    onFinish = (values) => {   
        const userData = {};
        userData['email'] = values.email;   
        userData['token'] = this.state.tokens;       
            client
            .post('/'+this.props.match.params.db+'/reset/password/send-email', userData)
            .then(response => {
                showAToast(response.data.message, 'successReg', 'success') 
                this.props.history.push('/'+this.props.match.params.db+'/user/reset-password/check-email');
            })
            .catch(error => {
                showAToast(error.response.data.message, 'errregister', 'error') 
            });
            
    }

   render() {
     
    return (
       <div className="header-new">
            <Row type="flex" align="middle" className="login-top">
                <Col lg={1} />
                 <Col lg={16} md={10} sm={12} xs={12}>
                   <img className="composity-logo" src="https://composity.com/images/org/8f621c.png" alt="Composity logo" />
                 </Col>
                 <Col lg={6} md={12} sm={12} xs={12}>
                   <Link style={{float: "right"}} className="login-button-white" to={'/' + this.props.match.params.db + '/user/signin'}>{plainTranslate(this.props.settings.locale, 'Sign in')}</Link>
                 </Col>
                 <Col lg={1} />
            </Row>
              <div className="col-md-12 col-sm-12 col-xs-12 login-body">
                <div className="col-md-4"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 frame">
                  <div className="minimal-body">
                  <div className="text-center company-logo"></div>
                      <h2 style={{marginBottom: 30,fontWeight: "bold" }} className="text-center">{plainTranslate(this.props.settings.locale, 'Forgot Your Password?')}</h2>
                      <h4 style={{marginBottom: 30 }} className="text-center">{plainTranslate(this.props.settings.locale, 'To reset your password, enter your email')}</h4>
                    <Form 
                    ref={this.formRef}
                    layout="vertical"
                    onFinish={this.onFinish} 
                    className="login-form">
                      <Form.Item 
                      name="email"
                      label={plainTranslate(this.props.settings.locale, "Email")} 
                      className="login-input"
                      rules={[
                        {
                          required: true,
                          message: plainTranslate(this.props.settings.locale,'Please input your email!'),
                        },
                      ]}
                      >
                          <Input/>
                          </Form.Item>
                          <button className="g-recaptcha auth-button" 
                            data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            data-callback={'onSubmit'}
                            data-action='submit'
                            style={{width: 190}}>
                            {plainTranslate(this.props.settings.locale, 'Reset password')}</button> <br/>
                 </Form>
                  </div>
                </div></div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
    settings: state.settings,
    auth: state.auth,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

