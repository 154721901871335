import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MenuDrawer from '../sidebar/menu-drawer.jsx'
import { getSettings } from '../../actions/settingsActions'
import { withRouter } from 'react-router-dom'

class PosLayout extends Component {
    constructor() {
        super();
    }

    componentDidMount() {        
        this.props.getSettings(this.props.match.params.db)
        let sidebar = document.getElementById('sidebar').style.display = "none";
        let header = document.getElementById('header').style.display = "none";
    }

    render() {
        
        return (
                <div>
                    <MenuDrawer db={this.props.match.params.db}/>
                    <section id="pos-content"
                                 className={this.props.settings.sidebar === 'nav-collapse closed' ? 'merge-left' : ''}>
                        <section className='wrapper'>
                            <div>
                                {this.props.children}
                            </div>
                        </section>
                    </section>
                </div>
                );
    }
}

PosLayout.propTypes = {
    auth: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    children: PropTypes.node,
    getSettings: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
        auth: state.auth,
        settings: state.settings
    });

export default connect(mapStateToProps, {getSettings})(withRouter(PosLayout));
