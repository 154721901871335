import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Checkbox, Alert } from 'antd';
import showAToast from '../common/showAToast'
import { plainTranslate } from '../common/translate';
import client from '../../actions/client'
import "./login.css"
var loadjs = require('loadjs');

class Login extends Component {
  formRef = React.createRef();
    constructor() {
        super();
        this.state = { 
          tokens: null,
          loading: false
        };
    }

    componentDidMount() {
      window.onSubmit = this.onSubmit
      loadjs('https://www.google.com/recaptcha/api.js');
        if (this.props.auth && this.props.auth.isAuthenticated) {
            this.props.history.push('/' + this.props.match.params.db + '/dashboard');
        }
        document.documentElement.style.backgroundColor = "#fbfaff";
        client
        .post('/saas/checks/workspace', {'workspace_saas': this.props.match.params.db})
        .then(response => {
            client.post('/saas/workspace/migrate', {workspace: this.props.match.params.db});
        })
        .catch(error => {
          if(error.response && error.response.data && error.response.data.message){
            showAToast(error.response.data.message, 'errWorkspace', 'error')
          } 
          this.props.history.push('/workspace/signin')
        })
    }

    componentDidUpdate(nextProps) {
      loadjs('https://www.google.com/recaptcha/api.js');
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push('/' + this.props.match.params.db + '/dashboard');
        }
    }

    onFinish = (values) => {     
         const userData = {
            username: values.email,
            password: values.password
        };
        this.props.loginUser(this.props.match.params.db, userData, this.props.history); 
        this.setState({
          loading: false
        })      
    }

    onSubmit = (token) => { 
      this.setState({
        loading: true
      })
       setTimeout(
        () => {
          client
            .post('/'+ this.props.match.params.db +'/secured/check-token', {token: token})
            .then(response => {           
              this.formRef.current.submit()
            })
            .catch(error => {
              this.setState({
                loading: false
              })
              if(error.response && error.response.data && error.response.data.message){
              showAToast(error.response.data.message, 'errWorkspace', 'error')
              }
            })  
        }, 
        1000
      );     
  }

  onFinishFailed = (values, errorFields, outOfDate ) => {    
    this.setState({
      loading: false
    })
  }
   
    render() {

    return (
          <div className="login">
            <div className="bg"><img className="login-hero" src="https://composity.com/images/org/e4e130.png" width="100%" height="100%"/> </div>
             <Row>
              <Col lg={1} />
              <Col lg={18} md={24} className="login-top">
                <Col lg={12} md={10} sm={12} xs={24}>
                  <img className="composity-logo" src="https://composity.com/images/org/8f621c.png" alt="Composity logo" />
                </Col>
                <Col lg={10} md={12} sm={12} xs={24}>
                  <Link className="login-button-white" to="/workspace/create">{plainTranslate(this.props.settings.locale, 'Create workspace')}</Link>
                </Col>
              </Col>
              </Row>
              <Row type="flex" justify="space-around" align="middle" className="login-mainRow">
                <Col lg={1} />
                <Col lg={16} md={24}>
                    <Col lg={12} md={24}>
                      <div className="">
                        <h1 className="login-title">
                          {plainTranslate(this.props.settings.locale, 'Sign in to ')} 
                          {this.props.match.params.db} {plainTranslate(this.props.settings.locale, 'workspace')}
                          </h1>
                        
                        <Form 
                        ref={this.formRef}
                        onFinish={this.onFinish} 
                        onFinishFailed={this.onFinishFailed}
                        layout="vertical" 
                        className="login-form">
                          <Form.Item 
                          name="email"
                          label={plainTranslate(this.props.settings.locale, "Email")} 
                          className="login-input"                        
                          rules={[
                            {
                              required: true,
                              message: 'Please input your username!',
                            },
                          ]}>
                              <Input/>
                              </Form.Item>
                         <Form.Item 
                         name="password"                        
                         label={plainTranslate(this.props.settings.locale, "Password")} 
                         className="login-input"
                         rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                        ]}>
                               <Input type="password"/>
                         </Form.Item>
                         <Link className="login-link" to={'/' + this.props.match.params.db + '/user/reset-password'}>{plainTranslate(this.props.settings.locale, 'Forgot Your Password?')}</Link> 
                        {this.state.loading ?
                         <Button className="auth-button" 
                         style={{height: 50}}                         
                         loading={true}>
                         {plainTranslate(this.props.settings.locale, 'Sign in')}</Button>
                        :
                        <button className="g-recaptcha auth-button" 
                        data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        data-callback={'onSubmit'}
                        data-action='submit'>
                        {plainTranslate(this.props.settings.locale, 'Sign in')}</button>
                      } <br/>
                     </Form>
                      </div>
                    </Col>
                </Col>
                <Col lg={7} className="demo-col">
                  <div className="text-center">
                    <h2 className="login-title-white">{plainTranslate(this.props.settings.locale, 'The power to make smarter business decisions')}</h2>
                    <h3 className="login-subTitle">{plainTranslate(this.props.settings.locale, 'Get more tools for your business')}</h3>
                    <div className="buttons-section">
                      <a className="demo-button" target="_blank" href="https://composity.com/request-demo" size="small">{plainTranslate(this.props.settings.locale, 'REQUEST DEMO')}</a>
                    </div>
                    <div style={{textAlign:"center", marginBottom: "10px", color: "#aaa"}}>
                      Powered by <a style={{color:"#777", textDecoration:"underline"}} target="_blank" href="https://composity.com/" title="Composity Business Software - ERP, CRM, eCommerce, Helpdesk">Composity Business Software</a>
                    </div> 
                  </div>
                </Col>
              </Row>
          </div>
        );
      }
    }

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  settings: state.settings,
});

export default connect(mapStateToProps, { loginUser })(Login);
