import React from 'react';
import {connect} from 'react-redux';
import Yamde from 'yamde';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from 'markdown-it';

class Markdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.input.value,
        }
        this.onChange = this.onChange.bind(this);
    }
   

    // Finish!
     handleEditorChange = ({ html, text }) => {
   //   console.log('handleEditorChange', html);
      this.setState({ value: text });
        this.props.input.onChange(text);
    }
    onChange(value) {
        this.setState({ value: value });
        this.props.input.onChange(value);
    };

    render() {
        const mdParser = new MarkdownIt();
        return <>
            <MdEditor style={{ height: '500px' }} 
            renderHTML={text => mdParser.render(text)} 
            value={this.state.value}
            onChange={this.handleEditorChange} />
           {/*<Yamde value={this.state.value} handler={this.onChange} theme="light" />*/} 
        </>
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Markdown);
