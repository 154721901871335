import React, { Component } from 'react';
import ChartOfAccountsData from './chartOfAccountsData';
import ChartOfAccountsSettings from './chartOfAccountsSettings';
import client from './../../../../actions/client';

class ChartOfAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.updateChartOfAccountsData = this.updateChartOfAccountsData.bind(this);
        this.updateSettings = this.updateSettings.bind(this);
    }

    componentDidMount() {
        this.loadChartOfAccounts();
    }

    loadChartOfAccounts() {
        if (this.props.id || this.props.path || this.chartOfAccountsId) {
            client.post(this.props.id ? this.props.path : '/' + this.props.db + '/accounting/chart-of-accounts/find/' + this.chartOfAccountsId)
                .then(response => response.data)
                .then(response => {
                    this.setState({
                        chartOfAccounts: response,
                        chartOfAccountsId: response.id ? response.id : null
                    });
                });
        }
    }

    updateChartOfAccountsData(id, code, name, type, status) {

        if (!this.state.chartOfAccountsId) {
            this.chartOfAccountsId = id;
            this.loadChartOfAccounts();
            return;
        }

        let chartOfAccounts = this.state.chartOfAccounts;
        chartOfAccounts.name = name;
        chartOfAccounts.type = type;
        chartOfAccounts.code = code;
        chartOfAccounts.status = status;
        this.setState({ chartOfAccounts });
    }

    updateSettings(data) {
        let chartOfAccounts = this.state.chartOfAccounts;
        chartOfAccounts.settings = data;

        this.setState({ chartOfAccounts });
    }

    render() {
        return (
            <div>
                <div className="settings">
                    <ChartOfAccountsData
                        locale={this.props.locale}
                        db={this.props.db}
                        chartOfAccounts={this.state.chartOfAccounts}
                        chartOfAccountsId={this.state.chartOfAccountsId}
                        update={this.updateChartOfAccountsData} />
                    {
                        this.state.chartOfAccountsId &&
                        <div>
                            <hr />
                            <ChartOfAccountsSettings db={this.props.db}
                                locale={this.props.locale}
                                chartOfAccounts={this.state.chartOfAccounts}
                                update={this.updateSettings} />
                        </div>
                    }
                </div>
            </div>
        );
    }
}
export default ChartOfAccounts;

