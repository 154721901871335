import {
	INVOICE_PROFILE_DATA_LOADED,
	INVOICE_PROFILE_DATA_LOADING
} from "../constants/actionTypes";

export default (state = [], action) => {

    switch (action.type) {
        case INVOICE_PROFILE_DATA_LOADING:
            return {
                ...state,
                loading: true,
              };

        case INVOICE_PROFILE_DATA_LOADED:
	      return {
	        ...state,
	        loading: false,
	        invoiceProfileInfo: action.payload.data
	      };
	      
        default:
            return state;
    }
};