import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import {Link} from 'react-router-dom';
import {plainTranslate} from "../common/translate";
import showAToast from '../common/showAToast'
import { Menu, Dropdown, Modal, Button, Input, Row, Col, Upload, Card, Select, Spin, Space, Tooltip, Pagination, Form, Radio, Table } from 'antd';
import { MoreOutlined, SendOutlined, ShareAltOutlined, UnorderedListOutlined, DeleteOutlined, EditOutlined, RedoOutlined, CopyOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons'
import ModalAntd from '../common/ModalAntd'
import axiosFileApi from '../../actions/axiosFileApi';
import client from '../../actions/client';
import './documents.css'
const { Option } = Select;

class Documents extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
        open: false,
        folderName: '',
        files: [],
        fileList: [],
        openDel: false,
        fileId: null,
        fileName: '',
        openFileEdit: false,
        openFileDel: false,
        id: null,
        previewVisible: false,
        previewImage: '',
        pageFiles: 1,
        pageFolders: 1,
        documents: [],
        openFileLinks: false,
        showUploadModal: false
    };
    this.handleUpload = this.handleUpload.bind(this);
    this.handleCancel  = this.handleCancel.bind(this);
    this.handleChange  = this.handleChange.bind(this);
    this.handleDeleteFile  = this.handleDeleteFile.bind(this);
    this.handleOkDelFile  = this.handleOkDelFile.bind(this);
    this.handleCanceDelFile  = this.handleCanceDelFile.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.handleCancelPreview = this.handleCancelPreview.bind(this);
    this.handleDowloadFile = this.handleDowloadFile.bind(this);
    this.onChangePageFolder = this.onChangePageFolder.bind(this);
    this.onChangePageFile = this.onChangePageFile.bind(this);
    this.openUploadModal = this.openUploadModal.bind(this);
    this.closeUploadModal = this.closeUploadModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

onChangePageFolder(page, pageSize){
     this.setState({
        pageFolders: page
    })
    this.props.getDocuments(this.props.type, this.state.pageFiles, page,  this.props.id)
}

onChangePageFile(page, pageSize){
    this.setState({
        pageFiles: page
    })
    this.props.getDocuments(this.props.type, page, this.state.pageFolders,  this.props.id)
}

    getFiles(){
        let url = '/'+this.props.match.params.db+'/api/get/files/list';

        if(this.props.settings && !this.props.settings.storageIntegration){
            url = '/'+this.props.match.params.db+'/api/get/local-files/list';
        }

        client
        .post(url, {'location': this.props.match.params[0]})
        .then(response => {
          if(response.data && Object.keys(response.data).length > 0){
            this.setState({
              documents: response.data
            })
          }
         })
        .catch(error => {

        })
    }

    componentDidMount() {
        this.setState({uploadDirectory: this.props.match.params[0] !== 'all' ? this.props.match.params[0] : null});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params[0] !== this.props.match.params[0] || this.props.settings.storageIntegration !== prevProps.settings.storageIntegration) {
            this.getFiles();
            this.setState({uploadDirectory: !this.props.match.params[0].endsWith("/all") ? this.props.match.params[0] : null});
        }
    }

  handleDowloadFile(id, name){
     client
    .get('/'+this.props.match.params.db+'/api/storage/files/download', {responseType: 'blob'})
    .then(res => {                   
       var blob = new Blob([res.data], {type: res.headers['content-type']});
        var downloadUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = downloadUrl;
        a.download = name;
        document.body.appendChild(a);
        a.click();
    })
    .catch(err => {         
        console.log('Download error!', {autoClose: 10000})
    });
  }
     //delete file
     handleDeleteFile(fileName, filePath, isPublic){
         this.setState({
             openFileDel: true,
             fileName: fileName,
             file: filePath,
             isPublic: isPublic
         })
     }
     handleOkDelFile(){
        client
        .post('/'+this.props.match.params.db+"/api/storage/file/delete", {file: this.state.file, isPublicFile: this.state.isPublic})
        .then(res => {
            showAToast('File deleted!', 'successUpload', 'success');

            this.getFiles();
            this.setState({
                 openFileDel: false,
                 fileId: null,
                 fileName: null,
                 isPublic: null
            })
        })
        .catch(err => {
            showAToast("Error!", 'baseError', 'error')
        });
     }
     handleCanceDelFile(){
         this.setState({
             openFileDel: false,
             fileId: null,
             fileName: '',
             isPublic: null
         })
     }

     //upload file
     handleUpload(){
        if(this.state.fileList){
            var body = new FormData();
            this.state.fileList.map(file => (
                    body.append('files[]', file.originFileObj)
            ))

            let type = null;
            let dir = '';
            if(this.state.uploadDirectory){
                type = this.state.uploadDirectory.substring(0, this.state.uploadDirectory.indexOf("/"));
                dir = this.state.uploadDirectory.replace(type + "/",'');
            }
            if(!type){
                type = this.props.match.params[0].split("/")[0];
            }

            body.append('path', dir);
            body.append('isPublicFile', type === "public" ? true : false);

            this.setState({uploadLoading: true});
            client
            .post('/'+this.props.match.params.db+"/api/storage/files/upload", body, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                 showAToast('Upload success!', 'successUpload', 'success');

                this.setState({fileList: [], showUploadModal: false, uploadLoading: false})
                this.getFiles();
            })
            .catch(err => {
                showAToast("Error!", 'baseError', 'error')
            });
        }
     }

    handleChange ({ fileList }){
        this.setState({ fileList });
    }

     handlePreview(file){

    if (!file.url && !file.preview) {
      file.preview = URL.createObjectURL(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  }

    handleCancelPreview(){
        this.setState({ previewVisible: false });
    }

     handleCancel(){
         this.setState({
             open: false,
             folderName: '',
             folderId: null,
         })
     }

     openFileLinksModal = (hash) => {  
        client
        .post('/'+this.props.match.params.db+"/api/storage/get-linked-files", {hash: hash})
        .then(response => {
            this.setState({openFileLinks: true, fileLinks: response.data})
        })
        .catch(err => {
            showAToast("Error!", 'baseError', 'error')
        });     
     }

    closeFileLinksModal = () => {
      this.setState({
        openFileLinks: false, fileLinks: null
      })
     }

    openUploadModal = () => {
      this.setState({
        showUploadModal: true
      })
     }

    closeUploadModal = () => {
      this.setState({
        showUploadModal: false,
        uploadDirectory: !this.props.match.params[0].endsWith("/all") ? this.props.match.params[0] : null
      })
     }

     handleInputChange(e){
        this.setState({uploadDirectory: e.target.value});
     }

    viewImage = (file) => {
        this.setState({
            previewImage: file,
            previewVisible: true,
        });
    }

    render() {
    const { classes } = this.props;
    
    let documents = this.state.documents ? this.state.documents : [];

    const fileLinkCols = [
        {
            'dataIndex' : 'type',
            'title' : plainTranslate(this.props.settings.locale, 'Type'),
            'width': 50,
        },
        {
            'dataIndex' : 'name',
            'title' : plainTranslate(this.props.settings.locale, 'Name'),
            'width': 200,
            render: (text, record) => {
                return <Link to={record.link} style={{color: "#000"}}>{record.name}</Link>
            }
        },
        {
            'dataIndex' : 'created',
            'title' : plainTranslate(this.props.settings.locale, 'Created'),
            'width': 50,
        },
        {
            'dataIndex' : 'createdBy',
            'title' : plainTranslate(this.props.settings.locale, 'Created by'),
            'width': 50,
        },
    ];

    const { previewVisible, previewImage, fileList, previewTitle, uploading } = this.state;
    const uploadButton = (
      <div>
        <i className="fa fa-file-o" aria-hidden="true"></i>
        <div style={{ marginTop: 8 }}>{plainTranslate(this.props.settings.locale, 'New file')}</div>
      </div>
    );
     const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    const { Meta } = Card;

    return (
      <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
        <div className="moduleSubMenu dms_new_menu" style={{marginBottom: 10}}>
          <Row gutter={[16,16]} type="flex" justify="start">
            <Col  span={24} >
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
             <div className="pull-left">
             {this.props.type === 'trash' ?
             <span style={{fontSize: 20}}>Trash<div style={{fontSize: 14}}>{plainTranslate(this.props.settings.locale, 'All files and folder will be deleted automatically in 30 days.')}</div></span>
                 :
              <span className="navigation">
               <Link to={'/'+this.props.match.params.db + '/storage-v2/storages'}><h3 className="module-title">{plainTranslate(this.props.settings.locale, 'Documents')}</h3></Link>
                {documents && documents.path && documents.path.map((path, k) => {

                    return <Link style={{marginLeft: 5}} to={'/'+this.props.match.params.db + '/storage-v2'+path.path} key={k}> {' > '+path.name}</Link>
                 
                })}
             </span>
           }
                </div>
              </Col>
          </Col>
        </Row>
        <Row type="flex" style={{marginTop: 10}}>
          {this.props.type !== 'trash' && <Col>
                <Upload
                  {...props}
                 listType="picture-card"
                 className="dms-upload"
                 fileList={fileList}
                 onPreview={this.handlePreview}
                 onChange={this.handleChange}
                 style={{display: "flex"}}
                 multiple={true}
                 accept="application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/plain, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text,  image/png, image/jpeg, image/svg+xml, image/gif, image/vnd.microsoft.icon, application/json, text/html, text/css"
                  >
                 {fileList.length >= 8 ? null : uploadButton}
               </Upload>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancelPreview}>
                  <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
               </Col>
            }
            {this.props.type !== 'trash' && <Col>
                  <button
                    type="button"
                    className="button-outline grey pull-left"
                    onClick={this.openUploadModal}
                    disabled={fileList.length === 0}
                    loading={uploading}
                  >
                    {uploading ? 'Uploading' : 'Start Upload'}
                  </button>
                   </Col>
                }
              </Row>
           </div>
                {!documents && <Row><Col span={24} className="text-center"><Spin size="large" /></Col></Row>}
                {this.props.match.params[0] === 'storages' &&
                    <Row type="flex" justify="start" className="dir_row">
                        <Col xs={24} sm={12} md={8} lg={6} xl={6} key={0} className="dir">
                           <Card
                                hoverable
                                className="dir_folder"
                                cover={
                                  <Link to={"/" + this.props.match.params.db + "/storage-v2/private/all"} className="dir_element text-center">
                                     <i className="ion-ios-folder-outline" style={{fontSize: 38}}></i></Link> 
                                       }>
                                 <Link to={"/" + this.props.match.params.db + "/storage-v2/private/all"} className="dir_element text-center">    
                               <Meta title={<Tooltip title={plainTranslate(this.props.settings.locale, "Private")}>
                                   <h4 className="text-center">{plainTranslate(this.props.settings.locale, "Private")}</h4>
                                   </Tooltip>} style={{textAlign: 'center', height: 14}}/></Link> 
                            </Card> 
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6} key={1} className="dir">
                           <Card
                                hoverable
                                className="dir_folder"
                                cover={
                                  <Link to={"/" + this.props.match.params.db + "/storage-v2/public/all"} className="dir_element text-center">
                                     <i className="ion-ios-folder-outline" style={{fontSize: 38}}></i></Link> 
                                       }>
                                 <Link to={"/" + this.props.match.params.db + "/storage-v2/public/all"} className="dir_element text-center">    
                               <Meta title={<Tooltip title={plainTranslate(this.props.settings.locale, "Public")}>
                                   <h4 className="text-center">{plainTranslate(this.props.settings.locale, "Public")}</h4>
                                   </Tooltip>} style={{textAlign: 'center', height: 14}}/></Link> 
                            </Card> 
                        </Col>
                    </Row>
                }
               {this.props.match.params[0] !== 'storages' && documents && documents.folders && <Row type="flex" justify="start" className="dir_row">
               {documents.folders.map((folder, key) => (
                       <Col xs={24} sm={12} md={8} lg={6} xl={6} key={key} className="dir">
                      
                       <Card
                            hoverable
                            className="dir_folder"
                            cover={
                              <Link to={"/" + this.props.match.params.db + "/storage-v2/"+folder.path} className="dir_element text-center">
                                 <i className="ion-ios-folder-outline" style={{fontSize: 38}}></i></Link> 
                                   }>
                     <Link to={"/" + this.props.match.params.db + "/storage-v2/"+folder.path} className="dir_element text-center">    
                   <Meta title={<Tooltip title={folder.name}>
                       <h4 className="text-center">{folder.name.slice(0, 20)}{folder.name.length > 20 && <span>...</span>}</h4>
                       </Tooltip>} style={{textAlign: 'center', height: 14}}/></Link> 
              </Card> 
                    </Col>
                ))}
                
                </Row>}
                {documents && documents.folders && documents.folders.length > 0 && <Row type="flex" justify="start" className="dir_row">
                <Pagination 
                defaultCurrent={this.state.pageFolders} 
                total={documents.countFolders} 
                pageSize={12} 
                onChange={this.onChangePageFolder}
                showSizeChanger={false}/>
                </Row>}
                 {this.props.match.params[0] !== 'storages' && documents && documents.files && <Row type="flex" justify="start" className="dir_row">
               {documents.files.map((file, key) => (
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} key={key} className="dir" >
                      <div className="dir_card">
                       <div>
                        <div className="dir_image" style={{ backgroundImage: "url(" + file.thumb + ")"}}></div>
                           <div className="dir_image2" ><img alt="example" src={file.thumb}/></div>
                       </div>
                       <div> <Tooltip title={file.name}>
                       <a target='_blank' href={file.full}> 
                       <h4 className="text-center">
                         {file.name.slice(0, 20)}{file.name.length > 20 && <span>...</span>}</h4>
                       </a>  
                       </Tooltip></div>
                            <ul className="ant-card-actions">
                            {this.props.type === 'trash' ?
                                   [
                                      <li style={{width:"50%"}} key={1}><Tooltip title="Restore file"> <CopyOutlined className="dir_icon" key="redo"/></Tooltip></li>,
                                       <li style={{width:"50%"}} key={2}><Tooltip title="Show file"><a target='_blank' href={file.full}> <EyeOutlined key="eye"/></a></Tooltip></li>
                                   ]
                                   :
                                   [
                                  <li style={{width:"25%"}} key={1}><Tooltip title={plainTranslate(this.props.settings.locale, "Preview")}>
                                    {(file.extension === 'jpeg' || file.extension === 'jpg' || file.extension === 'png' || file.extension === 'webp' || file.extension === 'svg') ?
                                        <Link to='#' onClick={() => { this.viewImage(file.full) }}><EyeOutlined className="dir_icon" key="eye"/></Link>
                                        :
                                        <a href={file.full} target="_blank"><i className="fa fa-eye"></i></a>
                                    }
                                  </Tooltip></li>,    
                                  <li style={{width:"25%"}} key={2}><Tooltip title={plainTranslate(this.props.settings.locale, "Copy link")}><i onClick={() => { navigator.clipboard.writeText(file.full) }} className="fa fa-clipboard" aria-hidden="true" style={{ cursor: "pointer" }} title={plainTranslate(this.props.settings.locale, 'Copy link')}></i></Tooltip></li>,                                  
                                  <li style={{width:"25%"}} key={3}><Tooltip title={plainTranslate(this.props.settings.locale, "File links")}><SendOutlined className="dir_icon" key="share" onClick={() => this.openFileLinksModal(file.hash)}/></Tooltip></li>,
                                  <li style={{width:"25%"}} key={4}><Tooltip title={plainTranslate(this.props.settings.locale, "Delete")}><DeleteOutlined onClick={() => this.handleDeleteFile(file.name,file.path, file.isPublic)} className="dir_icon" key="delete" /></Tooltip></li>
                                 ]
                              }
                            </ul>
                      </div>
                    </Col>
                ))}
        
                </Row>}
            {this.props.match.params[0] !== 'storages' && documents && documents.files && documents.files.length > 0 && <Row type="flex" justify="start" className="dir_row">
        <Pagination 
        defaultCurrent={this.state.pageFiles} 
        total={ documents.countFiles} 
        pageSize={24}  
        onChange={this.onChangePageFile}
        showSizeChanger={false}/></Row>}
               <ModalAntd
                title={this.state.fileName +  plainTranslate(this.props.settings.locale, ' will be deleted!')}
                visible={this.state.openFileDel}
                onOk={this.handleOkDelFile}
                onCancel={this.handleCanceDelFile}
                locale={this.props.settings.locale}
               />
        <Modal title={plainTranslate(this.props.settings.locale, 'Upload File')}
            visible={this.state.showUploadModal}
            onCancel={this.closeUploadModal}   
            footer={null}>
            <Input value={this.state.uploadDirectory} onChange={this.handleInputChange} style={{width: 400, marginRight: 30}}/>
                <Button key="back" onClick={this.closeUploadModal} style={{marginRight: 30}}>
                  {plainTranslate(this.props.settings.locale, 'Cancel')}
                </Button>
                <Button key="submit" type="primary" onClick={this.handleUpload} loading={this.state.uploadLoading}>
                  {plainTranslate(this.props.settings.locale, 'Upload')}
                </Button>
          </Modal>
        <Modal width={1000} title={plainTranslate(this.props.settings.locale, 'Linked files')}
            visible={this.state.openFileLinks}
            onCancel={this.closeFileLinksModal}   
            footer={null}>
            <Table rowKey={record => record.id} columns={fileLinkCols} dataSource={this.state.fileLinks}/>
          </Modal>
      </div>

    );
  }
}

Documents.propTypes = {
  documents: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired
};


const mapStateToProps = state => ({
  settings: state.settings,
  documents: state.documents  
});

export default connect( mapStateToProps )(withRouter(Documents));
