import React, {Component} from 'react';

class BooleanRenderer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        var tick = Number(this.props.value);

        if (this.props.value === 'true') tick = 1;
        if (this.props.value === 'false') tick = 0;

        const hasText = 'text' in this.props;
        const icon = tick ? "ag-icon ag-icon-tick content-icon" : "ag-icon ag-icon-cross content-icon";

        return (
            <span>
                {
                    hasText &&
                    <span className={this.props.text[tick] ? this.props.text[tick].className : ''}>{this.props.text[tick] ? this.props.text[tick].label : ''}</span>
                }
                {
                    !hasText &&
                    <span className={icon}></span>
                }
            </span>);
    }
}

export default BooleanRenderer;
