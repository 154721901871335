import React from 'react';
import { connect } from 'react-redux';
import "./tableCollection.css";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Translate, { plainTranslate } from "../../common/translate";
import SlideModal from '../../common/SlideModal';
import SimpleForm from "../../redux-forms/form";
import { Form, Input, Button, Row, Col, Alert, Modal } from "antd";

const ModalForm = props => {
   
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function toggleRedirect (value) {
        
    }

    function successfulSave () {
        setOpen(false)
        props.updateForm()
    }

    return (<div>
        <Button
            loading={loading}
            htmlType="button"
            className="upload-btn btn btn-success"
            style={{background: '#95d472', color: 'white', margin: 30}}
            onClick={() => {setOpen(true)}}>
            {plainTranslate(props.locale, props.field.labelButton)}
        </Button>
        {open &&
            <SlideModal 
            onClose={() => {setOpen(false)}} 
            title={plainTranslate(props.locale, props.field.label)}>
                <section className="newPanel">
                    <div className="panel-body">
                        <div className="row m-bot15">
                            <SimpleForm
                                saveRoute={props.db + props.field.form.saveRoute}
                                formRoute={props.db + props.field.form.formRoute}                       
                                isNew={true}
                                popup={true}
                                match={{ params: { id: 0 } }}
                                form={'initializeFromState'}
                                onCancel={() => {}}
                                refs={props.formData.id}
                                onSuccessfulSave={successfulSave}
                            />
                        </div>
                    </div>
                </section>
            </SlideModal>
            }
    </div> );
}

export default ModalForm;