import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AsyncSelect from 'react-select/lib/Async';
import {onSelect2InputChange} from '../../../actions/actions';
import client from '../../../actions/client'

class Select2 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: {}
        };

        this.options = [];

        this.onChange = this.onChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.getSelectedByValue = this.getSelectedByValue.bind(this);
    }

    componentDidMount() {
        let selected = this.getSelectedByValue(this.props.value);
        this.setState({
            value: selected ? selected : {}
        });
        if(this.props.options.length > 0) this.props.onNewOptions(this.props.options);
    }

    componentDidUpdate(prevProps) {

        if (!this.props.value && (!this.props.settings || !this.props.settings.emptyOption) && this.props.options.length > 0) {
            this.setState({
                value: this.props.options[0]
            });
            this.options = this.props.options;
            if(this.props.options.length > 0) this.props.onNewOptions(this.props.options);
            this.onChange(this.props.options[0]);
        }
        if (prevProps.value !== this.props.value || JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
            let selected = this.getSelectedByValue(this.props.value, this.props.options);
            this.setState({
                value: selected ? selected : '',
            });
            this.options = this.props.options;
            if(this.props.options.length > 0) this.props.onNewOptions(this.props.options);
            this.props.onChange(this.props.id, selected ? selected.value : '', selected);
        }
    }

    getSelectedByValue(value, options) {
        let selected = null;
        if(!options) options = [];
        options = options.length > 0 ? options : this.options.length > 0 ? this.options : this.props.options;
        options.forEach(option => {
            if (option.value === value) {
                selected = option;
            }
        });

        return selected;
    }

    onChange(newValue, actionMeta) {
        if(this.props.id=='custId'){
            this.props.onCustChange(newValue.value);
        }
        if (this.props.field.hasOwnProperty('changeOptionFields')) {
            this.props.field.changeOptionFields.map(item => {
                if (item) {
            let addVal = {};
            let jsonData = [];
            
                addVal['body'] = newValue.value;
                jsonData.push(JSON.stringify(addVal));
            client.post(item.optionsUrl,addVal)
                    .then(response => response.data)
                    .then(response => {
                            this.props.onChangeMainOptions(item.field, response);
                                this.props.onChange(item.field, null);
                    })
                }
           })
        } 
        if (this.props.field.options.length > 0) {
            this.props.onNewOptions(this.props.field.options, newValue);
        }
        this.props.loseFocus(this.props.id);
        if(this.options.length > 0) this.props.onNewOptions(this.options);
        else if(this.props.options.length > 0) this.props.onNewOptions(this.props.options);

        if(newValue){
            if(this.props.field.options.indexOf(this.props.field.options.find(o => o.value === newValue.value)) === -1){
                this.props.field.options.push(newValue);
            }
        }            

        this.props.onChange(this.props.id, newValue ? newValue.value : '', newValue);
        this.setState({value: newValue});
 
    }

    loadOptions(inputValue, callback) {
        if (!inputValue) inputValue = -1;
        let hasSettings = this.props.settings && this.props.settings.optionsUrl;
        if(!hasSettings) {
            let ops = this.props.options;
            ops = ops.filter(item => item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);
            callback(ops);
        }else{
            this.props.onInputChange(this.props.formId, this.props.id, inputValue, hasSettings ? this.props.settings.optionsUrl : null)
            .then(response => {
                let options = [];
                if (response.payload && response.payload.options && response.payload.options.length) {
                    options = response.payload.options.map(option => {
                        return {
                            ...option,
                            'value': option.value,
                            'label': option.label
                        };
                    });
                }
                this.options = options;
                if(options.length > 0) this.props.onNewOptions(options);
                else if(this.props.options.length > 0) this.props.onNewOptions(this.props.options);                
                callback(options ? options : this.props.options);
            });
        }
       
    };

    render() {
        return <div>
            {this.props.id === 'parId' &&
                <AsyncSelect
                    isClearable
                    cacheOptions
                    openMenuOnClick={false}
                    value={this.state.value}
                    onChange={this.onChange}
                    loadOptions={this.loadOptions}
                    defaultOptions={this.props.options}
                    isDisabled={this.props.isDisabled}
                    tabIndex={this.props.tabindex ? this.props.tabindex : ''}
                />
            }
            {this.props.id !== 'parId' &&
                <AsyncSelect
                    isClearable
                    cacheOptions
                    openMenuOnClick={false}
                    value={this.state.value}
                    onChange={this.onChange}
                    loadOptions={this.loadOptions}
                    defaultOptions={this.props.options}
                    isDisabled={this.props.isDisabled}
                    tabIndex={this.props.tabindex ? this.props.tabindex : ''}
                />
            }
        </div>
    }
}

Select2.defaultProps = {
    onNewOptions: () => {}
};

Select2.propTypes = {
  onCustChange: PropTypes.func,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    onInputChange: (formId, id, value, route) => dispatch(onSelect2InputChange(formId, id, value, route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Select2);
