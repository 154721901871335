import React, { useEffect, useState, useRef } from 'react';
import { plainTranslate } from "../../../common/translate";
import { Card, Form, Input, Row, Col, Button, DatePicker, Select, Switch } from 'antd';
import client from '../../../../actions/client'
import showAToast from '../../../common/showAToast'
import { EditOutlined } from '@ant-design/icons'
import { onAccountFetchData } from '../../../../actions/actions';
import { connect } from 'react-redux';
import moment from 'moment';
import 'antd/dist/antd.css';
import SlideModal from '../../../common/SlideModal';
import SimpleForm from "../../../forms/form";
import { data } from 'jquery';

const { TextArea } = Input;

const Codes = (props) => {
    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    const [codes, setCodes] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [code, setCode] = useState(null);
    const [analisys, setAnalisys] = useState(null);
    const [codesOptions, setCodesOptions] = useState([]);

    const config = {
        rules: [
            {
                type: 'object',
                required: false,
                message: 'Please select date!',
            },
        ],
    };

    useEffect(() => {
        var codesArray = [];
        if (props.account.additionalFields && props.account.additionalData && props.account.additionalFields.length) {
            props.account.additionalFields.map(field => {
                if (field.type === 'datepicker' && props.account.additionalData[field.id]) {
                    codesArray[field.id] = moment(props.account.additionalData[field.id], "DD.MM.YYYY")
                } else {
                    codesArray[field.id] = props.account.additionalData[field.id];
                }
            })
        }
        if (props.account.codes && props.account.codes.length) {
            props.account.codes.map(code => (
                codesArray[code.id] = code.value
            ))
        }
        setCodes(codesArray);
        if (props.account.codesOptions) {
            setCodesOptions(props.account.codesOptions);
        }
    }, [props.account.additionalData]);

    useEffect(() => {
        if (open) {
            ref.current.setFieldsValue(Object.assign({}, codes));
        }
    }, [open])

    const onFinish = (values) => {
        if (props.account.additionalFields && props.account.additionalFields.length) {
            props.account.additionalFields.map(field => {
                if (field.type === 'datepicker' && values[field.id]) {
                    values[field.id] = values[field.id].format('DD.MM.YYYY')
                }
            })
        }
        setLoading(true)
        let route = '/' + props.db + '/api/account-analysis/modify/' + props.account.id;
        client.post(route, { codes: values })
            .then(response => response.data)
            .then(response => {
                showAToast('Success!', 'success', 'success')
                props.onAccountFetchData(props.db, props.account.id, false)
                setLoading(false)
                openEdit()
            })
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
                setLoading(false)
            })
    }

    const openEdit = () => {
        setOpen(!open);
    }

    const onInputChange = (event, name) => {
        let fields = codes;
        fields[name] = event.target.value;
        setCodes(fields)
        ref.current.setFields([Object.assign({}, fields)]);
    };
    const onChangeDate = (date, dateString, name) => {
        let fields = codes;
        fields[name] = dateString;
        setCodes(fields)
        ref.current.setFields([Object.assign({}, fields)]);
    }
    const onChangeSwitch = (checked, name) => {
        let fields = codes;
        fields[name] = checked;
        setCodes(fields)
        ref.current.setFields([Object.assign({}, fields)]);
    }

    const handleChange = (value, category, codeAnalisys) => {
        if (value == 'addOption') {
            setCode(category);
            setAnalisys(codeAnalisys);
            setOpenForm(true);
        }
    }

    const addCodesOption = (data) => {
        let options = codesOptions;
        options[analisys].push({value: data.id, label: data.name});
        setCodesOptions(options);
        setCode(null);
        setAnalisys(null);
        setOpenForm(false);
    }

    const onDropdownChange = (val, name) => {
        let fields = codes;
        fields[name] = val;
        setCodes(fields)
        ref.current.setFields([Object.assign({}, fields)]);
    }

    return (<>
        <Card size="small" title={plainTranslate(props.locale, 'Additional Data')}
            style={{ marginTop: 10 }}
            extra={<EditOutlined onClick={openEdit} />}>
            {props.account.codes && props.account.codes.map((code, key) => {
                return <Row style={{ margin: 10 }}>
                    <Col span={12} className="account-profile-card-header">{code.key ? code.key : plainTranslate(props.locale, 'Additional Data')}</Col>
                    <Col span={12} className="notes"> {code.label}</Col>
                </Row>
            })}
            {props.account.additionalData && props.account.additionalFields && props.account.additionalFields.length && props.account.additionalFields.map((addData, key) => {
                return <Row style={{ margin: 10 }}>
                    <Col span={12} className="account-profile-card-header">{addData.label ? addData.label : plainTranslate(props.locale, 'Data')}:</Col>
                    {addData.type === 'dropdown' && props.account.addionalFieldsOptions[addData.id] && Object.keys(props.account.addionalFieldsOptions[addData.id]).length > 0 ?
                        <>{Object.keys(props.account.addionalFieldsOptions[addData.id]).map(k => (
                            <>{props.account.addionalFieldsOptions[addData.id][k]['value'] === props.account.additionalData[addData.id] &&
                                <Col span={12} className="notes" key={k}>{props.account.addionalFieldsOptions[addData.id][k]['label']}
                                </Col>
                            }</>
                        ))}
                        </>
                        :
                        <Col span={12} className="notes" key={addData.id}>
                            {addData.type === 'switch' ?
                                <>
                                    {props.account.additionalData[addData.id] === true ?
                                        <>{plainTranslate(props.locale, 'Yes')}</>
                                        :
                                        <>{plainTranslate(props.locale, 'No')}</>}
                                </>
                                :
                                <Col span={12} className="notes" key={addData.id}>
                                    {addData.type === 'switch' ?
                                        <>
                                            {props.account.additionalData[addData.id] === true ?
                                                <>{plainTranslate(props.locale, 'Yes')}</>
                                                :
                                                <>{plainTranslate(props.locale, 'No')}</>}
                                        </>
                                        :
                                        <>
                                            {addData.type === 'datepicker' ?
                                                <>
                                                    {props.account.additionalData[addData.id] ? moment((props.account.additionalData[addData.id]).replaceAll('.', '/'), 'DD/MM/YYYY').format(props.settings.dateFormat) : null}
                                                </>
                                                :
                                                <>
                                                    {props.account.additionalData[addData.id]}
                                                </>
                                            }
                                        </>
                                    }
                                </Col>
                            }
                        </Col>
                    }
                </Row>
            })}
        </Card>
        {open && <SlideModal locale={props.locale}
                title={plainTranslate(props.locale, 'Edit Metrics')}
                onClose={openEdit}>
                <section className="newPanel">
                    <div className="panel-body" id="metrics">
                        <Form
                            ref={ref}
                            name="metrics"
                            onFinish={onFinish}
                            // initialValues={codes ? codes : {}}
                            layout="vertical" className="account-profile-popup-form">
                            <Row gutter={[40, 22]}>
                                {props.account.codes && props.account.codes.length && props.account.codes.map((code, key) => (  
                                    <Col span={12}>
                                        <Form.Item
                                            name={code.id}
                                            label={code.key ? code.key : 'Analysis'}>
                                            <Select
                                                size={'large'}
                                                showSearch
                                                onChange={(value) => handleChange(value, code.category, code.id)}
                                                getPopupContainer={() => document.getElementById('metrics')}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                <Select.Option value={''} key={''} children={''}></Select.Option>
                                                <Select.Option value={'addOption'}>{plainTranslate(props.locale, '+ Add option')}</Select.Option>
                                                {codesOptions && codesOptions[code.id].length && codesOptions[code.id].map((option) => (
                                                    <Select.Option value={option.value} key={option.value}>
                                                        {option.label}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                ))}

                                {/* {props.account.additionalFields && props.account.additionalFields.length && props.account.additionalFields.map((field, key) => (
                                    <Col span={12}>
                                        {field.type === 'number' &&
                                            <Form.Item
                                                name={field.id}
                                                label={field.label ? field.label : ''}>
                                                <Input type={'number'} style={{ height: 40, width: '100%' }} />
                                            </Form.Item>}
                                        {field.type == 'text' &&
                                            <Form.Item
                                                name={field.id}
                                                label={field.key ? field.key : 'Analysis'}>
                                                <Select
                                                    size={'large'}
                                                    showSearch
                                                    getPopupContainer={() => document.getElementById('metrics')}
                                                    ptionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    <Select.Option value={''} key={''} children={''}></Select.Option>
                                                    {codesOptions && codesOptions.length && codesOptions[field.id].map((option) => (
                                                        <Select.Option value={option.value} key={option.value}>
                                                            {option.label}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        }
                                    </Col>
                                ))} */}

                                {props.account.additionalFields && props.account.additionalFields.length && props.account.additionalFields.map((field, key) => (
                                    <Col span={12}>
                                        {field.type === 'number' &&
                                            <Form.Item
                                                name={field.id}
                                                label={field.label ? field.label : ''}>
                                                <Input type={'number'} style={{ height: 40, width: '100%' }} />
                                            </Form.Item>}
                                        {field.type == 'text' &&
                                            <Form.Item
                                                name={field.id}
                                                label={field.label ? field.label : ''}>
                                                <Input onChange={(event, name) => onInputChange(event, field.id)} />
                                            </Form.Item>}
                                        {field.type == 'dropdown' &&
                                            <Form.Item
                                                name={field.id}
                                                label={field.label ? field.label : ''}>
                                                <Select size={'large'} getPopupContainer={() => document.getElementById('metrics')} onChange={(event) => onDropdownChange(event, field.id)}>
                                                    {field.options && field.options.length && field.options.map((option) => (
                                                        <Select.Option value={option.value} key={option.value}>
                                                            {option.label}
                                                    </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>}
                                        {field.type == 'datepicker' &&
                                            <Form.Item
                                                name={field.id}
                                                label={field.label ? field.label : ''}>
                                                <DatePicker
                                                    style={{ height: 40, width: '100%' }}
                                                    onChange={(date, dateString, name) => onChangeDate(date, dateString, field.id)}
                                                    format={props.settings.dateFormat} />
                                            </Form.Item>}
                                        {field.type == 'switch' &&
                                            <Form.Item
                                                name={field.id}
                                                label={field.label ? field.label : ''}>
                                                <Switch
                                                    defaultChecked={codes[field.id]}
                                                    onChange={(event, name) => onChangeSwitch(event, field.id)} />
                                            </Form.Item>
                                        }

                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                <Col span={2}>
                                    <Button
                                        loading={loading}
                                        htmlType="submit"
                                        className='button-primary'
                                        //style={{ paddingLeft: 49, paddingBottom: 35 }}
                                        >
                                        {plainTranslate(props.locale, 'Save')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </section>
            </SlideModal>
        }
        {openForm &&
            <SlideModal onClose={() => setOpenForm(false)} title={plainTranslate(props.settings.locale, 'Add option')}>
                <section className="newPanel">
                    <div className="panel-body">
                        <SimpleForm
                            formRoute={props.db + '/api/analysis-codes/'}
                            saveRoute={props.db + '/api/analysis-codes'}
                            updateParent={(data) => addCodesOption(data)}
                            popup={true}
                            onCancel={() => setOpenForm(false)}
                            match={{params: {id: 0}}}
                            refs={code}
                            isNew={true}
                            form={'initializeFromState2'}
                        />
                    </div>
                </section>
            </SlideModal>
        }
    </>
    )
}

const mapStateToProps = state => ({
    settings: state.settings
});

export default connect(mapStateToProps, { onAccountFetchData })(Codes);