import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../../common/translate';
import Checkbox from './switch';

class RolesTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: {}
        };

        this.MODULE_CRM = 'MODULE_CRM';
        this.MODULE_INVOICES = 'MODULE_INVOICES';
        this.MODULE_PAYMENTS = 'MODULE_PAYMENTS';
        this.MODULE_INVENTORY = 'MODULE_INVENTORY';

        this.INVOICES_INVOICES = 'INVOICES_INVOICES';
        this.PAYMENTS_PAYMENTS = 'PAYMENTS_PAYMENTS';
        this.INVENTORY_RECEIPTS = 'INVENTORY_RECEIPTS';

        this.ACTIONS = {
            VIEW: 'VIEW',
            CREATE: 'CREATE',
            EDIT: 'EDIT',
            DELETE: 'DELETE'
        };

        this.updateMain = this.updateMain.bind(this);
        this.isMainChecked = this.isMainChecked.bind(this);
        this.getCurrentActions = this.getCurrentActions.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.input.value ? this.props.input.value : {}
        });
    }

    updateMain(module, value) {
        let roles = {};
        if (value) {
            if(module === this.MODULE_CRM) {
                this.props.lists.map(list => {
                    roles[list.id] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                });
            }
            else if(module === this.MODULE_INVOICES) {
                roles[this.INVOICES_INVOICES] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
            else if(module === this.MODULE_PAYMENTS) {
                roles[this.PAYMENTS_PAYMENTS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
            else if(module === this.MODULE_INVENTORY) {
                roles[this.INVENTORY_RECEIPTS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
        }
        else {
            if(module === this.MODULE_CRM) {
                this.props.lists.map(list => {
                    roles[list.id] = [];
                });
            }
            else if(module === this.MODULE_INVOICES) {
                roles[this.INVOICES_INVOICES] = [];
            }
            else if(module === this.MODULE_PAYMENTS) {
                roles[this.PAYMENTS_PAYMENTS] = [];
            }
            else if(module === this.MODULE_INVENTORY) {
                roles[this.INVENTORY_RECEIPTS] = [];
            }
        }

        let allRoles = this.state.value;
        allRoles[module] = roles;
        this.props.change(this.props.field.id, allRoles);
    }

    updateRow(module, submodule, action, value) {
        let roles = module in this.state.value ? this.state.value[module] : {};

        if (!(submodule in roles))
            roles[submodule] = [];

        if (action === 'ALL') {
            if (value) roles[submodule] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            else roles[submodule] = [];
        }
        else {
            if (value) {
                roles[submodule].push(action);
                if(!roles[submodule].includes(this.ACTIONS.VIEW))
                    roles[submodule].push(this.ACTIONS.VIEW);
            }
            else {
                let index = roles[submodule].indexOf(action);
                if (index !== -1) roles[submodule].splice(index, 1);
            }
        }

        let allRoles = this.state.value;
        allRoles[module] = roles;

        this.props.change(this.props.field.id, allRoles);
    }

    isMainChecked(module) {
        let result = false;
        if (module === this.MODULE_CRM) {
            this.props.lists.map(list => {
                if (module in this.state.value && list.id in this.state.value[module] && this.state.value[module][list.id].length > 0) {
                    result = true;
                }
            });
        }
        else if (module === this.MODULE_INVOICES) {
            if (this.MODULE_INVOICES in this.state.value && this.INVOICES_INVOICES in this.state.value[module] && this.state.value[module][this.INVOICES_INVOICES].length > 0) {
                result = true;
            }
        }
        else if (module === this.MODULE_PAYMENTS) {
            if (this.MODULE_PAYMENTS in this.state.value && this.PAYMENTS_PAYMENTS in this.state.value[module] && this.state.value[module][this.PAYMENTS_PAYMENTS].length > 0) {
                result = true;
            }
        }
        else if (module === this.MODULE_INVENTORY) {
            if (this.MODULE_INVENTORY in this.state.value && this.INVENTORY_RECEIPTS in this.state.value[module] && this.state.value[module][this.INVENTORY_RECEIPTS].length > 0) {
                result = true;
            }
        }


        return result;
    }

    getCurrentActions(module, submodule) {
        let roles = module in this.state.value ? this.state.value[module] : [];

        if (!(submodule in roles)) return '';

        let actions = [];
        if (roles[submodule].includes(this.ACTIONS.VIEW)) actions.push(plainTranslate(this.props.settings.locale, 'View'));
        if (roles[submodule].includes(this.ACTIONS.CREATE)) actions.push(plainTranslate(this.props.settings.locale, 'Create'));
        if (roles[submodule].includes(this.ACTIONS.EDIT)) actions.push(plainTranslate(this.props.settings.locale, 'Edit'));
        if (roles[submodule].includes(this.ACTIONS.DELETE)) actions.push(plainTranslate(this.props.settings.locale, 'Delete'));

        return actions.join(', ');

    }

    render() {
        const crmLists = this.props.lists;
        const crmRoles = this.MODULE_CRM in this.state.value ? this.state.value[this.MODULE_CRM] : [];
        const invoicesRoles = this.MODULE_INVOICES in this.state.value ? this.state.value[this.MODULE_INVOICES] : [];
        const paymentsRoles = this.MODULE_PAYMENTS in this.state.value ? this.state.value[this.MODULE_PAYMENTS] : [];
        const inventoryRoles = this.MODULE_INVENTORY in this.state.value ? this.state.value[this.MODULE_INVENTORY] : [];


        return (
            <div className="col-md-12">
                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td>{plainTranslate(this.props.settings.locale, 'CRM')}</td>
                            <td><Checkbox id={'sales-modules-all'} isChecked={this.isMainChecked(this.MODULE_CRM)}
                                          onChange={(value) => this.updateMain(this.MODULE_CRM, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        {
                            crmLists.map((list, key) => {
                                return (
                                    <tr key={key}>

                                        <td>{plainTranslate(this.props.settings.locale,list.name)}</td>
                                        <td><Checkbox id={'crm-' + list.id + '-all'}
                                                      isChecked={list.id in crmRoles && (crmRoles[list.id].includes(this.ACTIONS.VIEW)
                                                          || crmRoles[list.id].includes(this.ACTIONS.CREATE)
                                                          || crmRoles[list.id].includes(this.ACTIONS.EDIT)
                                                          || crmRoles[list.id].includes(this.ACTIONS.DELETE))}
                                                      onChange={(value) => this.updateRow(this.MODULE_CRM, list.id, 'ALL', value)}/>
                                        </td>
                                        <td><Checkbox id={'crm-' + list.id + '-view'}
                                                      isChecked={list.id in crmRoles && crmRoles[list.id].includes(this.ACTIONS.VIEW)}
                                                      onChange={(value) => this.updateRow(this.MODULE_CRM, list.id, this.ACTIONS.VIEW, value)}/>
                                        </td>
                                        <td><Checkbox id={'crm-' + list.id + '-create'}
                                                      isChecked={list.id in crmRoles && crmRoles[list.id].includes(this.ACTIONS.CREATE)}
                                                      onChange={(value) => this.updateRow(this.MODULE_CRM, list.id, this.ACTIONS.CREATE, value)}/>
                                        </td>
                                        <td><Checkbox id={'crm-' + list.id + '-edit'}
                                                      isChecked={list.id in crmRoles && crmRoles[list.id].includes(this.ACTIONS.EDIT)}
                                                      onChange={(value) => this.updateRow(this.MODULE_CRM, list.id, this.ACTIONS.EDIT, value)}/>
                                        </td>
                                        <td><Checkbox id={'crm-' + list.id + '-delete'}
                                                      isChecked={list.id in crmRoles && crmRoles[list.id].includes(this.ACTIONS.DELETE)}
                                                      onChange={(value) => this.updateRow(this.MODULE_CRM, list.id, this.ACTIONS.DELETE, value)}/>
                                        </td>
                                        <td>{this.getCurrentActions(this.MODULE_CRM, list.id)}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td>{plainTranslate(this.props.settings.locale, 'Invoices')}</td>
                            <td><Checkbox id={'invoices-all'} isChecked={this.isMainChecked(this.MODULE_INVOICES)}
                                          onChange={(value) => this.updateMain(this.MODULE_INVOICES, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>{plainTranslate(this.props.settings.locale, 'Invoices')}</td>
                            <td><Checkbox id={'invoices-invoices-orders-all'}
                                          isChecked={this.INVOICES_INVOICES in invoicesRoles && (invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.VIEW)
                                              || invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.CREATE)
                                              || invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.EDIT)
                                              || invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_INVOICES, this.INVOICES_INVOICES, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'invoices-invoices-orders-view'}
                                          isChecked={this.INVOICES_INVOICES in invoicesRoles && invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVOICES, this.INVOICES_INVOICES, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'invoices-invoices-orders-create'}
                                          isChecked={this.INVOICES_INVOICES in invoicesRoles && invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVOICES, this.INVOICES_INVOICES, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'invoices-invoices-orders-edit'}
                                          isChecked={this.INVOICES_INVOICES in invoicesRoles && invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVOICES, this.INVOICES_INVOICES, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'invoices-invoices-orders-delete'}
                                          isChecked={this.INVOICES_INVOICES in invoicesRoles && invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVOICES, this.INVOICES_INVOICES, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td>{this.getCurrentActions(this.MODULE_INVOICES, this.INVOICES_INVOICES)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td>{plainTranslate(this.props.settings.locale, 'Payments')}</td>
                            <td><Checkbox id={'payments-all'} isChecked={this.isMainChecked(this.MODULE_PAYMENTS)}
                                          onChange={(value) => this.updateMain(this.MODULE_PAYMENTS, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>{plainTranslate(this.props.settings.locale, 'Payments')}</td>
                            <td><Checkbox id={'payments-payments-all'}
                                          isChecked={this.PAYMENTS_PAYMENTS in paymentsRoles && (paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.VIEW)
                                              || paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.CREATE)
                                              || paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.EDIT)
                                              || paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_PAYMENTS, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'payments-payments-view'}
                                          isChecked={this.PAYMENTS_PAYMENTS in paymentsRoles && paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_PAYMENTS, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'payments-payments-create'}
                                          isChecked={this.PAYMENTS_PAYMENTS in paymentsRoles && paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_PAYMENTS, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'payments-payments-edit'}
                                          isChecked={this.PAYMENTS_PAYMENTS in paymentsRoles && paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_PAYMENTS, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'payments-payments-delete'}
                                          isChecked={this.PAYMENTS_PAYMENTS in paymentsRoles && paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_PAYMENTS, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td>{this.getCurrentActions(this.MODULE_PAYMENTS, this.PAYMENTS_PAYMENTS)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td>{plainTranslate(this.props.settings.locale, 'Inventory')}</td>
                            <td><Checkbox id={'inventory-all'} isChecked={this.isMainChecked(this.MODULE_INVENTORY)}
                                          onChange={(value) => this.updateMain(this.MODULE_INVENTORY, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>{plainTranslate(this.props.settings.locale, 'Receipts')}</td>
                            <td><Checkbox id={'inventory-receipts-all'}
                                          isChecked={this.INVENTORY_RECEIPTS in inventoryRoles && (inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.VIEW)
                                              || inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.CREATE)
                                              || inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.EDIT)
                                              || inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_RECEIPTS, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'inventory-receipts-view'}
                                          isChecked={this.INVENTORY_RECEIPTS in inventoryRoles && inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_RECEIPTS, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-receipts-create'}
                                          isChecked={this.INVENTORY_RECEIPTS in inventoryRoles && inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_RECEIPTS, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-receipts-edit'}
                                          isChecked={this.INVENTORY_RECEIPTS in inventoryRoles && inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_RECEIPTS, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-receipts-delete'}
                                          isChecked={this.INVENTORY_RECEIPTS in inventoryRoles && inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_RECEIPTS, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td>{this.getCurrentActions(this.MODULE_INVENTORY, this.INVENTORY_RECEIPTS)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RolesTable);
