import React, {Component} from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from '../common/translate';
import moment from 'moment';

class LoggedChanges extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    render() {
        return (
            <div>
                <div className="account-profile-other-information-more">
                    <h4>{plainTranslate(this.props.settings.locale, this.props.title)}</h4>
                    {this.props.data && this.props.data.length > 0 &&
                        <a className="account-profile-show" id="previous-documents" href="javascript:void(0)"
                           onClick={() => this.setState({show: !this.state.show})}>
                            {this.state.show ? <i className="glyphicon glyphicon-chevron-up"></i> :
                                <i className="glyphicon glyphicon-chevron-down"></i>}
                        </a>  
                    }
                    {
                        this.state.show &&
                        <div className="line">
                            <table  className="table">
                                <thead>
                                    <tr>
                                        <th width="25%">{plainTranslate(this.props.settings.locale, 'Date')}</th>
                                        <th width="25%">{plainTranslate(this.props.settings.locale, 'Action')}</th>
                                        <th width="20%">{plainTranslate(this.props.settings.locale, 'Stage')}</th>
                                        <th width="30%">{plainTranslate(this.props.settings.locale, 'User')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.data.map(row =>
                                        <tr>
                                            <td>{row.date ? moment((row.date).replaceAll('.', '/'), 'DD/MM/YYYY H:m').format(this.props.settings.dateFormat + ' H:m') : ''}</td>
                                            <td>{plainTranslate(this.props.settings.locale, row.action)}</td>
                                            <td>{row.stage}</td>
                                            <td>{row.user}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(LoggedChanges);

