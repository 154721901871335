import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import SettingsMenu from '../../sidebar/settings-menu';
import { client} from "../../../actions/actions";
import Attributes from './attributes.jsx';
import { fetchSettings} from "../../../actions/actions";

class DeviceSettingsPage extends Component {

    constructor(props) {
    super(props);
        this.state = {
            showMessage: false,
            };
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/devices/settings/get-attributes', 'devices');
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

     render() {

        return (
                <div>
                    <SettingsMenu
                       location = {this.props.location.pathname} db = {this.props.match.params.db}/>
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                    <div className="settings">
                                        <Attributes
                                            saveRoute={'/' + this.props.match.params.db + '/api/devices/settings/set-attributes'}
                                            settings={this.props.settings}
                                            type={'devices'}
                                        />
                                   </div>
                                </div>
                             </section>
                          </div>
                       </div>
                    </form>
                </div>
                );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
   getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettingsPage);
