import React from 'react';
import {connect} from 'react-redux';
import {fetchSettings} from "../../../actions/actions";
import Collapsible from './collapsible';
import Stages from '../../settings/stages.jsx';

class StagesList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            ready: false
        };  
        this.updateStages = this.updateStages.bind(this);
    }

    componentDidMount() {
       this.setState({
            value: this.props.input.value ? this.props.input.value : []
        }); 
        if (this.props.db) {
            this.updateStages();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.db !== this.props.db) {
            this.updateStages();
        }
    }
    
    updateStages() {
        this.props.getSettings('/' + this.props.db + '/api/list/' + this.props.formData.id + '/settings/crm-settings', 'crmSettings')
        .then(res => {            
            this.setState({ready: true})
        })
    }

     render() {
         const element = <div className="col-md-12 no-gutter">
                            <div className="panel panel-default" style={{marginTop: 0}}>
                                {this.state.ready && <Stages
                                    saveRoute={'/' + this.props.db + '/api/stages/save/settings/data'}
                                    type={'crmSettings'}
                                    workflow={"crm_" + this.props.formData.id}
                                    setting={'stages'}
                                    roles={'roles'}
                                    updateStages={this.updateStages}
                                    />}
                            </div>
                    </div>;

         return <React.Fragment>
                {this.props.field.collapsible ?
                    <Collapsible
                      locale={this.props.locale}
                      title={false}
                      subtitle={this.props.field.description}> 
                      {element}
                    </Collapsible>
                    : {element}
                }
                </React.Fragment>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StagesList);

