import React from 'react';
import { connect } from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import {Field as FormField, reduxForm, FieldArray} from 'redux-form';
import LineField from '../types/lineField';
import "./dataTable.css";

class DataTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            lines: [],
            filters: null,
            url: null,
        };

        this.changePage = this.changePage.bind(this);

    }

    componentDidMount() {
        this.setState({
            data: this.props.formData.items ? this.props.formData.items : [],
        });
    }

    changePage(page) {

/*        const data = {
            body: {
                ...this.state.filters,
                'currentPage': page,
            }
        }

        fetch('/' + this.props.settings.db + '/api/reports/' + this.state.url + '/dataSource', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(response => {
                this.setState({
                    lines: response.lines,
                    page: page
                });
            })*/
    }

    render() {



/*        this.state.lines.forEach((line, i) => {
            line['key'] = i;
        });*/

        return <table className="dataTable">
                    <thead>
                        <tr>
                            {
                                this.props.field && this.props.field.fields.map((column, key) => {
                                    return <td key={key} className={column.type=='number' ? "text-right " : "text-left "} className={column.hide ? 'hidden ' : ''}>
                                    {plainTranslate(this.props.settings.locale, column.label)}
                                    </td>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                     {
                        this.state.data && this.state.data.map((line, key) => {
                        //let a =
                        return <tr>
                            {this.props.field.fields.map((column) => {
                                return <FormField
                                        name={this.props.field.id+'['+key+'].'+column.id}
                                        field={column}
                                        //input={line}
                                        locale={this.props.settings.locale}
                                        component={LineField}
                                        type={column.type}
                                        change={this.props.change}
                                    />
                                
                            })}
                        </tr>
                    })
                }
                    </tbody>
                </table>


    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});


export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
