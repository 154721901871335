import React, { useEffect, useState, useRef } from 'react';
import Translate, { plainTranslate } from "../../../common/translate";
import { Card, Form, Input, Row, Col, Button } from 'antd';
import client from '../../../../actions/client'
import showAToast from '../../../common/showAToast'
import {  PlusCircleOutlined } from '@ant-design/icons'
import Popup from "../../../common/popup";
import { onEditOptionsLoad, onFieldSave } from '../../../../actions/actions';
import {connect} from 'react-redux';
import InlineDropdownEdit from './inline-dropdown-edit';
import SimpleForm from "../../../forms/form";
import SlideModal from '../../../common/SlideModal';
import InlineSelect2Edit from './inline-select2-edit';

const Notes = props => {
    const [groups, setGroups] = useState([]);
    const [open, setOpen] = useState(false);


    useEffect(() => {        
        if(props.account.groupsOptions){
            setGroups(props.account.groupsOptions)
        }
    }, [props.account.groupsOptions]);

   
  const openEdit = () => {        
        setOpen(!open);
    }

    const onSuccessfulSave = () => {
        props.onEditOptionsLoad('/' + props.db + '/api/accounts/edit-options/' + props.account.id);
        openEdit() 
    }

    return (
            <Card title={plainTranslate(props.locale, 'Group')}
              size="small"
              extra={<PlusCircleOutlined onClick={openEdit}/>}
              style={{marginTop: 10}}>
                            <div className="group">
                            <InlineSelect2Edit 
                                updateRoute={props.updateRoute}
                                additionalData={{accountId: props.account.id}}
                                id={'group'}
                                isMulti={false}
                                notCreatable={true}
                                options={groups}
                                value={props.account.accountGroup}/> 
                            </div>                  
                       
                     
              {open && <SlideModal onClose={openEdit} title={plainTranslate(props.locale, 'Group')}>
                     <section className="newPanel">
                         <div className="panel-body">
                             <div className="row m-bot15">
                                 <SimpleForm
                                     formRoute={props.db + '/api/groups/'}
                                     saveRoute={props.db + '/api/groups'}
                                     onSuccessfulSave={onSuccessfulSave}
                                     popup={true}
                                     isNew= {true}
                                     onCancel={openEdit}
                                     match={{params: {id: 0}}}
                                 />
                             </div>
                         </div>
                     </section>
                 </SlideModal>}
           </Card>
    );
}

const mapStateToProps = state => ({    
});


export default connect(mapStateToProps, {onEditOptionsLoad, onFieldSave})(Notes);