import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from "../../common/translate";
import {Link} from 'react-router-dom';
import MenuContents from "./menuContents";

class MenuBuilder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        this.addNewItem = this.addNewItem.bind(this);
        this.remove = this.remove.bind(this);
        this.change = this.change.bind(this);
    }

    componentDidMount() {
        if (this.props.input.value) {
            let menu = JSON.parse(this.props.input.value)
            this.setState({
                value: menu.categories
            })
        }
    }

    addNewItem() {
        let menu = this.state.value ? this.state.value : [];
        let newItem = {'id': (menu.length+1), 'name': 'New main element', 'level': '1'};
        menu.push(newItem);
        this.setState({
            value: menu
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.input.value != this.props.input.value) {
            let menu = JSON.parse(this.props.input.value)
            this.setState({
                value: menu.categories
            })
        }
    }

    change() {
        let menu = new Object();
        menu['categories'] = this.state.value;
        this.props.input.onChange(JSON.stringify(menu));
    }

    remove(item) {
        let array = this.state.value;
        let index;
        array.find((val, key) => {
                if (val === item) {
                    index = key;
                    return true;
                }
        });
        if (index > -1) {
            array.splice(index, 1);
         }

        this.setState({value: array});
        this.change();
    }

    render() {
        return <div>
                <React.Fragment>
                    {this.state.value && this.state.value.length > 0 && this.state.value.map((item, key) =>
                        <MenuContents item={item} key={key} change={this.change} remove={this.remove} level={1} options={this.props.field.options}/>
                    )}
                    <button className="button-outline grey" style={{float: 'right'}} onClick = {this.addNewItem}>{plainTranslate(this.props.locale, 'Add new menu item')}</button>
                </React.Fragment>
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    });

const mapDispatchToProps = dispatch => ({
    });

export default connect(mapStateToProps, mapDispatchToProps)(MenuBuilder);
