import { Tabs, Button, Dropdown, Menu, Space, Drawer } from 'antd';
import { useRef, useState, useEffect } from 'react';
import 'antd/lib/tabs/style/index.css';
import Form from "../../redux-forms/form";
import { connect } from 'react-redux';
import Translate, { plainTranslate } from '../../common/translate';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';
import ModalConfirm from '../../common/ModalConfirm';

const { TabPane } = Tabs;

const TabsComponent = (props) => {
  const [activeKey, setActiveKey] = useState(1);
  const [items, setItems] = useState([]);
  const [editableProfile, setEditableProfile] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const newTabIndex = useRef(0);

  useEffect(() => {
    if (props.formData && props.formData.routes && props.formData.routes.length) {
      let items = props.formData.routes.map((item, key) => (
        {
          id: item.id,
          label: item.channelName,
          language: item.languageName,
          channel: item.channelName,
          metaTitle: item.metaTitle,
          metaDescription: item.metaDescription,
          status: item.status,
          rating: item.rating,
          route: item.route,
          routeFull: item.routeFull,
          key: key + 1
        }
      ));
      setActiveKey(items.length && items[0].key);
      setItems(items);
    }
  }, []);

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  const add = () => {
    setShowProfile(true);
    setEditableProfile(null);
  };

  const onSuccessfullSave = (data) => {
    const newActiveKey = `newTab${newTabIndex.current++}`;
    const newPanes = [...items];
    let found = null;
    let i;
    for (i = 0; i < newPanes.length; i++) {
      if (newPanes[i].id == data.entity.id) {
        found = i;
      }
    }
    if (found != null) {
      newPanes[found].label = data.entity.channelName;
      newPanes[found].route = data.entity.route;
      newPanes[found].routeFull = data.entity.routeFull;
      newPanes[found].language = data.entity.languageName;
      newPanes[found].channel = data.entity.channelName;
      newPanes[found].metaTitle = data.entity.metaTitle;
      newPanes[found].metaDescription = data.entity.metaDescription;
      newPanes[found].status = data.entity.status;
      newPanes[found].rating = data.entity.rating;
    } else {
      newPanes.push({
        id: data.entity.id,
        label: data.entity.channelName,
        language: data.entity.languageName,
        channel: data.entity.channelName,
        metaTitle: data.entity.metaTitle,
        metaDescription: data.entity.metaDescription,
        status: data.entity.status,
        rating: data.entity.rating,
        route: data.entity.route,
        routeFull: data.entity.routeFull,
        key: newActiveKey,
      });
    }

    setItems(newPanes);
    setActiveKey(newActiveKey);
    setShowProfile(false);
  }

  const remove = (e, targetKey) => {
    e.preventDefault();
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key == targetKey) {
        lastIndex = i - 1;
        client.get('/' + props.settings.db + '/api/cms-content-routes/' + item.id + '/delete')
          .then(response => response.data)
          .then(response => {
            setShowConfirm(false);
            showAToast(plainTranslate(props.settings.locale, 'Successfully deleted'), 'succSettings', 'success');
          })
      }
    });

    const newPanes = items.filter((item) => item.key != targetKey);
    if (newPanes.length && newActiveKey == targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      setEditableProfile(null);
      add();
    } else {
      setShowConfirm(targetKey);
      // remove(targetKey);
    }
  };

  const openCloseDrawer = (val) => {
    setEditableProfile(val);
    setShowProfile(!showProfile);
  }

  return (
    <>
      <Space style={{ marginBottom: 24 }}>
        <Button type="default" ghost onClick={() => add()} style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
              {plainTranslate(props.settings.locale, 'Add content page')}
        </Button>
      </Space>
      <Tabs
        type="editable-card"
        onChange={onChange}
        activeKey={activeKey}
        onEdit={onEdit}
        hideAdd
      // tabPosition='left'
      >
        {items.map(pane => (
          <TabPane tab={pane.label} key={pane.key}>
            <p>{plainTranslate(props.locale, 'Route')}: {pane.route}</p>
            <p>{plainTranslate(props.locale, 'Full Route')}: {pane.routeFull}</p>
            <p>{plainTranslate(props.locale, 'Language')}: {pane.languageName}</p>
            <p>{plainTranslate(props.locale, 'Meta Title')}: {pane.metaTitle}</p>
            <p>{plainTranslate(props.locale, 'Mata Description')}: {pane.metaDescription}</p>
            <p>{plainTranslate(props.locale, 'Status')}: {pane.status}</p>
            <p>{plainTranslate(props.locale, 'Rating')}: {pane.rating}</p>
            <button type="button" onClick={() => openCloseDrawer(pane.id)}> {plainTranslate(props.locale, 'Edit')} </button>
          </TabPane>
        ))}
      </Tabs>
      <Drawer
        title={plainTranslate(props.settings.locale, 'Content page')}
        className="grid-popup-form" placement="right"
        visible={showProfile}
        width={'90%'}
        onClose={() => openCloseDrawer(null)}>
        <div className="convertion-form">
          <Form
            formRoute={props.settings.db + '/api/cms-content-routes/'}
            saveRoute={editableProfile ? props.settings.db + '/api/cms-content-routes/' : props.settings.db + '/api/cms-content-routes'}
            onSuccessfulSave={(data) => onSuccessfullSave(data)}
            popup={true}
            refs={props.formData.id}
            form={'initializeFromState'}
            match={{ params: { id: editableProfile } }}
            isNew={true}
          />
        </div>
      </Drawer>
      {showConfirm &&
        <ModalConfirm onClose={() => setShowConfirm(false)}>
          <section className="newPanel">
            <div className="panel-body">
              <div className="row m-bot15">
                <div className="text-center">
                  <h3><strong>
                    <Translate locale={props.settings.locale} value="Are you sure?" />
                  </strong>
                  </h3>
                  <div className="buttons">
                    <button className="button-outline grey text-center"
                      onClick={() => setShowConfirm(false)}>
                      <Translate locale={props.settings.locale} value="Cancel" />
                    </button>
                    <button className="button-primary text-center btn-ok"
                      onClick={(e) => remove(e, showConfirm)}>
                      <Translate locale={props.settings.locale} value="Yes" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ModalConfirm>
      }
    </>
  );
};

const mapStateToProps = state => ({
  settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(TabsComponent);
