import React from 'react';
import {connect} from 'react-redux';
import {DatePicker as DatePickerComponent} from 'antd';
import 'antd/lib/date-picker/style/index.css';
import moment from 'moment';

class DatePicker extends React.Component {

    constructor(props) {
        super(props);

        this.dateFormat = 'DD.M.YYYY';

        this.state = {
            value: moment(),
            showTime: false
        };

        this.onChange = this.onChange.bind(this);
        this.onOpenChange = this.onOpenChange.bind(this);
    }

    componentDidMount() {
        let dateFormat = this.props.settings ? this.props.settings.dateFormat : this.dateFormat;
        this.dateFormat = dateFormat;

         if (this.props.settings) {
            if (this.props.settings.showTime) {
                this.dateFormat = this.dateFormat + ' HH:mm';
                this.setState({showTime: true});
            }
        }
        let date = this.props.value ? new Date(this.props.value) : null;

        if (date) {
            if (this.state.showTime) {
                var parts = this.props.value.split(' ');

                var partsDate = parts[0].split('/');
                var partsTime = parts[1].split(':');
                parts = partsDate.concat(partsTime);
                if (parts.length === 3) {
                    date = new Date(parts[2], parts[1] - 1, parts[0]);
                } else if (parts.length === 5) {
                    date = new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]);
                }
            } else {
                var parts = this.props.value.split('/');
                if (parts.length === 1) {
                    date = new Date(this.props.value);
                    date = moment(date);
                } else if (parts.length === 3) {
                    date = new Date(parts[2], parts[1] - 1, parts[0]);
                    date = moment(date);
                } else {
                    date = null;
                }
            }
            
        }

        this.setState({
                value: date
            });
        // this.props.onChange(this.props.id, date ? date.format(this.dateFormat) : '');
    }

    componentDidUpdate(prevProps) {
        let date = null;
        if (prevProps.value !== this.props.value) {
            if(this.props.value) {
                if (this.state.showTime) {
                var parts = this.props.value.split(' ');

                var partsDate = parts[0].split('/');
                var partsTime = parts[1].split(':');  
                parts = partsDate.concat(partsTime);
                if (parts.length === 3) {
                    date = new Date(parts[2], parts[1] - 1, parts[0]);
                } else if (parts.length === 5) {
                    date = new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]);
                }
            } else {
                var parts = this.props.value.split('/');
                date = parts.length === 3 ? new Date(parts[2], parts[1] - 1, parts[0]) : null;
            }

                if (date) {
                    date = moment(date);
                }
                this.setState({
                    value: date
                });
            }
            // this.props.onChange(this.props.id, date ? date.format(this.dateFormat) : '');
        }
    }

    onChange(value) {
        this.setState({value});
        this.props.onChange(this.props.id, value ? value.format(this.dateFormat) : '');
    }
    ;

    onOpenChange(status) {
        if (status === false) {
            this.props.loseFocus(this.props.id);
        }
    }

    render() {
        return <DatePickerComponent 
                    size="large"
                    style={{width: "100%"}}
                    showTime = {this.state.showTime}
                    defaultValue={this.state.value} 
                    value={this.state.value} 
                    format={this.dateFormat} 
                    onChange={this.onChange}
                    onOpenChange={this.onOpenChange}/>
    }
}

const mapStateToProps = state => ({
    formData: state.formData
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
