import {
    FETCH_EMPLOYEE_BEGIN, 
    FETCH_EMPLOYEE_SUCCESS, 
    FETCH_EMPLOYEE_FAILURE,
    EMPLOYEE_PROFILE_UPDATE_FIELD
} from "../constants/actionTypes";


export default (state = [], action) => {
    let employee = null;
    switch (action.type) {
                    
        case FETCH_EMPLOYEE_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_EMPLOYEE_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        employee: action.payload.employee
      };

    case FETCH_EMPLOYEE_FAILURE:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      // This is up to you and your app though: maybe you want to keep the items
      // around! Do whatever seems right.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        employee: []
      };
      
      case EMPLOYEE_PROFILE_UPDATE_FIELD:
            employee = state.employee;
            employee[action.payload.id] = action.payload.value;
            return {
                ...state,
                employee
            };
      
        default:
            return state;
    }
};
