import React from 'react';
import {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';
import SlideModal from '../../../common/SlideModal';
import { onTabsLoad} from '../../../../actions/actions';
import { Table, Dropdown, Menu, Button, Progress,Select } from 'antd';
import FormLoader from '../../../forms/form-loader';
import {Link} from 'react-router-dom';
import Form from "../../../redux-forms/form";
import Popup from '../../../common/popup';
import { withRouter } from 'react-router-dom';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';
import AsyncSelect from 'react-select/lib/Async';
import { PlusOutlined, FolderOpenOutlined, FileAddOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;

const customStyles = {
    input: styles => ({ ...styles, width: 60 })
  }

class Tasks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenTask: false,
            taskForm: false,
            taskProject: false,
            userOptions: [],
            userValues: {},
            userOptionsProject: [],
            userValuesProject: {},
        }    
        this.updateTasks = this.updateTasks.bind(this);
    }

    componentDidMount(){
        this.setState({
            userOptions: this.props.tasksTable ? this.props.tasksTable.users : [],
            userOptionsProject: this.props.tasksTable ? this.props.tasksTable.users : []
        })
    }
    
    updateTasks() {
        this.props.onTabsLoad('/' + this.props.match.params.db + '/api/accounts/tabs/' + this.props.accountId, 1, false);
    }

    handleChangeField(e, task, value, type) {
        if(e){
            e.preventDefault();
        }     
        if(type === 'user'){
        let userValues= this.state.userValues;
        userValues[task.id] = value.id;
         this.setState({
            userValues: userValues
         })
         value = value.id;
        }  

        if(type === 'userProject'){
            let userValuesProject= this.state.userValuesProject;
            userValuesProject[task.id] = value.id;
             this.setState({
                userValuesProject: userValuesProject
             })
             value = value.id;
             type = 'user'
            }  

        client
        .post('/' + this.props.match.params.db + '/api/task/' + task.id + '/update-field', {
            id: type,
            value: value
        })
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
            }
            else {
                this.updateTasks();
                showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
            }
        });
    }

    onSaveTask(response){
        this.updateTasks();
        this.setState({isOpenTask: false, openCopyTask: false, editTask: false, editTaskId: null, parentTask: null});
    }

    copyTask(id) {
        this.setState({openCopyTask: true, copyTaskId: id});
    }

    taskLogTime(id) {
        this.setState({taskLogTime: true, taskId: id});
    }

    createNewTask(e,form, project, parent){
         e.preventDefault();
        this.setState({isOpenTask: true, taskForm: form, taskProject : project,parentTask: parent});
    }

    closeLogTimePopup(){
        this.setState({ taskLogTime: null, taskId: null});
    }

    render() {
        const style = {
            margin: '0 5px',
        };

        let taskForms = this.props.tasksTable ? this.props.tasksTable.taskForms : [];
        let projects = this.props.tasksTable ? this.props.tasksTable.projects : [];
        let tasks = this.props.tasksTable ? this.props.tasksTable.tasks : [];
        let taskStages = this.props.tasksTable ? this.props.tasksTable.taskStages : [];
        let users = this.props.tasksTable ? this.props.tasksTable.users : [];
        let boards = this.props.tasksTable ? this.props.tasksTable.boards : [];
        let activityTypes = this.props.activityTypes ? this.props.activityTypes : [];

        const tasksCols = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    const FormOptions = (
                        <Menu>
                            {taskForms.map((option, i) => (
                                    <Menu.Item key={i}>
                                        <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.createNewTask(e,option.form, null, record.id)}>
                                            {plainTranslate(this.props.settings.locale, option.label)}
                                        </a>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );

                    let activityOptions = (
                        <Menu>
                            {activityTypes && activityTypes.map(type => {
                                return <Menu.Item key={type.value}>
                                    <a onClick={() => this.setState({ activityForm: true, activityType: type.value, taskId: record.id })}>{plainTranslate(this.props.settings.locale, type.label)}</a>
                                </Menu.Item>
                            })}
                        </Menu>
                    ); 
                    return (
                        !record.type ? <span className="table-actions">
                            <Link to={'/' + this.props.match.params.db + '/task/' + record.id + '/profile'} title={plainTranslate(this.props.settings.locale, 'Profile')}><i style={style} className="fa fa-eye"></i></Link>
                            <i title={plainTranslate(this.props.settings.locale, 'Edit')} style={{margin: '0 5px', cursor: "pointer"}} onClick={() => this.setState({editTask: true, editTaskId: record.id})} className="fa fa-pencil-square-o"></i>
                            <i title={plainTranslate(this.props.settings.locale, 'Copy task')} style={{margin: '0 5px', cursor: "pointer"}} onClick={() => this.copyTask(record.id)} className="fa fa-clone"></i>
                            <Dropdown overlay={activityOptions}>
                                <Link to="#" data-toggle="dropdown" title="New Activity" style={{top: 10}}>
                                    <i title={plainTranslate(this.props.settings.locale, 'New Activity')} style={{margin: '0 5px', cursor: "pointer"}} className="ion-android-time"></i>
                                </Link>
                            </Dropdown>
                    
                            <Dropdown overlay={FormOptions}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <i style={{margin: '0 5px',display: record.parent ? 'none' : 'inline-block'}} className="fa fa-plus-circle"></i>
                                </a>
                            </Dropdown>
                    
                        </span> : <span></span>
                    )
                },
                width: 170,
            },
            {
                'dataIndex' : 'code',
                'title' : plainTranslate(this.props.settings.locale, 'Code'),
                'width': 100,
                sorter: (a, b) => a.code.localeCompare(b.code),
                render: (text, record) => {
                    return(<span className={record.stageStatus === 'Closed' ? 'text-line-through' : ''}  onClick={() => this.setState({showTaskHint: true, taskHint: record})}>{record.code}</span>)
                }
            },
            {
                'dataIndex' : 'name',
                'title' : plainTranslate(this.props.settings.locale, 'Name'),
                'width': 100,
                sorter: (a, b) => a.name.localeCompare(b.name),
                render: (text, record) => {
                    return(<span className={record.stageStatus === 'Closed' ? 'text-line-through' : ''}  onClick={() => this.setState({showTaskHint: true, taskHint: record})}>{record.name}</span>)
                }
            },
            {
                'dataIndex' : 'status',
                'title' : plainTranslate(this.props.settings.locale, 'Status'),
                'width': 250,
                'filters':  taskStages && taskStages.map(val => {
                    return {text: plainTranslate(this.props.settings.locale, val.label),value: val.label}
                }),
                onFilter: (value, record) => record.status.indexOf(value) === 0,
                render: (text, record) => {
                    const StagesOptions = (
                        <Menu>
                            {taskStages.map((option, i) => (
                                    <Menu.Item key={i}>
                                        <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,option.id,"stage")}>
                                            {plainTranslate(this.props.settings.locale, option.label)}
                                        </a>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );
                    return(!record.type ? <span>
                        <Dropdown overlay={StagesOptions}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <i style={style} className="fa fa-flag-o"></i>
                            </a>
                        </Dropdown>
                        <span style={{whiteSpace: "nowrap"}} className={record.stageStatus} style={record.color ? {background: record.color, border: '1px solid #364a5a', color: "#000"} : {}}>{plainTranslate(this.props.settings.locale, text)}</span>
                    </span> : <span></span>)
                }
            },
            {
                'dataIndex' : 'project',
                'title' : plainTranslate(this.props.settings.locale, 'Project'),
                'width': 100,
                'filters':  projects && projects.map(val => {
                    return {text: plainTranslate(this.props.settings.locale, val.name),value: val.name}
                }),
                onFilter: (value, record) => record.project.indexOf(value) === 0,
                render: (text, record) => {
                    return <Link to={'/' + this.props.match.params.db + '/project/' + record.projectId + '/profile'}>{text}</Link> 
                }
            },
            {
                'dataIndex' : 'dueDate',
                'title' : plainTranslate(this.props.settings.locale, 'Due Date'),
                'width': 150, 
                render: (text, record) => (
                    <div>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}
                    </div>
                ),
            },
            {
                'dataIndex' : 'section',
                'title' : plainTranslate(this.props.settings.locale, 'Section'),
                'width': 100,
                sorter: (a, b) => a.section.localeCompare(b.section),
/*                'filteredValue': this.state.taskSection ? [this.state.taskSection] : null,
                onFilter: (value, filters) => this.state.taskSection ? filters.section.indexOf(this.state.taskSection) === 0 : null*/
            },
            {
                'dataIndex' : 'board',
                'title' : plainTranslate(this.props.settings.locale, 'Board'),
                'width': 150,
                sorter: (a, b) => a.board - b.board,
                render: (text, record) => (
                    <Select 
                    onChange={(val) => this.handleChangeField(null, record, val, 'board')}
                    defaultValue={record.board} style={{ width: 120 }}>
                    <Option value={null} style={{height: 25}}></Option>
                    {
                        boards.map(option =>(
                            <Option  value={option.id}>{option.label}</Option>
                        ))
                    }
                    </Select>
                ),
            },
            {
                'dataIndex' : 'user',
                'title' : plainTranslate(this.props.settings.locale, 'User'),
                'width': 50,
                sorter: (a, b) => a.user-b.user,
                render: (text, record) => (
                    !record.type ? <Select 
                    onChange={(val) => this.handleChangeField(null, record, val, 'user')}
                    defaultValue={record.user} style={{ width: 120 }}>
                    <Option value={null} style={{height: 25}}></Option>
                    {
                        users.map(option =>(
                            <Option  value={option.id}>{option.label}</Option>
                        ))
                    }
                    </Select> : <span></span>
                ),
            },
            {
                'dataIndex' : 'completion',
                'title' : plainTranslate(this.props.settings.locale, 'Completion'),
                'width': 100,
                render: (text, record) => {
                    const completionOptions = (
                        <Menu>
                            <Menu.Item key={1}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,10,'completion')}>
                                    10
                                </a>
                            </Menu.Item>
                             <Menu.Item key={2}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,20,'completion')}>
                                    20
                                </a>
                            </Menu.Item>
                            <Menu.Item key={3}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,30,'completion')}>
                                    30
                                </a>
                            </Menu.Item>
                            <Menu.Item key={4}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,40,'completion')}>
                                    40
                                </a>
                            </Menu.Item>
                            <Menu.Item key={5}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,50,'completion')}>
                                    50
                                </a>
                            </Menu.Item>
                            <Menu.Item key={6}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,60,'completion')}>
                                    60
                                </a>
                            </Menu.Item>
                            <Menu.Item key={7}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,70,'completion')}>
                                    70
                                </a>
                            </Menu.Item>
                            <Menu.Item key={8}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,80,'completion')}>
                                    80
                                </a>
                            </Menu.Item>
                            <Menu.Item key={9}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,90,'completion')}>
                                   90
                                </a>
                            </Menu.Item>
                            <Menu.Item key={10}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,100,'completion')}>
                                   100
                                </a>
                            </Menu.Item>
                        </Menu>
                    );
                    return(!record.type ? <span>
                        <Dropdown overlay={completionOptions}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <i style={style} className="fa fa-line-chart"></i>
                            </a>
                        </Dropdown>
                        <Progress style={{width: "70%",marginLeft: "10px"}} percent={record.completion} />
                    </span> : <span></span>)
                }
            },
            {
                'dataIndex' : 'created',
                'title' : plainTranslate(this.props.settings.locale, 'Created'),
                'width': 150, 
                sorter: (a, b) => moment(a.created, "DD/MM/YYYY").toDate()-moment(b.created, "DD/MM/YYYY").toDate()
            },
        ];

        let menuOptions = null;
        if (taskForms && taskForms.length > 0) {
            menuOptions = (
                <Menu>
                    {taskForms.map((item, i) => {
                        return <Menu.Item key={i}>
                            <a onClick={() => this.setState({ isOpenTask: true, taskForm: item.form, taskProject: null })}>
                                <FileAddOutlined /> {plainTranslate(this.props.settings.locale, item.label)}
                            </a>
                        </Menu.Item>
                    })}
                </Menu>
            );
        }

        return(
            <div >
                <div className="text-center">
                    {menuOptions &&
                       <Dropdown overlay={menuOptions} className="btn-round btn-blue">
                            <Link to="#" data-toggle="dropdown" title="New Task">
                                <i className="ion-android-add"></i>
                            </Link>
                        </Dropdown>
                    }
                    <Table className="projectsAndTasksTable" rowKey={record => record.id} columns={tasksCols} dataSource={tasks} scroll={{ x: 'max-content' }}
                    /> 
                </div>

                {
                    this.state.isOpenTask &&
                    <SlideModal onClose={() => this.setState({isOpenTask: false, parentTask: null})} title={plainTranslate(this.props.settings.locale, 'Task')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/tasks/'}
                                        saveRoute={this.props.match.params.db + '/api/tasks'}
                                        match={{params: {id: 0}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({isOpenTask: false, parentTask: null})}
                                        isNew={true}
                                        popup={true}
                                        parent={this.state.parentTask ? this.state.parentTask : null}
                                        account={this.props.accountId}
                                        type={this.state.taskForm}
                                        refs={this.state.taskProject ? this.state.taskProject : ' '}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.taskLogTime &&
                    <SlideModal onClose={() => this.setState({taskLogTime: false})} title={plainTranslate(this.props.settings.locale, 'Log time')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/log-time/'}
                                        saveRoute={this.props.match.params.db + '/api/log-time'}
                                        match={{params: {id: 0}}}
                                        onSuccessfulSave={this.closeLogTimePopup}
                                        isNew={true}
                                        popup={true}
                                        onCancel={() => this.setState({taskLogTime: false})}
                                        type={'task'}
                                        refs={this.state.taskId}
                                        //parent={this.props.match.params.id}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }

                {
                    this.state.openCopyTask &&
                    <SlideModal onClose={() => this.setState({openCopyTask: false})} title={plainTranslate(this.props.settings.locale, 'Task')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/tasks/'}
                                        saveRoute={this.props.match.params.db + '/api/tasks'}
                                        match={{params: {id: 0}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({openCopyTask: false})}
                                        isNew={true}
                                        popup={true}
                                        copy={this.state.copyTaskId}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.editTask &&
                    <SlideModal onClose={() => this.setState({editTask: false})} title={plainTranslate(this.props.settings.locale, 'Task')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/tasks/'}
                                        saveRoute={this.props.match.params.db + '/api/tasks/'}
                                        match={{params: {id: this.state.editTaskId}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({editTask: false})}
                                        isNew={true}
                                        popup={true}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.activityForm &&
                    <SlideModal onClose={() => this.setState({ activityForm: false, editActivity: false, taskId: null })} title={plainTranslate(this.props.settings.locale, 'Activity')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/activity/form/'}
                                        saveRoute={this.props.match.params.db + '/api/activities/new'}
                                        //updateParent={(data) => this.updateActivities(data)}
                                        onSuccessfulSave={() => this.setState({ activityForm: false, taskId: null })}
                                        popup={true}
                                        onCancel={() => this.setState({ activityForm: false, taskId: null })}
                                        match={{ params: {id: 'new'} }}
                                        refs={'&task=' + this.state.taskId + '&type=' + this.state.activityType}
                                        accountId={this.props.accountId}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {this.state.showTaskHint &&
                    <Popup locale={this.props.settings.locale}
                   title={this.state.taskHint.name}
                   onClose={() => this.setState({showTaskHint: false, taskHint: null})}
                   className="popupReports">
                        <div dangerouslySetInnerHTML={{ __html: this.state.taskHint.description }}> 
                        </div>
                    </Popup>
                }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onTabsLoad: (route, page, requiredTotal) => dispatch(onTabsLoad(route,page, requiredTotal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tasks));

