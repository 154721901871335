import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../common/translate";
import {searchItem} from "../../actions/actions";
import { BrowserRouter, Route, withRouter } from 'react-router-dom';
import client from '../../actions/client'

class ImportSale extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lines:[],
            errors: [],
            enableSave:true,
        };

        //this.save = this.save.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleImport = this.handleImport.bind(this);
    }

    componentDidMount() {
        client.post('/' + this.props.match.params.db + '/api/sales/settings/sale-settings')
        .then(response => response.data)
        .then(response => {
            this.setState({importAvailableItems: true});
        })
    }

    onChange(e) {
        this.setState({ value: e.target.value, enableSave: false});
    }

    handleImport(){
        let params = this.props.link.indexOf('?');

        let formType = this.props.link.substr(params,this.props.link.length);

        const urlParams = new URLSearchParams(formType);
        let formId = urlParams.get('type');

        var data = this.state.value;
        var rows = data.split("\n");
        let index = 0;
        let rowIndex = 0;
        let itemsArray = [];
        var items = [];
        var selectedHeaders = [];
        let date = null;
        let custId = null;
        let docData = rows[1].split(";");

        for(var i in rows) {
            if(i > 2 && i < rows.length){
                var cells = rows[i].split(";");
                if(cells.length > 1){
                    var code = cells[0];
                    var qty = cells[1];
                    if(cells[2]){
                        var price = cells[2];
                        items.push({code , qty, price});
                    }else{
                        items.push({code , qty});
                    }
                }
            }
        }

        let url = '/' + this.props.match.params.db + '/api/items/item/items-search';

        if(formType){
            url = url + formType;
        }

        this.props.searchItem(url, items, null, docData[1],docData[2], this.state.importAvailableItems).then(() => {
            let response = {};

            if(this.state.importAvailableItems == 1){
                let lines = [];

                this.props.item.item.data.map(value =>{
                    if(value.importItem === true){
                        lines.push(value);
                    }
                })
                response.lines = lines;
            }
            else{
                response.lines = this.props.item.item.data;
            }

            response.formType = formId;
            response.date = docData[1];
            response.cust = docData[2];

            this.props.history.push(this.props.link,{ response: response });
        });
    }

    render() {
        return <div>
                    <textarea className="form-control" onChange={this.onChange} placeholder="Paste your data here">
                    </textarea>
                    <div className="import-preview">
                        <div>
                            <button type="button" className={"button-primary "} style={{margin:" 30px auto"}} id="import" onClick={this.handleImport}>Import</button>
                        </div>
                    </div>
                </div>
    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
    item: state.importData
});

const mapDispatchToProps = dispatch => ({
   searchItem: (db, items, locId, docDate, cust, importAvailableItems) => dispatch(searchItem(db, items, locId, docDate, cust, importAvailableItems)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ImportSale));
