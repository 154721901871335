import React from 'react';
import PropTypes from 'prop-types';
import './slideModal.css';

class SlideModal extends React.Component {
    constructor(props) {
        super(props);        
    this.handleEscClick = this.handleEscClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);

    }
    
    handleEscClick(e) {
        if (e.keyCode === 27) {
              this.props.onClose();
        }
    }
    
    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('keydown', this.handleEscClick);
    }

  render() {
    return (
            <div className={this.props.customClass ? 'slide-modal backendropss gridModal' : 'slide-modal backendropss'} ref={this.setWrapperRef}>
              <div className={'modalConfirms scroll'} style = {{width : this.props.width ? this.props.width : '80%'}}>
              <div className="modal-header">
                  <h4 className="modal-title" style={{display: 'inline'}}>{this.props.title}</h4>
                  <div style={{float: 'right'}} onClick={this.props.onClose}><i className="fa fa-close" aria-hidden="true"></i></div>
              </div>
                {this.props.children}          
              </div>
            </div>
          );
        }
      }


SlideModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default SlideModal;
