import React from 'react';
import Translate, { plainTranslate } from '../../common/translate';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class LedgerData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showMessage: false,
            message: '',
            
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
    }

    componentDidUpdate(prevProps) {    
        
    }

    handleChangeDate(date) {
            this.props.update('date', moment(date).format('DD.MM.YYYY'));
    };

    handleChange(prop, e) {
        this.props.update(prop, e.target.value);
    }

    render() {
        return (
            <div>
                <div className="col-sm-3 no-gutter">
                    <div className="row m-bot15">
                        <div className="form-group">
                            <label htmlFor="ledger_journal" className="required col-md-12 text-left"><Translate locale={this.props.locale} value="Journal" />
                                <span className="required" title="This field is required">*</span>
                            </label><div className="col-sm-12">
                                <select id="ledger_journal" name="ledger[journal]" className="form-control"
                                    onChange={(e) => this.handleChange('journal', e)}
                                    value={this.props && this.props.ledger && this.props.ledger.journal}>
                                    {this.props.journals && this.props.journals.map((item, key) => (
                                        <option key={key} value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 no-gutter">
                    <div className="row m-bot15">
                        <div className="form-group">
                            <label htmlFor="ledger_period" className="required col-md-12 text-left"><Translate locale={this.props.locale} value="Period" />
                                <span className="required" title="This field is required">*</span>
                            </label>
                            <div className="col-sm-12">
                                <span className="form-input-field">
                                    <div className="col-md-12">
                                        <input type="text" className="form-control"
                                            value={this.props && this.props.ledger && this.props.ledger.period}
                                            onChange={(e) => this.handleChange('period', e)} />
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 no-gutter">
                    <div className="row m-bot15">
                        <div className="form-group">
                            <label htmlFor="ledger_date" className="required col-md-12 text-left"><Translate locale={this.props.locale} value="Date" />
                                <span className="required" title="This field is required">*</span>
                            </label>
                            <div className="col-sm-12">
                                <span className="form-input-field">
                                    <div className="col-md-12">
                                        <DatePicker
                                            selected={this.props && this.props.ledger && this.props.ledger.date ? moment(this.props.ledger.date) : moment()}
                                            onChange={this.handleChangeDate}
                                            showMonthDropdown
                                            showYearDropdown
                                            locale={this.locale}
                                            dateFormat="DD.MM.YYYY"
                                            todayButton={"Today"}
                                        />
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 no-gutter">
                    <div className="row m-bot15">
                        <div className="form-group">
                            <label className="required col-md-12 text-left" htmlFor="inv_status"><Translate locale={this.props.locale} value="Status" />
                            </label>
                            <div className="col-sm-12">
                                <h5>{this.props && this.props.ledger && this.props.ledger.status ? this.props.ledger.status : 'Draft'}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        );
    }
}

export default LedgerData;