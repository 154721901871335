import React from 'react'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import connect from "react-redux/es/connect/connect";
import Popup from '../common/popup';
import {Link} from 'react-router-dom';
import FiltersSelect from './select';
import {getCalendarEmployees} from '../../actions/actions';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import Translate, {plainTranslate} from '../common/translate';
import SlideModal from '../common/SlideModal';
import NewForm from "../redux-forms/form";
import SimpleForm from "../forms/form";
import './calendar.css';
import CalendarToolbar from './calendar-toolbar';
import 'antd/lib/select/style/index.css';
import showAToast from '../common/showAToast';
import client from '../../actions/client'
import { Button, Menu, Dropdown, Select } from 'antd';
import {UserOutlined, LinkOutlined, EnvironmentOutlined, ScheduleOutlined, HomeOutlined} from '@ant-design/icons';
import 'moment/locale/en-gb';

const { Option } = Select;

class ActivityCalendar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      employees: [],
      user: {},
      isNew: true,
      isOpen: false,
      candarsAll: false,
      calendarEvent: true,
      changeStagePopup: false,
      newCalendarEventPopup: false,
      activity: null,
      activities: [],
      calendarSelect: [],
      typesSelect: [],
      calendarsUrl: null,
      typesUrl: null,
      startDate: null,
      firstDay: null,
      lastDay: null,
      calendar: null,
      calendarFilter: [],
      calendarFilterInitial: [],
      typesFilter: [],
      ready: false,
      types: null,
      typesSelected: [],
      statusOptions: []
    }

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.showEditPopup = this.showEditPopup.bind(this);
    this.popUpClose = this.popUpClose.bind(this);
    this.handleEscClick = this.handleEscClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSelectSlot = this.handleSelectSlot.bind(this);
  }

  componentDidMount(){
    let startDate = new Date();
    let firstDay = new Date(startDate.getFullYear(), startDate.getMonth(), 2);
    let lastDay = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
    this.setState({
      startDate: startDate,
      firstDay: firstDay,
      lastDay: lastDay
    })
    this.props.getCalendarEmployees(this.props.match.params.db)
    .then(() => {
    this.setState({
      employees: this.props.employees.selectedEmployees
    });
    })
    this.getBookingActivities(this.props.match.params.db,firstDay,lastDay)
    if (this.props.title) {
        let titleArr = this.props.title;
        titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
        document.title = titleArr.join(' ');
    }
  }

  formatDate = (date) => {
    var month, day, year;
      month = '' + (date.getMonth() + 1);
      day = '' + date.getDate();
      year = date.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

getBookingActivities = (db, start, end, calendar=null, types=null) => {   
  let route = '/' + db + '/api/calendar/booking';  
   if(start instanceof Date){
    start = this.formatDate(start);
    end = this.formatDate(end);
  }
    client
       .post(route,{
          'startDate': start,
          'endDate': end,
          'calendar': calendar,
          'types': types
  })
      .then(response => response.data)
      .then(response => {
        let acts = response.activities;
        {acts && acts.map(activity =>(
          activity.title = activity.title + (activity.account ? '-' + activity.account : '') + (activity.calendarName ? '-' + activity.calendarName : '')
          ))}
        var calendar = [];
        if(response.calendarFilter){
          response.calendarFilter.map(row => {
            calendar.push(row.value)
          })
        }
          this.setState({
            activities: acts,
            calendarSelect: response.calendarSelect,
            calendarsUrl: response.calendarsUrl,
            calendarFilter: response.calendarFilter,
            calendarFilterInitial: response.calendarFilter,
            typesSelect: response.types,
            typesFilter: response.typesSelected,
            typesUrl: response.typesUrl,
            ready: true,
            calendar: calendar,
            statusOptions: response.statusOptions
          })         
     })
      .catch(err => {
          showAToast('Error!', 'baseError', 'error')
      })
}

applyFilters(){
  this.getBookingActivities(this.props.match.params.db, this.state.firstDay, this.state.lastDay, this.state.calendar, this.state.types);
  this.setState({filters: false});
}

cancelFilters = () => {
var result = []
  this.state.calendarFilterInitial.map(row =>{
    result.push(row.value)
})
  this.setState({
    calendarFilter: this.state.calendarFilterInitial,
    calendar: result,
    filters: false,
    calendarsAll: false
  });
}

  handleFilterChange(value, id=null, filter=[]){
    this.setState({
      [id]: value,
      calendarFilter: filter
    })  
  }

  handleTypesChange = (value, id=null, filter=[]) => {
    this.setState({
      [id]: value,
      typesFilter: filter
    })  
  }

  handleCheckboxChange(e){  
    if(e.target.name === "calendarsAll"){
      this.setState({
        calendarsAll: e.target.checked        
      });
      var result = []        
      if(e.target.checked === true){
        this.state.calendarSelect.map(row =>{
          result.push(row.value)
      })
        this.setState({
          isDisabledEmployees: true,
          calendarFilter: this.state.calendarSelect,
          calendar: result
        });
      }else{
        this.state.calendarFilterInitial.map(row =>{
          result.push(row.value)
      })
        this.setState({
          isDisabledEmployees: false,
          calendarFilter: [],//this.state.calendarFilterInitial,
          calendar: [],
          calendarFilterInitial: []
        });
        this.getBookingActivities(this.props.match.params.db, this.state.firstDay, this.state.lastDay, result, this.state.types);
      }
    }
  }

  handleDateChange(date){
    let start = null;
    let end = null;

    if(date.start){
      start = date.start
    }else if(date[0]){
      start = date[0];
    }else{
      start = date[0];
    }

    if(date.end){
      end = date.end;
    }else if(date[6]){
      end = date[6];
    }else{
      end = date[0];
    }

    this.setState({
      firstDay: start,
      lastDay: end
    })
    this.getBookingActivities(this.props.match.params.db, start, end,  this.state.calendar, this.state.types);
  }

  handleChange(activity){
    console.log(activity)
    let day = activity.start.getDate();
    let month = activity.start.toLocaleString('en-us', { month: 'long' });
    let year = activity.start.getFullYear();
    let startDate = day + ' ' + month + ' ' + year;

    let day1 = activity.end.getDate();
    let month1 = activity.end.toLocaleString('en-us', { month: 'long' });
    let year1 = activity.end.getFullYear();
    let endDate =  day1 + ' ' + month1 + ' ' + year1;

    this.setState({
      isOpen: true,
      activity: activity,
    });
  }

  handleClose() {
        this.setState({
            isOpen: !this.state.isOpen,
            activity: null
        });
    }

  popUpClose(type) {    
      if(type==='calendarEvent'){
        this.setState({newCalendarEventPopup: false, activity: null});
      }
     
      if(type==='edit'){
        this.setState({editPopup: false, activity: null});
      }
      this.getBookingActivities(this.props.match.params.db, this.state.firstDay, this.state.lastDay,  this.state.calendar, this.state.types);
  }

 

  showEditPopup() {
    if(this.state.activity.type === 'calendarEvent'){
      return (
        <SlideModal onClose={() => this.popUpClose('edit')} 
        title={plainTranslate(this.props.settings.locale, '')}>
            <section className="newPanel" ref={this.setWrapperRef}>
                <div className="panel-body">
                    <div className="row m-bot15">
                        <NewForm
                            formRoute={this.props.match.params.db + '/api/calendar-event/'}
                            saveRoute={this.props.match.params.db + '/api/calendar-event/'}
                            match={{params: {id: this.state.activity.id}}}
                            onSuccessfulSave={() => this.popUpClose('edit')}
                            popup={true}
                            isNew={true}
                            type={this.state.formId}
                            form={'initializeFromState2'}
                            onCancel={() => this.popUpClose('edit')}
                        />
                    </div>
                </div>
            </section>
        </SlideModal>
      );
    }
  }


  showNewCalendarEventPopup = () => {
    return (
      <SlideModal onClose={() => this.popUpClose('calendarEvent')} title={plainTranslate(this.props.settings.locale, 'Calendar event')}>
          <section className="newPanel" ref={this.setWrapperRef}>
              <div className="panel-body">
                  <div className="row m-bot15">
                    <NewForm
                      formRoute={this.props.match.params.db + '/api/calendar-event/'}
                      saveRoute={this.props.match.params.db + '/api/calendar-event'}
                      isNew={true}
                      popup={true}
                      form={'initializeFromState2'}
                      onSuccessfulSave={() => this.popUpClose('calendarEvent')}
                      onCancel={() => this.popUpClose('calendarEvent')}
                      match={{params: {id: 'undefined'}}}
                      start={this.state.newEventStartDate}
                      end={this.state.newEventEndDate}
                    />                   
                  </div>
              </div>
          </section>
      </SlideModal>
    );
  }

  setWrapperRef(node) {
      this.wrapperRef = node;
      document.addEventListener('mousedown', this.handleClickOutside);
      document.addEventListener('keydown', this.handleEscClick);
  }

  handleEscClick(e) {
      if (e.keyCode === 27) {
          this.setState({
                isOpen: false,
                editPopup: false,
                activity: null
            });
      }
  }  

  handleClick(value){
    this.setState({changeStagePopup: value});
  }

  onStatusChange = (stageId, eventId) => {
    client
    .post('/'+this.props.match.params.db +'/api/calendar/event/'+eventId+'/change/stage/'+stageId)
    .then(res => {
      if(res.data && res.data.success){
        showAToast(res.data.success, 'baseSuccess', 'success')
        this.getBookingActivities(this.props.match.params.db, this.state.firstDay, this.state.lastDay,  this.state.calendar, this.state.types);
        let activity = this.state.activity;
        activity.stage = res.data.stage;
        activity.stageId = stageId;
        this.setState({
          activity: activity
        })
      }
    })
    .catch()
  }

  handleSelectSlot(event){
    let day = event.start.getDate();
    let month = event.start.toLocaleString('en-us', { month: 'numeric'})
    let year = event.start.getFullYear();

    let startDate = day + '.' + month + '.' + year + ' '+ event.start.toLocaleTimeString('en-GB');
    
    

    let day1 = event.end.getDate();
    let month1 = event.end.toLocaleString('en-us', { month: 'numeric'});
    let year1 = event.end.getFullYear();
    let endDate =  day1 + '.' + month1 + '.' + year1 + ' '+ event.end.toLocaleTimeString('en-GB');
    
    
    this.setState({
      newCalendarEventPopup: true,
      newEventStartDate: startDate,
      newEventEndDate: endDate
    });
  }

  render() {
    let events = this.state.events;
    let activities = this.state.activities ? this.state.activities : [];

    {activities && activities.map(activity =>(
      activity.start = new Date(activity.start),
      activity.end = new Date(activity.end)
      ))}
    let fieldsView = [];
   
    if(this.state.activity && this.state.activity.type === 'calendarEvent'){
      fieldsView.push('Start and End Date');
      fieldsView.push('Description');
    }

    const monthNames = [
      plainTranslate(this.props.settings.locale, 'January'),
      plainTranslate(this.props.settings.locale, 'February'),
      plainTranslate(this.props.settings.locale, 'March'),
      plainTranslate(this.props.settings.locale, 'April'),
      plainTranslate(this.props.settings.locale, 'May'),
      plainTranslate(this.props.settings.locale, 'June'),
      plainTranslate(this.props.settings.locale, 'July'),
      plainTranslate(this.props.settings.locale, 'August'),
      plainTranslate(this.props.settings.locale, 'September'),
      plainTranslate(this.props.settings.locale, 'October'),
      plainTranslate(this.props.settings.locale, 'November'),
      plainTranslate(this.props.settings.locale, 'December')
    ];

    moment.locale(this.props.settings.locale);
    const localizer = momentLocalizer(moment);
    const messages = {
      today: plainTranslate(this.props.settings.locale, 'Today'),
      month: plainTranslate(this.props.settings.locale, 'Month'),
      week: plainTranslate(this.props.settings.locale, 'Week'),
      day: plainTranslate(this.props.settings.locale, 'Day'),
      monthNames: monthNames
    };
    const eventStyleGetter = (event, start, end, isSelected) => {
      return {
        style: {
          opacity: isSelected ? 1 : 0.9,
          borderColor: event.color,
          backgroundColor: event.color,
          color: 'black'
        },
      };
    };

    const formats = {
      eventTimeRangeFormat: () => { 
        return "";
      },
    };

    return (
        <div className="activity-calendar">
          <div className="dashboard">
            <div className="moduleSubMenu backMenu">
                <div className="col-sm-12">
                    <h3 className="pull-left">{plainTranslate(this.props.settings.locale, 'Booking Calendar')}</h3>
                        <Button style={{ width: "80px!important" }} 
                        className="button-primary"
                        onClick={() => this.setState({newCalendarEventPopup: true})}>                        
                          {window.innerWidth > 768 &&
                            <span>{plainTranslate(this.props.settings.locale, 'New')}</span>
                          }
                          {window.innerWidth < 768 &&
                            <i className="ion-plus-round"></i>
                          }
                          </Button>
                          
                </div>
            </div>
          </div>
          <div className="col-md-12 calendar">
            <div className="activeFilters">
            {this.state.employees && Object.keys(this.state.employees).length > 0 && 
              <b>{plainTranslate(this.props.settings.locale, 'Assigned to')}: </b>}
              {this.state.employees && this.state.employees.map((employee) =>
                <span>{plainTranslate(this.props.settings.locale, employee.label)}, </span>
              )}
             
              {this.state.calendarFilter && Object.keys(this.state.calendarFilter).length > 0 && 
             <b>{plainTranslate(this.props.settings.locale, 'Calendars')}: </b>}
              {this.state.calendarFilter && this.state.calendarFilter.map((event) =>
                <span>{plainTranslate(this.props.settings.locale, event.label)}, </span>
              )}

           {this.state.typesFilter && Object.keys(this.state.typesFilter).length > 0 && 
             <b>{plainTranslate(this.props.settings.locale, 'Types')}: </b>}
              {this.state.typesFilter && this.state.typesFilter.map((event) =>
                <span>{plainTranslate(this.props.settings.locale, event.label)}, </span>
              )}
            
            </div>
            <span className="filters" onClick={() => this.setState({filters: true})}><img src='/images/filter.svg' alt="email settings"/>{plainTranslate(this.props.settings.locale, 'Filter')}</span>
            <Calendar
              onSelectEvent={(activity) => {
                this.handleChange(activity)
              }}
              components = {{ toolbar : CalendarToolbar }}          
              messages={messages}
              localizer={localizer}
              events={activities}
              settings={this.props.settings}
              defaultView={localStorage.getItem("calendarView") ? localStorage.getItem("calendarView") : window.innerWidth > 780 ? Views.MONTH : Views.DAY}
              defaultDate={new Date()}
              onRangeChange={(date) => {
                this.handleDateChange(date)
              }}
              eventPropGetter={eventStyleGetter}
              formats={formats}
              onSelectSlot={this.handleSelectSlot}
              selectable
            />
          </div>
        
           {
            this.state.newCalendarEventPopup && this.showNewCalendarEventPopup()

          }
          {
            this.state.editPopup && this.showEditPopup()

          }
          {this.state.filters === true &&
            <SlideModal onClose={() => this.setState({filters: false})} title={plainTranslate(this.props.settings.locale, 'Filters')}>
              <section className="newPanel" ref={this.setWrapperRef}>
                <div className="panel-body">
                  {this.state.ready && <div className="col-md-10 col-sm-12 row m-bot15">               
                    <div className="col-md-12 filter-type">
                    <h3>{plainTranslate(this.props.settings.locale, 'Calendars')}</h3>
                        <FiltersSelect updateRoute={this.updateRoute}
                             id={'calendar'}
                             isMulti={true}
                             change={this.handleFilterChange}
                             options={this.state.calendarSelect}
                             isDisabled={this.state.isDisabledEvents}
                             url={this.state.calendarsUrl}
                             value={this.state.calendarFilter}/>
                        <div style={{margin: 10}}>
                          <input type="checkbox" id="calendarsAll" 
                          name="calendarsAll" 
                          onChange={this.handleCheckboxChange} 
                          value="calendarsAll" 
                          checked={this.state.calendarsAll || false} 
                          className="eventsAll"
                          style={{padding: 10, margin: 10}}/>
                          <label for="calendarsAll">{plainTranslate(this.props.settings.locale, 'All')}</label>
                        </div>
                    </div>
                    <div className="clearfix"></div>    
                    <div className="col-md-12 filter-type">
                    <h3>{plainTranslate(this.props.settings.locale, 'Event types')}</h3>
                    <FiltersSelect updateRoute={this.updateRoute}
                             id={'types'}
                             isMulti={true}
                             change={this.handleTypesChange}
                             options={this.state.typesSelect}
                             isDisabled={this.state.isDisabledEvents}
                             url={this.state.typesUrl}
                             value={this.state.typesFilter}/></div>              
                    <div className="buttons" style={{display: "table"}}>
                      <button type="button" className="button-primary" onClick={this.applyFilters} id="apply">{plainTranslate(this.props.settings.locale, 'Apply Filters')}</button>
                      <button type="button" className="button-outline grey" onClick={this.cancelFilters} id="cancel">{plainTranslate(this.props.settings.locale, 'Cancel')}</button>
                    </div>
                  </div>}
                </div>
              </section>
            </SlideModal>
          }
        {this.state.isOpen === true &&
          <SlideModal onClose={() => this.setState({ isOpen: false, activity: null })}
            title={plainTranslate(this.props.settings.locale, 'Calendar Event')}>
            <section className="newPanel" ref={this.setWrapperRef}>
              <div className="panel-body">
                <div className="row col-md-12 m-bot15">
                  <div className="row">
                    <div className="status-container col-md-12 col-xs-12">
                      <div className={"status-breadcrumb"}>
                        {
                          this.state.statusOptions.map((element, key) => {
                            return <a
                              className={element.value === this.state.activity.stageId ? 'active' : ''}
                              href="javascript:;" key={key}
                              onClick={() => this.onStatusChange(element.value, this.state.activity.id)}>
                              <span className="breadcrumb__inner">
                                <span className="breadcrumb__desc">{element.label}</span>
                              </span>
                            </a>
                          })
                        }
                      </div>
                    </div>

                    <div className="col-md-5"></div>
                    <div className="col-md-2">
                      <a className="button-primary" href='javascript:void(0)'
                        onClick={() => this.setState({ editPopup: true, isOpen: false })}>
                        {plainTranslate(this.props.settings.locale, 'Edit')}
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    {this.state.activity.title &&
                      <div className="col-md-12">
                        <p className="taskTitle">
                          {plainTranslate(this.props.settings.locale, this.state.activity.title)}/{plainTranslate(this.props.settings.locale, this.state.activity.typeName)}</p>
                        <hr />
                      </div>}
                  </div>
                  <div className="row">
                    {this.state.activity.stage &&
                      <div className="col-md-12">
                        <p className="resultsLabelDate">
                          {plainTranslate(this.props.settings.locale, 'Stage')}: {plainTranslate(this.props.settings.locale, this.state.activity.stage)}</p>
                      </div>}
                  </div>
                  {this.state.activity.start && this.state.activity.end && fieldsView.indexOf("Start and End Date") >= 0 &&
                    <div className="row">
                      <div className="col-md-12">
                        <p className="resultsLabelDate">
                          <i class="fa fa-hourglass-start" aria-hidden="true" style={{ marginRight: 5 }}></i>
                          {moment((this.state.activity.start), 'DD/MM/YYYY').format(this.props.settings.dateFormat)}
                          {/* {this.state.activity.start.toLocaleDateString(this.props.settings.locale, { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', 'hour': 'numeric', 'minute': '2-digit' })} */}
                          <i class="fa fa-hourglass-end" aria-hidden="true" style={{ marginLeft: 20, marginRight: 5 }}></i>
                          {/* {this.state.activity.end.toLocaleDateString(this.props.settings.locale, { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', 'hour': 'numeric', 'minute': '2-digit' })} */}
                          {moment((this.state.activity.end), 'DD/MM/YYYY').format(this.props.settings.dateFormat)}
                        </p>
                      </div>
                    </div>
                  }
                  {this.state.activity.location &&
                    <div className="row">
                      <div className="col-md-12">
                        <p className="resultsLabelDate">
                          <HomeOutlined style={{ marginRight: 5 }} /> {this.state.activity.location}</p>
                      </div>
                    </div>}
                  {this.state.activity.link &&
                    <div className="row">
                      <div className="col-md-12">
                        <p className="resultsLabelDate"><LinkOutlined style={{ marginRight: 5 }} />
                          <a target="_blank" href={this.state.activity.link} style={{ color: '#40a9ff' }}>{this.state.activity.link}</a></p>
                      </div>
                    </div>}
                  {this.state.activity.account &&
                    <div className="row">
                      <div className="col-md-12">
                        <p className="resultsLabelDate">
                          <UserOutlined style={{ marginRight: 5 }} />
                          <Link to={'/' + this.props.match.params.db + '/account/' + this.state.activity.accountId + '/profile'} style={{ color: '#40a9ff' }}>
                            {' ' + this.state.activity.account}</Link></p>
                      </div>
                    </div>}
                  {this.state.activity.task &&
                    <div className="row">
                      <div className="col-md-12">
                        <p className="resultsLabelDate">
                          <ScheduleOutlined style={{ marginRight: 5 }} />
                          <Link to={'/' + this.props.match.params.db + '/task/' + this.state.activity.taskId + '/profile'} style={{ color: '#40a9ff' }}>
                            {this.state.activity.task}</Link></p>
                      </div>
                    </div>}
                  {this.state.activity.address &&
                    <div className="row">
                      <div className="col-md-12">
                        <p className="resultsLabelDate">
                          <EnvironmentOutlined style={{ marginRight: 5 }} /> {this.state.activity.address}</p>
                      </div>
                    </div>}
                  {this.state.activity.description &&
                    <div className="row">
                      <div className="col-md-12">
                        <p className="resultsLabelDate">
                          {plainTranslate(this.props.settings.locale, 'Description')}: <span dangerouslySetInnerHTML={{ __html: this.state.activity.description }}></span></p>
                      </div>
                    </div>}

                </div>
              </div>
            </section>
          </SlideModal>
        }
         
        </div>
      )
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    employees: state.calendar.employees,
});

const mapDispatchToProps = dispatch => ({
    getCalendarEmployees: (db) => dispatch(getCalendarEmployees(db)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityCalendar);
