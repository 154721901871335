import React from 'react';
import { connect } from 'react-redux';
import Translate, { plainTranslate } from "../common/translate";
import Checkbox from './checkbox';
import { MODULES_CHANGE, SETTINGS_CHANGE } from "../../constants/actionTypes";
import client from '../../actions/client';
import showAToast from '../common/showAToast';

class FormFields extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            showMessage: false,
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value ? this.props.value : null
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value ? this.props.value : null
            });
        }
    }

    onChange(item) {
        const settings = this.state.value ? this.state.value : null;
        let found = false;
        for (var i = 0; i < settings[this.props.setting].length; i++) {
            if (settings[this.props.setting][i].name == item.value) {
                found = i;
            }
        }

        if (found !== false) {
            settings[this.props.setting].splice(found, 1);
        } else {
            let newVal = { name: item.value, required: '0' };
            settings[this.props.setting].push(newVal);
        }

        this.setState({
            value: settings,
        }, function () {
            client.post(this.props.saveRoute, {
                'settings': this.state.value.form,
            })
                .then(response => response.data)
                .then(response => {
                    showAToast(plainTranslate(this.props.settings.locale, 'Settings saved successfully!'), 'succSettings', 'success');
                    this.props.onChangeSettings(this.props.setting, this.state.value.form, this.props.type)
                }
                );

        });
    }

    render() {
        return <div className="col-md-12 no-gutter">
            <div className="panel panel-default" style={{ marginTop: 0 }}>
                <table className="table">
                    <thead>
                        <tr>
                            <th><Translate locale={this.props.settings.locale} value={this.props.label} /></th>
                            <th><Translate locale={this.props.settings.locale} value="Enable" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.fields && this.props.fields.map((item, key) => (
                            <Checkbox
                                locale={this.props.settings.locale}
                                key={key}
                                name={item}
                                isChecked={this.state.value && this.state.value[this.props.setting] && this.state.value[this.props.setting].length > 0 && this.state.value[this.props.setting].find(cat => cat['name'] == [item.value]) ? true : false}
                                handleCheckboxChange={this.onChange}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onChangeModules: (modules, setting) => dispatch({ type: MODULES_CHANGE, payload: { plan: modules, setting: setting } }),
    onChangeSettings: (setting, value, type) => dispatch({ type: SETTINGS_CHANGE, payload: { setting: setting, value: value, type: type } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormFields);

