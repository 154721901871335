import React from 'react';
import { connect } from 'react-redux';
import Translate,{ plainTranslate } from "../../common/translate";
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';
import ModalConfirm from '../../common/ModalConfirm';
import { Modal, Button, Select } from 'antd';

class CustomButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            loading: false,
            selectValue: null,
            showConfirmModal: false
        }
        this.clickButton = this.clickButton.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onClick(){
        if(this.props.field.confirm){
            let count = this.props.formData.lines.filter(p => p.lineStatus == false);
            if(count.length > 0){
                this.setState({showConfirmModal: true});
            }else{
                this.setState({showModal: true});
            }
        }else{
            this.clickButton();
        }
    }

    clickButton() {
        this.setState({loading: true});
        //        console.log(this.props.field.linkUrl);  линк към където трябва да отиде потребителя след действието
        //        console.log(this.props.field.actionUrl); линк на действието
        //        console.log(this.props.formData.ids); ид нужно за действията
        let url = this.props.field.actionUrl;
        if(this.props.formData.ids){
            url += '?ids=' + this.props.formData.ids;
        }
        client.post(url, {body: this.state.selectValue})
            .then(response => response.data)
            .then(response => {
                if (response['success']) {
                    showAToast(plainTranslate(this.props.locale, response.success), 'succSettings', 'success');
                    if(this.props.gridpopup){
                        this.props.onSuccessfulSave();
                        this.props.closePopup();
                    }else{
                        if(this.props.field.linkUrl){
                            this.props.history.push(this.props.field.linkUrl);
                        }
                    }
                    if(response.redirectUrl){
                        if(response.newTab){
                            if(Array.isArray(response.redirectUrl)){
                                response.redirectUrl.map(url =>{
                                    window.open(url, '_blank');
                                })
                            }else{
                                window.open(response.redirectUrl, '_blank');
                            }
                        }
                        else{
                            this.props.history.push(response.redirectUrl);
                        }
                    }
                } else {
                    showAToast(plainTranslate(this.props.locale, response.errors), 'errSettings', 'error');
                }
                this.setState({showModal: false,loading: false});
            })
            .catch(err => {console.log(err);
                this.setState({showModal: false,loading: false});
                showAToast('Error!', 'success2', 'error');
            })
    }

    render() {
        return <React.Fragment>
                <Modal visible={this.state.showModal} onOk={this.clickButton} onCancel={() => this.setState({showModal: false})} footer={null}>
                    <div className="text-center">
                        {this.props.field.dependencyOptions &&
                            <div style={{marginTop: 30}}><span style={{marginRight: 20}}>{plainTranslate(this.props.locale, 'Select stage of inventory receipts')}</span>
                                <Select
                                    onSelect={(value) => this.setState({selectValue: value})}
                                    filterOption={false}
                                    options={this.props.field.dependencyOptions}
                                    style={{ width: 240 }} 
                                />
                            </div>
                        }
                        <div className="buttons">
                            <Button className="button-outline grey text-center" loading={this.state.loading} onClick={() => this.setState({showModal: false})}>
                                <Translate locale={this.props.settings.locale} value="Cancel" />
                            </Button>
                            <Button className={"button-primary text-center btn-ok" + (this.state.selectValue ? '' : ' disabled')}
                                onClick={this.clickButton} loading={this.state.loading} disabled={this.state.selectValue ? false : true}>
                                <Translate locale={this.props.settings.locale} value="Yes" />
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal visible={this.state.showConfirmModal} onOk={this.clickButton} onCancel={() => this.setState({showConfirmModal: false})} footer={null}>
                    <div className="text-center">
                            <h3><strong>
                                <Translate locale={this.props.settings.locale} value="Are you sure?" />
                            </strong>
                            </h3>
                        <div className="buttons">
                            <Button className="button-outline grey text-center" onClick={() => this.setState({showConfirmModal: false})}>
                                <Translate locale={this.props.settings.locale} value="Cancel" />
                            </Button>
                            <Button className="button-primary text-center btn-ok"
                                onClick={() => this.setState({showConfirmModal: false, showModal: true})}>
                                <Translate locale={this.props.settings.locale} value="Yes" />
                            </Button>
                        </div>
                    </div>
                </Modal>
            
            <button className="button-outline red " type="button" name={this.props.field.id} onClick={this.onClick}>{plainTranslate(this.props.locale, this.props.field.buttonText)}</button>
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CustomButton);
