import React, {useState, useRef} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';
import {onAccountFetchData, onFieldSave} from "../../../../actions/actions";
import { Card, Form, Input, Row, Col, Button, DatePicker, Select } from 'antd';
import client from '../../../../actions/client'
import showAToast from '../../../common/showAToast'
import { EditOutlined } from '@ant-design/icons'
import Popup from "../../../common/popup";
import moment from 'moment';

const OtherInformation = (props) => {
    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const openEdit = () => {        
        setOpen(!open);
    }    
    var showButton = false;
    if(props.account.listFields.find(field => field=='subscribeDate')){
        showButton = true;
    }
    if(props.account.listFields.find(field => field=='unsubscribeDate')){
        showButton = true;
    }
    if(props.account.listFields.find(field => field=='unsubscribeReason')){
        showButton = true;
    }
    if(props.account.listFields.find(field => field=='subscribeStatus')){
        showButton = true;
    }

    const onFinish = (values) => {   
        if(values['subscribeDate']){
            values['subscribeDate'] = values['subscribeDate'].format(props.settings.dateFormat)
        } 
        if(values['unsubscribeDate']){
            values['unsubscribeDate'] = values['unsubscribeDate'].format(props.settings.dateFormat)
        }     
         setLoading(true)   
        let route = '/'+props.db+'/api/account-more/modify/'+props.account.id;       
         client.post(route, {more: values})
                .then(response => response.data)
                .then(response => {                       
                     showAToast('Success!', 'success', 'success')
                     props.onAccountFetchData(props.db, props.account.id, false)                      
                     setLoading(false) 
                     openEdit()
                 })
                .catch(err => {
                  showAToast('Error!', 'baseError', 'error')
                  setLoading(false) 
                })  
    }
    
        return (
            <><Card title={plainTranslate(props.locale, 'More')}
            size="small"
            extra={showButton && <EditOutlined onClick={openEdit}/>}
            style={{marginTop: 10}}>
                <div className="account-profile-other-information-more"> 
                {(props.account.listFields.find(field => field=='subscribeStatus') ? true:false) &&
                <Row style={{margin: 10}}><Col span={12}>{plainTranslate(props.locale, 'Subscribe')}: </Col>
                    <Col>{props.account.subscribeStatus === 1 ? 
                    <span>{plainTranslate(props.locale, 'Yes')}</span>
                    :
                    (props.account.subscribeStatus === 2 ?
                        <span>{plainTranslate(props.locale, 'No')}</span>
                        :
                        <span>{plainTranslate(props.locale, 'No data')}</span>
                        )                
                    }</Col>
                </Row>}           
                {(props.account.listFields.find(field => field=='subscribeDate') ? true:false) &&
                <Row style={{margin: 10}}><Col span={12}>{plainTranslate(props.locale, 'Subscribe at')}: </Col>
                    <Col>{props.account.subscribeDate ? moment(props.account.subscribeDate).format(props.settings.dateFormat) : ''} </Col>
                </Row>}
                {(props.account.listFields.find(field => field=='unsubscribeDate') ? true:false) &&
                <Row style={{margin: 10}}><Col span={12}>{plainTranslate(props.locale, 'Unsubscribe at')}: </Col>
                    <Col> {props.account.unsubscribeDate ? moment(props.account.unsubscribeDate).format(props.settings.dateFormat) : ''}
                    </Col>
                </Row>}
                {(props.account.listFields.find(field => field=='unsubscribeReason') ? true:false) &&
                <Row style={{margin: 10}}><Col span={12}>{plainTranslate(props.locale, 'Unsubscribe reason')}: </Col>
                    <Col>  {props.account.unsubscribeReason}   
                    </Col>
                </Row>}               
                <Row style={{margin: 10}}><Col span={12}>{plainTranslate(props.locale, 'Consent to personal data processing')}: </Col>
                    <Col>{props.account.consentDataProcessing ?
                            <span>{plainTranslate(props.locale, 'Yes')}</span>
                            :
                            <span className="warning">{plainTranslate(props.locale, 'No')}</span>
                        }   
                    </Col>
                </Row>
              
                <Row style={{margin: 10}}>
                    <Col span={12}>{plainTranslate(props.locale, 'Consent for marketing communication')}: </Col>
                <Col>{props.account.consentMarketing ?
                        <span>{plainTranslate(props.locale, 'Yes')}</span>
                        :
                        <span className="warning">{plainTranslate(props.locale, 'No')}</span>
                    }</Col>
                </Row>                   
                </div>
            </Card>            
             {open &&
                        <Popup
                        locale={props.locale}
                        title={plainTranslate(props.locale, 'More')}
                        onClose={openEdit}>
                        <section className="newPanel">
                        <div className="panel-body">
                              <Form
                                ref={ref}
                                name="more"                                
                                onFinish={onFinish}                             
                                layout="vertical">
                                <Row gutter={[40, 22]}>
                        {(props.account.listFields.find(field => field=='subscribeStatus') ? true:false) &&
                         <Col span={12}>
                            <Form.Item
                                name={'subscribeStatus'}
                                label={plainTranslate(props.locale, 'Subscribe')}
                                initialValue={props.account.subscribeStatus ? props.account.subscribeStatus.toString() : null}>
                                <Select size={'large'}>  
                                        <Select.Option value={'1'} key={1}>
                                            {plainTranslate(props.locale, 'Yes')}
                                        </Select.Option>
                                        <Select.Option value={'2'} key={1}>
                                            {plainTranslate(props.locale, 'No')}
                                        </Select.Option>                   
                                </Select> 
                            </Form.Item> </Col> }
                            {(props.account.listFields.find(field => field=='subscribeDate') ? true:false) &&
                            <Col span={12}>
                            <Form.Item
                                name={'subscribeDate'}
                                label={plainTranslate(props.locale, 'Subscribe at')}
                                initialValue={props.account.subscribeDate ? moment(props.account.subscribeDate) : null}>
                                    <DatePicker 
                                    style={{height: 40, width: '100%'}}  
                                    format={props.settings.dateFormat}/>
                                </Form.Item></Col> }
                            {(props.account.listFields.find(field => field=='unsubscribeDate') ? true:false) &&
                                <Col span={12}>
                               <Form.Item
                                name={'unsubscribeDate'}
                                label={plainTranslate(props.locale, 'Unsubscribe at')}
                                initialValue={props.account.unsubscribeDate ? moment(props.account.unsubscribeDate) : null}>
                                    <DatePicker 
                                    style={{height: 40, width: '100%'}}  
                                    format={props.settings.dateFormat}/>
                              </Form.Item></Col>}
                            {(props.account.listFields.find(field => field=='unsubscribeReason') ? true:false) &&
                              <Col span={12}>
                               <Form.Item
                                name={'unsubscribeReason'}
                                label={plainTranslate(props.locale, 'Unsubscribe reason')}
                                initialValue={props.account.unsubscribeReason}>
                                   <Input /> 
                              </Form.Item></Col> } 
                              </Row>
                              <Button 
                                    loading={loading}
                                    htmlType="submit"
                                    className='button-primary'
                                    style={{  
                                        paddingLeft: 40,
                                        paddingBottom: 37}}>
                                    {plainTranslate(props.locale, 'Save')}</Button>
                            </Form>
                            </div>
                            </section> 
                        </Popup>
                    }</>
        )
}

const mapStateToProps = state => ({
    settings: state.settings,
});

export default connect(mapStateToProps, {onAccountFetchData})(OtherInformation);

