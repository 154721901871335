import React, {Component} from 'react';
import {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';
import {onUpdateContentRecords} from "../../../../actions/actions";
import SlideModal from '../../../common/SlideModal';
import { Table} from 'antd';
import SimpleForm from "../../../redux-forms/form";
import { withRouter } from 'react-router-dom';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';

class ContentGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns : [],
            showForm: false
        };
        this.onLoad = this.onLoad.bind(this);
        this.showForm = this.showForm.bind(this);
        this.updateRecords= this.updateRecords.bind(this);
        this.deleteContent = this.deleteContent.bind(this);
    }
    
    componentDidMount() {
        this.onLoad();
    }

    deleteContent(contentId) {
        client
            .get('/' + this.props.match.params.db + '/api/content-data/' + this.props.contentType.id + contentId + '/delete')
            .then(response => response.data)
            .then(response => {
                this.props.updateContentRecords(this.props.match.params.db, this.props.contentType.id, this.props.contentType.sin, this.props.contentType.linkTables, this.props.contentType.links);
                if ('errors' in response) {
                    showAToast(response.errors, 'baseError', 'error');
                }
                else {
                    showAToast('Data is deleted successfully!', 'baseSuccess', 'success');
                }
            })
    }
    
    onLoad() {
        let schema = this.props.contentType.schema; 
        let columns = [];
        columns = schema.map(field => ({'key' : field.id, 'dataIndex': field.id, 'type': field.type, 'title': plainTranslate(this.props.settings.locale, field.label)}));
        columns= [({
                'key': 'actions',
                'dataIndex': 'id',
                'title': plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text) => {
                    return (
                        <span>
                            <a to='#' onClick={() => this.setState({ showForm: true, contentTypeId: text })}>
                                <span> <i className="fa fa-pencil-square-o"></i> </span>
                            </a>
                            <a to='#' onClick={() => this.deleteContent(text)}>
                                <span> <i className="fa fa-trash-o"></i> </span>
                            </a>
                        </span>
                    )
                },
            }), ...columns];

            for (var i = 0; i < columns.length; i++) {
                if (columns[i].type == 'images') {
                    columns[i].render = (text) => {
                    return (
                        <span>
                            {text &&
                                <img alt={text[0].name} src={text[0].thumbUrl} />
                            }
                        </span>
                    )
                }
              } 
              if (columns[i].type == 'switch') {
                    columns[i].render = (text) => {
                    return (
                        <span>
                            {text == '1' ?
                                <i className="fa fa-check" aria-hidden="true"></i>
                                :
                                <i className="fa fa-times" aria-hidden="true"></i>
                            }
                        </span>
                    )
                }
              } 
            }
        this.setState({ columns: columns }); 
        this.props.updateContentRecords(this.props.match.params.db, this.props.contentType.id, this.props.contentType.sin, this.props.contentType.linkTables, this.props.contentType.links);   
    }
    
    showForm() {
        this.setState({
            showForm: !this.state.showForm,
        });
    }
    
    updateRecords() {
        this.props.updateContentRecords(this.props.match.params.db, this.props.contentType.id, this.props.contentType.sin, this.props.contentType.linkTables, this.props.contentType.links); 
        this.setState({showForm: false});
    }
    
    render() {                 
        return (
                <div>
                    <div className="custom-data btn-group">
                        <button  className="btn-round btn-blue" type="button" onClick={this.showForm}><i className="ion-android-add "></i></button>
                    </div>
                    {this.props.accountProfileInformation && this.props.accountProfileInformation.contentData && this.props.accountProfileInformation.contentData.data.length > 0 ?
                       <Table
                            rowKey={record => record.id}
                            columns={this.state.columns}
                            dataSource={this.props.accountProfileInformation.contentData.data}
                            pagination={true}
                            scroll={{ x: "auto" }}
                            size="small"
                            />
                        :
                        <div className="no-projects text-center">
                            <img src='/images/no-activities.svg' />
                            <h5>{plainTranslate(this.props.settings.locale, 'No data to show')}</h5>
                        </div>
                    }
                    {this.state.showForm &&
                    <SlideModal onClose={() => this.setState({showForm: false, contentTypeId: null})} title={plainTranslate(this.props.settings.locale, this.props.label)}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                     <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/content-data/'}
                                        saveRoute={this.state.contentTypeId ? this.props.match.params.db + '/api/content-data/edit/' + this.props.contentType.id + this.state.contentTypeId : this.props.match.params.db + '/api/content-data/' + this.props.contentType.id}
                                        onSuccessfulSave={this.updateRecords}
                                        popup={true}
                                        onCancel={() => this.setState({showForm: false, contentTypeId: null})}
                                        match={{params: {id: this.state.contentTypeId ? this.props.contentType.id + this.state.contentTypeId : this.props.contentType.id}}}
                                        isNew={true}
                                        refs={this.props.accountProfileInformation.sin}
                                        form={'initializeFromState'}
                                    />
                            </div>
                        </div>
                    </section>
                </SlideModal>
                }
                </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    accountProfileInformation: state.accountProfileInformation
});
const mapDispatchToProps = dispatch => ({
    updateContentRecords: (db, typeId, accountId, linkTables, links) => dispatch(onUpdateContentRecords(db, typeId, accountId, linkTables, links))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContentGrid));

