import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../../common/translate';
import Select2 from './select2';

class UserRoles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            roles: [],
            location: {}
        };

        this.onLocationChange = this.onLocationChange.bind(this);
        this.onRolesChange = this.onRolesChange.bind(this);
        this.findLocation = this.findLocation.bind(this);
        this.findRoles = this.findRoles.bind(this);
    }

    componentDidMount() {
        this.setState({
            selectedLocation: this.findLocation(),
            selectedRoles: this.findRoles()
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedLocation !== this.props.selectedLocation || JSON.stringify(prevProps.selectedRoles) !== JSON.stringify(this.props.selectedRoles)) {
            this.setState({
                selectedLocation: this.findLocation(),
                selectedRoles: this.findRoles()
            });
        }
    }

    onLocationChange(option) {
        this.props.onChange({
            'location': option.value,
            'roles': this.state.selectedRoles.map(role => role.value)
        });
    }

    onRolesChange(option) {
        this.props.onChange({
            'location': this.props.selectedLocation,
            'roles': option.map(role => role.value)
        });
    }

    findRoles() {
        if(!this.props.roles || !this.props.selectedRoles) return [];

        let roles = [];
        this.props.roles.forEach(item => {
            if(this.props.selectedRoles.includes(item.value)) {
                roles.push(item);
            }
        });
        return roles;
    }

    findLocation() {
        if(!this.props.locations || !this.props.selectedLocation) return null;

        let loc = null;
        this.props.locations.forEach(item => {
            if(item.value === this.props.selectedLocation) {
                loc = item;
            }
        });
        return loc;
    }

    render() {
        return (
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <div className="form-group">
                        <label
                            className="required col-md-12 text-left">
                            {plainTranslate(this.props.settings.locale, 'Location')}
                        </label>
                        <div className={'col-md-12'}>
                            <Select2
                                options={this.props.locations}
                                onChange={this.onLocationChange}
                                value={this.state.selectedLocation}
                            />
                        </div>
                    </div>
                </div>
                <div className={'col-md-6'}>
                    <div className="form-group">
                        <label
                            className="required col-md-12 text-left">
                            {plainTranslate(this.props.settings.locale, 'Roles')}
                        </label>
                        <div className={'col-md-12'}>
                            <Select2
                                isMulti
                                closeOnSelect={true}
                                options={this.props.roles}
                                onChange={this.onRolesChange}
                                value={this.state.selectedRoles}
                            />
                        </div>
                        <button type="button" className="button-outline grey pull-right" style={{borderColor: 'red', color: 'red'}} onClick={this.props.removeLine}>
                            {plainTranslate(this.props.settings.locale, 'Delete location')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserRoles);
