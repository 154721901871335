import React from 'react';
import {connect} from 'react-redux';
import client from '../../../actions/client';
import { plainTranslate } from "../../common/translate";

class Text extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '', 
            warn: null
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value ? this.props.value : ''
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value ? this.props.value : ''
            });
        }
    }

    onChange(e) {
        this.setState({
            value: e.target.value
        });
        this.props.onChange(this.props.id, e.target.value);
    }

    handleOutFocus(e) {
        this.props.loseFocus(this.props.id)
        if (this.props.isUnique) {
            client
                .post(this.props.isUnique, { field: this.props.id, value: this.props.value })
                .then(res => {
                    if (res.data.notunique) {
                        var warning = plainTranslate(this.props.locale, "Billing with ") + plainTranslate(this.props.locale, this.props.id) + ': ' + this.props.value + plainTranslate(this.props.locale, " already exists!");
                        this.setState({warn:warning})
                    } else {
                        this.setState({warn:''})
                    }
                })
                .catch(err => {

                })
        }
    }

    render() {
        return this.props.isPopup ?
            <div className="form-input-field">
                {this.state.warn && <span style={{ color: 'orangered', margin: 10 }}>{this.state.warn}</span>}
                <input type="text" className="form-control"
                       value={this.state.value}
                       onChange={this.onChange}
                       onBlur={(e) => this.handleOutFocus(e)}
                    //    onBlur={() => this.props.loseFocus(this.props.id)}
                       placeholder={this.props.placeholder ? this.props.placeholder : ''}
                       tabIndex={this.props.tabindex ? this.props.tabindex : ''}
                />
            </div>
            :
            <span className="form-input-field">
                    {this.state.warn && <span style={{ color: 'orangered', margin: 10 }}>{this.state.warn}</span>}
                    <input type="text" className="form-control"
                           value={this.state.value}
                           onChange={this.onChange}
                           onBlur={(e) => this.handleOutFocus(e)}
                        //    onBlur={() => this.props.loseFocus(this.props.id)}
                           placeholder={this.props.placeholder ? this.props.placeholder : ''}
                           tabIndex = {this.props.tabindex ? this.props.tabindex : ''}
                           disabled={this.props.disabled}
                    />
                </span>

    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Text);
