import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerUser } from '../../actions/authActions';
import { Link } from 'react-router-dom';
import { Form, Row, Col, Select, Input, Button } from 'antd';
import TextFieldGroup from '../common/TextFieldGroup';
import { plainTranslate } from '../common/translate';
import showAToast from '../common/showAToast';
import { passwordStrength } from 'check-password-strength'
import client from '../../actions/client'

var loadjs = require('loadjs');

const { Option } = Select;

class Register extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      tokens: null,
      errors: [],
      loading: false
    };

  }

  componentDidMount() {
    window.onSubmit = this.onSubmit
    loadjs('https://www.google.com/recaptcha/api.js');
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/'+this.props.match.params.db+'/dashboard');
    }
    client
      .get('/'+this.props.match.params.db+'/check/data')
      .then(response => {

       if(response.data.data >= 1){
        this.props.history.push('/'+this.props.match.params.db+'/user/signin')
       }
       
      })
      .catch(error => {
        this.props.history.push('/'+ this.props.match.params.db +'/user/signin')
      })
    
    document.documentElement.style.backgroundColor = "#fbfaff";
    const queryString = window.location.search;    
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get('email')
        if(email){
          this.formRef.current.setFields([
            {
              name: 'email',
              value: email
            },
         ]); 
        }
      }

      componentDidUpdate(prevProps){
        loadjs('https://www.google.com/recaptcha/api.js');
    }

      compareToFirstPassword  = (rule, value, callback) => {
        if(!this.formRef.current.getFieldValue('password')){          
          this.formRef.current.setFields([
            {
              name: 'password',
              errors: [plainTranslate(this.props.settings.locale,'Please input your password!')],
            },
         ]);
        }
        
        if (value && value !== this.formRef.current.getFieldValue('password')) {
          this.setState({enableSave: false});
          callback(plainTranslate(this.props.settings.locale,'Passwords do not match!'));
        } else if(value) {
            this.setState({enableSave: true});
          callback();
        }else if(!value){
          callback(plainTranslate(this.props.settings.locale,'Please repeat your password!'))
        }else{
          callback()
        }
      }

      validatePassword = (rule, value, callback) => {
        if(!value){    
          callback(plainTranslate(this.props.settings.locale,'Please input your password!'))       
        }else{
          var validate = passwordStrength(value);
          if(validate.value === 'Strong'){
            callback()
          }else{
            callback('Your password must  contain minimum 10 characters and at least one uppercase, one lowercase, one number and one special character.')
          }          
        }
        if(value && this.formRef.current.getFieldValue('repeatPassword')){
          if(value === this.formRef.current.getFieldValue('repeatPassword')){
            this.formRef.current.setFields([
              {
                name: 'repeatPassword',
                errors: [],
              },
          ]); 
          callback()
          }else{
            this.formRef.current.setFields([
              {
                name: 'repeatPassword',
                errors: [plainTranslate(this.props.settings.locale,'Passwords do not match!')],
              },
           ]);   
           callback()
          }
        }else{
          callback()
        }
      }  

    onSubmit = (token) => { 
      this.setState({
        loading: true
      })
      setTimeout(
        () => {
          this.setState({
            tokens: token
          })
          this.formRef.current.setFields([
            {
              name: 'token',
              value: token,
            }
         ]); 
          this.formRef.current.submit()
        }, 
        1000
      );      
  }

  onFinish = (values) => {   
    values.role = 'user'
  this.props.registerUser(this.props.match.params.db, values, this.props.history)
  .then(res => {    
    this.setState({
      loading: false
    }) 
  })
  
}

onFinishFailed = (values, errorFields, outOfDate ) => {    
  this.setState({
    loading: false
  })
}

  render() {    
   
    return (
        <article className="register">
            <div>
              <Row type="flex" align="middle" className="login-top">
                <Col lg={1} />
                 <Col lg={16} md={10} sm={12} xs={12}>
                   <img className="composity-logo" src="https://composity.com/images/org/8f621c.png" alt="Composity logo" />
                 </Col>
                 <Col lg={6} md={12} sm={12} xs={12}>
                   <Link style={{float: "right"}} className="login-button-white" to={'/' + this.props.match.params.db + '/user/signin'}>{plainTranslate(this.props.settings.locale, 'Sign in')}</Link>
                 </Col>
                 <Col lg={1} />
               </Row>
              <div className="col-md-12 col-sm-12 col-xs-12 login-body">
                <div className="col-md-4"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 frame" >
                  <div className="minimal-body">
                  <div className="text-center company-logo"></div>
                      <h3 style={{marginBottom: 30 }} className="text-center">{plainTranslate(this.props.settings.locale, 'Create an account for')} {this.props.match.params.db} {plainTranslate(this.props.settings.locale, 'workspace')}</h3>
                     <Form 
                    ref={this.formRef}
                    layout="vertical"
                    onFinish={this.onFinish} 
                    onFinishFailed={this.onFinishFailed}
                    className="login-form">
                      <Form.Item 
                       name="firstName"
                      label={plainTranslate(this.props.settings.locale, "First name")} 
                      rules={[
                        {
                          required: true,
                          message: plainTranslate(this.props.settings.locale,'Please input your first name!'),
                        },
                      ]}
                      className="login-input">
                        <Input />                          
                          </Form.Item>
                          <Form.Item 
                          name="lastName"
                          label={plainTranslate(this.props.settings.locale, "Last name")} 
                          className="login-input"
                          rules={[
                            {
                              required: true,
                              message: plainTranslate(this.props.settings.locale,'Please input your last name!'),
                            },
                          ]}>
                            <Input />                        
                          </Form.Item>
                          <Form.Item 
                          name="email"
                          label={plainTranslate(this.props.settings.locale, "Email")} 
                          className="login-input"
                          rules={[
                            {
                              required: true,
                              message: plainTranslate(this.props.settings.locale,'Please input your email!'),
                            },
                          ]}>
                          <Input />
                          </Form.Item>                          
                          <Form.Item 
                          name="phone"
                          label={plainTranslate(this.props.settings.locale, "Phone")} 
                          className="login-input"
                          rules={[
                            {
                              required: true,
                              message: plainTranslate(this.props.settings.locale,'Please input your phone!'),
                            },
                          ]}>
                         <Input />
                          </Form.Item>
                          <Form.Item 
                           name='language'
                          label={plainTranslate(this.props.settings.locale, "Language")} 
                          className="login-input"
                          rules={[
                            {
                              required: true,
                              message: plainTranslate(this.props.settings.locale,'Please input your language!'),
                            },
                          ]}>                          
                            <select                       
                            style={{ width: '100%', height: 60, background: 'white' }}
                            className="form-select-basic form-control">
                              <option value=""></option>
                              <option value="en">English</option>
                              <option value="bg">Български</option>
                              <option value="ro">Română</option>
                              <option value="de">Deutsch</option>
                              <option value="tr">Türkçe</option>
                              <option value="km">Khmer</option>
                            </select>                           
                          </Form.Item>
                        <Form.Item 
                         name="password"                         
                        label={plainTranslate(this.props.settings.locale, "Password")} 
                        className="login-input"
                        rules={[
                          {
                            required: true,
                            validator: this.validatePassword,
                          },
                        ]}>
                        <Input type="password"/>
                        </Form.Item>
                        <Form.Item 
                        name="repeatPassword"                        
                        label={plainTranslate(this.props.settings.locale, "Repeat password")} 
                        className="login-input"
                        rules={[
                          {
                            required: true,
                            validator: this.compareToFirstPassword,
                          }
                        ]}>
                        <Input type="password"/>
                        </Form.Item>
                        <Form.Item 
                        name="token">
                          <Input type="hidden"/>
                          </Form.Item> 
                          {this.state.loading ?
                         <Button className="auth-button" 
                         style={{height: 50, width: 180}}                         
                         loading={true}>
                         {plainTranslate(this.props.settings.locale, 'Sign  up')}</Button>
                        :
                        <button className="g-recaptcha auth-button" 
                        style={{marginBottom: 100, width: 180}}
                            data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            data-callback={'onSubmit'}
                            data-action='submit'>
                            {plainTranslate(this.props.settings.locale, 'Sign up')}</button> }
                            <br/>
                 </Form>
                  </div>
                </div>

              </div>
              </div>
        </article>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired 
};

const mapStateToProps = state => ({
  auth: state.auth,
  settings: state.settings,
});

export default connect(mapStateToProps, { registerUser })(Register);
