import React, { useState, useEffect } from 'react';
import Translate, { plainTranslate } from '../../common/translate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NewForm from "../../redux-forms/form";
import { Button, Tooltip, Radio, Form, Input, Select, Checkbox, Skeleton } from 'antd';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';
import { SendOutlined } from '@ant-design/icons';
import SlideModal from '../../common/SlideModal';

const { Option } = Select;

/**
* @author Nevyana Mihaleva
* @function SurveyVisualization
**/

const SurveyVisualization = (props) => {
  const [fetching, setFetching] = useState(false);
  const [survey, setSurvey] = useState(null);
  const [emailPopup, setEmailPopup] = useState(false);
  const [emailSettings, setEmailSettings] = useState(null);
  const [sendEmailPopup, setSendEmailPopup] = useState(false);
  const [surveyCc, setSurveyCc] = useState(null);
  const [respondentCc, setRespondentCc] = useState(null);
  const [choosenEmail, setChoosenEmail] = useState(null);
  const [form] = Form.useForm();
  const [emailForm] = Form.useForm();

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!'
    }
  };

  useEffect(() => {
    setFetching(true);
    if (props.match.params.id) {
      client
        .get(`/${props.match.params.db}/api/surveys/${props.match.params.id}`)
        .then((response) => {
          setSurvey(response.data);
          client
            .get('/' + props.match.params.db + '/api/survey/' + props.match.params.id + '/info')
            .then((response) => {
              setEmailSettings(response.data.emailSettings);
            })
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [props.match.params.id]);

  const sendSurvey = (email) => {
    const hostname = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';
    client
      .post(`/${props.match.params.db}/web/content/survey/send`, { email: email, survey: survey.id, name: email })
      .then((response) => {
        if (response.data.message) {
          showAToast(response.data.message, 'succSettings', 'success');
        } else {
          setSurveyCc(response.data.surveyCc);
          setRespondentCc(response.data.cc);
          console.log(`You can see it on ${hostname}/${props.match.params.db}/public/survey/${response.data.surveyCc}/${response.data.cc}`);
          setEmailPopup(false);
          setSendEmailPopup(true);
        }
      })
      .catch((error) => {
        showAToast('Error', 'errSettings', 'error');
      })
  }

  const onFinish = (values) => {
    setChoosenEmail(values.email);
    sendSurvey(values.email);
  };

  return (
    <div>
      {fetching &&
        <>
          <div className="invoice-profile item-profile">
            <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
              <div className="col-lg-12 col-md-12 col-xs-12" style={{ display: window.innerWidth > 780 ? "flex" : "block", alignItems: "center" }}>
                <div className="profile-breadcrumb col-md-4">
                  <Link to={"/" + props.match.params.db + "/surveys"}>
                    <i className="ion-ios-arrow-back"></i>{plainTranslate(props.settings.locale, 'Survey')}
                  </Link>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-xs-12 account-profile-tabs">
                {window.innerWidth > 780 && survey && <div className="col-md-12 document-profile-tabs" style={{ margin: "10px 0 30px 0" }}>
                  <Tooltip placement="top" title={plainTranslate(props.settings.locale, 'Send survey')}>
                    <Button onClick={() => setEmailPopup(true)} type="default" ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                      <SendOutlined />
                    </Button>
                  </Tooltip>
                </div>
                }
              </div>
              <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                <div className="col-lg-12 col-md-12 col-xs-12 itemHistory-table">
                  {survey ?
                    <Form
                      form={form}
                      layout="vertical"
                      className="form mtb-30"
                    >
                      <h1 style={{ whiteSpace: "pre-line" }}>{plainTranslate(props.settings.locale, survey.name)}</h1>
                      <p style={{ whiteSpace: "pre-line" }}>{plainTranslate(props.settings.locale, survey.description)} </p>
                      {survey.questions && survey.questions.length && survey.questions.sort((a, b) => a.position - b.position).map((question, key) => {
                        return <>
                          <Form.Item
                            name={question.id}
                            label={<span className="form-label">{question.position}. {plainTranslate(props.settings.locale, question.question)} {question.isRequired ? '*' : ''}</span>}
                            key={question.id}
                          >
                            {question.type === 'scale' && !isNaN(parseInt(question.options)) &&
                              <Radio.Group name="radiogroup" >
                                {question.options && [...Array(parseInt(question.options))].map((value, index) => (
                                  <Radio key={index} value={index + 1}>{index + 1}</Radio>
                                ))
                                }
                                {question.isEmptyOption &&
                                  <Radio value={null}>{plainTranslate(props.settings.locale, "I don’t know/ not applicable")}</Radio>
                                }
                              </Radio.Group>
                            }
                            {question.type === 'text' &&
                              <Input type="text" />
                            }
                            {question.type === 'dropdown' &&
                              <Select style={{ width: 120 }} >
                                {question.options && question.options.split(',').map((value, index) => (
                                  <Option key={index} value={value}>{plainTranslate(props.settings.locale, value)}</Option>
                                ))
                                }
                              </Select>
                            }
                            {question.type === 'checkbox' &&
                              <Checkbox.Group  >
                                {question.options && question.options.split(',').map((value) => {
                                  return <Checkbox value={value}>{plainTranslate(props.settings.locale, value)}</Checkbox>
                                })
                                }
                              </Checkbox.Group>
                            }
                            {question.type === 'multiply-dropdown' &&
                              <Select
                                mode="multiple"
                                allowClear
                                placeholder="Please select"
                                style={{ width: '100%' }}
                              >
                                {question.options && question.options.split(',').map((value, index) => (
                                  <Option key={index} value={value}>{plainTranslate(props.settings.locale, value)}</Option>
                                ))
                                }
                              </Select>
                            }
                          </Form.Item>
                        </>
                      })}
                    </Form>
                    :
                    <Skeleton />
                  }
                </div>
              </div>
            </div>
          </div>
          {emailPopup &&
            <SlideModal onClose={() => setEmailPopup(false)} title={plainTranslate(props.settings.locale, 'Who will you send this survey to?')}>
              <section className="newPanel">
                <div className="panel-body">
                  <div className="row m-bot15">
                    {survey && emailSettings && Array.isArray(emailSettings) ?
                      <Form
                        form={emailForm}
                        onFinish={onFinish}
                        layout="vertical"
                        className="form mtb-30"
                        validateMessages={validateMessages}
                      >
                        <Form.Item
                          label="Email"
                          name="email"
                          type="email"
                          rules={[{ required: true, message: 'Please input email!', type: 'email' }]}
                        >
                          <Input />
                        </Form.Item>
                        <Button type="primary" htmlType="submit">{plainTranslate(props.settings.locale, 'Submit')}</Button>
                      </Form>
                      :
                      <div>
                        <p>{plainTranslate(props.settings.locale, 'Send email directly from an Account profile with the Email service integration. Configure Email integration-related settings on the Composity platform from Account -> Email.')}</p>
                        {props.settings.user.roles.includes('Admin') &&
                          <a className="rectangular_button green" href={'/' + props.match.params.db + '/settings/integrations'}>
                            <Translate locale={props.settings.locale} value="Setup now" />
                          </a>
                        }
                      </div>
                    }
                  </div>
                </div>
              </section>
            </SlideModal>
          }
          {sendEmailPopup && choosenEmail &&
            <SlideModal onClose={() => setSendEmailPopup(false)} title={plainTranslate(props.settings.locale, 'New Email')}>
              <section className="newPanel">
                <div className="panel-body">
                  <div className="row m-bot15">
                    {survey && emailSettings && Array.isArray(emailSettings) ?
                      <NewForm
                        formRoute={props.match.params.db + '/api/message-email/'}
                        saveRoute={props.match.params.db + '/api/message-email/new'}
                        popup={true}
                        onSuccessfulSave={(response) => sendSurvey(response)}
                        onCancel={() => setSendEmailPopup(false)}
                        form={'initializeFromState'}
                        match={{ params: { id: 0 } }}
                        refs={choosenEmail}
                        type={JSON.stringify({ "surveyCc": surveyCc, "respondentCc": respondentCc })}
                        isNew={true}
                        sendButton={true}
                      />
                      :
                      <div>
                        <p>{plainTranslate(props.settings.locale, 'Send email directly from an Account profile with the Email service integration. Configure Email integration-related settings on the Composity platform from Account -> Email.')}</p>
                        {props.settings.user.roles.includes('Admin') &&
                          <a className="rectangular_button green" href={'/' + props.match.params.db + '/settings/integrations'}>
                            <Translate locale={props.settings.locale} value="Setup now" />
                          </a>
                        }
                      </div>
                    }
                  </div>
                </div>
              </section>
            </SlideModal>
          }
        </>
      }
    </div>
  )
}

const mapStateToProps = state => ({
  settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyVisualization);
