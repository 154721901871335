import React, {Component} from 'react';
import Translate,{plainTranslate} from '../common/translate';
import {connect} from 'react-redux';
import {onDocumentProfileFetchData, onFieldSave} from '../../actions/actions';
import {Link} from 'react-router-dom';
import FormLoader from '../forms/form-loader';
import './invoiceProfile.css';
import { Table, Dropdown, Menu, Button, Progress,Select, Tooltip, Card, Statistic, Modal, Space, Radio, Switch, Pagination, Drawer } from 'antd';
import { ExportOutlined, PlusOutlined, SettingOutlined, CloseOutlined, CheckOutlined, DiffOutlined, LockOutlined, GlobalOutlined, LinkOutlined } from '@ant-design/icons';
import SlideModal from '../common/SlideModal';
import SimpleForm from "../redux-forms/form";
import Popup from '../common/popup';
import client from '../../actions/client';
import showAToast from '../common/showAToast';
import moment from 'moment';
import Documents from './documents';
import { deleteFolder, deleteFile } from '../../actions/documentsActions';
import RteEditor from '../common/rteEditor';

const { Option } = Select;

class ProjectProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeStage: null,
            active: 'description',
            isEdit: false,
            addComment: false,
            openCopyTask: false,
            loading: false,
            documentsLoading: false,
            fileId: null,
            fileName: '',
            openFileDel: false,
            taskSection: null,
            isCommentPublic: true,
            comments: null
        };
        this.onBreadcrumbClick = this.onBreadcrumbClick.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.showAddComment = this.showAddComment.bind(this);
        this.closeAddComment = this.closeAddComment.bind(this);
        this.onUploadFile = this.onUploadFile.bind(this);
        this.fetchUserList = this.fetchUserList.bind(this);
        this.handleCancelPreview = this.handleCancelPreview.bind(this);
        this.onCopyLink = this.onCopyLink.bind(this);
        this.onSectionChange = this.onSectionChange.bind(this);
        this.onSwitchChange = this.onSwitchChange.bind(this);
        this.changePage = this.changePage.bind(this);
        this.handleOpenRte = this.handleOpenRte.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/project/' + this.props.match.params.id + '/profile-info').then(response => this.setState({loading: false}));
        this.updateRoute = '/' + this.props.match.params.db + '/api/project/' + this.props.match.params.id + '/update-field';
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({loading: true});
            this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/project/' + this.props.match.params.id + '/profile-info').then(response => this.setState({loading: false}));
        }
        if (prevProps.projectProfileInformation.invoiceProfileInfo && prevProps.projectProfileInformation.invoiceProfileInfo.project && (prevProps.projectProfileInformation.invoiceProfileInfo.project.stageId !== this.props.projectProfileInformation.invoiceProfileInfo.project.stageId)) {
            this.setState({activeStage: this.props.projectProfileInformation.invoiceProfileInfo.project.stageId});
        }
    }

    onBreadcrumbClick(stage) {
        this.setState({activeStage: stage}, function(){
            this.props.onFieldSave(this.updateRoute, 'stage', stage, null).then(() => {
                showAToast(plainTranslate(this.props.settings.locale, 'Data saved successfully!'), 'succSettings', 'success');
            });
        });

    }

    changeTab(tab) {
        localStorage.setItem('description', tab);
        this.setState({active: tab,isEdit: false});
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if(this.state.isEdit === false && (event.target.className === 'profile-description-rte' || (event.target.parentElement && event.target.parentElement.className === 'profile-description-rte'))){
            this.setState({isEdit: true});
        }
    }

    handleClose(val) {
        this.setState({isEdit: false});
        if (val === true) {
            this.setState({loading: true});
            this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/project/' + this.props.match.params.id + '/profile-info').then(response => this.setState({loading: false}));
        }
    }

    handleOpenRte(event) {
        this.setState({ isEdit: true });
    }

    showAddComment(data=false){
        let commentValue = '';
        let commentId = null;
        if(data){
            commentValue = data.description;
            commentId = data.id;
        }
        this.setState({addComment: true, commentValue: commentValue, commentId: commentId});
    }

    closeAddComment(){
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/project/' + this.props.match.params.id + '/profile-info');
        this.setState({addComment: false,  commentValue: null, commentId: null, isCommentPublic: true});
    }

    handleChangeField(e, task, value, type) {
        if(e){
            e.preventDefault();
        }

        client.post('/' + this.props.match.params.db + '/api/task/' + task.id + '/update-field', {
            id: type,
            value: value
        })
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
            }
            else {
                this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/project/' + this.props.match.params.id + '/profile-info');
                showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
            }
        });
    }

    handleChangeProject(e, project, value, type) {
        if(e){
            e.preventDefault();
        }

        client.post('/' + this.props.match.params.db + '/api/project/' + project.id + '/update-field', {
            id: type,
            value: value
        })
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
            }
            else {
                this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/project/' + this.props.match.params.id + '/profile-info');
                showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');

                if(this.props.gridPopup){
                    this.props.updateGridData();
                }
            }
        });
    }

    onSaveTask(response){
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/project/' + this.props.match.params.id + '/profile-info');
        this.setState({isOpenTask: false, openCopyTask: false, editProject: false, editTask: false, editTaskId: null, editProjectItemId: null, parentTask: null, activityForm: false, servicesForm: false, projectBomForm: false, editActivity: false});
    }

    copyTask(id) {
        this.setState({openCopyTask: true, copyTaskId: id});
    }

    createNewTask(e,form, project, parent){
         e.preventDefault();
        this.setState({isOpenTask: true, taskForm: form, taskProject : project,parentTask: parent});
    }

    onUploadFile(){
        this.setState({documentsLoading: true});
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/project/' + this.props.match.params.id + '/profile-info').then(response => this.setState({documentsLoading: false}));
    }

    fetchUserList(val) {
        if(val){
            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(() => {
                this.setState({selectLoading: true});
                client.get(this.props.match.params.db + "/api/find/users/search?q="+val).then(response => {
                    this.setState({users: response.data});
                    this.setState({selectLoading: false});
                });   
            }, 1000);   
        } 
    }

    handleDeleteFile(fileName, filePath, fileId, isPublic){
         this.setState({
             openFileDel: true,
             fileName: fileName,
             file: filePath,
             fileId: fileId,
             isPublicFile: isPublic
         })
     }
     handleOkDelFile(){
        client
        .post('/' + this.props.match.params.db + "/api/storage/remove/file/share", { fileId: this.state.fileId, isPublicFile: this.state.isPublicFile })
        .then(res => {
            showAToast('File deleted!', 'successUpload', 'success');
            this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/project/' + this.props.match.params.id + '/profile-info').then(response => this.setState({documentsLoading: false}));
            this.setState({
                 openFileDel: false,
                 fileId: null,
                 fileName: null,
                 isPublicFile: false
            });
        })
        .catch(err => {
            showAToast("Error!", 'baseError', 'error')
        });
     }
     handleCanceDelFile(){
         this.setState({
             openFileDel: false,
             isPublicFile: false,
             fileId: null,
             fileName: ''
         })
     }

    handlePreview = (file) => {
        this.setState({
            previewImage: file,
            previewVisible: true,
        });
    }

    handleCancelPreview(){
        this.setState({ previewVisible: false });
    }

    onCopyLink(cc){
        navigator.clipboard.writeText(window.location.origin + '/' + this.props.match.params.db + '/public/project/'+cc+'?lang=en');
        showAToast(plainTranslate(this.props.settings.locale, 'Profile link copied!'), 'succSettings', 'success');
    }

    onSectionChange(e){
        this.setState({ 'taskSection' : e.target.value});
    }

    onSwitchChange(checked) {
        this.setState({isCommentPublic: checked});
    }

    changePage(page, type){
        if(type === 'comments'){
            client
            .get('/'+this.props.match.params.db+"/api/project/" + this.props.match.params.id + '/get-comments?page='+page)
            .then(response => response.data)
            .then(response => {
                this.setState({comments: response});
            })
        }
        else if(type === 'activities'){
            client
            .get('/'+this.props.match.params.db+"/api/project/" + this.props.match.params.id + '/get-activities?page='+page)
            .then(response => response.data)
            .then(response => {
                this.setState({activities: response});
            })
        }
        else if(type === 'tasks'){
            client
            .get('/'+this.props.match.params.db+"/api/project/" + this.props.match.params.id + '/get-tasks?page='+page)
            .then(response => response.data)
            .then(response => {
                this.setState({tasks: response});
            })
        }
    }

    render() {
        let project = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.project : {};
        let stages = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.projectStages : [];
        let taskStages = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.taskStages : [];
        let users = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.users : [];
        let projectTasks = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.projectTasks : [];
        let taskForms = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.taskForms : [];
        let commentsCount = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.commentsCount : 0;
        let tasksCount = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.tasksCount : 0;
        let attachments = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.attachments : [];
        let boards = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.boards : [];
        let priorityOptions = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.priorityOptions : [];
        let activityTypes = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.activityTypes : [];
        let activitiesCount = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.activitiesCount : 0;

        let deals = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.deals : [];
        let sales = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.sales : [];
        let invoices = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.invoices : [];
        let expenses = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.expenses : [];
        let projectStats = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.projectStats : [];
        let services = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.services : [];
        let sections = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.sections : [];
        let projectBoms = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.projectBoms : [];
        let loggedTime = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.loggedTime : 0;

        const style = {
            margin: '0 5px',
        };

        if ((this.props.projectProfileInformation.loading && this.state.addComment === null) || this.state.loading){
            return <FormLoader/>
        }

        let activeStage = project ? project.stageId : '';

        if(this.state.activeStage){
            activeStage = this.state.activeStage;
        }

        let activities = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.projectActivities : [];

        let allTasks = projectTasks;

        if(this.state.taskSection){
            projectTasks = projectTasks.filter(value => {
                return value.section === this.state.taskSection
            })
        }

        const activitiesCols = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    return (
                        <i title={plainTranslate(this.props.settings.locale, 'Edit')} style={{ margin: '0 5px', cursor: "pointer" }} onClick={() => this.setState({ activityForm: true, editActivity: record.id })} className="fa fa-pencil-square-o"></i>
                    )
                }
            },
            {
                'dataIndex' : 'date',
                'title' : plainTranslate(this.props.settings.locale, 'Date'),
                'width': 100,
                render: (text, record) => (
                    <div>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY H:m').format(this.props.settings.dateFormat+ ' H:m') : ''}
                    </div>
                ),
            },
            {
                'dataIndex' : 'action',
                'title' : plainTranslate(this.props.settings.locale, 'Action'),
                'width': 100,
            },
            {
                'dataIndex' : 'task',
                'title' : plainTranslate(this.props.settings.locale, 'Task'),
                'width': 100,
            },
            {
                'dataIndex' : 'loggedTime',
                'title' : plainTranslate(this.props.settings.locale, 'Logged time'),
                'width': 100,
            },
            {
                'dataIndex' : 'user',
                'title' : plainTranslate(this.props.settings.locale, 'User'),
                'width': 100,
            },
        ];

        let comments = this.props.projectProfileInformation.invoiceProfileInfo ? this.props.projectProfileInformation.invoiceProfileInfo.projectComments : [];

        const commentsCols = [
            {
                'dataIndex' : 'date',
                'title' : plainTranslate(this.props.settings.locale, 'Date'),
                'width': 100,
            },
            {
                'dataIndex' : 'taskName',
                'title' : plainTranslate(this.props.settings.locale, 'Task Name'),
                'width': 100,
                render: (text, record) => (
                    <Link to={'/' + this.props.match.params.db + '/task/' + record.taskId + '/profile'} style={{color: "#000"}}>{record.taskName}</Link>
                ),
            },
            {
                'dataIndex' : 'description',
                'title' : plainTranslate(this.props.settings.locale, 'Description'),
                'width': 100,
                render: (text, record) => (
                    record.userId === this.props.settings.user.id ? <div style={{cursor: "pointer"}} onClick={() => this.showAddComment(record)} dangerouslySetInnerHTML={{ __html: record.description }}></div> : <div dangerouslySetInnerHTML={{ __html: record.description }}></div>
                ),
            },
            {
                'dataIndex' : 'user',
                'title' : plainTranslate(this.props.settings.locale, 'User'),
                'width': 100,
            },
            {
                'dataIndex' : 'isPublic',
                'title' : plainTranslate(this.props.settings.locale, 'Is Public?'), 
                'width': 100,
                'render': (text, record) => (
                    record.isPublic ? <CheckOutlined style={{color: 'green'}}/> : <CloseOutlined style={{color: 'red'}}/>
                )
            }
        ];

        const projectTasksCols = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    const FormOptions = (
                        <Menu>
                            {taskForms.map((option, i) => (
                                    <Menu.Item key={i}>
                                        <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.createNewTask(e,option.form, project.id, record.id)}>
                                            {plainTranslate(this.props.settings.locale, option.label)}
                                        </a>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );

                    let taskActivityOptions = (
                        <Menu>
                            {activityTypes && activityTypes.map(type => {
                                return <Menu.Item key={type.value}>
                                    <a onClick={() => this.setState({ activityForm: true, activityType: type.value, taskId: record.id })}>{plainTranslate(this.props.settings.locale, type.label)}</a>
                                </Menu.Item>
                            })}
                        </Menu>
                    ); 

                    return (
                        !record.type ? <span className="table-actions">
                            <Link to={'/' + this.props.match.params.db + '/task/' + record.id + '/profile'} title={plainTranslate(this.props.settings.locale, 'Profile')}><i style={style} className="fa fa-eye"></i></Link>
                            <i title={plainTranslate(this.props.settings.locale, 'Edit')} style={{margin: '0 5px', cursor: "pointer"}} onClick={() => this.setState({editTask: true, editTaskId: record.id})} className="fa fa-pencil-square-o"></i>
                            <i title={plainTranslate(this.props.settings.locale, 'Copy task')} style={{margin: '0 5px', cursor: "pointer"}} onClick={() => this.copyTask(record.id)} className="fa fa-clone"></i>
                            <Dropdown overlay={taskActivityOptions}>
                                <Link to="#" data-toggle="dropdown" title="New Activity" style={{top: 10}}>
                                    <i title={plainTranslate(this.props.settings.locale, 'New Activity')} style={{margin: '0 5px', cursor: "pointer"}} className="ion-android-time"></i>
                                </Link>
                            </Dropdown>
                    
                            <Dropdown overlay={FormOptions}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <i style={{margin: '0 5px',display: record.parent ? 'none' : 'inline-block'}} className="fa fa-plus-circle"></i>
                                </a>
                            </Dropdown>
                    
                        </span> : <span></span>
                    )
                },
                width: 170,
            },
            {
                'dataIndex' : 'code',
                'title' : plainTranslate(this.props.settings.locale, 'Code'),
                'width': 100,
                sorter: (a, b) => a.code.localeCompare(b.code),
                render: (text, record) => {
                    return(<span className={record.stageStatus === 'Closed' ? 'text-line-through' : ''}  onClick={() => this.setState({showTaskHint: true, taskHint: record})}>{record.code}</span>)
                }
            },
            {
                'dataIndex' : 'name',
                'title' : plainTranslate(this.props.settings.locale, 'Name'),
                'width': 100,
                sorter: (a, b) => a.name.localeCompare(b.name),
                render: (text, record) => {
                    return(<span className={record.stageStatus === 'Closed' ? 'text-line-through' : ''}  onClick={() => this.setState({showTaskHint: true, taskHint: record})}>{record.name}</span>)
                }
            },
            {
                'dataIndex' : 'status',
                'title' : plainTranslate(this.props.settings.locale, 'Status'),
                'width': 250,
                'filters':  taskStages && taskStages.map(val => {
                    return {text: plainTranslate(this.props.settings.locale, val.label),value: val.label}
                }),
                onFilter: (value, record) => record.status.indexOf(value) === 0,
                render: (text, record) => {
                    const StagesOptions = (
                        <Menu>
                            {taskStages.map((option, i) => (
                                    <Menu.Item key={i}>
                                        <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,option.id,"stage")}>
                                            {plainTranslate(this.props.settings.locale, option.label)}
                                        </a>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );
                    return(!record.type ? <span>
                        <Dropdown overlay={StagesOptions}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <i style={style} className="fa fa-flag-o"></i>
                            </a>
                        </Dropdown>
                        <span style={{whiteSpace: "nowrap"}} className={record.stageStatus} style={record.color ? {background: record.color, border: '1px solid #364a5a', color: "#000"} : {}}>{plainTranslate(this.props.settings.locale, text)}</span>
                    </span> : <span></span>)
                }
            },
            {
                'dataIndex' : 'dueDate',
                'title' : plainTranslate(this.props.settings.locale, 'Due Date'),
                'width': 150, 
                render: (text, record) => (
                    <div>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}
                    </div>
                ),
            },
            {
                'dataIndex' : 'section',
                'title' : plainTranslate(this.props.settings.locale, 'Section'),
                'width': 100,
                sorter: (a, b) => a.section.localeCompare(b.section),
/*                'filteredValue': this.state.taskSection ? [this.state.taskSection] : null,
                onFilter: (value, filters) => this.state.taskSection ? filters.section.indexOf(this.state.taskSection) === 0 : null*/
            },
            {
                'dataIndex' : 'board',
                'title' : plainTranslate(this.props.settings.locale, 'Board'),
                'width': 150,
                sorter: (a, b) => a.board - b.board,
                render: (text, record) => (
                    <Select 
                    onChange={(val) => this.handleChangeField(null, record, val, 'board')}
                    defaultValue={record.board} style={{ width: 120 }}>
                    <Option value={null} style={{height: 25}}></Option>
                    {
                        boards.map(option =>(
                            <Option  value={option.id}>{option.label}</Option>
                        ))
                    }
                    </Select>
                ),
            },
            {
                'dataIndex' : 'priority',
                'title' : plainTranslate(this.props.settings.locale, 'Priority'),
                'width': 150,
                render: (text, record) => (
                    <Select 
                    onChange={(val) => this.handleChangeField(null, record, val, 'priority')}
                    defaultValue={record.priority} style={{ width: 120 }}>
                    {
                        priorityOptions.map(option =>(
                            <Option  value={option.value}>{option.label}</Option>
                        ))
                    }
                    </Select>
                ),
            },
            {
                'dataIndex' : 'user',
                'title' : plainTranslate(this.props.settings.locale, 'User'),
                'width': 50,
                sorter: (a, b) => a.user.localeCompare(b.user),
                render: (text, record) => (
                    !record.type ? <Select 
                    onChange={(val) => this.handleChangeField(null, record, val, 'user')}
                    defaultValue={record.user} style={{ width: 120 }}>
                    <Option value={null} style={{height: 25}}></Option>
                    {
                        users.map(option =>(
                            <Option  value={option.id}>{option.label}</Option>
                        ))
                    }
                    </Select> : <span></span>
                ),
            },
            {
                'dataIndex' : 'completion',
                'title' : plainTranslate(this.props.settings.locale, 'Completion'),
                'width': 100,
                render: (text, record) => {
                    const completionOptions = (
                        <Menu>
                            <Menu.Item key={1}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,10,'completion')}>
                                    10
                                </a>
                            </Menu.Item>
                             <Menu.Item key={2}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,20,'completion')}>
                                    20
                                </a>
                            </Menu.Item>
                            <Menu.Item key={3}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,30,'completion')}>
                                    30
                                </a>
                            </Menu.Item>
                            <Menu.Item key={4}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,40,'completion')}>
                                    40
                                </a>
                            </Menu.Item>
                            <Menu.Item key={5}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,50,'completion')}>
                                    50
                                </a>
                            </Menu.Item>
                            <Menu.Item key={6}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,60,'completion')}>
                                    60
                                </a>
                            </Menu.Item>
                            <Menu.Item key={7}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,70,'completion')}>
                                    70
                                </a>
                            </Menu.Item>
                            <Menu.Item key={8}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,80,'completion')}>
                                    80
                                </a>
                            </Menu.Item>
                            <Menu.Item key={9}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,90,'completion')}>
                                   90
                                </a>
                            </Menu.Item>
                            <Menu.Item key={10}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,100,'completion')}>
                                   100
                                </a>
                            </Menu.Item>
                        </Menu>
                    );
                    return(!record.type ? <span>
                        <Dropdown overlay={completionOptions}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <i style={style} className="fa fa-line-chart"></i>
                            </a>
                        </Dropdown>
                        <Progress style={{width: "70%",marginLeft: "10px"}} percent={record.completion} />
                    </span> : <span></span>)
                }
            },
            {
                'dataIndex' : 'created',
                'title' : plainTranslate(this.props.settings.locale, 'Created'),
                'width': 150, 
                sorter: (a, b) => moment(a.created, "DD/MM/YYYY").toDate()-moment(b.created, "DD/MM/YYYY").toDate()
            },
        ];

        const dealsColumns = [
            {
                key: 'date',
                dataIndex: 'date',
                title: plainTranslate(this.props.settings.locale, 'Date'),
                width: 110,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'no',
                dataIndex: 'no',
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                width: 130,
                render: (text, record) => {
                    return record.hasEdit
                        ? <Link to={record.documentNoURL} style={{color: "#000"}}>{record.no}</Link>
                        : <span>{text}</span>
                },
            },
            {
                key: 'itemNo',
                dataIndex: 'itemNo',
                title: plainTranslate(this.props.settings.locale, 'Item code'),
                width: 130
            },
            {
                key: 'itemTitle',
                dataIndex: 'itemTitle',
                width: 130,
                title: plainTranslate(this.props.settings.locale, 'Item Title'),
            },
            {
                key: 'baseAmount',
                dataIndex: 'baseAmount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Base Amount'),
                width: 130
            },
            {
                key: 'amount',
                dataIndex: 'amount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Amount'),
                width: 130
            }
        ];

        const salesColumns = [
            {
                key: 'date',
                dataIndex: 'date',
                title: plainTranslate(this.props.settings.locale, 'Date'),
                width: 110,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'no',
                dataIndex: 'no',
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                width: 130
            },
            {
                key: 'itemNo',
                dataIndex: 'itemNo',
                title: plainTranslate(this.props.settings.locale, 'Item code'),
                width: 130
            },
            {
                key: 'itemTitle',
                dataIndex: 'itemTitle',
                width: 130,
                title: plainTranslate(this.props.settings.locale, 'Item Title'),
            },
            {
                key: 'baseAmount',
                dataIndex: 'baseAmount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Base Amount'),
                width: 130
            },
            {
                key: 'amount',
                dataIndex: 'amount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Amount'),
                width: 130
            }
        ];

        const invoiceColumns = [
            {
                key: 'date',
                dataIndex: 'date',
                title: plainTranslate(this.props.settings.locale, 'Date'),
                width: 110,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'no',
                dataIndex: 'no',
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                width: 130,
                render: (text, record) => {
                    return <Link to={record.documentNoURL} style={{color: "#000"}}>{record.no}</Link>
                }
            },
            {
                dataIndex: 'paid',
                title: plainTranslate(this.props.settings.locale, 'Paid'),
                key: 'paid',
                width: 100,
                render: (text, record) => <span className={record.paidClassName} >{plainTranslate(this.props.settings.locale, text)}</span>
            },
            {
                key: 'itemNo',
                dataIndex: 'itemNo',
                title: plainTranslate(this.props.settings.locale, 'Item code'),
                width: 130
            },
            {
                key: 'itemTitle',
                dataIndex: 'itemTitle',
                width: 130,
                title: plainTranslate(this.props.settings.locale, 'Item Title'),
            },
            {
                key: 'baseAmount',
                dataIndex: 'baseAmount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Base Amount'),
                width: 130
            },
            {
                key: 'amount',
                dataIndex: 'amount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Amount'),
                width: 130
            }
        ];

        const expenseColumns = [
            {
                key: 'date',
                dataIndex: 'date',
                title: plainTranslate(this.props.settings.locale, 'Date'),
                width: 110,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'no',
                dataIndex: 'no',
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                width: 130
            },
            {
                key: 'itemNo',
                dataIndex: 'itemNo',
                title: plainTranslate(this.props.settings.locale, 'Item code'),
                width: 130
            },
            {
                key: 'itemTitle',
                dataIndex: 'itemTitle',
                width: 130,
                title: plainTranslate(this.props.settings.locale, 'Item Title'),
            },
            {
                key: 'description',
                dataIndex: 'description',
                width: 130,
                title: plainTranslate(this.props.settings.locale, 'Description'),
            },
            {
                key: 'amount',
                dataIndex: 'amount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Amount'),
                width: 130
            }
        ];

        let menuOptions = [];
        if (taskForms && taskForms.length) {
            menuOptions = (
                <Menu>
                    {taskForms.map((item, i) => {
                        return <Menu.Item key={i}>
                            <a onClick={() => this.setState({ isOpenTask: true, taskForm: item.form, taskProject: project.id })}>
                                <Translate locale={this.props.settings.locale} value={item.label} />
                            </a>
                        </Menu.Item>
                    })}
                </Menu>
            );
        }

        const documentsColumns = [
            {
                key: 'actions',
                dataIndex: 'actions',
                width: 50,
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    return (<span>
                        {(record.extension === 'jpeg' || record.extension === 'jpg' || record.extension === 'png' || record.extension === 'webp' || record.extension === 'svg') ?
                            <Link to='#' onClick={() => { this.handlePreview(record.full) }}>
                                <span> <i className="fa fa-eye"></i> </span>
                            </Link>
                        :
                        <a href={record.full} target="_blank"><i className="fa fa-eye"></i></a>}
                            <Link to='#' onClick={() => {
                                navigator.clipboard.writeText(record.full);
                                showAToast("Link copied sucessfully", 'baseSuccess', 'success');
                            }}>
                                <span> <LinkOutlined /> </span>
                            </Link>
                        <Link to='#' onClick={() => { this.handleDeleteFile(record.name,record.path, record.id, record.isPublic) }}>
                            <span> <i className="fa fa-trash-o"></i> </span>
                        </Link>
                        </span>
                    )
                },
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: plainTranslate(this.props.settings.locale, 'Name'),
                width: 300
            },
            {
                dataIndex : 'Access',
                title : plainTranslate(this.props.settings.locale, 'Access'),
                width: 50,
                render: (text, record) => {
                    return <span>{record.isPublic ? <GlobalOutlined style={{marginRight: 5}}/> : <LockOutlined style={{marginRight: 5}}/>}</span>
                }
            },
            {
                dataIndex : 'extension',
                title : plainTranslate(this.props.settings.locale, 'Extension'),
                width: 50,
            },
            {
                dataIndex : 'size',
                title : plainTranslate(this.props.settings.locale, 'Size'),
                width: 50,
            },
            {
                dataIndex : 'created',
                title : plainTranslate(this.props.settings.locale, 'Created'),
                width: 50,
            },
            {
                dataIndex : 'createdBy',
                title : plainTranslate(this.props.settings.locale, 'Created by'),
                width: 50,
            }
        ];

        const servicesColumns = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    return (
                        <span className="table-actions">
                            <i title={plainTranslate(this.props.settings.locale, 'Edit')} style={{margin: '0 5px', cursor: "pointer"}} onClick={() => this.setState({servicesForm: true, editProjectItemId: record.id})} className="fa fa-pencil-square-o"></i>
                        </span>
                    )
                },
                width: 100,
            },
            {
                key: 'itemNo',
                dataIndex: 'itemNo',
                title: plainTranslate(this.props.settings.locale, 'Item code'),
                width: 130
            },
            {
                key: 'itemTitle',
                dataIndex: 'itemTitle',
                width: 130,
                title: plainTranslate(this.props.settings.locale, 'Item Title'),
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: plainTranslate(this.props.settings.locale, 'Name'),
                width: 100,
            },
            {
                key: 'account',
                dataIndex: 'account',
                title: plainTranslate(this.props.settings.locale, 'Account'),
                width: 100,
            },
            {
                key: 'dateStart',
                dataIndex: 'dateStart',
                title: plainTranslate(this.props.settings.locale, 'Date Start'),
                width: 110,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'dateEnd',
                dataIndex: 'dateEnd',
                title: plainTranslate(this.props.settings.locale, 'Date End'),
                width: 110,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'autoRenewal',
                dataIndex: 'autoRenewal',
                title: plainTranslate(this.props.settings.locale, 'Auto Renewal'),
                width: 50
            },
        ];

        let activityOptions = (
            <Menu>
                {activityTypes && activityTypes.map(type => {
                    return <Menu.Item key={type.value}>
                        <a onClick={() => this.setState({ activityForm: true, activityType: type.value })}>{plainTranslate(this.props.settings.locale, type.label)}</a>
                    </Menu.Item>
                })}
            </Menu>
        ); 
        return (
            <div className="invoice-profile item-profile">
                {
                    stages && stages.length > 0 &&
                    <div className="status-container col-md-12 col-xs-12">
                        <div className="status-breadcrumb">
                            {
                                stages.map((stage, key) => {
                                    return <a
                                        className={activeStage == stage.id ? 'active' : ''}
                                        href="#" key={key}
                                        onClick={() => this.onBreadcrumbClick(stage.id)}>
                                            <span className="breadcrumb__inner">
                                                <span className="breadcrumb__desc">{stage.label}</span>
                                            </span>
                                    </a>
                                })
                            }
                        </div>
                    </div>
                }
                {project &&
                    <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                        <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb">
                        {!this.props.gridPopup &&
                            <Link to={"/" + this.props.match.params.db + "/projects-v2"}>
                                <i className="ion-ios-arrow-back"></i>{plainTranslate(this.props.settings.locale, 'Projects')}
                            </Link>
                        }
                            <div className="pull-right">
                                {this.props.gridPopup && <a style={{marginRight: 20}} href={"/" + this.props.match.params.db + '/project/'+ this.props.match.params.id +'/profile'} target="_blank">{plainTranslate(this.props.settings.locale, 'Open in new tab')}<ExportOutlined style={{fontSize: 15, marginLeft:5}}/></a>}
                                <span onClick={() => this.setState({editProject: true})}><a href="#">{plainTranslate(this.props.settings.locale, 'Edit')}<i style={{marginLeft: 5}} className="fa fa-pencil-square-o"></i></a></span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12 no-gutter">
                            <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information">
                                <div className="account-profile-information-general">
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-md-12 col-xs-12" style={{paddingTop: "25px"}}> 
                                            {project && !project.parentId &&
                                                <div style={{display: "flex", alignItems: "baseline"}}>
                                                    <h3>{project.name}</h3>
                                                    <span style={{cursor:'pointer', marginLeft: 5}} onClick={() => this.setState({projectBomForm: true})}>
                                                        <DiffOutlined />
                                                    </span>
                                                    <span style={{cursor:'pointer', marginLeft: 10}} onClick={() => this.setState({projectSettingsForm: true})}>
                                                        <SettingOutlined/>
                                                    </span>
                                                </div>
                                            }
                                            {project && project.parentId &&
                                                <div>
                                                    <h3>{project.name}</h3>
                                                    <span>{plainTranslate(this.props.settings.locale, 'in')}</span>
                                                    <Link to={'/' + this.props.match.params.db +'/project/'+ project.parentId +'/profile'} className="link">{project.parent} </Link>
                                                </div>
                                            }
                                        </div>
                                        <Dropdown overlay={activityOptions} className="btn-round btn-blue">
                                            <Link to="#" data-toggle="dropdown" title="New Activity" style={{top: 10}}>
                                                <i className="ion-android-add"></i>
                                            </Link>
                                        </Dropdown>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-8 col-md-8 col-xs-8" style={{marginTop: 30, fontSize: 14, paddingLeft: 7}}>
                                            <span style={{cursor:'pointer'}} onClick={() => this.onCopyLink(project.cc)}>{plainTranslate(this.props.settings.locale, 'Copy public link')}</span>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Account')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                                {project &&
                                                    <Link to={'/' + this.props.match.params.db +'/account/'+ project.accountId +'/profile'} className="link">{project.account} </Link>
                                                }
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>
                                            {plainTranslate(this.props.settings.locale, 'Owner')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                               {project && 
                                                    <Select 
                                                    className="small-select"
                                                    size='small'
                                                    filterOption={false}
                                                    showSearch={true}
                                                    loading={this.state.selectLoading}
                                                    onSearch={(value) => this.fetchUserList(value)}
                                                    onChange={(val) => this.handleChangeProject(null, project, val, 'user')}
                                                    value={project.user} 
                                                    style={{ width: 200, height: 25 }}
                                                    onDropdownVisibleChange={(e) => this.setState({users: null})}
                                                    >
                                                        <Option value={null} style={{height: 25}}></Option>                                                        {
                                                            (this.state.users ? this.state.users : users).map(option =>(
                                                                <Option  value={option.id}>{option.label}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Start Date')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                               {project ? project.startDate ? moment((project.startDate).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : '' : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'End Date')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                               {project ? project.endDate ? moment((project.endDate).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : '' : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Due Date')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                               {project ? project.dueDate ? moment((project.dueDate).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : '' : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Total logged time')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                                {loggedTime}
                                            </div>
                                        </div>
                                    </div>
                                    {project.additionalFields && project.additionalFields.map(project => {
                                        return <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span style={{ textTransform: "capitalize" }}>{plainTranslate(this.props.settings.locale, Object.keys(project))}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                {Array.isArray(Object.values(project)[0]) ?
                                                    <><img src={(Object.values(project)[0][0]['url'])} style={{ width: '250px' }} /></>
                                                    :
                                                    <div className="created" style={{ textTransform: "capitalize" }}>
                                                        {Object.values(project)}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-xs-12 no-gutter-m no-gutter-r">
                            <div className="col-lg-12 col-md-12 col-xs-12 account-profile-tabs">
                                <div className="nav nav-tabs" id="tabBtn">
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 basic no-gutter">
                                        <button className={this.state.active == 'description' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('description')}>
                                          {plainTranslate(this.props.settings.locale, 'Description')}
                                        </button>
                                        <button className={this.state.active == 'projectTasks' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('projectTasks')}>
                                            {plainTranslate(this.props.settings.locale, 'Tasks') + " (" + tasksCount + ")"}
                                        </button>
                                        <button className={this.state.active == 'activities' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('activities')}>
                                            {plainTranslate(this.props.settings.locale, 'Activities')}
                                        </button>
                                        {
                                            this.props.settings.bundles.dealbundle && this.props.settings.plan.deals &&
                                            <button className={this.state.active == 'deals' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('deals')}>
                                                {plainTranslate(this.props.settings.locale, 'Deals')}
                                            </button>
                                        }
                                        {
                                            this.props.settings.bundles.salesbundle && this.props.settings.plan.sales &&
                                            <button className={this.state.active == 'sales' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('sales')}>
                                                {plainTranslate(this.props.settings.locale, 'Sales')}
                                            </button>
                                        }
                                        {
                                            this.props.settings.bundles.invoicebundle && this.props.settings.plan.invoice &&
                                            <button className={this.state.active == 'invoices' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('invoices')}>
                                                {plainTranslate(this.props.settings.locale, 'Invoices')}
                                            </button>
                                        }
                                        {
                                            this.props.settings.bundles.expensebundle && this.props.settings.plan.expense &&
                                            <button className={this.state.active == 'expenses' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('expenses')}>
                                                {plainTranslate(this.props.settings.locale, 'Expenses')}
                                            </button>
                                        }
                                        {
                                            this.props.settings.bundles.documentbundle && this.props.settings.plan.documents &&
                                            <button className={this.state.active == 'attachments' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('attachments')}>
                                                {plainTranslate(this.props.settings.locale, 'Documents') + " (" + attachments.length + ")"}
                                            </button>
                                        }
                                        <button className={this.state.active == 'services' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('services')}>
                                            {plainTranslate(this.props.settings.locale, 'Services')}
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-12 col-xs-12 no-gutter" style={{paddingLeft: 30}}>
                                    {
                                        project && this.state.active === 'description' && 
                                        <React.Fragment>
                                            {this.state.isEdit ? <RteEditor 
                                                value={project.description} 
                                                id={'description'}
                                                showButtons={true} 
                                                updateRoute={this.updateRoute}
                                                handleClose={this.handleClose}
                                                />
                                                :
                                                <React.Fragment>
                                                       <div style={{overflow: 'auto', height: '500px', backgroundColor: "#fff", paddingLeft: 30}}>
                                                        <div className="profile-description-rte" dangerouslySetInnerHTML={{__html: project.description }}></div>
                                                       </div>
                                                        <button style={{float: 'right'}} className='button-primary' onClick={this.handleOpenRte}>
                                                            {plainTranslate(this.props.settings.locale, 'Edit')}
                                                        </button>
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    }
                                    {
                                    this.state.active === 'projectTasks' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Space style={{marginBottom: 16, marginTop: 16}}>
                                                <Radio.Group defaultValue={null} size="small" onChange={this.onSectionChange}>
                                                <Radio.Button value={null}>{plainTranslate(this.props.settings.locale, 'All Tasks') + "(" + tasksCount + ")"}</Radio.Button>
                                                {sections && sections.map(section =>{
                                                    return <Radio.Button value={section.name}>{section.name+ " (" + (allTasks.filter(value => value.section === section.name).length) + ")"}</Radio.Button>
                                                })}
                                                </Radio.Group>
                                            </Space>
                                            {taskForms.length > 0 ?
                                               <Dropdown overlay={menuOptions} className="btn-round btn-blue">
                                                    <Link to="#" data-toggle="dropdown" title="New Task">
                                                        <i className="ion-android-add"></i>
                                                    </Link>
                                                </Dropdown>
                                                :
                                                <a href='javascript:void(0)'
                                                    onClick={() => this.setState({ popup: true })}
                                                    className="btn-round btn-blue">
                                                    <i className="ion-android-add"></i>
                                                </a>
                                            }
                                            <Table className="projectsAndTasksTable" rowKey={record => record.id} columns={projectTasksCols} dataSource={projectTasks} scroll={{ x: 'max-content' }}/>
                                        </div>
                                    }
                                    {
                                    this.state.active === 'activities' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Table rowKey={record => record.id} columns={activitiesCols} dataSource={activities} dataSource={this.state.activities ? this.state.activities : activities} pagination={false}/>
                                            <Pagination
                                                total={activitiesCount}
                                                showSizeChanger={false}
                                                onChange={(page) => this.changePage(page, 'activities')}
                                                style={{ margin: "15px 0" }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.active === 'deals' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Table rowKey={record => record.id} columns={dealsColumns} dataSource={deals}/>
                                        </div>
                                    }
                                    {
                                        this.state.active === 'sales' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Table rowKey={record => record.id} columns={salesColumns} dataSource={sales}/>
                                        </div>
                                    }
                                    {
                                        this.state.active === 'invoices' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Table rowKey={record => record.id} columns={invoiceColumns} dataSource={invoices}/>
                                        </div>
                                    }
                                    {
                                        this.state.active === 'expenses' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Table rowKey={record => record.id} columns={expenseColumns} dataSource={expenses}/>
                                        </div>
                                    }
                                    {
                                        this.state.active === 'attachments' &&
                                         <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            {attachments && 
                                                <Documents
                                                    loading={this.state.documentsLoading}
                                                    data={project}
                                                    columnsDocuments={documentsColumns}
                                                    dataDocuments={attachments}
                                                    onUploadFile={this.onUploadFile}
                                                    type="project"
                                                />
                                            }
                                            {!attachments && 
                                                <FormLoader />
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.active === 'services' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <span className="text-right">
                                                <a className="btn-round btn-blue"
                                                    style={{ top: '0' }}
                                                    href="#"
                                                    onClick={() => this.setState({ servicesForm: true })}
                                                    >
                                                   <PlusOutlined/>
                                                </a>
                                            </span>
                                            <Table rowKey={record => record.id} columns={servicesColumns} dataSource={services}/>
                                        </div>
                                    }
                                </div>
                            </div>
                            <h4>{plainTranslate(this.props.settings.locale, 'Comments') + '(' + commentsCount + ')'}</h4>
                            <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                <Table rowKey={record => record.id} columns={commentsCols} dataSource={this.state.comments ? this.state.comments : comments} pagination={false}/>
                                <Pagination
                                    total={commentsCount}
                                    showSizeChanger={false}
                                    onChange={(page) => this.changePage(page, 'comments')}
                                    style={{ margin: "15px 0" }}
                                />
                                {
                                !this.state.addComment &&
                                   <Button style={{margin: 15}} onClick={this.showAddComment}>
                                        <i style={{ fontSize: "20px",position: "relative", top: "3px",left: "-6px" }} className="ion-ios-chatbubble-outline"></i>{plainTranslate(this.props.settings.locale, 'Add comment')}
                                    </Button>
                                }
                                {
                                this.state.addComment &&
                                    <React.Fragment>
                                        <RteEditor 
                                            value={this.state.commentValue ? this.state.commentValue : '' } 
                                            id={this.state.commentId}
                                            showButtons={true} 
                                            updateRoute={'/' + this.props.match.params.db + '/api/project/' + this.props.match.params.id + '/comment/new'}
                                            handleClose={this.closeAddComment}
                                            additionalData={{isPublic: this.state.isCommentPublic}}
                                        />
                                        <span style={{position: "absolute",bottom: 15}}>
                                            <label style={{marginRight: 10}}>{plainTranslate(this.props.settings.locale, 'Is Public?')}</label>
                                            <Switch defaultChecked={this.state.isCommentPublic} onChange={this.onSwitchChange} />
                                        </span>
                                    </React.Fragment>
                                }
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12 no-gutter" style={{ padding: 15, marginBottom: 35 }}>
                                {projectStats.map((stat, i) => {
                                    return <div id={i} className="col-sm-4" style={{ padding: 0 }}>
                                        <Card>
                                            <Statistic
                                                title={plainTranslate(this.props.settings.locale, stat.label)}
                                                value={stat.value}
                                                style={{ height: 80 }}
                                            />
                                        </Card>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.isOpenTask &&
                    <SlideModal onClose={() => this.setState({isOpenTask: false, parentTask: null})} title={plainTranslate(this.props.settings.locale, 'Task')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/tasks/'}
                                        saveRoute={this.props.match.params.db + '/api/tasks'}
                                        match={{params: {id: 0}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({isOpenTask: false, parentTask: null})}
                                        isNew={true}
                                        popup={true}
                                        parent={this.state.parentTask ? this.state.parentTask : null}
                                        type={this.state.taskForm}
                                        refs={this.state.taskProject ? this.state.taskProject : null}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }

                {
                    this.state.openCopyTask &&
                    <SlideModal onClose={() => this.setState({openCopyTask: false})} title={plainTranslate(this.props.settings.locale, 'Task')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/tasks/'}
                                        saveRoute={this.props.match.params.db + '/api/tasks'}
                                        match={{params: {id: 0}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({openCopyTask: false})}
                                        isNew={true}
                                        popup={true}
                                        copy={this.state.copyTaskId}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.editTask &&
                    <SlideModal onClose={() => this.setState({editTask: false})} title={plainTranslate(this.props.settings.locale, 'Task')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/tasks/'}
                                        saveRoute={this.props.match.params.db + '/api/tasks/'}
                                        match={{params: {id: this.state.editTaskId}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({editTask: false})}
                                        isNew={true}
                                        popup={true}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.editProject &&
                    <SlideModal onClose={() => this.setState({editProject: false})} title={plainTranslate(this.props.settings.locale, 'Project')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/projects/'}
                                        saveRoute={this.props.match.params.db + '/api/projects/'}
                                        match={{params: {id: this.props.match.params.id}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({editProject: false})}
                                        isNew={true}
                                        popup={true}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {this.state.showTaskHint &&
                    <Popup locale={this.props.settings.locale}
                   title={this.state.taskHint.name}
                   onClose={() => this.setState({showTaskHint: false, taskHint: null})}
                   className="popupReports">
                        <div dangerouslySetInnerHTML={{ __html: this.state.taskHint.description }}> 
                        </div>
                    </Popup>
                }
                {
                    this.state.openFileDel &&
                    <Popup locale={this.props.settings.locale}
                        title={plainTranslate(this.props.settings.locale, 'Delete')}
                        onClose={() => this.setState({ openFileDel: false, isPublicFile: false })}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <div className="text-center">
                                        <h3>
                                            <Translate locale={this.props.settings.locale} value="Are you sure?" />
                                        </h3>
                                        <button className="button-outlines green text-center"
                                            onClick={() => this.setState({ openFileDel: false, isPublicFile: false })}>
                                            <Translate locale={this.props.settings.locale} value="No" />
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                            onClick={() => this.handleOkDelFile()}>
                                            <Translate locale={this.props.settings.locale} value="Yes" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Popup>
                }
                {
                    this.state.activityForm &&
                    <SlideModal onClose={() => this.setState({ activityForm: false, editActivity: false, taskId: null })} title={plainTranslate(this.props.settings.locale, 'Activity')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/activity/form/'}
                                        saveRoute={this.state.editActivity ? 
                                        this.props.match.params.db + '/api/activities/edit' 
                                        : this.props.match.params.db + '/api/activities/new'}
                                        //updateParent={(data) => this.updateActivities(data)}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        popup={true}
                                        onCancel={() => this.setState({ activityForm: false, editActivity: false, taskId: null })}
                                        match={{ params: { id: this.state.editActivity ? this.state.editActivity : 'new' } }}
                                        refs={'&project=' + this.props.match.params.id + '&type=' + this.state.activityType + (this.state.taskId ? "&task=" + this.state.taskId : "")}
                                        accountId={project.accountId}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.servicesForm &&
                    <SlideModal onClose={() => this.setState({ servicesForm: false, editProjectItemId: null })} title={plainTranslate(this.props.settings.locale, 'Services')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/service/'}
                                        saveRoute={this.state.editProjectItemId ? this.props.match.params.db + '/api/service/' : this.props.match.params.db + '/api/service'}
                                        match={{params: {id: this.state.editProjectItemId}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({servicesForm: false, editProjectItemId: null})}
                                        isNew={true}
                                        popup={true}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.projectSettingsForm &&
                    <SlideModal onClose={() => this.setState({projectSettingsForm: false})} title={plainTranslate(this.props.settings.locale, 'Project Settings')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/projects-settings/'}
                                        saveRoute={this.props.match.params.db + '/api/projects-settings/'}
                                        match={{params: {id: this.props.match.params.id}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({projectSettingsForm: false})}
                                        isNew={true}
                                        popup={true}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.projectBomForm &&
                        <Drawer title={plainTranslate(this.props.settings.locale, 'Project Bom')} visible={true} className="grid-popup-form" placement="right" width={'90%'} onClose={() => this.setState({projectBomForm: false})}>
                            <section className="newPanel">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/project-bom/'}
                                        saveRoute={this.props.match.params.db + '/api/project-bom/'}
                                        match={{params: {id: this.props.match.params.id}}}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({projectBomForm: false})}
                                        isNew={true}
                                        popup={true}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </section>
                        </Drawer>
                }
                <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancelPreview}>
                  <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    projectProfileInformation: state.profiles
});
const mapDispatchToProps = dispatch => ({
    onDocumentProfileFetchData: (url) => dispatch(onDocumentProfileFetchData(url)),
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectProfile);
