import React, {Component} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

class DateRenderer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let value = this.props.value;
        let dot = 0;
        let dash = 0;
        if (value) {
            dot = value.indexOf(".");
            dash = value.indexOf("-");
        }
        
        return (
            <span>
                <p>{value ? dot > 1 ? moment((value).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : dash > 1 ? moment((value).replaceAll('-', '/'), 'YYYY-MM-DD').format(this.props.settings.dateFormat) : '' : ''}</p>
            </span>);
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});


export default connect(mapStateToProps)(DateRenderer);

