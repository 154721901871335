import React, { Component } from 'react';
import { render } from "react-dom";
import { connect } from 'react-redux';
import Translate, { plainTranslate } from '../../common/translate';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import 'antd/lib/table/style/index.css';
import { withRouter } from 'react-router-dom'

class SelectedRowDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const docColumns = [
            {
                'key': 'no',
                'dataIndex': 'no',
                'title': plainTranslate(this.props.settings.locale, 'Document No'),
                render: (text, record) => (record.hasEdit)
                ? <a target="_blank" href={record.type == 'transfer' ? "/" + this.props.match.params.db + "/receipt/" + record.id + "/edit" : "/" + this.props.match.params.db + "/purchase/" + record.id + "/edit"}>{text}</a>
                : <span> {text} </span>,
            },
            {
                'key': 'type',
                'dataIndex': 'type',
                'title': plainTranslate(this.props.settings.locale, 'Type'),
            },
            {
                'key': 'status',
                'dataIndex': 'status',
                'title': plainTranslate(this.props.settings.locale, 'Status'),
            },
            {
                'key': 'qty',
                'dataIndex': 'qty',
                'title': plainTranslate(this.props.settings.locale, 'Processed items'),
            },
            {
                'key': 'locationFrom',
                'dataIndex': 'locationFrom',
                'title': plainTranslate(this.props.settings.locale, 'Location from'),
            },
            {
                'key': 'locationTo',
                'dataIndex': 'locationTo',
                'title': plainTranslate(this.props.settings.locale, 'Location to'),
            },
        ];

        let i;
        let processed = 0;
        let total = this.props.data.quantity;
        if (this.props.data && this.props.data.docs) {
            for (i = 0; i < this.props.data.docs.length; i++) {
                if (this.props.data.docs[i]['type'] == 'transfer' && this.props.data.docs[i]['locationTo'] == this.props.data.locationTo)
                processed += this.props.data.docs[i]['qty'];
            }
        }
        let balance = total - processed;

        return (
            <div>
                {this.props.data ?
                    <div className="col-md-12" style={{marginTop:20}}>

                        {this.props.data && this.props.data.docs &&
                            <div><Table
                                rowKey={record => record.id} columns={docColumns} dataSource={this.props.data.docs}
                                pagination={false}
                                bordered
                            />
                          <div className="col-md-6 pull-right">
                              <h3>{plainTranslate(this.props.settings.locale, 'Summary')}:</h3>
                                <div className="listSingle-item">
                                  <label>{plainTranslate(this.props.settings.locale, 'Processed')}:</label>
                                  <label className="qty float-right"><span>{processed}</span></label>
                                 </div>

                                   <div className="listSingle-item">
                                     <label>{plainTranslate(this.props.settings.locale, 'Quantity To Be Processed')}:</label>
                                     <label className="qty float-right"><span>{balance}</span></label>
                                    </div>
                              </div>

                            </div>
                        }
                    </div>
                    :
                    'No data chosen'
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectedRowDetails));
