import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Menu, Row, Skeleton, Spin, Select, Table, Dropdown,Progress, Button } from "antd";
import client from "../../actions/client";
import showAToast from "../common/showAToast";
import { plainTranslate } from "../common/translate";
import { RightOutlined } from "@ant-design/icons";
import {config} from '../../config';
import {Link} from 'react-router-dom';
import moment from 'moment';
import SlideModal from '../common/SlideModal';
import SimpleForm from "../redux-forms/form";
import {onDocumentProfileFetchData} from '../../actions/actions';
import FormLoader from '../forms/form-loader';

const { Option } = Select;

const PublicBoard = (props) => {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [active, setActive] = useState("activeTasks");
  const [addTicket, setAddTicket] = useState(false);
  const urlParams = new URLSearchParams(props.location.search);
  const [lang,setLang] = useState(urlParams.get('lang'));

    useEffect(() => {
        getProfileData();
    }, []);

    function getProfileData(){
        let url = props.match.params.db+"/web/board/"+props.match.params.id;
        setLoading(true);
        client
          .get(url)
          .then((res) => {
            setData(res.data);
            setLoading(false);
          })
          .catch((err) => {
            var error = "Error!";
            setLoading(false);
            showAToast(
              plainTranslate(lang, error),
              "errSettings",
              "error"
            );
        });
    }

    function changeTab(tab) {
        localStorage.setItem('description', tab);
        setActive(tab);
    }

    function showAddTicket(){
        setAddTicket(true);
    }

    function closeAddTicket(){
        setAddTicket(false);
    }

    function updateTasks(){
        setAddTicket(false);
        getProfileData();
    }

    function changeLang(val){
        props.history.push(props.location.pathname+'?lang='+val);
        setLang(val);
    }

    let board = data ? data.board : null;

    const style = {
        margin: '0 5px',
    };

    const activeTasksCols = [
            {
                'dataIndex' : 'name',
                'title' : plainTranslate(lang, 'Name'),
                'width': '50%',
                render: (text, record) => {
                    return <Link to={'/' + props.match.params.db + '/public/board/' + props.match.params.id + '/task/' + record.cc}>{text}</Link> 
                }
            },
            {
                'dataIndex' : 'status',
                'title' : plainTranslate(lang, 'Status'),
                'width': 200,
                render: (text, record) => {
                    return(!record.type ? <span>
                        <span style={{whiteSpace: "nowrap"}} className={record.stageStatus} style={record.color ? {background: record.color, border: '1px solid #364a5a', color: "#000"} : {}}>{plainTranslate(lang, text)}</span>
                    </span> : <span></span>)
                }
            },
            {
                'dataIndex' : 'user',
                'title' : plainTranslate(lang, 'Assigned'),
                'width': 50,
            },
            {
                'dataIndex' : 'dueDate',
                'title' : plainTranslate(lang, 'Due Date'),
                'width': 80
            }
        ];

        if (loading){
            return <FormLoader/>
        }
  return (
    <>
      {(
        <>
          {board && (
            <div className="pg-content">
              <nav className="navbar pg-header">
                <div style={{maxWidth: 1480, margin: "0 auto"}}>
                    <a href="#" className="logo-wrapper">
                      <img src="/images/composity-logo.svg" />
                    </a>
                    <span style={{float: 'right', paddingTop: 10}}>
                        <Select 
                            className="small-select"
                            size='small'
                            style={{ width: 120, height: 25 }}
                            onChange={(val) => changeLang(val)}
                            value={lang}
                            >
                            {data.languages && data.languages.map(lang => {
                                return <Option value={lang.value} style={{height: 25}}>{lang.label}</Option>
                            })}
                        </Select>
                    </span>
                </div>
                <div className="bar-colors" />
              </nav>

            <div className="container pg-container">
                <div className="row">
                    <div className="col-md-12" style={{zIndex: 1}}>
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                            <div className="col-md-12 col-xs-12" style={{paddingTop: "25px", textAlign:'center'}}> 
                                {board &&
                                   <h3>{board.name}</h3>
                                }
                            </div>
                            <div className="col-lg-12 col-md-12 col-xs-12 no-gutter-m no-gutter-r">
                                <div className="col-lg-12 col-md-12 col-xs-12 account-profile-tabs" style={{marginTop: 0}}>
                                    <div className="nav nav-tabs" id="tabBtn">
                                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 basic no-gutter">
                                            <button className={active == 'activeTasks' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => changeTab('activeTasks')}>
                                                {plainTranslate(lang, 'Active Tasks') + " (" + data.activeTasksCount + ")"}
                                            </button>
                                            <button className={active == 'closedTasks' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => changeTab('closedTasks')}>
                                                {plainTranslate(lang, 'Closed Tasks') + " (" + data.closedTasksCount + ")"}
                                            </button>
      
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-xs-12 no-gutter">
                                        {
                                        active === 'activeTasks' &&
                                            <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                                <Table pagination={data.activeTasksCount > 25 ? {pageSize: 25} : false} className="projectsAndTasksTable" rowKey={record => record.id} columns={activeTasksCols} dataSource={data.activeTasks}/>
                                                <Button style={{margin: 15}} onClick={showAddTicket}>
                                                    {plainTranslate(lang, 'Add ticket')}
                                                </Button>
                                            </div>
                                        }
                                        {
                                        active === 'closedTasks' &&
                                           <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                                <Table pagination={data.closedTasksCount > 25 ? {pageSize: 25} : false} className="projectsAndTasksTable" rowKey={record => record.id} columns={activeTasksCols} dataSource={data.closedTasks} pagination={{pageSize: 25}}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                </div>
                {
                    addTicket &&
                    <SlideModal onClose={closeAddTicket} title={plainTranslate(lang, 'Add Ticket')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        saveRoute={props.match.params.db + '/web/add-ticket'}
                                        formRoute={props.match.params.db + '/web/add-ticket/'}
                                        match={{params: {id: 0}}}
                                        onSuccessfulSave={updateTasks}
                                        isNew={true}
                                        popup={true}
                                        onCancel={closeAddTicket}
                                        type={'board'}
                                        refs={props.match.params.id}
                                        form={'initializeFromState'}
                                    />

                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                <div className="row justify-content-center" style={{position: 'absolute', bottom: 0, width: 1480}}>
                  <div className="col">
                    <a href="https://composity.com" target="_blank">
                      <p className="software">
                        <span style={{fontSize: 14}}>
                          Powered by <img style={{height: 25}} src="/images/composity-logo.svg" />
                        </span>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="bg-icon-wrapper-right fix">
                <img src="/images/rectangles-bg.svg" />
              </div>
              <div className="bg-icon-wrapper-left">
                <img src="/images/2-rectangles-bg.svg" />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onDocumentProfileFetchData: (url) => dispatch(onDocumentProfileFetchData(url))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PublicBoard));