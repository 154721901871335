import React from 'react';
import PropTypes from 'prop-types';
import Translate from '../common/translate';
import {Link} from 'react-router-dom';
import { Menu, Dropdown, Button, message } from 'antd';

class SaveCancel extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {
                    this.props.issueButton &&    
                    <button type="button"
                            className={"button-primary text-uppercase button-issue"} disabled={this.props.enableSave ? '' : 'disabled'}
                            onClick={() => this.props.save(false, true)}>
                        <Translate locale={this.props.locale}
                                   value="Issue"/>
                    </button>
                }
                {
                    this.props.sendButton &&    
                    <button type="button" disabled = {this.props.enableSave ? '' : 'disabled'}
                            className={"button-primary " + (this.props.enableSave ? '' : 'disabled')}
                            onClick={() => this.props.save(false)}>
                        <Translate locale={this.props.locale}
                                   value="Send"/>
                    </button>
                }
                { !this.props.applyButton && !this.props.showPrintSave && !this.props.checkout && !this.props.showPrintStorno && !this.props.sendButton &&
                    <Button type="button" disabled = {this.props.enableSave ? '' : 'disabled'}
                            className={"form-save-button button-primary " + (this.props.enableSave ? '' : 'disabled')}
                            onClick={() => this.props.save(false)} loading={this.props.submitting}>
                        <Translate locale={this.props.locale}
                                   value="Save"/>
                    </Button>  
                } 
                { this.props.download &&
                    <button type="button" disabled={this.props.enableSave ? '' : 'disabled'}
                        className={"button-primary " + (this.props.enableSave ? '' : 'disabled')}
                        onClick={() => this.props.save(false, false, true)}>
                        <Translate locale={this.props.locale}
                            value="Save&Print" />
                    </button>
                }
                {
                    this.props.showSaveNew && !this.props.applyButton && !this.props.showPrintSave && 
                    <button type="button"
                            className={"button-outline grey "} disabled={this.props.enableSave ? '' : 'disabled'}
                            onClick={() => this.props.save(true)}>
                        <Translate locale={this.props.locale}
                                   value="Save & New"/>
                    </button>
                }
                {
                    this.props.cancelButton &&    
                    <Link to={this.props.cancelButton} className={"button-outline grey"} >
                        <Translate locale={this.props.locale}
                                   value="Cancel"/>
                    </Link>
                }
                {
                    this.props.showCancel &&
                    <button type="button" className="button-outline grey"
                            onClick={() => this.props.cancel()}>
                        <Translate locale={this.props.locale}
                                   value="Cancel"/>
                    </button>
                }
                {
                    this.props.applyButton &&    
                    <button type="button" disabled = {this.props.enableSave ? '' : 'disabled'}
                            className={"button-primary " + (this.props.enableSave ? '' : 'disabled')}
                            onClick={this.props.apply}>
                        <Translate locale={this.props.locale}
                                   value="Apply"/>
                    </button>
                }   
                {
                    this.props.showPrintSave && !this.props.showPrintStorno && 
                    <button type="button"
                            disabled = {this.props.enableSave ? '' : 'disabled'}
                            className={"button-primary " + (this.props.enableSave ? '' : 'disabled')}
                            onClick={this.props.printAndSave}>
                        <Translate locale={this.props.locale}
                                   value="Print and Save"/>
                    </button>
                } 
                {
                    this.props.showPrintStorno &&    
                    <button type="button"
                            disabled = {this.props.enableSave ? '' : 'disabled'}
                            className={"button-primary " + (this.props.enableSave ? '' : 'disabled')}
                            onClick={this.props.printAndSave}>
                        <Translate locale={this.props.locale}
                                   value="Print Storno"/>
                    </button>
                }  
                { this.props.checkout &&
                    <button type="button" disabled = {this.props.enableSave ? '' : 'disabled'}
                            className={"button-primary " + (this.props.enableSave ? '' : 'disabled')}
                            onClick={() => this.props.save(false)}>
                        <Translate locale={this.props.locale}
                                   value="Checkout"/>
                    </button>  
                }
                {
                    this.props.saveReport &&  
                    <Dropdown overlay={
                        <Menu>
                            <Menu.Item key="1" onClick={() => this.props.reportSaveAs(true)}>  
                              Save As
                            </Menu.Item>
                            {(this.props.isAdmin || this.props.userId === this.props.reportUserId) && this.props.reportId &&
                                <Menu.Item key="2" onClick={() => this.props.reportSaveAs(false)}>
                                  Edit as private
                                </Menu.Item>
                            }
                            {this.props.isAdmin && this.props.reportId &&
                                <Menu.Item key="3" onClick={() => this.props.reportSaveAs(false,true)}>
                                  Edit as public
                                </Menu.Item>
                            }
                        </Menu>}
                    >
                        <button type="button" disabled = {this.props.enableSave ? '' : 'disabled'}
                            className={"button-primary " + (this.props.enableSave ? '' : 'disabled')}>
                        <Translate locale={this.props.locale}
                                   value="Save Report"/>
                        </button>  
                    </Dropdown>  
                } 
            </div>
        );
    }
}

SaveCancel.propTypes = {
    locale: PropTypes.string,
    enableSave: PropTypes.bool,
    showCancel: PropTypes.bool,
    showSaveNew: PropTypes.bool,
    save: PropTypes.func,
    cancel: PropTypes.func,
};

SaveCancel.defaultProps = {
    locale: 'en',
    enableSave: true,
    showCancel: false,
    save: () => {
    },
    cancel: () => {
    },
};

export default SaveCancel;
