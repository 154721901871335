import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";
import {DatePicker as DatePickerComponent} from 'antd';

class DocumentPayments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            'lines': [{}],
        };

        this.onChange = this.onChange.bind(this);
        this.addNewRow = this.addNewRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.handleSingleDateChange = this.handleSingleDateChange.bind(this);
    }

    componentDidMount() {
        let lines = this.props.formData['lines'];
        this.setState({
           lines
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            let value = this.props.value ? this.props.value : '';
            this.setState({
                value
            });
        }
    }

    onChange(e, type, key) {
        let lines = this.state.lines;
        lines[key][type] = e.target.value;
         this.setState({
            lines
        });
        this.props.onChange('lines', lines);
    }

    addNewRow() {
        let lines = this.state.lines;
        let newLine = {amount: 0, paymentMethod: null, 'date': null, 'docId': this.props.formData.docId, 'custId': this.props.formData.custId, 'accId': this.props.formData.accId, 'stageId': this.props.formData.stageId};
        lines.push(newLine);
        this.setState({
            lines
        });
    }

    removeRow(key) {
        let lines = this.state.lines;
        lines.splice(key, 1);
        this.setState({
            lines
        });
    }

    handleSingleDateChange(event, key){
        let date = event;

        let lines = this.state.lines;
        lines[key]['date'] = date.format('DD/MM/YYYY');
        this.setState({
            lines
        });

        this.props.onChange('lines', lines);
    }

    render() {
        return <React.Fragment>
                    {this.state.lines && this.state.lines.length > 0 && this.state.lines.map((payment, key) =>
                        <React.Fragment key={key}>
                            <div className="payment-form">
                                <div className="payment-row">
                                    <div className="col-md-4">
                                        <label className="text-bold">{plainTranslate(this.props.locale, "Date")}</label>
                                        <DatePickerComponent 
                                            format='DD/MM/YYYY'
                                            onChange={(e) => this.handleSingleDateChange(e, key)}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="text-bold">{plainTranslate(this.props.locale, "Payment Method")}</label>
                                        <select className="form-control" name="paymentMethod" value={payment.payMethod} onChange={(e) => this.onChange(e, 'paymentMethod', key)} required>
                                        <option value=''></option>
                                        {this.props.payMethods.length > 0 && this.props.payMethods.map((payment, key) =>
                                            <option key={key} value={payment.value}>{plainTranslate(this.props.locale, payment.label)}</option>
                                        )}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="text-bold">{plainTranslate(this.props.locale, "Amount")}</label>
                                        <input name="amount" type="number" className="form-control" key={'value'} onChange={(e) => this.onChange(e, 'amount', key)} required/>
                                        <i className="fa fa-trash contact-icon" aria-hidden="true" style={{position: 'relative', top: '-20px', 'left': '20px'}} onClick = {() => this.removeRow(key)} title={plainTranslate(this.props.locale, 'Remove row')}></i>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </React.Fragment>
                        )}

                <a className="button-outline grey " style={{textTransform: 'upperCase'}} onClick = {this.addNewRow}>{plainTranslate(this.props.locale, 'Add row')}</a>
                <div className="col-md-4" style={{'float': 'right', 'marginBottom': '30px', 'marginRight': '10px'}} >
                    <label className="text-bold">{plainTranslate(this.props.locale, "Total payments")}</label>
                    <input name="amount" className="form-control" value={this.props.formData.payments} style={{fontWeight: "bold", color: "#636363"}}disabled/>
                </div>
        </React.Fragment>

    }
}

const mapStateToProps = state => ({
    });

const mapDispatchToProps = dispatch => ({

    });

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPayments);
