import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Translate, {plainTranslate} from '../common/translate';

class Module extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: this.props.isChecked,
        };
        this.toggleClass = this.toggleClass.bind(this);
    }

    toggleClass() {
        const currentState = this.state.isChecked;
        this.setState({ isChecked: !currentState });
        this.props.update(this.props.value, !currentState);
    };
    render() {
        return (
                <div className={this.state.isChecked ? 'onboarding-line-module text-center checked' : 'onboarding-line-module text-center'}
                onClick={this.toggleClass}>
                    <i className={this.props.icon}></i>
                    {this.props.value == "personnel" &&
                        <p className="beta-notes">Beta</p>
                    }
                    <p className="text-black"><Translate locale={this.props.locale} value={this.props.name}/></p>
                </div>
                );
    }
}

export default Module;



