import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Progress } from 'antd';
import showAToast from '../common/showAToast'
import client from '../../actions/client'
import { plainTranslate } from '../common/translate';
import "./login.css"

class WorkspaceCheck extends Component {
    constructor() {
        super();
        this.state = {
          percent: 1,
        };
    }

    componentDidMount() {
        this.interval = setInterval(() => this.checkForWorkspace(), 5000);
        if (this.props.auth.isAuthenticated) {
          this.props.logoutUser(null, false);
        }
      }
      componentWillUnmount() {
        clearInterval(this.interval);
      }

    checkForWorkspace = () => {    
      const queryString = window.location.search;    
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get('email')    
        client
            .get('/'+this.props.match.params.db+'/workspace/check')
            .then(response => {                
                if (response.data.result == true) {
                    this.increase(100)
                    setTimeout(() =>  {
                      this.props.history.push('/'+response.data.db+'/auth/register/user?email='+email)
                    }, 1000)
                }else{
                  this.increase()
                }
                
            })
            .catch(error => {
                console.error(error);
            });

    }

    increase = (value = null) => {
      let percent = value ? value : this.state.percent + 1;
      if (percent > 99 && !value) {
        percent = 99;
      }
      this.setState({ percent });
    }

    render() {
        return ( <div className="login">
        <div className="bg"><img className="login-hero" src="https://composity.com/images/org/e4e130.png" width="100%" height="100%"/> </div>
         <Row>
          <Col lg={1} />
          <Col lg={18} md={24} className="login-top">
            <Col lg={12} md={10} sm={12} xs={24}>
              <img className="composity-logo" src="https://composity.com/images/org/8f621c.png" alt="Composity logo" />
            </Col>
          </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="login-mainRow">
            <Col lg={1} />
            <Col lg={16} md={24}>
                <Col lg={12} md={24}>
                <h3>{plainTranslate(this.props.settings.locale, "Your workspace is being created. It will be available in a few moments. You will be redirected when it's ready.")}</h3>
                        
                <div className="description">
                            <p>{plainTranslate(this.props.settings.locale, "Composity workspace gives you an exclusive access to the Composity business platform. It's your private area, equipped with advanced tools to run your whole business.")}</p>
                            <p>{plainTranslate(this.props.settings.locale, "Once you enter, you will have the opportunity to activate and deactivate modules, configure and setup the software to meet your current and evolving needs.")}</p>
                        </div>
                        <Progress percent={this.state.percent}/>
                        </Col>
                            </Col>
                         <Col lg={7} className="demo-col">
                     <div className="text-center">
                    <h2 className="login-title-white">{plainTranslate(this.props.settings.locale, 'The power to make smarter business decisions')}</h2>
                    <h3 className="login-subTitle">{plainTranslate(this.props.settings.locale, 'Get more tools for your business')}</h3>
                    <div className="buttons-section">
                    <a className="demo-button" target="_blank" href="https://composity.com/request-demo" size="small">{plainTranslate(this.props.settings.locale, 'REQUEST DEMO')}</a>
                    </div>
                  </div>
                </Col>
              </Row>
          </div>)
    }

}  
WorkspaceCheck.propTypes = {
  settings: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  settings: state.settings,
  auth: state.auth,
});

export default connect(mapStateToProps, {logoutUser})(WorkspaceCheck);
