import React from 'react';
import {connect} from 'react-redux';

class Dropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : ''
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.props.value)
                this.props.onChange(this.props.id, this.props.value);
            else if(this.state.value === '' && this.props.options[0] && (!this.props.settings || !this.props.settings.emptyOption))
                this.props.onChange(this.props.id, this.props.options[0].value);
        }, 2000);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            if (this.state.value === '' && !this.props.value && this.props.options.length > 0 && (!this.props.settings || !this.props.settings.emptyOption)) {
                this.setState({
                    value: this.props.options[0]
                });
                setTimeout(this.props.onChange(this.props.id, this.props.options[0].value), 1000);
            }
            else {
                this.setState({
                    value: this.props.value ? this.props.value : ''
                });
                setTimeout(this.props.onChange(this.props.id, this.props.value ? this.props.value : ''), 1000);
            }
        }
    }

    onChange(e) {
        this.setState({value: e.target.value});
        this.props.onChange(this.props.id, e.target.value);
    }

    render() {
        return <select
            className="form-control"
            value={this.state.value}
            tabIndex={this.props.tabindex ? this.props.tabindex : ''}
            onChange={this.onChange}
            onBlur={() => this.props.loseFocus(this.props.id)}>
            {
                this.props.settings && this.props.settings.emptyOption &&
                <option value=""></option>
            }
            {
                this.props.options.map((option, key) => {
                    return <option value={option.value} key={key}>{option.label}</option>
                })
            }
        </select>
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
