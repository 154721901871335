import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import { client} from "../../../actions/actions";
import OpenSection from './../openSection.jsx';
import { fetchSettings} from "../../../actions/actions";

class UserProfilePage extends Component {

    constructor(props) {
    super(props);
            this.state = {
            showMessage: false,
            };
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/user/settings/user-profile-settings', 'userSettings');
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

     render() {
        return (
                <div>
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                   <div className="settings">
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Profile')}
                                            description={plainTranslate(this.props.settings.locale, "Enter your user data: email, first name, last name, company logo")}
                                            component={'user_profile'}
                                            value ={this.props.settings.userSettings}
                                            type={'userSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/user-profile-settings/save/settings'}
                                            setting={'userInfo'}
                                         />
                                         <hr/>
                                        <OpenSection
                                          label={plainTranslate(this.props.settings.locale, 'Password')}
                                          description={plainTranslate(this.props.settings.locale, "Change your password")}
                                          component={'user_password'}
                                          value ={this.props.settings.userSettings}
                                          type={'userSettings'}
                                          saveRoute={'/' + this.props.match.params.db + '/api/user-profile-settings/save/settings'}
                                          setting={'userPassword'}
                                        />
                                        <hr/>
                                        <OpenSection
                                          label={plainTranslate(this.props.settings.locale, 'Email')}
                                          description={plainTranslate(this.props.settings.locale, "Enter your user email configurations")}
                                          component={'user_email_settings'}
                                          value ={this.props.settings.userSettings}
                                          setting={'userEmailSettings'}
                                        />
                                        <hr/>
                                        <OpenSection
                                          label={plainTranslate(this.props.settings.locale, 'SMS')}
                                          description={plainTranslate(this.props.settings.locale, "Enter your user SMS configurations")}
                                          component={'user_sms_settings'}
                                          value ={this.props.settings.userSettings}
                                          type={'userSettings'}
                                          saveRoute={'/' + this.props.match.params.db + '/api/user-profile-settings/save/settings'}
                                          setting={'userSMSSettings'}
                                        />
                                         <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Settings')}
                                            description={plainTranslate(this.props.settings.locale, "Set your language and color preference")}
                                            component={'user_general_settings'}
                                            value ={this.props.settings.userSettings}
                                            type={'userSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/user-profile-settings/save/settings'}
                                            setting={'userGeneralSettings'}
                                         />
                                   </div>
                                </div>
                             </section>
                          </div>
                       </div>
                    </form>
                </div>
                );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
   getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);
