import React from 'react';
import { connect } from 'react-redux';
import { DatePicker as DatePickerComponent } from 'antd';
import 'antd/lib/date-picker/style/index.css';
import moment from 'moment';
import client from '../../../actions/client'

class DatePicker extends React.Component {

    constructor(props) {
        super(props);

        this.dateFormat = 'DD.M.YYYY';

        this.state = {
            showTime: false,
            value: moment()
        };

        this.onChange = this.onChange.bind(this);
        this.disabledDate = this.disabledDate.bind(this);
    }

    componentDidMount() {
        let dateFormat = this.props.settings ? this.props.settings.dateFormat : this.dateFormat;
        this.dateFormat = dateFormat;
        if (this.props.field.settings) {
            if (this.props.field.settings.showTime) {
                this.dateFormat = this.dateFormat + ' HH:mm';
                this.setState({ showTime: true });
            }
        }

        if (this.props.data) {
            this.setState({
                value: moment(this.props.data, 'DD.M.YYYY')
            })
        }
    }

    onChange(date) { 
        let value = null;   
        if (date) {
            if (this.state.showTime) {
                value = moment(date).format('DD.M.YYYY HH:mm');
            } else {
                value = moment(date).format('DD.M.YYYY');
            }
            this.props.change(this.props.field.id, value);
            this.setState({value: value});
        } else {
            this.setState({value: null});
            this.props.change(this.props.field.id, null)
        }
        
        if (this.props.field.hasOwnProperty('connectedFields')) {
            let addVal = {};
            let jsonData = [];
            if (this.props.field.connectedFields.hasOwnProperty('dependency')) {
                this.props.field.connectedFields.dependency.map(field => {
                    addVal[field] = this.props.formData[field];
                })
            }
            addVal['body'] = moment(date).format(this.dateFormat);
            jsonData.push(JSON.stringify(addVal));

            let fields = this.props.field.connectedFields.fields;
            client.post(this.props.field.connectedFields.optionsUrl, addVal)
                .then(response => response.data)
                .then(response => {
                    fields.map(field => {
                        if (typeof response[field] !== 'undefined' && response[field] && response[field].length > 0) {
                            let i;
                            for (i = 0; i < response[field].length; i++) {
                                this.props.change(field, response[field] ? response[field] : null);
                            }
                        }
                    }
                    )
                })
        }
    }

    disabledDate(current){
        return current && !(moment().diff(current, 'months') >= 0 && moment().diff(current, 'months') < this.props.field.openPeriodBefore) && !(moment().diff(current, 'months') <= 0 && moment().diff(current, 'months') > this.props.field.openPeriodAfter*-1);
    };

    render() {
        var defaultDate = null;
        if (this.state.value) {
                if (this.state.showTime) {
                    defaultDate = moment(this.state.value, 'DD.M.YYYY HH:mm')
                } else {
                    defaultDate = moment(this.state.value, 'DD.M.YYYY')
                } 
            } 

        return <DatePickerComponent
            size="large"
            style={{ width: "100%" }}
            disabledDate={(this.props.field.openPeriodAfter || this.props.field.openPeriodBefore) ? this.disabledDate : false}
            showTime={this.state.showTime}
            dateFormat={this.dateFormat}
            onChange={this.onChange}
            disabled={this.props.field.disabled}
            selected={moment(this.state.value).format(this.dateFormat)}
            //defaultValue={moment(this.state.value)}
            value={defaultDate}
            format={this.dateFormat}
        />
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
