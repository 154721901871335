import React, {Component} from 'react';
import {render} from "react-dom";
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../common/translate';
import {Link} from 'react-router-dom';
import SlideModal from '../common/SlideModal';
import Select2 from "../forms/types/select2";
import SimpleForm from "../forms/form";
import DocumentPayments from "../forms/types/documentPayments";
import {onDocumentProfileFetchData} from '../../actions/actions';
import { withRouter } from 'react-router-dom';
import { Button, Menu, Dropdown} from 'antd';
import moment from 'moment';

class PaymentsInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            showPopup: false
        };

        this.handleClose = this.handleClose.bind(this);
    }
    handleClose() {
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/sale/' + this.props.sale.id + '/profile-info');
        this.setState({showPopup: false});
    }
    render() {
        let paymentTypes = this.props.paymentTypes ? this.props.paymentTypes : [];
        
        let menuOptions = null;
        if (this.props.sale && this.props.sale.hasOwnProperty('paymentStatus') && this.props.isSale) {
            menuOptions = (
                <Menu>
                    <Menu.Item>
                        <Link to={"/" + this.props.match.params.db + '/sale/' + this.props.sale.id + '/make-payment/new'}>
                            {plainTranslate(this.props.settings.locale, 'Fiscal Payment')}
                        </Link>
                    </Menu.Item>
                    {paymentTypes && paymentTypes.map((type) => { 
                        return <Menu.Item>
                            <Link to={"/" + this.props.match.params.db + '/payment/new?ids=' + this.props.sale.id + '&module=sales&type=' + type.id}>
                                {plainTranslate(this.props.settings.locale, type.name)}
                            </Link>
                        </Menu.Item>
                    })}
                </Menu>
            );
        }
        if (this.props.sale && this.props.sale.hasOwnProperty('paid') && paymentTypes && paymentTypes.length) {
            menuOptions = (
                <Menu>
                    {paymentTypes.map((type) => { 
                        return <Menu.Item>
                            <Link to={"/" + this.props.match.params.db + '/payment/new?ids=' + this.props.sale.id + '&module=expense&type=' + type.id}>
                                {plainTranslate(this.props.settings.locale, type.name)}
                            </Link>
                        </Menu.Item>
                    })}
                </Menu>
            );
        }

        return (
            <div>
                <div className="account-profile-other-information-more">
                    <h4>{plainTranslate(this.props.settings.locale, 'Payments')}</h4>

                    {this.props.sale && this.props.roles && this.props.roles.hasCreate == true &&
                        <span className="text-right btn-add-payment">
                            {((this.props.sale.paymentStatus !== 2 && this.props.sale.paymentStatus !== 'paid') || (this.props.sale.hasOwnProperty('paid') && this.props.sale.paid !== 'paid')) &&
                            <div className="dropdown div-inline">
                                {menuOptions && 
                                    <div className="btn-round btn-blue" id="dropdownMenuButton">
                                        <Dropdown overlay={menuOptions} placement="bottomCenter" >
                                            <Button style={{ background: "transparent", border: "none" }}><i className="ion-android-add "></i></Button>
                                        </Dropdown>
                                    </div>
                                }
                                {this.props.sale.hasOwnProperty('paid') && menuOptions &&
                                    // <Link className="btn-round btn-blue dropdown-toggle" to={"/" + this.props.match.params.db + '/payment/new?ids=' + this.props.sale.id + '&module=expense'}>
                                    //     <i className="ion-android-add "></i>
                                    // </Link>
                                    <div className="btn-round btn-blue" id="dropdownMenuButton">
                                        <Dropdown overlay={menuOptions} placement="bottomCenter" >
                                            <Button style={{ background: "transparent", border: "none" }}><i className="ion-android-add "></i></Button>
                                        </Dropdown>
                                    </div>
                                }
                            </div>
                              }
                        </span>
                    }

                    {this.props.payments && this.props.payments.length > 0 &&
                        <a className="account-profile-show" id="payment" href="javascript:void(0)"
                           onClick={() => this.setState({show: !this.state.show})}>
                            {this.state.show ? <i className="glyphicon glyphicon-chevron-up"></i> :
                                <i className="glyphicon glyphicon-chevron-down"></i>}
                        </a>
                    }
                    {
                        this.state.show &&
                        <div className="line">
                        <table  className="table">
                            <thead>
                                <tr>
                                    <th>{plainTranslate(this.props.settings.locale, '#')}</th>
                                    <th>{plainTranslate(this.props.settings.locale, 'Date')}</th>
                                    <th>{plainTranslate(this.props.settings.locale, 'Amount')}</th>
                                    <th>{plainTranslate(this.props.settings.locale, 'Payment method')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.payments.map(payment=>
                                    <tr>
                                        <td>
                                            {this.props.roles && this.props.roles.hasEdit == true && 
                                                <a href={'/' + this.props.match.params.db +'/payment/'+payment.secureId+'/edit'}>{payment.no}</a>}
                                            {this.props.roles && this.props.roles.hasEdit == false && 
                                                <span>{payment.no}</span>
                                            }
                                        </td>
                                        <td>{payment.date ? moment((payment.date).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</td>
                                        <td>{parseFloat(payment.amount).toFixed(2)}</td>
                                        <td>{payment.method}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        </div>
                    }
                    {
                        this.props.sale && this.state.showPopup &&     
                            <SlideModal onClose={() => this.setState({showPopup: false})} title={plainTranslate(this.props.settings.locale, 'Make payment')}>
                                <section className="newPanel">
                                    <div className="panel-body">
                                        <div className="row m-bot15">
                                            <SimpleForm
                                                settings={this.props.settings}
                                                formRoute={this.props.match.params.db + '/api/sale/'+this.props.sale.id+'/profile/payment-form/'}
                                                saveRoute={this.props.match.params.db + '/api/sale/'+this.props.sale.id+'/profile/payment-form'}
                                                match={{params: {id: this.props.sale.id}}}
                                                onSuccessfulSave={this.handleClose}
                                                popup={true}
                                                isNew={true}
                                                onCancel={this.handleClose}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </SlideModal>
                    }
                </div>
                <div className="separate"></div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onDocumentProfileFetchData: (db, id) => dispatch(onDocumentProfileFetchData(db, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentsInfo));
