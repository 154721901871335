import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';

class Switch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : ''
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value
            });
        }

        if(this.props.value === undefined) {
            this.props.onChange(this.props.id, false);
        }
    }

    onChange(e) {
            if (this.props.checkUrl) {
                if (!this.state.value == true) {
                let accountId;
                if (this.props.saveRoute){
                    accountId = this.props.saveRoute.split('accountId=').pop();
                }
                client.post(this.props.checkUrl, {'entityId': this.props.entityId, 'accountId' : accountId})
                        .then(response => response.data)
                        .then(response => {
                           if (response['success']) {
                               this.props.onChange(this.props.id, !this.state.value);
                           } else {
                                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
                           }
                })
            } else {
                this.props.onChange(this.props.id, !this.state.value);
            }
        } else {
            this.props.onChange(this.props.id, !this.state.value);
        }
        
    }

    render() {
        return <div className="switchComponent text-left">
            <input
                checked={this.state.value}
                onChange={this.onChange}
                onBlur={() => this.props.loseFocus(this.props.id)}
                type="checkbox"
                id={'check-status' + this.props.id}
                label='status'
                tabIndex={this.props.tabindex ? this.props.tabindex : ''}
            />
            <label htmlFor={'check-status' + this.props.id}></label>
        </div>
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Switch);
