import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Translate, {plainTranslate} from '../common/translate';

class Confirmation extends Component {
  constructor() {
    super();
  }

  componentDidMount(){
    document.documentElement.style.backgroundColor = "#fbfaff";
    if (this.props.auth && this.props.auth.isAuthenticated) {
      this.props.history.push('/' + this.props.match.params.db + '/dashboard');
  }
  }

  render() {
    return (
       <div className="header-new">
        <div className="container">
            <div id="content">
                <div className="col-md-12">
                    
                        <h1 className="text-center" style={{fontWeight: 'bold'}}><Translate locale={this.props.settings.locale} value="Thank you for registering!"/></h1>
                        <p className="text-center" style={{fontSize: 20, width: "50%",margin: "auto"}}><Translate locale={this.props.settings.locale} value="Your account has been created, but it requires administrator approval before you can sign in."/></p>
                        <div className="text-center" style={{marginTop: 30}}>
                            <input onClick={() => this.props.history.push('/'+this.props.match.params.db+'/user/signin')} type="submit" style={{float: 'none'}} className="auth-button" value={plainTranslate(this.props.settings.locale, "Sign in")}/>
                        </div>
                </div>
            </div>
{/*            <div className="menu-bottom">
                <span>'Copyright 2020 '&copy;
                    <a href="http://composity.com">'Composity'</a>
                    'All rights reserved.
                </span> 
                <span id="powered">'Powered by' <a href="http://composity.com">Image </a>
                </span>
            </div>*/}
        </div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
    settings: state.settings,
    auth: state.auth,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);

