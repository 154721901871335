
import {
        SET_CURRENT_USER,
        GET_ERRORS,
        GET_ERRORS_REGISTRATION,
        GET_REGISTER_DATA,
        POPUP_MESSAGE_SHOW,
        CLEAR_ERRORS,
        CLEAR_CURRENT_PROFILE } from '../constants/actionTypes';

const initialState = {
    isAuthenticated: false,
    user: {},
    profile: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: action.payload ? true : false,
                user: action.payload
            };
        case CLEAR_CURRENT_PROFILE:
            return {
                ...state,
                profile: null
            };
        default:
        case GET_REGISTER_DATA:
            return {
                ...state,
                registerData: action.payload
            };
        case GET_ERRORS_REGISTRATION:
            return {
                ...state,
                registerErrors: action.payload
            };
            return state;
}
}
