import React from 'react';
import { connect } from 'react-redux';
import Translate, { plainTranslate } from '../../common/translate';
import SlideModal from '../../common/SlideModal';
import Select from 'react-select';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';
import Form from "../form";

class EmailMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emailPopup: false,
        };
        this.sendMessage = this.sendMessage.bind(this);
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.onBodyChange = this.onBodyChange.bind(this);
    }

    handleTemplateChange(value) {
        console.log(value)
        if (value) {
            var body = value.template ? value.template.body : value.body;
            if (this.props.field.fields) {
                var i;
                for (i = 0; i < this.props.field.fields.length; i++) {
                    body = body.replace('{' + this.props.field.fields[i]['id'] + '}', this.props.formData.form.initializeFromState2.values[this.props.field.fields[i]['id']]);
                }
            }
            this.setState({ body: body, template: value ? value.template : null });
        } else {
            this.setState({ template: null, body: null })
        }
    }

    handleSelectChange(val, type) {
        if (type == 'from') {
            this.setState({ from: val });
        }
        if (type == 'to') {
            this.setState({ to: val ? val.value : null });
        }
    }

    onBodyChange(event) {
        this.setState({ body: event.target.value });
    }

    sendMessage() {
     /*   client.post('/' + this.props.settings.db + '/api/email/send', {
            'template': this.state.template,
            'body': this.state.body,
            'setting': this.state.from,
            'receiver': this.state.to,
            'topic': document.getElementById('topic').value
        })
            .then(response => response.data)
            .then((response) => {
                if ('errors' in response) {
                    showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
                }
                else {
                    this.setState({ emailPopup: false });
                    showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
                }
            })*/
    }

    render() {
        let enableSave = false;
        if (this.state.from && this.state.to) {
            enableSave = true;
        }

        return (
            <React.Fragment>
                <a className="btn btn-crm" onClick={() => this.setState({ emailPopup: true })}>
                    {plainTranslate(this.props.settings.locale, this.props.field.title)}</a>
                {this.state.emailPopup === true &&
                    <SlideModal onClose={() => this.setState({ emailPopup: false })} title={plainTranslate(this.props.settings.locale, 'New Email')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    {this.props.field.emailSettings && this.props.field.emailSettings.length > 0 &&
                                        <React.Fragment> {console.log(this.props.field.id)}
                                                <Form
                                                   formRoute={this.props.settings.db + '/api/message-email/' }
                                                   saveRoute={this.props.settings.db + '/api/message-email/new'}
                                                   popup={true}
                                                   updateParent={() => this.closePopup()}
                                                   onCancel={() => this.setState({emailPopup: false})}
                                                   form={'initializeFromState'}
                                                   type =  {this.props.field && this.props.field.ids ? JSON.stringify({id: this.props.field.ids}) : null}
                                                   refs = {this.props.field && this.props.field.userEmail && this.props.field.userEmail.length > 0 ? this.props.field.userEmail[0].value : null}
                                                   match={{params: {id: 0}}}
                                                   isNew={true}
                                               />
                                            </React.Fragment>
                                    }
                                    {this.props.field.emailSettings && this.props.field.emailSettings.length == 0 &&
                                        <div>
                                            <p>{plainTranslate(this.props.settings.locale, 'Send email directly from an Account profile with the Email service integration. Configure Email integration-related settings on the Composity platform from Account -> Email.')}</p>
                                            <a className="rectangular_button green" href={'/' + this.props.settings.db + '/settings/integrations'}><Translate locale={this.props.settings.locale} value="Setup now" /></a>
                                        </div>
                                    }
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    formData: state,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(EmailMessage);
