import React from 'react';
import { connect } from 'react-redux';
import Translate, { plainTranslate } from "../common/translate";
import Checkbox from './checkbox';
import { MODULES_CHANGE, SETTINGS_CHANGE } from "../../constants/actionTypes";
import client from '../../actions/client';
import showAToast from '../common/showAToast';
import {
    onSidebarMount
} from '../../actions/actions';

class ModulesFields extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            showMessage: false,
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value ? this.props.value : []
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value ? this.props.value : []
            });
        }
    }

    onChange(item) {
        let settings = Object.assign({}, this.state.value);
        settings[item.value] = !settings[item.value];
        this.setState({
            value: settings,
        }, function () {
            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(() => {
                client.post(this.props.saveRoute, {
                    'settings': this.state.value,
                })
                .then(response => response.data)
                .then(response => {
                    this.props.onChangeModules(settings, this.props.setting);
                    this.props.onSidebarMount('/'+this.props.settings.db+'/api/settings/sidebar')
                    .then(response => showAToast(plainTranslate(this.props.settings.locale, 'Settings saved successfully!'), 'succSettings', 'success'));
                });
            }, 2000);
        });
}

render() {
    return <div className="col-md-12 no-gutter">
        <div className="panel panel-default" style={{ marginTop: 0 }}>
            <table className="table">
                <thead>
                    <tr>
                        <th><Translate locale={this.props.settings.locale} value={this.props.label} /></th>
                        <th><Translate locale={this.props.settings.locale} value="Enable" /></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.fields && this.props.fields.map((item, key) => (
                        <Checkbox
                            locale={this.props.settings.locale}
                            key={key}
                            name={item}
                            isChecked={this.state.value && this.state.value[item.value]}
                            handleCheckboxChange={this.onChange}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    </div>
}
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onChangeModules: (modules, setting) => dispatch({ type: MODULES_CHANGE, payload: { plan: modules, setting: setting } }),
    onChangeSettings: (setting, value, type) => dispatch({ type: SETTINGS_CHANGE, payload: { setting: setting, value: value, type: type } }),
    onSidebarMount: (route) => dispatch(onSidebarMount(route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModulesFields);


