import React from 'react';
import {connect} from 'react-redux';
import "./tableCollection.css";
import Text from "./text";
import Number from './number';
import Select2 from "./select2";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {plainTranslate} from "../../common/translate";
import SlideModal from '../../common/SlideModal';
import Form from "../form";
import {onAddSelectOption} from "../../../actions/actions";
import { withRouter } from 'react-router-dom'

class TableCollection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lines: [],
            errors: [],
            itemForm: false
        };

        this.onChange = this.onChange.bind(this);
        this.removeLine = this.removeLine.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onNewOptions = this.onNewOptions.bind(this);
        this.newItem = this.newItem.bind(this);
        this.calculateOperation = this.calculateOperation.bind(this);
    }

    componentDidMount() {
        let lines = this.props.lines ? this.props.lines : [];
        lines.push({});
        this.setState({
            lines: lines ? lines : []
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.lines) !== JSON.stringify(this.props.lines) || JSON.stringify(prevProps.errors) !== JSON.stringify(this.props.errors)) {
            let lines = this.props.lines ? this.props.lines : [];
            this.setState({
                lines: lines ? lines : [],
                errors: this.props.errors
            });
        }
    }

    onChange(line, fieldId, value, additionalValue) {
        let lines = this.state.lines;
        let columns = this.props.columns;
        if ((JSON.stringify(lines[line]) === "{}") && !value) return;
        lines[line][fieldId] = value;
        if (additionalValue) {
            let found = columns[0].options.some(el=> el.value === additionalValue.value);
            if(!found){
                columns[0].options.push(additionalValue);
            }
            lines[line][fieldId + 'AdditionalValue'] = additionalValue;
        }

        this.props.onChange(this.props.id, lines);
        if (line === lines.length - 1) {
            line = lines[line];
            Object.keys(line).forEach(ob => {
                if (ob.indexOf('options') !== -1 || !line[ob]) delete line[ob];
            });
            if (JSON.stringify(line) !== "{}")
                lines.push({});
        }

        this.setState({lines});

        this.props.onChange(this.props.id, lines);
    }

    onNewOptions(line, fieldId, options) {
        let lines = this.state.lines;
        if (lines) {
            if (!(line in lines))
                return;
            if (options)
                lines[line][fieldId + 'options'] = options;
            this.setState({lines});
            this.props.onChange(this.props.id, lines);
        }
    }

    removeLine(line) {
        let lines = this.state.lines;
        lines.splice(line, 1);
        this.setState({lines});
        this.props.onChange(this.props.id, lines);
    }

    onDragEnd(result) {
        // dropped outside the table
        if (!result.destination) {
            return;
        }

        let lines = this.state.lines;
        const removed = lines.splice(result.source.index, 1);
        lines.splice(result.destination.index, 0, removed[0]);
        this.setState({lines});
        this.props.onChange(this.props.id, lines);
    }
    
    buttonAction(action) {
        if (!action) return;

        if (action.reactMethod) {
            if(action.reactMethod === 'newItem') {
                this.setState({itemForm: this.state.itemForm ? false : action});
            }
        }
    }
    
    newItem(data) {
        //data = data.entity;
        this.setState({itemForm: false});
//        let fproperties = this.props.formProperties;
//        fproperties.form.fields[0].tabs[0].fields[0].options.push(data);
//        this.props.onAddSelectOption(this.props.formId, fproperties)
//            .then(() => this.props.onUpdateField(this.props.formId, 'itId', data.id));
    }

    calculateOperation(operation, line) {
        let field1 = operation.field1;
        let field2 = operation.field2;

        if (field1.type === 'additionalValue') field1 = field1.populateFrom in line && field1.name in line[field1.populateFrom] ? line[field1.populateFrom][field1.name] ? line[field1.populateFrom][field1.name] : 0 : 0;
        else if (field1.type === 'formValue') field1 = field1.name in line ? line[field1.name] : 'populateFrom' in field1 && field1.populateFrom in line && field1.name in line[field1.populateFrom] ? line[field1.populateFrom][field1.name] : field1.defaultValue ? field1.defaultValue : 0;
        else if(field1.type === 'mainFormValue' && this.props.mainFormData) field1 = field1.name in this.props.mainFormData ? this.props.mainFormData[field1.name] : field1.defaultValue ? field1.defaultValue : 0;
        else if ('operation' in field1) field1 = this.calculateOperation(field1.operation, line);

        if ('pre' in operation.field1) {
            operation.field1.pre.forEach(op => {
                if (op.type === 'add') field1 += op.value;
                if (op.type === 'subtract') field1 -= op.value;
                if (op.type === 'multiply') field1 *= op.value;
                if (op.type === 'divide') field1 /= op.value;
            });
        }

        let rate = 1;
        if (field2.type === 'additionalValue') field2 = field2.populateFrom in line && field2.name in line[field2.populateFrom] ? line[field2.populateFrom][field2.name] ? line[field2.populateFrom][field2.name] : 0 : 0;
        else if (field2.type === 'formValue') field2 = field2.name in line ? line[field2.name] : 'populateFrom' in field2 && field2.populateFrom in line && field2.name in line[field2.populateFrom] ? line[field2.populateFrom][field2.name] : field2.defaultValue ? field2.defaultValue : 0;
        else if ('operation' in field2) field2 = this.calculateOperation(field2.operation, line);
        else if(field2.type === 'range') {
            let col = field2.name in line ? line[field2.name] : field2.defaultValue ? field2.defaultValue : 0;
            field2.range.forEach(item => {
                if(item.id === col) {
                    let checkDate = field1;
                    if(field1.indexOf('/') !== -1) {
                        checkDate = checkDate.split('/');
                        checkDate = new Date(checkDate[2], parseInt(checkDate[1]) - 1, checkDate[0]);
                    }
                    else {
                        checkDate = new Date(checkDate);
                    }

                    let from = new Date(item.from.date);
                    let to = new Date(item.to.date);
                    if(checkDate >= from && checkDate <= to) {
                        rate = item.value;
                    }
                }
            });
        }

        if ('pre' in operation.field2) {
            operation.field2.pre.forEach(op => {
                if (op.type === 'add') field2 += op.value;
                if (op.type === 'subtract') field2 -= op.value;
                if (op.type === 'multiply') field2 *= op.value;
                if (op.type === 'divide') field2 /= op.value;
            });
        }

        let result = 0;
        if (operation.type === 'add') {
            result = parseFloat(field1) + parseFloat(field2);
        }
        if (operation.type === 'subtract') result = field1 - field2;
        if (operation.type === 'multiply') result = field1 * field2;
        if (operation.type === 'dateRange') result = rate;

        if (operation.decimalPoint) {
            result = result.toFixed(operation.decimalPoint);
        }

        return result;
    }

    render() {
        let errors = {};
        if (this.state.errors) {
            this.state.errors.map(error => {
                if (!(error.index in errors))
                    errors[error.index] = {};

                errors[error.index][error.field] = error.type;
            });
        }

        return <span className="form-input-field tableCollection">
            {
                this.state.itemForm &&
                <SlideModal onClose={() => this.buttonAction(this.state.itemForm)}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <Form
                                    saveRoute={'/' + this.props.match.params.db + '/api/items'}
                                    formRoute={'/' + this.props.match.params.db + '/api/items/form'}
                                    match={{params: {id: 0}}}
                                    onSuccessfulSave={(data) => this.newItem(data)}
                                    isNew={true}
                                    popup={true}
                                    onCancel={() => this.buttonAction({reactMethod: 'newItem'})}
                                />
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <table ref={provided.innerRef}>
                            <thead>
                            {
                                this.props.columns &&
                                <tr>
                                    <td className={'left tableHeader'}></td>
                                    <td></td>
                                    {
                                        this.props.columns.map((column, key) => {
                                            return <td key={key} className={column.type=='number' ? "text-right number-field" : "text-left"}>
                                            {plainTranslate(this.props.settings.locale, column.label)}
                                            {column.button &&
                                                <span>
                                                    <a className="pull-right add-customer-label" onClick={() => this.buttonAction(column.button.action)}>
                                                    {column.button.icon &&
                                                        <i className={column.button.icon}></i>
                                                    }
                                                    <p>{plainTranslate(this.props.settings.locale, column.button.label)}</p></a>
                                                </span>
                                            }
                                            </td>
                                        })
                                    }
                                    <td className={'right tableHeader'}></td>
                                </tr>
                            }
                            </thead>
                            <tbody>
                            {
                                this.state.lines &&
                                this.state.lines.map((line, key) => {
                                    return <Draggable key={key} draggableId={key + 1} index={key}>
                                        {(provided, snapshot) => (
                                            <tr key={key} ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <td className={'left'}>
                                                    {
                                                        key !== this.state.lines.length &&
                                                        <span onClick={() => this.removeLine(key)}>
                                                        <i className='fa fa-arrows' title="Reorder rows"></i></span>
                                                    }
                                                </td>
                                                <td className={'rowNumber'}>{key + 1}</td>
                                                {
                                                    this.props.columns.map((column, colKey) => {
                                                        let fieldValue = line[column.id];
                                                        if ('populateFrom' in column && key !== this.state.lines.length - 1) {
                                                            if (column.populateFrom === 'mainFormValue' && this.props.mainFormData) {
                                                                fieldValue = column.id in this.props.mainFormData ? this.props.mainFormData[column.id] : column.defaultValue ? column.defaultValue : 0;
                                                            }
                                                            else {
                                                                fieldValue = line[column.id] && !(column.populateFrom in line) ? line[column.id] : column.populateFrom in line ? line[column.populateFrom][column.id] : null;
                                                            }
                                                        }
                                                        else if ('operation' in column) {
                                                            fieldValue = this.calculateOperation(column.operation, line);
                                                        }

                                                        if (column.initialValue !== undefined && !fieldValue && key !== this.state.lines.length - 1) {
                                                            fieldValue = column.initialValue;
                                                        }

                                                        return <td key={colKey}
                                                                   style={{borderColor: key in errors && column.id in errors[key] ? 'red' : ''}}>
                                                            {
                                                                column.type === 'text' &&
                                                                <Text
                                                                    id={column.id}
                                                                    value={fieldValue}
                                                                    placeholder={column.placeholder}
                                                                    onChange={(id, value) => this.onChange(key, id, value)}
                                                                    loseFocus={(id) => this.props.loseFocus(key, id)}
                                                                    disabled={column.disabled}
                                                                    tabindex={column.tabindex}
                                                                />
                                                            }
                                                            {
                                                                column.type === 'number' &&
                                                                <Number
                                                                    id={column.id}
                                                                    value={fieldValue ? fieldValue : key !== this.state.lines.length - 1 ? 0 : ''}
                                                                    rules={column.rules}
                                                                    placeholder={column.placeholder}
                                                                    onChange={(id, value) => this.onChange(key, id, value)}
                                                                    scale={column.scale}
                                                                    loseFocus={(id) => this.props.loseFocus(key, id)}
                                                                    disabled={column.disabled}
                                                                    tabindex={column.tabindex}
                                                                />
                                                            }
                                                            {
                                                                column.type === 'select2' &&

                                                                <Select2
                                                                    formId={this.props.formId}
                                                                    id={column.id}
                                                                    value={fieldValue ? fieldValue : key !== this.state.lines.length - 1 ? 0 : ''}
                                                                    placeholder={column.placeholder}
                                                                    onChange={(id, value, additionalValue, options) => this.onChange(key, id, value, additionalValue, options)}
                                                                    onNewOptions={(options) => this.onNewOptions(key, column.id, options)}
                                                                    loseFocus={(id) => this.props.loseFocus(key, id)}
                                                                    tabindex={column.tabindex}
                                                                    options={column.options}
                                                                    settings={column.settings}
                                                                />
                                                            }
                                                        </td>
                                                    })
                                                }

                                                <td className={'right'}>{key !== this.state.lines.length &&
                                                <span onClick={() => this.removeLine(key)}>
                                                    <i className='ion-android-close' title="Delete row"></i></span>
                                                }
                                                </td>
                                            </tr>
                                        )}
                                    </Draggable>
                                })
                            }
                            {provided.placeholder}
                            </tbody>
                        </table>
                    )}
                    </Droppable>
            </DragDropContext>
        </span>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableCollection));
