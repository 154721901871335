import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Translate, { plainTranslate } from '../common/translate';
import client from '../../actions/client';
import showAToast from '../common/showAToast';

class DemoContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDemoOpen: true,
            selectedOption: 'no',
            isLoadingOpen: false,
            isOpen: this.props.isOpen
        };

        this.handleChange = this.handleChange.bind(this);
        this.getStarted = this.getStarted.bind(this);
    }

    handleChange(prop, e) {
        this.setState({
            selectedOption: e.target.value
          });
    }

    getStarted() {
        this.setState({
            isDemoOpen: false,
        }, function () {
            client
            .post('/' + this.props.db + '/api/onboarding/faker/data', {answer: this.state.selectedOption})
            .then(response => {
                setTimeout(function () {
                    if ('message' in response.data) {                      
                            showAToast(plainTranslate(this.props.settings.locale, response.data.message), 'succFaker', 'success');                      
                    }else{
                        showAToast(plainTranslate(this.props.settings.locale, "Success!"), 'succFaker', 'success');
                    }
                    window.location.replace(this.props.db + '/dashboard');
                }.bind(this), 10000);               
            })
            .catch(err => {      
                if (err.response && err.response.data && err.response.data.message) {
                    showAToast(plainTranslate(this.props.settings.locale, err.response.data.message), 'errFaker', 'error');
                }else{
                    showAToast(plainTranslate(this.props.settings.locale, 'Error'), 'errFaker', 'error');
                }         
            });
          
            if (this.state.selectedOption === 'yes') {
                this.setState({
                    isLoadingOpen: true,
                }
                );
            } else {
                this.props.closeModal();
            }
        }
        );
    }

    render() {
        return (
            <div>
                <div className={this.state.isDemoOpen ? " " : "hidden"}>
                    <div>
                        <div className="col-md-12 text-center m-bot50">
                            <span className="dot">✓</span>
                            <div className="rectangle"></div>
                            <span className="dot">✓</span>
                            <div className="rectangle"></div>
                            <span className="dot">3</span>
                        </div>
                        <div className="col-md-12 onboarding-selectable">
                        <div className="col-md-10 column-centered">
                            <h4 className="text-bold"><Translate locale={this.props.locale} value="Do you want to activate a sample demo data in the system?" /></h4>                                
                        </div>
                        <div className="col-md-10 column-centered">
                            <form
                            onChange={(e) => this.handleChange('demo', e)}>
                                <div className="col-md-12 onboarding-demo">
                                    <div> 
                                        <input className="custom-radio" id="option-one" type="radio" value="yes" checked={this.state.selectedOption === 'yes'}/> 
                                        <label htmlFor="option-one"> 
                                        <span></span>
                                        <div className="custom-radio-border"></div>
                                        <Translate locale={this.props.locale} value="Add demo data to my workspace" />
                                        </label> 
                                    </div>
                                        <p>
                                              <Translate locale={this.props.locale} value="A sample demo data will be added to your system. Once you want to remove the demo data and start again with an empty workspace, please contact our support team at "/>
                                              <a className='links' href="mailto:support@composity.com">support@composity.com</a>. 
                                              <Translate locale={this.props.locale} value=" Keep in mind that all records you have created will be deleted too." />
                                        </p>
                                 </div>
                                <div className="col-md-12 onboarding-demo">
                                    <div>
                                        <input className="custom-radio" id="option-two" type="radio" value="no" checked={this.state.selectedOption === 'no'}/> 
                                        <label htmlFor="option-two"> 
                                        <span></span>
                                        <div className="custom-radio-border"></div>
                                        <Translate locale={this.props.locale} value="Don't add demo data to my workspace" />
                                        </label> 
                                    </div>
                                        <p>
                                            <Translate locale={this.props.locale} value="If you have chosen this option a sample demo data will not be installed in the system. Your workspace will be empty." />
                                        </p>
                                </div>
                           </form>
                        </div>
                        </div>
                    </div>

                    <button className="button-outline white pull-right text-uppercase"
                        onClick={this.getStarted}>
                        <Translate locale={this.props.locale} value="Get Started »" />
                    </button>
                </div>
                <div className={this.state.isLoadingOpen ? " " : "hidden"}>
                        <div className="col-md-12 col-xs-12 onboarding-selectable">
                            <div className="col-md-12 col-xs-12 text-center">
                                <h2 className="col-md-12 text-center text-bold"><Translate locale={this.props.locale} value="Please wait a little while the demo data is loading." /></h2>
                                <div>
                                    <img src='/images/loading.gif' style={{width: 100 + 'px'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
export default connect(mapStateToProps, {})(DemoContent);


