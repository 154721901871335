import React, { Component } from 'react';

export default class CustomColorRenderer extends Component {
        
    constructor(props) {
         super(props);
        
          this.state = {
            value: this.props.value,
        };
    }

     render() { 
         var background = this.props.data[this.props.column.colDef.colorProperty] ? this.props.data[this.props.column.colDef.colorProperty] : '#fff';
        return (<>
            {this.state.value ?
             <span style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 3,
                paddingBottom: 3,
                borderRadius: 4,
                border: '1px solid #364a5a !important',
                background: background, 
                width: this.props.column.colDef.width-10,                  
                textAlign: 'center'}}>
                    {this.state.value}
            </span>
            :
            <span></span>
            }
            </>
        );
    }
}        