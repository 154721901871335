import React from 'react';
import {connect} from 'react-redux';

class Percents extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ''
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value ? this.props.value : ''
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value ? this.props.value : ''
            });
        }
    }

    onChange(e) {
        this.setState({
            value: e.target.value
        });
        this.props.onChange(this.props.id, e.target.value);
    }

    render() {
        return <div className="col-xs-12 col-sm-12 no-gutter">
            <span className="form-input-field">
                <input type="text"
                       required="required"
                       className="text-right form-control percent-input"
                       value={this.state.value}
                       onChange={this.onChange}
                       onBlur={() => this.props.loseFocus(this.props.id)}
                       placeholder={this.props.placeholder ? this.props.placeholder : ''}
                       tabIndex={this.props.tabindex ? this.props.tabindex : ''}
                style={{width: '50%', minWidth: '45px', padding: '5px', borderRadius: '3px 0 0 3px'}}/>
            </span>
            <span className="input-group-addon" style={{width: 'auto', height: '40px'}}>%</span>
        </div>
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Percents);
