import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from "../../common/translate";
import SingleCheckbox from './singleCheckbox';


class SingleCheckboxes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
        };  
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
       this.setState({
            value: this.props.input.value ? this.props.input.value : this.props.field.initialValue
        });
    }

    onChange(name) {
        const settings = this.state.value ? this.state.value : [];
        for (var i = 0; i < settings.length; i++) {
            if (settings[i] == name) {
                var index = i;
            }
        }
        if (index != undefined) {
            settings.splice(index, 1);
        } else {
            let obj = {};
            obj = name;
            settings.push(obj);
        }

        this.setState({
            settings,
        }, function () {
            this.props.change(this.props.field.id, this.state.value);
        });
    }

     render() {
     return     <div className="col-md-12 no-gutter">
                    <div className="panel panel-default" style={{marginTop: 0}}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th><Translate locale={this.props.settings.locale} value="Field"/></th>
                                    <th><Translate locale={this.props.settings.locale} value="Enable"/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.fields && this.props.fields.map((item, key) => (
                                    <SingleCheckbox
                                        locale={this.props.settings.locale}                                                   
                                        key={key}                                      
                                        name={item}
                                        isChecked={this.state.value && this.state.value.find(cat => cat==[item]) ? true:false}
                                        handleCheckboxChange={this.onChange}
                                        />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SingleCheckboxes);

