export default function getPosGridRoutes(db) {
    return [
        {
            title: ['Sales', '›', 'List'],
            path: '/:db/pos/transactions/sales',
            gridRoute: '/api/pos/transactions/sales/settings/grid-properties',
            gridDataRoute: '/api/pos/transactions/sales'
        },
        {
            title: ['Invoices', '›', 'List'],
            path: '/:db/pos/transactions/invoices',
            gridRoute: '/api/pos/transactions/invoices/settings/grid-properties',
            gridDataRoute: '/api/pos/transactions/invoices'
        },
        {
            title: ['Stock on Hand'],
            path: '/:db/pos/data-source/stock-on-hand',
            gridRoute: '/api/data-source/onhand/settings/grid-properties',
            filtersRoute: '/api/reports/onhand/filters/',
            filtersDataRoute: '/api/reports/onhand/dataSource',
            filtersSaveRoute: '/api/reports/onhand/filters/save',
            gridExportRoute: '/api/reports/onhand/export',
            isReport: true
        },
    ];
}