import React, { Component } from 'react';
import { render } from "react-dom";
import Translate, { plainTranslate } from '../../common/translate';
import { connect } from 'react-redux';
import SettingsMenu from '../../sidebar/settings-menu';
import client from '../../../actions/client';
import { fetchSettings} from "../../../actions/actions";
import showAToast from '../../common/showAToast';
import OpenSection from './../openSection.jsx';

class AccountingSettingsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/accounting/settings/accounting-settings', 'accountingSettings');
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

   

    render() {
        return (
            <div>
                <SettingsMenu
                    location={this.props.location.pathname} db = {this.props.match.params.db}/>
                    <form name="settings_acc" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                   <div className="settings">
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Accounting custom fields')}
                                            description={plainTranslate(this.props.settings.locale, "Specify different types of fields for accounting")}
                                            component={'textarea'}
                                            value ={this.props.settings.accountingSettings}
                                            type={'accountingSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/accounting/save/settings/individual'}
                                            setting={'accountingAdditionalFields'}
                                         />
                                         <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Accounting operations')}
                                            description={plainTranslate(this.props.settings.locale, "Specify different types of accounting operations")}
                                            component={'textarea'}
                                            value ={this.props.settings.accountingSettings}
                                            type={'accountingSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/accounting/save/settings/individual'}
                                            setting={'accountingSettingAccounting'}
                                         />
                                   </div>  
                                </div>
                             </section>
                          </div>
                       </div>
                    </form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    getSettings: (path, type) => dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountingSettingsPage);
