import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Translate, {plainTranslate} from '../common/translate';
import {GRID_HEADER_SHOW_OPTIONS, GRID_HEADER_OPTION_SELECTED} from '../../constants/actionTypes';
import {onHeaderOptionSelected} from '../../actions/actions';
import SlideModal from '../common/SlideModal';
import ImportSale from '../import/importSale';
import { BrowserRouter, Route } from 'react-router-dom';
import { Button, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons'

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {visible:false};

        this.optionClicked = this.optionClicked.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleImportClick = this.handleImportClick.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    optionClicked(option) {
        this.props.onOptionSelected(option);
        this.props.onFilterChange();
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.props.gridProperties.header.showOptions) {
            this.props.onShowOptions();
        }
    }

    handleImportClick(link = null) {
        if (link) {
            this.setState({ link: link });
        }
        this.setState({ importPopup: true })
    }

    render() {
        const header = this.props.gridProperties.header;

        let dropdownOptions = [];
        let additionalButtonOptions = [];
        let importButtonOptions = [];
        let activeOption = null;

        if(header && header.dropdown){
            dropdownOptions = (
                <Menu>
                    {header.dropdown.map((option, i) => (
                            <Menu.Item key={i}>
                                <Link to={option.link}>
                                    <Translate locale={this.props.settings.locale} value={option.label}/>
                                </Link>
                            </Menu.Item>
                        ))
                    }
                </Menu>
            );
            activeOption = this.props.gridProperties.header.dropdown.find(o => o.active == true);
        }

        if (header && header.additionalButton) {
            additionalButtonOptions = (
                <Menu>
                    {Array.isArray(header.additionalButton) && header.additionalButton.map((option, i) => {
                        if (option.notReact < 0)
                            return <Menu.Item key={i}>
                                <a href={option.link}>
                                    <Translate locale={this.props.settings.locale} value={option.label} />
                                </a>
                            </Menu.Item>
                        return <Menu.Item key={i}>
                            <Link to={option.link}>
                                <Translate locale={this.props.settings.locale} value={option.label} />
                            </Link>
                        </Menu.Item>
                    })}
                </Menu>
            );
        }
        
        if (header && header.importButton && header.additionalButton) {
            importButtonOptions = (
                <Menu>
                    {header.additionalButton.map((option, i) => 
                            <Menu.Item key={i}>
                                <a onClick={() => this.handleImportClick(option.link)}>
                                    <Translate locale={this.props.settings.locale} value={option.label} />
                                </a>
                            </Menu.Item>
                    )}
                </Menu>
            );
        }

        return (
            <div className="moduleSubMenu">
                <div className="col-sm-12">
                    {
                        header &&
                        <React.Fragment>
                            <nav role="navigation" className="nav">
                                <h3 className="module-title pull-left">

                                    {header.title}
                                    {
                                        header.selectedFilter &&
                                        <span>
                                    &nbsp;
                                            <span style={{fontSize: '20px', color: '#a5a3a3'}}><i
                                                className="fa fa-angle-right"
                                                aria-hidden="true"></i></span>
                                            &nbsp;
                                            {plainTranslate(this.props.settings.locale, header.selectedFilter)}
                                            &nbsp;
                                </span>
                                    }
                                    &nbsp;
                                </h3>
                                <ul className="nav-items">
                                    {
                                        header.options && header.options.length > 0 &&
                                        <li
                                            className={'nav-item dropdown hideMobile ' + (header.additionalButton || !header.newButton ? 'add' : '')}
                                            onClick={() => this.props.onShowOptions()}
                                            ref={this.setWrapperRef}
                                            // ref={this.setWrapperRef}
                                        >
                                            <a href="#">
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </a>
                                            {
                                                header.showOptions &&
                                                <nav className="submenus">
                                                    <ul className="submenus-items">
                                                        {
                                                            header.options.map((option, key) => {
                                                                return <li
                                                                    className={'submenus-item' + (option.line ? ' border-del' : '')}
                                                                    style={{marginLeft: 0}}
                                                                    key={key}>
                                                                    <a href="#"
                                                                       onClick={() => this.optionClicked(option)}
                                                                       className="submenus-link">
                                                                        <Translate locale={this.props.settings.locale}
                                                                                   value={option.label}/>
                                                                    </a>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                </nav>
                                            }
                                        </li>
                                    }

                                    {
                                        header.newButton && !header.additionalButton &&

                                        <li className="nav-item">
                                            {
                                                header.formsNotReact &&
                                                <a href={header.newButton} className="button-primary">
                                                    <i className="ion-plus-round"></i>
                                                    <span>
                                            <Translate locale={this.props.settings.locale} value="New"/>
                                        </span>
                                                </a>
                                            }
                                            {
                                                !header.formsNotReact &&
                                                <Link to={header.newButton} className="button-primary">
                                                    <i className="ion-plus-round"></i>
                                                    <span>
                                            <Translate locale={this.props.settings.locale} value="New"/>
                                        </span>
                                                </Link>
                                            }
                                        </li>
                                    }

                                    

                                    {
                                        header.importButton && !header.additionalButton &&

                                        <li className="nav-item hideMobile">
                                            {
                                                header.formsNotReact &&
                                                <a className="button-primary" onClick={() => this.handleImportClick(header.importButton)}>
                                                    <i className="ion-plus-round"></i>
                                                    <span>
                                                        <Translate locale={this.props.settings.locale} value="Import"/>
                                                    </span>
                                                </a>
                                            }
                                            {
                                                !header.formsNotReact &&
                                                <a className="button-primary" onClick={() => this.handleImportClick(header.importButton)}>
                                                    <i className="ion-plus-round"></i>
                                                    <span>
                                                        <Translate locale={this.props.settings.locale} value="Import"/>
                                                    </span>
                                                </a>
                                            }
                                        </li>
                                    }
                                    
                                    {
                                        this.state.importPopup &&
                                        <SlideModal onClose={()=>this.setState({importPopup: false})} title={plainTranslate(this.props.settings.locale, 'Import')}>
                                            <section className="newPanel">
                                                <div className="panel-body">
                                                    <div className="row m-bot15">
                                                        <ImportSale history={this.props.history} link={this.state.link}/>
                                                    </div>
                                                </div>
                                            </section>
                                        </SlideModal>
                                    }
                                </ul>
                                {
                                    header.additionalButton &&
                                    <Dropdown overlay={additionalButtonOptions} placement="bottomCenter" className="button-primary" visible={this.state.visible}>
                                        <Button style={{ width: "80px!important" }} onClick={() => this.setState({visible: !this.state.visible})}>
                                        {window.innerWidth > 768 &&
                                            <Translate locale={this.props.settings.locale} value="New" />
                                        }
                                        {window.innerWidth < 768 &&
                                            <i className="ion-plus-round"></i>
                                        }
                                        <span className="caret"></span></Button>
                                    </Dropdown>
                                }
                                {
                                    header.importButton && header.additionalButton && window.innerWidth > 768 &&
                                    <Dropdown overlay={importButtonOptions} placement="bottomCenter" className="button-primary">
                                        <Button style={{ width: "80px!important" }}><Translate locale={this.props.settings.locale} value="Import" /><span className="caret"></span></Button>
                                    </Dropdown>
                                }
                                {
                                    header.importDataButton &&
                                        <li className="nav-item hideMobile">
                                            <Link className="button-primary" to={header.importDataButton} style={{paddingTop: 0}}>
                                                <Translate locale={this.props.settings.locale} value="Import"/>
                                            </Link>
                                        </li>
                                    }
                                {
                                    header.tabs &&
                                    <ul className="nav navbar-nav pull-right">
                                        {
                                            header.tabs.map((tab, key) => {
                                                if (tab.notReact) {
                                                    return <li className="nav-item" key={key}>
                                                        <a className={tab.active ? 'active' : ''} href={tab.link}>
                                                            <Translate locale={this.props.settings.locale}
                                                                       value={tab.label}/>
                                                        </a>
                                                    </li>
                                                }
                                                else return <li className="nav-item" key={key}>
                                                    <Link className={tab.active ? 'active' : ''} to={tab.link} onClick={() => this.props.onTabChange(true)}>
                                                        <Translate locale={this.props.settings.locale}
                                                                   value={tab.label}/>
                                                    </Link>
                                                </li>
                                            })
                                        }
                                    </ul>
                                }
                                {
                                    header.dropdown &&
                                        <div>
                                            <Dropdown overlay={dropdownOptions}>
                                              <Button size="large" style={{marginLeft: 10, marginRight: 70, float: "right"}}>
                                                {plainTranslate(this.props.settings.locale, activeOption ? activeOption.label : null)}<DownOutlined />
                                              </Button>
                                            </Dropdown>
                                        </div>
                                }
                            </nav>

                            {
                                this.props.count && this.props.count > 15000 &&
                                <span><Translate locale={this.props.settings.locale}
                                                 value="You have more than 15000..."/></span>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    gridProperties: state.gridProperties,
    data: state.gridData
});

const mapDispatchToProps = dispatch => ({
    onShowOptions: () => dispatch({type: GRID_HEADER_SHOW_OPTIONS}),
    onOptionSelected: (option) => dispatch(onHeaderOptionSelected(option))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);