import {
    SETTINGS_LOAD,
    SETTINGS_SIDEBAR_CHANGE,
    SETTINGS_CONFIRM_DIALOG_TOGGLE,
    POPUP_MESSAGE_SHOW,
    POPUP_MESSAGE_HIDE,
    USER_ROLES_GRID_LOAD, USER_ROLES_GRID_REMOVE,
    MODULES_CHANGE,
    SETTINGS_CHANGE,
    CONFIGURATIONS_CHANGE,
    FETCH_SETTINGS
} from "../constants/actionTypes";


export default (state = [], action) => {
    let plan = null;
    let setting = null;
    let settings = null;
    let typeSettings = null;   
    switch (action.type) {        
        case SETTINGS_LOAD:
            return {
                ...state,
                ...action.payload
            };

        case SETTINGS_SIDEBAR_CHANGE:
            return {
                ...state,
                sidebar: action.payload.sidebar
            };

        case SETTINGS_CONFIRM_DIALOG_TOGGLE:
            let dialog = 'confirm' in state ? !state.confirm : true;
            return {
                ...state,
                confirm: dialog
            };

        case USER_ROLES_GRID_LOAD:
            return {
                ...state,
                userRoles: action.payload.roles
            };

        case USER_ROLES_GRID_REMOVE:
            const roles = state.userRoles.filter(role => role.id !== action.payload.id);
            return {
                ...state,
                userRoles: roles
            };

        case POPUP_MESSAGE_SHOW:
            return {
                ...state,
                message: {
                    showMessage: true,
                    text: action.payload.text,
                    isError: action.payload.isError,
                }
            };

        case POPUP_MESSAGE_HIDE:
            return {
                ...state,
                message: {
                    showMessage: false
                }
            };
        case MODULES_CHANGE:
            plan = action.payload.plan;
            setting = action.payload.setting;
            return {
                ...state,
                setting: plan
            };
        case FETCH_SETTINGS:
            settings = action.payload.settings;
            return {
                ...state,
                [action.payload.type]: settings
              };
        case SETTINGS_CHANGE:
            typeSettings = state[action.payload.type];
            typeSettings[action.payload.setting] = action.payload.value;
            return {
                ...state,
                [action.payload.type]: typeSettings
            };
        case CONFIGURATIONS_CHANGE:
            typeSettings = state[action.payload.type];
            typeSettings[action.payload.setting][action.payload.row][action.payload.subsetting] = action.payload.value;
            return {
                ...state,
                [action.payload.type]: typeSettings
            };
        
        default:
            return state;
    }
};