import React from 'react';
import { connect } from 'react-redux';
import Translate, { plainTranslate } from '../../common/translate';
import { Form, Input, Button, Select } from 'antd';
import ColorPicker from '../../colorPicker/popUpColor.jsx';
import { withRouter } from 'react-router-dom'
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';

const { Option } = Select;

class UserGeneralSettings extends React.Component {
    formRef = React.createRef()
    constructor(props) {
        super(props);

        this.state = {
            value: null,
        };
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onLangChange = this.onLangChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.generateKey = this.generateKey.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.state.value == null) {
            this.setState({
                value: this.props.value
            }, function () {
                this.formRef.current.setFieldsValue(this.state.value)
            });
        }
    }

    handleSubmit = (values) => {  
        this.props.onSave(values);
    }

    submitForm = e => {
        e.preventDefault()
        this.formRef.current.submit()
      }

    onLangChange(value) {
        switch (value) {
            case 'bg':
                this.formRef.current.setFieldsValue({ ui_language: 'bg' });
                return;
            case 'en':
                this.formRef.current.setFieldsValue({ ui_language: 'en' });
                return;
        }
    };

    onSelectChange(value) {
        this.setState({apiKeyValidation: value});
    }

    generateKey() {
        if(this.state.apiKeyValidation){
            client.post('/' + this.props.match.params.db + '/api/profile/settings/generate-key', {validation: this.state.apiKeyValidation})
                .then(response => response.data)
                .then(response => {
                    this.formRef.current.setFieldsValue({ apiKey: response });
                    showAToast(plainTranslate(this.props.settings.locale, 'Api key generated successfully!!'), 'succSettings', 'success');
                });
        }else{
            showAToast(plainTranslate(this.props.settings.locale, 'Select api key validation!'), 'errSettings', 'error');
        }
    }

    render() {
      
        return (
            <React.Fragment>
                <div className="row m-bot15">
                    <div className="col-lg-12">
                        <Form 
                        name="userGeneralSettings"
                        layout="vertical"
                        className="company-settings-form" 
                        onFinish={this.handleSubmit}  
                        ref={this.formRef}>
                            <div className="col-lg-12">
                                <div className="col-lg-3">
                                    <Form.Item 
                                    name="ui_language"
                                    label="User interface language">                                       
                                            <Select onChange={this.onLangChange}>
                                                 {this.props.langOptions.map(lang => {
                                                    return <Option value={lang.value}>{lang.label}</Option>
                                                })}
                                            </Select>                                      
                                    </Form.Item>
                                </div>
                                <div className="col-lg-5">
                                    <Form.Item 
                                    name="apiKey"
                                    label="API Key">
                                        <Input disabled={true} />
                                    </Form.Item>
                                </div>
                                <div className="col-lg-2">
                                    <Form.Item name="apiKeyValidation" label="API Key Validation">
                                        <Select onChange={this.onSelectChange}>
                                            <Option value={null}></Option>
                                            <Option value={31556952}>{plainTranslate(this.props.settings.locale, '1 Year')}</Option>
                                            <Option value={63113904}>{plainTranslate(this.props.settings.locale, '2 Years')}</Option>
                                            <Option value={94670856}>{plainTranslate(this.props.settings.locale, '3 Years')}</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-lg-2">
                                    <input onClick={this.generateKey} style={{ marginTop: 25 }} className="btn btn-danger" type="button" value={plainTranslate(this.props.settings.locale, 'Regenerate')} />
                                </div>
                                <div className="col-lg-12">
                                    <Form.Item label="Change Theme">
                                        <ColorPicker db={this.props.match.params.db} />
                                    </Form.Item>
                                </div>
                            </div>
                            <Button className="button-primary" 
                            onClick={this.submitForm}
                            id="user_profile_save" 
                            type="primary" htmlType="submit">
                                {plainTranslate(this.props.settings.locale, 'Save')}
                            </Button>
                        </Form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

//const UserGeneralSettingsForm = Form.create({ name: 'UserGeneralSettingsForm' })(UserGeneralSettings);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserGeneralSettings));