import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PublicRoute = ({ component: Component, auth, settings,  ...rest }) => {
  
 return <Route
    {...rest}
    render={(props) => {
      return <Component  {...rest} {...props} />
    }}
  />
  };

const mapStateToProps = state => ({
  auth: state.auth,
  settings: state.settings
});

export default connect(mapStateToProps)(PublicRoute);
