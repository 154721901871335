import React from 'react';
import {connect} from 'react-redux';
import RichTextEditor from 'react-rte';

class Rte extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.input.value === '' ? RichTextEditor.createEmptyValue() : RichTextEditor.createValueFromString(this.props.input.value, 'html'),
        }
        this.onChange = this.onChange.bind(this);
    }
  
    onChange(value) {
        this.setState({ value });
        this.props.input.onChange(value.toString('html'));
    };

    render() {
        return <RichTextEditor
            value={this.state.value}
            onChange={this.onChange}
        />
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Rte);
