import React, { useEffect, useState, useRef } from 'react';
import Translate, { plainTranslate } from "../../../common/translate";
import { Card, Form, Input, Row, Col, Button } from 'antd';
import client from '../../../../actions/client'
import showAToast from '../../../common/showAToast'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
//import { Editor } from '@tinymce/tinymce-react';
import Popup from "../../../common/popup";
import { onAccountFetchData, onFieldSave } from '../../../../actions/actions';
import {connect} from 'react-redux';
import RteEditor from '../../../common/rteEditor';

const Notes = props => {
    const ref = useRef(null);
    const [description, setDescription] = useState(props.description);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);


    useEffect(() => {      
        if ( props.description) {
            setDescription( props.description);
        }
        
    }, []);

    const onFinish = (update) => {
        if(update){
            props.onAccountFetchData(props.db, props.accountId, true);
        }
        openEdit();
    }

   const  onChange = (value, editor) => { 
       setDescription(value)
       
    }
    
    const openEdit = () => {        
        setOpen(!open);
    }

    return (
            <Card title={plainTranslate(props.locale, 'Note')}
              size="small"
              extra={description ? <EditOutlined onClick={openEdit}/> : <PlusCircleOutlined onClick={openEdit}/>}
              style={{marginTop: 10}}>
                {props.description ? 
                  <div  dangerouslySetInnerHTML={{ __html: props.description }} ></div>
                 :
                    <div className="div-inline m-left15">
                        <span className="text-italic" style={{color: '#a0a0a0'}}>
                            {plainTranslate(props.locale, 'No notes added.')}</span>
                    </div>
                }
                  
              {open && <Popup
              locale={props.locale}
              onClose={openEdit}>
            <section className="newPanel">
               <div className="panel-body">
               <RteEditor 
                    value={description} 
                    id={'description'}
                    showButtons={true} 
                    updateRoute={props.updateRoute}
                    handleClose={onFinish}
                    additionalData={props.additionalData}
                />
</div>
</section>    
                  </Popup>}
           </Card>
    );
}

const mapStateToProps = state => ({    
});
const mapDispatchToProps = dispatch => ({
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData)),
    onAccountFetchData: (db, id) => dispatch(onAccountFetchData(db, id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notes);