import React, { Component } from 'react';
import { plainTranslate } from '../../../common/translate';
import { connect } from 'react-redux';
import GeneralInfo from './generalInfo';
import Notes from './notes';
import { onAccountFetchData, onEditOptionsLoad, onTabsLoad, onFieldSave } from '../../../../actions/actions';
import BillingDataEdit from './billing-data';
import Statistics from './statistics';
import Tabs from './tabs';
import './profile.css';
import { Link } from 'react-router-dom';
import Addresses from './addresses';
import OtherInformation from './other-information';
import FormLoader from '../../../forms/form-loader';
import AccountLinks from './accountLinks';
import showAToast from '../../../common/showAToast';
import Codes from './codes'
import InlineSelect2Edit from './inline-select2-edit';
import { Card, Select } from 'antd';
import Groups from './groups';
import { ExportOutlined } from '@ant-design/icons';
import AccountLocationDataEdit from '../profile/account-location-data';
import AccountAttributes from './accountAttributes';
import client from '../../../../actions/client';


class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeStage: null,
        };
        this.updateRoute = '/' + this.props.match.params.db + '/api/accounts/update-field';
        this.onBreadcrumbClick = this.onBreadcrumbClick.bind(this);
        this.setTabsLoading = this.setTabsLoading.bind(this);
        this.paymentMethodsChange = this.paymentMethodsChange.bind(this);
    }

    componentDidMount() {
        this.setState({ tabsLoading: true });
        this.props.onAccountFetchData(this.props.match.params.db, this.props.match.params.id);
        this.props.onEditOptionsLoad('/' + this.props.match.params.db + '/api/accounts/edit-options/' + this.props.match.params.id);
        this.props.onTabsLoad('/' + this.props.match.params.db + '/api/accounts/tabs/' + this.props.match.params.id, 1, true);
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.accountProfileInformation !== this.props.accountProfileInformation) {
            this.setState({ activeStage: this.props.accountProfileInformation.stage });
        }
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({ tabsLoading: true });
            this.props.onAccountFetchData(this.props.match.params.db, this.props.match.params.id);
            this.props.onEditOptionsLoad('/' + this.props.match.params.db + '/api/accounts/edit-options/' + this.props.match.params.id);
            this.props.onTabsLoad('/' + this.props.match.params.db + '/api/accounts/tabs/' + this.props.match.params.id, 1, true);
        }
        if (prevProps.accountProfileInformation.tables !== this.props.accountProfileInformation.tables) {
            this.setState({ tabsLoading: false });
        }
    }


    onBreadcrumbClick(stage) {
        this.setState({ activeStage: stage }, function () {
            this.props.onFieldSave(this.updateRoute, 'stage', stage, { accountId: this.props.accountProfileInformation.id }, null).then(() => {
                showAToast('Data saved successfully!', 'baseSuccess', 'success');
                if (this.props.gridPopup) {
                    this.props.updateGridData();
                }
            });
        });

    }

    setTabsLoading() {
        this.setState({ tabsLoading: true });
    }

    paymentMethodsChange(value, isDefault) {
        let url = `${this.props.match.params.db}/api/account/${this.props.accountProfileInformation.id}/change/payment/methods`;
        if (isDefault == true) {
            url =`${this.props.match.params.db}/api/account/${this.props.accountProfileInformation.id}/change/payment/methods?isDefault=true`
        }

        let data = {body: value}
        client
            .post(url, data)
            .then(response => response.data)
            .then(() => {
                showAToast('Data saved successfully!', 'baseSuccess', 'success');
            })
    }

    render() {
        if (this.props.accountProfileInformation.loading) {
            return <FormLoader />
        }

        const account = this.props.accountProfileInformation;
        const id = account.listObj ? account.listObj.id : null;
        const smsTemplates = this.props.accountProfileInformation.smsTemplates;
        const paymentMethodsOptions = this.props.accountProfileInformation.paymentMethodsOptions;
        const accountPaymentMethods = this.props.accountProfileInformation.accountPaymentMethods;
        const defaultPaymentMethod = this.props.accountProfileInformation.defaultPaymentMethod;
        const emailTemplates = this.props.accountProfileInformation.emailTemplates;
        const smsSettings = this.props.accountProfileInformation.smsSettings;
        const emailSettings = this.props.accountProfileInformation.emailSettings;
        const emailActivityType = this.props.accountProfileInformation.emailActivityType;

        return (
            <div className="crm-profile">
                <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                    <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb">
                        {!this.props.gridPopup &&
                            <Link to={"/" + this.props.match.params.db + "/accounts-v2/" + id}>
                                <i className="ion-ios-arrow-back"></i>{plainTranslate(this.props.settings.locale, account.listObj ? account.listObj.name : null)}
                            </Link>
                        }
                        {this.props.gridPopup && <a style={{ marginRight: 20, float: "right" }} href={"/" + this.props.match.params.db + '/account/' + this.props.match.params.id + '/profile'} target="_blank">{plainTranslate(this.props.settings.locale, 'Open in new tab')}<ExportOutlined style={{ fontSize: 15, marginLeft: 5 }} /></a>}
                    </div>
                    {
                        account && account.stages && account.stages.length > 0 &&
                        <div className="status-container col-md-12 col-xs-12">
                            <div className="status-breadcrumb">
                                {
                                    account.stages.map((stage, key) => {
                                        return <a
                                            className={this.state.activeStage == stage.value && stage.forbiddenAccess ? 'active forbidden' :
                                                this.state.activeStage == stage.value ? 'active' :
                                                    stage.forbiddenAccess ? 'forbidden' : ''}
                                            href="#" key={key}
                                            onClick={() => this.onBreadcrumbClick(stage.value)}>
                                            <span className="breadcrumb__inner">
                                                <span className="breadcrumb__desc">{stage.label}</span>
                                            </span>
                                        </a>
                                    })
                                }
                            </div>
                        </div>
                    }
                    <div className="col-lg-5 col-md-5 col-xs-12 no-gutter">
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information" style={{ zIndex: 9 }}>
                            <GeneralInfo
                                account={account}
                                listId={id}
                                emailActivityType={emailActivityType}
                                smsTemplates={smsTemplates}
                                emailTemplates={emailTemplates}
                                smsSettings={smsSettings}
                                emailSettings={emailSettings}
                                listFields={account.listFields}
                                templates={account.templates}
                                editTemplates={account.editTemplates}
                                updateGrid={this.props.updateGridData}
                                update={(db, id) => this.props.onAccountFetchData(db, id)}
                                gridPopup={this.props.gridPopup}
                            />
                            {/* {account && account.tabs && account.tabs.includes('contacts') &&
                                <Contacts
                                    contacts={account.contacts}
                                    updateRoute={this.updateRoute}
                                    accountId={account.id}
                                    website={account.website}
                                    listFields={account.listFields}
                                    db={this.props.match.params.db}
                                />
                            } */}
                            {(account.listFields.find(field => field.id == 'accountPaymentMethods') ? true : false) &&
                                <Card title={plainTranslate(this.props.settings.locale, 'Payment methods')}
                                    size="small"
                                    style={{ marginTop: 10 }}>
                                    <div className="group" style={{marginBottom: '10px'}}>
                                        <label style={{marginRight: '5px'}}>{plainTranslate(this.props.settings.locale, 'Default payment method')}:</label>
                                        <Select
                                            size='middle'
                                            placeholder="Please select default payment method"
                                            defaultValue={defaultPaymentMethod}
                                            onChange={(val) => this.paymentMethodsChange(val, true)}
                                            style={{
                                                width: '65%',
                                            }}
                                            options={paymentMethodsOptions}
                                        />
                                    </div>
                                    <div className="group" style={{marginBottom: '10px'}}>
                                        <label style={{marginRight: '57px'}}>{plainTranslate(this.props.settings.locale, 'Payment methods')}:</label>
                                        <Select
                                            mode="multiple"
                                            size='middle'
                                            placeholder="Please select"
                                            defaultValue={accountPaymentMethods}
                                            onChange={this.paymentMethodsChange}
                                            style={{
                                                width: '65%',
                                            }}
                                            options={paymentMethodsOptions}
                                        />
                                    </div>
                                </Card>
                            }
                            {(account.listFields.find(field => field.id == 'group') ? true : false) &&
                                <Groups
                                    db={this.props.match.params.db}
                                    account={account}
                                    locale={this.props.settings.locale}
                                    updateRoute={this.updateRoute}
                                />}
                            {(account.additionalFields || account.codes) &&
                                <Codes
                                    db={this.props.match.params.db}
                                    account={account}
                                    locale={this.props.settings.locale}
                                />}
                            {account && account.tabs && account.tabs.includes('tag') &&
                                <Card
                                    style={{ marginTop: 10 }}
                                    size="small"
                                    title={<span>{plainTranslate(this.props.settings.locale, 'Tags')}</span>}>
                                    <InlineSelect2Edit
                                        updateRoute={this.updateRoute}
                                        additionalData={{ accountId: account.id }}
                                        id={'tags'}
                                        isMulti={true}
                                        options={account.tagsOptions}
                                        value={account.tags}
                                        gridPopup={this.props.gridPopup}
                                        updateGrid={this.props.updateGridData}
                                    />

                                </Card>
                            }
                            {account && account.tabs && account.tabs.includes('segments') &&
                                <Card
                                    style={{ marginTop: 10 }}
                                    size="small"
                                    title={<span>{plainTranslate(this.props.settings.locale, 'Segments')}</span>}>
                                    <InlineSelect2Edit
                                        updateRoute={this.updateRoute}
                                        additionalData={{ accountId: account.id }}
                                        id={'segments'}
                                        isMulti={true}
                                        notCreatable={true}
                                        options={account.segmentsOptions}
                                        value={account.segments}
                                        gridPopup={this.props.gridPopup}
                                        updateGrid={this.props.updateGridData}
                                    />
                                </Card>
                            }
                            {(account.listFields.find(field => field.id == 'owner') ? true : false) &&
                                <Card
                                    style={{ marginTop: 10 }}
                                    size="small"
                                    title={<span>{plainTranslate(this.props.settings.locale, 'Owner')}</span>}>
                                    <InlineSelect2Edit
                                        updateRoute={this.updateRoute}
                                        additionalData={{ accountId: account.id }}
                                        id={'owner'}
                                        async={'/' + this.props.match.params.db + '/api/find/users/search'}
                                        isMulti={false}
                                        options={account.usersOptions}
                                        value={account.owner} />
                                </Card>}
                            <div className="account-profile-other-information">
                                {account && account.tabs && account.tabs.includes('notes') &&
                                    <Notes
                                        description={account.description}
                                        accountId={account.id}
                                        locale={this.props.settings.locale}
                                        db={this.props.match.params.db}
                                        updateRoute={this.updateRoute}
                                        additionalData={{ accountId: account.id }}
                                    />
                                }
                                {account && account.tabs && account.tabs.includes('account_contacts') &&
                                    <AccountLinks id={'accountLinks'}
                                        account={account}
                                        value={account.accountLinks}
                                        updateRoute={this.updateRoute}
                                        lists={this.props.settings.lists}
                                        additionalData={{ accountId: account.id }} />
                                }
                                {account && account.tabs && account.tabs.includes('billing_data') &&
                                    <BillingDataEdit id={'billingData'}
                                        value={account.billingData}
                                        updateRoute={this.updateRoute}
                                        accountId={account.id}
                                        db={this.props.match.params.db}
                                        additionalData={{ accountId: account.id }} />
                                }
                                {account && account.tabs && account.tabs.includes('addresses') &&
                                    <Addresses id={'addresses'}
                                        account={account}
                                        value={account.addresses}
                                        updateRoute={this.updateRoute}
                                        accountId={account.id}
                                        db={this.props.match.params.db}
                                        additionalData={{ accountId: account.id }} />
                                }
                                {account && account.tabs && account.tabs.includes('account_location_data') &&
                                    <AccountLocationDataEdit id={'accountLocationData'}
                                        value={account.accountLocationData}
                                        updateRoute={this.updateRoute}
                                        accountId={account.id}
                                        db={this.props.match.params.db}
                                        additionalData={{ accountId: account.id }} />
                                }
                                {account && account.tabs && account.tabs.includes('attributes') &&
                                    <AccountAttributes id={'attributes'}
                                        value={account.attributes}
                                        updateRoute={this.updateRoute}
                                        accountId={account.id}
                                        db={this.props.match.params.db}
                                        additionalData={{ accountId: account.id }} />
                                }
                                {account && account.tabs && account.tabs.includes('more') &&
                                    <OtherInformation account={account}
                                        updateRoute={this.updateRoute}
                                        additionalData={{ accountId: account.id }}
                                        db={this.props.match.params.db}
                                        locale={this.props.settings.locale} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-8 col-xs-12 no-gutter-m no-gutter-r">
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile-statistics">
                            <Statistics id={'rating'}
                                account={account}
                                updateRoute={this.updateRoute}
                                additionalData={{ accountId: account.id }} />
                        </div>
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile-tabs" style={{ paddingLeft: 10 }}>
                            <Tabs consent={this.props.settings.consent}
                                tabs={account.tabs}
                                tables={account.tables}
                                total={account.total}
                                hasView={account.hasView}
                                totalConsents={account.totalConsents}
                                totalAccess={account.totalAccess}
                                account={account}
                                history={this.props.history}
                                updateRoute={this.updateRoute}
                                additionalData={{ accountId: account.id }}
                                loading={this.state.tabsLoading}
                                setLoading={this.setTabsLoading} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    accountProfileInformation: state.accountProfileInformation
});
const mapDispatchToProps = dispatch => ({
    onAccountFetchData: (db, id) => dispatch(onAccountFetchData(db, id)),
    onEditOptionsLoad: (route) => dispatch(onEditOptionsLoad(route)),
    onTabsLoad: (route, page, requiredTotal) => dispatch(onTabsLoad(route, page, requiredTotal)),
    onFieldSave: (route, id, value, additionalData, additionalId) => dispatch(onFieldSave(route, id, value, additionalData, additionalId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
