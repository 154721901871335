import React from 'react';
import Truncate from 'react-truncate';
import {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';

class ReadMore extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {
            expanded: false,
            truncated: false
        };

        this.handleTruncate = this.handleTruncate.bind(this);
        this.toggleLines = this.toggleLines.bind(this);
    }

    handleTruncate(truncated) {
        if (this.state.truncated !== truncated) {
            this.setState({
                truncated
            });
        }
    }

    toggleLines(event) {
        event.preventDefault();

        this.setState({
            expanded: !this.state.expanded
        });
    }

    render() {
        const {
            children,
            more,
            less,
            lines
        } = this.props;

        const {
            expanded,
            truncated
        } = this.state;

        return (
            <div>
                <Truncate
                    lines={!expanded && lines}
                    ellipsis={(
                        <span className="read-more"><a href='javascript:void(0)' onClick={this.toggleLines}>{plainTranslate(this.props.settings.locale, 'Expand')}</a></span>
                    )}
                    onTruncate={this.handleTruncate}>
                    <div className="p_wrap">   {children}</div>
                </Truncate>
                {!truncated && expanded && (
                    <span className="read-more"> <a href='javascript:void(0)' onClick={this.toggleLines}>{plainTranslate(this.props.settings.locale, 'Collapse')}</a></span>
                )}
            </div>
        );
    }
}

ReadMore.defaultProps = {
    lines: 2,
    more: 'Expand',
    less: 'Collapse'
};

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReadMore);
    