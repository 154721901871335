export default function getPosFormRoutes(db) {
    return [
        {
            title: ['Transactions', '›', 'New'],
            path: '/:db/pos/transactions/new',
            saveRoute: '/api/sales',
            formRoute: '/api/transactions/',
            isNew: true,
            isPos: true,
            customForm: 'pos',
        },
        {
            title: ['Transactions', '›', 'Edit'],
            path: '/:db/pos/transactions/:id/edit',
            saveRoute: '/api/sales/',
            formRoute: '/api/transactions/',
            isNew: true,
            isPos: true,
            customForm: 'pos',
        },

        {
            title: ['Make payment', '›' ,'New'],
            path: '/:db/pos/sale/:id/make-payment/new',
            saveRoute: '/api/make-payments',
            formRoute: '/api/pos/make-payments/',
            customForm: 'payment',
            isNew: true,
            checkDevices: '/api/devices-search',
            printReceipt: true,
            isPos: true,
        },
        {
            title: ['Payments Report'],
            path: '/:db/pos/report/payments/filters',
            saveRoute: '/api/reports/payment/dataSource',
            formRoute: '/api/reports/payment/filters/',
            isNew: true,
            applyButton: true,
            isPos: true,
            isReport: true,
        },
        {
            title: ['Stock on hand'],
            path: '/:db/pos/report/onhand/filters',
            saveRoute: '/api/reports/onhand/dataSource',
            formRoute: '/api/reports/onhand/filters/',
            isNew: true,
            applyButton: true,
            isReport: true,
            isPos: true,
        }
    ];
}
