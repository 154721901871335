import React from 'react';
import {connect} from 'react-redux';
import { Select } from 'antd';
import 'antd/lib/select/style/index.css';
import {plainTranslate} from "../../common/translate";
import SlideModal from '../../common/SlideModal';
import SimpleForm from "../../redux-forms/form";
import { withRouter } from 'react-router-dom'
import client from '../../../actions/client'

const { Option } = Select;

class UpdatedSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options: [],
            batchForm: false,
            value: ''
        }
        this.handleClick = this.handleClick.bind(this);
        this.addItem = this.addItem.bind(this);
    }
    
    componentDidMount() {
        this.setState({options : this.props.field.options});
    }
    
    handleClick(url, dependency, lineDependency) {
        let addVal = {};
        let jsonData = [];
        lineDependency.map(field => {
            let numb = (this.props.field.id).match(/^(\D*)(\d+)/)[2];
            if ('lines' in this.props.formData.form.initializeFromState2.values) {
                addVal[field] = this.props.formData.form.initializeFromState2.values.lines[numb][field];
            }
        })
        dependency.map(field => {
           addVal[field] = this.props.formData.form.initializeFromState2.values[field]; 
        })
        jsonData.push(JSON.stringify(addVal));

        let fields = this.props.field.selfUpdate.fields;
        client.post(url,addVal)
                .then(response => response.data)
                .then(response => {
                    fields.map(field => {
                        if (response[field]) {
                            let options = response[field];
                            options.push({'value': null, 'label': 'Without batch'});
                            this.setState({options: options});
                        }
                    })
                    if ('item' in response) {
                        this.setState({item: response['item']})
                    } else {
                        this.setState({item: false})
                    }
                })
    }
    
    handleChange(val, name, action, fieldProps) {
        action(name, val);
        if ('changedFields' in fieldProps) {
            let addVal = {};
            let jsonData = [];
            fieldProps.changedFields.lineDependency.map(field => {
                let numb = (name).match(/^(\D*)(\d+)/)[2];
                addVal[field] = this.props.formData.form.initializeFromState2.values.lines[numb][field];
            })
            fieldProps.changedFields.dependency.map(field => {
                addVal[field] = this.props.formData.form.initializeFromState2.values[field];
            })
            addVal['body'] = val;

            jsonData.push(JSON.stringify(addVal));

            let fields = this.props.field.changedFields.fields;
            client.post(fieldProps.changedFields.url, addVal)
                    .then(response => response.data)
                    .then(response => {
                        fields.map(field => {
                            let ind = (name).indexOf('.'+this.props.field.id);
                            let line = (name).substring(0, ind);
                            action(line + field, response[field]);
                        })
                    })
        }
    }
    
    addItem() {
        this.setState({batchForm: true});
    }

    render() {
        return (
                <span className="form-input-field">
                    <Select 
                            value={this.state.value} 
                            style={{width: '100%'}} 
                            notFoundContent = {plainTranslate(this.props.settings.locale, 'No options')}
                            onChange={(value) => this.handleChange(value, this.props.field.id, this.props.change, this.props.field)}
                            onMouseEnter={() => this.handleClick(this.props.field.selfUpdate.url, this.props.field.selfUpdate.dependency, this.props.field.selfUpdate.lineDependency)} >                                
                                {this.state.options && this.state.options.map((option) =>
                                    <Option value={option.value} disabled = {option.disabled} key={Math.random().toString(36).substring(7)}>{option.label}</Option>
                                    )}
                                {this.props.field.id === 'batch' && <Option key={Math.random().toString(36).substring(7)} onClick={this.addItem}>+{plainTranslate(this.props.settings.locale, 'Add')}</Option>}
                    </Select>
                    {
                    this.state.batchForm &&
                    <SlideModal onClose={() => this.setState({batchForm: false})} title={plainTranslate(this.props.settings.locale, 'Batch')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        saveRoute={this.props.settings.db + '/api/batches'}
                                        formRoute={this.props.settings.db + '/api/batches/'}
                                        match={{params: {id: null}}}
                                        matchAdditionalId={{params: {id: this.state.item}}}
                                        onSuccessfulSave={() => this.setState({batchForm: false})}
                                        isNew={true}
                                        popup={true}
                                        onCancel={() => this.setState({batchForm: false})}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                </span>
                 )
                }
            }

    const mapStateToProps = state => ({
        settings: state.settings,
        formData: state,
        });

    const mapDispatchToProps = dispatch => ({});

    export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdatedSelect));
