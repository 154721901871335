import React, {Component} from 'react';

class SingleLinkRenderer extends Component {

    constructor(props) {
        super(props);

        this.generateRoute = this.generateRoute.bind(this);
        this.hasPermissions = this.hasPermissions.bind(this);
    }

    generateRoute(link) {
        if (!('params' in link)) {
            return link.url;
        }

        var url = link.url;

        link.params.map(param => {
            if (param.source in this.props || param.source in this.props.data) {
                if('inProps' in param){
                    url = url.replace('{' + param.target + '}', this.props.data[param.source]);
                }
                else {
                    url = url.replace('{' + param.target + '}', this.props[param.source]);
                }
            }
        });

        return url;
    }

    hasPermissions() {
        let perm = true;
        if('checkPermissions' in this.props) {
            this.props.checkPermissions.forEach(item => {
                if(!(item in this.props.data) || !this.props.data[item])
                    perm = false;
            });
        }
        return perm;
    }

    render() {
        let access = this.hasPermissions();
        return (
            <span>
                {access && <a href={this.generateRoute(this.props.link)} target="_blank">{this.props.value}</a>}
                {!access && this.props.value}
          </span>
        );
    }
}

export default SingleLinkRenderer;
