import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../common/translate';
import { fetchLinks, fetchCategories, onUpdateLinkField } from "../../actions/actions";
import Tabs from '../common/Tabs';
import SimpleForm from "./../forms/form";
import SlideModal from '../common/SlideModal';
import ModalConfirm from '../common/ModalConfirm';
import client from '../../actions/client';
import showAToast from '../common/showAToast';

class LinksList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            link: {
                id : '',
                name: '',
                url: '',
                status: '',
                category: '',
                inNewTab: '',
                description: '',
                favicon: ''
            },
            confirm: false,
            deleteLink: false
        };

        this.closeForm = this.closeForm.bind(this);
        this.handleEscClick = this.handleEscClick.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.saveField = this.saveField.bind(this);
        this.openConfirm = this.openConfirm.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleEscClick);
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
        this.props.loadCategories(this.props.match.params.db);
        this.props.loadLinks(this.props.match.params.db);
    }

    saveField(data) {
        let remove = false;
        this.setState({
            isOpen: false,
        });
        this.props.updateLinkField(data.id, data, remove);
    }

    changeValue(data) {
        this.setState({
            value: data
        }, () => this.saveField(data));
    }

    handleDelete() {
        let remove = true;
        client.post('/' + this.props.match.params.db + '/api/delete/link', {
            'id' : this.state.deleteLink,
        })
            .then(response => response.data)
            .then(response => {
                this.props.updateLinkField(response.id, response, remove);
                if ('errors' in response) {
                    showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
                }
                else {
                    showAToast(plainTranslate(this.props.settings.locale, 'Data deleted successfully!'), 'succSettings', 'success');
                }
            })
            .then(this.closeForm())
    }

     handleEscClick(e) {
        if (e.keyCode === 27) {
            this.setState({
                isOpen: false,
                confirm:false
            });
        }
    }

    closeForm() {
        this.setState({
            isOpen: false,
            confirm: false,
            deleteLink: false
        });
    }

    openConfirm(id) {
        this.setState({
            confirm: true,
            deleteLink: id
        });
    }

    truncate(s) {
    if (s.length > 14) {
        let str = s.substring(0, 11);
         return str + '...';
     }
      else {
         return s;
     }
    }

    render() {
        if (this.props.links.error) {
            return <div><Translate locale={this.props.settings.locale} value="Error "/> {this.props.links.error.message}</div>;
        }

        if (this.props.links.loading) {
            return <div><Translate locale={this.props.settings.locale} value="Loading..."/></div>;
        }
        return (
        <div className="links-list">
        {this.state.confirm &&
                <ModalConfirm onClose={this.closeForm}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <div className="text-center">
                                    <h3>
                                        <Translate locale={this.props.settings.locale} value="Are you sure?"/>
                                    </h3>
                                    <button className="button-outlines green text-center"
                                            onClick={this.closeForm}>
                                        <Translate locale={this.props.settings.locale} value="No"/>
                                    </button>
                                    <button className="button-primary-edited text-center btn-ok"
                                            onClick={this.handleDelete}>
                                        <Translate locale={this.props.settings.locale} value="Yes"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </ModalConfirm>
            }
        <a className="new-bookmark button-primary pull-right" onClick={() => this.setState({isOpen: true, link: {
                id: null,
                name: '',
                url: '',
                category: '',
                status: '2',
                description: '',
                favicon: '',
                inNewTab: true
            },})}
                title={plainTranslate(this.props.settings.locale, 'New')}> <i className="ion-plus-round"></i>
                <span>{plainTranslate(this.props.settings.locale, 'New')}</span>
        </a>
            {this.props.links && this.props.links.categories && this.props.links.categories.length > 0
                 ? (<Tabs>
                  {this.props.links && this.props.links.categories && this.props.links.categories.length > 0 && this.props.links.categories.map((cat, key) =>
                       <div key={key} className = "col-md-12" label={cat}>
                                {this.props.links && this.props.links.links && this.props.links.links.length > 0 && this.props.links.links.map((link, key) =>
                                { return (link.category === cat) ?
                                    <div className="back-border-div" key={key}>
                                        <div className="tab-item text-center" title={link.url}>
                                            <a href={link.url} target={link.inNewTab ? "_blank" : ''}>
                                                <div>
                                                    {link.linkImage ?
                                                    <img className="tab-item-image" src={link.linkImage}/>
                                                    :
                                                    <img className="tab-item-favicon-image" src={link.favicon}/>
                                                    }
                                                </div>
                                                <p className="tab-item-name">{this.truncate(link.name)}</p>
                                            </a>
                                            {!this.props.frontend &&
                                            <React.Fragment>
                                                <button className="tab-item-edit-menu" title="Edit link"
                                                        onClick={() => this.setState({isOpen: true, link: link})}>
                                                    <i className="ion-android-more-vertical" title="Edit link"></i>
                                                </button>
                                                <button className="tab-item-delete-menu" title="Delete link"
                                                        onClick={() => this.openConfirm(link.id)}>
                                                    <i className="icon ion-close" title="Delete link"></i>
                                                </button>
                                            </React.Fragment>
                                            }
                                            {this.props.frontend && link.status==2 &&
                                            <React.Fragment>
                                                <button className="tab-item-edit-menu" title="Edit link"
                                                        onClick={() => this.setState({isOpen: true, link: link})}>
                                                    <i className="ion-android-more-vertical" title="Edit link"></i>
                                                </button>
                                                 <button className="tab-item-delete-menu" title="Delete link"
                                                        onClick={() => this.openConfirm(link.id)}>
                                                    <i className="fa fa-close" title="Delete link"></i>
                                                </button>
                                            </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                    : ''
                                }
                     )}
                         </div>
                    )}
                    </Tabs>) :
                    (<div>
                        No links added to database
                    </div>)
            }
            {this.state.isOpen &&
                <SlideModal onClose={() => this.setState({isOpen: false})} title={plainTranslate(this.props.settings.locale, 'Bookmark')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <SimpleForm
                                    formRoute={this.props.match.params.db + '/api/links/'}
                                    saveRoute={this.state.link.id ? this.props.match.params.db + '/api/links/' : this.props.match.params.db + '/api/links'}
                                    popup={true}
                                    isNew={true}
                                    updateParent={(data) => this.changeValue(data)}
                                    onCancel={() => this.setState({isOpen: false})}
                                    match={{params: {id: this.state.link.id ? this.state.link.id : 0}}}
                                    />
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }
            </div>
            )
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    links: state.links,
    categories: state.categories
});

const mapDispatchToProps = dispatch => ({
    updateLinkField: (id, key, value) =>
            dispatch(onUpdateLinkField(id, key, value)),
    loadLinks: (db) =>
            dispatch(fetchLinks(db)),
    loadCategories: (db) =>
            dispatch(fetchCategories(db)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinksList);
