import { toast } from 'react-toastify'

 const showAToast = (str, id, type) => { 
    toast.dismiss();
     if(type === 'default'){
         toast(str, {
                        position: "bottom-center",
                        toastId: id,
                        autoClose: 5000,
                        style: {bordeRadius: '10px 10px 0px 0px'},
                        toastClassName: 'Toastify'})
       
     }else{
        toast[type](str, {
                    toastId: id,
                    position: "bottom-center",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    toastClassName: 'Toastify',
                    style: {bordeRadius: '10px 10px 0px 0px'},
                    className: 'black-background',
                    bodyClassName: "grow-font-size",
                    newestOnTop: true
                    });  
                }
    }
    
export default  showAToast;   

