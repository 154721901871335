import React, { Component } from 'react';
import { render } from "react-dom";
import Translate, { plainTranslate } from '../../common/translate';
import { connect } from 'react-redux';
import SettingsMenu from '../../sidebar/settings-menu';
import client from '../../../actions/client';
import { fetchSettings} from "../../../actions/actions";
import showAToast from '../../common/showAToast';

class MaintenanceSettingsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMessage: false,
        };
        this.executeProcedure = this.executeProcedure.bind(this);
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/maintenance/settings/maintenance-settings', 'maintenanceSettings');
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

    executeProcedure(procedure) {
        client.post(this.props.match.params.db + '/api/maintenance/settings/execute/procedure', {
            'procedure': procedure
        })
            .then(response => response.data)
            .then(response => {
                showAToast(plainTranslate(this.props.settings.locale, 'Procedure is succesfully executed'), 'succSettings', 'success');
            }
            );
    }

    render() {
        return (
            <div>
                <SettingsMenu
                    location={this.props.location.pathname} db = {this.props.match.params.db}/>
                <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                    <div className="row m-bot15">
                        <div className="col-lg-12">
                            <section className="panel">
                                <div className="panel-body">
                                    <div className="settings">
                                        {this.props.settings && this.props.settings.maintenanceSettings && this.props.settings.maintenanceSettings.maintenance && this.props.settings.maintenanceSettings.maintenance.length &&
                                            this.props.settings.maintenanceSettings.maintenance.map((val, key) => {
                                                return <div>
                                                    <h4>{plainTranslate(this.props.settings.locale, val.text)}</h4>
                                                    <a type="button" className="button-outline grey pull-right" style={{ borderColor: 'grey', color: 'grey' }} onClick={() => this.executeProcedure(val.procedure)}>
                                                        {val.button}
                                                    </a>
                                                </div>
                                            })}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    getSettings: (path, type) => dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceSettingsPage);
