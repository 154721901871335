import React, {Component} from 'react';
import Translate, {plainTranslate} from '../common/translate';
import {connect} from 'react-redux';
import { onShowMessage, onHideMessage } from '../../actions/actions';

class Checkboxe extends Component {
    constructor(props) {
        super(props);
        this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
    }

    toggleCheckboxChange() {
        if (this.props.disabled) {
            this.props.onShowMessage(this.props.message, true);
                setTimeout(() => this.props.onHideMessage(), 5000);
        } else {
            this.props.handleCheckboxChange(this.props.name, this.props.isChecked);
        }
    }

    render() {
        const {name} = this.props.name;
        let before = plainTranslate(this.props.locale, 'Yes');
        let after = plainTranslate(this.props.locale, 'No');
        return (
            <React.Fragment>
            {this.props.index ?
            <div className="col-md-12 no-gutter">
            <h5 className="col-md-6 no-gutter" style={{fontWeight: 'bold'}}><Translate locale={this.props.locale} value={name}/></h5>
                <div before={before} after={after} className="col-md-6 no-gutter slideThree text-left">
                    <input
                        type="checkbox"
                        value={name}
                        checked={this.props.isChecked}
                        id={`check-${this.props.index}`}
                        onChange={this.toggleCheckboxChange}
                        name={<Translate locale={this.props.locale} value={name}/>}/>
                    <label htmlFor={`check-${this.props.index}`}></label>
                </div>
            </div>
                :
                <tr>
                    {name &&
                    <td>
                        <h5 style={{fontWeight: 'bold'}}><Translate locale={this.props.locale} value={name}/></h5>
                        <p>{plainTranslate(this.props.locale, this.props.name.text)}</p>
                    </td>
                    }
                    <td>
                        <div before={before} after={after} className="slideThree text-left">
                            <input
                                type="checkbox"
                                value={name}
                                checked={this.props.isChecked}
                                id={`check-${name}`}
                                onChange={this.toggleCheckboxChange}
                                name={<Translate locale={this.props.locale} value={name}/>}/>
                            <label htmlFor={`check-${name}`}></label>
                        </div>
                    </td>
                </tr>
            }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    onShowMessage: (text, type) => onShowMessage(text, type),
    onHideMessage: () => onHideMessage()
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkboxe); 
