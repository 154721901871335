import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import { Form, Input, Button } from 'antd';
import Image from '../image';


class UserProfile extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
              value: null
        };    
  }

  componentDidMount() {
  
  }
    
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.state.value == null) {
            this.setState({
                value: this.props.value
            }, function () {
                this.formRef.current.setFieldsValue(this.state.value)
            });
        }
    }

  handleSubmit = (values) => {  
         this.props.onSave(values, 'userInfo');       
  }
  submitForm = e => {
    e.preventDefault()
    this.formRef.current.submit()
  }

    render() {
      
        return (
                <React.Fragment>
                    <div className="row m-bot15">
                        <div className="col-lg-12">
                                <Form 
                                id="userProfile"                                
                                ref={this.formRef}
                                layout="vertical"
                                onFinish={this.handleSubmit} 
                                className="company-settings-form">
                                    <div className="col-lg-12"> 
                                        <br />
                                        <div className="col-lg-12">
                                            <Form.Item 
                                            name="email"
                                            label={plainTranslate(this.props.settings.locale, 'Email')}
                                            rules={[
                                                {
                                                  required: true,
                                                  message: plainTranslate(this.props.settings.locale,'Please input email!'),
                                                },
                                              ]}
                                            >                                           
                                            <Input placeholder={plainTranslate(this.props.settings.locale, 'Email')}/>
                                          
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-12">
                                            <Form.Item 
                                            name="firstName"
                                            label={plainTranslate(this.props.settings.locale, 'First name')}>                                               
                                        <Input placeholder={plainTranslate(this.props.settings.locale, 'First name')}/>                                            
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-12">
                                            <Form.Item 
                                            name="lastName"
                                            label={plainTranslate(this.props.settings.locale, 'Last name')}>                                               
                                                    <Input placeholder={plainTranslate(this.props.settings.locale, 'Last name')}/>
                                             </Form.Item>
                                        </div>
                                        <div className="col-lg-12">
                                            <Form.Item 
                                            name="avatar"
                                            label={plainTranslate(this.props.settings.locale, 'User logo')}>                                            
                                                    <Image 
                                                    formData={this.state.value} 
                                                    fieldId="avatar" 
                                                    form={this.formRef.current}/>                                            
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <Button 
                                    onClick={this.submitForm}
                                    form="userProfile" type="primary" htmlType="submit" className="button-primary" >
                                    {plainTranslate(this.props.settings.locale, 'Save')}
                                     </Button>
                                   
                                </Form>
                        </div>
                    </div>
                </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

//const UserProfileForm = Form.create({ name: 'UserProfileForm' })(UserProfile);
    
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);