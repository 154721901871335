import React from 'react';
import {connect} from 'react-redux';

class Number extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ''
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value !== undefined ? this.numberScale(this.props.value, this.props.scale) : ''
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value !== undefined ? this.props.value : ''
            }, () => this.props.onChange(this.props.id, this.props.value));

        }
    }
    
    numberScale(number, scale) {
        let float = parseFloat(number);
        float = float.toFixed(scale ? scale : 2);
        
        return float;
    }

    onChange(e) {
        if(e.target.value === '') {
            if(this.state.value.toString().length === 1 && this.state.value !== 0) {
                this.setState({value: 0});
                this.props.onChange(this.props.id, 0);
            }
            return;
        }

        let value = parseFloat(e.target.value);

        if (isNaN(value)) {
            return;
        }

        this.setState({
            value: value
        });

        this.props.onChange(this.props.id, value);
    }

    render() {
        let min = '';
        let max = '';
        const rules = this.props.rules;
        if (rules) {
            min = 'min' in rules ? rules.min : '';
            max = 'max' in rules ? rules.max : '';
        }

        return <span className="form-input-field">
                    <input type="number"
                           step="any"
                           className="form-control"
                           value={this.state.value}
                           onChange={this.onChange}
                           onBlur={() => this.props.loseFocus(this.props.id)}
                           min={min}
                           max={max}
                           disabled={this.props.disabled}
                           tabIndex={this.props.tabindex ? this.props.tabindex : ''}
                    />  
                </span>
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Number);
