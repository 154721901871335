import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Translate, {plainTranslate} from '../../../common/translate';

class DoubleCheckbox extends Component {
    constructor(props) {
        super(props);
        this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
        this.toggleSecondCheckboxChange = this.toggleSecondCheckboxChange.bind(this);
    }

    toggleCheckboxChange() {
        this.props.handleCheckboxChange(this.props.label, this.props.id, this.props.pos);
    }
    
    toggleSecondCheckboxChange() {
        this.props.handleSecondCheckboxChange(this.props.label);
    }

    render() {
        const {label, name, type} = this.props;
        let before = plainTranslate(this.props.locale, 'Yes');
        let after = plainTranslate(this.props.locale, 'No');
        return (
            <tr>
                {name &&
                <td>
                    <h5><Translate locale={this.props.locale} value={name}/></h5>
                </td>
                }
                {type &&
                <td>
                    <h5><Translate locale={this.props.locale} value={type}/></h5>
                </td>
                }
                <td>
                    <div before={before} after={after} className="slideThree text-left">
                        <input
                            type="checkbox"
                            value={label}
                            checked={this.props.isChecked}
                            id={`check-${label}`}
                            onChange={this.toggleCheckboxChange}
                            name={<Translate locale={this.props.locale} value={name}/>}/>
                        <label htmlFor={`check-${label}`}></label>
                    </div>
                </td>
                <td>
                    <div before={before} after={after} className="slideThree text-left">
                        <input
                            type="checkbox"       
                            id={`check1-${label}`} 
                            value={label}
                            checked={this.props.isSecondChecked}
                            onChange={this.toggleSecondCheckboxChange}
                            name={<Translate locale={this.props.locale} value={name}/>}
                            />
                        <label htmlFor={`check1-${label}`}></label>
                    </div>
                </td>
            </tr>
        );
    }
}

DoubleCheckbox.propTypes = {
    isChecked: PropTypes.bool,
    handleCheckboxChange: PropTypes.func
};

DoubleCheckbox.defaultProps = {
    isChecked: false,
    handleCheckboxChange: () => {
    },
};

export default DoubleCheckbox;
