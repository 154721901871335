import React, { Component } from 'react';
import { render } from "react-dom";
import Translate, { plainTranslate } from '../common/translate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { onSidebarMount } from "../../actions/actions";
import { SETTINGS_CHANGE } from "../../constants/actionTypes";
import { Select } from 'antd';
import 'antd/lib/select/style/index.css';
import DoubleSelect from './doubleSelect';
import MyPosSettings from './myposSettings';
import CompanySettings from './companySettings';
import SingleCheckbox from './singleCheckbox.jsx';
import UserProfile from './user/userProfile.jsx';
import UserPassword from './user/userPassword.jsx';
import UserSMSSettings from './user/userSMSSettings.jsx';
import UserEmailSettings from './user/userEmailSettings.jsx';
import UserGeneralSettings from './user/userGeneralSettings.jsx';
import client from '../../actions/client';
import showAToast from '../common/showAToast';
import AccountNameSettings from './accountNameSettings';
import StorageSettings from './storageSettings';

const { Option } = Select;

class OpenSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            isEdit: false,
            isChanged: false
        };
        this.showSetting = this.showSetting.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.change = this.change.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openEditField = this.openEditField.bind(this);
        this.closeEditField = this.closeEditField.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside, false);
        this.setState({
            value: this.props.value ? this.props.value : []
        });
    }

    openEditField() {
        this.setState({
            isEdit: true
        });
    }

    closeEditField() {
        if (this.state.isChanged == true) {
          //  showAToast(plainTranslate(this.props.settings.locale, 'Settings saved successfully!'), 'succSettings', 'success');
        }

        this.setState({
            isEdit: false,
            isChanged: false
        });
    }

    handleClickOutside(event) {
        if (this.state.isEdit === true && event.target.id !== 'seqNo' && this.props.component !== 'select') {
            this.closeEditField();
        }
    }

    showSetting() {
        this.setState({
            show: !this.state.show
        });
    }

    change(val, id = null) {
        var message = false;
        const settings = this.state.value ? this.state.value : [];
        if (val.target) {
            settings[id ? id : this.props.setting] = val.target.value;
        } else {
            settings[id ? id : this.props.setting] = val;
        }
        
        this.setState({
            settings,
        }, function () {
            
            client.post(this.props.saveRoute, {
                [id ? id : this.props.setting]: settings[id ? id : this.props.setting],
            })
                .then(response => { 
                    if ('message' in response.data) {     
                        if (!message){
                            message = true;
                            showAToast(plainTranslate(this.props.settings.locale, response.data.message), 'succSettings', 'success');
                        }
                    }else{
                        showAToast(plainTranslate(this.props.settings.locale, response.data.message), 'succSettings', 'success');
                    }
                        if (id === 'userInfo') {
                            this.props.onSidebarMount(this.props.settings.sidebarRoute);
                        }
                    
                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.message) {
                        showAToast(plainTranslate(this.props.settings.locale, err.response.data.message), 'errSettings', 'error');
                    }else{
                        showAToast(plainTranslate(this.props.settings.locale, 'Error'), 'errSettings', 'error');
                    }
                })
        });

        if (id !== 'userPassword') {
            this.props.onChangeSettings(id ? id : this.props.setting, settings[id ? id : this.props.setting], this.props.type);


            this.setState({
                isChanged: true
            });

            if (val.target && val.target.type !== "text" || Array.isArray(val) || typeof val === 'object') {
                //if (!message){
                    //message = true;
                   // showAToast(plainTranslate(this.props.settings.locale, 'Settings saved successfully!'), 'succSettings', 'success');
                //}              
            }
        }


    }

    handleChange(value) {
        const settings = this.state.value ? this.state.value : [];
        settings[this.props.setting] = value;
        this.setState({
            settings,
        }, function () {
            client.post(this.props.saveRoute, {
                [this.props.setting]: settings[this.props.setting],
            })
            .then(res => {

            })
            .catch(err => {

            })
        });

        this.props.onChangeSettings(this.props.setting, settings[this.props.setting], this.props.type);

        this.setState({
            isChanged: true
        });
        showAToast(plainTranslate(this.props.settings.locale, 'Settings saved successfully!'), 'succSettings', 'success');
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-10 setting-item">
                    <label>
                        <Translate locale={this.props.locale} value={this.props.label} />
                    </label>
                    <p>
                        <Translate locale={this.props.locale} value={this.props.description} />
                    </p>
                </div>
                <div className="col-md-2">
                    <div className="type-1">
                        <div>
                            <Link to={this.props.link ? this.props.link : '#'} className="button-expand" onClick={this.showSetting}>
                                <span className="txt"><Translate locale={this.props.locale} value={this.state.openSettings ? 'Close' : 'Open'} /></span>
                                <span className="round"><i className="fa fa-chevron-right"></i></span>
                            </Link>
                        </div>
                    </div>
                </div>
                {!this.props.link &&
                    <div className="col-md-10 setting" style={{ display: this.state.show ? 'block' : 'none' }}>
                        <div className={(this.props.component == 'double_select' || this.props.component == 'company_settings' || this.props.component == 'selectWithCheckbox' || this.props.component == 'accountProfileStatistics' || this.props.component == 'user_email_settings' || this.props.component == 'user_general_settings' || this.props.component == 'storage_settings') ? "col-md-10 col-xs-12" : "col-md-6 col-xs-12"}>
                            {this.props.component == 'input' &&
                                <input type="text" className="form-control"
                                    value={this.props.value ? this.props.value[this.props.setting] : null}
                                    id="seqNo"
                                    onClick={this.openEditField}
                                    onChange={this.change}
                                />
                            }
                            {this.props.component == 'textarea' &&
                                <textarea type="text" className="form-control"
                                    value={this.props.value ? this.props.value[this.props.setting] : ''}
                                    id="seqNo"
                                    onClick={this.openEditField}
                                    rows="30"
                                    onChange={this.change}
                                />
                            }
                            {this.props.component == 'select' &&
                                <select className="form-control" value={this.props.value ? this.props.value[this.props.setting] : null} 
                                onClick={this.openEditField} 
                                onChange={this.change}>
                                    {!this.props.hideEmptyValue && <option value='none'>{plainTranslate(this.props.settings.locale, 'Select option')}</option>}
                                    {this.props.value ? this.props.value[this.props.setting + 'Options'].map((option, key) => {
                                        return <option key={key}
                                            value={option.value}>{plainTranslate(this.props.settings.locale, option.label)}</option>
                                    }) : ''
                                    }
                                </select>
                            }
                            {this.props.component == 'double_select' &&
                                <DoubleSelect
                                    onSave={this.change}
                                    value={this.props.value ? this.props.value[this.props.setting] : []}
                                    setting={this.props.value ? this.props.value[this.props.setting + 'Options'] : []}
                                    secondSetting={this.props.value ? this.props.value[this.props.secondSetting + 'Options'] : []}
                                />
                            }
                            {this.props.component == 'multi_select' &&
                                <Select value={this.props.value ? this.props.value[this.props.setting] : null}
                                    mode="multiple"
                                    onChange={this.handleChange}
                                    className='col-xs-12'
                                    placeholder={plainTranslate(this.props.settings.locale, 'Select option')}>
                                    {this.props.value ? this.props.value[this.props.setting + 'Options'].map((option, key) => {
                                        return <Option key={key}
                                            value={option.value}>{plainTranslate(this.props.settings.locale, option.label)}</Option>
                                    }) : ''
                                    }
                                </Select>
                            }
                            {this.props.component == 'mypos_settings' &&
                                <MyPosSettings
                                    value={this.props.value ? this.props.value[this.props.setting] : null}
                                    onSave={this.change}
                                />
                            }
                            {this.props.component == 'general_scale_of_decimal' &&
                                <div>
                                    <div style={{ display: "grid" }}>
                                        <span style={{ marginLeft: "10px", marginBottom: "10px" }}>Scale Of Decimal - Quantity</span>
                                        <input type="text" className="form-control"
                                            value={this.props.value ? this.props.value['decimalQuantityPoint'] : null}
                                            id="decimalQuantityPoint"
                                            onClick={this.openEditField}
                                            onChange={(val) => this.change(val, "decimalQuantityPoint")}
                                        />
                                    </div>
                                    <br />
                                    <div style={{ display: "grid" }}>
                                        <span style={{ marginLeft: "10px", marginBottom: "10px" }}>Scale Of Decimal - Unit Price</span>
                                        <input type="text" className="form-control"
                                            value={this.props.value ? this.props.value['decimalUnitPrice'] : null}
                                            id="decimalUnitPrice"
                                            onClick={this.openEditField}
                                            onChange={(val) => this.change(val, "decimalUnitPrice")}
                                        />
                                    </div>
                                    <br />
                                    <div style={{ display: "grid" }}>
                                        <span style={{ marginLeft: "10px", marginBottom: "10px" }}>Scale Of Decimal - All</span>
                                        <input type="text" className="form-control"
                                            value={this.props.value ? this.props.value['decimalAll'] : null}
                                            id="decimalAll"
                                            onClick={this.openEditField}
                                            onChange={(val) => this.change(val, "decimalAll")}
                                        />
                                    </div>
                                </div>
                            }
                            {this.props.component == 'company_settings' &&
                                <CompanySettings
                                    value={this.props.value ? this.props.value[this.props.setting] : null}
                                    onSave={this.change}
                                />
                            }
                            {this.props.component == 'selectWithCheckbox' &&
                                <div>
                                    <select style={{ marginBottom: 40 }} className="form-control" value={this.props.value ? this.props.value[this.props.setting] : null} onClick={this.openEditField} onChange={this.change}>
                                        <option value='none'>{plainTranslate(this.props.settings.locale, 'Select option')}</option>
                                        {this.props.value ? this.props.value[this.props.setting + 'Options'].map((option, key) => {
                                            return <option key={key}
                                                value={option.value}>{plainTranslate(this.props.settings.locale, option.label)}</option>
                                        }) : ''
                                        }
                                    </select>
                                    <SingleCheckbox
                                        value={this.props.value ? this.props.value : null}
                                        label={plainTranslate(this.props.settings.locale, 'Reset the retention period for an account with each new activity, created for that account.')}
                                        settings={this.props.settings}
                                        type={'accountSettings'}
                                        saveRoute={'/' + this.props.settings.db + '/api/account/save/settings/individual'}
                                        setting={'resetActivity'}
                                    />
                                </div>
                            }
                            {this.props.component == 'accountProfileStatistics' &&
                                <div>
                                    <SingleCheckbox
                                        value={this.props.value ? this.props.value['accountStatistics'] : null}
                                        label={plainTranslate(this.props.settings.locale, 'Lifetimevalue')}
                                        description={plainTranslate(this.props.settings.locale, 'Choose if you want to see lifetimevalue in account profile')}
                                        settings={this.props.settings}
                                        type={'accountSettings'}
                                        checkSelected={true}
                                        saveRoute={'/' + this.props.settings.db + '/api/account/save/settings/individual'}
                                        setting={'accountAmount'}
                                    />
                                    <SingleCheckbox
                                        value={this.props.value ? this.props.value['accountStatistics'] : null}
                                        label={plainTranslate(this.props.settings.locale, 'Rating')}
                                        description={plainTranslate(this.props.settings.locale, 'Choose if you want to see rating in account profile')}
                                        settings={this.props.settings}
                                        type={'accountSettings'}
                                        checkSelected={true}
                                        saveRoute={'/' + this.props.settings.db + '/api/account/save/settings/individual'}
                                        setting={'accountRating'}
                                    />
                                    <SingleCheckbox
                                        value={this.props.value ? this.props.value['accountStatistics'] : null}
                                        label={plainTranslate(this.props.settings.locale, 'Date Created')}
                                        description={plainTranslate(this.props.settings.locale, 'Choose if you want to see date of creation of account in account profile')}
                                        settings={this.props.settings}
                                        type={'accountSettings'}
                                        checkSelected={true}
                                        saveRoute={'/' + this.props.settings.db + '/api/account/save/settings/individual'}
                                        setting={'accountCreated'}
                                    />
                                    <SingleCheckbox
                                        value={this.props.value ? this.props.value['accountStatistics'] : null}
                                        label={plainTranslate(this.props.settings.locale, 'Last Contact')}
                                        description={plainTranslate(this.props.settings.locale, 'Choose if you want to see last contact with this account in account profile')}
                                        settings={this.props.settings}
                                        type={'accountSettings'}
                                        checkSelected={true}
                                        saveRoute={'/' + this.props.settings.db + '/api/account/save/settings/individual'}
                                        setting={'accountLastContact'}
                                    />
                                    <SingleCheckbox
                                        value={this.props.value ? this.props.value['accountStatistics'] : null}
                                        label={plainTranslate(this.props.settings.locale, 'Unpaid Sales')}
                                        description={plainTranslate(this.props.settings.locale, 'Choose if you want to see unpaid sales in account profile')}
                                        settings={this.props.settings}
                                        type={'accountSettings'}
                                        checkSelected={true}
                                        saveRoute={'/' + this.props.settings.db + '/api/account/save/settings/individual'}
                                        setting={'accountUnpaid'}
                                    />
                                </div>
                            }
                            {this.props.component == 'user_profile' &&
                                <UserProfile
                                    value={this.props.value ? this.props.value[this.props.setting] : null}
                                    onSave={this.change}
                                />
                            }
                            {this.props.component == 'user_password' &&
                                <UserPassword
                                    value={this.props.value ? this.props.value[this.props.setting] : null}
                                    onSave={this.change}
                                />
                            }
                            {this.props.component == 'user_email_settings' &&
                                <UserEmailSettings
                                    value={this.props.value ? this.props.value[this.props.setting] : null}
                                    onSave={this.change}
                                />
                            }
                            {this.props.component == 'user_sms_settings' &&
                                <UserSMSSettings
                                    value={this.props.value ? this.props.value[this.props.setting] : null}
                                    onSave={this.change}
                                />
                            }
                            {this.props.component == 'user_general_settings' &&
                                <UserGeneralSettings
                                    value={this.props.value ? this.props.value[this.props.setting] : null}
                                    onSave={this.change}
                                    langOptions = {this.props.value ? this.props.value['languageOptions'] : []}
                                />
                            }
                            {this.props.component == 'accountNamePattern' &&
                                <AccountNameSettings 
                                    value={this.props.value ? this.props.value[this.props.setting] : null}
                                    options={this.props.value ? this.props.value[this.props.setting + 'Options'] : null}
                                    onSave={this.change}
                                />
                            }
                            {this.props.component == 'storage_settings' &&
                                <StorageSettings
                                    value={this.props.value ? this.props.value[this.props.setting] : null}
                                    onSave={this.change}
                                />
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({
    onChangeSettings: (setting, value, type) => dispatch({ type: SETTINGS_CHANGE, payload: { setting: setting, value: value, type: type } }),
    onSidebarMount: (route) => dispatch(onSidebarMount(route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenSection);
