import React, { Component } from 'react';
import { connect } from 'react-redux';
import { plainTranslate } from '../common/translate';
import { Link } from 'react-router-dom';
import FormLoader from "../forms/form-loader";
import { Statistic, Card, Progress, Table, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words';
import 'antd/es/page-header/style/index.css';
import 'antd/es/statistic/style/index.css';
import 'antd/es/card/style/index.css';
import 'antd/es/progress/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/icon/style/index.css';
import client from '../../actions/client'

class Stats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: true,
            data: null,
        };
    }

    componentDidMount() {
        client
        .get('/' + this.props.match.params.db + '/api/stats')
        .then(response => response.data)
        .then(response => {
            this.setState({ showLoader: false, data: response });
        });
    }

    render() {
        var planRecords = 0;
        var moduleRecords = [];
        const activeUsers = this.state.data ? this.state.data.activeUsers : null;
        const system = this.state.data ? this.state.data.system : null;
        const documents = this.state.data ? this.state.data.documents : null;
        const accounts = this.state.data ? this.state.data.accounts : null;
        const sales = this.state.data ? this.state.data.sales : null;
        const invoices = this.state.data ? this.state.data.invoices : null;
        const projects = this.state.data ? this.state.data.projects : null;
        const expenses = this.state.data ? this.state.data.expenses : null;
        const dates = this.state.data ? this.state.data.dates : null;
        const infinity = new DOMParser().parseFromString('<!doctype html><body>&#8734;', 'text/html').body.textContent;
        const usersTableTitle = plainTranslate(this.props.settings.locale, 'Active users');
        const recordsTableTitle = plainTranslate(this.props.settings.locale, 'Records by module');

        var accountLimit = null;
        var storageLimit = null;
        var storageStats = null;

        const recordsCount = this.state.data
            ? parseInt(sales ? sales.salesMonth : 0) +
            parseInt(invoices ? invoices.invoicesMonth : 0) +
            parseInt(projects ? projects.tasksMonth : 0) +
            parseInt(projects ? projects.projectsMonth : 0) +
            parseInt(expenses ? expenses.expensesMonth : 0)
            : 0;

        if (this.state.data) {

            if (system.plan === 'Free') {
                accountLimit = 200;
                storageLimit = 100000;
                storageStats = documents.used + ' ' + plainTranslate(this.props.settings.locale, 'of') + ' 100 MB';
            } else {
                accountLimit = infinity;
                storageLimit = 1000000;
                storageStats = documents.used + ' ' + plainTranslate(this.props.settings.locale, 'of') + ' 1 GB';
            }

            switch (system.plan) {
                case "Free":
                    planRecords = 50;
                    break;
                case "Growth":
                    planRecords = 1000;
                    break;
                case "Professional":
                    planRecords = 5000;
                    break;
                case "Business":
                    planRecords = 10000;
                    break;
                case "Enterprise":
                    planRecords = infinity;
                    break;
                default:
                    planRecords = 0;
            }

            moduleRecords = [
                {
                    module: plainTranslate(this.props.settings.locale, 'Sales'),
                    records: parseInt(sales ? sales.salesMonth : 0) + parseInt(invoices ? invoices.invoicesMonth : 0),
                },
                {
                    module: plainTranslate(this.props.settings.locale, 'Expenses'),
                    records: expenses.expensesMonth,
                },
                {
                    module: plainTranslate(this.props.settings.locale, 'Projects and tasks'),
                    records: parseInt(projects.tasksMonth) + parseInt(projects.projectsMonth),
                },
            ];
        }

        const getColumnSearchProps = dataIndex => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`${plainTranslate(this.props.settings.locale, 'Search')} ${plainTranslate(this.props.settings.locale, dataIndex)}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block', height: 30 }}
                    />
                    <div>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon="search"
                            size="small"
                            style={{ width: 90, marginRight: 8 }}
                        >
                            {plainTranslate(this.props.settings.locale, 'Search')}
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            {plainTranslate(this.props.settings.locale, 'Reset')}
                        </Button>
                    </div>
                </div>
            ),
            filterIcon: filtered => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            onFilter: (value, record) =>
                record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select());
                }
            },
            render: text =>
                this.state.searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[this.state.searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                        text
                    ),
        });

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();
            this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
            });
        };

        const handleReset = clearFilters => {
            clearFilters();
            this.setState({ searchText: '' });
        };

        const activeUsersColumns = [
            {
                title: plainTranslate(this.props.settings.locale, 'First name'),
                dataIndex: 'firstName',
                key: 'firstName',
                sorter: (a, b) => {
                    return a.firstName.localeCompare(b.firstName)
                },
                ...getColumnSearchProps('firstName'),
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Last name'),
                dataIndex: 'lastName',
                key: 'lastName',
                sorter: (a, b) => {
                    return a.lastName.localeCompare(b.lastName)
                },
                ...getColumnSearchProps('lastName'),
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Email'),
                dataIndex: 'email',
                key: 'email',
                sorter: (a, b) => {
                    return a.email.localeCompare(b.email)
                },
                ...getColumnSearchProps('email'),
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Last Login'),
                dataIndex: 'lastLogin',
                key: 'lastLogin',
                sorter: (a, b) => {
                    return a.lastLogin.localeCompare(b.lastLogin)
                },
                ...getColumnSearchProps('lastLogin'),
            },
        ];

        const moduleRecordsColumns = [
            {
                title: plainTranslate(this.props.settings.locale, 'Module'),
                dataIndex: 'module',
                key: 'module',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Records'),
                dataIndex: 'records',
                key: 'records',
            },
        ];

        return (
            <div>
                {
                    !this.state.showLoader &&
                    <div>
                        <div className="moduleSubMenu">
                            <div className="col-sm-12">
                                <h3 className="module-title pull-left">{plainTranslate(this.props.settings.locale, 'Statistics')}</h3>
                            </div>
                        </div>

                        <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                            <h3>{plainTranslate(this.props.settings.locale, 'Your workspace is on')} {system.plan} {plainTranslate(this.props.settings.locale, 'plan')}.</h3>
                            {
                                system.plan == "Free" &&
                                <React.Fragment>
                                    <Link to={'/' + this.props.match.params.db + '/subscription-details'} className="link"> {plainTranslate(this.props.settings.locale, 'Upgrade to a paid plan')}</Link>
                                    <span> {plainTranslate(this.props.settings.locale, 'to access more users, records, file storage and app integrations.')}</span>
                                </React.Fragment>
                            }
                        </div>
                        <div className="col-sm-12 stats-period text-right">
                            <p>{dates.previous} - {dates.current}</p>
                        </div>
                        <div className="col-sm-3 text-center">
                            <Card>
                                <Statistic
                                    title={plainTranslate(this.props.settings.locale, 'Users')}
                                    value={system ? system.users + ' ' + plainTranslate(this.props.settings.locale, 'of') + ' ' + system.usersLimit : 0}
                                    valueStyle={{ color: '#303030' }}
                                />
                                <Progress percent={system ? Math.round((system.users / system.usersLimit) * 100) : 0} status="active" />
                            </Card>
                        </div>
                        <div className="col-sm-3 text-center">
                            <Card>
                                <Statistic
                                    title={plainTranslate(this.props.settings.locale, 'Records')}
                                    value={recordsCount + ' ' + plainTranslate(this.props.settings.locale, 'of') + ' ' + planRecords}
                                    valueStyle={{ color: '#303030' }}
                                />
                                {
                                    planRecords === infinity 
                                    ? (
                                        <div style={{visibility:"hidden"}}>
                                            <Progress percent={0} style={{display: 'hidden'}} status="active" />
                                        </div>
                                    )
                                    : <Progress percent={system ? Math.round((recordsCount / planRecords) * 100) : 0 } status="active" />
                                }
                            </Card>
                        </div>
                        <div className="col-sm-3 text-center">
                            <Card>
                                <Statistic
                                    title={plainTranslate(this.props.settings.locale, 'Accounts')}
                                    value={accounts.accounts + ' ' + plainTranslate(this.props.settings.locale, 'of') + ' ' + accountLimit}
                                    valueStyle={{ color: '#303030' }}

                                />
                                {
                                    accountLimit === 200 && 
                                    <Progress percent={system ? Math.round( (parseInt(accounts.accounts) / accountLimit) * 100) : 0} status="active" />
                                }
                                {
                                    accountLimit === infinity &&
                                    <div style={{visibility:"hidden"}}>
                                        <Progress percent={0} style={{display: 'hidden'}} status="active" />
                                    </div>
                                }
                            </Card>
                        </div>
                        <div className="col-sm-3 text-center">
                            <Card>
                                <Statistic
                                    title={plainTranslate(this.props.settings.locale, 'Storage space')}
                                    value={storageStats}
                                    valueStyle={{ color: '#303030' }}
                                />
                                <Progress percent={documents ? Math.round((parseInt(documents.used) * 100) / storageLimit) : 0} status="active" />
                            </Card>
                        </div>
                        <div className="col-sm-6" style={{ marginTop: 30 }}>
                            <Table
                                className="itemHistory-table"
                                rowKey={record => record.secureId}
                                title={() => usersTableTitle}
                                dataSource={activeUsers}
                                columns={activeUsersColumns}
                            />
                        </div>
                        <div className="col-sm-6" style={{ marginTop: 30 }}>
                            <Table
                                className="itemHistory-table"
                                rowKey={record => record.module}
                                title={() => recordsTableTitle}
                                dataSource={moduleRecords}
                                columns={moduleRecordsColumns}
                                pagination={false}
                            />
                        </div>
                    </div>
                }
                {
                    this.state.showLoader &&
                    <FormLoader />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Stats);