import {
	ITEM_REQUESTS_DATA_LOADED,
	ITEM_REQUEST_DATA_LOADING,
        SINGLE_ITEM_REQUEST_DATA_LOADED,
        GET_ITEM_REQUESTS_STAGES,
        UPDATE_ITEM_REQUESTS_DATA,
        ITEM_REQUEST_SINGLE_VIEW
} from "../constants/actionTypes";

export default (state = [], action) => {
    let requests = null;
    switch (action.type) {
        case ITEM_REQUEST_DATA_LOADING:
            return {
                ...state,
                loading: true,
              };
        
        case ITEM_REQUEST_SINGLE_VIEW:
            return {
                ...state,
                loadingSingleView: true,
              };

        case ITEM_REQUESTS_DATA_LOADED:
	      return {
	        ...state,
	        loading: false,
	        itemRequests: action.payload.data,
                itemRequest: null
	      };
              
            case SINGLE_ITEM_REQUEST_DATA_LOADED:
	      return {
	        ...state,
                loadingSingleView: false,
	        itemRequest: action.payload.data
	      };
              
            case GET_ITEM_REQUESTS_STAGES:
              return {
	        ...state,
	        stage: action.payload.data
	      };
            
            case UPDATE_ITEM_REQUESTS_DATA:
            requests = state.itemRequests;
            let ind = requests.data.findIndex(i => i.id === action.payload.request);
            requests['data'][ind]['stageId'] = action.payload.stage;
            requests['data'][ind]['status'] = action.payload.name;
            return {
                ...state,
                itemRequests: requests,
            };
            
        default:
            return state;
    }
};
