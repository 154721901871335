import {
    ACCOUNT_PROFILE_DATA_LOADED,
    ACCOUNT_PROFILE_DATA_LOADING,
    ACCOUNT_PROFILE_UPDATE_FIELD,
    ACCOUNT_PROFILE_EDIT_OPTIONS,
    ACCOUNT_ADD_NEW_ACTIVITY,
    ACCOUNT_EDIT_ACTIVITY,
    LOAD_CUSTOM_BLOCKS,
    UPDATE_BLOCK_RECORDS,
    SAVE_BLOCK_RECORDS,
    UPDATE_CONTENT_RECORDS,
    COMMENTS_WIDGET_UNLOADED,
    ADD_COMMENT,
    EDIT_COMMENT,
    DELETE_COMMENT,
    UPDATE_CONSENT_FIELD,
    ACCOUNT_ADD_NEW_DATA_ACCESS,
    ACCOUNT_ADD_NEW_CONSENT,
    ADD_CONTACT_FIELD, 
    DELETE_CONTACT_FIELD,
    DELETE_BILLING_DATA,
    ACCOUNT_ADD_NEW_FILE,
    ACCOUNT_PROFILE_DELETE_TABLE_DATA,
    ACCOUNT_ADD_NEW_PROJECT,
    ACCOUNT_ADD_NEW_TASK,
    ACCOUNT_EDIT_TASK,
    SALE_CHANGE_STAGE
} from "../constants/actionTypes";

function parseComments(comments) {
    let dates = parseCommentsByDate(comments);
    let result = [];
    for(var i = 0; i < dates.length; i++) {
        result[i] = {
            date: dates[i].date,
            list: parseCommentsByUser(dates[i].list),
        };
    }
    return result;
}

function parseCommentsByDate(comments) {
    var list = [];
    var temp = [];
    var currentDate = null;
    var prevDate = null;
    var currDate = null;

    for (var i = 0; i < comments.length; i++) {
        prevDate = currDate;
        comments[i].created = new Date(comments[i].created);
        currDate = comments[i].created;
        var date = comments[i].created.getDate() + ' ' + comments[i].created.getMonth() + ' ' + comments[i].created.getFullYear();

        if (currentDate == null) {
            currentDate = date;
        }

        if (currentDate == date) {
            temp.push(comments[i]);
        }
        else {
            list.push({
                date: prevDate,
                list: temp
            });
            temp = [];
            temp.push(comments[i]);
            currentDate = date;
        }
    }

    if (temp.length) {
        list.push({
            date: currDate,
            list: temp
        });
    }
    return list;
}

function parseCommentsByUser(comments) {
    var list = [];
    var temp = [];
    var currentUser = null;

    for (var i = 0; i < comments.length; i++) {

        var user = comments[i].createdById;

        if (currentUser == null) {
            currentUser = user;
        }

        if (currentUser == user) {
            temp.push(comments[i]);
        }
        else {
            list.push(temp);
            temp = [];
            temp.push(comments[i]);
            currentUser = user;
        }
    }

    if (temp.length) {
        list.push(temp);
    }

    return list;
}

export default (state = [], action) => {
    let tables = null;
    let contacts = null;
    let activities = null;
    let dataEntries = null;
    let dataAccess = null;
    let dataConsents = null;
    let dataDocs = null;
    let dataTable = null;
    let table = null;
    let projects = null;
    let tasks = null;
    let sales = null;

    switch (action.type) {
        case ACCOUNT_PROFILE_DATA_LOADING:
            return {
                ...state,
                loading: true,
              };
        case ACCOUNT_PROFILE_DATA_LOADED:
            return {
                ...state,
                ...action.payload.data,
                loading: false,
                original: action.payload.data.comments,
                parsed: parseComments(action.payload.data.comments)
            };
        case ACCOUNT_PROFILE_UPDATE_FIELD:
            return {
                ...state,
                [action.payload.id]: action.payload.value
            };
        case ACCOUNT_PROFILE_EDIT_OPTIONS:
            return {
                ...state,
                ...action.payload.data
            };

        case ACCOUNT_ADD_NEW_ACTIVITY:
            tables = state.tables;
            activities = tables.activities && tables.activities.data ? tables.activities.data : [];
            if (action.payload.activity.parent) {
                activities.forEach(function (item, key) {
                    if (item.id == action.payload.activity.parent) {
                        activities[key]['thread'].push(action.payload.activity);
                    }
                });
            } else {
                activities.push(action.payload.activity);
            }
            activities.sort(function(a,b){
                let bdate = b.date + ' ' + b.time;
                let adate = a.date + ' ' + a.time;
                let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
                let adt = new Date(adate.replace(pattern,'$3-$2-$1'));
                let bdt = new Date(bdate.replace(pattern,'$3-$2-$1'));
                return bdt - adt;
              });
            tables.activities.data = activities;

            return {
                ...state,
                tables
            };

        case ACCOUNT_EDIT_ACTIVITY:
            tables = state.tables;
            activities = tables.activities.data;

            let remove = false;
            let removeThread = false;
            let id = action.payload.activity ? action.payload.activity.id : action.payload.id;

            if ((action.payload.activity && action.payload.activity.parent) || action.payload.thread) {
                activities.forEach(function (item, key) {
                    item.thread.forEach(function (thread, ind) {
                        if (thread.id === id && removeThread === false)
                        removeThread = ind;
                        remove = key;
                    })     
                });
            } else {
                activities.forEach(function (item, key) {
                    if (item.id === id && remove === false)
                        remove = key;
                });
            } 

            if (removeThread === false) {
                if (remove !== false) {
                    if (!action.payload.activity)
                        activities.splice(remove, 1);
                    else
                        activities.splice(remove, 1, action.payload.activity);
                }
            } else if(removeThread !== false && remove !== false) {
                if(!action.payload.activity) {
                    activities[remove]['thread'].splice(removeThread, 1);
                }
                else {
                    activities[remove]['thread'].splice(removeThread, 1, action.payload.activity);
                }
            }
            activities.sort(function(a,b){
                let bdate = b.date + ' ' + b.time;
                let adate = a.date + ' ' + a.time;
                let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
                let adt = new Date(adate.replace(pattern,'$3-$2-$1'));
                let bdt = new Date(bdate.replace(pattern,'$3-$2-$1'));
                return bdt - adt;
              });
            tables.activities.data = activities;

            return {
                ...state,
                tables
            };
            
        case LOAD_CUSTOM_BLOCKS:  
            return {
                ...state,
                customBlocks: action.payload.customBlocks
            }
            
        case UPDATE_BLOCK_RECORDS:  
            return {
                ...state,
                dataEntry: action.payload.dataEntry
            }
            
        case UPDATE_CONTENT_RECORDS:  
            return {
                ...state,
                contentData: action.payload.contentData
            }
            
        case SAVE_BLOCK_RECORDS:
            dataEntries = state.dataEntry;
            let del = action.payload.del;
            let index = null;

            let dataEntryId = action.payload.dataEntry ? action.payload.dataEntry.id : null;

            if (dataEntryId) {
                dataEntries.forEach(function (item, key) {
                    if (item.id === dataEntryId){
                        index = key;
                    }
                }); 
                
                if (index != null) {
                    if (del) {
                        dataEntries.splice(index, 1);
                    } else {
                        dataEntries[index] = action.payload.dataEntry;
                    }
                } else {
                    dataEntries.push(action.payload.dataEntry);
                }
            } 
            return {
                 ...state,
                 dataEntries
            }

        case ADD_COMMENT:
            return {
                ...state,
                original: [action.payload.comment].concat(state.original || []),
                parsed: parseComments([action.payload.comment].concat(state.original || []))
            }
        case EDIT_COMMENT:
            let res = state.original.map(comment => {
                if(comment.id == action.id) {
                    comment.message = action.message;
                }
                return comment;
            });
            return {
                 ...state,
                original: res,
                parsed: parseComments(res)
            }
        case DELETE_COMMENT:
            let result = state.original.filter(comment => comment.id !== action.id)
            return {
                 ...state,
                original: result,
                parsed: parseComments(result)
            }
        case ACCOUNT_ADD_NEW_DATA_ACCESS:
            tables = state.tables;

            dataAccess = tables.consents.dataAccess;
            dataAccess.push(action.payload.dataAccess);
            
            tables.consents.dataAccess = dataAccess;

            return {
                ...state,
                tables
            };
        case ACCOUNT_ADD_NEW_CONSENT:
            tables = state.tables;

            dataConsents = tables.consents.dataConsents;
            dataConsents.push(action.payload.dataConsents);
            
            tables.consents.dataConsents = dataConsents;

            return {
                ...state,
                tables
            };
        case ADD_CONTACT_FIELD:
            contacts = state.contacts.filter(item => item.id !== 'new');
            contacts.push(action.payload.contact);
        return {
                ...state,
                contacts
            };
        case DELETE_CONTACT_FIELD:
            contacts = state.contacts.filter(item => item.id !== action.payload.contactId);
            
        return {
                ...state,
                contacts
            };
        case DELETE_BILLING_DATA:
            delete state.billingData;
            
        return {
             ...state
            };
        case ACCOUNT_ADD_NEW_FILE:
            tables = state.tables;
            dataDocs = tables.documentTable.data;
            dataDocs.push(action.payload.dataFile);
            
            tables.documentTable.data = dataDocs;

            return {
                ...state,
                tables
            };
        case ACCOUNT_PROFILE_DELETE_TABLE_DATA:
            table = state.tables[action.payload.data.table];
            if (action.payload.data.table == 'projectsAndTasksTable') {
                let taskTable = table.tasks.filter(item => item.id !== action.payload.data.id);
                table.tasks = taskTable;
                table.projects.map((project, key) => {
                        let projectTaskTable = project.tasks.filter(item => item.id !== action.payload.data.id);
                        table.projects[key]['tasks'] = projectTaskTable;
                });
            } else {
                dataTable = table.data.filter(item => item.id !== action.payload.data.id);
                table.data = dataTable;
            }

            return {
                ...state,
                table
            };
        case ACCOUNT_ADD_NEW_PROJECT:
            tables = state.tables; 
            projects = tables.projectsAndTasksTable.projects;
            let project = action.payload.project;
            if(!project.hasOwnProperty('tasks')){
                project['tasks'] = [];
            }
                
            if (action.payload.projectId) {
            projects.forEach(function (item, key) {
                    if (item.id === action.payload.projectId){
                        index = key;
                    }
                });
            projects[index] = project;
            } else { 
                projects.push(project);   
            }
            
            tables.projectsAndTasksTable.projects = projects;
            return {
                ...state,
                tables
            };
            
        case ACCOUNT_ADD_NEW_TASK:

            tables = state.tables;
            tasks = tables.projectsAndTasksTable.tasks;
            if (action.payload.projectId) {
                projects = tables.projectsAndTasksTable.projects;
                projects.forEach(function (item, key) {
                    if (item.id === action.payload.projectId){
                        index = key;
                    }
                }); 
                tasks = tables.projectsAndTasksTable.projects[index].tasks;
            }
            tasks.push(action.payload.task);
            if (action.payload.projectId) {
                tables.projectsAndTasksTable.projects[index].tasks = tasks;

            } else {
                tables.projectsAndTasksTable.tasks = tasks;
            }
            return {
                ...state,
                tables
            };
        
            case ACCOUNT_EDIT_TASK:
            tables = state.tables;
            tasks = tables.projectsAndTasksTable.tasks;
            projects = tables.projectsAndTasksTable.projects;
            let ind = false;
            tasks.forEach(function (item, key) {
                    if (item.id === action.payload.task.id){
                        ind = key;
                    }
                });

            if (ind !== false) {
                tables.projectsAndTasksTable.tasks[ind] = action.payload.task;
            } else {
                let indexTask = false;
                let indexProject = false;
                projects.forEach(function (project, keyProject) {
                    project.tasks.forEach(function (item, keyTask) {
                        if (item.id === action.payload.task.id) {
                            indexTask = keyTask;
                            indexProject = keyProject;
                        }
                    });
                });
               tables.projectsAndTasksTable.projects[indexProject].tasks[indexTask] = action.payload.task;
            }

            return {
                ...state,
                tables
            };
            
            case SALE_CHANGE_STAGE:
            tables = state.tables;
            sales = tables.salesTable.data;
            let i = false;
            sales.forEach(function (item, key) {
                if (item.id === action.payload.id){
                    i = key;
                }
            });
            if (i !== false) {
                sales[i]['status'] = action.payload.stage;
            }

        return {
            ...state,
            tables
        };
        default:
            return state;
    }
};