import React from 'react';    
    
class Text extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
        this.handleEnterClick = this.handleEnterClick.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {   
     
        if(this.state.value === '' && typeof this.props.data !== 'undefined'){    
            this.setState({
                value: this.props.data ? this.props.data : ''
            });
        }
    }
    
 
   shouldComponentUpdate(nextProps, nextState) {  
        return nextProps.data !== this.props.data;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data && nextProps.data !== this.state.value) {         
            this.setState({
                value: nextProps.data
            })
          
        }else{
            return null;
        }
      }
      
    onChange(event){  
        //this.props.change(this.props.name, event.target.value)
        if(this.props.line){
            let lines = this.props.lines ? this.props.lines : [];
            lines[this.props.index][this.props.field.id] = event.target.value;            
            this.props.change('lines', lines)
        }else{
            this.props.change(this.props.field.id, event.target.value)
        }
        this.setState({
          value: event.target.value
       }); 
    } 
    
    handleEnterClick(e) {
    	if(this.props.field.id === 'itName'){
    		if (e.key === 'Enter'){
                this.props.search(e.target.value, this.props.input.name);

            }else if(e.target.id === 'searchButton') {
                let value = e.target.nextElementSibling.value;

		    	this.props.search(value, this.props.input.name);
			}
    	}
    }

 render() { 
     
        return  <React.Fragment> 
        {(this.props.clicked === this.props.index  && this.props.line) || !this.props.line ?
        <span className="form-input-field" style={{position: 'relative'}}>          
        {this.props.field.icon && 
          <i style={{position: 'absolute', paddingTop: 13, left: 120}} 
            id="searchButton" 
            onClick={(e) => this.handleEnterClick(e)} 
            className="fa fa-search"></i>}
        <input 
            onKeyDown={(e) => this.handleEnterClick(e)} 
            value={this.state.value}
            type="text" 
            name={this.props.name}
            className={"form-control " + this.props.field.class} 
            style={{
                minWidth: this.props.field.width ? this.props.field.width : 150,
                maxWidth: this.props.field.width ? this.props.field.width : 150,
                fontSize: 14,
                width: '100%'
             }} 
            disabled={this.props.field.disabled} 
            onChange={this.onChange}
            placeholder={this.props.field.placeholder}/>
    </span>
    :
    <span className={'tableSpan'} >
    {this.state.value}</span>}
     </React.Fragment> 
    
                }
}

export default Text;
