import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';
import { withRouter } from 'react-router-dom';

class InsertTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        };

        this.onClick = this.onClick.bind(this);
    }
    
    onClick() {
        let type = this.props.formData.form.initializeFromState2.values.type;

        //this.props.formData.form.initializeFromState2.values.body = template;
        client
        .post('/' + this.props.match.params.db + "/api/default/template", {
            'body': type
        })
        .then(response => response.data)
        .then(response => {
            this.props.change('body', response);
        })
        .catch(err => {
            showAToast("Error!", 'baseError', 'error')
        });

        
    }

    render() {
        return (
            <div>
                <input id="templates-add" 
                title="CAUTION: this button will remove all your changes and add a sample template in the body area below!" 
                type="button" 
                value="</>"
                onClick={this.onClick}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    formData: state,
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InsertTemplate));
