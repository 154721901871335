import React, {Component} from 'react';
import Translate from '../../common/translate';

export default class Collapsible extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        };

        this.handleToggle = this.handleToggle.bind(this);
    }

    handleToggle(e) {
        e.preventDefault();
        this.setState({
            isExpanded: !this.state.isExpanded
        })
    }

    render() {
        return (
            <div className="row custom-margin">
                <div className="col-md-10 setting-item">
                    {this.props.title &&      
                        <label className="collapsible-label text-bold">
                             <Translate locale={this.props.locale} value={this.props.title}/>
                        </label>
                    }
                    {this.props.subtitle &&
                        <p>
                            <Translate locale={this.props.locale} value={this.props.subtitle}/>
                        </p>
                    }
                </div>
                <div className="col-md-2">
                    <div className="type-1">
                        <div>
                            <a href="" className="button-expand" onClick={this.handleToggle}>
                                <span className="txt">{this.state.isExpanded ?
                                    <Translate locale={this.props.locale} value={"Close"}/> :
                                    <Translate locale={this.props.locale} value="Open"/>}</span>
                                <span className="round"><i className="fa fa-chevron-right"></i></span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={`col-md-12 setting_collapsed ${this.state.isExpanded ? 'show' : ''}`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}