import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { fetchData  } from "../../../actions/actions";
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';

class TreeNode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: true,
        };  
        this.toggleShow = this.toggleShow.bind(this);
        this.move = this.move.bind(this);
    }
    
    toggleShow() {
        this.setState({
                show:!this.state.show
            });
    }
    
    move(id) {
        client.post('/' + this.props.db + '/hr/move/node/' + id)
                .then(response => response.data)
                .then(response => {
                if ('errors' in response) {
                    showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
                }
                else {
                    this.props.loadData(this.props.paramsId, this.props.db);
                    showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
                }
            });
    }

    render() {
        return (
                <li key={this.props.node.id} className= {this.props.class ? "horizontal" : "vertical"}>
                    <div className={this.props.node.__children && this.props.node.__children.length > 0 ? "node" : "node"}>
                        {this.props.frontend ? (
                            <h4>
                            <Link to={"/" + this.props.db + "/org_chart/" + this.props.node.secureId}>  
                                {this.props.node.name}
                            </Link>
                            </h4>
                                    ) : (
                            <h4>
                            <Link to={"/" + this.props.db + "/hr/organizational/structure/" + this.props.node.secureId}>  
                                {this.props.node.name}
                            </Link>
                            </h4>
                        )}
                            {this.props.node.manager.name &&
                                <div className={"item-employee " + (this.props.showManagers ? '' : 'hidden')}> 
                                <p><Translate locale={this.props.locale} value="Manager"/></p>
                                    <h5>
                                    {this.props.frontend ? (
                                        <Link to={"/" + this.props.db + "/employee/" + this.props.node.manager.secureId + "/profile"}>  
                                          {this.props.node.manager.name}
                                        </Link>
                                        ) : (
                                        <Link to={"/" + this.props.db + "/hr/employee/" + this.props.node.manager.secureId + "/profile"}>  
                                          {this.props.node.manager.name}
                                        </Link>
                                    )}
                                    </h5>
                                </div>
                            }
                            {this.props.node.manager.name && this.props.node.employees && 
                                <div className="separate"></div>
                            }
                            {this.props.node.employees &&
                            <div className={"item-employee " + (this.props.showEmployees ? '' : 'hidden')}> 
                            
                            {this.props.node.employees.map((employee, key) =>
                            <p key={key}>
                            {this.props.frontend ? (
                                <Link to={"/" + this.props.db + "/employee/" + employee.secureId + "/profile"}>  
                                    {employee.name}
                                </Link>
                                ) : (
                                <Link to={"/" + this.props.db + "/hr/employee/" + employee.secureId + "/profile"}>  
                                    {employee.name}
                                </Link>
                            )}
                            </p> 
                            )}
                            </div>
                            }
                            {this.props.node.__children && this.props.node.__children.length > 0 &&
                                    <React.Fragment>
                                    <button className="show-hide-nodes" title="Show/hide"
                                        onClick = {this.toggleShow}>
                                        {this.state.show ? (<i className="fa fa-minus" title="Hide" onClick = {this.toggleShow}></i>) : (<i className="fa fa-plus" title="Show" onClick = {this.toggleShow}></i>)}
                                    </button>
                                    <span className={this.state.show ? 'connect-this' : 'hidden-node'}></span>
                                    </React.Fragment>
                            }
                            {!this.props.frontend ?
                            <a href="#" className="move-nodes" title="Move node up">
                                        <i className="fa fa-chevron-up" title="Move node up" onClick={() => this.move(this.props.node.secureId)}></i>
                            </a> : ''
                            }
                    </div>
                    {this.props.children && this.props.children.length > 0 &&
                    <ul className={this.state.show ? 'sub-level' : 'hidden-node'}>
                        {this.props.children.map(node =>
                                <TreeNode 
                                    showEmployees ={this.props.showEmployees}
                                    showManagers ={this.props.showManagers}
                                    db = {this.props.db}
                                    key = {node.id}
                                    node = {node}
                                    frontend = {this.props.frontend}
                                    paramsId = {this.props.paramsId}
                                    loadData= {this.props.loadData}
                                    showMessage = {this.props.showMessage}
                                    hideMessage = {this.props.hideMessage}
                                    children={node.__children}/>           
                               )
                            }
                    </ul>
                    }   
                </li>
                );
    }
}

const mapStateToProps = state => ({
        settings: state.settings,
        treeData: state.treeData
    });
const mapDispatchToProps = dispatch => ({
        loadData: (id, db) =>
            dispatch(fetchData(id, db)),
    });

export default connect(mapStateToProps, mapDispatchToProps)(TreeNode);
