import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../common/translate';
import {onOpenBulk, 
        onOpenBulkActionList, 
        onBulkActionClick } from '../../actions/actions';
import showAToast from '../common/showAToast';
import MinQtyTable from './min-quantity-table';
import client from '../../actions/client';
import { Select, Table, DatePicker } from 'antd';
import Popup from './../common/popup';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

const { Option } = Select;

class BulkActions extends Component {

    constructor(props) {
        super(props);

        this.dateFormat = 'DD/M/YYYY';

        this.state = {
            showPopup: false,
            showCancelPopup: false,
            changedStages: {}
        };

        this.openBulk = this.openBulk.bind(this);
        this.openActionList = this.openActionList.bind(this);
        this.actionClick = this.actionClick.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.linkedDocumentsChangeStage = this.linkedDocumentsChangeStage.bind(this);
        this.getLinkedDocumentsContent = this.getLinkedDocumentsContent.bind(this);
        this.changeStage = this.changeStage.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.changeDocumentStage = this.changeDocumentStage.bind(this);
        this.disabledDate = this.disabledDate.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    openBulk() {
        this.props.onOpenBulk(this.props.bulk);
    }

    openActionList(action) {
        this.props.onOpenActionList(this.props.bulk, action);
    }

    onDateChange(date, dateString) {
        this.setState({documentDate: dateString});
    }

    disabledDate(currentDate){
        let current = moment(currentDate, "DD.MM.YYYY");
        return current && !(moment().diff(current, 'months') >= 0 && moment().diff(current, 'months') < this.props.settings.openPeriodBefore) && !(moment().diff(current, 'months') <= 0 && moment().diff(current, 'months') > this.props.settings.openPeriodAfter*-1);
    };

    actionClick(action) {
        if(action.isReport && action.isReport === true){
            this.props.handleExport(action.type, action.value);
        }else if(action.showPopup){
            let locs = [];

            if(this.props.selectAll === false){
                
                var newArray = this.props.selectedRowsData.map(function(obj) {
                    return obj.locationId;
                });
                locs = [...new Set(newArray)];
            }

            if(this.props.selectAll === true && this.props.pinnedRows[0].locations > 1){
                showAToast(plainTranslate(this.props.settings.locale, 'The selected rows do not have the same location'), 'errSettings', 'error');
            }else if(locs.length > 1){
                showAToast(plainTranslate(this.props.settings.locale, 'The selected rows do not have the same location'), 'errSettings', 'error');
            }
            else{
                this.setState({showPopup: true});
            }
        }
        else{
            let selectedRowsData = this.props.selectedRowsData;

            if(this.props.selectAll === true){
                selectedRowsData = this.props.deSelectedRowsData;
            }

            let visibleColumns = {};
            if(this.props.columnApi){
                let cols = this.props.columnApi.getAllDisplayedColumns();
                
                let i=0;
                cols.map((value) => {
                    if(value.colDef.field !== 'id' && value.colDef.field !== '0' && value.colDef.field && value.colDef.field.length > 0){
                        visibleColumns[i] = [];
                        visibleColumns[i].push(value.colDef.field);
                        visibleColumns[i].push(value.colDef.headerName);
                        visibleColumns[i].push(value.colDef.type);

                        i++;
                    }
                });
            }
            
            if(action.cancel){
                let ids = this.props.gridApi.getSelectedRows().map(a => a.id);
                let val = this.props;
                client.post('/' + this.props.match.params.db + '/api/check-documents/sales', {'body': ids})
                .then(response => response.data)
                .then(response => {
                    if(response.linkedDocuments.length > 0){
                        this.setState({showCancelPopup: true, linkedDocumentsData: response, cancelStage: action, showLoader: false});
                    }else{
                        val.onActionClick(action, val.bulk, val.data.data, val.filterOptions, val.gridProperties.startDate, val.gridProperties.endDate, val.quickFilter ? val.quickFilter : val.gridProperties.quickFilter, val.sortedCols, selectedRowsData, val.selectAll, visibleColumns);
                    }
                });
            }else if(action.dependencyLink){
                let ids = this.props.gridApi.getSelectedRows().map(a => a.id);
                client.post(action.dependencyLink, {'body': ids})
                .then(response => response.data)
                .then(response => {
                    if ('error' in response) {
                        showAToast(response.error, 'baseError', 'error');
                    }else{
                        this.props.customPopupOpen(action.type,ids);
                    }

                });
            }else if(action.status && action.status === 6){
                let ids = this.props.gridApi.getSelectedRows().map(a => a.id);
                client.post('/' + this.props.match.params.db + '/api/check-inventory-dates', {'body': ids})
                .then(response => response.data)
                .then(response => {
                    if(response === false){
                        this.setState({showChangeDocumentDatePopup: true, stageLink : action.route});
                    }else{
                        this.props.onActionClick(action, this.props.bulk, this.props.data.data, this.props.filterOptions, this.props.gridProperties.startDate, this.props.gridProperties.endDate, this.props.quickFilter ? this.props.quickFilter : this.props.gridProperties.quickFilter, this.props.sortedCols, selectedRowsData, this.props.selectAll, visibleColumns);
                    }
                });
            }
            else{
                this.props.onActionClick(action, this.props.bulk, this.props.data.data, this.props.filterOptions, this.props.gridProperties.startDate, this.props.gridProperties.endDate, this.props.quickFilter ? this.props.quickFilter : this.props.gridProperties.quickFilter, this.props.sortedCols, selectedRowsData, this.props.selectAll, visibleColumns)
            }
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.props.bulk.showDropdown) {
            this.openBulk();
        }
    }

    closePopup(){
        this.setState({showPopup: false});
    }

    linkedDocumentsChangeStage(value, record) {
        let arr = Object.assign({}, this.state.changedStages);
        arr[record.id] = {'url':record.url + value, 'saleId': record.saleId};
        this.setState({changedStages: arr});
    }

    getLinkedDocumentsContent(){
        const columns = [
            {
                title: plainTranslate(this.props.settings.locale, 'Sale No'),
                dataIndex: 'saleNo',
                key: 'saleNo',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Document Type'),
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                dataIndex: 'no',
                key: 'no',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Document Date'),
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Current Stage'),
                dataIndex: 'stage',
                key: 'stage',
                render: (text, record) => {
                    let stages = [];
                    if(record.type === 'payment'){
                        stages = this.state.linkedDocumentsData.paymentStagesOptions;
                    }else if(record.type === 'inventory receipt'){
                        stages = this.state.linkedDocumentsData.inventoryStagesOptions;
                    }else if(record.type === 'item request'){
                        stages = this.state.linkedDocumentsData.itemRequestStagesOptions;
                    }
                    return <Select dropdownMatchSelectWidth={false} defaultValue={record.stage} onChange={(value,type, id) => this.linkedDocumentsChangeStage(value, record)}>
                        {stages.map(stage => {
                            return <Option disabled={stage.position <= record.stagePosition ? true : false} className={"linked-documents-table"} value={stage.value}>{stage.label}</Option>
                        })}
                    </Select>
                }
            }];

        return <div><p>This sale is linked by:</p>
            <p>*choose a status for linked documents</p>
            <Table  dataSource={this.state.linkedDocumentsData.linkedDocuments} columns={columns} pagination={false} />
            <p>*if payment document contains more than one document, the line with this sale will be deleted.</p>
            <button className="button-primary-edited text-center btn-ok"
                    onClick={() => this.changeStage()}>
                <Translate locale={this.props.settings.locale} value="Confirm"/>
            </button>
            <button className="button-outlines green text-center"
                    onClick={() => this.setState({showCancelPopup: false})}>
                <Translate locale={this.props.settings.locale} value="Cancel"/>
            </button>
            </div>
    }

    changeStage(value){
        this.setState({showLoader: true});

        let changedStages = this.state.changedStages;

        for (const property in changedStages) {
          client.post('/' + this.props.match.params.db + changedStages[property].url, {'body':changedStages[property].saleId})
            .then(response => response.data)
            .then(response => {
                if ('errors' in response) {
                    showAToast(response.errors, 'baseError', 'error');
                }
            })
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            });
        }

        let ids = this.props.gridApi.getSelectedRows().map(a => a.id);

        client.post(this.state.cancelStage.route, {
            'body': ids.join()
        })
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                this.setState({changeStagePopup: false, showLoader: false})
                showAToast(response.errors, 'baseError', 'error');
            }
            else {
                this.setState({changeStagePopup : false, showLoader: false, showCancelPopup: false});
                this.props.updateGrid();
                showAToast(response.success, 'baseSuccess', 'success');
            }
        });
    }

    changeDocumentStage(){
        this.setState({showLoader: true});

        let ids = this.props.gridApi.getSelectedRows().map(a => a.id);

        client.post(this.state.stageLink, {
            'body': ids.join(),
            'documentDate': this.state.documentDate
        })
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                this.setState({changeStagePopup: false, showLoader: false})
                showAToast(response.errors, 'baseError', 'error');
            }
            else {
                this.setState({changeStagePopup : false, showLoader: false, showCancelPopup: false});
                this.props.updateGrid();
                showAToast(response.success, 'baseSuccess', 'success');
            }
        });
    }

    render() {
        return (
            <div>
                <nav role="navigation" className="nav">
                    <ul className="nav-items">
                        <li className="nav-item dropdown"  ref={this.setWrapperRef}>
                            <a href="#" className="nav-link" onClick={this.openBulk}>
                                <span>
                                <i className={this.props.bulk.icon} aria-hidden="true"></i>
                                <Translate locale={this.props.settings.locale} value={this.props.bulk.label}/>
                                </span>
                            </a>
                            {this.props.bulk.showDropdown &&
                            <nav className="submenus">
                                <ul className="submenus-items">
                                    {this.props.bulk.actions && this.props.bulk.actions.map((action, key) => {
                                            if (action && 'actions' in action) {
                                                const childrenEls = action.actions.map((child, childKey) => {
                                                    return <li className={child.disabled ? "submenus-item not-allow" : "submenus-item"} key={childKey}>
                                                        <a href="#" disabled={child.disabled} onClick={() => this.actionClick(child)}
                                                           className="submenus-link">
                                                            <Translate locale={this.props.settings.locale}
                                                                       value={child.label}/>
                                                        </a>
                                                    </li>
                                                });
                                                return <li className="submenus-link" key={key}>
                                                    <span onClick={() => this.openActionList(action)}>
                                                        <Translate locale={this.props.settings.locale}
                                                                   value={action.label}/>
                                                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                                                    </span>
                                                    {
                                                        action.showDropdown &&
                                                        <ul className="inner-list">
                                                            {childrenEls}
                                                        </ul>
                                                    }
                                                </li>
                                            } else {
                                                if (action){
                                                return <li className={'submenus-item' + (action.line ? ' border-del' : '')} style={{marginLeft: 0}} key={key}>
                                                    <a href="#" onClick={() => this.actionClick(action)}
                                                       className={'submenus-link' + (action.line ? ' text-red' : '')}>
                                                        <Translate locale={this.props.settings.locale} value={action.label}/>
                                                    </a>
                                                </li>
                                                }
                                            }
                                        })
                                    }
                                </ul>
                            </nav>
                            }
                        </li>
                    </ul>
                </nav>
                {
                    this.state.showPopup && <MinQtyTable {...this.props} onClose={this.closePopup} />
                }
                {
                this.state.showCancelPopup &&
                <Popup locale={this.props.settings.locale}
                       title={plainTranslate(this.props.settings.locale, 'Change stage')}
                       onClose={() => this.setState({showCancelPopup: false})} showLoader={this.state.showLoader}>
                    <section className="newPanel">
                        <div className="row m-bot15">
                        {this.getLinkedDocumentsContent()}
                        </div>
                    </section>
                </Popup>
            }
            {
                this.state.showChangeDocumentDatePopup &&
                <Popup locale={this.props.settings.locale}
                       title={plainTranslate(this.props.settings.locale, 'To change the stage you must select a date from the open period')}
                       onClose={() => this.setState({showChangeDocumentDatePopup: false})} showLoader={this.state.showLoader}>
                    <section className="newPanel">
                        <div className="row m-bot15" style={{height: 150}}>
                            <div style={{marginLeft: 15}}>
                                <span style={{marginRight: 20, fontSize: 18}}>{plainTranslate(this.props.settings.locale, 'Document Date')}:</span><DatePicker popupStyle={{zIndex: 2000}} size="large" style={{width: 220}} onChange={this.onDateChange} defaultValue={moment()} format={this.dateFormat} disabledDate={this.disabledDate}/>
                            </div>
                            <div>
                                <button className="button-primary-edited text-center btn-ok"
                                        onClick={() => this.changeDocumentStage()}>
                                    <Translate locale={this.props.settings.locale} value="Save"/>
                                </button>
                                <button className="button-outlines green text-center"
                                        onClick={() => this.setState({showChangeDocumentDatePopup: false})}>
                                    <Translate locale={this.props.settings.locale} value="Cancel"/>
                                </button>
                            </div>
                        </div>
                    </section>
                </Popup>
            }
            </div>

        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    gridProperties: state.gridProperties,
    data: state.gridData
});

const mapDispatchToProps = dispatch => ({
    onOpenBulk: (bulk) => dispatch(onOpenBulk(bulk)),
    onOpenActionList: (bulk, action) => dispatch(onOpenBulkActionList(bulk, action)),
    onActionClick: (action, bulk, data, filter, startDate, endDate, quickFilter, sortedCols, selectedRowsData,selectAll, visibleColumns) => dispatch(onBulkActionClick(action, bulk, data, filter, startDate, endDate, quickFilter, sortedCols, selectedRowsData,selectAll, visibleColumns)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BulkActions));

