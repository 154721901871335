import React from 'react';
import SaveCancel from '../../../common/saveCancelButtons';
import Translate, { plainTranslate } from '../../../common/translate';
import client from './../../../../actions/client';

class ChartOfAccountsData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showMessage: false,
            message: '',
            chartOfAccounts: {
                code: '',
                name: '',
                type: '',
                status: true
            },
            enableSave: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
    }
    
    componentDidUpdate(prevProps) {    
        if (prevProps.chartOfAccounts.code !== this.props.chartOfAccounts.code
            || prevProps.chartOfAccounts.name !== this.props.chartOfAccounts.name
            || prevProps.chartOfAccounts.type !== this.props.chartOfAccounts.type
            || prevProps.chartOfAccounts.status !== this.props.chartOfAccounts.status) {
            const clone = Object.assign({}, this.props.chartOfAccounts);
            this.setState({
                chartOfAccounts: clone
            });
        }

        if (prevProps.chartOfAccountsId !== this.props.chartOfAccountsId) {
            this.setState({
                enableSave: !this.props.chartOfAccountsId
            });
        }
    }

    handleChange(property, e) {
        let chartOfAccounts = this.state.chartOfAccounts;
        if (property == 'status') {
            chartOfAccounts['status'] = !this.state.chartOfAccounts.status;
        } else if (property != 'status'){
            let value = e.target.value;
            chartOfAccounts[property] = value;
            if (chartOfAccounts['status'] === undefined) {
                chartOfAccounts['status'] = true;
            }
        }
        
        let diff = (this.state.chartOfAccounts.code !== this.props.chartOfAccounts.code
            || this.state.chartOfAccounts.name !== this.props.chartOfAccounts.name
            || (this.state.chartOfAccounts.type !== this.props.chartOfAccounts.type)
            ||(this.state.chartOfAccounts.status !== this.props.chartOfAccounts.status)
            && (this.state.chartOfAccounts.name && this.state.chartOfAccounts.code));

        this.setState({
            chartOfAccounts,
            enableSave: diff ? true : false
        });
    }
    
    save() {
        if (!this.state.enableSave) {
            return;
        }
        
        if (typeof this.state.chartOfAccounts['status'] === 'undefined' || this.state.chartOfAccounts['status'] === null) {
            this.state.chartOfAccounts['status']=true;
        }
       
        this.setState({
            enableSave: false,
        });

        client.post('/' + this.props.db + '/api/accounting/chart-of-accounts/main-data/' + (this.state.chartOfAccounts.id ? this.state.chartOfAccounts.id : 'new-record'), 
        {
            code: this.state.chartOfAccounts.code,
            name: this.state.chartOfAccounts.name,
            status: this.state.chartOfAccounts.status,
            type: this.state.chartOfAccounts.type ? this.state.chartOfAccounts.type : 'BS'
        })
        .then(response => response.data)
        .then(response => {
            this.setState({
                showMessage: true,
                message: response.message,
            });

            this.props.update(response.chartOfAccounts.id, this.state.chartOfAccounts.code, this.state.chartOfAccounts.name, this.state.chartOfAccounts.type, this.state.chartOfAccounts.status);
        });
    }
    
    render() {
        return (
            <div>
                <div className="col-md-6">
                    <div className="row m-bot15">
                        <div className="form-group">
                            <label className="col-md-12 text-left">{<Translate locale={this.props.locale} value='Code'/>}<span
                                className="required"
                                title="This field is required">*</span></label>
                            <div className="col-sm-12">
                                <span className="form-input-field">
                                    <input type="text" className="form-control"
                                           value={this.state.chartOfAccounts.code}
                                           onChange={(e) => this.handleChange('code', e)}/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row m-bot15">
                        <div className="form-group">
                            <label className="required col-md-12 text-left">{<Translate locale={this.props.locale} value="Name"/>}<span
                                className="required"
                                title="This field is required">*</span></label>
                            <div className="col-sm-12">
                                <span className="form-input-field">
                                    <input type="text"
                                           className="form-control"
                                           value={this.state.chartOfAccounts.name}
                                           onChange={(e) => this.handleChange('name', e)}/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row m-bot15">
                        <div className="form-group">
                            <label className="required col-md-12 text-left">{<Translate locale={this.props.locale} value="Type"/>}<span
                                className="required"
                                title="This field is required">*</span></label>
                            <div className="col-sm-12">
                                <span className="form-input-field">
                                    <select className="form-control" onChange={(e) => this.handleChange('type', e)}
                                                value={this.state.chartOfAccounts.type}>
                                            <option value='BS'>{<Translate locale={this.props.locale} value="Balance Sheet"/>}</option>
                                            <option value='PL'>{<Translate locale={this.props.locale} value="Profit / Loss"/>}</option>
                                            <option value='CS'>{<Translate locale={this.props.locale} value="Customers / Suppliers"/>}</option>
                                        </select>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="col-md-12">
                        <label className="required col-md-12 text-left">{< Translate locale = {this.props.locale} value="Status"/>}<span
                                className="required"
                                title="This field is required">*</span></label>
                    </div>
                    <div className="col-md-12">
                        <div before={plainTranslate(this.props.locale, 'Yes')} after={plainTranslate(this.props.locale, 'No')} className="slideThree text-left">
                            <input
                                type="checkbox"
                                id='check-status'
                                label ='status'
                                checked={this.state.chartOfAccounts.status}
                                onChange={(e) => this.handleChange('status', e)}
                                />
                            <label htmlFor='check-status'></label>
                        </div>      
                    </div>
                </div>
                <div className="col-md-12 col-xs-12">
                    <div className="pull-right">
                        <SaveCancel
                            locale={this.props.locale}
                            enableSave={this.state.enableSave}
                            save={this.save}/>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        );
    }
}


export default ChartOfAccountsData;