import {
    GET_DOCUMENTS,
    GET_DOCUMENT,
    GET_FOLDER_OPTIONS,
    LOAD_UPLOAD
} from "../constants/actionTypes";

export default (state = {}, action) => {
        
switch (action.type) {
    case GET_FOLDER_OPTIONS:
    return {
        ...state,
        allFolders: action.payload
      };
    case GET_DOCUMENTS:
        return {
        ...state,
        documents: action.payload
      };
    case GET_DOCUMENT:
        return {
        ...state,
        document: action.payload
      };
    case LOAD_UPLOAD:  
        return {
        ...state,
        loadUpload: action.payload
    };
        default:
            return state;
    }
};