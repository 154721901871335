import React from 'react';
import {connect} from 'react-redux';
import SlideModal from '../../common/SlideModal';
import SimpleForm from "../../formnew/Forms";
import {plainTranslate} from "../../common/translate";
import { withRouter } from 'react-router-dom'

class AddTranslation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modalTranslation: false
        };
        this.closeModal = this.closeModal.bind(this);
    }
    
    closeModal(){
        this.setState({
            modalTranslation: false
        });
    }

    render() {
        
        let value = this.props.formData[this.props.field.translate];//this.props.formData.form.initializeFromState2.values[this.props.field.translate];

        if(value && value.indexOf('+') > -1){
            value = value.replace("+", "%2B");
        }

         if(value && value.indexOf('&') > -1){
            value = value.replace("&", "%26");
        }

        return <div >
               <i className="fa fa-language" aria-hidden="true" title="Add translation" onClick={() => this.setState({modalTranslation: true})}></i> 
                {
                        this.state.modalTranslation &&
                            <SlideModal onClose={() => this.setState({modalTranslation: false})} title={plainTranslate(this.props.settings.locale, 'Translation')}>
                                <section className="newPanel">
                                    <div className="panel-body">
                                        <div className="row m-bot15">
                                            <SimpleForm
                                                saveRoute={ '/api/translates/'}
                                                formRoute={'/api/translates/form'}
                                                match={{params: {id: 0}}}
                                                onSuccessfulSave={this.closeModal}
                                                refs = {value}
                                                popup={true}
                                                isNew={true}
                                                onCancel={() => this.setState({modalTranslation: false})}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </SlideModal>
                    }
                    </div>
                }
            }

const mapStateToProps = state => ({
    settings: state.settings,
 //   formData: state.formData,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddTranslation));


