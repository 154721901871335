import React, {Component} from 'react';
import {connect} from 'react-redux';
import ReactDOM from 'react-dom';
import 'antd/lib/drawer/style/index.css';
import 'antd/lib/menu/style/index.css';
import {Link} from 'react-router-dom';
import { Drawer, Button, Menu } from 'antd';
import {plainTranslate} from "../common/translate";
import {
    onSidebarMount,
} from '../../actions/actions';
import { withRouter } from 'react-router-dom'
const { SubMenu } = Menu;

class MenuDrawer extends Component {

  constructor(props) {
      super(props);
      this.state = {
        visible: false,
        placement: 'left'
      };
      this.showDrawer = this.showDrawer.bind(this);
      this.onChange = this.onChange.bind(this);
      this.onClose = this.onClose.bind(this);
  }

  showDrawer(){
    this.setState({
      visible: true,
    });
  }

  onClose(){
    this.setState({
      visible: false,
    });
  }

  onChange(e){
    this.setState({
      placement: e.target.value,
    });
  }

  componentDidMount() {
      this.props.onSidebarMount('/'+this.props.db+'/api/settings/sidebar');
  }

  render() {
    let showReportsTab = false;
    if(this.props.settings.bundles.reportsbundle && this.props.settings.reports && this.props.settings.reports.predefinedReports){
      this.props.settings.reports.predefinedReports.map((report, key) => {
        if(report.id === 'stockOnHand' || report.id === 'payments'){
          showReportsTab=true;
        }
      });
    }

    const urlParams = new URLSearchParams(this.props.location.search);
    const locId = urlParams.get('locId');
    const formId = urlParams.get('type');

    if(locId !== 'undefined' && locId !== null){
      localStorage.setItem('locId', locId);
    }

    if(formId !== 'undefined' && formId !== null){
      localStorage.setItem('formid', formId);
    }

    let saleLink = '/' + this.props.match.params.db + '/pos/transactions/new?locId='+localStorage.locId;

    if(localStorage.formid !== 'undefined'){
      saleLink = saleLink+'&type='+localStorage.formid;
    }

    return (
           <div className="left-menuPos">
              <Button type="primary" onClick={this.showDrawer}>
              <i className="fa fa-bars" aria-hidden="true"/>
              </Button>
              <Drawer
                title={plainTranslate(this.props.settings.locale, 'Menu')}
                placement={this.state.placement}
                closable={false}
                onClose={this.onClose}
                visible={this.state.visible}
                bodyStyle={{ padding: 0 }}
                width= {256}
              >
                <Menu
                  onClick={this.handleClick}
                  mode="inline"
                >
                    <Menu.Item key="1">
                      <Link className="draggable" id="New"
                               to={saleLink}
                               title={plainTranslate(this.props.settings.locale, 'New sale')}>
                          <span>{plainTranslate(this.props.settings.locale, 'New sale')}</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="2">
                      <Link className="draggable" id="Sales"
                               to={'/' + this.props.match.params.db + '/pos/transactions/sales'}
                               title={plainTranslate(this.props.settings.locale, 'Sales')}>
                          <span>{plainTranslate(this.props.settings.locale, 'Sales')}</span>
                      </Link>
                    </Menu.Item>
                    {this.props.settings.bundles.invoicebundle && this.props.settings.plan.invoice &&
                      <Menu.Item key="3">
                        <Link className="draggable" id="Invoices"
                                 to={'/' + this.props.match.params.db + '/pos/transactions/invoices'}
                                 title={plainTranslate(this.props.settings.locale, 'Invoices')}>
                            <span>{plainTranslate(this.props.settings.locale, 'Invoices')}</span>
                        </Link>
                      </Menu.Item>
                    }
                    {showReportsTab &&
                      <SubMenu
                        key="sub1"
                        title={
                          <span>
                            <span>Reports</span>
                          </span>
                        }
                      >
                        {
                          this.props.settings.bundles.reportsbundle && this.props.settings.reports && this.props.settings.reports.predefinedReports &&
                          this.props.settings.reports.predefinedReports.map((report, key) => {
                            if(report.id === 'stockOnHand'){
                              return <Menu.Item key="4">
                                <Link className="draggable" id="New"
                                       to={'/' + this.props.match.params.db + '/pos/data-source/stock-on-hand'}
                                       title={plainTranslate(this.props.settings.locale, 'Stock on Hand')}>
                                  <span>{plainTranslate(this.props.settings.locale, 'Stock on Hand')}</span>
                                </Link>
                              </Menu.Item>
                            }
                            if(report.id === 'payments'){
                             return <Menu.Item key="5">
                                 <Link className="draggable" id="New"
                                       to={'/' + this.props.match.params.db + '/pos/report/payments/filters'}
                                       title={plainTranslate(this.props.settings.locale, 'Payments Report')}>
                                  <span>{plainTranslate(this.props.settings.locale, 'Payments Report')}</span>
                                </Link>
                              </Menu.Item>
                            }
                          })
                        } 
                      </SubMenu>
                    }
                    <Menu.Item key="6">
                      <a id="Exit" title={plainTranslate(this.props.settings.locale, 'Logout')}
                               href={'/' + this.props.match.params.db + '/dashboard'}>
                                <span>{plainTranslate(this.props.settings.locale, 'Logout')}</span>
                            </a>
                    </Menu.Item>
                </Menu>
              </Drawer>
            </div>
    );
  }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
  onSidebarMount: (route) => dispatch(onSidebarMount(route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuDrawer));