export default function getGridRoutes(db) {
    return [
        {
            title: ['Accounts', '›', 'List'],
            path: '/:db/accounts/list/:id',
            gridRoute: '/api/accounts/grid-properties/',
            gridDataRoute: '/api/accounts/all/',
            customPagination: true
        },
        {
            title: ['Currencies', '›', 'List'],
            path: '/:db/administration/currencies',
            gridRoute: '/api/currencies/settings/grid-properties',
            gridDataRoute: '/api/currencies'
        },
        {
            title: ['Analysis codes', '›', 'List'],
            path: '/:db/administration/analysis-codes',
            gridRoute: '/api/analysis-codes/settings/grid-properties',
            gridDataRoute: '/api/analysis-codes'
        },
        {
            title: ['Analysis categories', '›', 'List'],
            path: '/:db/administration/analysis-categories',
            gridRoute: '/api/analysis-categories/settings/grid-properties',
            gridDataRoute: '/api/analysis-categories'
        },
        {
            title: ['Currency rates', '›', 'List'],
            path: '/:db/administration/currency-rates',
            gridRoute: '/api/currency-rates/settings/grid-properties',
            gridDataRoute: '/api/currency-rates'
        },
        {
            title: ['Activities Types', '›', 'List'],
            path: '/:db/administration/events',
            gridRoute: '/api/events/settings/grid-properties',
            gridDataRoute: '/api/events'
        },
        {
            title: ['Taxes', '›', 'List'],
            path: '/:db/administration/taxes',
            gridRoute: '/api/taxes/settings/grid-properties',
            gridDataRoute: '/api/taxes'
        },
        {
            title: ['Item categories', '›', 'List'],
            path: '/:db/administration/items/categories',
            gridRoute: '/api/item-categories/settings/grid-properties',
            gridDataRoute: '/api/item-categories'
        },
        {
            title: ['Items', '›', 'List'],
            path: '/:db/administration/items',
            gridRoute: '/api/items/settings/grid-properties',
            gridDataRoute: '/api/grid/items/old',
            exportTemplateRoute: '/api/export/importData/items',
            customPagination: true,
            customSelection: true
        },
        {
            title: ['Measures', '›', 'List'],
            path: '/:db/administration/measure',
            gridRoute: '/api/measures/settings/grid-properties',
            gridDataRoute: '/api/measures'
        },
        {
            title: ['Measure conversions', '›', 'List'],
            path: '/:db/administration/measure-conversion',
            gridRoute: '/api/measure-conversions/settings/grid-properties',
            gridDataRoute: '/api/measure-conversions'
        },
        {
            title: ['Billing data', '›', 'List'],
            path: '/:db/administration/billing-data',
            gridRoute: '/api/billing-datas/grid-properties',
            gridDataRoute: '/api/grid/billing-data',
            customPagination: true
        },
        {
            title: ['Locations', '›', 'List'],
            path: '/:db/administration/locations',
            gridRoute: '/api/locations/settings/grid-properties',
            gridDataRoute: '/api/locations'
        },
        {
            title: ['Payment methods', '›', 'List'],
            path: '/:db/administration/payment-methods',
            gridRoute: '/api/payment-methods/settings/grid-properties',
            gridDataRoute: '/api/payment-methods'
        },
        {
            title: ['Templates Email', '›', 'List'],
            path: '/:db/administration/templates/email',
            gridRoute: '/api/email-templates/settings/grid-properties',
            gridDataRoute: '/api/email-templates'
        },
        {
            title: ['Templates', '›', 'List'],
            path: '/:db/administration/templates/documents',
            gridRoute: '/api/document-templates/settings/grid-properties',
            gridDataRoute: '/api/document-templates'
        },
        {
            title: ['SMS Templates', '›', 'List'],
            path: '/:db/administration/templates/sms',
            gridRoute: '/api/sms-templates/settings/grid-properties',
            gridDataRoute: '/api/sms-templates'
        },
        {
            title: ['Languages', '›', 'List'],
            path: '/:db/administration/languages',
            gridRoute: '/api/languages/settings/grid-properties',
            gridDataRoute: '/api/languages'
        },
        {
            title: ['Translations', '›', 'List'],
            path: '/:db/administration/translations',
            gridRoute: '/api/translations/settings/grid-properties',
            gridDataRoute: '/api/translations'
        },
        {
            title: ['Texts', '›', 'List'],
            path: '/:db/administration/texts',
            gridRoute: '/api/texts/settings/grid-properties',
            gridDataRoute: '/api/texts'
        },
        {
            title: ['Employees', '›', 'List'],
            path: '/:db/administration/employee',
            gridRoute: '/api/employees/settings/grid-properties',
            gridDataRoute: '/api/employees'
        },
        {
            title: ['Settings general', '›', 'List'],
            path: '/:db/administration/settings/general',
            gridRoute: '/api/setups/settings/grid-properties',
            gridDataRoute: '/api/setups'
        },
        {
            title: ['Settings general', '›', 'List'],
            path: '/:db/administration/setup',
            gridRoute: '/api/setups/settings/grid-properties',
            gridDataRoute: '/api/setups'
        },
        {
            title: ['Reports', '›', 'List'],
            path: '/:db/reports',
            gridRoute: '/api/reports/grid-properties',
            gridDataRoute: '/api/reports'
        },
        {
            title: ['Roles', '›', 'List'],
            path: '/:db/settings/accounts/roles',
            gridRoute: '/api/roles/settings/grid-properties',
            gridDataRoute: '/api/roles'
        },
        {
            title: ['Contracts', '›', 'List'],
            path: '/:db/contracts/orders',
            gridRoute: '/api/orders/settings/grid-properties',
            gridDataRoute: '/api/orders'
        },
        {
            title: ['Activities', '›', 'List'],
            path: '/:db/activities',
            gridRoute: '/api/activities/settings/grid-properties',
            gridDataRoute: '/api/activities'
        },
        {
            title: ['Payments', '›', 'List'],
            path: '/:db/payments',
            gridRoute: '/api/payments/grid-properties',
            gridDataRoute: '/api/payments'
        },
        {
            title: ['Invoices', '›', 'List'],
            path: '/:db/invoices',
            gridRoute: '/api/invoices/settings/grid-properties',
            gridDataRoute: '/api/invoices'
        },
        {
            title: ['Chart of accounts', '›', 'List'],
            path: '/:db/accounting/chart-of-accounts',
            gridRoute: '/api/chart-of-accounts/settings/grid-properties',
            gridDataRoute: '/api/chart-of-accounts'
        },
        {
            title: ['Journal types', '›', 'List'],
            path: '/:db/accounting/journal-types',
            gridRoute: '/api/journals/settings/grid-properties',
            gridDataRoute: '/api/journals'
        },
        {
            title: ['Production', '›', 'List'],
            path: '/:db/production',
            gridRoute: '/api/production/settings/grid-properties',
            gridDataRoute: '/api/production'
        },
        {
            title: ['Production Detail', '›', 'List'],
            path: '/:db/production/details',
            gridRoute: '/api/production-detail/settings/grid-properties',
            gridDataRoute: '/api/production-detail'
        },
        {
            title: ['Ledger', '›', 'List'],
            path: '/:db/accounting/ledger',
            gridRoute: '/api/ledgers/settings/grid-properties',
            gridDataRoute: '/api/ledgers'
        },
        {
            title: ['Ledger Details', '›', 'List'],
            path: '/:db/accounting/ledger-details',
            gridRoute: '/api/ledger-details/settings/grid-properties',
            gridDataRoute: '/api/ledger-details'
        },
        {
            title: ['Projects', '›', 'List'],
            path: '/:db/projects',
            gridRoute: '/api/projects/settings/grid-properties',
            gridDataRoute: '/api/projects'
        },
        {
            title: ['Tasks', '›', 'List'],
            path: '/:db/tasks',
            gridRoute: '/api/tasks/settings/grid-properties',
            gridDataRoute: '/api/tasks'
        },
        {
            title: ['Notifications', '›', 'List'],
            path: '/:db/notifications',
            gridRoute: '/api/notifications/grid-properties',
            gridDataRoute: '/api/notifications'
        },
        {
            title: ['Inventory', '›', 'List'],
            path: '/:db/inventory',
            gridRoute: '/api/inventories/grid-properties',
            gridDataRoute: '/api/inventories'
        },
        {
            title: ['Custom Data Entry', '›', 'List'],
            path: '/:db/data/:id',
            gridRoute: '/api/data-entry/grid-properties/',
            gridDataRoute: '/api/data-entry/'
        },
        {
            title: ['Templates Export', '›', 'List'],
            path: '/:db/administration/templates/export',
            gridRoute: '/api/export-templates/settings/grid-properties',
            gridDataRoute: '/api/export-templates'
        },
        {
            title: ['Departments', '›', 'List'],
            path: '/:db/hr/departments',
            gridRoute: '/api/departments/settings/grid-properties',
            gridDataRoute: '/api/departments'
        },
        {
            title: ['Employees', '›', 'List'],
            path: '/:db/hr/employees',
            gridRoute: '/api/employees/settings/grid-properties',
            gridDataRoute: '/api/employees'
        },
        {
            title: ['Phone book', '›', 'List'],
            path: '/:db/hr/phone-book',
            gridRoute: '/api/hr/phone-book/grid-properties',
            gridDataRoute: '/api/hr/phone-book'
        },
        {
            title: ['Users', '›', 'List'],
            path: '/:db/administration/users',
            gridRoute: '/api/users/settings/grid-properties/admin',
            gridDataRoute: '/api/users'
        },
        {
            title: ['Online Users', '›', 'List'],
            path: '/:db/online/users',
            gridRoute: '/api/users/settings/grid-properties/online',
            gridDataRoute: '/api/web/users'
        },
        {
            title: ['Portal Users', '›', 'List'],
            path: '/:db/portal/users',
            gridRoute: '/api/users/settings/grid-properties/portal',
            gridDataRoute: '/api/portal/users'
        },
        {
            title: ['Item history', '›', 'List'],
            path: '/:db/item-history/:id',
            gridRoute: '/api/items-history/grid-properties/',
            gridDataRoute: '/api/items-history/'
        },
        {
            title: ['Expenses', '›', 'List'],
            path: '/:db/expenses',
            gridRoute: '/api/expenses/settings/grid-properties',
            gridDataRoute: '/api/expenses'
        },
        {
            title: ['Expense categories', '›', 'List'],
            path: '/:db/expense-categories',
            gridRoute: '/api/expense-categories/settings/grid-properties',
            gridDataRoute: '/api/expense-categories'
        },
        {
            title: ['Expense types', '›', 'List'],
            path: '/:db/expense-types',
            gridRoute: '/api/expense-types/settings/grid-properties',
            gridDataRoute: '/api/expense-types'
        },
        {
            title: ['Form types', '›', 'List'],
            path: '/:db/form-types',
            gridRoute: '/api/form-types/settings/grid-properties',
            gridDataRoute: '/api/form-types'
        },
        {
            title: ['Price Lists'],
            path: '/:db/administration/pricelists',
            gridRoute: '/api/pricelist/settings/grid-properties',
            gridDataRoute: '/api/pricelist'
        },
        {
            title: ['Discounts'],
            path: '/:db/administration/discounts',
            gridRoute: '/api/discount/settings/grid-properties',
            gridDataRoute: '/api/discount'
        },
        {
            title: ['Sales', '›', 'List'],
            path: '/:db/sales',
            gridRoute: '/api/sales/settings/grid-properties',
            gridDataRoute: '/api/sales'
        },
        {
            title: ['User Sessions', '›', 'List'],
            path: '/:db/user/sessions',
            gridRoute: '/api/user/sessions/grid-properties',
            gridDataRoute: '/api/user/sessions'
        },
        {
            title: ['Batches', '›', 'List'],
            path: '/:db/administration/batches',
            gridRoute: '/api/batches/settings/grid-properties',
            gridDataRoute: '/api/batches'
        },
        {
            title: ['History', '›', 'List'],
            path: '/:db/history',
            gridRoute: '/api/history/grid-properties',
            gridDataRoute: '/api/history'
        },
        {
            title: ['Devices', '›', 'List'],
            path: '/:db/administration/devices',
            gridRoute: '/api/devices/settings/grid-properties',
            gridDataRoute: '/api/devices'
        },
        {
            title: ['Groups', '›', 'List'],
            path: '/:db/administration/groups',
            gridRoute: '/api/groups/settings/grid-properties',
            gridDataRoute: '/api/groups'
        },
        {
            title: ['Location Workplaces', '›', 'List'],
            path: '/:db/administration/workplaces',
            gridRoute: '/api/administration/workplace/settings/grid-properties',
            gridDataRoute: '/api/administration/workplace'
        },
        {
            title: ['Document Sequences', '›', 'List'],
            path: '/:db/administration/document-sequences',
            gridRoute: '/api/document-sequences/settings/grid-properties',
            gridDataRoute: '/api/document-sequences'
        },
        {
            title: ['Printed Receipts', '›', 'List'],
            path: '/:db/printed-receipts',
            gridRoute: '/api/printed-receipts/settings/grid-properties',
            gridDataRoute: '/api/printed-receipts'
        },
        {
            title: ['Inventory requests', '›', 'List'],
            path: '/:db/inventory-requests',
            gridRoute: '/api/ordered-items/settings/grid-properties',
            gridDataRoute: '/api/ordered-items',
            customPagination: true
        },
        {
            title: ['Content types', '›', 'List'],
            path: '/:db/content-types',
            gridRoute: '/api/content-types/settings/grid-properties',
            gridDataRoute: '/api/content-types'
        },
        {
            title: ['Content Data Entry', '›', 'List'],
            path: '/:db/contents/:id',
            gridRoute: '/api/contents/grid-properties/',
            gridDataRoute: '/api/contents/',
            customPagination: true,
            gridExportRoute: '/api/contents/export/'
        },
        {
            title: ['Content categories', '›', 'List'],
            path: '/:db/content-categories',
            gridRoute: '/api/content-categories/settings/grid-properties',
            gridDataRoute: '/api/content-categories'
        },
        {
            title: ['Payment Balance Report'],
            path: '/:db/data-source/payment-balance',
            gridRoute: '/api/data-source/payment-balance/settings/grid-properties',
            filtersRoute: '/api/reports/payment-balance/filters/',
            filtersDataRoute: '/api/reports/payment-balance/dataSource',
            filtersSaveRoute: '/api/reports/payment-balance/filters/save',
            gridExportRoute: '/api/reports/payment-balance/export',
            isReport: true
        },
        {
            title: ['Stock on Hand'],
            path: '/:db/data-source/stock-on-hand',
            gridRoute: '/api/data-source/onhand/settings/grid-properties',
            filtersRoute: '/api/reports/onhand/filters/',
            filtersDataRoute: '/api/reports/onhand/dataSource',
            filtersSaveRoute: '/api/reports/onhand/filters/save',
            gridExportRoute: '/api/reports/onhand/export',
            isReport: true
        },
        {
            title: ['Account Payables'],
            path: '/:db/data-source/account-payables',
            gridRoute: '/api/data-source/payables/settings/grid-properties',
            filtersRoute: '/api/reports/payables/filters/',
            filtersDataRoute: '/api/reports/payables/dataSource',
            filtersSaveRoute: '/api/reports/payables/filters/save',
            gridExportRoute: '/api/reports/payables/export',
            isReport: true
        },
        {
            title: ['Account Receivables'],
            path: '/:db/data-source/account-receivables',
            gridRoute: '/api/data-source/receivables/settings/grid-properties',
            filtersRoute: '/api/reports/receivables/filters/',
            filtersDataRoute: '/api/reports/receivables/dataSource',
            filtersSaveRoute: '/api/reports/receivables/filters/save',
            gridExportRoute: '/api/reports/receivables/export',
            isReport: true
        },
        {
            title: ['Aging Analysis'],
            path: '/:db/data-source/aging-analysis',
            gridRoute: '/api/data-source/aging/settings/grid-properties',
            filtersRoute: '/api/reports/aging/filters/',
            filtersDataRoute: '/api/reports/aging/dataSource',
            filtersSaveRoute: '/api/reports/aging/filters/save',
            gridExportRoute: '/api/reports/aging/export',
            isReport: true
        },
        {
            title: ['Profit and Loss'],
            path: '/:db/data-source/profit-loss',
            gridRoute: '/api/data-source/profitloss/settings/grid-properties',
            filtersRoute: '/api/reports/profitloss/filters/',
            filtersDataRoute: '/api/reports/profitloss/dataSource',
            filtersSaveRoute: '/api/reports/profitloss/filters/save',
            gridExportRoute: '/api/reports/profitloss/export',
            isReport: true
        },
        {
            title: ['Sale Revenue'],
            path: '/:db/data-source/sale-revenue',
            gridRoute: '/api/data-source/revenue/settings/grid-properties',
            filtersRoute: '/api/reports/revenue/filters/',
            filtersDataRoute: '/api/reports/revenue/dataSource',
            filtersSaveRoute: '/api/reports/revenue/filters/save',
            gridExportRoute: '/api/reports/revenue/export',
            isReport: true
        },
        {
            title: ['Reserved Quantity'],
            path: '/:db/data-source/reserved-quantity',
            gridRoute: '/api/data-source/reservedquantity/settings/grid-properties',
            filtersRoute: '/api/reports/reservedquantity/filters/',
            filtersDataRoute: '/api/reports/reservedquantity/dataSource',
            filtersSaveRoute: '/api/reports/reservedquantity/filters/save',
            gridExportRoute: '/api/reports/reservedquantity/export',
            isReport: true
        },
        {
            title: ['Inventory Movement'],
            path: '/:db/data-source/inventory-movement',
            gridRoute: '/api/data-source/inventory-movement/settings/grid-properties',
            filtersRoute: '/api/reports/inventory-movement/filters/',
            filtersDataRoute: '/api/reports/inventory-movement/dataSource',
            filtersSaveRoute: '/api/reports/inventory-movement/filters/save',
            gridExportRoute: '/api/reports/inventory-movement/export',
            isReport: true
        },
        {
            title: ['Suppliers'],
            path: '/:db/data-source/suppliers',
            gridRoute: '/api/data-source/suppliers/settings/grid-properties',
            filtersRoute: '/api/reports/suppliers/filters/',
            filtersDataRoute: '/api/reports/suppliers/dataSource',
            filtersSaveRoute: '/api/reports/suppliers/filters/save',
            gridExportRoute: '/api/reports/suppliers/export',
            isReport: true
        },
        {
            title: ['Cart sales', '›', 'List'],
            path: '/:db/online/sales',
            gridRoute: '/api/carts/sales/settings/grid-properties',
            gridDataRoute: '/api/carts/sales'
        },        
        {
            title: ['Report Settings'],
            path: '/:db/report-definitions',
            gridRoute: '/api/report-definitions/settings/grid-properties',
            gridDataRoute: '/api/report-definitions'
        },
        {
            title: ['Content attributes', '›', 'List'],
            path: '/:db/content-attributes',
            gridRoute: '/api/content-attributes/settings/grid-properties',
            gridDataRoute: '/api/content-attributes'
        },
        {
            title: ['Channels', '›', 'List'],
            path: '/:db/administration/channels',
            gridRoute: '/api/channels/settings/grid-properties',
            gridDataRoute: '/api/channels'
        },
        {
            title: ['Minimal quantity', '›', 'List'],
            path: '/:db/data-source/min-quantity',
            gridRoute: '/api/min-quantity/settings/grid-properties',
            gridDataRoute: '/api/min-quantity',
            customPagination: true,
            customSelection: true
        },
        {
            title: ['Deals', '›', 'List'],
            path: '/:db/deals',
            gridRoute: '/api/deals/settings/grid-properties',
            gridDataRoute: '/api/deals'
        },
        {
            title: ['Deals Details', '›', 'List'],
            path: '/:db/deal/details',
            gridRoute: '/api/deal-details/settings/grid-properties',
            gridDataRoute: '/api/deal-details'
        },
        {
            title: ['Log Time', '›', 'List'],
            path: '/:db/log-time',
            gridRoute: '/api/log-time/settings/grid-properties',
            gridDataRoute: '/api/log-time'
        },
        {
            title: ['Incoterms', '›', 'List'],
            path: '/:db/administration/incoterms',
            gridRoute: '/api/incoterms/settings/grid-properties',
            gridDataRoute: '/api/incoterms'
        },
        {
            title: ['Event type', '›', 'List'],
            path: '/:db/administration/calendar-type',
            gridRoute: '/api/calendar-type/settings/grid-properties',
            gridDataRoute: '/api/grid/calendar-type/get/data'
        },
        {
            title: ['Calendar', '›', 'List'],
            path: '/:db/administration/calendars',
            gridRoute: '/api/calendars/settings/grid-properties',
            gridDataRoute: '/api/grid/calendars/get/data'
        },
        {
            title: ['Calendar Event', '›', 'List'],
            path: '/:db/administration/calendar-event',
            gridRoute: '/api/calendar-event/settings/grid-properties',
            gridDataRoute: '/api/grid/calendar-event/get/data'
        },
        {
            title: ['Products', '›', 'List'],
            path: '/:db/products',
            gridRoute: '/api/products/settings/grid-properties',
            gridDataRoute: '/api/products/all',
            customPagination: true
        },
        {
            title: ['Surveys', '›', 'List'],
            path: '/:db/surveys',
            gridRoute: '/api/surveys/settings/grid-properties',
            gridDataRoute: '/api/surveys/all',
            customPagination: true
        },
        {
            title: ['Account Segments', '›', 'List'],
            path: '/:db/segments',
            gridRoute: '/api/segments/settings/grid-properties',
            gridDataRoute: '/api/segments'
        },
        {
            title: ['Availability by date'],
            path: '/:db/data-source/availability-by-date',
            gridRoute: '/api/data-source/availability-by-date/settings/grid-properties',
            filtersRoute: '/api/reports/availability-by-date/filters/',
            filtersDataRoute: '/api/reports/availability-by-date/dataSource',
            filtersSaveRoute: '/api/reports/availability-by-date/filters/save',
            gridExportRoute: '/api/reports/availability-by-date/export',
            isReport: true
        },
        {
            title: ['Production Bill of Materials'],
            path: '/:db/data-source/production-bom',
            gridRoute: '/api/data-source/production-bom/settings/grid-properties',
            filtersRoute: '/api/reports/production-bom/filters/',
            filtersDataRoute: '/api/reports/production-bom/dataSource',
            filtersSaveRoute: '/api/reports/production-bom/filters/save',
            gridExportRoute: '/api/reports/production-bom/export',
            isReport: true
        },
        {
            title: ['Trial Balance'],
            path: '/:db/data-source/trial-balance',
            gridRoute: '/api/data-source/trial-balance/settings/grid-properties',
            filtersRoute: '/api/reports/trial-balance/filters/',
            filtersDataRoute: '/api/reports/trial-balance/dataSource',
            filtersSaveRoute: '/api/reports/trial-balance/filters/save',
            gridExportRoute: '/api/reports/trial-balance/export',
            isReport: true
        },
        {
            title: ['Account Movement'],
            path: '/:db/data-source/account-movement',
            gridRoute: '/api/data-source/account-movement/settings/grid-properties',
            filtersRoute: '/api/reports/account-movement/filters/',
            filtersDataRoute: '/api/reports/account-movement/dataSource',
            filtersSaveRoute: '/api/reports/account-movement/filters/save',
            gridExportRoute: '/api/reports/account-movement/export',
            isReport: true
        },
        {
            title: ['Sent Surveys', '›', 'List'],
            path: '/:db/sent/surveys',
            gridRoute: '/api/respondents/settings/grid-properties',
            gridDataRoute: '/api/respondents/all',
            customPagination: true
        },
        {
            title: ['Item Bill of Materials'],
            path: '/:db/data-source/item-bom',
            gridRoute: '/api/data-source/item-bom/settings/grid-properties',
            filtersRoute: '/api/reports/item-bom/filters/',
            filtersDataRoute: '/api/reports/item-bom/dataSource',
            filtersSaveRoute: '/api/reports/item-bom/filters/save',
            gridExportRoute: '/api/reports/item-bom/export',
            isReport: true
        },
    ];
}
