import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import SettingsMenu from '../../sidebar/settings-menu';
import ListCheckboxes from './../listCheckboxes.jsx';
import SingleCheckbox from './../singleCheckbox.jsx';
import { client} from "../../../actions/actions";
import OpenSection from './../openSection.jsx';
import { fetchSettings} from "../../../actions/actions";

class InvoiceSettingsPage extends Component {

    constructor(props) {
    super(props);
            this.state = {
            showMessage: false,
            };
        this.allSettings = [
            { value: "name", name: 'Name', text: 'Add a text field name in an invoice'},
            { value: "currency", name: 'Document multi currency', text: 'Select a currency for whole invoice'},
            { value: "lineDiscount", name: 'Invoice line discount', text: 'Choose whether a discount can be set on each item line'},
            { value: "lineVat", name: 'Invoice line vat', text: 'Choose whether the tax rate can be set on each item line'},
            { value: "totalDiscount", name: 'Invoice total discount', text: 'Choose whether a discount can be applied on invoices'},
            { value: "lineCurrency", name: 'Multi currencies', text: 'Select a currency on each item line'},
            { value: "measures", name: 'Measures', text: 'Select the measure applicable on each item line'},
            { value: "project", name: 'Project', text: 'Select the project applicable on each item line'},
            { value: "lineComment", name: 'Invoice line comment', text: 'Add comment for additional information on each item line'},
            { value: "invoiceAccount", name: 'Invoice account', text: 'Choose whether an account can be applied on invoices'},
            { value: "incoterm", name: 'Incoterm', text: 'Add a text field for incoterm in an invoice'},
            { value: "customText1", name: 'Custom text 1', text: 'Add a text field for additional information in an invoice'},
            { value: "customText2", name: 'Custom text 2', text: 'Add a text field for additional information in an invoice'},
            { value: "customText3", name: 'Custom text 3', text: 'Add a text field for additional information in an invoice'},
            { value: "customText4", name: 'Custom text 4', text: 'Add a text field for additional information in an invoice'},
            { value: "customText5", name: 'Custom text 5', text: 'Add a text field for additional information in an invoice'},
            { value: "additionalData", name: 'Additional Data', text: ''}
        ];
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/invoices/settings/invoice-settings', 'invoiceSettings');
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

     render() {

        return (
                <div>
                    <SettingsMenu
                       location = {this.props.location.pathname} db = {this.props.match.params.db}/>
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                   <div className="settings">
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Invoice Sequential No')}
                                            description={plainTranslate(this.props.settings.locale, "Set the format of the sequential number of invoices")}
                                            component={'input'}
                                            value ={this.props.settings.invoiceSettings}
                                            type={'invoiceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/invoices/save/settings/individual'}
                                            setting={'seqNo'}
                                         />
                                         <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale,'Invoice Template')}
                                            description={plainTranslate(this.props.settings.locale,'Set the default template for printing invoices')}
                                            component={'select'}
                                            value ={this.props.settings.invoiceSettings}
                                            type={'invoiceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/invoices/save/settings/individual'}
                                            setting={'invoiceTemplate'}
                                         />
                                         <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale,'Invoice Sorting')}
                                            description={plainTranslate(this.props.settings.locale,"Set the way a document's lines to be sorted.")}
                                            component={'select'}
                                            value ={this.props.settings.invoiceSettings}
                                            type={'invoiceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/invoices/save/settings/individual'}
                                            setting={'invoiceSorting'}
                                         />
                                         <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Export folder')}
                                            description={plainTranslate(this.props.settings.locale, "Choose a folder from those available in Documents module, where a copy of the invoice will be saved")}
                                            component={'select'}
                                            value ={this.props.settings.invoiceSettings}
                                            type={'invoiceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/invoices/save/settings/individual'}
                                            setting={'exportDir'}
                                         />
                                         <hr/>
                                         <SingleCheckbox
                                            value={this.props.settings.invoiceSettings}
                                            label={plainTranslate(this.props.settings.locale, 'Enable/Disable item batch')}
                                            description={plainTranslate(this.props.settings.locale, 'Enable/Disable item batch tracking for items in Invoices')}
                                            settings={this.props.settings}
                                            type={'invoiceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/invoices/save/settings/individual'}
                                            setting = {'enableSerial'}
                                                />
                                         <hr/>
                                         <SingleCheckbox
                                            value={this.props.settings.invoiceSettings}
                                            label={plainTranslate(this.props.settings.locale, 'Enable/Disable pack')}
                                            description={plainTranslate(this.props.settings.locale, 'Enable/Disable pack field in Invoices')}
                                            settings={this.props.settings}
                                            type={'invoiceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/invoices/save/settings/individual'}
                                            setting = {'enableSecondMeasure'}
                                                />
                                         <hr/>
                                         <SingleCheckbox
                                            value={this.props.settings.invoiceSettings}
                                            label={plainTranslate(this.props.settings.locale, 'Show stock availability')}
                                            description={plainTranslate(this.props.settings.locale, 'Show stock availability in Invoices')}
                                            settings={this.props.settings}
                                            type={'invoiceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/invoices/save/settings/individual'}
                                            setting = {'enableStockAvailability'}
                                                />
                                         <hr/>
                                         <SingleCheckbox
                                            value={this.props.settings.invoiceSettings}
                                            label={plainTranslate(this.props.settings.locale, 'Show Stock Availability By Pack')}
                                            description={plainTranslate(this.props.settings.locale, 'Show pack availability in Invoices')}
                                            settings={this.props.settings}
                                            type={'invoiceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/invoices/save/settings/individual'}
                                            setting = {'enableStockAvailabilityMeasure2'}
                                                />
                                         <hr/>
                                        <div className="col-sm-6 column-centered">
                                        <ListCheckboxes
                                            id={'settings'}
                                            value={this.props.settings.invoiceSettings ? this.props.settings.invoiceSettings : null}
                                            label={'Settings'}
                                            settings={this.props.settings}
                                            fields={this.allSettings}
                                            saveRoute={'/' + this.props.match.params.db + '/api/invoices/save/settings/data'}
                                            type = {'invoiceSettings'}
                                            setting = {'form'}
                                        />
                                        </div>
                                   </div>
                                </div>
                             </section>
                          </div>
                       </div>
                    </form>
                </div>
                );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
   getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSettingsPage);
