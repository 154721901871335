import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Message from '../common/message';
import Translate, {plainTranslate} from '../common/translate';
import ModalActivityForm from './modalForm';
import FirstModal from './firstModal';

class Onboarding extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount(){
        if(this.props.isOpen !== null){
            this.setState({isOpen: false});
        }else{
            this.setState({isOpen: true});
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.isOpen !== this.props.isOpen){
            if(this.props.isOpen !== null){
                this.setState({isOpen: false});
            }else{
                this.setState({isOpen: true});
            }
        }
    }
   
    closeModal() {
        this.setState({isOpen: false});
    }
    
    render() {
        return (
                <div>
                
                   <ModalActivityForm
                        show={this.state.isOpen}
                        >
                        <FirstModal 
                            db={this.props.db}
                            locale={this.props.locale}
                            userName={this.props.user}
                            closeModal={this.closeModal}
                        />
                    </ModalActivityForm>
                </div>
                );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});


export default connect(mapStateToProps,mapDispatchToProps)(Onboarding);