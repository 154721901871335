import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Menu, Dropdown, Button, List, Input, Table, Radio, Collapse } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import {connect} from 'react-redux';
import { plainTranslate } from '../common/translate';
import {Link} from 'react-router-dom';
import client from '../../actions/client';
import { withRouter } from 'react-router-dom'
const { Panel } = Collapse;

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target) || (typeof event.target.className === 'string' && event.target.className.includes('custom-filters'))) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

const CustomFilters = (props, state) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(props.selectedFilters);
  const [deSelectedKeys, setDeSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('no-value');
  const [selectedKeys, setSelected] = useState([]);
  const [filtersChanged, setFiltersChanged] = useState(false);
  let inputTimer;

  const ref = useRef();
  const isInitialMount = useRef(true);

  useOnClickOutside(ref, () => setIsOpen(false));
  const rowSelection = {
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if(selected){
          let keys = Object.assign({}, selectedValues);

          if(!selectedValues[searchValue]){
            keys[searchValue] = {keys: [record.value], deSelectedKeys: [], selectAll: selectAll};
          }else{
            let newKeys = keys[searchValue].deSelectedKeys.filter(item => item !== record.value);
            keys[searchValue].deSelectedKeys = newKeys;
            keys[searchValue].keys.push(record.value);
          }
          setSelectedValues(keys);

          if(selectAll){
            setSelected([...selectedKeys, record.value]);
          }else{
            setSelected([...selectedKeys, record.value]);
          }

        }else{
          let keys = Object.assign({}, selectedValues);
          if(!selectedValues[searchValue]){
            if(keys['no-value']['selectAll']){
              keys[searchValue] = {keys: [], deSelectedKeys: [record.value], selectAll: selectAll};
            }
          }else{
            if(keys['no-value'] && keys['no-value']['selectAll']){
              keys[searchValue].deSelectedKeys.push(record.value);
            }
            let newKeys = keys[searchValue].keys.filter(item => item !== record.value);
            keys[searchValue].keys = newKeys;
            if(keys[searchValue].keys.length == 0 && keys[searchValue].deSelectedKeys == 0 && keys[searchValue].selectAll === false){
              delete keys[searchValue];
            }
          }
          setSelectedValues(keys);

          Object.keys(keys).map(function(key, index) {
            if(key !== searchValue){
              keys[key].keys = keys[key].keys.filter(item => item !== record.value);
            }
          });

          setSelected(selectedKeys.filter(item => item !== record.value));
        }
        setFiltersChanged(true);
      }
  };

  function onSelectAll(options){
    let keys = Object.assign({}, selectedValues);
    keys[searchValue] = {keys: [],deSelectedKeys: [], selectAll: true};

    setSelectedValues(keys);
    setSelected(options.map(a => a.key));
    setSelectAll(true);
    setDeSelected([]);
    setFiltersChanged(true);
  }

  function onDeSelectAll(){
    setSelectAll(false);
    if(searchValue === 'no-value'){
      setSelectedValues([]);
      setSelected([]);
    }else{
      let keys = Object.assign({}, selectedValues);
      delete keys[searchValue];

      let selected = options.map(a => a.key);

      Object.keys(keys).map(function(key, index) {
        keys[key].keys = keys[key].keys.filter(( el ) => !selected.includes( el ));
      });
      setSelectedValues(keys);

      setSelected(selectedKeys.filter( ( el ) => !selected.includes( el )));
    }
    setFiltersChanged(true);
  }

  function onClickOnly(record){
    let keys = Object.assign({}, selectedValues);
    Object.keys(keys).map(function(key, index) {
      if(key !== searchValue){
        delete keys[key];
      }
    });

    keys[searchValue] = {keys: [record.value], deSelectedKeys: [], selectAll: false};

    setSelectedValues(keys);
    setSelectAll(false);
    setSelected([record.value]);
    setFiltersChanged(true);
  }

  function showOptions(value){
    if(options === undefined || options === null){
      setLoading(true);
      onSearchChange(null,value);
    }
    setIsOpen(!isOpen);
  }

  function onSearchChange(e, filter){
    let url = filter.optionsUrl + '?startDate=' + props.startDate + '&endDate=' + props.endDate + (props.match.params.refs ? '&refs='+props.match.params.refs : '');
    let timeout = 1;
    if(e && e.target){
      url = url +'&q='+e.target.value;
      timeout = 2000;
    }

    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
      setLoading(true);
      
      client.post(url, {
        selectedValues
      })
      .then(response => {
        if(e && e.target){
          setSearchValue(e.target.value === '' ? 'no-value' : e.target.value);
        }
        let arr = options;
        arr = response.data;
        setOptions(arr);

        //let newValues = selectedKeys.concat(arr.filter((item,pos) => item.selected == 1 && selectedKeys.indexOf(item) === pos).map(a => a.key));

        let newValues = selectedKeys.concat(arr.filter((item,pos) => item.selected == 1).map(a => a.key));
        setSelected(newValues);

        let searchString = 'no-value';

        if(e && e.target){
          searchString = e.target.value === '' ? 'no-value' : e.target.value;;
        }

        let selectedAll = false;
        
        if(selectedValues[searchString]){
          selectedAll = selectedValues[searchString].selectAll;
        }
        setSelectAll(selectedAll);
        setLoading(false);
      })
    }, timeout);
  }

  useEffect(() => {
      setOptions(props.filter.options);

  }, [])

  useEffect(() => {
      setSelectedValues(props.selectedFilters);
      setSelected([]);
      setOptions(null);
      setFiltersChanged(false);
  }, [props.gridChanged])

  useEffect(() => {
    if(filtersChanged){
      props.onChange(props.filter.type, selectedValues);
    }
  }, [selectedValues])

  useEffect(() => {
    if(props.clear){
      setSelectedValues([]);
      setSelected([]);
      setFiltersChanged(true);
    }
  }, [props.clear])

  function getMenu(filter){
    const columns = [
      {
        title: 'Name',
        dataIndex: 'label',
        render: (text,record) => <span>{record.label}</span>
      },
      {
        title: 'Action',
        dataIndex: 'value',
        render: (text,record) => <Button className="only-button" onClick={() => onClickOnly(record)}>{plainTranslate(props.settings.locale, "Only")}</Button>
      },
    ];

    const menu = (<div className="demo-infinite-container"  ref={ref}>
      <Input placeholder={plainTranslate(props.settings.locale,"Search...")} onChange={(val) => onSearchChange(val,filter)}/>
      {filter.action !== 'having' && <Button onClick={() => onSelectAll(options)}>{plainTranslate(props.settings.locale, "Select All")}</Button>}<Button onClick={() => onDeSelectAll()}>{plainTranslate(props.settings.locale, "Clear Selected")}</Button>
      <Table
       rowSelection={{
          type: 'checkbox',
          ...rowSelection,
          selectedRowKeys: selectedKeys
        }}
        rowClassName={(record, index) => {
          return "grid-custom-filter-row"
        }}
        size={"small"}
        columns={columns}
        dataSource={options}
        pagination={false}
        showHeader={false}
        loading={loading}
        className="custom-filters-table"
      />
    </div>);
    return menu;
  }

  return (<div>
    {window.innerWidth >= 768 && 
      <div className="custom-filters-container">
        <Dropdown className={"custom-filters" + (Object.keys(selectedValues).length > 0 ? ' custom-filter-selected' : '')} overlay={() => getMenu(props.filter)} placement="bottomLeft" visible={isOpen}>
          <Button  onClick={() => showOptions(props.filter)}><span className={"custom-filters"}>{plainTranslate(props.settings.locale,props.filter.label)}</span><DownOutlined /></Button>
        </Dropdown>
      </div>
    }
    {window.innerWidth < 768 &&
      <div>
        <Collapse className="mobileFiltesGridFonts" accordion={true}>
           <Panel header={<span style={{width: '100%'}} onClick={() => showOptions(props.filter)}> {plainTranslate(props.settings.locale,props.filter.label)}</span>}>
              {getMenu(props.filter)}
            </Panel>
        </Collapse>
        
      </div>
    }
    
    </div>)
  ;
};

const mapStateToProps = state => ({
  settings: state.settings
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomFilters));