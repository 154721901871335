import {
  ADD_NOTIFICATION,
  GET_CURRENT_NOTIFICATIONS,
  COUNT_UNREAD,
} from "../constants/actionTypes";
import client from './client';

// Get current notifications
export const sendNotification = (data, socket) => dispatch => {  
  var config;
  return dispatch => {
    return new Promise((resolve, reject) => {
       let url = '/api/admin/notifications-form/create/';
       if(typeof data.id !== 'undefined'){
           url = url + data.id
       }       
      //send to api and after success send to socket
      client.post(url, {
          credentials: 'same-origin',
          headers: {
              'content-type': 'application/json'
          },
          body: JSON.stringify({
            body: data
          })
      })
      .then(res => res.data)
      .then(res => {
         data = res.data
         data.serverType = ADD_NOTIFICATION  
         //TO DO ???
         data.apiId = config.API_ID
         socket.socket.send(JSON.stringify(data))
          dispatch({
            type: ADD_NOTIFICATION,
            payload: res.data
          })
      })
      .catch(err => {       
        console.log('error')
      });
    });
  }
}

export const receivedNotification = (data) => dispatch => {  
    //if notification is for this user add or change notification
      dispatch({
        type: ADD_NOTIFICATION,
        payload: data
      })
};

export const getCurrentNotifications = (url) => dispatch => { 
      client
        .get(url)
        .then(res => res.data)
        .then(res => {
          dispatch({
            type: GET_CURRENT_NOTIFICATIONS,
            payload: res.notifications
          })
          dispatch({
            type: COUNT_UNREAD,
            payload: res.notificationCount
          })
        })
        .catch(err => {       
          console.log(err)       
    })  
}
 


export const changeStatusNotification = (notification, status, socket) => dispatch => { 
  return dispatch => {
    return new Promise((resolve, reject) => { 
      let url = '/api/admin/notifications-list/'+status+'/change/status';
      let  data = notification;    
        
        //send to api and after success send to socket
          client.post(url, {
              credentials: 'same-origin',
              headers: {
                  'content-type': 'application/json'
              },
              body: JSON.stringify({
                body: data.id
              })
          })
         .then(res => {
           
             data.serverType = ADD_NOTIFICATION  
             data.status = status
             socket.socket.send(JSON.stringify(data))
             
              dispatch({
                type: ADD_NOTIFICATION,
                payload: data
              })
          })
          .catch(err => {       
            console.log('error')   
          });
    });
  };
};


