import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';

class InfoSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isExpanden:false
        };
        this.handleToggle = this.handleToggle.bind(this);
    }
    
    handleToggle(e) {
        e.preventDefault();
        this.setState({
            isExpanded: !this.state.isExpanded
        })
    }

    render() {
        return (
                <div>
                    <div className="head-line">
                        <h3>{this.props.title}</h3>
                    </div>   
                    <a className={`account-profile-show ${this.state.isExpanded ? 'rotate' : ''}`} id="note" href="" onClick={this.handleToggle}><i className="glyphicon glyphicon-chevron-down"></i></a>
                    <div className={`account-profile-other-information-more ${this.state.isExpanded ? ' ' : 'hide'}`}>
                        {this.props.children}
                    </div>
                </div>
                );
    }
}

const mapStateToProps = state => ({
        settings: state.settings,
        employeeProfile: state.employeeProfile
    });
const mapDispatchToProps = dispatch => ({

    });

export default connect(mapStateToProps, mapDispatchToProps)(InfoSection);


