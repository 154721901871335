import { PlusOutlined } from '@ant-design/icons';
import { Input, Tag, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Field from '../field';
import {Field as FormField, reduxForm} from 'redux-form';

const AttributeOptions = (props) => {console.log(props.field.fields)
  return (
    <>
      {
     (props.formData.typeInput === 'dropdown' || props.formData.typeInput === 'multipleSelect') && props.field.fields.map((field, key) => {
        return <FormField
          //locale={props.settings.locale}
          name={field.id}
          label={field.label}
          field={field}
          fields={props.fields}
          component={Field}
          change={props.change}
          type={field.type}
          history={props.history}
          formData={props.formData}
          placeholder={field.placeholder}
        />
      })}
    </>
  );
};
export default AttributeOptions;