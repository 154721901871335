import React from 'react';
import Translate, { plainTranslate } from '../../common/translate';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ModalActivityForm from './ModalActivityForm';
import ChartOfAccounts from './chartOfAccounts/chartOfAccounts';
import client from './../../../actions/client';

class LedgerLine extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showMessage: false,
            message: '',
            ledgerLine: props.ledgerLine,
            settings: props.settings,
            isOpen: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.loadChartOfAccountSettings = this.loadChartOfAccountSettings.bind(this);
        this.closeForm = this.closeForm.bind(this);
        this.handleShowForms = this.handleShowForms.bind(this);
        this.loadChartOfAccountsData = this.loadChartOfAccountsData.bind(this);
        this.loadBillingData = this.loadBillingData.bind(this);
        this.loadCurrenciesData = this.loadCurrenciesData.bind(this);
    }
    
    componentDidMount() {
        this.loadBillingData();
        this.loadChartOfAccountsData();
        this.loadCurrenciesData();
  }
  
    componentDidUpdate(prevProps) {    
        if (prevProps.settings !== this.props.settings) {
            this.setState({
                settings: this.props.settings
            });
        }
    }
    
    handleChangeDate(date, ind) {
       let ledgerLine = Object.assign({}, this.state.ledgerLine);    
       ledgerLine.date = date;   
       this.setState({ledgerLine}, function () {
            this.props.updateLines('date', moment(this.state.ledgerLine['date']).format('DD.MM.YYYY'), ind);
        });
    }; 
    
    handleChange(property, e, ind) {
        let ledgerLine = this.state.ledgerLine;

        let value = e.target.value;

        if (property === "debitCredit") {
            if (value === "C") {
                ledgerLine['amountOriginal'] = Math.abs(ledgerLine['amountOriginal'])*(-1);
            } else {
                ledgerLine['amountOriginal'] = Math.abs(ledgerLine['amountOriginal'])*(1);
            }
        }
        
        ledgerLine[property] = value;   

        this.setState({
            ledgerLine
        });
        this.props.updateLines(property, value, ind)
    }
    
    handleRemove(ind) { 
        this.props.update(ind);
    }
    
    loadBillingData(inputValue, callback) {
        client.post('/' + this.props.db + '/api/v1/accounting/ledger_lines/billing_data', {q:inputValue})
        .then(response => response.data)
        .then(response => {
            if (callback) {
                callback(response);
            }
        });
    }
    
    loadChartOfAccountsData(inputValue, callback) {
        client.post('/' + this.props.db + '/api/v1/accounting/ledger_lines/chart_of_accounts', {q:inputValue})
        .then(response => response.data)
        .then(response => {
            if (callback) {
                callback(response);
            }
        });
    }
    
    loadCurrenciesData(inputValue, callback) {
        client.post('/' + this.props.db + '/api/v1/accounting/ledger_lines/currencies', {q:inputValue})
        .then(response => response.data)
        .then(response => {
            if (callback) {
                callback(response);
            }
        });
    }
    
    handleSelectChange(prop, selectedOption, ind) {
        let ledgerLine = this.state.ledgerLine;

        let value = selectedOption;
        ledgerLine[prop] = value;   

        this.setState({
            ledgerLine
        });
        this.props.updateLines(prop, value, ind);
  }
  
    loadChartOfAccountSettings(selectedOption, ind) {
        var settings ='';
        client.post('/' + this.props.db + '/api/v1/accounting/ledger/chart_of_account/'+selectedOption.value+'/find/settings')
        .then(response => response.data)
        .then(response => {
            settings = response;
        });

        let ledgerLine = this.state.ledgerLine;

        let value = selectedOption;
        ledgerLine['account'] = value;   
        ledgerLine['settings'] = settings;  

        this.setState({
            ledgerLine,
        });

        this.props.updateLines('account', value, ind);
        this.props.updateLines('settings', settings, ind);    
    }
    
    closeForm(event) {
        event.preventDefault();
        this.setState({
            isOpen: false,
        });
    }
    
    handleShowForms(event) {
        this.setState(prevState => ({isOpen: !prevState.isOpen}));
    }
 
    
    render() {
        return (
                    <tr id={`ims-line-row-${this.props.ind}`} className="ims-line-row item">
                        <td data-title="Menu">
                            <a href="" className="handle ui-sortable-handle">
                                <i className="fa fa-arrows"></i>
                            </a>
                        </td>
                        <td data-title={plainTranslate(this.props.locale, '#')} className="row-no">
                            <span>{this.props.ind}</span>
                        </td>
                        {this.state.settings && this.state.settings.includes('reference') &&
                            <td data-title={plainTranslate(this.props.locale, 'Reference')} className="text-center ims-line-no">
                            <span className="form-input-field">
                                <input type="text" className="form-control"
                                value = {this.state.ledgerLine.reference}
                                onChange={(e) => this.handleChange('reference', e, (this.props.ind-1))}/>
                            </span>
                            </td>
                        }
                        {this.state.settings && this.state.settings.includes('date-line') &&
                        <td data-title={plainTranslate(this.props.locale, 'Date')} className="ims-line-date">
                            <DatePicker
                                selected={this.state.ledgerLine.date? moment(this.state.ledgerLine.date):''}
                                onChange={(date) => this.handleChangeDate(date, (this.props.ind-1))}
                                showMonthDropdown
                                showYearDropdown
                                locale={this.locale}
                                dateFormat="DD.MM.YYYY"
                                todayButton={"Today"}
                            />
                        </td>
                        }
                        <td data-title={plainTranslate(this.props.locale, 'Account')} className="text-center ims-line-account">
                        <div className="async-select">                    
                        <AsyncSelect
                                defaultValue={this.state.ledgerLine.account}
                                onChange={(selectedOption) => this.loadChartOfAccountSettings(selectedOption, (this.props.ind-1))}
                                loadOptions={this.loadChartOfAccountsData}
                                defaultOptions
                                isClearable
                                onCreateOption={this.handleCreate}
                            />
                            <div className="add-button">
                        <i className="fa fa-plus" onClick={this.handleShowForms}></i>
                        </div>
                        </div>
                        </td>
                        <td data-title={plainTranslate(this.props.locale, 'Amount Original')} className="text-center ims-line-amountOriginal">
                            <input type="text" className="text-right form-control" 
                            value = {this.state.ledgerLine.amountOriginal}
                            onChange={(e) => this.handleChange('amountOriginal', e, (this.props.ind-1))}/>
                        </td>
                        {this.state.settings && this.state.settings.includes('multy-currency') &&
                        <td data-title={plainTranslate(this.props.locale, 'Currency')} className="text-center ims-line ims-line-currencyOriginal">
                            <AsyncSelect
                                defaultValue={this.state.ledgerLine.currency}
                                onChange={(selectedOption) => this.handleSelectChange('currency', selectedOption, (this.props.ind-1))}
                                loadOptions={this.loadCurrenciesData}
                                defaultOptions
                                isClearable
                            />
                        </td>
                        }
                        <td data-title={plainTranslate(this.props.locale, 'Currency rate')} className="text-center ims-line ims-line-currencyRate">
                            <input type="text" style={{width: 100+'px'}} className="text-right form-control" 
                                value={this.state.ledgerLine.currencyRate}
                                onChange={(e) => this.handleChange('currencyRate', e, (this.props.ind-1))}/>
                        </td>
                        <td data-title={plainTranslate(this.props.locale, 'Amount Local')} className="text-right ims-line ims-line-amountLocal">
                            {this.state.ledgerLine.amountOriginal * this.state.ledgerLine.currencyRate}
                        </td>
                        <td data-title={plainTranslate(this.props.locale, 'Debit or Credit')} className="text-right ims-line ims-line-debitCredit">
                            <select id="ledger_lines_0_debitCredit" name="ledger[lines][0][debitCredit]" className="form-control"
                            value = {this.state.ledgerLine.debitCredit}
                            onChange={(e) => this.handleChange('debitCredit', e, (this.props.ind-1))}>
                                <option value="D">Debit</option>
                                <option value="C">Credit</option>
                            </select>
                        </td>
                        {this.state.settings && this.state.settings.includes('account-billing') &&
                        <td data-title={plainTranslate(this.props.locale, 'Billing Data')} className="text-center ims-line-billingData">
                            <AsyncSelect
                                defaultValue={this.state.ledgerLine.billingData}
                                onChange={(selectedOption) => this.handleSelectChange('billingData', selectedOption, (this.props.ind-1))}
                                loadOptions={this.loadBillingData}
                                defaultOptions
                                isClearable
                            />
                        </td>
                        }
                        {this.props.acc_analysis && this.props.acc_analysis.map((val, i) => (
                        <td data-title={plainTranslate(this.props.locale, (val.name))} className="text-right ims-line ims-line-analysis">
                                {(this.state.ledgerLine 
                                        && this.state.ledgerLine.settings 
                                        &&  this.state.ledgerLine.settings.map((item, key) => (
                                        <span className="form-input-field">
                                        {item.type == val.type &&
                                            <select className="form-control" placeholder={item.name} key={item.position}
                                            value={this.state.ledgerLine['analysis'+(item.position)]?this.state.ledgerLine['analysis'+(item.position)]:null}
                                            onChange={(e) => this.handleChange(['analysis'+(item.position)], e, (this.props.ind-1))}
                                            >
                                            <option value=''>&nbsp;</option>
                                            {item.options && item.options.map((val, ind) => (
                                                 <option key={ind} value={val.id}>{val.name}</option>
                                              ))}  
                                            </select>
                                            }
                                        </span> 
                           )))} 
                        </td>
                        ))}
                        {this.state.settings && this.state.settings.includes('comment-line') &&
                        <td data-title={plainTranslate(this.props.locale, 'Description')} className="ims-line-description">
                            <span className="form-input-field">
                                <input type="text" placeholder="Description" className="form-control" 
                                value = {this.state.ledgerLine.description}
                                onChange={(e) => this.handleChange('description', e, (this.props.ind-1))}/>
                            </span>
                        </td>
                        }
                        <td>
                            <a href="#" onClick={(e) => this.handleRemove(this.props.ind, e)}>
                                <i className="ion-android-close"></i>
                            </a>
                        </td>
                   <ModalActivityForm
                        show={this.state.isOpen}
                        onClose={this.closeForm}>
                        <ChartOfAccounts 
                            locale={this.props.locale}
                            db={this.props.db}
                            path={this.props.path}
                            id={this.props.id}        
                        />
                    </ModalActivityForm>
                    </tr>
                );
    }
}

export default LedgerLine;