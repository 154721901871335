import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";

class Contacts extends React.Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.addNewContact = this.addNewContact.bind(this);
        this.removeContact = this.removeContact.bind(this);
    }

    onChange(e, type, key) {
        this.props.change('contacts['+key+']['+type+']', e.target.value);
    }
    
    addNewContact() {
        let contacts = this.props.formData.contacts;
        let newContact = {type: '1', value: '', id: ''};
        contacts.push(newContact);
        this.setState({
            contacts
        });
    }
    
    removeContact(key) {
        let contacts = this.props.formData.contacts;
        delete contacts[key];
        this.setState({
            contacts
        });
    }

    render() {
        return <React.Fragment>
            {this.props.formData.contacts && this.props.formData.contacts.length > 0 && this.props.formData.contacts.map((contact, key) =>
                        <React.Fragment>
                            <select className="form-control form-contacts" key={key + 'type'} value={contact.type} onChange={(e) => this.onChange(e, 'type', key)}>
                                <option value='1'>{plainTranslate(this.props.locale, 'Email')}</option>
                                <option value='2'>{plainTranslate(this.props.locale, 'Phone')}</option>
                                <option value='3'>{plainTranslate(this.props.locale, 'Mobile')}</option>
                                <option value='4'>{plainTranslate(this.props.locale, 'Google plus')}</option>
                                <option value='5'>{plainTranslate(this.props.locale, 'Facebook')}</option>
                                <option value='6'>{plainTranslate(this.props.locale, 'Linkedin')}</option>
                                <option value='7'>{plainTranslate(this.props.locale, 'Twitter')}</option>
                                <option value='8'>{plainTranslate(this.props.locale, 'Skype')}</option>
                                <option value='9'>{plainTranslate(this.props.locale, 'Fax')}</option>
                                <option value='10'>{plainTranslate(this.props.locale, 'Website')}</option>
                                <option value='11'>{plainTranslate(this.props.locale, 'Other')}</option>
                                <option value='12'>{plainTranslate(this.props.locale, 'Personal number')}</option>
                            </select>
                            <input className="form-control form-contacts" key={key + 'value'} value={contact.value} onChange={(e) => this.onChange(e, 'value', key)}/>
                            <i className="fa fa-trash contact-icon" aria-hidden="true" onClick = {() => this.removeContact(key)} title={plainTranslate(this.props.locale, 'Remove contact')}></i>
                            <br/>
                        </React.Fragment>
                        )}
                <button className="button-outline grey " onClick = {this.addNewContact}>{plainTranslate(this.props.locale, 'Add new contact')}</button>
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    });

const mapDispatchToProps = dispatch => ({
    });

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
