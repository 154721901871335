import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';

class CustomButton extends React.Component {

    constructor(props) {
        super(props);
        this.clickButton = this.clickButton.bind(this);
    }
    
    clickButton() {
//        console.log(this.props.field.linkUrl);  линк към където трябва да отиде потребителя след действието
//        console.log(this.props.field.actionUrl); линк на действието
//        console.log(this.props.formData.ids); ид нужно за действията
        client.post(this.props.field.actionUrl + '?ids=' + this.props.formData.ids)
                        .then(response => response.data)
                        .then(response => {
                           if (response['success']) {
                                showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
                                this.props.history.push(this.props.field.linkUrl);
                           } else {
                                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
                           }
                })
    }

    render() {
        return <React.Fragment>
                <button className="button-outline red " type="button" name={this.props.field.id} onClick={this.clickButton}>{plainTranslate(this.props.locale, this.props.field.buttonText)}</button>
             </React.Fragment>
    }
}
    
const mapStateToProps = state => ({
    
    });

const mapDispatchToProps = dispatch => ({

    });

export default connect(mapStateToProps, mapDispatchToProps)(CustomButton);
