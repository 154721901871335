import React, {Component} from 'react';
import Loader from 'react-loader-spinner';


export default class LoadingOverlay extends Component {
    render() {
        return (
            <div>
                <Loader
                    type="Grid"
                    color={this.props.color ? this.props.color : '#e6767f'}
                    height="100"
                    width="100"
                />
            </div>
        );
    }
}

