import React, { Component } from 'react';
import Translate, { plainTranslate } from '../common/translate';
import { connect } from 'react-redux';
import { onDocumentProfileFetchData, onEditOptionsLoad, onTabsLoad } from '../../actions/actions';
import { Link } from 'react-router-dom';
import FormLoader from '../forms/form-loader';
import GeneralInformation from './general-information';
import InvoicesInfo from './invoices-info';
import PreviousDocuments from './previous-documents';
import LoggedChanges from './logged-changes';
import ReversedDocs from './reversed-docs';
import './invoiceProfile.css';
import Select from 'react-select';
import NewForm from "../redux-forms/form";
import { Button, Menu, Dropdown, Tooltip } from 'antd';
import 'antd/lib/button/style/index.css';
import 'antd/lib/dropdown/style/index.css';
import Popup from "../common/popup";
import client from '../../actions/client';
import showAToast from '../common/showAToast';
import { SendOutlined } from '@ant-design/icons';
import Form from "../redux-forms/form";
import SlideModal from '../common/SlideModal';

class InventoryProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            template: null,
            active: 'receipt',
            accountingTemplate: null,
            disableButton: false,
            receiptTemplateCc: false
        };
        this.handleTemplateChange = this.handleTemplateChange.bind(this)
        this.update = this.update.bind(this)
    }

    componentDidMount() {
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/receipt/' + this.props.match.params.id + '/profile-info').then(() => {
            this.setState({ template: this.props.inventoryProfileInformation.invoiceProfileInfo.defaultTemplate.id })
        });
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
        this.setState({ active: localStorage.getItem('activeInventoryProfileTab') ? localStorage.getItem('activeInventoryProfileTab') : 'receipt' });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.update();
        }
    }

    handleTemplateChange(e) {
        this.setState({ template: e.value });
    }

    update() {
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/receipt/' + this.props.match.params.id + '/profile-info').then(() => {
            this.setState({ disableButton: false });
        });;
    }

    changeTab(tab) {
        localStorage.setItem('activeInventoryProfileTab', tab);

        this.setState({ active: tab });
    }

    createAccounting(id) {
        this.setState({ disableButton: true });
        let addVal = {};
        addVal['body'] = id;
        client.post('/' + this.props.match.params.db + '/api/inventories/transfer/to/accounting/' + this.state.accountingTemplate, addVal)
            .then(response => response.data)
            .then(response => {
                if (response['success']) {
                    showAToast(response.success, 'baseSuccess', 'success');
                } else {
                    showAToast(response.errors, 'baseError', 'error');
                }
                this.setState({
                    accountingTemplate: false
                });
                this.update();
            })
    }

    sentEmailTemplate = () => {
        this.setState({ emailPopup: true });
    }


    render() {
        let roles = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.roles : [];
        let receipt = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.receipt : {};
        let docNo = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.docNo : '';
        let currencySign = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.currency : null;
        let stages = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.stages : [];
        let previous = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.previous : [];
        let templates = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.templates : [];
        let invoices = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.invoices : [];
        let sales = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.sales : [];
        let reversedReceipts = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.reversedReceipts : [];
        let changes = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.changes : [];
        let ledger = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.ledger : null;
        let accountingOptions = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.accOptions : null;
        let isAccounting = this.props.inventoryProfileInformation.invoiceProfileInfo ? this.props.inventoryProfileInformation.invoiceProfileInfo.isAccounting : null;

        if (this.props.inventoryProfileInformation.loading && !this.props.inventoryProfileInformation.invoiceProfileInfo) {
            return <FormLoader />
        }
        let accOptionsMenu;
        if (accountingOptions) {
            accOptionsMenu = (
                <Menu>
                    {accountingOptions.map((option, i) => {
                        return <Menu.Item key={i}>
                            <a onClick={() => this.setState({ accountingTemplate: option['id'] })}>
                                <Translate locale={this.props.settings.locale} value={option['name']} />
                            </a>
                        </Menu.Item>
                    })}
                </Menu>
            );
        }

        return (
            <div className="invoice-profile item-profile">
                <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                    <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb">
                        {!this.props.gridPopup &&
                            <Link to={"/" + this.props.match.params.db + "/inventory-v2"}>
                                <i className="ion-ios-arrow-back"></i>{plainTranslate(this.props.settings.locale, 'Inventory')}
                            </Link>
                        }
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12 no-gutter">
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information">
                            <GeneralInformation
                                roles={roles && roles.crm}
                                document={receipt}
                                docNo={docNo}
                                showAmount={roles?.showAmount}
                                stages={stages}
                                currency={currencySign}
                                update={this.update}
                                type="inventory"
                                changeStageUrl={'/' + this.props.match.params.db + '/api/inventories/'}
                            />
                            {receipt &&
                                <div className="account-profile-other-information">
                                    <hr className="col-lg-12 col-md-12 col-xs-12 no-gutter" />
                                    {(receipt.docType == '1' || receipt.docType == '2') &&
                                        <React.Fragment>
                                            <InvoicesInfo invoices={invoices} />
                                            <PreviousDocuments roles={roles.sales} previousDocuments={sales} title="Sales" type="sale" />
                                            <PreviousDocuments roles={roles.inventory} previousDocuments={previous} title="Previous Inventories" type="receipt" />
                                            <ReversedDocs roles={roles.inventory} reversedDocs={reversedReceipts} title={'Reversed Receipts'} type={'receipt'} />
                                        </React.Fragment>
                                    }
                                    <LoggedChanges data={changes} title={'Logged changes'} />
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-xs-12 no-gutter-m no-gutter-r">
                        <div className="col-lg-12 col-md-12 col-xs-12 actions">
                            {receipt && (receipt.docType == '1' || receipt.docType == '2') && roles.inventory && roles.inventory.hasEdit === true &&
                                <span>
                                    <Link to={"/" + this.props.match.params.db + "/receipt/" + this.props.match.params.id + '/edit'}>
                                        {plainTranslate(this.props.settings.locale, 'Edit')}<i className="fa fa-pencil-square-o"></i>
                                    </Link>
                                </span>
                            }
                            {receipt && (receipt.docType == '3' || receipt.docType == '4') && roles.transfer && roles.transfer.hasEdit === true &&
                                <span>
                                    <Link to={"/" + this.props.match.params.db + "/receipt/" + this.props.match.params.id + '/edit'}>
                                        {plainTranslate(this.props.settings.locale, 'Edit')}<i className="fa fa-pencil-square-o"></i>
                                    </Link>
                                </span>
                            }
                            {receipt && (receipt.docType == '5' || receipt.docType == '6') && roles.revision && roles.revision.hasEdit === true &&
                                <span>
                                    <Link to={"/" + this.props.match.params.db + "/receipt/" + this.props.match.params.id + '/edit'}>
                                        {plainTranslate(this.props.settings.locale, 'Edit')}<i className="fa fa-pencil-square-o"></i>
                                    </Link>
                                </span>
                            }
                            <span>
                                {this.state.template && <a href={'/' + this.props.match.params.db + '/inventory/' + this.props.match.params.id + '/template/' + this.state.template + '/export.pdf'} target="_blank">
                                    {plainTranslate(this.props.settings.locale, 'Download')}<i className="fa fa-download"></i>
                                </a>}
                            </span>
                            <div className={'inlineHover'} style={{ float: "right" }}>
                                <span>{plainTranslate(this.props.settings.locale, 'Template')}:</span>
                                <Select
                                    onChange={this.handleTemplateChange}
                                    onBlurResetsInput={false}
                                    onCloseResetsInput={false}
                                    menuPlacement={"auto"}
                                    options={templates}
                                    value={templates ? templates.find(o => o.value === this.state.template) : null}
                                />
                            </div>
                            <div className="col-md-12 document-profile-tabs" style={{ margin: "10px 0 30px 0" }}>
                                <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Send receipt')}>
                                        <Button type="default" onClick={() => this.sentEmailTemplate()} ghost style={{ width: '42px', color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                            <SendOutlined />
                                        </Button>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile-tabs">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutter sale-tabs">
                                {window.innerWidth > 780 &&
                                    <button className={this.state.active == 'receipt' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('receipt')}>
                                        {plainTranslate(this.props.settings.locale, 'Receipt')}
                                    </button>
                                }
                                {isAccounting && roles.ledger && roles.ledger.hasView === true &&
                                    <button className={this.state.active == 'ledgerEntry' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('ledgerEntry')}>
                                        {plainTranslate(this.props.settings.locale, 'Ledger entry')}
                                    </button>
                                }
                            </div>
                            <div className="col-md-12 col-xs-12 no-gutter">
                                {this.state.active === 'receipt' &&
                                    <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                                        {this.state.template && window.innerWidth > 780 && <embed src={'/' + this.props.match.params.db + '/inventory/' + this.props.match.params.id + '/template/' + this.state.template + '/export.pdf'} type="application/pdf" height="775px" width="100%" />}
                                        {this.state.template && window.innerWidth < 780 && <object data={'/' + this.props.match.params.db + '/inventory/' + this.props.match.params.id + '/template/' + this.state.template + '/export.pdf'} type="application/pdf" height="775px" width="100%" />}
                                    </div>
                                }
                                {isAccounting && roles.ledger && roles.ledger.hasView === true && this.state.active === 'ledgerEntry' &&
                                    <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                        {ledger && !this.state.disableButton ?
                                            <>
                                                <NewForm
                                                    formRoute={'/' + this.props.match.params.db + '/api/new-ledger/'}
                                                    saveRoute={'/' + this.props.match.params.db + '/api/new-ledger/'}
                                                    match={{ params: { id: ledger } }}
                                                    type={this.state.formId}
                                                    isNew={true}
                                                    popup={true}
                                                    form={'initializeFromState2'}
                                                />
                                            </>
                                            :
                                            <>
                                                {accountingOptions && !this.state.disableButton &&
                                                    <>
                                                        <Dropdown overlay={accOptionsMenu} >
                                                            <Button style={{ margin: '30px' }}>{plainTranslate(this.props.settings.locale, 'Add ledger')}</Button>
                                                        </Dropdown>
                                                    </>
                                                }
                                                {!accountingOptions && !this.state.disableButton &&
                                                    <><Link style={{ fontSize: '20px', margin: '30px' }} to={'/' + this.props.match.params.db + '/form-types-v2'}>{plainTranslate(this.props.settings.locale, 'No accounting templates. Click here to add some')}</Link></>
                                                }
                                                {this.state.disableButton &&
                                                    <FormLoader />
                                                }
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                            {this.state.accountingTemplate &&
                                <Popup locale={this.props.settings.locale}
                                    title={plainTranslate(this.props.settings.locale, 'Create ledger')}
                                    onClose={() => this.setState({ accountingTemplate: false })}>
                                    <section className="newPanel">
                                        <div className="panel-body">
                                            <div className="row m-bot15">
                                                <div className="text-center">
                                                    <h3>
                                                        <Translate locale={this.props.settings.locale} value="Are you sure you want to create accounting ledger?" />
                                                    </h3>
                                                    <button className="button-outlines green text-center"
                                                        onClick={() => this.setState({ accountingTemplate: false })}>
                                                        <Translate locale={this.props.settings.locale} value="No" />
                                                    </button>
                                                    <button className="button-outlines text-center btn-ok"
                                                        onClick={() => this.createAccounting(receipt.id)}>
                                                        <Translate locale={this.props.settings.locale} value="Yes" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </Popup>
                            }
                            {this.state.emailPopup === true &&
                                <SlideModal onClose={() => this.setState({ emailPopup: false })} title={plainTranslate(this.props.settings.locale, 'New Email')}>
                                    <section className="newPanel">
                                        <div className="panel-body">
                                            <div className="row m-bot15">
                                                <Form
                                                    formRoute={this.props.match.params.db + '/api/message-email/'}
                                                    saveRoute={this.props.match.params.db + '/api/message-email/new'}
                                                    popup={true}
                                                    onSuccessfulSave={() => this.setState({ emailPopup: false })}
                                                    onCancel={() => this.setState({ emailPopup: false })}
                                                    type={JSON.stringify({ "invId": receipt.id})}
                                                    form={'initializeFromState'}
                                                    match={{ params: { id: 0 } }}
                                                    isNew={true}
                                                    sendButton={true}
                                                />
                                            </div>
                                        </div>
                                    </section>
                                </SlideModal>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    inventoryProfileInformation: state.profiles
});
const mapDispatchToProps = dispatch => ({
    onDocumentProfileFetchData: (db, id) => dispatch(onDocumentProfileFetchData(db, id)),
    onEditOptionsLoad: (route) => dispatch(onEditOptionsLoad(route)),
    onTabsLoad: (route) => dispatch(onTabsLoad(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryProfile);

