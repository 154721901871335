import React, {Component} from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from '../common/translate';
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

class InvoiceSalesInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    render() {
        return (
            <div>
                <div className="account-profile-other-information-more">
                    <h4>{plainTranslate(this.props.settings.locale, 'Sales to Invoice No')} {this.props.invoice.no}</h4>
                    {this.props.invoiceSales && this.props.invoiceSales.length > 0 &&
                        <a className="account-profile-show" id="invoiceSales" href="javascript:void(0)"
                           onClick={() => this.setState({show: !this.state.show})}>
                            {this.state.show ? <i className="glyphicon glyphicon-chevron-up"></i> :
                                <i className="glyphicon glyphicon-chevron-down"></i>}
                        </a>  
                    }
                    {
                        this.state.show &&
                        <div className="line">
                            <table  className="table">
                                <thead>
                                    <tr>
                                        <th>{plainTranslate(this.props.settings.locale, '#')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Date')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Amount')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Location')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.invoiceSales.map(sale=>
                                        <tr>
                                            <td>
                                                {sale.hasView
                                                    ? <Link to={'/' + this.props.match.params.db +'/sale/'+sale.secureId+'/profile'}>{sale.no}</Link>
                                                    : sale.no
                                                }
                                            </td>
                                            <td>{sale.date ? moment((sale.date).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</td>
                                            <td>{parseFloat(sale.amount).toFixed(2)}</td>
                                            <td>{sale.location}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <div className="separate"></div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InvoiceSalesInfo));
