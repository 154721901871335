import Ajv from 'ajv';

function getErrors(values, schema) {
    let ajvValidator = (new Ajv({allErrors: true})).compile(schema);
    let valid = ajvValidator(values);

    let errors = {};
    if(!valid) {
        ajvValidator.errors.map(error => {
            let key = error.dataPath;
            if(error.keyword === 'required') key = error.params.missingProperty;

            if(key.charAt(0) === '.') key = key.substr(1);
        
            errors[key] = error.keyword;
    
            
        });
    }

    return errors;
}

export default function validate(values, props) {
 
    let data = props.formData ? props.formData : [];
    let fields = [];
    if(props.formProperties && props.formProperties.form && props.formProperties.form.fields){
        fields = props.formProperties.form.fields;
    }    
    var errors = getErrors(values, props.formProperties.schema ? props.formProperties.schema : {});
   
    fields.map(field => {
        if ('customType' in field) {
            if (errors[field.id] && field.customType === 'phone' && field.id in data && (field.id in errors) && errors[field.id] === 'pattern' && data[field.id]) {
                errors[field.id] = 'phone';
            }else if(errors[field.id] && field.customType === 'phone' && !data[field.id]){
                delete errors.phone;
            }
            if (errors[field.id] && field.customType === 'email' && field.id in data && (field.id in errors) &&  errors[field.id] === 'format' && data[field.id]) {
                errors[field.id] = 'email';
            }else if(errors[field.id] && field.customType === 'email' && !data[field.id]){
                delete errors.email;
            }
        }
    })
  
    return errors;
}

function checkCustomRules(field, errors, data) {
    if ('customRules' in field) {
        field.customRules.forEach(rule => {
            if (rule.type === 'sameAs' && field.id in data) {
                if (!(rule.field in data)) {
                    if (!(field.id in errors)) errors[field.id] = [];
                    errors[field.id].push('sameAs');
                }
                else if (data[rule.field] !== data[field.id]) {
                    if (!(field.id in errors)) errors[field.id] = [];
                    errors[field.id].push('sameAs');
                }
            }

            if (rule.type === 'dateRange' && field.id in data) {
                let date = data[field.id];
                if (date.indexOf('/') !== -1) {
                    date = date.split('/');
                    date = new Date(date[2], parseInt(date[1]) - 1, date[0]);
                }
                else {
                    date = new Date(date);
                }

                let startDate = new Date(rule.startDate);
                let endDate = new Date(rule.endDate);
                if (date > endDate || date < startDate) {
                    if (!(field.id in errors)) errors[field.id] = [];
                    errors[field.id].push('dateRange');
                }
            }

            if (rule.type === 'phone' && field.id in data && (field.id in errors) && errors[field.id] === 'type' && data[field.id]) {
                errors[field.id].push('phone')
            }
            if (rule.type === 'email' && field.id in data && (field.id in errors) &&  errors[field.id] === 'format' && data[field.id]) {
                errors[field.id].push('email')
            }
        });
    }
    else if ('fields' in field) {
        field.fields.forEach(innerField => {
            errors = checkCustomRules(innerField, errors, data);
        });
    }

    return errors;
}