import React, { Component } from 'react';
import { connect } from 'react-redux';
import Translate, { plainTranslate } from '../common/translate';
import showAToast from '../common/showAToast';
import client from '../../actions/client';
import { onDocumentProfileFetchData } from '../../actions/actions';
import { withRouter } from 'react-router-dom';
import { Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { Select } from 'antd';

const { Option } = Select;

class SharesInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            sherableLink: null
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleCreateShare = this.handleCreateShare.bind(this);
        this.setUserToShare = this.setUserToShare.bind(this);
    }
    handleClose() {
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/sale/' + this.props.sale.id + '/profile-info');
    }

    handleCreateShare() {
        this.setState({ sherableLink: null, shareId: null }, function () {
            client.post(`/${this.props.match.params.db}/api/content-user-share/sale/${this.props.sale.id}`)
                .then(response => response.data)
                .then(response => {
                    showAToast(plainTranslate(this.props.settings.locale, 'Share is succefully saved!'), 'succSettings', 'success');
                    this.props.update();
                    this.setState({ sherableLink: this.props.sherableLink + this.props.match.params.db + '/sale/' + response.code, shareId: response.id });
                });
        });
    }

    setUserToShare(user, shareId) {
        client.post(`/${this.props.match.params.db}/api/content-share/user/${user}/${shareId}`)
            .then(response => response.data)
            .then(response => {
                if (response.success) {
                    showAToast(plainTranslate(this.props.settings.locale, 'User is succefully invited!'), 'succSettings', 'success');
                    this.props.update();
                }
            })
            .catch(err => {
                showAToast(plainTranslate(this.props.settings.locale, 'User is not invited'), 'errSettings', 'error');
            })
    }

    render() {

        return (
            <div>
                <div className="account-profile-other-information-more">
                    <h4>{plainTranslate(this.props.settings.locale, 'Document Shares')}</h4>
                    <a className="account-profile-show" id="payment" href="javascript:void(0)"
                        onClick={() => this.setState({ show: !this.state.show })}>
                        {this.state.show ? <i className="glyphicon glyphicon-chevron-up"></i> :
                            <i className="glyphicon glyphicon-chevron-down"></i>}
                    </a>

                    {this.state.show &&
                        <div>
                            <Button onClick={this.handleCreateShare}>{plainTranslate(this.props.settings.locale, 'Create share')}</Button>
                            {this.state.sherableLink &&
                                <React.Fragment>
                                    <p><strong>{this.state.sherableLink} <i onClick={() => { navigator.clipboard.writeText(this.state.sherableLink) }} className="fa fa-clipboard" aria-hidden="true" style={{ cursor: "pointer" }} title={plainTranslate(this.props.settings.locale, 'Copy link')}></i></strong></p>
                                    <label forHtml='invitations'>{plainTranslate(this.props.settings.locale, 'Invite someone')}</label>
                                    <Select id='invitations' style={{ maxWidth: '100%', minWidth: "70%" }} >
                                        {this.props.users.map(user =>
                                            <Select.Option key={user.id} value={user.id} onClick={() => this.setUserToShare(user.id, this.state.shareId)}>{plainTranslate(this.props.settings.locale, user.label)}</Select.Option>
                                        )}
                                    </Select>
                                </React.Fragment>
                            }
                            <div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>{plainTranslate(this.props.settings.locale, 'Copy')}</th>
                                            <th>{plainTranslate(this.props.settings.locale, 'User')}</th>
                                            <th>{plainTranslate(this.props.settings.locale, 'Url')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.shares.map((share, key) =>
                                            <tr key={key}>
                                                <td><i onClick={() => { navigator.clipboard.writeText(this.props.sherableLink + this.props.match.params.db + '/sale/' + share.code) }} className="fa fa-clipboard" aria-hidden="true" style={{ cursor: "pointer" }} title={plainTranslate(this.props.settings.locale, 'Copy link')}></i></td>
                                                <td>{share.user}</td>
                                                <td><span><a href={`${this.props.sherableLink}${this.props.match.params.db}/sale/${share.code}`} target="_blank">{this.props.sherableLink + this.props.match.params.db + '/sale/' + share.code}</a></span></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
                <div className="separate"></div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onDocumentProfileFetchData: (db, id) => dispatch(onDocumentProfileFetchData(db, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SharesInfo));
