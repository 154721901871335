import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import settings from './reducers/settings';
import gridProperties from './reducers/gridProperties';
import gridData from './reducers/gridData';
import formProperties from './reducers/formProperties';
import formData from './reducers/formData';
import formIsValid from './reducers/formIsValid';
import treeData from './reducers/treeData';
import employeeProfile from './reducers/employeeProfile';
import hrSettings from './reducers/hrSettings';
import links from './reducers/links';
import accountProfileInformation from './reducers/accoutProfileInformation';
import calendar from './reducers/calendar';
import profiles from './reducers/profiles';
import importData from "./reducers/importData";
import report from "./reducers/report";
import paymentData from './reducers/paymentData';
import itemProfile from './reducers/itemProfile';
import documents from './reducers/documentsReducer';
import notification from './reducers/notificationReducer';
import auth from './reducers/auth';
import errors from './reducers/errors';
import itemRequests from './reducers/itemRequests';

export default combineReducers({
    form: reduxFormReducer,
    settings,
    gridProperties,
    gridData,
    formProperties,
    formData,
    formIsValid, 
    treeData,
    employeeProfile,
    hrSettings,
    links,
    formIsValid,
    accountProfileInformation,
    calendar,
    profiles,
    itemProfile,
    importData,
    report,
    paymentData,
    notification,
    itemRequests,
    documents,
    auth,
    errors
});
