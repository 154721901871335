import {
	GENERATE_REPORT_HTML,
} from "../constants/actionTypes";

export default (state = [], action) => {

    switch (action.type) {
                    
        case GENERATE_REPORT_HTML:
	      return {
	        ...state,
	        html: action.payload,
	       
	      };
        default:
            return state;
    }
};