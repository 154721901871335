import React from "react";
import ReactDOM from "react-dom";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import Message from '../common/message';
import client from '../../actions/client'
import "./style.css";
import Translate, {plainTranslate} from '../common/translate';

class ColorPicker extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            displayColorPicker: false,
            FirstColor: {
                r: "",
                g: "",
                b: "",
                a: "",
            },
            SecondColor: {
                r: "",
                g: "",
                b: "",
                a: "",
            },
            hexLeft: "",
            hexRight: "",
            active: "",
            showMessage: false,
            message: "",
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.hexToRgbA = this.hexToRgbA.bind(this);
    } 

    componentDidMount() {
        var header = document.getElementsByClassName("header");
        var left = header[0].getAttribute('data-firstcolor');
        if (!left) {
            left = "#8B73D3";
        }
        var right = header[0].getAttribute('data-secondcolor');
        if (!right) {
            right = "#66B8C9";
        }

        var leftRGBA = this.hexToRgbA(left);
        var rightRGBA = this.hexToRgbA(right);
        this.setState({ 
            FirstColor: leftRGBA,
            SecondColor: rightRGBA,
            hexLeft: left,
            hexRight: right,
        })
    }

    hexToRgbA(hex){
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            var array = {
                r: (c>>16)&255,
                g: (c>>8)&255,
                b: c&255,
                a: 1
            }
            return array;
        }
        throw new Error('Bad Hex');
    }

    handleClick(e) {
        const colorId = e.target.getAttribute("data-color");
        this.setState({ 
            displayColorPicker: !this.state.displayColorPicker,
            active: colorId
        })
    };

    handleClose() {
        this.setState({ 
            displayColorPicker: false,
            active: ""
        })
    };

    handleSave() {
        setTimeout(function () {
            var leftColor = this.state.hexLeft;
            var rightColor = this.state.hexRight;

            //Save Colors And show message
            var url = '/' + this.props.db + '/api/internal/header/gradient';

            client
            .post(url, {
                colors: {
                    first: leftColor,
                    second: rightColor
                },
            })
                .then(response => response.data)
                .then(response => {
                    this.setState({
                        showMessage: true,
                        message: plainTranslate(this.props.locale, "This information was successfully saved!")
                    });
                    setTimeout(function () {
                        this.setState({
                            showMessage: false,
                            message: ""
                        });
                    }.bind(this), 1500);
                });
        }.bind(this), 100);
    };

    handleChange(color,e) {
        var leftColor = this.state.hexLeft;
        var rightColor = this.state.hexRight;
        if (this.state.active == 1) {
            this.setState({ 
                FirstColor: color.rgb,
                hexLeft: color.hex 
            });
            leftColor = color.hex;
        } else {
            this.setState({ 
                SecondColor: color.rgb, 
                hexRight: color.hex    
            });
            rightColor = color.hex;
        }

        var header = document.getElementsByClassName("header");
        //var sidebarIcons = document.getElementById("sidebar");
        //var closeMenuIcons = document.getElementById("closeMenu");
        
        //var i;
        header[0].style.background = "-webkit-gradient(linear, left top, right top, from(" + leftColor + "), to(" + rightColor + "))";
        // for (i = 0; i < sidebarIcons.length; i++) {
        //     sidebarIcons[i].style.cssText = "background: -webkit-gradient(linear, left top, right top, from("+leftColor+"), to("+rightColor+")); -webkit-background-clip: text; -webkit-text-fill-color: transparent";
        // }
        // for (i = 0; i < closeMenuIcons.length; i++) {
        //     closeMenuIcons[i].style.cssText = "background: -webkit-gradient(linear, left top, right top, from("+leftColor+"), to("+rightColor+")); -webkit-background-clip: text; -webkit-text-fill-color: transparent";
        // }
    };

    render() {
        const styles = reactCSS({
            "default": {
                FirstColor: {
                    background: `${ this.state.hexLeft }`,
                },
                SecondColor: {
                    background: `${ this.state.hexRight }`,
                },
            },
        });


        return (
        <div>
            <div>
                <div className="swatch" onClick={ this.handleClick }>
                    <div className="color" data-color="1" style={ styles.FirstColor} />
                </div>
                <div className="swatch" onClick={ this.handleClick }>
                    <div className="color" data-color="2" style={ styles.SecondColor} />
                </div>
            </div>
            
        
            { this.state.displayColorPicker ? 
                <div className="popUpColor">
                    <div className="cover" onClick={ this.handleClose }/>
                    <div id="Sketch" onMouseUp={ this.handleSave }>
                        <SketchPicker color={ this.state.active == "2" ? this.state.SecondColor : this.state.FirstColor } data-colorId={this.state.active} onChange={ this.handleChange } />
                    </div>
                </div>
                : null 
            }

            <Message
                locale={this.props.locale}
                show={this.state.showMessage} message={this.state.message}/>

        </div>
        )
    }
}

const checkout = document.getElementById("colorPicker");
if (checkout) {
    try {
        ReactDOM.render(
            <ColorPicker {...(checkout.dataset)} />,
            checkout
        );
    } catch (error) {
        console.error(error);
    }
}

export default ColorPicker