import { Button } from 'antd';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Translate, { plainTranslate } from '../../common/translate';
import Select from 'react-select';
import { DeleteOutlined } from '@ant-design/icons';

const CmsCollection = (props) => {
    const [value, setValue] = useState(props.input.value);

    const add = () => {
        let newValue = value ? value : [];
        newValue[null] = [];
        setValue(value => ({
            ...value,
            ...newValue
          }));
          props.input.onChange(newValue);
    };

    const remove = (key) => {
        let newValue = value;
        delete newValue[key];
        setValue(value => ({
            ...value,
            ...newValue
          }));
        props.input.onChange(newValue);
    };

    const onChange = (key, selectedOption) => {
        let newValue = value;
        delete newValue[key];
        newValue[selectedOption.value] = [];
        setValue(({
            ...newValue
          }));
        props.input.onChange(newValue);
    }
    const onMultipleChange = (key, selectedOptions) => {
        let newValue = value;
        newValue[key] = selectedOptions;
        setValue(value => ({
            ...value,
            ...newValue
          }));
        props.input.onChange(newValue);
    }

    return (
        <>
            <div className='col-md-12'>
                {value && Object.keys(value).map((key, index) => {
                    return <div key={index}>
                        <Select
                            className='col-md-5'
                            onChange={(selectedOption) => onChange(key, selectedOption)}
                            value={value.hasOwnProperty(key) && value[key] ? { 'value': key, 'label': key.split('/')[1] } : null}
                            options={props.field.field1.options}
                        />
                        <Select
                            className='col-md-6 m-bot15'
                            isMulti
                            closeOnSelect={true}
                            onChange={(selectedOptions) => onMultipleChange(key, selectedOptions)}
                            options={props.field.field2.options.filter(x => x.listId == key) || []}
                            value={value.hasOwnProperty(key) ? value[key] : null}
                        />
                        {/* <span className='col-md-1' style={{cursor: "pointer"}} onClick={() => remove(key)}><i className="fa fa-trash-o" style={{marginTop: '10px'}} title={plainTranslate(props.locale, 'Remove collection')} aria-hidden="true"></i></span> */}
                    </div>
                })}
                <Button type="default" ghost onClick={() => add()} style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                    {plainTranslate(props.settings.locale, 'Add to collection')}
                </Button>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CmsCollection);
