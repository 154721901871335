import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';
import {onFieldSave} from "../../../../actions/actions";
import CreatableSelect from "react-select/lib/Creatable";
import AsyncSelect from 'react-select/lib/Async';
import client from '../../../../actions/client';
import { EditOutlined, PlusCircleOutlined, MinusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import Select from 'react-select';

class InlineSelect2Edit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: [],
            isEdit: false,
            isChanged: false,
            options: []
        };

        this.openEditField = this.openEditField.bind(this);
        this.closeEditField = this.closeEditField.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.saveField = this.saveField.bind(this);
        this.closeUnchangedField = this.closeUnchangedField.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value) || JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
            this.setState({
                value: this.props.value
            });
        }
        if (this.props.async && this.state.options.length == 0) {
            this.setState({options: this.props.options});
        }
    }

    openEditField() {
        this.setState({
            isEdit: !this.state.isEdit
        });
    }

    closeEditField() {
        this.setState({
            value: this.props.value
        });

        this.openEditField();
    }

    saveField() {       
        this.props.onFieldSave(this.props.updateRoute, this.props.id, this.state.value, this.props.additionalData);
        this.openEditField();     

        if(this.props.gridPopup){
            this.props.updateGrid();
        }
    }

    changeValue(value) {
        this.setState({value, isChanged: true,});
    }

    closeUnchangedField() {
        if (!this.state.isChanged) {
            this.openEditField();
            this.setState({isChanged: false});
        }
    }

    loadOptions(inputValue, callback) {
        let url = '';
        if (!inputValue) {
            url = url = this.props.async;
        } else {
            url = this.props.async + '?q=' + inputValue;
        }

        client
        .get(url)
        .then(response => response.data)
        .then(response => {
            let options = [];
            if (response) {
                options = response.map(option => { 
                    return {
                        'value': option.value,
                        'label': option.label + ' (' + option.email + ')'
                    };
                });
            }
            this.setState({options: options});
            callback(options ? options : this.props.options);
        });

    }

    render() {
        //onMouseLeave={this.closeUnchangedField} 
        return (
            <React.Fragment>
            <div className="inlineHover input-select2">
            
                {this.state.isEdit ?
                            <React.Fragment>                           
                            <div className="col-lg-10 col-md-10 col-xs-10 no-gutter"                            
                            onClick={()=>this.setState({isChanged:true})}>
                                {this.props.async ?
                                <AsyncSelect
                                    isClearable
                                    cacheOptions
                                    isSearchable
                                    onChange={this.changeValue}
                                    onBlurResetsInput={false}
                                    onBlur={event => event.preventDefault()}
                                    onCloseResetsInput={false}
                                    menuPlacement={"auto"}
                                    loadOptions={this.loadOptions}
                                    defaultOptions={this.props.options}
                                    defaultValue={this.state.value}
                                />
                                :
                                (this.props.notCreatable ?
                                    <Select
                                    isMulti={this.props.isMulti}
                                    value={this.state.value}
                                    onChange={this.changeValue}
                                    options={this.props.options}
                                    isClearable={true}
                                    onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            this.saveField()
                                        }
                                    }}
                                    />
                                    :
                                    <CreatableSelect
                                    isMulti={this.props.isMulti}
                                    value={this.state.value}
                                    onChange={this.changeValue}
                                    options={this.props.options}
                                    isClearable={true}
                                    onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            this.saveField()
                                        }
                                    }}
                                />

                                )
                               
                            }
                                </div>    
                                <div className="col-lg-2 col-md-2 col-xs-2 no-gutter inlineHover" style={{padding: 10}}>                      
                            <i className="icon ion-checkmark-round" onClick={this.saveField} style={{margin: 5}}></i>
                            <CloseCircleOutlined onClick={this.closeEditField} style={{margin: 5}}/>
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {this.props.isMulti && (this.state.value.length < 1 || this.state.value.value === null) &&
                                    <div className={"col-lg-11 col-md-11 col-xs-10 no-gutter"}                                    
                                    onClick={this.openEditField}>
                                        <span className='text-italic'  style={{color: 'rgb(160, 160, 160)'}}>
                                            {plainTranslate(this.props.settings.locale,'Add field content here')}</span>
                                        <PlusCircleOutlined style={{margin: 10}}/>                                       
                                    </div>
                                }
                        {this.props.isMulti  ?
                        <React.Fragment>
                        {this.state.value.length > 0 && <ul className="tags ">                                
                                {this.state.value.map((item, key) => {
                                        return <li key={key}><a href="javascript:void(0)">
                                            {plainTranslate(this.props.settings.locale, item.label)}
                                            </a></li>
                                    })
                                }                           
                                  {this.state.value.length > 0 &&
                                  <EditOutlined onClick={this.openEditField} />}
                                
                            </ul>}
                           </React.Fragment>
                            :
                                    <div className={"col-lg-11 col-md-11 col-xs-10 no-gutter"} 
                                    onClick={this.openEditField}>                                        
                                        {this.state.value && this.state.value.value !== null ? 
                                        <span>{this.state.value.label}
                                        <EditOutlined style={{margin: 10}}/></span> 
                                        : 
                                        <span>
                                       <span className='text-italic' style={{color: 'rgb(160, 160, 160)'}}>
                                           {plainTranslate(this.props.settings.locale, 'Add field content here')}</span>
                                           <PlusCircleOutlined style={{margin: 10}}/></span> } 
                                    </div>
                           }


                        </React.Fragment>
                }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData))
});

export default connect(mapStateToProps, mapDispatchToProps)(InlineSelect2Edit);
