import React, { Component } from 'react';
import Translate, {plainTranslate} from '../../common/translate';
import { connect } from 'react-redux';
import {
    fetchDepartmentNames,
    onFieldNameChange,
    onDepartmentNamesSave
} from "../../../actions/actions";
import showAToast from '../../common/showAToast';
import { withRouter } from 'react-router-dom'

class HrOpenSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openSettings: false
        };
        this.levels = [
            "Top-",
            "First-",
            "Second-",
            "Third-",
            "Fourth-",
            "Fifth-",
            "Sixth-",
            "Seventh-",
            "Eighth-",
            "Ninth-",
            "Tenth-",
            "Eleventh-",
            "Twelfth-",
            "Thirteenth-",
            "Fourteenth-",
            "Fifteenth-",
            "Sixteenth-",
            "Seventeenth-",
            "Eighteenth-",
            "Nineteenth-",
            "Twentieth-",
            "Twenty-first-",
            "Twenty-second-",
            "Twenty-third-",
            "Twenty-fourth-",
            "Twenty-fifth-"
        ];
        this.handleChange = this.handleChange.bind(this);
        this.onOpenSettings = this.onOpenSettings.bind(this);
        this.saveDepartmentNames = this.saveDepartmentNames.bind(this);
    }

    componentDidMount() {
        this.props.getDepartmentsCustomNames(this.props.match.params.db);
    }

    handleChange(property, e) {
        let value = e.target.value;
        let names = this.props.hrSettings.names;
        names[property] = value;
        this.props.onUpdateField(names);
    }

    onOpenSettings() {
        this.setState({
            openSettings: !this.state.openSettings
        });
    }

    saveDepartmentNames() {
        this.props.saveDepartmentNames(this.props.match.params.db, this.props.hrSettings.names)
            .then((response) => {
                if ('errors' in response) {
                    showAToast(plainTranslate(this.props.settings.locale, 'Data not saved successfully!'), 'errSettings', 'error');
                }
                else {
                    showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
                }
            })
    }

    render() {
        const mapItem = [];
        for (let i = 0; i <= this.props.maxLvl; i++)
            mapItem.push(i);
        return (
            <div>
                <div className="col-md-10 setting-item">
                    <label>
                        <Translate locale={this.props.locale} value={this.props.label} />
                    </label>
                    <p>
                        <Translate locale={this.props.locale} value={this.props.description} />
                    </p>
                </div>
                <div className="col-md-2">
                    <div className="type-1">
                        <div>
                            <a href="#" className="button-expand" onClick={this.onOpenSettings}>
                                <span className="txt"><Translate locale={this.props.locale} value={this.state.openSettings ? 'Close' : 'Open'} /></span>
                                <span className="round"><i className="fa fa-chevron-right"></i></span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row m-bot50"></div>
                <div className="col-md-10 setting" style={{ display: this.state.openSettings ? 'block' : 'none' }}>
                    {mapItem.map((x, i) =>
                        <div className="field col-md-12 col-xs-12" key={i}>
                            <div className="form-group" key={i}>
                                <label className="col-md-2 text-right"><Translate locale={this.props.locale} value={`${this.levels[i]}level`} /></label>
                                <div className="col-md-8">
                                    <span className="form-input-field">
                                        <input key={i} type="text" className="form-control"
                                            onChange={(e) => this.handleChange(`level${i}`, e)}
                                            value={this.props.hrSettings && this.props.hrSettings.names && this.props.hrSettings.names[`level${i}`] ? this.props.hrSettings.names[`level${i}`] : ` `} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-md-12 col-xs-12">
                        <div className="pull-left">
                            <button type="button" className="button-primary "
                                onClick={this.saveDepartmentNames}
                            ><Translate locale={this.props.locale} value="Save" /></button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    hrSettings: state.hrSettings
});

const mapDispatchToProps = dispatch => ({
    getDepartmentsCustomNames: (db) =>
        dispatch(fetchDepartmentNames(db)),
    onUpdateField: (names) => dispatch(onFieldNameChange(names)),
    saveDepartmentNames: (db, data) => dispatch(onDepartmentNamesSave(db, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HrOpenSection));
