import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducer';
//const db =   window.location.pathname.split('/')[1];
//const initialState = {};
const initialState = {
  settings: {
      db: null,
      sidebarRoute: null,
      storageUrl: null,
      storageToken: null,
      bundles: {},
      plan: {},
      roles: [],
      locale: localStorage.locale ? localStorage.locale : 'en',
      checkDevices: null,
      message: {
          showMessage: false
      }
  },

  gridProperties: {
      quickFilter: ''
  },
  gridData: [],

  formProperties: {
  },
  formData: [],
  formIsValid: {},
  
  treeData: [],
  
  employeeProfile: [],
  accountProfileInformation: {
      codesOptions: [],
      locationsOptions: [],
      tagsOptions: [], 
      listFields: [],
      usersOptions: [],
  }
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  compose(
    applyMiddleware(...middleware)
  )
);

export default store;
