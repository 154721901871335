import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import { Form, Input, Button, Select } from 'antd';
import NewForm from "../../redux-forms/form";
import { withRouter } from 'react-router-dom'
const { Option } = Select;

class UserEmailSettings extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
              value: null,
              enableSave: false
        };
    }

    render() {
        return (
                <React.Fragment>
                    {this.props.settings && this.props.settings.userSettings &&
                        <NewForm
                            formRoute={this.props.match.params.db + '/api/user-profile/integration/'}
                            saveRoute={this.props.match.params.db + '/api/user-profile/integration/'}
                            match={{params: {id: this.props.value}}}
                            isNew={true}
                            popup={true}
                            form={'initializeFromState2'}
                        />
                    }
                </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

//const UserEmailSettingsForm = Form.create({ name: 'UserEmailSettingsForm' })(UserEmailSettings);
    
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserEmailSettings));
