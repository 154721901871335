import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Menu, Row, Skeleton, Spin, Select, Table, Dropdown,Progress, Button, Avatar, List, Comment, Modal } from "antd";
import client from "../../actions/client";
import showAToast from "../common/showAToast";
import { plainTranslate } from "../common/translate";
import { UserOutlined, RightOutlined } from "@ant-design/icons";
import {config} from '../../config';
import {Link} from 'react-router-dom';
import moment from 'moment';
import SlideModal from '../common/SlideModal';
import SimpleForm from "../redux-forms/form";
import {onDocumentProfileFetchData} from '../../actions/actions';
import FormLoader from '../forms/form-loader';
import Documents from './documents';
import RteEditor from '../common/rteEditor';

const { Option } = Select;

const PublicTask = (props) => {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [addComment, setAddComment] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [openDelFile, setOpenDelFile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState(null);
  const urlParams = new URLSearchParams(props.location.search);
  const [lang,setLang] = useState(urlParams.get('lang') ? urlParams.get('lang') : 'en');

    useEffect(() => {
        getProfileData();
    }, []);

    function getProfileData(){
        let url = props.match.params.db+"/web/task/"+props.match.params.taskId;
        setLoading(true);
        client
          .get(url)
          .then((res) => {
            setData(res.data);
            setLoading(false);
          })
          .catch((err) => {
            var error = "Error!";
            setLoading(false);
            showAToast(
              plainTranslate(lang, error),
              "errSettings",
              "error"
            );
        });
    }

    let task = data ? data.task : null;

    const style = {
        margin: '0 5px',
    };


    if (loading){
        return <FormLoader/>
    }

    function showAddComment(){
        setAddComment(true);
    }

    function changeLang(val){
        props.history.push(props.location.pathname+'?lang='+val);
        setLang(val);
    }

    function closeAddComment(changed){
        if(changed){
            getProfileData();
        }
        setAddComment(false);
    }

    function closeTask(){
        client.get(props.match.params.db+"/web/task/"+props.match.params.taskId+'/close-task')
        .then((response) => {
            showAToast(plainTranslate(lang, 'Task closed successfully!'), 'succSettings', 'success');
            getProfileData();
        })
        .catch((err) => {
            var error = "Error!";
            showAToast(plainTranslate(lang, error),"errSettings","error");
        });
    }

     function handleOkDeleteFile(){
        setIsUploading(true);
        client
        .post('/'+props.match.params.db+"/web/public/storage/file/delete", {file: file, fileId: fileId})
        .then(res => {
            showAToast('File deleted!', 'successUpload', 'success');
            setOpenDelFile(false);
            setIsUploading(false);
            setFile(null);
            setFileId(null);
            getProfileData();
        })
        .catch(err => {
            showAToast("Error!", 'baseError', 'error')
        });
     }

    function handleDeleteFile(filePath, fileId){
        setOpenDelFile(true);
        setFile(filePath);
        setFileId(fileId);
     }

    function handleCanceDelFile(){
        setOpenDelFile(false);
        setFile(null);
        setFileId(null);
     }

    function onUploadFile(){
        getProfileData();
    }

    function handlePreview(file){
        setPreviewImage(file)
        setPreviewVisible(true);
    }

    function handleCancelPreview(){
       setPreviewVisible(false);
    }

    const CommentList = ({ comments }) => (
      <List
        dataSource={comments}
        header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
        itemLayout="horizontal"
        renderItem={(props) => <Comment {...props} />}
      />
    );

    let comments = data ? data.taskComments : [];

    comments.map((comment,key) => {
        comments[key]['avatar'] = <Avatar icon={<UserOutlined />} />
        if(typeof comments[key]['content'] !== 'object'){
            comments[key]['content'] = <div style={{overflow: "auto"}} dangerouslySetInnerHTML={{ __html: comment.content }}></div>
        }
    })

    if(task){
        document.title = task.project + ' › ' + task.name;
    }

    const documentsColumns = [
        {
            key: 'actions',
            dataIndex: 'actions',
            width: 50,
            title: plainTranslate(lang, 'Actions'),
            render: (text, record) => {
                return (<span>
                    {(record.extension === 'jpeg' || record.extension === 'jpg' || record.extension === 'png' || record.extension === 'webp' || record.extension === 'svg') ?
                        <Link to='#' onClick={() =>  handlePreview(record.full) }>
                            <span> <i className="fa fa-eye"></i> </span>
                        </Link>
                    :
                    <a href={record.full} target="_blank"><i className="fa fa-eye"></i></a>}
                    <Link to='#' onClick={() =>  handleDeleteFile((record.path ? record.path : record.name),record.id) }>
                        <span> <i className="fa fa-trash-o"></i> </span>
                    </Link>
                    </span>
                )
            },
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: plainTranslate(lang, 'Name'),
            width: 300,
        },
        {
            dataIndex : 'extension',
            title : plainTranslate(lang, 'Extension'),
            width: 50,
        },
        {
            dataIndex : 'size',
            title : plainTranslate(lang, 'Size'),
            width: 50,
        },
        {
            dataIndex : 'created',
            title : plainTranslate(lang, 'Created'),
            width: 50,
        },
        {
            dataIndex : 'createdBy',
            title : plainTranslate(lang, 'Created by'),
            width: 50,
        }
    ];

  return (
    <>
      {(
        <>
          {task && (
            <div className="pg-content">
              <nav className="navbar pg-header">
                <div style={{maxWidth: 1480, margin: "0 auto"}}>
                    <a href="#" className="logo-wrapper">
                      <img src="/images/composity-logo.svg" />
                    </a>
                    <span style={{float: 'right', paddingTop: 10}}>
                        <Select 
                            className="small-select"
                            size='small'
                            style={{ width: 120, height: 25 }}
                            onChange={(val) => changeLang(val)}
                            value={lang}
                            >
                            {data.languages && data.languages.map((lang,key) => {
                                return <Option key={key} value={lang.value} style={{height: 25}}>{lang.label}</Option>
                            })}
                        </Select>
                    </span>
                </div>
                <div className="bar-colors" />
              </nav>

            <div className="container pg-container">
                <div className="row">
                  <div className="col-md-12">

                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb pg-breadcrumbs">
                        <li className="breadcrumb-item" aria-current="page">
                          <Link to={'/' + props.match.params.db + (window.location.pathname.includes("/public/board") ? '/public/board/' : '/public/project/') + props.match.params.id + '?lang=' + lang}>{plainTranslate(lang, 'Tasks')}</Link> 
                        </li>
                        <RightOutlined />
                        <li className="breadcrumb-item" aria-current="page">
                          {task.code + " / " +task.name}
                        </li>
                      </ol>
                    </nav>
                  </div>
                    <div className="col-md-12">
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                            <div className="col-lg-7 col-md-4 col-xs-12 no-gutter">
                                <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information" style={{minHeight: 500, overflow: 'auto'}}>
                                    <div dangerouslySetInnerHTML={{ __html: task.description }}></div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information" style={{marginTop: 20}}>
                                    {data.taskComments.length > 0 && <CommentList comments={data.taskComments} />}
                                    {addComment &&
                                        <Comment
                                            content={
                                                <RteEditor 
                                                    value={''} 
                                                    id={'comment'}
                                                    showButtons={true} 
                                                    updateRoute={'/' + props.match.params.db + '/web/task/' + props.match.params.taskId + '/comment/new'}
                                                    handleClose={closeAddComment}
                                                />
                                            }
                                          />
                                    }
                                    {!addComment &&
                                        <span>
                                            <Button style={{margin: 15}} onClick={showAddComment}>
                                                {plainTranslate(lang, 'Add comment')}
                                            </Button>
                                            <Button style={{margin: 15, background: "#44c699", color: "white"}} onClick={closeTask}>
                                                {plainTranslate(lang, 'Close Task')}
                                            </Button>
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-8 col-xs-12 no-gutter-m no-gutter-r">
                                <div className="col-lg-12 col-md-12 col-xs-12" style={{marginTop: 0}}>
                                    <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information" style={{minHeight: 50}}>
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(lang, 'Status')}:</span></div>
                                        <div className="col-lg-4 col-md-4 col-xs-4 content">
                                            <div style={task.stageColor ? { color:task.stageColor } : {}}>
                                                {task.status}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-8 col-xs-12 no-gutter-m no-gutter-r">
                                <div className="col-lg-12 col-md-12 col-xs-12" style={{marginTop: 50}}>
                                    <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information" style={{minHeight: 50}}>
                                        <Documents
                                            loading={loading}
                                            data={task}
                                            columnsDocuments={documentsColumns}
                                            dataDocuments={data.attachments}
                                            onUploadFile={onUploadFile}
                                            type="task"
                                            hideMediaLibrary={true}
                                            isPublic={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col">
                    <a href="https://composity.com" target="_blank">
                      <p className="software">
                        <span>
                          Business Software by{" "}
                          <img src="/images/composity-logo.svg" />
                        </span>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
                <Modal visible={previewVisible} footer={null} onCancel={handleCancelPreview}>
                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>

                <Modal visible={openDelFile} footer={null} onCancel={() => setOpenDelFile(false)} confirmLoading={true}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <div className="text-center">
                                    <h3>
                                        {plainTranslate(lang, 'Are you sure?')}
                                    </h3>
                                    <button className={"button-outlines green text-center" + (isUploading ? ' disabled' : '')}
                                        disabled = {isUploading ? 'disabled' :  '' }
                                        onClick={() => setOpenDelFile(false)}>
                                        {plainTranslate(lang, 'No')}
                                    </button>
                                    <button className={"button-primary text-center btn-ok"  + (isUploading ? ' disabled' : '')}
                                        disabled = {isUploading ? 'disabled' :  '' }
                                        onClick={() => handleOkDeleteFile()}>
                                        {plainTranslate(lang, 'Yes')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
               </Modal>
              <div className="bg-icon-wrapper-right fix">
                <img src="/images/rectangles-bg.svg" />
              </div>
              <div className="bg-icon-wrapper-left">
                <img src="/images/2-rectangles-bg.svg" />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onDocumentProfileFetchData: (url) => dispatch(onDocumentProfileFetchData(url))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PublicTask));