import React from "react";
import { connect } from "react-redux";
import { DatePicker as DatePickerComponent } from "antd";
import "antd/lib/date-picker/style/index.css";
import moment from "moment";

class Period extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        let period = moment(value).format("YYYYMM");
        this.props.input.onChange(period);
    }

    render() {
        let defaultPeriod = null;
        if (this.props.input.value) {
            defaultPeriod = moment(this.props.input.value, "YYYY-MM");
        }
        var additionalStyle = this.props.field.style ? this.props.field.style : {};
        additionalStyle['width'] = "100%";

        return (
            <DatePickerComponent
                size="large"
                style={additionalStyle}
                onChange={this.onChange}
                value={defaultPeriod}
                picker="month"
                disabled={this.props.field.disabled}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Period);
