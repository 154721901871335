import React, { useState, useEffect, useRef } from 'react';
import client from '../../../actions/client';
import SignaturePad from 'react-signature-canvas';
import './signature.css';
import { plainTranslate } from '../../common/translate';
import { connect } from 'react-redux';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Draggable from 'react-draggable';
import moment from 'moment';

const Contract = (props) => {
  const [value, setValue] = useState(null);
  const [nameFile, setNameFile] = useState(null);
  const { id } = props.match.params;
  const { db } = props.match.params;
  const [imageURL, setImageURL] = useState(null);
  const [activeDrags, setActiveDrags] = useState(0);
  const [timeStamp, setTimeStamp] = useState(0);
  const [account, setAccount] = useState(null);
  const [data, setData] = useState(null);

  const sigCanvas = useRef({});

  const clear = () => sigCanvas.current.clear();

  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
    updateImage();
    setTimeStamp(moment().format('DD/M/YYYY HH:mm'));
    //make activity
    let data = {
      body: {
        activityType: {
          cc: '60696a5',
          name: 'Sign added',
        },
        date: moment().format('YYYY-M-DD HH:mm'),
        action: 'created',
        actionType: 'in',
        account: account,
        description: 'Contract ' + nameFile + ' ' + id + ' singned',
      },
    };
    client
      .post(`${db}/web/activity/new`, data)
      .then((res) => {})
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log('error');
        }
      });
  };

  const printDocument = () => {
    updateImage();
    window.html2canvas = html2canvas;
    let pdf = new jsPDF('p', 'pt', [595, 842]);
    let pWidth = pdf.internal.pageSize.width;
    let srcWidth = document.getElementById('canvas').scrollWidth;
    let margin = 18;
    let scale = (pWidth - margin * 2) / srcWidth;
    html2canvas(document.getElementById('canvas')).then(function (canvas) {
      pdf.html(canvas, {
        x: margin,
        y: margin,
        html2canvas: {
          scale: scale,
        },
        callback: function (pdf) {
          pdf.save('contract.pdf');
        },
      });
    });
  };

  const updateImage = () => {
    var htmlString = document.getElementById('canvas').innerHTML;
    client
      .post(`${db}/web/contract/save/signature`, {
        body: htmlString,
        filename: nameFile,
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log('error');
        }
      });
  };

  useEffect(() => {
    client
      .get(`${db}/web/contract/${id}`)
      .then((res) => {
        setData(res.data);
        setAccount(res.data.accId);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log('error');
        }
      });
    let url = '/' + db + '/web/document/' + id + '/export.pdf';
    client
      .get(url)
      .then((res) => {
        let filenameArray = res.data.url.split('/');
        let filename = filenameArray[filenameArray.length - 1];
        setNameFile(filename.split('.')[0]);
        client.get(res.data.url).then((resp) => {
          setValue(resp.data);
        });
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log('error');
        }
      });
  }, []);

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };

  const dragHandlers = { onStart: onStart, onStop: onStop };

  return (
    <>
      <div className="pg-content">
        <div className="row">
          <div className="col-md- page-container">
            <div className="col-md-" style={{ zIndex: '1' }}>
              {value && (
                <>
                  <div
                    // style={{ position: 'absolute' }}
                    id="canvas"
                    className="page"
                  >
                    <div
                      className="document-container"
                      dangerouslySetInnerHTML={{ __html: value }}
                    ></div>
                    {imageURL ? (
                      <Draggable {...dragHandlers}>
                        <div
                          className="draggable-container"
                          style={{
                            background: '#fff',
                            width: '150px',
                            margin: '10px',
                            padding: '10px',
                            float: 'left',
                          }}
                        >
                          <p>{timeStamp}</p>
                          <img
                            src={imageURL}
                            alt=""
                            style={{
                              display: 'block',
                              margin: '0 auto',
                              width: '150px',
                            }}
                          />
                        </div>
                      </Draggable>
                    ) : null}
                  </div>
                </>
              )}
            </div>
            <div className="side-container">
              <div className="info-container">
                <div className="mobile-container" style={{ marginTop: '65px' }}>
                  <div className="card pg-card">
                    <div className="card-body">
                      <div className="card-price">
                        <p className="lead">Contarct</p>
                        <div className="total">
                          <span className="amount">
                            {data?.name} Yonas Henok
                          </span>
                          <span className="curency-sign">
                            {data?.currency} 25$
                          </span>
                        </div>
                      </div>
                      <div className="card-price--info">
                        <div className="status">
                          <p className="lead">Document No</p>
                          <span>{data?.documentNo}</span>
                        </div>
                        <div className="due-date">
                          <p className="lead">Dodument Date</p>
                          <span>{data?.documentDate}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="actions-container">
                <div>
                  <p>
                    {plainTranslate(props.settings.locale, 'Sign here ...')}
                  </p>
                  <SignaturePad
                    ref={sigCanvas}
                    canvasProps={{ className: 'signatureCanvas' }}
                  />
                </div>
                <div className="button-container">
                  <button className="button-outline grey" onClick={save}>
                    {plainTranslate(props.settings.locale, 'Save')}
                  </button>
                  <button className="button-outline grey" onClick={clear}>
                    {plainTranslate(props.settings.locale, 'Clear')}
                  </button>
                  <button
                    className="button-outline grey"
                    onClick={printDocument}
                  >
                    {plainTranslate(props.settings.locale, 'Print')}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col">
              <a href="https://composity.com" target="_blank">
                <p className="software">
                  <span>
                    Business Software by{' '}
                    <img src="/images/composity-logo.svg" alt="" />
                  </span>
                </p>
              </a>
            </div>
          </div>
        </div>
        <div className="bg-icon-wrapper-right fix">
          <img src="/images/rectangles-bg.svg" alt="" />
        </div>
        <div className="bg-icon-wrapper-left">
          <img src="/images/2-rectangles-bg.svg" alt="" />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
