import React from 'react';    
    
class TextLine extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            clicked: null
        };
        this.handleEnterClick = this.handleEnterClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.triggerChange = this.triggerChange.bind(this);
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {   
        if(this.state.value === '' && typeof this.props.data !== 'undefined'){    
            this.setState({
                value: this.props.data ? this.props.data : ''
            });
        }
    } 
    
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data && nextProps.data !== prevState.value) {         
            return {
                value: nextProps.data
            }
        }else{
            return null;
        }
    }
 
 //  shouldComponentUpdate(nextProps, nextState) {    
 //       return nextState.value !== this.state.value;
 //   }
    handleClick(event){        
        this.setState({
          clicked: event.target.id
       }); 
    }
    
    onChange(event){        
        this.setState({
          value: event.target.value
       }); 
        this.props.handleChangeLine(this.props.field.id, event.target.value)
    }
    
       triggerChange() {
        const { value } = this.state;
        this.props.handleChangeLine(this.props.field.id, value)
         this.setState({
          clicked: null
       }); 
  }
    
    handleEnterClick(e) {
    	if(this.props.field.id === 'itName'){
    		if (e.key === 'Enter'){
                this.props.search(e.target.value, this.props.name);

            }else if(e.target.id === 'searchButton') {
                let value = e.target.nextElementSibling.value;

		    	this.props.search(value, this.props.name);
			}
        }
    }

 render() { 
if(this.state.clicked !== this.props.id){
   return <div className={this.props.className + ' div-text-center'} style={this.props.style} onClick={this.handleClick}  id={this.props.id}>
   {this.state.value}</div> 
}else{
        return  <input 
            id={this.props.id}
            onKeyDown={(e) => this.handleEnterClick(e)} 
            value={this.state.value}
            type="text" 
            name={this.props.name}
            className={this.props.className + ' text-center border-green'}
            style={this.props.style} 
            disabled={this.props.field.disabled} 
            onChange={this.onChange}
            onBlur={this.triggerChange}
            placeholder={this.props.field.placeholder}
            onKeyPress={this.props.onKeyPress}
            />
        }
                }
}

export default TextLine;
