import React from 'react';
import { connect } from 'react-redux';
import Translate, { plainTranslate } from '../../common/translate';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';

class TestButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        client.get(this.props.formData.url + '/status')
            .then(result => result.data)
            .then(result => {
                if (result.ok !== undefined && result.ok === true) {
                    showAToast(plainTranslate(this.props.settings.locale, 'Printer is connected!'), 'succSettings', 'success');
                } else {
                    showAToast(plainTranslate(this.props.settings.locale, 'Printer is not connected!'), 'errSettings', 'error');
                }
            })
    }

    render() {
        return (
            <div>
                <input id="templates-add"
                    title="Fiscal printer test button"
                    type="button"
                    value={plainTranslate(this.props.settings.locale, "Test")}
                    onClick={this.onClick} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(TestButton);

