import React from 'react';
import {plainTranslate} from "../common/translate";
import "./popup.css";
import FormLoader from "./../forms/form-loader";
import { Spin, Alert } from 'antd';

export default class Popup extends React.Component {

    constructor(props) {
        super(props);

//        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleEscClick = this.handleEscClick.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleEscClick);
    }

//    handleClickOutside(event) {
//        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
//            this.props.onClose();
//        }
//    }

    handleEscClick(e) {
        if (e.keyCode === 27) {
            this.props.onClose();
        }
    }

    render() {

        const overlayStyle = {
            backgroundColor: '#000',
            position: 'absolute', 'top': '0px',
            left: '0px', width: '100%',
            height: '100%',
            zIndex: '2000',
            opacity: 0.4
        };

        return (
            <div className={"modal fade bd-example-modal-lg " + (this.props.large ? 'x-lg' : '') + " in"}
                 aria-hidden="false" style={{display: "block"}}>

               {/* <div style={overlayStyle}></div>*/}

                <div className="modal-dialog modal-lg" style={{border: '0', zIndex: 10001, width: this.props.width ? this.props.width : '900px'}}>
                    <div className="modal-content" ref={this.setWrapperRef}>
                        <div className="modal-header"><h5 className="modal-title">{this.props.title}</h5>
                            <button type="button" className="close" onClick={this.props.onClose}>
                                <span aria-hidden="true" className="ion-android-close"></span>
                            </button>
                        </div>
                        {!this.props.showLoader && 
                            <div className={"modal-body " + (this.props.className ? this.props.className : '')}>
                                {this.props.children}
                            </div>
                        }
                        {
                            this.props.showLoader &&
                            <div style={{background: "white", height: 200,display: "flex",alignItems: "center",justifyContent: "center"}}>
                                <Spin tip="Loading..." size="large">
                                </Spin>
                            </div>
                        }
                    </div>
                </div>
            </div>

        );
    }
}