import React from 'react';
import { connect } from 'react-redux';
import Translate, { plainTranslate } from "../../common/translate";
import { Link } from 'react-router-dom';
import MenuContents from "./menuContents";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class MenuBuilder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        this.addNewItem = this.addNewItem.bind(this);
        this.remove = this.remove.bind(this);
        this.change = this.change.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        if (this.props.input.value && this.props.input.value !== '' && this.props.input.value.length !== 0) {
            let menu = JSON.parse(this.props.input.value)
            this.setState({
                value: menu[this.props.field.settings.mainLevel]
            })
        }
    }

    onDragEnd(val) {    
        let items = this.state.value;
        let droppable = val.destination.droppableId;
        let destinationIndex = val.destination.index;
        let sourceIndex = val.source.index;
        let updatedItems = null;

        if (droppable == 'main') {
            updatedItems = this.moveInArray(items, sourceIndex, destinationIndex);
        } else {
            let index = null;
            let updatedSubItems = null;
            items.find((item, key) => {
                if (item.name === droppable) {
                    index = key;
                }
            });

            if (index !== null) {
                updatedSubItems = this.moveInArray(items[index][this.props.field.settings.subLevel], sourceIndex, destinationIndex);
                items[index][this.props.field.settings.subLevel] = updatedSubItems;
                updatedItems = items;
            } else {
                updatedItems = items;
            }
        }

        this.setState({ value: updatedItems }, function() {
            this.change();
        });
    };

    moveInArray(arr, from, to) {
        if (Object.prototype.toString.call(arr) !== '[object Array]') {
            throw new Error('Please provide a valid array');
        }
        var item = arr.splice(from, 1);
        if (!item.length) {
            throw new Error('There is no item in the array at index ' + from);
        }
        arr.splice(to, 0, item[0]);
        return arr;
    };

    addNewItem() {
        let menu = this.state.value ? this.state.value : [];
        let newItem = { 'id': (menu.length + 1), 'name': 'New main element', 'level': '1' };
        menu.push(newItem);
        this.change();
        this.setState({
            value: menu
        });
        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.input.value != this.props.input.value) {
            if (this.props.input.value && this.props.input.value !== '' && this.props.input.value.length !== 0) {
                let menu = JSON.parse(this.props.input.value)
                this.setState({
                    value: menu[this.props.field.settings.mainLevel]
                })
            }
        }
    }

    change() {
        let menu = new Object();
        menu[this.props.field.settings.mainLevel] = this.state.value;
        this.props.input.onChange(JSON.stringify(menu));
    }

    remove(item) {
        let array = this.state.value;
        let index;
        array.find((val, key) => {
            if (val === item) {
                index = key;
                return true;
            }
        });
        if (index > -1) {
            array.splice(index, 1);
        }

        this.setState({ value: array });
        this.change();
    }

    render() {
        return <div>
            <React.Fragment>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="main">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {this.state.value && this.state.value.length > 0 && this.state.value.map((item, key) =>
                                    <Draggable key={item.id} draggableId={item.name} index={key}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <MenuContents type={item.name} item={item} key={key} change={this.change} remove={this.remove} level={1} options={this.props.field.options} fieldSettings={this.props.field.settings}/>
                                            </div>
                                        )}
                                    </Draggable>
                                )}
                                <a className="button-outline grey" onClick={this.addNewItem}>{plainTranslate(this.props.locale, 'Add')}</a>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </React.Fragment>
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuBuilder);
