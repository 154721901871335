import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { plainTranslate } from '../common/translate';
import SaveCancel from '../common/saveCancelButtons';
import { change } from 'redux-form';
import Field from './field';
import validate from './validator';
import "./redux-forms.css";
import PosPage from "../custom-forms/posPage";
import MakePaymentPage from "../custom-forms/makePaymentPage";
import {
    onLoadForm as onLoadForm,
    onImportLoadForm as onImportLoadForm
} from "../../actions/formActions";
import FormLoader from "../forms/form-loader";
import { onSave, onSidebarMount } from "../../actions/actions";
import { logoutUser } from "../../actions/authActions"
import { FORM_SHOW_LOADER } from "../../constants/actionTypes";
import { Modal } from 'antd';
import 'antd/lib/modal/style/index.css';
import SlideModal from '../common/SlideModal';
import { Switch } from 'antd';
import axios from 'axios'
import showAToast from '../common/showAToast'
import client from '../../actions/client'

const components = {
    pos: PosPage,
    payment: MakePaymentPage
};

class Forms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            showLoader: false,
            formData: [],
            initialData: [],
            formProperties: null,
            saveRoute: null,
            formRoute: null,
            isEdit: false,
            popup: false,
            form: null
        };
        this.updateForm = this.updateForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeFieldValue = this.changeFieldValue.bind(this);
        this.changeFieldValueLine = this.changeFieldValueLine.bind(this);
        this.onBreadcrumbClick = this.onBreadcrumbClick.bind(this);
    }

    componentDidMount() {
        this.updateForm();
        if(this.props.popup){
            this.setState({
                popup: this.props.popup
            })
        }
    }

    changeFieldValue(id, value) {// console.log(id+'  changeFieldValue')
        if (this.state.formData[id] !== value || id === 'lines') {
            let formData = this.state.formData;
            formData[id] = value;
            this.setState({
                formData: formData
            })
        }
    }

    changeFieldValueLine(id, value, index) {
       
        if (this.state.formData['lines'][index][id] !== value) {
            let formData = this.state.formData;
            formData['lines'][index][id] = value;
            this.setState({
                formData: formData
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match && prevProps.match.params.id !== this.props.match.params.id) {
            this.updateForm();
        }
    }

    onBreadcrumbClick(element, target) {
        if (element.status == 8) {
            showAToast('Document cancelled. Review all attached documents!', 'errBreadcrump', 'error')
        }

        if (this.state.isEdit) {
            let pos = this.state.formData['stagePosition'];

            if (pos) {
                if (element.position >= pos) {
                    this.changeFieldValue(target, element.value);

                    if (this.state.form.breadcrumb.changeLines) {
                        if (this.state.formData && this.state.formData.lines) {
                            this.state.formData.lines.map((line, key) => {
                                this.changeFieldValue('lines[' + key + '][stage]', element.value);
                                this.changeFieldValue('lines[' + key + '][stageStatus]', element.status);
                            })
                        }
                    }
                } else {
                    showAToast('Cannot change document Status backward.', 'errBreadcrumpBack', 'error')
                }
            } else {
                this.changeFieldValue(target, element.value);
            }
            if (element.status == 8 && element.manySalesInvoice) {
                showAToast('Cancellation forbidden. The document is part of a group.', 'errBreadcrumpgroup', 'error')
            }
        } else {
            this.changeFieldValue(target, element.value);
            if (this.state.form.breadcrumb.changeLines) {
                if (this.state.formData && this.state.formData.lines) {
                    this.state.formData.lines.map((line, key) => {
                        this.changeFieldValue('lines[' + key + '][stage]', element.value);
                        this.changeFieldValue('lines[' + key + '][stageStatus]', element.status);
                    })
                }
            }
        }

    }

    save(saveNew, issue, formSaveRoute = null) {
        this.setState({ submitting: true });
        let form;

        if (this.props.saveReport) {
            if (saveNew) {
                this.saveRoute = '/' + this.props.match.params.db + this.props.saveRoute + '?type=' + this.props.type;
            } else {
                this.saveRoute = '/' + this.props.match.params.db + this.props.saveRoute;
            }
            this.props.onSidebarMount(this.props.settings.sidebarRoute);
        }
        if (issue) {
            form = { ...this.state.formData, status: 'issued' };
        } else {
            if (localStorage.workplace !== 'undefined') {
                form = { ...this.state.formData, workplace: localStorage.workplace };
            } else {
                form = this.state.formData;
            }
            if (this.props.custFormData) {
                form = { ...this.state.formData, locId: this.props.custFormData.locId };
            }
        }
        if (this.props.isReport) {
            form['startDate'] = this.props.startDate;
            form['endDate'] = this.props.endDate;
        }
        var route = formSaveRoute ? formSaveRoute : this.saveRoute;
        client.post(route, {
            body: form
        })
            .then(response => response.data)
            .then((response) => {
                this.setState({ submitting: false });
                if ('errors' in response) {
                    showAToast(response.errors, 'errBreadcrump1', 'error')
                } else {
                    showAToast('Data saved successfully!', 'errBreadcrump1', 'success')
                    if ('documentNumberChanged' in response) {
                        Modal.warning({
                            title: plainTranslate(this.props.settings.locale, response.documentNumberChanged.title),
                            content: (
                                <div>
                                    <p>{plainTranslate(this.props.settings.locale, 'Please check your')} <a style={{ textDecoration: "underline", cursor: 'pointer' }} onClick={() => this.redirectOnClick(response.documentNumberChanged.redirectUrl)}>{plainTranslate(this.props.settings.locale, 'document sequence')}</a></p>
                                </div>
                            )
                        });

                    }
                    if (this.props.updateOnSave && !response.redirect && !response.keepFormData) {
                        this.updateForm();
                    }
                    if (this.props.onSuccessfulSave) {
                        this.props.onSuccessfulSave(response);
                    }

                    if (!this.props.popup) {
                        if(this.props.redirectRoute){
                            var redirectUrl= this.props.redirectRoute.replace(':db', this.props.match.params.db)                            
                            redirectUrl = redirectUrl.replace(':id', response.entity.id)                          
                            this.props.history.push(redirectUrl);
                        }else{
                            if (response.redirect) {
                                this.props.history.push(response.redirectUrl);
                            } else {
                                if (!this.state.form.isEdit) {
                                    if (!saveNew) {
                                        this.props.history.push(response.redirectUrl);
                                    } else {
                                        this.props.reset();
                                    }
                                }
                            }
                        }
                    }
                }
            })
            .catch(error => {
                showAToast('The operation is not allowed!', 'errForm', 'error')
            })
    }

    checkDeviceAndPrint(saveNew, issue) {
        this.setState({ submitting: true });
        if (this.state.formData.isFiscalDevice === true) {
            client.post(this.props.checkDevices, {
                workplace: localStorage.workplace
            })
                .then(result => result.data)
                .then(result => {
                    if (result.url) {
                        client.get(result.url + '/status')
                            .then(device => device.data)
                            .then(device => {
                                let deviceStatus = '';
                                if (device.ok === true) {
                                    deviceStatus = 'connected';
                                } else {
                                    deviceStatus = 'not connected';
                                }

                                if (deviceStatus === "connected") {
                                    let response = null;
                                    if (this.props.printReceipt) {
                                        if (this.state.formData.reversalId === null) {
                                            this.printReceipt(result.url);
                                        } else {
                                            this.printStorno(result.url);
                                        }
                                    }
                                } else {
                                    showAToast('Fiscal Device is not connected!', 'errBreadcrump2', 'error')
                                }
                            })
                    } else {
                        showAToast('Add a fiscal device to this workplace!', 'errBreadcrump3', 'error')
                    }
                })
        } else {
            this.save(saveNew, issue);
        }


    }

    updateForm() {
        this.setState({
            showLoader: true
        })

        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }

        this.saveRoute = '/' + this.props.match.params.db + this.props.saveRoute;
        this.formRoute = '/' + this.props.match.params.db + this.props.formRoute;
        //let saveRoute = this.props.saveRoute;
        //let formRoute = this.props.formRoute;
      
        if (this.props.match) {
            this.formRoute = this.formRoute + this.props.match.params.id;
        }
     
        if (this.props.isNew) {
            this.formRoute = this.formRoute + '/form';
            if (this.saveRoute[this.saveRoute.length - 1] === '/') {
                this.saveRoute = this.saveRoute + this.props.match.params.id;
            }
        }

        if (this.props.match && !this.props.isNew) {
            let params = [];
            for (var prop in this.props.match.params) {
                params.push(prop + '=' + this.props.match.params[prop]);
            }
            if (params.length > 0) {
                this.saveRoute += '?' + params.join('&');
            }
        }
       
if (this.props.matchAdditionalId) {
    this.formRoute = this.formRoute + '/' + this.props.matchAdditionalId.params.id;
}

if (this.props.location && !this.props.popup) {
    const urlParams = new URLSearchParams(this.props.location.search);
    const key = urlParams.get('refs');
  
    if (key !== null) {
        this.formRoute = this.formRoute + '/' + key;
        this.saveRoute = this.saveRoute + '?refs=' + key;
    }
}

if (this.props.account) {
    this.formRoute = this.formRoute + '/' + this.props.account;
}
if (this.props.location && !this.props.popup) {
    const urlParams = new URLSearchParams(this.props.location.search);
    const key = urlParams.get('ids');
    const lineIds = urlParams.get('lineIds');
    const page = urlParams.get('page');
    const itemIds = urlParams.get('itemIds');
    const item = urlParams.get('item');
    const saleLine = urlParams.get('saleLine');
    const qty = urlParams.get('qty');
    const location = urlParams.get('location');
    const module = urlParams.get('module');

    if(lineIds !== null){
        this.formRoute = this.formRoute + '?lineIds=' + lineIds;
    }
    if (key !== null && module !== null) {
        this.formRoute = this.formRoute + '?ids=' + key + '&module=' + module;
        this.saveRoute = this.saveRoute;
    } else if (key !== null) {
        this.formRoute = this.formRoute + '?ids=' + key;
        this.saveRoute = this.saveRoute;
    } else if (itemIds !== null) {
        this.formRoute = this.formRoute + '?itemIds=' + itemIds;
        if (location) {
            this.formRoute = this.formRoute + '&location=' + location;
        }
        this.saveRoute = this.saveRoute + '?itemIds=' + itemIds;
        if (page) {
            this.formRoute = this.formRoute + '&page=' + page;
            this.saveRoute = this.saveRoute + '&page=' + page;
        }

    } else if (item !== null) {
        this.formRoute = this.formRoute + '?item=' + item;
        if (location) {
            this.formRoute = this.formRoute + '&location=' + location;
        }
        if (qty) {
            this.formRoute = this.formRoute + '&qty=' + qty;
        }
        this.saveRoute = this.saveRoute + '?item=' + item;
        if (saleLine) {
            this.formRoute = this.formRoute + '&saleLine=' + saleLine;
            this.saveRoute = this.saveRoute + '&saleLine=' + saleLine;
        }

    }
}

if (this.props.location && !this.props.popup) {
    const urlParams = new URLSearchParams(this.props.location.search);
    const key = urlParams.get('type');
    if (key !== null) {
        this.formRoute = this.formRoute + '?type=' + key;
    }
}
if (this.props.location && !this.props.popup) {
    const urlParams = new URLSearchParams(this.props.location.search);
    const key = urlParams.get('locId');
    const type = urlParams.get('type');

    if (key !== null && type !== null) {
        this.formRoute = this.formRoute + '&locId=' + key;
    }

    if (key !== null && type === null) {
        this.formRoute = this.formRoute + '?locId=' + key;
    }
}

if (this.props.refs && this.props.type && this.props.sale) {
    this.formRoute = this.formRoute + '?refs=' + this.props.refs + '&type=' + this.props.type + '&sale=' + this.props.sale;
}
else if ((this.props.refs || this.props.refs === null) && this.props.type) {
    this.formRoute = this.formRoute + '?refs=' + this.props.refs + '&type=' + this.props.type;
    if (this.props.parent) {
        this.formRoute = this.formRoute + '&parent=' + this.props.parent;
    }
} else if (this.props.refs) {
    this.formRoute = this.formRoute + '?refs=' + this.props.refs;
} else if (this.props.type) {
    this.formRoute = this.formRoute + '?type=' + this.props.type;
} else if (this.props.copy) {
    this.formRoute = this.formRoute + '?copy=' + this.props.copy;
}

if (this.props.location && !this.props.popup) {
    const urlParams = new URLSearchParams(this.props.location.search);
    const key = urlParams.get('storno');
    const copyKey = urlParams.get('copy');
    const deal = urlParams.get('deal');

    if (key !== null) {
        this.formRoute = this.formRoute + '?storno=' + key;
    }
    if (copyKey !== null) {
        this.formRoute = this.formRoute + '?copy=' + copyKey;
    }
    if (deal !== null) {
        this.formRoute = this.formRoute + '&deal=' + deal;
    }

}
        let importData = this.props.location ? this.props.location.state : null;
        if (importData) {
            // this.props.onImportLoadForm(this.props.form, this.props.match.params.db, importData);
        } else {
            if (this.props.applyFilters) {
                //  this.props.onImportLoadForm(this.props.form, this.props.match.params.db, this.props.importData,formRoute);
            } else {
                client
                    .get(this.formRoute)
                    .then(res => {
                        if (res.data.data) {
                            let dataForm = res.data.data;
                            if (dataForm.lines && dataForm.lines.length > 0) {
                                /*  dataForm.lines.map((line, index) => {                                
                                      Object.entries(line).forEach(([key, value]) => { 
                                          dataForm['lines['+index+'].'+key] = value;
                                      })
                                  });   */
                            }else{
                                if(res.data.form.fields.find(o => o.id === 'lines')){
                                    dataForm['lines'] = []
                                }
                               // console.log(res.data.form.fields.find(o => o.id === 'lines'))
                            }

                            this.setState({
                                formData: dataForm,
                            })
                        }

                        if (res.data.form) {
                            this.setState({
                                form: res.data.form,
                                fields: res.data.form.fields
                            })
                        }
                        if (res.data.schema) {
                            this.setState({
                                schema: res.data.schema
                            })
                        }
                        if (res.data.isEdit) {
                            this.setState({
                                isEdit: res.data.isEdit
                            })
                        }
                        this.setState({
                            showLoader: false
                        })
                    })
                    .catch(err => {
                        var error = 'Error!';
                        if (err.response && err.response.data.code && err.response.data.code === 401) {
                            this.props.logoutUser(this.props.match.params.db)
                        }
                        if (err.response && err.response.data.message) {
                            error = err.response.data.message;
                        }
                        showAToast(error, 'errSettings', 'error')
                    })
            }
        }
    }

    handleSubmit(values) {

    }

    render() {
        let disableSave = false;

        if (this.state.formData && this.state.formData.hasOwnProperty('disableSave')) {
            disableSave = this.state.formData.disableSave;
        }

        var formDataField = {};
        const formDataIn = Object.assign(formDataField, this.state.formData);

        let returnUrl = this.state.form ? this.state.form.returnUrl : '#';
        let title = this.state.form && this.state.form.title ? this.state.form.title.join(' ') : '';
        title = plainTranslate(this.props.settings.locale, title);

        let linkTitle = this.state.form && this.state.form.linkTitle ? this.state.form.linkTitle.label.join(' ') : '';
        let urlLinkTitle = this.state.form && this.state.form.linkTitle ? this.state.form.linkTitle.url : '#';

        let showPrintSave = false;
        if (localStorage.workplace !== 'undefined' && this.state.formData && this.state.formData.isFiscalDevice !== undefined && this.state.formData.isFiscalDevice === true) {
            showPrintSave = true;
        }
        const CustomForm = components[this.props.customForm];
        return (
            <div style={{ background: '#f0eae7' }}>
                <div className="dashboard">
                    {this.props.popup !== true && (!this.props.customForm || this.props.customForm !== 'pos') &&
                        <div className="moduleSubMenu backMenu">
                            <div className="col-sm-12">
                                <h3 className="pull-left">
                                    <Link to={returnUrl}>
                                        {!this.props.popup && !this.props.isReport && <i className="ion-ios-arrow-back"></i>}</Link> {!this.state.showLoader && !linkTitle && title}
                                    {!this.state.showLoader && linkTitle &&
                                        <Link to={urlLinkTitle}>
                                            {linkTitle}
                                        </Link>
                                    }
                                </h3>

                                {
                                    this.state.form && this.state.form.tabs &&
                                    <ul className="nav navbar-nav pull-right">
                                        {
                                            this.state.form.tabs.map((tab, key) => {
                                                return <li className="nav-item" key={key}>
                                                    <Link className={tab.active ? 'active' : ''} to={tab.link}>
                                                        {plainTranslate(this.props.settings.locale, tab.label)}
                                                    </Link>
                                                </li>
                                            })
                                        }
                                    </ul>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div id="reactForm" >
                    <div className="row m-bot15 cmxform form-horizontal bucket-form ng-pristine ng-valid">
                        <div className="col-lg-12">
                            <section className={this.state.form && this.state.form.noBackground ? '' : 'panel'} style={{ display: this.state.form && this.state.form.noBackground ? '' : 'block' }} >
                                {this.state.form && <header className="panel-heading"
                                    style={{ display: this.state.form && this.state.form.noBackground ? 'none' : 'block' }}>
                                    {plainTranslate(this.props.settings.locale, this.state.form.showLoader ? '...' : this.state.form.isEdit ? 'Edit' : 'New')}
                                </header>}

                                {!this.state.showLoader && this.state.form &&
                                    <div className="panel-body"
                                        style={this.state.form && 'panelBody' in this.state.form ? { width: this.state.form.panelBody } : {}}>
                                        {this.state.form.breadcrumb && this.state.form.breadcrumb.fields.length > 0 &&
                                            <div className="status-container col-md-12 col-xs-12">
                                                <div className={this.state.form.breadcrumb.disabled ? "status-breadcrumb disabled-breadcrumb" : "status-breadcrumb"}>
                                                    {this.state.form.breadcrumb.fields.map((element, key) => {
                                                        return <a
                                                            className={this.state.form.breadcrumb.target in this.state.formData && element.value === this.state.formData[this.state.form.breadcrumb.target] && element.forbiddenAccess ? 
                                                                'active forbidden' : this.state.form.breadcrumb.target in this.state.formData && element.value === this.state.formData[this.state.form.breadcrumb.target] ? 
                                                                'active' : element.forbiddenAccess ? 'forbidden' : ''}
                                                            href="#" key={key}
                                                            onClick={() => this.onBreadcrumbClick(element, this.state.form.breadcrumb.target)}>
                                                            <span className="breadcrumb__inner">
                                                                <span className="breadcrumb__desc">{element.label}</span>
                                                            </span>
                                                        </a>
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                {!this.state.showLoader && this.state.fields &&
                                    <span> <form onSubmit={this.handleSubmit}>
                                        {this.state.fields.map((field, key) => {
                                            return <div key={key}>
                                                <Field
                                                    locale={this.props.settings.locale}
                                                    name={field.id}
                                                    label={field.label}
                                                    field={field}
                                                    fields={this.state.fields}
                                                    history={this.props.history}
                                                    change={this.changeFieldValue}
                                                    changeFieldValueLine={this.changeFieldValueLine}
                                                    type={field.type}
                                                    hide={field.hide}
                                                    formData={formDataIn}
                                                    placeholder={field.label}
                                                    save={(saveNew, issue, route) => this.save(saveNew, issue, route)}
                                                    db={this.props.match.params.db}
                                                    data={formDataIn[field.id]}
                                                    isEdit={this.state.isEdit}
                                                />
                                            </div>
                                        })
                                        }
                                    </form>
                                        {this.state.form && !this.props.notSaveButton &&
                                            <div className="col-md-12 col-xs-12">
                                                <div className="pull-left">
                                                    <SaveCancel
                                                        enableSave={!this.state.submitting && !disableSave}
                                                        save={(saveNew, issue) => this.props.checkDevices ? this.checkDeviceAndPrint(saveNew, issue) : this.save(saveNew, issue)}
                                                        apply={this.apply}
                                                        reportSaveAs={this.reportSaveAs}
                                                        showPrintSave={showPrintSave}
                                                        printAndSave={this.checkDeviceAndPrint}
                                                        showCancel={this.props.popup}
                                                        applyButton={this.props.applyButton}
                                                        saveReport={this.props.saveReport}
                                                        reportId={this.props.type}
                                                        reportUserId={this.state.formData.userId ? this.state.formData.userId : null}
                                                        isAdmin={this.props.settings.roles.includes('ROLE_SUPER_ADMIN') ? true : false}
                                                        userId={this.props.settings.user ? this.props.settings.user.id : null}
                                                        showSaveNew={!this.state.form.hideSaveNew && !this.state.isEdit && !this.state.popup}
                                                        issueButton={this.props.issueButton}
                                                        cancelButton={this.props.cancelButton}
                                                        cancel={this.props.onCancel}
                                                        submitting={this.state.submitting}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {this.state.reportName &&
                                            <div className="col-md-12 col-xs-12">
                                                <div className="col-md-7 col-xs-7">
                                                    <label style={{ "paddingTop": 20 }} for="reportName">{plainTranslate(this.props.settings.locale, 'Report name:')}</label>
                                                    <input style={{ border: "1px solid #ccc", "marginTop": 13 }} id="reportName" name="reportName" onChange={(e) => this.changeFieldValue('reportName', e.target.value)} type="text" />
                                                </div>
                                                <div className="col-md-1 col-xs-1">
                                                </div>
                                                {this.props.settings.roles.includes('ROLE_SUPER_ADMIN') &&
                                                    <div className="col-md-1 col-xs-1">
                                                        <label style={{ paddingTop: 20 }}>{plainTranslate(this.props.settings.locale, 'Is Public?')}</label>
                                                        <Switch style={{ marginTop: 20 }} onChange={(e) => this.changeFieldValue('isPublic', e)} />
                                                    </div>
                                                }
                                                <div style={{ marginTop: 40 }}>
                                                    <SaveCancel
                                                        enableSave={!this.props.invalid && !this.state.submitting}
                                                        save={(saveNew, issue) => this.props.checkDevices ? this.checkDeviceAndPrint(saveNew, issue) : this.save(saveNew, issue)}
                                                        reportSaveAs={this.reportSaveAs}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </span>}
                                {this.state.showLoader &&
                                    <FormLoader />
                                }
                            </section>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

Forms.propTypes = {
    settings: PropTypes.object.isRequired,
    logoutUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    settings: state.settings,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Forms));

