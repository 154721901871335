import React from 'react';
import { connect } from 'react-redux';
import { plainTranslate } from '../../common/translate';
import { onUploadImages, onDeleteImage } from '../../../actions/actions';
import FileSaver from 'file-saver';
import './image.css';
import 'antd/lib/upload/style/index.css';
import UploadList from 'antd/es/upload/UploadList';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Upload, Modal, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons'
import en_US from 'antd/es/locale-provider/en_US';
import { withRouter } from 'react-router-dom'
import client from '../../../actions/client'

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class Images extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            previewVisible: false,
            previewImage: '',
            fileList: [],
            images: [],
            progress: 0,
        };

        this.uploadImages = this.uploadImages.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePreview = this.handlePreview.bind(this);
        this.onChangeLink = this.onChangeLink.bind(this);
        this.addNewLink = this.addNewLink.bind(this);
        this.removeLink = this.removeLink.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }
    
    moveInArray(arr, from, to) {
	// Make sure a valid array is provided
	if (Object.prototype.toString.call(arr) !== '[object Array]') {
		throw new Error('Please provide a valid array');
	}

	// Delete the item from it's current position
	var item = arr.splice(from, 1);

	// Make sure there's an item to move
	if (!item.length) {
		throw new Error('There is no item in the array at index ' + from);
	}

	// Move the item to its new position
	arr.splice(to, 0, item[0]);
        return arr;

};
    
    onDragEnd(val) { 
        let images = this.state.images; 
        let destinationIndex = val.destination.index;
        let sourceIndex = val.source.index;
        let updatedImages = this.moveInArray(images, sourceIndex, destinationIndex);

        this.setState({images: updatedImages, fileList: updatedImages});
        this.props.change(this.props.field.id, updatedImages);
  };

    handleCancel() {
        this.setState({ previewVisible: false });
    }

    handleChange({ fileList }) {
        this.setState({
            fileList: [...fileList],
        });
    }

    handlePreview(file) {
        if (file.originFileObj) {
            file.preview = getBase64(file.originFileObj)
                .then(preview => {
                    this.setState({
                        previewImage: preview,
                        previewVisible: true,
                    });
                });
        }
        else {
            client.post(file.url)
                .then(response => response.blob())
                .then(blob => {
                    file.preview = getBase64(blob)
                        .then(preview => {
                            this.setState({
                                previewImage: preview,
                                previewVisible: true,
                            });
                        });
                });
        }
    };

    handleDownload(file) {
        FileSaver.saveAs(file.originFileObj || file.url);
    }

    uploadImages(options) {
        const { onSuccess, onError, file, onProgress } = options;
        let list = new DataTransfer();
        list.items.add(file);
        let files = list.files;

        const config = {
            headers: { "content-type": "multipart/form-data" },
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                this.setState({ progress: percent });
                if (percent === 100) {
                    setTimeout(() => this.setState({ progress: 0 }), 1000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            }
        };
        
        this.props.onUploadImages('newform', this.props.match.params.db, this.props.field.id, this.props.field.folder ? this.props.field.folder : 'system', files, config)
            .then(response => {
                if (response.status !== 'error') {
                    this.props.formData[this.props.field.id] = this.state.images.concat(response);
                    this.setState({ images: this.state.images.concat(response) }, function () {
                        this.props.change(this.props.field.id, this.state.images);
                    });
                    onSuccess(response[0].id);
                }
            })
            .catch(err => {               
                onError({ err });
            });

    };

    handleDelete(file) {
        let images = this.state.images; 
        let updatedImages = [];
        for (let i=0; i< images.length; i++) {
            if (images[i].id !== file.id) {
                updatedImages.push(images[i]);
            }
        }

        this.setState({images: updatedImages});
        this.props.change(this.props.field.id, updatedImages);
        //this.props.onDeleteImage(file.id, this.props.meta.form, this.props.field.id);
    }

    onChangeLink(e, type, key) {
        const name = (this.props.field.settings && this.props.field.settings.name) ? this.props.field.settings.name : 'imageLinks';
        this.props.change(name + '[' + key + '][' + type + ']', e.target.value);
    }

    addNewLink() { 
        const name = (this.props.field.settings && this.props.field.settings.name) ? this.props.field.settings.name : 'imageLinks';
        let imageLinks = this.props.formData[name];
        let newLink = { link: '' };
        imageLinks.push(newLink);
        this.setState({
            imageLinks
        });
    }

    removeLink(key) {
        const name = (this.props.field.settings && this.props.field.settings.name) ? this.props.field.settings.name : 'imageLinks';
        let imageLinks = this.props.formData[name];
        imageLinks.splice(key, 1)
        this.setState({
            imageLinks
        });
    }

    componentDidMount() {
        if (this.props.formData && this.props.formData[this.props.field.id] && this.props.formData[this.props.field.id].length>0) {
            let fileList = [];
            for (let i=0; i<this.props.formData[this.props.field.id].length; i++) {
                fileList.push(this.props.formData[this.props.field.id][i]);
                this.props.formData[this.props.field.id][i]['uid'] =this.props.formData[this.props.field.id][i]['id'];
                this.props.formData[this.props.field.id][i]['name'] = this.props.formData[this.props.field.id][i].name ? this.props.formData[this.props.field.id][i].name : this.props.formData[this.props.field.id][i].originalName
            }
            this.setState({
                fileList: fileList,
                images:  this.props.formData[this.props.field.id],
            });
        }
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.formData && prevProps.formData[this.props.field.id] !== this.props.formData[this.props.field.id]) {
            let fileList = [];
            for (let i=0; i<this.props.formData[this.props.field.id].length; i++) {
                fileList.push(this.props.formData[this.props.field.id][i]);
                this.props.formData[this.props.field.id][i]['uid'] =this.props.formData[this.props.field.id][i]['id'];
                this.props.formData[this.props.field.id][i]['name'] = this.props.formData[this.props.field.id][i].name ? this.props.formData[this.props.field.id][i].name : this.props.formData[this.props.field.id][i].originalName
            }

            this.setState({
                fileList: fileList
            });
        }
    }

    render() {
        const { previewVisible, previewImage, fileList } = this.state;
        const name = (this.props.field.settings && this.props.field.settings.name) ? this.props.field.settings.name : 'imageLinks';
        const uploadButton = (
            <Button>
                <UploadOutlined /> {plainTranslate(this.props.locale, 'Upload')}
            </Button>
        );

        return (
            <React.Fragment>
                <div className="col-md-6">
                    <div className="clearfix">
                        <Upload
                            multiple
                            customRequest={this.uploadImages}
                            onRemove={this.handleDelete}
                            defaultFileList={fileList}
                            fileList={fileList}
                            showUploadList={false}
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            onDownload={this.handleDownload}
                        >
                            {fileList.length >= 8 ? null : uploadButton}
                        </Upload>
                        {fileList && (
                            <DragDropContext onDragEnd={this.onDragEnd}>
                              <Droppable droppableId="droppable">
                                {provided => (
                                  <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {fileList.map((item, index) => (
                                      <Draggable key={item.uid ? item.uid : item.id} draggableId={item.uid ? item.uid : item.id} index={index}>
                                        {provided => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <UploadList
                                              listType="picture"
                                              showPreviewIcon ={true}
                                              showRemoveIcon ={true}
                                              showDownloadIcon={true}
                                              locale={en_US}
                                              items={[item]}
                                              onPreview={this.handleDownload}
                                              onChange={this.handleChange}
                                              onDownload={this.handleDownload}
                                              onRemove={this.handleDelete}     
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          )}
                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                            <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </div>
                </div>
                {this.props.field && this.props.field.settings && this.props.field.settings.links &&
                    <div className="col-md-6">
                        <React.Fragment>
                            {this.props.formData[name] && this.props.formData[name].length > 0 && this.props.formData[name].map((link, key) =>
                                <React.Fragment>
                                    <input className="form-control form-imageLinks" key={key + 'link'} value={link.link} onChange={(e) => this.onChangeLink(e, 'link', key)} />
                                    <i className="fa fa-trash link-icon" aria-hidden="true" onClick={() => this.removeLink(key)} title={plainTranslate(this.props.locale, 'Remove link')}></i>
                                    <br />
                                </React.Fragment>
                            )}
                              <button className="button-outline grey" style={{margin: '0'}} onClick={this.addNewLink}>{plainTranslate(this.props.locale, 'Add new link')}</button>
                        </React.Fragment>
                    </div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onUploadImages: (formId, db, id, folder, files) => dispatch(onUploadImages(formId, db, id, folder, files)),
    onDeleteImage: (id, formId, fieldId) => dispatch(onDeleteImage(id, formId, fieldId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Images));
