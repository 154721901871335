import React from 'react';
import {connect} from 'react-redux';
import "../redux-forms/types/tableCollection.css";
import Text from "../redux-forms/types/text";
import {plainTranslate} from "../common/translate";
import {onUploadFileGetColumns} from "../../actions/actions";
import {Field as FormField, reduxForm, FieldArray} from 'redux-form';
import Select from 'react-select';
import Checkbox from '../settings/checkbox.jsx';
import PaymentsTemplate from './paymentsTemplate.jsx';
import {Link} from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import FormLoader from "../forms/form-loader";

class PaymentsDataSourcePreview extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lines:[],
            errors: [],
            enableSave:false
        };

    }

    componentDidMount() {
        this.setState({data: this.props.location.state ? this.props.location.state.response : []});
    }

    render() {
        let data = this.state.data ? this.state.data : [];
        //let title = ;console.log(title);
        document.title = 'Payments Report '+ data.startDate + ' ' + data.endDate;

        let url = "/" + this.props.match.params.db + "/report/payments/filters";
        if(this.props.isPos){
            url = "/" + this.props.match.params.db + "/pos/report/payments/filters";
        }

        return (
                <div>
                    <div className="moduleSubMenu">
                        <div className="col-sm-12">
                            <Link to={url} style={{    textDecoration: "none"}}>
                                <h3><i className="ion-ios-arrow-back"></i> {plainTranslate(this.props.settings.locale, 'Back')}</h3>
                            </Link>
                        </div>
                    </div>
                    <div>
                        {Object.keys(data).length > 0 &&
                            <div className="grid-panel col-md-12 col-xs-12">
                                <ReactToPrint
                                  trigger={() => <div style={{position: 'absolute', top: '50px', right: 30, fontSize: 16, color: "#4eb0e2", fontWeight: 'bold'}}><a href="#" style={{color: "#4eb0e2"}}><i className="fa fa-download" style={{marginLeft: 5}}></i> {plainTranslate(this.props.settings.locale, 'Download')}</a></div>}
                                  content={() => this.componentRef}
                                />
                                <PaymentsTemplate data={data} ref={el => (this.componentRef = el)} />
                            </div>
                        }
                    </div>
                </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});


export default connect(mapStateToProps, mapDispatchToProps)(PaymentsDataSourcePreview);
