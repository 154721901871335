import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../../common/translate';

class Switch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.isChecked ? this.props.isChecked : false
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isChecked !== this.props.isChecked) {
            this.setState({
                value: this.props.isChecked
            });
        }
    }

    onChange(e) {
        this.props.onChange(!this.state.value);
        this.setState({value: !this.state.value});
    }

    render() {
        return <div className="switchComponent text-left">
            <input
                checked={this.state.value}
                onChange={this.onChange}
                type="checkbox"
                id={'check-status-' + this.props.id}
                label='status'
            />
            <label htmlFor={'check-status-' + this.props.id}></label>
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    // saveChanges: (data) => dispatch(saveChanges(data)),
    // deleteComment: (db, id) => dispatch(deleteComment(db, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Switch);
