import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Checkbox, Alert } from 'antd';
import showAToast from '../common/showAToast'
import client from '../../actions/client'
import { plainTranslate } from '../common/translate';
import "./login.css"
var loadjs = require('loadjs');

class WorkspaceCreate extends Component {
    formRef = React.createRef();
    constructor() {
        super();
        this.state = {
            company: '',
            email: '',
            code: '',
            errors: {},
            tokens: null,
            loading: false
        };
    }

    componentDidMount() {
        window.onSubmit = this.onSubmit
        loadjs('https://www.google.com/recaptcha/api.js');
        if (this.props.auth && this.props.auth.isAuthenticated) {
            this.props.logoutUser(null, false);
        }
        document.documentElement.style.backgroundColor = "#fbfaff";
    }

    componentDidUpdate(prevProps){
        loadjs('https://www.google.com/recaptcha/api.js');
    }

    handleSubmit = (values) => { 
        var form = new FormData();
        form.append('email', values.email)
        form.append('company', values.company)
        form.append('code', values.code)  
        form.append('token', this.state.tokens)          
        client
            .post('/workspace/create', form)
            .then(res => {              
                if(res.data && res.data.url){
                     this.props.history.push(res.data.url+'?email='+values.email)
                }
            })
            .catch(err => {       
                this.setState({
                    loading: false
                  })         
                if(err.response && err.response.data && err.response.data.error){
                    showAToast(err.response.data.error, 'errLoginFields', 'error')
                }
            })
     
    }

    onSubmit = (token) => { 
        this.setState({
            loading: true
          })
        setTimeout(
          () => {
            this.setState({
              tokens: token
            }, this.formRef.current.submit())
          }, 
          1000
        );
    }

    onFinishFailed = (values, errorFields, outOfDate ) => {    
        this.setState({
          loading: false
        })
      }

    render() {
        return (
            <div className="login">
            <div className="bg"><img className="login-hero" src="https://composity.com/images/org/e4e130.png" width="100%" height="100%"/> </div>
             <Row>
              <Col lg={1} />
              <Col lg={18} md={24} className="login-top">
                <Col lg={12} md={10} sm={12} xs={24}>
                  <img className="composity-logo" src="https://composity.com/images/org/8f621c.png" alt="Composity logo" />
                </Col>
                <Col lg={10} md={12} sm={12} xs={24}>
                  <Link className="login-button-white" to="/workspace/signin">{plainTranslate(this.props.settings.locale, 'Workspace signin')}</Link>
                </Col>
              </Col>
              </Row>
              <Row type="flex" justify="space-around" align="middle" className="login-mainRow">
                <Col lg={1} />
                <Col lg={16} md={24}>
                    <Col lg={12} md={24}>
                                            <Form
                                                ref={this.formRef}
                                                onFinish={this.handleSubmit}
                                                className="login-form"
                                                onFinishFailed={this.onFinishFailed}
                                            >
                                                <h2 className="text-center text-bold largeFont mtb-30"> { plainTranslate(this.props.settings.locale, 'Welcome to Composity')} </h2>
                                                <h5 className="text-center text-bold largeFont mtb-30"> { plainTranslate(this.props.settings.locale, 'Lets get started by creating your Composity workspace')} </h5>
                                                <Form.Item
                                                    className="login-input" 
                                                    name="company"                                                  
                                                    rules={[{ 
                                                        required: true, 
                                                        message: plainTranslate(this.props.settings.locale,'Please input company name') 
                                                    }]}
                                                >
                                                    <Input placeholder={plainTranslate(this.props.settings.locale,"Company name")}/>
                                                </Form.Item>
                                                <Form.Item
                                                    name="email"
                                                    className="login-input"
                                                    rules={[{ required: true, message: plainTranslate(this.props.settings.locale,'Please input email') }]}
                                                >
                                                    <Input placeholder={plainTranslate(this.props.settings.locale,"Email")}/>
                                                </Form.Item>
                                                <Form.Item
                                                    name="code"
                                                    className="login-input"
                                                    rules={[{ 
                                                        required: true, 
                                                        message: plainTranslate(this.props.settings.locale,'Please input workspace name') 
                                                    }]}
                                                >
                                                    <Input 
                                                    placeholder={plainTranslate(this.props.settings.locale,"Workspace")}/>
                                                </Form.Item>    
                                                {this.state.loading ?
                         <Button className="auth-button" 
                         style={{height: 50}}                         
                         loading={true}>
                         {plainTranslate(this.props.settings.locale, 'Create')}</Button>
                        :                                          
                                            <button className="g-recaptcha auth-button" 
                                            data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                            data-callback={'onSubmit'}
                                            data-action='submit'>
                                            {plainTranslate(this.props.settings.locale, 'Create')}</button>
    }
                                            </Form>
                                </Col>
                            </Col>
                         <Col lg={7} className="demo-col">
                     <div className="text-center">
                    <h2 className="login-title-white">{plainTranslate(this.props.settings.locale, 'The power to make smarter business decisions')}</h2>
                    <h3 className="login-subTitle">{plainTranslate(this.props.settings.locale, 'Get more tools for your business')}</h3>
                    <div className="buttons-section">
                    <a className="demo-button" target="_blank" href="https://composity.com/request-demo" size="small">{plainTranslate(this.props.settings.locale, 'REQUEST DEMO')}</a>
                    </div>
                  </div>
                </Col>
              </Row>
          </div>
        );
    }

}

WorkspaceCreate.propTypes = {
    auth: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    logoutUser: PropTypes.func.isRequired
  }
  
  const mapStateToProps = state => ({
    auth: state.auth,
    settings: state.settings
  });
  
  export default connect(mapStateToProps, { logoutUser })(WorkspaceCreate);