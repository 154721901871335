import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from "../../common/translate";
import DoubleCheckbox from './doubleCheckbox';
import Collapsible from './collapsible';
import showAToast from '../../common/showAToast';


class ListAnalisys extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            showMessage: false,
        };  
        this.onChange = this.onChange.bind(this);
        this.onSecondChange = this.onSecondChange.bind(this);
    }

    componentDidMount() {
       this.setState({
            value: this.props.input.value ? this.props.input.value : []
        });
    }

    onChange(name, id, pos) {
        const settings = this.state.value ? this.state.value : [];
        for (var i = 0; i < settings.length; i++) {
            if (settings[i]['id'] == id || settings[i]['name'] == name || settings[i]['type'] == id) {
                var index = i;
            }
        }
         
        if (index != undefined) {
            settings.splice(index, 1);
        } else {
            var choosen = this.props.field.chooseOneOfTheese;
            if (choosen && choosen.indexOf(name) >= 0) {
                let array = [];
                for (var q = 0; q < choosen.length; q++) {   
                    if (q !== choosen.indexOf(name)) {
                        array.push(choosen[q]);
                    }
                }
                for (var c = 0; c < array.length; c++) {
                    for (var s = 0; s < settings.length; s++) {
                        if (settings[s]['name'] == array[c]) { 
                            var choosenIndex = s;
                        }
                    }
                }
            }  

            if (choosenIndex == undefined) {
                let obj = {};
                obj['name'] = name;
                obj['type'] = id;
                obj['required'] = '0';
                obj['position'] = pos;
                settings.push(obj);  
            } else {
                showAToast('You can choose just one of these ' + choosen.toString(), 'errSettings', 'error'); 
            }
        }

        this.setState({
            settings,
        }, function () {
            this.props.change(this.props.field.id, this.state.value);
        });
    }
    
    onSecondChange(name) {
        const settings = this.state.value;
        for (var i = 0; i < settings.length; i++) {
            if (settings[i]['name'] == name) {
                var index = i;
            }
        }

        if (index !== undefined) {
            var val = settings[index]['required'];
            if (val === "1") {
                settings[index]['required'] = "0";
            } else {
                settings[index]['required'] = "1";
            }
        } else {
            showAToast('First you must activate the field!', 'errSettings', 'error'); 
        }
        
        this.setState({
            settings,
        }, function(){
            this.props.change(this.props.field.id, this.state.value);
        });
    }

     render() {
         let element = <div className="col-md-12 no-gutter">
                    <div className="panel panel-default" style={{marginTop: 0}}>
                        <table className="table">
                            <thead>
                            {this.props.field.fieldNames ?
                                <tr>
                                    {this.props.field.fieldNames && this.props.field.fieldNames.map((label, key) => (
                                            <th key={key}><Translate locale={this.props.settings.locale} value={label}/></th>
                                    ))}
                                </tr>
                                :
                                <tr>
                                    <th><Translate locale={this.props.settings.locale} value="Field"/></th>
                                    <th><Translate locale={this.props.settings.locale} value="Enable"/></th>
                                </tr>
                            }
                            </thead>
                            <tbody>
                                {this.props.fields && this.props.fields.map((item, key) => (
                                    <DoubleCheckbox
                                        locale={this.props.settings.locale}                                                   
                                        key={key}
                                        name={item.name}
                                        pos={item.position}
                                        id={item.type}
                                        isChecked={this.state.value && (this.state.value.find(cat => cat['name']==[item.name]) || this.state.value.find(cat => cat['id']==[item.type]))? true:false}
                                        isSecondChecked ={this.state.value && this.state.value.find(cat => (cat['name']==[item.name])&&cat['required']==='1') ? true:false}
                                        handleCheckboxChange={(name, id, pos) => this.onChange(item.name, item.type, item.position)}
                                        handleSecondCheckboxChange={this.onSecondChange}
                                        doubleCheckboxes={this.props.field.doubleCheckboxes ? true : false}
                                        />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>;
        return <React.Fragment>
                {this.props.field.collapsible ?
                    <Collapsible
                      locale={this.props.locale}
                      title={false}
                      subtitle={this.props.field.description}> 
                      {element}
                    </Collapsible>
                    : {element}
                }
                </React.Fragment>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ListAnalisys);


