import React, { Component } from 'react';
import {connect} from 'react-redux';
import ImagePicker from 'react-image-picker'
import 'react-image-picker/dist/index.css';
import { Button, Modal, Tabs, Skeleton, Card, Tooltip, Col, Row, Image, Breadcrumb, Upload, Input } from 'antd';
import Translate, {plainTranslate} from '../../common/translate';
import client from '../../../actions/client';
import { withRouter } from 'react-router-dom';
import {onUploadImageV2} from '../../../actions/actions';
import { PlusOutlined } from '@ant-design/icons';
import showAToast from '../../../components/common/showAToast'
 
//import images from local
const { Meta } = Card;
 
class MediaLibrary extends Component {
    constructor(props) {
        super(props)
        this.state = {
          images: [],
          currentDir: "all",
          loading: false
        }
        this.onPick = this.onPick.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.openModal = this.openModal.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.chooseImage = this.chooseImage.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    componentDidMount(){
        this.setState({'images': this.props.formData[this.props.field.id] ? this.props.formData[this.props.field.id] : []});
    }
 
    onPick(image,selectedImage, key) {
        if(image.length === 0){
            let filteredArray = this.state.images.filter(item => item.value !== key);
            this.setState({images: filteredArray});
        }else{
            selectedImage.value = key;
            this.setState({ images: [...this.state.images, selectedImage]});
        }
    }

    openModal(){
        this.setState({showModal: true});
        this.getFiles();
    }

    handleOk() {
        let images = this.state.images;

        if(this.props.formData[this.props.field.id]){
            images = this.state.images.concat(this.props.formData[this.props.field.id]);
        }

        this.props.change(this.props.field.id, images);
        //this.props.formData[this.props.field.id]=images;
        this.setState({showModal: false, searchValue: null});
    };

    handleCancel(){
        //this.props.formData[this.props.field.id]=null;
        this.setState({showModal: false, images: [], searchValue: null});
    };

    getFiles(path=null, searchValue=null){
        if(path && path.substring(0, 1) === "/"){
            path = path.substring(1);
        }
        this.setState({loading: true});
        client
        .post('/'+this.props.match.params.db+'/api/get/files/list', {'location': path ? path : this.state.currentDir, searchValue: searchValue})
        .then(response => {
          if(response.data && Object.keys(response.data).length > 0){
            this.setState({
              documents: response.data,
              loading: false
            })
          }
         })
        .catch(error => {

        })
    }

    chooseImage() {
        let dependency = null;
        if(this.props.field.dependency){
            dependency = this.props.formData[this.props.field.dependency];
            
            if(!dependency){
                showAToast('Field ' + this.props.field.dependency + ' is required to upload a file', 'baseError', 'error');
            }else{
                document.getElementById('imageUpload').click();
            }
        }else{
            document.getElementById('imageUpload').click();
        }
    }

    uploadImage(e) {
        const file = e.target.files[0];
        this.setState({loading:true});
        this.props.onUploadImageV2(this.props.form, this.props.match.params.db, this.props.fieldId, this.props.field.uploadUrl, file, this.props.formData[this.props.field.dependency]).then((response) => {
            this.setState({loading:false});
            this.setState({'images': [response]});
        });
    }

    onRemove(file){
        let filteredArray = this.props.formData[this.props.field.id].filter(item => item.full !== file.full);console.log(filteredArray);
        this.props.change(this.props.field.id, filteredArray);
        this.setState({images: filteredArray});
    }
 
    render() {
        let files = this.state.documents && this.state.documents.files ? this.state.documents.files : [];

        if(files){
             files = files.filter(item => (item.full.split('.').pop() === 'png' || item.full.split('.').pop() === 'jpg' || item.full.split('.').pop() === 'jpeg' || item.full.split('.').pop() === 'svg' || item.full.split('.').pop() === 'pdf'));
        }
        return (
        <div>
            <div style={{marginBottom: 5}}>
{/*                <Image.PreviewGroup>
                    {this.props.formData && this.props.formData[this.props.field.id] && this.props.formData[this.props.field.id].map(image => {
                        return <Image width={100} src={image.src ? image.src : image.full} />
                    })}
                </Image.PreviewGroup>*/}
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={this.props.formData && this.props.formData[this.props.field.id] ? this.props.formData[this.props.field.id] : []}
                    onRemove={this.onRemove}
                    //onPreview={handlePreview}
                    //onChange={handleChange}
                  >
                  {/*<div><PlusOutlined /><div style={{marginTop: 8}} onClick={this.openModal}>{plainTranslate(this.props.settings.locale, 'Media Library')}</div></div>*/}
                  </Upload>
                    <Button onClick={this.openModal}>{plainTranslate(this.props.settings.locale, 'Media Library')}</Button>
            </div>
            
            <Modal okButtonProps={{loading: this.state.loading}} width={1000} title={plainTranslate(this.props.settings.locale, 'Upload Images')} visible={this.state.showModal} onOk={this.handleOk} onCancel={this.handleCancel}>
                <Tabs defaultActiveKey="2">
                    <Tabs.TabPane tab={plainTranslate(this.props.settings.locale, 'Upload')} key="1">
                        <div className="row m-bot15">
                            <div className="text-center">
                                {this.state.loading && 'Loading...'}
                                {!this.state.loading && 'Drop your image here'}
                            </div>
                            <button 
                            type="button" 
                            className={'upload-btn btn btn' + ' upload-button'}
                            onClick={this.chooseImage}>
                                <i className="fa fa-upload"></i>
                                Choose
                            </button>
                            <input id={'imageUpload'} type="file" style={{visibility: 'hidden'}}
                                   onChange={this.uploadImage}/>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={plainTranslate(this.props.settings.locale, 'Library')} key="2">
                        <Skeleton loading={this.state.loading} />
                        {!this.state.loading && this.state.documents &&
                            <div>
                                <Row>
                                    <Col span={16}>
                                    {this.state.documents.path &&
                                        <Breadcrumb>
                                            <Breadcrumb.Item style={{cursor: 'pointer'}} onClick={() => this.getFiles()}>Home</Breadcrumb.Item>
                                            {this.state.documents.path.map(path => {
                                                if(path.name !== 'all'){
                                                    return <Breadcrumb.Item style={{cursor: 'pointer'}} onClick={() => this.getFiles(path.path)}>{path.name}</Breadcrumb.Item>
                                                }
                                            })}
                                        </Breadcrumb>
                                    }
                                    </Col>
                                    <Col span={8}>
                                        <Input.Search type="search" value={this.state.searchValue} allowClear placeholder={plainTranslate(this.props.settings.locale, "Enter file name")} onChange={(e) => this.setState({searchValue: e.target.value})} onSearch={(value) => this.getFiles(null, value)}/>
                                    </Col>
                                </Row>
                                <Row type="flex" justify="start" className="dir_row">
                                    {this.state.documents.files && this.state.documents.folders.map((folder, key) => {
                                        return <Col xs={24} sm={12} md={8} lg={6} xl={6} key={1} className="dir" style={{marginBottom: 60}}>
                                            <Card
                                                hoverable
                                                className="dir_folder"
                                                onClick={() => this.getFiles(folder.path)}
                                                cover={<i className="ion-ios-folder-outline" style={{fontSize: 38, textAlign: "center"}}></i>}>
                                                    <Meta className="dir_element text-center" title={<Tooltip title={folder.name}>
                                                    <h4 className="text-center">{folder.name.slice(0, 20)}{folder.name.length > 20 && <span>...</span>}</h4>
                                                    </Tooltip>} style={{textAlign: 'center', height: 14}}/>
                                            </Card>
                                        </Col>
                                    })}
                                </Row>
                                <div style={{display: "flex", flexWrap: "wrap"}}>{files && files.map((image,i) =>{
                                    return <div style={{textAlign: "center"}}>
                                        <Tooltip placement="bottom" title={image.full.split(this.props.match.params.db+"/")[1]}><ImagePicker 
                                          images={[{src: image.full, value: i}]}
                                          onPick={(val) => this.onPick(val,image,i)}
                                          multiple
                                        />
                                        {image.name}</Tooltip></div>
                                    })}
                                </div>
                            </div>
                        }
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
          </div>
        )
    }
}
 
const mapStateToProps = (state, ownProps) => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onUploadImageV2: (formId, db, id, url, file, dependency) => dispatch(onUploadImageV2(formId, db, id, url, file, dependency))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MediaLibrary));