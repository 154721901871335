import React, {Component} from 'react';
import Translate, {plainTranslate} from '../../common/translate';
import {connect} from 'react-redux';

class DoubleCheckbox extends Component {
    constructor(props) {
        super(props);
        this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
        this.toggleSecondCheckboxChange = this.toggleSecondCheckboxChange.bind(this);
    }

    toggleCheckboxChange() {
        this.props.handleCheckboxChange(this.props.name, this.props.id ? this.props.id : null, this.props.isChecked);
    }
    
    toggleSecondCheckboxChange() {
        this.props.handleSecondCheckboxChange(this.props.name, this.props.isSecondChecked);
    }

    render() {
        const {name, id} = this.props;
        let before = plainTranslate(this.props.locale, 'Yes');
        let after = plainTranslate(this.props.locale, 'No');
        return (
            <tr>
                {name &&
                <td>
                    <h5><Translate locale={this.props.locale} value={name}/></h5>
                </td>
                }
                <td>
                    <div before={before} after={after} className="slideThree text-left">
                        <input
                            type="checkbox"
                            value={id ? id : name}
                            checked={this.props.isChecked}
                            id={`check-${id ? id : name}`}
                            onChange={this.toggleCheckboxChange}
                            name={<Translate locale={this.props.locale} value={id ? id : name}/>}/>
                        <label htmlFor={`check-${id ? id : name}`}></label>
                    </div>
                </td>
                {this.props.doubleCheckboxes &&
                <td>
                    <div before={before} after={after} className="slideThree text-left">
                        <input
                            type="checkbox"       
                            id={`check1-${name}`} 
                            value={name}
                            checked={this.props.isSecondChecked}
                            onChange={this.toggleSecondCheckboxChange}
                            name={<Translate locale={this.props.locale} value={name}/>}
                            />
                        <label htmlFor={`check1-${name}`}></label>
                    </div>
                </td>
                }
            </tr>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DoubleCheckbox); 
