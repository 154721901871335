import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';
import { clearCurrentProfile } from './actions/authActions';
import { Provider } from 'react-redux';
import store from './store';
import routes from './routes/routes'
import Message from './components/common/message';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
//import cryptoRandomString from 'crypto-random-string';
//import Cookies from 'js-cookie'
import './App.css';
import MenuV2 from './components/sidebar-v2/menuV2';
import Header from "./components/header/header.jsx"
import setFileApi from './utils/setFileApi'
import 'antd/dist/antd.css';

let isAuthenticated = false;

// Check for token
if (localStorage.jwtToken && localStorage.jwtToken !== 'undefined') {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  isAuthenticated = true;
  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    store.dispatch(clearCurrentProfile());
    isAuthenticated = false;
    // Redirect to login
    //window.location.href = '/';
  }
}
//alert(localStorage.storageUrl)
if (localStorage.storageToken && localStorage.storageUrl) {
  setFileApi(localStorage.storageUrl, localStorage.storageToken)
}


class App extends Component {

  render() {
    const db = window.location.pathname.split('/')[1];
    let ext = window.location.pathname.includes(".pdf");

    let publicProject = window.location.pathname.includes("/public/project");
    let publicBoard = window.location.pathname.includes("/public/board");

    //  if(!Cookies.get('userSession') && Cookies.get('CookieConsent')){
    //     Cookies.set('userSession', cryptoRandomString({length: 20}));
    //  }
    return (
      <Provider store={store}>
        <Router>
          <div>
            {isAuthenticated && db !== 'workspace' && db !== '' && !ext && !publicProject && !publicBoard &&
              <React.Fragment>
                <Header db={localStorage.db} />
                <MenuV2 db={localStorage.db} />
              </React.Fragment>
            }
            {routes}
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />

            <Message
              locale={'en'}
              show={false}
              message={''}
              error={false} />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
