import React, { Component } from 'react';
import { connect } from 'react-redux';
import { plainTranslate } from '../common/translate';
import { Row, Col, Form, Input, Button, Checkbox, Alert } from 'antd';
import { findWorkplace, logoutUser } from '../../actions/authActions';
var loadjs = require('loadjs');

class WorkspaceSignin extends Component {
  formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            tokens: null,
            loading: false
        };
    }

    componentDidMount(){
     window.onSubmit = this.onSubmit
     loadjs('https://www.google.com/recaptcha/api.js');
     this.props.logoutUser(null,false);
     document.documentElement.style.backgroundColor = "#fbfaff";
    }

    componentDidUpdate(prevProps){
      loadjs('https://www.google.com/recaptcha/api.js');
  }

  onFinish = (values) => {   
    const workplace = {
      workspace_saas: values.workplace,
      token: this.state.tokens
  };
  this.props.findWorkplace(workplace, this.props.history);  
  this.setState({
    loading: false
  })     
}   

onSubmit = (token) => { 
  this.setState({
    loading: true
  })
    setTimeout(
      () => {
        this.setState({
          tokens: token
        }, this.formRef.current.submit())
      }, 
      1000
    );
}

onFinishFailed = (values, errorFields, outOfDate ) => {    
  this.setState({
    loading: false
  })
}
    render() {     
        return (
            <div className="login">
            <div className="bg"><img className="login-hero" src="https://composity.com/images/org/e4e130.png" width="100%" height="100%"/> </div>
             <Row>
              <Col lg={1} />
              <Col lg={18} md={24} className="login-top">
                <Col lg={12} md={10} sm={12} xs={24}>
                  <img className="composity-logo" src="https://composity.com/images/org/8f621c.png" alt="Composity logo" />
                </Col>
                <Col lg={10} md={12} sm={12} xs={24}>
                  <a className="login-buttonW-white" href="/workspace/create">{plainTranslate(this.props.settings.locale, 'Create workspace')}</a>
                </Col>
              </Col>
              </Row>
              <Row type="flex" justify="space-around" align="middle" className="login-mainRow">
                <Col lg={1} />
                <Col lg={16} md={24}>
                    <Col lg={12} md={24}>
                      <div className="">
                        <h1 className="login-title">{plainTranslate(this.props.settings.locale, 'Please fill in the name of your workspace')}</h1>
                       
                        <Form 
                        ref={this.formRef}
                        onFinish={this.onFinish} 
                        onFinishFailed={this.onFinishFailed}
                        className="login-form" 
                        layout="vertical">
                          <Form.Item 
                          name="workplace"
                          label={plainTranslate(this.props.settings.locale,"Your Workspace Name")} 
                          className="login-input"
                          rules={[
                            {
                              required: true,
                              message: plainTranslate(this.props.settings.locale,'Please input your workspace!'),
                            },
                          ]}>
                              <Input/>
                              </Form.Item>
                              {this.state.loading ?
                         <Button className="auth-button" 
                         style={{height: 50}}                         
                         loading={true}>
                         {plainTranslate(this.props.settings.locale, 'Continue')}</Button>
                        :
                              <button className="g-recaptcha auth-button" 
                                  data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                  data-callback={'onSubmit'}
                                  data-action='submit'>
                                    {plainTranslate(this.props.settings.locale, 'Continue')}
                              </button>}
                     <br/>
                     </Form>
                      </div>
                    </Col>
                </Col>
                <Col lg={7} className="demo-col">
                  <div className="text-center">
                    <h2 className="login-title-white">{plainTranslate(this.props.settings.locale, 'The power to make smarter business decisions')}</h2>
                    <h3 className="login-subTitle">{plainTranslate(this.props.settings.locale, 'Get more tools for your business')}</h3>
                    <div className="buttons-section">
                      <a className="demo-button" target="_blank" href="https://composity.com/request-demo" size="small">{plainTranslate(this.props.settings.locale, 'REQUEST DEMO')}</a>
                    </div>
                    <div style={{textAlign:"center", marginBottom: "10px", color: "#aaa"}}>
                      Powered by <a style={{color:"#777", textDecoration:"underline"}} target="_blank" href="https://composity.com/" title="Composity Business Software - ERP, CRM, eCommerce, Helpdesk">Composity Business Software</a>
                    </div> 
                  </div>
                </Col>
              </Row>
          </div>
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings
});

export default connect(mapStateToProps, { findWorkplace, logoutUser })(WorkspaceSignin);