import React, { Component } from 'react';
import { render } from "react-dom";
import Translate, { plainTranslate } from '../../common/translate';
import { onItemRequestsFetchData, onItemRequestView, onStagesFetchData, onChangeStage, onDatesChange } from '../../../actions/actions';
import FormLoader from '../../forms/form-loader';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Select, Input } from 'antd';
import AsyncSelect from 'react-select/lib/Async';
import 'antd/lib/table/style/index.css';
import 'antd/lib/select/style/index.css';
import 'antd/lib/tabs/style/index.css';
import 'antd/lib/tooltip/style/index.css';
import "../style.css"
import List from '../components/list';
import SelectedRowDetails from '../components/selected-row-details';
import {DatePicker as DatePickerComponent, Tooltip} from 'antd';
import SlideModal from '../../common/SlideModal';
import 'antd/lib/checkbox/style/index.css';
import { withRouter } from 'react-router-dom';
import client from '../../../actions/client';
import moment from 'moment';
import { Menu, Dropdown, Button } from 'antd';
import showAToast from '../../common/showAToast';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const { Option } = Select;
const { RangePicker } = DatePickerComponent;

const divSpace = {
  marginBottom: 25,
  height: 60
};
const divTopspace = {
  marginTop: 15,
  marginBottom: 25,
};
const emptyValue = {
  color: "grey"
};

class InventoryRequestsDetailsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: 'itemRequestAvailability',
            requests: [],
            openFilter: false,
            page: 1,
            startDate: null,
            endDate: null,
            checkedSaleLines: []
        };
        this.onChooseStage = this.onChooseStage.bind(this);
        this.onItemRequestClick = this.onItemRequestClick.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.onChangeSingleStage = this.onChangeSingleStage.bind(this);
        this.changePage = this.changePage.bind(this);
        this.handleDatesChange = this.handleDatesChange.bind(this);
        this.filterOpen = this.filterOpen.bind(this);
        this.onChooseLocationFrom = this.onChooseLocationFrom.bind(this);
        this.onChooseLocationTo = this.onChooseLocationTo.bind(this);
        this.onChooseProcessFilter = this.onChooseProcessFilter.bind(this);
        this.onChooseSaleFilter = this.onChooseSaleFilter.bind(this);
        this.onChooseDealFilter = this.onChooseDealFilter.bind(this);
        this.changeSelected = this.changeSelected.bind(this);
        this.onBulkActionChangeStage = this.onBulkActionChangeStage.bind(this);
        this.onChooseVendor = this.onChooseVendor.bind(this);
        this.searchVendor = this.searchVendor.bind(this);
    }

    componentDidMount() {
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
        localStorage.removeItem('requests');
        this.setState({ active: localStorage.getItem('activeItemRequestTab') ? localStorage.getItem('activeItemRequestTab') : 'itemRequestAvailability' });
        {/*this.setState({ requests: localStorage.getItem('requests') ? (localStorage.getItem('requests')).split(",").map(String) : [] });*/}
        this.setState({ stages: localStorage.getItem('stages') ? (localStorage.getItem('stages')).split(",").map(String) : null });
        this.setState({ fromLocation: localStorage.getItem('fromLocation') && localStorage.getItem('fromLocation') !== "null" ? localStorage.getItem('fromLocation') : null });
        this.setState({ toLocation: localStorage.getItem('toLocation') && localStorage.getItem('toLocation') !== "null" ? localStorage.getItem('toLocation') : null });
        this.setState({ processFilter: localStorage.getItem('processFilter') == "true" ? true : localStorage.getItem('processFilter') == "false" ? false : null });
        this.setState({ saleFilter: localStorage.getItem('saleFilter') == "true" ? true : localStorage.getItem('saleFilter') == "false" ? false : null });
        this.setState({ dealFilter: localStorage.getItem('dealFilter') == "true" ? true : localStorage.getItem('dealFilter') == "false" ? false : null });
        this.setState({ vendor: localStorage.getItem('vendor') ? JSON.parse(localStorage.getItem('vendor')) : [] });

        this.props.onStagesFetchData('/' + this.props.match.params.db + '/api/ordered/items/get/options').then(() => {
            this.setState({vendorOptions : this.props.itemRequests.stage.vendors});
        });
        let start;
        let end;
        let stateEnd;
        if (localStorage.getItem('grid_startDate')) {
            start = moment(localStorage.getItem('grid_startDate'));
        }
        else if (localStorage.getItem('grid_range')) {
            start = moment().subtract(localStorage.getItem('grid_range'), 'days');
        } else {
            start = moment();
        }
        if(localStorage.getItem('grid_endDate')){
            end = moment(localStorage.getItem('grid_endDate'));
        }else{
            end = moment();
        }

        stateEnd = end.year() + '-' + (end.month() + 1) + '-' + (end.date());
        end.add('day', 1);
        start = start.year() + '-' + (start.month() + 1) + '-' + start.date();
        end = end.year() + '-' + (end.month() + 1) + '-' + (end.date());

        this.setState({start : start, end: end, stateEnd: stateEnd}, function() {
            this.props.onItemRequestsFetchData(this.props.match.params.db, '/' + this.props.match.params.db + '/api/ordered/items/details', this.state.start, this.state.end, this.state.stages, 1, this.state.fromLocation, this.state.toLocation, this.state.processFilter, this.state.saleFilter, this.state.vendor, this.state.dealFilter);
        });
    }

    componentDidUpdate(prevState) {
        //console.log(this.state)
    }

    onChooseStage(value) {
        clearTimeout(this.inputTimer);
        this.setState({stages: value}, function() {
            localStorage.setItem('stages', value);
            this.inputTimer = setTimeout(() => {
                this.props.onItemRequestsFetchData(this.props.match.params.db, '/' + this.props.match.params.db + '/api/ordered/items/details', this.state.start, this.state.end, this.state.stages, 1, this.state.fromLocation, this.state.toLocation, this.state.processFilter, this.state.saleFilter, this.state.vendor, this.state.dealFilter);
            }, 2000);
        });
    }

    onItemRequestClick(id) {
        this.props.onItemRequestView('/' + this.props.match.params.db + '/api/ordered/item/' + id + '/details');
    }

    changeTab(tab) {
        localStorage.setItem('activeItemRequestTab', tab);
        this.setState({ active: tab });
    }

    onChangeSingleStage(stageId, requestId) {
        client
        .post('/' + this.props.match.params.db + '/api/item-request/change/status/' + stageId, {body: requestId})
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(response.errors, 'baseError', 'error');
            }
            else {
                this.props.onItemRequestView('/' + this.props.match.params.db + '/api/ordered/item/' + requestId + '/details');
                this.props.onChangeStage(stageId, requestId, response.stage.name);
                showAToast(response.success, 'baseSuccess', 'success');
            }
        });
    }

    changePage(page) {
        this.setState({page: page}, function(){
             this.props.onItemRequestsFetchData(this.props.match.params.db, '/' + this.props.match.params.db + '/api/ordered/items/details', this.state.start, this.state.end, this.state.stages, this.state.page, this.state.fromLocation, this.state.toLocation, this.state.processFilter, this.state.saleFilter, this.state.vendor, this.state.dealFilter);
        });
    }

    onCalendarOpen = (open) => {        
        this.setState({
            openRangePicker: open
        })
        setTimeout(() => {
            if(open === false && this.state.startDate && this.state.endDate){
                this.handleDatesChange([this.state.startDate, this.state.endDate])
            }
        }, 1000)
    }

    onCalendarChange = (dates, dateStrings, range) => {    
        let start = dates[0] ? dates[0] : null;
        let end = dates[1] ? dates[1] : null;
        let daysDiff = (moment(end).diff(moment(start), 'days'));

        if (daysDiff < 31) {
            localStorage.setItem('grid_range', daysDiff);
            localStorage.setItem('grid_startDate', moment(start));
            localStorage.setItem('grid_endDate', moment(end));
            this.props.onDatesChange(start, end);
        }

        if(start){            
            start = dates[0].format('YYYY-MM-DD')//start.year() + '-' + (start.month() + 1) + '-' + start.date();
           
            this.setState({
                startDate:start
            })
        }
        if(end){            
            end = dates[1].format('YYYY-MM-DD')+ ' 23:59:59'//end.year() + '-' + (end.month() + 1) + '-' + end.date() + ' 23:59:59';
            
            this.end = end
            this.setState({
                endDate: end
            })
        }  
    }

    handleDatesChange(event) {
        let start = event[0];
        let end = event[1];
        let daysDiff = (moment(end).diff(moment(start), 'days'));

        localStorage.setItem('grid_range', daysDiff);
        localStorage.setItem('grid_startDate', moment(start));
        localStorage.setItem('grid_endDate', moment(end));

     //   start = start.year() + '-' + (start.month() + 1) + '-' + start.date();
     //   end = end.year() + '-' + (end.month() + 1) + '-' + end.date() + ' 23:59:59';
        this.setState({start : start, end: end, stateEnd: end}, function() {
            this.props.onDatesChange(start, end);
            this.props.onItemRequestsFetchData(this.props.match.params.db, '/' + this.props.match.params.db + '/api/ordered/items/details', this.state.start, this.state.end, this.state.stages, 1, this.state.fromLocation, this.state.toLocation, this.state.processFilter, this.state.saleFilter, this.state.vendor, this.state.dealFilter);
        });
    }

    componentWillReceiveProps(nextProps) {
        let savedStages = localStorage.getItem('stages');
        if (nextProps.itemRequests && nextProps.itemRequests.stage && nextProps.itemRequests.stage.defaultStage && !savedStages) {
            this.setState({stages: nextProps.itemRequests.stage.defaultStage});
        } else if (nextProps.itemRequests && nextProps.itemRequests.stage && nextProps.itemRequests.stage.stages && !savedStages) {
            let stageArray = [];
            let i;
            for (i = 0; i < nextProps.itemRequests.stage.stages.length; i++) {
              stageArray[i] = nextProps.itemRequests.stage.stages[i].value;
            }
            this.setState({stages: stageArray});
        }
    }

    filterOpen() {
        this.setState({openFilter: !this.state.openFilter});
    }

    onChooseLocationFrom(value) {
        this.setState({fromLocation: value}, function() {
            localStorage.setItem('fromLocation', value);
             this.props.onItemRequestsFetchData(this.props.match.params.db, '/' + this.props.match.params.db + '/api/ordered/items/details', this.state.start, this.state.end, this.state.stages, 1, this.state.fromLocation, this.state.toLocation, this.state.processFilter, this.state.saleFilter, this.state.vendor, this.state.dealFilter);
        });
    }

    onChooseLocationTo(value) {
        this.setState({toLocation: value}, function() {
            localStorage.setItem('toLocation', value);
             this.props.onItemRequestsFetchData(this.props.match.params.db, '/' + this.props.match.params.db + '/api/ordered/items/details', this.state.start, this.state.end, this.state.stages, 1, this.state.fromLocation, this.state.toLocation, this.state.processFilter, this.state.saleFilter, this.state.vendor, this.state.dealFilter);
        });
    }

    onChooseProcessFilter(value) {
        this.setState({processFilter: value}, function() {
            localStorage.setItem('processFilter', value);
             this.props.onItemRequestsFetchData(this.props.match.params.db, '/' + this.props.match.params.db + '/api/ordered/items/details', this.state.start, this.state.end, this.state.stages, 1, this.state.fromLocation, this.state.toLocation, this.state.processFilter, this.state.saleFilter, this.state.vendor, this.state.dealFilter);
        });
    }

    onChooseSaleFilter(value) {
        this.setState({saleFilter: value}, function() {
            localStorage.setItem('saleFilter', value);
             this.props.onItemRequestsFetchData(this.props.match.params.db, '/' + this.props.match.params.db + '/api/ordered/items/details', this.state.start, this.state.end, this.state.stages, 1, this.state.fromLocation, this.state.toLocation, this.state.processFilter, this.state.saleFilter, this.state.vendor, this.state.dealFilter);
        });
    }

    onChooseDealFilter(value) {
        this.setState({dealFilter: value}, function() {
            localStorage.setItem('dealFilter', value);
             this.props.onItemRequestsFetchData(this.props.match.params.db, '/' + this.props.match.params.db + '/api/ordered/items/details', this.state.start, this.state.end, this.state.stages, 1, this.state.fromLocation, this.state.toLocation, this.state.processFilter, this.state.saleFilter, this.state.vendor, this.state.dealFilter);
        });
    }

    onChooseVendor(value) {
        this.setState({vendor: value}, function() {
            localStorage.setItem('vendor', JSON.stringify(value));
             this.props.onItemRequestsFetchData(this.props.match.params.db, '/' + this.props.match.params.db + '/api/ordered/items/details', this.state.start, this.state.end, this.state.stages, 1, this.state.fromLocation, this.state.toLocation, this.state.processFilter, this.state.saleFilter, this.state.vendor, this.state.dealFilter);
        });
    }

    changeSelected(requests) {
        this.setState({requests: requests}, function() {
            localStorage.setItem('requests', requests);
        });
    }

    onBulkActionChangeStage(stageId) {
        client
        .post('/' + this.props.match.params.db + '/api/item-request/change/stage/' + stageId,{body: this.state.requests})
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(response.errors, 'baseError', 'error');
            }
            else {
                this.setState({requests: []}, function() {
                    localStorage.removeItem('requests');
                    this.props.onItemRequestsFetchData(this.props.match.params.db, '/' + this.props.match.params.db + '/api/ordered/items/details', this.state.start, this.state.end, this.state.stages, 1, this.state.fromLocation, this.state.toLocation, this.state.processFilter, this.state.saleFilter, this.state.vendor, this.state.dealFilter);
                });

                showAToast(response.success, 'baseSuccess', 'success');
            }
        });
    }

    searchVendor(value, callback) {
        client
        .get('/' + this.props.match.params.db + '/api/billing-data?itemSupplier='+value)
        .then(response => response.data)
        .then(response => {
            let options = [];
            if (response) {
                options = response.map(option => {
                    return {
                        ...option,
                        'value': option.value,
                        'label': option.label
                    };
                });
            }
            this.options = options;
            callback(options ? options : this.state.vendorOptions);
        });
    }

    checkboxSelect = (e, locId) => {
        let checkedSaleLines = this.state.checkedSaleLines;
        if (e.target.value != '' && e.target.value != 0) {
            let index = checkedSaleLines.findIndex(x => x.locId === locId);
            if (index > -1) {
                checkedSaleLines[index]['qty'] = e.target.value;
            } else {
                checkedSaleLines.push({locId: locId, qty: e.target.value});
            }
        } else {
            let index = checkedSaleLines.findIndex(x => x.locId === locId);
            if (index > -1) {
                checkedSaleLines.splice(index, 1);
            }
        }
        this.setState({ checkedSaleLines: checkedSaleLines });
    }

    createMultipleRequest = () => {
        let itemId = this.props.itemRequests.itemRequest.itemId;
        let locId = this.props.itemRequests.itemRequest.locationIdFrom;
        let stageId = this.props.itemRequests.itemRequest.stageId;
        let parentId = this.props.itemRequests.itemRequest.id;
        let date = moment().format('YYYY-MM-DD');
        let data = [];
        for (let i = 0; i < this.state.checkedSaleLines.length; i++) {
            data.push({
                body: {
                    stageId: stageId,
                    date: date,
                    locId: this.state.checkedSaleLines[i].locId,
                    parentId: parentId,
                    locIdTo: locId,
                    lines: [
                        {
                            value: itemId,
                            itId: itemId,
                            quantity: this.state.checkedSaleLines[i].qty,
                        }
                    ]
                }
            });
        }
        if (data.length) {
            client
                .post('/' + this.props.match.params.db + '/api/item-request/array', data)
                .then(response => response.data)
                .then(response => {
                    if ('errors' in response) {
                        showAToast(response.errors, 'baseError', 'error');
                    }
                    else {
                        this.setState({ checkedSaleLines: [] });
                        showAToast(response.success, 'baseSuccess', 'success');
                    }
                });
        }
    }

    render() {
        let list = this.props.itemRequests && this.props.itemRequests.itemRequests && this.props.itemRequests.itemRequests.data ? this.props.itemRequests.itemRequests.data : [];
        let count = this.props.itemRequests && this.props.itemRequests.itemRequests && this.props.itemRequests.itemRequests.count ? this.props.itemRequests.itemRequests.count : 0;
        let stages = this.props.itemRequests && this.props.itemRequests.stage && this.props.itemRequests.stage.stages ? this.props.itemRequests.stage.stages : [];
        let roles = this.props.itemRequests && this.props.itemRequests.stage && this.props.itemRequests.stage.roles ? this.props.itemRequests.stage.roles : [];
        let locations = this.props.itemRequests && this.props.itemRequests.stage && this.props.itemRequests.stage.locations ? this.props.itemRequests.stage.locations : [];
        let single = this.props.itemRequests && this.props.itemRequests.itemRequest ? this.props.itemRequests.itemRequest : false;
        const availabilityColumns = [
            {
                'key': 'batch',
                'dataIndex': 'batch',
                'title': plainTranslate(this.props.settings.locale, 'Batch'),
            },
            {
                'key': 'location',
                'dataIndex': 'location',
                'title': plainTranslate(this.props.settings.locale, 'Location'),
            },
            {
                'key': 'minQty',
                'dataIndex': 'minQty',
                 sorter: {
                    compare: (a, b) => a.qty - b.qty,
                    multiple: 2,
                  },
                'title': plainTranslate(this.props.settings.locale, 'Min Quantity'),
            },
            {
                'key': 'qty',
                'dataIndex': 'qty',
                 sorter: {
                    compare: (a, b) => a.qty - b.qty,
                    multiple: 2,
                  },
                'title': plainTranslate(this.props.settings.locale, 'Quantity'),
            },
            {
                'title': '',
                'key': 'choose',
                'fixed': 'left',
                'width': 30,
                'hide': true,
                render: (text, record) => (
                    <span> 
                        {record.itemCountStock != 8 && record.itemCountStock != 9 ?
                            <Input type='number' style={{minWidth:'50px'}} onChange={(e) => this.checkboxSelect(e, record.locationId)}></Input>
                        : ''}
                    </span> 
                )  
            },
            {
                'title': plainTranslate(this.props.settings.locale, 'Action'),
                'dataIndex': '',
                key: 'x',
                render: (text, record) => (
                <span>
                    <Link to={"/" + this.props.match.params.db + "/item-requests/new?itemIds=" + single.id + "&location=" + record.locationId + "&page=itemRequests"}>
                            <span>{plainTranslate(this.props.settings.locale, 'Create request')}</span>
                    </Link>
                  </span>
                ),
              },
        ];
        let dropdownOptions = [];
        dropdownOptions = (
            <Menu>
                    <Menu.Item>
                        <Link to={this.state.requests && this.state.requests.length > 0 ? "/" + this.props.match.params.db + "/item-requests/new?itemIds=" + this.state.requests + "&page=itemRequests" : "/" + this.props.match.params.db + "/item-requests/new?page=itemRequests"}>
                            <Translate locale={this.props.settings.locale} value='New Request' />
                        </Link>
                    </Menu.Item>
                    {roles && roles.transfer && roles.transfer.hasCreate &&
                        <Menu.Item>
                            <Link to={"/" + this.props.match.params.db + "/receipt/new?itemIds=" + this.state.requests + "&page=itemRequests"}>
                                <Translate locale={this.props.settings.locale} value='Transfer' />
                            </Link>
                        </Menu.Item>
                    }
            </Menu>
        );

/*        let startDate = moment(this.state.start,'YYYY-M-DD');console.log(startDate);
        let endDate = moment("2021-9-26",'YYYY-MM-DD');console.log(endDate);*/

        let start;
        let end;
        let stateEnd;
        if (localStorage.getItem('grid_startDate')) {
            start = moment(localStorage.getItem('grid_startDate'));
        }
        else if (localStorage.getItem('grid_range')) {
            start = moment().subtract(localStorage.getItem('grid_range'), 'days');
        } else {
            start = moment();
        }
        if(localStorage.getItem('grid_endDate')){
            end = moment(localStorage.getItem('grid_endDate'));
        }else{
            end = moment();
        }

        return (
            <div>
                <div className="col-md-12 no-gutter">
                    <div className="col-lg-12 col-md-12 col-xs-12 account-profile item-requestPage no-gutter">
                        <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb" style={{padding: "10px 0 5px 10px", background: "white", margin: 0}}>
                            <Link to={"/" + this.props.match.params.db + "/inventory-requests"}>
                                <i className="ion-ios-arrow-back"></i>{plainTranslate(this.props.settings.locale, 'Requests')}
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12 no-gutter listGrid">
                          <RangePicker
                                  bordered={false}
                                  defaultValue={[start, end]}
                                  open={this.state.openRangePicker}
                                  onOpenChange={this.onCalendarOpen}
                                  onCalendarChange={this.onCalendarChange}
                                  format={this.props.settings.dateFormat}
                                  focusedInput={this.state.focusedInput}
                                  onFocusChange={(focusedInput) => {
                                      this.setState({ focusedInput })
                                  }}
                                  disabledDate={ d => {
                                      return false;
                                  }}
                                  separator={<span style={{display: "flex", fontSize: "9px", left: "-10px", position: "relative"}}><LeftOutlined /><RightOutlined /></span>}
                                  ranges={{
                                      'Today': [moment(), moment()],
                                      'This week': [moment().subtract(1, 'weeks'), moment()],
                                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                                      'Last Month': [moment().subtract(30, 'days'), moment()],
                                  }}
                              />
                          <div className="list-header">
                          <span className="filterBox" onClick={this.filterOpen}><img style={{width: 15}} src="/images/filter.svg" alt="filter"/> {plainTranslate(this.props.settings.locale, 'Filters')}</span>
                            <div className="col-lg-3 col-md-3 no-gutter">
                              <div className="btn-group">
                                    <Dropdown overlay={dropdownOptions} placement="bottomCenter" className="button-primary dropdown-toggle">
                                        <Button style={{width: "80px!important",minWidth: "auto"}}><Translate locale={this.props.settings.locale} value="New" /></Button>
                                    </Dropdown>   
                                  </div>
                              </div>
                              {this.state.requests && this.state.requests.length > 0 &&
                                <div>
                                    {stages &&
                                    <div>
                                        <label>{plainTranslate(this.props.settings.locale, 'Change stage')}</label>
                                        <Select
                                        onChange={(val) => this.onBulkActionChangeStage(val)}
                                            style={{ width: "100%"}}
                                            >
                                            {stages.map(stage =>
                                              <Option value={stage.value} key={stage.value} disabled = {stage.forbiddenAccess}>
                                                  {plainTranslate(this.props.settings.locale, stage.label)}</Option>
                                            )}
                                        </Select>
                                    </div>
                                    }
                                </div>
                                }
                            </div>
                            <div className="list-table">
                              <List
                                loading={this.props.itemRequests.loading}
                                data={list}
                                count={count}
                                clickRow={this.onItemRequestClick}
                                page={this.changePage}
                                onChangeSelected={this.changeSelected}/>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12 no-gutter item-profile-right">
                          <div className="listSingle-info col-md-12 no-gutter">
                            <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                              <div className="listSingle-shodow col-md-12">
                                <div className="listSingle-header">
                                  <div className="col-md-6">
                                    <h4>{single.requestNo}</h4>
                                  </div>
                                </div>
                                <div className="nav nav-tabs" id="tabBtn">
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 basic no-gutter">
                                        <button className={this.state.active == 'itemRequestMovements' ? 'request-tabs-tab active' : 'request-tabs-tab'} type="button" onClick={() => this.changeTab('itemRequestMovements')}>
                                            {plainTranslate(this.props.settings.locale, 'Movements')}
                                        </button>

                                        <button className={this.state.active == 'itemRequestAvailability' ? 'request-tabs-tab active' : 'request-tabs-tab'} type="button" onClick={() => this.changeTab('itemRequestAvailability')}>
                                            {plainTranslate(this.props.settings.locale, 'Availability')}
                                        </button>

                                    </div>
                                </div>
                                <div className="col-md-12 listSingle-body">
                                  <div className="col-md-6">
                                  <h2>{plainTranslate(this.props.settings.locale, 'Request')} # <Link to={"/" + this.props.match.params.db + "/item-requests/" + single.id  + "/edit"}>{single.requestNo}</Link></h2>
                                    <label>{plainTranslate(this.props.settings.locale, 'Sales order')}</label> <strong><Link to={"/" + this.props.match.params.db + "/sale/" + single.saleId + '/profile'} style={{color: "#4eb0e2"}}>{single.sale}</Link></strong>
                                    <h3>{plainTranslate(this.props.settings.locale, 'Stage')}</h3>
                                    <div className="stage greenBorder col-md-10 no-gutter" style={{paddingTop: 5}}>
                                      <span>{plainTranslate(this.props.settings.locale, 'Request')}</span>
                                      {stages &&
                                        <label className="pull-right">
                                          <Select 
                                          onChange={(val) => this.onChangeSingleStage(val, single.id)}
                                          style={{ width: 120 }} value={single.stageId} >
                                              {stages.map(stage =>
                                                  <Option value={stage.value} key={stage.value} >{plainTranslate(this.props.settings.locale, stage.label)}</Option>
                                              )}
                                          </Select>
                                        </label>
                                      }
                                    </div>
                                      <div className="stage orange col-md-10 no-gutter" style={{marginBottom:15}}>
                                       {single && single.docs.length > 0 && single.docs.map(doc => (
                                           <div style={{marginBottom: 10}}><span>{plainTranslate(this.props.settings.locale, doc.type)} {doc.no}</span> <label className="pull-right">{doc.status}</label></div>
                                       ))}
                                       </div>
                                     </div>
                                  <div className="col-md-6">
                                    <h3>{single.locationTo}</h3>
                                    <div>
                                      <label>{plainTranslate(this.props.settings.locale, 'Item')}</label> 
                                      <Link to={"/" + this.props.match.params.db + "/administration/items/" + single.itemId + "/edit"} target="_blank">
                                            <span> <strong>{single.itemName}</strong></span>
                                      </Link>
                                    </div>
                                    <div>
                                      <label>{plainTranslate(this.props.settings.locale, 'Code')}</label>
                                      <Link to={"/" + this.props.match.params.db + "/item/" + single.itemId + "/profile"} target="_blank">
                                            <span> <strong>{single.itemNo}</strong></span>
                                      </Link>
                                    </div>
                                    <div>
                                      <label>{plainTranslate(this.props.settings.locale, 'Supplier')}</label> <strong>{single.itemSupplier}</strong>
                                    </div>
                                    {single.qtyCancelled &&
                                        <div className="listSingle-item red">
                                          <label>{plainTranslate(this.props.settings.locale, 'Quantity Cancelled')}:</label>
                                          <label className="qty float-right"> <span>{single.qtyCancelled}</span></label>
                                         </div>
                                     }
                                     {single.itemMinQty ?
                                         <div className="listSingle-item">
                                          <label>{plainTranslate(this.props.settings.locale, 'Min Quantity')}:</label>
                                          <label className="qty float-right"> <span>{single.itemMinQty}</span></label>
                                         </div> 
                                        : '' 
                                     }

                                    <div className="listSingle-item">
                                      <label>{plainTranslate(this.props.settings.locale, 'Quantity Ordered')}:</label>
                                      <label className="qty float-right"> <span>{single.quantity}</span></label>
                                     </div>   
                                  </div>
                                </div>
                              </div>
                                {this.props.itemRequests.loadingSingleView  ? <FormLoader/> :
                                <div className="col-md-12 col-xs-12 no-gutter">
                                    {
                                        this.state.active === 'itemRequestMovements' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                                            <SelectedRowDetails data={single} />
                                        </div>
                                    }
                                    {
                                        this.state.active === 'itemRequestAvailability' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12" style={{marginTop:20}}>
                                            {this.state.checkedSaleLines && this.state.checkedSaleLines.length ?
                                                <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Create multiple item requests')}>
                                                        <Button onClick={() => this.createMultipleRequest()} type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px', position: 'relative', float: 'right', top: '-14px'}}>  
                                                            <i className="fa fa-truck" style={{ cursor: 'pointer' }} title={plainTranslate(this.props.settings.locale, 'Create multiple item requests')}></i>   
                                                        </Button>
                                                </Tooltip> 
                                                : ''
                                            }
                                            <Table
                                              rowKey={record => record.location}
                                              columns={availabilityColumns}
                                              dataSource={single.availability}
                                              bordered
                                              title={() => <strong>{single.itemName}</strong>}
                                              className="listSingle-table"/>
                                        </div>
                                    }
                                </div>
                                }
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.openFilter &&
                    <SlideModal onClose={() => this.filterOpen()} title={plainTranslate(this.props.settings.locale, 'Filters')}>
                        <div className="col-lg-12 col-md-12 no-gutter">
                              <div className="col-md-12" style={divTopspace}>
                              {stages &&
                                <div>
                                  <label><strong>{plainTranslate(this.props.settings.locale, "Stage")}</strong></label>
                                  <Select
                                        style={{ width: "100%"}}
                                        value={this.state.stages}
                                        mode="multiple"
                                        onChange={this.onChooseStage}
                                  >
                                      {stages.map(stage =>
                                          <Option value={stage.value} key={stage.value} >{plainTranslate(this.props.settings.locale, stage.label)}</Option>
                                      )}
                                  </Select>
                                </div>
                              }
                              </div>
                              <div className="col-md-6" style={divSpace}>
                              {locations &&
                                <div>
                                    <label><strong>{plainTranslate(this.props.settings.locale, "From location")}</strong></label>
                                      <Select style={{ width: '100%' }} 
                                      className="listFilters-select" 
                                      value={this.state.fromLocation} 
                                      onChange={this.onChooseLocationFrom}>
                                          <Option value={null} >...</Option>
                                          {locations.map(loc =>
                                              <Option value={loc.value} key={loc.value}>{plainTranslate(this.props.settings.locale, loc.label)}</Option>
                                          )}
                                      </Select>
                                    </div>
                              }
                              </div>
                              <div className="col-md-6" style={divSpace}>
                              {locations &&
                                <div>
                                    <label><strong>{plainTranslate(this.props.settings.locale, "To location")}</strong></label>
                                      <Select style={{ width: '100%' }} className="listFilters-select" value={this.state.toLocation} onChange={this.onChooseLocationTo}>
                                          <Option value={null} >...</Option>
                                          {locations.map(loc =>
                                              <Option value={loc.value} key={loc.value}>{plainTranslate(this.props.settings.locale, loc.label)}</Option>
                                          )}
                                      </Select>
                                  </div>
                              }
                              </div>
                              <div className="col-md-6" style={divSpace}>
                                <label><strong>{plainTranslate(this.props.settings.locale, "Process")}</strong></label>
                                <Select style={{ width: "100%"}} className="listFilters-select" onChange={this.onChooseProcessFilter} value={this.state.processFilter}>
                                      <Option value={null} >...</Option>
                                      <Option value={true} >{plainTranslate(this.props.settings.locale, 'On stock')}</Option>
                                      <Option value={'part'} >{plainTranslate(this.props.settings.locale, 'Part Available')}</Option>
                                      <Option value={'full_part'} >{plainTranslate(this.props.settings.locale, 'Full or Part Available')}</Option>
                                      <Option value={false} >{plainTranslate(this.props.settings.locale, "Not Available")}</Option>
                                </Select>
                              </div>
                              <div className="col-md-6" style={divSpace}>
                                <label><strong>{plainTranslate(this.props.settings.locale, "Sale")}</strong></label>
                                  <Select style={{ width: "100%"}} className="listFilters-select" onChange={this.onChooseSaleFilter} value={this.state.saleFilter}>
                                    <Option value={null} >...</Option>
                                    <Option value={true} >{plainTranslate(this.props.settings.locale, 'With sale')}</Option>
                                    <Option value={false} >{plainTranslate(this.props.settings.locale, "Without sale")}</Option>
                                  </Select>
                              </div>
                              <div className="col-md-6" style={divSpace}>
                                <label><strong>{plainTranslate(this.props.settings.locale, "Deal")}</strong></label>
                                  <Select style={{ width: "100%"}} className="listFilters-select" onChange={this.onChooseDealFilter} value={this.state.dealFilter}>
                                    <Option value={null} >...</Option>
                                    <Option value={true} >{plainTranslate(this.props.settings.locale, 'With deal')}</Option>
                                    <Option value={false} >{plainTranslate(this.props.settings.locale, "Without deal")}</Option>
                                  </Select>
                              </div>
                              {this.state.vendorOptions &&
                                <div className="col-md-6" style={divSpace}>
                                    <label><strong>{plainTranslate(this.props.settings.locale, "Vendor")}</strong></label>

                                      <AsyncSelect
                                        isMulti={true}
                                        value={this.state.vendor}
                                        onChange={this.onChooseVendor}
                                        options={this.state.vendorOptions}
                                        loadOptions={this.searchVendor}
                                        defaultOptions={this.state.vendorOptions}
                                        isClearable={true}
                                    />
                                  </div>
                              }
                        </div>
                    </SlideModal>
                }
            </div >
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    itemRequests: state.itemRequests,
});
const mapDispatchToProps = dispatch => ({
    onItemRequestsFetchData: (db, url, start, end, stage, page, fromLocation, toLocation, isProcessable, isSale, vendor, isDeal) => dispatch(onItemRequestsFetchData(db, url, start, end, stage, page, fromLocation, toLocation, isProcessable, isSale, vendor, isDeal)),
    onItemRequestView: (db, id) => dispatch(onItemRequestView(db, id)),
    onStagesFetchData: (db, id) => dispatch(onStagesFetchData(db, id)),
    onChangeStage: (stage, request, name) => dispatch(onChangeStage(stage, request, name)),
    onDatesChange: (startDate, endDate, route) => dispatch(onDatesChange(startDate, endDate, route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventoryRequestsDetailsPage));
