import React from 'react';
import {connect} from 'react-redux';
import { Radio } from 'antd';
import 'antd/lib/radio/style/index.css';

class RadioButtons extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.input.value,
        }
        this.onChange = this.onChange.bind(this);
    }
  
    onChange(e) {
       
        this.setState({value:e.target.value}, function(){
            this.props.change(this.props.field.id, this.state.value);
        });
    };

    render() {
        return <Radio.Group onChange={this.onChange} value={this.state.value}>
            {this.props.field.options.map((option) => 
                <Radio value={option.value}>{option.label}</Radio>
               )}
              </Radio.Group>
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RadioButtons);
