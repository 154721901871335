import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import Popup from '../../common/popup';
import showAToast from '../../common/showAToast'

class Switch extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            popup: false,
            value: 0
        };

        this.onChange = this.onChange.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
     }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.state.value && prevProps.data !== 'undefined' && this.state.value === '') {
            this.setState({
                value: prevProps.data
            });
        }

        if(prevProps.data === 'undefined' && this.state.value === '') {
           this.setState({
                value: 0
            });
        }
    }
    
    componentDidMount() {       
        if (this.props.data) {
            if (this.props.data == '1') {
                this.setState({
                    value: 1
                });
               // this.props.change(this.props.field.id, true)
            } else if (this.props.data == '0') {
                this.setState({
                    value: 0
                });
               // this.props.change(this.props.field.id, false)
            } else {
                this.setState({
                    value: 0
                });
               // this.props.change(this.props.field.id, false)
            }
            
        }

    }

    onChange(e) {       
        if (this.props.field.hasOwnProperty('check')) {
            if (this.props.formData[this.props.field.check]) {
                this.setState({
                    value: this.state.value === 0 || typeof this.state.value === 'undefined' ? 1 : 0
                }, function(){

                    this.props.change(this.props.field.id, this.state.value)
                });
            } else {
                this.setState({
                    value: this.state.value
                }, function () {
                    showAToast('Please, select payment method', 'errPayMeth', 'error')                   
                });
            }
        } else {
            this.setState({
                value: this.state.value === 0 || typeof this.state.value === 'undefined' ? 1 : 0
            }, function(){
                this.props.change(this.props.field.id, this.state.value)
            });
        }
        if (this.props.field.hasOwnProperty('connectedFields')) {
            for (let i = 0; i < this.props.fields.length; i++) {
                for (let n = 0; n < this.props.field['connectedFields'].length; n++) {
                if (this.props.fields[i]['id'] == this.props.field['connectedFields'][n]) {
                    if (this.props.field.hasOwnProperty('connectedFieldAction')) {
                        if (this.props.field.connectedFieldAction == 'show') {
                            if (this.state.value == true) {
                                if (this.props.fields[i].hasOwnProperty('hide')) {
                                    this.props.fields[i]['hide'] = true;
                                }
                            } else {
                                if (this.props.fields[i].hasOwnProperty('hide')) {
                                    this.props.fields[i]['hide'] = false;
                                }
                            }
                        }
                    } else {
                        if (this.state.value == true) {
                            if (this.props.fields[i].hasOwnProperty('isDisabled')) {
                                this.props.fields[i]['isDisabled'] = false;
                            }
                            if (this.props.fields[i].hasOwnProperty('disabled')) {
                                this.props.fields[i]['disabled'] = false;
                            }
                        } else {
                            if (this.props.fields[i].hasOwnProperty('isDisabled')) {
                                this.props.fields[i]['isDisabled'] = true;
                                
                            }
                            if (this.props.fields[i].hasOwnProperty('disabled')) {
                                this.props.fields[i]['disabled'] = true;
                            }
                        }
                    }
                }
            }
        }
        }

        if (this.props.field.hasOwnProperty('confirm') && this.state.value == false) {
            this.setState({popup: true}, function () {
                this.setState({
                    value: 0
                });
            });
        }
    }
    
    onConfirm() {
        this.setState({
            popup: false,
            value: 1
        })
    }

    render() {
        let background = '#fff';
        if(this.props.field.disabled || (this.props.formData && this.props.formData.hasOwnProperty('disableSave') && this.props.formData.disableSave === true)){
            background = '#B8B8B8';
        }
        return <React.Fragment>
                <div className="switchComponent text-left" style={{background: background}}>    
                    <input
                        checked={this.state.value}
                        onChange={this.onChange}
                        type="checkbox"
                        disabled={this.props.field.disabled || (this.props.formData && this.props.formData.hasOwnProperty('disableSave') && this.props.formData.disableSave === true)}
                        id={'check-status' + this.props.field.id}
                        label='status'
                        value={this.state.value}
                    />
                    <label htmlFor={'check-status' + this.props.field.id}></label>
                </div>
                {
                    this.state.popup &&
                    <Popup locale={this.props.settings.locale}
                           title={plainTranslate(this.props.settings.locale, 'Confirm')}
                           onClose={() => this.setState({popup: false})}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <div className="text-center">

                                        <h3>
                                            <Translate locale={this.props.settings.locale} value={this.props.field.confirm}/>
                                        </h3>
                                        <button className="button-outlines green text-center"
                                                onClick={() => this.setState({popup: false})}>
                                            <Translate locale={this.props.settings.locale} value="No"/>
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                                onClick={this.onConfirm}>
                                            <Translate locale={this.props.settings.locale} value="Yes"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Popup>
                }
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Switch);
