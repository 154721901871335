import React from 'react';
import { connect } from 'react-redux';
import Translate, { plainTranslate } from '../common/translate';
import SingleCheckbox from './singleCheckbox.jsx';
import { Form, Input, Button } from 'antd';
import 'antd/lib/form/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/button/style/index.css';
import { withRouter } from 'react-router-dom'

class MyPosSettings extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            value: null
        };
    }

    componentDidMount() {
        this.setState({
            value: JSON.parse(this.props.value)
        }, function () {
            this.formRef.current.setFieldsValue(this.state.value)
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.state.value == null) {
            this.setState({
                value: JSON.parse(this.props.value)
            }, function () {
                this.formRef.current.setFieldsValue(this.state.value)
            });
        }
    }

    handleSubmit = (values) => {
        this.props.onSave(values);
    }

    submitForm = e => {
        e.preventDefault()
        this.formRef.current.submit()
    }

    render() {

        return (
            <React.Fragment>
                <br />
                <br />
                <SingleCheckbox
                    value={this.props.settings.ecommerceSettings}
                    label={plainTranslate(this.props.settings.locale, 'Use real MyPos settings')}
                    description={plainTranslate(this.props.settings.locale, 'Switch between real and test MyPos settings')}
                    settings={this.props.settings}
                    type={'ecommerceSettings'}
                    saveRoute={'/' + this.props.match.params.db + '/api/ecommerce/save/settings/individual'}
                    setting={'useRealMyPos'}
                />
                <br />
                <br />
                <div className="row m-bot15">
                    <div className="col-lg-12">
                        <Form onFinish={this.handleSubmit} className="my-pos-form" ref={this.formRef} >
                            <div className="col-lg-6">
                                <h4><strong>{plainTranslate(this.props.settings.locale, 'MyPos Real Settings')}</strong></h4>
                                <br />
                                <Form.Item label="Real Ipc Url" name="ipcUrl"
                                    rules={[
                                        {
                                            required: true,
                                            message: plainTranslate(this.props.settings.locale, 'Please input real ipc url!')

                                        }
                                    ]}>
                                    <Input
                                        placeholder="Real Ipc URL"
                                    />
                                </Form.Item>
                                <Form.Item label="Real Configuration Package" name="configurationPackage"
                                    rules={[{
                                        required: true,
                                        message: plainTranslate(this.props.settings.locale, 'Please input real configuration package!')

                                    }
                                    ]}
                                >
                                    <Input
                                        placeholder="Real Configuration Package"
                                    />
                                </Form.Item>
                                <Form.Item label="Real cancel url" name="urlCancel"
                                    rules={[{
                                        required: true,
                                        message: plainTranslate(this.props.settings.locale, 'Please input real url cancel!')

                                    }
                                    ]}>
                                    <Input
                                        placeholder="Real cancel url"
                                    />
                                </Form.Item>
                                <Form.Item label="Real OK url" name="urlOk"
                                    rules={[{
                                        required: true,
                                        message: plainTranslate(this.props.settings.locale, 'Please input real url ok!')

                                    }
                                    ]}>
                                    <Input
                                        placeholder="Real OK url"
                                    />
                                </Form.Item>
                                <Form.Item label="Real notify url" name="urlNotify"
                                    rules={[{
                                        required: true,
                                        message: plainTranslate(this.props.settings.locale, 'Please input real url notify!')

                                    }
                                    ]}>
                                    <Input
                                        placeholder="Real notify url"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-lg-6">
                                <h4><strong>{plainTranslate(this.props.settings.locale, 'MyPos Test Settings')}</strong></h4>
                                <br />
                                <Form.Item label="Test Ipc URL" name="test_ipcUrl"
                                    rules={[{
                                        required: true,
                                        message: plainTranslate(this.props.settings.locale, 'Please input test ipc url!')

                                    }
                                    ]}>
                                    <Input
                                        placeholder="Test Ipc URL"
                                    />
                                </Form.Item>
                                <Form.Item label="Test Configuration Package" name="test_configurationPackage"
                                    rules={[{
                                        required: true,
                                        message: plainTranslate(this.props.settings.locale, 'Please input test configuration package!')

                                    }
                                    ]}>
                                    <Input
                                        placeholder="Test Configuration Package"
                                    />
                                </Form.Item>
                                <Form.Item label="Test cancel url" name="test_urlCancel"
                                    rules={[{
                                        required: true,
                                        message: plainTranslate(this.props.settings.locale, 'Please input test url cancel!')

                                    }
                                    ]}>
                                    <Input
                                        placeholder="Test cancel url"
                                    />
                                </Form.Item>
                                <Form.Item label="Test OK url" name="test_urlOk"
                                    rules={[{
                                        required: true,
                                        message: plainTranslate(this.props.settings.locale, 'Please input real url ok!')

                                    }
                                    ]}>
                                    <Input
                                        placeholder="Test OK url"
                                    />
                                </Form.Item>
                                <Form.Item label="Test notify url" name="test_urlNotify"
                                    rules={[{
                                        required: true,
                                        message: plainTranslate(this.props.settings.locale, 'Please input test url notify!')

                                    }]}
                                >
                                    <Input
                                        placeholder="Test notify url"
                                    />
                                </Form.Item>
                            </div>
                            <Button type="primary" onClick={this.submitForm} htmlType="submit" className="login-form-button" style={{ backgroundColor: "#44c699", borderColor: "#44c699", float: "right" }}>
                                {plainTranslate(this.props.settings.locale, 'Save')}
                            </Button>
                        </Form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

//const MyPosForm = Form.create({ name: 'MyPosForm' })(MyPosSettings);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyPosSettings));

