import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import {connect} from 'react-redux';
import { Table, Button } from 'antd';
import 'antd/lib/table/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/icon/style/index.css';
import 'antd/lib/dropdown/style/index.css';
import 'antd/lib/tabs/style/index.css';
import 'antd/lib/tooltip/style/index.css';
import 'antd/lib/form/style/index.css';
import 'antd/lib/switch/style/index.css';
import TableCollection from './tableCollection';
import { Select } from 'antd';
import SlideModal from '../../common/SlideModal';
import SimpleForm from "../../redux-forms/form";
import SaveCancel from '../../common/saveCancelButtons';
import moment from 'moment';

const { Option } = Select

class TabTable extends Component {


    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
            batchForm: false,
            expandedRows: []
        };

        this.changeTab = this.changeTab.bind(this);
        this.getContent = this.getContent.bind(this);
        this.renderSelect = this.renderSelect.bind(this);
        this.expandedRowRender = this.expandedRowRender.bind(this);
        this.addItem = this.addItem.bind(this);
        this.handleRowExpand = this.handleRowExpand.bind(this);
        this.addNewMaterial = this.addNewMaterial.bind(this);
    }

    changeTab(tab) {
        this.setState({currentTab: tab,expandedRows: []});
    }

    addNewMaterial(response) {
        let name = this.props.field.tabs[this.state.currentTab].id +'[0]' + 'lines';
        this.props.change('materialConsumption['+this.state.lineNo+']',response.materials);
        this.props.change('lines['+this.state.lineNo+']',response.line);

        this.setState({showMaterialForm: false, lineNo: null, lineId: null});
    }


    handleChange(val, field,index) {        
        if(val){
            let name = this.props.field.tabs[this.state.currentTab].id +'['+index+']' + 'lines[' + (Number(field.no)-1)+'].batch';
            this.props.change(name,val);
        }
        
    }

    componentDidUpdate(prevProps){
        if(this.props.isSubmitting !== prevProps.isSubmitting){
            this.setState({loading: this.props.isSubmitting});

            if(!this.props.isSubmitting){
                this.props.updateForm();
            }
        }
    }

    renderSelect(text, line, index) {
        let tabId = this.props.field.tabs[this.state.currentTab].id;

        return <Select style={{ width: 240 }} value={line.batch ? line.batch : ''} onChange={(value) => this.handleChange(value, line, index)}>
            {tabId !== 'inventoryConsumption' &&
                <Option
                    key={Math.random().toString(36).substring(7)}
                    value={null}>
                    <Button
                        style={{ border: 'none' }}
                        onClick={() => this.setState({ batchForm: true, selectedLine: line })}>
                        +{plainTranslate(this.props.settings.locale, 'Add')}
                    </Button>
                </Option>
            }
            <Option
                key={Math.random().toString(36).substring(7)}
                value={null}> </Option>
            {line.hasOwnProperty('batchOptions') && line.batchOptions.map(batch => {
                return <Option value={batch.value}>{batch.label}</Option>
            })}
        </Select>
    }

    addItem(line,index) {
        this.state.selectedLine.batch = line.entity.id;
        this.state.selectedLine.batchOptions.push({value: line.entity.id,label: line.entity.name});
        this.setState({batchForm: false});
    }

    expandedRowRender(line,index,cols){
    	let columns = [];
    	cols.map((field, key) => {
    		let arr = {};
        	arr.key = field.id;
        	arr.dataIndex = field.id;
        	arr.title = plainTranslate(this.props.settings.locale, field.label);

            if(field.type === "number"){
                arr.align = "right";
            }

            if(field.id === 'batch'){
                arr.render= (text,row) => this.renderSelect(text,row,index);
            }
        	columns.push(arr);
    	});

	    return <Table columns={columns} dataSource={line.lines} pagination={false} />;
	  };

    getContent(value,lineNo,showSave) {
        const currentTab = this.state.currentTab;

        let content = 'no content';

        let data = [];
        let nestedData = [];

        this.props.field.tabs[currentTab].fields.map((field, key) => {
        	if(field.id === 'lines'){
        		nestedData = field.fields;
        	}else{
	        	let arr = {};
	        	arr.key = field.id;
	        	arr.dataIndex = field.id;
	        	arr.title = plainTranslate(this.props.settings.locale, field.label);
                arr.width = field.width ? field.width : 50;
                if (field.id == "date") {
                    arr.render = (text,row) => (<div>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</div>);
                }
                if(field.type === "number"){
                    arr.align = "right"; 
                }

                if(field.id === 'documentNo'){
                    arr.render = (text,row) => (<a href={'/'+this.props.settings.db + '/receipt/'+row.id+'/profile'} target="_blank">{text}</a>)
                }
                if(field.id === 'description'){
                    arr.render = (text,row) => (<a href={'/'+this.props.settings.db + '/item/'+row.itId+'/profile'} target="_blank">{text}</a>)
                }
                if(field.id === 'lineStatus'){
                    arr.render = (text,row) => (
                        <span className="form-input-field">
                            {row['lineStatus'] == '1' && 
                                <i className="fa fa-check" style={{color:'green', marginLeft: 15}}></i> 
                            }
                            {row['lineStatus'] == '0' && 
                                <i className="fa fa-times" style={{color:'red', marginLeft: 15}}></i> 
                            } 
                        </span>)
                }
	        	data.push(arr);
	        }
        });	

        let tableData = [];
        let nestedTable = null;
        let showNestedTable = false;

        let tabId = this.props.field.tabs[currentTab].id;

        let totalAmount = 0;

        let title = null;

        let showMaterialButton = false;

        if(lineNo !== null){
            title = "Item: "+this.props.formData.lines[lineNo].itName+" - "+this.props.formData.lines[lineNo].description;

            if(this.props.formData.lines[lineNo].stageStatus < 4){
                showMaterialButton = true;
            }

        }

        if(this.props.formData.hasOwnProperty(tabId)){
            value.map((field,key) => {

                totalAmount = Number(totalAmount) + Number(field.amountCost);
               

                if(field.hasOwnProperty('lines')){
                    field.key = field.no;
                    showNestedTable=true;
                }else{
                    showNestedTable=false;
                }
                tableData.push(field);
            });
        }

        if(showNestedTable){
            nestedTable = (row, index) => this.expandedRowRender(row, index, nestedData);
        }

        return <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
            <Table 
            onExpand={(expanded, record) => this.handleRowExpand(record)} 
            expandedRowKeys={this.state.expandedRows} 
            columns={data} 
            title={() => <span>{title} {showMaterialButton === true && <button type="button" class="button-primary" style={{float: "none"}} 
            onClick={() => this.setState({showMaterialForm: true, lineNo: lineNo, lineId: this.props.formData.lines[lineNo].id})}>{plainTranslate(this.props.settings.locale, 'Add/Edit material')}</button>}</span>} 
            dataSource={tableData} 
            expandedRowRender={nestedTable} 
            pagination={false} 
            footer={() => !showNestedTable ? <span style={{marginLeft: "79%"}}>Total: {totalAmount.toFixed(this.props.field.tabs[this.state.currentTab].decimalPoint)}</span> : null} />
      
            {showSave && 
                <div style={{marginTop: 40}}>
                    <SaveCancel
                        // locale={this.props.locale}
                        enableSave={!this.props.invalid && !this.state.loading}
                        save={(saveNew, issue,print,saveRoute) => this.props.save(saveNew, issue,null, '/' + this.props.settings.db + '/api/production/save/'+tabId)}
                        submitting={this.state.loading}
                    />
                </div>
            }
        </div>

    }

     handleRowExpand(record) {
        this.setState(prevState =>
          prevState.expandedRows.includes(record.no)
            ? {
                expandedRows: prevState.expandedRows.filter(
                  key => key !== record.no
                )
              }
            : { expandedRows: [...prevState.expandedRows, record.no] }
        );
      }
    render() {
        let lines = this.props.formData[this.props.field.tabs[this.state.currentTab].id];

        return <div style={{marginTop: '0px', overflowX: 'auto'}}>
            {this.props.field.tabs &&
            	<header className="panel-heading" style={{display: 'block', marginBottom: '10px'}}>
	                {
	                    this.props.field.tabs && this.props.field.tabs.map((tab, key) => {
                            let showTab = this.props.formData[tab.id];
	                    	if(showTab && showTab.length > 0){
	                        	return <span className={this.state.currentTab != key ? 'text-grey':''} key={key}
	                                     onClick={() => this.changeTab(key)}>{plainTranslate(this.props.settings.locale, tab.label)} {key !== this.props.field.tabs.length - 1 ? <span> | </span> : ''}</span>
	                        }
	                    })
	                }
            	</header>
            }

            {this.props.field.tabs[this.state.currentTab].type === 'antTable' && Array.isArray(lines[0]) &&
                lines.map((line, key) => {
                   return this.getContent(line,key,false)
                })
            }
            {this.props.field.tabs[this.state.currentTab].type === 'antTable' && !Array.isArray(lines[0]) &&
                this.getContent(lines,null,true)
            }

            {this.props.field.tabs[this.state.currentTab].type === 'tableCollection' &&
                <TableCollection
                    {...this.props}
                    mainFormData={this.props.formData}
                    lines={this.props.formData[this.props.field.id]}
                    columns={this.props.field.tabs[this.state.currentTab].fields}
                    field={this.props.field.tabs[this.state.currentTab]}
                    oneLine={this.props.field.tabs[this.state.currentTab].oneLine}
                    change={this.props.change}
                    tableClass={this.props.field.tabs[this.state.currentTab].tableClass}
                />
            }
            {
                this.state.batchForm &&
                <SlideModal onClose={() => this.setState({batchForm: false})} title={plainTranslate(this.props.settings.locale, 'Batch')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <SimpleForm
                                    saveRoute={'/' + this.props.settings.db + '/api/batches'}
                                    formRoute={'/' + this.props.settings.db + '/api/batches/'}
                                    match={{params: {id: null}}}
                                    matchAdditionalId={{params: {id: this.state.selectedLine.itId}}}
                                    onSuccessfulSave={(response) => this.addItem(response)}
                                    isNew={true}
                                    popup={true}
                                    onCancel={() => this.setState({batchForm: false})}
                                    form={'initializeFromState'}
                                />
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }
            {
                this.state.showMaterialForm &&
                <SlideModal onClose={() => this.setState({showMaterialForm: false, lineId: null, lineNo: null})} title={plainTranslate(this.props.settings.locale, 'Bill Of Materials')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <SimpleForm
                                    saveRoute={'/' + this.props.settings.db + '/api/production-line/'+this.state.lineId+'/item/bill-of-materials'}
                                    formRoute={'/' + this.props.settings.db + '/api/production/item/bill-of-materials/'}
                                    match={{params: {id: this.state.lineId}}}
                                    //matchAdditionalId={{params: {id: this.state.selectedLine.itId}}}
                                    onSuccessfulSave={(response) => this.addNewMaterial(response)}
                                    isNew={true}
                                    popup={true}
                                    onCancel={() => this.setState({showMaterialForm: false, lineId: null, lineNo: null})}
                                    form={'initializeFromState'}
                                />
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }
            </div>
            
        
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(TabTable);