import React from 'react';
import showAToast from '../../../common/showAToast'
import field from '../../../forms/field';
import client from '../../../../actions/client'
import {plainTranslate} from "../../../common/translate";

class LineNumber extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            clicked: null
        };

        this.onChange = this.onChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {        
        
        this.setState({
            value: this.props.data !== '' && this.props.data && this.props.data !== undefined ? this.props.data : (this.props.field.setDefault || this.props.field.setDefault === 0 ? this.props.field.setDefault : ''),
            calculateValue: this.props.data !== '' && this.props.data && this.props.data !== undefined ? this.props.data : (this.props.field.setDefault || this.props.field.setDefault === 0 ? this.props.field.setDefault : '')
        });

        var allElements = document.getElementsByTagName("label");
        let price = null;
        let discountPercent= null;
        let minAmount = null;
        let minQty = null;
       
        for(let i = 0; i < allElements.length; i++){
            if(allElements[i].textContent == 'Price'){
                price = allElements[i];
            }
            if(allElements[i].textContent == 'Discount Percent'){
                discountPercent = allElements[i];
            }
            if(allElements[i].textContent == 'Minimum Quantity'){
                minQty = allElements[i];
            }
            if(allElements[i].textContent == 'Minimum Amount'){
                minAmount = allElements[i];
            }
        }
        
        if(price && discountPercent && minQty && minAmount){
            let priceInput = price.nextSibling.getElementsByTagName("input");
            let discountPercentInput = discountPercent.nextSibling.getElementsByTagName("input");
            let minAmountInput = minAmount.nextSibling.getElementsByTagName("input");
            let minQtyInput = minQty.nextSibling.getElementsByTagName("input");

            if(priceInput[0].value.length > 0){
                discountPercentInput[0].disabled=true;
            }else{
                discountPercentInput[0].disabled=false;
            }
            if(discountPercentInput[0].value.length > 0){
                priceInput[0].disabled=true;
            }else{
                 priceInput[0].disabled=false;
            }
            if(minAmountInput[0].value.length > 0){
                minQtyInput[0].disabled=true;
            }else{
                 minQtyInput[0].disabled=false;
            }
            if(minQtyInput[0].value.length > 0){
                minAmountInput[0].disabled=true;
            }else{
                 minAmountInput[0].disabled=false;
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data && nextProps.data !== prevState.value) {      
            return {value: nextProps.data}
          //this.props.handleChangeLine(this.props.field.id, nextProps.data)
        }else{
            return null;
        }
      }    
        
    handleBlur(event){  
        event.preventDefault()
        this.setState({
          clicked: null
       }); 
    }
    
     handleClick(event){ 
        this.setState({
          clicked: this.props.id
       }); 
    }
    
    
    numberScale(number, scale) {
        let float = parseFloat(number);
        float = float.toFixed(scale ? scale : 2);
        return float;
    }

    onChange(e) {
        var allElements = document.getElementsByTagName("label");
        let price = null;
        let discountPercent= null;
        let minAmount = null;
        let minQty = null;
  
        for(let i = 0; i < allElements.length; i++){
            if(allElements[i].textContent == 'Price'){
                price = allElements[i];
            }
            if(allElements[i].textContent == 'Discount Percent'){
                discountPercent = allElements[i];
            }
            if(allElements[i].textContent == 'Minimum Quantity'){
                minQty = allElements[i];
            }
            if(allElements[i].textContent == 'Minimum Amount'){
                minAmount = allElements[i];
            }
        }

        const oldFormData = JSON.parse(JSON.stringify(this.props.formData));

        if(price && discountPercent && minQty && minAmount){
            let priceInput = price.nextSibling.getElementsByTagName("input");
            let discountPercentInput = discountPercent.nextSibling.getElementsByTagName("input");
            let minAmountInput = minAmount.nextSibling.getElementsByTagName("input");
            let minQtyInput = minQty.nextSibling.getElementsByTagName("input");

            if(priceInput[0].value.length > 0){
                discountPercentInput[0].disabled=true;
            }else{
                discountPercentInput[0].disabled=false;
            }
            if(discountPercentInput[0].value.length > 0){
                priceInput[0].disabled=true;
            }else{
                 priceInput[0].disabled=false;
            }
            if(minAmountInput[0].value.length > 0){
                minQtyInput[0].disabled=true;
            }else{
                 minQtyInput[0].disabled=false;
            }
            if(minQtyInput[0].value.length > 0){
                minAmountInput[0].disabled=true;
            }else{
                 minAmountInput[0].disabled=false;
            }
        }       
            if(e.target.value === '') {
              this.props.handleChangeLine(this.props.field.id, null);
            }

        let value = null;

        if(e.target.value === ''){
             value = null;
        }else{
            value = parseFloat(e.target.value);

            if (isNaN(value)) {
                return;
            }
        }

        this.setState({
            value: value,
            calculateValue: value
        });
        this.props.handleChangeLine(this.props.field.id, value)
        const formDataIn = JSON.parse(JSON.stringify(this.props.formData))
        
        if (this.props.field.hasOwnProperty('onChangeField')) {
            let ind = (this.props.name).indexOf('.' + this.props.field.id);
            let line = (this.props.name).substring(0, ind);

            if (formDataIn.lines[this.props.index][this.props.field.onChangeField] != ''
                    && formDataIn.lines[this.props.index][this.props.field.onChangeField] != 0
                    && formDataIn.lines[this.props.index][this.props.field.onChangeField] != null) {
                this.props.handleChangeLine(this.props.field.onChangeField, value);
            }
        }
        if (this.props.field.hasOwnProperty('connectedFields')) {
            
            let addVal = {};
            let jsonData = [];
            if (this.props.field.connectedFields.hasOwnProperty('dependency')) {
                this.props.field.connectedFields.dependency.map(field => {
                    if (field == 'itId') {
                        addVal['itId'] = formDataIn.lines[this.props.index]['itId'];
                    } else {
                        addVal[field] = formDataIn[field];
                    }
                })
            }               

            let line='';
            if (this.props.field.connectedFields.isLine) {
                   let ind = (this.props.name).indexOf('.'+this.props.field.id);
                   line = (this.props.name).substring(0, ind);
                   addVal['pos'] = line;
                   if (this.props.field.connectedFields.hasOwnProperty('lineDependency')) {
                        this.props.field.connectedFields.lineDependency.map(field => {
                            addVal[field] = formDataIn.lines[this.props.index][field];
                     })
                   }
                }

            addVal['body'] = value;
            jsonData.push(JSON.stringify(addVal));

            let fields = this.props.field.connectedFields.fields;
      
            client.post(this.props.field.connectedFields.optionsUrl, addVal)
                    .then(response => response.data)
                    .then(response => {
                        fields.map(field => {
                            if (typeof(response[field])=== 'string') {                         
                                if(field === 'totalDiscount'){
                                    if(response[field] !== this.props.formData.totalDiscountRate){
                                        showAToast('Total discount changed to '+response[field]+'%', 'success2', 'success')                                        
                                    }
                                    this.props.handleChange('totalDiscountRate', response[field] ? response[field] : null)
                                }else{
                                    this.props.handleChange(line+field, response[field] ? response[field] : null)
                                    this.props.handleChangeLine(field, response[field] ? response[field] : null);
                                }
                            } 
                        })
                    })
        }

        if (this.props.field.hasOwnProperty('checkStockQuantity') && this.props.field.hasOwnProperty('disabledAvailabilityStage') && 
                ((Array.isArray(this.props.field.disabledAvailabilityStage)
                && !this.props.field.disabledAvailabilityStage.includes(formDataIn['stageId'])))) {
            
        
            if (this.props.field.checkStockQuantity !== false) {
                let addVal = {};
                let jsonData = [];
                if (this.props.field.checkStockQuantity.hasOwnProperty('dependency')) {//console.log('checkStockQuantit dependency')
                    this.props.field.checkStockQuantity.dependency.map(field => {
                        if (field == 'itId') {
                            addVal['itId'] = formDataIn.lines[this.props.index]['itId'];
                        } else {
                            addVal[field] = formDataIn[field];
                        }
                    })
                }
                addVal['body'] = value;
                jsonData.push(JSON.stringify(addVal));

                let line = '';
                if (this.props.field.checkStockQuantity.isLine) {
                    let ind = (this.props.name).indexOf('.' + this.props.field.id);
                    line = (this.props.name).substring(0, ind);

                }
                let field = this.props.field.checkStockQuantity.field;
               
                let maxQuantity = (formDataIn.lines[this.props.index][this.props.field.checkStockQuantity.field]);

                if(formDataIn.lines[this.props.index]['batch'] !== null && formDataIn.lines[this.props.index]['batch'] !== ""){
                    maxQuantity = formDataIn.lines[this.props.index]['availableQuantity'];
                }
                if(formDataIn.isPos === true && formDataIn.lines[this.props.index]['batch'] !== null){
                    maxQuantity = formDataIn.lines[this.props.index]['availableQuantity'];
                }

                let negativeQty = (formDataIn.lines[this.props.index]['negativeQty']);
                let orderedQty = (formDataIn.lines[this.props.index]['quantityOrdered']) ? true : false;

                if (typeof maxQuantity == 'undefined' ||  maxQuantity == null) {                   
                    client.post(this.props.field.checkStockQuantity.optionsUrl, addVal)
                            .then(response => response.data)
                            .then(response => {
                                if (response) {                               
                                    this.props.handleChange(line + field, response['response'] ? response['response'] : null);
                                    this.props.handleChange(line + 'negativeQty',  response['negative'] ? response['negative'] : false);
                                    this.checkQuantity(value, response['availableQuantity'] ? parseFloat(response['availableQuantity']) : parseFloat(response['response']), response['negative'], orderedQty);
                                }
                                })
                } else {
                    this.checkQuantity(value, maxQuantity, negativeQty, orderedQty);
                }
            }
        }
        
        if (this.props.field.hasOwnProperty('checkStockMovement') && this.props.field.checkStockMovement){
            let addVal = {};
            let jsonData = [];
            let numb = (this.props.name).match(/^(\D*)(\d+)/)[2];

            if (this.props.field.checkStockMovement.hasOwnProperty('dependency')) {
                this.props.field.checkStockMovement.dependency.map(field => {
                    if (field == 'lineId') {
                       
                        addVal['lineId'] = formDataIn.lines[numb]['id'];
                    }
                     else {
                        addVal[field] = formDataIn[field];
                    }
                })
            }
            addVal['body'] = value;
            jsonData.push(JSON.stringify(addVal));   

            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(() => {
                client.post(this.props.field.checkStockMovement.optionsUrl, addVal)
                .then(response => response.data)
                .then(response => {
                    if (response && (value < response['quantity'])) {console.log(formDataIn.lines[numb]['quantity']);
                        this.props.handleChangeLine(this.props.field.id, oldFormData.lines[numb]['quantity']);
                        showAToast(plainTranslate(this.props.settings.locale,'The quantity of this line cannot be changed because it has been used in a release receipt'), 'errSettings', 'error');
                    }
                })   
            }, 1000);
        }

        if (this.props.field.hasOwnProperty('calculateQty')) {            
            let addVal = {};
            let jsonData = [];
            let line='';
            
            if (this.props.field.calculateQty.isLine) {
                   let ind = (this.props.name).indexOf('.'+this.props.field.id);                   
                   line = (this.props.name).substring(0, ind);
                   addVal['pos'] = line;
                }
                
            if (this.props.field.calculateQty.hasOwnProperty('dependency')) {
                this.props.field.calculateQty.dependency.map(field => {
               
                    
                    if (this.props.field.calculateQty.isLine){
                     //   addVal[field] = formDataIn.lines[this.props.index][field];
                    } else {
                        addVal[field] = formDataIn[field];
                    }
                })
            }

            addVal['body'] = value;
            jsonData.push(JSON.stringify(addVal));

            let fields = this.props.field.calculateQty.fields;
            client.post(this.props.field.calculateQty.optionsUrl, addVal)
                    .then(response => response.data)
                    .then(response => {
                        fields.map(field => {
                                    this.props.handleChange(line+field, response ? response : null)
                        })
                    })
        }
        
    }
    
    checkQuantity(value, maxQuantity, negativeQty, orderedQty = false) { 
        if (!orderedQty && value > maxQuantity && negativeQty == 0) {

                if (isNaN(value)) {
                    return;
                }
                if (maxQuantity < 0) {
                    maxQuantity = 0;
                }
               
                this.props.handleChangeLine(this.props.field.id, maxQuantity)
                
                this.setState({
                    value: maxQuantity
                });              
                  showAToast('Insufficient quantity', 'errCreateFolder', 'error')
            }
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
        document.addEventListener('keydown', this.handleRecalculatePrice, false);
        
    }

    handleRecalculatePrice = (e) => {
        let name = document.activeElement ? document.activeElement.name : 'none'//;
       
        let priceWithVat = this.props.fields.find(o => o.id === 'unitPriceWithVat');
        
        if ((this.props.field.id === "unitPriceOriginal" || this.props.field.id === "price") && ((e.keyCode === 191 && e.ctrlKey) || (e.keyCode === 111 && e.ctrlKey)) && this.props.name === name && (priceWithVat && priceWithVat.hide === false)) {
            let taxRate = this.props.formData.taxRate;
            if(!taxRate){
                taxRate = this.props.formData.taxPercent;
            }
            let price = this.state.value;

            if(this.state.calculateValue){
                price = this.state.calculateValue;
            }
            
            let newPrice = price / (1+taxRate/100);
            this.props.handleChangeLine(this.props.field.id,newPrice.toFixed(this.props.field.scale));
        }
    }

    render() {
        let min = '';
        let max = '';
        const rules = this.props.field.rules;
        if (rules) {
            min = 'min' in rules ? rules.min : '';
            max = 'max' in rules ? rules.max : '';
        }
        
        let highlightField = null;
        if(this.props.field.id == "quantity"){
            let qty = this.props.line.stockAvailability
            if(this.props.hasBatch && this.props.hasBatch.hide === false && this.props.line.availableQuantity !== null){
                qty = this.props.line.availableQuantity;
            }
            
            if(this.props.highlightAvailability && Number(this.props.line.quantity) > Number(qty)){
                highlightField = 'highlightField';
            }
        }
     
        if(this.state.clicked !== this.props.id){         
           return <div onClick={this.handleClick} 
            id={this.props.id} 
            className={this.props.className + ' div-text-right ' + (highlightField ? highlightField : '')} 
            style={this.props.style} >
        <p style={{marginRight: 10, textAlign: 'right', minWidth: 160, width: 160, padding: 10}} >   {this.state.value}</p></div> 
        }else{            
          return <input type="number"
                        step="any"
                        className={this.props.className + ' div-text-right border-green ' + (highlightField ? highlightField : '')}
                        style={this.props.style} 
                        value={this.state.value}
                        onChange={this.onChange}
                        min={min}
                        max={max}
                        id={this.props.id}
                        disabled={this.props.field.disabled}
                        tabIndex={this.props.field.tabindex ? this.props.field.tabindex : ''}
                        name={this.props.name}
                        onBlur={this.handleBlur}
                        ref={this.setWrapperRef}
                        />   
        }
        
    }
}



export default LineNumber;

