import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import {onUploadImage} from '../../../actions/actions';
import { withRouter } from 'react-router-dom'

import defaultImage from './default-image-holder.png';
import './image.css';

class Image extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: {},
            showUploadModal: false,
            dragging: false,
            loading: false
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleEscClick = this.handleEscClick.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);

        this.showUploadModal = this.showUploadModal.bind(this);
        this.chooseImage = this.chooseImage.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.onImageDrop = this.onImageDrop.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
    }

    componentDidMount() {
        this.setState({
            value:this.props.input.value ? this.props.input.value : []
        });
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleEscClick);
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.showUploadModal();
        }
    }
    handleEscClick(e) {
        if (e.keyCode === 27) {
            this.showUploadModal();
        }
    }

    showUploadModal() {
        this.setState({showUploadModal: !this.state.showUploadModal});
    }

    chooseImage() {
        document.getElementById('imageUpload').click();
    }

    uploadImage(e) {
        const file = e.target.files[0];

        this.setState({loading: true});
        this.props.onUploadImage(this.props.meta.form, this.props.settings.db, this.props.field.id, file).then((response) => {           
            this.setState({loading:false, showUploadModal: false, value:response});
        this.props.formData[this.props.field.id]=response;
        });
    }

    onImageDrop(e) {
        e.preventDefault();
        e.stopPropagation();

        let file = false;
        if (e.dataTransfer.items) { // Use DataTransferItemList interface to access the file(s)
            file = e.dataTransfer.items.length > 0 && e.dataTransfer.items[0].kind === 'file' ? e.dataTransfer.items[0].getAsFile() : false;
        } else { // Use DataTransfer interface to access the file(s)
            file = e.dataTransfer.files.length > 0 ? e.dataTransfer.files[0] : false;
        }

        if(file) {
            this.setState({loading: true});
            this.props.onUploadImage(this.props.meta.form, this.props.settings.db, this.props.field.id, file).then(() => this.setState({loading:false, showUploadModal: false}));
        }
        else {
            // TODO: show error
        }
    }

    onDragOver(e) {
        e.preventDefault();
        this.setState({dragging: !this.state.dragging});
    }

    render() {
        return (
            <div>
                <div>
                    <div className="col-md-4">
                        <img className={this.state.value && this.state.value.url ? "company-logo-image" : "default-image"}
                            src={this.state.value && this.state.value.url ? this.state.value.url : defaultImage}/>
                    </div>
                    <div className="cold-md-8">
                        <ul className="nav nav-pills">
                            <li role="presentation">
                                <button type="button" className="upload-btn btn btn-success"
                                        onClick={() => this.showUploadModal()}>
                                    <i className="fa fa-upload"></i>
                                    Upload
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                {
                    this.state.showUploadModal &&
                    <div className={`backendropss`}>
                        <div className={'modalConfirms scroll input-board ' + (this.state.dragging ? 'beige' : '')}
                             onDrop={this.onImageDrop}
                             onDragOver={(e) => e.preventDefault()}
                             onDragEnter={this.onDragOver}
                             onDragLeave={this.onDragOver}
                             ref={this.setWrapperRef}>
                            <div className="text-right">
                                <div className="" onClick={this.showUploadModal}>
                                    <i className="fa fa-close" aria-hidden="true"></i>
                                </div>
                            </div>
                            <section className="newPanel">
                                <div className="panel-body">
                                    <div className="row m-bot15">
                                        <div className="text-center">
                                            {this.state.loading && 'Loading...'}
                                            {!this.state.loading && 'Drop your image here or'}
                                        </div>
                                        <button type="button" className={'upload-btn btn btn' + (this.state.loading ? ' disabled' : '-success') + ' upload-button'}
                                                onClick={this.chooseImage}>
                                            <i className="fa fa-upload"></i>
                                            Choose
                                        </button>
                                        <input id={'imageUpload'} type="file" style={{visibility: 'hidden'}}
                                               onChange={this.uploadImage}/>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                }

            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onUploadImage: (formId, db, id, file) => dispatch(onUploadImage(formId, db, id, file))
});

export default connect(mapStateToProps, mapDispatchToProps)(Image);
