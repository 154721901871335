import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";
import ReactDOM from "react-dom";
import BarcodeViewer from 'react-barcode';
import { Menu, Dropdown, Button, message } from 'antd';
import { withRouter } from 'react-router-dom'
import client from '../../../actions/client'

import 'brace/mode/java';
import 'brace/theme/github';

class Barcode extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            displayValue: false,
            value: ''
        };

        this.print = this.print.bind(this);
    }

    componentDidMount() {  
     
        if(this.state.value === '' && typeof this.props.data !== 'undefined'){    
            this.setState({
                value: this.props.data ? this.props.data : ''
            });
        }
    }

    print(tempId = null) {
        this.setState({
            displayValue: true
        }, () => {

            let printContents = document.getElementById('barcode' + this.props.field.id).innerHTML;
            

            client.post('/' + this.props.match.params.db + '/api/items/print/barcode', {
                template: tempId,
                barcode: printContents,
                item: this.props.formData.id
            })
            .then(response => response.data)
            .then(response => {

                var printWindow = window.open('', 'PRINT', 'height=800,width=800');
                if(printWindow){
                printWindow.document.write(response);

                printWindow.document.close(); // necessary for IE >= 10
                printWindow.focus(); // necessary for IE >= 10
                setTimeout(
                    function(){
                        printWindow.print();
                            printWindow.close();
                    },(1000)
                );
                }

                this.setState({displayValue: false});
            })
        });

    }

    render() {
      // displayValue={this.state.displayValue}
        return <div>
            <div id={'barcode' + this.props.field.id} style={{float: 'left', margin: 0}}>
                <BarcodeViewer 
                    value={this.props.formData && this.props.formData[this.props.field.id] ? this.props.formData[this.props.field.id] : '0'}
                    width={2}
                    height={50}                   
                    margin={0}
                    marginTop={10}
                    marginBottom={10}
                    displayValue={this.props.formData && this.props.formData[this.props.field.id] ? true : false}
                />
            </div>
            {(!this.props.field.options || this.props.field.options.length === 0) &&
                <button type="button"
                    className="button-primary "
                    onClick={() => this.print()}>
                {plainTranslate(this.props.locale, 'Print')}
                </button>
            }
            {this.props.field.options && this.props.field.options.length > 0 &&
                    <Dropdown overlay={
                        <Menu>
                        {this.props.field.options.map((tab, key) => {
                            return <Menu.Item key={key} onClick={() => this.print(tab.value)}>  
                              {plainTranslate(this.props.locale, tab.label)}
                            </Menu.Item>
                        })}
                        </Menu>
                    }
                    >
                        <button type="button"
                            className={"button-primary "}>
                        {plainTranslate(this.props.locale, 'Print')}
                        <span className="caret"></span>
                        </button>  
                    </Dropdown> 
            }
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Barcode));
