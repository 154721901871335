import React, {Component} from 'react';
import {render} from "react-dom";
import Translate from '../common/translate';
import {plainTranslate} from "../common/translate";
import Message from '../common/message';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { Button, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons'

class SettingsMenu extends Component {

    constructor(props) {
        super(props);  
        this.state = {
            showActionsDropdown: false
        }; 

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount(){
        let tab = this.props.location.substring(this.props.location.lastIndexOf('/') + 1)
        this.setState({title: tab.charAt(0).toUpperCase() + tab.slice(1)});

    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('touchend', this.handleClickOutside);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.target.className !== "menuLink") {
            this.setState({showActionsDropdown:false});
        }
    }
 
    render() {
        const menu =(                     
            <Menu selectedKeys={[this.state.title]}>
                <Menu.Item key="General" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/general'} className="menuLink">
                            <Translate locale={this.props.settings.locale} value="General"/>
                    </Link>
                </Menu.Item>
                <Menu.Item key="Account" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/accounts'} className="menuLink">
                            <Translate locale={this.props.settings.locale} value="Account"/>
                    </Link>
                </Menu.Item>
                <Menu.Item key="Deal" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/deal'} className="menuLink">
                        <Translate locale={this.props.settings.locale} value="Deal"/>
                    </Link>
                </Menu.Item>
                <Menu.Item key="Sale" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/sales'} className="menuLink">
                            <Translate locale={this.props.settings.locale} value="Sale"/>
                    </Link> 
                </Menu.Item>
                <Menu.Item key="Invoice" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/invoices'} className="menuLink">
                            <Translate locale={this.props.settings.locale} value="Invoice"/>
                    </Link>
                </Menu.Item>
                <Menu.Item key="Expense" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={"/" + this.props.db + "/settings/expense"} className="menuLink">
                            <Translate locale={this.props.settings.locale} value="Expense"/>
                    </Link> 
                </Menu.Item>
                <Menu.Item key="Payment" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/payments'} className="menuLink">
                        <Translate locale={this.props.settings.locale} value="Payment"/>
                    </Link>
                </Menu.Item>
                <Menu.Item key="Inventory" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/inventory'} className="menuLink">
                        <Translate locale={this.props.settings.locale} value="Inventory"/>
                    </Link>
                </Menu.Item>
                <Menu.Item key="Project" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/project'} className="menuLink">
                        <Translate locale={this.props.settings.locale} value="Project"/>
                    </Link>
                </Menu.Item>
                <Menu.Item key="Task" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/task'} className="menuLink">
                        <Translate locale={this.props.settings.locale} value="Task"/>
                    </Link>
                </Menu.Item>
                <Menu.Item key="Personnel" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={"/" + this.props.db + "/settings/personnel"} className="menuLink">
                        <Translate locale={this.props.settings.locale} value="Personnel"/>
                    </Link> 
                </Menu.Item>
                <Menu.Item key="Calendar" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={"/" + this.props.db + "/settings/calendars"} className="menuLink">
                        <Translate locale={this.props.settings.locale} value="Calendar"/>
                    </Link> 
                </Menu.Item>
                <Menu.Item key="Ecommerce" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/ecommerce'} className="menuLink">
                            <Translate locale={this.props.settings.locale} value="Ecommerce"/>
                    </Link>
                </Menu.Item>
                <Menu.Item key="Production" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/production'} className="menuLink">
                            <Translate locale={this.props.settings.locale} value="Production"/>
                    </Link>
                </Menu.Item>  
                <Menu.Item key="Contact" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={"/" + this.props.db + "/settings/contract"} className="menuLink">
                            <Translate locale={this.props.settings.locale} value="Contract"/>
                    </Link> 
                </Menu.Item> 
                <Menu.Item key="Item" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/items'} className="menuLink">
                        <Translate locale={this.props.settings.locale} value="Item"/>
                    </Link>
                </Menu.Item>
                <Menu.Item key="Accounting" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/accounting'} className="menuLink">
                            <Translate locale={this.props.settings.locale} value="Accounting"/>
                        </Link>
                </Menu.Item>
                <Menu.Item key="Device" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/devices'} className="menuLink">
                        <Translate locale={this.props.settings.locale} value="Device"/>
                    </Link>
                </Menu.Item>
                <Menu.Item key="Integration" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/integrations'} className="menuLink">
                            <Translate locale={this.props.settings.locale} value="Integration"/>
                    </Link>
                </Menu.Item>
                <Menu.Item key="Storage" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/storage'} className="menuLink">
                            <Translate locale={this.props.settings.locale} value="Storage"/>
                        </Link>
                </Menu.Item>
                <Menu.Item key="Maintenance" onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                    <Link to={'/' + this.props.db + '/settings/maintenance'} className="menuLink">
                            <Translate locale={this.props.settings.locale} value="Maintenance"/>
                        </Link>
                </Menu.Item>
                
              </Menu>
        );

        return (
            <div className="moduleSubMenu">
                <div ref={this.setWrapperRef}>
                    <span style={{fontSize: 24, marginLeft: 20}}>{plainTranslate(this.props.settings.locale, "Settings")}</span>
                    <Dropdown overlay={menu} visible={this.state.showActionsDropdown}>
                      <Button size="large" style={{marginLeft: 10, marginRight: 70, float: "right"}} onClick={() => this.setState({showActionsDropdown:!this.state.showActionsDropdown})}>
                        {plainTranslate(this.props.settings.locale, this.state.title)} <DownOutlined />
                      </Button>
                    </Dropdown>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
        settings: state.settings,
    });

const mapDispatchToProps = dispatch => ({
        
    });

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMenu);



