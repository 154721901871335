import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import { maxLvl } from "../../../actions/actions";
import {Link} from 'react-router-dom';
import SettingsMenu from '../../sidebar/settings-menu';
import HrOpenSection from './hr_openSection';
import { withRouter } from 'react-router-dom'

class Settings extends Component {

    constructor(props) {
        super(props);  
        this.state = {
            showMessage: false,
        }; 
    }

    componentDidMount() {
         if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
        this.props.getMaxLvl(this.props.match.params.db);
    } 
    
    render() {

        return (
                <div>
                    <SettingsMenu db={this.props.match.params.db}
                       location = {this.props.location.pathname}/>
                    <form name="hr_settings" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                   <div className="settings">
                                      <div className="row">
                                         <HrOpenSection 
                                         label={plainTranslate(this.props.locale, 'Organizational Structure Types')}
                                         description={plainTranslate(this.props.locale,'Depending on your company organizational structure, custom name each of its levels. Please note, when a new level of the hierarchy is added in module HR it will appear here. After that, you will be able to name it.')}
                                         maxLvl={this.props.hrSettings.level}
                                         />
                                      </div>
                                       
                                   </div> 
                                </div>
                             </section>
                          </div>
                       </div>    
                    </form>        
                </div>
                );
    }
}

const mapStateToProps = state => ({
        settings: state.settings,
        hrSettings: state.hrSettings
    });

const mapDispatchToProps = dispatch => ({
        getMaxLvl: (db) =>
            dispatch(maxLvl(db)),
    });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Settings));


