import React from 'react';

const Checkmark = props => (
            <span className="form-input-field">
                    {props.input.value == '1' && 
                    <i className="fa fa-check" style={{color:'green', marginLeft: 15}}></i> 
                    }
                    {props.input.value == '0' && 
                    <i className="fa fa-times" style={{color:'red', marginLeft: 15}}></i> 
                    } 
            </span>
            );

export default Checkmark;