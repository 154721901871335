import React from 'react';
import {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';
import SlideModal from '../../../common/SlideModal';
import {ACCOUNT_ADD_NEW_PROJECT} from "../../../../constants/actionTypes";
import { onTabsLoad} from '../../../../actions/actions';
import { Table, Dropdown, Menu, Button, Progress,Select } from 'antd';
import FormLoader from '../../../forms/form-loader';
import {Link} from 'react-router-dom';
import Form from "../../../redux-forms/form";
import Popup from '../../../common/popup';
import { withRouter } from 'react-router-dom';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';
import AsyncSelect from 'react-select/lib/Async';
import { PlusOutlined, FolderOpenOutlined, FileAddOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;

const customStyles = {
    input: styles => ({ ...styles, width: 60 })
  }

class Projects extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenProject: false,
            editProject: false,
            userOptions: [],
            userValues: {},
            userOptionsProject: [],
            userValuesProject: {},
        }    
    }

    componentDidMount(){
        this.setState({
            userOptions: this.props.projectsTable ? this.props.projectsTable.users : [],
            userOptionsProject: this.props.projectsTable ? this.props.projectsTable.users : []
        })
    }
    
    updateProjects() {
        this.props.onTabsLoad('/' + this.props.match.params.db + '/api/accounts/tabs/' + this.props.accountId, 1, false);
    }

    handleChangeField(e, project, value, type) {
        if(e){
            e.preventDefault();
        }     
/*        if(type === 'user'){
            let userValues= this.state.userValues;console.log(value);
            userValues[project.id] = value.id;
             this.setState({
                userValues: userValues
             })
             value = value.id;
        }  */

        if(type === 'userProject'){
            let userValuesProject= this.state.userValuesProject;
            userValuesProject[project.id] = value.id;
             this.setState({
                userValuesProject: userValuesProject
             })
             value = value.id;
             type = 'user'
            }  

        client
        .post('/' + this.props.match.params.db + '/api/project/' + project.id + '/update-field', {
            id: type,
            value: value
        })
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
            }
            else {
                this.updateProjects();
                showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
            }
        });
    }

    onSaveProject(response){
        this.updateProjects();
        this.setState({addNewProject: false, editProject: false});
    }

    render() {
        const style = {
            margin: '0 5px',
        };

        let projectForms = this.props.projectsTable ? this.props.projectsTable.projectForms : [];
        let projects = this.props.projectsTable ? this.props.projectsTable.projects : [];
        let projectStages = this.props.projectsTable ? this.props.projectsTable.projectStages : [];
        let users = this.props.projectsTable ? this.props.projectsTable.users : [];
        let activityTypes = this.props.activityTypes ? this.props.activityTypes : [];

        const projectsCols = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {

                    let activityOptions = (
                        <Menu>
                            {activityTypes && activityTypes.map(type => {
                                return <Menu.Item key={type.value}>
                                    <a onClick={() => this.setState({ activityForm: true, activityType: type.value, projectId: record.id })}>{plainTranslate(this.props.settings.locale, type.label)}</a>
                                </Menu.Item>
                            })}
                        </Menu>
                    ); 

                    return (
                        !record.type ? <span className="table-actions">
                            <Link to={'/' + this.props.match.params.db + '/project/' + record.id + '/profile'} title={plainTranslate(this.props.settings.locale, 'Profile')}><i style={style} className="fa fa-eye"></i></Link>
                            <i title={plainTranslate(this.props.settings.locale, 'Edit')} style={{margin: '0 5px', cursor: "pointer"}} onClick={() => this.setState({editProject: true, projectId: record.id})} className="fa fa-pencil-square-o"></i>
                            <Dropdown overlay={activityOptions}>
                                <Link to="#" data-toggle="dropdown" title="New Activity" style={{top: 10}}>
                                    <i title={plainTranslate(this.props.settings.locale, 'New Activity')} style={{margin: '0 5px', cursor: "pointer"}} className="ion-android-time"></i>
                                </Link>
                            </Dropdown>                  
                        </span> : <span></span>
                    )
                },
                width: 170,
            },
            {
                'dataIndex' : 'code',
                'title' : plainTranslate(this.props.settings.locale, 'Code'),
                'width': 100,
                sorter: (a, b) => a.code.localeCompare(b.code),
                render: (text, record) => {
                    return(<span className={record.stageStatus === 'Closed' ? 'text-line-through' : ''}  onClick={() => this.setState({showProjectHint: true, projectHint: record})}>{record.code}</span>)
                }
            },
            {
                'dataIndex' : 'name',
                'title' : plainTranslate(this.props.settings.locale, 'Name'),
                'width': 100,
                sorter: (a, b) => a.name.localeCompare(b.name),
                render: (text, record) => {
                    return(<span className={record.stageStatus === 'Closed' ? 'text-line-through' : ''}  onClick={() => this.setState({showProjectHint: true, projectHint: record})}>{record.name}</span>)
                }
            },
            {
                'dataIndex' : 'status',
                'title' : plainTranslate(this.props.settings.locale, 'Status'),
                'width': 250,
                'filters':  projectStages && projectStages.map(val => {
                    return {text: plainTranslate(this.props.settings.locale, val.label),value: val.label}
                }),
                onFilter: (value, record) => record.status.indexOf(value) === 0,
                render: (text, record) => {
                    const StagesOptions = (
                        <Menu>
                            {projectStages.map((option, i) => (
                                    <Menu.Item key={i}>
                                        <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e,record,option.id,"stage")}>
                                            {plainTranslate(this.props.settings.locale, option.label)}
                                        </a>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );
                    return(!record.type ? <span>
                        <Dropdown overlay={StagesOptions}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <i style={style} className="fa fa-flag-o"></i>
                            </a>
                        </Dropdown>
                        <span style={{whiteSpace: "nowrap"}} className={record.stageStatus} style={record.color ? {background: record.color, border: '1px solid #364a5a', color: "#000"} : {}}>{plainTranslate(this.props.settings.locale, text)}</span>
                    </span> : <span></span>)
                }
            },
            {
                'dataIndex': 'startDate',
                'title': plainTranslate(this.props.settings.locale, 'Start date'),
                'width': 130,
                sorter: (a, b) => moment(a.startDate, "DD/MM/YYYY").toDate()-moment(b.startDate, "DD/MM/YYYY").toDate(),
                render: (text, record) => (
                    <div>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}
                    </div>
                ),
            },
            {
                'dataIndex': 'endDate',
                'title': plainTranslate(this.props.settings.locale, 'End date'),
                'width': 130,
                sorter: (a, b) => moment(a.endDate, "DD/MM/YYYY").toDate()-moment(b.endDate, "DD/MM/YYYY").toDate(),
                render: (text, record) => (
                    <div>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}
                    </div>
                ),
            },
            {
                'dataIndex' : 'dueDate',
                'title' : plainTranslate(this.props.settings.locale, 'Due Date'),
                'width': 150, 
                render: (text, record) => (
                    <div>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}
                    </div>
                ),
            },
            {
                'dataIndex' : 'user',
                'title' : plainTranslate(this.props.settings.locale, 'Owner'),
                'width': 50,
                sorter: (a, b) => a.user-b.user,
                render: (text, record) => (
                    !record.type ? <Select 
                    onChange={(val) => this.handleChangeField(null, record, val, 'user')}
                    value={record.user} style={{ width: 120 }}>
                    <Option value={null} style={{height: 25}}></Option>
                    {
                        users.map(option =>(
                            <Option  value={option.id}>{option.label}</Option>
                        ))
                    }
                    </Select> : <span></span>
                ),
            },
        ];

        let menuOptions = null;
        if ((projectForms && projectForms.length > 0) || (projectForms && projectForms.length > 0)) {
            menuOptions = (
                <Menu>
                    {projectForms.map((item, i) => {
                        return <Menu.Item key={i}>
                            <a onClick={() => this.setState({ addNewProject: true, projectForm: item.form })}>
                                <FolderOpenOutlined /> {plainTranslate(this.props.settings.locale, item.label)}
                            </a>
                        </Menu.Item>
                    })}
                </Menu>
            );
        }

        return(
            <div >
                <div className="text-center">
                    {menuOptions &&
                       <Dropdown overlay={menuOptions} className="btn-round btn-blue">
                            <Link to="#" data-toggle="dropdown" title="New Project">
                                <i className="ion-android-add"></i>
                            </Link>
                        </Dropdown>
                    }
                    <Table className="projectsAndTasksTable" rowKey={record => record.id} columns={projectsCols} dataSource={projects} scroll={{ x: 'max-content' }}
                    /> 
                </div>
                {
                    this.state.editProject &&
                    <SlideModal onClose={() => this.setState({editProject: false, projectId: false})} title={plainTranslate(this.props.settings.locale, 'Project')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/projects/'}
                                        saveRoute={this.props.match.params.db + '/api/projects/'}
                                        match={{params: {id: this.state.projectId}}}
                                        onSuccessfulSave={(response) => this.onSaveProject(response)}
                                        onCancel={() => this.setState({editProject: false})}
                                        isNew={true}
                                        popup={true}
                                        //account={this.props.accountId}
                                        //type={this.state.projectForm}
                                        //refs={this.state.taskProject ? this.state.taskProject : ' '}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }

                {
                    this.state.addNewProject &&
                    <SlideModal onClose={() => this.setState({addNewProject: false})} title={plainTranslate(this.props.settings.locale, 'Project')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/projects/'}
                                        saveRoute={this.props.match.params.db + '/api/projects'}
                                        match={{params: {id: 0}}}
                                        onSuccessfulSave={(response) => this.onSaveProject(response)}
                                        onCancel={() => this.setState({addNewProject: false})}
                                        isNew={true}
                                        popup={true}
                                        account={this.props.accountId}
                                        type={this.state.projectForm}
                                        //refs={this.state.taskProject ? this.state.taskProject : ' '}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.activityForm &&
                    <SlideModal onClose={() => this.setState({ activityForm: false, editActivity: false, projectId: null })} title={plainTranslate(this.props.settings.locale, 'Activity')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/activity/form/'}
                                        saveRoute={this.props.match.params.db + '/api/activities/new'}
                                        //updateParent={(data) => this.updateActivities(data)}
                                        onSuccessfulSave={() => this.setState({ activityForm: false, projectId: null })}
                                        popup={true}
                                        onCancel={() => this.setState({ activityForm: false, projectId: null })}
                                        match={{ params: {id: 'new'} }}
                                        refs={'&project=' + this.state.projectId + '&type=' + this.state.activityType}
                                        accountId={this.props.accountId}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {this.state.showProjectHint &&
                    <Popup locale={this.props.settings.locale}
                   title={this.state.projectHint.name}
                   onClose={() => this.setState({showProjectHint: false, projectHint: null})}
                   className="popupReports">
                        <div dangerouslySetInnerHTML={{ __html: this.state.projectHint.description }}> 
                        </div>
                    </Popup>
                }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onAddNewProject: (data, projectId) => dispatch({type: ACCOUNT_ADD_NEW_PROJECT, payload: {project: data, projectId: projectId}}),
    onTabsLoad: (route, page, requiredTotal) => dispatch(onTabsLoad(route,page, requiredTotal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Projects));

