import React from 'react';
import { connect } from 'react-redux';
import { DatePicker as DatePickerComponent } from 'antd';
import 'antd/lib/date-picker/style/index.css';
import moment from 'moment';
import client from '../../../actions/client'

class DatePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dateFormat: '',
            showTime: false
        };

        this.onChange = this.onChange.bind(this);
        this.disabledDate = this.disabledDate.bind(this);
    }

    componentDidMount() {
        this.setState({ dateFormat: this.props.settings ? this.props.settings.dateFormat : 'DD.M.YYYY' }, function() {
            if (this.props.field.settings) {
                if (this.props.field.settings.showTime) {
                    this.setState({ dateFormat: this.state.dateFormat + ' HH:mm' })
                    this.setState({ showTime: true });
                }
                if (this.props.field.settings.format) {
                    this.setState({ dateFormat: this.props.field.settings.format })
    
                }
            }
        })
    }

    onChange(date) {
        let value = null;
        if (date) {
            if (this.state.showTime) {
                value = moment(date).format('DD.M.YYYY HH:mm');
            } else {
                value = moment(date).format('DD.M.YYYY');
            }
            
            this.props.input.onChange(value);
        } else {
            this.props.input.onChange(null);
        }
        if (this.props.field.hasOwnProperty('connectedFields')) {
            let addVal = {};
            let jsonData = [];
            if (this.props.field.connectedFields.hasOwnProperty('dependency')) {
                this.props.field.connectedFields.dependency.map(field => {
                    addVal[field] = this.props.formData[field];
                })
            }
            if (this.props.field.connectedFields.hasOwnProperty('lineDependency')) {
                this.props.field.connectedFields.lineDependency.map(field => {
                    let numb = (this.props.input.name).match(/^(\D*)(\d+)/)[2];
                    addVal[field] = this.props.formData.lines[numb][field];
                })
            }
            if (this.state.showTime) {
                addVal['body'] = moment(date).format('DD.M.YYYY HH:mm');
            } else {
                addVal['body'] = moment(date).format('DD.M.YYYY');
            }
            
            jsonData.push(JSON.stringify(addVal));
            let line = '';
                if (this.props.field.connectedFields.isLine) {
                    let ind = (this.props.input.name).indexOf('.' + this.props.field.id);
                    line = (this.props.input.name).substring(0, ind);
                }

            let fields = this.props.field.connectedFields.fields;
            client.post(this.props.field.connectedFields.optionsUrl, addVal)
                .then(response => response.data)
                .then(response => {
                    fields.map(field => {
                        if (this.props.field.connectedFields.isLine) {
                            this.props.change(line + field, response[field] ? response[field] : null);
                        } else {
                            if (typeof response[field] !== 'undefined' && response[field] && response[field].length > 0) {
                                let i;
                                for (i = 0; i < response[field].length; i++) {
                                    this.props.change(field, response[field] ? response[field] : null);
                                }
                            }
                        } 
                    }
                    )
                })
        }
        if (this.props.field.hasOwnProperty('fieldDuration') && this.props.formData && this.props.formData[this.props.field.fieldDuration]) {          
            let duration = this.props.field.typesDuration[this.props.formData[this.props.field.fieldDuration]];
             if(value && duration){
                 var dateNew = moment(value, 'DD.M.YYYY HH:mm')
                     .add(duration, 'minutes')
                     .format('DD.M.YYYY HH:mm')
                // console.log(value);
                // console.log(moment(dateNew, 'DD.M.YYYY HH:mm').format(this.state.dateFormat));
                 this.props.change(this.props.field.fieldDepend, dateNew)
             }
         }
    }

    disabledDate(current){
        return current && !(moment().diff(current, 'months') >= 0 && moment().diff(current, 'months') < this.props.field.openPeriodBefore) && !(moment().diff(current, 'months') <= 0 && moment().diff(current, 'months') > this.props.field.openPeriodAfter*-1);
      };

    render() {
        var defaultDate = null;
        if (this.props.input.value) {
            if (this.props.input.value.includes('/') || this.props.input.value.includes('.')) {
                if (this.state.showTime) {
                    defaultDate = moment(this.props.input.value, 'DD.M.YYYY HH:mm')
                } else {
                    defaultDate = moment(this.props.input.value, 'DD.M.YYYY')
                } 
            } else {
                defaultDate = moment(this.props.input.value)
            }
        }
        
        var additionalStyle = this.props.field.style ? this.props.field.style : {};
        additionalStyle['width'] = this.props.field.width ? this.props.field.width : "100%";

        return <DatePickerComponent
            size="large"
            style={additionalStyle}
            disabledDate={(this.props.field.openPeriodAfter || this.props.field.openPeriodBefore) ? this.disabledDate : false}
            showTime={this.state.showTime}
            dateFormat={this.dateFormat}
            onChange={this.onChange}
            disabled={this.props.field.disabled}
            selected={this.props.input.value ? (moment(this.props.input.value).format(this.state.dateFormat)) : null}
            //defaultValue={defaultDate}
            value={defaultDate} 
            format={this.state.dateFormat} 
        />
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);