import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";
import BarcodeViewer from 'react-barcode';

import 'brace/mode/java';
import 'brace/theme/github';

class ConversionsComponent extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            value: '',
            itId: ''
        };
        
        this.onChange = this.onChange.bind(this);
        this.addNewConversion = this.addNewConversion.bind(this);
        this.removeConversion = this.removeConversion.bind(this);
        this.print = this.print.bind(this);
    }
    
    componentDidMount() {
        this.setState({
            value: this.props.value ? this.props.value : '',
            itId: this.props.item
        });
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value ? this.props.value : ''
            });
        }
    }

    onChange(e, type, key) {
        let conversions = this.state.value;
        conversions[key][type] = e.target.value;
         this.setState({
            conversions
        });
        this.props.onChange('conversions', conversions);
    }
    
    addNewConversion() {
        let conversions = this.state.value;
        let newConversion = {barcode: '', price: '', toMeasId: '', conversion: '', id: '', fromMeasId: this.props.selected.value, itId: this.state.itId};
        conversions.push(newConversion);
        this.setState({
            conversions
        });
    }
    
    removeConversion(key) {
        let conversions = this.state.value;
        delete conversions[key];
        this.setState({
            conversions
        });
    }
    
    print() {
        this.setState({
            displayValue: true
        }, () => {

            let printContents = document.getElementById('barcode').innerHTML;
            var printWindow = window.open('', 'PRINT', 'height=800,width=800');

            printWindow.document.write('<html><head><title>' + document.title + '</title>');
            printWindow.document.write('</head><body >');
            printWindow.document.write(printContents);
            printWindow.document.write('</body></html>');

            printWindow.document.close(); // necessary for IE >= 10
            printWindow.focus(); // necessary for IE >= 10*/

            printWindow.print();
            printWindow.close();

            this.setState({displayValue: false});
        });
    }

    render() {
        return <React.Fragment>
                {this.state.value && this.state.value.length > 0 && this.state.value.map((conversion, key) =>
                    <React.Fragment>
                        <div className="col-md-12" key={key}>
                            <div className="col-md-6">
                                <label>{plainTranslate(this.props.settings.locale, 'Barcode')}</label>
                                <input type="text" onChange={(e) => this.onChange(e, 'barcode', key)} value={conversion.barcode} />
                                <div id={'barcode'} style={{float: 'left'}}>
                                    <BarcodeViewer value={conversion.barcode ? conversion.barcode : '0'}
                                                   width={2}
                                                   height={50}
                                                   displayValue={this.state.displayValue}
                                                   margin={0}
                                    />
                                </div>
                                <button type="button"
                                        className="button-primary "
                                        onClick={this.print}>
                                    {plainTranslate(this.props.locale, 'Print')}
                                </button>
                            </div>
                            <div className="col-md-6">
                                <label>{plainTranslate(this.props.settings.locale, 'Pack Price*')}</label>
                                <input type="text" onChange={(e) => this.onChange(e, 'price', key)} value={conversion.price} />
                            </div>
                        </div>
                        <div className="col-md-12" key={key}>
                            <div className="col-md-4">
                                <select className="form-control form-contacts" key={key + 'type'} value={conversion.toMeasId} onChange={(e) => this.onChange(e, 'toMeasId', key)}> 
                                <option key={key} value={''}>{plainTranslate(this.props.settings.locale, 'Select option ...')}</option>
                                {this.props.options.length > 0 && this.props.options.map((option, key) =>
                                    <option key={key} value={option.value}>{plainTranslate(this.props.settings.locale, option.label)}</option>
                                )}  
                                </select>
                            </div>
                            <div className="col-md-1"><p style={{fontSize: 24}}>=</p></div>
                            <div className="col-md-7">
                                <input className="form-control form-contacts" key={key + 'value'} type="number" onChange={(e) => this.onChange(e, 'conversion', key)} value={conversion.conversion} />
                                <input className="form-control form-contacts" disabled key={key + 'selected'} value={this.props.selected.label} />
                                <i className="fa fa-trash contact-icon" aria-hidden="true" onClick = {() => this.removeConversion(key)} title={plainTranslate(this.props.settings.locale, 'Remove conversion')}></i>
                            </div>
                        <br/>
                        </div>
                    </React.Fragment>
                        )}
                <a className="button-outline grey " onClick = {this.addNewConversion}>{plainTranslate(this.props.settings.locale, 'Add conversion')}</a>
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({
    });

export default connect(mapStateToProps, mapDispatchToProps)(ConversionsComponent);
