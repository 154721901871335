import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Table, Dropdown, Upload, Menu, Select, Row, Button, Modal, } from "antd";
import Translate, { plainTranslate } from "../../common/translate";
import { getFolderOptions, getDocuments, uploadFiles, createFolder, loadUpload, } from "../../../actions/documentsActions";
import showAToast from "../../common/showAToast";
import client from "../../../actions/client";
import { Link } from "react-router-dom";
import Popup from "../../common/popup";

const Documents = (props) => {
    const [uploadLoading, setUploadLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [openFileDel, setOpenFileDel] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [file, setFile] = useState(null);
    const [attachments, setAttachments] = useState(props.formData && props.field.nestedFormName && props.formData.formData.hasOwnProperty(props.field.nestedFormName) && props.formData.formData[props.field.nestedFormName].hasOwnProperty('attachments') ? 
                                            props.formData.formData[props.field.nestedFormName].attachments : 
                                            props.formData && props.formData.formData && props.formData.formData.initializeFromState2 && 
                                            props.formData.formData.initializeFromState2.hasOwnProperty('attachments') && props.formData.formData.initializeFromState2.attachments);

    const uploadButton = (
        <div>
            <i className="fa fa-file-o" aria-hidden="true"></i>
            <div style={{ marginTop: 8 }}> {plainTranslate(props.settings.locale, "New file")} </div>
        </div>
    );
    const documentsColumns = [
        {
            key: "actions",
            dataIndex: "actions",
            width: 50,
            title: plainTranslate(props.settings.locale, "Actions"),
            render: (text, record) => {
                return (
                    <span>
                        {record.extension === "jpeg" || record.extension === "jpg" || record.extension === "png" || record.extension === "webp" || record.extension === "svg" ? (
                            <Link to="#" onClick={() => { handlePreview(record.full); }} >
                                <span> {" "} <i className="fa fa-eye"></i>{" "} </span>
                            </Link>
                        ) : (
                            <a href={record.full} target="_blank"> <i className="fa fa-eye"></i> </a>
                        )}
                        <Link to="#" onClick={() => { handleDeleteFile(record.name, record.path); }} >
                            <span> {" "} <i className="fa fa-trash-o"></i>{" "} </span>
                        </Link>
                    </span>
                );
            },
        },
        {
            key: "name",
            dataIndex: "name",
            title: plainTranslate(props.settings.locale, "Name"),
            width: 300,
        },
        {
            dataIndex: "extension",
            title: plainTranslate(props.settings.locale, "Extension"),
            width: 50,
        },
        {
            dataIndex: "size",
            title: plainTranslate(props.settings.locale, "Size"),
            width: 50,
        },
        {
            dataIndex: "created",
            title: plainTranslate(props.settings.locale, "Created"),
            width: 50,
        },
        {
            dataIndex: "createdBy",
            title: plainTranslate(props.settings.locale, "Created by"),
            width: 50,
        },
    ];

    const handleUpload = () => {
        if (fileList.length <= (props.field.limit ? props.field.limit : 8)) {
            var body = new FormData();
            fileList.map(file => (
                body.append('files[]', file.originFileObj)
            ))

            body.append("type", props.field.fileType);
            body.append("id", props.field.nestedFormName ? props.formData.formData[props.field.nestedFormName].id : props.formData.formData.initializeFromState2.id);
            setUploadLoading(true);

            client
                .post('/' + props.db + "/api/storage/files/upload", body, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    showAToast('Upload success!', 'successUpload', 'success');
                    setUploadLoading(false);
                    setFileList([]);
                    client
                        .get(`/${props.db}/api/get/files/${props.field.fileType}/${props.field.nestedFormName ? props.formData.formData[props.field.nestedFormName].id : props.formData.formData.initializeFromState2.id}/list`)
                        .then((response) => {
                            setAttachments(response.data);
                        })
                })
                .catch(err => {
                    console.log(err)
                    showAToast("Error!", 'baseError', 'error')
                });
        } else {
            showAToast(`You can upload a maximum of ${(props.field.limit ? props.field.limit : 8)} files!`, 'baseError', 'error')
        }
    }

    const handleChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handlePreview = (file) => {
        setPreviewImage(file);
        setPreviewVisible(true);
    };

    const handleDeleteFile = (fileName, filePath) => {
        setOpenFileDel(true);
        setFile(filePath);
        setFileName(fileName);
    };

    const handleOkDelFile = (e) => {
        e.preventDefault();
        client
            .post("/" + props.db + "/api/storage/file/delete", { file: file })
            .then((res) => {
                showAToast("File deleted!", "successUpload", "success");
                let arr = attachments.filter((item) => item.name !== fileName);
                setAttachments(arr);
                setOpenFileDel(false);
                setFile(null);
                setFileName(null);
            })
            .catch((err) => {
                showAToast("Error!", "baseError", "error");
            });
    };

    const handleCancelPreview = () => {
        setPreviewVisible(false);
    };

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", }} className="col-md-12" >
                <div>
                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleChange}
                        multiple={true}
                        style={{ display: "flex" }}
                        customRequest={dummyRequest}
                        accept="application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/plain, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text,  image/png, image/jpeg, image/svg+xml, image/gif, image/vnd.microsoft.icon, application/json, text/html, text/css"
                    >
                        {attachments && attachments.length >= (props.field.limit ? props.field.limit : 8) ? null : uploadButton}
                    </Upload>
                    <Button
                        type="button"
                        className="button-outline grey pull-left"
                        onClick={handleUpload}
                        loading={uploadLoading}
                        disabled={fileList && fileList.length === 0}
                        style={{ marginLeft: "0" }}
                    >
                        {uploadLoading ? (<Translate locale={props.settings.locale} value="Uploading" />) : (<Translate locale={props.settings.locale} value="Start Upload" />)}
                    </Button>
                </div>
                <div>
                    {attachments && attachments.length ? (
                        <Table
                            rowKey={(record) => record.id}
                            columns={documentsColumns}
                            dataSource={attachments}
                            pagination={false}
                            size="big"
                        />
                    ) : (<p> {plainTranslate(props.settings.locale, "No files have been uploaded yet")} </p>
                    )}
                </div>
            </div>
            {openFileDel && (
                <Popup
                    locale={props.settings.locale}
                    title={plainTranslate(props.settings.locale, "Delete")}
                    onClose={() => setOpenFileDel(false)}
                >
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <div className="text-center">
                                    <h3> <Translate locale={props.settings.locale} value="Are you sure?" /> </h3>
                                    <button
                                        className="button-outlines green text-center"
                                        onClick={() => setOpenFileDel(false)}
                                    >
                                        <Translate locale={props.settings.locale} value="No" />
                                    </button>
                                    <button
                                        className="button-primary-edited text-center btn-ok"
                                        onClick={(e) => handleOkDelFile(e)}
                                    >
                                        <Translate locale={props.settings.locale} value="Yes" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </Popup>
            )}
            <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleCancelPreview}
            >
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    formData: state,
});

const mapDispatchToProps = (dispatch) => ({
    getFolderOptions: () => dispatch(getFolderOptions()),
    getDocuments: (type, pageFiles, pageFolders, id) => dispatch(getDocuments(type, pageFiles, pageFolders, id)),
    uploadFiles: (form) => dispatch(uploadFiles(form)),
    createFolder: (db, name, parentId) => dispatch(createFolder(db, name, parentId)),
    loadUpload: () => dispatch(loadUpload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
