import React from 'react';
import {Field as FormField, FieldArray, reduxForm } from 'redux-form';
import LineField from './lineField';
import Field from '../field';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {plainTranslate} from "../../common/translate";
import Translate from '../../common/translate';
import Switch from './switch';

class RenderPosLines extends React.Component {

    constructor(props) {
        super(props);

        this.discardOrder = this.discardOrder.bind(this);
    }

    discardOrder(){
        this.props.change('lines', null);
    }

    render() {
        let subTotal = 0;
        let totalDiscountRate = Number(this.props.formData.totalDiscountRate ? this.props.formData.totalDiscountRate : 0)/100;
        let tax = this.props.formData.taxRate;
        let taxAmount = 0;
        let totalWithoutVat = 0;
        let discountAmount = 0;
        let totalAmount = 0;

        let totals = [];
        totals['subTotal'] = subTotal;
        totals['discountAmount'] = discountAmount;
        totals['taxAmount'] = taxAmount;
        let index = this.props.totals.findIndex(o => o.id === 'totalDiscountRate');
        return <React.Fragment>
                <table className="posTable">
                        <thead>
                            {
                                this.props.columns &&
                                <tr>
                                    {
                                        this.props.columns.map((column, key) => {
                                            return <td key={key} className={column.hide ? 'hidden ' : ''}>
                                            {plainTranslate(this.props.settings.locale, column.label)}
                                            </td>
                                        })
                                    }
                                </tr>
                            }
                        </thead>
                        <tbody>
                        {this.props.lines && this.props.lines.map((line, key) => {
                            if(line.itName){
                                subTotal+= Number(line.amountBase);
                                totalAmount += Number(line.amountTotal);
                                taxAmount = subTotal * (tax/100);
                                if(this.props.totals[index].hide !== true){
                                    discountAmount = (totalAmount)*totalDiscountRate;
                                }
                                //totalWithoutVat = subTotal - discountAmount;
                                
                                

                                totals['subTotal'] = subTotal;
                                totals['discountAmount'] = discountAmount;
                                totals['taxAmount'] = taxAmount;
                                return <tr>
                                {this.props.columns.map((column, colKey) => {
                                    let fieldValue = line[column.id];
                                    if ('populateFrom' in column && key !== this.props.lines.length - 1) {
                                        if (column.populateFrom === 'mainFormValue' && this.props.mainFormData) {
                                            fieldValue = column.id in this.props.mainFormData ? this.props.mainFormData[column.id] : column.defaultValue ? column.defaultValue : 0;
                                        }
                                        else {
                                            fieldValue = line[column.id] && !(column.populateFrom in line) ? line[column.id] : column.populateFrom in line ? line[column.populateFrom][column.id] : null;
                                        }
                                    }
                                    else if ('operation' in column) {
                                        fieldValue = this.props.calculateOperation(column.operation, line);
                                        this.props.change(this.props.fieldId+'['+key+'].'+column.id, fieldValue);
                                    }

                                    if (column.initialValue !== undefined && !fieldValue && key !== this.props.lines.length - 1) {
                                        fieldValue = column.initialValue;
                                    }

                                    return <LineField
                                            name={this.props.fieldId+'['+key+'].'+column.id}
                                            field={column}
                                            locale={this.props.settings.locale}
                                            
                                            type={column.type}
                                            search={this.props.search}
                                            width={column.width}
                                            placeholder={column.placeholder}
                                            onDatalistChange = {(e, field) => this.onDatalistChange(e, field, key)}
                                            onNewOptions={(options, addValue) => this.onNewOptions(key, column.id, options, addValue)}
                                            onChangeOptions={(field, options, isLine) => this.onChangeOptions(field, options, isLine)}
                                            change={this.props.change}
                                            isPos={true}
                                        />

                                    })
                                }
                                    <td className={'left'}>
                                        <span onClick={() => this.props.remove(key)} style={{cursor: "pointer"}}>
                                            <i className='ion-android-close' title="Delete row" ></i></span>
 
                                    </td>
                                </tr>
                            }
                        })}
                        </tbody>
                </table>
                <table className="totals">
                    {this.props.totals && this.props.totals.map((column, key) => {
                        if(column.hasOwnProperty('type') && column.hide !== true && column.id !== 'issueInvoice'){
                            return <tr>
                                <td className="field-label"><h4>{plainTranslate(this.props.settings.locale, column.name)}</h4></td>
                                <td>
                                    <LineField
                                        name={column.id}
                                        field={column}
                                        locale={this.props.settings.locale}
                                        
                                        type={column.type}
                                        search={this.props.search}
                                        width={column.width}
                                        placeholder={column.placeholder}
                                        onDatalistChange = {(e, field) => this.onDatalistChange(e, field, key)}
                                        onNewOptions={(options, addValue) => this.onNewOptions(key, column.id, options, addValue)}
                                        onChangeOptions={(field, options, isLine) => this.onChangeOptions(field, options, isLine)}
                                        change={this.props.change}
                                        isPos={true}
                                    />
                                </td>
                            </tr>
                        }
                        else if(column.hide !== true && column.id !== 'issueInvoice'){
                           return <tr>
                                <td className="field-label"><h4>{column.name}</h4></td>
                                <td className="value"><h4>{totals[column.id].toFixed(2)}</h4></td>
                            </tr>
                        }
                    })}
                    <tr className="line">
                        <td className="field-label"><h3><strong>{plainTranslate(this.props.settings.locale, 'Total')}</strong></h3></td>
                        <td className="value"><h3><strong>{(totalAmount-totals['discountAmount']).toFixed(2)}</strong></h3></td>
                    </tr>
                    {this.props.formProperties && !this.props.formProperties.isEdit &&
                        <button type="button" className="button-outline grey"
                            onClick={this.discardOrder}>
                            <Translate locale={this.props.settings.locale}
                                       value="Discard Order"/>
                        </button>
                    }
                    {this.props.totals && this.props.totals.map((column, key) => {
                    if(column.hide !== true && column.id === 'issueInvoice'){
                       return <Field
                                        name={column.id}
                                        field={column}
                                        locale={this.props.settings.locale}
                                        
                                        type={column.type}
                                        search={this.props.search}
                                        width={column.width}
                                        placeholder={column.placeholder}
                                        onDatalistChange = {(e, field) => this.onDatalistChange(e, field, key)}
                                        onNewOptions={(options, addValue) => this.onNewOptions(key, column.id, options, addValue)}
                                        onChangeOptions={(field, options, isLine) => this.onChangeOptions(field, options, isLine)}
                                        change={this.props.change}
                                        isPos={true}
                                    />
                    }
                    })}
                </table>

        </React.Fragment>

    }
}
export default RenderPosLines;
