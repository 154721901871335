import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Tab extends Component {
    constructor(props) {
    super(props);

    this.onTab = this.onTab.bind(this);
  }
  onTab() {
    this.props.onClick(this.props.label);
  }

  render() {
    
    let className = 'tab-list-item';

    if (this.props.activeTab === this.props.label) {
      className += ' tab-list-active';
    }

    return (
      <li 
        className={className}
        onClick={this.onTab}
      >
        {this.props.label}
      </li>
    );
  }
}


export default Tab;
