import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Drawer, Button, Select } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import {connect} from 'react-redux';
import Translate, { plainTranslate } from '../../common/translate';
import {Link} from 'react-router-dom';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';
import { withRouter } from 'react-router-dom';
import ModalConfirm from '../../common/ModalConfirm';

const InventoryPlacesPopup = (props, state) => {
  const [openSaveAs, setOpenSaveAs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [locationPlacesOptions, setLocationPlacesOptions] = useState(false);
  const [selectedCart, setSelectedCart] = useState(false);

  let inputTimer;

  useEffect(() => {

  }, []);

  function closePopup(){
    setOpenSaveAs(false);
  }

  function selectOptionsSearch(val){
      clearTimeout(inputTimer);
      inputTimer = setTimeout(() => {
          setIsLoading(true);
          client.get(props.db + "/api/inventory-places/get-active/carts?q="+val).then(response => {
              setLocationPlacesOptions(response.data);
              setIsLoading(false);
          });   
      }, 1000);
  }

  function getSelectOptions(){
      if(!locationPlacesOptions){
          client.get(props.db + "/api/inventory-places/get-active/carts").then(response => {
              setLocationPlacesOptions(response.data);
          });    
      }
  }

  function saveCart(){
    if(selectedCart){
      setIsLoading(true);

      client.post(props.db + "/api/inventory-places/"+selectedCart+"/save/receipt", {'body': props.id, 'filters': props.filters, 'selectAll': props.selectAll}).then(response => {
          setSelectedCart(null);
          setLocationPlacesOptions(null);

          if(response.data.error){
            showAToast(response.data.error, 'baseError', 'error');
            setIsLoading(false);
          }else{
            showAToast(plainTranslate(props.settings.locale, response.data.success), 'succSettings', 'success');
            props.closePopup();
          }
          
          //props.updateGridData();
          
      });
    }
  }

  return (
    <>
        <section className="newPanel" style={{width: "100%",height: "100%",backgroundColor: "white"}}>
          <div className="panel-body">
              <div className="row m-bot15">
                  <div><span style={{marginRight: 20}}>{plainTranslate(props.locale, 'Select cart')}</span>
                  <Select showSearch 
                      filterOption={false}
                      loading={isLoading}
                      onSearch={(value) => selectOptionsSearch(value)}
                      onSelect={(value) => setSelectedCart(value)} 
                      style={{ width: 240 }}
                      options={locationPlacesOptions} 
                      onDropdownVisibleChange={() => getSelectOptions()}
                  >
                  </Select>
                  </div>
                  {<div>
                  <button className="button-primary" type="button" id="saveCart" 
                  disabled = {isLoading ? 'disabled' :  '' } 
                  className={"button-primary " + (isLoading ? 'disabled' : '')}
                      onClick={() => saveCart()}>
                  <Translate locale={props.settings.locale} value="Save"/>
              </button></div>}
              </div>
          </div>
        </section>
    </>
  );
};

const mapStateToProps = state => ({
  settings: state.settings,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventoryPlacesPopup));