import React from 'react';
import {connect} from 'react-redux';

class Password extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ''
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value ? this.props.value : ''
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value ? this.props.value : ''
            });
        }
    }

    onChange(e) {
        this.setState({
            value: e.target.value
        });
        this.props.onChange(this.props.id, e.target.value);
    }

    render() {
        return <span className="form-input-field">
                    <input type="password" className="form-control"
                           value={this.state.value}
                           onChange={this.onChange}
                           onBlur={() => this.props.loseFocus(this.props.id)}
                           placeholder={this.props.placeholder ? this.props.placeholder : ''}
                           tabIndex = {this.props.tabindex ? this.props.tabindex : ''}
                    />
                </span>
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Password);
