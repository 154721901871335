import React, {Component} from 'react';
import Translate, {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';
import {onFieldSave} from "../../../../actions/actions";
import moment from 'moment';

class Statistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rating: 0,
            amount: 0,
            created: '',
            ratingStars: ''
        };
        this.changeRating = this.changeRating.bind(this);
    }

    componentDidMount() {
        this.setState({
            rating: this.props.account.rating ? this.props.account.rating : 0,
            amount: this.props.account.customerLifetimeValue ? this.props.account.customerLifetimeValue : 0,
            created: this.props.account.accountDate ? this.props.account.accountDate : '',
            unpaid: this.props.account.unpaidSales ? this.props.account.unpaidSales : '',
            curr: this.props.account.currency ? this.props.account.currency : '',
            activityLastDate: this.props.account.activityLastDate ? this.props.account.activityLastDate : 'No activities'
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rating !== this.props.rating || prevProps.amount !== this.props.amount || prevProps.accountDate !== this.props.accountDate) {
            this.setState({
                rating: this.props.account.rating,
                amount: this.props.account.amount
            });
        }
    }

    changeRating(e) {
        let rating = e.target.getAttribute('data-value');
        this.setState({rating});
        this.props.onFieldSave(this.props.updateRoute, this.props.id, Number(rating), this.props.additionalData);
    }

    render() {

        return (
            <div>
            {this.props.account.settings && this.props.account.settings.accountAmount == 1 &&
                <div className="col-md-4 col-xs-4 text-center profile-statistics-item">
                    <h4 style={{marginTop: '1em'}}>{this.state.amount ? Number(this.state.amount).toFixed(2) : '0.00'} {this.state.curr ? this.state.curr : '€'}</h4>
                    <p><Translate locale={this.props.settings.locale} value="Amount"/></p>
                </div>
            }
            {this.props.account.settings && this.props.account.settings.accountUnpaid == 1 &&
                <div className="col-md-4 col-xs-4 text-center profile-statistics-item">
                    <h4 style={{marginTop: '1em'}}>{this.state.unpaid ? Number(this.state.unpaid).toFixed(2) : '0.00'} {this.state.curr ? this.state.curr : '€'}</h4>
                    <p><Translate locale={this.props.settings.locale} value="Balance"/></p>
                </div>
            }
            {this.props.account.settings && this.props.account.settings.accountCreated == 1 &&
            <div className="col-md-4 col-xs-4 text-center profile-statistics-item">
                <h4 style={{marginTop: '1em'}}>{this.props.account && this.props.account.accountDate ? moment((this.props.account.accountDate).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : 'No data'}</h4>
                <p><Translate locale={this.props.settings.locale} value="Created"/></p>
            </div>
            }
            {this.props.account.settings && this.props.account.settings.accountRating == 1 &&
            <div className="col-md-4 col-xs-4 text-center profile-statistics-item">
               <React.Fragment>
                <h4 style={{marginTop: '1em'}}>
                    {
                        [1, 2, 3, 4, 5].map(i => <i className={"fa fa-star " + (i <= this.state.rating ? 'gold' : '')}
                                                    onClick={this.changeRating}
                                                    key={i} data-value={i}></i>)
                    }
                </h4>
                <p><Translate locale={this.props.settings.locale} value="Rating"/></p>
                </React.Fragment>
            </div>
            }
            {this.props.account.settings && this.props.account.settings.accountLastContact == 1 &&
            <div className="col-md-4 col-xs-4 text-center profile-statistics-item">
                <React.Fragment>
                    <h4 style={{marginTop: '1em'}}>
                        {this.state.activityLastDate}
                    </h4>
                    <p><Translate locale={this.props.settings.locale} value="Last Contact"/></p>
                </React.Fragment>
            </div>
            }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData))
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
