import React, { useState } from 'react';
import { connect } from 'react-redux';
import SlideModal from '../../common/SlideModal';
import Form from '../form';
import { plainTranslate } from "../../common/translate";
import { withRouter } from 'react-router-dom';

const CreateNew = (props) => {
    const [showForm, setShowForm] = useState(false);
    return (
        <div >
            <i className="fa fa-plus-square-o"
                onClick={() => setShowForm(true)} aria-hidden="true"
                title={plainTranslate(props.locale, props.field.title)}
                style={{ cursor: "pointer" }}
            ></i>
            {showForm &&
                <SlideModal onClose={() => setShowForm(false)} title={plainTranslate(props.settings.locale, props.field.title)}>
                    <section className="newPanel">
                        {/* <div className="panel-body"> */}
                        <div className="row m-bot15">
                            <Form
                                formRoute={props.db + '/api/cms-contents/'}
                                saveRoute={props.db + '/api/cms-contents'}
                                popup={true}
                                isNew={true}
                                // onSuccessfulSave={(data) => this.updateAccountConsents(data)}
                                match={{ params: { id: 'createNew' } }}
                                onCancel={() => setShowForm(false)}
                                form={'initializeFromState'}
                            />
                        </div>
                        {/* </div> */}
                    </section>
                </SlideModal>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateNew));


