import React, { useEffect, useState } from 'react';
import { plainTranslate } from "../../common/translate";
import { Button, Form, Input, Select, Switch, Table, Space, Tag } from 'antd';
import { CloseOutlined, PlusOutlined, CheckOutlined, EditOutlined, DeleteOutlined, MinusCircleOutlined, ContactsOutlined } from '@ant-design/icons';
import SlideModal from '../../common/SlideModal';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const { Option } = Select;

const VisualJsonBuilder = props => {
    const [value, setValue] = useState(null);
    const [fields, setFields] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedType, setSelectedType] = useState(false);
    const [editableField, setEditableField] = useState(false);
    const formRef = React.createRef();
    const [form] = Form.useForm();
    const [editableValues, setEditableValues] = useState(false);
    const [additionalOptions, setAdditionalOptions] = useState(null);
    const [additionalOptionsValue, setAdditionalOptionsValue] = useState(null);

    useEffect(() => {
        let jsonData = isJsonString(props.input.value) ? JSON.parse(props.input.value) : null;
        let fields = props.field.settings.fields;
        for (let i = 0; i < fields.length; i++) {
            if (fields[i]['specification']) {
                for (let s = 0; s < fields[i]['specification'].length; s++) {
                    if (fields[i]['specification'][s]['value']) {
                        if (jsonData && jsonData.hasOwnProperty('fields') && jsonData['fields'].length) {
                            for (let j = 0; j < jsonData['fields'].length; j++) {
                                if (jsonData['fields'][j].addOption) {
                                    if (jsonData['fields'][j].type == fields[i].id) {
                                        let obj = { [fields[i]['specification'][s].id]: fields[i]['specification'][s].value };
                                        Object.assign(jsonData['fields'][j], obj);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (jsonData) {
            let addOptValue = Object.fromEntries(Object.entries(jsonData).filter(([key]) => !key.includes('fields')));
            setValue(jsonData['fields'] ? jsonData['fields'] : []);
            setAdditionalOptionsValue(addOptValue);
        } else {
            setValue([]);
            setAdditionalOptionsValue({});
        }
        setFields(fields);

        let addOpt = Object.fromEntries(Object.entries(props.field.settings).filter(([key]) => !key.includes('fields')));
        let array = [];
        array.push(addOpt);
        const [entries] = array.map((el) => Object.entries(el));
        const newArray = entries.map((entry) => Object.fromEntries([entry]));
        setAdditionalOptions(newArray);
    }, []);

    const onFinish = (values) => {
        if (selectedType.prefix && !values['id'].includes(selectedType.prefix)) {
            values['id'] = selectedType.prefix + '_' + values['id'];
        }
        if (editableField === false) {
            let arr = value.push(values);
            setValue(value);
        } else {
            value[editableField] = values;
            setValue(value);
        }

        let allValues = new Object();
        allValues['fields'] = value;
        allValues = Object.assign(allValues, additionalOptionsValue);
        props.input.onChange(JSON.stringify(allValues));
        setSelectedType(false);
        setEditableField(false);
        setShowModal(false);
    }

    const onSubmit = () => {
        formRef.current.submit()
    }

    const onSubmitAdditional = (val, type) => {
        let additional = additionalOptionsValue;
        additional[type] = val;
        setAdditionalOptionsValue(additional);
        let allValues = new Object();
        allValues['fields'] = value;
        allValues = Object.assign(allValues, additional);
        props.input.onChange(JSON.stringify(allValues));
    }

    const onAddNewField = (selectedTypeId) => {
        form.resetFields();
        let selected = fields.filter((item) => item.id == selectedTypeId)[0];
        form.setFieldsValue({ id: '', type: selected.id, required: false, label: '', width: null });
        setSelectedType(selected);
    }

    const handleDeleteField = (key) => {
        let arr = value.splice(key, 1);
        setValue(value);

        let allValues = new Object();
        allValues['fields'] = value;
        allValues = Object.assign(allValues, additionalOptionsValue);
        props.input.onChange(JSON.stringify(allValues));
    }

    const handleEditField = (key) => {
        let editField = value[key];
        form.setFieldsValue(editField);
        let selectedField = fields.filter((item) => item.id == editField.type)[0];
        setEditableField(key);
        setSelectedType(selectedField);
        setEditableValues(editField);
    }

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const onDragEnd = (val) => {
        let items = value;
        let droppable = val.destination.droppableId;
        let destinationIndex = val.destination.index;
        let sourceIndex = val.source.index;
        let updatedItems = null;

        if (droppable == 'main') {
            updatedItems = moveInArray(items, sourceIndex, destinationIndex);
        } else {
            let index = null;
            let updatedSubItems = null;
            items.find((item, key) => {
                if (item.name === droppable) {
                    index = key;
                }
            });

            if (index !== null) {
                updatedSubItems = moveInArray(items[index][props.field.settings.subLevel], sourceIndex, destinationIndex);
                items[index][props.field.settings.subLevel] = updatedSubItems;
                updatedItems = items;
            } else {
                updatedItems = items;
            }
        }
        setValue(updatedItems);

        let allValues = new Object();
        allValues['fields'] = updatedItems;
        allValues = Object.assign(allValues, additionalOptionsValue);
        props.input.onChange(JSON.stringify(allValues));

    };

    const moveInArray = (arr, from, to) => {
        if (Object.prototype.toString.call(arr) !== '[object Array]') {
            throw new Error('Please provide a valid array');
        }
        var item = arr.splice(from, 1);
        if (!item.length) {
            throw new Error('There is no item in the array at index ' + from);
        }
        arr.splice(to, 0, item[0]);
        return arr;
    };

    const colors = {
        'text': '#33cc33',
        'number': '#4287f5',
        'datepicker': '#ff9933',
        'markdown': '#ff99cc',
        'images': '#9933ff',
        'tinymce': '#00ffff',
        'switch': '#66ccff',
        'dropdown': '#808000',
        'textarea': '#ff7f50',
        'sections': '#8c2d3a',
        'menuBuilder': '#008080'
    }

    return (<div>
        <React.Fragment>
            <Button className="button-outline grey" onClick={() => setShowModal(true)}><PlusOutlined /> {plainTranslate(props.settings.locale, 'Add another field')}</Button>
            {/* <Table dataSource={dataSource} columns={columns} pagination={false}/> */}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="main">
                    {(provided, snapshot) => (
                        <table className="json-builder-table"
                            {...provided.droppableProps}
                            ref={provided.innerRef}>
                            <tr>
                                <th>{plainTranslate(props.settings.locale, 'Type')}</th>
                                <th>{plainTranslate(props.settings.locale, 'Id')}</th>
                                <th>{plainTranslate(props.settings.locale, 'Label')}</th>
                                <th>{plainTranslate(props.settings.locale, 'Width')}</th>
                                <th>{plainTranslate(props.settings.locale, 'Required')}</th>
                                <th>{plainTranslate(props.settings.locale, 'Action')}</th>
                            </tr>
                            {value && value.map((item, key) => {
                                return <Draggable key={item.id} draggableId={item.id} index={key}>
                                    {(provided, snapshot) => (<tr key={key} ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                        <td>{<Tag color={colors[item.type]}>{item.type}</Tag>}</td>
                                        <td>{item.id}</td>
                                        <td>{item.label}</td>
                                        <td>{item.width}</td>
                                        <td>{(item.required == true ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />)}</td>
                                        <td><Space size="middle">
                                            <a onClick={() => handleEditField(key)} title={plainTranslate(props.settings.locale, 'Edit')}><EditOutlined /></a>
                                            <a onClick={() => handleDeleteField(key)} title={plainTranslate(props.settings.locale, 'Delete')}><DeleteOutlined /></a>
                                        </Space></td>
                                    </tr>
                                    )}
                                </Draggable>
                            }
                            )}
                        </table>
                    )}
                </Droppable>
            </DragDropContext>
            {additionalOptions && additionalOptions.length && additionalOptions.map((addOpt) => {
                return <>
                    <div className="col-md-12 no-gutter">
                        <div className="col-md-4">{plainTranslate(props.settings.locale, `Add ${Object.keys(addOpt)}`)}</div>
                        <div className="col-md-5">
                            {Object.values(addOpt) == 'text' ?
                                <Input defaultValue={additionalOptionsValue && additionalOptionsValue[Object.keys(addOpt)]} onChange={(e) => onSubmitAdditional(e.target.value, Object.keys(addOpt)[0])}/> 
                                :
                                <Switch
                                    style={{ margin: "10px" }}
                                    size="large"
                                    defaultChecked={additionalOptionsValue && additionalOptionsValue[Object.keys(addOpt)]}
                                    onChange={(val) => onSubmitAdditional(val, Object.keys(addOpt)[0])}
                                />
                            }
                        </div>
                    </div>
                </>
            })
            }
            {showModal &&
                <SlideModal
                    onClose={() => setShowModal(false)}
                    title={plainTranslate(props.settings.locale, 'Select a field for your content type')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                {fields && fields.length && fields.map((field, key) => {
                                    return <div className="col-md-8" key={key} onClick={() => onAddNewField(field.id)} style={{ border: '1px solid rgba(0, 0, 0, 0.05)', padding: '10px', cursor: 'pointer', marginBottom: '10px' }}>
                                        <span style={{ fontSize: '16px', fontWeight: '600' }}><Tag color={colors[field.id]}>{field.label}</Tag>{field.description}</span>
                                    </div>
                                })}
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }
            {selectedType &&
                <SlideModal
                    onClose={() => setSelectedType(false)}
                    title={plainTranslate(props.settings.locale, editableField !== false ? "Edit" : 'Add new') + ' ' + plainTranslate(props.settings.locale, selectedType.label) + ' ' + plainTranslate(props.settings.locale, 'field')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <Form form={form} name="form_content_field" ref={formRef}
                                    onFinish={onFinish} autoComplete="off" id="content-field">
                                    <Form.Item name={'type'} label={"Type"} rules={[{ required: true }]} hidden={true}>
                                        <Input />
                                    </Form.Item>
                                    {selectedType.specification && Array.isArray(selectedType.specification) && selectedType.specification.map((item, key) => {
                                        return <>
                                            {item.type == 'text' &&
                                                <Form.Item name={item.id} label={item.id} rules={[{ required: item.required }]} hidden={item.hide}>
                                                    <Input />
                                                </Form.Item>
                                            }
                                            {item.type == 'boolean' &&
                                                <Form.Item name={item.id} label={item.id} rules={[{ required: item.required }]}>
                                                    <Switch defaultChecked={editableValues ? editableValues[item.id] : null}/>
                                                </Form.Item>
                                            }
                                            {item.type == 'options' &&
                                                <Form.Item name={item.id} label={item.id} rules={[{ required: item.required }]}>
                                                    <Select style={{ width: 130 }}>
                                                        {item.options.map((item, a) => (
                                                            <Option key={a} value={item}>
                                                                {plainTranslate(props.settings.locale, item)}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            }
                                            {item.type == 'settings' &&
                                                <>
                                                    <h4>{plainTranslate(props.settings.locale, 'Settings')}</h4>
                                                    {item.type == 'settings' && item.settings && item.settings.map((settingField, fkey) => {
                                                        return <>
                                                            {settingField.type == 'text' &&
                                                                <Form.Item tooltip={settingField.tooltip} name={['settings', settingField.id]} label={settingField.id} rules={[{ required: settingField.required }]}>
                                                                    <Input />
                                                                </Form.Item>
                                                            }
                                                            {settingField.type == 'select' &&
                                                                <Form.Item tooltip={settingField.tooltip} name={['settings', settingField.id]} label={settingField.id} rules={[{ required: settingField.required }]}>
                                                                    <Select style={{ width: 130 }}>
                                                                        {item.options.map((item, a) => (
                                                                            <Option key={a} value={item}>
                                                                                {plainTranslate(props.settings.locale, item)}
                                                                            </Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            }
                                                            {settingField.type == 'boolean' &&
                                                                <Form.Item tooltip={settingField.tooltip} name={['settings', settingField.id]} label={settingField.id} rules={[{ required: settingField.required }]}>
                                                                    <Switch defaultChecked={editableValues && editableValues['settings'] ? editableValues['settings'][settingField.id] : false}/>
                                                                </Form.Item>
                                                            }
                                                            {settingField.type == 'multiOptions' &&
                                                                <Form.Item name={['settings', settingField.id]} label={settingField.id} rules={[{ required: settingField.required }]}>
                                                                <Select style={{ width: 530 }} mode="multiple" defaultValue={editableValues && editableValues['settings'] ? editableValues['settings'][settingField.id] : ''}>
                                                                    {settingField.options.map((item, a) => (
                                                                        <Option key={a} value={item}>
                                                                            {plainTranslate(props.settings.locale, item)}
                                                                        </Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                            }
                                                        </>
                                                    })
                                                    }
                                                </>
                                            }
                                            {item.type == 'selectOptions' &&
                                                <>
                                                    <h4>{plainTranslate(props.settings.locale, 'Options')}</h4>
                                                    <Form.List name="options">
                                                        {(fields, { add, remove }) => (
                                                            <>
                                                                {fields.map(({ key, name, ...restField }) => (
                                                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'value']}
                                                                            rules={[{ required: true, message: 'Missing value' }]}
                                                                        >
                                                                            <Input placeholder="Value" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'label']}
                                                                            rules={[{ required: true, message: 'Missing label' }]}
                                                                        >
                                                                            <Input placeholder="Label" />
                                                                        </Form.Item>
                                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                                    </Space>
                                                                ))}
                                                                <Form.Item>
                                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                        Add option
                                                                    </Button>
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    </Form.List>
                                                </>
                                            }
                                        </>
                                    })}
                                    <Form.Item>
                                        <Button
                                            htmlType="button"
                                            onClick={onSubmit} className="button-primary">
                                            {plainTranslate(props.settings.locale, "Save")}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }
        </React.Fragment>
    </div>
    );
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(VisualJsonBuilder);


