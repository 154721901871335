import React from 'react';
import {connect} from 'react-redux';
import SlideModal from '../../common/SlideModal';
import SimpleForm from "../../forms/form";
import {plainTranslate} from "../../common/translate";
import { withRouter } from 'react-router-dom';
import client from '../../../actions/client';

class AddTranslation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modalTranslation: false
        };
        this.closeModal = this.closeModal.bind(this);
    }
    
    closeModal(){
        this.setState({
            modalTranslation: false
        });
    }

    translateText(){
        client.post('/' + this.props.match.params.db + '/api/translations/text/translate',{
            lang: this.props.formData.form.initializeFromState2.values[this.props.field.dependency], 
            text: this.props.formData.form.initializeFromState2.values[this.props.field.translate],
            html: this.props.field.isHtml
        })
        .then(response => response.data)
        .then(response => {
            this.props.change(this.props.field.translate, response);
        })
    }

    render() {
        let value = this.props.formData.form.initializeFromState2 && this.props.formData.form.initializeFromState2.values && this.props.formData.form.initializeFromState2.values.hasOwnProperty(this.props.field.translate) ? this.props.formData.form.initializeFromState2.values[this.props.field.translate] : '';

        if(value && value.indexOf('+') > -1){
            value = value.replace("+", "%2B");
        }

         if(value && value.indexOf('&') > -1){
            value = value.replace("&", "%26");
        }

        value = encodeURIComponent(value);

        return <div >
               <i className="fa fa-lightbulb-o contact-icon" style={{marginRight: '20px', float:'none'}} aria-hidden="true" onClick = {() => this.translateText()} title={plainTranslate(this.props.locale, 'Generate translation')}></i>
               <i className="fa fa-language" aria-hidden="true" title="Add translation" onClick={() => this.setState({modalTranslation: true})}></i> 
                {
                        this.state.modalTranslation &&
                            <SlideModal onClose={() => this.setState({modalTranslation: false})} title={plainTranslate(this.props.settings.locale, 'Translation')}>
                                <section className="newPanel">
                                    <div className="panel-body">
                                        <div className="row m-bot15">
                                            <SimpleForm
                                                saveRoute={this.props.match.params.db + '/api/translates/'}
                                                formRoute={this.props.match.params.db + '/api/translates/form'}
                                                match={{params: {id: 0}}}
                                                onSuccessfulSave={this.closeModal}
                                                refs = {value}
                                                type={this.props.field.translate}
                                                val={this.props.formData.form.initializeFromState2.values.id}
                                                popup={true}
                                                isNew={true}
                                                onCancel={() => this.setState({modalTranslation: false})}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </SlideModal>
                    }
                    </div>
                }
            }

const mapStateToProps = state => ({
    settings: state.settings,
    formData: state,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddTranslation));


