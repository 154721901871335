import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import {onUploadFile} from '../../../actions/actions';
import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom'

import './image.css';

class File extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: [],
            showUploadModal: false,
            loading: false
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleEscClick = this.handleEscClick.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);

        this.showUploadModal = this.showUploadModal.bind(this);
        this.chooseFile = this.chooseFile.bind(this);
        this.uploadImages = this.uploadImages.bind(this);
    }

    componentDidMount(){
        this.setState({ value:this.props.input.value ? this.props.input.value : []})
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleEscClick);
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.showUploadModal();
        }
    }
    handleEscClick(e) {
        if (e.keyCode === 27) {
            this.showUploadModal();
        }
    }

    showUploadModal() {
        this.setState({showUploadModal: !this.state.showUploadModal});
    }

    chooseFile() {
        document.getElementById('fileUpload').click();
    }

    uploadImages(e) {
        const files = e.target.files[0];

        this.setState({loading: true});
        this.props.onUploadFile(this.props.settings.db, this.props.formData.type, files).then((response) => {
            var file = response;
            this.setState({loading:false, showUploadModal: false, value:file});
            this.props.formData.itemImages=file;
            this.props.history.push(response.redirectUrl);
        });
    }
    
    truncate(s) {
        if (s.length > 10) {
            let str = s.substring(0, 9);
             return str + '...';
         } 
          else {
             return s; 
         }
    }


    render() {
        return (
            <div>
                <div>
                    <div className="col-md-12">
                        <ul className="nav nav-pills">
                            <li role="presentation">
                                <button type="button" className="upload-btn btn btn-success"
                                        onClick={() => this.showUploadModal()}>
                                    <i className="fa fa-upload"></i>
                                    {plainTranslate(this.props.settings.locale, 'Upload')}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                {
                    this.state.showUploadModal &&
                    <div className={`backendropss`}>
                        <div className={'modalConfirms scroll input-board ' + (this.state.dragging ? 'beige' : '')}
                             onDrop={this.onImageDrop}
                             onDragOver={(e) => e.preventDefault()}
                             onDragEnter={this.onDragOver}
                             onDragLeave={this.onDragOver}
                             ref={this.setWrapperRef}>
                            <div className="text-right">
                                <div className="" onClick={this.showUploadModal}>
                                    <i className="fa fa-close" aria-hidden="true"></i>
                                </div>
                            </div>
                            <section className="newPanel">
                                <div className="panel-body">
                                    <div className="row m-bot15">
                                        <div className="text-center">
                                            {this.state.loading && 'Loading...'} 
                                        </div>
                                        <button type="button" className={'upload-btn btn btn' + (this.state.loading ? ' disabled' : '-success') + ' upload-button'}
                                                onClick={this.chooseFile}>
                                            <i className="fa fa-upload"></i>
                                            Choose
                                        </button>
                                        <input id={'fileUpload'} name="files[]" type="file" style={{visibility: 'hidden'}}
                                               onChange={this.uploadImages} multiple/>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                }

            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onUploadFile: (db, id, file) => dispatch(onUploadFile(db, id, file)),
    //onDeleteImage: (id, formId) => dispatch(onDeleteImage(id, formId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(File);
