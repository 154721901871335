import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from "../../common/translate";
import DoubleCheckbox from './doubleCheckbox';
import showAToast from '../../common/showAToast';

class FormFields extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ''
        };  
        this.onChange = this.onChange.bind(this);
        this.onSecondChange = this.onSecondChange.bind(this);
    }

    componentDidMount() {
       this.setState({
            value: this.props.value ? this.props.value : []
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value ? this.props.value : []
            });
        }
    }

    onChange(name) {
        const settings = this.state.value ? this.state.value : [];
        for (var i = 0; i < settings.length; i++) {
            if (settings[i]['name'] == name) {
                var index = i;
            }
        }
        if (index != undefined) {
            settings.splice(index, 1);
        } else {
            let obj = {};
            obj['name'] = name;
            obj['required'] = '0';
            settings.push(obj);
        }

        this.setState({
            settings,
        }, function () {
            this.props.onChange(this.props.id, this.state.value);
        });
    }
    
    onSecondChange(name) {
        const settings = this.state.value;
        for (var i = 0; i < settings.length; i++) {
            if (settings[i]['name'] == name) {
                var index = i;
            }
        }

        if (index !== undefined) {
            var val = settings[index]['required'];
            if (val === "1") {
                settings[index]['required'] = "0";
            } else {
                settings[index]['required'] = "1";
            }
        } else {
            showAToast(plainTranslate(this.props.settings.locale, 'First you must activate the field!'), 'errSettings', 'error');
        }
        
        this.setState({
            settings,
        }, function(){
            this.props.onChange(this.props.id, this.state.value);
        });
    }

     render() {
     return     <div className="col-md-12 no-gutter">
                    <div className="panel panel-default" style={{marginTop: 0}}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th><Translate locale={this.props.settings.locale} value="Field"/></th>
                                    <th><Translate locale={this.props.settings.locale} value="Enable"/></th>
                                    <th><Translate locale={this.props.settings.locale} value="Required"/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.fields && this.props.fields.map((item, key) => (
                                    <DoubleCheckbox
                                        locale={this.props.settings.locale}                                                   
                                        key={key}
                                        name={item}
                                        isChecked={this.state.value && this.state.value.find(cat => cat['name']==[item]) ? true:false}
                                        isSecondChecked ={this.state.value && this.state.value.find(cat => (cat['name']==[item])&&cat['required']==='1') ? true:false}
                                        handleCheckboxChange={this.onChange}
                                        handleSecondCheckboxChange={this.onSecondChange}
                                        />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(FormFields);

