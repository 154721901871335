import React, {Component} from 'react';
import {render} from "react-dom";
import {connect} from 'react-redux';
import RichTextEditor from 'react-rte';
import Select from 'react-select';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';

class MessageContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            message: '',
            isEdit: false,
            length: 0
        };

        this.changeValue = this.changeValue.bind(this);
        this.handleSettingsChange = this.handleSettingsChange.bind(this);
        this.handleReceiverChange = this.handleReceiverChange.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.createActivity = this.createActivity.bind(this);
    }

    changeValue(event) {
        this.setState({value:event.target.value, length: event.target.value.length});
    }
    
    createActivity(accountId) {
        let data = {
            'body': {
                'actionType': "out",
                'description': this.state.value, 
                'typeId': this.props.emailActivityType, 
                'activityDate': moment(new Date()).format('MM-DD-YYYY HH:mm')
            }
          };
        client.post('/' + this.props.match.params.db + '/api/activities/new?accountId=' + accountId, {data})
        .then(response => response.data)
        .then((response) => {
            if ('errors' in response) {
                showAToast(response.errors, 'baseError', 'error');
            }
            else {
                showAToast(response.success, 'baseSuccess', 'success');
            }
        })
    }

    sendMessage(){
        if(this.props.type == 'sms'){
            client.post('/' + this.props.match.params.db + '/api/sms/send', {
                'account': this.props.account.id,
                'body': this.state.value,
                'setting': this.state.setting,
                'receiver': this.state.receiver
            })
            .then(response => response.data)
            .then((response) => {
                if ('errors' in response) {
                    showAToast(response.errors, 'baseError', 'error');
                }
                else {
                    showAToast(response.success, 'baseSuccess', 'success');
                }
            })
        } 
        if(this.props.type == 'email'){
            client.post('/' + this.props.match.params.db + '/api/email/send', {
                'template': this.state.template,
                'account': this.props.account.id,
                'body': this.state.value,
                'setting': this.state.setting,
                'receiver': this.state.receiver,
                'topic': document.getElementById('topic').value
            })
            .then(response => response.data)
            .then((response) => {
                if ('errors' in response) {
                    showAToast(response.errors, 'baseError', 'error');
                }
                else {
                    //this.createActivity(this.props.account.id);
                    showAToast(response.success, 'baseSuccess', 'success');
                }
            })
        }
    }

    handleSettingsChange(value){
        this.setState({'setting': value});
    }

    handleReceiverChange(value){
        this.setState({'receiver': value ? value.value : null});
    }
    handleSelectChange(value){
        let account = this.props.account;
        if(value){
            var body = value.template ? value.template.body : value.body;
            var res = body.replace('{name}', account.name ? account.name : "");
            res=res.replace('{firstName}', account.firstName ? account.firstName : "");
            res=res.replace('{lastName}', account.lastName ? account.lastName : "");
            res=res.replace('{email}', account.email ? account.email : "");
            res=res.replace('{companyName}', account.companyName ? account.companyName : "");
            res=res.replace('{department}', account.department ? account.department : "");
            res=res.replace('{position}', account.position ? account.position : "");
            res=res.replace('{phone}', account.phone ? account.phone : "");
            res=res.replace('{title}', account.title ? account.title : "");
            res=res.replace('{website}', account.website ? account.website : "");
            res=res.replace('{description}', account.description ? account.description : "");
            res=res.replace('{address}', account.addresses ? account.addresses[0].addressLine1 : "");

            this.setState({value: res, template: value ? value.template : null, length: res.length});
        }else{
            this.setState({template: null, value: null})
        }

        
    }
    
    render() {
        let templates = this.props.templates ? this.props.templates : [];
        let smsSettingsOptions = this.props.smsSettingOptions ? this.props.smsSettingOptions : [];
        let emailSettingsOptions = this.props.emailSettingOptions ? this.props.emailSettingOptions : [];
        let settingsOptions = this.props.settingOptions ? this.props.settingOptions : [];
        let accountPhoneNumbers = [];
        let accountEmails = [];
        let enableSave = false;
        if(this.state.setting && this.state.receiver){
            enableSave = true;
        }
        if(this.props.account.phone){
            accountPhoneNumbers.push({label:this.props.account.phone, value:this.props.account.phone});
        }
        if(this.props.account.contacts){
            this.props.account.contacts.map(phone => {
                if(phone.value == 2 || phone.value == 3){
                    let label = phone.label;
                    let value = phone.label;
                    accountPhoneNumbers.push({label:label, value:value});
                }
            })
        }

        if(this.props.account.email){
            accountEmails.push({label:this.props.account.email, value:this.props.account.email});
        }

        if(this.props.account.contacts){
            this.props.account.contacts.map(contact => {
               if(contact.value == 1){
                    let label = contact.label;
                    let value = contact.label;
                    accountEmails.push({label:label, value:value});
               }
            })
        }

        return (
            <React.Fragment>
                <div className='head-line'>
                    <div className="col-md-4 template" style={{paddingLeft: 0, marginBottom: 20}}>
                        <label>Template</label>
                        <Select
                            isClearable
                            options={templates}
                            onChange={this.handleSelectChange}
                            //value={fileHeaders.find(o => o.value === col.value)}
                        />
                    </div>
                   
                        <div className="col-md-4 settings" style={{paddingLeft: 0, marginBottom: 20}}>
                             <label>From</label>
                            {this.props.type ==='sms' &&
                                <Select
                                    isClearable
                                    options={smsSettingsOptions}
                                    onChange={this.handleSettingsChange}
                                    //value={fileHeaders.find(o => o.value === col.value)}
                                />
                            }
                            {this.props.type ==='email' &&
                                <Select
                                    isClearable
                                    options={emailSettingsOptions}
                                    onChange={this.handleSettingsChange}
                                    //value={fileHeaders.find(o => o.value === col.value)}
                                />
                            }
                        </div>
                        <div className="col-md-4 phones" style={{paddingLeft: 0, marginBottom: 20}}>
                            <label>To</label>
                            {this.props.type ==='sms' &&
                                <Select
                                    isClearable
                                    options={accountPhoneNumbers}
                                    onChange={this.handleReceiverChange}
                                    //value={fileHeaders.find(o => o.value === col.value)}
                                />
                            }
                            {this.props.type ==='email' &&
                                <Select
                                    isClearable
                                    options={accountEmails}
                                    onChange={this.handleReceiverChange}
                                    //value={fileHeaders.find(o => o.value === col.value)}
                                />
                        }
                        </div>
                        {this.props.type ==='email' && 
                            <div className="col-md-12 topic"  style={{paddingLeft: 0}}>
                                <label>Topic</label>
                                {!this.state.template &&
                                    <input id="topic" type="text"></input>
                                }
                                {this.state.template && this.state.template.id.length > 0  &&
                                    <input id="topic" type="text" disabled></input>
                                }
                            </div>
                        }
                    <React.Fragment>
                        <textarea id="message-content" className="form-control" onChange={this.changeValue} value={this.state.value}>
                        </textarea>
                    </React.Fragment>
                </div> 
                <span style={{float: 'right'}}>Message length: {this.state.length}</span>
                <button type="button" disabled = {enableSave ? '' : 'disabled'}
                            className={"button-primary " + (enableSave ? '' : 'disabled')} onClick={this.sendMessage}>Send</button>
                <button type="button" className="button-outline grey" onClick={this.props.onCancel}>Cancel</button>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessageContent));

