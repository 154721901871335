import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component { 
 
    
  render() {
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }

    return (
      <div className="backendropss onboard" >    
        <div className="modalS scroll onboarding" >
        <div className="text-right">  
        </div>
          {this.props.children}          
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node
};

export default Modal;