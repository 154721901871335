import {
    FETCH_MAX_LVL,
    FETCH_DEPARTMENT_NAMES,
    DEPARTMENT_NAME_FIELD_UPDATE
} from "../constants/actionTypes";


export default (state = [], action) => {
    switch (action.type) {

    case FETCH_MAX_LVL :
      return {
        ...state,
        level: action.payload.data
      };
      
     case FETCH_DEPARTMENT_NAMES :
      return {
        ...state,
        names: action.payload.data
      };
      
      case DEPARTMENT_NAME_FIELD_UPDATE :
      return {
        ...state,
        names: action.payload.names
      };

        default:
            return state;
    }
};

