import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../common/translate';
import DoubleSelectLine from './doubleSelectLine';


class DoubleSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
           value: []
        };
        this.addNewLine = this.addNewLine.bind(this);
        this.onLineChange = this.onLineChange.bind(this);
        this.removeLine = this.removeLine.bind(this);
    }
    
    componentDidMount() {
        this.setState({
            value: this.props.value
        });
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value
            });
        }
    }

    addNewLine() {
        let value = this.state.value;
        value.push({'stage' : null, 'template': null});
        this.setState({value: value});
    }
    
    onLineChange(id, val, type) {
        let value = this.state.value;
        value[id][type] = val;
        let save = true;
        this.setState({value}, function() {
            let i;
            for (i = 0; i < value.length; i++) {
                let keys = Object.keys(value[i]);
                let n;
                for (n = 0; n < keys.length; n++) {
                    if (value[i][keys[n]] == null) {
                        save = false;
                        break;
                    }
                }           
            }
            if (save) {
                this.props.onSave(value);
            }
        }); 
        
    }
    
    removeLine(id) {
        let value = this.state.value;
        value.splice(id, 1);
        this.setState({value}, function() {
            this.props.onSave(value);
        });
    }

    render() {
        return (
                <React.Fragment>
                    {this.state.value.map((value, key) => {
                        return <DoubleSelectLine
                            key={key}
                            value={value}
                            setting={this.props.setting}
                            secondSetting={this.props.secondSetting}
                            onChange={(value, type) => this.onLineChange(key, value, type)}
                            removeLine={() => this.removeLine(key)}
                        />
                        })
                    }
                    <button type="button" className="button-outline grey pull-right" style={{borderColor: 'grey', color: 'grey'}} onClick={this.addNewLine}>
                                    {plainTranslate(this.props.settings.locale, 'Add email on stage change')}
                    </button>
                </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DoubleSelect);

