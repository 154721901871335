import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Translate, { plainTranslate } from '../common/translate';
import DemoContent from './demoContent';
import Select from 'react-select';
import client from '../../actions/client';

class CountryContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSelectable: false,
            isCountryOpen: true,
            isDemoOpen: false
        };

        this.countries = [
            {value:'Afghanistan',label:'Afghanistan', tax:'10', currency:'Afghan afghani', code:'AFN'},
            {value:'Albania',label:'Albania', tax:'20', currency:'Albanian lek', code:'ALL'},
            {value:'Algeria',label:'Algeria', tax:'19', currency:'Algerian dinar', code:'DZD'},
            {value:'Andorra',label:'Andorra', tax:'4.5', currency:'European euro', code:'EUR'},
            {value:'Angola',label:'Angola', tax:'10', currency:'Angolan kwanza', code:'AOA'},
            {value:'Antigua And Barbuda',label:'Antigua And Barbuda', tax:'15', currency:'East Caribbean dollar', code:'XCD'},
            {value:'Argentina',label:'Argentina', tax:'21', currency:'Argentine peso', code:'ARS'},
            {value:'Armenia',label:'Armenia', tax:'20', currency:'Armenian dram', code:'AMD'},
            {value:'Aruba',label:'Aruba', tax:'1.5', currency:'Aruban florin', code:'AWG'},
            {value:'Australia',label:'Australia', tax:'10', currency:'Australian dollar', code:'AUD'},
            {value:'Austria',label:'Austria', tax:'20', currency:'European euro', code:'EUR'},
            {value:'Azerbaijan',label:'Azerbaijan', tax:'18', currency:'Azerbaijan manat', code:'AZN'},
            {value:'Bahamas',label:'Bahamas', tax:'7.5', currency:'Bahamian dollar', code:'BSD'},
            {value:'Bahrain',label:'Bahrain', tax:'5', currency:'Bahraini dinar', code:'BHD'},
            {value:'Bangladesh',label:'Bangladesh', tax:'15', currency:'Bangladeshi taka', code:'BDT'},
            {value:'Barbados',label:'Barbados', tax:'17.5', currency:'Barbadian dollar', code:'BBD'},
            {value:'Belarus',label:'Belarus', tax:'20', currency:'Belarusian ruble', code:'BYN'},
            {value:'Belgium',label:'Belgium', tax:'21', currency:'European euro', code:'EUR'},
            {value:'Belize',label:'Belize', tax:'12.5', currency:'Belize dollar', code:'BZD'},
            {value:'Benin',label:'Benin', tax:'18', currency:'West African CFA franc', code:'XOF'},
            {value:'Bhutan',label:'Bhutan', tax:'50', currency:'Bhutanese ngultrum', code:'BTN'},
            {value:'Bolivia',label:'Bolivia', tax:'13', currency:'Bolivian boliviano', code:'BOB'},
            {value:'Bosnia and Herzegovina',label:'Bosnia and Herzegovina', tax:'17', currency:'Bosnia and Herzegovina convertible mark', code:'BAM'},
            {value:'Botswana',label:'Botswana', tax:'12', currency:'Botswana pula', code:'BWP'},
            {value:'Brazil',label:'Brazil', tax:'17', currency:'Brazilian real', code:'BRL'},
            {value:'Brunei',label:'Brunei', tax:'18.5', currency:'Brunei dollar', code:'BND'},
            {value:'Bulgaria',label:'Bulgaria', tax:'20', currency:'Bulgarian lev', code:'BGN'},
            {value:'Burkina Faso',label:'Burkina Faso', tax:'18', currency:'West African CFA franc', code:'XOF'},
            {value:'Burundi',label:'Burundi', tax:'18', currency:'Burundi franc', code:'BIF'},
            {value:'Cabo Verde',label:'Cabo Verde', tax:'15', currency:'Cape Verdean Escudo', code:'CVE'},
            {value:'Cambodia',label:'Cambodia', tax:'10', currency:'Cambodian riel', code:'KHR'},
            {value:'Cameroon',label:'Cameroon', tax:'19.25', currency:'Central African CFA franc', code:'XAF'},
            {value:'Canada',label:'Canada', tax:'5', currency:'Canadian dollar', code:'CAD'},
            {value:'Cape Verde',label:'Cape Verde', tax:'15', currency:'Cape Verdean escudo', code:'CVE'},
            {value:'Central African Republic',label:'Central African Republic', tax:'19', currency:'Central African CFA franc', code:'XAF'},
            {value:'Chad',label:'Chad', tax:'18', currency:'Central African CFA franc', code:'XAF'},
            {value:'Chile',label:'Chile', tax:'19', currency:'Chilean peso', code:'CLP'},
            {value:'China',label:'China', tax:'17', currency:'Chinese Yuan Renminbi', code:'CNY'},
            {value:'Colombia',label:'Colombia', tax:'19', currency:'Colombian peso', code:'COP'},
            {value:'Comoros',label:'Comoros', tax:'10', currency:'Comorian franc', code:'KMF'},
            {value:'Congo',label:'Congo', tax:'16', currency:'Congolese franc', code:'CDF'},
            {value:'Congo, Democratic Republic of the',label:'Congo, Democratic Republic of the', tax:'35', currency:'Congolese Franc', code:'CDF'},
            {value:'Congo, Republic of the',label:'Congo, Republic of the', tax:'30', currency:'Communaute Financiere Africaine franc', code:'XAF'},
            {value:'Costa Rica',label:'Costa Rica', tax:'13', currency:'Costa Rican colon', code:'CRC'},
            {value:'Croatia',label:'Croatia', tax:'25', currency:'Croatian kuna', code:'HRK'},
            {value:'Cuba',label:'Cuba', tax:'20', currency:'Cuban peso', code:'CUP'},
            {value:"Cute d'Ivoire",label:"Cute d'Ivoire", tax:'25', currency:' West African CFA franc', code:'CFA'},
            {value:'Cyprus',label:'Cyprus', tax:'19', currency:'European euro', code:'EUR'},
            {value:'Czech Republic',label:'Czech Republic', tax:'21', currency:'Czech koruna', code:'CZK'},
            {value:'Denmark',label:'Denmark', tax:'25', currency:'Danish krone', code:'DKK'},
            {value:'Djibouti',label:'Djibouti', tax:'10', currency:'Djiboutian franc', code:'DJF'},
            {value:'Dominica',label:'Dominica', tax:'15', currency:'East Caribbean dollar', code:'XCD'},
            {value:'Dominican Republic',label:'Dominican Republic', tax:'18', currency:'Dominican peso', code:'DOP'},
            {value:'East Timor (Timor-Leste)',label:'East Timor (Timor-Leste)', tax:'10', currency:'United States dollar', code:'USD'},
            {value:'Ecuador',label:'Ecuador', tax:'12', currency:'United States dollar', code:'USD'},
            {value:'Egypt',label:'Egypt', tax:'14', currency:'Egyptian pound', code:'EGP'},
            {value:'El Salvador',label:'El Salvador', tax:'13', currency:'United States dollar', code:'USD'},
            {value:'Equatorial Guinea',label:'Equatorial Guinea', tax:'15', currency:'Central African CFA franc', code:'XAF'},
            {value:'Eritrea',label:'Eritrea', tax:'5', currency:'Eritrean nakfa', code:'ERN'},
            {value:'Estonia',label:'Estonia', tax:'20', currency:'European euro', code:'EUR'},
            {value:'Ethiopia',label:'Ethiopia', tax:'15', currency:'Ethiopian birr', code:'ETB'},
            {value:'Fiji',label:'Fiji', tax:'9', currency:'Fijian dollar', code:'FJD'},
            {value:'Finland',label:'Finland', tax:'24', currency:'European euro', code:'EUR'},
            {value:'France',label:'France', tax:'20', currency:'European euro', code:'EUR'},
            {value:'Gabon',label:'Gabon', tax:'18', currency:'Central African CFA franc', code:'XAF'},
            {value:'Gambia',label:'Gambia', tax:'15', currency:'Gambian dalasi', code:'GMD'},
            {value:'Georgia',label:'Georgia', tax:'18', currency:'Georgian lari', code:'GEL'},
            {value:'Germany',label:'Germany', tax:'19', currency:'European euro', code:'EUR'},
            {value:'Ghana',label:'Ghana', tax:'15', currency:'Ghanaian cedi', code:'GHS'},
            {value:'Greece',label:'Greece', tax:'24', currency:'European euro', code:'EUR'},
            {value:'Grenada',label:'Grenada', tax:'15', currency:'East Caribbean dollar', code:'XCD'},
            {value:'Guam',label:'Guam', tax:'4', currency:'United States dollar', code:'USD'},
            {value:'Guatemala',label:'Guatemala', tax:'12', currency:'Guatemalan quetzal', code:'GTQ'},
            {value:'Guinea',label:'Guinea', tax:'18', currency:'Guinean franc', code:'GNF'},
            {value:'Guinea-Bissau',label:'Guinea-Bissau', tax:'25', currency:'West African CFA franc', code:'CFA'},
            {value:'Guyana',label:'Guyana', tax:'14', currency:'Guyanese dollar', code:'GYD'},
            {value:'Haiti',label:'Haiti', tax:'10', currency:'Haitian gourde', code:'HTG'},
            {value:'Honduras',label:'Honduras', tax:'15', currency:'Honduran lempira', code:'HNL'},
            {value:'Hungary',label:'Hungary', tax:'27', currency:'Hungarian forint', code:'HUF'},
            {value:'Iceland',label:'Iceland', tax:'24', currency:'Icelandic krona', code:'ISK'},
            {value:'India',label:'India', tax:'18', currency:'Indian rupee', code:'INR'},
            {value:'Indonesia',label:'Indonesia', tax:'10', currency:'Indonesian rupiah', code:'IDR'},
            {value:'Iran',label:'Iran', tax:'9', currency:'Iranian rial', code:'IRR'},
            {value:'Iraq',label:'Iraq', tax:'15', currency:'Iraqi dinar', code:'IQD'},
            {value:'Ireland',label:'Ireland', tax:'23', currency:'European euro', code:'EUR'},
            {value:'Isle Of Man',label:'Isle Of Man', tax:'20', currency:'Manx pound', code:'IMP'},
            {value:'Israel',label:'Israel', tax:'17', currency:'Israeli new shekel', code:'ILS'},
            {value:'Italy',label:'Italy', tax:'22', currency:'European euro', code:'EUR'},
            {value:'Ivory Coast',label:'Ivory Coast', tax:'18', currency:'West African CFA franc', code:'XOF'},
            {value:'Jamaica',label:'Jamaica', tax:'16.5', currency:'Jamaican dollar', code:'JMD'},
            {value:'Japan',label:'Japan', tax:'8', currency:'Japanese yen', code:'JPY'},
            {value:'Jordan',label:'Jordan', tax:'16', currency:'Jordanian dinar', code:'JOD'},
            {value:'Kazakhstan',label:'Kazakhstan', tax:'12', currency:'Kazakhstani tenge', code:'KZT'},
            {value:'Kenya',label:'Kenya', tax:'16', currency:'Kenyan shilling', code:'KES'},
            {value:'Kiribati',label:'Kiribati', tax:'32.7', currency:'Kiribati dollar', code:'KID'},
            {value:'Korea, North',label:'Korea, North', tax:'15', currency:'North Korean won', code:'KPW'},
            {value:'Korea, South',label:'Korea, South', tax:'10', currency:'South Korean won', code:'KRW'},
            {value:'Kosovo',label:'Kosovo', tax:'18', currency:'European euro', code:'EUR'},
            {value:'Kuwait',label:'Kuwait', tax:'15', currency:'Kuwaiti dinar', code:'KWD'},
            {value:'Kyrgyzstan',label:'Kyrgyzstan', tax:'12', currency:'Kyrgyzstani som', code:'KGS'},
            {value:'Laos',label:'Laos', tax:'10', currency:'Lao kip', code:'LAK'},
            {value:'Latvia',label:'Latvia', tax:'21', currency:'European euro', code:'EUR'},
            {value:'Lebanon',label:'Lebanon', tax:'11', currency:'Lebanese pound', code:'LBP'},
            {value:'Lesotho',label:'Lesotho', tax:'30', currency:'Lesotho loti', code:'LSL'},
            {value:'Liberia',label:'Liberia', tax:'15', currency:'Liberian dollar', code:'LRD'},
            {value:'Libya',label:'Libya', tax:'10', currency:'Libyan dinar', code:'LYD'},
            {value:'Liechtenstein',label:'Liechtenstein', tax:'7.7', currency:'Swiss franc', code:'CHF'},
            {value:'Lithuania',label:'Lithuania', tax:'21', currency:'European euro', code:'EUR'},
            {value:'Luxembourg',label:'Luxembourg', tax:'17', currency:'European euro', code:'EUR'},
            {value:'Macedonia',label:'Macedonia', tax:'18', currency:'Macanese pataca', code:'MOP'},
            {value:'Madagascar',label:'Madagascar', tax:'20', currency:'Malagasy ariary', code:'MGA'},
            {value:'Malawi',label:'Malawi', tax:'16.5', currency:'Malawian kwacha', code:'MWK'},
            {value:'Malaysia',label:'Malaysia', tax:'10', currency:'Malaysian ringgit', code:'MYR'},
            {value:'Maldives',label:'Maldives', tax:'30.2', currency:'Maldivian rufiyaa', code:'MVR'},
            {value:'Mali',label:'Mali', tax:'18', currency:'West African CFA franc', code:'XOF'},
            {value:'Malta',label:'Malta', tax:'18', currency:'European euro', code:'EUR'},
            {value:'Marshall Islands',label:'Marshall Islands', tax:'0', currency:'United States dollar', code:'USD'},
            {value:'Mauritania',label:'Mauritania', tax:'16', currency:'Mauritanian ouguiya', code:'MRU'},
            {value:'Mauritius',label:'Mauritius', tax:'15', currency:'Mauritian rupee', code:'MUR'},
            {value:'Mexico',label:'Mexico', tax:'16', currency:'Mexican peso', code:'MXN'},
            {value:'Micronesia',label:'Micronesia', tax:'5', currency:'United States dollar', code:'USD'},
            {value:'Moldova',label:'Moldova', tax:'20', currency:'Moldovan leu', code:'MDL'},
            {value:'Monaco',label:'Monaco', tax:'20', currency:'European euro', code:'EUR'},
            {value:'Mongolia',label:'Mongolia', tax:'10', currency:'Mongolian tugrik', code:'MNT'},
            {value:'Montenegro',label:'Montenegro', tax:'21', currency:'European euro', code:'EUR'},
            {value:'Morocco',label:'Morocco', tax:'20', currency:'Moroccan dirham', code:'MAD'},
            {value:'Mozambique',label:'Mozambique', tax:'17', currency:'Mozambican metical', code:'MZN'},
            {value:'Myanmar',label:'Myanmar', tax:'5', currency:'Mozambican metical', code:'MZN'},
            {value:'Namibia',label:'Namibia', tax:'15', currency:'Namibian dollar', code:'NAD'},
            {value:'Nauru',label:'Nauru', tax:'18', currency:'Australian dollar ', code:'AUD'},
            {value:'Nepal',label:'Nepal', tax:'13', currency:'Nepalese rupee', code:'NPR'},
            {value:'Netherlands',label:'Netherlands', tax:'21', currency:'European euro', code:'EUR'},
            {value:'New Zealand',label:'New Zealand', tax:'15', currency:'New Zealand dollar', code:'NZD'},
            {value:'Nicaragua',label:'Nicaragua', tax:'15', currency:'Nicaraguan cordoba', code:'NIO'},
            {value:'Niger',label:'Niger', tax:'19', currency:'West African CFA franc', code:'XOF'},
            {value:'Nigeria',label:'Nigeria', tax:'5', currency:'Nigerian naira', code:'NGN'},
            {value:'Norway',label:'Norway', tax:'25', currency:'Norwegian krone', code:'NOK'},
            {value:'Oman',label:'Oman', tax:'0', currency:'Omani rial', code:'OMR'},
            {value:'Pakistan',label:'Pakistan', tax:'17', currency:'Pakistani rupee', code:'PKR'},
            {value:'Palau',label:'Palau', tax:'0', currency:'United States dollar', code:'USD'},
            {value:'Panama',label:'Panama', tax:'7', currency:'United States dollar', code:'USD'},
            {value:'Papua New Guinea',label:'Papua New Guinea', tax:'10', currency:'Papua New Guinean kina', code:'PGK'},
            {value:'Paraguay',label:'Paraguay', tax:'10', currency:'Paraguayan guarani', code:'PYG'},
            {value:'Peru',label:'Peru', tax:'18', currency:'Peruvian sol', code:'PEN'},
            {value:'Philippines',label:'Philippines', tax:'12', currency:'Philippine peso', code:'PHP'},
            {value:'Poland',label:'Poland', tax:'23', currency:'Polish zloty', code:'PLN'},
            {value:'Portugal',label:'Portugal', tax:'23', currency:'European euro', code:'EUR'},
            {value:'Qatar',label:'Qatar', tax:'0', currency:'Qatari riyal', code:'QR'},
            {value:'Romania',label:'Romania', tax:'19', currency:'Romanian leu', code:'RON'},
            {value:'Russia',label:'Russia', tax:'18', currency:'Russian ruble', code:'RUB'},
            {value:'Rwanda',label:'Rwanda', tax:'18', currency:'Rwandan franc', code:'RWF'},
            {value:'Saint Kitts and Nevis',label:'Saint Kitts and Nevis', tax:'0', currency:'Eastern Caribbean dollar', code:'XCD'},
            {value:'Saint Lucia',label:'Saint Lucia', tax:'12.5', currency:'Eastern Caribbean dollar', code:'XCD'},
            {value:'Saint Vincent and the Grenadines',label:'Saint Vincent and the Grenadines', tax:'15', currency:'Eastern Caribbean dollar', code:'XCD'},
            {value:'Samoa',label:'Samoa', tax:'27', currency:'Samoan tālā', code:'SAT'},
            {value:'San Marino',label:'San Marino', tax:'9.5', currency:'European euro', code:'EUR'},
            {value:'Sao Tome and Principe',label:'Sao Tome and Principe', tax:'20', currency:'São Tomé and Príncipe dobra', code:'STD'},
            {value:'Saudi Arabia',label:'Saudi Arabia', tax:'5', currency:'Saudi Arabian riyal', code:'SAR'},
            {value:'Senegal',label:'Senegal', tax:'18', currency:'West African CFA franc', code:'XOF'},
            {value:'Serbia',label:'Serbia', tax:'20', currency:'Serbian dinar', code:'RSD'},
            {value:'Seychelles',label:'Seychelles', tax:'15', currency:'Seychellois rupee', code:'SCR'},
            {value:'Sierra Leone',label:'Sierra Leone', tax:'15', currency:'Sierra Leonean leone', code:'SLL'},
            {value:'Singapore',label:'Singapore', tax:'7', currency:'Singapore dollar', code:'SGD'},
            {value:'Slovakia',label:'Slovakia', tax:'20', currency:'European euro', code:'EUR'},
            {value:'Slovenia',label:'Slovenia', tax:'22', currency:'European euro', code:'EUR'},
            {value:'Solomon Islands',label:'Solomon Islands', tax:'10', currency:'Solomon Islands dollar', code:'SBD'},
            {value:'Somalia',label:'Somalia', tax:'10', currency:'Somali shilling', code:'SOS'},
            {value:'South Africa',label:'South Africa', tax:'15', currency:'South African rand', code:'ZAR'},
            {value:'Spain',label:'Spain', tax:'21', currency:'European euro', code:'EUR'},
            {value:'Sri Lanka',label:'Sri Lanka', tax:'15', currency:'Sri Lankan rupee', code:'LKR'},
            {value:'St Lucia',label:'St Lucia', tax:'12.5', currency:'Sri Lankan rupee', code:'LKR'},
            {value:'Sudan',label:'Sudan', tax:'15', currency:'Sudanese pound', code:'SDN'},
            {value:'Suriname',label:'Suriname', tax:'10', currency:'Surinamese dollar', code:'SRD'},
            {value:'Swaziland',label:'Swaziland', tax:'14', currency:'Norwegian krone', code:'NOK'},
            {value:'Sweden',label:'Sweden', tax:'25', currency:'Swedish krona', code:'SEK'},
            {value:'Switzerland',label:'Switzerland', tax:'7.7', currency:'Swiss franc', code:'CHF'},
            {value:'Syria',label:'Syria', tax:'22', currency:'Syrian pound', code:'SYP'},
            {value:'Taiwan',label:'Taiwan', tax:'5', currency:'New Taiwan dollar', code:'TWD'},
            {value:'Tajikistan',label:'Tajikistan', tax:'18', currency:'Tajikistani somoni', code:'TJS'},
            {value:'Tanzania',label:'Tanzania', tax:'18', currency:'Tanzanian shilling', code:'TZS'},
            {value:'Thailand',label:'Thailand', tax:'7', currency:'Thai baht', code:'THB'},
            {value:'Togo',label:'Togo', tax:'18', currency:'West African CFA franc', code:'XOF'},
            {value:'Tonga',label:'Tonga', tax:'15', currency:'Tongan pa’anga', code:'TOP'},
            {value:'Trinidad And Tobago',label:'Trinidad And Tobago', tax:'12.5', currency:'Trinidad and Tobago dollar', code:'TTD'},
            {value:'Tunisia',label:'Tunisia', tax:'18', currency:'Tunisian dinar', code:'TND'},
            {value:'Turkey',label:'Turkey', tax:'18', currency:'Turkish lira', code:'TRY'},
            {value:'Turkmenistan',label:'Turkmenistan', tax:'15', currency:'Turkmen manat', code:'TMT'},
            {value:'Tuvalu',label:'Tuvalu', tax:'30', currency:'Tuvaluan dollar', code:'TVD'},
            {value:'Uganda',label:'Uganda', tax:'18', currency:'Ugandan shilling', code:'UGX'},
            {value:'Ukraine',label:'Ukraine', tax:'20', currency:'Ukrainian hryvnia', code:'UAH'},
            {value:'United Arab Emirates',label:'United Arab Emirates', tax:'5', currency:'UAE dirham', code:'AED'},
            {value:'United Kingdom',label:'United Kingdom', tax:'20', currency:'Pound sterling', code:'GBP'},
            {value:'United States of America',label:'United States of America', tax:'0', currency:'United States dollar', code:'USD'},
            {value:'Uruguay',label:'Uruguay', tax:'22', currency:'Uruguayan peso', code:'UYU'},
            {value:'Uzbekistan',label:'Uzbekistan', tax:'20', currency:'Uzbekistani som', code:'UZS'},
            {value:'Vanuatu',label:'Vanuatu', tax:'15', currency:'Vanuatu vatu', code:'VUV'},
            {value:'Vatican City',label:'Vatican City', tax:'0', currency:'European euro', code:'EUR'},
            {value:'Venezuela',label:'Venezuela', tax:'12', currency:'Venezuelan bolivar', code:'VES'},
            {value:'Vietnam',label:'Vietnam', tax:'10', currency:'Vietnamese dong', code:'VND'},
            {value:'Yemen',label:'Yemen', tax:'5', currency:'Yemeni rial', code:'YER'},
            {value:'Zambia',label:'Zambia', tax:'16', currency:'Zambian kwacha', code:'ZMW'},
            {value:'Zimbabwe',label:'Zimbabwe', tax:'15', currency:'United States dollar', code:'USD'},
        ];

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEv = this.handleChangeEv.bind(this);
        this.goToDemo = this.goToDemo.bind(this);
    }

    goToDemo() {
        client
        .post('/' + this.props.db + '/api/onboarding/save/country_tax/data', {
            'country': this.state.country,
            'tax': this.state.tax,
            'currency': this.state.currency,
            'code': this.state.code
        })
        .then(res => {
            this.setState({isCountryOpen: false});
            this.setState({isDemoOpen: true});             
        })
        .catch(err => {               
        });
        
/*
        $.ajax({
            method: 'POST',
            async: false,
            data: {
                'country': this.state.country,
                'tax': this.state.tax,
                'currency': this.state.currency,
                'code': this.state.code
            },
            url: '/' + this.props.db + '/onboarding/save/country_tax/data',
            success: function (response) {
                this.setState({isCountryOpen: false});
                this.setState({isDemoOpen: true});
                
            }.bind(this)
        });*/

    }

    handleChange(prop, selectedOption) {
        if (prop == 'country') {
            let country = selectedOption.value;
            let tax;
            let currency;
            let code;

            this.countries.forEach(function (x) {
                if (x.value == country) {
                    tax = x.tax;
                    currency = x.currency;
                    code = x.code;
                }
            })

            this.setState({
                showSelectable: true,
                country: country,
                tax: tax,
                currency: currency,
                code: code
            });
        }
    }
    handleChangeEv(prop, e) {
        if (prop == 'currency') {
            let curr;
            this.countries.forEach(function (x) {
                if (x.code == e.target.value) {
                    curr = x.currency;
                }
            })
            this.setState({
                code: e.target.value,
                currency: curr,
            });
        }
        if (prop == 'tax') {
            let val;
            if (isNaN(e.target.value)) {
                val = 0;
            } else {
            val = e.target.value;
        }
            this.setState({
                tax: val,
            });
        }
    }

    render() {
        return (
                <div>
                    <div className={this.state.isCountryOpen ? " " : "hidden"}>
                        <div>
                            <div className="col-md-12 col-xs-12 text-center m-bot50">
                                <span className="dot">✓</span>
                                <div className="rectangle"></div>
                                <span className="dot">2</span>
                                <div className="rectangle"></div>
                                <span className="dot">3</span>
                            </div>
                            <div className="col-md-12 col-xs-12 onboarding-selectable">
                                <div className="col-md-6 col-xs-12 text-center">
                                    <h4><Translate locale={this.props.locale} value="Where is your business located?" /></h4>
                                    <Select
                                        onChange={(selectedOption) => this.handleChange('country', selectedOption)}
                                        options={this.countries}
                                        placeholder={plainTranslate(this.props.locale, '-Select a country-')}
                                        >  
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {this.state.showSelectable &&
                                    <div className="col-md-12 col-xs-12 onboarding-modules">
                                        <div className='onboarding-modules-arrow'></div>
                                        <div className="col-md-12 text-center">
                                            <h4><Translate locale={this.props.locale} value="Based on your selection:" /></h4>
                                            <h6><Translate locale={this.props.locale} value="If the tax and currency are not the ones you want, you can adjust them now or later in the platform" /></h6>
                                        </div>
                                        <div className="col-md-12 col-xs-12 onboarding-modules-all">
                                            <div className="col-md-8 col-xs-12 column-centered m-bot50">
                                                <label><Translate locale={this.props.locale} value="Sales tax %:" /></label>
                                                <input className="text-right" type="text" id="initial-tax"
                                                       onChange={(e) => this.handleChangeEv('tax', e)}
                                                       value={this.state.tax}
                                                       />
                                            </div>
                                            <div className="col-md-8 col-xs-12 column-centered">
                                                <label><Translate locale={this.props.locale} value="Currency:" /></label>
                                                <select className="form-control" id="initial-currency"
                                                        value={this.state.code}
                                                        onChange={(e) => this.handleChangeEv('currency', e)}
                                                        >
                                                    {this.countries.map((val, ind) => (
                                                                <option key={ind} value={val.code}>{val.currency}</option>
                                                                            ))}
                                                </select>
                                            </div>
                                        </div>
                                        <button className="button-primary pull-right text-uppercase"
                                                onClick={this.goToDemo}
                                                ><Translate locale={this.props.locale} value="Next" />
                                        </button>
                                    </div>
                        }
                    </div>
                    <div className={this.state.isDemoOpen ? " " : "hidden"}>
                        <DemoContent
                            db={this.props.db}
                            locale={this.props.locale}
                            closeModal={this.props.closeModal}
                            />
                    </div>
                </div>
                );
    }
}

export default CountryContent;


