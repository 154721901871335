import React from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';


class Select2 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: []
        };

        this.onChange = this.onChange.bind(this);
        this.getSelectedByValue = this.getSelectedByValue.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
            this.setState({
                value: this.props.value
            });
        }
    }

    getSelectedByValue(value) {
        let selected = null;
        this.props.options.forEach(option => {
            if (option.value === value) {
                selected = option;
            }
        });
        return selected;
    }

    onChange(newValue, actionMeta) {
        //this.props.loseFocus(this.props.id);
        //this.props.onChange(this.props.id, newValue ? newValue.value : '');
        this.props.onChange(newValue);
    }

    render() {
        return <Select
            closeMenuOnSelect={!this.props.closeOnSelect}
            value={this.state.value}
            onChange={this.onChange}
            options={this.props.options}
            isMulti={this.props.isMulti}
        />
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Select2);
