import React from 'react';
import {connect} from 'react-redux';
import "../redux-forms/types/tableCollection.css";
import Text from "../redux-forms/types/text";
import {plainTranslate} from "../common/translate";
import {onUploadFileGetColumns} from "../../actions/actions";
import {Field as FormField, reduxForm, FieldArray} from 'redux-form';
import Select from 'react-select';
import Checkbox from '../settings/checkbox.jsx';
import {Link} from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import {generatePaymentsReportHTML} from '../../actions/actions';
import { withRouter } from 'react-router-dom'

class PaymentsTemplate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lines:[],
            errors: [],
            enableSave:false
        };

    }

    componentDidMount() {
        this.props.generatePaymentsReportHTML(this.props.match.params.db, this.props.data.balance, this.props.data.lines, this.props.data.startingBalance, this.props.data.paymentMethods, this.props.data.locIds, this.props.data.startDate, this.props.data.endDate, this.props.data.cashInflow, this.props.data.cashOutflow);
    }

    render() {
        let html = this.props.reportHTML.html ? this.props.reportHTML.html : [];
        if(html && html.data){
            document.title = 'Payments Report '+ html.data.startDate + '-' + html.data.endDate;
        }
        return(

        <div>
            {html && html.data &&
                <div style={{backgroundColor: 'white'}} dangerouslySetInnerHTML={{ __html: html.data.html }} />
            }
        </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
    reportHTML: state.report,
});

const mapDispatchToProps = dispatch => ({
    generatePaymentsReportHTML: (db, balance, lines, startingBalance, paymentMethods, locIds, startDate, endDate, cashInflow, cashOutflow) => dispatch(generatePaymentsReportHTML(db, balance, lines, startingBalance, paymentMethods, locIds, startDate, endDate, cashInflow, cashOutflow))
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentsTemplate));
