import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../common/translate";
import './import.css';
import { Radio, Select, Button, Drawer,Statistic, Card, Row, Col, Tooltip, Table } from 'antd';
import client from '../../actions/client';
import showAToast from '../../components/common/showAToast';
import SaveCancel from '../common/saveCancelButtons';
import {onUploadFile} from "../../actions/actions";

const importTypes = [
    {
      value: 'address',
      label: 'Address',
    },
    {
      value: 'accounts',
      label: 'Accounts',
    },
    {
      value: 'accountAddress',
      label: 'Account Address',
    },
    {
      value: 'billingData',
      label: 'Billing Data',
    },
    {
      value: 'assets',
      label: 'Assets',
    },
    {
      value: 'content',
      label: 'Content',
    },
    {
      value: 'collectionContent',
      label: 'Collection Content',
    },
    {
      value: 'collectionElement',
      label: 'Collection Element',
    },
    {
      value: 'files',
      label: 'Files',
    },
    {
      value: 'fileShares',
      label: 'File Shares',
    },
    {
      value: 'items',
      label: 'Items',
    },
    {
      value: 'itemAttributes',
      label: 'Item Attributes',
    },
    {
      value: 'itemAvailability',
      label: 'Item Availability',
    },
    {
      value: 'itemBillOfMaterials',
      label: 'Item Bill Of Materials',
    },
    {
      value: 'itemLocations',
      label: 'Item Location',
    },
    {
      value: 'itemMeasures',
      label: 'Item Measure',
    },
    {
      value: 'itemRelations',
      label: 'Item Relations',
    },
    {
      value: 'ledgerAccount',
      label: 'Ledger Account',
    },
    {
      value: 'measures',
      label: 'Measures',
    },
    {
      value: 'priceLists',
      label: 'Price Lists',
    },
    {
      value: 'projects',
      label: 'Projects',
    },
    {
      value: 'tasks',
      label: 'Tasks',
    },
    {
      value: 'tradeDiscounts',
      label: 'Trade Discounts',
    },
    {
      value: 'translations',
      label: 'Translations',
    }
];


class Import extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            enableSave:true
        };

        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.handleImport = this.handleImport.bind(this);
        this.onImportTypeChange = this.onImportTypeChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.showDrawer = this.showDrawer.bind(this);
        this.onClose = this.onClose.bind(this);
        this.showUploadModal = this.showUploadModal.bind(this);
        this.chooseFile = this.chooseFile.bind(this);
        this.uploadFile = this.uploadFile.bind(this);

    }

    componentDidMount() {
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

    onChange(e) {
        this.setState({ value: e.target.value});
    }

    save(){
        var data = this.state.value;
        var rows = data.split("\n");

        var table = '<table style="width: 100%;background-color:white"/><thead id="headers" style="position: sticky;top: 0;background-color: white;">';
        var items = [];
        var error = null;
        var index = 0;


        var tableHeaders = rows[0].split("\t");

        let defaultCols = this.state.columns.filter(item => item.defaultValue === true);
        tableHeaders.map(value => {
            defaultCols = defaultCols.filter(item => item.label !== value)
        })

        defaultCols.map(value => {
            tableHeaders.push(value.label);
            rows.map((row,index) => {
                if(index > 0){
                    rows[index] = rows[index].concat("\tDefault Value");
                }
            })
        })

        for(var i = 0; i < tableHeaders.length; i++){
            table+='<th><select style="min-width: 100px!important; width: 100%" class="form-control" id="headerOption'+i+'"><option value=""></option>';
            this.state.columns.map(column =>{
                table+= '<option ' + (tableHeaders[i] === column.label ? 'selected' : '') + ' value="'+ column.value +'">'+column.label+'</option>';
            })
            
            table += '</select></th>';
        }

        table+='</thead>';
        for(var value in rows) {
            if(index > 0){
                var cells = rows[value].split("\t");
                var row = '<tr id="row'+index+'" />';

                if(cells.length == tableHeaders.length){
                    for(var x in cells) {
                        row+='<td style="width: 50px!important; height: 40px; text-align: center; padding:5px">'+cells[x]+'</td>';
                    }
                }

                table+=row;
            }

            index++;

            if (index === 50) { break; }
        }

        this.setState({table: table, showImportResult: false});
    
    }

    handleImport(){
        this.setState({ enableSave: false});
        var data = this.state.value;
        var rows = data.split("\n");
        let emptyRow = rows.slice(-1).filter(element => element !== "" && element !== "\t");

        if(emptyRow.length === 0){
            rows.pop();
        }
        let index = 0;
        let rowIndex = 0;
        let itemsArray = [];
        var items = [];
        var selectedHeaders = [];
        let i = 0;  

        var tableHeaders = rows[0].split("\t");

        let defaultCols = this.state.columns.filter(item => item.defaultValue === true);
        tableHeaders.map(value => {
            defaultCols = defaultCols.filter(item => item.label !== value)
        })

        defaultCols.map(value => {
            tableHeaders.push(value.label);
            rows.map((row,index) => {
                if(index > 0){
                    rows[index] = rows[index].concat("\tDefault Value");
                }
            })
        })
        for(var y in tableHeaders){
            var id = 'headerOption'+y;
            var a = document.getElementById(id);
            selectedHeaders.push(a.value);  
        }
        for(var value in rows) {
            if(i > 0){
                if(rows[value].length > 0){
                    var cells = rows[value].split("\t");

                    var obj = {};
                    selectedHeaders.map((header,key) => {
                        if(header){
                            obj[header] = cells[key];
                        }
                    })
                    items.push(obj);
                }
            }
            i++;

        }

        var body = new FormData();

        var currentdate = new Date(); 
        let fileName =  this.state.importDefinition + ' ' + currentdate.getDate() + "-"
                + (currentdate.getMonth()+1)  + "-" 
                + currentdate.getFullYear() + "-"  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();

        if(this.state.file){
            body.append('file', this.state.file, fileName);
        }
        body.append('importType', this.state.importType);
        body.append('items', JSON.stringify(items));

        client.post('/' + this.props.settings.db + '/api/import/'+this.state.importDefinition, body)
        .then(response => response.data)
        .then(response => {
            this.setState({ enableSave: true});
            if ('errors' in response) {
               showAToast(response.errors, 'baseError', 'error');
            }
            else {
                showAToast('Success!', 'baseSuccess', 'success');
                this.setState({ showImportResult: true, importResult: response.importResult })
            }
        })
        .catch(error => { this.setState({ enableSave: true }) });
    }

    onImportTypeChange(e){
        this.setState({
          importType: e.target.value,
        });
    }

    onSelectChange(value){
        this.setState({loading: true});
        client.get('/' + this.props.match.params.db + '/api/import/'+value+'/columns')
        .then(response => response.data)
        .then(response => {
            this.setState({ columns: response['columns'], loading: false, templateLink: response['templateLink']});
        })

        this.setState({ importDefinition: value });
    }

    showDrawer(e) {
        this.setState({ open: true});
    }

    onClose(e) {
        this.setState({ open: false});
    }

    showUploadModal() {
        this.setState({showUploadModal: !this.state.showUploadModal});
    }

    chooseFile() {
        document.getElementById('fileUpload').click();
    }

    uploadFile(e) {
        const file = e.target.files[0];

        this.setState({loading: true});

        var body = new FormData();

        let index = importTypes.findIndex(value => value.value === this.state.importDefinition);

        body.append('file', file);
        body.append('importType', importTypes[index ? index : 0]['label']);
        

        this.setState({uploadLoading: true});

        client
        .post('/'+this.props.match.params.db+"/api/import-data/read-file", body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
             showAToast('Upload success!', 'successUpload', 'success');

            this.setState({file: file, value: res.data, showUploadModal: false, loading: false})
        })
        .catch(err => {
            showAToast("Error!", 'baseError', 'error')
        });
    }


    render() {

        let cols = [];

        let keys = this.state.importResult && this.state.importResult.data ? this.state.importResult && Object.keys(this.state.importResult.data[0]) : [];

        keys.map(row => {
            let arr = this.state.columns;
            arr = arr.filter(item => item.value === row);

            cols.push({title: arr[0] ? arr[0].label : row, dataIndex: row, key: row });
        })

        return <div>
            <div>
                <div style={{display: 'grid', margin: "0 auto", width: 900}}>
                    <h5 style={{fontWeight: 'bold', marginTop: 20}}>Import Definition</h5>
                    <Select
                      style={{ width: 120 }}
                      onChange={ this.onSelectChange}
                      options={importTypes}
                    />
                    <span>
                        <Button type="primary" shape="round" onClick={this.showDrawer} style={{width: 140, marginTop: 20}} loading={this.state.loading}>
                            {plainTranslate(this.props.settings.locale, 'Show columns')}
                        </Button>
                        {this.state.templateLink &&
                            <Button type="primary" shape="round" style={{width: 140, marginTop: 20, marginLeft: 20}} href={this.state.templateLink} target="_blank">
                                {plainTranslate(this.props.settings.locale, 'Import Template')}
                            </Button>
                        }
                    </span>

                    <span style={{marginTop: 15, fontWeight: 'bold'}}>{plainTranslate(this.props.settings.locale, 'Import type')}</span>
                    <Radio.Group onChange={this.onImportTypeChange}>
                        <Radio value={'importData'}>
                          {plainTranslate(this.props.settings.locale, 'Import new data')}
                        </Radio>
                        <Radio value={'importAndUpdate'}>
                          {plainTranslate(this.props.settings.locale, 'Import and update')}
                        </Radio>
                    </Radio.Group>
                <textarea style={{marginTop: 15}} className="form-control" onChange={this.onChange} placeholder="Paste your data here"></textarea>

                <div className="col-md-12" style={{marginBottom: 10}}>
                    <button type="button" className="upload-btn btn btn-success"
                            onClick={() => this.showUploadModal()}>
                        <i className="fa fa-upload"></i>
                        {plainTranslate(this.props.settings.locale, 'Upload')}
                    </button>
                    {this.state.value &&
                        <span>{this.state.value.name}</span>
                    }
                </div>
                
                <button style={{width: "100px", marginLeft: 0}} disabled = {this.state.enableSave ? '' : 'disabled'} type="button" className={"button-primary " + (this.state.enableSave ? '' : 'disabled')} id="save" onClick={this.save}>Save</button>
                </div>
            </div>
            {this.state.table &&
                <div style={{width: "90%", margin: "auto"}}>
                    <h3>Preview Import Data</h3>
                    <div style={{overflow: "auto", maxHeight: 450, width: "100%"}} id="preview-table" dangerouslySetInnerHTML={{ __html: this.state.table }}></div>
                    <button disabled = {this.state.enableSave ? '' : 'disabled'} type="button" className={"button-primary " + (this.state.enableSave ? '' : 'disabled')} id="import" onClick={this.handleImport}>Import</button>
                </div>
            }
            {this.state.showImportResult &&
                <div style={{margin: '100px auto', width: '90%', outline: 'none'}}>
                    <h3>Import Result</h3>
                    <Row gutter={8} style={{marginBottom: 20}}>
                        <Col span={4}>
                            <Card>
                              <Statistic
                                title={plainTranslate(this.props.settings.locale, "Rows count")}
                                value={this.state.importResult.rowsCount}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                              />
                            </Card>
                        </Col>
                        <Col span={4}>
                            <Card>
                              <Statistic
                                title={plainTranslate(this.props.settings.locale, "Inserted records")}
                                value={this.state.importResult.insertedRows}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                              />
                            </Card>
                        </Col>
                        <Col span={4}>
                            <Card>
                              <Statistic
                                title={plainTranslate(this.props.settings.locale, "Uninserted records")}
                                value={this.state.importResult.rowsCount - this.state.importResult.insertedRows}
                                precision={0}
                                valueStyle={{ color: '#cf1322' }}
                              />
                            </Card>
                        </Col>
                    </Row>
                    <Table dataSource={this.state.importResult.data} columns={cols} />
                </div>

            }

            {
                this.state.showUploadModal &&
                <div className={`backendropss`}>
                    <div className={'modalConfirms scroll input-board ' + (this.state.dragging ? 'beige' : '')}
                         ref={this.setWrapperRef}>
                        <div className="text-right">
                            <div className="" onClick={this.showUploadModal}>
                                <i className="fa fa-close" aria-hidden="true"></i>
                            </div>
                        </div>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <div className="text-center">
                                        {this.state.loading && 'Loading...'} 
                                    </div>
                                    <button type="button" className={'upload-btn btn btn' + (this.state.loading ? ' disabled' : '-success') + ' upload-button'}
                                            onClick={this.chooseFile}>
                                        <i className="fa fa-upload"></i>
                                        Choose
                                    </button>
                                    <input id={'fileUpload'} name="files[]" type="file" style={{visibility: 'hidden'}}
                                           onChange={this.uploadFile} multiple/>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            }

            <Drawer title="Columns" placement="right" onClose={this.onClose} visible={this.state.open} bodyStyle={{backgroundColor: "white"}}>
                <ul>
                    {this.state.columns && this.state.columns.map(col =>{
                        return <li style={{margin: "10px 0px"}}> {col.label} {col.description && <Tooltip placement="bottomRight" title={<span dangerouslySetInnerHTML={{__html: col.description}}></span>}><i className="fa fa-info-circle"></i></Tooltip>}</li>
                    
                    })}
                </ul>
            </Drawer>
        </div>
    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onUploadFile: (db, id, file) => dispatch(onUploadFile(db, id, file))
});

export default connect(mapStateToProps, mapDispatchToProps)(Import);