import React, {Component} from 'react';

class InOutRenderer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let icon = '';
        if(this.props.value) {
            if(this.props.value === '1') icon = 'ag-icon ag-icon-desc';
            else if(this.props.value === '2') icon = 'ag-icon ag-icon-asc';
        }

        return (
            <span>
                    <i className={icon}></i>
            </span>);
    }
}

export default InOutRenderer;
