import React, {Component} from 'react';
import Translate, {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';
import {onFieldSave} from "../../../../actions/actions";
import Popup from '../../../common/popup';
import {ACCOUNT_PROFILE_DELETE_TABLE_DATA, ACCOUNT_EDIT_TASK, SALE_CHANGE_STAGE} from "../../../../constants/actionTypes";
import {Link} from 'react-router-dom';
import SlideModal from '../../../common/SlideModal';
import SimpleForm from "../../../forms/form";
import { withRouter } from 'react-router-dom';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';

class Table extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            data: [],
            isOpenTasks: false,
            taskId: false,
            count: 1,
            loadMore: false
        };

        this.dropdownChange = this.dropdownChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.updateTasks = this.updateTasks.bind(this);
        this.addRow = this.addRow.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount() {
        this.setState({
            columns: this.props.columns ? this.props.columns : [],
            data: this.props.data ? this.props.data : 0,
            dataLength: this.props.data ? this.props.data.length : 0
        });

        if(this.props.loadMore && this.props.data.length > 9){
            this.setState({loadMore: true});
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.columns) !== JSON.stringify(this.props.columns) || JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState({
                columns: this.props.columns,
                data: this.props.data
            });
        }
    }

    dropdownChange(e) {
        const location = e.target.value;
        if(location !== '0') {
            window.open(location, '_blank');
        }
    }

    handleDelete(url, data) {
        client
        .post(url)
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(response.errors, 'baseError', 'error');
            }
            else {
                this.props.onDeleteTableData(data);
                showAToast(response.success, 'baseSuccess', 'success');
            }
        });

        this.setState({
            deletePopup: false,
            deleteData: false
        });
    }

    handleClick(url,id, action){

        if(action === 'delete'){
            client
            .get(url)
            .then(response => response.data)
            .then(response => {
                if ('errors' in response) {
                    showAToast(response.errors, 'baseError', 'error');
                }
                else {
                    showAToast(response.success, 'baseSuccess', 'success');
                }
            });
        } else {
            client
            .post(url, {
                'body': id.value,
            })
            .then(response => response.data)
            .then(response => {
                if ('errors' in response) {
                    showAToast(response.errors, 'baseError', 'error');
                }
                else {
                    if ('sale' in response) {
                        this.props.onChangeSaleStage(response['sale'], response['stage']);
                    }
                    showAToast(response.success, 'baseSuccess', 'success');
                }
            });
        }
    }

    updateTasks(data) {
        let result = {
             'id' : data['id'],
             'name' : {'type' : 'link', 'value' : data['name'], 'id' : data['id'], 'url' : 'popup', 'form' : data['form']},
             'status' : {'type' : 'text', 'value' : data['status']},
             'employee' : {'type' : 'text', 'value' : data['employee']},
             'endDate' : {'type' : 'text', 'value' : data['endDate']},
             'startDate' : {'type' : 'text', 'value' : data['startDate']},
             'completion' : {'type' : 'text', 'value' : data['completion']},
        };

        this.props.onEditTask(result);
        this.setState({isOpenTask: false, taskId: false});
    }

    addRow(row) {
        this.props.onAddRow(row);
    }

    loadMore(){
        let count = this.state.count;
        count++;
        this.setState({count: count});

        client
        .post('/' + this.props.match.params.db + '/api/accounts/'+this.props.additionalData.accountId+'/table/'+this.props.type+'/data', {
            page: count
        })
        .then(response => response.data)
        .then(response => {
            let res = response.data.length%10;
            this.setState({
                data: response.data,
                showLoader : false,
                loadMore: res === 0 ? true : false
            })
        })
    }

    render() {
        let data = this.state.data ? this.state.data : this.props.data;

        return (
           <React.Fragment>
        {this.props.columns ?
            <section id="no-more-tables" className="panel">
                <table className="table">
                    <thead>
                    <tr>
                        {this.props.addButton &&
                                <td className="left tableHeader white"></td>
                        }
                        {
                            this.props.columns && this.props.columns.map((column, key) => <td className = {column.styleName}
                                key={key}>{plainTranslate(this.props.settings.locale, column.title)}</td>)
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data && data.map((row, key) => {
                            return this.props.columns ?
                                <tr key={key}>{this.props.addButton && <td style={{border: 0}}><i className="fa fa-plus-circle" onClick={() => this.addRow(row)}></i></td>}
                                {this.props.columns.map((column, ckey) => {
                                    if (!(column.field in row)) return <td key={ckey}></td>;

                                    const field = row[column.field];

                                    if (field.type === 'text') return <td className={field.styleName ? field.styleName : ''} key={ckey} style={{minWidth: 130}}>
                                        <span className={field.className ? field.className : ''}>{field.value}</span>
                                    </td>;
                                    else if (field.type === 'icon') return <td key={ckey}>
                                        <i className={field.value} aria-hidden="true"></i>
                                    </td>;
                                    else if (field.type === 'link')
                                        return <td key={ckey} style={{minWidth: 120}} className={field.styleName ? field.styleName : ''}>
                                                {column.field === 'actions' ?
                                                    field.buttons.map(button =>{

                                                            if(button.type === 'link' && button.url !== 'popup'){
                                                                return <Link to={button.url} title={button.title}>
                                                                    {button.icon ?
                                                                        <i className={button.value} aria-hidden="true" style={{marginLeft: '7px'}}></i> : button.value}
                                                                </Link>
                                                            }else if(button.type === 'dropdown'){
                                                                return <div className="btn-group">
                                                                            <Link to="#" data-toggle="dropdown" title={button.title}>
                                                                            <i className={button.value} style={{marginLeft: '7px'}}></i>
                                                                            </Link>
                                                                            <ul className="dropdown-menu">
                                                                                {button.options && button.options.map(option =>
                                                                                    <li>
                                                                                        {
                                                                                            option.link ?
                                                                                                <a href={option.link}><span> {plainTranslate(this.props.settings.locale, option.title)}</span></a>
                                                                                            :
                                                                                                <a href="#" onClick={() => this.handleClick(option.value, row.id, option.id)}><span>{plainTranslate(this.props.settings.locale, option.title)}</span></a>

                                                                                        }
                                                                                    </li>
                                                                                )}
                                                                            </ul>
                                                                </div>
                                                            }else if(button.type === 'delete'){
                                                                return <Link to='#' title={button.title} onClick={() => this.setState({deletePopup: button.url, deleteData: {id: button.secureId, table: button.table}})}
                                                                       className={button.className ? button.className : ''}>

                                                                        {button.icon ?
                                                                            <i className={button.value} aria-hidden="true" style={{'marginLeft': 5}}></i> : button.value}
                                                                    </Link>

                                                            } else if(button.type === 'link' && button.url == 'popup') {
                                                                return <a href="#" onClick={() => this.setState({isOpenTask: true, taskForm: field.form, taskId: button.id})}><i className={button.value} aria-hidden="true" style={{marginLeft: '7px'}}></i></a>

                                                            }

                                                    }) :
                                                    (field.url !== 'popup' ? field.notReact ?
                                                    <a href={field.url}>
                                                        {field.icon ?
                                                            <i className={field.value} aria-hidden="true"></i> : field.value}
                                                    </a> :
                                                    <Link to={field.url}>
                                                        {field.icon ?
                                                            <i className={field.value} aria-hidden="true"></i> : field.value}
                                                    </Link>
                                                    : <a href="#" onClick={() => this.setState({isOpenTask: true, taskForm: field.form, taskId: field.id})}>{field.value}</a>
                                                    )
                                                }


                                        </td>;

                                    else if (field.type === 'delete') return <td key={ckey}>
                                        <a href='#' onClick={() => this.setState({deletePopup: field.url, deleteData: {id: field.secureId, table: field.table}})}
                                           className={field.className ? field.className : ''}>
                                            {field.icon ?
                                                <i className={field.value} aria-hidden="true"></i> : field.value}
                                        </a>
                                    </td>;
                                    else if (field.type === 'dropdown') {
                                        return <td key={ckey}>
                                            <select onChange={this.dropdownChange}>
                                                <option value={'0'}>'Choose'</option>
                                                {field.options.map((option, okey) => <option key={okey}
                                                                                             value={option.value}>{plainTranslate(this.props.settings.locale, option.title)}</option>)}
                                            </select>
                                        </td>
                                    }

                                })}</tr> : <tr></tr>
                        })
                    }
                    </tbody>
                </table>
                {this.state.loadMore &&
                    <button type="button" className="button-outline grey" onClick={this.loadMore}>
                        {plainTranslate(this.props.settings.locale, 'Load More')}
                    </button>
                }
            </section>
            : <p> {plainTranslate(this.props.settings.locale, 'No unpaid documents!')} </p> }
            {
                this.state.deletePopup &&
                <Popup locale={this.props.settings.locale}
                       title={plainTranslate(this.props.settings.locale, 'Delete')}
                       onClose={() => this.setState({deletePopup: false})}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <div className="text-center">

                                    <h3>
                                        <Translate locale={this.props.settings.locale} value="Are you sure?"/>
                                    </h3>
                                    <button className="button-outlines green text-center"
                                            onClick={() => this.setState({deletePopup: false})}>
                                        <Translate locale={this.props.settings.locale} value="No"/>
                                    </button>
                                    <button className="button-primary-edited text-center btn-ok"
                                            onClick={() => this.handleDelete(this.state.deletePopup, this.state.deleteData)}>
                                        <Translate locale={this.props.settings.locale} value="Yes"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </Popup>
            }
            {
            this.state.isOpenTask &&
            <SlideModal onClose={() => this.setState({isOpenTask: false, taskId: false})} title={plainTranslate(this.props.settings.locale, 'Task')}>
                <section className="newPanel">
                    <div className="panel-body">
                        <div className="row m-bot15">
                            <SimpleForm
                                formRoute={this.props.match.params.db + '/api/tasks/'}
                                saveRoute={this.props.match.params.db + '/api/tasks/'}
                                updateParent={(data) => this.updateTasks(data)}
                                match={{params: {id: this.state.taskId}}}
                                onCancel={() => this.setState({isOpenTask: false})}
                                isNew={true}
                                popup={true}
                                account={this.props.accountId}
                                type={this.state.taskForm}
                            />
                        </div>
                    </div>
                </section>
            </SlideModal>
            }
         </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({
    onDeleteTableData: (data) => dispatch({type: ACCOUNT_PROFILE_DELETE_TABLE_DATA, payload: {data: data}}),
    onEditTask: (data) => dispatch({type: ACCOUNT_EDIT_TASK, payload: {task: data}}),
    onChangeSaleStage: (id, stage) => dispatch({type:SALE_CHANGE_STAGE, payload: {id: id, stage: stage}}),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Table));
