import React from 'react';
import PropTypes from 'prop-types';
import DoubleCheckbox from './doubleCheckbox';
import Collapsible from '../../../redux-forms/types/collapsible';
import SaveCancel from '../../../common/saveCancelButtons';
import Translate, { plainTranslate } from '../../../common/translate';
import client from './../../../../actions/client';

class ChartOfAccountsSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showMessage: false,
            message: '',
            enableSave: false,
            settings: [],
            chartOfAccountsType: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSecondChange = this.handleSecondChange.bind(this);
        this.save = this.save.bind(this);
        this.loadAnalysisCodes = this.loadAnalysisCodes.bind(this);
    }

    componentDidMount() {
        this.loadAnalysisCodes();
        this.setState({
            settings: [...this.props.chartOfAccounts.settings]
        });
    }

    handleChange(label, id, pos) {
        const settings = this.state.settings;
        for (var i = 0; i < settings.length; i++) {
            if (settings[i]['name'] == label) {
                var index = i;
            }
        }
        if (index != undefined) {
            settings.splice(index, 1);
        } else {
            let obj = {};
            obj['name'] = label;
            obj['type'] = id;
            obj['position'] = pos;
            obj['required'] = '0';
            settings.push(obj);
        }

        this.setState({
            settings,
            enableSave: true
        });
//        this.props.update(this.state.settings);
    }
    
    handleSecondChange(label) {  
        const settings = this.state.settings;
        for (var i = 0; i < settings.length; i++) {
            if (settings[i]['name'] == label) {
                var index = i;
            }
        }

        if (index !== undefined) {
            var val = settings[index]['required'];
            if (val === "1") {
                settings[index]['required'] = "0";
            } else {
                settings[index]['required'] = "1";
            }
        } else {
            this.setState({
                    showMessage: true,
                    message: "First you must activate the field!"
                });
                setTimeout(function () {
                    this.setState({showMessage: false})
                }.bind(this), 5000);
        }
        
        this.setState({
            settings,
            enableSave: true
        });

    }

    save() {
        if (!this.state.enableSave) {
            return;
        }

        this.setState({
            enableSave: false
        });
        client.post( '/' + this.props.db + '/accounting/chart-of-accounts/settings/save/' + this.props.chartOfAccounts.id, 
        {
            settings: this.state.settings
        }) 
        .then(response => response.data)
        .then(response => {
            this.props.update(this.state.settings);

                this.setState({
                    showMessage: true,
                    message: response['message']
                });
        });
    }
    
    loadAnalysisCodes() {
        let chartOfAccountsType = [];
        client.post('/' + this.props.db + '/api/accounting/chart-of-accounts/analysis-codes')
        .then(response => response.data)
        .then(response => {
            let i=0;
                for (i = 0; i < response.length; i++) { 
                let item = {label: response[i]['name'], name: response[i]['name'], type: response[i]['type'], pos: response[i]['position']};
                chartOfAccountsType.push(item);
                }
                this.setState({
                    chartOfAccountsType: chartOfAccountsType
                });
        });
         
    }

    render() {
        return (
                <div>
                    <Collapsible
                        locale={this.props.locale}
                        title="Chart оf Accounts type settings"
                        subtitle="Choose how to work with accounting module">
                        <div className="col-md-12">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="panel panel-default">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th><Translate locale={this.props.locale} value="Setting type"/></th>
                                                <th><Translate locale={this.props.locale} value="Activated"/></th>
                                                <th><Translate locale={this.props.locale} value="Required"/></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.chartOfAccountsType && this.state.chartOfAccountsType.map((item, key) => (
                                                <DoubleCheckbox
                                                    locale={this.props.locale}
                                                    label={item.label}
                                                    pos={item.pos}
                                                    id={item.type}
                                                    key={key}
                                                    name={item.name}
                                                    isChecked={this.state.settings.find(cat => cat['name']==[item.label]) ? true:false}
                                                    isSecondChecked ={this.state.settings.find(cat => (cat['name']==[item.label])&&cat['required']==='1') ? true:false}
                                                    handleCheckboxChange={this.handleChange}
                                                    handleSecondCheckboxChange={this.handleSecondChange}
                                                    />
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                            <div className="pull-right">
                                <SaveCancel
                                    locale={this.props.locale}
                                    enableSave={this.state.enableSave}
                                    save={this.save}/>
                            </div>
                        </div>
                    </Collapsible>
                </div>
                );
    }
}

export default ChartOfAccountsSettings;
