export default function getFormRoutes(db) {
    return [
        {
            title: ['Sale', '›' ,'New'],
            path: '/:db/sale-new/new',
            saveRoute: '/api/sales',
            formRoute: '/api/sales/',
            dataRoute: '/api/sales-data/',
            isNew: true,
            checkDevices: '/api/devices-search'
        },
         {
            title: ['Sale', '›' ,'Edit'],
            path: '/:db/sale-new/:id/edit',
            saveRoute: '/api/sales/',
            formRoute: '/api/sales/',
            dataRoute: '/api/sales-data/',
            isNew: true,
            updateOnSave: true,
            checkDevices: '/api/devices-search'
        },
        {
            title: ['Expenses', '›' ,'New'],
            path: '/:db/expense-new/new',
            saveRoute: '/api/expenses',
            formRoute: '/api/expenses/',
            isNew: true,
            redirectRoute:  '/:db/expense-new/:id/edit'
        },
        {
            title: ['Expenses', '›' ,'Edit'],
            path: '/:db/expense-new/:id/edit',
            saveRoute: '/api/expenses/',
            formRoute: '/api/expenses/',
            isNew: true,
            redirectRoute:  '/:db/expense-new/:id/edit'
        }, 
     ];
}   