import React from 'react';
import { connect } from 'react-redux';
import { plainTranslate } from "../../common/translate";
import FormLoader from "../../forms/form-loader";
import Table from '../../crm/accounts/profile/table.jsx';
import client from '../../../actions/client'

class SearchDocuments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            data: false
        };
    }

    componentDidMount() {
        let addVal = {};
        let jsonData = [];
        this.props.params.map(field => {
            addVal[field] = this.props.formData[field];
        })
        jsonData.push(JSON.stringify(addVal));
        this.setState({
            showLoader: true
        });
        client.post(this.props.searchUrl, addVal)
            .then(response => response.data)
            .then(response => {
                this.setState({
                    data: response,
                    showLoader: false
                })
            })
    }

    render() {
        return <div>
            {
                this.state.showLoader ?
                    <FormLoader />
                    :
                    <Table columns={this.state.data.columns} data={this.state.data.data} addButton={true} onAddRow={this.props.onAddRow} />
            }
        </div>

    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SearchDocuments);
