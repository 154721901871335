import React from 'react';
import '../common/loader.css';

export default class MenuLoader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaders: []
        };

        this.unmount = false;
        this.setNext = this.setNext.bind(this);
    }

    componentDidMount() {
        this.interval = this.setNext();
    }

    setNext() {
        setTimeout(() => {
            if (this.unmount) return;
            let loaders = this.state.loaders.length <= 6 ? this.state.loaders : [];
            loaders.push(<img className={'loaderImage'}
                              src={this.props.image}
                              style={{width: '80%', marginTop: '20px'}}/>);

            this.setState({loaders});
            this.interval = this.setNext();
        }, 200);
    }

    componentWillUnmount() {
        this.unmount = true;
    }

    render() {
        return (
            <div>
                {this.state.loaders.map((item, key) => <span key={key}>{item}<br/></span>)}
            </div>
        );
    }
}