import React, {Component} from 'react';
import Translate, {plainTranslate} from '../../common/translate';
import {connect} from 'react-redux';

class SingleCheckbox extends Component {
    constructor(props) {
        super(props);
        this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
    }

    toggleCheckboxChange() {
        this.props.handleCheckboxChange(this.props.name, this.props.isChecked);
    }

    render() {
        const {name} = this.props;
        const {label} = this.props;
        const {type} = this.props;
        let before = plainTranslate(this.props.locale, 'Yes');
        let after = plainTranslate(this.props.locale, 'No');
        return (
            <React.Fragment>
            {!this.props.hide ? 
            <tr>
                {name && !label &&
                <td>
                    <h5><Translate locale={this.props.locale} value={name}/></h5>
                </td>
                }
                {name && label &&
                <td>
                    <h5><Translate locale={this.props.locale} value={label}/></h5>
                </td>
                }
                {type &&
                <td>
                    <h5><Translate locale={this.props.locale} value={type}/></h5>
                </td>
                }
                <td>
                    <div before={before} after={after} className="slideThree text-left">
                        <input
                            type="checkbox"
                            value={name}
                            checked={this.props.isChecked}
                            id={`check-${name}`}
                            onChange={this.toggleCheckboxChange}
                            name={<Translate locale={this.props.locale} value={label}/>}/>
                        <label htmlFor={`check-${name}`}></label>
                    </div>
                </td>
            </tr>
            : ''
            }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SingleCheckbox); 
