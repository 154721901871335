import React, { useState } from "react";
import { Form, Button, Input, Space, Select, Switch } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {plainTranslate} from '../../common/translate';
import showAToast from '../../common/showAToast';

const SurveyQuestions = (props) => {
  const [form] = Form.useForm();
  const [questionsTypes, setQuestionsTypes] = useState(null);

  const { Option } = Select;

  React.useEffect(() => {
          let questions = [];
          if (props.input.value != '') {
                  let arr = [];
                  let trimedArr = [];
                  for (let i = 0; i < props.input.value.length; i++) {
                          questions[i] = props.input.value[i];
                          arr = props.input.value && props.input.value.length && props.input.value[i]['options'] && typeof props.input.value[i]['options'] === 'string' ? props.input.value[i]['options'].split(',') : null;
                          trimedArr = arr ? arr.map((item) => item.trim()) : [];
                          questions[i]['options'] = trimedArr;
                  }
          }

          form.setFieldsValue({
                  questions: questions
          });
          setQuestionsTypes(questions);
  }, []);

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = () => {
    let values = form.getFieldsValue();
    console.log(values['questions'])
    if (values['questions']) {
      for (let i = 0; i < values.questions.length; i++) {
        if (values.questions[i]['options']) {
          values.questions[i]['options'] = values.questions[i]['options'].toString();
        }       
    }
      props.input.onChange(values["questions"]);
    } else {
      props.input.onChange([]);
    }
    
    showAToast('Changes have been applied!', 'info', 'info')
  };

  const handleChangeType = () => {
    
    let values = form.getFieldsValue();
    console.log(values)
    setQuestionsTypes(values["questions"]);
    form.setFieldsValue({
      questions: values["questions"],
    });
  };

  const types = [
    { value: "checkbox", label: "Checkbox" },
    { value: "dropdown", label: "Dropdown" },
    { value: "multiply-dropdown", label: "Multiply dropdown" },
    { value: "text", label: "Open question" },
    { value: "scale", label: "Scale" },
  ];

  return (
    <div>
      <>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="form mtb-30"
        >
          <Form.List name="questions">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, key) => (
                  <Space key={field.key} align="baseline">
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                    <Form.Item noStyle>
                      <Form.Item
                        {...field}
                        label={plainTranslate(props.locale, "Type")} 
                        name={[field.name, "type"]}
                        fieldKey={[field.fieldKey, "type"]}
                        rules={[{ required: true, message: "Missing type" }]}
                      >
                        <Select
                          style={{ width: 130 }}
                          onChange={handleChangeType}
                        >
                          {types.map((item) => (
                            <Option key={item.label} value={item.value}>
                              {plainTranslate(props.locale, item.label)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Form.Item>
                    <Form.Item noStyle>
                      <Form.Item
                        {...field}
                        label="Category Group"
                        name={[field.name, "categoryGroup"]}
                        fieldKey={[field.fieldKey, "categoryGroup"]}
                      >
                        <Input />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item noStyle>
                      <Form.Item
                        {...field}
                        label="Category"
                        name={[field.name, "category"]}
                        fieldKey={[field.fieldKey, "category"]}
                      >
                        <Input />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label={<span className="form-label">{plainTranslate(props.locale, "Question")}</span>}
                      name={[field.name, "question"]}
                      fieldKey={[field.fieldKey, "question"]}
                      rules={[{ required: true, message: "Missing question" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label={plainTranslate(props.locale, "Is required?")}
                      name={[field.name, "isRequired"]}
                      fieldKey={[field.fieldKey, "isRequired"]}
                    >
                      <Switch defaultChecked={props.input.value && props.input.value && props.input.value.length && props.input.value[field.key] && props.input.value[field.key]['isRequired'] != null ? props.input.value[field.key]['isRequired'] : false}/>
                    </Form.Item>{console.log(props.input.value)}
                    <Form.Item
                      {...field}
                      label={plainTranslate(props.locale, "Is empty?")}
                      name={[field.name, "isEmptyOption"]}
                      fieldKey={[field.fieldKey, "isEmptyOption"]}
                    >
                      <Switch defaultChecked={props.input.value && props.input.value && props.input.value.length && props.input.value[field.key] && props.input.value[field.key]['isEmptyOption'] != null ? props.input.value[field.key]['isEmptyOption'] : false}/>                    </Form.Item>
                    {questionsTypes && questionsTypes.length && questionsTypes[key] && questionsTypes[key]["type"] != "scale" && questionsTypes[key]["type"] != "text" && (
                        <Form.List name={[field.name, "options"]}>
                          {(options, { add, remove }) => {
                            return (
                              <div>
                                {options.map((option) => (
                                  <Space key={option.key} align="start">
                                    <Form.Item
                                      {...option}
                                      name={[option.name]}
                                      label={<span className="form-label">{plainTranslate(props.locale, "Options")}</span>}
                                      fieldKey={[option.fieldKey]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing option",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Option" />
                                    </Form.Item>

                                    <MinusCircleOutlined
                                      onClick={() => {
                                        remove(option.name);
                                      }}
                                    />
                                  </Space>
                                ))}

                                <Form.Item>
                                  <Button
                                    style={{ backgroundColor: "#f4f4f4" }}
                                    type="dashed"
                                    onClick={() => {
                                      add();
                                    }}
                                    block
                                  >
                                    <PlusOutlined /> {plainTranslate(props.locale, "Add option")}
                                  </Button>
                                </Form.Item>
                              </div>
                            );
                          }}
                        </Form.List>
                      )}
                    {questionsTypes &&
                      questionsTypes.length &&
                      questionsTypes[key] &&
                      questionsTypes[key]["type"] == "scale" && (
                        <Form.Item
                          {...field}
                          label={<span className="form-label">{plainTranslate(props.locale, "Options")}</span>}
                          tooltip="Scale field must be a number. Enter the highest value"
                          name={[field.name, "options"]}
                          fieldKey={[field.fieldKey, "options"]}
                          rules={[{ required: false }]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {plainTranslate(props.locale, "Add question")}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Button className="pull-right" htmlType="button" onClick={onReset}>
                {plainTranslate(props.locale, "Clear all questions")} 
          </Button>
          <Button type="primary" className="pull-right" style={{marginRight: '20px'}} onClick={onFinish}>
                {plainTranslate(props.locale, "Apply changes")}
          </Button>
        </Form>
      </>
    </div>
  );
};
    
export default SurveyQuestions;
