import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import { Form, Input, Button } from 'antd';

class UserSMSSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
              value: null,
              enableSave: false
        };
        this.formRef = React.createRef();
        this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
        this.validateToNextPassword = this.validateToNextPassword.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.state.value == null) {
            this.setState({
                value: this.props.value
            }, function () {
                this.formRef.current.setFieldsValue(this.state.value)
            });
        }
    }

    onFinish = (values) => {   
            this.props.onSave(values)
    }

    submitForm = e => {
        e.preventDefault()
        this.formRef.current.submit()
      }

    compareToFirstPassword (rule, value, callback){       
        if (value && value !== this.formRef.current.getFieldValue('password')) {
         callback(plainTranslate(this.props.settings.locale,'Passwords do not match!'));
        } else if(!value){
            callback(plainTranslate(this.props.settings.locale,'Please repeat your password!'))         
        }else{
            callback()
        }
    };

    validateToNextPassword(rule, value, callback){
        if(value){
            callback();
        }else{
            callback(plainTranslate(this.props.settings.locale,'Please input your password!')) 
        }        
    };


    render() {
       
        return (
                <React.Fragment>
                    <div className="row m-bot15">
                        <div className="col-lg-12">
                                <Form 
                                layout="vertical"
                                ref={this.formRef}
                                onFinish={this.onFinish} 
                                className="company-settings-form">
                                    <div className="col-lg-12"> 
                                        <br />
                                        <div className="col-lg-12">
                                            <Form.Item label={plainTranslate(this.props.settings.locale, 'Name')}
                                            name="name"                                          
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your name!',

                                                    }
                                                ]}>
                                            <Input placeholder={plainTranslate(this.props.settings.locale, 'Name')}/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-12">
                                            <Form.Item 
                                            name="username"
                                            label={plainTranslate(this.props.settings.locale, 'Username')}                                               
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your username!',

                                                    }
                                                ]
                                              }>
                                                    <Input placeholder={plainTranslate(this.props.settings.locale, 'Username')}/>
                                                
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-12">
                                            <Form.Item 
                                            name="password"
                                            label={plainTranslate(this.props.settings.locale, "Password")}                                             
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your password!',

                                                    },
                                                    {
                                                        validator: this.validateToNextPassword,
                                                    }
                                                ]}>
                                              <Input.Password visibilityToggle={false} />
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-12">
                                            <Form.Item 
                                            name="repeatPassword"
                                            label={plainTranslate(this.props.settings.locale,"Repeat Password")}
                                            rules={[
                                                  {
                                                    validator: this.compareToFirstPassword,
                                                  },
                                                ]}>
                                              <Input.Password visibilityToggle={false} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <Button className={"button-primary"} 
                                    id="user_profile_save"                                    
                                    type="primary" 
                                    htmlType="submit" 
                                    onClick={this.submitForm}>
                                        {plainTranslate(this.props.settings.locale, 'Save')}
                                    </Button>
                                </Form>
                        </div>
                    </div>
                </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

//const UserSMSSettingsForm = Form.create({ name: 'UserSMSSettingsForm' })(UserSMSSettings);
    
export default connect(mapStateToProps, mapDispatchToProps)(UserSMSSettings);