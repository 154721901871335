import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';

export default class CalendarToolbar extends Toolbar {

	handleClick(e) {
		if (e.target.id === 'day') {
			this.props.onView('day');
			localStorage.setItem("calendarView", 'day');
		} else if (e.target.id === 'week') {
			this.props.onView('week');
			localStorage.setItem("calendarView", 'week');
		} else if (e.target.id === 'month') {
			this.props.onView('month');
			localStorage.setItem("calendarView", 'month');
		}

		let selectedView = document.querySelectorAll(".items.itemActive");

		selectedView[0].classList.remove("itemActive");

		let newView = document.getElementById(e.target.id);
		newView.classList.add("itemActive");
	}

	render() {
		let nextMonth = null;

		if (this.props.date.getMonth() == 11) {
			let year = this.props.date.getFullYear() + 1;
			nextMonth = this.props.localizer.messages.monthNames[0] + ' ' + year;
		} else {
			nextMonth = this.props.localizer.messages.monthNames[this.props.date.getMonth() + 1] + ' ' + this.props.date.getFullYear();
		}

		return (
			<div className="calendar-toolbar">
				<div className="col-md-4 noPadding col-sm-12" id="calendar-views">
					<span className={localStorage.getItem("calendarView")== 'day' ? "items itemActive" : window.innerWidth < 780 ? "items itemActive" : "items"} id="day" onClick={this.handleClick.bind(this)} onChange={this.handleClick}>{this.props.localizer.messages.day}</span>
					<span className={localStorage.getItem("calendarView")== 'week' ? "items itemActive" : "items"} id="week" onClick={this.handleClick.bind(this)}>{this.props.localizer.messages.week}</span>
					<span className={localStorage.getItem("calendarView")== 'month' ? "items itemActive" : window.innerWidth < 780 ? "items itemActive" : "items"} id="month" onClick={this.handleClick.bind(this)}>{this.props.localizer.messages.month}</span>
					{/*<span className="items" onClick={() => this.navigate('TODAY')}>{this.props.localizer.messages.today}</span>*/}
					<span className="items" onClick={() => this.navigate('PREV')}><i className="fa fa-angle-left"></i></span>
					<span className="items" onClick={() => this.navigate('NEXT')}><i className="fa fa-angle-right"></i></span>
					<span className="nextMonth">{nextMonth}</span>
				</div>
				<div className="currentMonth text-center col-md-4 col-sm-12"><h2>{this.props.label}</h2></div>
			</div>
		);
	}
}
