import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { getFolderOptions, getDocuments, uploadFiles, createFolder, editFolder,  deleteFolder, editFile, deleteFile, downloadFile, loadUpload, restoreFile, restoreFolder } from '../../actions/documentsActions'
import {Link} from 'react-router-dom';
import {plainTranslate} from "../common/translate";
import showAToast from '../common/showAToast'
import { Menu, Dropdown, Modal, Button, Input, Row, Col, Upload, Card, Select, Spin, Space, Tooltip, Pagination, Form, Radio } from 'antd';
import { MoreOutlined, SendOutlined, ShareAltOutlined, UnorderedListOutlined, DeleteOutlined, EditOutlined, RedoOutlined, CopyOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons'
import ModalAntd from '../common/ModalAntd'
import axiosFileApi from '../../actions/axiosFileApi';
import client from '../../actions/client';
import './documents.css'
const { Option } = Select;

class Documents extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
        open: false,
        folderName: '',
        folderId: null,
        files: [],
        uploadError: null,
        openFile: false,
        fileList: [],
        openDel: false,
        fileId: null,
        fileName: '',
        filePrivacy: null,
        openFileEdit: false,
        openFileDel: false,
        id: null,
        previewVisible: false,
        previewImage: '',
        openRestore: false,
        openFileRestore: false,
        pageFiles: 1,
        pageFolders: 1,
        allFolders: [],
        rendered: false,
        openShare: false, //open form to make share file or files
        shareSizes: [], //all possible share sizes from settings
        shareFileId: null, // id of file that will be shared
        sharedFileId: null, //id of fileshare -> for delete
        sharedFiles: null, //all shared file verions for one file
        openShared: false, //open modal with shared versions
        openDelShare: false  //open modal to confirm file share deletion
    };
    this.handleUpload = this.handleUpload.bind(this);
    this.openModalFolder  = this.openModalFolder.bind(this);
    this.handleOk  = this.handleOk.bind(this);
    this.handleCancel  = this.handleCancel.bind(this);
    this.handleInputChange  = this.handleInputChange.bind(this);
    this.handleOnDelete  = this.handleOnDelete.bind(this);
    this.handleChange  = this.handleChange.bind(this);
    this.handleDeleteFolder  = this.handleDeleteFolder.bind(this);
    this.handleOkDelFolder  = this.handleOkDelFolder.bind(this);
    this.handleCanceDelFolder  = this.handleCanceDelFolder.bind(this);
    this.handleDeleteFile  = this.handleDeleteFile.bind(this);
    this.handleOkDelFile  = this.handleOkDelFile.bind(this);
    this.handleCanceDelFile  = this.handleCanceDelFile.bind(this);
    this.handleEditFile  = this.handleEditFile.bind(this);
    this.handleOkEditFile  = this.handleOkEditFile.bind(this);
    this.handleCanceEditFile  = this.handleCanceEditFile.bind(this);
    this.handleInputChangeFile = this.handleInputChangeFile.bind(this);
    this.handlePrivacyChangeFile = this.handlePrivacyChangeFile.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.handleCancelPreview = this.handleCancelPreview.bind(this);
    this.handleDowloadFile = this.handleDowloadFile.bind(this);
    this.handleRestoreFile = this.handleRestoreFile.bind(this);
    this.handleOkResFolder = this.handleOkResFolder.bind(this);
    this.handleCanceResFolder = this.handleCanceResFolder.bind(this);
    this.handleOkResFile = this.handleOkResFile.bind(this);
    this.handleCanceResFile = this.handleCanceResFile.bind(this);
    this.handleFolderChangeFile = this.handleFolderChangeFile.bind(this);
    this.onChangePageFolder = this.onChangePageFolder.bind(this);
    this.onChangePageFile = this.onChangePageFile.bind(this);
    this.onFolderSearch = this.onFolderSearch.bind(this);
  }

onFolderSearch(value){    
    this.props.getFolderOptions(value)
}

onChangePageFolder(page, pageSize){
     this.setState({
        pageFolders: page
    })
    this.props.getDocuments(this.props.type, this.state.pageFiles, page,  this.props.id)
}

onChangePageFile(page, pageSize){
    this.setState({
        pageFiles: page
    })
    this.props.getDocuments(this.props.type, page, this.state.pageFolders,  this.props.id)
}

     componentDidMount() {
if(localStorage.storageToken && localStorage.storageUrl){
      this.props.getDocuments(this.props.type,this.state.pageFiles, this.state.pageFolders,  this.props.id)
      this.props.getFolderOptions()
      this.setState({
          id: this.props.id,
          rendered: true
      })
    }
    client
    .get('/'+this.props.match.params.db+'/api/settings/documents/share-size')
    .then(response => {
      if(response.data && Object.keys(response.data).length > 0){
        this.setState({
          shareSizes: response.data
        })
      }
     })
    .catch(error => {

    })
    
    }

    componentDidUpdate(prevProps) {
      if(this.state.rendered === false){
        if(localStorage.storageToken && localStorage.storageUrl || prevProps.settings.storageToken && prevProps.settings.storageUrl){
          this.props.getDocuments(this.props.type,this.state.pageFiles, this.state.pageFolders,  this.props.id)
          this.props.getFolderOptions()
          this.setState({
              id: this.props.id,
              rendered: true
          })
        }
      }
        if (prevProps.match.params.id !== this.props.match.params.id) {
             this.props.getDocuments(this.props.type, this.state.pageFiles, this.state.pageFolders, this.props.id)
          this.setState({
            id: this.props.match.params.id,
          });
        }
    }
    //restore file
    handleRestoreFile(fileId, fileName){
         this.setState({
             openFileRestore: true,
             fileId: fileId,
             fileName: fileName
         })
    }

    handleOkResFile(){
        this.props.restoreFile(this.state.fileId)
     setTimeout(() => {
                 this.props.getDocuments(this.props.type, this.state.pageFiles, this.state.pageFolders, this.props.id)
              }, 3000)
        this.setState({
             openFileRestore: false,
             fileId: null,
             fileName: ''
        })
    }

    handleCanceResFile(){
        this.setState({
             openFileRestore: false,
             fileId: null,
             fileName: ''
        })
    }

    //restore folder
    handleRestoreFolder(folderId, folderName){
        this.setState({
             openRestore: true,
             folderName: folderName,
             folderId: folderId
         })
    }

    handleOkResFolder(){
        this.props.restoreFolder(this.state.folderId)
        setTimeout(() => {
                 this.props.getDocuments(this.props.type, this.state.pageFiles, this.state.pageFolders, this.props.id)
              }, 3000)
        this.setState({
             openRestore: false,
             folderName: '',
             folderId: null
         })
    }

    handleCanceResFolder(){
        this.setState({
             openRestore: false,
             folderName: '',
             folderId: null
         })
    }

  handleDowloadFile(id, name){
      this.props.downloadFile(id, name)
  }

     //delete folder
     handleDeleteFolder(folderId, folderName){
         this.setState({
             openDel: true,
             folderName: folderName,
             folderId: folderId
         })
     }
     handleOkDelFolder(){
    this.props.deleteFolder(this.state.folderId)
     setTimeout(() => {
                 this.props.getDocuments(this.props.type, this.state.pageFiles, this.state.pageFolders, this.props.id)
              }, 3000)
         this.setState({
             openDel: false,
             folderId: null,
             folderName: ''
         })}
     handleCanceDelFolder(){
         this.setState({
             openDel: false,
             folderId: null,
             folderName: ''
         })
     }
     //delete file
     handleDeleteFile(fileId, fileName){
         this.setState({
             openFileDel: true,
             fileId: fileId,
             fileName: fileName
         })
     }
     handleOkDelFile(){
         this.props.deleteFile(this.state.fileId)
          setTimeout(() => {
                 this.props.getDocuments(this.props.type, this.state.pageFiles, this.state.pageFolders, this.props.id)
              }, 3000)
         this.setState({
             openFileDel: false,
             fileId: null,
             fileName: ''
         })
     }
     handleCanceDelFile(){
         this.setState({
             openFileDel: false,
             fileId: null,
             fileName: ''
         })
     }
     //edit file
     handleEditFile(fileId, fileName, filePrivacy, folderId, folderName){
         this.setState({
             openFileEdit: true,
             fileId: fileId ? fileId : null,
             fileName: fileName ? fileName : null,
             filePrivacy: filePrivacy ? filePrivacy : null,
             folderId: folderId,
             folderName: folderName
         })
     }

     handleOkEditFile(){
     this.props.editFile(this.props.match.params.db, this.state.fileId, this.state.filePrivacy, this.state.fileName, this.state.folderId)
      setTimeout(() => {
                 this.props.getDocuments(this.props.type, this.state.pageFiles, this.state.pageFolders, this.props.id)
              }, 3000)
         this.setState({
             openFileEdit: false,
             fileId: null,
             fileName: '',
             filePrivacy: null,
             folderId: null,
             folderName: ''
         })
     }
     handleCanceEditFile(){
         this.setState({
             openFileEdit: false,
             fileId: null,
             fileName: '',
             filePrivacy: null,
             folderId: null,
             folderName: ''
         })
     }
     handleInputChangeFile(event){
         this.setState({
             fileName: event.target.value
         })
     }
     handlePrivacyChangeFile(value){
         this.setState({
             filePrivacy: value
         })
     }

     handleFolderChangeFile(value){
         this.setState({
             folderId: value
         })
     }
     //upload file
     handleUpload(){
        if(this.state.fileList){
         this.props.loadUpload(true)
            var body = new FormData();
            this.state.fileList.map(file => (
                    body.append('files[]', file.originFileObj)
                    ))
            if(this.props.id && this.props.id !== 'undefined'){
                body.append('folder', this.props.id)
            }
            body.append('tenant', this.props.match.params.db)
            this.props.uploadFiles(body);

             setTimeout(() => {
                    this.setState({
                     fileList: []
                    })
                 this.props.getDocuments(this.props.type, this.state.pageFiles, this.state.pageFolders, this.props.id)
              }, 3000)

        }
     }

     handleOnDelete(e){
      e.preventDefault()
     let files = this.state.files;
     files.map((fileObj, key) => {
        if(fileObj.preview === e.target.value){
            files.splice(key, 1)
        }
      })
        this.setState({ files: files });
    }

    handleChange ({ fileList }){
        this.setState({ fileList });
    }

     handlePreview(file){

    if (!file.url && !file.preview) {
      file.preview = URL.createObjectURL(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  }

    handleCancelPreview(){
        this.setState({ previewVisible: false });
    }


     //create folder
     openModalFolder(folderName = null, folderId = null){
         this.setState({
             open: true,
             folderName: folderName ? folderName : '',
             folderId: folderId ? folderId : null
         })
     }

     handleOk(){
         if(this.state.folderId){
             this.props.editFolder(this.props.match.params.db, this.state.folderName, this.state.folderId)
         }else{
         this.props.createFolder(this.props.match.params.db, this.state.folderName, this.props.id)
     }
         setTimeout(() => {
                 this.props.getDocuments(this.props.type, this.state.pageFiles, this.state.pageFolders, this.props.id)
              }, 3000)
         this.setState({
             open: false,
             folderName: '',
             folderId: null,
         })
     }

      

     handleCancel(){
         this.setState({
             open: false,
             folderName: '',
             folderId: null,
         })
     }

     handleInputChange(event){
         this.setState({
             folderName: event.target.value
         })
     }

     openShareModal = (fileId = null, isImage = null) => {       
      this.setState({
        openShare: true,
        shareFileId: fileId,
        isImage: isImage
      })
     }

     handleCancelShare = () => {
      this.setState({
        openShare: false,
        shareFileId: null,
        isImage: null
      })
      this.formRef.current.resetFields();
     }

     removeErrors = (name) => {
      this.formRef.current.setFields([
        {
          name: name,
          errors: [],
        },
     ]);
     }

     onFinish = (values) => {
        var files = []      
        if(this.state.shareFileId){ 
          files.push(this.state.shareFileId)
        }else{
          if(this.props.documents && this.props.documents.documents && this.props.documents.documents.files){
            this.props.documents.documents.files.map(file => {
              files.push(file.id)
            })
          }
        }         
        var formData = {};
        if(values.folder){
          var pattern = new RegExp('^[a-zA-Z0-9_]+$');        
         if(pattern.test(values.folder)){
          formData.folder = values.folder;
         }else{
          this.formRef.current.setFields([
            {
              name: 'folder',
              errors: ['Folder field is not valid!'],
            },
         ]);
          return; //showAToast("Folder field is not valid!", 'baseError', 'error')
         }
          
        }
        if(values.format){
          formData.format = values.format;
        }else{
          if(this.state.isImage){
            this.formRef.current.setFields([
              {
                name: 'format',
                errors: ['Please, choose a format!'],
              },
           ]);
            return; //showAToast("Please, choose a format!", 'baseError', 'error')
          }
        }       
         
        formData.files = files;
        var sizes = [];
        if(values.size){
          sizes.push(values.size)
        }
        
        if(values.width && values.height){
          var pattern = new RegExp('^[0-9_]+$');   
          if(pattern.test(values.width) && pattern.test(values.height)){          
            sizes.push(values.width+'x'+values.height)
          }else if(!pattern.test(values.width)){
            this.formRef.current.setFields([
              {
                name: 'width',
                errors: ['Width must be integer!'],
              },
           ]);
          }else if(!pattern.test(values.height)){
            this.formRef.current.setFields([
              {
                name: 'height',
                errors: ['Height must be integer!'],
              },
           ]);
          }
        }
        if(sizes.length === 0 && this.state.isImage){
          this.formRef.current.setFields([
            {
              name: 'size',
              errors: ['Missing size! Choose a size or fill your customized in the fields.'],
            },
         ]);
            return; //showAToast("Missing size! Choose a size or fill your customized in the fields.", 'baseError', 'error')
        }
        formData.size = sizes;
      
      axiosFileApi
        .post('/api/user/files/multiple-shares', formData)
        .then(res => {          
          if(Object.keys(res.data).length > 0){
            showAToast('Successful file creation!', 'baseSuccess', 'success')
          }else{
            showAToast("Sharing not created!", 'baseError', 'error')
          }          
        })
        .catch(err => {
          var message = 'An error occurred!'         
          if(err.response && err.response.data && err.response.data.message){
            message = err.response.data.message;
        }
          showAToast(message, 'baseError', 'error')
        })

        this.setState({
          openShare: false,
          shareFileId: null,
          isImage: null
        })
        this.formRef.current.resetFields();
     }

     onReset = () => {
      this.formRef.current.resetFields();
    };

    openGetSharesModal = (id) => {
      this.setState({
        openShared: true,
        fileId: id
      })
      axiosFileApi
        .get('/api/user/files/get-shares/'+id)
        .then(res => {          
            this.setState({
              sharedFiles: res.data
            })       
        })
        .catch(err => {})
    }

    closeGetSharesModal = () => {
      this.setState({
        openShared: false,
        sharedFiles: null,
        fileId: null
      })
    }    

    deleteShare = (id) => {
      this.setState({
        openDelShare: true,
        sharedFileId: id
      })
    }

  handleCanceDelShare = () => {
    this.setState({
      openDelShare: false,
      sharedFileId: null
    })
  }

    handleOkDelShare = () => {
      axiosFileApi
      .get('/api/user/files/shared/delete/'+this.state.sharedFileId)
      .then(res => {
          showAToast('Successful file deletion!', 'baseSuccess', 'success')
          axiosFileApi
          .get('/api/user/files/get-shares/'+this.state.fileId)
          .then(res => {          
              this.setState({
                sharedFiles: res.data
              })       
          })
          .catch(err => {}) 
      })
      .catch(err => {
        var message = 'An error occurred!'         
        if(err.response && err.response.data && err.response.data.message){
          message = err.response.data.message;
      }
        showAToast(message, 'baseError', 'error')
      })

      this.setState({
        openDelShare: false,
        sharedFileId: null
      })
    }

    render() {
    const { classes } = this.props;
    
    const { documents, allFolders } = this.props.documents
    const { previewVisible, previewImage, fileList, previewTitle, uploading } = this.state;
    const uploadButton = (
      <div>
        <i className="fa fa-file-o" aria-hidden="true"></i>
        <div style={{ marginTop: 8 }}>{plainTranslate(this.props.settings.locale, 'New file')}</div>
      </div>
    );
     const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    const { Meta } = Card;
   
    

    return (
      <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
        <div className="moduleSubMenu dms_new_menu" style={{marginBottom: 10}}>
          <Row gutter={[16,16]} type="flex" justify="start">
            <Col  span={24} >
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
             <div className="pull-left">
             {this.props.type === 'trash' ?
             <span style={{fontSize: 20}}>Trash<div style={{fontSize: 14}}>{plainTranslate(this.props.settings.locale, 'All files and folder will be deleted automatically in 30 days.')}</div></span>
                 :
              <span className="navigation">
               <Link to={'/'+this.props.match.params.db + '/storage'}><h3 className="module-title">{plainTranslate(this.props.settings.locale, 'Documents')}</h3></Link>
                {documents && documents.path && documents.path.map((path, k) => {
                   if(parseInt(path.id) === parseInt(this.props.match.params.id)){
                return <span style={{marginLeft: 5}}key={k}> {' > ' + path.name}</span>
                }else{
                    return <Link style={{marginLeft: 5}} to={'/'+this.props.match.params.db + '/storage/folder/'+path.id} key={k}> {' > '+path.name}</Link>
                 }
                })}
             </span>
           }
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="dir_links pull-right">
              <Link to={'/'+this.props.match.params.db+"/storage"} className="dir_link"><UnorderedListOutlined /> {plainTranslate(this.props.settings.locale, 'Browse files')}</Link>
              <Link to={'/'+this.props.match.params.db+"/storage/trash"} className="dir_link"><DeleteOutlined /> {plainTranslate(this.props.settings.locale, 'Deleted files')}</Link>
            </div>
            </Col>
          </Col>
        </Row>
        <Row type="flex" style={{marginTop: 10}}>
          {this.props.type !== 'trash' && <Col>
                <Upload
                  {...props}
                 listType="picture-card"
                 className="dms-upload"
                 fileList={fileList}
                 onPreview={this.handlePreview}
                 onChange={this.handleChange}
                 style={{display: "flex"}}
                 multiple={true}
                 accept="application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/plain, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text,  image/png, image/jpeg, image/svg+xml, image/gif, image/vnd.microsoft.icon, application/json, text/html, text/css"
                  >
                 {fileList.length >= 8 ? null : uploadButton}
               </Upload>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancelPreview}>
                  <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
               </Col>
            }
            {this.props.type !== 'trash' && <Col>
                  <button
                    type="button"
                    className="button-outline grey pull-left"
                    onClick={this.handleUpload}
                    disabled={fileList.length === 0}
                    loading={uploading}
                  >
                    {uploading ? 'Uploading' : 'Start Upload'}
                  </button>
                  <button
                      type="button"
                      className="button-primary"
                      onClick={() => this.openModalFolder(this.state.folderName, this.state.folderId)}
                      >
                      <i className="fa fa-folder" aria-hidden="true"></i>
                      <span>{plainTranslate(this.props.settings.locale, 'New folder')}</span>
                   </button>
                   <button
                      type="button"
                      className="button-outline grey pull-left"
                      onClick={() => this.openShareModal(null, true)}
                      >
                      <span>{plainTranslate(this.props.settings.locale, 'Share all')}</span>
                   </button>
                   </Col>
                }
              </Row>
           </div>
                {!documents && <Row><Col span={24} className="text-center"><Spin size="large" /></Col></Row>}
               {documents && documents.folders && <Row type="flex" justify="start" className="dir_row">
               {documents.folders.map((folder, key) => (
                       <Col xs={24} sm={12} md={8} lg={6} xl={6} key={key} className="dir">
                      
                       <Card
                            hoverable
                            className="dir_folder"
                            cover={
                              <Link to={"/" + this.props.match.params.db + "/storage/folder/"+folder.id} className="dir_element text-center">
                                 <i className="ion-ios-folder-outline" style={{fontSize: 38}}></i></Link> 
                                   }
                             actions={this.props.type === 'trash' ?
                             [
                                 <Tooltip title="Restore folder"><RedoOutlined className="dir_icon" key="redo" onClick={() => this.handleRestoreFolder(folder.id, folder.name)}/></Tooltip>,
                             ]
                             :
                             [
                             <Tooltip title="Edit folder"><EditOutlined className="dir_icon" key="edit" onClick={() => this.openModalFolder(folder.name, folder.id)} style={{marginLeft: 10}}/></Tooltip>,
                             <Tooltip title="Delete folder"><DeleteOutlined className="dir_icon" key="delete" onClick={() => this.handleDeleteFolder(folder.id, folder.name)} style={{marginLeft: 10}}/></Tooltip>
                        ]}>
                     <Link to={"/" + this.props.match.params.db + "/storage/folder/"+folder.id} className="dir_element text-center">    
                   <Meta title={<Tooltip title={folder.name}>
                       <h4 className="text-center">{folder.name.slice(0, 20)}{folder.name.length > 20 && <span>...</span>}</h4>
                       </Tooltip>} style={{textAlign: 'center', height: 14}}/></Link> 
              </Card> 
                    </Col>
                ))}
                
                </Row>}
                {documents && documents.folders && <Row type="flex" justify="start" className="dir_row">
                <Pagination 
                defaultCurrent={this.state.pageFolders} 
                total={documents.countFolders} 
                pageSize={12} 
                onChange={this.onChangePageFolder}
                showSizeChanger={false}/>
                </Row>}
                 {documents && documents.files && <Row type="flex" justify="start" className="dir_row">
               {documents.files.map((file, key) => (
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} key={key} className="dir" >
                      <div className="dir_card">
                       <div>
                        <div className="dir_image" style={{ backgroundImage: "url(" + file.thumb + ")"}}></div>
                           <div className="dir_image2" ><img alt="example" src={file.thumb}/></div>
                       </div>
                       <div> <Tooltip title={file.name}>
                       <a target='_blank' href={file.full}> 
                       <h4 className="text-center">
                         {file.name.slice(0, 20)}{file.name.length > 20 && <span>...</span>}</h4>
                       </a>  
                       </Tooltip></div>
                            <ul className="ant-card-actions">
                            {this.props.type === 'trash' ?
                                   [
                                      <li style={{width:"50%"}} key={1}><Tooltip title="Restore file"> <CopyOutlined className="dir_icon" key="redo" onClick={() => this.handleRestoreFile(file.id, file.name)}/></Tooltip></li>,
                                       <li style={{width:"50%"}} key={2}><Tooltip title="Show file"><a target='_blank' href={file.full}> <EyeOutlined key="eye"/></a></Tooltip></li>
                                   ]
                                   :
                                   [
                                  <li style={{width:"25%"}} key={1}><Tooltip title={plainTranslate(this.props.settings.locale, "Public Shares")}><ShareAltOutlined className="dir_icon" key="shared" onClick={() => this.openGetSharesModal(file.id)}/></Tooltip></li>,    
                                  <li style={{width:"25%"}} key={2}><Tooltip title={plainTranslate(this.props.settings.locale, "Share")}><SendOutlined className="dir_icon" key="share" onClick={() => this.openShareModal(file.id, file.isImage)}/></Tooltip></li>,                                  
                                  <li style={{width:"25%"}} key={3}><Tooltip title={plainTranslate(this.props.settings.locale, "Edit")}><EditOutlined className="dir_icon" key="edit" onClick={() => this.handleEditFile(file.id, file.name, file.privacy, file.folder, file.folderName)}/></Tooltip></li>,
                                  <li style={{width:"25%"}} key={4}><Dropdown overlay={
                                    <Menu>
                                       <Menu.Item key={3}>
                                  <a target='_blank' href={file.full}> 
                                  <EyeOutlined className="dir_icon" key="eye"/></a>
                                       <span style={{margin: 10}}>{plainTranslate(this.props.settings.locale, 'View')}</span>
                                      </Menu.Item>
                                      <Menu.Item key={2} onClick={() => this.handleDowloadFile(file.id, file.name)}>
                                        <DownloadOutlined className="dir_icon" key="download" 
                                        />
                                        <span style={{margin: 10}}>{plainTranslate(this.props.settings.locale, "Download")}</span>
                                      </Menu.Item>
                                      <Menu.Item key={1} onClick={() => this.handleDeleteFile(file.id, file.name)}>
                                        <DeleteOutlined className="dir_icon" key="delete" 
                                        />
                                        <span style={{margin: 10}}>{plainTranslate(this.props.settings.locale, "Delete")}</span>
                                      </Menu.Item>                                                                    
                                    </Menu> }>
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <MoreOutlined />
                                    </a>
                                  </Dropdown></li>,
                                  
                                 ]
                              }
                            </ul>
                      </div>
                    </Col>
                ))}
        
                </Row>}
            {documents && documents.files && <Row type="flex" justify="start" className="dir_row">
        <Pagination 
        defaultCurrent={this.state.pageFiles} 
        total={ documents.countFiles} 
        pageSize={24}  
        onChange={this.onChangePageFile}
        showSizeChanger={false}/></Row>}
           <Modal title={plainTranslate(this.props.settings.locale, 'Create folder')}
            visible={this.state.open}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
             footer={[
            <Button key="back" onClick={this.handleCancel}>
              {plainTranslate(this.props.settings.locale, 'Close')}
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleOk}>
              {plainTranslate(this.props.settings.locale, 'Submit')}
            </Button>,
          ]}
            >
            <p style={{marginBottom: 30}}>
               <Input placeholder={plainTranslate(this.props.settings.locale, 'Folder name')} onChange={this.handleInputChange} value={this.state.folderName}/></p>
              </Modal>
            <Modal title={plainTranslate(this.props.settings.locale, 'Edit file')}
                visible={this.state.openFileEdit}
                onOk={this.handleOkEditFile}
                onCancel={this.handleCanceEditFile}
                footer={[
                <Button key="back" onClick={this.handleCanceEditFile}>
                  {plainTranslate(this.props.settings.locale, 'Cancel')}
                </Button>,
                <Button key="submit" type="primary" onClick={this.handleOkEditFile}>
                  {plainTranslate(this.props.settings.locale, 'Edit')}
                </Button>,
              ]}
            >
            <div style={{marginBottom: 10 }}>
                <label>{plainTranslate(this.props.settings.locale, 'File folder')}</label>
                    <Select 
                        defaultValue={this.state.folderId}  
                        value={this.state.folderId} 
                        showSearch
                         defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onChange={this.handleFolderChangeFile} 
                        style={{ width: '100%' }} 
                        onSearch={this.onFolderSearch}>
                    {this.props.documents.allForders && allFolders && allFolders.map((folder, ky) => (
                        <Option value={folder.id} key={ky}>{folder.name}</Option>    ))}
                  </Select>
                </div>
                <div style={{marginBottom: 10 }}>
                     <label>{plainTranslate(this.props.settings.locale, 'Access rights')}</label>
                    <Select defaultValue={this.state.filePrivacy}  value={this.state.filePrivacy} onChange={this.handlePrivacyChangeFile} style={{ width: '100%' }}>
                    <Option value={2}>{plainTranslate(this.props.settings.locale, 'Private')}</Option>
                    <Option value={3}>{plainTranslate(this.props.settings.locale, 'Public')}</Option>
                  </Select>
                </div>
            <p style={{marginBottom: 30}}>
                 <label>{plainTranslate(this.props.settings.locale, 'File name')}</label>
               <Input onChange={this.handleInputChangeFile} value={this.state.fileName}/></p>
              </Modal>
          <Modal title={plainTranslate(this.props.settings.locale, 'Share files')}
            visible={this.state.openShare}
            onCancel={this.handleCancelShare}   
            footer={null}       
            >
        <Form
        ref={this.formRef}
      name="share_files"
      onFinish={this.onFinish}>  
       <Form.Item
        name="folder"
        label={plainTranslate(this.props.settings.locale, 'Folder')}
        onClick={() => this.removeErrors("folder")}
        >
          <Input  placeholder={plainTranslate(this.props.settings.locale, 'Folder')} style={{height: 35}}/>
      </Form.Item>       
      <Form.Item
        name="format"
        label={plainTranslate(this.props.settings.locale, 'Format')}
        onClick={() => this.removeErrors("format")}>
        <Radio.Group>
          <Radio.Button value="image">{plainTranslate(this.props.settings.locale, 'Original')}</Radio.Button>
          <Radio.Button value="webp_filter">{plainTranslate(this.props.settings.locale, 'Webp')}</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="size"
        label={plainTranslate(this.props.settings.locale, 'Size')}
        onClick={() => this.removeErrors("size")}
       >
        <Radio.Group>
          <Radio.Button value="full">{plainTranslate(this.props.settings.locale, 'Original')}</Radio.Button>
          {this.state.shareSizes && this.state.shareSizes.map(shareSize => (
            <Radio.Button value={shareSize}>{shareSize}</Radio.Button>
           ))}         
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Custom size" style={{ marginBottom: 0 }}>
      <Form.Item
        name="width"
        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Input  placeholder={plainTranslate(this.props.settings.locale, 'Width')} style={{height: 35}}/>
      </Form.Item>
      <Form.Item
        name="height"
        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
        >
          <Input  placeholder={plainTranslate(this.props.settings.locale, 'Height')} style={{height: 35}}/>
      </Form.Item>
      </Form.Item>
      <Button type="primary" htmlType="submit">
      {plainTranslate(this.props.settings.locale, 'Submit')}
        </Button>
        <Button htmlType="button" onClick={this.onReset}>
        {plainTranslate(this.props.settings.locale, 'Reset')}
          </Button>
      </Form>
          </Modal>
          <Modal title={plainTranslate(this.props.settings.locale, 'Share files')}
            visible={this.state.openShared}
            onCancel={this.closeGetSharesModal}   
            footer={null}       
            >
              {this.state.sharedFiles ? 
             <Row gutter={[16,16]} type="flex" justify="start">  
           {Object.keys(this.state.sharedFiles).map((key) => (
                  <Row gutter={[16,16]} type="flex" justify="start">
                  <Col  span={20} >
                    <Input value={this.state.sharedFiles[key]} style={{width: 350, marginRight: 30}}/>
                    </Col>
                  <Col  span={2} >
                  <Tooltip title="Copy shared versions">
                      <CopyOutlined 
                      className="dir_icon" 
                      key="copy" 
                      onClick={() => {navigator.clipboard.writeText(this.state.sharedFiles[key])}}/>
                  </Tooltip>
                 </Col>
                 <Col span={2}>
                 <DeleteOutlined className="dir_icon" key="delete" onClick={() => this.deleteShare(key)} />
                 </Col>
                 </Row>
               ))}            
               </Row>
            :
            <Space size="middle">
              <Spin size="large" />
            </Space>  
            }
          </Modal>
            <ModalAntd
                title={ plainTranslate(this.props.settings.locale, 'File share will be deleted!')}
                visible={this.state.openDelShare}
                onOk={this.handleOkDelShare}
                onCancel={this.handleCanceDelShare}
                locale={this.props.settings.locale}
               />
               <ModalAntd
                title={this.state.folderName + plainTranslate(this.props.settings.locale, ' will be deleted!')}
                visible={this.state.openDel}
                onOk={this.handleOkDelFolder}
                onCancel={this.handleCanceDelFolder}
                locale={this.props.settings.locale}
               />
               <ModalAntd
                title={this.state.fileName +  plainTranslate(this.props.settings.locale, ' will be deleted!')}
                visible={this.state.openFileDel}
                onOk={this.handleOkDelFile}
                onCancel={this.handleCanceDelFile}
                locale={this.props.settings.locale}
               />

              <ModalAntd
                title={this.state.folderName + plainTranslate(this.props.settings.locale, ' will be restored!')}
                visible={this.state.openRestore}
                onOk={this.handleOkResFolder}
                onCancel={this.handleCanceResFolder}
                locale={this.props.settings.locale}
               />
               <ModalAntd
                title={this.state.fileName +  plainTranslate(this.props.settings.locale, ' will be restored!')}
                visible={this.state.openFileRestore}
                onOk={this.handleOkResFile}
                onCancel={this.handleCanceResFile}
                locale={this.props.settings.locale}
               />
      </div>

    );
  }
}

Documents.propTypes = {
  documents: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  getDocuments: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  createFolder: PropTypes.func.isRequired,
  editFolder: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired,
  editFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  loadUpload: PropTypes.func.isRequired,
  restoreFile: PropTypes.func.isRequired,
  restoreFolder: PropTypes.func.isRequired,
  getFolderOptions: PropTypes.func.isRequired 
};


const mapStateToProps = state => ({
  settings: state.settings,
  documents: state.documents  
});

export default connect( mapStateToProps, { getFolderOptions, getDocuments, uploadFiles, createFolder, editFolder, deleteFolder, editFile, deleteFile, downloadFile, loadUpload, restoreFile, restoreFolder })(withRouter(Documents));
