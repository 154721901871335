import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';

class CommonLink extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        };
 
    }
   
    render() {
        return (
            <a className="btn btn-crm" href={this.props.input.value + this.props.field.addId} target="_blank">{plainTranslate(this.props.settings.locale, this.props.field.title)}</a>
        );
    }
}

const mapStateToProps = state => ({
                settings: state.settings,
            });

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CommonLink);
