import React, {Component} from 'react';
import {plainTranslate} from '../common/translate';
import {connect} from 'react-redux';
import {onDocumentProfileFetchData, onEditOptionsLoad, onTabsLoad} from '../../actions/actions';
import {Link} from 'react-router-dom';
import FormLoader from '../forms/form-loader';
import GeneralInformation from './general-information';
import PaymentsInfo from './payments-info';
import OrdersInfo from './orders-info';
import InventoryInfo from './inventory-receipts';
import PreviousInvoices from './previous-invoices';
import './invoiceProfile.css';
import Select from 'react-select';
import {config} from '../../config';

class InvoiceProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            template: null,
        };
        this.handleTemplateChange = this.handleTemplateChange.bind(this)
    }

    componentDidMount() {

        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/invoice/' + this.props.match.params.id + '/profile-info').then(() => {
            this.setState({template: this.props.invoiceProfileInformation.invoiceProfileInfo.defaultTemplate.id})

        });
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/invoice/' + this.props.match.params.id + '/profile-info').then(() => {
            this.setState({template: this.props.invoiceProfileInformation.invoiceProfileInfo.defaultTemplate.id})
            });
        }
    }

    handleTemplateChange(e){
        this.setState({template : e.value});
    }

    render() {
        let invoice = this.props.invoiceProfileInformation.invoiceProfileInfo ? this.props.invoiceProfileInformation.invoiceProfileInfo.invoice : {};
        let payments = this.props.invoiceProfileInformation.invoiceProfileInfo ? this.props.invoiceProfileInformation.invoiceProfileInfo.payments : [];
        let orders = this.props.invoiceProfileInformation.invoiceProfileInfo ? this.props.invoiceProfileInformation.invoiceProfileInfo.sales : [];
        let inventories = this.props.invoiceProfileInformation.invoiceProfileInfo ? this.props.invoiceProfileInformation.invoiceProfileInfo.inventories : [];
        let previousInvoices = this.props.invoiceProfileInformation.invoiceProfileInfo ? this.props.invoiceProfileInformation.invoiceProfileInfo.previousInvoices : [];
        let templates = this.props.invoiceProfileInformation.invoiceProfileInfo ? this.props.invoiceProfileInformation.invoiceProfileInfo.templates : [];
        let paymentTypes = this.props.invoiceProfileInformation.invoiceProfileInfo ? this.props.invoiceProfileInformation.invoiceProfileInfo.paymentTypes : [];
        let currencySign = this.props.invoiceProfileInformation.invoiceProfileInfo ? this.props.invoiceProfileInformation.invoiceProfileInfo.currency : null;
        let roles = this.props.invoiceProfileInformation.invoiceProfileInfo ? this.props.invoiceProfileInformation.invoiceProfileInfo.roles : [];

        if (this.props.invoiceProfileInformation.loading) {
            return <FormLoader/>
        }

        return (
            <div className="invoice-profile">
                <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                    <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb">
                        <Link to={"/" + this.props.match.params.db + "/invoices/"}>
                            <i className="ion-ios-arrow-back"></i>{plainTranslate(this.props.settings.locale, 'Invoices')}
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12 no-gutter">
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information">
                            <GeneralInformation roles={roles.crm} document={invoice} currency={currencySign}/>
                            <div className="account-profile-other-information">
                                <hr className="col-lg-12 col-md-12 col-xs-12 no-gutter"/>
                                <PaymentsInfo roles={roles.payments} payments={payments} paymentTypes={paymentTypes} account={invoice ? invoice.accountId : null}/>
                                <OrdersInfo orders={orders} />
                                <InventoryInfo inventories={inventories}/>
                                <PreviousInvoices roles={roles.invoices} previousInvoices={previousInvoices}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-xs-12 no-gutter-m no-gutter-r">
                        <div className="col-lg-12 col-md-12 col-xs-12 actions">
                            {roles.invoices && roles.invoices.hasEdit===true &&
                                <span>
                                    <Link to={"/" + this.props.match.params.db + "/invoice/"+this.props.match.params.id+'/edit'}>
                                        {plainTranslate(this.props.settings.locale, 'Edit')}<i className="fa fa-pencil-square-o"></i>
                                    </Link>
                                </span>
                            }
                            <span>
                            {this.state.template &&  <a href={"/" + this.props.match.params.db + "/invoicing/invoice/"+this.props.match.params.id+'/template/'+this.state.template+'/export.pdf'} target="_blank">
                                    {plainTranslate(this.props.settings.locale, 'Download')}<i className="fa fa-download"></i>
                                </a>}
                            </span>
                            <div className={'inlineHover'} style={{float: "right"}}>
                                <span>{plainTranslate(this.props.settings.locale, 'Template')}:</span>
                                <Select
                                    onChange={this.handleTemplateChange}
                                    onBlurResetsInput={false}
                                    onCloseResetsInput={false}
                                    menuPlacement={"auto"}
                                    options={templates}
                                    value={templates ? templates.find(o => o.value === this.state.template) : null}
                                />
                            </div>
                        </div>
                        {this.state.template && <embed src={ '/' + this.props.match.params.db + '/invoicing/invoice/' + this.props.match.params.id + '/template/' + this.state.template + '/export.pdf'} type="application/pdf" height="775px" width="100%" />}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    invoiceProfileInformation: state.profiles
});
const mapDispatchToProps = dispatch => ({
    onDocumentProfileFetchData: (url) => dispatch(onDocumentProfileFetchData(url)),
    onEditOptionsLoad: (route) => dispatch(onEditOptionsLoad(route)),
    onTabsLoad: (route) => dispatch(onTabsLoad(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceProfile);
