import React, {Component} from 'react';
import SlideModal from '../common/SlideModal';
import Translate, {plainTranslate} from '../common/translate';

class ButtonRenderer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            popup: false, 
            rowData: []
        };
        this.handleClickButton = this.handleClickButton.bind(this);
    }

    handleClickButton() {
        this.setState({popup: true});
        let arrayOfObj = Object.entries(JSON.parse(this.props.data.data)).map((e) => ( { [e[0]]: e[1] } ));
        this.setState({rowData: arrayOfObj});
    }
    
    render() 
    {
        return (
            <React.Fragment>
                <span>
                    <a className="marg" onClick={this.handleClickButton}><i className="ion-eye" aria-hidden="true"></i></a> 
                </span>
                {
                this.state.popup &&
                <SlideModal onClose={() => this.setState({popup: false})} title='Changed data' customClass={"grid-modal"}>
                    <section className="newPanel">
                        <div className="panel-body">
                                <div className="row m-bot15">
                                <table  className="table">
                                <col width="50"/>
                                <col width="80"/>
                                <col width="80"/>
                                <thead>
                                    <tr>
                                        <th>Property</th>
                                        <th>New value</th>
                                        <th>Old value</th>
                                     </tr> 
                                 </thead>
                                 <tbody>
                                    {this.state.rowData.map((data, key) => (
                                    <tr>
                                        <td>{Object.keys(data)}</td>
                                        <td>{Object.keys(data).map(k => 
                                        data[k]['new'] ?
                                        (typeof data[k]['new'] === 'object' && 'date' in data[k]['new']) ?
                                                <p>{data[k]['new']['date']}</p>
                                            :
                                                <p>{JSON.stringify(data[k]['new']).replace(/(<([^>]+)>)/ig," ").replace(/['"]+/g, '')}</p>
                                        : '')}
                                        </td>
                                        <td>{Object.keys(data).map(k => 
                                            data[k]['old'] ?
                                        (typeof data[k]['old'] === 'object' && 'date' in data[k]['new']) ?
                                                <p>{data[k]['old']['date']}</p>
                                            :
                                                <p>{JSON.stringify(data[k]['old']).replace(/(<([^>]+)>)/ig," ").replace(/['"]+/g, '')}</p>
                                        : '-')}
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                        </table>
                        </div>
                        </div>
                    </section>
                </SlideModal>
                }
            </React.Fragment>
        );
    }
}

export default ButtonRenderer;

