import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import { fetchData } from "../../../actions/actions";
import RootNode from './root';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {Link} from 'react-router-dom';

class Tree extends Component {

    constructor(props) {
        super(props);  
        this.state = {
            fSize: 12,
            showEmployees: true,
            showManagers: true,
            fIncrease: 72,
        }; 
        this.changeFont = this.changeFont.bind(this);
        this.printDocument = this.printDocument.bind(this);
        this.hideEmployees = this.hideEmployees.bind(this);
        this.hideManagers = this.hideManagers.bind(this);
    }

    componentDidMount() {
         if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
        this.props.loadData(this.props.match.params.id, this.props.match.params.db);
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.loadData(this.props.match.params.id, this.props.match.params.db);
        }
    }
    
    changeFont(sign) {
        let element = document.getElementById("tree");
        let elementInc = document.getElementById("increase-bar");
        let elementDec = document.getElementById("decrease-bar");
        let fSize = this.state.fSize;
        let fIncrease = this.state.fIncrease;
        let size;
        let increase;
        if (sign == '+') {
            if (fIncrease < 130) {
                size = fSize +1; 
                increase = fIncrease+10;
            }
        } else if (sign == '-') {
            if (fIncrease > 3) {
                size = fSize -1;
                increase = fIncrease-10;
            }
        }

        this.setState({ fSize: size, fIncrease: increase }, function() {
            element.style.fontSize = this.state.fSize + 'px';
            elementInc.style.width = this.state.fIncrease + 'px';
            elementDec.style.width = (144 - this.state.fIncrease) + 'px';
        });  
    }
    
     printDocument() {
        html2canvas(document.getElementById('tree')).then(function (canvas) {
            var wid = document.getElementById('tree').offsetWidth;
            var hgt = document.getElementById('tree').offsetHeight;

            var img = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
            var hratio = hgt / wid
            var doc = new jsPDF('l', 'mm', [297, 210]);
            var width = 297;
            var height = width * hratio;
            doc.addImage(img, 'JPEG', 10, 10, width, height);
            doc.save('Org_chart.pdf');
        });     
     }
  
    hideEmployees() {
      this.setState({
                  showEmployees:!this.state.showEmployees
              });
    }
  
    hideManagers() {
      this.setState({
                  showManagers:!this.state.showManagers
              });
    }

    render() {
        let departments = null;
        if('personnel' in this.props.settings.plan){
            departments = this.props.settings.plan.personnel.departments;
        }

        if (this.props.treeData.error) {
            return <div><Translate locale={this.props.settings.locale} value="Error "/> {this.props.treeData.error.message}</div>;
        }

        if (this.props.treeData.loading) {
            return <div><Translate locale={this.props.settings.locale} value="Loading..."/></div>;
        }
        return (
                <div id="organizationalStructure">
                {!this.props.frontend &&
                    <div className="profile-breadcrumb">
                    {this.props.match.params.id!=='all' ?
                        (<Link to={"/" + this.props.match.params.db + "/hr/organizational/structure/all"}>
                            <i className="ion-ios-arrow-back"></i><Translate locale={this.props.settings.locale} value="Organizational structure"/>
                        </Link>) : departments ? (
                        <Link to={"/" + this.props.match.params.db + "/hr/departments"}>
                            <i className="ion-ios-arrow-back"></i><Translate locale={this.props.settings.locale} value="Departments"/>
                        </Link> 
                        ) : null
                    }
                    </div>
                }
                <section className="panel-org">
                    <div className="moduleSubMenu">
                        <div className="col-sm-12">
                            <nav role="navigation" className="nav">
                                <h3><Translate locale={this.props.settings.locale} value="Organizational Structure"/></h3>
                            </nav>
                        </div>
                    </div>
                    <div className="panel-body">
                    <div className="col-md-10 m-bot15">
                    <div className="col-md-2 hide-button">
                    <label className="custom-checkbox">
                        <Translate locale={this.props.settings.locale}
                                   value={this.state.showEmployees ? 'Hide employees' : 'Show employees'}/>
                        <input name="employees" type="checkbox"
                               onChange={() => this.hideEmployees()}
                               checked={this.state.showEmployees}/>
                        <span className="checkmark"></span>
                    </label>
                    </div>
                    <div className="col-md-2 hide-button">
                     <label className="custom-checkbox">
                        <Translate locale={this.props.settings.locale}
                                   value={this.state.showManagers ? 'Hide managers' : 'Show managers'}/>
                        <input name="employees" type="checkbox"
                               onChange={() => this.hideManagers()}
                               checked={this.state.showManagers}/>
                        <span className="checkmark"></span>
                    </label>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-2 volume-buttons">
                    <span><Translate locale={this.props.settings.locale}
                                   value="Zoom"/></span>
                        <div id="increase-bar" onClick = {() => this.changeFont('-')}><p onClick = {() => this.changeFont('-')}>-</p></div>
                        <div className="circle-bar"></div>
                        <div id="decrease-bar" onClick = {() => this.changeFont('+')}><p onClick = {() => this.changeFont('+')}>+</p></div>    
                    </div>
                    <div className="col-md-1">
                    <div className="inline-block">
                        <button className="button-outline green" onClick={this.printDocument}><Translate locale={this.props.settings.locale} value="Download"/></button>
                    </div>
                    </div>
                    </div>
                <div className="col-md-12 tree" id="tree">
                    <ul>
                        {this.props.treeData.treeData && this.props.treeData.treeData.map(root =>
                          <RootNode 
                              showEmployees ={this.state.showEmployees}
                              showManagers ={this.state.showManagers}
                              key = {root.id}
                              root = {root}
                              frontend = {this.props.frontend}
                              paramsId = {this.props.match.params.id}
                           />
                        )}
                    </ul>
                </div>
                </div>
                </section>
                </div>
                );
    }
}

const mapStateToProps = state => ({
        settings: state.settings,
        treeData: state.treeData
    });

const mapDispatchToProps = dispatch => ({
        loadData: (id, db) =>
            dispatch(fetchData(id, db))
    });

export default connect(mapStateToProps, mapDispatchToProps)(Tree);


