import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Translate, {plainTranslate} from '../common/translate';
import { Row, Col } from 'antd';

class ResetPasswordCheck extends Component {
  constructor() {
    super();
  }

  componentDidMount(){
    document.documentElement.style.backgroundColor = "#fbfaff";
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/'+this.props.match.params.db+'/dashboard');
    }
  }

  render() {
    return (
            <div className="header-new">
            <Row type="flex" align="middle" className="login-top">
                <Col lg={1} />
                 <Col lg={16} md={10} sm={12} xs={12}>
                   <img className="composity-logo" src="https://composity.com/images/org/8f621c.png" alt="Composity logo" />
                 </Col>
                 <Col lg={6} md={12} sm={12} xs={12}>
                   <Link style={{float: "right"}} className="login-button-white" to={'/' + this.props.match.params.db + '/user/signin'}>{plainTranslate(this.props.settings.locale, 'Sign in')}</Link>
                 </Col>
                 <Col lg={1} />
            </Row>    
            <h1 className="text-center" style={{fontWeight: 'bold'}}><Translate locale={this.props.settings.locale} value="Check Your Email"/></h1>
            <h4 className="text-center" style={{fontSize: 20, width: "50%",margin: "auto"}}><Translate locale={this.props.settings.locale} value="We've sent you an email with a link to finish ressetting your password."/></h4>
            <h4 className="text-center" style={{fontSize: 20, width: "50%",margin: "auto"}}><Translate locale={this.props.settings.locale} value="If you still can't log in, contact us at support@composity.com"/></h4>

        </div>
    
    );
  }
}

const mapStateToProps = state => ({
    settings: state.settings,
    auth: state.auth,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordCheck);

