import React from 'react';
import { connect } from 'react-redux';
import Translate, { plainTranslate } from "../../common/translate";
import Collapsible from './collapsible';
import ModalForm from '../../common/ModalForm';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';

class CustomBlocks extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            openNewBlock: false,
            selectedBlock: false,
            error: false,
            blocks: [],
            allBlocks: []
        };
        this.selectNewBlock = this.selectNewBlock.bind(this);
        this.deleteBlock = this.deleteBlock.bind(this);
        this.saveNewBlock = this.saveNewBlock.bind(this);
        this.handleBlockChange = this.handleBlockChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.input.value ? this.props.input.value : [],
            allBlocks: this.props.field.allBlocks,
            selectedBlock: this.props.field.allBlocks.length ? this.props.field.allBlocks[0].id : false,
            blocks: this.props.input.value ? this.props.input.value : []
        });
    }

    deleteBlock(blockId) {
        client
            .post('/' + this.props.db + '/api/crm/list/delete-block/' + this.props.formData.id, { blockId: blockId })
            .then(res => {
                let blocks = this.state.blocks;
                blocks = blocks.filter(block => block.id !== blockId);
                this.setState({ blocks });

                showAToast(plainTranslate(this.props.settings.locale, 'Block successfully deleted!'), 'succSettings', 'success');
            })
            .catch(err => {

            });
    }

    selectNewBlock() {
        this.setState({
            openNewBlock: !this.state.openNewBlock
        });
    }

    handleBlockChange(e) {
        this.setState({
            selectedBlock: e.target.value
        });
    }

    saveNewBlock() {
        this.selectNewBlock();
        client
            .post('/' + this.props.db + '/api/crm/list/save-block/' + this.props.formData.id, { blockId: this.state.selectedBlock })
            .then(response => {
                if ('error' in response) {
                    showAToast(plainTranslate(this.props.settings.locale, 'Error'), 'errSettings', 'error');
                }
                else {
                    let blocks = this.state.blocks;
                    blocks.push(response);
                    this.setState({ blocks });
                    showAToast(plainTranslate(this.props.settings.locale, 'Block successfully saved!'), 'succSettings', 'success');
                }
            })
            .catch(err => {

            });
    }

    render() {
        const element = <div className="col-md-12 no-gutter">
            <div className="col-md-8">
                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                            <tr>
                                <th><Translate locale={this.props.locale} value="Name" /></th>
                                <th style={{ width: '10%' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.blocks.map((block, key) => {
                                return <tr key={key}>
                                    <td>{block.name}</td>
                                    <td><i className="fa fa-trash" onClick={() => this.deleteBlock(block.id)}></i></td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 text-center">
                    <a className="rectangular_button green"
                        href="javascript:void(0)"
                        onClick={this.selectNewBlock}><Translate
                            locale={this.props.locale} value="+ Add new block" /></a>
                </div>
            </div>
        </div>;

        return <React.Fragment>
            {this.props.field.collapsible ?
                <Collapsible
                    locale={this.props.locale}
                    title={false}
                    subtitle={this.props.field.description}>
                    {element}
                </Collapsible>
                : { element }
            }
            <ModalForm onClose={this.selectNewBlock} show={this.state.openNewBlock}>
                <section className="newPanel">
                    <div className="panel-body">
                        <div className="row m-bot15">
                            <div className="text-center">
                                <h3>
                                    <Translate locale={this.props.locale} value="Select block" />
                                </h3>
                                <select className="form-control" onChange={this.handleBlockChange}
                                    selected={this.state.selectedBlock}>
                                    {this.state.allBlocks.length > 0 && this.state.allBlocks.map((block, key) => {
                                        return <option key={key} value={block.id}>{block.name}</option>
                                    })
                                    }
                                </select>
                                <button className="button-primary-edited text-center btn-ok"
                                    onClick={this.saveNewBlock}>
                                    <Translate locale={this.props.locale} value="Add" />
                                </button>
                                <button className="button-outlines green text-center"
                                    onClick={this.selectNewBlock}>
                                    <Translate locale={this.props.locale} value="Close" />
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </ModalForm>
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CustomBlocks);


