import React, { Component } from 'react';
import { plainTranslate } from '../../../common/translate';
import { connect } from 'react-redux';
import SimpleForm from "../../../forms/form";
import Form from "../../../redux-forms/form";
import SlideModal from '../../../common/SlideModal';
import { ACCOUNT_ADD_NEW_DATA_ACCESS, ACCOUNT_ADD_NEW_CONSENT } from "../../../../constants/actionTypes";
import { onTabsLoad } from '../../../../actions/actions';
import { Table } from 'antd';
import FormLoader from '../../../forms/form-loader';
import { withRouter } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

class Consents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenConsent: false,
            isNew: false,
            isAccess: false
        }
        
        this.updateDataAccess = this.updateDataAccess.bind(this);
        this.updateAccountConsents = this.updateAccountConsents.bind(this);
    }
    
    updateDataAccess(data) {
        let result = {
             'id' :data['id'],
             'email' : data['email'],
             'dateExpired' : data['dateExpired'],
             'dateRequested' : data['dateRequested'],
        };
        this.props.onAddNewDataAccess(result);
        this.updateTable();
        this.setState({isAccess: false});
    }
    
    updateAccountConsents(data) {
        let status = "No";
        if (data['status']) {
            status = "Yes"
        };
        let type = "Data processing";
        if (data['type'] == 1) {
            type = "Marketing";
        };

        let result = {
            'id': data.entity['id'],
            'date': data.entity['date'],
            'comment': data.entity['comment'],
            'status': status,
            'type': type,
        };
        this.props.onAddNewConsent(result);
        this.updateTable();
        this.setState({ isOpenConsent: false });
    }

    updateTable = () => {
        this.props.onTabsLoad('/' + this.props.match.params.db + '/api/accounts/tabs/' + this.props.accountId, 1, false);
    }

    render() {
        return (
                <div>
                    <div className="col-sm-12" style={{marginTop: 10}}><h4>{plainTranslate(this.props.settings.locale, 'Consents')}</h4>
                        <a href="#" onClick={() => this.setState({isOpenConsent: true, isNew: 'undefined'})}
                           className="btn-round btn-blue">
                             <PlusOutlined/>
                        </a>
                    </div>
                    <Table 
                        columns={this.props.columnsConsents} 
                        dataSource={this.props.data.dataConsents}
                        pagination={false}
                        size="small"
                    />
                    <div className="col-sm-12" style={{marginTop: 10}}><h4>{plainTranslate(this.props.settings.locale, 'Sent data access')}</h4>
                        <a href="#" 
                        onClick={() => this.setState({isAccess: true, isNew: 'undefined'})} 
                        className="btn-round btn-blue"  
                        title={plainTranslate(this.props.settings.locale, 'Send data access')}>
                        <PlusOutlined/>
                        </a>
                    </div> 
                    <Table 
                        columns={this.props.columnsAccess} 
                        dataSource={this.props.data.dataAccess}
                        pagination={false}
                        size="small"
                    />
                    {
                    this.state.isOpenConsent &&
                    <SlideModal onClose={() => this.setState({isOpenConsent: false, isNew: false})} title={plainTranslate(this.props.settings.locale, 'Account consent')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    {/* <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/account-consents/form/'}
                                        saveRoute={this.props.match.params.db + '/api/account-consents?accountId=' + this.props.accountId}
                                        updateParent={(data) => this.updateAccountConsents(data)}
                                        match={{params: {id: 0}}}
                                        onCancel={() => this.setState({isOpenConsent: false, isNew: false})}
                                        popup={true}
                                    /> */}
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/account-consents/form/'}
                                        saveRoute={this.props.match.params.db + '/api/account-consents'}
                                        popup={true}
                                        onSuccessfulSave={(data) => this.updateAccountConsents(data)}
                                        match={{params: {accountId: this.props.accountId}}}
                                        onCancel={() => this.setState({isOpenConsent: false, isNew: false})}
                                        form={'initializeFromState'}       
                                    />  
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                    }
                    {
                    this.state.isAccess &&
                    <SlideModal onClose={() => this.setState({isAccess: false, isNew: false})} title={plainTranslate(this.props.settings.locale, 'Send data access to')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    {/* <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/data-access/form/'}
                                        saveRoute={this.state.isNew !== 'undefined' ? this.props.match.params.db + '/api/data-access/edit' : this.props.match.params.db + '/api/data-access/new/'+ this.props.accountId}
                                        updateParent={(data) => this.updateDataAccess(data)}
                                        match={{params: {id: 0}}}
                                        onCancel={() => this.setState({isAccess: false, isNew: false})}
                                        popup={true}
                                    /> */}
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/data-access/form/'}
                                        saveRoute={this.state.isNew !== 'undefined' ? this.props.match.params.db + '/api/data-access/edit' : this.props.match.params.db + '/api/data-access/new/'+ this.props.accountId}
                                        onSuccessfulSave={(data) => this.updateDataAccess(data)}
                                        match={{params: {id: 0}}}
                                        onCancel={() => this.setState({isAccess: false, isNew: false})}
                                        popup={true}
                                        form={'initializeFromState'}
                                    />  
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                    }
                </div>
        );
    }
}


const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onAddNewDataAccess: (data) => dispatch({type: ACCOUNT_ADD_NEW_DATA_ACCESS, payload: {dataAccess: data}}),
    onAddNewConsent: (data) => dispatch({type: ACCOUNT_ADD_NEW_CONSENT, payload: {dataConsents: data}}),
    onTabsLoad: (route, page, requiredTotal) => dispatch(onTabsLoad(route, page, requiredTotal))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Consents));


