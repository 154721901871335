import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { 
    ClassicEditor, 
    Essentials, 
    Mention, 
    Paragraph, 
    Undo, 
    SourceEditing, 
    GeneralHtmlSupport, 
    FullPage, 
    Image, 
    ImageInsert, 
    AutoImage, 
    LinkImage, 
    Heading, 
    Font, 
    Bold, 
    Code, 
    Italic, 
    Strikethrough, 
    Subscript, 
    Superscript, 
    Underline,
    Link,
    BlockQuote,
    CodeBlock,
    Autoformat,
    List,
    TodoList,
    Markdown,
    MediaEmbed,
    PageBreak,
    RemoveFormat,
    Table, 
    TableToolbar,
    SimpleUploadAdapter,
    ImageResize,
    Clipboard
} from 'ckeditor5';
import {onFieldSave } from "../../actions/actions";
import {plainTranslate} from '../common/translate';
import showAToast from '../common/showAToast';
import './rteEditor.css';
import {config} from '../../config';
import { PrinterOutlined } from '@ant-design/icons';

const RteEditor = (props) => {
  const [value, setValue] = useState(props.value);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const editorRef = useRef();

    function handlePrint() {
        const editor = editorRef.current.editor;
        const content = editor.getData();

        const printWindow = window.open('', '_blank', 'width=1200,height=800');
            printWindow.document.open();
            printWindow.document.write(`
                <html>
                <head>
                    <title>Print Preview</title>
                </head>
                <body>
                    ${content}
                    <script>
                        window.print();
                        window.onafterprint = window.close;
                    </script>
                </body>
                </html>
            `);
        printWindow.document.close();
    }

    function saveField(e) {
        setLoading(true);

        const editor = editorRef.current.editor;
        const content = editor.getData();

        props.onFieldSave(props.updateRoute, props.id, content, props.additionalData).then((response) =>{
            showAToast(plainTranslate(props.settings.locale, 'Data saved successfully!'), 'succSettings', 'success');
            props.handleClose(true);
            setLoading(false);
        });
    }

    function onChange(){
        const editor = editorRef.current.editor;
        const content = editor.getData();

        if(props.onChange){
            props.onChange(content);
        }
    }

    useEffect(() => { 
        if(value){
            let dom = (new DOMParser()).parseFromString(value, "text/html");
            const elementStyle = dom.style;
            if(dom.getElementsByTagName('style') && dom.getElementsByTagName('style')[0]){
                let styles = dom.getElementsByTagName('style')[0].firstChild.data;

                const styleSheet = document.createElement("style");
                styleSheet.type = "text/css";
                styleSheet.innerText = styles;
                document.head.appendChild(styleSheet);


                return () => {
                    document.head.removeChild(styleSheet);
                };
            }
        }
    }, []);

    function onFocus(e){
        setEditing(true);
    }

    function onBlur(e){
        setEditing(false);
    }

    useEffect(() => {
        if(!editing && editorRef && editorRef.current){
            const editor = editorRef.current.editor;
            if(props.value){
                editor.setData(props.value);
            }
        }
    }, [props.value]);

    let users = props.settings && props.settings.users ? props.settings.users.map(function(value) { return "@"+value['label']; }) : [];

    return (<div>
            {props.settings.db && props.showPrint && 
                <button style={{    position: 'relative', left: -27, top: 40, fontSize: "21.5px"}} onClick={handlePrint}> <PrinterOutlined /> </button>
            }
            {props.settings.db &&
            <CKEditor
                editor={ ClassicEditor }
                onReady={(editor) => {
                    editorRef.current = { editor };
                }}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                config={ {
                    allowedContent: true,  // Allow all content including classes and styles
                    toolbar: {
                        items: [ 'undo', 'redo','|','heading',
                        '|',
                        'bold', 'italic', 'strikethrough', 'code',
                        '|',
                        'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                        '|',
                        'bulletedList', 'numberedList', 'todoList', 'pageBreak', 'removeFormat',
                        '|',
                        'link', 'insertImage', 'blockQuote', 'codeBlock', 'mediaEmbed' ,'insertTable',
                        '|',
                        'sourceEditing'],
                    },
                    plugins: [
                        Essentials, Mention, Paragraph, Undo, SourceEditing, GeneralHtmlSupport, FullPage, Image, ImageInsert, AutoImage, LinkImage, Heading, Font, Bold, Code, Italic, Strikethrough, Subscript, Superscript, Underline, Link, BlockQuote, CodeBlock,
Autoformat, List, TodoList, Markdown, MediaEmbed, PageBreak, RemoveFormat, Table, TableToolbar, SimpleUploadAdapter, ImageResize, Clipboard
                    ],
                    htmlSupport: {
                        allow: [
                            {
                                name: /.*/,
                                attributes: true,
                                classes: true,
                                styles: true
                            }
                        ]
                    },
                    mention: {
                        feeds: [
                            {
                                marker: '@',
                                feed:  users,
                                minimumCharacters: 1
                            }
                        ]
                    },
                    table: {
                        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                    },
                    simpleUpload: {
                        // The URL that the images are uploaded to.
                        uploadUrl: process.env[config] +'/' + props.settings.db + '/api/settings/image/upload/rte',

                        // Enable the XMLHttpRequest.withCredentials property.
                        withCredentials: false,

                        // Headers sent along with the XMLHttpRequest to the upload server.
                        headers: {
                            Authorization: 'Bearer '+localStorage.jwtToken,
                            'Access-Control-Allow-Origin' : '*',
                        }
                    },
                    initialData: value,
                } }
            />}
            {props.showButtons &&
                <div>
                    <button disabled = {loading ? 'disabled' : ''} className={"button-primary " + (loading ? 'disabled' : '')} type="button" onClick={saveField}>
                        {plainTranslate(props.settings.locale, 'Save')}
                    </button>
                    <button className='button-outline grey' type="button" onClick={() => props.handleClose()}>
                        {plainTranslate(props.settings.locale, 'Cancel')}
                    </button>
                </div>
            }
        </div>
  );
};

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RteEditor);
