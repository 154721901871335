import React from 'react';
import {connect} from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import client from '../../../actions/client'

class Tinymce extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ''
        }
    }

    componentDidMount() {            
      if(this.state.value === '' && typeof this.props.data !== 'undefined'){    
          this.setState({
              value: this.props.data ? this.props.data : ''
          });
      }
  }
    onEditorChange = (value) => {
      this.setState({ value: value });
      //  e.target.setContent(e.target.getContent())
        this.props.change(this.props.field.id, value);
    }

    imageUpload = (blobInfo, success, failure, progress) => {
       
        var formData = new FormData();
          formData.append('file', blobInfo.blob(), blobInfo.filename());
          client
          .post('/' + this.props.db + '/api/settings/image/upload/tinymce', formData)
          .then(res => {
            success(res.data.location)
          })
          .catch(err => {
            failure('HTTP Error: ' + err.response.code);
          })      
      }

    render() {
        return <Editor 
        apiKey='9e6m6n8f3yaairxxedx81e03olfhzeht5oubgv38egohvg5z'
        content={this.state.value}
        init={{
          plugins: 'link image imagetools code directionality lists advlist media emoticons table preview print searchreplace hr wordcount',
          file_picker_types: 'file image media',
        //  images_upload_url: config.API_URL+'/' + this.props.db + '/api/settings/image/upload/tinymce',
          images_upload_handler: this.imageUpload,
          directionality: "rtl",
          relative_urls: false,
          toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code | bullist numlist outdent indent | undo redo | image code | media',
          height : this.props && this.props.field && this.props.field.settings && this.props.field.settings.height ? this.props.field.settings.height : "200",
          forced_root_block : "", 
          force_br_newlines : true,
          force_p_newlines : false
        }}
        onEditorChange={this.onEditorChange}
        />
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Tinymce);
