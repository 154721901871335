import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";
import SlideModal from '../../common/SlideModal';
import Field from '../field';
import SimpleForm from "../../forms/form"; 
import {Field as FormField } from 'redux-form';
import {onFieldChange} from "../../../actions/actions";
import { Menu, Dropdown } from "antd";
import { Link } from 'react-router-dom';

class TabBox extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tabs: [],
            billingDataForm: null
        };
        this.calculateOperation = this.calculateOperation.bind(this);
        this.newBillingData = this.newBillingData.bind(this);
        this.onNewOptions = this.onNewOptions.bind(this);
    }

    componentDidMount() {
        this.setState({
            tabs: this.props.tabs ? this.props.tabs : '',
            currentTab: 0
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.tabs) !== JSON.stringify(this.props.tabs)) {
            this.setState({
                tabs: this.props.tabs ? this.props.tabs : ''
            });
        } 
    }
    
    onNewOptions(fieldId, options, addValue) {
        if (addValue) {
            this.props.change(fieldId, addValue.value);
            this.props.change(fieldId + 'AdditionalValue', addValue);
        }     
    }

    calculateOperation(operation, item) {
        if (operation.type === 'sumList') {
            let sum = 0;
            if (operation.list in this.props.formData) {
                this.props.formData[operation.list].map(item => {
                    if ('operation' in operation) {
                        sum += this.calculateOperation(operation.operation, item);
                    }
                });
            }

            if ('decimalPoint' in operation){

                let multiplier =  "e+"+operation.decimalPoint;
                let multiplier2 =  "e-"+operation.decimalPoint;
                
                return this.addZeroes(parseFloat(Math.sign(sum)*(Math.round(Math.abs(sum) + multiplier)+multiplier2)), operation.decimalPoint);
            }
            return sum;
        }
        if (operation.type === 'multiply') {
            let field1 = operation.field1;
            let field2 = operation.field2;
            
            if (field1.type === 'additionalValue') field1 = field1.populateFrom in item && field1.name in item[field1.populateFrom] ? item[field1.populateFrom][field1.name] ? item[field1.populateFrom][field1.name] : 0 : 0;
            else if (field1.type === 'formValue') field1 = field1.name in item ? item[field1.name] : 0;
            if (field2.type === 'additionalValue') field2 = field2.populateFrom in item && field2.name in item[field2.populateFrom] ? item[field2.populateFrom][field2.name] ? item[field2.populateFrom][field2.name] : 0 : 0;
            else if (field2.type === 'formValue') field2 = field2.name in item ? item[field2.name] : 0;

            if ('decimalPoint' in operation){
                let multiplier =  "e+"+operation.decimalPoint;
                let multiplier2 =  "e-"+operation.decimalPoint;
                
                return this.addZeroes(parseFloat(Math.sign(field1 * field2)*(Math.round(Math.abs(field1 * field2) + multiplier)+multiplier2)), operation.decimalPoint);

            }

            return field1 * field2;
        }
    }
    
    onChangeMainOptions(fieldId, options, group) {
        let arr = (this.props.fields);
        let obj = arr.find(o => o.id === group);
        let push = false;
        let field = null;

        if (obj) {
            if (obj.hasOwnProperty('id')) {
                if (obj['id'] == 'billingData') {
                    field = obj.tabs[0].fields.find(o => o.id === fieldId);
                    push = true;

                    if(fieldId === 'addrId' || fieldId === 'contId'){
                        push = false;
                    }
                } else {
                    field = (obj.fields).find(o => o.id === fieldId);
                }
            }
        }

        if (field) {
            if (Object.keys(options).length > 0) {
                if (!push) {
                     field.options = options;
                } else {
                    if (!(field.options.find(o => o.value ===options.value))) {
                        field.options.push(options);
                    }
                }
            } else {
                field.options = [];
            }
        }
    }

    getContent() {
        const currentTab = this.state.currentTab;
        let content = 'no content';       
        if (currentTab in this.state.tabs && 'fields' in this.state.tabs[currentTab]) {           
            content = this.state.tabs[currentTab].fields.map((field, key) => {
                if (field.type === 'label') {
                    return <div className={'col-md-12 col-xs-12'} key={key}>
                        {
                            'label' in field &&
                            <div className="col-xs-4 col-sm-4"
                                 style={{borderBottom: field.underline ? '1px solid #ccc' : ''}}>
                                <h5>
                                    {plainTranslate(this.props.settings.locale, field.label)}
                                </h5>
                            </div>
                        }
                        <div className={'label' in field ? "col-xs-8 col-sm-8" : "col-xs-12 col-sm-12"}
                            style={{borderBottom: field.underline ? '1px solid #ccc' : ''}}>
                            <h5 className={('label' in field ? 'text-left ' : '') + "text-bold"}>
                                {'operation' in field && this.calculateOperation(field.operation)}
                                {'populateFrom' in field && field.populateFrom in this.props.formData && this.props.formData[field.populateFrom] && field.id in this.props.formData[field.populateFrom] ? 
                                    <span style={{color: 'black'}}>{this.props.formData[field.populateFrom][field.id]}</span>
                                    : 
                                    null
                                }

                                {'localValue' in field
                                && this.props.additionalData
                                && field.localValue in this.props.additionalData
                                && field.id in this.props.additionalData[field.localValue]
                                    ?
                                    this.props.additionalData[field.localValue][field.id]
                                    :
                                    null}
                            </h5>
                        </div>
                    </div>
                } else {
                    return <FormField
                                locale={this.props.settings.locale}
                                name={field.id}
                                label={field.label}
                                field={field}
                                component={Field}
                                formFields={this.props.fields}
                                fields={this.state.tabs[currentTab].fields}
                                change={this.props.change}
                                type={field.type}
                                onNewOptions={(options, addValue) => this.onNewOptions(field.id, options, addValue)}
                                onChangeMainOptions={(field, options, group) => this.onChangeMainOptions(field, options, group)}
                                placeholder={field.placeholder}
                            />
                }
            });
        }
        return content;
    }

    changeTab(key) {
        this.setState({currentTab: key});
    }

    buttonAction(action) {
        if (!action) return;

        if (action.reactMethod) {
            if(action.reactMethod === 'newCustomer') {
                this.setState({billingDataForm: this.state.billingDataForm ? null : action});
            }
        }
    }

    newBillingData(data) {
        data = data.entity;
        data['value']=data?.billingData?.id;
        this.setState({billingDataForm: false});
        let fproperties = this.props.fields;
        let index;
        let ind;
        for (ind = 0; ind < fproperties[0].tabs[0].fields.length; ++ind) {
            if (fproperties[0].tabs[0].fields[ind].id == 'custId') {
                    index = ind
                }
        }
        fproperties[0].tabs[0].fields[index].options.push(data);
        this.props.change('custId', data.id);
        this.props.change('custIdAdditionalValue', data);
    }

    render() { 
        let menuOptions = null;
        if (this.props.tabs && this.state.currentTab in this.state.tabs && 'dropdown' in this.state.tabs[this.state.currentTab] &&
        'options' in this.state.tabs[this.state.currentTab].dropdown) {
            menuOptions = (
                <Menu>
                   {this.state.tabs[this.state.currentTab].dropdown.options?.map((item, i) => {
                        return <Menu.Item key={i}>
                            <a onClick={() => this.setState({ billingDataForm: item.value })}>
                                {plainTranslate(this.props.settings.locale, item.label)}
                            </a>
                        </Menu.Item>
                    })}
                </Menu>
        );
        }
    
        return <div className="panel total" style={{marginTop: '0px'}}>
            {
                this.state.billingDataForm &&
                <SlideModal onClose={() => this.setState({billingDataForm: null})} title={plainTranslate(this.props.settings.locale, this.state.tabs[this.state.currentTab].label)}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <SimpleForm
                                    saveRoute={'/' + this.props.db + '/api/accounts/new?id=' + this.state.billingDataForm}
                                    formRoute={'/' + this.props.db + '/api/account/form/' + this.state.billingDataForm}
                                    // match={{params: {id: 0}}}
                                    onSuccessfulSave={(data) => this.newBillingData(data)}
                                    popup={true}
                                    // isNew={true}
                                    onCancel={() => this.buttonAction({reactMethod: 'newCustomer'})}
                                />
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }

            <header className="panel-heading" style={{display: 'block', marginBottom: '10px'}}>
                {
                    this.state.tabs && this.state.tabs.map((tab, key) => {
                        return <span className={this.state.currentTab != key ? 'text-grey':''} key={key}
                                     onClick={() => this.changeTab(key)}>{plainTranslate(this.props.settings.locale, tab.label)} {key !== this.state.tabs.length - 1 ? <span> | </span> : ''}</span>
                    })
                }
                {
                    this.props.tabs && this.state.currentTab in this.state.tabs && 'button' in this.state.tabs[this.state.currentTab] &&
                    <a className={'pull-right add-customer-label'} 
                          onClick={() => this.buttonAction(this.state.tabs[this.state.currentTab].button.action)}>
                            {'icon' in this.state.tabs[this.state.currentTab].button &&
                            <i className={this.state.tabs[this.state.currentTab].button.icon}></i>}
                        {'label' in this.state.tabs[this.state.currentTab].button &&
                        <p>{plainTranslate(this.props.settings.locale, this.state.tabs[this.state.currentTab].button.label)}</p>}
                        </a>
                }
                {this.props.tabs && this.state.currentTab in this.state.tabs && 'dropdown' in this.state.tabs[this.state.currentTab] &&
                    <Dropdown overlay={menuOptions} className="pull-right add-customer-label">
                        <a href="#" data-toggle="dropdown" title={'label' in this.state.tabs[this.state.currentTab].dropdown && plainTranslate(this.props.settings.locale, this.state.tabs[this.state.currentTab].dropdown.label)}>
                            {'icon' in this.state.tabs[this.state.currentTab].dropdown &&
                                <i className={this.state.tabs[this.state.currentTab].dropdown.icon}></i>}
                            {'label' in this.state.tabs[this.state.currentTab].dropdown &&
                                <p>{plainTranslate(this.props.settings.locale, this.state.tabs[this.state.currentTab].dropdown.label)}</p>}
                        </a>
                    </Dropdown>
                }
            </header>
            {this.getContent()}
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onUpdateField: (formId, key, value) => dispatch(onFieldChange(formId, key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabBox);
