import {
    UPLOAD_GET_COLUMNS,
    GET_ITEM
} from "../constants/actionTypes";

export default (state = [], action) => {

    switch (action.type) {

        case UPLOAD_GET_COLUMNS:
	      return {
	        ...state,
	        columns: action.payload.data
	      };

        case GET_ITEM:
	       return {
            ...state,
            item: action.payload
          };

        default:
            return state;
    }
};