import {
    МАКЕ_PAYMENT_DATA_LOADED
} from "../constants/actionTypes";

export default (state = [], action) => {

    switch (action.type) {
                    
        case МАКЕ_PAYMENT_DATA_LOADED:
	      return {
	        ...state,
                ...action.payload.data
	      };
              
        default:
            return state;
    }
};