import React from 'react';
import {connect} from 'react-redux';
import SlideModal from '../../common/SlideModal';
import SimpleForm from "../../forms/form";
import {plainTranslate} from "../../common/translate";
import { withRouter } from 'react-router-dom'
import { onUserRolesGridRemove } from '../../../actions/actions';

class AddMeasureConversion extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modalTranslation: false
        };
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal(){
        this.setState({
            modalTranslation: false
        });
    }

    render() {
        return <div >
               <i className={!this.props.formData.form.initializeFromState2.values[this.props.field.dependant] || this.props.field.hide ? 'hidden' : 'fa fa-balance-scale'} aria-hidden="true" title="Add measure conversion" onClick={() => this.setState({modalTranslation: true})}></i>
                {
                        this.state.modalTranslation &&
                            <SlideModal onClose={() => this.setState({modalTranslation: false})} title={plainTranslate(this.props.settings.locale, 'Measure conversions')}>
                                        <div className="convertion-form">
                                            <SimpleForm
                                                saveRoute={this.props.db + '/api/item-measure-conversions/'}
                                                formRoute={this.props.db + '/api/item-measure-conversions/form'}
                                                match={{params: {id: 0}}}
                                                onSuccessfulSave={this.closeModal}
                                                refs = {this.props.formData.form.initializeFromState2.values.id + '&measure=' + this.props.formData.form.initializeFromState2.values[this.props.field.dependant]}
                                                popup={true}
                                                isNew={true}
                                                onCancel={() => this.setState({modalTranslation: false})}
                                            />
                                        </div>
                            </SlideModal>
                    }
                    </div>
                }
            }

const mapStateToProps = state => ({
    settings: state.settings,
    formData: state,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddMeasureConversion);
