import client from './client';
import {
    SETTINGS_LOAD,
} from "../constants/actionTypes";
import showAToast from '../components/common/showAToast'
import {logoutUser} from './authActions';

export const getSettings = (db) => dispatch => {
    client
          .get('/'+db+'/api/initials/settings')
          .then(res => {  
            dispatch({
                type: SETTINGS_LOAD,
                payload: res.data
            });
           
            if(res.data.locale){
                localStorage.setItem('locale', res.data.locale);
            }
            
          })
          .catch(err => {
                var error = 'Error!';             
                if(err.response && err.response.data.code && err.response.data.code === 401){
                    dispatch(logoutUser(db));           
                }
                if(err.response && err.response.data.message){
                    error = err.response.data.message;
                }
                showAToast(error, 'errSettings', 'error')
          });

}

