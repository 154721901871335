import {
    GET_DOCUMENTS,
    LOAD_UPLOAD,
    POPUP_MESSAGE_SHOW,
    POPUP_MESSAGE_HIDE,
    GET_FOLDER_OPTIONS
} from "../constants/actionTypes";
import axiosFileApi from './axiosFileApi';
//import showAToast from '../components/common/showAToast'

export const getDocuments = (type, pageFiles, pageFolders, id = null) => dispatch => {  
    var route = '/api/user/get/documents/'+pageFiles+'/'+pageFolders+'/'+type;   
    if(id){
        route = '/api/user/get/documents/'+pageFiles+'/'+pageFolders+'/'+type+'/'+id
    } 
  axiosFileApi
    .get(route)
    .then(res => {         
      dispatch({
        type: GET_DOCUMENTS,
        payload: res.data
      })
  })
    .catch(err => {       
      dispatch({
        type: GET_DOCUMENTS,
        payload: {}
      })
       
  });
};

export const getFolderOptions = (value = null) => dispatch => {
    var route = '/api/user/get/options/folders';
    if(value){
        route = route+'?search='+value
    }
  axiosFileApi
    .get(route)
    .then(res => {         
      dispatch({
        type: GET_FOLDER_OPTIONS,
        payload: res.data
      })
  })
    .catch(err => {       
      dispatch({
        type: GET_FOLDER_OPTIONS,
        payload: {}
      })
       
  });
}

export const getDocumentsFolders = (type, id = null) => dispatch => {  
    var route = '/api/user/get/documents/'+type;   
    if(id){
        route = '/api/user/get/documents/'+type+'/'+id
    } 
  axiosFileApi
    .get(route)
    .then(res => {         
      dispatch({
        type: GET_DOCUMENTS,
        payload: res.data
      })
  })
    .catch(err => {       
      dispatch({
        type: GET_DOCUMENTS,
        payload: {}
      })
       
  });
};


export const uploadFiles = (form) => dispatch => {
        return new Promise((resolve, reject) => {
            axiosFileApi
                .post("/api/user/files/upload-multiple/3", form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    loadUpload(false)
                    // showAToast('Upload success!', 'successUpload', 'success')
                    var message = 'Upload success!';
                    
                    if (res.data.message) {
                        message = res.data.message;
                    }

                    dispatch({
                        type: POPUP_MESSAGE_SHOW,
                        payload: {
                            text: message,
                            isError: false
                        }
                    });
                    resolve(res.data);
                    setTimeout(() => dispatch({ type: POPUP_MESSAGE_HIDE }), 5000);
                })
                .catch(err => {
                    var error = 'Upload error - your file is too big or in wrong format.';
                    if (err && err.response && err.response.data && err.response.data.message) {
                        error = err.response.data.message;
                    }
                    dispatch({
                        type: POPUP_MESSAGE_SHOW,
                        payload: {
                            text: error,
                            isError: true
                        }
                    });
                    setTimeout(() => dispatch({ type: POPUP_MESSAGE_HIDE }), 5000);
                });
        })
};

export const createFolder = (db, name, parentId = null) => dispatch => {
    return new Promise((resolve, reject) => {
        var route = '/api/user/create/folder'
        if (parentId) {
            route = '/api/user/create/folder-child/' + parentId;
        }
        axiosFileApi
            .post(route, { name: name, tenant: db })
            .then(res => {
                var message = 'Success!';
                if (res.data.message) {
                    message = res.data.message;
                }
                dispatch({
                    type: POPUP_MESSAGE_SHOW,
                    payload: {
                        text: message,
                        isError: false
                    }
                });
                resolve(res.data);
                setTimeout(() => dispatch({ type: POPUP_MESSAGE_HIDE }), 5000);
            })
            .catch(err => {
                var error = 'Error!';
                if (err && err.response && err.response.data && err.response.data.message) {
                    error = err.response.data.message;
                }
                dispatch({
                    type: POPUP_MESSAGE_SHOW,
                    payload: {
                        text: error,
                        isError: true
                    }
                });
                resolve({'error': error});
                setTimeout(() => dispatch({ type: POPUP_MESSAGE_HIDE }), 5000);
                //  showAToast(error, 'errCreateFolder', 'error')
            });
    })
}

export const editFolder = (db, name, id) => dispatch => {
     axiosFileApi
           .post('/api/user/edit/folder/' + id, {name: name, tenant: db})
           .then(res => {  
                  var message = 'Success!';
               if(res.data.message){
                   message = res.data.message;
               }              
                       dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: message,
                                    isError: false
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
           })
           .catch(err => {
                 var error = 'Error!';
       if(err.response.data.message){
           error = err.response.data.message;
       }
                       dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: error,
                                    isError: true
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
           });

}

export const deleteFolder = (id) => dispatch => {
     axiosFileApi
           .get('/api/user/delete/folder/' + id)
           .then(res => {  
                  var message = 'Success!';
               if(res.data.message){
                   message = res.data.message;
               }              
                       dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: message,
                                    isError: false
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
           })
           .catch(err => {
                 var error = 'Error!';
       if(err.response.data.message){
           error = err.response.data.message;
       }
                       dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: error,
                                    isError: true
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
           });

}

export const editFile = (db, id, privacyValue, name, folderId) => dispatch => {
   
     axiosFileApi
           .post('/api/user/files/change/file/privacy', {privacyValue: privacyValue, name: name, fileId: id, tenant: db, folderId: folderId})
           .then(res => {  
                  var message = 'Success!';
               if(res.data.message){
                   message = res.data.message;
               }              
                       dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: message,
                                    isError: false
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
           })
           .catch(err => {
                 var error = 'Error!';
       if(err.response.data.message){
           error = err.response.data.message;
       }
                       dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: error,
                                    isError: true
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
           });

}

export const deleteFile = (id) => dispatch => {
     axiosFileApi
           .get('/api/user/delete/file/' + id)
           .then(res => {  
                  var message = 'Success!';
               if(res.data.message){
                   message = res.data.message;
               }              
                       dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: message,
                                    isError: false
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
           })
           .catch(err => {
               var error = 'Error!';
       if(err.response.data.message){
           error = err.response.data.message;
       }
                       dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: error,
                                    isError: true
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
           });

}

export const downloadFile = (id, name) => dispatch => {  
            axiosFileApi
                .get('/api/user/documents/files/'+id+'/download', {responseType: 'blob'})
                .then(res => {                   
                   var blob = new Blob([res.data], {type: res.headers['content-type']});
                    var downloadUrl = URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = downloadUrl;
                    a.download = name;
                    document.body.appendChild(a);
                    a.click();
                })
               .catch(err => {         
                    console.log('Download error!', {autoClose: 10000})
              });
};

export const restoreFile = (id) => dispatch => {
     axiosFileApi
           .get('/api/user/restore/file/' + id)
           .then(res => {  
                  var message = 'Success!';
               if(res.data.message){
                   message = res.data.message;
               }              
                       dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: message,
                                    isError: false
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
           })
           .catch(err => {
                 var error = 'Error!';
       if(err.response.data.message){
           error = err.response.data.message;
       }
                       dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: error,
                                    isError: true
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
           });

}

export const restoreFolder = (id) => dispatch => {
     axiosFileApi
           .get('/api/user/restore/folder/' + id)
           .then(res => {  
                  var message = 'Success!';
               if(res.data.message){
                   message = res.data.message;
               }              
                       dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: message,
                                    isError: false
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
           })
           .catch(err => {
               var error = 'Error!';
       if(err.response.data.message){
           error = err.response.data.message;
       }
                       dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: error,
                                    isError: true
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
           });

}

export const loadUpload = (load) => dispatch => { 
            dispatch({
                type: LOAD_UPLOAD,
                payload: load
            });
};
