import React, { Component } from 'react';
import { connect } from 'react-redux';
import { plainTranslate } from '../common/translate';
import { Link } from 'react-router-dom';

class SubscriptionDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

    render() {

        return (
            <section className="panel upgrade-account"style={{height: "550px", margin:"30px 50px",width: "95%"}}>
                <div style={{margin: "0 auto", top: "200px", position: "relative", width: "1000px"}}>
                    <div className="row text-center red">
                        <h3>{plainTranslate(this.props.settings.locale, 'To purchase a Composity subscription, please')} <a href="mailto:sales@composity.com">{plainTranslate(this.props.settings.locale, 'contact us')}</a> {plainTranslate(this.props.settings.locale, 'or call')} <a href="tel:+359888259239">+359888259239</a></h3>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetails);