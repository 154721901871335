import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../common/translate';
import ModalConfirm from '../common/ModalConfirm';
import {onShowColumns, onCheckColumn, onColumnUpdate} from '../../actions/actions';
import {Link} from 'react-router-dom';

class Columns extends Component {

    constructor(props) {
        super(props);

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleEscClick = this.handleEscClick.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.showColumnsModal = this.showColumnsModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleEscClick);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.target.className !== "modalConfirms scroll column-selector ") {
            this.props.onShowColumns();
        }
    }

    handleEscClick(e) {
        if (e.keyCode === 27) {
            this.props.onShowColumns();
        }
    }

    showColumnsModal() {
        this.props.onShowColumns();
    }

    handleInputChange(field, show) {
        this.props.cols.setColumnVisible(field, show);
        var columnState = JSON.stringify(this.props.cols.getColumnState());
        this.props.updateGridColumns();
        this.props.onColumnUpdate(this.props.gridProperties.options.enableColumns, columnState);
    }
        
    render() {
        return (
            <React.Fragment>
            <div className="col-md-6 columns-button">
                <div className="columns-button-open" onClick={this.showColumnsModal}>
                    <a href="#" className="" title={plainTranslate(this.props.locale, 'Add columns')}>
                        <i className="fa fa-table" aria-hidden="true"></i>
                    </a>   
                </div>     
                {
                    this.props.gridProperties.showColumns &&
                    <ModalConfirm onClose={this.showColumnsModal}
                                  customClass={"column-selector"}
                                 >    
                        <section className="newPanel"
                                 ref={this.setWrapperRef}>

                            <div className="panel-body">
                                <div className="row">
                                    <ul className="">
                                        {this.props.columns && this.props.columns.map((item, key) => {
                                            let headerName = '';
                                            let obj = this.props.columnsDefault.find((val, key) => {
                                                if (val.hasOwnProperty('field')) {
                                                    if (val.field == item.colId) {
                                                        headerName = this.props.columnsDefault[key].headerName;
                                                    }
                                                } else {
                                                    val.children.find((child, chkey) => {
                                                            if (child.field == item.colId) {
                                                                headerName = this.props.columnsDefault[key].children[chkey].headerName;
                                                            }
                                                        })
                                                }
                                                 
                                                })
                                            
                                            return !('hideFromList' in item) &&
                                                <div key={key}  className="column-selector-item">
                                                            <li key={key} className="">
                                                                <label className="custom-checkbox">
                                                                    <Translate locale={this.props.settings.locale}
                                                                    value={headerName}
                                                                               />
                                                                    <input name={item.colId} type="checkbox" key={key}
                                                                           onChange={() => this.handleInputChange(item.colId, item.hide)}
                                                                           checked={!item.hide}/>
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </li>                                         
                                                </div>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </ModalConfirm>
                }
            </div>
            {this.props.gridProperties.header.settingsPath && this.props.settings.roles.includes('ROLE_SUPER_ADMIN') && !this.props.gridProperties.header.settingsNotReact &&
                <div className="col-md-6">
                    <Link to={this.props.gridProperties.header.settingsPath} className="grid-settings" title={plainTranslate(this.props.locale, 'Settings')}>
                        <i className="fa fa-cogs" aria-hidden="true"></i>
                    </Link>
                </div>
            }
            {this.props.gridProperties.header.settingsPath && this.props.settings.roles.includes('ROLE_SUPER_ADMIN') && this.props.gridProperties.header.settingsNotReact &&
                <div className="col-md-6">
                    <a href={this.props.gridProperties.header.settingsPath} className="grid-settings" title={plainTranslate(this.props.locale, 'Settings')}>
                        <i className="fa fa-cogs" aria-hidden="true"></i>
                    </a>
                </div>
            }
        </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    gridProperties: state.gridProperties,
    gridData: state.data
});

const mapDispatchToProps = dispatch => ({
    onShowColumns: () => dispatch(onShowColumns()),
    onCheckColumn: (key, childKey) => dispatch(onCheckColumn(key, childKey)),
    onColumnUpdate: (route, cols) => dispatch(onColumnUpdate(route, cols))
});

export default connect(mapStateToProps, mapDispatchToProps)(Columns);

