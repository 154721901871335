import React from 'react';
import {connect} from 'react-redux';
import "./tableCollection.css";
import Text from "./text";
import Number from './number';
import Select2 from "./select2";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import Translate, {plainTranslate } from "../../common/translate";
import SlideModal from '../../common/SlideModal';
import SimpleForm from "../../formnew/Forms";
import Field from '../field';
import {onAddSelectOption} from "../../../actions/actions";
import {Field as FormField, reduxForm, FieldArray} from 'redux-form';
import RenderLines from './renderLines';
import RenderMobileLines from './renderMobileLines';
import RenderPosLines from './renderPosLines';
import ImportLines from '../../import/importLines';
import SearchItem from './searchItem';
import LineField from './lineField';
import SearchDocuments from './searchDocuments';
import BarcodeReader from 'react-barcode-reader'
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import 'antd/lib/spin/style/index.css';
import { Spin, InputNumber, Input, Row, Col, Button, Dropdown, Select, Checkbox, Menu } from 'antd';
import 'antd/lib/input-number/style/index.css';
import {StickyGridList } from './RenderRows';
import showAToast from '../../common/showAToast';
import { withRouter } from 'react-router-dom';
import client from '../../../actions/client';
import { SearchOutlined } from '@ant-design/icons';
import AsyncSelect from 'react-select/lib/Async';
import moment from 'moment';
const InputGroup = Input.Group;
const { Option } = Select;

class TableCollection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lines:[{}],
            mobileLines: [],
            errors: [],
            itemForm: false,
            importForm: false,
            batchForm: false,
            searchItem: false,
            searchValue: null,
            documentsSearch: false,
            showScanLoader: false,
            scannedItemQuantity: 1,
            rowCount: '',
            showCopyLinePopup: false,
            distributableModal: false,
            distributableCost: {},
            searchLine: null,
            currentLine: null
        };
       
        this.listRef = React.createRef();

        this.removeLine = this.removeLine.bind(this);
        this.splitLine = this.splitLine.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.newItem = this.newItem.bind(this);
        this.newBatch = this.newBatch.bind(this);
        this.buttonAction = this.buttonAction.bind(this);
        this.calculateOperation = this.calculateOperation.bind(this);
        this.addLine = this.addLine.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.saveMobileLine = this.saveMobileLine.bind(this);
        this.editMobileLine = this.editMobileLine.bind(this);
        this.onEnterClickSearchItem = this.onEnterClickSearchItem.bind(this);
        this.closeSearchPopup = this.closeSearchPopup.bind(this);
        this.checkParams = this.checkParams.bind(this);
        this.addDocumentRow = this.addDocumentRow.bind(this);
        this.addItemRows = this.addItemRows.bind(this);
        this.isEquivalent = this.isEquivalent.bind(this);
        this.handleScan = this.handleScan.bind(this);
        this.handleEscClick = this.handleEscClick.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.addZeroes = this.addZeroes.bind(this);
        this.onScanQuantityChange = this.onScanQuantityChange.bind(this);
        this.openScanPopup = this.openScanPopup.bind(this);
        this.closeScanPopup = this.closeScanPopup.bind(this);
        this.scrollToRow = this.scrollToRow.bind(this);
        this.handleError = this.handleError.bind(this);
        this.goToItem = this.goToItem.bind(this);
        this.scrollToRowChange = this.scrollToRowChange.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.addItemAction = this.addItemAction.bind(this);
        this.showCopyLine = this.showCopyLine.bind(this);
        this.copyLine = this.copyLine.bind(this);
        this.loadExpenseOptions = this.loadExpenseOptions.bind(this);
        this.addAddExpense = this.addAddExpense.bind(this);
        this.saveExpense = this.saveExpense.bind(this);
        this.changeDistributeType = this.changeDistributeType.bind(this);
        this.recalculateCosts = this.recalculateCosts.bind(this);
        this.changeExpense = this.changeExpense.bind(this);
    }


    componentDidMount() {
        let lines = this.props.lines ? this.props.lines : [];
        if (lines.length == 0) {
            lines.push({});
        }
        if (this.props.formData.distributableCost && this.props.field.distributableCost) {
            this.setState({distributableCost: JSON.parse(this.props.formData.distributableCost)}, function(){
                this.saveExpense();
            })
        } else {
            this.state.distributableCost.docExpenses = [];
            this.state.distributableCost.addExpenses = [];
        }
        
        this.setState({
            lines: lines ? lines : [],
            mobileLines: lines
        }, function() {
            if (this.state.distributableCost.docExpenses.length !== 0 || this.state.distributableCost.addExpenses.length !== 0) {
                this.recalculateCosts();
            }
        });
    }


    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.lines) !== JSON.stringify(this.props.lines) || JSON.stringify(prevProps.errors) !== JSON.stringify(this.props.errors) && JSON.stringify(prevState.mobileLines) !== JSON.stringify(this.state.mobileLines)) {
            let lines = this.props.lines ? this.props.lines : [];
            if(window.innerWidth > 780){
                this.setState({
                    lines: lines ? lines : [],
                    mobileLines: lines,
                    errors: this.props.errors
                }, function() {
                    if (this.state.distributableCost.docExpenses.length !== 0 || this.state.distributableCost.addExpenses.length !== 0) {
                        this.recalculateCosts();
                    }
                });
            }
        }
        if(this.props.field.type === 'tableCollection' && this.props.formData && this.props.formData[this.props.field.id] && this.props.formData[this.props.field.id].length > 0){
       
            this.props.lines.map((line, index) => {      
            this.props.columns.map((column, colKey) => {        
                if ('operation' in column) {                                         
                      var  fieldValue = this.calculateOperation(column.operation, line);
                     
                      this.props.changeFieldValueLine(column.id, fieldValue, index)
                      this.props.change(this.props.field.id+'['+index+'].'+column.id, fieldValue);
                }            
            })    
        })
    }
    if (prevState.distributableModal !== this.state.distributableModal && this.state.distributableModal == true) {
        this.loadExpenseOptions(null);
    }
    
    }

    handleScroll (event) {       
       // event.stopPropagation();
      };
    
    goToItem(event){
        var result = []
     this.props.formData[this.props.field.id].map((o, i) => {
        if(o.itName === event.target.value && typeof o.itName !== 'undefined'){           
            result.push(i);
            }
        })
   
        var scrollTop;
        if(this.props.lines.length > 15){
            var lastPosibleRow = this.props.lines.length - 14;
            if(result.length > 0) {
            var lastPosibleRow = this.props.lines.length - 14;
                if(result[0] > lastPosibleRow){
                    scrollTop = lastPosibleRow * 40;  
                }else{
                    scrollTop = result[0] * 40;   
                }          
                this.listRef.current.scrollTo({scrollTop: scrollTop, scrollLeft: 0});           
            }
         }
    }
    
    scrollToRowChange(event){   
        var length =  event.target.value ? parseInt(event.target.value) : '';
        if(length > this.state.lines.length){
            length = this.state.lines.length > 0 ? this.state.lines.length : ''        
        }       
        this.setState({
            rowCount: length
        })
    }
    
    scrollToRow(e){ 
        e.preventDefault()
        var scrollTop;
        if(this.props.lines.length > 15){
        var lastPosibleRow = this.props.lines.length - 14;
        if(this.state.rowCount > 0) {
        var lastPosibleRow = this.props.lines.length - 14;
            if(this.state.rowCount > lastPosibleRow){
                scrollTop = lastPosibleRow * 40;  
            }else{
                scrollTop = this.state.rowCount * 40;   
            }                
            scrollTop = scrollTop - 40
            var scrollLeft = 0          
            this.listRef.current.scrollTo({scrollTop: scrollTop, scrollLeft: 0});           
        }
    }
    }

    isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    for (var i = 0; i < aProps.length-2; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
    }

    setWrapperRef(node) {
      this.wrapperRef = node;
      //document.addEventListener('mousedown', this.handleClickOutside);
      document.addEventListener('keydown', this.handleEscClick);
    }

    handleEscClick(e) {
        if (e.keyCode === 27) {
          this.setState({
               searchItem: false
            });
        }
    }

    openScanPopup(){
        document.documentElement.style.overflow="hidden";
        this.setState({showScan: true});

    }

    closeScanPopup(action){        
        if(action === "showScan"){
            this.setState({showScan: false, itemFound: false})
        }

        if(action === "addItem"){
            this.setState({scanAddItem: false,itemFound: false})
        }
        document.documentElement.style.overflow="auto";
    }

    onScanQuantityChange(value){
        this.setState({scannedItemQuantity: value});
    }

    mobileDeviceScanBarcode(data,url){
        this.setState({showScanLoader: true});
        client.post(url, {
            'barcode': data,
            'documentDate': this.props.formData.documentDate,
            'locId' : this.props.formData.locId,
            'documentDate' : this.props.formData.documentDate ? this.props.formData.documentDate : null,
            'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
            'accId': this.props.formData.accIdAdditionalValue ? this.props.formData.accIdAdditionalValue.value : null,
            'channel': this.props.formData.channel ? this.props.formData.channel : null
        })
        .then(response => response.data)
        .then(response => {
            if(response.itId){
                let arr = (this.props.columns)
                let obj = arr.find(o => o.id === "itId");
                if (obj) {
                    let options = {};
                    options.value = response.itId;
                    options.label = response.itName + "/" + response.itemDescription;

                    let optionExists = obj.options.find(o => o.value === response.itId);

                    if(!optionExists){
                        obj.options.push(options);
                    }
                }

                this.setState({scannedItem: response, scannedItemQuantity: 1, scanAddItem: true, showScan: false});
            }else{
                showAToast(plainTranslate(this.props.settings.locale, 'Item not found'), 'errSettings', 'error');

                this.setState({itemFound: false});
            }
            this.setState({showScanLoader: false});
        });
    }

    addScannedItem(closePopUp){
        let line = this.state.scannedItem;
        let lines = this.state.lines ? this.state.lines : [];
        line['quantity'] = this.state.scannedItemQuantity;
        let newLines;
        let action = this;
        if (this.props.field.itemSearch.quantityUrl) {
            client.post(this.props.field.itemSearch.quantityUrl, {
                'qty':  line['quantity'] ?  line['quantity'] : 1,
                'itId': line.itId,
                'locId' : this.props.formData.documentType == 3 ? this.props.formData.locIdFrom : this.props.formData.locId,
                'item': line.itId,
                'stockAvailability': this.state.stockAvailability,
                'documentDate' : this.props.formData.documentDate ? this.props.formData.documentDate : null,
                'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
                'accId': this.props.formData.accIdAdditionalValue ? this.props.formData.accIdAdditionalValue.value : null,
                'channel': this.props.formData.channel ? this.props.formData.channel : null
            })
            .then(response => response.data)
            .then(response => {
                newLines = Array(response.length).fill(line);
                response.forEach(function (arrayItem, i) {
                    newLines[i].measId = arrayItem.measId;
                    newLines[i].measId2 = arrayItem.measId2;
                    newLines[i].quantity2 = arrayItem.qty2;
                    newLines[i].quantity = arrayItem.qty;
                    newLines[i].minQuantity = arrayItem.qty;
                    if(line.batchId){
                        newLines[i].batch = line.batchId;
                    }else{
                        newLines[i].batch = '';
                    }
                    newLines[i].batchName = line.batchLabel;
                    if (arrayItem.hasOwnProperty("price")) {
                        newLines[i].unitPriceOriginal = arrayItem.price;
                    }
                    newLines[i].discountRate = arrayItem.discountRate;
                    action.addItemRows(newLines[i]);
                });
            });
        }
        else{
            this.addItemRows(line);
        }
        document.documentElement.style.overflow="auto";
        this.setState({scannedItem: null, scannedItemQuantity: null, scanAddItem: false, showScan: !closePopUp,itemFound: false});
    }

    addItemRows(line) {
        let lines = this.props.lines ? this.props.lines : []; 
        var scrollTop = lines.length * 40;   
        scrollTop = scrollTop-40
       
        if(!this.props.formData.isProduction && this.props.formData.custIdAdditionalValue){
            
            client.post('/' + this.props.match.params.db + '/api/items/item/item-search-price-by-quantity', {
                'qty': line.quantity,
                'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
                'accId': this.props.formData.accId ? this.props.formData.accId : null,
                'documentDate' : this.props.formData.documentDate,
                'itId': line.itId,
                'lines': lines,
                'line': line,
                'channel': this.props.formData.channel ? this.props.formData.channel : null
            })
            .then(response => response.data)
            .then(response => {               
            //@TO DO check this: 
               //line['unitPriceOriginal'] = response['unitPriceOriginal'];
                line['currId'] = response['currId'];
                //line['discountRate'] = response['discountRate'];
                line['totalDiscount'] = response['totalDiscount'];
                if(response['totalDiscount'] !== this.props.formData.totalDiscountRate){
                    showAToast('Total discount changed to '+response['totalDiscount']+'%', 'success1', 'success')                   
                }

                this.props.change('totalDiscountRate',response['totalDiscount']);
            })
        }
   
        this.props.columns.map((column, key) => {
            if ('operation' in column) {
                var fieldValue = this.calculateOperation(column.operation, line);       
                line[column.id] = fieldValue;
            }        
        })     
     
        if (lines.length > 0){ //????&& lines[0].hasOwnProperty('itId')) {
           
            if(this.state.currentLine){
                lines[this.state.currentLine] = line;
            }else{
                if (lines[lines.length-1].hasOwnProperty('itId') || lines[lines.length-1].hasOwnProperty('itName') || lines[lines.length-1].hasOwnProperty('itemDescription')) {
                
                if(lines[lines.length-1]['itName'] === line.itName && !lines[lines.length-1].hasOwnProperty('itId')){                      
                    lines.splice(lines.length-1, 1, line);
                }else{              
                    lines.push(line);
                    if(lines.length > 15){
                    this.listRef.current.scrollTo({scrollLeft: 0, scrollTop});
                    }
                }
                    
                } else {                
                    lines.splice(lines.length-1, 0, line);                
                    if (!lines[lines.length-1].hasOwnProperty['value']) {
                        lines.splice(lines.length-1, 1);                    
                    }
                }
            }
        } else {
            lines[0] = line;
        }       
        this.setState({
            lines: lines,
            mobileLines: lines,
            currentLine: null
        }, function () {
            this.props.change(this.props.field.id, this.state.lines);
        });
    }

    addDocumentRow(row) {
        let addVal = {};

        this.props.columns.map(field => {
            if (row[field.id]) {
                addVal[field.id] = row[field.id]['value'];
            }
        })

        let lines = this.state.lines ? this.state.lines : [];
        if(lines.length > 0 && lines[0].hasOwnProperty('document')){
            let add = true;
            lines.map(item => {
                if (this.isEquivalent(item, addVal)) {
                    showAToast(plainTranslate(this.props.settings.locale, 'Can not add same document twice'), 'errSettings', 'error');
                    add = false;
                    return;
                }
            })
            if (add) {
                lines.push(addVal);
            }

        } else {
            lines[0] = addVal;
        }

        this.setState({
            lines: lines
        }, function () {
            this.props.change(this.props.field.id, this.state.lines);
        });
    }

    

    removeLine(key) {
       let lines = this.state.lines;
       lines.splice(key, 1);

       this.setState({lines: lines}, function(){
           this.props.change(this.props.field.id, this.state.lines);
       });
   }

    splitLine(key, makeZeroFields) {
        let lines = this.state.lines;
        let newKey = key + 1;
        lines.splice(newKey, 0, lines[key]);
        this.props.change(this.props.field.id, lines);

        makeZeroFields.map(item => {
            let field = 'lines[' + newKey + ']' + item;
            this.props.change(field, 0);
        });
    }

    addLine() {
        let lines = this.state.lines ? this.state.lines : [];
        lines.push({});
        if(lines.length > 15){
          var scrollTop = lines.length * 40       
          this.listRef.current.scrollTo({scrollLeft: 0, scrollTop});
     }
        this.setState({
            lines: lines
        }, function () {
            this.props.change(this.props.field.id, this.state.lines);
        });
    }

    onDragEnd(result) {
        // dropped outside the table
        if (!result.destination) {
            return;
        }

        let lines = this.props.lines;
        const removed = lines.splice(result.source.index, 1);
        lines.splice(result.destination.index, 0, removed[0]);

        this.setState({lines});
        this.props.change(this.props.field.id, lines);
    }

    onEnterClickSearchItem(value, fieldName){
        let start = fieldName.indexOf('[');
        let end = fieldName.indexOf(']');
        let lineNo = fieldName.substring(start+1, end);

        let action = {};
        action.reactMethod = 'search';
        this.setState({searchItem: action, searchValue: value, currentLine: lineNo});
    }

    buttonAction(action, lineNo = null) {
        if (!action) return;

        if(action === 'newMobileLine'){
            if(this.state.editLine === undefined || this.state.editLine === null){
                if(!this.state.newMobileLine && this.state.lines.length > 1 || (!this.state.newMobileLine && (this.state.lines.length === 1 && (this.state.lines[0].itId) || this.state.lines[0].price) || this.state.lines.length === 0)){
                    this.addLine();
                }
                if(this.state.newMobileLine){
                    this.removeLine(this.state.lines.length-1);
                }
            }
            if(this.state.newMobileLine){
                this.props.change('lines',this.state.lines);
                this.setState({ editLine: null});
            }
            this.setState({ newMobileLine: this.state.newMobileLine ? false : action});
        }
        if (action.reactMethod) {
            if(action.reactMethod === 'newItem') {
                this.setState({itemForm: this.state.itemForm ? false : action});
            }
            if(action.reactMethod === 'newBatch') {
                this.setState({batchForm: this.state.batchForm ? false : action});
                if(lineNo !== null){
                    this.setState({lineNo: lineNo});
                }
            }
            if(action.reactMethod === 'newImport'){
                this.setState({importForm: this.state.importForm ? false : action});
            }
            if(action.reactMethod === 'search'){
                this.setState({searchItem: this.state.searchItem ? false : action, searchValue: null});
            }
            if(action.reactMethod === 'documentsSearch'){
                this.setState({documentsSearch: this.state.documentsSearch ? false : action});
            }
        }

        if(this.state.importForm && this.props.formData.custIdAdditionalValue){
            client.post('/' + this.props.match.params.db + '/api/items/item/item-search-price-by-quantity', {
                'custId': this.props.formData.custIdAdditionalValue.id,
                'accId': this.props.formData.accId ? this.props.formData.accId : null,
                'documentDate' : this.props.formData.documentDate,
                'lines': this.props.formData.lines,
                'channel': this.props.formData.channel ? this.props.formData.channel : null
            })
            .then(response => response.data)
            .then(response => {
                this.props.change('totalDiscountRate',response.totalDiscount);
            })
        }
    }

    checkParams(dependencies, action, message) {
        let found = false
        for (var i = 0; i < dependencies.length; i++){
             if (this.props.formData[dependencies[i]]) {
               found = true;
            }
          }
        if (found) {
            this.buttonAction(action);
        } else {
            showAToast(plainTranslate(this.props.settings.locale, message), 'errSettings', 'error');
        }
    }

    newItem(data) {      
        this.setState({
            itemForm: false
        });
    }

    newBatch(data) {
        this.setState({batchForm: false});
    }

    addZeroes(num, decimal) {
    // Convert input string to a number and store as a variable.
        //var value = parseFloat(num);
    // Split the input string into two arrays containing integers/decimals
        var res = num.toString().split(".");
    // If there is no decimal point or only one decimal place found.
        if(res.length == 1 || res[1].length < 3) {
    // Set the number to two decimal places
            num = num.toFixed(decimal);
        }
    // Return updated or original number.
        return num;
    }

    calculateOperation(operation, line) {
   
        if (operation.type === 'summaryList') {
            let sum = 0;
            if (operation.list in this.props.formData) {
                this.props.formData[operation.list].map(item => {
                    let value = parseFloat(item[operation.field]);
                    if (!isNaN(value)) {
                        sum += value;
                    }
                });
            }
            if ('decimalPoint' in operation) {

                let multiplier =  "e+"+operation.decimalPoint;
                let multiplier2 =  "e-"+operation.decimalPoint;

                return this.addZeroes(parseFloat(Math.sign(sum)*(Math.round(Math.abs(sum) + multiplier)+multiplier2)), operation.decimalPoint);

            }
            return sum;
        } else {
            let field1 = operation.field1;
            let field2 = operation.field2;

            if (field1.type === 'additionalValue')
                field1 = field1.populateFrom in line && field1.name in line[field1.populateFrom] ? line[field1.populateFrom][field1.name] ? line[field1.populateFrom][field1.name] : 0 : 0;
            else if (field1.type === 'formValue')
                field1 = field1.name in line ? line[field1.name] : 'populateFrom' in field1 && field1.populateFrom in line && field1.name in line[field1.populateFrom] ? line[field1.populateFrom][field1.name] : field1.defaultValue ? field1.defaultValue : 0;
            else if (field1.type === 'mainFormValue' && this.props.mainFormData)
                field1 = field1.name in this.props.mainFormData ? this.props.mainFormData[field1.name] : field1.defaultValue ? field1.defaultValue : 0;
            else if ('operation' in field1)
                field1 = this.calculateOperation(field1.operation, line);

            if ('pre' in operation.field1) {
                operation.field1.pre.forEach(op => {
                    if (op.type === 'add')
                        field1 += op.value;
                    if (op.type === 'subtract')
                        field1 -= op.value;
                    if (op.type === 'multiply')
                        field1 *= op.value;
                    if (op.type === 'divide')
                        field1 /= op.value;
                });
            }

            let rate = 1;
            if (field2.type === 'additionalValue')
                field2 = field2.populateFrom in line && field2.name in line[field2.populateFrom] ? line[field2.populateFrom][field2.name] ? line[field2.populateFrom][field2.name] : 0 : 0;
            else if (field2.type === 'formValue')
                field2 = field2.name in line ? line[field2.name] : 'populateFrom' in field2 && field2.populateFrom in line && field2.name in line[field2.populateFrom] ? line[field2.populateFrom][field2.name] : field2.defaultValue ? field2.defaultValue : 0;
            else if (field2.type === 'mainFormValue' && this.props.mainFormData)
                field2 = field2.name in this.props.mainFormData ? this.props.mainFormData[field2.name] : field2.defaultValue ? field2.defaultValue : 0;
            else if ('operation' in field2)
                field2 = this.calculateOperation(field2.operation, line);
            else if (field2.type === 'range') {
                let col = field2.name in line ? line[field2.name] : field2.defaultValue ? field2.defaultValue : 0;
                field2.range.forEach(item => {
                    if (item.id === col) {
                        let checkDate = field1;
                        if (field1 && field1.indexOf('/') !== -1) {
                            checkDate = checkDate.split('/');
                            checkDate = new Date(checkDate[2], parseInt(checkDate[1]) - 1, checkDate[0]);
                        } else {
                            // checkDate = new Date(checkDate);
                            checkDate = moment(checkDate, 'DD.M.YYYY').toDate();
                        }

                        let from = new Date(item.from.date);
                        let to = new Date(item.to.date);
                        if (checkDate >= from && checkDate <= to) {
                            rate = item.value;
                        }
                    }
                });
            }

            if ('pre' in operation.field2) {
                operation.field2.pre.forEach(op => {
                    if (op.type === 'add')
                        field2 += op.value;
                    if (op.type === 'subtract')
                        field2 -= op.value;
                    if (op.type === 'multiply')
                        field2 *= op.value;
                    if (op.type === 'divide')
                        field2 /= op.value;
                });
            }

            let result = 0;

            if (operation.decimalPoint) {

                let multiplier =  "e+"+operation.decimalPoint;
                let multiplier2 =  "e-"+operation.decimalPoint;

/*                if(!operation.field1.isPercent){
                    if(operation.field1.name !== 'unitPriceOriginal'){
                        field1 =  parseFloat(Math.sign(field1)*Math.round(Math.abs(field1) + multiplier)+multiplier2);
                    }
                }
                if(!operation.field2.isPercent){
                    if(operation.field1.name !== 'unitPriceOriginal'){
                        field2 =  parseFloat(Math.sign(field2)*Math.round(Math.abs(field2) + multiplier)+multiplier2);
                    }
                }*/
            }

            if (operation.type === 'add') {
                result = parseFloat(field1) + parseFloat(field2);
            }
            if (operation.type === 'subtract')
                result = field1 - field2;
            if (operation.type === 'multiply'){
                result = field1 * field2;
            }
            if (operation.type === 'dateRange')
                result = rate;

            if (operation.decimalPoint) {

               let multiplier =  "e+"+operation.decimalPoint;
               let multiplier2 =  "e-"+operation.decimalPoint;

               result = parseFloat(Math.sign(result)*(Math.round(Math.abs(result) + multiplier)+multiplier2));

               result = this.addZeroes(result, operation.decimalPoint);
            }

            return result;
        }
    }

    onCancel(){
        this.setState({importForm: false});
    }

    closeSearchPopup(){
        this.setState({searchItem: false});
    }

    saveMobileLine(e){
        let length = this.props.formData.lines.length;
        let lines = this.props.lines;
        //lines.push(this.props.formData.lines[length-1]);
        if(e === true){
            this.addLine();
            this.setState({mobileLines: lines, newMobileLine: false, editLine: null}, function () {
                this.props.change(this.props.field.id, this.state.lines);this.setState({newMobileLine: 'newMobileLine'});
            });
        }else{
            this.setState({mobileLines: lines, newMobileLine: false, editLine: null }, function () {
                this.props.change(this.props.field.id, this.state.lines);
            });
        }

        //this.addLine();
    }

    editMobileLine(key){
        this.setState({ newMobileLine: 'newMobileLine', editLine: key});
    }

    handleScan(data, url) {
        let lines = this.props.lines ? this.props.lines : [];
        client.post(url, {
            'barcode': data,
            'documentDate': this.props.formData.documentDate,
            'locId' : this.props.formData.locId,
            'documentDate' : this.props.formData.documentDate ? this.props.formData.documentDate : null,
            'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
            'accId': this.props.formData.accId ? this.props.formData.accId : null,
            'channel': this.props.formData.channel ? this.props.formData.channel : null
        })
        .then(response => response.data)
        .then(response => {
            let line = response;
            let lines = this.props.formData.lines ? this.props.formData.lines : [];
            if(this.props.formData.custIdAdditionalValue){
                client.post('/' + this.props.match.params.db + '/api/items/item/item-search-price-by-quantity', {
                    'isPos': true,
                    'qty': line.quantity,
                    'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
                    'accId': this.props.formData.accId ? this.props.formData.accId : null,
                    'documentDate' : this.props.formData.documentDate,
                    'itId': line.itId,
                    'lines': lines,
                    'line': line,
                    'locId': this.props.formData.locIdFrom ? this.props.formData.locIdFrom : this.props.formData.locId,
                    'channel': this.props.formData.channel ? this.props.formData.channel : null
                })
                .then(response => response.data)
                .then(response => {                            
                    line['unitPriceOriginal'] = response['unitPriceOriginal'];
                    line['currId'] = response['currId'];
                    //line['discountRate'] = response['discountRate'];
                    line['totalDiscount'] = response['totalDiscount'];
                    line['batch'] = response['batch'];
                    line['batchName'] = response['batchName'];
                    line['availableQuantity'] = response['availableQuantity'];
                    
                    this.props.change('totalDiscountRate',response['totalDiscount']);
                    //this.addItemRows(line);

                    if (lines.length > 0 && lines[0].hasOwnProperty('itId')) {
                        if (lines[lines.length-1].hasOwnProperty('itId')) {
                            lines.push(line);
                        } else {
                            lines.splice(lines.length-1, 0, line);
                            if (!lines[lines.length-1].hasOwnProperty['value']) {
                                lines.splice(lines.length-1, 1);
                            }
                        }
                    } else {
                        lines[0] = line;
                    }
                    this.setState({
                        lines: lines
                    }, function () {
                        this.props.change('lines', this.state.lines);
                    });
                })
            }else{
                //this.addItemRows(line);
            }
        })
    }   
    


    handleError(err) {
      
    }

    loadExpenseOptions(inputValue) {
        client
        .get('/' + this.props.settings.db + '/api/expenses?q=' + inputValue)
        .then(response => response.data)
        .then(response => {
            let options = [];
            if (response) {
                options = response.map(option => {
                    return {
                        ...option
                    };
                });
            }
            this.setState({options: options});
            return (options ? options : this.state.options);
        });
    }

    onChange(data) {
        data['distributeType'] = 'totalLinePrice';
        let jsonData = this.state.distributableCost;
        jsonData.docExpenses.push(data);
        this.setState({distributableCost: jsonData});
    }

    addAddExpense() {
        let jsonData = this.state.distributableCost;
        jsonData.addExpenses.push({'distributeType':'totalLinePrice'});
        this.setState({distributableCost: jsonData});
    }

    changeExpense(e, key, type, expense) {
        let jsonData = this.state.distributableCost;
        if (e.target.value && type !== "remove") {
            jsonData[expense][key][type] = e.target.value;
        } 
        if (e.target.checked && type !== "remove") {
            jsonData[expense][key][type] = e.target.checked;
        } 
        if (type === 'remove') {
            jsonData[expense].splice(key, 1); 
        }
        
        this.setState({distributableCost: jsonData})
    }

    saveExpense() {
        this.props.change('distributableCost', JSON.stringify(this.state.distributableCost));
        let addExpenses = this.state.distributableCost.addExpenses;
        let docExpenses = this.state.distributableCost.docExpenses;
        let columns = this.props.columns;
        //columns = columns.filter(item => item.userAdded !== true);

        for (let i = 0; i < addExpenses.length; i++) {
            let obj = columns.find(o => o.id === addExpenses[i]['description']);
            if (!obj) {
                columns.push({
                    'hide': false,
                    'id': addExpenses[i]['description'],
                    'label': addExpenses[i]['description'],
                    'type': "number",
                    'width': "80px",
                    'disabled': true,
                })
            }
          }
          for (let i = 0; i < docExpenses.length; i++) {
            let obj = columns.find(o => o.id === docExpenses[i]['documentNo']);
            if (!obj) {
                columns.push({
                    'hide': false,
                    'id': docExpenses[i]['documentNo'],
                    'label': docExpenses[i]['documentNo'],
                    'type': "number",
                    'width': "80px",
                    'disabled': true,
                })
            }
          }
          let totalWithExpenses = columns.find(o => o.id === 'totalWithExpenses');
          if (!totalWithExpenses) {
            columns.push({
                'hide': false,
                'id': 'totalWithExpenses',
                'label': 'All total',
                'disabled': true,
                'type': "text",
                'width': "80px"
            })
        }
        let totalWithExpensesUnit = columns.find(o => o.id === 'totalWithExpensesUnit');
          if (!totalWithExpensesUnit) {
            columns.push({
                'hide': false,
                'id': 'totalWithExpensesUnit',
                'label': 'All total per unit',
                'disabled': true,
                'type': "text",
                'width': "80px"
            })
        }
        if (this.state.distributableModal) {
            this.setState({distributableModal: false, columns: columns}, function() {
                this.recalculateCosts();
            });
        } 
    }

    changeDistributeType(value, key, type) {
        let jsonData = this.state.distributableCost;
        jsonData[type][key]['distributeType'] = value;
        this.setState({distributableCost: jsonData})
    }

    recalculateCosts() {
        let distributableCost = JSON.parse(this.props.formData.distributableCost);
        let addExpenses = distributableCost.addExpenses;
        let docExpenses = distributableCost.docExpenses;
        let lines = this.props.formData.lines;
        let weightNet = 0;
        let weightGross = 0;
        let volume = 0;
        let totalLinePrice = 0;
        let quantity = 0;
       
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].itId) {
                weightGross += parseFloat(lines[i].weightGross);
                weightNet += parseFloat(lines[i].weightNet);
                totalLinePrice += parseFloat(lines[i].totalLinePrice); 
                quantity += parseFloat(lines[i].quantity); 
                volume += parseFloat(lines[i].volume);
            }
        }

        for (let i = 0; i < lines.length; i++) {
            let allTotal = 0;
            let externalExpense = 0;
            let dutyTotal = 0;
            let distributableTotal = 0;
            for (let a = 0; a < addExpenses.length; a++) {
                let lineVal = parseFloat(addExpenses[a]['amount']);
                let type = addExpenses[a]['distributeType'];
                let expenseAddValue = (lineVal/parseFloat(eval(type)))*parseFloat(lines[i][type]);
                
                lines[i][addExpenses[a]['description']] = expenseAddValue.toFixed(3);
                if (addExpenses[a]['isExternal']) {
                    externalExpense += parseFloat(expenseAddValue);  
                }
                allTotal += expenseAddValue;
                distributableTotal += expenseAddValue;
            }
            for (let a = 0; a < docExpenses.length; a++) {
                let lineVal = parseFloat(docExpenses[a]['amountSum']);
                let type = docExpenses[a]['distributeType'];
                let expenseDocValue = (lineVal/parseFloat(eval(type)))*parseFloat(lines[i][type]);

                lines[i][docExpenses[a]['documentNo']] = expenseDocValue.toFixed(3);
                if (docExpenses[a]['isExternal']) {
                    externalExpense += parseFloat(expenseDocValue);  
                }
                allTotal += expenseDocValue;
                distributableTotal += expenseDocValue;
            }

            if (lines[i]['duty']) {
                dutyTotal += (parseFloat(lines[i]['totalLinePrice']) + parseFloat(externalExpense)) * (parseFloat(lines[i]['duty'])/100);
            }

            allTotal += dutyTotal;
            allTotal += parseFloat(lines[i]['ecoTaxNetTotal']);
            allTotal += parseFloat(lines[i]['ecoTaxPackagingTotal']);
            allTotal += parseFloat(parseFloat(lines[i].totalLinePrice));
            //amountDistributable, amountDistributableUnit
            lines[i]['dutyTotal'] = dutyTotal.toFixed(3);
            lines[i]['distributableTotal'] = distributableTotal;
            lines[i]['distributableUnit'] = distributableTotal/parseFloat(lines[i]['quantity']);
            lines[i]['totalWithExpenses'] = allTotal.toFixed(3);
            lines[i]['totalWithExpensesUnit'] = (allTotal/parseFloat(lines[i]['quantity'])).toFixed(this.props.field.distributableCostDecimal ? this.props.field.distributableCostDecimal : 3);

            this.props.change('lines', lines);
        }
    }

    showCopyLine(no){
        this.setState({ showCopyLinePopup: true, copyLineNo: no});
    }

    copyLine(){
        let line = this.props.lines[this.state.copyLineNo];

        var newLine = {};
        newLine.quantity = this.state.copyLineQuantity;
        newLine.description = line.description;
        newLine.comment = line.comment;
        if(line.startDate){
            newLine.startDate = line.startDate;
        }
        if(line.endDate){
            newLine.endDate = line.endDate;
        }

        newLine.itId = line.itId;
        newLine.itName = line.itName;
        newLine.locConsumption = line.locConsumption;
        newLine.locInProgress = line.locInProgress;
        newLine.locProduction = line.locProduction;
        newLine.production = line.production;
        newLine.unitCost = line.unitCost;
        newLine.stage = this.props.field.defaultStage['id'];
        newLine.stageStatus = this.props.field.defaultStage['status'];
        newLine.dealLineId = line.dealLineId;

        this.props.lines.push(newLine);
        this.props.change('lines', this.props.lines);

        this.setState({ showCopyLinePopup: false});
    }

    addItemAction(formId) {
        this.setState({formItem: formId }, function() {
            this.buttonAction({ reactMethod: 'newItem'});
        });
    }

    render() {
    
     let columns = JSON.parse(JSON.stringify(this.props.columns));
    let columnsVisual = []
   columns.map(col => {
         if(!col.hide){
              columnsVisual.push(col);
         } 
    })    
        let errors = {};
        if (this.state.errors) {
            this.state.errors.map(error => {
                if (!(error.index in errors))
                    errors[error.index] = {};

                errors[error.index][error.field] = error.type;
            });
        }
        let marginTop = this.props.formData.hasOwnProperty('priority') ? 100 : '';

        let menuOptions = [];
        if (this.props.field.addItemButton && this.props.field.addItemButton.itemForms && this.props.field.addItemButton.itemForms.length) {
            menuOptions = (
                <Menu>
                    {this.props.field.addItemButton.itemForms.map((option, i) => {
                            return <Menu.Item key={i}>
                                <a onClick={() => this.addItemAction(option.value)}>
                                    <Translate locale={this.props.settings.locale} value={option.label} />
                                </a>
                            </Menu.Item>
                    })}
                </Menu>
            );
        }

        if(this.props.field.isPos){
            return <div className="pos-billing-data">
                <RenderPosLines
                    name="lines"
                    columns={this.props.columns}
                    totals={this.props.field.totals}
                    search={this.onEnterClickSearchItem}
                    lines={this.props.lines}
                    settings={this.props.settings}
                    oneLine={this.props.field.oneLine ? true : false}
                    splitRow={this.props.field.splitRow ? true : false}
                    makeZeroFields={this.props.field.makeZeroFields ? this.props.field.makeZeroFields : []}                    
                    remove={this.removeLine}
                    split={this.splitLine}                   
                    formData={this.props.formData}
                    formProperties={this.props.formProperties}
                    calculateOperation={this.calculateOperation}
                    change={this.props.change}
                    fieldId={this.props.field.id}
                />
            </div>
        }
        else if(window.innerWidth > 780){
             return <span className={"form-input-field tableCollection " + (this.props.tableClass ? this.props.tableClass : "")} style={{marginTop: marginTop}}>

                {
                    this.state.itemForm &&
                    <SlideModal onClose={() => this.buttonAction(this.state.itemForm)} 
                    title={plainTranslate(this.props.settings.locale, 'Item')}>
                      <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        saveRoute={ '/api/items'}
                                        formRoute={'/api/items/'}
                                        match={{params: {id: 0}}}
                                        onSuccessfulSave={(data) => this.newItem(data)}
                                        matchAdditionalId={{ params: { id: this.state.formItem } }}
                                        isNew={true}
                                        popup={true}
                                        form={'initializeFromState'}
                                        onCancel={() => this.buttonAction({reactMethod: 'newItem'})}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.searchItem &&
                    <SlideModal onClose={() => this.buttonAction(this.state.searchItem)} title={plainTranslate(this.props.settings.locale, 'Search item')}>
                        <section className="newPanel" ref={this.setWrapperRef}>
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SearchItem
                                        onAddItemRow={this.addItemRows}
                                        addBatch={this.addBatch}
                                        showAvailableItems={this.props.field.itemSearch.showAvailableItems}
                                        checkLocation={this.props.field.itemSearch.hasOwnProperty('checkLocation') ? this.props.field.itemSearch.checkLocation : true}
                                        searchUrl={this.props.field.itemSearch.searchUrl}
                                        treeFindItem={this.props.field.itemSearch.treeFindItem}
                                        treeUrl={this.props.field.itemSearch.treeUrl}
                                        closeSearch={this.closeSearchPopup}
                                        checkAvailabilityForm={this.props.field.itemSearch.checkAvailabilityForm}
                                        disabledAvailabilityStage={this.props.field.itemSearch.disabledAvailabilityStage}
                                        quantityUrl={this.props.field.itemSearch.quantityUrl}
                                        treeFindItem={this.props.field.itemSearch.treeFindItem}
                                        treeUrl={this.props.field.itemSearch.treeUrl}
                                        closeSearch={this.closeSearchPopup}
                                        line={this.state.currentLine}
                                        searchValue={this.state.searchValue}
                                        options={this.props.field.fields}
                                        remove={this.removeLine}
                                        change={this.props.change}
                                        formData={this.props.formData}
                                        orderQty={this.props.field.itemSearch.orderQty}
                                        close={this.onCancel}
                                        editPrice={this.props.field.itemSearch.editPrice}
                                         />

                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.importForm &&
                    <SlideModal onClose={() => this.buttonAction(this.state.importForm)} title={plainTranslate(this.props.settings.locale, 'Import')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <ImportLines fields={this.props.fields} onAddItemRow={this.addItemRows} quantityUrl={this.props.field.itemSearch.quantityUrl} remove={this.removeLine} change={this.props.change} formData={this.props.formData} close={this.onCancel}/>
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.batchForm &&
                    <SlideModal onClose={() => this.buttonAction(this.state.batchForm)} title={plainTranslate(this.props.settings.locale, 'Batchsss')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        saveRoute={ '/api/batches'}
                                        formRoute={ '/api/batches/form'}
                                        //match={{params: {id: 0}}}
                                        onSuccessfulSave={(data) => this.newBatch(data)}
                                        isNew={true}
                                        popup={true}
                                        onCancel={() => this.buttonAction({reactMethod: 'newBatch'})}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                 {this.state.showCopyLinePopup &&
                    <SlideModal onClose={() => this.setState({ showCopyLinePopup: false})} title={plainTranslate(this.props.settings.locale, 'Quantity')}>
                        <section className="newPanel" style={{ margin: 20, padding: 10 }}>
                            <div className="panel-body" style={{ width: "100%" }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
                                    <label style={{ marginRight: 25 }} for="quantity">{plainTranslate(this.props.settings.locale, 'Quantity')}</label>
                                    <input type="number" id="quantity" onChange={(e) => this.setState({ copyLineQuantity: e.target.value })}></input>
                                </div>
                                <button type="button" className="button-primary" onClick={this.copyLine}>{plainTranslate(this.props.settings.locale, 'Save')}</button>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.documentsSearch &&
                    <SlideModal onClose={() => this.buttonAction(this.state.documentsSearch)} title={plainTranslate(this.props.settings.locale, 'Choose documents')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SearchDocuments searchUrl={this.props.field.documentsSearch.searchUrl} params = {this.props.field.documentsSearch.dependency} formData = {this.props.formData} onAddRow={this.addDocumentRow}/>
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }

{
                    this.state.distributableModal &&
                    <SlideModal onClose={() => this.setState({ distributableModal: false })} title={plainTranslate(this.props.settings.locale, 'Distributable cost')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <div className="col-md-10 col-xs-12">
                                        <h4 className="no-gutter">{plainTranslate(this.props.settings.locale, 'Add from existing expenses')}</h4>
                                        <AsyncSelect
                                            isClearable
                                            cacheOptions
                                            isSearchable
                                            onChange={(value) => this.onChange(value)}
                                            onBlurResetsInput={false}
                                            onBlur={event => event.preventDefault()}
                                            onCloseResetsInput={false}
                                            menuPlacement={"auto"}
                                            loadOptions={this.loadExpenseOptions}
                                            defaultOptions={this.state.options}
                                        />
                                        {this.state.distributableCost.docExpenses && this.state.distributableCost.docExpenses.length ?
                                            <div className="row m-top10">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Document #')}</th>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Amount')}</th>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Distribute Type')}</th>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Is external?')}</th>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Remove')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.distributableCost.docExpenses.map((item, key) => {
                                                            return <tr>
                                                                <td>{this.state.distributableCost.docExpenses[key]['documentNo']}/{this.state.distributableCost.docExpenses[key]['date']}</td>
                                                                <td>{this.state.distributableCost.docExpenses[key]['amountSum']}</td>
                                                                <td><Select key={key} value={this.state.distributableCost.docExpenses[key]['distributeType']} defaultValue={'amount'} onChange={(value) => this.changeDistributeType(value, key, 'docExpenses')}>
                                                                    <Option value={'totalLinePrice'} >{plainTranslate(this.props.settings.locale, 'Amount')}</Option>
                                                                    <Option value={'quantity'} >{plainTranslate(this.props.settings.locale, 'Quantity')}</Option>
                                                                    <Option value={'weightNet'} >{plainTranslate(this.props.settings.locale, 'Weight Net')}</Option>
                                                                    <Option value={'weightGross'} >{plainTranslate(this.props.settings.locale, 'Weight Gross')}</Option>
                                                                    <Option value={'volume'} >{plainTranslate(this.props.settings.locale, 'Volume')}</Option>
                                                                </Select></td>
                                                                <td><Checkbox checked={this.state.distributableCost.docExpenses[key]['isExternal']} onChange={(e) => this.changeExpense(e, key, 'isExternal', 'docExpenses')}></Checkbox></td>
                                                                <td><i className="fa fa-trash" onClick={(e) => this.changeExpense(e, key, 'remove', 'docExpenses')} aria-hidden="true" title={plainTranslate(this.props.settings.locale, 'Remove expense')}></i></td>
                                                            </tr>
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                                <div className="row m-bot15">
                                    <div className="col-md-10 col-xs-12">
                                    <hr />
                                        <h4 className="no-gutter">{plainTranslate(this.props.settings.locale, 'Add additional expenses')}</h4>
                                        {this.state.distributableCost.addExpenses && this.state.distributableCost.addExpenses.length ?
                                            <div className="row m-top10">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Expense')}</th>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Amount')}</th>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Distribute Type')}</th>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Is external?')}</th>
                                                            <th>{plainTranslate(this.props.settings.locale, 'Remove')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.distributableCost.addExpenses.map((item, key) => {
                                                            return <tr>
                                                                <td><input type="text"
                                                                    onChange={(e) => this.changeExpense(e, key, 'description', 'addExpenses')}
                                                                    value={this.state.distributableCost.addExpenses[key]['description']}
                                                                ></input></td>
                                                                <td><input type="number" onChange={(e) => this.changeExpense(e, key, 'amount', 'addExpenses')}
                                                                    value={this.state.distributableCost.addExpenses[key]['amount']}
                                                                ></input></td>
                                                                <td><Select key={key} value={this.state.distributableCost.addExpenses[key]['distributeType']} defaultValue={'amount'} onChange={(value) => this.changeDistributeType(value, key, 'addExpenses')}>
                                                                    <Option value={'totalLinePrice'} >{plainTranslate(this.props.settings.locale, 'Amount')}</Option>
                                                                    <Option value={'quantity'} >{plainTranslate(this.props.settings.locale, 'Quantity')}</Option>
                                                                    <Option value={'weightNet'} >{plainTranslate(this.props.settings.locale, 'Weight Net')}</Option>
                                                                    <Option value={'weightGross'} >{plainTranslate(this.props.settings.locale, 'Weight Gross')}</Option>
                                                                    <Option value={'volume'} >{plainTranslate(this.props.settings.locale, 'Volume')}</Option>
                                                                </Select></td>
                                                                <td><Checkbox checked={this.state.distributableCost.addExpenses[key]['isExternal']} onChange={(e) => this.changeExpense(e, key, 'isExternal', 'addExpenses')}></Checkbox></td>
                                                                <td><i className="fa fa-trash" onClick={(e) => this.changeExpense(e, key, 'remove', 'addExpenses')} aria-hidden="true" title={plainTranslate(this.props.settings.locale, 'Remove expense')}></i></td>
                                                            </tr>
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            : ''
                                        }
                                        <a onClick={() => this.addAddExpense()} className="button-outline grey pull-right"><Translate locale={this.props.settings.locale} value="Add new" /></a>
                                    </div>
                                </div>
                                <a className="button-primary" onClick={() => this.saveExpense()}><Translate locale={this.props.settings.locale} value="Save" /></a>
                                <a className="button-outline grey" onClick={() => this.setState({ distributableModal: false })}><Translate locale={this.props.settings.locale} value="Cancel" /></a>
                            </div>
                        </section>
                    </SlideModal>
                }
          <InputGroup size="large">
          <Row gutter={16} align="middle" justify="start">
                       <Col span={4} >
                        <Input 
                              type="text" 
                              size="small" 
                              placeholder={plainTranslate(this.props.settings.locale, 'Go to item ')} 
                              onChange={(e) => this.goToItem(e)}
                              style={{width: '100%', border: '1px solid #ccc', background: 'white'}}
                              name="gotoitem"
                              key="gotoitem"/>                               
                        </Col>   
                        <Col span={1} >OR</Col>                   
                        <Col span={4} >
                              <Input  
                              type="text" 
                              size="small" 
                              value={this.state.rowCount}
                              placeholder={plainTranslate(this.props.settings.locale, 'Go to row ')} 
                              onChange={(e) => this.scrollToRowChange(e)}
                              style={{width: '100%', border: '1px solid #ccc',  background: 'white', backgroundColor:  'white !important'}}
                              name="gotorow"
                              key="gotorow"                              
                              suffix={<Button onClick={this.scrollToRow} shape="circle" type="text" icon={<SearchOutlined />} />} 
                              />                       
                        </Col>
                        <Col span={4}  style={{padding: 6}}>
                        {plainTranslate(this.props.settings.locale, '  '+this.state.lines.length+' rows')} 
                        </Col>
                    <Col span={6} ></Col>
                </Row> 
                </InputGroup>
             {this.state.lines &&   <StickyGridList  
                columns={columnsVisual}
                lines={this.state.lines}
                formData={this.props.formData}
                remove={this.removeLine}
                changeFieldValueLine={this.props.changeFieldValueLine}
                calculateOperation={this.calculateOperation}
                change={this.props.change}
                search={this.onEnterClickSearchItem}
                listRef={this.listRef}  
                settings={this.props.settings}
                highlightAvailability={this.props.field.highlightAvailability}
                onScroll={this.handleScroll}
                />}
       
                {!this.props.field.oneLine &&
                <div className="col-md-12 col-xs-12 text-center">
                {!this.props.field.hideAddRowButton &&
                    <button className="button-outline grey text-uppercase" type="button" style={{float:"none", fontWeight: 'bold'}} onClick={this.addLine} >{plainTranslate(this.props.settings.locale, 'New row')}</button>
                }
                    {this.props.field.importButton &&
                        <span style={{float: 'left', marginTop: 20, marginLeft: 15}}>
                            <a className="add-customer-label" onClick={() => this.buttonAction(this.props.field.importButton.action)}>
                            {this.props.field.importButton.icon &&
                                <i className={this.props.field.importButton.icon}></i>
                            }
                            <p>{plainTranslate(this.props.settings.locale, 'Import')}</p></a>
                        </span>
                    }
                    {this.props.field.barcodeScanner &&
                         <BarcodeReader
                                //onError={this.handleError}
                                onScan={(data) => this.handleScan(data, this.props.field.barcodeScanner.searchUrl)}
                            />
                    }
                    {this.props.field.itemSearch &&
                        <span style={{float: 'left', marginTop: 20, marginLeft: 15}}>
                           <a className="add-customer-label" onClick={() => this.checkParams(this.props.field.itemSearch.dependency, this.props.field.itemSearch.action, this.props.field.itemSearch.message)}>
                            {this.props.field.itemSearch.icon &&
                                <i className={this.props.field.itemSearch.icon}></i>
                            }
                            <p>{plainTranslate(this.props.settings.locale, this.props.field.itemSearch.label)}</p>
                           </a>
                         </span>
                    }
                    {this.props.field.documentsSearch &&
                    <span style={{float: 'left', marginTop: 20, marginLeft: 15}} className={this.props.field.documentsSearch.class}>
                       <a className="add-customer-label" onClick={() => this.checkParams(this.props.field.documentsSearch.dependency, this.props.field.documentsSearch.action, this.props.field.documentsSearch.message)}>
                        {this.props.field.documentsSearch.icon &&
                            <i className={this.props.field.documentsSearch.icon}></i>
                        }
                        <p>{plainTranslate(this.props.settings.locale, this.props.field.documentsSearch.label)}</p>
                       </a>
                     </span>
                    }
                     {this.props.field.addItemButton &&
                            <span style={{ float: 'left', marginTop: 20, marginLeft: 15 }}>
                                    <Dropdown overlay={menuOptions} placement="bottomCenter" className="add-customer-label">
                                    <span><i className="fa fa-cart-plus"></i>
                                    <p>{plainTranslate(this.props.settings.locale, 'Add item')}</p></span>
                                    </Dropdown>
                            </span>
                        }
                        {this.props.field.distributableCost &&
                            <span style={{ float: 'left', marginTop: 20, marginLeft: 15 }}>
                                    <a className="add-customer-label" onClick={() => this.setState({distributableModal: true})}>
                                            <i className="fa fa-pie-chart"></i>
                                            {this.props.formData.distributableCost ? 
                                                <p>{plainTranslate(this.props.settings.locale, 'Edit distributable cost')}</p>
                                                :
                                                <p>{plainTranslate(this.props.settings.locale, 'Add distributable cost')}</p>
                                            }
                                    </a>
                            </span>
                        }
                        {this.props.field.distributableCost && this.state.distributableCost && ((this.state.distributableCost.docExpenses && this.state.distributableCost.docExpenses.length) || (this.state.distributableCost.addExpenses && this.state.distributableCost.addExpenses.length)) ?
                            <span style={{ float: 'left', marginTop: 20, marginLeft: 15 }}>
                                <a className="add-customer-label" onClick={() => this.recalculateCosts()}>
                                    <i className="fa fa-calculator"></i>
                                    <p>{plainTranslate(this.props.settings.locale, 'Recalculate costs')}</p>
                                </a>
                            </span>
                            : ''
                        }
                </div>
                }
            </span>
        }
        else{
            return  <div className="form-input-field" style={{display: 'grid'}}>
            {
                this.state.showScan &&
                    <SlideModal onClose={() => this.closeScanPopup('showScan')} title={plainTranslate(this.props.settings.locale, 'Scan item')}>
                        <div className="scanItem" style={{position: 'absolute',height: '100%',width: '100%', padding: "0 20px"}}>
                            <BarcodeScannerComponent
                                width={"100%"}
                                height={"100%"}
                                onUpdate={(err, result) => {
                                    if(result && !this.state.itemFound){
                                        this.mobileDeviceScanBarcode(result.text, this.props.field.barcodeScanner.searchUrl);
                                        this.setState({itemFound: true});
                                    }
                                }}
                            />
                        </div>
                        {this.state.showScanLoader &&
                            <div className="scan-spinner">
                                <Spin size="large" style={{margin: "auto"}}/>
                            </div>
                        }
                    </SlideModal>
            }
            {
                    this.state.newMobileLine &&
                    <SlideModal onClose={() => this.buttonAction(this.state.newMobileLine)} title={plainTranslate(this.props.settings.locale, 'Add row')}>
                        <section className="newPanel">
                            <div className="panel-body" style={{width: '100%'}}>
                                <div className="row" style={{display: 'grid', margin: '0 5px'}}>
                                    <RenderMobileLines
                                        name="lines"
                                        columns={this.props.columns}
                                        editLine={this.state.editLine}
                                        settings={this.props.settings}
                                        search={this.onEnterClickSearchItem}
                                        lines={this.state.lines}
                                        isNew={true}
                                        oneLine={this.props.field.oneLine ? true : false}                                        
                                        remove={this.removeLine}
                                        formData={this.props.formData}
                                        formData={this.props.formData}
                                        calculateOperation={this.calculateOperation}
                                        change={this.props.change}
                                        save={this.saveMobileLine}
                                        fieldId={this.props.field.id}
                                        buttonAction={this.buttonAction}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.itemForm &&
                    <SlideModal onClose={() => this.buttonAction(this.state.itemForm)} title={plainTranslate(this.props.settings.locale, 'Item')}>
                         <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        saveRoute={'/api/items'}
                                        formRoute={ '/api/items/form'}
                                        //match={{params: {id: 0}}}
                                        onSuccessfulSave={(data) => this.newItem(data)}
                                        isNew={true}
                                        popup={true}
                                        form={'initializeFromState'}
                                        onCancel={() => this.buttonAction({reactMethod: 'newItem'})}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.scanAddItem &&
                    <SlideModal onClose={() => this.closeScanPopup('addItem')} title={plainTranslate(this.props.settings.locale, 'Item')}>
                        <section className="newPanel" style={{borderRadius: 4, border: "1px solid #e5e5e5", margin: 0,padding: 10, position: "fixed"}}>
                            <div className="panel-body" style={{width: "100%"}}>
                                <div style={{marginBottom: 10}}>
                                    <label style={{marginRight: 5}}>{plainTranslate(this.props.settings.locale, 'Item')}:</label>
                                    <span style={{fontSize: 15}}>{this.state.scannedItem.itName}</span>
                                </div>
                                <div className="form-treeview-quantity" style={{display: 'flex', alignItems: 'center', marginBottom: 30}}>
                                    <label style={{marginRight: 5}} for="quantity">{plainTranslate(this.props.settings.locale, 'Quantity')}</label>
                                    <InputNumber style={{ width: '40%' }} size="large" max={100000} defaultValue={1} onChange={this.onScanQuantityChange} />
                                </div>
                                <button type="button" className={"button-primary " + ((this.state.scannedItemQuantity === null || this.state.scannedItemQuantity === "" ) ? 'disabled' : '')} disabled={((this.state.scannedItemQuantity === null || this.state.scannedItemQuantity === "" ) ? 'disabled' : '')} onClick={() => this.addScannedItem(true)}>{plainTranslate(this.props.settings.locale, 'Add')}</button>
                                <button type="button" className={"button-primary " + ((this.state.scannedItemQuantity === null || this.state.scannedItemQuantity === "" ) ? 'disabled' : '')} disabled={((this.state.scannedItemQuantity === null || this.state.scannedItemQuantity === "" ) ? 'disabled' : '')} onClick={() => this.addScannedItem(false)}>{plainTranslate(this.props.settings.locale, 'Add and Scan')}</button>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.importForm &&
                    <SlideModal onClose={() => this.buttonAction(this.state.importForm)} title={plainTranslate(this.props.settings.locale, 'Import')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <ImportLines quantityUrl={this.props.field.itemSearch.quantityUrl} remove={this.removeLine} change={this.props.change} formData={this.props.formData} close={this.onCancel}/>
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.searchItem &&
                    <SlideModal onClose={() => this.buttonAction(this.state.searchItem)} title={plainTranslate(this.props.settings.locale, 'Search item')}>
                        <section className="newPanel">
                            <div className="panel-body" style={{width: "100%"}}>
                                <div className="row m-bot15">
                                    <SearchItem 
                                    onAddItemRow={this.addItemRows} 
                                    addBatch={this.addBatch} 
                                    showAvailableItems={this.props.field.itemSearch.showAvailableItems} 
                                    searchUrl={this.props.field.itemSearch.searchUrl} 
                                    treeFindItem={this.props.field.itemSearch.treeFindItem} 
                                    treeUrl={this.props.field.itemSearch.treeUrl} 
                                    closeSearch={this.closeSearchPopup} 
                                    line={this.state.currentLine} 
                                    searchValue={this.state.searchValue} 
                                    options={this.props.field.fields} 
                                    remove={this.removeLine} 
                                    change={this.props.change} 
                                    formData={this.props.formData} 
                                    orderQty={this.props.field.itemSearch.orderQty}
                                    close={this.onCancel}/>
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.batchForm &&
                    <SlideModal onClose={() => this.buttonAction(this.state.batchForm)} title={plainTranslate(this.props.settings.locale, 'Batch')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        saveRoute={ '/api/batches'}
                                        formRoute={ '/api/batches/form'}
                                        //match={{params: {id: 0}}}
                                        onSuccessfulSave={(data) => this.newBatch(data)}
                                        isNew={true}
                                        popup={true}
                                        onCancel={() => this.buttonAction({reactMethod: 'newBatch'})}
                                        form={'initializeFromState'}
                                        type={this.props.lines[this.state.lineNo].itId}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.mobileLines &&
                    <table className="mobileTable">

                            {this.state.mobileLines.map((line, no) => {
                                return <tbody>
                                <RenderMobileLines
                                    name="lines"
                                    columns={this.props.columns}
                                    search={this.onEnterClickSearchItem}
                                    lines={line}
                                    lineNo={no}
                                    settings={this.props.settings}
                                    oneLine={this.props.field.oneLine ? true : false}                                    
                                    remove={this.removeLine}
                                    formData={this.props.formData}
                                    formData={this.props.formData}
                                    calculateOperation={this.calculateOperation}
                                    change={this.props.change}
                                    edit={this.editMobileLine}
                                    //save={this.saveMobileLine}
                                    fieldId={this.props.field.id}
                                    buttonAction={this.buttonAction}
                                />

                        </tbody>
                            })}

                    </table>
                }
                {this.props.field.itemSearch &&
                        <span style={{float: 'left', marginTop: 20, marginLeft: 15}}>
                           <a className="add-customer-label" onClick={() => this.checkParams(this.props.field.itemSearch.dependency, this.props.field.itemSearch.action, this.props.field.itemSearch.message)}>
                            {this.props.field.itemSearch.icon &&
                                <i className={this.props.field.itemSearch.icon}></i>
                            }
                            <p>{plainTranslate(this.props.settings.locale, this.props.field.itemSearch.label)}</p>
                           </a>
                         </span>
                    }
                    {this.props.field.scanItem &&
                        <span style={{float: 'left', marginTop: 20, marginLeft: 15}}>
                           <a className="add-customer-label" onClick={this.openScanPopup}>
                           <i style={{paddingTop: 3}}className="fa fa-barcode" aria-hidden="true"></i>
                            <p>{plainTranslate(this.props.settings.locale, 'Scan')}</p>
                           </a>
                         </span>
                    }
                    
                <button className="button-outline grey text-uppercase" type="button" style={{float:"none", fontWeight: 'bold'}} onClick={() => this.buttonAction('newMobileLine')} >{plainTranslate(this.props.settings.locale, 'Add row')}</button>
                </div>
        }
    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableCollection));
