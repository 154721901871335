import {
    SETTINGS_LOAD,
    SETTINGS_SIDEBAR_CHANGE,
    // START GRID ACTIONS
    GRID_PROPERTIES_LOADED,
    GRID_DATA_LOADED,
    GRID_QUICK_FILTER_CHANGE,
    GRID_DATES_CHANGE,
    GRID_ROWS_SELECTED,
    GRID_HEADER_OPTION_SELECTED,
    GRID_HEADER_OPTION_LABEL_CHANGED,
    GRID_BULK_OPEN,
    GRID_BULK_ACTION_OPEN,
    GRID_COLUMNS_SHOW,
    GRID_COLUMNS_CHECK,
    GRID_COLUMNS_UPDATE,
    GRID_CONFIRM_DIALOG_SHOW,
    GRID_HEADER_SHOW_LOADER,
    GRID_VIEW_DIALOG_OPEN,
    GRID_VIEW_DIALOG_CONTENT,
    // END START GRID ACTIONS

    // START FORM ACTIONS
    FORM_LOADED,
    FORM_DATA_LOAD,
    FORM_FIELD_UPDATE,
    FORM_FIELD_LOSE_FOCUS,
    FORM_DATA_VALIDATION,
    FORM_INITIAL_VALIDATION,
    FORM_FIELD_SELECT2_OPTIONS,
    IMAGE_UPLOAD_SUCCESSFUL,
    IMAGES_UPLOAD_SUCCESSFUL,
    IMAGE_UPLOAD_LOADING,
    FILE_UPLOAD_SUCCESSFUL,
    UPLOAD_GET_COLUMNS,
    FORM_REMOVE_IMAGE,
    // END FORM ACTIONS

    // START POPUP MESSAGE ACTIONS
    POPUP_MESSAGE_SHOW,
    POPUP_MESSAGE_HIDE,
    // END POPUP MSSAGE ACTIONS

    //START EMPLOYEE PROFILE
    FETCH_EMPLOYEE_BEGIN,
    FETCH_EMPLOYEE_SUCCESS,
    FETCH_EMPLOYEE_FAILURE,
    //END EMPLOYEE PROFILE

    //START ORG STRUCTURE
    FETCH_STRUCTURE_BEGIN,
    FETCH_STRUCTURE_SUCCESS,
    FETCH_STRUCTURE_FAILURE,
    //END ORG STRUCTURE

    //START HR SETTINGS
    FETCH_MAX_LVL,
    FETCH_DEPARTMENT_NAMES,
    DEPARTMENT_NAME_FIELD_UPDATE,
    //END HR SETTINGS
    
    //START SETTINGS
    FETCH_SETTINGS,
    //END SETTINGS

    //START LINKS PROFILE
    FETCH_LINKS_BEGIN,
    FETCH_LINKS_SUCCESS,
    FETCH_LINKS_FAILURE,
    FETCH_CATEGORIES_BEGIN,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_FAILURE,
    UPDATE_LINK_FIELD,
    //END LINKS PROFILE

    //START USER ROLES
    USER_ROLES_GRID_LOAD,
    USER_ROLES_GRID_REMOVE, FORM_FIELD_SELECT2_ADD_OPTIONS,
    //END USER ROLES
    
    // START ACCOUNT PROFILE ACTIONS
    ACCOUNT_PROFILE_DATA_LOADING,
    ACCOUNT_PROFILE_DATA_LOADED,
    ACCOUNT_PROFILE_UPDATE_FIELD,
    ACCOUNT_PROFILE_EDIT_OPTIONS,
    ACCOUNT_EDIT_ACTIVITY,
    LOAD_CUSTOM_BLOCKS,
    UPDATE_BLOCK_RECORDS,
    SAVE_BLOCK_RECORDS,
    ADD_CONTACT_FIELD,
    DELETE_CONTACT_FIELD,
    DELETE_BILLING_DATA,
    UPDATE_CONTENT_RECORDS,
    //END ACCOUNT PROFILE ACTIONS
    
    // START ACCOUNT PROFILE COMMENTS
    COMMENTS_WIDGET_UNLOADED,
    ADD_COMMENT,
    EDIT_COMMENT,
    DELETE_COMMENT,
    //END ACCOUNT PROFILE COMMENTS

    //CALENDAR ACTIONS
    GET_ACTIVITIES,
    GET_EMPLOYEES,
    //END CALENDAR ACTIONS

    //INVOICE PROFILE ACTIONS
    INVOICE_PROFILE_DATA_LOADED,
    INVOICE_PROFILE_DATA_LOADING,

    GET_ITEM,

    GENERATE_REPORT_HTML,
    
    //START MAKE PAYMENT ACTIONS
    МАКЕ_PAYMENT_DATA_LOADED,
    //END MAKE PAYMENT ACTIONS
    
    //ITEM PROFILE
    ITEM_PROFILE_DATA_LOADING,
    ITEM_PROFILE_LOADING,
    ITEM_PROFILE_DATA_LOADED,
    //END ITEM PROFILE
    
    //ITEM REQUESTS
    ITEM_REQUEST_DATA_LOADING,
    ITEM_REQUESTS_DATA_LOADED,
    SINGLE_ITEM_REQUEST_DATA_LOADED,
    GET_ITEM_REQUESTS_STAGES,
    UPDATE_ITEM_REQUESTS_DATA,
    ITEM_REQUEST_SINGLE_VIEW,
    //END ITEM REQUESTS

    ACCOUNT_ADD_NEW_ACTIVITY


} from "../constants/actionTypes";
import client from './client'
import saveAs from 'file-saver';
import showAToast from '../components/common/showAToast'
import axios from "axios";
import {config} from '../config';
import {logoutUser} from './authActions';

export const openViewHandler = (open, content) => dispatch => {
    dispatch({
        type: GRID_VIEW_DIALOG_CONTENT,
        payload: content
    });
    dispatch({
        type: GRID_VIEW_DIALOG_OPEN,
        payload: open
    });
}

export const onSidebarMount = (route) => dispatch => {   
          return  client.get(route)
                .then(response => response.data)
                .then(response => {
                        dispatch({
                            type: SETTINGS_LOAD,
                            payload: response
                        });
                        
                    }
                )
                .catch(err => {
                    if(err.response && err.response.data.code && err.response.data.code === 401){
                        dispatch(logoutUser());           
                    }
                    showAToast('Error!', 'baseError', 'error')
                })       
}

export const onSidebarChange = (db, sidebar) => dispatch => {
    dispatch({
        type: SETTINGS_SIDEBAR_CHANGE,
        payload: {sidebar}
    });

    return client
    .post('/' + db + '/api/settings/sidebar/update', {'sidebarClass': sidebar})
    .then(response => {            
     })
    .catch(err => {        
        showAToast('Error!', 'baseError', 'error')
    })
}

export const onAddNewActivity = (data) => dispatch => {
    dispatch({ type: ACCOUNT_ADD_NEW_ACTIVITY, payload: { activity: data } })
}


// START GRID ACTIONS
var gridApi, gridColumnApi;
var waitingForData = true;

export const onGridMount = (route) => dispatch => {
    dispatch({
        type: GRID_ROWS_SELECTED,
        payload: {rows: 0}
    });

  return  client
    .get(''+route)
    .then(response => {
        if (response.data.columns) {
             dispatch({
                type: GRID_PROPERTIES_LOADED,
                payload: {
                    columns: response.data.columns,
                    visibleColumns: response.data.visibleColumns,
                    bulkActions: response.data.bulkActions,
                    header: response.data.header,
                    options: response.data.options,
                    pinnedRows: response.data.pinnedRows ? response.data.pinnedRows : null
                }
            });
        }
    })
    .catch(err => {
        if(err.response && err.response.data.code && err.response.data.code === 401){
            dispatch(logoutUser());           
        }
        showAToast('Error!', 'baseError', 'error')
    })
}
export const onGridFetchData = (route,signal) => dispatch => {
    //cancelRequest && cancelRequest();
    return   client
    .get(route,{
        cancelToken: signal
    })
    .then(response => {
             dispatch({
                type: GRID_DATA_LOADED,
                payload: {
                    data: response.data.data ? response.data.data : response.data,
                    pinnedRows: response.data.pinnedRows ? response.data.pinnedRows : null,
                    pages: response.data.pages ? response.data.pages : null,
                    totalRecords: response.data.totalRecords ? response.data.totalRecords : null
                }
            });
            if (waitingForData && gridApi) {
                gridApi.hideOverlay();
            }
            waitingForData = false;
            if(response.data && response.data.length === 20000){
                showAToast('The result is limited to 20 000, select a shorter period to see all the records!', 'baseError', 'error')
            }
        }
    )
    .catch(err => {
        if(err.response && err.response.data.code && err.response.data.code === 401){
            dispatch(logoutUser());           
        }
         if(!axios.isCancel(err)) {
            showAToast('Error!', 'baseError', 'error')
        }
    })
}

export function onCustomGridFetchData(route,filters,startDate,endDate, sort,currentPage,pageSize,signal, total=true) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            client
            .post(route, {
                filters: filters,
                currentPage: currentPage,
                startDate: startDate,
                endDate: endDate,
                sort: sort,
                pageSize: pageSize,
                total: total
            }, signal)
                .then(response => response.data)
                .then(response => {
                        dispatch({
                            type: GRID_DATA_LOADED,
                            payload: {
                                data: response.data ? response.data : response,
                                pinnedRows: response.pinnedRows ? response.pinnedRows : null,
                                pages: response.pages ? response.pages : null,
                                totalRecords: response.totalRecords ? response.totalRecords : null
                            }
                        });
                        if (waitingForData && gridApi) {
                            gridApi.hideOverlay();
                        }
                        waitingForData = false;
                        resolve();
                    })
                    .catch(err => {
                        showAToast('Error!', 'baseError', 'error')
                    })
        })
    }
}

export const onReportFetchData = (route,filters,startDate,endDate,currentPage, sort, limit=true,signal) => dispatch => {
    return client
    .post(route, {
         body: filters,
        currentPage: currentPage,
        startDate: startDate,
        endDate: endDate,
        sort: sort,
        hasLimit: limit
    },{cancelToken: signal})
    .then(response => {
            dispatch({
                type: GRID_DATA_LOADED,
                payload: {
                    data: response.data.data ? response.data.data : response.data,
                    pinnedRows: response.data.pinnedRows ? response.data.pinnedRows : null,
                    pages: response.data.pages ? response.data.pages : null,
                    totalRecords: response.data.totalRecords ? response.data.totalRecords : null
                }
            });
            if (waitingForData && gridApi) {
                gridApi.hideOverlay();
            }
            waitingForData = false;
        }
    )
    .catch(err => {
        if(!axios.isCancel(err)) {
            showAToast('Error!', 'baseError', 'error')
        }
    })
}

export const gridV2FetchData = (route,filters,signal) => dispatch => {
    return client
    .post(route, {
         body: filters
    },{cancelToken: signal})
    .then(response => {
            dispatch({
                type: GRID_DATA_LOADED,
                payload: {
                    data: response.data.data ? response.data.data : response.data,
                    pinnedRows: response.data.pinnedRows ? response.data.pinnedRows : null,
                    pages: response.data.pages ? response.data.pages : null,
                    totalRecords: response.data.totalRecords ? response.data.totalRecords : null
                }
            });
            if (waitingForData && gridApi) {
                gridApi.hideOverlay();
            }
            waitingForData = false;
        }
    )
    .catch(err => {
        if(!axios.isCancel(err)) {
            showAToast('Error!', 'baseError', 'error')
        }
    })
}

export const onGridMountV2 = (route, columns = null) => dispatch => {
  return  client
    .post(route, {columns})
    .then(response => {
        if (response.data.columns) {
             dispatch({
                type: GRID_PROPERTIES_LOADED,
                payload: {
                    columns: response.data.columns,
                    visibleColumns: response.data.visibleColumns,
                    bulkActions: response.data.bulkActions,
                    header: response.data.header,
                    options: response.data.options,
                    pinnedRows: response.data.pinnedRows ? response.data.pinnedRows : null
                }
            });
        }
    })
    .catch(err => {
        showAToast('Error!', 'baseError', 'error')
    })
}

export const onGridV2BulkActionClick = (url, data=null, filters=null, gridId=null) => dispatch => {
     return client.post(url, {
            'body': data,
            'filters': filters,
            'gridId': gridId
        })
        .then(response => {
            return response.data;
        })
        .then(response => {
            if (response.success) {
                if (response.download) {
                    if (response.type == 'application/pdf') {
                        var content = response.content;
                        var binary = atob(content.replace(/\s/g, ''));
                        var len = binary.length;
                        var buffer = new ArrayBuffer(len);
                        var view = new Uint8Array(buffer);
                        for (var i = 0; i < len; i++) {
                            view[i] = binary.charCodeAt(i);
                        }

                        let blob = new Blob([view], {type: response.type + ";charset=utf-8"});
                        saveAs(blob, response.name, {type: 'application/pdf;charset=utf-8'});

                    }else if (response.type == 'application/excel' || response.type == 'text/csv') {
                        var BOM = "\uFEFF";
                        var csvData = BOM + response.content;
                        let blob = new File([csvData], response.name, {type: response.type + ";charset=utf-8",encoding: "utf-8" });
                        saveAs(blob);
                    }
                    else {
                        let blob = new File([response.content], response.name, {type: response.type + ";charset=utf-8"});
                        saveAs(blob);
                    }
                }
            }
            if (response.redirectUrl) {
                if(response.newTab){
                    window.open(response.redirectUrl, '_blank');
                }else{
                    window.location = response.redirectUrl;
                }
            }
            if ('errors' in response) {
                showAToast(response.errors, 'errSettings1', 'error')
            }else{
                showAToast(response.success, 'succSettings', 'success')
            }
            
            return response;
        })
        .catch(err => {
            console.log(err.response)
            var error = 'Error!';               
            
            if(err.response && err.response.data.errors){
                error = err.response.data.errors;
            }
            showAToast(error, 'errSettings', 'error')
            return err.response.data;
        })
}

export function onGridV2ExportClick(route, filters,template, data, cols, selectAll) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            client.post(route,{
                'filters': filters,
                'template': template,
                'data': data,
                'cols': cols,
                'selectAll': selectAll
            })
            .then(response => {
                return response.data;
            })
            .then(response => {
                if (response.success) {
                    if (response.download) {
                        var BOM = "\uFEFF";
                        var csvData = BOM + response.content;
                        let blob = new File([csvData], response.name, {type: response.type + ";charset=utf-8",encoding: "utf-8" });
                        saveAs(blob);
                    }else if(response.downloadExcel){
                        var link = document.createElement("a");
                        link.download = 'export';
                        link.href = process.env[config] + '/' + response.name;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        delete link.href;
                    }
                }
                resolve(response);
            })
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
        })
    }
}

export const onReportApplyFilters = (data) => dispatch => {
    return dispatch({
        type: GRID_DATA_LOADED,
        payload: {
            data: data.data,
            pinnedRows: data.pinnedRows ? data.pinnedRows : null,
            pages: data.pages ? data.pages : null,
            totalRecords: data.totalRecords ? data.totalRecords : null
        }
    });
    if (waitingForData && gridApi) {
        gridApi.hideOverlay();
    }
    waitingForData = false;
}

export const onGridEmptyData = () => dispatch => {
    return dispatch({
        type: GRID_DATA_LOADED,
        payload: {data: []}
    });
}


export const onGridApiLoad = (grApi, gridColApi, header) => dispatch => {
    return new Promise((resolve, reject) => {
        gridApi = grApi;
        gridColumnApi = gridColApi;
        if (waitingForData) {
            gridApi.showLoadingOverlay();
        }
    })
}

export const onQuickFilterChange = (value) => dispatch => {
    return dispatch({
        type: GRID_QUICK_FILTER_CHANGE,
        payload: {value}
    });
}

export const onDatesChange = (startDate, endDate, route) => dispatch => {
    return dispatch({
        type: GRID_DATES_CHANGE,
        payload: {startDate, endDate}
    });
}

export const onRowSelection = (clear = false) => dispatch => {
    if (clear) {
        gridApi.deselectAll();
    }
    return dispatch({type: GRID_ROWS_SELECTED, payload: clear ? 0 : {rows: gridApi.getSelectedRows().length}});
}

export const onHeaderOptionSelected = (option) => dispatch => {
    gridApi.setFilterModel(null);
    dispatch(
        {
            type: GRID_HEADER_OPTION_SELECTED,
            payload: {option, gridApi}
        });

    gridApi.onFilterChanged();

    dispatch(
        {
            type: GRID_HEADER_OPTION_LABEL_CHANGED,
            payload: {option}
        });
}

export const onOpenBulk = (bulk) => dispatch => {
    return dispatch(
        {
            type: GRID_BULK_OPEN,
            payload: {bulk}
        });
}


export const onOpenBulkActionList = (bulk, action) => dispatch => {
    return dispatch(
        {
            type: GRID_BULK_ACTION_OPEN,
            payload: {bulk, action}
        });
}

export function onBulkActionClick(action, bulk, data, filter=false, startDate=false, endDate=false, quickFilter=false, sortedCols=false, selectedRowsData=false, selectAll=false, visibleColumns=null) {
    return dispatch => {

        dispatch({
            type: GRID_BULK_OPEN,
            payload: {bulk}
        });

        return new Promise((resolve, reject) => {
            if ('confirm' in action) resolve(dispatch({type: GRID_CONFIRM_DIALOG_SHOW, payload: {action}}));
            else resolve(handleBulkRowsAction({action}, {data}, {filter}, {startDate}, {endDate}, {quickFilter}, {sortedCols}, {selectedRowsData}, selectAll, visibleColumns));
        })
    }
}

export function onConfirmActionTrigger(action) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({type: GRID_CONFIRM_DIALOG_SHOW});
            if ('button' in action) resolve(handleCellRowAction(action)); // action is triggered by row button
            else resolve(handleBulkRowsAction(action)); // action is triggered by bulk action
        })
    }
}

function handleBulkRowsAction(payload, data=false, filter=false, startDate=false, endDate=false, quickFilter=false, sortedCols=false,selectedRowsData=false, selectAll=false, visibleColumns=null) {
    const action = payload.action;
    let selectedRows;

    if(selectedRowsData && selectedRowsData.selectedRowsData){
        selectedRows = selectedRowsData.selectedRowsData;
    }else{
        selectedRows = getSelectedRows();
    }    
     return client.post(action.route, {
            'body': selectedRows,
            'filterOptions': filter,
            'startDate': startDate,
            'endDate': endDate,
            'quickFilter': quickFilter,
            'sortedCols': sortedCols['sortedCols'],
            'workplace': localStorage.workplace !== 'undefined' ? localStorage.workplace : null,
            'selectAll': selectAll,
            'visibleColumns': visibleColumns
        })
            .then(response => {
                return response.data;
            })
            .then(response => {
                //saveAs(response, "test.pdf", {type: 'application/pdf;charset=utf8'});
                // return;
                if (response.success) {
                    if (response.download) {
                        if (response.type == 'application/pdf') {
                            var content = response.content;
                            var binary = atob(content.replace(/\s/g, ''));
                            var len = binary.length;
                            var buffer = new ArrayBuffer(len);
                            var view = new Uint8Array(buffer);
                            for (var i = 0; i < len; i++) {
                                view[i] = binary.charCodeAt(i);
                            }

                            let blob = new Blob([view], {type: response.type + ";charset=utf-8"});
                            saveAs(blob, response.name, {type: 'application/pdf;charset=utf-8'});

                        }else if (response.type === 'application/excel' || response.type === 'text/csv') {
                            var BOM = "\uFEFF";
                            var csvData = BOM + response.content;
                            let blob = new File([csvData], response.name, {type: response.type + ";charset=utf-8",encoding: "utf-8" });
                            saveAs(blob);
                        }
                        else if(response.type === 'text/ansi'){
                            let text = response.content;

                            for(var c, i = -1, l = (text = text.split("")).length, o = String.fromCharCode; ++i < l;
                                text[i] = (c = text[i].charCodeAt(0)) >= 127 ? o(0xc0 | (c >>> 6)) + o(0x80 | (c & 0x3f)) : text[i]
                            );
                             text = text.join("");
                            let blob = new File([text], response.name, {type: response.type + ";charset=windows-1252",encoding: "windows-1252"});
                            saveAs(blob);
                        }
                        else {
                            let blob = new File([response.content], response.name, {type: response.type + ";charset=utf-8"});
                            saveAs(blob);
                        }
                    }

                    if ('update' in action) {
                        action.update.forEach((field, key) => {
                            let itemsToUpdate = [];
                            gridApi.getSelectedRows().map(rowNode => {
                                rowNode[field.field] = field.value;
                                itemsToUpdate.push(rowNode);
                            });
                            gridApi.updateRowData({update: itemsToUpdate});
                        });
                    }
                    else if ('remove' in action) {
                        let rowsToRemove = [];
                        gridApi.getSelectedRows().map(rowNode => {
                            rowsToRemove.push(rowNode);
                        });
                        gridApi.updateRowData({remove: rowsToRemove});
                    }
                }
                if (response.redirectUrl) {
                    if(response.newTab){
                        window.open(response.redirectUrl, '_blank');
                    }else{
                        window.location = response.redirectUrl;
                    }
                }
                if ('errors' in response) {
                    showAToast(response.errors, 'errSettings1', 'error')
                }else{
                    showAToast(response.success, 'succSettings', 'success')
                }
                
                return response;
            })
            .catch(err => {
                console.log(err.response)
                var error = 'Error!';               
                
                if(err.response && err.response.data.errors){
                    error = err.response.data.errors;
                }
                showAToast(error, 'errSettings', 'error')
                return err.response.data;
            })
}

function getSelectedRows() {
    let selectedRows = gridApi.getSelectedRows();

    let selectedRowsString = "";
    selectedRows.forEach(function (selectedRow, index) {
        if (index !== 0) {
            selectedRowsString += ",";
        }
        selectedRowsString += selectedRow["id"];
    });

    return selectedRowsString;
}


export function onClickCalculateButtonClick(route, filter=false, startDate=false, endDate=false, quickFilter=false, sortedCols=false, visibleColumns) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            client.post(route, {
                'filterOptions': filter,
                'startDate': startDate,
                'endDate': endDate,
                'quickFilter': quickFilter,
                'sortedCols': sortedCols['sortedCols'],
                'visibleColumns': visibleColumns
            })
                .then(response => response.data)
                .then(response => {
                        dispatch({
                            type: GRID_DATA_LOADED,
                            payload: {
                                data: response.data ? response.data : response,
                                pinnedRows: response.pinnedRows ? response.pinnedRows : null
                            }
                        });
                        if (waitingForData && gridApi) {
                            gridApi.hideOverlay();
                        }
                        waitingForData = false;
                        resolve();
                    }
                )
                .catch(err => {
                    showAToast('Error!', 'baseError', 'error')
                })
        })
    }
}

export function onShowColumns() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({type: GRID_COLUMNS_SHOW});
            resolve();
        });
    }
}

export function onCheckColumn(key, childKey) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: GRID_COLUMNS_CHECK,
                payload: {key, childKey, gridColumnApi}
            });
            dispatch({type: GRID_COLUMNS_UPDATE, payload: {gridColumnApi}});
            resolve();
        });
    }
}

//export const onOpenBulkActionList = (bulk, action) => dispatch => {
export const onColumnUpdate = (route, cols) => dispatch => {
     
        return client.post(route, {'columns': cols})
                .then(response => response.data)
                .then(response => {
                       
                    }
                )
        .catch(err => {
            showAToast('Error!', 'baseError', 'error')
        })
}

export function onCellActionTrigger(button, node, route) {
    return dispatch => {
        return new Promise((resolve, reject) => {
                if ('confirm' in button) resolve(dispatch({
                    type: GRID_CONFIRM_DIALOG_SHOW,
                    payload: {button, node, route}
                }));
                else resolve(handleCellRowAction({button, node, route}));
            }
        )
    }
}

function handleCellRowAction(payload) {
    let button = payload.button;
    let node = payload.node;
    let route = payload.route;

    return  client.get(route)
            .then(response => response.data)
            .then(response => {
                //TODO: show message
                if (response.success) {
                    if ('update' in button) {
                        button.update.forEach((field, key) => {
                            node.data[field.field] = field.value;
                        });
                        gridApi.updateRowData({update: [node]});
                    }
                    else if ('remove' in button) {
                        let rowsToRemove = [];
                        gridApi.updateRowData({remove: [node.data]});
                    }else if ('updateGridData' in button){
                        let data = [];
                        data['updateGridData'] = true;
                        showAToast(response.success, 'succSettings', 'success');
                        return data;
                    }
                    showAToast(response.success, 'succSettings', 'success')
                }
                if ('errors' in response) {
                    showAToast(response.errors, 'errSettings2', 'error')
                }
               
            })
            .catch(err => {
                let message = "An error has occurred";
                if(err.response && err.response.data && err.response.data.message){
                    message = err.response.data.message;
                }else if(err.response && err.response.data && err.response.data.errors){
                    message = err.response.data.errors;
                }
                
                showAToast(message, 'baseErrorForm', 'error')
            })
    
}

// END GRID ACTIONS


// START FORM ACTIONS
export const onLoadForm = (formId, route) =>  dispatch => {
    return client.get(route)
                .then(response => response.data)
                .then(response => {
                        dispatch({
                            'type': FORM_DATA_LOAD,
                            payload: {
                                formId,
                                data: response.data,
                            }
                        });
                        dispatch({
                            'type': FORM_LOADED,
                            payload: {
                                formId,
                                form: response.form,
                                schema: response.schema,
                                isEdit: response.isEdit
                            }
                        });
                        return  response;
                      
                    }
                )
                .catch(err => {
                    if(err.response && err.response.data.code && err.response.data.code === 401){
                        dispatch(logoutUser());           
                    }
                    showAToast('Error!', 'baseError', 'error')
                })
      
}

export const onCustomerChangeLoadData = (formId, route , value) => dispatch => {
            return client.get(route+'?q='+value)
            .then(response => response.data)
            .then(response => {
                    dispatch({
                        'type': FORM_LOADED,
                        payload: {
                            formId,
                            form: response.form,
                            schema: response.schema,
                            isEdit: response.isEdit
                        }
                    });
               
            })
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
       
}

export function initialValidation(formId, form, schema, data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: FORM_INITIAL_VALIDATION,
                payload: {form, schema, data, formId}
            });

            resolve();
        })
    }
}

export function onFieldChange(formId, key, value) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: FORM_FIELD_UPDATE,
                payload: {key, value, formId}
            });

            resolve();
        })
    }
}

export function onLoseFocus(formId, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: FORM_FIELD_LOSE_FOCUS,
                payload: {id, formId}
            });

            resolve();
        })
    }
}

export function onValidateForm(formId, form, schema, data, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: FORM_DATA_VALIDATION,
                payload: {form, schema, data, id, formId}
            });

            resolve();
        })
    }
}

export const onSave = (formId, route, data) =>  dispatch => {
    if(!route.includes('translations') && !route.includes('translates')){
        Object.entries(data).forEach(([key, value]) => { 
            if(typeof (value) == 'string'){
                data[key] = value.trim();
            }
        })
    }
    return client.post(route, {
        body: data
    })
    .then(response => response.data)
    .then(response => {
      return response;
    })
    .catch(err => {
        let message = "An error has occurred";
        if(err.response && err.response.data && err.response.data.message){
            message = err.response.data.message;
        }else if(err.response && err.response.data && err.response.data.errors){
            message = err.response.data.errors;
        }
        if(err.response && err.response.data.code && err.response.data.code === 401){
            dispatch(logoutUser());           
        }
        showAToast(message, 'baseErrorForm', 'error')
        return message;
    })
}

export const onSelect2InputChange = (formId, id, value, route = null) => dispatch => {   
            if (route) {
              return client.get(route + '?q=' + value)
                    .then(response => response.data)
                    .then(response => {

/*                        dispatch({
                            type: FORM_FIELD_SELECT2_OPTIONS,
                            payload: {
                                formId,
                                options: response,
                                id: id,
                                value: value
                            }
                        }); */
                        return { payload: {
                                formId,
                                options: response,
                                id: id,
                                value: value
                            }}                    
                    })
                    .catch(err => {
                        showAToast('Error!', 'baseError', 'error')
                    })
            }else {
                dispatch({
                    type: FORM_FIELD_SELECT2_OPTIONS,
                    payload: {
                        formId,
                        id: id,
                        options: null,
                        value: value
                    }
                });
              
            }
      
}

export function onAddSelectOption(formId, properties) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: FORM_FIELD_SELECT2_ADD_OPTIONS,
                payload: {formId, properties}
            });

            resolve();
        })
    }
}

export const onUploadImage = (formId, db, id, file) =>  dispatch => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', id);
            
           return client
           .post('/' + db + '/api/settings/image/upload', formData)
                .then(response => response.data)
                .then(response => {
                    dispatch({
                        type: IMAGE_UPLOAD_SUCCESSFUL,
                        payload: {
                            formId,
                            image: response,
                            id: id
                        }
                    });
                    if(response.data && response.data.message){
                        showAToast(response.data.message, 'baseSuccess', 'success')
                    }                    
                    return response;
                })
                .catch(err => {
                    console.log(err)
                    if(err.response && err.response.data && err.response.data.message){
                        showAToast(err.response.data.message, 'baseError', 'error')
                    }else{
                        showAToast('Error!', 'baseError', 'error')
                    }                   
                })
}

export const onUploadImageV2 = (formId, db, id, url, file, name) =>  dispatch => {
            const formData = new FormData();
            formData.append('files[]', file);
            formData.append('type', id);
            if(name){
                formData.append('name', name)
            }
            
            if(!url){
                url = '/' + db + '/api/settings/image/upload';
            }

           return client
           .post(url, formData)
                .then(response => response.data)
                .then(response => {
                    dispatch({
                        type: IMAGE_UPLOAD_SUCCESSFUL,
                        payload: {
                            formId,
                            image: response,
                            id: id
                        }
                    });
                    if(response.data && response.data.message){
                        showAToast(response.data.message, 'baseSuccess', 'success')
                    }                    
                    return response;
                })
                .catch(err => {
                    console.log(err)
                    if(err.response && err.response.data && err.response.data.message){
                        showAToast(err.response.data.message, 'baseError', 'error')
                    }else{
                        showAToast('Error!', 'baseError', 'error')
                    }                   
                })
}

export const onUploadImages = (formId, db, id, folder, files, config = null, shares = null) =>  dispatch => {
  
            const data = new FormData();
            const h = new Headers();

            for (const file of files) {
              data.append('files[]', file);
            }

            let url = '/' + db + '/api/settings/images/upload' + '?folder=' + folder;
            if (shares) {
                url = '/' + db + '/api/settings/images/upload' + '?folder=' + folder + '&shares=' + shares;
            }

            return  client.post(url, data, config)
                .then(response => response.data)
                .then(response => {
                        if (response instanceof Array) {
                            dispatch({
                                type: IMAGES_UPLOAD_SUCCESSFUL,
                                payload: {
                                    formId,
                                    images: response,
                                    id: id
                                }
                            });
                            return response;
                        } else {                          
                            dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: response.msg,
                                    error: response.status
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
                            return response;
                        }
                   
                })
                .catch(err => {
                    console.log(err)
                    showAToast('Error!', 'baseError', 'error')
                })
}

export const onUploadFile = (db, type, file) =>  dispatch => {
  
            const formData = new FormData();
            formData.append('file', file);
            return client.post('/' + db + '/api/import/file/'+type, formData)
                .then(response => response.data)
                .then(response => {

                    dispatch({
                        type: FILE_UPLOAD_SUCCESSFUL,
                        payload: {
                            file: response
                        }
                    });

                    return response;
                })
                .catch(err => {
                    showAToast('Error!', 'baseError', 'error')
                })
}

export const onUploadFileGetColumns = (db, type, fileId) =>  dispatch => {
    return  client.post('/' + db + '/api/import/upload/'+type+'/'+fileId)
                .then(response => response.data)
                .then(response => {
                    dispatch({
                        type: UPLOAD_GET_COLUMNS,
                        payload: {
                            data:response
                        }
                    });

                   
                })
                .catch(err => {
                    showAToast('Error!', 'baseError', 'error')
                })
}

export const onDeleteImage = (id, formId, fieldId) => ({
    type: FORM_REMOVE_IMAGE,
    payload: {id: id, formId: formId, fieldId: fieldId}
});

// END FORM ACTIONS


// START POPUP MESSAGE
export function onShowMessage(text, isError) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({type: POPUP_MESSAGE_SHOW, payload: {text, isError}});
        })
    }
}

export function onHideMessage() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({type: POPUP_MESSAGE_HIDE});
        })
    }
}

// END POPUP MESSAGE

// START PROFILE
export const fetchEmployeeBegin = () => ({
    type: FETCH_EMPLOYEE_BEGIN
});

export const fetchEmployeeSuccess = employee => ({
    type: FETCH_EMPLOYEE_SUCCESS,
    payload: {employee}
});

export const fetchEmployeeFailure = error => ({
    type: FETCH_EMPLOYEE_FAILURE,
    payload: {error}
});

export const fetchEmployee = (employeeId, db) => dispatch => {
  
        dispatch(fetchEmployeeBegin());
        return client.post('/' + db + '/load/employee/' + employeeId + '/profile')
            .then(response => response.data)
            .then(response => {
                dispatch(fetchEmployeeSuccess(response.employee));
                return response.employee;
            })
            .catch(error => dispatch(fetchEmployeeFailure(error)));
    
}


// END PROFILE

//START ORG STRUCTURE
export const fetchDataBegin = () => ({
    type: FETCH_STRUCTURE_BEGIN
});

export const fetchDataSuccess = data => ({
    type: FETCH_STRUCTURE_SUCCESS,
    payload: {data}
});

export const fetchDataFailure = error => ({
    type: FETCH_STRUCTURE_FAILURE,
    payload: {error}
});

export const fetchData = (id, db)  => dispatch => {
  
        dispatch(fetchDataBegin());
        return client.post('/' + db + '/api/hr/load/' + id + '/structure/data')
            .then(response => response.data)
            .then(response => {
                dispatch(fetchDataSuccess(response.data));
                return response.data;
            })
            .catch(error => dispatch(fetchDataFailure(error)));
    
}


//END ORG STRUCTURE

//START HR SETTINGS
export const fetchLvlSuccess = data => ({
    type: FETCH_MAX_LVL,
    payload: {data}
});

export const maxLvl = (db)  => dispatch => {
   
        return client.post('/' + db + '/api/hr/get/max/lvl')
            .then(response => response.data)
            .then(response => {
                dispatch(fetchLvlSuccess(response.data));
                return response.data;
            })
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
}

export const fetchNamesSuccess = data => ({
    type: FETCH_DEPARTMENT_NAMES,
    payload: {data}
});

export const fetchDepartmentNames = (db) => dispatch => {
  
        return client.post('/' + db + '/api/hr/get/department/custom/names')
            .then(response => response.data)
            .then(response => {
                dispatch(fetchNamesSuccess(response.data));
                return response.data;
            })
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
}

export function onFieldNameChange(names) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: DEPARTMENT_NAME_FIELD_UPDATE,
                payload: {names}
            });
            resolve();
        })
    }
}

export const onDepartmentNamesSave = (db, data) => dispatch => {  
            return client.post('/' + db + '/api/hr/save/department/names/settings', {
                body: data
            })
                .then(response => response.data)
                .then(response => {
                   return response;
                })
                .catch(err => {
                    showAToast('Error!', 'baseError', 'error')
                })
       
}

//END HR SETTINGS

// START LIST LINKS
export const fetchLinksBegin = () => ({
    type: FETCH_LINKS_BEGIN
});

export const fetchLinksSuccess = links => ({
    type: FETCH_LINKS_SUCCESS,
    payload: {links}
});

export const fetchLinksFailure = error => ({
    type: FETCH_LINKS_FAILURE,
    payload: {error}
});

export const fetchCategoriesSuccess = categories => ({
    type: FETCH_CATEGORIES_SUCCESS,
    payload: {categories}
});

export const fetchLinks = (db) => dispatch => {
        dispatch(fetchLinksBegin());
        return client.post('/' + db + '/api/load/links', )
            .then(response => response.data)
            .then(response => {
                dispatch(fetchLinksSuccess(response.links));
                return response;
            })
            .catch(error => dispatch(fetchLinksFailure(error)));
    
}

export const fetchCategoriesBegin = () => ({
    type: FETCH_CATEGORIES_BEGIN
});

export const fetchCategoriesFailure = error => ({
    type: FETCH_CATEGORIES_FAILURE,
    payload: {error}
});

export const fetchCategories = (db)  => dispatch => {
  
        dispatch(fetchCategoriesBegin());
        return client.post('/' + db + '/api/load/categories')
            .then(response => response.data)
            .then(response => {
                dispatch(fetchCategoriesSuccess(response.categories));
                return response;
            })
            .catch(error => dispatch(fetchCategoriesFailure(error)));
    
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export function onUpdateLinkField(id, link, remove) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: UPDATE_LINK_FIELD,
                payload: {id, link, remove}
            });
            resolve();
        })
    }
}

// END LINKS

// START USER ROLES
export const onUserRolesGridLoad = (db) => dispatch => {
    return  client.get('/' + db + '/api/user-roles')
                .then(response => response.data)
                .then(response => {
                        dispatch({
                            type: USER_ROLES_GRID_LOAD,
                            payload: {roles: response}
                        });
                    })
                    .catch(err => {
                        showAToast('Error!', 'baseError', 'error')
                    })     
}

export const onUserRolesGridRemove = (db, id) => dispatch => {
 
        dispatch({
            type: USER_ROLES_GRID_REMOVE,
            payload: {id: id}
        });
        return client.get('/' + db + '/api/user-roles/' + id + '/delete')
                .then(response => response.data)
                .then(response => {
                    })
                    .catch(err => {
                        showAToast('Error!', 'baseError', 'error')
                    })
        
}

// END USER ROLES

// ACCOUNT PROFILE ACTIONS
export const fetchAccountDataBegin = () => ({
    type: ACCOUNT_PROFILE_DATA_LOADING
});

export const onAccountFetchData = (db, id, start=true) => dispatch => {
      if(start){
        dispatch(fetchAccountDataBegin());  
     }           
      
        let route = '/' + db + '/api/account/' + id;
        return client.post(route)
                .then(response => response.data)
                .then(response => {
                        if ('error' in response) {
                            // TODO: show error message
                        }
                        else {
                            dispatch({
                                type: ACCOUNT_PROFILE_DATA_LOADED,
                                payload: {
                                    data: response
                                }
                            });
                        }
                     
                    }
                )
                .catch(err => {
                    showAToast('Error!', 'baseError', 'error')
                })
      
}

export const onFieldSave = (route, id, value, additionalData, additionalId) => dispatch => {
    
        return client
                .post(route, {id, value, additionalData, additionalId})
                .then(response => {
                    return response.data
                })
                .then(response => {
                        if (response && ('error' in response)) {
                            // TODO: show error message
                        } else {
                            dispatch({
                                type: ACCOUNT_PROFILE_UPDATE_FIELD,
                                payload: {id, value, additionalId}
                            });
                        }
                    })
                    .catch(err => {
                        showAToast('Error!', 'baseError', 'error')
                    })
      
}
export function onEditOptionsLoad(route) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            client.get(route)
                .then(response => response.data)
                .then(response => {
                        if ('error' in response) {
                            // TODO: show error message
                        }
                        else {
                            dispatch({
                                type: ACCOUNT_PROFILE_EDIT_OPTIONS,
                                payload: {
                                    data: response
                                }
                            });
                        }
                        resolve();
                    }
                )
                .catch(err => {
                    showAToast('Error!', 'baseError', 'error')
                })
        })
    }
}

export function onTabsLoad(route, page, requireTotal) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            client.post(route,{
                'currentPage': page, 
                'total': requireTotal,
                'currentTab': localStorage.getItem('activeCrmTab') 
                            ? localStorage.getItem('activeCrmTab') 
                            : 'activities_tab',
            })
                .then(response => response.data)
                .then(response => {
                        if ('error' in response) {
                            // TODO: show error message
                        }
                        else {
                            dispatch({
                                type: ACCOUNT_PROFILE_EDIT_OPTIONS,
                                payload: {
                                    data: response
                                }
                            });
                        }
                        resolve();
                    }
                )
                .catch(err => {
                    showAToast('Error!', 'baseError', 'error')
                })
        })
    }
}

export function onDeleteActivity(route, id, thread) {
    return dispatch => {
        dispatch({
            type: ACCOUNT_EDIT_ACTIVITY,
            payload: {id, thread}
        });
        return new Promise((resolve, reject) => {
            client.post(route, {id})
                .then(response => response.data)
                .then(response => {
                        if ('error' in response) {
                            // TODO: show error message
                        }
                        else {
                            // TODO: show success message
                        }
                        resolve();
                    }
                )
                .catch(err => {
                    showAToast('Error!', 'baseError', 'error')
                })
        })
    }
}

export function onDeleteBilling() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: DELETE_BILLING_DATA,
            });
            resolve();
        })
    }
}

export const fetchCustomBlocks = (db, listId) =>  dispatch => {
 
    return client.post('/' + db + '/api/data-blocks/all/profile/' + listId)
     .then(response => response.data)  
      .then(response =>  dispatch({type: LOAD_CUSTOM_BLOCKS, payload: {'customBlocks': response}}))
      .catch(err => {
        showAToast('Error!', 'baseError', 'error')
    })
  
}

export const onUpdateBlockRecords = (db, blockId, accountId) =>  dispatch => {
 
    return client.post('/' + db + '/api/data-blocks/results/' + blockId + '/' + accountId)
      .then(response => response.data)  
      .then(response =>  dispatch({type: UPDATE_BLOCK_RECORDS, payload: {'dataEntry': response}}))
      .catch(err => {
        showAToast('Error!', 'baseError', 'error')
    })
  
}

export function onUpdateContentRecords(db, typeId, accountId, linkTables, links) {
  let url = '/' + db + '/web/content-data-find/' + typeId + '?accountId=' + accountId;
  if (linkTables) {
      url = '/' + db + '/web/content-data-find/' + typeId + '/account?accountId=' + accountId
  }
  return dispatch => {
      return client.get(url)
     .then(response => response.data)  
      .then(response =>  dispatch({type: UPDATE_CONTENT_RECORDS, payload: {'contentData': response}}))
      .catch(err => {
        showAToast('Error!', 'baseError', 'error')
    })
  };
}

export function saveRecord(id, dataEntry, del) {
  return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: SAVE_BLOCK_RECORDS,
                payload: {id, dataEntry, del}
            });
            resolve();
        })
    }
}

export function onAddContactField(value, account, db) {
    return dispatch => {
        return client.post('/' + db + '/api/account/'+ account +'/contact/new', {value})
            .then(response => response.data)
            .then(response => dispatch({type: ADD_CONTACT_FIELD, payload: {'contact': response}}))
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
    }
}

export function onDeleteContactField(contactId) {
  return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: DELETE_CONTACT_FIELD,
                payload: {contactId}
            });
            resolve();
        })
    }
}

export const  onFilterActivitiesByType = (db, type, account) => dispatch => {
            client
            .post('/' + db + '/api/account/' + account + '/activities/' + type + '/filter')
                .then(response => response.data)
                .then(response => {
                        if ('error' in response) {
                            // TODO: show error message
                        }
                        else {
                            dispatch({
                                type: ACCOUNT_PROFILE_EDIT_OPTIONS,
                                payload: {
                                    data: response
                                }
                            });
                        }
                    }
                )
                .catch(err => {
                    showAToast('Error!', 'baseError', 'error')
                })
        
    
}
// END ACCOUNT PROFILE ACTIONS

// END ACCOUNT PROFILE COMMENTS

export const addComment = (userId, db, text) => {
    return dispatch => {
        return client.post('/' + db + '/api/ui/comment/post/' + userId, {comment: text})
            .then(response => response.data)
            .then(response => dispatch({type: ADD_COMMENT, payload: {'comment': response.comment}}))
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
    }
}

export const deleteComment = (db, id) => dispatch => {  
        dispatch({'type': DELETE_COMMENT, 'id': id});
        return client.get('/' + db + '/api/ui/comment/delete/' + id)
            .then(response => response.data)
            .then(response => Promise.resolve())
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })

}

export const editComment = (db, id, text) => dispatch => {
    
        dispatch({'type': EDIT_COMMENT, 'id': id, 'message': text});
        return client.post('/' + db + '/api/ui/comment/edit/' + id, {'comment': text})
            .then(response => response.data)
            .then(response => Promise.resolve())
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
}
// END ACCOUNT PROFILE COMMENTS

//START SETTINGS
 export const fetchSettings = (url, type) =>  dispatch => {
        return client.post(url)
            .then(response => response.data)
            .then(response => {
                dispatch(fetchSettingsSuccess(response, type));
                return response;
            })
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
}
export const fetchSettingsSuccess = (settings, type) => ({
    type: FETCH_SETTINGS,
    payload: {settings, type}
});

//END SETTINGS

//CALENDAR ACTIONS

//CALENDAR ACTIONS
export function getCalendarEmployees(db) {
    return dispatch => {
        let route = '/' + db + '/api/calendar/employees';

        return new Promise((resolve, reject) => {
            client.get(route)
            .then(response => response.data)
            .then(response => {
                    dispatch({
                        'type': GET_EMPLOYEES,
                        payload: {
                            data: response
                        }
                    });
                    resolve(response.data);
                }
            )
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
        })
    }
}
// INVOICE PROFILE ACTIONS
export const fetchInvoiceDataBegin = () => ({
    type: INVOICE_PROFILE_DATA_LOADING
});

export function onDocumentProfileFetchData(url) {
    return dispatch => {
        dispatch(fetchAccountDataBegin());
        return new Promise((resolve, reject) => {
            let route = url;
            client.get(route)
            .then(response => response.data)
            .then(response => {
                    if ('error' in response) {
                        // TODO: show error message
                    }
                    
                    else {
                        dispatch({
                            type: INVOICE_PROFILE_DATA_LOADED,
                            payload: {
                                data: response
                            }
                        });
                    }
                    resolve();
                }
            )
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
        })
    }
}

export function onGridExportClick(route, data, cols, template=null, action=null, filters=null) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            client.post(route,{
                'data': data,
                'cols': cols,
                'template': template,
                'action': action,
                'filters' : filters
            })
            .then(response => {
                return response.data;
            })
            .then(response => {
                if (response.success) {
                    if (response.download) {
                            var BOM = "\uFEFF";
                            var csvData = BOM + response.content;
                            let blob = new File([csvData], response.name, {type: response.type + ";charset=utf-8",encoding: "utf-8" });
                            saveAs(blob);
                    }else if(response.downloadExcel){
                        var link = document.createElement("a");
                        link.download = 'export';
                        link.href = process.env[config] + '/' + response.name;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        delete link.href;
                    }
                }
                resolve(response);
            })
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
        })
    }
}

export function onReportExportClick(route, filters, startDate,endDate,sortedCols,templ) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            client.post(route,{
                'filters': filters,
                'startDate': startDate,
                'endDate': endDate,
                'sortedCols': sortedCols,
                'templ': templ
            })
            .then(response => {
                return response.data;
            })
            .then(response => {
                if (response.success) {
                    if (response.download) {
                        var BOM = "\uFEFF";
                        var csvData = BOM + response.content;
                        let blob = new File([csvData], response.name, {type: response.type + ";charset=utf-8",encoding: "utf-8" });
                        saveAs(blob);
                    }
                }
                resolve(response);
            })
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
        })
    }
}

export function searchItem(url, itemId, locId=null, docDate=null, cust=null, importAvailableItems=null) {

    return dispatch => {
        return new Promise((resolve, reject) => {
            client.post(url, {
                'body': itemId,
                'locId': locId,
                'documentDate': docDate,
                'custId': cust,
                'importAvailableItems': importAvailableItems
            })
            .then(response => response.data)
            .then(response => {
                    dispatch({
                        type: GET_ITEM,
                        payload: {
                            data: response
                        }
                    });
                    resolve(response.data);
                }
            )
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
        })
    }
}

export function generatePaymentsReportHTML(db, balance, lines, startingBalance, paymentMethods, locIds, startDate, endDate, cashInflow, cashOutflow) {

    return dispatch => {
        return new Promise((resolve, reject) => {
            client.post('/' + db + '/api/reports/payment/export.pdf',{
                'balance': balance,
                'lines': lines,
                'startingBalance': startingBalance,
                'paymentMethods': paymentMethods,
                'locIds': locIds,
                'startDate': startDate,
                'endDate': endDate,
                'cashInflow' : cashInflow,
                'cashOutflow': cashOutflow,
            })
            .then(response => response.data)
            .then(response => {
                    dispatch({
                        type: GENERATE_REPORT_HTML,
                        payload: {
                            data: response
                        }
                    });
                    resolve(response.data);
                }
            )
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
        })
    }
}

export function renderReportHTML(url) {

    return dispatch => {
        return new Promise((resolve, reject) => {
            client.get(url)
            .then(response => response.data)
            .then(response => {
                    dispatch({
                        type: GENERATE_REPORT_HTML,
                        payload: {
                            data: response
                        }
                    });
                    resolve(response.data);
                }
            )
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
        })
    }
}

export function generateSmallReportHTML(db, lines, path, total) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            client.post('/' + db + path, {
                'lines': lines,
                'total': total,
            })
            .then(response => response.data)
            .then(response => {
                dispatch({
                    type: GENERATE_REPORT_HTML,
                    payload: {
                        data: response
                    }
                });
                resolve(response.data);
            })
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
        })
    }
}

//MAKE PAYMENT ACTIONS
export function onPaymentFetchData(db, id) {
    return dispatch => {
        dispatch(fetchAccountDataBegin());
        return new Promise((resolve, reject) => {
            let route = '/' + db + '/api/sale/' + id + '/make/payment'; 
            client.get(route)
                .then(response => response.data)
                .then(response => {
                        if ('error' in response) {
                            // TODO: show error message
                        }
                        else {
                            dispatch({
                                    type: МАКЕ_PAYMENT_DATA_LOADED,
                                payload: {
                                    data: response
                                }
                            });
                        }
                        resolve();
                    }
                )
                .catch(err => {
                    showAToast('Error!', 'baseError', 'error')
                })
        })
    }
}
//END MAKE PAYMENT ACTIONS

//ITEM PROFILE
export const fetchItemDataBegin = () => ({
    type: ITEM_PROFILE_DATA_LOADING
});
export const fetchItemProfileBegin = () => ({
    type: ITEM_PROFILE_LOADING
});
export function onItemProfileFetchData(url, type) {
    return dispatch => {
        if (type === 'item') {
            dispatch(fetchItemProfileBegin());
        } else {
            dispatch(fetchItemDataBegin());
        }
        return new Promise((resolve, reject) => {
            let route = url;
            client.get(route)
            .then(response => response.data)
            .then(response => {
                    if ('error' in response) {
                        showAToast('Error!', 'baseError', 'error')
                    }
                    else {
                        dispatch({
                            type: ITEM_PROFILE_DATA_LOADED,
                            payload: {
                                type, 
                                data: response
                            } 
                        });
                    }
                    resolve();
                }
            )
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
        })
    }
}
//END ITEM PROFILE

//ITEM REQUESTS
export const fetchItemRequestDataBegin = () => ({
    type: ITEM_REQUEST_DATA_LOADING
});
export function onItemRequestsFetchData(db, url, start, end, stages, page, fromLocation, toLocation, isProcessable, isSale, vendor,isDeal) {
    return dispatch => {
        dispatch(fetchItemRequestDataBegin());
        return new Promise((resolve, reject) => {
            let route = url;
            client.post(route, {
                stages: stages, 
                page: page, 
                startDate: start,
                endDate: end,
                fromLocation: fromLocation,
                toLocation: toLocation,
                isProcessable: isProcessable,
                isSale: isSale,
                vendor: vendor,
                isDeal: isDeal,
            })
            .then(response => response.data)
            .then(response => {
                    if ('error' in response) {
                        
                    }
                    else {
                        dispatch({
                            type: ITEM_REQUESTS_DATA_LOADED,
                            payload: {
                                data: response
                            }
                        });
                            if (response['data'].length > 0) {
                                dispatch(onItemRequestView('/' + db + '/api/ordered/item/' + response['data'][0]['id'] + '/details'));
                            }
                    }
                    resolve();
                }
            )
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
        })
    }
}
export const fetchItemRequestSingleView = () => ({
    type: ITEM_REQUEST_SINGLE_VIEW
});
export function onItemRequestView(url) {
    return dispatch => {
        dispatch(fetchItemRequestSingleView());
        return new Promise((resolve, reject) => {
            let route = url;
            client.get(route)
            .then(response => response.data)
            .then(response => {
                    if ('error' in response) {
                        
                    }
                    else {
                        dispatch({
                            type: SINGLE_ITEM_REQUEST_DATA_LOADED,
                            payload: {
                                data: response
                            }
                        });
                    }
                    resolve();
                }
            )
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
        })
    }
}
export function onStagesFetchData(url) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            let route = url;
            client.get(route)
            .then(response => response.data)
            .then(response => {
                    if ('error' in response) {
                        
                    }
                    else {
                        dispatch({
                            type: GET_ITEM_REQUESTS_STAGES,
                            payload: {
                                data: response
                            }
                        });
                    }
                    resolve();
                }
            )
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            })
        })
    }
}
export function onChangeStage(stage, request, name) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: UPDATE_ITEM_REQUESTS_DATA,
                payload: {stage, request, name}
            });
            resolve();
        })
    }
}
//END ITEM REQUESTS
