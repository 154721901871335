import React from 'react';
import {connect} from 'react-redux';

class Percents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {    
        this.setState({
            value: this.props.data !== '' && this.props.data && this.props.data !== undefined ? this.props.data : ''
        });
    }    

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data && nextProps.data !== prevState.value) {         
            return {
                value: nextProps.data
            }          
        }else{
            return null;
        }
      }

    onChange(e) {
        this.setState({
            value: e.target.value
        })
        this.props.change(this.props.field.id, e.target.value);
    }

    render() {
        return <div className="col-xs-12 col-sm-12 no-gutter">
            <span className="form-input-field">
                <input type="number"
                        name={this.props.name}
                       required="required"
                       className="text-right form-control percent-input"
                       value={this.state.value}
                       onChange={this.onChange}
                       placeholder={this.props.placeholder ? this.props.placeholder : ''}
                       disabled={this.props.field.disabled}
                       tabIndex={this.props.tabindex ? this.props.tabindex : ''}
                style={{width: '50%', minWidth: '45px', padding: '5px', borderRadius: '3px 0 0 3px'}}/>
            </span>
            <span className="input-group-addon" style={{width: 'auto', height: '40px'}}>%</span>
        </div>
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Percents);
