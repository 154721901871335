import React from 'react';
import { plainTranslate } from '../../common/translate';
import { connect } from 'react-redux';
import { RightOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons'
import 'antd/lib/icon/style/index.css';
import 'antd/lib/modal/style/index.css';
import 'antd/lib/button/style/index.css';
import { Droppable, Draggable } from 'react-beautiful-dnd';

const Section = props => {

    return (
        <div>
            {props.item &&
                <div className="accordion-body">
                    <div className="accordion-inner">
                        <div className="accordion" style={{ padding: "0 0 0 18px" }}>
                            <div className="accordion-group col-sm-12 no-gutter">
                                <div className="accordion-heading area collapsible col-md-12 no-gutter">
                                    <div className="col-md-9 col-xs-6" onClick={() => props.handleShowContent()}>
                                        {props.item && props.item.sections && props.item.sections.length > 0 &&
                                            <span style={{ marginRight: 10 }} >
                                                <RightOutlined />
                                            </span>
                                        }
                                        <strong>{props.item.type == "container" ? "" : "Section - "} {props.item?.name} - {props.item.id}</strong>
                                    </div>
                                    <div className="col-md-3 col-xs-6">
                                        {props.type == "container" ?
                                            <>
                                                <span onClick={() => props.addSection(props.containerId, props.item)} style={{ marginRight: 10 }} ><FormOutlined title={plainTranslate(props.locale, 'Add section')} /> {plainTranslate(props.locale, 'Add section')}</span>
                                                <span onClick={() => props.addInnerContainer(props.containerId, props.item)} style={{ marginRight: 10 }} ><FormOutlined title={plainTranslate(props.locale, 'Edit container')} /> {plainTranslate(props.locale, 'Add container')}</span>
                                                <span onClick={() => props.editContainer(props.containerId, props.item)} style={{ marginRight: 10 }} ><FormOutlined title={plainTranslate(props.locale, 'Edit container')} /> {plainTranslate(props.locale, 'Edit')}</span>
                                                <span onClick={() => props.remove(props.parent, props.item)}><DeleteOutlined title={plainTranslate(props.locale, 'Remove container')} /> {plainTranslate(props.locale, 'Delete')}</span>
                                            </>
                                            :
                                            <>
                                                <span onClick={() => props.editSection(props.parent, props.item, props.sectionId)} style={{ marginRight: 10 }} >    <FormOutlined title={plainTranslate(props.locale, 'Edit section')} /> {plainTranslate(props.locale, 'Edit')}</span>
                                                <span onClick={() => props.remove(props.parent, props.item)}><DeleteOutlined title={plainTranslate(props.locale, 'Remove section')} /> {plainTranslate(props.locale, 'Delete')}</span>
                                            </>
                                        }

                                    </div>
                                </div>
                                {props.showContent &&
                                    <div className="content">
                                        <Droppable droppableId={props.item.id} type={props.item.id}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                >
                                                    {props.item && props.item.sections && props.item.sections.length > 0 && props.item.sections.map((child, key) =>
                                                        <Draggable key={child.id} draggableId={child.id} index={key}>
                                                            {(provided, snapshot) => (
                                                                <div style={{ display: "table", width: '100%' }}>
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <Section
                                                                            type={child.type == "container" ? "container" : "section"}
                                                                            item={child} key={key}
                                                                            db={props.db}
                                                                            containerId={props.containerId}
                                                                            sectionId={key}
                                                                            parent={props.item}
                                                                            remove={(parent, child) => props.remove(parent, child)}
                                                                            addSection={(key, child) => props.addSection(key, child)}
                                                                            addInnerContainer={(key, child) => props.addInnerContainer(key, child)}
                                                                            editContainer={(key, child) => props.editContainer(key, child)}
                                                                            editSection={(parent, item, key) => props.editSection(parent, item, key)}
                                                                            handleShowContent={() => props.handleShowContent()}
                                                                            showContent={props.showContent}
                                                                        />
                                                                    </div>
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Section);
