import React, { useEffect, useState } from 'react';
import client from '../../../actions/client';
import { plainTranslate } from "../../common/translate";
import { Tooltip } from 'antd';

var warning = null;

const handleEnterClick = (props, e) => {
    if (props.field.id === 'itName') {
        if (e.key === 'Enter') {
            props.search(e.target.value, props.input.name);

        } else if (e.target.id === 'searchButton') {
            let value = e.target.nextElementSibling.value;

            props.search(value, props.input.name);
        }
    }
}

const Text = props => {
    const [warn, setWarn] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);

    function handleOutFocus(props, e) {
        props.input.onBlur()
        if ('isUnique' in props.field && props.field.isUnique) {
            client
                .post(props.field.isUnique, { field: props.field.id, value: props.input.value })
                .then(res => {
                    if (res.data.notunique) {
                        var warning = plainTranslate(props.locale, "An account with ") + plainTranslate(props.locale, props.field.id) + ': ' + props.input.value + plainTranslate(props.locale, " already exists!");
                        setWarn(warning)
                    } else {
                        setWarn('')
                    }
                })
                .catch(err => {

                })
        }
        if ('autofill' in props.field && props.field.autofill) {
            if (!props.formData[props.field.connectedField] || props.formData[props.field.connectedField] == '') {
                client
                    .post(props.field.autofill, { field: props.field.id, value: props.input.value })
                    .then(res => {
                        if (!props.formData[props.field.connectedField] || props.formData[props.field.connectedField] == '') {
                           props.change(props.field.connectedField, res.data.newValue);
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        }
    }
    
    return (<span className="form-input-field" style={{ position: 'relative' }}>
        {warn && <span style={{ color: 'orangered', margin: 10 }}>{warn}</span>}
        {props.field.icon && <i style={{ position: 'absolute', paddingTop: 13, left: 120 }} id="searchButton" onClick={(e) => handleEnterClick(props, e)} className="fa fa-search"></i>}
        <Tooltip placement="bottom" title={props.input.value} visible={showTooltip}>
            <input
                onChange={() => { }}
                onKeyDown={(e) => handleEnterClick(props, e)}
                type="text"
                className={"form-control " + props.field.class}
                {...props.input}
                onBlur={(e) => handleOutFocus(props, e)}
                style={{ minWidth: props.field.width ? props.field.width : 'unset' }}
                disabled={props.field.disabled}
                placeholder={props.field.placeholder}
                onTouchStart={() => setShowTooltip(!showTooltip)}
                onMouseOver={() => setShowTooltip(props.field.tooltip ? true : false)}
                onMouseLeave={() => setShowTooltip(false)}
            /></Tooltip>
    </span>)
}

export default Text;
