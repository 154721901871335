import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../../common/translate';
import Message from '../../../common/message';
import {connect} from 'react-redux';
import {onAccountFetchData, onFieldSave} from "../../../../actions/actions";
import { EditOutlined, PlusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

class InlineDropdownEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            isEdit: false,
            isChanged: false
        };

        this.openEditField = this.openEditField.bind(this);
        this.closeEditField = this.closeEditField.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.saveField = this.saveField.bind(this);
        this.getLabel = this.getLabel.bind(this);
        this.closeUnchangedField = this.closeUnchangedField.bind(this);
    }

    componentDidMount() {
        let label = this.getLabel(this.props.value);        
        this.setState({
            value: this.props.value,
            label: label
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value || JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
            let label = this.getLabel(this.props.value);
            this.setState({
                value: this.props.value,
                label: label
            });
        }
    }

    openEditField() {
        this.setState({
            isEdit: !this.state.isEdit
        });
    }

    closeEditField() {
        let label = this.getLabel(this.props.value);
        this.setState({
            value: this.props.value,
            label: label
        });

        this.openEditField();
    }
    
    closeUnchangedField() {
        if (!this.state.isChanged) {
            this.openEditField();
            this.setState({isChanged: false});
        }
    }

    saveField() {
        this.props.onFieldSave(this.props.updateRoute, this.props.id, this.state.value, this.props.additionalData, this.props.additionalId);
        this.openEditField();
    }

    changeValue(e) {
        this.setState({
            value: e.target.options[e.target.selectedIndex].value,
            label: this.getLabel(e.target.options[e.target.selectedIndex].value),
            isChanged: true,
        });
    }

    getLabel(value) {
       if (!this.props.options) return '';
        let label = '';
        this.props.options.forEach(option => {
            if (option.value == value)
                label = option.label;
        })

        return label;
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.isEdit &&  
                        <div className={'inlineHover'}>
                            <select style={{width: this.props.width}}
                                onChange={this.changeValue} 
                                onClick={()=>this.setState({isChanged:true})}
                                onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            this.saveField()
                                        }
                                    }}  
                                value={this.state.value}>
                                {this.props.emptyOption &&
                                    <option value="">{plainTranslate(this.props.settings.locale, this.props.emptyOption)}</option>
                                }
                                {
                                    this.props.options.map((option, key) => {
                                        return <option key={key}
                                                       value={option.value}>{plainTranslate(this.props.settings.locale, option.label)}</option>
                                    })
                                }
                            </select>
                            <i className="icon ion-checkmark-round" onClick={this.saveField}></i>
                            <CloseCircleOutlined onClick={this.closeEditField}/>
                        </div>
                }
                {!this.state.isEdit && this.state.value &&
                        <div className={'inlineHover'}>
                            {plainTranslate(this.props.settings.locale, this.state.label)}
                            <EditOutlined onClick={this.openEditField} style={{marginLeft: 10}}/>
                        </div>
                }
                {!this.state.isEdit && !this.state.value &&
                        <span className='text-italic' style={{color: 'rgb(160, 160, 160)'}} onClick={this.openEditField}>
                            <p>Add field content here</p>
                        </span>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onFieldSave: (route, id, value, additionalData, additionalId) => dispatch(onFieldSave(route, id, value, additionalData, additionalId))
});

export default connect(mapStateToProps, mapDispatchToProps)(InlineDropdownEdit);
