import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const TemplateRoute = ({ component: Component, auth, settings,  ...rest }) => {
  
 return <Route
    {...rest}
    render={(props) => {
      if (auth.isAuthenticated === true || props.match.url.includes('/public/')) {
                return <Component  {...rest} {...props} />
            } else {
              if(this,props.match.params.db){
                return <Redirect to={'/'+props.match.params.db + "/user/signin"} />
              }else{
                return <Redirect to={"/workspace/signin"} />
                }
              }                
            
        }
    }
  />
  };

const mapStateToProps = state => ({
  auth: state.auth,
  settings: state.settings
});

export default connect(mapStateToProps)(TemplateRoute);
