import React, {Component} from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from '../common/translate';
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

class InventoryInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    render() {
        return (
            <div>
                <div className="account-profile-other-information-more">
                    <h4>{plainTranslate(this.props.settings.locale, 'Inventory Receipts')}</h4>
                    {this.props.inventories && this.props.inventories.length > 0 &&
                        <a className="account-profile-show" id="inventories" href="javascript:void(0)"
                           onClick={() => this.setState({show: !this.state.show})}>
                            {this.state.show ? <i className="glyphicon glyphicon-chevron-up"></i> :
                                <i className="glyphicon glyphicon-chevron-down"></i>}
                        </a>  
                    }
                    {
                        this.state.show &&
                        <div className="line">
                            <table  className="table">
                                <thead>
                                    <tr>
                                        <th>{plainTranslate(this.props.settings.locale, '#')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Date')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.inventories.map(inventory=>
                                        <tr id={ inventory.secureId }>
                                            <td>
                                                { inventory.hasView 
                                                    ? <Link to={'/' + this.props.match.params.db +'/receipt/'+inventory.secureId+'/profile'}>{inventory.no}</Link>
                                                    : inventory.no
                                                }
                                            </td>
                                            <td>{inventory.date ? moment((inventory.date).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</td>      
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <div className="separate"></div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventoryInfo));
