import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import InfoSection from './infoSection.jsx';
import {Link} from 'react-router-dom';
import SimpleForm from "../../forms/form";
import SlideModal from '../../common/SlideModal';
import {EMPLOYEE_PROFILE_UPDATE_FIELD} from "../../../constants/actionTypes";
import InlineRteEdit from '../../crm/accounts/profile/inline-rte-edit';
import { withRouter } from 'react-router-dom'
        
class GeneralInformation extends Component {

constructor(props) {
    super(props);
    this.updateRoute = '/' + this.props.match.params.db + '/api/employee/update-field';
    
    this.state = {
            isEditUser: false,
        };
        
    this.changeValue = this.changeValue.bind(this);
    this.saveField = this.saveField.bind(this);
}
    saveField() {
        this.setState({
            isEditUser: false,
        });
        this.props.onFieldSave('imageUrl' ,this.state.value.userImage ? this.state.value.userImage.full : null);
    }

    changeValue(data) {
        this.setState({
            value: data
        }, () => this.saveField());
    }

    render() {
            return (
                <div>
                    <div className="line general col-md-12 col-xs-12 no-gutter">
                        <div className="col-lg-10 col-md-10 col-xs-10 no-gutter">       
                        </div> 
                    </div>
                    <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                        <h2>
                            <div className="line">
                                <div> 
                                    <img className="employee-picture" src={this.props.employeeProfile.employee.imageUrl ? this.props.employeeProfile.employee.imageUrl : '/images/user.jpg'}/>
                                    <p className="employee-title">{plainTranslate(this.props.settings.locale, this.props.employeeProfile.employee.firstName)} {plainTranslate(this.props.settings.locale, this.props.employeeProfile.employee.middleName)} {plainTranslate(this.props.settings.locale, this.props.employeeProfile.employee.lastName)} </p>
                                </div>
                            </div>
                            <div className="line">
                                <h3>{plainTranslate(this.props.settings.locale, this.props.employeeProfile.employee.position)}</h3>
                            </div>
                        </h2>
                    </div>
                    <div className="line general col-md-12 col-xs-12 no-gutter">
                        {!this.props.frontend &&
                        <div className="inlineHover">
                            <Link className = "button green" to={"/" + this.props.match.params.db + "/hr/employees/" + this.props.employeeProfile.employee.secureId + "/edit"}><Translate locale={this.props.settings.locale} value="Edit"/></Link>
                            {this.props.employeeProfile.employee.currentUser &&
                            <a className = "button green" href='javascript:void(0)' onClick={() => this.setState({isEditUser: true})}>
                                {plainTranslate(this.props.settings.locale, 'Edit user profile')}
                            </a>
                            }
                        </div>
                        }
                        {this.props.frontend && this.props.employeeProfile.employee.currentUser &&
                        <a className = "button green" href='javascript:void(0)' onClick={() => this.setState({isEditUser: true})}>
                            {plainTranslate(this.props.settings.locale, 'Edit user profile')}
                        </a>
                        }   
                    </div> 
                    <div className="line col-lg-12 col-xs-12 col-md-12 no-gutter">
                        <div className ='col-lg-9 col-xs-9 col-md-9 no-gutter'>
                            <h4><span><i className="fa fa-envelope-o" aria-hidden="true"></i></span><a className='link' href={"mailto:"+this.props.employeeProfile.employee.email+""}>{this.props.employeeProfile.employee.email}</a></h4>
                        </div>
                    </div>
                    <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                        <div className ='col-lg-9 col-xs-9 col-md-9 no-gutter'>
                            <h4><span><i className="fa fa-phone" aria-hidden="true"></i></span><a className="link" href={"tel:"+this.props.employeeProfile.employee.phone+""}>{this.props.employeeProfile.employee.phone}</a></h4>
                        </div>
                    </div>
                    {this.props.employeeProfile.employee && this.props.employeeProfile.employee.contacts.length > 0 && this.props.employeeProfile.employee.contacts.map((contact, key) =>
                    <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter" key={key}>
                        <div className ='col-lg-9 col-xs-9 col-md-9 no-gutter'>
                            <h4>
                                <div className="col-lg-3 col-md-3 col-xs-3 title no-gutter-r">
                                    <div>{contact.type}</div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9  content">
                                    <div>{contact.value}</div>
                                </div>
                            </h4>
                        </div>
                    </div>
                    )}
                    <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                        <div className="line">
                                <h3><Translate locale={this.props.settings.locale} value="Work"/></h3>
                        </div>
                        {this.props.employeeProfile.employee.department && this.props.employeeProfile.employee.department.length > 0 && this.props.employeeProfile.employee.department.map((item, key) =>
                        <div key={key}>
                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span><Translate locale={this.props.settings.locale} value={item.depName}/></span></div>
                            <div className="col-lg-8 col-md-8 col-xs-8 content"><div className="notes">
                                    {plainTranslate(this.props.settings.locale, item.dep)}
                                </div>
                            </div>
                        </div>
                        )}
                        {this.props.employeeProfile.employee.manager && 
                        <div className="line">
                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span><Translate locale={this.props.settings.locale} value="Manager:"/></span></div>
                        <div className="col-lg-8 col-md-8 col-xs-8 content"><div className="notes">
                                {this.props.employeeProfile.employee.manager}
                            </div>
                        </div>
                        </div>
                        }
                        {this.props.employeeProfile.employee.dateStart && 
                        <div className="line">
                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span><Translate locale={this.props.settings.locale} value="Hire date:"/></span></div>
                        <div className="col-lg-8 col-md-8 col-xs-8 content"><div className="notes">
                                {this.props.employeeProfile.employee.dateStart}
                            </div>
                        </div>
                        </div>
                        }
                        {this.props.employeeProfile.employee.position && 
                        <div className="line">
                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span><Translate locale={this.props.settings.locale} value="Job title:"/></span></div>
                        <div className="col-lg-8 col-md-8 col-xs-8 content"><div className="notes">
                                {plainTranslate(this.props.settings.locale, this.props.employeeProfile.employee.position)}
                            </div>
                        </div>
                        </div>
                        }
                        {this.props.settings.user && this.props.settings.user.employee ==  this.props.employeeProfile.employee.secureId &&
                        <div className="account-profile-other-information">              
                            <InlineRteEdit id={'notes'}
                                                cols={'div-inline'}
                                                emptyText={"You haven't made a note."}
                                                title={plainTranslate(this.props.settings.locale, 'Note')}
                                                value={this.props.employeeProfile.employee.notes} updateRoute={this.updateRoute}
                                                additionalData={{employeeId: this.props.employeeProfile.employee.secureId}}/>
                        </div>
                        }
                    </div>
            {this.props.frontend ? ('') :
              (
              <div>
                    
                </div>
                )}
                {
                     this.state.isEditUser &&     
                    <SlideModal onClose={() => this.setState({isEditUser: false})} title={plainTranslate(this.props.settings.locale, 'Edit user profile')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                         <SimpleForm
                                            formRoute={this.props.match.params.db + '/api/user/profiles/'}
                                            saveRoute={this.props.match.params.db + '/api/user/profiles/' + this.props.employeeProfile.employee.currentUser}
                                            isNew={true}
                                            popup={true}
                                            updateParent={(data) => this.changeValue(data)}
                                            onCancel={() => this.setState({isEditUser: false})}
                                            match={{params: {id: this.props.employeeProfile.employee.currentUser}}}
                                        />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                    }
                </div>
            );
          }
        }

const mapStateToProps = state => ({
    settings: state.settings,
    employeeProfile: state.employeeProfile
});
const mapDispatchToProps = dispatch => ({
    onFieldSave: (id, value) => dispatch({type: EMPLOYEE_PROFILE_UPDATE_FIELD, payload: {id: id, value: value}}),
    });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GeneralInformation));


