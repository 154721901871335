import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PosLayout from '../components/layout/PosLayout'

const PosRoute = ({ component: Component, auth, settings,  ...rest }) => {
  
 return <Route
    {...rest}
    render={(props) => {
      if (auth.isAuthenticated === true) {
                return <PosLayout><Component  {...rest} {...props} /></PosLayout>
            } else {
              if(settings.db){
                return <Redirect to={'/'+settings.db + "/user/signin"} />
              }else{
                return <Redirect to={"/workspace/signin"} />
                }
              }                
            
        }
    }
  />
  };

PosRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  settings: state.settings
});

export default connect(mapStateToProps)(PosRoute);
