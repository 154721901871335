import React, {Component} from 'react';
import Collapsible from './collapsible';

class Textarea extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    render() {
        const element = <span className="form-input-field">
            <textarea className="form-control" disabled={this.props.field.disabled} rows={this.props.field.rows}
                      {...this.props.input}
                      >
            </textarea>
        </span>
        return (
                <React.Fragment>
                    {this.props.field.collapsible ?
                                    <Collapsible
                                        locale={this.props.locale}
                                        title={false}
                                        subtitle={this.props.field.description}> 
                                        {element}
                                    </Collapsible>
                                : <React.Fragment>{element}</React.Fragment>
                    }
                </React.Fragment>
                );
    }
}
export default Textarea;
    