import React, {Component} from 'react';
import Translate, {plainTranslate} from '../common/translate';
import Message from '../common/message';
import { connect } from 'react-redux';
import {Field as FormField, reduxForm} from 'redux-form';
import SaveCancel from '../common/saveCancelButtons';
import Field from "../redux-forms/custom-field";
import FormLoader from "../forms/form-loader";

class MakePaymentPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: true
        };
    }

    render() {
        const fields = this.props && this.props.fields ? this.props.fields : [];
        const formData = this.props.formData  ? this.props.formData : [];
        let showPrintSave = false;
        let showPrintStorno = false;

        if(localStorage.workplace !== undefined && this.props.formData && this.props.formData.isFiscalDevice !== undefined && this.props.formData.isFiscalDevice === true){
          showPrintSave = true;

          if(this.props.formData.reversalId !== null && this.props.formData.strornoReceiptData !== null){
            showPrintStorno = true;
          }
        }

        return (<div>
          {!this.props.formProperties.showLoader &&
              <div id="makePayment" className="col-md-12 col-xs-12 no-gutter">
                <div className="col-md-5 col-xs-12 no-gutter ">
                  <div className="panel padding10">
                    <h3 style={{marginBottom:10, paddingTop: 10}}>{plainTranslate(this.props.settings.locale, 'Sale summary')}</h3>
                     <div>
                      <div className="col-md-12 no-gutter" style={{marginBottom:15}}>
                        <span>{plainTranslate(this.props.settings.locale, 'Document')} # {formData.documentNo}</span>
                        <span className="pull-right">{formData.documentDate}</span>
                        {/*<p>formData.documentExtNo</p>*/}
                      </div>
                      <div className="col-md-12 col-xs-12 no-gutter padding10">
                        <div className={formData.isPos ? "col-md-4 col-xs-5 no-gutter" : "col-md-7 col-xs-6 no-gutter"}><strong>{plainTranslate(this.props.settings.locale, 'Item')}</strong></div>
                        <div className="col-md-2 col-xs-3 no-gutter"><span className="pull-right"><strong>{plainTranslate(this.props.settings.locale, 'Qty')}</strong></span></div>
                        {formData.isPos &&
                          <div className="col-md-2 col-xs-3 no-gutter"><span className="pull-right"><strong>{plainTranslate(this.props.settings.locale, 'Unit Price')}</strong></span></div>
                        }
                        {formData.isPos &&
                          <div className="col-md-2 col-xs-3 no-gutter"><span className="pull-right"><strong>{plainTranslate(this.props.settings.locale, 'Discount')}</strong></span></div>
                        }
                        <div className={formData.isPos ? "col-md-2 col-xs-3 no-gutter" : "col-md-3 col-xs-3 no-gutter"}><span className="pull-right"><strong>{plainTranslate(this.props.settings.locale, 'Amount')}</strong></span></div>
                          <hr />
                      </div>
                        {
                            formData.lines && formData.lines.map((line, key) => {
                                return <span><div>
                                  <div className="col-md-12 col-xs-12 no-gutter padding10" style={{marginBottom:15}}>
                                    <div className={formData.isPos ? "col-md-4 col-xs-5 no-gutter" : "col-md-7 col-xs-6 no-gutter"}>{line.itemDescription} <br/> {line.itName}</div>
                                    <div className="col-md-2 col-xs-3 no-gutter"><span className="pull-right">{line.quantity}</span></div>
                                    {formData.isPos &&
                                      <div className="col-md-2 col-xs-3 no-gutter"><span className="pull-right">{line.unitPrice}</span></div>
                                    }
                                    {formData.isPos &&
                                      <div className="col-md-2 col-xs-3 no-gutter"><span className="pull-right">{line.discountRate}%</span></div>
                                    }
                                    <div className={formData.isPos ? "col-md-2 col-xs-3 no-gutter" : "col-md-3 col-xs-3 no-gutter"}><span className="pull-right">{formData.isPos ? line.amountTotal : line.amount}</span></div>
                                  </div>
                                </div>
                                  </span>
                            })
                        }
                        <div className="col-md-12" style={{marginBottom:10,fontSize:16}}>
                         <hr/>
                         <div className="col-md-9 col-xs-6 no-gutter"><span className="pull-right"><strong>{plainTranslate(this.props.settings.locale, 'Subtotal')}:</strong></span></div>
                         <div className="col-md-3 col-xs-6 no-gutter"><span className="pull-right">{formData.amountBase}</span></div>
                         <div className="clearfix"></div>
                         <div className="col-md-9 col-xs-6 no-gutter"><span className="pull-right"><strong>{plainTranslate(this.props.settings.locale, 'Tax')} {formData.taxRate}%:</strong></span></div>
                         <div className="col-md-3 col-xs-6 no-gutter"><span className="pull-right">{(formData.amountBase*(formData.taxRate/100)).toFixed(2)}</span></div>
                        {formData.isPos &&
                          <div>
                            <div className="col-md-9 col-xs-6 no-gutter"><span className="pull-right"><strong>{plainTranslate(this.props.settings.locale, 'Discount')} {formData.totalDiscountRate}%:</strong></span></div>
                            <div className="col-md-3 col-xs-6 no-gutter"><span className="pull-right">{formData.amountDiscount}</span></div>
                          </div>
                        }
                         <div className="col-md-12 col-xs-12 no-gutter">
                         <hr />
                          <div className="col-md-9 col-xs-6 no-gutter"><span className="pull-right"><strong>{plainTranslate(this.props.settings.locale, 'Total')}:</strong></span></div>
                          <div className="col-md-3 col-xs-6 no-gutter"><span className="pull-right">{formData.documentAmount}</span></div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                 <div className="col-md-7 no-gutter padding10">
                  <div className="panel">
                   <h2 style={{paddingLeft: 30, marginBottom: 20, paddingTop: 10}}>{plainTranslate(this.props.settings.locale, 'Pay')}</h2>
                    <form onSubmit={this.props.handleSubmit}>
                        {
                            fields.map((field, key) => {
                                return <div key={key}>
                                    <FormField
                                        locale={this.props.settings.locale}
                                        name={field.id}
                                        label={field.label}
                                        field={field}
                                        fields={this.props.fields}
                                        component={Field}
                                        change={this.props.change}
                                        type={field.type}
                                        hide={field.hide}
                                        formData={formData}
                                        placeholder={field.label}
                                        db={this.props.db}
                                    />
                                </div>
                            })
                        }
                    </form>
                  </div>
                    {!this.props.notSaveButton &&
                    <div className="col-md-12 col-xs-12">
                        <div className="pull-left">
                            <SaveCancel
                                enableSave={this.props.enableSave}
                                save={this.props.save}
                                showPrintSave={showPrintSave}
                                showPrintStorno={showPrintStorno}
                                printAndSave={this.props.printAndSave}
                                printStorno={this.props.printStorno}
                                apply={this.props.apply}
                                showCancel={this.props.showCancel}
                                applyButton={this.props.applyButton}
                                showSaveNew={this.props.showSaveNew}
                                issueButton = {this.props.issueButton}
                                cancel={this.props.onCancel}
                                submitting={this.props.submitting}
                            />
                        </div>
                    </div>
                    }
                    </div>
            </div>
          }
          {
            this.props.formProperties.showLoader &&
            <FormLoader/>
          }
            </div>
        );
    }
}

const mapStateToProps = state => ({
        settings: state.settings,
    });

const mapDispatchToProps = dispatch => ({

    });

export default connect(mapStateToProps, mapDispatchToProps)(MakePaymentPage);
