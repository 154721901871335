import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import client from "../../../actions/client";
import { plainTranslate } from "../../common/translate";
import {
  Radio,
  Form,
  Button,
  Input,
  message,
  Skeleton,
  Select,
  Checkbox,
} from "antd";
import { CloseSquareOutlined, GlobalOutlined } from "@ant-design/icons";
import "./survey.css";
import { div } from "react-dom-factories";

const { Option } = Select;

/**
 * @author Nevyana Mihaleva
 * @function Survey
 **/

const Survey = (props) => {
  const [fetching, setFetching] = useState(false);
  const [survey, setSurvey] = useState(null);
  const [form] = Form.useForm();
  const [answers, setAnswers] = useState({});
  const [isSubmitted, setIsSubmited] = useState(false);

  console.log(survey);

  useEffect(() => {
    setFetching(true);
    if (
      props.match.params &&
      props.match.params.surveyCc &&
      props.match.params.respondentCc
    ) {
      client
        .get(
          `/${props.match.params.db}/web/get/survey/${props.match.params.surveyCc}/respondent/${props.match.params.respondentCc}`
        )
        .then((response) => {
          setSurvey(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = () => {
    for (let i = 0; i < survey.questions.length; i++) {
      if (survey.questions[i]["isRequired"]) {
        if (
          !answers.hasOwnProperty(survey.questions[i]["id"]) ||
          answers[survey.questions[i]["id"]] == ""
        ) {
          message.error(
            "Question - " +
              survey.questions[i]["question"] +
              " is required your answer"
          );
          return;
        }
      }
    }
    let data = {
      respondent: props.match.params.respondentCc,
      responses: answers,
    };
    client
      .post(`/${props.match.params.db}/web/save/responses`, data)
      .then((response) => {
        message.success("Form is successfully submitted");
      })
      .catch((error) => {
        console.error(error);
      });

    setIsSubmited(true);
  };

  const onChange = (question, value) => {
    answers[question] = value;
    setAnswers(answers);
  };

  const ClearIcon = () => {
    return (
      <div className="survey-list-select-clear-icon">
        <CloseSquareOutlined />
      </div>
    );
  };

  return (
    <div>
      {fetching && (
        <div>
          {!survey ? (
            <Skeleton />
          ) : (
            <div className="survey-container">
              <div className="bg-icon-wrapper-right">
                <img src="/images/rectangles-bg.svg" alt="bg-element" />
              </div>
              <div className="bg-icon-wrapper-left">
                <img src="/images/2-rectangles-bg.svg" alt="bg-element" />
              </div>

              <div className="survey-wrapper">
                {survey && survey.respondent && survey.respondent.isFulfilled && (
                  <div className="survey-list-wrapper">
                    <div className="survey-list-header">
                      <img
                        alt="Logo"
                        className="survey-logo"
                        src={
                          survey.companyLogo
                            ? survey.companyLogo.url
                            : "/images/composity-logo-white-200.png?v1.0"
                        }
                      ></img>
                      <div className="survey-list-lang">
                        <GlobalOutlined /> {props.settings.locale.toUpperCase()}
                      </div>
                    </div>
                    <h4>
                      {plainTranslate(
                        props.settings.locale,
                        "You have already completed this survey!"
                      )}
                    </h4>
                  </div>
                )}
                {isSubmitted && (
                  <div className="survey-list-wrapper">
                    <div className="survey-list-header">
                      <img
                        alt="Logo"
                        className="survey-logo"
                        src={
                          survey.companyLogo
                            ? survey.companyLogo.url
                            : "/images/composity-logo-white-200.png?v1.0"
                        }
                      ></img>
                      <div className="survey-list-lang">
                        <GlobalOutlined /> {props.settings.locale.toUpperCase()}
                      </div>
                    </div>
                    <h4 className="success-text">
                      {plainTranslate(
                        props.settings.locale,
                        "Successfully saved"
                      )}
                    </h4>
                  </div>
                )}
                {survey &&
                  survey.respondent &&
                  !survey.respondent.isFulfilled &&
                  !isSubmitted && (
                    <div>
                      <div className="survey-list-wrapper">
                        <div className="survey-list-header">
                          <img
                            alt="Logo"
                            className="survey-logo"
                            src={
                              survey.companyLogo
                                ? survey.companyLogo.url
                                : "/images/composity-logo-white-200.png?v1.0"
                            }
                          ></img>
                          <div className="survey-list-lang">
                            <GlobalOutlined />{" "}
                            {props.settings.locale.toUpperCase()}
                          </div>
                        </div>

                        <Form
                          form={form}
                          onFinish={onFinish}
                          layout="vertical"
                          className="form mtb-30"
                        >
                          <div className="survey-list-text-content">
                            <h1>
                              {plainTranslate(
                                props.settings.locale,
                                survey.name
                              )}{" "}
                            </h1>
                            <p>
                              {plainTranslate(
                                props.settings.locale,
                                survey.description
                              )}
                            </p>
                          </div>
                          {survey.questions &&
                            survey.questions.length &&
                            survey.questions
                              .sort((a, b) => a.position - b.position)
                              .map((question, key) => {
                                return (
                                  <>
                                    <Form.Item
                                      name={question.id}
                                      label={
                                        <span className="form-label">
                                          {question.position}.{" "}
                                          {plainTranslate(
                                            props.settings.locale,
                                            question.question
                                          )}{" "}
                                          {question.isRequired ? (
                                            <span>*</span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      }
                                      key={question.id}
                                    >
                                      {question.type === "scale" &&
                                        !isNaN(parseInt(question.options)) && (
                                          <Radio.Group
                                            name={question.id}
                                            onChange={(e) =>
                                              onChange(
                                                question.id,
                                                e.target.value
                                              )
                                            }
                                          >
                                            {question.options &&
                                              [
                                                ...Array(
                                                  parseInt(question.options)
                                                ),
                                              ].map((value, index) => (
                                                <>
                                                  <Radio
                                                    id={index + 1}
                                                    key={index}
                                                    value={index + 1}
                                                  >
                                                    <label
                                                      htmlFor={index + 1}
                                                      className="scale-option"
                                                    >
                                                      {index + 1}
                                                    </label>
                                                  </Radio>
                                                </>
                                              ))}
                                            {question.isEmptyOption && (
                                              <Radio value={null}>
                                                {plainTranslate(
                                                  props.settings.locale,
                                                  "I don’t know/ not applicable"
                                                )}
                                              </Radio>
                                            )}
                                          </Radio.Group>
                                        )}
                                      {question.type === "text" && (
                                        <div className="survey-list-textarea">
                                          <Input.TextArea
                                            type="text"
                                            onChange={(e) =>
                                              onChange(
                                                question.id,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      )}
                                      {question.type === "dropdown" && (
                                        <div className="survey-list-select">
                                          <Select
                                            style={{ width: 120 }}
                                            onChange={(value) =>
                                              onChange(question.id, value)
                                            }
                                          >
                                            {question.options &&
                                              question.options
                                                .split(",")
                                                .map((value, index) => (
                                                  <Option
                                                    key={index}
                                                    value={value}
                                                  >
                                                    {plainTranslate(
                                                      props.settings.locale,
                                                      value
                                                    )}
                                                  </Option>
                                                ))}
                                          </Select>
                                        </div>
                                      )}
                                      {question.type === "checkbox" && (
                                        <div className="survey-list-checkbox">
                                          <Checkbox.Group
                                            onChange={(value) =>
                                              onChange(question.id, value)
                                            }
                                          >
                                            {question.options &&
                                              question.options
                                                .split(",")
                                                .map((value) => {
                                                  return (
                                                    <Checkbox value={value}>
                                                      <p className="checkbox-text">
                                                        {plainTranslate(
                                                          props.settings.locale,
                                                          value
                                                        )}
                                                      </p>
                                                    </Checkbox>
                                                  );
                                                })}
                                          </Checkbox.Group>
                                        </div>
                                      )}
                                      {question.type ===
                                        "multiply-dropdown" && (
                                        <div className="survey-list-select survey-list-multiply-select">
                                          <Select
                                            clearIcon={<ClearIcon />}
                                            mode="multiple"
                                            allowClear
                                            showArrow
                                            placeholder="Please select"
                                            style={{ width: "100%" }}
                                            onChange={(value) =>
                                              onChange(question.id, value)
                                            }
                                          >
                                            {question.options &&
                                              question.options
                                                .split(",")
                                                .map((value, index) => (
                                                  <Option
                                                    key={index}
                                                    value={value}
                                                  >
                                                    {plainTranslate(
                                                      props.settings.locale,
                                                      value
                                                    )}
                                                  </Option>
                                                ))}
                                          </Select>
                                        </div>
                                      )}
                                    </Form.Item>
                                  </>
                                );
                              })}

                          <div className="survey-list-buttons">
                            <Button
                              className="survey-btn-clear"
                              htmlType="button"
                              onClick={onReset}
                            >
                              {plainTranslate(
                                props.settings.locale,
                                "Clear form"
                              )}
                            </Button>
                            <Button
                              className="survey-btn-submit"
                              htmlType="submit"
                            >
                              {plainTranslate(
                                props.settings.locale,
                                "Submit Answers"
                              )}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  )}
                <div class="survey-footer">
                  <a
                    href="https://composity.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p class="software">
                      Business Software by{" "}
                      <img src="/images/composity-logo.svg" alt="logo" />
                    </p>
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
