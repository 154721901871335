import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../../common/translate';
import Checkbox from './switch';
import UserRoleLine from './userRoleLine';

class UserRoles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: []
        };

        this.addNewLine = this.addNewLine.bind(this);
        this.onLineChange = this.onLineChange.bind(this);
        this.removeLine = this.removeLine.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.input.value ? this.props.input.value : []
        });
    }

    addNewLine(type) {
        let value = this.state.value;
        if (type == 'location') {
            value.push({'location' : 'test', 'roles': []});
        } else {
            value.push({'paymentMethod' : 'test', 'roles': []});
        }
        this.setState({value});
        //this.props.onChange(this.props.id, value);
    }

    onLineChange(id, val) {
        let value = this.state.value;
        value[id] = val;
        this.setState({value}, function() {
            console.log(value);
        });
        
    }

    removeLine(id, type) {
        let value = this.state.value;
        value[id][type] = null;
        this.setState({value});
    }

    render() {
        return (
            <div className="">
                {
                    this.state.value.map((line, key) => {
                        return <UserRoleLine key={key}
                                             locations={this.props.locations ? this.props.locations : []}
                                             paymentMethods = {this.props.paymentMethods ? this.props.paymentMethods : []}
                                             roles={this.props.roles ? this.props.roles : []}
                                             selectedLocation={line.location ? line.location : null}
                                             selectedPaymentMethod={line.paymentMethod ? line.paymentMethod : null}
                                             selectedRoles={line.roles ? line.roles : []}
                                             onChange={(value) => this.onLineChange(key, value)}
                                             removeLine={(type) => this.removeLine(key, type)}
                        />
                    })
                }
                <button type="button" className="button-outline grey" style={{margin: '0'}} onClick={() => this.addNewLine('location')}>
                    {plainTranslate(this.props.settings.locale, 'Add new location & roles')}
                </button>
                <button type="button" className="button-outline grey" style={{marginTop: '0', marginLeft: '30'}} onClick={() => this.addNewLine('payment')}>
                    {plainTranslate(this.props.settings.locale, 'Add new payment method & roles')}
                </button>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserRoles);
