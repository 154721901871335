import React from "react";
import ReactDOM from "react-dom";
import { VariableSizeGrid as Grid } from "react-window";
import LineNumber from './lineEditNew/LineNumber';
import TextLine from './lineEditNew/LineText';
import SelectLine from './lineEditNew/LineSelect';
import LineUpdatedSelect from './lineEditNew/LineUpdatedSelect'
import { plainTranslate } from '../../common/translate';

import "./style.css";

var focusField = 1;
var columnsMain;
var linesMain;
var columnsStyle;
var formData;

class CellInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  handleChange(id, value) {
    this.props.change(id, value)
  }

  handleChangeLine(id, value) {
    var data = this.state.data;
    var line = linesMain[this.props.index];

    this.props.changeFieldValueLine(id, value, this.props.index)
    columnsMain.map((column, key) => {
      if ('operation' in column) {
        var fieldValue = this.props.calculateOperation(column.operation, line);
        this.props.changeFieldValueLine(column.id, fieldValue, this.props.index)
        this.props.change('lines[' + this.props.index + '].' + column.id, fieldValue)
      }
    })
  }

  // The cell value has changed
  onChange(event) {
    this.setState({
      changed: true
    });
  }

  // Blur the input field if the 'Enter' key is pressed
  onKeyPress(event) {
    if (event.which === 13) {
      event.target.blur();
    }
    return false;
  }

  // The cell has gained focus
  onFocus(event) {
    focusField = event.target.id;
  }

  // The cell lost focus; i.e. was blurred
  onBlur(event) {
    if (this.state.changed) {
      if (this.props.cellChange === undefined)
        event.target.value = this.props.defaultValue;
      else this.props.cellChange(this.props, event.target.value);
      this.setState({
        changed: false
      });
    }
  }
  /*
    React.createElement("input", {
        id: this.props.id,
        className: this.props.className,
        style: this.props.style,
        type: this.props.type,
        value: this.props.value,
        onChange: this.onChange.bind(this),
        onKeyPress: this.onKeyPress.bind(this),
        onFocus: this.onFocus.bind(this),
        onBlur: this.onBlur.bind(this)
      });   
   
  */

  render() {
    let itName = columnsMain.find(o => o.id === 'itName');
    var batchDisabled = null;
    if (itName && itName.hide === false) {
      batchDisabled = true;
    }
    if (this.props.field.id === 'batch' && batchDisabled !== null) {
      this.props.field.isDisabled = batchDisabled;
    }

    var indexes = null;
    var line = linesMain[this.props.index]
    indexes = linesMain.map((o, i) => o.itId === line['itId'] && typeof line['itId'] !== 'undefined' ? line['itId'] : '').filter(String);

    let objStyle = columnsStyle.find(o => o.label === plainTranslate(this.props.settings.locale, this.props.field.label));

    var style = {}
    style.position = this.props.style.position
    style.left = objStyle ? objStyle.left : this.props.style.left;
    style.top = this.props.style.top
    style.height = this.props.style.height
    style.width = objStyle ? objStyle.width : this.props.style.width;
    style.minWidth = objStyle ? objStyle.width : this.props.style.width;
    style.background = 'white';
    if (this.props.field.isDisabled || this.props.field.disabled) {
      style.cursor = 'not-allowed'
      style.backgroundColor = '#f2f2f2'
    }

    if (this.props.field.id === 'itName' && indexes.length > 1) {
      style.background = '#ff8d00'
    }

    let hasBatch = columnsMain.find(o => o.id === 'batch');
    if (!hasBatch) {
      hasBatch = columnsMain.find(o => o.id === 'batchName');
    }

    if (this.props.field.type === 'select2') {
      return React.createElement(SelectLine, {
        id: this.props.id,
        className: this.props.className,
        style: style,
        type: this.props.type,
        data: this.props.value,
        valueLabel: this.props.value,
        onChange: this.onChange.bind(this),
        onKeyPress: this.onKeyPress.bind(this),
        onFocus: this.onFocus,
        onBlur: this.onBlur,
        field: this.props.field,
        name: 'lines[' + this.props.index + '].' + this.props.field.id,
        handleChange: this.handleChange.bind(this),
        handleChangeLine: this.handleChangeLine.bind(this),
        formData: formData
      });
    } else if (this.props.field.type === 'number') {
      return React.createElement(LineNumber, {
        id: this.props.id,
        className: this.props.className,
        style: style,
        type: this.props.type,
        data: this.props.valueLabel,
        valueLabel: this.props.valueLabel,
        onChange: this.onChange.bind(this),
        onKeyPress: this.onKeyPress.bind(this),
        onFocus: this.onFocus,
        onBlur: this.onBlur,
        field: this.props.field,
        name: 'lines[' + this.props.index + '].' + this.props.field.id,
        handleChange: this.handleChange.bind(this),
        handleChangeLine: this.handleChangeLine.bind(this),
        formData: formData,
        line: this.props.line,
        highlightAvailability: this.props.highlightAvailability,
        hasBatch: hasBatch,
        index: this.props.index,
        fields: columnsMain,
        settings: this.props.settings
      });
    } else if (this.props.field.type === 'updatedSelect') {
      return React.createElement(LineUpdatedSelect, {
        id: this.props.id,
        className: this.props.className,
        style: style,
        type: this.props.type,
        data: this.props.value,
        valueLabel: this.props.value,
        onChange: this.onChange.bind(this),
        onKeyPress: this.onKeyPress.bind(this),
        onFocus: this.onFocus,
        onBlur: this.onBlur,
        field: this.props.field,
        name: 'lines[' + this.props.index + '].' + this.props.field.id,
        handleChange: this.handleChange.bind(this),
        handleChangeLine: this.handleChangeLine.bind(this),
        formData: formData
      });
    } else {
      return React.createElement(TextLine, {
        id: this.props.id,
        className: this.props.className,
        style: style,
        type: this.props.type,
        data: this.props.value,
        valueLabel: this.props.value,
        onChange: this.onChange.bind(this),
        onKeyPress: this.onKeyPress.bind(this),
        onFocus: this.onFocus,
        onBlur: this.onBlur,
        field: this.props.field,
        name: 'lines[' + this.props.index + '].' + this.props.field.id,
        handleChange: this.handleChange.bind(this),
        handleChangeLine: this.handleChangeLine.bind(this),
        search: this.props.search,
        formData: formData
      });
    }
  }
}

export const GridColumn = ({ rowIndex, columnIndex, style, data }) => {
  let line = linesMain[rowIndex]
  let col = columnsMain[columnIndex]
  let value = null;
  let valueLabel = ''

  if (line && line[col.id]) {
    value = line[col.id]
    valueLabel = line[col.id];
    if ((col.type == 'select2' || col.type == 'updatedSelect') && line[col.id]) {
      var val = col.options.find(o => o.value == line[col.id]);
      valueLabel = val ? val.label : '';
    }
    if (col.type === 'number' && valueLabel) {
      valueLabel = parseFloat(valueLabel);
    }
  }

  return React.createElement(CellInput, {
    id: rowIndex + 1 + "," + (columnIndex + 1),
    className: "sticky-grid__data__column",
    style: style,
    valueLabel: valueLabel,
    value: value,
    cellChange: handleCellChange,
    field: col,
    index: rowIndex,
    columnIndex: columnIndex,
    changeFieldValueLine: data.changeFieldValueLine,
    change: data.change,
    calculateOperation: data.calculateOperation,
    search: data.search,
    settings: data.settings,
    line: line,
    highlightAvailability: data.highlightAvailability
  });
};

const getRenderedCursor = (children) =>
  children.reduce(
    (
      [minRow, maxRow, minColumn, maxColumn],
      { props: { columnIndex, rowIndex } }
    ) => {
      if (rowIndex < minRow) {
        minRow = rowIndex;
      }

      if (rowIndex > maxRow) {
        maxRow = rowIndex;
      }

      if (columnIndex < minColumn) {
        minColumn = columnIndex;
      }

      if (columnIndex > maxColumn) {
        maxColumn = columnIndex;
      }

      return [minRow, maxRow, minColumn, maxColumn];
    },
    [
      Number.POSITIVE_INFINITY,
      Number.NEGATIVE_INFINITY,
      Number.POSITIVE_INFINITY,
      Number.NEGATIVE_INFINITY
    ]
  );

const headerBuilder = (minColumn, maxColumn, columnWidth, stickyHeight, settings) => {

  const columns = [];
  let left = [0],
    pos = 0;

  for (let c = 1; c <= maxColumn; c++) {
    pos += columnWidth(c - 1, columnsMain[c - 1]);
    left.push(pos);
  }

  for (let i = minColumn; i <= maxColumn; i++) {
    columns.push({
      height: stickyHeight,
      width: columnWidth(i, columnsMain[i]),
      left: left[i],
      label: plainTranslate(settings.locale, columnsMain[i].label)
    });
  }
  columnsStyle = columns;
  return columns;
};

const columnsBuilder = (minRow, maxRow, rowHeight, stickyWidth, remove) => {
  const rows = [];
  let top = [0],
    pos = 0;

  for (let c = 1; c <= maxRow; c++) {
    pos += rowHeight(c - 1);
    top.push(pos);
  }

  for (let i = minRow; i <= maxRow; i++) {
    var labelCol = <div><span style={{ marginLeft: 10, marginRight: 10 }}>{i + 1}</span><span onClick={() => remove(i)}><i className='fa fa-trash' title="Delete row"></i></span></div>;
    rows.push({
      height: rowHeight(i),
      width: stickyWidth,
      top: top[i],
      label: labelCol
    });
  }

  return rows;
};



const StickyHeader = ({ stickyHeight, stickyWidth, headerColumns }) => {
  const baseStyle = {
    height: stickyHeight,
    width: stickyWidth,
    position: "absolute",
    left: 0
  };
  const scrollableStyle = {
    position: "absolute",
    left: stickyWidth
  };
  return React.createElement(
    "div",
    {
      className: "sticky-grid__header"
    },
    React.createElement(
      "div",
      {
        className: "sticky-grid__header__base",
        style: baseStyle
      },
      "Actions"
    ),
    React.createElement(
      "div",
      {
        className: "sticky-grid__header__scrollable",
        style: scrollableStyle
      },
      headerColumns.map(({ label, ...style }, i) =>
        React.createElement(
          "div",
          {
            className: "sticky-grid__header__scrollable__column",
            style: style,
            key: i
          },
          label
        )
      )
    )
  );
};

const StickyColumns = ({ rows, stickyHeight, stickyWidth }) => {
  const leftSideStyle = {
    top: stickyHeight,
    width: stickyWidth,
    height: `calc(100% - ${stickyHeight}px)`
  };
  return React.createElement(
    "div",
    {
      className: "sticky-grid__sticky-columns__container",
      style: leftSideStyle
    },
    rows.map(({ label, ...style }, i) =>
      React.createElement(
        "div",
        {
          className: "sticky-grid__sticky-columns__row",
          style: style,
          key: i
        },
        label
      )
    )
  );
};

const StickyGridContext = React.createContext();
StickyGridContext.displayName = "StickyGridContext";
const innerGridElementType = React.forwardRef(({ children, ...rest }, ref) =>
  React.createElement(
    StickyGridContext.Consumer,
    null,
    ({
      stickyHeight,
      stickyWidth,
      headerBuilder,
      columnsBuilder,
      columnWidth,
      rowHeight,
      remove,
      settings
    }) => {
      const [minRow, maxRow, minColumn, maxColumn] = getRenderedCursor(
        children
      );

      const headerColumns = headerBuilder(
        minColumn,
        maxColumn,
        columnWidth,
        stickyHeight,
        settings
      );
      const leftSideRows = columnsBuilder(
        minRow,
        maxRow,
        rowHeight,
        stickyWidth,
        remove
      );
      const containerStyle = {
        ...rest.style,
        width: `${parseFloat(rest.style.width) + stickyWidth}px`,
        height: `${parseFloat(rest.style.height) + stickyHeight}px`
      };
      const containerProps = {
        ...rest,
        style: containerStyle
      };
      const gridDataContainerStyle = {
        top: stickyHeight,
        left: stickyWidth
      };

      return React.createElement(
        "div",
        {
          className: "sticky-grid__container",
          ref: ref,
          ...containerProps
        },
        React.createElement(StickyHeader, {
          headerColumns: headerColumns,
          stickyHeight: stickyHeight,
          stickyWidth: stickyWidth
        }),
        React.createElement(StickyColumns, {
          rows: leftSideRows,
          stickyHeight: stickyHeight,
          stickyWidth: stickyWidth
        }),
        React.createElement(
          "div",
          {
            className: "sticky-grid__data__container",
            style: gridDataContainerStyle
          },
          children
        )
      );
    }
  )
);

export const StickyGrid = ({
  stickyHeight,
  stickyWidth,
  columnWidth,
  rowHeight,
  children,
  columns,
  lines,
  remove,
  changeFieldValueLine,
  calculateOperation,
  change,
  search,
  listRef,
  settings,
  highlightAvailability,
  ...rest
}) =>
  React.createElement(
    StickyGridContext.Provider,
    {
      value: {
        stickyHeight,
        stickyWidth,
        columnWidth,
        rowHeight,
        headerBuilder,
        columnsBuilder,
        columns,
        lines,
        remove,
        changeFieldValueLine,
        calculateOperation,
        change,
        search,
        listRef,
        settings
      }
    },
    React.createElement(
      Grid,
      {
        ref: listRef,
        changeFieldValueLine: changeFieldValueLine,
        columns: columns,
        itemData: { changeFieldValueLine, change, calculateOperation, search, settings, highlightAvailability },
        columnWidth: columnWidth,
        rowHeight: rowHeight,
        innerElementType: innerGridElementType,
        ...rest
      },
      children
    )
  );

function getRowHeight(index) {
  return 40//index % 2 ? 60 : 30;
}

function getColumnWidth(index, column = null) {
  var width = 200;
  if (column && column.width && parseInt(column.width) === 400) {
    width = 400
  }

  return width;//index % 2 ? 240 : 120;
}

// Cause a grid cell to blur when scrolling
function handleScroll(event) {
  document.activeElement.blur();
}

// Record cell changes
function handleCellChange(props, value) {

}

export const StickyGridList = (props) => {
  let perct = 82;
  if (window.screen.width > 1890) {
    perct = 82;
  }
  let width = (window.screen.width / 100) * perct;

  columnsMain = props.columns
  linesMain = props.lines
  formData = props.formData
  return <StickyGrid
    columnCount={columnsMain.length}
    rowCount={props.lines.length}
    rowHeight={getRowHeight}
    columnWidth={getColumnWidth}
    stickyHeight={40}
    stickyWidth={120}
    onScroll={props.onScroll}
    width={parseInt(width)}
    height={650}
    columns={props.columns}
    lines={linesMain}
    remove={props.remove}
    changeFieldValueLine={props.changeFieldValueLine}
    calculateOperation={props.calculateOperation}
    change={props.change}
    search={props.search}
    listRef={props.listRef}
    settings={props.settings}
    highlightAvailability={props.highlightAvailability}>
    {GridColumn}
  </StickyGrid>
}

/*var gparms = {
  columnCount: 20,
  rowCount: 1000,
  rowHeight: getRowHeight,
  columnWidth: getColumnWidth,
  stickyHeight: 40,
  stickyWidth: 120,
  onScroll: handleScroll
};
ReactDOM.render(
  React.createElement(AutoSizer, null, function (params) {
    gparms.height = params.height;
    gparms.width = params.width;
    return React.createElement(StickyGrid, gparms, GridColumn);
  }),
  rootElement
);*/
