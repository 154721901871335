import React from 'react';

const Datalist = props => (
    <span className="form-input-field">
        <input type="text" list={props.field.id} className="form-control" {...props.input} disabled={props.field.disabled} onChange={(e) => props.onDatalistChange ? props.onDatalistChange(e, props.field) : props.change(props.field.id, e.target.value)}/>
        <datalist id={props.field.id}>  
            {
                props.field.options && props.field.options.map((option, key) => {
                    return <option value={option.text} key={key}>{option.text}</option>
                })
                        }
        </datalist>
    </span>
);

export default Datalist;
