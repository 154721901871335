import React, { Component } from 'react';
import { render } from "react-dom";
import Translate, { plainTranslate } from '../../../common/translate';
import { connect } from 'react-redux';
import { onDeleteBilling, onFieldSave, onAccountFetchData } from "../../../../actions/actions";
import SimpleForm from "../../../redux-forms/form";
import { Route } from "react-router-dom";
import SlideModal from '../../../common/SlideModal';
import Popup from '../../../common/popup';
import { withRouter } from 'react-router-dom';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Card, Table } from 'antd';

class BillingDataEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: [],
            show: false
        };

        this.changeValue = this.changeValue.bind(this);
        this.saveField = this.saveField.bind(this);
        this.getBillingDataValue = this.getBillingDataValue.bind(this);
        this.getNoBillingData = this.getNoBillingData.bind(this);
        this.handleDeleteBillingData = this.handleDeleteBillingData.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value ? this.props.value : [],
            popup: false,
            deletePopup: false
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value ? this.props.value : []
            });
        }
    }

    saveField() {
        this.setState({ popup: false, editId: null });
        this.props.onFieldSave(this.props.updateRoute, this.props.id, this.state.value, this.props.additionalData);
    }

    changeValue(data) {
        let indx = null;
        let arr = this.state.value;

        if (this.props.value) {
            indx = this.state.value.indexOf(this.props.value.find(o => o.id === data.id));
            if (indx > -1) {
                arr[indx] = data;
            } else {
                arr.push(data);
            }
        } else {
            arr.push(data);
        }

        this.setState({
            value: arr
        }, () => this.saveField());
    }

    handleDeleteBillingData(id) {
        client
            .post('/' + this.props.match.params.db + '/api/account/billing-data/delete/' + this.state.editId)
            .then(response => response.data)
            .then(response => {
                //this.props.onDeleteBilling();
                //this.saveField();
                let data = this.state.value;
                let indx = data.indexOf(data.find(o => o.id === this.state.editId));
                if (indx > -1) {
                    data.splice(indx, 1);
                }

                this.setState({
                    deletePopup: false, editId: null, value: data
                }, () => this.saveField());
                showAToast(response.success, 'baseSuccess', 'success');
            })
            .catch(err => {
                showAToast(err.response.data.errors, 'baseError', 'error');
            });
    }

    getBillingDataValue() {
        const columns = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    return (
                        <span>
                            <EditOutlined
                                style={{ margin: 5 }}
                                onClick={() => this.setState({ popup: true, editId: record.id })} />
                            <DeleteOutlined
                                style={{ fontSize: 14 }}
                                onClick={() => this.setState({ deletePopup: true, editId: record.id })} />
                        </span>
                    )
                },
                width: 30,
                align: 'center',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Name'),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'National Id'),
                dataIndex: 'identifierNationalId',
                key: 'identifierNationalId',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Tax Id'),
                dataIndex: 'identifierTaxId',
                key: 'identifierTaxId'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Country'),
                dataIndex: 'country',
                key: 'country'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'City'),
                dataIndex: 'city',
                key: 'city'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Address 1'),
                dataIndex: 'address1',
                key: 'address1'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Address 2'),
                dataIndex: 'address2',
                key: 'address2'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Responsible Person'),
                dataIndex: 'responsiblePerson',
                key: 'responsiblePerson'
            },
        ];
        return (
            // this.props.value.map((value, key) => {
            //     return <React.Fragment key={key}>
            //         <div className="line line-address" style={{display: "block", paddingLeft: 20}}>
            //             {value.name ? value.name + ',' : ''} {value.uic ? value.uic + ',' : ''} 
            //             {value.vat ? value.vat + ',' : ''} {value.country ? value.country + ',' : ''} 
            //             {value.city ? value.city + ',' : ''} {value.address1 ? value.address1 + ',' : ''} 
            //             {value.address2 ? value.address2: '' } 
            //             {value.responsiblePerson ? value.responsiblePerson + ',' : '' }
            //              <EditOutlined 
            //              style={{margin: 5}}
            //              onClick={() => this.setState({popup: true, editId: value.id})}/> 
            //             <DeleteOutlined 
            //             style={{fontSize: 14}}
            //             onClick={() => this.setState({deletePopup: true, editId: value.id})}/>
            //         </div> 
            //         {key < this.props.value.length-1 &&
            //             <hr />
            //         }
            //     </React.Fragment>
            // })
            <Table dataSource={this.props.value} columns={columns} pagination={false} rowKey={record => record.id} scroll={{ x: 'max-content' }}/>
        );
    }

    getNoBillingData() {
        return (
            <div className="div-inline m-left15"><span
                className="text-italic" style={{ color: '#a0a0a0' }}>{plainTranslate(this.props.settings.locale, 'No billing information added.')}</span></div>
        );
    }

    onSuccessfulSave = () => {
        this.props.onAccountFetchData(this.props.db, this.props.accountId, false)
        this.setState({ popup: false })
    }

    render() {
        return (
            <Card
                size="small"
                style={{ marginTop: 10 }}
                title={plainTranslate(this.props.settings.locale, 'Billing data')}
                extra={<PlusCircleOutlined onClick={() => this.setState({ popup: true })} />}>
                <div className="account-profile-other-information-more">
                    <React.Fragment>
                        {this.props.value ? this.getBillingDataValue() : this.getNoBillingData()}
                    </React.Fragment>
                </div>
                {this.state.popup &&
                    <SlideModal onClose={() => this.setState({ popup: false, editId: null })} title={plainTranslate(this.props.settings.locale, 'Billing data')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/billing-datas/'}
                                        saveRoute={this.state.editId ? this.props.match.params.db + '/api/billing-datas/' : this.props.match.params.db + '/api/billing-datas'}
                                        popup={true}
                                        isNew={true}
                                        onCancel={() => this.setState({ popup: false })}
                                        match={{ params: { id: this.state.editId ? this.state.editId : 0 } }}
                                        refs={this.props.accountId}
                                        form={'initializeFromState2'}
                                        onSuccessfulSave={this.onSuccessfulSave}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {this.state.deletePopup &&
                    <Popup locale={this.props.settings.locale}
                        title={plainTranslate(this.props.settings.locale, 'Delete Billing data')}
                        onClose={() => this.setState({ deletePopup: false })}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <div className="text-center">

                                        <h3>
                                            <Translate locale={this.props.settings.locale} value="Are you sure?" />
                                        </h3>
                                        <button className="button-outlines green text-center"
                                            onClick={() => this.setState({ deletePopup: false })}>
                                            <Translate locale={this.props.settings.locale} value="No" />
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                            onClick={() => this.handleDeleteBillingData(this.props.accountId)}>
                                            <Translate locale={this.props.settings.locale} value="Yes" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Popup>
                }
            </Card>

        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onAccountFetchData: (db, id, start) => dispatch(onAccountFetchData(db, id, start)),
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData)),
    onDeleteBilling: () =>
        dispatch(onDeleteBilling())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BillingDataEdit));