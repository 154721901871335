import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";
import ErrorMessage from '../errorMessage';
import TextLine from './LineText';
import Select2 from './select2';
import Number from './number';
import Dropdown from './dropdown';
import Textarea from './textarea';
import Percents from "./percents";
import Datalist from "./datalist";
import Switch from './switch';
import Checkmark from './checkmark';
import UpdatedSelect from './updatedSelect';
import LineButton from './lineButton';
import SingleCheckbox from './checkbox';

class LineField extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        
      let style = {
          backgroundColor: '#fff', 
          minWidth: this.props.field.type === 'select2' ? 190 : (this.props.field.width ? this.props.field.width : 150),
          maxWidth: this.props.field.width ? this.props.field.width : 190,  
          fontSize: 14
      };
        if(this.props.field.isDisabled || this.props.field.disabled){
            style = {
                cursor: 'not-allowed', 
                backgroundColor: '#f2f2f2', 
                fontSize: 14, 
                minWidth: this.props.field.type === 'select2' ? 190 : (this.props.field.width ? this.props.field.width : 150),
                maxWidth: this.props.field.width ? this.props.field.width : 190                
            };
        }
      
        return <td className={this.props.field.hide ? 'hidden' : 'tableCell'} style={style} >
          
            {
                        this.props.field.type === 'text' && 
                        <TextLine 
                data={this.props.data}
                change={this.props.change} 
                field={this.props.field}
                clicked={this.props.clicked}
                index={this.props.index}
                handleClickedRow={this.props.handleClickedRow}
                line={this.props.line}
                name={this.props.name}
                lines={this.props.formData['lines']}/>
                    }
                    
                    {
                        this.props.field.type === 'updatedSelect' &&
                        <UpdatedSelect {...this.props} />
                    }
                    
                    {
                        this.props.field.type === 'checkmark' &&
                        <Checkmark {...this.props} />
                    }

                    {
                        this.props.field.type === 'select2' &&
                        <Select2 {...this.props} />
                    }

                    {
                        this.props.field.type === 'number' &&
                        <Number {...this.props} />
                    }

                    {
                        this.props.field.type === 'dropdown' &&
                        <Dropdown {...this.props} />
                    }

                    {
                        this.props.field.type === 'textarea' &&
                        <Textarea {...this.props} />
                    }

                    {
                        this.props.field.type === 'percents' &&
                        <Percents
                            {...this.props}
                            rules={this.props.field.rules}   
                        />
                    }
                    
                    {
                        this.props.field.type === 'datalist' &&
                        <Datalist {...this.props} />
                    }
                    
                    {
                        this.props.field.type === 'switch' &&
                        <Switch {...this.props} />
                    }
                    
                    {
                        this.props.field.type === 'checkbox' &&
                        <SingleCheckbox {...this.props} />
                    }
                    
                    {
                        this.props.field.type === 'action' &&
                        <LineButton {...this.props} />
                    }

                    {
                        this.props.error &&
                        <div>
                            <div className="forms-error-msg">
                                <ErrorMessage
                                    locale={this.props.locale}
                                    error={this.props.error}
                                    message={'errorMessage' in this.props.field ? this.props.field.errorMessage : false}
                                />
                                <br/>
                            </div>
                        </div>
                    }

            </td>
    }
}

export default LineField;
