import React, {Component} from 'react';
import Translate, {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';
import {onFieldSave, onAccountFetchData} from "../../../../actions/actions";
import SimpleForm from "../../../redux-forms/form";
import Popup from '../../../common/popup';
import SlideModal from '../../../common/SlideModal';
import { withRouter } from 'react-router-dom'
import client from '../../../../actions/client'
import { Card, Table } from 'antd';
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

class Addresses extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addresses: [],
            show: false
        };
        this.changeValue = this.changeValue.bind(this);
        this.saveField = this.saveField.bind(this);
        this.getAddresses = this.getAddresses.bind(this);
        this.getNoAddresses = this.getNoAddresses.bind(this);
        this.showNewPopup = this.showNewPopup.bind(this);
        this.showEditPopup = this.showEditPopup.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.openForm = this.openForm.bind(this);
    }
    
    componentDidMount() {
        this.setState({
            addresses: this.props.value ? this.props.value : [],
            popup: false,
            newPopup: false
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                addresses: this.props.value ? this.props.value : []
            });
        }
    }

    saveField() {
        this.setState({popup: false});
        this.props.onFieldSave(this.props.updateRoute, this.props.id, this.state.addresses, this.props.additionalData);
        this.props.onAccountFetchData(this.props.db, this.props.accountId, false)
    }

    changeValue(data) {
        let addresses = this.state.addresses;
        let find = addresses.find((addr, key) => {
                if (addr.id === data.entity.id) {
                    addresses[key] = data.entity;
                    return true;
                } 
        });

        if (!find) {
            addresses.push(data.entity);
          }
        this.setState({
            addresses: addresses,
            newPopup: false,
            popup: false
            
        }, () => this.saveField());
    } 
    
    openForm(id) {
        this.setState({
            popup: id 
        });
    }
    
    handleDelete(id) {
        client
        .post('/' + this.props.match.params.db + '/api/address/delete/' + id)
        .then(response => response.data)
        .then(res => {
            return res;
        })

            
        let addresses = this.state.addresses;
        let index;
        addresses.find((addr, key) => {
                if (addr.id === id) {
                    index = key;
                    return true;
                } 
        });
        if (index > -1) {
            addresses.splice(index, 1);
         }
        this.setState({
            addresses: addresses,
            deletePopup: false,          
        }, () => this.saveField());

    }
    
    getAddresses() {
        const columns = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    return (
                        <span>
                            <EditOutlined
                                style={{ margin: 5 }}
                                onClick={() => this.openForm(record.id)} />
                            <DeleteOutlined
                                style={{ fontSize: 14 }}
                                onClick={() => this.setState({deletePopup: record.id})} />
                        </span>
                    )
                },
                width: 30,
                align: 'center',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Name'),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Country'),
                dataIndex: 'country',
                key: 'country'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'District'),
                dataIndex: 'district',
                key: 'district'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'City'),
                dataIndex: 'city',
                key: 'city'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Post Code'),
                dataIndex: 'postCode',
                key: 'postCode'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Address 1'),
                dataIndex: 'addressLine1',
                key: 'addressLine1'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Address 2'),
                dataIndex: 'addressLine2',
                key: 'addressLine2'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Phone'),
                dataIndex: 'phone',
                key: 'phone'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Email'),
                dataIndex: 'email',
                key: 'email'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Location'),
                dataIndex: 'location',
                key: 'location'
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Warehouse'),
                dataIndex: 'warehouse',
                key: 'warehouse'
            },
        ];
        return (
            <React.Fragment>
                {/* {
                    this.state.addresses.map((address, key) => (
                        <React.Fragment key={key}>
                                    <div className="line line-address" style={{paddingRight: "8px"}}>{address.name ? address.name + ',' : ''} {address.country ? address.country + ',' : ''} {address.district ? address.district + ',' : ''} {address.city ? address.city + ',' : ''} {address.postCode ? address.postCode + ',' : ''} {address.addressLine1 ? address.addressLine1 + ',' : ''} {address.addressLine2 ? address.addressLine2: '' } {address.phone ? address.phone + ',' : '' } {address.email ? address.email: '' }
                                    <EditOutlined 
                                    style={{margin: 5}}
                                    onClick={() => this.openForm(address.id)}/> 
                                    <DeleteOutlined 
                                    style={{fontSize: 14}}
                                    onClick={() => this.setState({deletePopup: address.id})}/>
                                    </div> 
                        </React.Fragment>
                        
                ))}                             */}
                <Table dataSource={this.state.addresses} columns={columns} pagination={false} rowKey={record => record.id} scroll={{ x: 'max-content' }}/>
            </React.Fragment>
        );
    }

    getNoAddresses() {
        return (
           <div className="div-inline m-left15"><span className="text-italic" style={{color: '#a0a0a0'}}>{plainTranslate(this.props.settings.locale, 'No addresses added.')}</span></div>
        );
    }
    
    showNewPopup() {
        return (
                <SlideModal onClose={() => this.setState({newPopup: false})} title={plainTranslate(this.props.settings.locale, 'Address')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <SimpleForm                                            
                                    formRoute={this.props.match.params.db + '/api/address/'}
                                    saveRoute={this.props.match.params.db + '/api/address?accountId=' + this.props.additionalData.accountId}
                                    // updateParent={(data) => this.changeValue(data)}
                                    // popup={true}
                                    // isNew={true}
                                    // onCancel={() => this.setState({newPopup: false})}
                                    // match={{params: {id: 0}}}
                                    isNew={true}
                                    popup={true}
                                    onSuccessfulSave={(data) => this.changeValue(data)}
                                    onCancel={() => this.setState({newPopup: false})}
                                    refs={this.props.additionalData.accountId}
                                    form={'initializeFromState'}
                                    match={{params: {id: 0}}}
                                />
                            </div>
                        </div>
                    </section>
                </SlideModal>
                );
    }

    showEditPopup(){
        return (
            <SlideModal onClose={() => this.setState({popup: false})} title={plainTranslate(this.props.settings.locale, 'Address')}>
                <section className="newPanel">
                    <div className="panel-body">
                        <div className="row m-bot15">
                            <SimpleForm
                                formRoute={this.props.match.params.db + '/api/address/'}
                                saveRoute={this.state.popup ? this.props.match.params.db + '/api/address/' + this.state.popup + '?accountId=' + this.props.additionalData.accountId: this.props.match.params.db + '/api/address/' + this.state.popup + '?accountId=' + this.props.additionalData.accountId}
                                // updateParent={(data) => this.changeValue(data)}
                                // popup={true}
                                // isNew={true}
                                // onCancel={() => this.setState({popup: false})}
                                // match={{params: {id: this.state.popup}}}
                                isNew={true}
                                popup={true}
                                onSuccessfulSave={(data) => this.changeValue(data)}
                                onCancel={() => this.setState({popup: false})}
                                form={'initializeFromState'}
                                match={{params: {id: this.state.popup}}}
                            />
                        </div>
                    </div>
                </section>
            </SlideModal>
        )
    }

    render() {
        return (
            <Card 
            size="small" 
            style={{marginTop: 10}}
            title={plainTranslate(this.props.settings.locale, 'Addresses')}
            extra={<PlusCircleOutlined onClick={() => this.setState({newPopup: true})}/>}>
                <div className="account-profile-other-information-more">                    
                        <React.Fragment>
                            {
                                this.props.value ? this.getAddresses() : this.getNoAddresses()
                            }
                        </React.Fragment>
                </div>
                {
                    this.state.newPopup && this.showNewPopup()
                    
                }
                {
                    this.state.popup && this.showEditPopup()
                    
                }
                {
                    this.state.deletePopup &&
                    <Popup locale={this.props.settings.locale}
                           title={plainTranslate(this.props.settings.locale, 'Delete address')}
                           onClose={() => this.setState({deletePopup: false})}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <div className="text-center">

                                        <h3>
                                            <Translate locale={this.props.settings.locale} value="Are you sure?"/>
                                        </h3>
                                        <button className="button-outlines green text-center"
                                                onClick={() => this.setState({deletePopup: false})}>
                                            <Translate locale={this.props.settings.locale} value="No"/>
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                                onClick={() => this.handleDelete(this.state.deletePopup)}>
                                            <Translate locale={this.props.settings.locale} value="Yes"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Popup>
                }
               </Card>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onAccountFetchData: (db, id, start) => dispatch(onAccountFetchData(db, id, start)),
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Addresses));
