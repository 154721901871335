import React, {Component} from 'react';
import {render} from "react-dom";
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import {Link} from 'react-router-dom';
import { Checkbox } from 'antd';
import 'antd/lib/checkbox/style/index.css';
import { Pagination } from 'antd';
import 'antd/lib/pagination/style/index.css';
import FormLoader from '../../forms/form-loader';
import moment from 'moment';

const tagGreen = {
  borderRadius: 9.5,
  backgroundColor: '#daf2eb',
  padding: '3px 7px',
  color: '#649f6f',
  fontWeight: 600,
};
const tagOrange ={
  borderRadius: 9.5,
  backgroundColor: '#fbfaff',
  padding: '3px 7px',
  color: '#e76a97',
  fontWeight: 600,
}


class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requests: []
        };
        this.changePage = this.changePage.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.checkboxSelect = this.checkboxSelect.bind(this);
    }

    componentDidMount() {
        this.setState({ requests: localStorage.getItem('requests') ? (localStorage.getItem('requests')).split(",").map(String) : [] });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data && nextProps.data.length > 0) {
            this.setState({active: nextProps.data[0].id});
            this.setState({ requests: localStorage.getItem('requests') ? (localStorage.getItem('requests')).split(",").map(String) : [] });
        }
    }

    changePage(page) {
        this.props.page(page);
    }

    handleClick(rowId) {
        this.props.clickRow(rowId);
        this.setState({active: rowId});
    }

    checkboxSelect(e, id = null) {
        if (id) {
            let requests = localStorage.getItem('requests') ? localStorage.getItem('requests').split(",").map(String) : [];
            if (e.target.checked) {
                requests.push(id);
                this.setState({requests: requests}, function() {
                    this.props.onChangeSelected(requests)
                });
            } else {
                let remove = requests.indexOf(id);
                    if (remove > -1) {
                        requests.splice(remove, 1);
                        this.setState({requests: requests}, function() {
                            this.props.onChangeSelected(requests)
                        });
                    }
            }
        } else {
            if (e.target.checked) {
                let list = this.props.data;
                let i;
                let requests = [];
                for (i = 0; i < list.length; i++) {
                      requests[i] = list[i].id;
                    }
                let prevPageRequests = localStorage.getItem('requests') ? localStorage.getItem('requests').split(",").map(String) : [];
                let mergeRequests = requests.concat(prevPageRequests);
                let uniq = [...new Set(mergeRequests)];
                this.setState({requests: uniq}, function() {
                    this.props.onChangeSelected(uniq)
                });
            } else {
                this.setState({requests: []}, function() {
                    this.props.onChangeSelected([])
                });
            }
        }
    }

    componentWillUnmount() {
        localStorage.removeItem('requests');
    }

    render() {
        return (
            <React.Fragment>
            {this.props.loading ? <FormLoader/> :
            <React.Fragment>
                {this.props.data && this.props.data.length > 0 &&
                    <Checkbox style={{marginLeft:16, marginBottom:5}} onChange={(e) => this.checkboxSelect(e)}>{plainTranslate(this.props.settings.locale, 'Check all')}</Checkbox>
                }
                <table width="100%" style={{marginBottom:20}}>
              <div style={{overflow: "scroll",  maxHeight: 670}}>
                <tbody className="ant-table-tbody">
                    {this.props.data && this.props.data.length > 0 && this.props.data.map(row => (
                    <tr className={this.state.active == row.id ? "list-tableRow ant-table-row focused" : "list-tableRow ant-table-row"} key={row.id}>
                        <td><Checkbox onChange={(e) => this.checkboxSelect(e, row.id)} checked={(this.state.requests && this.state.requests.find(item => item == row.id)) ? true:false}></Checkbox>
                        </td>
                        <td><a className="list-tableLink" onClick={() => this.handleClick(row.id)}>
                          <div className="top">
                            <span className="first">{row.itemNo}</span>
                            <span className="second">{row.itemName}</span>
                            <span className="list-quantity pull-right">{row.quantity}{row.measure}</span>
                          </div>
                          <div className="bottom">
                            <span className="first">{row.locationTo}</span>
                            <span className="pull-right">{row.locationFrom}</span>
                          </div>
                          <div className="bottom">
                            <span className="first list-docNo">{row.requestNo}</span>
                            <span className="second list-date">{row.requestDate ? moment((row.requestDate).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span> 
                            <span className="second pull-right">{row.status}</span>
                          </div>
                          <div className="bottom">
                            {row.isProcessed == true &&
                            <span style={tagGreen}>{plainTranslate(this.props.settings.locale, 'On stock')}</span>}
                            <span className="list-stage pull-right">{row.sale}</span>
                          </div>
                        </a></td>
                    </tr>
                    ))}
                </tbody>
              </div>
              </table>
          </React.Fragment>
          }
          {this.props.data && this.props.data.length > 0 &&
              <Pagination 
              defaultCurrent={1} 
              total={this.props.count} 
              defaultPageSize={30} 
              onChange={this.changePage}
              showSizeChanger={false}/>
           }
          </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(List);
