import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Menu, Row, Skeleton, Spin } from "antd";
import client from "../../actions/client";
import showAToast from "../common/showAToast";
import { plainTranslate } from "../common/translate";
import { RightOutlined } from "@ant-design/icons";
import {config} from '../../config';
import "./template.css";

const Print = (props) => {
  const [value, setValue] = useState(null);
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    let url = props.match.url;
    url = url.replace("/" + props.match.params.db, "");
    if (props.public) {
      url = url.replace("/public", "");
      if(props.type === 'invoice'){
        url = "/" + props.match.params.db + "/web/invoicing" + url + "/export.pdf" + "?view=true";
      }else if(props.type === 'deal'){
         url = "/" + props.match.params.db + "/web" + url + "/export.pdf" + "?view=true";
      }
    } else {
      url = "/" + props.match.params.db + "/api" + url;
    }
    client
      .get(url, { responseType: "blob" })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/pdf;  charset=UTF-8",
        });
        var downloadUrl = URL.createObjectURL(blob);
        setValue(downloadUrl);
        setReady(true);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
        }
        var error = "Error!";
        showAToast(
          plainTranslate(props.settings.locale, error),
          "errSettings",
          "error"
        );
      });
    if (props.additionalData) {
      let additionalUrl = props.additionalData;
      additionalUrl = additionalUrl.replace(":db", props.match.params.db);
      additionalUrl = additionalUrl.replace(":id", props.match.params.id);
      client.get(additionalUrl).then((response) => {
        setData(response.data);
      });
    }
  }, []);

  var downloadPath = props.match.url;
  if (props.public) {
    downloadPath = downloadPath.replace("/public", "");
    downloadPath = downloadPath.replace("/" + props.match.params.db, "");
    downloadPath = downloadPath + "/export.pdf";
    if(props.type === 'invoice'){
      downloadPath = "/" + props.match.params.db + "/web/invoicing" + downloadPath;
    }else if(props.type === 'deal'){
      downloadPath = "/" + props.match.params.db + "/web" + downloadPath;
    }
  } else {
    downloadPath = downloadPath.replace("/" + props.match.params.db, "");
    downloadPath = "/" + props.match.params.db + "/web" + downloadPath;
  }

  return (
    <>
      {!props.public ? (
        <Row>
          <Col span={1}>
            {ready && (
              <Menu
                mode="inline"
                theme="light"
                style={{ background: "#f0eae7", height: 52 }}
              >
                <Menu.Item
                  key="1"
                  id="printDoc"
                  style={{ paddingLeft: 15 }}
                  icon={
                    <a href={process.env[config] + downloadPath}>
                      <i
                        className="fa fa-download fa-lg"
                        style={{ color: "#6f6f6f" }}
                      ></i>
                    </a>
                  }
                ></Menu.Item>
              </Menu>
            )}
          </Col>
          <Col span={23}>
            {!ready && (
              <Col span={24}>
                <Skeleton active size="large" />{" "}
              </Col>
            )}
            <embed
              id="mypdf"
              style={{ height: "110vh", border: "none", top: 0 }}
              src={value}
              type="application/pdf"
              width="100%"
            />
          </Col>
        </Row>
      ) : (
        <>
          {data && (
            <div className="pg-content">
              <nav className="navbar pg-header">
                <a href="#" className="logo-wrapper">
                  <img src={data.companyLogo} alt="Company Logo" />
                </a>

                {/* <div className="navbar-right justify-content-end">
                  <a href="#" className="icon-wrapper">
                    <img src="/images/human-icon.svg" />
                  </a>
                  <a href="#" className="language">
                    <div className="icon-wrapper">
                      <img src="/images/earth-icon.svg" />
                    </div>
                    <span>EN</span>
                  </a>
                </div> */}

                <div className="bar-colors" />
              </nav>

              <div className="container pg-container">
                <div className="row" style={{ marginLeft: 0 }}>
                  <div className="col-md-12">

                    <nav aria-label="breadcrumb">
                    {props.type === 'invoice' &&
                      <ol className="breadcrumb pg-breadcrumbs">
                        <li className="breadcrumb-item">
                          <a href="#">{data.companyName}</a>
                        </li>
                        <RightOutlined />

                        <li className="breadcrumb-item" aria-current="page">
                          Invoice
                        </li>
                        <RightOutlined />
                        <li className="breadcrumb-item" aria-current="page">
                          {data.invoiceNo}/{data.date}
                        </li>
                      </ol>
                    }
                    {props.type === 'deal' &&
                      <ol className="breadcrumb pg-breadcrumbs">
                        <li className="breadcrumb-item">
                          <a href="#">{data.companyName}</a>
                        </li>
                        <RightOutlined />

                        <li className="breadcrumb-item" aria-current="page">
                          Deal
                        </li>
                        <RightOutlined />
                        <li className="breadcrumb-item" aria-current="page">
                          {data.dealNo}/{data.date}
                        </li>
                      </ol>
                    }
                    </nav>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-8" style={{ zIndex: "1" }}>
                      {!ready && (
                        <div className="col-md-8 mobile-skeleton">
                          <Skeleton active size="large" />{" "}
                        </div>
                      )}
                      <div className="mobile-text">
                        The PDF preview is available on desktop version.
                      </div>
                      <div className="mobile-embed">
                        <embed
                          id="mypdf"
                          style={{
                            border: "none",
                            top: 0,
                            boxShadow:
                              "0px 4px 18px 1px rgb(100 100 100 / 74%)",
                          }}
                          src={value}
                          height="800"
                          width="100%"
                          type="application/pdf"
                        />
                      </div>
                    </div>
                    <div className="mobile-container col-md-4">
                      <div className="card pg-card">
                        <div className="card-body">
                          <div className="card-price">
                            <p className="lead">Amount Due</p>
                            <div className="total">
                              <span className="amount">{data.amount}</span>
                              <span className="curency-sign">
                                {data.currency}
                              </span>
                            </div>
                          </div>
                          <div className="card-price--info">
                            <div className="status">
                              <p className="lead">Status</p>
                              <span>{data.paymentStatus}</span>
                            </div>
            
                            {data.dueDate && (
                              <div className="due-date">
                                <p className="lead">Due Date</p>
                                <span>{data.dueDate}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="buttons">
                        <a
                          className="btn btn-download"
                          href={process.env[config] + downloadPath}
                          style={{ margin: "0" }}
                        >
                          {plainTranslate(props.settings.locale, "Download")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col">
                    <a href="https://composity.com" target="_blank">
                      <p className="software">
                        <span>
                          Business Software by{" "}
                          <img src="/images/composity-logo.svg" />
                        </span>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="bg-icon-wrapper-right fix">
                <img src="/images/rectangles-bg.svg" />
              </div>
              <div className="bg-icon-wrapper-left">
                <img src="/images/2-rectangles-bg.svg" />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  settings: state.settings,
});
export default connect(mapStateToProps, {})(withRouter(Print));
