import React, { Component } from 'react';
import Translate, { plainTranslate } from '../../../common/translate';
import { connect } from 'react-redux';
import Activities from './activities';
import { Link } from 'react-router-dom';
import Consents from './consents';
import Documents from './documents';
import SimpleForm from "../../../forms/form";
import SlideModal from '../../../common/SlideModal';
import NewForm from "../../../redux-forms/form";
import Projects from './projects';
import Tasks from './tasks';
import ImportSale from '../../../import/importSale';
import { Pagination,Table, Button, Menu, Dropdown, Tabs as AntTabs, Select, Row, Col, Tooltip, Modal} from 'antd';
import { ACCOUNT_PROFILE_DELETE_TABLE_DATA, SALE_CHANGE_STAGE, ACCOUNT_EDIT_TASK } from "../../../../constants/actionTypes";
import { onUpdateBlockRecords, onFieldSave, onTabsLoad, onUpdateContentRecords } from "../../../../actions/actions";
import { deleteFolder, deleteFile } from '../../../../actions/documentsActions';
import Popup from '../../../common/popup';
import FormLoader from '../../../forms/form-loader';
import 'antd/es/menu/style/index.css';
import ContentGrid from './content-grid';
import { withRouter } from 'react-router-dom';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';
import {PlusOutlined, FileAddOutlined, EyeOutlined, SendOutlined, LockOutlined, GlobalOutlined } from '@ant-design/icons'
import 'antd/lib/select/style/index.css';
import moment from 'moment';

const { TabPane } = AntTabs;
const { SubMenu } = Menu;
const { Option } = Select;

class Tabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            popup: false,
            importPopup: false,
            deletePopup: false,
            deleteData: false,
            isOpenTasks: false,
            contentDataReady: true,
            messagePopup: false,
            tabPages: {
                invoice: 1,
                payments: 1,
                inventory: 1,
                document: 1,
                sales: 1,
                tasks:1,
                projects: 1,
                activities: 1,
                consents: 1,
            },
            total: [],
            changedStages: {},
            emailPopup: false,
            invoiceTemplateCc: null,
            invoiceCc: null,
            fileId: null,
            fileName: '',
            openFileDel: false,
            activeContent: 0
        };
        this.updateTasks = this.updateTasks.bind(this);
        this.handleStages = this.handleStages.bind(this);
        this.changeTabHandler = this.changeTabHandler.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.saveField = this.saveField.bind(this);
        this.deleteDocumentHandler = this.deleteDocumentHandler.bind(this);
        this.changePageHandler = this.changePageHandler.bind(this);
        this.RELOAD_URL =  '/' + this.props.match.params.db + '/api/accounts/tabs/' + this.props.account.id;
        this.changeContentTab = this.changeContentTab.bind(this);
        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.changeStage = this.changeStage.bind(this);
        this.getLinkedDocumentsContent = this.getLinkedDocumentsContent.bind(this);
        this.linkedDocumentsChangeStage = this.linkedDocumentsChangeStage.bind(this);
        this.updateEvents = this.updateEvents.bind(this);
        this.sendInvoiceTemplate = this.sendInvoiceTemplate.bind(this);
        this.handleCancelPreview = this.handleCancelPreview.bind(this);
    }

    componentDidMount() {
        let activeTab = localStorage.getItem('activeCrmTab');
        if (this.props && this.props.tabs && !this.props.tabs.includes(activeTab)) {
            activeTab = 'activities_tab';
            this.changeTabHandler('activities_tab');
        }
        
        this.setState({
            active: activeTab,
        });
    }

    updateTasks(data) {
        let result = {
             'id' : data['id'],
             'name' : data['name'],
             'status' :data['status'],
             'employee' : data['employee'],
             'endDate' : data['endDate'],
             'startDate' : data['startDate'],
             'completion' : data['completion'],
             'edit' : data['form'],
             'statusClassName' : data['statusType'] === 0 ? 'Draft' : data['statusType'] === 1 ? 'Actual' : data['statusType'] === 2 ? 'Archived' : ''
        };

        this.props.onEditTask(result);
        this.setState({isOpenTask: false, taskId: false});
    }

    changeTabHandler(tab) {
        localStorage.setItem('activeCrmTab', tab);
        this.setState({active: tab});
        this.setState({loading:true});
        this.props.onTabsLoad(this.RELOAD_URL, 1, false);
    }

    handleStages(e, stage, record) {
         e.preventDefault();
        this.setState({showLoader: true});

        let index = null;
        if(record.stagesLinks){
            index = record.stagesLinks.findIndex(o => o.id === stage.id);
        }
        if(record.stagesLinks[index].cancel){
            let body = [];
            body.push(record.id);
            client.post('/' + this.props.match.params.db + '/api/check-documents/sales', {'body': body})
            .then(response => response.data)
            .then(response => {
                this.setState({showCancelPopup: true, linkedDocumentsData: response, cancelStage: stage.value, documentId: record.id, showLoader: false});
            });
        }else{
            this.changeStage(e, stage.value,record.id);
        }
    }

    changeStage(e, url, id) {
        e.preventDefault();
        this.setState({showLoader: true});
        let changedStages = this.state.changedStages;

        for (const property in changedStages) {
          client.post('/' + this.props.match.params.db + changedStages[property], {'body': id})
            .then(response => response.data)
            .then(response => {
                if ('errors' in response) {
                    showAToast(response.errors, 'baseError', 'error');
                }
            })
            .catch(err => {
                showAToast('Error!', 'baseError', 'error')
            });
        }

        client.post(url, {
            'body': id
        })
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(response.errors, 'baseError', 'error');
            }
            else {
                if ('sale' in response) {
                    this.props.onChangeSaleStage(response['sale'], response['stage']);
                }
                this.props.setLoading();
                this.props.onTabsLoad(this.RELOAD_URL, 1, false);
                showAToast(response.success, 'baseSuccess', 'success');
            }
            this.setState({showCancelPopup: false, linkedDocumentsData: {}, cancelStage: null, documentId: null, showLoader: false});
        });
    }

    handleChangeForm(e, url) {
        e.preventDefault();

        client.post(url)
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(response.errors, 'baseError', 'error');
            }
            else {
                showAToast(response.success, 'baseSuccess', 'success');
            }
        });
    }

    handleTransferToInvoice(e,url,id) {
        e.preventDefault();

        client.post(url, {
            body:id
        })
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(response.errors, 'baseError', 'error');
            }
            else {
                showAToast(response.success, 'baseSuccess', 'success');
            }
        });
    }

    saveField() {
        this.setState({ popup: false });
        this.props.onFieldSave(this.props.updateRoute, 'billingData', this.state.value, this.props.additionalData);
    }

    changeValue(data) {
        let arr = [];
        arr.push(data);
        this.setState({
            value: arr
        }, () => this.saveField());
    }

    deleteDocumentHandler(url, data) {
        client.post(url)
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(response.errors, 'baseError', 'error');
            }
            else {
                this.props.onDeleteTableData(data);
                if (url.includes("files")) {
                    this.props.deleteFile(data.storageId);
                }
                if (url.includes("directories")) {
                    this.props.deleteFolder(data.storageId);
                }
                
                showAToast(response.success, 'baseSuccess', 'success');
            }
        });

        this.setState({
            deletePopup: false,
            deleteData: false
        });
    }

    changePageHandler(page) {
        this.setState({loading:true});
        this.props.onTabsLoad(this.RELOAD_URL, page, false);
    }

    changeContentTab(index){
        this.setState({contentDataReady : false});
        let tab = this.props.accountProfileInformation.contentTabs[index];
        this.props.updateContentRecords(this.props.match.params.db, tab.id, tab.sin, tab.linkTables, tab.links).then(() => {
            this.setState({contentDataReady : true});
        });
    }

    getLinkedDocumentsContent(){
        const columns = [{
                title: plainTranslate(this.props.settings.locale, 'Document Type'),
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                dataIndex: 'no',
                key: 'no',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Document Date'),
                dataIndex: 'date',
                key: 'date',
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Current Stage'),
                dataIndex: 'stage',
                key: 'stage',
                render: (text, record) => {
                    let stages = [];
                    if(record.type === 'payment'){
                        stages = this.state.linkedDocumentsData.paymentStagesOptions;
                    }else if(record.type === 'inventory receipt'){
                        stages = this.state.linkedDocumentsData.inventoryStagesOptions;
                    }else if(record.type === 'item request'){
                        stages = this.state.linkedDocumentsData.itemRequestStagesOptions;
                    }
                    return <Select dropdownMatchSelectWidth={false} defaultValue={record.stage} onChange={(value,type, id) => this.linkedDocumentsChangeStage(value, record)}>
                        {stages.map(stage => {
                            return <Option disabled={stage.position <= record.stagePosition ? true : false} className={"linked-documents-table"} value={stage.value}>{stage.label}</Option>
                        })}
                    </Select>
                }
            }];

        return <div><p>This sale is linked by:</p>
            <p>*choose a status for linked documents</p>
            <Table  dataSource={this.state.linkedDocumentsData.linkedDocuments} columns={columns} pagination={false} />
            <p>*if payment document contains more than one document, the line with this sale will be deleted.</p>
            <button className="button-primary-edited text-center btn-ok"
                    onClick={(e) => this.changeStage(e, this.state.cancelStage, this.state.documentId)}>
                <Translate locale={this.props.settings.locale} value="Confirm"/>
            </button>
            <button className="button-outlines green text-center"
                    onClick={() => this.setState({showCancelPopup: false})}>
                <Translate locale={this.props.settings.locale} value="Cancel"/>
            </button>
            </div>
    }

    linkedDocumentsChangeStage(value, record) {
        let arr = Object.assign({}, this.state.changedStages);
        arr[record.id] = record.url + value;
        this.setState({changedStages: arr});
    }

    updateEvents(){
        this.setState({eventsLoading:true});
        this.props.onTabsLoad(this.RELOAD_URL, 1, false).then(this.setState({eventsLoading:false}));
        this.setState({newEvent: false, editEvent: false, eventId: null});
    }

    sendInvoiceTemplate = (option) => {
        this.setState({emailPopup: true,invoiceTemplateCc: option.cc, invoiceCc: option.invoiceId});
    }

    handleDeleteFile(fileName, filePath,fileId, isPublic){
         this.setState({
             openFileDel: true,
             fileName: fileName,
             file: filePath,
             fileId: fileId,
             isPublicFile: isPublic
         })
     }
     handleOkDelFile(){
        client
        .post('/'+this.props.match.params.db+"/api/storage/file/delete", {fileId: this.state.fileId, isPublicFile: this.state.isPublicFile})
        .then(res => {
            showAToast('File deleted!', 'successUpload', 'success');
            this.props.onTabsLoad(this.RELOAD_URL, 1, false);
            this.setState({
                 openFileDel: false,
                 fileId: null,
                 fileName: null,
                 isPublicFile: null
            });
        })
        .catch(err => {
            showAToast("Error!", 'baseError', 'error')
        });
     }
     handleCanceDelFile(){
         this.setState({
             openFileDel: false,
             fileId: null,
             fileName: '',
             isPublicFile: null
         })
     }

    handlePreview = (file) => {
        this.setState({
            previewImage: file,
            previewVisible: true,
        });
    }

    handleCancelPreview(){
        this.setState({ previewVisible: false });
    }


    render() {
        const tabs = this.props.tabs ? this.props.tabs : [];
        const total = this.props.total ? this.props.total : [];
        const hasView = this.props.hasView ? this.props.hasView : [];
        const tables = this.props.accountProfileInformation.tables ? this.props.accountProfileInformation.tables : [];

        const style = {
            margin: '0 5px',
            color: 'black'
        };

        if(tables.length > 0) {
            this.setState({loading:false})
        }

        Array.prototype.remove = function() {
            var what, a = arguments, L = a.length, ax;
            while (L && this.length) {
                what = a[--L];
                while ((ax = this.indexOf(what)) !== -1) {
                    this.splice(ax, 1);
                }
            }
            return this;
        };

        if(this.props.settings.plan && this.props.settings.plan.hasOwnProperty('sales') && this.props.settings.plan.sales === false) tabs.remove('sales_tab');
        if(this.props.settings.plan && this.props.settings.plan.hasOwnProperty('invoice') && this.props.settings.plan.invoice === false) tabs.remove('invoice_tab');
        if(this.props.settings.plan && this.props.settings.plan.hasOwnProperty('expense') && this.props.settings.plan.expense === false) tabs.remove('expense_tab');
        if(this.props.settings.plan && this.props.settings.plan.hasOwnProperty('payments') && this.props.settings.plan.payments === false) tabs.remove('payments_tab');
        if(this.props.settings.plan && this.props.settings.plan.hasOwnProperty('inventory') && this.props.settings.plan.inventory === false) tabs.remove('inventory_tab');
        if(this.props.settings.plan && this.props.settings.plan.hasOwnProperty('project') && this.props.settings.plan.project === false) tabs.remove('projects_tab');
        if(this.props.settings.plan && this.props.settings.plan.hasOwnProperty('project') && this.props.settings.plan.project === false) tabs.remove('tasks_tab');

        let more = this.props.tabs
            ? ['sales_tab','invoice_tab','expense_tab','payments_tab','inventory_tab','projects_tab', 'tasks_tab','activities_tab','consent_tab','documents_tab','content_tab','events_tab']
            : [];

        more = more.filter(el => !tabs.includes(el));


        const consentColumns = [
            {
                key: 'date',
                dataIndex: 'date',
                title: plainTranslate(this.props.settings.locale, 'Date requested'),
                width: 110,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: plainTranslate(this.props.settings.locale, 'Status'),
                width: 130,
                render: (text, record) => <span>{plainTranslate(this.props.settings.locale, text)}</span>

            },
            {
                key: 'type',
                dataIndex: 'type',
                title: plainTranslate(this.props.settings.locale, 'Type'),
                width: 130,
                render: (text, record) => <span>{plainTranslate(this.props.settings.locale, text)}</span>
            },
            {
                key: 'comment',
                dataIndex: 'comment',
                title: plainTranslate(this.props.settings.locale, 'Comment'),
                width: 230
            },
        ];
        const accessColumns = [
            {
                key: 'dateRequested',
                dataIndex: 'dateRequested',
                title: plainTranslate(this.props.settings.locale, 'Date requested'),
                width: 130,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'email',
                dataIndex: 'email',
                title: plainTranslate(this.props.settings.locale, 'Email'),
                width: 130
            },
            {
                key: 'dateExpired',
                dataIndex: 'dateExpired',
                title: plainTranslate(this.props.settings.locale, 'Date expired'),
                width: 130,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
        ];

        const salesColumns = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    const printOptions = (
                        <Menu>
                            {record.printOptions &&
                                record.printOptions.map((option, i) => (
                                    <Menu.Item key={i}>
                                        <a target="_blank" rel="noopener noreferrer" href={option.link}>
                                            {plainTranslate(this.props.settings.locale, option.title)}
                                        </a>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );

                    const StagesOptions = (
                        <Menu>
                            {record.stagesLinks &&
                                record.stagesLinks.map((option, i) => (
                                    <Menu.Item key={i} className={option.forbiddenAccess ? 'forbidden' : ''} disabled={option.disabled}>
                                        <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleStages(e,option,record)}>
                                            {plainTranslate(this.props.settings.locale, option.title)}
                                        </a>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );

                    const ChangeFormOptions = (
                        <Menu style={{left: "125px",top: "-25px"}}>
                            {record.formLinks &&
                                record.formLinks.map((option, i) => (
                                    <Menu.Item key={i}>
                                        <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeForm(e,option.value)}>
                                            {plainTranslate(this.props.settings.locale, option.title)}
                                        </a>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );

                    const moreOptions = (
                        <Menu>
                            {
                                record.editBetaURL &&
                                <Menu.Item>
                                    <a rel="noopener noreferrer" href={record.editBetaURL}>
                                        {plainTranslate(this.props.settings.locale, 'Edit Beta')}
                                    </a>
                                </Menu.Item>
                            }
                            <Menu.Item>
                                <a rel="noopener noreferrer" href="#" onClick={e=>this.handleTransferToInvoice(e,record.transferToInvoiceURL,record.id)}>
                                    {plainTranslate(this.props.settings.locale, 'Transfer to invoice')}
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a rel="noopener noreferrer" href={record.reverseURL}>
                                    {plainTranslate(this.props.settings.locale, 'Reverse')}
                                </a>
                            </Menu.Item>
                            {
                                record.deliveryCreate &&
                                <Menu.Item>
                                    <a rel="noopener noreferrer" href={record.deliveryCreateURL}>
                                        {plainTranslate(this.props.settings.locale, 'Create Delivery')}
                                    </a>
                                </Menu.Item>
                            }
                            <Menu.Item>
                               <Dropdown overlay={ChangeFormOptions}>
                                    <a rel="noopener noreferrer">
                                        {plainTranslate(this.props.settings.locale, 'Change form')}
                                    </a>
                                </Dropdown>
                            </Menu.Item>
                        </Menu>
                    );

                    return (
                        <span>
                            <Link to={record.viewURL} title={plainTranslate(this.props.settings.locale, 'Profile')}><i style={style} className="fa fa-eye"></i></Link>
                            {record.hasEdit &&
                                <Link to={record.editURL} title={plainTranslate(this.props.settings.locale, 'Edit')}><i style={style} className="fa fa-pencil-square-o"></i></Link>
                            }
                            <Dropdown overlay={printOptions}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <i style={style} className="fa fa-print"></i>
                                </a>
                            </Dropdown>
                            <Dropdown overlay={StagesOptions}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <i style={style} className="fa fa-flag-o"></i>
                                </a>
                            </Dropdown>
                            <Dropdown overlay={moreOptions}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <i style={style} className="fa fa-ellipsis-v"></i>
                                </a>
                            </Dropdown>
                        </span>
                    )
                },
                width: 150,
            },
            {
                key: 'date',
                dataIndex: 'date',
                title: plainTranslate(this.props.settings.locale, 'Date'),
                width: 110,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'no',
                dataIndex: 'no',
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                width: 130,
                render: (text, record) => {
                    return record.hasEdit
                        ? <Link to={record.documentNoURL} style={{color: record.isStorno ? "#f01c1c" : "#000"}}>{record.no}</Link>
                        : <span>{text}</span>
                },
            },
            {
                key: 'location',
                dataIndex: 'location',
                title: plainTranslate(this.props.settings.locale, 'Location'),
                width: 130
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: plainTranslate(this.props.settings.locale, 'Status'),
                render: (text, record) => <span className={record.statusClassName}>{plainTranslate(this.props.settings.locale, text)}</span>,
                width: 180,
            },
            {
                key: 'paid',
                dataIndex: 'paid',
                title: plainTranslate(this.props.settings.locale, 'Paid'),
                width: 130,
                render: (text, record) => <span className={record.paidClassName}>{plainTranslate(this.props.settings.locale, text)}</span>
            },
            {
                key: 'amount',
                dataIndex: 'amount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Amount'),
                width: 130,
                render: (text, record) => <Tooltip title={record.currAmount}><span>{text}</span></Tooltip>
            },
            {
                key: 'paidSum',
                dataIndex: 'paidSum',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Paid'),
                width: 130
            },
            {
                key: 'Unpaid',
                dataIndex: 'unpaidSum',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Unpaid'),
                width: 130
            },

        ];
        const dealsColumns = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    const stagesOptions = (
                        <Menu>
                            {record.stagesLinks &&
                                record.stagesLinks.map((option, i) => (
                                    <Menu.Item key={i} className={option.forbiddenAccess ? 'forbidden' : ''}>
                                        <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleStages(e,option,record)}>
                                            {plainTranslate(this.props.settings.locale, option.title)}
                                        </a>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );

                    const ChangeFormOptions = (
                        <Menu style={{left: "135px",top: "-25px"}}>
                            {record.changeFormLinks &&
                                record.changeFormLinks.map((option, i) => (
                                    <Menu.Item key={i}>
                                        <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeForm(e,option.value)}>
                                            {plainTranslate(this.props.settings.locale, option.title)}
                                        </a>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );

                    const moreOptions = (
                        <Menu>
                            <Menu.Item>
                               <Dropdown overlay={ChangeFormOptions}>
                                    <a rel="noopener noreferrer">
                                        {plainTranslate(this.props.settings.locale, 'Change form')}
                                    </a>
                                </Dropdown>
                            </Menu.Item>
                            <Menu.Item>
                                <a rel="noopener noreferrer" href={record.copyURL}>
                                    {plainTranslate(this.props.settings.locale, 'Copy')}
                                </a>
                            </Menu.Item>
                        </Menu>
                    );


                    const salesLinksOptions = (
                        <Menu>
                            {record.crateSalesLinks &&
                                record.crateSalesLinks.map((option, i) => (
                                    <Menu.Item key={i}>
                                        <Link to={option.link}>
                                            {plainTranslate(this.props.settings.locale, option.title)}
                                        </Link>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );

                    return (
                        <span>
                            {record.hasEdit &&
                                <Link to={record.editURL} title={plainTranslate(this.props.settings.locale, 'Edit')}><i style={style} className="fa fa-pencil-square-o"></i></Link>
                            }
                            <Dropdown overlay={stagesOptions}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <i style={style} className="fa fa-flag-o"></i>
                                </a>
                            </Dropdown>
                            <Dropdown overlay={salesLinksOptions}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <i style={style} className="fa fa-shopping-cart"></i>
                                </a>
                            </Dropdown>
                            <Dropdown overlay={moreOptions}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <i style={style} className="fa fa-ellipsis-v"></i>
                                </a>
                            </Dropdown>
                        </span>
                    )
                },
                width: 200,
            },
            {
                key: 'date',
                dataIndex: 'date',
                title: plainTranslate(this.props.settings.locale, 'Date'),
                width: 110,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'no',
                dataIndex: 'no',
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                width: 130,
                render: (text, record) => {
                    return record.hasEdit
                        ? <Link to={record.documentNoURL} style={{color: "#000"}}>{record.no}</Link>
                        : <span>{text}</span>
                },
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: plainTranslate(this.props.settings.locale, 'Name'),
                width: 300
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: plainTranslate(this.props.settings.locale, 'Status'),
                render: (text, record) => <span className={record.statusClassName}>{plainTranslate(this.props.settings.locale, text)}</span>,
                width: 150,
            },
            {
                key: 'probability',
                dataIndex: 'probability',
                width: 130,
                title: plainTranslate(this.props.settings.locale, 'Probability'),
            },
            {
                key: 'baseAmount',
                dataIndex: 'baseAmount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Base Amount'),
                width: 130
            },
            {
                key: 'amount',
                dataIndex: 'amount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Amount'),
                width: 130
            }
        ];

        const messageColumns = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {                                 
                    return (
                        <span>
                            {record.view &&
                                   <EyeOutlined onClick={()=>{this.setState({messagePopup: true, message: record})}}
                                   title={plainTranslate(this.props.settings.locale, 'View')}/>
                            }
                           
                        </span>
                    )
                },
                width: 30,
                align: 'center',
            },
            {
                key: 'type',
                dataIndex: 'type',
                title: plainTranslate(this.props.settings.locale, 'Type'),
                width: 30,
                align: 'center',
            },
            {
                key: 'messageFrom',
                dataIndex: 'messageFrom',
                title: plainTranslate(this.props.settings.locale, 'Message From'),
                width: 30,
                align: 'center',
            },
            {
                key: 'messageDate',
                dataIndex: 'messageDate',
                title: plainTranslate(this.props.settings.locale, 'Message Date'),
                width: 50,
                align: 'center',
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'subject',
                dataIndex: 'subject',
                title: plainTranslate(this.props.settings.locale, 'Subject'),
                width: 50,
                align: 'center',
            },
        ]
        const invoiceColumns = [
            {
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    const menu = (
                        <Menu>
                            {record.printOptions.map(option => {
                                return (
                                    <Menu.Item>
                                        <a rel="noopener noreferrer" href={option.link} key={option.title} target="_blank">
                                            {option.title}
                                        </a>
                                    </Menu.Item>
                                );
                            })}

                        </Menu>
                    );

                    const sendEmailOptions = (
                        <Menu>
                            {record.sendEmailOptions.map((option, i) => {
                                    return <Menu.Item key={i} className={this.state.defaultTemplate === option.value ? "active" : ""}>
                                        <a onClick={() => this.sendInvoiceTemplate(option)}>
                                            {option.label}
                                        </a>
                                    </Menu.Item>
                            })}
                        </Menu>
                    );

                    return (
                        <span>
                            <Link to={record.view}><i style={style} className="fa fa-eye"></i></Link>
                            {record.hasEdit &&
                                <Link to={record.edit}><i className="fa fa-pencil-square-o"></i></Link>
                            }
                            <Dropdown overlay={menu}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <i style={style} className="fa fa-print"></i>
                                </a>
                            </Dropdown>
                            <Dropdown overlay={sendEmailOptions}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <SendOutlined />
                                </a>
                            </Dropdown>
                        </span>
                    )
                },
                width: 150,
            },
            {
                dataIndex: 'date',
                title: plainTranslate(this.props.settings.locale, 'Date'),
                key: 'date',
                width: 110,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'no',
                width: 130,
                dataIndex: 'no',
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                render: (text, record) =>  record.hasEdit ? <Link to={record.noUrl} style={{color: "#000"}}>{text}</Link>: <span>{record.no}</span>
            },
            {
                dataIndex: 'location',
                title: plainTranslate(this.props.settings.locale, 'Location'),
                key: 'location',
                width: 130
            },
            {
                dataIndex: 'paid',
                title: plainTranslate(this.props.settings.locale, 'Paid'),
                key: 'paid',
                width: 300,
                render: (text, record) => <span className={record.paidClassName} >{plainTranslate(this.props.settings.locale, text)}</span>
            },
            {
                dataIndex: 'status',
                title: plainTranslate(this.props.settings.locale, 'Status'),
                key: 'status',
                width: 130,
                render: (text, record) => <span className={record.statusClassName} >{plainTranslate(this.props.settings.locale, text)}</span>
            },
            {
                dataIndex: 'paymentMethod',
                title: plainTranslate(this.props.settings.locale, 'Payment Method'),
                key: 'paymentMethod',
                width: 130
            },
            {
                dataIndex: 'amountTotal',
                title: plainTranslate(this.props.settings.locale, 'Amount'),
                align: 'right',
                key: 'amountTotal',
                width: 130,
                render: (text, record) => <Tooltip title={record.currAmount}><span>{text}</span></Tooltip>
            },
            {
                dataIndex: 'paidSum',
                title: plainTranslate(this.props.settings.locale, 'Paid Sum'),
                align: 'right',
                key: 'paidSum',
                width: 130
            },
            {
                dataIndex: 'unpaidSum',
                title: plainTranslate(this.props.settings.locale, 'Unpaid Sum'),
                align: 'right',
                key: 'unpaidSum',
                width: 130
            },
        ];
        const paymentsColumns = [
            {
                key: 'date',
                dataIndex: 'date',
                title: plainTranslate(this.props.settings.locale, 'Date'),
                width: 110,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'no',
                dataIndex: 'no',
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                width: 130,
                render: (text, record) => {
                    return record.hasEdit ? <Link to={record.noUrl} style={{color: "#000"}}>{record.no}</Link> : text;
                },
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: plainTranslate(this.props.settings.locale, 'Status'),
                width: 130,
                render: (text, record) => <span className={record.statusClassName}>{plainTranslate(this.props.settings.locale, text)}</span>
            },
            {
                key: 'print',
                width: 110,
                dataIndex: 'print',
                title: plainTranslate(this.props.settings.locale, 'Print'),
                render: (text, record) => {
                    const menu = Array.isArray(record.printOptions) ? (
                        <Menu>
                            {record.printOptions.map(option => {
                                return (
                                    <Menu.Item key={option.title}>
                                        <a rel="noopener noreferrer" href={option.link} key={option.title} target="_blank">
                                            {option.title}
                                        </a>
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    ) : null;

                    return Array.isArray(record.printOptions)
                            ? <Dropdown overlay={menu}><a className="ant-dropdown-link"><i style={style} className="fa fa-print"></i></a></Dropdown>
                            : <a href={record.printOptions} target="_blank" ><i style={style} className="fa fa-print"></i></a>
                },
            },
            {
                key: 'paymentMethod',
                dataIndex: 'paymentMethod',
                title: plainTranslate(this.props.settings.locale, 'Payment Method'),
                width: 180,
                render: (text, record) => <span>{text}</span>
            },
            {
                key: 'amount',
                dataIndex: 'amount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Amount'),
                width: 130
            },
            {
                key: 'comment',
                dataIndex: 'comment',
                title: plainTranslate(this.props.settings.locale, 'Comment'),
                width: 200,
                render: (text, record) => <span>{text}</span>
            }
        ];
        const documentsColumns = [
            {
                key: 'actions',
                dataIndex: 'actions',
                width: 50,
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    return (<>
                        <span>
                            {(record.extension === 'jpeg' || record.extension === 'jpg' || record.extension === 'png' || record.extension === 'webp' || record.extension === 'svg') ?
                                <Link to='#' onClick={() => { this.handlePreview(record.full) }}>
                                    <span> <i className="fa fa-eye"></i> </span>
                                </Link>
                                :
                                <a href={record.full} target="_blank"><i className="fa fa-eye"></i></a>}
                            <Link to='#' onClick={() => {
                                navigator.clipboard.writeText(record.full);
                                showAToast("Link copied sucessfully", 'baseSuccess', 'success');
                            }}>
                                <span> <i className="fa fa-external-link"></i> </span>
                            </Link>
                            <Link to='#' onClick={() => { this.handleDeleteFile(record.name, record.path, record.id, record.isPublic) }}>
                                <span> <i className="fa fa-trash-o"></i> </span>
                            </Link>
                        </span>
                        {record.contract &&
                        <span>
                            <Link to='#' onClick={() => {
                                navigator.clipboard.writeText(`${window.location.origin}/${this.props.match.params.db}/public/document/${record.contract}`);
                                showAToast("Link copied sucessfully", 'baseSuccess', 'success');
                            }}>
                                <span> <i className="fa fa-link"></i> </span>
                            </Link>
                        </span>
                }
                    </>
                    )
                },
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: plainTranslate(this.props.settings.locale, 'Name'),
                width: 300
            },
            {
                dataIndex : 'Access',
                title : plainTranslate(this.props.settings.locale, 'Access'),
                width: 50,
                render: (text, record) => {
                    return <span>{record.isPublic ? <GlobalOutlined style={{marginRight: 5}}/> : <LockOutlined style={{marginRight: 5}}/>}</span>
                }
            },
            {
                dataIndex : 'extension',
                title : plainTranslate(this.props.settings.locale, 'Extension'),
                width: 50,
            },
            {
                dataIndex : 'size',
                title : plainTranslate(this.props.settings.locale, 'Size'),
                width: 50,
            },
            {
                dataIndex : 'created',
                title : plainTranslate(this.props.settings.locale, 'Created'),
                width: 50,
            },
            {
                dataIndex : 'createdBy',
                title : plainTranslate(this.props.settings.locale, 'Created by'),
                width: 50,
            }
        ];
        const inventoryColumns = [
            {
                width: 180,
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    const menu = (
                        <Menu>
                        {
                            record.print.type === 'dropdown' &&
                            record.print.url.map(url => {
                                return (
                                    <Menu.Item key={url.title}>
                                        <a target="_blank" rel="noopener noreferrer" href={url.value}>
                                            {url.title}
                                        </a>
                                    </Menu.Item>
                                );
                            })
                        }
                        </Menu>
                    );

                    return (
                        <span>
                            <Link to={record.view}>
                                <i style={style} className="fa fa-eye"></i>
                            </Link>
                            {record.edit !== '' &&
                                <Link to={record.edit}>
                                    <i style={style} className="fa fa-pencil-square-o"></i>
                                </Link>
                            }
                            {record.print.type === 'link'
                                ? (
                                    <Link to={record.print.url.link}>
                                        <i style={style} className="fa fa-pencil-square-o"></i>
                                    </Link>
                                )
                                : (
                                    <Dropdown overlay={menu}>
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                            <i style={style} className="fa fa-print"></i>
                                        </a>
                                    </Dropdown>
                                )
                            }
                        </span>
                    )
                },
            },
            {
                key: 'date',
                dataIndex: 'date',
                title: plainTranslate(this.props.settings.locale, 'Date'),
                width: 110,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                dataIndex: 'no',
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                width: 130,
                key: 'no',
                render: (text, record) => {
                    return record.noUrl !== '' ? <Link to={record.noUrl} style={{color: "#000"}}>{record.noValue}</Link> : record.noValue;
                },
            },
            {
                key: 'type',
                dataIndex: 'type',
                title: plainTranslate(this.props.settings.locale, 'Type'),
                width: 320,
                render: (text, record) => <span>{plainTranslate(this.props.settings.locale, text)}</span>
            },
            {
                key: 'stage',
                dataIndex: 'stage',
                title: plainTranslate(this.props.settings.locale, 'Stage'),
                width: 130,
                render: (text, record) => <span className={record.statusClassName}>{plainTranslate(this.props.settings.locale, text)}</span>
            },
            {
                key: 'location',
                dataIndex: 'location',
                width: 130,
                title: plainTranslate(this.props.settings.locale, 'Location'),
            },
            {
                key: 'amount',
                dataIndex: 'amount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Amount'),
                width: 130
            },
        ];

        const eventColumns = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    const stagesOptions = (
                        <Menu>
                            {record.stagesLinks &&
                                record.stagesLinks.map((option, i) => (
                                    <Menu.Item key={i}>
                                        <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleStages(e,option,record)}>
                                            {plainTranslate(this.props.settings.locale, option.title)}
                                        </a>
                                    </Menu.Item>
                                ))
                            }
                        </Menu>
                    );
                    const moreOptions = (
                        <Menu>
                            <Menu.Item>
                               <Dropdown overlay={stagesOptions}>
                                    <a rel="noopener noreferrer">
                                        {plainTranslate(this.props.settings.locale, 'Change stage')}
                                    </a>
                                </Dropdown>
                            </Menu.Item>
                        </Menu>
                    );
                    const data = { id: record.id, table: record.table };
                    const url = record.delete;

                    return (
                        <span>
                            <Link to='#' onClick={() => this.setState({ editEvent: true, eventId: record.id})} title={plainTranslate(this.props.settings.locale, 'Edit')}><i style={style} className="fa fa-pencil-square-o"></i></Link>
                            <Link to='#' onClick={() => this.setState({ deletePopup: url, deleteData: data })}>
                                <span> <i style={style} className="fa fa-trash-o"></i> </span>
                            </Link>
                            <Dropdown overlay={moreOptions}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <i style={style} className="fa fa-ellipsis-v"></i>
                                </a>
                            </Dropdown>
                        </span>
                    )
                },
                width: 100,
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: plainTranslate(this.props.settings.locale, 'Name'),
                width: 150,
                render: (text, record) => {
                    return record.link
                        ? <a target="_blank" href={record.link}>{record.name}</a>
                        : <span>{text}</span>
                },
            },
            {
                key: 'calendar',
                dataIndex: 'calendar',
                title: plainTranslate(this.props.settings.locale, 'Calendar'),
                width: 150
            },
            {
                key: 'stage',
                dataIndex: 'stage',
                title: plainTranslate(this.props.settings.locale, 'Stage'),
                render: (text, record) => {
                    let style = {
                        backgroundColor: record.stageColor,
                        padding: "3px 15px",
                        borderRadius: "10px"
                    };
                    return <span style={style}>{plainTranslate(this.props.settings.locale, text)}</span>
                },
                width: 150,
            },
            {
                key: 'startDate',
                dataIndex: 'startDate',
                title: plainTranslate(this.props.settings.locale, 'Start Date'),
                width: 130,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                key: 'endDate',
                dataIndex: 'endDate',
                title: plainTranslate(this.props.settings.locale, 'End Date'),
                width: 130,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
        ];

        const expenseColumns = [
            {
                width: 100,
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    return (
                        <span>
                            <Link to={record.viewURL}>
                                <i style={style} className="fa fa-eye"></i>
                            </Link>
                            {record.hasEdit &&
                                <Link to={record.editURL}>
                                    <i style={style} className="fa fa-pencil-square-o"></i>
                                </Link>
                            }
                        </span>
                    )
                },
            },
            {
                key: 'date',
                dataIndex: 'date',
                title: plainTranslate(this.props.settings.locale, 'Date'),
                width: 110,
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                dataIndex: 'no',
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                width: 130,
                key: 'no',
                render: (text, record) => {
                    return record.hasEdit ? <Link to={record.editURL} style={{color: "#000"}}>{record.no}</Link> : text;
                },
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: plainTranslate(this.props.settings.locale, 'Name'),
                width: 150,
                render: (text, record) => <span>{plainTranslate(this.props.settings.locale, text)}</span>
            },
            {
                key: 'location',
                dataIndex: 'location',
                width: 130,
                title: plainTranslate(this.props.settings.locale, 'Location'),
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: plainTranslate(this.props.settings.locale, 'Stage'),
                width: 130,
                render: (text, record) => <span className={record.statusClassName}>{plainTranslate(this.props.settings.locale, text)}</span>
            },
            {
                key: 'paid',
                dataIndex: 'paid',
                width: 130,
                title: plainTranslate(this.props.settings.locale, 'Paid'),
            },
            {
                key: 'baseAmount',
                dataIndex: 'baseAmount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Amount Net'),
                width: 130
            },
            {
                key: 'amount',
                dataIndex: 'amount',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Amount Gross'),
                width: 130,
                render: (text, record) => <Tooltip title={record.currAmount}><span>{text}</span></Tooltip>
            },
            {
                key: 'paidSum',
                dataIndex: 'paidSum',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Amount Paid'),
                width: 130
            },
            {
                key: 'unpaidSum',
                dataIndex: 'unpaidSum',
                align: 'right',
                title: plainTranslate(this.props.settings.locale, 'Amount Unpaid'),
                width: 130
            },
        ];
        const associatedPriceColumns = [
            {
                'key': 'code',
                'dataIndex' : 'code',
                'exportTitle' : plainTranslate(this.props.settings.locale, 'Item Code'),
                'title' :   plainTranslate(this.props.settings.locale, 'Item Code'),
            },
            {
                'key': 'name',
                'dataIndex' : 'name',
                'exportTitle' : plainTranslate(this.props.settings.locale, 'Item Name'),
                'title' : plainTranslate(this.props.settings.locale, 'Name'),
            },
            {
                'key': 'currencyName',
                'dataIndex' : 'currencyName',
                'exportTitle' : plainTranslate(this.props.settings.locale, 'Currency'),
                'title' : plainTranslate(this.props.settings.locale, 'Currency'),
            },
            {
                'key': 'measureName',
                'dataIndex' : 'measureName',
                'exportTitle' : plainTranslate(this.props.settings.locale, 'Measure'),
                'title' : plainTranslate(this.props.settings.locale, 'Measure'),
            },
            {
                'key': 'quantityMin',
                'dataIndex' : 'quantityMin',
                'exportTitle' : plainTranslate(this.props.settings.locale, 'Min. QTY'),
                'title' : plainTranslate(this.props.settings.locale, 'Min. QTY'),
            },
            {
                'key': 'priceUnit',
                'dataIndex' : 'priceUnit',
                'exportTitle' : plainTranslate(this.props.settings.locale, 'Price'),
                'title' : plainTranslate(this.props.settings.locale, 'Price'),
            },
            {
                'key': 'isPromotional',
                'dataIndex' : 'isPromotional',
                'exportTitle' : plainTranslate(this.props.settings.locale, 'Is Promortional?'),
                'title' : plainTranslate(this.props.settings.locale, 'Is Promortional?'),
            },
          ];
    
        const associatedDiscountColumns = [
            {
                'key': 'name',
                'dataIndex' : 'name',
                'exportTitle' : plainTranslate(this.props.settings.locale, 'Name'),
                'title' :  plainTranslate(this.props.settings.locale, 'Name'),
            },
            {
                'key': 'type',
                'dataIndex' : 'type',
                'exportTitle' : plainTranslate(this.props.settings.locale, 'Type'),
                'title' :  plainTranslate(this.props.settings.locale, 'Type'),
            },
            {
                'key': 'rateDiscount',
                'dataIndex' : 'rateDiscount',
                'exportTitle' : plainTranslate(this.props.settings.locale, 'Rate Discount'),
                'title' :  plainTranslate(this.props.settings.locale, 'Rate Discount'),
            },
            {
                'key': 'amountDiscount',
                'dataIndex' : 'amountDiscount',
                'exportTitle' : plainTranslate(this.props.settings.locale, 'Amount Discount'),
                'title' :  plainTranslate(this.props.settings.locale, 'Amount Discount'),
            },
            {
                'key': 'isPromotional',
                'dataIndex' : 'isPromotional',
                'exportTitle' : plainTranslate(this.props.settings.locale, 'Is Promortional?'),
                'title' : plainTranslate(this.props.settings.locale, 'Is Promortional?'),
            },
          ];

        let menuDealsOptions=[];
        if (tables.dealsTable && tables.dealsTable.hasCreate && tables.dealsTable.buttonLinks && tables.dealsTable.buttonLinks.length > 0) {
            menuDealsOptions = (
                <Menu>
                    {tables.dealsTable.buttonLinks.map((option, i) => (
                            <Menu.Item key={i}>
                                {option.link ?
                                <Link to={option.link + '&refs=' + this.props.account.id}>
                                    <Translate locale={this.props.settings.locale} value={option.label}/>
                                </Link>
                                :
                                <a href="#" onClick={() => this.setState({ importPopup: true, importLink: option.popupLink })}>
                                    <i style={style} className="fa fa-plus"></i><span> {plainTranslate(this.props.settings.locale, option.label)}</span>
                                </a>
                            }
                            </Menu.Item>
                        ))
                    }
                </Menu>
            );
        }

        const dealsTabContent = (
            <div>
                {this.state.active == 'deals_tab' && <span>
                    <div>
                        {
                            tables.dealsTable && tables.dealsTable.hasCreate && tables.dealsTable.buttonLinks && tables.dealsTable.buttonLinks.length > 0 &&
                            <div className="btn-round btn-blue">
                                <Dropdown overlay={menuDealsOptions} placement="bottomCenter" >
                                    <PlusOutlined />
                                </Dropdown>
                            </div>
                        }
                    </div>
                </span>
                }
            {
                tables.dealsTable && tables.dealsTable.data.length > 0 && !this.props.loading &&
                <div>
                    <Table
                    rowKey={record => record.id}
                    columns={dealsColumns}
                    dataSource={tables.dealsTable.data}
                    pagination={false}
                    scroll={{ x: "auto" }}
                    rowClassName={"account-profile-tabTable"}
                    size="small"
                    />
                    <Pagination
                    total={total.dealsTable}
                    showSizeChanger={false}
                    onChange={this.changePageHandler}
                    style={{margin: "15px 0"}}
                    />
                </div>
            }
            {
                ((!tables.dealsTable && this.state.active === 'deals_tab') || this.props.loading) &&
                <FormLoader />
            }
            {
                tables.dealsTable && tables.dealsTable.data.length == 0 &&
                <div className="no-projects text-center">
                    <img src='/images/no-activities.svg' />
                    <h5>{plainTranslate(this.props.settings.locale, 'No deals to show')}</h5>
                </div>
            }
            </div>
        );

        let menuSalesOptions=[];
        if (tables.salesTable && tables.salesTable.hasCreate && tables.salesTable.buttonLinks && tables.salesTable.buttonLinks.length > 0) {
            menuSalesOptions = (
                <Menu>
                    {tables.salesTable.buttonLinks.map((option, i) => (
                            <Menu.Item key={i}>
                                {option.link ?
                                <Link to={option.link + '&refs=' + this.props.account.id}>
                                    <i style={style} className="fa fa-plus"></i>{plainTranslate(this.props.settings.locale, option.label)}
                                </Link>
                                :
                                <a href="#" onClick={() => this.setState({ importPopup: true, importLink: option.popupLink })}>
                                    <FileAddOutlined /><span> {plainTranslate(this.props.settings.locale, option.label)}</span>
                                </a>
                            }
                            </Menu.Item>
                        ))
                    }
                </Menu>
            );
        }

        const salesTabContent = (
            <div>
                {this.state.active == 'sales_tab' && <span>
                    {
                        this.props.accountProfileInformation.billingData ?
                            <div>
                                {
                                    tables.salesTable && tables.salesTable.hasCreate && tables.salesTable.buttonLinks && tables.salesTable.buttonLinks.length > 0 &&
                                    <div className="btn-round btn-blue">
                                        <Dropdown overlay={menuSalesOptions} placement="bottomCenter" >
                                            <PlusOutlined />
                                        </Dropdown>
                                    </div>
                                }
                            </div>
                            :
                            <a href='#'
                                onClick={() => this.setState({ popup: true })}
                                className="btn-round btn-blue">
                                <i className="ion-android-add sales"></i>
                            </a>
                    }
                </span>
                }
             
            {
                tables.salesTable && tables.salesTable.data.length > 0 && !this.props.loading &&
                <div>
                    <Table
                    rowKey={record => record.id}
                    columns={salesColumns}
                    dataSource={tables.salesTable.data}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                    rowClassName={"account-profile-tabTable"}
                    size="small"
                    />
                    <Pagination
                    showSizeChanger={false}
                    total={total.salesTable}
                    onChange={this.changePageHandler}
                    style={{margin: "15px 0"}}
                    />

                </div>
            }
            {
                ((!tables.salesTable && this.state.active === 'sales_tab') || this.props.loading) &&
                <FormLoader />
            }
            {
                tables.salesTable && tables.salesTable.data.length == 0 &&
                <div className="no-projects text-center">
                    <img src='/images/no-activities.svg' />
                    <h5>{plainTranslate(this.props.settings.locale, 'No sales to show')}</h5>
                </div>
            }
            </div>
        );

        const activitiesTabContent = (
            <div>
            {
                tables.activities && tables.activities.data &&
                <div className="crmActivities">
                    <Activities activities={tables.activities.data} 
                    accountId={this.props.account.id} types={tables.activities.types}></Activities>
                </div>
            }
            {
                !tables.activities && this.state.active === 'activities_tab' &&
                <FormLoader />
            }
            </div>
        );

        const projectsTabContent = (
            <div>
            {
                tables.projectsTable &&
                <Projects
                    accountId={this.props.account.id}
                    projectsTable={tables.projectsTable}
                    activityTypes={this.props.account.activityTypes}
                />
            }
            {
                !tables.projectsTable && this.state.active === 'projects_tab' &&
                <FormLoader />
            }
            </div>
        );

        const tasksTabContent = (
            <div>
            {
                tables.tasksTable &&
                <Tasks
                    accountId={this.props.account.id}
                    tasksTable={tables.tasksTable}
                    activityTypes={this.props.account.activityTypes}
                />
            }
            {
                !tables.tasksTable && this.state.active === 'tasks_tab' &&
                <FormLoader />
            }
            </div>
        );

        const invoiceTabContent = (
            <div>
                {
                tables.invoiceTable && tables.invoiceTable.data.length > 0 && !this.props.loading &&
                <div>
                    <Table
                    rowKey={record => record.id}
                    columns={invoiceColumns}
                    dataSource={tables.invoiceTable.data}
                    scroll={{ x: 1100 }}
                    pagination={false}
                    rowClassName={"account-profile-tabTable"}
                    size="small"
                    />
                    <Pagination
                    showSizeChanger={false}
                    total={total.invoiceTable}
                    onChange={this.changePageHandler}
                    style={{margin: "15px 0"}}
                    />
                </div>
            }
            {
                ((!tables.invoiceTable && this.state.active === 'invoice_tab') || this.props.loading) &&
                <FormLoader />
            }
            {
                tables.invoiceTable && tables.invoiceTable.data.length == 0 &&
                <div className="no-projects text-center">
                    <img src='/images/no-activities.svg' />
                    <h5>{plainTranslate(this.props.settings.locale, 'No invoices to show')}</h5>
                </div>
            }
            </div>
        );

        let menuExpensesOptions=[];
        if (tables.expenseTable && tables.expenseTable.hasCreate && tables.expenseTable.buttonLinks && tables.expenseTable.buttonLinks.length > 0) {
            menuExpensesOptions = (
                <Menu>
                    {tables.expenseTable.buttonLinks.map((option, i) => (
                            <Menu.Item key={i}>
                                {option.link ?
                                <Link to={option.link + '&type=' + this.props.account.id}>
                                    <i style={style} className="fa fa-plus"></i>{plainTranslate(this.props.settings.locale, option.label)}
                                </Link>
                                :
                                <a href="#" onClick={() => this.setState({ importPopup: true, importLink: option.popupLink })}>
                                    <FileAddOutlined /><span> {plainTranslate(this.props.settings.locale, option.label)}</span>
                                </a>
                            }
                            </Menu.Item>
                        ))
                    }
                </Menu>
            );
        }

        const expenseTabContent = (
            <div>
                {this.state.active == 'expense_tab' && <span>
                    {
                        this.props.accountProfileInformation.billingData ?
                            <div>
                                {
                                    tables.expenseTable && tables.expenseTable.hasCreate && tables.expenseTable.buttonLinks && tables.expenseTable.buttonLinks.length > 0 &&
                                    <div className="btn-round btn-blue">
                                        <Dropdown overlay={menuExpensesOptions} placement="bottomCenter" >
                                            <PlusOutlined />
                                        </Dropdown>
                                    </div>
                                }
                            </div>
                            :
                            <a href='#'
                                onClick={() => this.setState({ popup: true })}
                                className="btn-round btn-blue">
                                <i className="ion-android-add sales"></i>
                            </a>
                    }
                </span>
                }
             
            {
                tables.expenseTable && tables.expenseTable.data.length > 0 && !this.props.loading &&
                <div>
                    <Table
                    rowKey={record => record.id}
                    columns={expenseColumns}
                    dataSource={tables.expenseTable.data}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                    rowClassName={"account-profile-tabTable"}
                    size="small"
                    />
                    <Pagination
                    showSizeChanger={false}
                    total={total.expenseTable}
                    onChange={this.changePageHandler}
                    style={{margin: "15px 0"}}
                    />

                </div>
            }
            {
                ((!tables.expenseTable && this.state.active === 'expense_tab') || this.props.loading) &&
                <FormLoader />
            }
            {
                tables.expenseTable && tables.expenseTable.data.length == 0 &&
                <div className="no-projects text-center">
                    <img src='/images/no-activities.svg' />
                    <h5>{plainTranslate(this.props.settings.locale, 'No expenses to show')}</h5>
                </div>
            }
            </div>
        );
const messagesTabContent = (
    <div>
        {
        tables.messageTable && tables.messageTable.data.length > 0 && !this.props.loading &&
        <div>
            <Table
            rowKey={record => record.id}
            columns={messageColumns}
            dataSource={tables.messageTable.data}
            scroll={{ x: 1100 }}
            pagination={false}
            rowClassName={"account-profile-tabTable"}
            size="small"
            />
            <Pagination
            showSizeChanger={false}
            total={total.messageTable}
            onChange={this.changePageHandler}
            style={{margin: "15px 0"}}
            />
        </div>
    }
    {
        ((!tables.messageTable && this.state.active === 'message_tab') || this.props.loading) &&
        <FormLoader />
    }
    {
        tables.messageTable && tables.messageTable.data.length == 0 &&
        <div className="no-projects text-center">
            <img src='/images/no-activities.svg' />
            <h5>{plainTranslate(this.props.settings.locale, 'No messages to show')}</h5>
        </div>
    }
    </div>
);
        let menuPaymentsOptions=[];
        if (tables.paymentsTable && tables.paymentsTable.hasCreate && tables.paymentsTable.buttonLinks && tables.paymentsTable.buttonLinks.length > 0) {
            menuPaymentsOptions = (
                <Menu>
                    {tables.paymentsTable.buttonLinks.map((option, i) => (
                            <Menu.Item key={i}>
                                <Link to={option.link + '&refs=' + this.props.account.id}>
                                    <Translate locale={this.props.settings.locale} value={option.label}/>
                                </Link>
                            </Menu.Item>
                        ))
                    }
                </Menu>
            );
        }

        const paymentsTabContent = (
            <div>
                {
                    tables.paymentsTable && tables.paymentsTable.hasCreate && tables.paymentsTable.buttonLinks && tables.paymentsTable.buttonLinks.length > 0 &&
                    <div className="btn-round btn-blue">
                        <Dropdown overlay={menuPaymentsOptions} placement="bottomCenter" >
                        <PlusOutlined/>
                        </Dropdown>
                    </div>
                }
                {
                    tables.paymentsTable && tables.paymentsTable.data.length > 0 && !this.props.loading &&
                    <div>
                        <Table
                        rowKey={record => record.id}
                        columns={paymentsColumns}
                        dataSource={tables.paymentsTable.data}
                        pagination={false}
                        rowClassName={"account-profile-tabTable"}
                        size="small"
                        />
                        <Pagination
                        total={total.paymentsTable}
                        showSizeChanger={false}
                        onChange={this.changePageHandler}
                        style={{margin: "15px 0"}}
                        />
                    </div>
                }
                {
                    ((!tables.paymentsTable && this.state.active === 'payments_tab') || this.props.loading) &&
                    <FormLoader />
                }
                {
                    tables.paymentsTable && tables.paymentsTable.data.length == 0 &&
                    <div className="no-projects text-center">
                        <img src='/images/no-activities.svg' />
                        <h5>{plainTranslate(this.props.settings.locale, 'No payments to show')}</h5>
                    </div>
                }
            </div>
        );

        let menuInventoryOptions=[];
        if (tables.inventoryTable && tables.inventoryTable.hasCreate && tables.inventoryTable.buttonLinks && tables.inventoryTable.buttonLinks.length > 0) {
            menuInventoryOptions = (
                <Menu>
                    {tables.inventoryTable.buttonLinks.map((option, i) => (
                            <Menu.Item key={i}>
                                <Link to={option.link + '&refs=' + this.props.account.id}>
                                    <Translate locale={this.props.settings.locale} value={option.label}/>
                                </Link>
                            </Menu.Item>
                        ))
                    }
                </Menu>
            );
        }

        const inventoryTabContent = (
            <div>
                <div className="invent btn-group">
                    {
                        tables.inventoryTable && tables.inventoryTable.hasCreate && tables.inventoryTable.buttonLinks && tables.inventoryTable.buttonLinks.length > 0 &&
                        <div className="btn-round btn-blue">
                            <Dropdown overlay={menuInventoryOptions} placement="bottomCenter" >                               
                                    <PlusOutlined/>
                            </Dropdown>
                        </div>
                    }
                </div>
                {
                    tables.inventoryTable && tables.inventoryTable.data.length > 0 && !this.props.loading &&
                    <div>
                        <Table
                        rowKey={record => record.id}
                        columns={inventoryColumns}
                        dataSource={tables.inventoryTable.data}
                        pagination={false}
                        rowClassName={"account-profile-tabTable"}
                        size="small"
                        />
                        <Pagination
                        total={total.inventoryTable}
                        showSizeChanger={false}
                        onChange={this.changePageHandler}
                        style={{margin: "15px 0"}}
                        />
                    </div>
                }
                {
                    ((!tables.inventoryTable && this.state.active === 'inventory_tab') || this.props.loading) &&
                    <FormLoader />
                }
                {
                    tables.inventoryTable && tables.inventoryTable.data.length == 0 &&
                    <div className="no-projects text-center">
                        <img src='/images/no-activities.svg' />
                        <h5>{plainTranslate(this.props.settings.locale, 'No inventory receipts to show')}</h5>
                    </div>
                }
            </div>
        );

        const consentTabContent = (
            <div>
            {
                tables.consents && tables.consents.dataConsents && tables.consents.dataAccess &&
                <Consents
                    accountId={this.props.account.id}
                    data={tables.consents}
                    columnsConsents={tables.consents && consentColumns}
                    columnsAccess={tables.consents && accessColumns}
                />
            }
            {
                !tables.consents && this.state.active === 'consent_tab' &&
                <FormLoader />
            }
            </div>
        );

        const documentsTabContent = (
            <div>
            {
                tables.documentTable &&
                <Documents
                    accountId={this.props.account.id}
                    account={this.props.account}
                    columnsDocuments={tables.documentTable ? documentsColumns : null}
                    dataDocuments={tables.documentTable ? tables.documentTable.data : null}
                    total={tables.documentTable ? tables.documentTable.total : null}
                    changePage={this.changePageHandler}
                />
            }
            {
                !tables.documentTable && this.state.active === 'documents_tab' &&
                <FormLoader />
            }
            </div>
        );

        let contentTabContent = (
            <>
                {this.props.accountProfileInformation?.contentTabs && this.props.accountProfileInformation?.contentTabs.length ?
                    <>
                        <AntTabs
                            defaultActiveKey={this.state.activeContent}
                            size="default"
                            // onChange={this.changeContentTab}
                            tabBarStyle={{ fontWeight: 'bold', marginBottom: 0, color: 'rgb(0 0 0 / 70%)' }}
                        >
                            <>
                                {this.props.accountProfileInformation.contentTabs.map((grid, key) => {
                                    return <TabPane tab={grid.name} key={key}>
                                        <ContentGrid contentType={grid} />
                                    </TabPane>
                                })
                                }
                            </>
                        </AntTabs>
                    </>
                    :
                    <div className="no-projects text-center">
                        <img src='/images/no-activities.svg' />
                        <h5>{plainTranslate(this.props.settings.locale, 'No data to show')}</h5>
                    </div>
                }
            </>
        );

        if (!this.state.contentDataReady) {
            contentTabContent = ( <FormLoader />);
        }

        const eventsTabContent = (
            <div>
                {this.state.active == 'events_tab' && <span>
                    {
                            <a href='#'
                                onClick={() => this.setState({ newEvent: true })}
                                className="btn-round btn-blue">
                                <i className="ion-android-add sales"></i>
                            </a>
                    }
                </span>
                }
             
            {
                tables.eventsTable && tables.eventsTable.data.length > 0 && !this.state.eventsLoading &&
                <div>
                    <Table
                    rowKey={record => record.id}
                    columns={eventColumns}
                    dataSource={tables.eventsTable.data}
                    pagination={false}
                    scroll={{ x: 1000 }}
                    rowClassName={"account-profile-tabTable"}
                    size="small"
                    />
                    <Pagination
                    showSizeChanger={false}
                    total={total.eventsTable}
                    onChange={this.changePageHandler}
                    style={{margin: "15px 0"}}
                    />

                </div>
            }
            {
                (this.state.active === 'events_tab' && this.state.eventsLoading) &&
                <FormLoader />
            }
            {
                tables.eventsTable && tables.eventsTable.data.length == 0 && !this.state.eventsLoading &&
                <div className="no-projects text-center">
                    <img src='/images/no-activities.svg' />
                    <h5>{plainTranslate(this.props.settings.locale, 'No events to show')}</h5>
                </div>
            }
            </div>
        );

        const associatedPriceTab = (
            <div>
                {
                    tables && tables.associatedPriceTable && tables.associatedPriceTable.data && tables.associatedPriceTable.data.length > 0 && !this.props.loading &&
                    <div>
                        <Table
                        rowKey={record => record.id}
                        columns={associatedPriceColumns}
                        dataSource={tables.associatedPriceTable.data}
                        pagination={false}
                        rowClassName={"account-profile-tabTable"}
                        size="small"
                        />
                        <Pagination
                        total={total.associatedPriceTable}
                        showSizeChanger={false}
                        onChange={this.changePageHandler}
                        style={{margin: "15px 0"}}
                        />
                    </div>
                }
                {
                    ((!tables.associatedPriceTable && this.state.active === 'associatedPrice_tab') || this.props.loading) &&
                    <FormLoader />
                }
                {
                    tables && tables.associatedPriceTable && tables.associatedPriceTable.data && tables.associatedPriceTable.data.length == 0 &&
                    <div className="no-projects text-center">
                        <img src='/images/no-activities.svg' />
                        <h5>{plainTranslate(this.props.settings.locale, 'No data to show')}</h5>
                    </div>
                }
            </div>
        );

        const associatedDiscountTab = (
            <div>
                {
                    tables && tables.associatedDiscountTable && tables.associatedDiscountTable.data && tables.associatedDiscountTable.data.length > 0 && !this.props.loading &&
                    <div>
                        <Table
                        rowKey={record => record.id}
                        columns={associatedDiscountColumns}
                        dataSource={tables.associatedDiscountTable.data}
                        pagination={false}
                        rowClassName={"account-profile-tabTable"}
                        size="small"
                        />
                        <Pagination
                        total={total.associatedDiscountTable}
                        showSizeChanger={false}
                        onChange={this.changePageHandler}
                        style={{margin: "15px 0"}}
                        />
                    </div>
                }
                {
                    ((!tables.associatedDiscountTable && this.state.active === 'associatedDiscount_tab') || this.props.loading) &&
                    <FormLoader />
                }
                {
                    tables && tables.associatedDiscountTable && tables.associatedDiscountTable.data && tables.associatedDiscountTable.data.length == 0 &&
                    <div className="no-projects text-center">
                        <img src='/images/no-activities.svg' />
                        <h5>{plainTranslate(this.props.settings.locale, 'No data to show')}</h5>
                    </div>
                }
            </div>
        );

        return (
            <React.Fragment>
                <AntTabs
                activeKey={this.state.active}
                size="default"
                tabBarGutter={25}
                onChange={this.changeTabHandler}
                className='custom-data'
                tabBarStyle={{fontWeight: 'bold', marginBottom: 0, color: 'rgb(0 0 0 / 70%)'}}
                >
                    {
                        tabs.includes('activities_tab') &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Activities')} key="activities_tab"> {activitiesTabContent} </TabPane>
                    }
                    {
                        'project' in this.props.settings.plan && this.props.settings.plan.project && tabs.includes('projects_tab') &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Projects')} key="projects_tab">{projectsTabContent}</TabPane>
                    }
                    {
                        'project' in this.props.settings.plan && this.props.settings.plan.project && tabs.includes('tasks_tab') &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Tasks')} key="tasks_tab">{tasksTabContent}</TabPane>
                    }
                    {
                        'deals' in this.props.settings.plan && this.props.settings.plan.deals && tabs.includes('deals_tab') &&
                        hasView && hasView.deals &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Deals')} key="deals_tab">{dealsTabContent}</TabPane>
                    }
                    {
                        'sales' in this.props.settings.plan && this.props.settings.plan.sales && tabs.includes('sales_tab') &&
                        hasView && hasView.sales &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Sales')} key="sales_tab">{salesTabContent}</TabPane>
                    }
                    {
                        'invoice' in this.props.settings.plan && this.props.settings.plan.invoice && tabs.includes('invoice_tab') &&
                        hasView && hasView.invoice &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Invoices')} key="invoice_tab"> {invoiceTabContent} </TabPane>
                    }
                    {
                        'expense' in this.props.settings.plan && this.props.settings.plan.expense && tabs.includes('expense_tab') &&
                        hasView && hasView.expense &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Expenses')} key="expense_tab"> {expenseTabContent} </TabPane>
                    }
                    {
                        'payments' in this.props.settings.plan && this.props.settings.plan.payments && tabs.includes('payments_tab') &&
                        hasView && hasView.payments &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Payments')} key="payments_tab"> {paymentsTabContent} </TabPane>
                    }
                    {
                        'inventory' in this.props.settings.plan && this.props.settings.plan.inventory && tabs.includes("inventory_tab") &&
                        hasView && hasView.inventory &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Inventory')} key="inventory_tab">{inventoryTabContent}</TabPane>
                    }
                    {
                        tabs.includes("associatedPrice_tab") &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Associated Price Lists')} key="associatedPrice_tab">{associatedPriceTab}</TabPane>
                    }
                    {
                        tabs.includes("associatedDiscount_tab") &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Associated Discounts')} key="associatedDiscount_tab">{associatedDiscountTab}</TabPane>
                    }
                    {
                        tabs.includes("consent_tab") &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Data Processing')} key="consent_tab"> {consentTabContent} </TabPane>
                    }
                    {
                        tabs.includes("documents_tab") &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Documents')}  key="documents_tab"> {documentsTabContent} </TabPane>
                    }
                    {
                        tabs.includes('content_tab') &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, "Content data")} key="content_tab"> {contentTabContent} </TabPane>
                    }
                    {
                        tabs.includes('events_tab') &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, "Events")} key="events_tab"> {eventsTabContent} </TabPane>
                    }  
                    {
                        'messages' in this.props.settings.plan && this.props.settings.plan.messages && tabs.includes('message_tab') &&
                        hasView && hasView.messages &&
                        <TabPane tab={plainTranslate(this.props.settings.locale, 'Messages')} key="message_tab"> 
                        {messagesTabContent} </TabPane>
                    }
                </AntTabs>
                {
                     !tabs.includes('activities_tab')
                     && activitiesTabContent
                }
                {
                    'sales' in this.props.settings.plan && this.props.settings.plan.sales && !tabs.includes('sales_tab') &&
                    hasView && hasView.sales &&
                    salesTabContent
                }
                {
                    'deals' in this.props.settings.plan && this.props.settings.plan.deals && !tabs.includes('deals_tab') &&
                    dealsTabContent
                }
                {
                    'project' in this.props.settings.plan && this.props.settings.plan.project && !tabs.includes('projects_tab')
                    && projectsTabContent
                }
                {
                    'project' in this.props.settings.plan && this.props.settings.plan.project && !tabs.includes('tasks_tab')
                    && tasksTabContent
                }
                {
                    'inventory' in this.props.settings.plan && this.props.settings.plan.inventory && !tabs.includes("inventory_tab") &&
                    hasView && hasView.inventory &&
                    inventoryTabContent
                }
                {
                    'invoice' in this.props.settings.plan && this.props.settings.plan.invoice && !tabs.includes('invoice_tab') &&
                    hasView && hasView.invoice &&
                    invoiceTabContent
                }
                {
                    'expense' in this.props.settings.plan && this.props.settings.plan.expense && !tabs.includes('expense_tab') &&
                    hasView && hasView.expense &&
                    expenseTabContent
                }                    
                {
                    !tabs.includes("consent_tab") &&
                    consentTabContent
                }
                {
                    !tabs.includes("documents_tab") &&
                    documentsTabContent
                }
                {
                    'payments' in this.props.settings.plan && this.props.settings.plan.payments && !tabs.includes('payments_tab') &&
                    hasView && hasView.payments &&
                    paymentsTabContent
                }
                { tabs.contents && !tabs.includes('content_tab') &&
                    contentTabContent
                }
                { tabs.contents && !tabs.includes('events_tab') &&
                    eventsTabContent
                }
  
                 {
                    'messages' in this.props.settings.plan && this.props.settings.plan.messages && !tabs.includes('message_tab') &&
                    hasView && hasView.messages &&
                    messagesTabContent
                }
                {
                    this.state.deletePopup &&
                    <Popup locale={this.props.settings.locale}
                        title={plainTranslate(this.props.settings.locale, 'Delete')}
                        onClose={() => this.setState({ deletePopup: false })}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <div className="text-center">
                                        <h3>
                                            <Translate locale={this.props.settings.locale} value="Are you sure?" />
                                        </h3>
                                        <button className="button-outlines green text-center"
                                            onClick={() => this.setState({ deletePopup: false })}>
                                            <Translate locale={this.props.settings.locale} value="No" />
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                            onClick={() => this.deleteDocumentHandler(this.state.deletePopup, this.state.deleteData)}>
                                            <Translate locale={this.props.settings.locale} value="Yes" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Popup>
                }
                {
                    this.state.openFileDel &&
                    <Popup locale={this.props.settings.locale}
                        title={plainTranslate(this.props.settings.locale, 'Delete')}
                        onClose={() => this.setState({ openFileDel: false, isPublicFile: null })}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <div className="text-center">
                                        <h3>
                                            <Translate locale={this.props.settings.locale} value="Are you sure?" />
                                        </h3>
                                        <button className="button-outlines green text-center"
                                            onClick={() => this.setState({ openFileDel: false, isPublicFile: null })}>
                                            <Translate locale={this.props.settings.locale} value="No" />
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                            onClick={() => this.handleOkDelFile()}>
                                            <Translate locale={this.props.settings.locale} value="Yes" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Popup>
                }
                {
                    this.state.popup &&
                    <SlideModal onClose={() => this.setState({ popup: false })} title={plainTranslate(this.props.settings.locale, 'Billing data')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/billing-datas/'}
                                        saveRoute={this.props.match.params.db + '/api/billing-datas'}
                                        updateParent={(data) => this.changeValue(data)}
                                        popup={true}
                                        isNew={true}
                                        onCancel={() => this.setState({ popup: false })}
                                        match={{ params: { id: this.props.accountProfileInformation.id ? this.props.accountProfileInformation.id : 0 } }}
                                        refs={this.props.accountProfileInformation.id}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.importPopup &&
                    <SlideModal onClose={() => this.setState({ importPopup: false })} title={plainTranslate(this.props.settings.locale, 'Import')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <ImportSale history={this.props.history} link={this.state.importLink} />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                this.state.isOpenTask &&
                <SlideModal onClose={() => this.setState({isOpenTask: false, taskId: false})} title={plainTranslate(this.props.settings.locale, 'Task')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <SimpleForm
                                    formRoute={this.props.match.params.db + '/api/tasks/'}
                                    saveRoute={this.props.match.params.db + '/api/tasks/'}
                                    updateParent={(data) => this.updateTasks(data)}
                                    match={{params: {id: this.state.taskId}}}
                                    onCancel={() => this.setState({isOpenTask: false})}
                                    isNew={true}
                                    popup={true}
                                    account={this.props.accountId}
                                    type={this.state.taskForm}
                                />
                            </div>
                        </div>
                    </section>
                </SlideModal>
                }
                {
                    this.state.showCancelPopup &&
                    <Popup locale={this.props.settings.locale}
                           title={plainTranslate(this.props.settings.locale, 'Change stage')}
                           onClose={() => this.setState({showCancelPopup: false})} showLoader={this.state.showLoader}>
                        <section className="newPanel">
                            <div className="row m-bot15">
                            {this.getLinkedDocumentsContent()}
                            </div>
                        </section>
                    </Popup>
                }
                {
                    this.state.newEvent &&
                    <SlideModal onClose={() => this.setState({ newEvent: false })} title={plainTranslate(this.props.settings.locale, 'Calendar Event')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <NewForm
                                        formRoute={this.props.match.params.db + '/api/calendar-event/'}
                                        saveRoute={this.props.match.params.db + '/api/calendar-event'}
                                        onSuccessfulSave={() => this.updateEvents()}
                                        popup={true}
                                        isNew={true}
                                        onCancel={() => this.setState({ newEvent: false })}
                                        form={'initializeFromState2'}
                                        match={{ params: { } }}
                                        refs={this.props.accountProfileInformation.id}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.editEvent &&
                    <SlideModal onClose={() => this.setState({ editEvent: false, eventId: null })} title={plainTranslate(this.props.settings.locale, 'Calendar Event')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <NewForm
                                        formRoute={this.props.match.params.db + '/api/calendar-event/'}
                                        saveRoute={this.props.match.params.db + '/api/calendar-event/'}
                                        onSuccessfulSave={() => this.updateEvents()}
                                        popup={true}
                                        isNew={true}
                                        onCancel={() => this.setState({ editEvent: false, eventId: null })}
                                        form={'initializeFromState2'}
                                        match={{params: {id: this.state.eventId}}}
                                        refs={this.props.accountProfileInformation.id}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                 {
                    this.state.messagePopup &&
                    <SlideModal onClose={() => this.setState({ messagePopup: false, message: null })} 
                    title={plainTranslate(this.props.settings.locale, 'Message')}>
                        <section className="newPanel">
                            <div className="panel-body">
                              {this.state.message && <>
                                <Row>
                                   <Col span={12}>
                                   <b>{plainTranslate(this.props.settings.locale, 'Message to')} </b> {': '+this.state.message.messageTo}
                                   </Col>
                                </Row>
                                <Row>
                                   <Col span={12}>
                                   <b>{plainTranslate(this.props.settings.locale, 'Message from')}</b>{ ': '+this.state.message.messageFrom}
                                   </Col>
                                </Row>
                                <Row>
                                   <Col span={12}>
                                   <b>{plainTranslate(this.props.settings.locale, 'Date')}</b>{ ': '+this.state.message.messageDate}
                                   </Col>
                                </Row>
                                <Row>
                                   <Col span={12}>
                                   <b>{plainTranslate(this.props.settings.locale, 'Subject')}</b>{ ': '+this.state.message.subject}
                                   </Col>
                                </Row>
                                <Row>
                                   <Col span={12}>
                                   <div dangerouslySetInnerHTML={{ __html: this.state.message.body }}/>
                                   </Col>
                                </Row>
                              </>}
                            </div>
                        </section>
                    </SlideModal>
                }

                {this.state.emailPopup === true &&
                    <SlideModal onClose={() => this.setState({emailPopup: false, invoiceTemplateCc: null})} title={plainTranslate(this.props.settings.locale, 'New Email')}>
                    <section className="newPanel">
                      <div className="panel-body">
                        <div className="row m-bot15">
                            <NewForm
                                formRoute={this.props.match.params.db + '/api/message-email/'}
                                saveRoute={this.props.match.params.db + '/api/message-email/new'}
                                popup={true}
                                onSuccessfulSave={() => this.setState({ emailPopup: false })}
                                onCancel={() => this.setState({ emailPopup: false })}
                                refs={this.props.accountProfileInformation.email ? this.props.accountProfileInformation.email : ' '}
                                type={JSON.stringify({"invoiceCc": this.state.invoiceCc, "templateCc": this.state.invoiceTemplateCc,"type": 'invoice'})}
                                form={'initializeFromState'}
                                match={{ params: { id: 0 } }}
                                isNew={true}
                                sendButton={true}
                            />
                        </div>
                      </div>
                    </section>
                  </SlideModal>
                }
                <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancelPreview}>
                  <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    accountProfileInformation: state.accountProfileInformation,
    documents: state.documents
});

const mapDispatchToProps = dispatch => ({
    onEditTask: (data) => dispatch({type: ACCOUNT_EDIT_TASK, payload: {task: data}}),
    onTabsLoad: (route, page, requiredTotal) => dispatch(onTabsLoad(route,page, requiredTotal)),
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData)),
    onDeleteTableData: (data) => dispatch({ type: ACCOUNT_PROFILE_DELETE_TABLE_DATA, payload: { data: data } }),
    onChangeSaleStage: (id, stage) => dispatch({type:SALE_CHANGE_STAGE, payload: {id: id, stage: stage}}),
    updateRecords: (db, blockId, accountId) => dispatch(onUpdateBlockRecords(db, blockId, accountId)),
    updateContentRecords: (db, typeId, accountId, linkTables, links) => dispatch(onUpdateContentRecords(db, typeId, accountId, linkTables, links)),
    deleteFolder: (folderId) => dispatch(deleteFolder(folderId)),
    deleteFile: (fileId) => dispatch(deleteFile(fileId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tabs));
