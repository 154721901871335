import React from 'react';

const Textarea = props => (
            <span className="form-input-field">
                    <textarea className="form-control" disabled={props.field.disabled} rows={props.field.rows}
                              {...props.input}
                              >
                    </textarea>
                </span>
            );

export default Textarea;