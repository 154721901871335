import React, { useEffect, useState } from 'react';
import { plainTranslate } from "../../common/translate";
import { Button, Form, Input, Select, Switch, Table, Space, Tag } from 'antd';
import { CloseOutlined, PlusOutlined, CheckOutlined, EditOutlined, DeleteOutlined, MinusCircleOutlined, ContactsOutlined } from '@ant-design/icons';
import SlideModal from '../../common/SlideModal';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const AttributeCategories = props => {
    const [value, setValue] = useState(props.formData ? props.formData.categoryGroups : []);
    const [fields, setFields] = useState(null);
    const [selectedType, setSelectedType] = useState(false);
    const [editableField, setEditableField] = useState(false);
    const formRef = React.createRef();
    const [form] = Form.useForm();
    const [editableValues, setEditableValues] = useState(false);

    useEffect(() => {
    }, []);

    const onFinish = (values) => {
        if (editableField === false) {
            let arr = value.push(values);
            setValue(value);
        } else {
            value[editableField] = values;
            setValue(value);
        }

        let allValues = new Object();
        allValues = value;
        props.input.onChange(allValues);
        setSelectedType(false);
        setEditableField(false);
        form.resetFields();
    }

    const onSubmit = () => {
        formRef.current.submit();
    }

    const handleDeleteField = (key) => {
        const allValues = value.filter((item) => {
            return item.id !== value[key].id
        })
            setValue(allValues);

        props.input.onChange(allValues);
    }

    const handleEditField = (key) => {
        let editField = value[key];

        setEditableField(key);
        setSelectedType(editField);
        setEditableValues(editField);
        form.setFieldsValue(editField);
    }

    const addNewItem = () => {
        form.setFieldsValue({});
        setEditableValues(null);
        setSelectedType(true);
    }

    const onCloseModal = () => {
        setSelectedType(false);
        setEditableField(false);
        setEditableValues(null);
        form.resetFields();
    }

    return (<div>
        <React.Fragment>
            <Button className="button-outline grey" onClick={addNewItem}><PlusOutlined /> {plainTranslate(props.settings.locale, 'Add another field')}</Button>
            <table className="json-builder-table">
                <tr>
                    <th>{plainTranslate(props.settings.locale, 'category')}</th>
                    <th>{plainTranslate(props.settings.locale, 'status')}</th>
                    <th>{plainTranslate(props.settings.locale, 'statusLine')}</th>
                    <th>{plainTranslate(props.settings.locale, 'isPublic')}</th>
                    <th>{plainTranslate(props.settings.locale, 'isPublicShow')}</th>
                    <th>{plainTranslate(props.settings.locale, 'isRequired')}</th>
                    <th>{plainTranslate(props.settings.locale, 'Sort')}</th>
                    <th>{plainTranslate(props.settings.locale, 'Action')}</th>
                </tr>
                {value && value.map((item, key) => {

                    const index = props.field.specification.find(({ id }) => id === "category");

                    let category = index.options.find(({ value }) => value === item.category);

                    return <tr key={key}>
                            <td>{category ? category.label : null}</td>
                            <td>{(item.status == true ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />)}</td>
                            <td>{(item.statusLine == true ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />)}</td>
                            <td>{(item.isPublic == true ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />)}</td>
                            <td>{(item.isPublicShow == true ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />)}</td>
                            <td>{(item.isRequired == true ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />)}</td>
                            <td>{item.sort}</td>
                            <td><Space size="middle">
                                <a onClick={() => handleEditField(key)} title={plainTranslate(props.settings.locale, 'Edit')}><EditOutlined /></a>
                                <a onClick={() => handleDeleteField(key)} title={plainTranslate(props.settings.locale, 'Delete')}><DeleteOutlined /></a>
                            </Space></td>
                        </tr>
                }
                )}
            </table>
            {selectedType &&
                <SlideModal
                    onClose={onCloseModal}
                    title={plainTranslate(props.settings.locale, editableField !== false ? "Edit" : 'Add new') + ' ' + plainTranslate(props.settings.locale, 'Category') + ' ' + plainTranslate(props.settings.locale, 'field')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <Form form={form} name="form_content_field" ref={formRef}
                                    onFinish={onFinish} autoComplete="off" id="content-field">
                                    
                                    {props.field.specification && Array.isArray(props.field.specification) && props.field.specification.map((item, key) => {
                                        
                                        return <>
                                            {item.type == 'text' &&
                                                <Form.Item name={item.id} label={item.id} rules={[{ required: item.required }]} hidden={item.hide}>
                                                    <Input defaultValue={editableValues ? editableValues[item.id] : null}/>
                                                </Form.Item>
                                            }
                                            {item.type == 'boolean' &&
                                                <Form.Item name={item.id} label={item.id} rules={[{ required: item.required }]}>
                                                    <Switch defaultChecked={editableValues ? editableValues[item.id] : null}/>
                                                </Form.Item>
                                            }
                                            {item.type == 'options' &&
                                                <Form.Item name={item.id} label={item.id} rules={[{ required: item.required }]}>
                                                    <Select options={item.options} showSearch filterOption={true} optionFilterProp="label" style={{ width: 130 }} defaultValue={editableValues ? editableValues[item.id] : null}>
                                                    </Select>
                                                </Form.Item>
                                            }
                                        </>
                                    })}
                                    <Form.Item>
                                        <Button
                                            htmlType="button"
                                            onClick={onSubmit} className="button-primary">
                                            {plainTranslate(props.settings.locale, "Save")}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }
        </React.Fragment>
    </div>
    );
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AttributeCategories);


