import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";

import BarcodeViewer from 'react-barcode';

import 'brace/mode/java';
import 'brace/theme/github';

class Barcode extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            displayValue: false
        };

        this.print = this.print.bind(this);
    }

    print() {
        this.setState({
            displayValue: true
        }, () => {

            let printContents = document.getElementById('barcode' + this.props.id).innerHTML;
            var printWindow = window.open('', 'PRINT', 'height=800,width=800');

            printWindow.document.write('<html><head><title>' + document.title + '</title>');
            printWindow.document.write('</head><body >');
            printWindow.document.write(printContents);
            printWindow.document.write('</body></html>');

            printWindow.document.close(); // necessary for IE >= 10
            printWindow.focus(); // necessary for IE >= 10*/

            printWindow.print();
            printWindow.close();

            this.setState({displayValue: false});
        });

    }

    render() {
        return <div>
            <div id={'barcode' + this.props.id} style={{float: 'left'}}>
                <BarcodeViewer value={this.props.value ? this.props.value : '0'}
                               width={2}
                               height={50}
                               displayValue={this.state.displayValue}
                               margin={0}
                />
            </div>
            <button type="button"
                    className="button-primary "
                    onClick={this.print}>
                {plainTranslate(this.props.settings.locale, 'Print')}
            </button>
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Barcode);
