import React from 'react';

export function Avatar(props) {
    if (props.type == "Email sent") {
        return (
            <i className="fa fa-envelope-o" aria-hidden="true"></i>

        );
    }
    if (props.type == "Email received") {
        return (
            <i className="fa fa-envelope-open-o" aria-hidden="true"></i>

        );
    }
    if (props.type == "Email") {
        return (
            <i className="fa fa-envelope-o" aria-hidden="true"></i>

        );
    }
    if (props.type == "Meeting") {
        return (
            <i className="fa fa-handshake-o" aria-hidden="true"></i>

        );
    }
    if (props.type == "Call") {
        return (
            <i className="fa fa-phone" aria-hidden="true"></i>

        );
    }

    return (
        <i className="fa fa-info-circle" aria-hidden="true"></i>
    );

}