import React, { Component } from 'react';

export default class CustomNewRenderer extends Component {
        
    constructor(props) {
         super(props);
        
          this.state = {
            value: this.props.value,
        };
    }

     render() {
        return (
            <span className={(this.props.data[this.props.column.colDef.cellClass] ? this.props.data[this.props.column.colDef.cellClass].replace(/ /g,'') : this.props.data[this.props.column.colDef.cellClass]) + ("pos" in this.props.data ? this.props.data['pos'] : '')}>{this.state.value}</span>
        );
    }
}        