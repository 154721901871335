import React, {Component} from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../common/translate';
import SlideModal from '../common/SlideModal';
import { Table, InputNumber, Button } from 'antd';
import FormLoader from "../forms/form-loader";
import client from '../../actions/client';
import showAToast from '../common/showAToast';

class MinQtyTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            isClicked: false
        };

        this.onChange = this.onChange.bind(this);
        this.generateTransfer = this.generateTransfer.bind(this);
    }

    componentDidMount() {
        let index = this.props.bulk.actions.findIndex(o => o.type === 'generateTransfer');
        let route = this.props.bulk.actions[index].route;

        let selectedRowsData = JSON.parse(JSON.stringify(this.props.selectedRowsData));

        if(this.props.selectAll === true){
            this.setState({showLoader: true});

            selectedRowsData = this.props.deSelectedRowsData;

            let visibleColumns = {};
            if(this.props.columnApi){
                let cols = this.props.columnApi.getAllDisplayedColumns();
                
                let i=0;
                cols.map((value) => {
                    if(value.colDef.field !== 'id' && value.colDef.field !== '0' && value.colDef.field && value.colDef.field.length > 0){
                        visibleColumns[i] = [];
                        visibleColumns[i].push(value.colDef.field);
                        visibleColumns[i].push(value.colDef.headerName);
                        visibleColumns[i].push(value.colDef.type);

                        i++;
                    }
                });
            }

            let filterOptions = { filter: this.props.filterOptions ? this.props.filterOptions : false };
            let quickFilter = { quickFilter: this.props.quickFilter ? this.props.quickFilter : this.props.gridProperties.quickFilter};

            client
            .post(route, {
                'body': selectedRowsData,
                'filterOptions': filterOptions,
                'startDate': this.props.gridProperties.startDate,
                'endDate': this.props.gridProperties.endDate,
                'quickFilter': quickFilter,
                'sortedCols': this.props.sortedCols['sortedCols'],
                'visibleColumns': visibleColumns,
                'selectAll': this.props.selectAll,
                'getEditTransferData': true
            })
            .then(response => response.data)
            .then(response => {
                this.setState({rows: response.data, route: route, showLoader: false});
            })
        }else{
            this.setState({rows: selectedRowsData, route: route});
        }
    }

    onChange(val,record){
        record.OrderQuantity = val;
    }

    generateTransfer(){
        this.setState({isClicked: true});

        client
        .post(this.state.route, {
            'body': this.state.rows,
            'selectAll': false,
            'isEditTransfer': true
        })
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
            }
            if ('success' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
                this.props.onClose();
            
                if (response.redirectUrl) {
                    if(response.newTab){
                        window.open(response.redirectUrl, '_blank');
                    }else{
                        window.location = response.redirectUrl;
                    }
                }
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    render() {

        const cols = [
            {
                'key': 'itemNo',
                'dataIndex' : 'itemNo',
                'title' : plainTranslate(this.props.settings.locale, 'Item No'),
            },
            {
                'key': 'itemTitle',
                'dataIndex' : 'itemTitle',
                'title' : plainTranslate(this.props.settings.locale, 'Item Title'),
            },
            {
                'key': 'StoreQuantity',
                'dataIndex' : 'StoreQuantity',
                'title' : plainTranslate(this.props.settings.locale, 'Store Quantity'),
            },
            {
                'key': 'StoreMinQuantity',
                'dataIndex' : 'StoreMinQuantity',
                'title' : plainTranslate(this.props.settings.locale, 'Store Min Quantity'),
            },
            {
                'key': 'MainStoreQuantity',
                'dataIndex' : 'MainStoreQuantity',
                'title' : plainTranslate(this.props.settings.locale, 'Main Store Quantity'),
            },
            {
                'key': 'MainStoreMinQuantity',
                'dataIndex' : 'MainStoreMinQuantity',
                'title' : plainTranslate(this.props.settings.locale, 'Main Store Min Quantity'),
            },
            {
                'key': 'StoreTransitQty',
                'dataIndex' : 'StoreTransitQty',
                'title' : plainTranslate(this.props.settings.locale, 'Store Transit Quantity'),
            },
            {
                'key': 'CorrectionOrderQty',
                'dataIndex' : 'CorrectionOrderQty',
                'title' : plainTranslate(this.props.settings.locale, 'Correction Order Quantity'),
            },
            {
                'key': 'OrderQuantity',
                'dataIndex' : 'OrderQuantity',
                'title' : plainTranslate(this.props.settings.locale, 'Order Quantity'),
                render: (text, record) => {
                    return(<InputNumber onChange={(val) => this.onChange(val,record)} defaultValue={record.OrderQuantity}/>)
                }
            },
        ];

        return(
            <SlideModal onClose={this.props.onClose} title={plainTranslate(this.props.settings.locale, 'Transfer Data')}>
                <section className="newPanel">
                    {!this.state.showLoader &&
                        <div className="panel-body">
                            <Table 
                                rowKey={record => record.id} 
                                columns={cols} 
                                dataSource={this.state.rows} 
                                pagination={{
                                  pageSize: 20
                                }}
                            />
                            <button className="button-primary" type="button" id="generateTransfer" disabled = {this.state.isClicked ? 'disabled' :  '' } className={"button-primary " + (this.state.isClicked ? 'disabled' : '')}
                                    onClick={this.generateTransfer} dis>
                                <Translate locale={this.props.settings.locale} value="Generate transfer"/>
                            </button>
                        </div>
                    }
                    {this.state.showLoader &&
                        <FormLoader/>
                    }
                </section>
            </SlideModal>
        )
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MinQtyTable);