import React from 'react';
import {connect} from 'react-redux';
import "../redux-forms/types/tableCollection.css";
import Text from "../redux-forms/types/text";
import {plainTranslate} from "../common/translate";
import {onUploadFileGetColumns} from "../../actions/actions";
import {Field as FormField, reduxForm, FieldArray} from 'redux-form';
import Select from 'react-select';
import Checkbox from '../settings/checkbox.jsx';
import {Link} from 'react-router-dom';
import client from '../../actions/client'

class PrintReceipt extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
    }

    handleClick(){

        client.post('http://localhost:8001/printers/ed326647/receipt', {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'content-type': 'application/json'
                },
                body: '{"uniqueSaleNumber": "DT279013-0001-0000001","items": [{"text": "Cheese","quantity": 1,"unitPrice": 12,"taxGroup": 2},{"type": "comment","text": "Additional comment to the cheese..."},{"text": "Milk","quantity": 2,"unitPrice": 10,"taxGroup": 2,"priceModifierValue": 10,"priceModifierType": "discount-percent"}],"payments": [{"amount": 30,                              "paymentType": "cash"                            }                          ]                        }'
            });
    }

    render() {

        return(
                <div>
                    <button onClick={this.handleClick}>Print</button>
                </div>
        )
        

    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({
   
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintReceipt);
