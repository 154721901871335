import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";
import client from '../../../actions/client';
import { withRouter } from 'react-router-dom';

class TranslationsComponent extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            value: '',
            transId: ''
        };

        this.onChange = this.onChange.bind(this);
        this.addNewTrans = this.addNewTrans.bind(this);
        this.removeTrans = this.removeTrans.bind(this);
    }
    
    componentDidMount() {
        this.setState({
            value: this.props.value ? this.props.value : '',
            transId: this.props.value[0].transId
        });
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value ? this.props.value : ''
            });
        }
    }

    onChange(e, type, key) {
        let translations = this.state.value;
        translations[key][type] = e.target.value;
         this.setState({
            translations
        });
        this.props.onChange('translations', translations);
    }
    
    addNewTrans() {
        let translations = this.state.value;
        let newTrans = {lang: 'en', trans: '', id: '', transId: this.state.transId};
        translations.push(newTrans);
        this.setState({
            translations
        });
    }
    
    removeTrans(key) {
        let translations = this.state.value;
        delete translations[key];
        this.setState({
            translations
        });
    }

    translateText(key){
        client.post('/' + this.props.match.params.db + '/api/translations/data/translate',{lang: this.state.value[key].lang, transId: this.state.transId})
        .then(response => response.data)
        .then(response => {
            let translations = this.state.value;
            translations[key].trans = response;

            this.props.onChange('translations', translations);
        })
    }

    render() {
        return <React.Fragment>
            {this.state.value && this.state.value.length > 0 && this.state.value.map((translation, key) =>
                        <React.Fragment key={key}>
                            <select className="form-control form-contacts" key={key + 'type'} value={translation.lang} onChange={(e) => this.onChange(e, 'lang', key)}>
                            {this.props.languages.length > 0 && this.props.languages.map((lang, key) =>
                                <option key={key} value={lang.value}>{plainTranslate(this.props.locale, lang.label)}</option>
                            )}    
                            </select>
                            <input className="form-control" style={{color: 'black'}} key={key + 'value'} value={translation.trans} onChange={(e) => this.onChange(e, 'trans', key)}/>
                            <i className="fa fa-lightbulb-o contact-icon" style={{marginRight: '-20px'}} aria-hidden="true" onClick = {() => this.translateText(key)} title={plainTranslate(this.props.locale, 'Generate translation')}></i>
                            <i className="fa fa-trash contact-icon" style={{marginRight: '-40px'}} aria-hidden="true" onClick = {() => this.removeTrans(key)} title={plainTranslate(this.props.locale, 'Remove translation')}></i>
                            <br/>
                        </React.Fragment>
                        )}
                <a className="button-outline grey " onClick = {this.addNewTrans}>{plainTranslate(this.props.locale, 'Add new translation')}</a>
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    });

const mapDispatchToProps = dispatch => ({
    });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TranslationsComponent));
