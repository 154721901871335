import React from 'react';
import { connect } from 'react-redux';
import SimpleForm from "../../../forms/form";
import SlideModal from '../../../common/SlideModal';
import { ACCOUNT_ADD_NEW_FILE } from "../../../../constants/actionTypes";
import Form from "../../../redux-forms/form";
import { withRouter } from 'react-router-dom';
import Translate, { plainTranslate } from '../../../common/translate';
import { Table, Dropdown, Upload, Menu, Select, Row, Button, Pagination } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import client from '../../../../actions/client';
import { getFolderOptions, getDocuments, uploadFiles, createFolder, loadUpload } from '../../../../actions/documentsActions';
import { onTabsLoad } from '../../../../actions/actions';
import showAToast from '../../../common/showAToast';

const { Option } = Select;

class Documents extends React.Component {
    constructor() {
        super();
        this.state = {
            isNew: false,
            isFile: false,
            fileList: [],
            folder: 'all',
            pageFiles: 1,
            pageFolders: 1,
            uploadModal: false, 
            uploadLoading: false,
            contractModal: false
        }
        this.handleUpload = this.handleUpload.bind(this);
    }

    updateTable = () => {
        this.props.onTabsLoad('/' + this.props.match.params.db + '/api/accounts/tabs/' + this.props.accountId, 1, false);
        this.setState({ contractModal: false });
    }

    handleUpload(){
        if(this.state.fileList){
            var body = new FormData();
            this.state.fileList.map(file => (
                    body.append('files[]', file.originFileObj)
            ))
            
            body.append('type', 'accounts');
            body.append('id', this.props.accountId);
            body.append('isPublicFile', this.state.isPublicFile);
            this.setState({uploadLoading: true});
            client
            .post('/'+this.props.match.params.db+"/api/storage/files/upload", body, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                 showAToast('Upload success!', 'successUpload', 'success');

                this.setState({fileList: [], uploadModal: false, uploadLoading: false, isPublicFile: false})
                this.props.onTabsLoad('/' + this.props.match.params.db + '/api/accounts/tabs/' + this.props.accountId, 1, false);
            })
            .catch(err => {
                showAToast("Error!", 'baseError', 'error')
            });
        }
     }

    handlePreview = (file) => {
        if (!file.url && !file.preview) {
            file.preview = URL.createObjectURL(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    }

    handleChange = ({ fileList }) => {
        this.setState({ fileList });
    }

    dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };

    closePopup(){
        this.setState({isFile:false, isPublicFile: false});
        this.props.onTabsLoad('/' + this.props.match.params.db + '/api/accounts/tabs/' + this.props.accountId, 1, false);
    }

    render() {
        const { fileList } = this.state;
        const uploadButton = (
            <div>
                <i className="fa fa-file-o" aria-hidden="true"></i>
                <div style={{ marginTop: 8 }}>{plainTranslate(this.props.settings.locale, 'New file')}</div>
            </div>
        );
        let menuOptions = [];
        menuOptions = (
            <Menu>
                <Menu.Item key='file'>
                    <a to="#" onClick={() => this.setState({ isFile: true, isNew: 'undefined' })}>
                        <Translate locale={this.props.settings.locale} value="Save file link" />
                    </a>
                </Menu.Item>
                <Menu.Item key='uploadPublic'>
                    <a to="#" onClick={() => this.setState({ uploadModal: true, isPublicFile: true })}>
                        <Translate locale={this.props.settings.locale} value="Upload public file" />
                    </a>
                </Menu.Item>
                <Menu.Item key='uploadPrivate'>
                    <a to="#" onClick={() => this.setState({ uploadModal: true })}>
                        <Translate locale={this.props.settings.locale} value="Upload private file" />
                    </a>
                </Menu.Item>
                <Menu.Item key='upload'>
                    <a to="#" onClick={() => this.setState({ contractModal: true })}>
                        <Translate locale={this.props.settings.locale} value="New contract" />
                    </a>
                </Menu.Item>
            </Menu>
        );
        return (
            <div>
                {
                    this.props.dataDocuments &&
                    <React.Fragment>
                        <div className="btn-round btn-blue">
                            <Dropdown overlay={menuOptions} placement="bottomCenter" >
                                <PlusOutlined />
                            </Dropdown>
                        </div>
                        <Table
                            rowKey={record => record.id}
                            columns={this.props.columnsDocuments}
                            dataSource={this.props.dataDocuments}
                            pagination={false}
                            rowClassName={"account-profile-tabTable"}
                            size="small"
                        />
                        <Pagination
                            total={this.props.total}
                            showSizeChanger={false}
                            onChange={this.props.changePage}
                            style={{ margin: "15px 0" }}
                        />
                    </React.Fragment>
                }
                {this.state.uploadModal &&
                    <SlideModal onClose={() => this.setState({ uploadModal: false, isPublicFile: false })} title={plainTranslate(this.props.settings.locale, 'File upload')}>
                        <div id="reactForm">
                            <div class="row m-bot15 cmxform form-horizontal bucket-form ng-pristine ng-valid">
                                <div class="col-lg-12">
                                    <section class="panel">
                                    <div class="panel-body">
                                        <Row>
                                            <Upload
                                                listType="picture-card"
                                                fileList={fileList}
                                                onPreview={this.handlePreview}
                                                onChange={this.handleChange}
                                                multiple={true}
                                                style={{ display: "flex" }}
                                                customRequest={this.dummyRequest}
                                                accept="application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/plain, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text,  image/png, image/jpeg, image/svg+xml, image/gif, image/vnd.microsoft.icon, application/json, text/html, text/css"
                                            >
                                                {fileList.length >= 8 ? null : uploadButton}
                                            </Upload>
                                            <Button
                                                type="button"
                                                className="button-outline grey pull-left"
                                                onClick={this.handleUpload}
                                                loading={this.state.uploadLoading}
                                                disabled={fileList.length === 0}
                                                style = {{marginLeft: '0'}}
                                            >
                                                {this.state.uploadLoading ? <Translate locale={this.props.settings.locale} value="Uploading" /> : <Translate locale={this.props.settings.locale} value="Start Upload" />}
                                            </Button>
                                        </Row>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </SlideModal>
                }
                {
                    this.state.isFile &&
                    <SlideModal onClose={() => this.setState({ isFile: false, isNew: false })} title={plainTranslate(this.props.settings.locale, 'Save file link')}>
                        <section className="newPanel">
                            <SimpleForm
                                formRoute={this.props.match.params.db + '/api/files/form'}
                                saveRoute={this.state.isNew !== 'undefined' ? this.props.match.params.db + '/api/files/edit' : this.props.match.params.db + '/api/files?accountId=' + this.props.accountId}
                                match={{ params: { id: 0 } }}
                                updateParent={() => this.closePopup()}
                                onCancel={() => this.setState({ isFile: false, isNew: false })}
                                popup={true}
                                isNew={true}
                            />
                        </section>
                    </SlideModal>
                }
                {this.state.contractModal &&
                    <SlideModal onClose={() => this.setState({ contractModal: false })} title={plainTranslate(this.props.settings.locale, 'New contract')}>
                        <div id="reactForm">
                            <div class="row m-bot15 cmxform form-horizontal bucket-form ng-pristine ng-valid">
                                <div class="col-lg-12">
                                    <section class="panel">
                                        <div class="panel-body">
                                            <Form
                                                formRoute={this.props.match.params.db + '/api/contracts/'}
                                                saveRoute={this.props.match.params.db + '/api/contracts'}
                                                refs={this.props.accountId}
                                                popup={true}
                                                onSuccessfulSave={(data) => this.updateTable(data)}
                                                match={{ params: { id: 0 } }}
                                                onCancel={() => this.setState({ contractModal: false })}
                                                form={'initializeFromState'}
                                                isNew={true}
                                            />
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </SlideModal>
                }
            </div>
        );
    }
}


const mapStateToProps = state => ({
    settings: state.settings,
    documents: state.documents
});
const mapDispatchToProps = dispatch => ({
    onAddNewFile: (data) => dispatch({ type: ACCOUNT_ADD_NEW_FILE, payload: { dataFile: data } }),
    getFolderOptions: () => dispatch(getFolderOptions()),
    getDocuments: (type, pageFiles, pageFolders, id) => dispatch(getDocuments(type, pageFiles, pageFolders, id)),
    uploadFiles: (form) => dispatch(uploadFiles(form)),
    createFolder: (db, name, parentId) => dispatch(createFolder(db, name, parentId)),
    loadUpload: () => dispatch(loadUpload()),
    onTabsLoad: (route, page, requiredTotal) => dispatch(onTabsLoad(route, page, requiredTotal))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Documents));
