import React from 'react';
import LedgerData from './ledgerData';
import LedgerLine from './ledgerLine';
import SaveCancel from '../../common/saveCancelButtons';
import Translate, { plainTranslate } from '../../common/translate';
import client from './../../../actions/client';
import { withRouter } from 'react-router-dom';
import { Link} from 'react-router-dom';
import showAToast from './../../common/showAToast';

class LedgerComponent extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
            total: 0,
            validationMsg: "(The sum is valid)",
            enableSave: false,
            settings: []
        };
    this.handleChange = this.handleChange.bind(this);
    this.addNewLine = this.addNewLine.bind(this);
    this.save = this.save.bind(this);
    this.updateData = this.updateData.bind(this);
    this.updateRemoveLines=this.updateRemoveLines.bind(this);
    this.updateLinesData=this.updateLinesData.bind(this);
    }

    componentDidMount() {
        this.loadAccountingSettings();
        this.loadLedger();
        this.loadJournals();
    }
    
    loadAccountingSettings() {
        client.post('/' + this.props.match.params.db + '/api/accounting/settings/analysis')
        .then(response => response.data)
        .then(response => {
            let result = null;
            try {
                result = JSON.parse(response.data);
              }
              catch(e) {
                console.log(e)
              }
            this.setState({
                acc_analisys: result
            });
        });
    }
    
    checkTotal() {
        if (this.state.ledger && this.state.ledger.lines) {
        let total = 0;
                let validationMsg = '';
                for (let i = 0; i < this.state.ledger.lines.length; i++) {
                    if (this.state.ledger.lines[i].debitCredit) {
                        total += (this.state.ledger.lines[i].amountOriginal*this.state.ledger.lines[i].currencyRate);
                    } 
                };
                if (total == 0) {
                    validationMsg = "(The sum is valid)";
                } else {
                    validationMsg = "(The sum is invalid)";
                }
                this.setState({
                    total: total.toFixed(2),
                    validationMsg: validationMsg,
                    enableSave: total == 0 ? true : false
                });
            }
    }
    
    loadLedger() {
        client.post(this.props.match.params.id ? this.props.match.params.db + '/api/accounting/ledger/find/' + this.props.match.params.id : this.props.match.params.db + '/api/accounting/ledger/find/new')
        .then(response => response.data)
        .then(response => {
            this.setState({
                ledger: response,
                ledgerId: response.id ? response.id : null,
            });
            
            this.loadJournalSettings(response.journal);
            this.checkTotal();
        });
    }
    
    loadJournals() {
        client.post('/' + this.props.match.params.db + '/api/v1/accounting/ledger/journals/find')
        .then(response => response.data)
        .then(response => {
            this.setState({
                journals: response,
            });
        });
    }
    
    handleChange(property, e) {
        let ledger = this.state.ledger;
        
            let value = e.target.value;
            ledger[property] = value;   

        this.setState({
            ledger
        });
    }
    
    addNewLine(){
        let ledger = this.state.ledger;
        var st = ledger.date;
        var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
        var dt = (st.replace(pattern,'$3-$2-$1'));

        let line = {
            id:'new',
            reference:'',
            description:'',
            date:dt,
            billingData:'',
            account:'',
            debitCredit:'D',
            amountLocal:'',
            amountOriginal:'',
            currency:'',
            currencyRate:'', 
            settings:[]
        };

        ledger.lines.push(line);

        this.setState({
            ledger
        });
    }
 
    save(){
        client.post(this.props.match.params.id ? '/' + this.props.match.params.db + '/api/accounting/ledger/save/' + this.props.match.params.id : this.props.match.params.db + '/api/accounting/ledger/save/new',  {
            ledger: this.state.ledger
        })
        .then(response => response.data)
        .then(response => {
            showAToast(response.message, 'baseSuccess', 'success');
        });
    }
    
    updateData(prop, value) {
        let ledger = this.state.ledger;
        ledger[prop] = value;

        this.setState({ledger});
        this.checkTotal();
        if (prop == 'journal') {
            this.loadJournalSettings(value);
        }
    }
    updateRemoveLines(ind) {
        let ledger = this.state.ledger;
        ledger.lines.splice(ind-1, 1);
        this.setState({
            ledger
        });
        this.checkTotal();
    }
    updateLinesData(prop, value, ind){
        let lines = this.state.ledger.lines;
        lines[ind][prop] = value;
        this.setState({lines}); 
        this.checkTotal();
    }
    
    loadJournalSettings(value) {
        client.post('/' + this.props.match.params.db + '/api/v1/accounting/ledger/journal/'+value+'/find/settings')
        .then(response => response.data)
        .then(response => {
            this.setState({
                settings: response
            });
        });
    }

    render() {
        return (
                <div>
                    <div class="dashboard"><div class="moduleSubMenu backMenu"><div class="col-sm-12"><h3 class="pull-left"><Link to={'/'+ this.props.match.params.db + '/accounting/ledger'}><i class="ion-ios-arrow-back"></i></Link> Ledger</h3></div></div></div>
                <form className="constrain-width custom-panel cmxform form-horizontal bucket-form ng-pristine ng-valid">
                    <div className="row m-bot15">
                        <div className="col-lg-12">
                            <section className="panel">
                                <div className="panel-body">
                                    <div className="ledger">
                                    <div className="panel invoice-date">
                                        <LedgerData
                                            locale={this.props.locale}
                                            db={this.props.match.params.db}
                                            ledger={this.state.ledger}
                                            ledgerId={this.props.match.params.id}
                                            journals={this.state.journals}
                                            update={this.updateData}             
                                            />
                                    </div>
                                        <div className="row invoice-table">
                                            <div className="panel-body">
                                                <section id="no-more-tables">
                                                    <div className="table-inbox-wrap">
                                                        <table className="table table-hover table-condensed cf">
                                                            <thead className="cf grid_header">
                                                            <tr>
                                                                    <th className="text-center">&nbsp;</th>
                                                                    <th className="text-left"><Translate locale={this.props.locale} value="No."/></th>
                                                                    {this.state.settings && this.state.settings.includes('reference') &&
                                                                        <th className="text-left"><Translate locale={this.props.locale} value="Reference"/></th>
                                                                    }
                                                                    {this.state.settings && this.state.settings.includes('date-line') &&
                                                                        <th className="text-left"><Translate locale={this.props.locale} value="Date"/></th>
                                                                    }
                                                                    <th className="text-left"><Translate locale={this.props.locale} value="Account"/></th>
                                                                    <th className="text-left"><Translate locale={this.props.locale} value="Amount Original"/></th>
                                                                    {this.state.settings && this.state.settings.includes('multy-currency') &&
                                                                        <th className="text-left"><Translate locale={this.props.locale} value="Currency"/></th>
                                                                    }
                                                                    <th className="text-left"><Translate locale={this.props.locale} value="Rate"/></th>
                                                                    <th className="text-left"><Translate locale={this.props.locale} value="Amount Local"/></th>
                                                                    <th className="text-left"><Translate locale={this.props.locale} value="Debit or Credit"/></th>
                                                                    {this.state.settings && this.state.settings.includes('account-billing') &&
                                                                        <th className="text-left"><Translate locale={this.props.locale} value="Billing Data"/></th>
                                                                    }
                                                                    {(this.state.acc_analisys)
                                                                    && this.state.acc_analisys.map((item, key) => (
                                                                        <th className="text-left"><Translate locale={this.props.locale} value={item.name}/></th>
                                                                    ))} 
                                                                    {this.state.settings && this.state.settings.includes('comment-line') &&
                                                                        <th className="text-left"><Translate locale={this.props.locale} value="Description"/></th>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody className="ui-sortable">
                                                            {(this.state.ledger && this.state.ledger.lines)
                                                                    && this.state.ledger.lines.map((item, key) => (
                                                                <LedgerLine
                                                                    locale={this.props.locale}
                                                                    db={this.props.match.params.db}
                                                                    settings={this.state.settings}
                                                                    ledgerLine={item}
                                                                    acc_analysis={this.state.acc_analisys}
                                                                    ind={key+1}
                                                                    update={this.updateRemoveLines}
                                                                    updateLines={this.updateLinesData}        
                                                                    />
                                                               ))}                
                                                            </tbody>
                                                            <tfoot>
                                                                <tr className="text-center">
                                                                    <th colSpan="17">
                                                                        <button className="button-outline grey text-uppercase" 
                                                                        type="button" id="ims-add-line"
                                                                        onClick={() => this.addNewLine()}
                                                                        >Add new row 
                                                                        </button>
                                                                    </th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </section>
                                            </div>
                                         </div>
                                         <div className="row">
                                            <div className="col-sm-6">
                                            {this.state.settings && this.state.settings.includes('comment') &&
                                                <div className="panel table payment">
                                                    <div className="col-sm-12">
                                                        <h5 className="text-uppercase text-bold"><Translate locale={this.props.locale} value="Description"/></h5>
                                                    </div>
                                                    <div className="col-sm-12" id="inv_payment_method">
                                                        <div className="row m-bot15">
                                                            <div className="form-group">
                                                                <div className="col-sm-12">
                                                                    <textarea id="ledger_description" className="form-control"
                                                                    value={this.state.ledger ? this.state.ledger.desc : null} 
                                                                    onChange={(e) => this.handleChange('desc', e)}>  
                                                                    </textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="panel total">
                                                    <div className="row bottom">
                                                        <div className="col-xs-7 col-sm-7">
                                                            <h3><Translate locale={this.props.locale} value="Total Sum"/></h3>
                                                        </div>
                                                        <div className="col-xs-5 col-sm-5">
                                                            <h3 id="ledger_total_sum" className="text-right text-bold">{this.state.total}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12">
                                                        <h4 id="err_msg" className="text-right"><Translate locale={this.props.locale} value={this.state.validationMsg}/></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </form>
                <div className="col-md-12 col-xs-12">
                        <div className="pull-right">
                            <SaveCancel
                                locale={this.props.locale}
                                enableSave={this.state.enableSave}
                                save={this.save}/>
                        </div>
                    </div>
                </div>
                );
    }
}

export default withRouter(LedgerComponent);