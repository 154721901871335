import React, { useEffect, useState } from 'react';
import Translate, { plainTranslate } from '../../../common/translate';
import { connect } from 'react-redux';
import { onAccountFetchData } from "../../../../actions/actions";
import SimpleForm from "../../../forms/form";
import SlideModal from '../../../common/SlideModal';
import Popup from '../../../common/popup';
import { withRouter } from 'react-router-dom';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Card, Table } from 'antd';

const AccountAttributes = (props) => {
    const [value, setValue] = useState([]);
    const [popup, setPopup] = useState(false);

    useEffect(() => {
        setValue(props.value ? props.value : null);
    }, [])

    const getDataValue = () => {
        const columns = [
            {
                title: plainTranslate(props.settings.locale, 'Category'),
                dataIndex: 'category',
                key: 'category'
            },
            {
                title: plainTranslate(props.settings.locale, 'Value'),
                dataIndex: 'value',
                key: 'value'
            },
        ];
        return (
            <Table dataSource={props.value} columns={columns} pagination={{ pageSize: 5 }} rowKey={record => record.id} scroll={{ x: 'max-content' }}/>
        );
    }

    const getNoData = () => {
        return (
            <div className="div-inline m-left15"><span
                className="text-italic" style={{ color: '#a0a0a0' }}>{plainTranslate(props.settings.locale, 'No account attributes added.')}</span></div>
        );
    }

    const onSuccessfulSave = () => {
        props.onAccountFetchData(props.db, props.accountId, false);
        setPopup(false);
    }

    const closePopup = () => {
        setPopup(false);
    }

    return (
        <Card
            size="small"
            style={{ marginTop: 10 }}
            title={plainTranslate(props.settings.locale, 'Attributes')}>
            <div className="account-profile-other-information-more">
                <React.Fragment>
                    {props.value ? getDataValue() : getNoData()}
                </React.Fragment>
            </div>
        </Card>
    );
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onAccountFetchData: (db, id, start) => dispatch(onAccountFetchData(db, id, start)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountAttributes));