import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import { fetchEmployee } from "../../../actions/actions";
import GeneralInformation from './generalInformation';
import AdditionalInformation from './additionalInformation';
import {Link} from 'react-router-dom';

class Profile extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
         if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
        this.props.loadEmployee(this.props.match.params.id, this.props.match.params.db);
    }

    render() {
        if (this.props.settings.error) {
            return <div><Translate locale={this.props.settings.locale} value="Error "/> {this.props.settings.error.message}</div>;
        }

        if (this.props.settings.loading) {
            return <div><Translate locale={this.props.settings.locale} value="Loading..."/></div>;
        }
        return (
                <div>
                    {this.props.employeeProfile && this.props.employeeProfile.employee &&
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                            <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb">
                            {this.props.frontend ? (
                                    ''
                                    ) : (
                                    <Link to={"/" + this.props.match.params.db + "/hr/employees"}
                                            >
                                          <i className="ion-ios-arrow-back"></i><Translate locale={this.props.settings.locale} value="Employees"/>
                                    </Link>
                                    )}
                            </div>
                            <div className="col-lg-4 col-md-4 col-xs-12 no-gutter">
                                <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information">
                                    <div className="account-profile-information-general">
                                        <GeneralInformation 
                                            frontend = {this.props.frontend}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-xs-12 no-gutter-m no-gutter-r">
                                <div className="col-lg-12 col-md-12 col-xs-12 account-profile-tabs no-m">
                                        <AdditionalInformation
                                            frontend = {this.props.frontend}
                                        />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                );
    }
}

const mapStateToProps = state => ({
        settings: state.settings,
        employeeProfile: state.employeeProfile
    });

const mapDispatchToProps = dispatch => ({
        loadEmployee: (employeeId, db) =>
            dispatch(fetchEmployee(employeeId, db))
    });

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

