import React, { useEffect, useState } from 'react';
import { plainTranslate } from "../../common/translate";
import SlideModal from '../../common/SlideModal';
import { Button, Form, Input, Space } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import client from '../../../actions/client';

const Dropdown = props => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const formRef = React.createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        setOptions(props.field.options);
    }, [])

    const handleChange = (e, type) => {
        if (e.target.value == 'addOption') {
            //setOptions(props.field.options);
            form.setFieldsValue({ options: options });
            setOpen(type);
        }
    }

    const onFinish = (values) => {
        setOptions(values.options);
        props.onChangeMainOptions(props.field.id, values.options, props.field.id);
        props.change(props.field.id, values.options[values.options.length-1].value)
        setOpen(false);
        client.post(props.field.saveRoute, {
            body: values,
            type: open
        });
    }

    const onSubmit = () => {
        formRef.current.submit()
    }

    return (
        <>
            <select
                disabled={props.field.disabled}
                className="form-control"
                onClick={(e) => handleChange(e, props.field.id)}
                {...props.input}>
                {props.field.settings && props.field.settings.emptyOption &&
                    <option></option>
                }
                {
                    options.map((option, key) => {
                        return <option value={option.value} key={key}>{option.label}</option>
                    })
                }
                {props.field.addOption &&
                    <option value={'addOption'}>+ {plainTranslate(props.locale, 'Add option')}</option>
                }
            </select>
            {open &&
                <SlideModal onClose={() => setOpen(false)} title={plainTranslate(props.locale, 'Add option')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <>
                                <Form form={form} name="form_content_field" ref={formRef}
                                    onFinish={onFinish} autoComplete="off" id="content-field">
                                    <h4>{plainTranslate(props.locale, 'Options')}</h4>
                                    <Form.List name="options">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'value']}
                                                            rules={[{ required: true, message: 'Missing value' }]}
                                                        >
                                                            <Input placeholder="Value" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'label']}
                                                            rules={[{ required: true, message: 'Missing label' }]}
                                                        >
                                                            <Input placeholder="Label" />
                                                        </Form.Item>
                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                    </Space>
                                                ))}
                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        {plainTranslate(props.locale, 'Add option')}
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                    <Form.Item>
                                        <Button
                                            htmlType="button"
                                            onClick={onSubmit} className="button-primary">
                                            {plainTranslate(props.locale, "Save")}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </>
                        </div>
                    </section>
                </SlideModal>
            }
        </>
    )
};

export default Dropdown; 