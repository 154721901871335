import {
    FORM_DATA_LOAD,
    FORM_FIELD_UPDATE,
    IMAGE_UPLOAD_LOADING,
    IMAGE_UPLOAD_SUCCESSFUL,
    IMAGES_UPLOAD_SUCCESSFUL,
    FILE_UPLOAD_SUCCESSFUL,
    FORM_REMOVE_IMAGE
} from "../constants/actionTypes";

export default (state = [], action) => {
    const formId = action.payload ? action.payload.formId : -1;
    let form = formId in state ? state[formId] : {};
    let images = [];
    let imagesF = [];
    switch (action.type) {
        case FORM_DATA_LOAD:
            return {
                ...state,
                [formId]: action.payload.data && action.payload.data.length > 0 || Object.keys(action.payload.data).length > 0 ? action.payload.data : {}
            };
        case FORM_FIELD_UPDATE:
            form[action.payload.key] = action.payload.value;
            return {
                ...state,
                [formId]: form
            };

        case IMAGE_UPLOAD_SUCCESSFUL:
            form[action.payload.id] = action.payload.image;
            return {
                ...state,
                [formId]: form
            };
        case IMAGES_UPLOAD_SUCCESSFUL:
            images = state[action.payload.formId] ? state[action.payload.formId][action.payload.id] : [];
            let currentForm = state[action.payload.formId];
            
            if(typeof currentForm === 'undefined'){
                return {
                    ...state,
                };    
            }else{
            if (images && images.length > 0) {
                images = images.concat(action.payload.images);
            } else {
                images = action.payload.images;
            }
            currentForm[action.payload.id] = images;  
            return {
                ...state,
                [formId]: currentForm
            };
        }
        case FILE_UPLOAD_SUCCESSFUL:
            form[action.payload.id] = action.payload.file;
            return {
                ...state,
                [formId]: form
            };

        case FORM_REMOVE_IMAGE:
            images = state[action.payload.formId][action.payload.fieldId];
            currentForm = state[action.payload.formId]; 
            currentForm[action.payload.fieldId] = images.filter(id => id == action.payload.id); 
            return {
                ...state,
                [formId]: currentForm
            };
        default:
            return state;
    }
};