import React from 'react';
import { connect } from 'react-redux';
import { plainTranslate } from "../../common/translate";
import { searchItem } from "../../../actions/actions";
import SlideModal from '../../common/SlideModal';
import 'antd/lib/tree/style/index.css';
import { Tree } from 'antd';
import { StarOutlined, HomeOutlined, DatabaseOutlined, TagOutlined } from '@ant-design/icons'
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';

const { TreeNode, DirectoryTree } = Tree;

class TreeView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            treeData: [
            ]
        };
        this.selectItem = this.selectItem.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.onQuantityChange = this.onQuantityChange.bind(this);
    }

    componentDidMount() {
        if (this.props.treeUrl) {
            client.post(this.props.treeUrl, {
                'locations': false,
                'type': 'location',
                'locId': this.props.formData.locId,
                'locIdFrom': this.props.formData.locIdFrom,
            })
                .then(response => response.data)
                .then(response => {
                    this.setState({ treeData: response });
                })
        }
    }

    onLoadData(treeNode) {
        if (treeNode.props.dataRef.type === "location") {
            this.setState({ locId: treeNode.props.dataRef.id })
        }
        if (treeNode.props.dataRef.type === "item") {
            this.setState({ itemId: treeNode.props.dataRef.id })
        }

        return client
        .post(this.props.treeUrl, {
            'type': treeNode.props.dataRef.type,
            'id': treeNode.props.dataRef.id,
            'locId': this.props.formData.locId,
            'locIdFrom': this.props.formData.locIdFrom,
            'stockAvailability': this.props.stockAvailability,
            'docType': this.props.docType,
            'documentDate': this.props.formData.documentDate ? this.props.formData.documentDate : null,
            'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
            'accId': this.props.formData.accId ? this.props.formData.accId : null,
            'showBatches': this.props.formData.hasOwnProperty('priority') ? false : true
        })
            .then(response => response.data)
            .then(response => {                
                treeNode.props.dataRef.childrenData = response;
               
                this.setState({
                    treeData: [...this.state.treeData],
                });
            });
    }

    handleCheckboxChange(e) {
        let stockAvailability = this.state.stockAvailability;

        if (e.target.name === "stockAvailability") {
            stockAvailability = e.target.checked;
        }
        this.setState({
            stockAvailability: stockAvailability,
        });

        client.post(this.props.treeUrl, {
            'locations': true,
        })
            .then(response => response.data)
            .then(response => {
                this.setState({ treeData: response });

            })
    }

    openPopup(selectedKeys, info) {
        if (info.node.props.dataRef.type === 'batch' || (info.node.props.dataRef.type === 'item' && info.node.props.isLeaf) || (info.node.props.dataRef.type === 'item' && this.props.formData.hasOwnProperty('priority'))) {
            if (info.node.props.dataRef.hasOwnProperty('id')) {
                this.setState({ isOpen: true, selectedItem: info });
            } else {
                this.setState({ isOpen: false, selectedItem: null });
            }
        }
    }
    onQuantityChange(e) {
        this.setState({ quantity: e.target.value });
    }

    showPopup() {
        if (((Array.isArray(this.props.disabledAvailabilityStage) && this.props.disabledAvailabilityStage.includes(this.props.formData.stageId))) || (this.props.checkAvailabilityForm && this.state.selectedItem.node.props.dataRef.quantity > 0) || (this.props.checkAvailabilityForm && this.state.selectedItem.node.props.dataRef.negativeQty == 1) || !this.props.checkAvailabilityForm) {
            if (window.innerWidth > 780) {
                return (
                    //<SlideModal onClose={() => this.setState({isOpen: false, selectedItem: null, quantity: null})} title={plainTranslate(this.props.settings.locale, 'Item')}>

                    <section className="newPanel" style={{ borderRadius: 4, border: "1px solid #e5e5e5", margin: 20, padding: 10, position: "fixed", maxWidth: 350 }}>
                        <div className="panel-body" style={{ width: "100%" }}>
                            <div style={{ marginBottom: 10 }}>
                                <label style={{ marginRight: 5 }}>{plainTranslate(this.props.settings.locale, 'Item')}:</label>
                                <span style={{ fontSize: 15 }}>{this.state.selectedItem.node.props.dataRef.itemName ? this.state.selectedItem.node.props.dataRef.itemName : this.state.selectedItem.node.props.dataRef.name}</span>
                            </div>
                            <div style={{ marginBottom: 10 }}>
                                <label style={{ marginRight: 5 }}>{plainTranslate(this.props.settings.locale, 'Batch')}:</label>
                                {this.state.selectedItem.node.props.dataRef.itemName &&
                                    <span style={{ fontSize: 15 }}>{this.state.selectedItem.node.props.dataRef.name === null ? plainTranslate(this.props.settings.locale, 'Without batch') : this.state.selectedItem.node.props.dataRef.name}</span>
                                }
                                {this.state.selectedItem.node.props.dataRef.name &&
                                    <span style={{ fontSize: 15 }}>-</span>
                                }
                            </div>
                            <div className="form-treeview-quantity" style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
                                <label style={{ marginRight: 5 }} for="quantity">{plainTranslate(this.props.settings.locale, this.props.formData.hasOwnProperty('priority') ? 'Minimum Quantity' : 'Quantity')}</label>
                                <input value={this.state.quantity} onChange={this.onQuantityChange} type="number" id="quantity"></input>
                            </div>
                            <button type="button" className="button-primary" onClick={() => this.selectItem(this.state.selectedItem)}>{plainTranslate(this.props.settings.locale, 'Add')}</button>
                        </div>
                    </section>
                );
            } else {
                return (
                    <SlideModal onClose={() => this.setState({ isOpen: false, selectedItem: null, quantity: null })} title={plainTranslate(this.props.settings.locale, 'Item')}>

                        <section className="newPanel" style={{ borderRadius: 4, border: "1px solid #e5e5e5", margin: 20, padding: 10, position: "fixed", maxWidth: 350 }}>
                            <div className="panel-body" style={{ width: "100%" }}>
                                <div style={{ marginBottom: 10 }}>
                                    <label style={{ marginRight: 5 }}>{plainTranslate(this.props.settings.locale, 'Item')}:</label>
                                    <span style={{ fontSize: 15 }}>{this.state.selectedItem.node.props.dataRef.itemName}</span>
                                </div>
                                <div style={{ marginBottom: 10 }}>
                                    <label style={{ marginRight: 5 }}>{plainTranslate(this.props.settings.locale, 'Batch')}:</label>
                                    <span style={{ fontSize: 15 }}>{this.state.selectedItem.node.props.dataRef.name === null ? plainTranslate(this.props.settings.locale, 'Without batch') : this.state.selectedItem.node.props.dataRef.name}</span>
                                </div>
                                <div className="form-treeview-quantity" style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
                                    <label style={{ marginRight: 5 }} for="quantity">{plainTranslate(this.props.settings.locale, 'Quantity')}</label>
                                    <input onChange={this.onQuantityChange} type="number" id="quantity"></input>
                                </div>
                                <button type="button" className="button-primary" onClick={() => this.selectItem(this.state.selectedItem)}>{plainTranslate(this.props.settings.locale, 'Add')}</button>
                            </div>
                        </section>
                    </SlideModal>
                );
            }
        }
    }

    selectItem(info) {
        let hasBatch = true;
        let refresh = false;
        let index;
        let action = this.props;
        client
        .post(this.props.treeFindItem, {
            body: info.node.props.dataRef.type === 'item' ? info.node.props.dataRef.id : info.node.props.dataRef.itemId,
            batchId: info.node.props.dataRef.type === 'batch' ? info.node.props.dataRef.id : null,
            locId: this.props.formData.locIdFrom ? this.props.formData.locIdFrom : this.props.formData.locId,
            custId: this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
            accId: this.props.formData.accId ? this.props.formData.accId : null,
            documentDate: this.props.formData.documentDate ? this.props.formData.documentDate : null,
            stockAvailability: this.state.stockAvailability,
            channel: this.props.formData.channel ? this.props.formData.channel : null,
            docType: this.props.formData.documentType ? this.props.formData.documentType : null
        })
            .then(item => item.data)
            .then(item => {
                if ((Array.isArray(this.props.disabledAvailabilityStage) && !this.props.disabledAvailabilityStage.includes(this.props.formData.stageId)) && this.props.checkAvailabilityForm && item.checkQuantity && item.hasOwnProperty('batchName') && item.batchName === null && this.state.quantity > Number(item.stockAvailability) && item.negativeQty == 0) {
                    let qty = Number(item.stockAvailability);
                    if (qty < 0) {
                        qty = 0;
                    }
                    this.setState({ quantity: qty });
                    showAToast(plainTranslate(this.props.settings.locale, 'Insufficient quantity'), 'errSettings', 'error');
                } else if ((Array.isArray(this.props.disabledAvailabilityStage) && !this.props.disabledAvailabilityStage.includes(this.props.formData.stageId)) && this.props.checkAvailabilityForm && item.checkQuantity && item.hasOwnProperty('batchName') && item.batchName !== null && (this.state.quantity > Number(item.availableQuantity) || this.state.quantity > Number(item.stockAvailability)) && item.negativeQty == 0) {
                    let qty = Number(item.stockAvailability);
                    if (item.availableQuantity < item.stockAvailability) {
                        qty = Number(item.availableQuantity);
                    } else {
                        qty = Number(item.stockAvailability);
                    }
                    if (qty < 0) {
                        qty = 0;
                    }
                    this.setState({ quantity: qty });
                    showAToast(plainTranslate(this.props.settings.locale, 'Insufficient quantity'), 'errSettings', 'error');
                } else {
                    if (!this.props.formData.lines || (this.props.formData.lines.length === 1 && !this.props.formData.lines[0].itName)) {
                        index = 0;
                    } else {
                        index = this.props.formData.lines.length;
                    }
                    this.props.options.map(line => {
                        if (line.id === 'itId' && line.type === 'select2') {
                            let value = item.value;
                            let label = item.label + '/' + item.itName;
                            line.options.push({ value, label });
                        }
                        if (line.id === 'batch' && line.type === 'select2') {
                            let value = item.batchId;
                            let label = item.batchLabel;
                            line.options.push({ value, label });
                        }
                    })

                    let itemQuantity = this.state.quantity;

                    if (this.props.quantityUrl) {
                        client
                        .post(this.props.quantityUrl, {
                            'qty': itemQuantity ? itemQuantity : 1,
                            'itId': item.value,
                            'locId': this.props.formData.locId,
                            'item': item,
                            'stockAvailability': this.state.stockAvailability,
                            'documentDate': this.props.formData.documentDate ? this.props.formData.documentDate : null,
                            'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
                            'accId': this.props.formData.accIdAdditionalValue ? this.props.formData.accIdAdditionalValue.value : null,
                            'channel': this.props.formData.channel ? this.props.formData.channel : null
                        })
                            .then(response => response.data)
                            .then(response => {
                                let newLines = Array(response.length).fill(item);
                                response.forEach(function (arrayItem, i) {
                                    newLines[i].measId2 = arrayItem.measId2;
                                    newLines[i].quantity2 = arrayItem.qty2;
                                    newLines[i].quantity = arrayItem.qty;
                                    newLines[i].quantityOrdered = '';
                                    newLines[i].discountRate = arrayItem.discountRate;

                                    if (arrayItem.hasOwnProperty("price")) {
                                        newLines[i].unitPriceOriginal = arrayItem.price;
                                    }
                                    if (arrayItem.hasOwnProperty('currId')) {
                                        newLines[i].currId = arrayItem.currId;
                                    }
                                    action.onAddItemRow(newLines[i]);
                                });
                            });
                    } else {
                        item.quantity = itemQuantity;
                        item.minQty = itemQuantity;
                        item.quantityOrdered = '';
                        action.onAddItemRow(item);
                    }
                    if (refresh === true) {
                        this.props.closeSearch();
                        this.setState({ items: null, previewTable: true, batchTable: false });
                        document.getElementById('search').value = '';

                    }
                    this.setState({ lineNo: this.props.formData.lines && this.props.formData.lines.length ? this.props.formData.lines.length : 1, isOpen: false, quantity: null, selectedItem: null })
                }
            })
    }

    renderTreeNodes(data) {

        let taxRate = this.props.formData.taxRate ? this.props.formData.taxRate : this.props.formData.vatPercent;
        return data.map(item => {
            
            let icon = <StarOutlined/>;
            let title = item.name;
            let price = item.price ? item.price : 0;
            let qty = item.quantity ? item.quantity : 0;
            if (item.type === 'item') {
                icon = <StarOutlined/>
                if (!this.props.formData.hasOwnProperty('priority')) {
                    title += ' : ' + qty;
                }
                title += '; ' + plainTranslate(this.props.settings.locale, 'price') + ': ' + price;
                if (taxRate) {
                    let vatPrice = parseFloat(price) + (parseFloat(price) * (taxRate / 100));
                    title += ' / ' + parseFloat(vatPrice).toFixed(2);
                }
            } else if (item.type === 'location') {
                icon = <HomeOutlined/>
            }
            else if (item.type === 'category') {
                icon = <DatabaseOutlined/>
            }
            else if (item.type === 'batch') {
                icon = <TagOutlined/>
                if (item.name === null) {
                    title = plainTranslate(this.props.settings.locale, 'Without batch');
                }
                title += ' : ' + qty;
                title += '; ' + plainTranslate(this.props.settings.locale, 'price') + ': ' + price;
                if (taxRate) {
                    let vatPrice = parseFloat(price) + (parseFloat(price) * (taxRate / 100));
                    title += ' / ' + parseFloat(vatPrice).toFixed(2);
                }
            }
            if (item.type !== "batch" || (item.type === "batch" && !this.props.formData.hasOwnProperty('priority'))) {
                if (item.childrenData) {
                
                    return (
                        <TreeNode defaultExpandAll showIcon icon={icon} title={title} key={item.id} dataRef={item} >
                            {this.renderTreeNodes(item.childrenData)}
                        </TreeNode>
                    );
                }
              
                return <TreeNode  defaultExpandAll showIcon icon={icon} title={title} key={item.id} dataRef={item} {...item} />;
            }
        })
    };

    render() {
        return <div>
            {/*<div className="searchBar" style={{display: 'flex', alignItems: 'baseline'}}>
                        <input type="checkbox" id="stockAvailability" name="stockAvailability" onChange={this.handleCheckboxChange} value="stockAvailability" checked={this.state.stockAvailability || false} className="stockAvailability"/>
                        <label style={{marginLeft: 5}} for="stockAvailability">{plainTranslate(this.props.settings.locale, 'Show Available Items')}</label>
                    </div>*/}
            <div className="col-md-7">
                <DirectoryTree defaultExpandAll
                loadData={this.onLoadData} 
                onSelect={this.openPopup} 
                showIcon={this.renderTreeNodes(this.state.treeData)}>
                </DirectoryTree>
            </div>

            <div className="col-md-5">
                {
                    this.state.isOpen === true && this.showPopup()
                }
            </div>
        </div>

    }

}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
    item: state.importData
});

const mapDispatchToProps = dispatch => ({
    searchItem: (db, items, locId) => dispatch(searchItem(db, items, locId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TreeView);
