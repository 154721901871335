import React from 'react';
import {connect} from 'react-redux';

class Datalist extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : ''
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.props.value)
                this.props.onChange(this.props.id, this.props.value);
            else if(this.state.value === '' && this.props.options[0] && (!this.props.settings || !this.props.settings.emptyOption))
                this.props.onChange(this.props.id, this.props.options[0].value);
        }, 2000);
    }

    onChange(e) {
        this.setState({value: e.target.value});
        this.props.onChange(this.props.id, e.target.value);
    }

    render() {
        return <span className="form-input-field">
                    <input type="text" list={this.props.id} className="form-control" 
                        value={this.state.value}
                        disabled={this.props.disabled} 
                        onChange={this.onChange}
                        tabIndex={this.props.tabindex ? this.props.tabindex : ''}
                        onBlur={() => this.props.loseFocus(this.props.id)}
                    />
                    <datalist id={this.props.id}>  
                        {
                            this.props.options.map((option, key) => {
                                return <option value={option} key={key}>{option}</option>
                            })
                                    }
                    </datalist>
                </span>
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Datalist);

