import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";
import Field from '../field';

class GroupBox extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: ''
        };
        this.calculateOperation = this.calculateOperation.bind(this);
        this.addZeroes = this.addZeroes.bind(this);
        this.onChangeMainOptions = this.onChangeMainOptions.bind(this);
    }

    componentDidMount() {
        this.setState({
            fields: this.props.fields ? this.props.fields : ''
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.fields) !== JSON.stringify(this.props.fields)) {
            this.setState({
                fields: this.props.fields ? this.props.fields : ''
            });
        }
    }

    onChangeMainOptions(fieldId, options, group) {
        let arr = (this.props.fields);
        let field = arr.find(o => o.id === group);

        if (field) {
            if (Object.keys(options).length > 0) {
                field.options = options;
            } else {
                field.options = [];
            }
        }
    }
    
    addZeroes(num, decimal) {
    // Convert input string to a number and store as a variable.
        //var value = parseFloat(num);      
    // Split the input string into two arrays containing integers/decimals
        var res = num.toString().split(".");
    // If there is no decimal point or only one decimal place found.
        if(res.length == 1 || res[1].length < 3) { 
    // Set the number to two decimal places
            num = num.toFixed(decimal);
        }
    // Return updated or original number.
        return num;
    }

    calculateOperation(operation, item) {    
        if (operation.type === 'sumList') {
            let sum = 0;
            if (operation.list in this.props.formData) {
                this.props.formData[operation.list].map(item => {
                    if ('operation' in operation) {
                        sum += parseFloat(this.calculateOperation(operation.operation, item));
                    }
                });
            }

            if ('decimalPoint' in operation){
                let multiplier =  "e+"+operation.decimalPoint;
                let multiplier2 =  "e-"+operation.decimalPoint;

                return this.addZeroes(parseFloat(Math.sign(sum)*(Math.round(Math.abs(sum) + multiplier)+multiplier2)), operation.decimalPoint);
            }

            return sum;
        }
        if (operation.type === 'multiply' || operation.type === 'add' || operation.type === 'subtract' || operation.type === 'dateRange') {
            let field1 = operation.field1;
            let field2 = operation.field2;
            if (field1.type === 'additionalValue') field1 = field1.populateFrom in item && field1.name in item[field1.populateFrom] ? item[field1.populateFrom][field1.name] ? item[field1.populateFrom][field1.name] : 0 : 0;
            else if (field1.type === 'formValue') {
                field1 = item && field1.name in item ? item[field1.name] : field1.name in this.props.formData ? this.props.formData[field1.name] : item && 'populateFrom' in field1 && field1.populateFrom in item && field1.name in item[field1.populateFrom] ? item[field1.populateFrom][field1.name] : field1.defaultValue ? field1.defaultValue : 0;
            }
            else if(field1.type === 'mainFormValue' && this.props.mainFormData) field1 = field1.name in this.props.mainFormData ? this.props.mainFormData[field1.name] : field1.defaultValue ? field1.defaultValue : 0;

            else if ('operation' in field1) field1 = this.calculateOperation(field1.operation, item);

            if ('pre' in operation.field1) {
                operation.field1.pre.forEach(op => {
                    if (op.type === 'add') field1 += op.value;
                    if (op.type === 'subtract') field1 -= op.value;
                    if (op.type === 'multiply') field1 *= op.value;
                    if (op.type === 'divide') field1 /= op.value;
                });
            }

            let rate = 1;
            if (field2.type === 'additionalValue') field2 = field2.populateFrom in item && field2.name in item[field2.populateFrom] ? item[field2.populateFrom][field2.name] ? item[field2.populateFrom][field2.name] : 0 : 0;
            else if (field2.type === 'formValue') {
                field2 = field2.name in item ? item[field2.name] : item && 'populateFrom' in field2 && field2.populateFrom in item && field2.name in item[field2.populateFrom] ? item[field2.populateFrom][field2.name] : field2.defaultValue ? field2.defaultValue : 0;
            }
            else if (field2.type === 'mainFormValue' && this.props.mainFormData) {
                field2 = field2.name in this.props.mainFormData ? this.props.mainFormData[field2.name] : field2.defaultValue ? field2.defaultValue : 0;
            }
            else if ('operation' in field2) field2 = this.calculateOperation(field2.operation, item);
            else if (field2.type === 'range') {
                let col = field2.name in item ? item[field2.name] : field2.defaultValue ? field2.defaultValue : 0;
                field2.range.forEach(item => {
                    if (item.id === col) {
                        let checkDate = field1;
                        if (field1 && field1.indexOf('/') !== -1) {
                            checkDate = checkDate.split('/');
                            checkDate = new Date(checkDate[2], parseInt(checkDate[1]) - 1, checkDate[0]);
                        }
                        else {
                            checkDate = new Date(checkDate);
                        }

                        let from = new Date(item.from.date);
                        let to = new Date(item.to.date);
                        if (checkDate >= from && checkDate <= to) {
                            rate = item.value;
                        }
                    }
                });
            }

            if ('pre' in operation.field2) {
                operation.field2.pre.forEach(op => {
                    if (op.type === 'add') field2 += op.value;
                    if (op.type === 'subtract') field2 -= op.value;
                    if (op.type === 'multiply') field2 *= op.value;
                    if (op.type === 'divide') field2 /= op.value;
                });
            }

            if ('decimalPoint' in operation) {
                let multiplier =  "e+"+operation.decimalPoint;
                let multiplier2 =  "e-"+operation.decimalPoint;
            
                if (operation.type === 'multiply') return this.addZeroes(parseFloat(Math.sign(field1 * field2)*(Math.round(Math.abs(field1 * field2) + multiplier)+multiplier2)), operation.decimalPoint);
                if (operation.type === 'add') return this.addZeroes(parseFloat(Math.sign(field1 + field2)*(Math.round(Math.abs(field1 + field2) + multiplier)+multiplier2)), operation.decimalPoint);
                if (operation.type === 'subtract') return this.addZeroes(parseFloat(Math.sign(field1 - field2)*(Math.round(Math.abs(field1 - field2) + multiplier)+multiplier2)), operation.decimalPoint);

            }

            if (operation.type === 'multiply') return field1 * field2;
            if (operation.type === 'add') return field1 + field2;
            if (operation.type === 'subtract') return field1 - field2;
            if (operation.type === 'dateRange') return rate;
        }
    }

    render() {
        return <div className="panel total" style={{
            marginTop: '0px',
            paddingTop: 'paddingTop' in this.props.options ? this.props.options.paddingTop : '0px',
        }}>
            {this.props.field.id == 'totalData' ?
                <div className="panel-heading">
                    {
                        this.state.fields && this.state.fields.map((field, key) => {
                            if (field.type === 'header') {
                                return <div className={field.id == "totalSumFinal" ? "row bottom" : "row"} key={key}>
                                    {
                                        field.label &&
                                        <div className="col-xs-5 col-sm-7">
                                            <label>{plainTranslate(this.props.settings.locale, field.label)}</label>
                                        </div>
                                    }
                                    <div
                                        className={(!field.label ? 'col-md-4 col-xs-10 col-sm-5 text-right text-bold no-label' : 'col-xs-5 col-sm-5 text-right text-bold total-field')}>
                                        {'operation' in field ? this.calculateOperation(field.operation) : '0.00'}
                                    </div>
                                </div>
                            }
                            else {
                               return <Field
                                    key={key}
                                    locale={this.props.settings.locale}
                                    name={field.id}
                                    label={field.label}
                                    field={field}
                                    data={this.props.formData[field.id]}
                                    change={this.props.change}
                                    history={this.props.history}
                                    type={field.type}
                                    placeholder={field.placeholder}
                                    onChangeMainOptions={(field, options, group) => this.onChangeMainOptions(field, options, group)}
                                />
                            }
                        })
                    }
                </div>
                :
                <React.Fragment>
                    {
                        this.state.fields && this.state.fields.map((field, key) => {
                            if (field.type === 'header') {
                                return <React.Fragment key={key}>
                                    {
                                        field.label &&
                                        <div className="col-xs-7 col-sm-7">
                                            <p className="total">{plainTranslate(this.props.settings.locale, field.label)}</p>
                                        </div>
                                    }
                                    <div
                                        className={"col-xs-5 col-sm-5" + (!field.label ? ' text-right text-bold' : ' text-right text-bold')}>
                                        <p className="total">{'operation' in field ? this.calculateOperation(field.operation) : '0.00'}</p>
                                    </div>
                                </React.Fragment>
                            }
                            else {
                                
                                return <Field
                                    key={key}
                                    locale={this.props.settings.locale}
                                    name={field.id}
                                    label={field.label}
                                    field={field}
                                    fields={this.props.fields}
                                    data={this.props.formData[field.id]}
                                    change={this.props.change}
                                    type={field.type}
                                    history={this.props.history}
                                    formData={this.props.formData}
                                    placeholder={field.placeholder}
                                    onChangeMainOptions={(field, options, group) => this.onChangeMainOptions(field, options, group)}
                                />
                            }
                        })
                    }
                </React.Fragment>
            }
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupBox);
