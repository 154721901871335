import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import { Form, Input, Button, Tooltip } from 'antd';
import { passwordStrength } from 'check-password-strength'

class UserPassword extends React.Component {
  formRef = React.createRef();  
  constructor(props) {
        super(props);
        this.state = {
              value: null,
              enableSave: false
        };       
        
  }
    
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.state.value == null) {
            this.setState({
                value: this.props.value
            }, function () {
              this.formRef.current.setFieldsValue(this.state.value)
            });
        }
    }

    handleSubmit = (values) => {      
      this.props.onSave(values,'userPassword');           
    }

    submitForm = e => {
      e.preventDefault()
      this.formRef.current.submit()
    }

    
    compareToFirstPassword  = (rule, value, callback) => {
      if(!this.formRef.current.getFieldValue('newPassword')){          
        this.formRef.current.setFields([
          {
            name: 'newPassword',
            errors: [plainTranslate(this.props.settings.locale,'Please input your password!')],
          },
       ]);
      }
      
      if (value && value !== this.formRef.current.getFieldValue('newPassword')) {       
        callback(plainTranslate(this.props.settings.locale,'Passwords do not match!'));
      } else if(value) {         
        callback();
      }else if(!value){
        callback(plainTranslate(this.props.settings.locale,'Please repeat your new password!'))
      }else{
        callback()
      }
    }

    validatePassword = (rule, value, callback) => {
      if(!value){    
        callback(plainTranslate(this.props.settings.locale,'Please input your password!'))       
      }else{
        var validate = passwordStrength(value);       
        if(validate.value === 'Strong'){
          callback()
        }else{
          callback('Your password must  contain minimum 10 characters and at least one uppercase, one lowercase, one number and one special character.')
        }          
      }
      if(value && this.formRef.current.getFieldValue('repeatPassword')){
        if(value === this.formRef.current.getFieldValue('repeatPassword')){
          this.formRef.current.setFields([
            {
              name: 'repeatPassword',
              errors: [],
            },
        ]); 
        callback()
        }else{
          this.formRef.current.setFields([
            {
              name: 'repeatPassword',
              errors: [plainTranslate(this.props.settings.locale,'Passwords do not match!')],
            },
         ]);   
         callback()
        }
      }else{
        callback()
      }
    }  

    render() {       

        return (
                <React.Fragment>
                    <div className="row m-bot15">
                        <div className="col-lg-12">
                                <Form 
                                layout="vertical"
                                onFinish={this.handleSubmit} 
                                ref={this.formRef}
                                className="company-settings-form">
                                    <div className="col-lg-12"> 
                                        <h3>{plainTranslate(this.props.settings.locale, 'Old password')}</h3>
                                        <div className="col-lg-12">
                                            <Form.Item 
                                            name="oldPassword"
                                            label="Old Password"
                                            rules={[
                                              {
                                                required: true,
                                                message: 'Please input your old password!',
                                              }
                                            ]}>
                                              <Input.Password visibilityToggle={false} />
                                            </Form.Item>
                                        </div>
                                       {/*<h3>{plainTranslate(this.props.settings.locale, 'New password')}</h3>*/}
                                        <div className="col-lg-12">
                                            <Form.Item 
                                            name="newPassword"
                                            label="New password" 
                                            rules={[
                                              {
                                                validator: this.validatePassword,
                                              }
                                            ]}>
                                           <Input.Password visibilityToggle={false} />                                                                                     
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-12">
                                            <Form.Item 
                                            name="repeatPassword"
                                            label="Repeat Password"
                                            rules={[
                                              {
                                                validator: this.compareToFirstPassword,
                                              }
                                            ]}>
                                              <Input.Password visibilityToggle={false} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <Button 
                                    onClick={this.submitForm} 
                                    className="button-primary" 
                                    id="user_profile_save" 
                                    value={plainTranslate(this.props.settings.locale, 'Save')} 
                                    type="primary" 
                                    htmlType="button" >
                                        {plainTranslate(this.props.settings.locale, 'Save')}
                                    </Button>
                                </Form>
                        </div>
                    </div>
                </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

//const UserPasswordForm = Form.create({ name: 'UserPasswordForm' })(UserPassword);
    
export default connect(mapStateToProps, mapDispatchToProps)(UserPassword);