import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from "../common/translate";
import Checkbox from './checkbox';
import {CONFIGURATIONS_CHANGE} from "../../constants/actionTypes";

class ConfigurationFields extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ''
        };  
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
       this.setState({
            value: this.props.value ? this.props.value : null,
            row: this.props.row
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value ? this.props.value : null,
            });
        }
    }

    onChange(item) {
        const settings = this.props.value ? this.props.value : [];
        let keys = Object.keys(settings);
        let newValue = !settings[item.value];
        let i;
        for (i = 0; i < keys.length; i++) {
          settings[keys[i]] = false;  
        }
        settings[item.value] = newValue;
        
        this.setState({
            settings,
        }, function() {
            this.props.onChangeStagesConfiguration(this.props.setting, settings, this.props.type, this.props.row, this.props.subsetting);
        });
    }
    
     render() {
     return  <div className="col-md-12 no-gutter">
                    {this.props.fields && this.props.fields.map((item, ind) => (
                       this.props.stageStatus == 0 || this.props.stageStatus == 8 || item.value !== 'turnOffCheckQty' ?
                        (<Checkbox
                            locale={this.props.settings.locale}                                                   
                            index={this.props.stageId + this.props.row + ind}
                            name={item}
                            disabled = {item.value == 'processed' ? this.props.disabled : false}
                            message = {this.props.message}
                            isChecked={this.props.value && this.props.value[item.value]}
                            handleCheckboxChange={this.onChange}
                            />)
                        : null
                    ))}
                </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onChangeStagesConfiguration: (setting, value, type, row, subsetting) => dispatch({type: CONFIGURATIONS_CHANGE, payload: {setting: setting, value: value, type: type, row: row, subsetting: subsetting}}),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationFields);


