import React, { useState, useEffect } from 'react';
import Translate, { plainTranslate } from '../../common/translate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Tooltip, Radio, Form, Input, Select, Checkbox, Skeleton } from 'antd';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';
import { SendOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

/**
* @author Nevyana Mihaleva
* @function SurveyRespondentView
**/

const SurveyRespondentView = (props) => {
  const [fetching, setFetching] = useState(false);
  const [survey, setSurvey] = useState(null);
  const [form] = Form.useForm();
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    setFetching(true);
    if (props.match.params) {
      client
        .get(`/${props.match.params.db}/web/get/survey/${props.match.params.surveyCc}/respondent/${props.match.params.respondentCc}`)
        .then((response) => {
          setSurvey(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
          if (survey && survey.respondent && survey.respondent.responses && survey.respondent.responses.length) {
            let responses = survey.respondent.responses;
            let answersObj = {}; 
            for (let i = 0; i < responses.length; i++) {
              answersObj[`${responses[i]['id']}`] = responses[i]['value'] ? responses[i]['value'] : responses[i]['response'];
            }
            setAnswers(answersObj);
            form.setFieldsValue(answersObj);
          }
  }, [survey]);

  return (
    <div>
      {fetching &&
        <>
          <div className="invoice-profile item-profile">
            <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
              <div className="col-lg-12 col-md-12 col-xs-12" style={{ display: window.innerWidth > 780 ? "flex" : "block", alignItems: "center" }}>
                <div className="profile-breadcrumb col-md-4">
                  <Link to={"/" + props.match.params.db + "/sent/surveys"}>
                    <i className="ion-ios-arrow-back"></i>{plainTranslate(props.settings.locale, 'Respondents')}
                  </Link>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                <div className="col-lg-12 col-md-12 col-xs-12 itemHistory-table"> 
                    <Form
                      form={form}
                      layout="vertical"
                      className="form mtb-30"
                      style={{margin: '20px'}}
                    >
                      <h1 style={{ whiteSpace: "pre-line" }}>{plainTranslate(props.settings.locale, survey && survey.name)}</h1>
                      <p style={{ whiteSpace: "pre-line" }}>{plainTranslate(props.settings.locale, survey && survey.description)} </p>
                      {survey && survey.questions && survey.questions.length && survey.questions.sort((a, b) => a.position - b.position).map((question, key) => {
                        return <>
                          <Form.Item
                            name={question.id}
                            label={<span className="form-label" style={{margin: '16px'}}>{question.position}. {plainTranslate(props.settings.locale, question.question)} {question.isRequired ? '*' : ''}</span>}
                            key={question.id}
                          >
                            {question.type === 'scale' && !isNaN(parseInt(question.options)) &&
                              <Radio.Group value={answers[question.id]} name="radiogroup" >
                                {question.options && [...Array(parseInt(question.options))].map((value, index) => (
                                  <Radio key={index} value={index + 1} disabled>{index + 1}</Radio>
                                ))
                                }
                                {question.isEmptyOption &&
                                  <Radio value={null} disabled>{plainTranslate(props.settings.locale, "I don’t know/ not applicable")}</Radio>
                                }
                              </Radio.Group>
                            }
                            {question.type === 'text' &&
                              <TextArea value={answers[question.id]} type="text" disabled rows={10}/>
                            }
                            {question.type === 'dropdown' &&
                              <Select value={answers[question.id]} style={{ width: 120 }} disabled>
                                {question.options && question.options.split(',').map((value, index) => (
                                  <Option key={index} value={value}>{plainTranslate(props.settings.locale, value)}</Option>
                                ))
                                }
                              </Select>
                            }
                            {question.type === 'checkbox' &&
                              <Checkbox.Group  value={answers[question.id]} disabled>
                                {question.options && question.options.split(',').map((value) => {
                                  return <Checkbox value={value}>{plainTranslate(props.settings.locale, value)}</Checkbox>
                                })
                                }
                              </Checkbox.Group>
                            }
                            {question.type === 'multiply-dropdown' &&
                              <Select
                                mode="multiple"
                                allowClear
                                placeholder="Please select"
                                style={{ width: '100%' }}
                                value={answers && answers[question.id] && answers[question.id].split(',')}
                                disabled
                              > 
                                {question.options && question.options.split(',').map((value, index) => (
                                  <Option key={index} value={value}>{plainTranslate(props.settings.locale, value)}</Option>
                                ))
                                }
                              </Select>
                            }
                          </Form.Item>
                        </>
                      })}
                    </Form>
                    
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

const mapStateToProps = state => ({
  settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyRespondentView);
