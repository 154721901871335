import React from 'react';
import {connect} from 'react-redux';
import brace from 'brace';
import AceEditor from 'react-ace';

import 'brace/mode/java';
import 'brace/theme/github';

class Ace extends React.Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
            this.props.input.onChange(value);
    }

    render() {
        return <AceEditor
            mode="javascript"
            value={this.props.input.value ? this.props.input.value : ''}
            onChange={this.onChange}
            name={this.props.id}
            fontSize={14}
            showPrintMargin={true}
            width={"100%"}
            showGutter={true}
            editorProps={{$blockScrolling: true}}
            setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,}}
           />
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Ace);
