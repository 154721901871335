import React from 'react';
import { connect } from 'react-redux';
import Translate, { plainTranslate } from "../common/translate";
import Switch from './switch';
import { SETTINGS_CHANGE } from "../../constants/actionTypes";
import client from '../../actions/client';
import showAToast from '../common/showAToast';

class SingleCheckbox extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            showMessage: false,
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value ? this.props.value : []
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value ? this.props.value : []
            });
        }
    }

    onChange(item) {
        const settings = this.state.value ? this.state.value : [];

        settings[item] = !settings[item];

        let selectedOptions = null;
        
        if (this.props.checkSelected) {
            selectedOptions = Object.keys(settings)
                .filter(function (k) {
                    return settings[k] })
                .map(Number).length;

        }
        if (this.props.checkSelected && selectedOptions > 3) {
            settings[item] = !settings[item];
            showAToast(plainTranslate(this.props.settings.locale, 'You can choose up to three values!'), 'errSettings', 'error');
            this.setState({
                settings,
            })
        } else {
            this.setState({
                settings,
            }, function () {
                client.post(this.props.saveRoute, {
                    [this.props.setting]: settings[this.props.setting]
                })
                    .then(response => response.data)
                    .then(response => {
                        showAToast(plainTranslate(this.props.settings.locale, 'Settings saved successfully!'), 'succSettings', 'success');
                        this.props.onChangeSettings(this.props.setting, settings[this.props.setting], this.props.type)
                    }
                    );

            });
        }
    }

    render() {       
        return <div className="row">
            <div className="col-md-10 setting-item">
                <label style={{ fontSize: this.props.setting === 'resetActivity' ? 13 : 16 }}>{this.props.label}</label>
                <p>{this.props.description}</p>
            </div>
            <div className="col-md-2" style={{ marginTop: 0 }}>
                <Switch
                    locale={this.props.settings.locale}
                    name={this.props.setting}
                    isChecked={this.state.value[this.props.setting]}
                    handleCheckboxChange={this.onChange}
                />
            </div>
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onChangeSettings: (setting, value, type) => dispatch({ type: SETTINGS_CHANGE, payload: { setting: setting, value: value, type: type } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleCheckbox);

