import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';
import {onFieldSave} from "../../../../actions/actions";
import RichTextEditor from 'react-rte';

class InlineRteEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            isEdit: false
        };

        this.openEditField = this.openEditField.bind(this);
        this.closeEditField = this.closeEditField.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.saveField = this.saveField.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: RichTextEditor.createValueFromString(this.props.value ? this.props.value : '', 'html'),
            asHtml: null,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value ) {
            this.setState({
                value: RichTextEditor.createValueFromString(this.props.value ? this.props.value : '', 'html'),
            });
        }
    }

    openEditField() {
        this.setState({
            isEdit: !this.state.isEdit
        });
    }

    closeEditField() {
        this.setState({
            value: RichTextEditor.createValueFromString(this.props.value ? this.props.value : '', 'html'),
        });

        this.openEditField();
    }

    saveField() {
        this.props.onFieldSave(this.props.updateRoute, this.props.id, this.state.value.toString('html'), this.props.additionalData);
        this.openEditField();
    }

    changeValue(value) {
        this.setState({value});
    }

    render() {
        return (
            <React.Fragment>
                <hr className="col-lg-12 col-md-12 col-xs-12 no-gutter"/>
                <div className='head-line account-profile-other-information-more'>
                    <h3>
                        {this.props.title}
                        {
                            this.state.isEdit
                                ?
                                <React.Fragment>
                                <div className={this.props.cols + ' inlineHover'} style={{height: this.props.height}}>
                                    <i className="icon ion-checkmark-round" onClick={this.saveField}></i>
                                    <i className="fa fa-times-circle-o" onClick={this.closeEditField}></i>
                                </div>
                                </React.Fragment>
                                :
                                <div className={this.props.cols + ' inlineHover'} style={{height: this.props.height}}>
                                {
                                  this.state.value.length > 0 ?
                                    <a href="javascript:void(0)"
                                        onClick={this.openEditField}
                                        className="button green">
                                         {plainTranslate(this.props.settings.locale, 'Edit')}
                                    </a>
                                    :
                                    <a href='javascript:void(0)'
                                        onClick={this.openEditField}
                                        className="btn btn-crm">
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                         {plainTranslate(this.props.settings.locale, 'Add note')}
                                    </a>
                                }
                                </div>

                        }
                    </h3>
                    {!this.state.value &&
                            <div className="div-inline m-left15">
                                <span className="text-italic" style={{color: '#a0a0a0'}}><Translate locale={this.props.settings.locale} value={this.props.emptyText}/></span>
                            </div>
                        }
                </div>


                <a className="account-profile-show" id="note" href="javascript:void(0)" onClick={() => this.setState({moreText: !this.state.moreText})}>
                    {
                        this.state.value && this.state.value.toString('html').length > 200 &&
                        <React.Fragment>
                            {this.state.moreText ? <i className="glyphicon glyphicon-chevron-up"></i> : <i className="glyphicon glyphicon-chevron-down"></i>}
                        </React.Fragment>
                    }
                </a>

                {
                    this.state.isEdit
                        ?
                        <React.Fragment>
                            <RichTextEditor
                                value={this.state.value}
                                onChange={this.changeValue}
                            />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="account-profile-other-information-more">
                               <div className="line-note" dangerouslySetInnerHTML={{__html: this.state.moreText || (this.state.value && this.state.value.toString('html').length < 200) ? this.state.value.toString('html') : this.state.value.toString('html') ? (this.state.value.toString('html').substring(0, 200) + '...') : ''}}></div>
                            </div>
                        </React.Fragment>
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData))
});

export default connect(mapStateToProps, mapDispatchToProps)(InlineRteEdit);
