import React, { Component } from 'react';
import { connect } from 'react-redux';
import { plainTranslate } from '../common/translate';
import { Link } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import client from '../../actions/client'
import FormLoader from '../forms/form-loader';
import RteEditor from '../common/rteEditor';

class EditablePrint extends Component {
    constructor(props) {
        super(props);
        this.state = {          
        }
        this.getEditableTemplate = this.getEditableTemplate.bind(this);
    }

    componentDidMount() {
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
        this.getEditableTemplate();
    }

    componentDidUpdate(prevProps){
        if(this.props.value !== prevProps.value || this.props.templateId !== prevProps.templateId){
            this.getEditableTemplate();
        }
    }

    getEditableTemplate() {
        let url = '';
        const urlParams = new URLSearchParams(this.props.location.search);
        let key = urlParams.get('type');
        this.setState({loading: true});

        if(!key){
            key = this.props.type;
        }

        let templateId = this.props.match.params.templateId ? this.props.match.params.templateId : this.props.templateId;
        let docId = this.props.match.params.docId ? this.props.match.params.docId : this.props.docId;
        let db = this.props.match.params.db ? this.props.match.params.db : this.props.db;

        if (key == 'sale') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print_sales/' + docId;
        }
        if (key == 'inventory') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print_ims/' + docId;
        }
        if (key == 'invoice') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print/' + docId;
        }
        if (key == 'payment') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print_pay/' + docId;
        }
        if (key == 'deal') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print_deal/' + docId;
        }
        if (key == 'expense') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print_expense/' + docId;
        }
        if (key == 'account') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print_account/' + docId;
        }

        client
        .get(url)
        .then(response => response.data)
        .then(response => {
            if (response) {
                this.setState({value: response.twig, loading: false});
            }
        });
    }

    render() {
        return <div style={{width: "70%", margin: "0 auto"}}>
            {!this.state.loading &&
                <RteEditor value={this.state.value} showPrint={true}/>
            }
            {this.state.loading &&
                <FormLoader />
            }
        </div>
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(EditablePrint);
