import React, {Component} from 'react';

class HtmlRenderer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <span>
                <span>{this.props.value ? (this.props.value).replace(/<\/?[^>]+(>|$)/g, "") : ''}</span>
            </span>);
    }
}

export default HtmlRenderer;
