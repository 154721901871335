import React, {Component} from 'react';

class ImageRenderer extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <span>
                <img src={this.props.data.photo} />
            </span>
        );
    }
}

export default ImageRenderer;

