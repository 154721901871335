import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../common/translate";
import Text from './types/text';
import Password from './types/password';
import Textarea from './types/textarea';
import Number from './types/number';
import InsertTemplate from './types/templateButton';
import RolesTable from './types/custom/rolesTable';
import Dropdown from './types/dropdown';
import Switch from './types/switch';
import Select2 from './types/select2';
import Rte from './types/rte';
import Ace from './types/ace';
import DatePicker from "./types/datepicker";
import Image from './types/image';
import Images from './types/images';
import UserRoles from './types/custom/userRoles';
import {onFieldChange, onLoseFocus, onValidateForm} from "../../actions/actions";
import {FORM_FIELD_UPDATE, FORM_SAVE_VALIDATE} from "../../constants/actionTypes";
import {NOT_VALID} from "../../helpers/validatorTypes";
import ErrorMessage from './errorMessage';
import Barcode from './types/barcode';
import TableCollection from './types/tableCollection';
import GroupBox from './types/groupBox';
import TabBox from './types/tabBox';
import Percents from "./types/percents";
import FormFields from "./types/formFields";
import Datalist from "./types/datalist";
import TranslationsComponent from './types/translationsComponent';
import DocumentPayments from './types/documentPayments'
import ConversionsComponent from './types/conversionsComponent';
import Tinymce from './types/tinymce';

class Field extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.onChange = this.onChange.bind(this);
        this.loseFocus = this.loseFocus.bind(this);
    }

    onChange(id, value, additionalValue) {
        if(id == 'isParent'){
            this.setState({'isParent': value});
        }

        if (additionalValue) {
            this.props.onUpdateField(this.props.formId, id + 'AdditionalValue', additionalValue);
        }
        this.props.onUpdateField(this.props.formId, id, value)
            .then(() => this.props.validate(this.props.formId, this.props.formProperties.form.fields, this.props.formProperties.schema, this.props.formData, id))
            .then(() => this.props.validateSave(this.props.formId, this.props.formIsValid, this.props.formProperties.form.fields));

    }

    loseFocus(id) {
        this.props.loseFocus(this.props.formId, id)
            .then(() => this.props.validate(this.props.formId, this.props.formProperties.form.fields, this.props.formProperties.schema, this.props.formData, id))
            .then(() => this.props.validateSave(this.props.formId, this.props.formIsValid, this.props.formProperties.form.fields));
    }
    
    

    render() { 
        return <React.Fragment>{this.props.field.type === 'clearfix' ?
        <div className='clearfix'></div>
        :
        <div className={this.props.field.outerClass ? this.props.field.outerClass  : this.props.field.hide ? 'hidden' : ''}>
            <div className={this.props.field.type === 'hidden' ? 'hide-field' : ''}>
                <div className={this.props.field.width ? this.props.field.width : "field col-md-6 col-xs-12 "}
                style={{
                    height: 'height' in this.props.field ? this.props.field.height : ''
                }}>
                    <div
                        className={"row " + ('fieldClass' in this.props.field ? this.props.field.fieldClass : 'm-bot15')}>
                        <div className="form-group"
                             style={{
                                 marginBottom: 'marginBottom' in this.props.field ? this.props.field.marginBottom : ''
                             }}>
                        <label
                                className={"required " + (this.props.field.inlineLabel ? "col-md-6 col-xs-12" : "col-md-12 col-xs-12 no-gutter-m") + " text-left"}
                            style={{visibility: this.props.field.type === 'hidden' ? 'hidden' : 'visible'}}>
                            {plainTranslate(this.props.settings.locale, this.props.field.label)}
                                {this.props.field.required && <span>*</span>}
                        </label>
                            <div className={this.props.field.inlineLabel ? 'col-md-6 col-xs-12' : 'col-md-12 col-xs-12 no-gutter-m'}>
                            {
                                this.props.field.type === 'text' &&
                                <Text
                                    id={this.props.field.id}
                                    value={this.props.formData[this.props.field.id]}
                                    label={this.props.field.label}
                                        disabled={this.props.field.disabled}
                                        rules={this.props.field.rules}
                                        placeholder={this.props.field.placeholder}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        tabindex={this.props.field.tabindex}
                                        isUnique={this.props.field.isUnique}
                                    />
                                }

                                {
                                    this.props.field.type === 'password' &&
                                    <Password
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.id]}
                                        label={this.props.field.label}
                                        rules={this.props.field.rules}
                                        placeholder={this.props.field.placeholder}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        tabindex={this.props.field.tabindex}
                                    />
                                }

                                {
                                    this.props.field.type === 'textarea' &&
                                    <Textarea
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.id]}
                                        label={this.props.field.label}
                                        rules={this.props.field.rules}
                                        placeholder={this.props.field.placeholder}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        tabindex={this.props.field.tabindex}
                                    />
                                }

                                {
                                    this.props.field.type === 'dropdown' &&
                                    <Dropdown
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.id]}
                                        label={this.props.field.label}
                                        options={this.props.field.options}
                                        settings={this.props.field.settings}
                                        rules={this.props.field.rules}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        tabindex={this.props.field.tabindex}
                                    />
                                }

                                {
                                    this.props.field.type === 'switch' &&
                                    <Switch
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.id]}
                                        entityId={this.props.formData['id']}
                                        saveRoute={this.props.formProperties.saveRoute}
                                        label={this.props.field.label}
                                        rules={this.props.field.rules}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        checkUrl={this.props.field.checkUrl}
                                        tabindex={this.props.field.tabindex}
                                    />
                                }

                                {
                                    this.props.field.type === 'select2' &&
                                    <Select2
                                        formRoute={this.props.formProperties.formRoute}
                                        onCustChange={this.props.onCustChange}
                                        onChangeMainOptions={this.props.onChangeMainOptions}
                                        formId={this.props.formId}
                                        id={this.props.field.id}
                                        field={this.props.field}
                                        label={this.props.field.label}
                                        value={this.props.formData[this.props.field.id]}
                                        options={this.props.field.options}
                                        settings={this.props.field.settings}
                                        onChange={(id, value, additionalValue) => this.onChange(id, value, additionalValue)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        tabindex={this.props.field.tabindex}
                                        // isDisabled={this.props.formData['isParent']}
                                        isDisabled={this.props.field.isDisabled}
                                    />
                                }

                                {
                                    this.props.field.type === 'rte' &&
                                    <Rte
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.id]}
                                        label={this.props.field.label}
                                        options={this.props.field.options}
                                        settings={this.props.field.settings}
                                        rules={this.props.field.rules}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        tabindex={this.props.field.tabindex}
                                    />
                                }

                                {
                                    this.props.field.type === 'ace' &&
                                    <Ace
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.id]}
                                        label={this.props.field.label}
                                        options={this.props.field.options}
                                        settings={this.props.field.settings}
                                        rules={this.props.field.rules}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        tabindex={this.props.field.tabindex}
                                    />
                                }
                                
                                {
                                    this.props.field.type === 'tinymce' &&
                                    <Tinymce
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.id]}
                                        label={this.props.field.label}
                                        settings={this.props.field.settings}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        tabindex={this.props.field.tabindex}
                                    />
                                }

                                {
                                    this.props.field.type === 'number' &&
                                    <Number id={this.props.field.id}
                                            label={this.props.field.label}
                                            value={this.props.formData[this.props.field.id]}
                                            rules={this.props.field.rules}
                                            scale={this.props.field.scale}
                                            onChange={(id, value) => this.onChange(id, value)}
                                            loseFocus={(id) => this.loseFocus(id)}
                                            tabindex={this.props.field.tabindex}
                                    />
                                }
                                
                                {
                                    this.props.field.type === 'translations' &&
                                    <TranslationsComponent
                                            id={this.props.field.id}
                                            value={this.props.formData[this.props.field.id]}
                                            languages={this.props.field.languages}
                                            onChange={(id, value) => this.onChange(id, value)}
                                            loseFocus={(id) => this.loseFocus(id)}
                                            tabindex={this.props.field.tabindex} />
                                }
                                
                                {
                                    this.props.field.type === 'conversions' &&
                                    <ConversionsComponent
                                            id={this.props.field.id}
                                            value={this.props.formData[this.props.field.id]}
                                            item={this.props.formData['itemId']}
                                            options={this.props.field.options}
                                            selected ={this.props.field.selected}
                                            onChange={(id, value) => this.onChange(id, value)}
                                            loseFocus={(id) => this.loseFocus(id)}
                                            tabindex={this.props.field.tabindex} />
                                }

                                {   
                                    this.props.field.type === 'documentPayments' &&
                                    <DocumentPayments 
                                            id={this.props.field.id}
                                            formData={this.props.formData}
                                            locale={this.props.settings.locale}
                                            value={this.props.formData[this.props.field.id]}
                                            payMethods={this.props.field.payMethods}
                                            onChange={(id, value) => this.onChange(id, value)}
                                            loseFocus={(id) => this.loseFocus(id)}
                                            tabindex={this.props.field.tabindex} />

                                } 

                                {
                                    this.props.field.type === 'percents' &&
                                    <Percents
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.id]}
                                        label={this.props.field.label}
                                        rules={this.props.field.rules}
                                        placeholder={this.props.field.placeholder}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        tabindex={this.props.field.tabindex}
                                    />
                                }

                                {
                                    this.props.field.type === 'hidden' &&
                                    <span className="form-input-field">
                                    <input type="text" className="form-control" style={{visibility: 'hidden'}}/>
                                </span>
                                }

                                {
                                    this.props.field.type === 'datepicker' &&
                                    <DatePicker id={this.props.field.id}
                                                value={this.props.formData[this.props.field.id]}
                                                label={this.props.field.label}
                                                settings={this.props.field.settings}
                                                range={this.props.field.dateRange}
                                                onChange={(id, value) => this.onChange(id, value)}
                                                loseFocus={(id) => this.loseFocus(id)}/>
                                }


                                {
                                    this.props.field.type === 'templateButton' &&
                                    <InsertTemplate
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.id]}
                                        type={this.props.formData['type']}
                                        label={this.props.field.label}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                    />
                                }

                                {
                                    this.props.field.type === 'barcode' &&
                                    <Barcode
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.mapped]}
                                    />
                                }

                                {
                                    this.props.field.type === 'tableCollection' &&
                                    <TableCollection
                                        formId={this.props.formId}
                                        formRoute={this.props.formProperties.formRoute}
                                        id={this.props.field.id}
                                        custId={this.props.custId}
                                        mainFormData={this.props.formData}
                                        lines={this.props.formData[this.props.field.id]}
                                        columns={this.props.field.fields}
                                        errors={
                                            this.props.field.id in this.props.formIsValid
                                            && 'type' in this.props.formIsValid[this.props.field.id]
                                            && this.props.formIsValid[this.props.field.id].type === NOT_VALID
                                                ? this.props.formIsValid[this.props.field.id].errors
                                                : []
                                        }
                                        loseFocus={(id) => this.loseFocus(id)}
                                        onChange={(id, value) => this.onChange(id, value)}
                                    />
                                }

                                {
                                    this.props.field.type === 'groupBox' &&
                                    <GroupBox
                                        formId={this.props.formId}
                                        id={this.props.field.id}
                                        onCustChange={this.props.onCustChange}
                                        fields={this.props.field.fields}
                                        options={this.props.field.options ? this.props.field.options : {}}
                                        mainFormData={this.props.formData}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        formData={this.props.formData}
                                        formIsValid={this.props.formIsValid}
                                        formProperties={this.props.formProperties}
                                    />
                                }

                                {
                                    this.props.field.type === 'tabBox' &&
                                    <TabBox
                                        formId={this.props.formId}
                                        id={this.props.field.id}
                                        onCustChange={this.props.onCustChange}
                                        tabs={this.props.field.tabs}
                                        options={this.props.field.options}
                                        additionalData={this.props.field.additionalData}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        formData={this.props.formData}
                                        formIsValid={this.props.formIsValid}
                                        formProperties={this.props.formProperties}
                                    />
                                }

                                {
                                    this.props.field.type === 'rolesTable' &&
                                    <RolesTable
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.id]}
                                        label={this.props.field.label}
                                        lists={this.props.field.lists}
                                        reports={this.props.field.reports}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                    />
                                }

                                {
                                    this.props.field.type === 'userRoles' &&
                                    <UserRoles
                                        id={this.props.field.id}
                                        label={this.props.field.label}
                                        value={this.props.formData[this.props.field.id]}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        locations={this.props.field.locations}
                                        roles={this.props.field.roles}
                                    />
                                }

                                {
                                    this.props.field.type === 'image' &&
                                    <Image
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.id]}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                    />
                                }
                                {
                                    this.props.field.type === 'selectableFields' &&
                                    <FormFields id={this.props.field.id}
                                                value={this.props.formData[this.props.field.id]}
                                                label={this.props.field.label}
                                                options={this.props.field.options}
                                                settings={this.props.field.settings}
                                                fields={this.props.field.fields}
                                                chooseOneOfTheese={this.props.field.chooseOneOfTheese}
                                                onChange={(id, value) => this.onChange(id, value)}
                                                loseFocus={(id) => this.loseFocus(id)}/>
                                }
                                {
                                    this.props.field.type === 'images' &&
                                    <Images
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.id]}
                                        field={this.props.field}
                                        formId={this.props.formId}
                                        folder={this.props.field.folder}
                                        loseFocus={(id) => this.loseFocus(id)}
                                    />
                                }
                                {
                                    this.props.field.type === 'datalist' &&
                                    <Datalist 
                                        id={this.props.field.id}
                                        value={this.props.formData[this.props.field.id]}
                                        label={this.props.field.label}
                                        disabled={this.props.field.disabled}
                                        options={this.props.field.options}
                                        placeholder={this.props.field.placeholder}
                                        onChange={(id, value) => this.onChange(id, value)}
                                        loseFocus={(id) => this.loseFocus(id)}
                                        tabindex={this.props.field.tabindex}
                                        />
                                }
                                <div>
                                    {
                                        this.props.field.id in this.props.formIsValid
                                        && 'type' in this.props.formIsValid[this.props.field.id]
                                        && this.props.formIsValid[this.props.field.id].type === NOT_VALID
                                        && this.props.formIsValid[this.props.field.id].errors.map((error, key) => {
                                            if (key > 0) return;
                                            if (key > 0) return;
                                            if (typeof error !== 'string') return '';
                                            return <div className="forms-error-msg" key={key}><ErrorMessage
                                                error={error}
                                                message={'errorMessage' in this.props.field ? this.props.field.errorMessage : false}/><br/>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        </React.Fragment>
    }
    
}

const mapStateToProps = state => ({
    formProperties2: state.formProperties,
    formData2: state.formData,
    formIsValid2: state.formIsValid,
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({
    onUpdateField: (formId, key, value) => dispatch(onFieldChange(formId, key, value)),
    loseFocus: (formId, id) => dispatch(onLoseFocus(formId, id)),
    validate: (formId, form, schema, data) => dispatch(onValidateForm(formId, form, schema, data)),
    validateSave: (formId, isValid, form) => dispatch({type: FORM_SAVE_VALIDATE, payload: {isValid, form, formId}})
});

export default connect(mapStateToProps, mapDispatchToProps)(Field);
