import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";

class SingleCheckbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "0"
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({
                value: e.target.checked ? '1' : '0'
            })
       // this.props.input.onChange(e.target.checked ? '1' : '0');
    }
    componentDidMount() {
        if (this.props.data == "") {
            this.setState({
                value: "0"
            })
          //  this.props.input.onChange("0");
        }else{
             this.setState({
                value: this.props.data
            })
        }
    }

    render() {
        return <div className="col-xs-12 col-sm-12 no-gutter">
           <input type="checkbox" value={this.state.value} defaultChecked={this.state.value == '1' ? true : false} onChange={this.onChange} />
        </div>
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SingleCheckbox);
