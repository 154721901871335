import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Translate, { plainTranslate } from '../common/translate';
import Module from './module';
import ModalActivityForm from './modalForm';
import CountryContent from './countryContent';
import Select from 'react-select';
import client from '../../actions/client';
import { Spin } from 'antd';

class FirstModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModules: false,
            isCountryOpen: false,
            isFirstOpen: true,
            loading: false,
            modules: {
                 sales: false ,
                 invoice: false ,
                 payments: false ,
                 inventory: false ,
                 accounting: false ,
                 pos: false ,
                 project: false ,
                 itemRequests: false ,
                 production: false ,
            }
        };

        this.industries = [
            { value: "administration", label: 'Administration', modules: 'sales, invoice, accounting, payments, expense' },
            { value: "education", label: 'Education', modules: 'sales, invoice, accounting, project' },
            { value: "financial services", label: 'Financial services', modules: 'sales, invoice, accounting, payments, expense' },
            { value: "healthcare", label: 'Healthcare', modules: 'invoice, inventory, sales, accounting, payments, expense' },
            { value: "hr", label: 'HR', modules: 'invoice, sales, accounting, payments, project, expense' },
            { value: "non-profit", label: 'Non-profit', modules: 'invoice, inventory, sales, accounting, payments, project, itemRequests' },
            { value: "travel", label: 'Travel', modules: 'sales, invoice, payments, accounting, project' },
            { value: "services sector", label: 'Services sector', modules: 'sales, invoice, payments, accounting, project, expense' },
            { value: "ecommerce", label: 'eCommerce', modules: 'sales, invoice, payments, inventory, accounting, project' },
            { value: "production", label: 'Production', modules: 'sales, invoice, payments, inventory, accounting, itemRequests, production, project, expense' },
            { value: "retail", label: 'Retail', modules: 'sales, invoice, payments, inventory, accounting, project, pos, expense' },
            { value: "wholesale", label: 'Wholesale', modules: 'sales, invoice, payments, inventory, accounting, itemRequests, project, expense' },
            { value: "other", label: 'Other', modules: 'sales, invoice, payments, inventory, accounting, project, expense' },
        ];
        this.size = [
            { value: "1-4", label: '1 - 4 employees' },
            { value: "5-10", label: '5 - 10 employees' },
            { value: "11-50", label: '11 - 50 employees' },
            { value: "50+", label: '50 + employees' },
        ];
        this.allModules = [
            { value: "deals", name: 'Deals', icon: 'fa fa-clipboard' },
            { value: "sales", name: 'Sales', icon: 'icon icon-sales' },
            { value: "invoice", name: 'Invoicing', icon: 'icon icon-invoices' },
            { value: "expense", name: 'Expenses', icon: 'fa fa-money'},
            { value: "payments", name: 'Payments', icon: 'icon icon-payments' },
            { value: "inventory", name: 'Inventory', icon: 'icon icon-inventory' },
            { value: "accounting", name: 'Accounting', icon: 'icon icon-accounting' },
            { value: "pos", name: 'POS', icon: 'fa fa-cart-plus' },
            { value: "project", name: 'Projects', icon: 'fa fa-map-signs' },
            { value: "itemRequests", name: 'Item requests', icon: 'fa fa-truck' },
            { value: "production", name: 'Production', icon: 'fa fa-industry' },
            { value: "personnel", name: 'Personnel', icon: 'fa fa-users' },
        ];
        this.handleChange = this.handleChange.bind(this);
        this.goToCountry = this.goToCountry.bind(this);
        this.updateModules = this.updateModules.bind(this);
    }

    componentWillMount() {
        this.modules = new Set();
    }

    goToCountry() {   
        this.setState({loading: true});
        client.post('/' + this.props.db + '/api/onboarding/save/modules/data', {
            'settings' : this.state.modules,
            'industry': this.state.industry,
            'size': this.state.size,
        })
            .then(response => response.data)
            .then(response => {
                if (response) {
                    this.setState({
                        isFirstOpen: false,
                        isCountryOpen: true
                    })
                }
            }
            )
    }

    handleChange(prop, selectedOption) {
        let arr = [];
        if (prop === "industry" && selectedOption.value != '') {
            this.setState({
                        showModules: false
                    });
            this.industries.forEach(function (x) {
                if (x.value == selectedOption.value) {
                    arr = (x.modules).split(",").map(function(item) {
                        return item.trim();
                      });
                }
            })
            this.modules = arr;
            
            let modules = this.state.modules;
            for (let i = 0; i < this.modules.length; i++) { 
                modules[this.modules[i]]=true;
            }
            this.setState({ industry: selectedOption.value }, function () {
                if (this.state.industry && this.state.size) {
                    this.setState({
                        modules: modules,
                        showModules: true,
                    });
                } else {
                    this.setState({
                        showModules: false
                    });
                }
            });
        }
        if (prop === "size") {
            this.setState({ size: selectedOption.value }, function () {
                if (this.state.industry && this.state.size) {
                    this.setState({
                        showModules: true,
                    });
                } else {
                    this.setState({
                        showModules: false
                    });
                }
            });
        }
    }
    
    updateModules(val, state) {
        let modules = this.state.modules;
        modules[val] = state;
        
        this.setState({
             modules: modules
        });
    }

    render() {
        return (
            <div>
                <div className={this.state.isFirstOpen ? " " : "hidden"}>
                    <div>
                        <div className="col-md-12 col-xs-12 text-center">
                            <h2><Translate locale={this.props.locale} value={"Welcome to Composity, "}/><Translate locale={this.props.locale} value={this.props.userName + "!"}/></h2>
                            <h4><Translate locale={this.props.locale} value="Let's setup your workspace..." /></h4>
                        </div>
                        <div className="col-md-12 col-xs-12 onboarding-selectable">
                            <div className="col-md-6 col-xs-12 text-center">
                                <h4><Translate locale={this.props.locale} value="What is your industry?" /></h4>
                                <Select
                                        onChange={(selectedOption) => this.handleChange('industry', selectedOption)}
                                        options={plainTranslate(this.props.locale, this.industries)}
                                        placeholder={plainTranslate(this.props.locale, '-Select an industry-')}
                                        >  
                                </Select>
                            </div>
                            <div className="col-md-6 col-xs-12 text-center">
                                <h4><Translate locale={this.props.locale} value="What is the size of your company?" /></h4>
                                <Select
                                        onChange={(selectedOption) => this.handleChange('size', selectedOption)}
                                        options={plainTranslate(this.props.locale, this.size)}
                                        placeholder={plainTranslate(this.props.locale, '-Number of employees-')}
                                        >  
                                </Select>
                            </div>
                        </div>
                    </div>
                    {this.state.showModules &&
                        <div className="col-md-12 col-xs-12 onboarding-modules">
                            <div className='onboarding-modules-arrow'></div>
                            <div className="col-md-12 col-xs-12 text-center">
                                <h4><Translate locale={this.props.locale} value={"For the "} />{this.state.industry}<Translate locale={this.props.locale} value={" industry we recommend the following modules:"} /></h4>
                                <h6><Translate locale={this.props.locale} value="Add or remove the modules you want to activate by clicking on them." /></h6>
                            </div>
                            <div className="col-md-12 col-xs-12 onboarding-modules-all">
                                {this.allModules.map((val, ind) => (
                                    <Module key={ind}
                                        name={val.name}
                                        icon={val.icon}
                                        value={val.value}
                                        isChecked={this.modules.includes(val.value)}
                                        locale={this.props.locale}
                                        update={this.updateModules}
                                    />
                                ))}
                            </div>
                            <button className="button-primary pull-right text-uppercase"
                                onClick={this.goToCountry}
                            >
                                {this.state.loading &&
                                    <Spin size="small" style={{marginRight: "10px"}}/>
                                }
                                <Translate locale={this.props.locale} value="Next" />
                            </button>
                        </div>
                    }
                </div>
                <div className={this.state.isCountryOpen ? " " : "hidden"}>
                    <CountryContent
                        db={this.props.db}
                        locale={this.props.locale}
                        closeModal={this.props.closeModal}
                    />
                </div>
            </div>
        );
    }
}

export default FirstModal;


