import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import SettingsMenu from '../../sidebar/settings-menu';
import ListCheckboxes from './../listCheckboxes.jsx';
import { client} from "../../../actions/client";
import OpenSection from './../openSection.jsx';
import Attributes from './../attributes.jsx';
import SingleCheckbox from './../singleCheckbox.jsx';
import { fetchSettings} from "../../../actions/actions";

class ItemSettingsPage extends Component {

    constructor(props) {
    super(props);
        this.state = {
            showMessage: false,
            };
    }

    componentDidMount() {
/*        this.props.getSettings('/' + this.props.match.params.db + '/api/items/settings/get-attributes', 'itemAttributes');
        this.props.getSettings('/' + this.props.match.params.db + '/api/batches/settings/get-attributes', 'batches');*/
        this.props.getSettings('/' + this.props.match.params.db + '/api/items/settings/item-settings', 'itemSettings');
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

     render() {
        return (
                <div>
                    <SettingsMenu
                       location = {this.props.location.pathname} db = {this.props.match.params.db}/>
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                    <OpenSection
                                        label={plainTranslate(this.props.settings.locale, 'Items form type')}
                                        description={plainTranslate(this.props.settings.locale, "Specify different types of form for items")}
                                        link={"/" + this.props.match.params.db + "/form-types-v2"}
                                        />
                                    {/*<hr/>*/}
                                    <div className="settings">
                                      {/*  <Attributes
                                            saveRoute={'/' + this.props.match.params.db + '/api/items/settings/set-analysis-codes'}
                                            settings={this.props.settings}
                                            type={'itemAnalysis'}
                                        />  */}
{/*                                         <Attributes
                                            saveRoute={'/' + this.props.match.params.db + '/api/items/settings/set-attributes'}
                                            settings={this.props.settings}
                                            type={'itemAttributes'}
                                            label={'Item attributes'}
                                            text = {'Define common item attributes.'}
                                            count= {'10'}
                                        />*/}
                                   </div>
                                   <div className='clearfix'></div>
                                   <hr/>
                                   <SingleCheckbox
                                            value={this.props.settings.itemSettings}
                                            label={plainTranslate(this.props.settings.locale, 'Date expire')}
                                            description={plainTranslate(this.props.settings.locale, "Enable/Disable in sales orders the priority for outgoing items with an expiration date.")}
                                            settings={this.props.settings}
                                            type={'itemSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/items/save/settings/individual'}
                                            setting = {'dateExpire'}
                                    />
                                   <div className='clearfix'></div>
{/*                                   <hr/>
                                   <div className="settings">
                                        <Attributes
                                            saveRoute={'/' + this.props.match.params.db + '/api/batches/settings/set-attributes'}
                                            settings={this.props.settings}
                                            type={'batches'}
                                            label={'Item batch attributes'}
                                            text = {'Define common batch attributes.'}
                                            count= {'5'}
                                        />
                                   </div>*/}
                                </div>
                             </section>
                          </div>
                       </div>
                    </form>
                </div>
                );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
   getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ItemSettingsPage);
