import React, { Component } from 'react';
import Translate, {plainTranslate} from '../common/translate';
import {connect} from 'react-redux';

class CustomClassRenderer extends Component {
        
    constructor(props) {
         super(props);
        
          this.state = {
            value: this.props.value,
        };
    }

     render() {
        let value = this.props.column.colDef.cellClass ? this.props.data[this.props.column.colDef.cellClass] : this.state.value;

        return (
            <span className={value ? value.replace(/ /g,'') : ''}>{plainTranslate(this.props.settings.locale, this.state.value)}</span>
        );
    }
    
}    

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CustomClassRenderer);