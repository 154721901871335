import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import SettingsMenu from '../../sidebar/settings-menu';
import { client} from "../../../actions/actions";
import OpenSection from './../openSection.jsx';
import SingleCheckbox from './../singleCheckbox.jsx';
import Stages from './../stages.jsx';
import { fetchSettings} from "../../../actions/actions";
import { Skeleton } from 'antd';

class ProductionSettingsPage extends Component {

    constructor(props) {
    super(props);
            this.state = {
            showMessage: false,
            ready: false
            };
        this.allSettings = [
        
        ];
        this.updateStages = this.updateStages.bind(this);
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/production/settings/production-settings', 'productionSettings')
        .then(res => {            
            this.setState({ready: true})
        })
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }
    
    updateStages() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/production/settings/production-settings', 'productionSettings');
    }

     render() {

        return (
                <div>
                    <SettingsMenu
                       location = {this.props.location.pathname} db = {this.props.match.params.db}/>
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                   <div className="settings">
                                        <div className="col-sm-12 column-centered">
                                           {this.state.ready ? <Stages
                                                saveRoute={'/' + this.props.match.params.db + '/api/stages/save/settings/data'}
                                                type={'productionSettings'}
                                                workflow={"production"}
                                                configuration = {false}
                                                value={this.props.settings.productionSettings}
                                                settings={this.props.settings}
                                                fields={this.allSettings}
                                                setting = {'stages'}
                                                roles={'roles'}
                                                updateStages={this.updateStages}
                                            />
                                            :
                                            <Skeleton active />}
                                        </div>
                                   </div>
                                </div>
                             </section>
                          </div>
                       </div>
                    </form>
                </div>
                );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
   getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductionSettingsPage);
