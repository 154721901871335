import React from 'react';

const Dropdown = props => (
            <select
            disabled={props.field.disabled}
            className="form-control"
            {...props.input}>
            { props.field.settings && props.field.settings.emptyOption &&
                    <option></option>
            }
            {
                props.field.options.map((option, key) => {
                    return <option value={option.value} key={key}>{option.label}</option>
                })
            }
        </select>
            );

export default Dropdown;