import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Form, Input, Button, Space, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {plainTranslate} from '../../../common/translate';
import client from '../../../../actions/client'
import showAToast from '../../../common/showAToast'
import { onAccountFetchData } from '../../../../actions/actions';
import {connect} from 'react-redux';

const { Option } = Select;

const ContactForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => { 
      var contacts = [];
     if(props.contacts){
        props.contacts.map(contact => {
            contact.value = contact.value.toString()
            contacts.push(contact)
        })
     }
      
    form.setFieldsValue({ 
        contacts: contacts });
    
}, [props])

  const onFinish = values => {              
        setLoading(true)   
        let route = '/'+props.db+'/api/account-contact/modify/'+props.accountId;
        if(values.contacts){
          values.contacts.map(row => {
            props.types.map(item => {
              if(row.value === item.value){
                row.label = item.label;
              }
            })
          })
        }
        client
          .post(route, values)
          .then(response => {    
                  showAToast('Success!', 'success', 'success')
                  props.onAccountFetchData(props.db, props.accountId, false)                
                  setLoading(false) 
                  props.openEdit()
          })
          .catch(err => {            
            //  showAToast('Error!', 'baseError', 'error')                 
            setLoading(false) 
          }) 
          
  }

 

  return (
    <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off" id="contacts">   
      <Form.List name="contacts">
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <Space key={field.key} align="start">
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.contacts !== curValues.contacts
                  }
                >
                  {() => (
                    <Form.Item
                      {...field}
                      name={[field.name, 'value']}
                      rules={[{ required: true, message: 'Missing type' }]}
                    >
                      <Select  style={{ width: 130 }} getPopupContainer={() => document.getElementById('contacts')}>
                        {props.types.map(item => (
                          <Option key={item.value} value={item.value}>
                            {plainTranslate(props.settings.locale, item.label)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item
                  {...field}
                  name={[field.name, 'valueString']}
                  rules={[{ required: true, message: 'Missing contact' }]}
                >
                  <Input style={{height: 35}}/>
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}

            <Form.Item>
              <Button 
              style={{width: '28%', paddingTop: 0}}
              type="dashed" 
              onClick={() => add()} 
              block 
              icon={<PlusOutlined />}>
               {plainTranslate(props.settings.locale, 'Add new contact')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button 
        style={{  
          paddingLeft: 42,
          paddingBottom: 35}}
        htmlType="submit" className="button-primary" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = state => ({
  
});
export default connect(mapStateToProps, {onAccountFetchData})(ContactForm);