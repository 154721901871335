import React, {Component} from 'react';
import Translate, {plainTranslate} from '../common/translate';
import {connect} from 'react-redux';

class Switch extends Component {
    constructor(props) {
        super(props);
        this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
    }

    toggleCheckboxChange() {
        this.props.handleCheckboxChange(this.props.name, this.props.isChecked);
    }

    render() {
        let before = plainTranslate(this.props.locale, 'Yes');
        let after = plainTranslate(this.props.locale, 'No');
        return (
            <tr>
                <td>
                    <div before={before} after={after} className="slideThree text-left">
                        <input
                            type="checkbox"
                            checked={this.props.isChecked}
                            id={`check-${this.props.name}`}
                            onChange={this.toggleCheckboxChange}
                            name={<Translate locale={this.props.locale} value={this.props.name}/>}/>
                        <label htmlFor={`check-${this.props.name}`}></label>
                    </div>
                </td>
            </tr>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Switch); 
