import React from 'react';
import PropTypes from 'prop-types';

class ModalConfirm extends React.Component { 
    constructor(props) {
        super(props);
    }

  render() {
    return (
      <div className={`backendropss ${this.props.customClass ? 'transparent-back' : ''}`} >    
        <div className={`modalConfirms scroll ${this.props.customClass ? this.props.customClass : ''} ${this.props.bodyCustomClass ? this.props.bodyCustomClass : ''}`} >
        <div className="text-right">
            <div className="" onClick={this.props.onClose}>
              <i className="fa fa-close" aria-hidden="true"></i>
            </div>    
          </div>
          {this.props.children}          
        </div>
      </div>
    );
  }
}

ModalConfirm.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default ModalConfirm;