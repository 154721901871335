import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../common/translate';
import {connect} from 'react-redux';
import {onDocumentProfileFetchData, onEditOptionsLoad, onTabsLoad } from '../../actions/actions';
import {Link} from 'react-router-dom';
import FormLoader from '../forms/form-loader';
import { Timeline } from 'antd';
import {DateRangePicker, SingleDatePicker, DayPickerRangeController} from 'react-dates';
import {DatePicker as DatePickerComponent} from 'antd';
import {renderReportHTML} from '../../actions/actions';
import { Switch, Col, Row } from 'antd';
import moment from 'moment'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const { RangePicker } = DatePickerComponent;
class PrintDatasource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            template: [],
            active: 'template',
            loader: true,
            showReserved: false
        };
        this.onLoadReady = this.onLoadReady.bind(this);
        this.handleDatesChange = this.handleDatesChange.bind(this);
        this.onSwitchChange = this.onSwitchChange.bind(this);
    }

    componentDidMount() {
        let startDate = this.state.startDate ? moment(this.state.startDate) : moment();
        let endDate =  this.state.endDate ? moment(this.state.endDate) : moment();

        let start = startDate.year() + '-' + (startDate.month() + 1) + '-' + startDate.date();
        let end = endDate.year() + '-' + (endDate.month() + 1) + '-' + (endDate.date()+ ' 23:59:59');

        let url = '/' + this.props.match.params.db + '/api/data-source/'+this.props.match.params.type+'/'+this.props.match.params.id+'/export.pdf?startDate='+start+"&endDate="+end;

        if(this.props.match.params.type === 'stock-on-hand'){
            url = url+'&reserved='+this.state.showReserved;
        }

        document.title = this.props.title;
        this.props.renderReportHTML(url).then(() => {
            this.setState({loader: false});
        });

    }

    componentDidUpdate(prevProps) {
    }

    onLoadReady(){
        this.setState({loader: false});
    }

    handleDatesChange(event){
        this.setState({loader: true});

        let start = event[0];
        let end = event[1];

        start = start.year() + '-' + (start.month() + 1) + '-' + start.date();
        end = end.year() + '-' + (end.month() + 1) + '-' + end.date()+ ' 23:59:59';

        let url = '/' + this.props.match.params.db + '/api/data-source/'+this.props.match.params.type+'/'+this.props.match.params.id+'/export.pdf?startDate='+start+"&endDate="+end;

        if(this.props.match.params.type === 'stock-on-hand'){
            url = url+'&reserved='+this.state.showReserved;
        }

        this.props.renderReportHTML(url).then(() => {
            this.setState({loader: false});
        });

        this.setState({startDate : start, endDate: end});
    }

    onSwitchChange(checked) {

        this.setState({showReserved : checked,loader: true});

        let startDate = this.state.startDate ? moment(this.state.startDate) : moment();
        let endDate =  this.state.endDate ? moment(this.state.endDate) : moment();

        let start = startDate.year() + '-' + (startDate.month() + 1) + '-' + startDate.date();
        let end = endDate.year() + '-' + (endDate.month() + 1) + '-' + (endDate.date()+ ' 23:59:59');

        let url = '/' + this.props.match.params.db + '/api/data-source/'+this.props.match.params.type+'/'+this.props.match.params.id+'/export.pdf?startDate='+start+"&endDate="+end;

        if(this.props.match.params.type === 'stock-on-hand'){
            url = url+'&reserved='+checked;
        }

        document.title = this.props.title;
        this.props.renderReportHTML(url).then(() => {
            this.setState({loader: false});
        });
    }

    render() {
        let startDate = this.state.startDate ? moment(this.state.startDate) : moment();
        let endDate =  this.state.endDate ? moment(this.state.endDate) : moment();

        let start = startDate.year() + '-' + (startDate.month() + 1) + '-' + startDate.date();
        let end = endDate.year() + '-' + (endDate.month() + 1) + '-' + (endDate.date());

        let html = this.props.reportHTML ? this.props.reportHTML.data : [];

        return (<div>
            <Row>
           
            <Col 
            xs={{ span: 12, offset: 8 }}
            sm={{ span: 12, offset: 8 }}
            lg={{ span: 12, offset: 9 }} 
            md={{ span: 12, offset: 8 }}  
            style={{marginTop: 40}}>
                <RangePicker
                    separator={<span style={{display: "flex", fontSize: "9px", left: "-10px", position: "relative"}}><LeftOutlined /><RightOutlined /></span>}
                    style={{backgroundColor: "white"}}
                    defaultValue={[startDate,endDate]}
                    onChange={this.handleDatesChange}
                    format = {'DD.MM.YYYY'}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={(focusedInput) => {
                        this.setState({focusedInput})
                    }}
                    ranges={{
                        'Today': [moment(), moment()],
                        'This week': [moment().subtract(1, 'weeks'), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(30, 'days'), moment()],
                  }}
                />
            </Col>
            {this.props.match.params.type === 'stock-on-hand' && 
                <Col  
                xs={{ span: 12, offset: 8 }}
                sm={{ span: 12, offset: 8 }}
                lg={{ span: 12, offset: 9 }} 
                md={{ span: 12, offset: 8 }}    
                style={{marginTop: 10}}>
                    <label style={{marginRight: 10}}>{plainTranslate(this.props.settings.locale, 'Show Stock on Hand?')}</label>
                    <Switch defaultChecked={false} onChange={this.onSwitchChange} />
                </Col>              
            }
          
             </Row>
            {this.state.loader &&
            <Row>
                <Col span={18} offset={3}>
                <FormLoader/>
                </Col>
                </Row>
            }
            {!this.state.loader && html &&
            <Row>
                <Col md={{span: 18, offset:3}}>
                <div className=" col-centered printDatasource" dangerouslySetInnerHTML={{ __html: html }} style={{maxWidth: '95%', marginTop: 20}}/>
                </Col>
           </Row>
            }
        </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    reportHTML: state.report.html
});
const mapDispatchToProps = dispatch => ({
    renderReportHTML: (url) => dispatch(renderReportHTML(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintDatasource);
