import React, { Component } from 'react';
import Translate, { plainTranslate } from '../common/translate';
import { connect } from 'react-redux';
import { SETTINGS_CHANGE } from "../../constants/actionTypes";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ListCheckboxes from './listCheckboxes.jsx';
import client from '../../actions/client';
import showAToast from '../common/showAToast';
import ColorPicker from '../common/ColorPicker';
import { Select } from 'antd';

const { Option } = Select;

class Stages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: [],
            colors: [],
            stages: []
        };
        this.addNewRow = this.addNewRow.bind(this);
        this.saveStages = this.saveStages.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(){
        if(this.props.settings && this.props.settings[this.props.type] && this.props.settings[this.props.type][this.props.setting]){
            let stages = this.props.settings[this.props.type][this.props.setting];  
        if(stages){
            var displayColorPicker = [];
            var colors = [];
            stages.map((stage, key) => {
                if(this.props.colorSetting){
                    displayColorPicker[key] = false;
                colors[key] = stage.color ? stage.color : "#fff";
                }               
            })

            this.setState({
                displayColorPicker: displayColorPicker,
                colors: colors,
                stages: stages
            })
            
        }
    }
    }

   componentDidUpdate(prevProps, prevState){
       if(prevProps.settings && prevProps.settings[prevProps.type] && prevProps.settings[prevProps.type][prevProps.setting]){
             let stages = prevProps.settings[prevProps.type][prevProps.setting];       
             if(stages){    
                 var displayColorPicker = [];
                 var colors = [];
                 stages.map((stage, key) => {
                     if(this.props.colorSetting){
                         displayColorPicker[key] = false;
                         colors[key] = stage.color;
                     }               
                 })
                 if(JSON.stringify(colors) !== JSON.stringify(prevState.colors)){    
                    this.setState({
                        displayColorPicker: displayColorPicker,
                        colors: colors
                    })
                 }
             }
        }else{
           
        }
       
    }

    addNewRow() {
        let stages = this.state.stages;
        //this.props.settings && this.props.settings[this.props.type] && this.props.settings[this.props.type][this.props.setting] ? this.props.settings[this.props.type][this.props.setting] : [];
       // let l = this.props.settings && this.props.settings[this.props.type] && this.props.settings[this.props.type][this.props.setting] && this.props.settings[this.props.type][this.props.setting].length;
   
        let newStage = { id: '', workflow: this.props.workflow, position: this.state.stages.length + 1, code: '', name: '', status: '0', default: 0, configuration: this.props.configuration ? { reserved: false, processed: false, turnOffCheckQty: false } : null };      
        stages.push(newStage);
        this.setState({
            stages
        });
    }

    removeRow(key) {
        let stages = this.state.stages;
        delete stages[key];
        this.setState({
            stages
        });
    }

    onChange(e, type, key) {
        console.log(type)
        let stages = this.state.stages;
        if (type == 'default') {
            if (stages[key][type] == 1) {
                stages[key][type] = !stages[key][type];
            } else {
                stages.forEach(function (row) {
                    row['default'] = 0;
                })
                stages[key][type] = !stages[key][type];
            }
        } else {
            stages[key][type] = e.target.value;
            if (type == 'name') {
                stages[key]['code'] = e.target.value;
            }
        }

        this.props.onChangeSettings(this.props.setting, stages, this.props.type);
    }

    rolesChange = (val, key) => {
        let stages = this.state.stages;
        stages[key]['role'] = val;

        this.props.onChangeSettings(this.props.setting, stages, this.props.type);
    }
    
      handleChange = (color, key) => {
          var colors = this.state.colors;
          colors[key] = color.hex;
        this.setState({ colors: colors })
        let stages = this.state.stages;       
        stages[key]['color'] = color;
        this.props.onChangeSettings(this.props.setting, stages, this.props.type);
      }

    saveStages() {
        let stages = this.state.stages;
        let valid = true;
        if(stages.length > 0){
            stages.map(stage => {
                if(stage.name === ""){
                    valid = false;
                }
                if(stage.code === ""){
                    valid = false;
                }
            })
        }
        console.log(this.state.stages, this.props.workflow)
        if(valid){
            client.post(this.props.saveRoute, {
                'stages': this.state.stages,
                'workflow': this.props.workflow
                })
                .then(response => response.data)
                .then(response => {
                    showAToast(plainTranslate(this.props.settings.locale, 'Stages saved successfully!'), 'succSettings', 'success');
                    this.props.updateStages();
                });
        }else{
            showAToast(plainTranslate(this.props.settings.locale, 'Please, fill code and name!'), 'errorSettings', 'error');
        }
         
    }

    onDragEnd(result) {
        // dropped outside the table
        if (!result.destination) {
            return;
        }
        let stages = this.state.stages;
        const removed = stages.splice(result.source.index, 1);
        stages.splice(result.destination.index, 0, removed[0]);
        let colors = [];
        stages.forEach(function (row, key) {
            row['position'] = key + 1;
            colors[key] = row['color'] ? row['color']  : '#fff';
        });
        this.props.onChangeSettings(this.props.setting, stages, this.props.type);
        this.setState({ 
            stages: stages,
            colors: colors
         });
    }

    render() {
          const swatch = {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          };
          const popover = {
            position: 'absolute',
            zIndex: '2',
          };
          const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          }
          
        return (
            <div className='col-md-12 col-xs-12 no-gutter-m no-gutter custom-table'>
                {this.props.title ?
                    <h3 className='text-center'> {plainTranslate(this.props.settings.locale, this.props.title)}</h3>
                    :
                    <h3 className='text-center'> {plainTranslate(this.props.settings.locale, "Document stages and workflow")}</h3>
                }
                <span className="form-input-field tableCollection stages">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <table ref={provided.innerRef}>
                                    <thead>
                                        <tr>
                                            <td className={'left tableHeader'}></td>
                                            <td></td>
                                            <td className="text-left">
                                                {plainTranslate(this.props.settings.locale, "Name")}
                                            </td>
                                            <td className="text-left">
                                                {plainTranslate(this.props.settings.locale, "Status")}
                                            </td>
                                            <td className="text-left">
                                                {plainTranslate(this.props.settings.locale, "Default")}
                                            </td>
                                            {this.props.configuration &&
                                                <td className="text-left">
                                                    {plainTranslate(this.props.settings.locale, "Configuration")}
                                                </td>
                                            }
                                            <td className="text-left">
                                                {plainTranslate(this.props.settings.locale, "Allow Editing")}
                                            </td>
                                            {this.props.roles &&
                                                <td className="text-left">
                                                    {plainTranslate(this.props.settings.locale, "Not allowed to change for...")}
                                                </td>
                                            }
                                             {this.props.colorSetting &&
                                                <td className="text-left">
                                                    {plainTranslate(this.props.settings.locale, "Color")}
                                                </td>
                                            }
                                            <td className={'right tableHeader'}></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.stages.length > 0 && this.state.stages.map((stage, key) =>
                                            <Draggable key={key} draggableId={key + 1} index={key}>
                                                {(provided, snapshot) => (
                                                    <tr key={key} ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>
                                                        <td className={'left'}>
                                                            {
                                                                key !== this.state.stages.length &&
                                                                <span onClick={() => this.removeRow(key)}>
                                                                    <i className='fa fa-arrows' title="Reorder rows"></i></span>
                                                            }
                                                        </td>
                                                        <td className={'rowNumber'}>{key + 1}</td>
                                                        <td>
                                                            <input className="form-control form-stage" value={stage.name} onChange={(e) => this.onChange(e, 'name', key)} />
                                                        </td>
                                                        <td>
                                                            <select className="form-control form-stage form-stage-select" value={stage.status} onChange={(e) => this.onChange(e, 'status', key)}>
                                                                <option value='0'>{plainTranslate(this.props.settings.locale, 'Draft')}</option>
                                                                <option value='1'>{plainTranslate(this.props.settings.locale, 'New')}</option>
                                                                <option value='2'>{plainTranslate(this.props.settings.locale, 'Accepted')}</option>
                                                                <option value='3'>{plainTranslate(this.props.settings.locale, 'Awaiting')}</option>
                                                                <option value='4'>{plainTranslate(this.props.settings.locale, 'In progress')}</option>
                                                                {this.props.workflow == 'inventory' &&
                                                                    <option value='5'>{plainTranslate(this.props.settings.locale, 'In transit')}</option>
                                                                }
                                                                <option value='6'>{plainTranslate(this.props.settings.locale, 'Closed')}</option>
                                                                <option value='7'>{plainTranslate(this.props.settings.locale, 'Archived')}</option>
                                                                <option value='8'>{plainTranslate(this.props.settings.locale, 'Cancelled')}</option>
                                                                {/*<option value='9'>{plainTranslate(this.props.settings.locale, 'Deleted')}</option>*/}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <div className="switchComponent text-left">
                                                                <input
                                                                    checked={stage.default}
                                                                    onChange={(e) => this.onChange(e, 'default', key)}
                                                                    type="checkbox"
                                                                    id={'check-status' + key + this.props.setting}
                                                                    label='status'
                                                                />
                                                                <label htmlFor={'check-status' + key + this.props.setting}></label>
                                                            </div>
                                                        </td>
                                                        {this.props.configuration &&
                                                            <td>
                                                                <div className="col-sm-12">
                                                                    <ListCheckboxes
                                                                        id={'configuration'}
                                                                        stageId={stage.id}
                                                                        stageStatus={stage.status}
                                                                        value={stage.configuration}
                                                                        label={'Feature'}
                                                                        disabled={stage.status == '6' ? false : true}
                                                                        settings={this.props.settings}
                                                                        fields={this.props.fields}
                                                                        setting={'stages'}
                                                                        type={this.props.type}
                                                                        subsetting={'configuration'}
                                                                        message={'For processing quantity the status must be closed!'}
                                                                        row={key}
                                                                    />
                                                                </div>
                                                            </td>
                                                        }
                                                        <td>
                                                            <select className="form-control form-stage form-stage-select" value={stage.isPosted} onChange={(e) => this.onChange(e, 'isPosted', key)}>
                                                                <option value='0'>{plainTranslate(this.props.settings.locale, 'In Draft')}</option>
                                                                <option value='1'>{plainTranslate(this.props.settings.locale, 'Anyone')}</option>
                                                                <option value='2'>{plainTranslate(this.props.settings.locale, 'Only admins')}</option>
                                                            </select>
                                                        </td>
                                                        {this.props.roles &&
                                                            <td>
                                                            <Select value={stage.role ? stage.role : []}
                                                                mode="multiple"
                                                                style={{ width: '100%', maxWidth: '350px' }}
                                                                onChange={(val) => this.rolesChange(val, key)}
                                                                placeholder={plainTranslate(this.props.settings.locale, 'Select option')}>
                                                                {this.props.settings[this.props.type] &&  this.props.settings[this.props.type][this.props.roles] && this.props.settings[this.props.type][this.props.roles].length ? this.props.settings[this.props.type][this.props.roles].map((option, key) => {
                                                                    return <Option key={key}
                                                                        value={option.value}>{plainTranslate(this.props.settings.locale, option.label)}</Option>
                                                                }) : ''
                                                                }
                                                            </Select>
                                                            </td>
                                                        }
                                                        {this.props.colorSetting &&
                                                        <td >  
                                                        <ColorPicker 
                                                        number={key} 
                                                        value={this.state.colors[key] ? this.state.colors[key] : null}
                                                        onHandleChange={this.handleChange}/>                                                 
                                                            </td>
                                                        }
                                                        <td className={'right'}>
                                                            <span onClick={() => this.removeRow(key)}>
                                                                <i className='ion-android-close' title={plainTranslate(this.props.locale, 'Remove row')}></i></span>
                                                        </td>
                                                      
                                                    </tr>
                                                )}
                                            </Draggable>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr className="text-center"><th colSpan="6">
                                            <a className="button-outline grey text-uppercase" onClick={this.addNewRow}>
                                                {plainTranslate(this.props.locale, 'New row')}
                                            </a>
                                        </th>
                                        </tr>
                                    </tfoot>
                                </table>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div className="pull-right">
                        <a className="button-primary text-uppercase" disabled={this.props.settings[this.props.type] && this.state.stages.length != 0 ? false : true}
                            onClick={this.saveStages}>
                            {plainTranslate(this.props.locale, 'Save stages')}
                        </a>
                    </div>
                </span>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onChangeSettings: (setting, value, type) => dispatch({ type: SETTINGS_CHANGE, payload: { setting: setting, value: value, type: type } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stages);
