import React, {Component} from 'react';
import { connect } from 'react-redux';
        
class AdditionalInformation extends Component {

constructor(props) {
    super(props);
}

render() {
        return (
          <div>
          {this.props.frontend ? (
                <div className="rightside-profile-frontend">
                    <img src='/bundles/icard/images/blur.png' alt="blur-calendar"/>
                </div>) :
          (
             <div className="rightside-profile-frontend">
                    <img src='/images/right-side-profile.png' alt="blur-profiles-tabs"/>
                </div>
            )}
         </div>
        );
      }
    }

    const mapStateToProps = state => ({
        settings: state.settings,
        employeeProfile: state.employeeProfile
    });
    const mapDispatchToProps = dispatch => ({

        });
        
    export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInformation);


