import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../../common/translate';
import Checkbox from './switch';

class RolesTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: {}
        };

        this.MODULE_CRM = 'MODULE_CRM';
        this.MODULE_SALES = 'MODULE_SALES';
        this.MODULE_INVOICES = 'MODULE_INVOICES';
        this.MODULE_PAYMENTS = 'MODULE_PAYMENTS';
        this.MODULE_INVENTORY = 'MODULE_INVENTORY';
        this.MODULE_EXPENSES = 'MODULE_EXPENSES';
        this.MODULE_ACCOUNTING = 'MODULE_ACCOUNTING';
        this.MODULE_PERSONNEL = 'MODULE_PERSONNEL';
        this.MODULE_PURCHASES = 'MODULE_PURCHASES';
        this.MODULE_PRODUCTION = 'MODULE_PRODUCTION';
        this.REPORTS = 'REPORTS';
        this.MODULE_CONTENT = 'MODULE_CONTENT';
        this.ADMINISTRATION = 'ADMINISTRATION';
        this.MODULE_DEALS = 'MODULE_DEALS';
        this.MODULE_DOCUMENTS = 'MODULE_DOCUMENTS';

        this.SALES_SALES = 'SALES_SALES';
        this.SALES_POS = 'SALES_POS';
        this.INVOICES_INVOICES = 'INVOICES_INVOICES';
        this.PAYMENTS_PAYMENTS = 'PAYMENTS_PAYMENTS';
        this.PAYMENTS_TRANSFER = 'PAYMENTS_TRANSFER';
        this.PAYMENTS_REVISION = 'PAYMENTS_REVISION';
        this.INVENTORY_RECEIPTS = 'INVENTORY_RECEIPTS';
        this.INVENTORY_PURCHASE = 'INVENTORY_PURCHASE';
        this.INVENTORY_TRANSFER = 'INVENTORY_TRANSFER';
        this.INVENTORY_REVISION = 'INVENTORY_REVISION';
        this.INVENTORY_PLACES = 'INVENTORY_PLACES';
        this.EXPENSES_EXPENSES = 'EXPENSES_EXPENSES';
        this.ACCOUNTING_LEGDER = 'ACCOUNTING_LEGDER';
        this.CHART_OF_ACCOUNTS = 'CHART_OF_ACCOUNTS';
        this.ACCOUNTING_JOURNAL = 'ACCOUNTING_JOURNAL';
        this.PERSONNEL_DEPARTMENT = 'PERSONNEL_DEPARTMENT';
        this.PERSONNEL_EMPLOYEES = 'PERSONNEL_EMPLOYEES';
        this.PURCHASES_PURCHASES = 'PURCHASES_PURCHASES';
        this.PRODUCTION_PRODUCTION = 'PRODUCTION_PRODUCTION';
        this.ADMINISTRATION_ANALYSIS = 'ADMINISTRATION_ANALYSIS';
        this.ADMINISTRATION_CURRENCIES = 'ADMINISTRATION_CURRENCIES';
        this.ADMINISTRATION_EVENTS = 'ADMINISTRATION_EVENTS';
        this.ADMINISTRATION_TAXES = 'ADMINISTRATION_TAXES';
        this.ADMINISTRATION_ITEMS = 'ADMINISTRATION_ITEMS';
        this.ADMINISTRATION_MEASURES = 'ADMINISTRATION_MEASURES';
        this.ADMINISTRATION_CUSTOMERS = 'ADMINISTRATION_CUSTOMERS';
        this.ADMINISTRATION_LOCATIONS = 'ADMINISTRATION_LOCATIONS';
        this.ADMINISTRATION_PAYMENT_METHODS = 'ADMINISTRATION_PAYMENT_METHODS';
        this.ADMINISTRATION_TEMPLATES = 'ADMINISTRATION_TEMPLATES';
        this.ADMINISTRATION_LOCALIZATION = 'ADMINISTRATION_LOCALIZATION';
        this.ADMINISTRATION_OTHER = 'ADMINISTRATION_OTHER';
        this.ADMINISTRATION_PRICING = 'ADMINISTRATION_PRICING';
        this.ADMINISTRATION_ONLINE_USERS = 'ADMINISTRATION_ONLINE_USERS';
        this.ADMINISTRATION_PORTAL_USERS = 'ADMINISTRATION_PORTAL_USERS';
        this.DEALS_DEALS = 'DEALS_DEALS';
        this.SEGMENTS_SEGMENTS = 'SEGMENTS_SEGMENTS';
        this.MESSAGES_MESSAGES = 'MESSAGES_MESSAGES';
        this.BILLING_DATA = 'BILLING_DATA';
        this.ADDRESSES_ADDRESSES = 'ADDRESSES_ADDRESSES';
        this.ACTIVITIES_ACTIVITIES = 'ACTIVITIES_ACTIVITIES';
        this.CONTRACTS_CONTRACTS = 'CONTRACTS_CONTRACTS';
        this.LOCATION_PLACES = 'LOCATION_PLACES';
        this.CMS_CONTENT = 'CMS_CONTENT';
        this.DOCUMENTS_DOCUMENTS = 'DOCUMENTS_DOCUMENTS';

        this.ACTIONS = {
            VIEW: 'VIEW',
            CREATE: 'CREATE',
            EDIT: 'EDIT',
            DELETE: 'DELETE'
        };

        this.updateMain = this.updateMain.bind(this);
        this.isMainChecked = this.isMainChecked.bind(this);
        this.getCurrentActions = this.getCurrentActions.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value ? this.props.value : {}
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value ? this.props.value : {}
            });
        }
    }

    updateMain(module, value) {
        let roles = {};
        if (value) {
            if(module === this.MODULE_CRM) {
                this.props.lists.map(list => {
                    roles[list.id] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                });
                roles[this.SEGMENTS_SEGMENTS] = [this.ACTIONS.VIEW, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.MESSAGES_MESSAGES] = [this.ACTIONS.VIEW, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.BILLING_DATA] = [this.ACTIONS.VIEW, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADDRESSES_ADDRESSES] = [this.ACTIONS.VIEW, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ACTIVITIES_ACTIVITIES] = [this.ACTIONS.VIEW, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.CONTRACTS_CONTRACTS] = [this.ACTIONS.VIEW, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
            else if(module === this.MODULE_SALES) {
                roles[this.SALES_SALES] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.SALES_POS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
            else if(module === this.MODULE_INVOICES) {
                roles[this.INVOICES_INVOICES] = [this.ACTIONS.VIEW, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
            else if(module === this.MODULE_PAYMENTS) { 
                roles[this.PAYMENTS_PAYMENTS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT];
                roles[this.PAYMENTS_TRANSFER] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT];
                roles[this.PAYMENTS_REVISION] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT];
            }
            else if(module === this.MODULE_INVENTORY) {
                roles[this.INVENTORY_RECEIPTS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.INVENTORY_PURCHASE] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.INVENTORY_REVISION] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.INVENTORY_TRANSFER] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.INVENTORY_PLACES] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
            else if(module === this.MODULE_EXPENSES) {
                roles[this.EXPENSES_EXPENSES] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
            else if(module === this.MODULE_ACCOUNTING) {
                roles[this.ACCOUNTING_LEGDER] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.CHART_OF_ACCOUNTS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ACCOUNTING_JOURNAL] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
            else if(module === this.MODULE_PERSONNEL) {
                roles[this.PERSONNEL_DEPARTMENT] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.PERSONNEL_EMPLOYEES] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
            else if(module === this.MODULE_PURCHASES) {
                roles[this.PURCHASES_PURCHASES] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
            else if(module === this.MODULE_DOCUMENTS) {
                roles[this.DOCUMENTS_DOCUMENTS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
            else if(module === this.MODULE_PRODUCTION) {
                roles[this.PRODUCTION_PRODUCTION] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
            else if(module === this.ADMINISTRATION) {
                //roles[this.ADMINISTRATION] = [this.ACTIONS.VIEW];
                roles[this.ADMINISTRATION_ANALYSIS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_CURRENCIES] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_EVENTS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_TAXES] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_ITEMS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_MEASURES] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_CUSTOMERS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_LOCATIONS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_PAYMENT_METHODS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_TEMPLATES] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_LOCALIZATION] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_OTHER] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_PRICING] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_ONLINE_USERS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.ADMINISTRATION_PORTAL_USERS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                roles[this.LOCATION_PLACES] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
            else if(module === this.REPORTS) {
                this.props.reports.map(report => {
                    roles[report.id] = [this.ACTIONS.VIEW];
                });
            }
            else if(module === this.MODULE_CONTENT) {
                roles[this.CMS_CONTENT] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                this.props.settings.contentType && this.props.settings.contentType.map(content => {
                    roles[content.category] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                });
            }else if(module === this.MODULE_DEALS) {
                roles[this.DEALS_DEALS] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
            }
        }
        else {
            if(module === this.MODULE_CRM) {
                this.props.lists.map(list => {
                    roles[list.id] = [];
                });
                roles[this.SEGMENTS_SEGMENTS] = [];
                roles[this.MESSAGES_MESSAGES] = [];
                roles[this.BILLING_DATA] = [];
                roles[this.ADDRESSES_ADDRESSES] = [];
                roles[this.ACTIVITIES_ACTIVITIES] = [];
                roles[this.CONTRACTS_CONTRACTS] = [];
            }
            else if(module === this.MODULE_SALES) {
                roles[this.SALES_SALES] = [];
                roles[this.SALES_POS] = [];
            }
            else if(module === this.MODULE_INVOICES) {
                roles[this.INVOICES_INVOICES] = [];
            }
            else if(module === this.MODULE_PAYMENTS) {
                roles[this.PAYMENTS_PAYMENTS] = [];
                roles[this.PAYMENTS_TRANSFER] = [];
                roles[this.PAYMENTS_REVISION] = [];
            }
            else if(module === this.MODULE_INVENTORY) {
                roles[this.INVENTORY_RECEIPTS] = [];
                roles[this.INVENTORY_PURCHASE] = [];
                roles[this.INVENTORY_REVISION] = [];
                roles[this.INVENTORY_TRANSFER] = [];
                roles[this.INVENTORY_PLACES] = [];
            }
            else if(module === this.MODULE_EXPENSES) {
                roles[this.EXPENSES_EXPENSES] = [];
            }
            else if(module === this.MODULE_ACCOUNTING) {
                roles[this.ACCOUNTING_LEGDER] = [];
                roles[this.CHART_OF_ACCOUNTS] = [];
                roles[this.ACCOUNTING_JOURNAL] = [];
            }
            else if(module === this.MODULE_PERSONNEL) {
                roles[this.PERSONNEL_DEPARTMENT] = [];
                roles[this.PERSONNEL_EMPLOYEES] = [];
            }
            else if(module === this.MODULE_PURCHASES) {
                roles[this.PURCHASES_PURCHASES] = [];
            }
            else if(module === this.MODULE_DOCUMENTS) {
                roles[this.DOCUMENTS_DOCUMENTS] = [];
            }
            else if(module === this.MODULE_PRODUCTION) {
                roles[this.PRODUCTION_PRODUCTION] = [];
            }
            else if(module === this.ADMINISTRATION) {
                roles[this.ADMINISTRATION_ANALYSIS] = [];
                roles[this.ADMINISTRATION_CURRENCIES] = [];
                roles[this.ADMINISTRATION_EVENTS] = [];
                roles[this.ADMINISTRATION_TAXES] = [];
                roles[this.ADMINISTRATION_ITEMS] = [];
                roles[this.ADMINISTRATION_MEASURES] = [];
                roles[this.ADMINISTRATION_CUSTOMERS] = [];
                roles[this.ADMINISTRATION_LOCATIONS] = [];
                roles[this.ADMINISTRATION_PAYMENT_METHODS] = [];
                roles[this.ADMINISTRATION_TEMPLATES] = [];
                roles[this.ADMINISTRATION_LOCALIZATION] = [];
                roles[this.ADMINISTRATION_OTHER] = [];
                roles[this.ADMINISTRATION_PRICING] = [];
                roles[this.ADMINISTRATION_ONLINE_USERS] = [];
                roles[this.ADMINISTRATION_PORTAL_USERS] = [];
                roles[this.LOCATION_PLACES] = [];
            }
            else if(module === this.REPORTS) {
                this.props.reports.map(report => {
                    roles[report.id] = [];
                });
            }
            else if(module === this.MODULE_CONTENT) {
                roles[this.CMS_CONTENT] = [];
                this.props.settings.contentType && this.props.settings.contentType.map(content => {
                    roles[content.category] = [];
                });
            }
            else if(module === this.MODULE_DEALS) {
                roles[this.DEALS_DEALS] = [];
            }
        }

        let allRoles = this.state.value;
        allRoles[module] = roles;
        this.props.onChange(this.props.id, allRoles);
    }

    updateRow(module, submodule, action, value) {
        let roles = module in this.state.value ? this.state.value[module] : {};
        let ledger = [];
        if(roles['ACCOUNTING_LEGDER']){
            ledger = roles['ACCOUNTING_LEGDER'];
        }

        if (!(submodule in roles))
            roles[submodule] = [];

        if (action === 'ALL') {
            if (value) {
                if(module === 'REPORTS'){
                    roles[submodule] = [this.ACTIONS.VIEW]; 
                }
                if(module === 'MODULE_PAYMENTS'){
                         roles[submodule] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT];
                }
                 else {
                    if(ledger.length == 0 && (submodule === 'CHART_OF_ACCOUNTS' || submodule === 'ACCOUNTING_JOURNAL')){
                        roles['ACCOUNTING_LEGDER'] = [this.ACTIONS.VIEW];
                } 
                    roles[submodule] = [this.ACTIONS.VIEW, this.ACTIONS.CREATE, this.ACTIONS.EDIT, this.ACTIONS.DELETE];
                }
            }
            else roles[submodule] = [];
        }
        else {
            if (value) {
                roles[submodule].push(action);
                if(!roles[submodule].includes(this.ACTIONS.VIEW))
                    roles[submodule].push(this.ACTIONS.VIEW);

                if(ledger.length == 0 && (submodule === 'CHART_OF_ACCOUNTS' || submodule === 'ACCOUNTING_JOURNAL')){
                    roles['ACCOUNTING_LEGDER'].push(this.ACTIONS.VIEW);
                }
            }
            else {
                let index = roles[submodule].indexOf(action);
                if (index !== -1) roles[submodule].splice(index, 1);
            }
        }

        let allRoles = this.state.value;
        allRoles[module] = roles;

        this.props.onChange(this.props.id, allRoles);
    }

    isMainChecked(module) {
        let result = false;
        if (module === this.MODULE_CRM) {
            this.props.lists.map(list => {
                if (module in this.state.value && list.id in this.state.value[module] && this.state.value[module][list.id].length > 0) {
                    result = true;
                }
            });
            if (this.MODULE_CRM in this.state.value && this.SEGMENTS_SEGMENTS in this.state.value[module] && this.state.value[module][this.SEGMENTS_SEGMENTS].length > 0) {
                result = true;
            }
            if (this.MODULE_CRM in this.state.value && this.MESSAGES_MESSAGES in this.state.value[module] && this.state.value[module][this.MESSAGES_MESSAGES].length > 0) {
                result = true;
            }
            if (this.MODULE_CRM in this.state.value && this.BILLING_DATA in this.state.value[module] && this.state.value[module][this.BILLING_DATA].length > 0) {
                result = true;
            }
            if (this.MODULE_CRM in this.state.value && this.ADDRESSES_ADDRESSES in this.state.value[module] && this.state.value[module][this.ADDRESSES_ADDRESSES].length > 0) {
                result = true;
            }
            if (this.MODULE_CRM in this.state.value && this.ACTIVITIES_ACTIVITIES in this.state.value[module] && this.state.value[module][this.ACTIVITIES_ACTIVITIES].length > 0) {
                result = true;
            }
            if (this.MODULE_CRM in this.state.value && this.CONTRACTS_CONTRACTS in this.state.value[module] && this.state.value[module][this.CONTRACTS_CONTRACTS].length > 0) {
                result = true;
            }
        }
        else if (module === this.MODULE_SALES) {
            if ((this.MODULE_SALES in this.state.value && this.SALES_SALES in this.state.value[module] && this.state.value[module][this.SALES_SALES].length > 0) || (this.MODULE_SALES in this.state.value && this.SALES_POS in this.state.value[module] && this.state.value[module][this.SALES_POS].length > 0)) {
                result = true;
            }
        }
        else if (module === this.MODULE_INVOICES) {
            if (this.MODULE_INVOICES in this.state.value && this.INVOICES_INVOICES in this.state.value[module] && this.state.value[module][this.INVOICES_INVOICES].length > 0) {
                result = true;
            }
        }
        else if (module === this.MODULE_PAYMENTS) {
            if (this.MODULE_PAYMENTS in this.state.value && this.PAYMENTS_PAYMENTS in this.state.value[module] && this.state.value[module][this.PAYMENTS_PAYMENTS].length > 0 || this.MODULE_PAYMENTS in this.state.value && this.PAYMENTS_TRANSFER in this.state.value[module] && this.state.value[module][this.PAYMENTS_TRANSFER].length > 0 || this.MODULE_PAYMENTS in this.state.value && this.PAYMENTS_REVISION in this.state.value[module] && this.state.value[module][this.PAYMENTS_REVISION].length > 0) {
                result = true;
            }
        }
        else if (module === this.MODULE_INVENTORY) {
            if (this.MODULE_INVENTORY in this.state.value && this.INVENTORY_RECEIPTS in this.state.value[module] && this.state.value[module][this.INVENTORY_RECEIPTS].length > 0 || this.MODULE_INVENTORY in this.state.value && this.INVENTORY_PURCHASE in this.state.value[module] && this.state.value[module][this.INVENTORY_PURCHASE].length > 0 || this.MODULE_INVENTORY in this.state.value && this.INVENTORY_TRANSFER in this.state.value[module] && this.state.value[module][this.INVENTORY_TRANSFER].length > 0 || this.MODULE_INVENTORY in this.state.value && this.INVENTORY_REVISION in this.state.value[module] && this.state.value[module][this.INVENTORY_REVISION].length > 0 || this.MODULE_INVENTORY in this.state.value && this.INVENTORY_PLACES in this.state.value[module] && this.state.value[module][this.INVENTORY_PLACES].length > 0) {
                result = true;
            }
        }
        else if (module === this.MODULE_EXPENSES) {
            if (this.MODULE_EXPENSES in this.state.value && this.EXPENSES_EXPENSES in this.state.value[module] && this.state.value[module][this.EXPENSES_EXPENSES].length > 0) {
                result = true;
            }
        }
        else if (module === this.MODULE_ACCOUNTING) {
            if (this.MODULE_ACCOUNTING in this.state.value && this.ACCOUNTING_LEGDER in this.state.value[module] && this.state.value[module][this.ACCOUNTING_LEGDER].length > 0 || this.MODULE_ACCOUNTING in this.state.value && this.CHART_OF_ACCOUNTS in this.state.value[module] && this.state.value[module][this.CHART_OF_ACCOUNTS].length > 0 || this.MODULE_ACCOUNTING in this.state.value && this.ACCOUNTING_JOURNAL in this.state.value[module] && this.state.value[module][this.ACCOUNTING_JOURNAL].length > 0) {
                result = true;
            }
        }
        else if (module === this.MODULE_PERSONNEL) {
            if (this.MODULE_PERSONNEL in this.state.value  && this.PERSONNEL_DEPARTMENT in this.state.value[module] && this.state.value[module][this.PERSONNEL_DEPARTMENT].length > 0 || this.MODULE_PERSONNEL in this.state.value && this.PERSONNEL_EMPLOYEES in this.state.value[module] && this.state.value[module][this.PERSONNEL_EMPLOYEES].length > 0) {
                result = true;
            }
        }
        else if (module === this.MODULE_PURCHASES) {
            if (this.MODULE_PURCHASES in this.state.value && this.PURCHASES_PURCHASES in this.state.value[module] && this.state.value[module][this.PURCHASES_PURCHASES].length > 0) {
                result = true;
            }
        }
        else if (module === this.MODULE_DOCUMENTS) {
            if (this.MODULE_DOCUMENTS in this.state.value && this.DOCUMENTS_DOCUMENTS in this.state.value[module] && this.state.value[module][this.DOCUMENTS_DOCUMENTS].length > 0) {
                result = true;
            }
        }
        else if (module === this.MODULE_PRODUCTION) {
            if (this.MODULE_PRODUCTION in this.state.value && this.PRODUCTION_PRODUCTION in this.state.value[module] && this.state.value[module][this.PRODUCTION_PRODUCTION].length > 0) {
                result = true;
            }
        }
        else if (module === this.ADMINISTRATION) {
            if (this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_ANALYSIS in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_ANALYSIS].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_CURRENCIES in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_CURRENCIES].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_EVENTS in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_EVENTS].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_TAXES in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_TAXES].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_ITEMS in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_ITEMS].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_MEASURES in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_MEASURES].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_CUSTOMERS in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_CUSTOMERS].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_LOCATIONS in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_LOCATIONS].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_PAYMENT_METHODS in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_PAYMENT_METHODS].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_TEMPLATES in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_TEMPLATES].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_LOCALIZATION in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_LOCALIZATION].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_OTHER in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_OTHER].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_ONLINE_USERS in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_ONLINE_USERS].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_PORTAL_USERS in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_PORTAL_USERS].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.ADMINISTRATION_PRICING in this.state.value[module] && this.state.value[module][this.ADMINISTRATION_PRICING].length > 0 ||
                this.ADMINISTRATION in this.state.value && this.LOCATION_PLACES in this.state.value[module] && this.state.value[module][this.LOCATION_PLACES].length > 0) {
                result = true;
            }
        }
        else if (module === this.REPORTS) {
            this.props.reports.map(report => {
                if (module in this.state.value && report.id in this.state.value[module] && this.state.value[module][report.id].length > 0) {
                    result = true;
                }
            });
        }
        else if (module === this.MODULE_CONTENT) {
            if (this.MODULE_CONTENT in this.state.value && this.CMS_CONTENT in this.state.value[module] && this.state.value[module][this.CMS_CONTENT].length > 0) {
                result = true;
            }
            this.props.settings.contentType && this.props.settings.contentType.map(content => {
                if (module in this.state.value && content.category in this.state.value[module] && this.state.value[module][content.category].length > 0) {
                    result = true;
                }
            });
        }
        else if (module === this.MODULE_DEALS) {
            if (this.MODULE_DEALS in this.state.value && this.DEALS_DEALS in this.state.value[module] && this.state.value[module][this.DEALS_DEALS].length > 0) {
                result = true;
            }
        }

        return result;
    }

    getCurrentActions(module, submodule) {
        let roles = module in this.state.value ? this.state.value[module] : [];

        if (!(submodule in roles)) return '';

        let actions = [];
        if (roles[submodule].includes(this.ACTIONS.VIEW)) actions.push(plainTranslate(this.props.settings.locale, 'View'));
        if (roles[submodule].includes(this.ACTIONS.CREATE)) actions.push(plainTranslate(this.props.settings.locale, 'Create'));
        if (roles[submodule].includes(this.ACTIONS.EDIT)) actions.push(plainTranslate(this.props.settings.locale, 'Edit'));
        if (roles[submodule].includes(this.ACTIONS.DELETE)) actions.push(plainTranslate(this.props.settings.locale, 'Delete'));

        return actions.join(', ');

    }

    render() {

        const crmLists = this.props.lists;
        const crmRoles = this.MODULE_CRM in this.state.value ? this.state.value[this.MODULE_CRM] : [];
        const salesRoles = this.MODULE_SALES in this.state.value ? this.state.value[this.MODULE_SALES] : [];
        const invoicesRoles = this.MODULE_INVOICES in this.state.value ? this.state.value[this.MODULE_INVOICES] : [];
        const paymentsRoles = this.MODULE_PAYMENTS in this.state.value ? this.state.value[this.MODULE_PAYMENTS] : [];
        const inventoryRoles = this.MODULE_INVENTORY in this.state.value ? this.state.value[this.MODULE_INVENTORY] : [];
        const expensesRoles = this.MODULE_EXPENSES in this.state.value ? this.state.value[this.MODULE_EXPENSES] : [];
        const accountingRoles = this.MODULE_ACCOUNTING in this.state.value ? this.state.value[this.MODULE_ACCOUNTING] : [];
        const personnelRoles = this.MODULE_PERSONNEL in this.state.value ? this.state.value[this.MODULE_PERSONNEL] : [];
        const purchasesRoles = this.MODULE_PURCHASES in this.state.value ? this.state.value[this.MODULE_PURCHASES] : [];
        const productionRoles = this.MODULE_PRODUCTION in this.state.value ? this.state.value[this.MODULE_PRODUCTION] : [];
        const reportsRoles = this.REPORTS in this.state.value ? this.state.value[this.REPORTS] : [];
        const contentRoles = this.MODULE_CONTENT in this.state.value ? this.state.value[this.MODULE_CONTENT] : [];
        const administrationRole = this.ADMINISTRATION in this.state.value ? this.state.value[this.ADMINISTRATION] : [];
        const reports = this.props.reports;
        const contents = this.props.settings.contentType || [];
        const dealRoles = this.MODULE_DEALS in this.state.value ? this.state.value[this.MODULE_DEALS] : [];
        const documentsRoles = this.MODULE_DOCUMENTS in this.state.value ? this.state.value[this.MODULE_DOCUMENTS] : [];

        return (
            <div className="col-md-12">
                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td>{plainTranslate(this.props.settings.locale, 'CRM')}</td>
                            <td><Checkbox id={'sales-modules-all'} isChecked={this.isMainChecked(this.MODULE_CRM)}
                                          onChange={(value) => this.updateMain(this.MODULE_CRM, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        {
                            crmLists.map((list, key) => {
                                return (
                                    <tr key={key}>

                                        <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale,list.name)}</td>
                                        <td><Checkbox id={'crm-' + list.id + '-all'}
                                                      isChecked={list.id in crmRoles && (crmRoles[list.id].includes(this.ACTIONS.VIEW)
                                                          || crmRoles[list.id].includes(this.ACTIONS.CREATE)
                                                          || crmRoles[list.id].includes(this.ACTIONS.EDIT)
                                                          || crmRoles[list.id].includes(this.ACTIONS.DELETE))}
                                                      onChange={(value) => this.updateRow(this.MODULE_CRM, list.id, 'ALL', value)}/>
                                        </td>
                                        <td><Checkbox id={'crm-' + list.id + '-view'}
                                                      isChecked={list.id in crmRoles && crmRoles[list.id].includes(this.ACTIONS.VIEW)}
                                                      onChange={(value) => this.updateRow(this.MODULE_CRM, list.id, this.ACTIONS.VIEW, value)}/>
                                        </td>
                                        <td><Checkbox id={'crm-' + list.id + '-create'}
                                                      isChecked={list.id in crmRoles && crmRoles[list.id].includes(this.ACTIONS.CREATE)}
                                                      onChange={(value) => this.updateRow(this.MODULE_CRM, list.id, this.ACTIONS.CREATE, value)}/>
                                        </td>
                                        <td><Checkbox id={'crm-' + list.id + '-edit'}
                                                      isChecked={list.id in crmRoles && crmRoles[list.id].includes(this.ACTIONS.EDIT)}
                                                      onChange={(value) => this.updateRow(this.MODULE_CRM, list.id, this.ACTIONS.EDIT, value)}/>
                                        </td>
                                        <td><Checkbox id={'crm-' + list.id + '-delete'}
                                                      isChecked={list.id in crmRoles && crmRoles[list.id].includes(this.ACTIONS.DELETE)}
                                                      onChange={(value) => this.updateRow(this.MODULE_CRM, list.id, this.ACTIONS.DELETE, value)}/>
                                        </td>
                                        <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_CRM, list.id)}</td>
                                    </tr>
                                )
                            })
                        }
                          <tr>
                            <td>{plainTranslate(this.props.settings.locale, 'Segments')}</td>
                            <td><Checkbox id={'segments-all'}
                                          isChecked={this.SEGMENTS_SEGMENTS in crmRoles && (crmRoles[this.SEGMENTS_SEGMENTS].includes(this.ACTIONS.VIEW)
                                              || crmRoles[this.SEGMENTS_SEGMENTS].includes(this.ACTIONS.CREATE)
                                              || crmRoles[this.SEGMENTS_SEGMENTS].includes(this.ACTIONS.EDIT)
                                              || crmRoles[this.SEGMENTS_SEGMENTS].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.SEGMENTS_SEGMENTS, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'segments-view'}
                                          isChecked={this.SEGMENTS_SEGMENTS in crmRoles && crmRoles[this.SEGMENTS_SEGMENTS].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.SEGMENTS_SEGMENTS, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'segments-create'}
                                          isChecked={this.SEGMENTS_SEGMENTS in crmRoles && crmRoles[this.SEGMENTS_SEGMENTS].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.SEGMENTS_SEGMENTS, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'segments-edit'}
                                          isChecked={this.SEGMENTS_SEGMENTS in crmRoles && crmRoles[this.SEGMENTS_SEGMENTS].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.SEGMENTS_SEGMENTS, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'segments-delete'}
                                          isChecked={this.SEGMENTS_SEGMENTS in crmRoles && crmRoles[this.SEGMENTS_SEGMENTS].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.SEGMENTS_SEGMENTS, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td>{this.getCurrentActions(this.MODULE_CRM, this.SEGMENTS_SEGMENTS)}</td>
                        </tr>

                        <tr>
                            <td>{plainTranslate(this.props.settings.locale, 'Messages')}</td>
                            <td><Checkbox id={'messages-all'}
                                          isChecked={this.MESSAGES_MESSAGES in crmRoles && (crmRoles[this.MESSAGES_MESSAGES].includes(this.ACTIONS.VIEW)
                                              || crmRoles[this.MESSAGES_MESSAGES].includes(this.ACTIONS.CREATE)
                                              || crmRoles[this.MESSAGES_MESSAGES].includes(this.ACTIONS.EDIT)
                                              || crmRoles[this.MESSAGES_MESSAGES].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.MESSAGES_MESSAGES, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'messages-view'}
                                          isChecked={this.MESSAGES_MESSAGES in crmRoles && crmRoles[this.MESSAGES_MESSAGES].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.MESSAGES_MESSAGES, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'messages-create'}
                                          isChecked={this.MESSAGES_MESSAGES in crmRoles && crmRoles[this.MESSAGES_MESSAGES].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.MESSAGES_MESSAGES, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'messages-edit'}
                                          isChecked={this.MESSAGES_MESSAGES in crmRoles && crmRoles[this.MESSAGES_MESSAGES].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.MESSAGES_MESSAGES, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'messages-delete'}
                                          isChecked={this.MESSAGES_MESSAGES in crmRoles && crmRoles[this.MESSAGES_MESSAGES].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.MESSAGES_MESSAGES, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td>{this.getCurrentActions(this.MODULE_CRM, this.MESSAGES_MESSAGES)}</td>
                        </tr>
                        <tr>
                            <td>{plainTranslate(this.props.settings.locale, 'Billing Data')}</td>
                            <td><Checkbox id={'billing-data-all'}
                                          isChecked={this.BILLING_DATA in crmRoles && (crmRoles[this.BILLING_DATA].includes(this.ACTIONS.VIEW)
                                              || crmRoles[this.BILLING_DATA].includes(this.ACTIONS.CREATE)
                                              || crmRoles[this.BILLING_DATA].includes(this.ACTIONS.EDIT)
                                              || crmRoles[this.BILLING_DATA].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.BILLING_DATA, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'billing-data-view'}
                                          isChecked={this.BILLING_DATA in crmRoles && crmRoles[this.BILLING_DATA].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.BILLING_DATA, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'billing-data-create'}
                                          isChecked={this.BILLING_DATA in crmRoles && crmRoles[this.BILLING_DATA].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.BILLING_DATA, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'billing-data-edit'}
                                          isChecked={this.BILLING_DATA in crmRoles && crmRoles[this.BILLING_DATA].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.BILLING_DATA, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'billing-data-delete'}
                                          isChecked={this.BILLING_DATA in crmRoles && crmRoles[this.BILLING_DATA].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.BILLING_DATA, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td>{this.getCurrentActions(this.MODULE_CRM, this.BILLING_DATA)}</td>
                        </tr>
                        <tr>
                            <td>{plainTranslate(this.props.settings.locale, 'Addresses')}</td>
                            <td><Checkbox id={'addresses-all'}
                                          isChecked={this.ADDRESSES_ADDRESSES in crmRoles && (crmRoles[this.ADDRESSES_ADDRESSES].includes(this.ACTIONS.VIEW)
                                              || crmRoles[this.ADDRESSES_ADDRESSES].includes(this.ACTIONS.CREATE)
                                              || crmRoles[this.ADDRESSES_ADDRESSES].includes(this.ACTIONS.EDIT)
                                              || crmRoles[this.ADDRESSES_ADDRESSES].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.ADDRESSES_ADDRESSES, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'addresses-view'}
                                          isChecked={this.ADDRESSES_ADDRESSES in crmRoles && crmRoles[this.ADDRESSES_ADDRESSES].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.ADDRESSES_ADDRESSES, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'addresses-create'}
                                          isChecked={this.ADDRESSES_ADDRESSES in crmRoles && crmRoles[this.ADDRESSES_ADDRESSES].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.ADDRESSES_ADDRESSES, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'addresses-edit'}
                                          isChecked={this.ADDRESSES_ADDRESSES in crmRoles && crmRoles[this.ADDRESSES_ADDRESSES].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.ADDRESSES_ADDRESSES, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'addresses-delete'}
                                          isChecked={this.ADDRESSES_ADDRESSES in crmRoles && crmRoles[this.ADDRESSES_ADDRESSES].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.ADDRESSES_ADDRESSES, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td>{this.getCurrentActions(this.MODULE_CRM, this.ADDRESSES_ADDRESSES)}</td>
                        </tr>
                        <tr>
                            <td>{plainTranslate(this.props.settings.locale, 'Activities')}</td>
                            <td><Checkbox id={'activities-all'}
                                          isChecked={this.ACTIVITIES_ACTIVITIES in crmRoles && (crmRoles[this.ACTIVITIES_ACTIVITIES].includes(this.ACTIONS.VIEW)
                                              || crmRoles[this.ACTIVITIES_ACTIVITIES].includes(this.ACTIONS.CREATE)
                                              || crmRoles[this.ACTIVITIES_ACTIVITIES].includes(this.ACTIONS.EDIT)
                                              || crmRoles[this.ACTIVITIES_ACTIVITIES].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.ACTIVITIES_ACTIVITIES, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'activities-view'}
                                          isChecked={this.ACTIVITIES_ACTIVITIES in crmRoles && crmRoles[this.ACTIVITIES_ACTIVITIES].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.ACTIVITIES_ACTIVITIES, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'activities-create'}
                                          isChecked={this.ACTIVITIES_ACTIVITIES in crmRoles && crmRoles[this.ACTIVITIES_ACTIVITIES].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.ACTIVITIES_ACTIVITIES, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'activities-edit'}
                                          isChecked={this.ACTIVITIES_ACTIVITIES in crmRoles && crmRoles[this.ACTIVITIES_ACTIVITIES].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.ACTIVITIES_ACTIVITIES, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'activities-delete'}
                                          isChecked={this.ACTIVITIES_ACTIVITIES in crmRoles && crmRoles[this.ACTIVITIES_ACTIVITIES].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.ACTIVITIES_ACTIVITIES, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td>{this.getCurrentActions(this.MODULE_CRM, this.ACTIVITIES_ACTIVITIES)}</td>
                        </tr>
                        <tr>
                            <td>{plainTranslate(this.props.settings.locale, 'Contracts')}</td>
                            <td><Checkbox id={'contracts-all'}
                                          isChecked={this.CONTRACTS_CONTRACTS in crmRoles && (crmRoles[this.CONTRACTS_CONTRACTS].includes(this.ACTIONS.VIEW)
                                              || crmRoles[this.CONTRACTS_CONTRACTS].includes(this.ACTIONS.CREATE)
                                              || crmRoles[this.CONTRACTS_CONTRACTS].includes(this.ACTIONS.EDIT)
                                              || crmRoles[this.CONTRACTS_CONTRACTS].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.CONTRACTS_CONTRACTS, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'contracts-view'}
                                          isChecked={this.CONTRACTS_CONTRACTS in crmRoles && crmRoles[this.CONTRACTS_CONTRACTS].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.CONTRACTS_CONTRACTS, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'contracts-create'}
                                          isChecked={this.CONTRACTS_CONTRACTS in crmRoles && crmRoles[this.CONTRACTS_CONTRACTS].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.CONTRACTS_CONTRACTS, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'contracts-edit'}
                                          isChecked={this.CONTRACTS_CONTRACTS in crmRoles && crmRoles[this.CONTRACTS_CONTRACTS].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.CONTRACTS_CONTRACTS, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'contracts-delete'}
                                          isChecked={this.CONTRACTS_CONTRACTS in crmRoles && crmRoles[this.CONTRACTS_CONTRACTS].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CRM, this.CONTRACTS_CONTRACTS, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td>{this.getCurrentActions(this.MODULE_CRM, this.CONTRACTS_CONTRACTS)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Deals')}</td>
                            <td><Checkbox id={'deals-all'} isChecked={this.isMainChecked(this.MODULE_DEALS)}
                                          onChange={(value) => this.updateMain(this.MODULE_DEALS, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Deals')}</td>
                            <td><Checkbox id={'deals-all'}
                                          isChecked={this.DEALS_DEALS in dealRoles && (dealRoles[this.DEALS_DEALS].includes(this.ACTIONS.VIEW)
                                              || dealRoles[this.DEALS_DEALS].includes(this.ACTIONS.CREATE)
                                              || dealRoles[this.DEALS_DEALS].includes(this.ACTIONS.EDIT)
                                              || dealRoles[this.DEALS_DEALS].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_DEALS, this.DEALS_DEALS, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'deals-view'}
                                          isChecked={this.DEALS_DEALS in dealRoles && dealRoles[this.DEALS_DEALS].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_DEALS, this.DEALS_DEALS, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'deals-create'}
                                          isChecked={this.DEALS_DEALS in dealRoles && dealRoles[this.DEALS_DEALS].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_DEALS, this.DEALS_DEALS, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'deals-edit'}
                                          isChecked={this.DEALS_DEALS in dealRoles && dealRoles[this.DEALS_DEALS].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_DEALS, this.DEALS_DEALS, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'deals-delete'}
                                          isChecked={this.DEALS_DEALS in dealRoles && dealRoles[this.DEALS_DEALS].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_DEALS, this.DEALS_DEALS, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_DEALS, this.DEALS_DEALS)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Sales')}</td>
                            <td><Checkbox id={'sales-all'} isChecked={this.isMainChecked(this.MODULE_SALES)}
                                          onChange={(value) => this.updateMain(this.MODULE_SALES, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Sales')}</td>
                            <td><Checkbox id={'sales-sales-all'}
                                          isChecked={this.SALES_SALES in salesRoles && (salesRoles[this.SALES_SALES].includes(this.ACTIONS.VIEW)
                                              || salesRoles[this.SALES_SALES].includes(this.ACTIONS.CREATE)
                                              || salesRoles[this.SALES_SALES].includes(this.ACTIONS.EDIT)
                                              || salesRoles[this.SALES_SALES].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_SALES, this.SALES_SALES, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'sales-view'}
                                          isChecked={this.SALES_SALES in salesRoles && salesRoles[this.SALES_SALES].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_SALES, this.SALES_SALES, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'sales-create'}
                                          isChecked={this.SALES_SALES in salesRoles && salesRoles[this.SALES_SALES].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_SALES, this.SALES_SALES, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'sales-edit'}
                                          isChecked={this.SALES_SALES in salesRoles && salesRoles[this.SALES_SALES].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_SALES, this.SALES_SALES, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'sales-delete'}
                                          isChecked={this.SALES_SALES in salesRoles && salesRoles[this.SALES_SALES].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_SALES, this.SALES_SALES, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_SALES, this.SALES_SALES)}</td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'POS')}</td>
                            <td><Checkbox id={'pos-all'}
                                          isChecked={this.SALES_POS in salesRoles && (salesRoles[this.SALES_POS].includes(this.ACTIONS.VIEW)
                                              || salesRoles[this.SALES_POS].includes(this.ACTIONS.CREATE)
                                              || salesRoles[this.SALES_POS].includes(this.ACTIONS.EDIT)
                                              || salesRoles[this.SALES_POS].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_SALES, this.SALES_POS, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'pos-view'}
                                          isChecked={this.SALES_POS in salesRoles && salesRoles[this.SALES_POS].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_SALES, this.SALES_POS, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'pos-create'}
                                          isChecked={this.SALES_POS in salesRoles && salesRoles[this.SALES_POS].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_SALES, this.SALES_POS, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'pos-edit'}
                                          isChecked={this.SALES_POS in salesRoles && salesRoles[this.SALES_POS].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_SALES, this.SALES_POS, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'pos-delete'}
                                          isChecked={this.SALES_POS in salesRoles && salesRoles[this.SALES_POS].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_SALES, this.SALES_POS, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_SALES, this.SALES_POS)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Invoices')}</td>
                            <td><Checkbox id={'invoices-all'} isChecked={this.isMainChecked(this.MODULE_INVOICES)}
                                          onChange={(value) => this.updateMain(this.MODULE_INVOICES, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Invoices')}</td>
                            <td><Checkbox id={'invoices-invoices-orders-all'}
                                          isChecked={this.INVOICES_INVOICES in invoicesRoles && (invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.VIEW)
                                              || invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.CREATE)
                                              || invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.EDIT)
                                              || invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_INVOICES, this.INVOICES_INVOICES, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'invoices-invoices-orders-view'}
                                          isChecked={this.INVOICES_INVOICES in invoicesRoles && invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVOICES, this.INVOICES_INVOICES, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td></td>
                            <td><Checkbox id={'invoices-invoices-orders-edit'}
                                          isChecked={this.INVOICES_INVOICES in invoicesRoles && invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVOICES, this.INVOICES_INVOICES, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'invoices-invoices-orders-delete'}
                                          isChecked={this.INVOICES_INVOICES in invoicesRoles && invoicesRoles[this.INVOICES_INVOICES].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVOICES, this.INVOICES_INVOICES, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_INVOICES, this.INVOICES_INVOICES)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Expenses')}</td>
                            <td><Checkbox id={'expenses-all'} isChecked={this.isMainChecked(this.MODULE_EXPENSES)}
                                          onChange={(value) => this.updateMain(this.MODULE_EXPENSES, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Expenses')}</td>
                            <td><Checkbox id={'expenses-expenses-all'}
                                          isChecked={this.EXPENSES_EXPENSES in expensesRoles && (expensesRoles[this.EXPENSES_EXPENSES].includes(this.ACTIONS.VIEW)
                                              || expensesRoles[this.EXPENSES_EXPENSES].includes(this.ACTIONS.CREATE)
                                              || expensesRoles[this.EXPENSES_EXPENSES].includes(this.ACTIONS.EDIT)
                                              || expensesRoles[this.EXPENSES_EXPENSES].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_EXPENSES, this.EXPENSES_EXPENSES, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'expenses-expenses-view'}
                                          isChecked={this.EXPENSES_EXPENSES in expensesRoles && expensesRoles[this.EXPENSES_EXPENSES].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_EXPENSES, this.EXPENSES_EXPENSES, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'expenses-expenses-create'}
                                          isChecked={this.EXPENSES_EXPENSES in expensesRoles && expensesRoles[this.EXPENSES_EXPENSES].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_EXPENSES, this.EXPENSES_EXPENSES, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'expenses-expenses-edit'}
                                          isChecked={this.EXPENSES_EXPENSES in expensesRoles && expensesRoles[this.EXPENSES_EXPENSES].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_EXPENSES, this.EXPENSES_EXPENSES, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'expenses-expenses-delete'}
                                          isChecked={this.EXPENSES_EXPENSES in expensesRoles && expensesRoles[this.EXPENSES_EXPENSES].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_EXPENSES, this.EXPENSES_EXPENSES, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_EXPENSES, this.EXPENSES_EXPENSES)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Payments')}</td>
                            <td><Checkbox id={'payments-all'} isChecked={this.isMainChecked(this.MODULE_PAYMENTS)}
                                          onChange={(value) => this.updateMain(this.MODULE_PAYMENTS, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Incoming and Outcoming Payments')}</td>
                            <td><Checkbox id={'payments-payments-all'}
                                          isChecked={this.PAYMENTS_PAYMENTS in paymentsRoles && (paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.VIEW)
                                              || paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.CREATE)
                                              || paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.EDIT)
                                              )}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_PAYMENTS, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'payments-payments-view'}
                                          isChecked={this.PAYMENTS_PAYMENTS in paymentsRoles && paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_PAYMENTS, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'payments-payments-create'}
                                          isChecked={this.PAYMENTS_PAYMENTS in paymentsRoles && paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_PAYMENTS, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'payments-payments-edit'}
                                          isChecked={this.PAYMENTS_PAYMENTS in paymentsRoles && paymentsRoles[this.PAYMENTS_PAYMENTS].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_PAYMENTS, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td style={{width: 110}}></td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_PAYMENTS, this.PAYMENTS_PAYMENTS)}</td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Transfer')}</td>
                            <td><Checkbox id={'payments-transfer-all'}
                                          isChecked={this.PAYMENTS_TRANSFER in paymentsRoles && (paymentsRoles[this.PAYMENTS_TRANSFER].includes(this.ACTIONS.VIEW)
                                              || paymentsRoles[this.PAYMENTS_TRANSFER].includes(this.ACTIONS.CREATE)
                                              || paymentsRoles[this.PAYMENTS_TRANSFER].includes(this.ACTIONS.EDIT))}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_TRANSFER, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'payments-transfer-view'}
                                          isChecked={this.PAYMENTS_TRANSFER in paymentsRoles && paymentsRoles[this.PAYMENTS_TRANSFER].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_TRANSFER, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'payments-transfer-create'}
                                          isChecked={this.PAYMENTS_TRANSFER in paymentsRoles && paymentsRoles[this.PAYMENTS_TRANSFER].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_TRANSFER, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'payments-transfer-edit'}
                                          isChecked={this.PAYMENTS_TRANSFER in paymentsRoles && paymentsRoles[this.PAYMENTS_TRANSFER].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_TRANSFER, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td style={{width: 110}}></td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_PAYMENTS, this.PAYMENTS_TRANSFER)}</td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Revision')}</td>
                            <td><Checkbox id={'payments-revision-all'}
                                          isChecked={this.PAYMENTS_REVISION in paymentsRoles && (paymentsRoles[this.PAYMENTS_REVISION].includes(this.ACTIONS.VIEW)
                                              || paymentsRoles[this.PAYMENTS_REVISION].includes(this.ACTIONS.CREATE)
                                              || paymentsRoles[this.PAYMENTS_REVISION].includes(this.ACTIONS.EDIT)
                                              || paymentsRoles[this.PAYMENTS_REVISION].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_REVISION, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'payments-revision-view'}
                                          isChecked={this.PAYMENTS_REVISION in paymentsRoles && paymentsRoles[this.PAYMENTS_REVISION].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_REVISION, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'payments-revision-create'}
                                          isChecked={this.PAYMENTS_REVISION in paymentsRoles && paymentsRoles[this.PAYMENTS_REVISION].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_REVISION, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'payments-revision-edit'}
                                          isChecked={this.PAYMENTS_REVISION in paymentsRoles && paymentsRoles[this.PAYMENTS_REVISION].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_PAYMENTS, this.PAYMENTS_REVISION, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td style={{width: 110}}></td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_PAYMENTS, this.PAYMENTS_REVISION)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Inventory')}</td>
                            <td><Checkbox id={'inventory-all'} isChecked={this.isMainChecked(this.MODULE_INVENTORY)}
                                          onChange={(value) => this.updateMain(this.MODULE_INVENTORY, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Release receipt')}</td>
                            <td><Checkbox id={'inventory-receipts-all'}
                                          isChecked={this.INVENTORY_RECEIPTS in inventoryRoles && (inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.VIEW)
                                              || inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.CREATE)
                                              || inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.EDIT)
                                              || inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_RECEIPTS, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'inventory-receipts-view'}
                                          isChecked={this.INVENTORY_RECEIPTS in inventoryRoles && inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_RECEIPTS, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-receipts-create'}
                                          isChecked={this.INVENTORY_RECEIPTS in inventoryRoles && inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_RECEIPTS, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-receipts-edit'}
                                          isChecked={this.INVENTORY_RECEIPTS in inventoryRoles && inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_RECEIPTS, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-receipts-delete'}
                                          isChecked={this.INVENTORY_RECEIPTS in inventoryRoles && inventoryRoles[this.INVENTORY_RECEIPTS].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_RECEIPTS, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_INVENTORY, this.INVENTORY_RECEIPTS)}</td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Purchase receipt')}</td>
                            <td><Checkbox id={'inventory-purchase-all'}
                                          isChecked={this.INVENTORY_PURCHASE in inventoryRoles && (inventoryRoles[this.INVENTORY_PURCHASE].includes(this.ACTIONS.VIEW)
                                              || inventoryRoles[this.INVENTORY_PURCHASE].includes(this.ACTIONS.CREATE)
                                              || inventoryRoles[this.INVENTORY_PURCHASE].includes(this.ACTIONS.EDIT)
                                              || inventoryRoles[this.INVENTORY_PURCHASE].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_PURCHASE, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'inventory-purchase-view'}
                                          isChecked={this.INVENTORY_PURCHASE in inventoryRoles && inventoryRoles[this.INVENTORY_PURCHASE].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_PURCHASE, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-purchase-create'}
                                          isChecked={this.INVENTORY_PURCHASE in inventoryRoles && inventoryRoles[this.INVENTORY_PURCHASE].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_PURCHASE, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-purchase-edit'}
                                          isChecked={this.INVENTORY_PURCHASE in inventoryRoles && inventoryRoles[this.INVENTORY_PURCHASE].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_PURCHASE, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-purchase-delete'}
                                          isChecked={this.INVENTORY_PURCHASE in inventoryRoles && inventoryRoles[this.INVENTORY_PURCHASE].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_PURCHASE, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_INVENTORY, this.INVENTORY_PURCHASE)}</td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Transfer')}</td>
                            <td><Checkbox id={'inventory-transfer-all'}
                                          isChecked={this.INVENTORY_TRANSFER in inventoryRoles && (inventoryRoles[this.INVENTORY_TRANSFER].includes(this.ACTIONS.VIEW)
                                              || inventoryRoles[this.INVENTORY_TRANSFER].includes(this.ACTIONS.CREATE)
                                              || inventoryRoles[this.INVENTORY_TRANSFER].includes(this.ACTIONS.EDIT)
                                              || inventoryRoles[this.INVENTORY_TRANSFER].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_TRANSFER, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'inventory-transfer-view'}
                                          isChecked={this.INVENTORY_TRANSFER in inventoryRoles && inventoryRoles[this.INVENTORY_TRANSFER].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_TRANSFER, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-transfer-create'}
                                          isChecked={this.INVENTORY_TRANSFER in inventoryRoles && inventoryRoles[this.INVENTORY_TRANSFER].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_TRANSFER, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-transfer-edit'}
                                          isChecked={this.INVENTORY_TRANSFER in inventoryRoles && inventoryRoles[this.INVENTORY_TRANSFER].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_TRANSFER, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-transfer-delete'}
                                          isChecked={this.INVENTORY_TRANSFER in inventoryRoles && inventoryRoles[this.INVENTORY_TRANSFER].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_TRANSFER, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_INVENTORY, this.INVENTORY_TRANSFER)}</td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Revision')}</td>
                            <td><Checkbox id={'inventory-revision-all'}
                                          isChecked={this.INVENTORY_REVISION in inventoryRoles && (inventoryRoles[this.INVENTORY_REVISION].includes(this.ACTIONS.VIEW)
                                              || inventoryRoles[this.INVENTORY_REVISION].includes(this.ACTIONS.CREATE)
                                              || inventoryRoles[this.INVENTORY_REVISION].includes(this.ACTIONS.EDIT)
                                              || inventoryRoles[this.INVENTORY_REVISION].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_REVISION, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'inventory-revision-view'}
                                          isChecked={this.INVENTORY_REVISION in inventoryRoles && inventoryRoles[this.INVENTORY_REVISION].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_REVISION, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-revision-create'}
                                          isChecked={this.INVENTORY_REVISION in inventoryRoles && inventoryRoles[this.INVENTORY_REVISION].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_REVISION, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-revision-edit'}
                                          isChecked={this.INVENTORY_REVISION in inventoryRoles && inventoryRoles[this.INVENTORY_REVISION].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_REVISION, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-revision-delete'}
                                          isChecked={this.INVENTORY_REVISION in inventoryRoles && inventoryRoles[this.INVENTORY_REVISION].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_REVISION, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_INVENTORY, this.INVENTORY_REVISION)}</td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Inventory places')}</td>
                            <td><Checkbox id={'inventory-places-all'}
                                          isChecked={this.INVENTORY_PLACES in inventoryRoles && (inventoryRoles[this.INVENTORY_PLACES].includes(this.ACTIONS.VIEW)
                                              || inventoryRoles[this.INVENTORY_PLACES].includes(this.ACTIONS.CREATE)
                                              || inventoryRoles[this.INVENTORY_PLACES].includes(this.ACTIONS.EDIT)
                                              || inventoryRoles[this.INVENTORY_PLACES].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_PLACES, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'inventory-places-view'}
                                          isChecked={this.INVENTORY_PLACES in inventoryRoles && inventoryRoles[this.INVENTORY_PLACES].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_PLACES, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-places-create'}
                                          isChecked={this.INVENTORY_PLACES in inventoryRoles && inventoryRoles[this.INVENTORY_PLACES].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_PLACES, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'inventory-places-edit'}
                                          isChecked={this.INVENTORY_PLACES in inventoryRoles && inventoryRoles[this.INVENTORY_PLACES].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_INVENTORY, this.INVENTORY_PLACES, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_INVENTORY, this.INVENTORY_PLACES)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Accounting')}</td>
                            <td><Checkbox id={'accounting-all'} isChecked={this.isMainChecked(this.MODULE_ACCOUNTING)}
                                          onChange={(value) => this.updateMain(this.MODULE_ACCOUNTING, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Chart of Accounts')}</td>
                            <td><Checkbox id={'chart-of-accounts-all'}
                                          isChecked={this.CHART_OF_ACCOUNTS in accountingRoles && (accountingRoles[this.CHART_OF_ACCOUNTS].includes(this.ACTIONS.VIEW)
                                              || accountingRoles[this.CHART_OF_ACCOUNTS].includes(this.ACTIONS.CREATE)
                                              || accountingRoles[this.CHART_OF_ACCOUNTS].includes(this.ACTIONS.EDIT)
                                              || accountingRoles[this.CHART_OF_ACCOUNTS].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.CHART_OF_ACCOUNTS, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'chart-of-accounts-view'}
                                          isChecked={this.CHART_OF_ACCOUNTS in accountingRoles && accountingRoles[this.CHART_OF_ACCOUNTS].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.CHART_OF_ACCOUNTS, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'chart-of-accounts-create'}
                                          isChecked={this.CHART_OF_ACCOUNTS in accountingRoles && accountingRoles[this.CHART_OF_ACCOUNTS].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.CHART_OF_ACCOUNTS, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'chart-of-accounts-edit'}
                                          isChecked={this.CHART_OF_ACCOUNTS in accountingRoles && accountingRoles[this.CHART_OF_ACCOUNTS].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.CHART_OF_ACCOUNTS, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'chart-of-accounts-delete'}
                                          isChecked={this.CHART_OF_ACCOUNTS in accountingRoles && accountingRoles[this.CHART_OF_ACCOUNTS].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.CHART_OF_ACCOUNTS, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_ACCOUNTING, this.CHART_OF_ACCOUNTS)}</td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Journal')}</td>
                            <td><Checkbox id={'journal-all'}
                                          isChecked={this.ACCOUNTING_JOURNAL in accountingRoles && (accountingRoles[this.ACCOUNTING_JOURNAL].includes(this.ACTIONS.VIEW)
                                              || accountingRoles[this.ACCOUNTING_JOURNAL].includes(this.ACTIONS.CREATE)
                                              || accountingRoles[this.ACCOUNTING_JOURNAL].includes(this.ACTIONS.EDIT)
                                              || accountingRoles[this.ACCOUNTING_JOURNAL].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.ACCOUNTING_JOURNAL, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'journal-view'}
                                          isChecked={this.ACCOUNTING_JOURNAL in accountingRoles && accountingRoles[this.ACCOUNTING_JOURNAL].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.ACCOUNTING_JOURNAL, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'journal-create'}
                                          isChecked={this.ACCOUNTING_JOURNAL in accountingRoles && accountingRoles[this.ACCOUNTING_JOURNAL].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.ACCOUNTING_JOURNAL, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'journal-edit'}
                                          isChecked={this.ACCOUNTING_JOURNAL in accountingRoles && accountingRoles[this.ACCOUNTING_JOURNAL].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.ACCOUNTING_JOURNAL, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'journal-delete'}
                                          isChecked={this.ACCOUNTING_JOURNAL in accountingRoles && accountingRoles[this.ACCOUNTING_JOURNAL].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.ACCOUNTING_JOURNAL, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_ACCOUNTING, this.ACCOUNTING_JOURNAL)}</td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Ledger')}</td>
                            <td><Checkbox id={'ledger-all'}
                                          isChecked={this.ACCOUNTING_LEGDER in accountingRoles && (accountingRoles[this.ACCOUNTING_LEGDER].includes(this.ACTIONS.VIEW)
                                              || accountingRoles[this.ACCOUNTING_LEGDER].includes(this.ACTIONS.CREATE)
                                              || accountingRoles[this.ACCOUNTING_LEGDER].includes(this.ACTIONS.EDIT)
                                              || accountingRoles[this.ACCOUNTING_LEGDER].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.ACCOUNTING_LEGDER, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'ledger-view'}
                                          isChecked={this.ACCOUNTING_LEGDER in accountingRoles && accountingRoles[this.ACCOUNTING_LEGDER].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.ACCOUNTING_LEGDER, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'ledger-create'}
                                          isChecked={this.ACCOUNTING_LEGDER in accountingRoles && accountingRoles[this.ACCOUNTING_LEGDER].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.ACCOUNTING_LEGDER, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'ledger-edit'}
                                          isChecked={this.ACCOUNTING_LEGDER in accountingRoles && accountingRoles[this.ACCOUNTING_LEGDER].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.ACCOUNTING_LEGDER, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'ledger-delete'}
                                          isChecked={this.ACCOUNTING_LEGDER in accountingRoles && accountingRoles[this.ACCOUNTING_LEGDER].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_ACCOUNTING, this.ACCOUNTING_LEGDER, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_ACCOUNTING, this.ACCOUNTING_LEGDER)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Personnel')}</td>
                            <td><Checkbox id={'personnel-all'} isChecked={this.isMainChecked(this.MODULE_PERSONNEL)}
                                          onChange={(value) => this.updateMain(this.MODULE_PERSONNEL, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Departments')}</td>
                            <td><Checkbox id={'departments-all'}
                                          isChecked={this.PERSONNEL_DEPARTMENT in personnelRoles && (personnelRoles[this.PERSONNEL_DEPARTMENT].includes(this.ACTIONS.VIEW)
                                              || personnelRoles[this.PERSONNEL_DEPARTMENT].includes(this.ACTIONS.CREATE)
                                              || personnelRoles[this.PERSONNEL_DEPARTMENT].includes(this.ACTIONS.EDIT)
                                              || personnelRoles[this.PERSONNEL_DEPARTMENT].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_PERSONNEL, this.PERSONNEL_DEPARTMENT, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'departments-view'}
                                          isChecked={this.PERSONNEL_DEPARTMENT in personnelRoles && personnelRoles[this.PERSONNEL_DEPARTMENT].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_PERSONNEL, this.PERSONNEL_DEPARTMENT, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'departments-create'}
                                          isChecked={this.PERSONNEL_DEPARTMENT in personnelRoles && personnelRoles[this.PERSONNEL_DEPARTMENT].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_PERSONNEL, this.PERSONNEL_DEPARTMENT, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'departments-edit'}
                                          isChecked={this.PERSONNEL_DEPARTMENT in personnelRoles && personnelRoles[this.PERSONNEL_DEPARTMENT].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_PERSONNEL, this.PERSONNEL_DEPARTMENT, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'departments-delete'}
                                          isChecked={this.PERSONNEL_DEPARTMENT in personnelRoles && personnelRoles[this.PERSONNEL_DEPARTMENT].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_PERSONNEL, this.PERSONNEL_DEPARTMENT, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_PERSONNEL, this.PERSONNEL_DEPARTMENT)}</td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Employees')}</td>
                            <td><Checkbox id={'employees-all'}
                                          isChecked={this.PERSONNEL_EMPLOYEES in personnelRoles && (personnelRoles[this.PERSONNEL_EMPLOYEES].includes(this.ACTIONS.VIEW)
                                              || personnelRoles[this.PERSONNEL_EMPLOYEES].includes(this.ACTIONS.CREATE)
                                              || personnelRoles[this.PERSONNEL_EMPLOYEES].includes(this.ACTIONS.EDIT)
                                              || personnelRoles[this.PERSONNEL_EMPLOYEES].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_PERSONNEL, this.PERSONNEL_EMPLOYEES, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'employees-view'}
                                          isChecked={this.PERSONNEL_EMPLOYEES in personnelRoles && personnelRoles[this.PERSONNEL_EMPLOYEES].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_PERSONNEL, this.PERSONNEL_EMPLOYEES, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'employees-create'}
                                          isChecked={this.PERSONNEL_EMPLOYEES in personnelRoles && personnelRoles[this.PERSONNEL_EMPLOYEES].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_PERSONNEL, this.PERSONNEL_EMPLOYEES, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'employees-edit'}
                                          isChecked={this.PERSONNEL_EMPLOYEES in personnelRoles && personnelRoles[this.PERSONNEL_EMPLOYEES].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_PERSONNEL, this.PERSONNEL_EMPLOYEES, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'employees-delete'}
                                          isChecked={this.PERSONNEL_EMPLOYEES in personnelRoles && personnelRoles[this.PERSONNEL_EMPLOYEES].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_PERSONNEL, this.PERSONNEL_EMPLOYEES, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_PERSONNEL, this.PERSONNEL_EMPLOYEES)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Item requests')}</td>
                            <td><Checkbox id={'purchases-all'} isChecked={this.isMainChecked(this.MODULE_PURCHASES)}
                                          onChange={(value) => this.updateMain(this.MODULE_PURCHASES, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Item requests')}</td>
                            <td><Checkbox id={'purchases-all'}
                                          isChecked={this.PURCHASES_PURCHASES in purchasesRoles && (purchasesRoles[this.PURCHASES_PURCHASES].includes(this.ACTIONS.VIEW)
                                              || purchasesRoles[this.PURCHASES_PURCHASES].includes(this.ACTIONS.CREATE)
                                              || purchasesRoles[this.PURCHASES_PURCHASES].includes(this.ACTIONS.EDIT)
                                              || purchasesRoles[this.PURCHASES_PURCHASES].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_PURCHASES, this.PURCHASES_PURCHASES, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'purchases-view'}
                                          isChecked={this.PURCHASES_PURCHASES in purchasesRoles && purchasesRoles[this.PURCHASES_PURCHASES].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_PURCHASES, this.PURCHASES_PURCHASES, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'purchases-create'}
                                          isChecked={this.PURCHASES_PURCHASES in purchasesRoles && purchasesRoles[this.PURCHASES_PURCHASES].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_PURCHASES, this.PURCHASES_PURCHASES, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'purchases-edit'}
                                          isChecked={this.PURCHASES_PURCHASES in purchasesRoles && purchasesRoles[this.PURCHASES_PURCHASES].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_PURCHASES, this.PURCHASES_PURCHASES, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'purchases-delete'}
                                          isChecked={this.PURCHASES_PURCHASES in purchasesRoles && purchasesRoles[this.PURCHASES_PURCHASES].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_PURCHASES, this.PURCHASES_PURCHASES, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_PURCHASES, this.PURCHASES_PURCHASES)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Production')}</td>
                            <td><Checkbox id={'production-all'} isChecked={this.isMainChecked(this.MODULE_PRODUCTION)}
                                          onChange={(value) => this.updateMain(this.MODULE_PRODUCTION, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Production')}</td>
                            <td><Checkbox id={'production-all'}
                                          isChecked={this.PRODUCTION_PRODUCTION in productionRoles && (productionRoles[this.PRODUCTION_PRODUCTION].includes(this.ACTIONS.VIEW)
                                              || productionRoles[this.PRODUCTION_PRODUCTION].includes(this.ACTIONS.CREATE)
                                              || productionRoles[this.PRODUCTION_PRODUCTION].includes(this.ACTIONS.EDIT)
                                              || productionRoles[this.PRODUCTION_PRODUCTION].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_PRODUCTION, this.PRODUCTION_PRODUCTION, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'production-view'}
                                          isChecked={this.PRODUCTION_PRODUCTION in productionRoles && productionRoles[this.PRODUCTION_PRODUCTION].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_PRODUCTION, this.PRODUCTION_PRODUCTION, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'production-create'}
                                          isChecked={this.PRODUCTION_PRODUCTION in productionRoles && productionRoles[this.PRODUCTION_PRODUCTION].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_PRODUCTION, this.PRODUCTION_PRODUCTION, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'production-edit'}
                                          isChecked={this.PRODUCTION_PRODUCTION in productionRoles && productionRoles[this.PRODUCTION_PRODUCTION].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_PRODUCTION, this.PRODUCTION_PRODUCTION, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'production-delete'}
                                          isChecked={this.PRODUCTION_PRODUCTION in productionRoles && productionRoles[this.PRODUCTION_PRODUCTION].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_PRODUCTION, this.PRODUCTION_PRODUCTION, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_PRODUCTION, this.PRODUCTION_PRODUCTION)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                {/* Content Module */}
                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Content')}</td>
                            <td><Checkbox id={'content-all'} isChecked={this.isMainChecked(this.MODULE_CONTENT)}
                                          onChange={(value) => this.updateMain(this.MODULE_CONTENT, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Content Management')}</td>
                            <td><Checkbox id={'cms-content-all'}
                                          isChecked={this.CMS_CONTENT in contentRoles && (contentRoles[this.CMS_CONTENT].includes(this.ACTIONS.VIEW)
                                              || contentRoles[this.CMS_CONTENT].includes(this.ACTIONS.CREATE)
                                              || contentRoles[this.CMS_CONTENT].includes(this.ACTIONS.EDIT)
                                              || contentRoles[this.CMS_CONTENT].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_CONTENT, this.CMS_CONTENT, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'cms-content-view'}
                                          isChecked={this.CMS_CONTENT in contentRoles && contentRoles[this.CMS_CONTENT].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_CONTENT, this.CMS_CONTENT, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'cms-content-create'}
                                          isChecked={this.CMS_CONTENT in contentRoles && contentRoles[this.CMS_CONTENT].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CONTENT, this.CMS_CONTENT, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'cms-content-edit'}
                                          isChecked={this.CMS_CONTENT in contentRoles && contentRoles[this.CMS_CONTENT].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_CONTENT, this.CMS_CONTENT, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'cms-content-delete'}
                                          isChecked={this.CMS_CONTENT in contentRoles && contentRoles[this.CMS_CONTENT].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_CONTENT, this.CMS_CONTENT, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_CONTENT, this.CMS_CONTENT)}</td>
                        </tr>
                        {
                            contents.map((content, key) => {
                                return (
                                    <tr key={key}>

                                        <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale,content.category)}</td>
                                        <td style={{width: 110}}><Checkbox id={'content-' + content.category + '-all'}
                                                      isChecked={content.category in contentRoles && (contentRoles[content.category].includes(this.ACTIONS.VIEW))}
                                                      onChange={(value) => this.updateRow(this.MODULE_CONTENT, content.category, 'ALL', value)}/>
                                        </td>
                                        <td style={{width: 110}}><Checkbox id={'content-' + content.category + '-view'}
                                                      isChecked={content.category in contentRoles && contentRoles[content.category].includes(this.ACTIONS.VIEW)}
                                                      onChange={(value) => this.updateRow(this.MODULE_CONTENT, content.category, this.ACTIONS.VIEW, value)}/>
                                        </td>
                                        <td style={{width: 110}}><Checkbox id={'content-' + content.category + '-create'}
                                                      isChecked={content.category in contentRoles && contentRoles[content.category].includes(this.ACTIONS.CREATE)}
                                                      onChange={(value) => this.updateRow(this.MODULE_CONTENT, content.category, this.ACTIONS.CREATE, value)}/>
                                        </td>
                                        <td style={{width: 110}}><Checkbox id={'content-' + content.category + '-edit'}
                                                      isChecked={content.category in contentRoles && contentRoles[content.category].includes(this.ACTIONS.EDIT)}
                                                      onChange={(value) => this.updateRow(this.MODULE_CONTENT, content.category, this.ACTIONS.EDIT, value)}/>
                                        </td>
                                        <td style={{width: 110}}><Checkbox id={'content-' + content.category + '-delete'}
                                                      isChecked={content.category in contentRoles && contentRoles[content.category].includes(this.ACTIONS.DELETE)}
                                                      onChange={(value) => this.updateRow(this.MODULE_CONTENT, content.category, this.ACTIONS.DELETE, value)}/>
                                        </td>
                                        <td style={{width: 110}}>{this.getCurrentActions(this.MODULE_CONTENT, content.category)}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Documents')}</td>
                            <td><Checkbox id={'documents-all'} isChecked={this.isMainChecked(this.MODULE_DOCUMENTS)}
                                          onChange={(value) => this.updateMain(this.MODULE_DOCUMENTS, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Documents')}</td>
                            <td><Checkbox id={'documents-all'}
                                          isChecked={this.DOCUMENTS_DOCUMENTS in documentsRoles && (documentsRoles[this.DOCUMENTS_DOCUMENTS].includes(this.ACTIONS.VIEW)
                                              || documentsRoles[this.DOCUMENTS_DOCUMENTS].includes(this.ACTIONS.CREATE)
                                              || documentsRoles[this.DOCUMENTS_DOCUMENTS].includes(this.ACTIONS.EDIT)
                                              || documentsRoles[this.DOCUMENTS_DOCUMENTS].includes(this.ACTIONS.DELETE))}
                                          onChange={(value) => this.updateRow(this.MODULE_DOCUMENTS, this.DOCUMENTS_DOCUMENTS, 'ALL', value)}/>
                            </td>
                            <td><Checkbox id={'documents-view'}
                                          isChecked={this.DOCUMENTS_DOCUMENTS in documentsRoles && documentsRoles[this.DOCUMENTS_DOCUMENTS].includes(this.ACTIONS.VIEW)}
                                          onChange={(value) => this.updateRow(this.MODULE_DOCUMENTS, this.DOCUMENTS_DOCUMENTS, this.ACTIONS.VIEW, value)}/>
                            </td>
                            <td><Checkbox id={'documents-create'}
                                          isChecked={this.DOCUMENTS_DOCUMENTS in documentsRoles && documentsRoles[this.DOCUMENTS_DOCUMENTS].includes(this.ACTIONS.CREATE)}
                                          onChange={(value) => this.updateRow(this.MODULE_DOCUMENTS, this.DOCUMENTS_DOCUMENTS, this.ACTIONS.CREATE, value)}/>
                            </td>
                            <td><Checkbox id={'documents-edit'}
                                          isChecked={this.DOCUMENTS_DOCUMENTS in documentsRoles && documentsRoles[this.DOCUMENTS_DOCUMENTS].includes(this.ACTIONS.EDIT)}
                                          onChange={(value) => this.updateRow(this.MODULE_DOCUMENTS, this.DOCUMENTS_DOCUMENTS, this.ACTIONS.EDIT, value)}/>
                            </td>
                            <td><Checkbox id={'documents-delete'}
                                          isChecked={this.DOCUMENTS_DOCUMENTS in documentsRoles && documentsRoles[this.DOCUMENTS_DOCUMENTS].includes(this.ACTIONS.DELETE)}
                                          onChange={(value) => this.updateRow(this.MODULE_DOCUMENTS, this.DOCUMENTS_DOCUMENTS, this.ACTIONS.DELETE, value)}/>
                            </td>
                            <td style={{width: 100}}>{this.getCurrentActions(this.MODULE_DOCUMENTS, this.DOCUMENTS_DOCUMENTS)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                
                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr style={{backgroundColor: '#f8f9fc'}}>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Administration')}</td>
                                <td style={{width: 130}}><Checkbox id={'administration-all'} isChecked={this.isMainChecked(this.ADMINISTRATION)}
                                              onChange={(value) => this.updateMain(this.ADMINISTRATION, value)}/></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Analysis')}</td>
                                <td><Checkbox id={'administration-analysis-all'}
                                              isChecked={this.ADMINISTRATION_ANALYSIS in administrationRole && (administrationRole[this.ADMINISTRATION_ANALYSIS].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_ANALYSIS].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_ANALYSIS].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_ANALYSIS].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ANALYSIS, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-analysis-view'}
                                              isChecked={this.ADMINISTRATION_ANALYSIS in administrationRole && administrationRole[this.ADMINISTRATION_ANALYSIS].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ANALYSIS, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-analysis-create'}
                                              isChecked={this.ADMINISTRATION_ANALYSIS in administrationRole && administrationRole[this.ADMINISTRATION_ANALYSIS].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ANALYSIS, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-analysis-edit'}
                                              isChecked={this.ADMINISTRATION_ANALYSIS in administrationRole && administrationRole[this.ADMINISTRATION_ANALYSIS].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ANALYSIS, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-analysis-delete'}
                                              isChecked={this.ADMINISTRATION_ANALYSIS in administrationRole && administrationRole[this.ADMINISTRATION_ANALYSIS].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ANALYSIS, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_ANALYSIS)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Currencies')}</td>
                                <td><Checkbox id={'administration-currencies-all'}
                                              isChecked={this.ADMINISTRATION_CURRENCIES in administrationRole && (administrationRole[this.ADMINISTRATION_CURRENCIES].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_CURRENCIES].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_CURRENCIES].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_CURRENCIES].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_CURRENCIES, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-currencies-view'}
                                              isChecked={this.ADMINISTRATION_CURRENCIES in administrationRole && administrationRole[this.ADMINISTRATION_CURRENCIES].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_CURRENCIES, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-currencies-create'}
                                              isChecked={this.ADMINISTRATION_CURRENCIES in administrationRole && administrationRole[this.ADMINISTRATION_CURRENCIES].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_CURRENCIES, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-currencies-edit'}
                                              isChecked={this.ADMINISTRATION_CURRENCIES in administrationRole && administrationRole[this.ADMINISTRATION_CURRENCIES].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_CURRENCIES, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-currencies-delete'}
                                              isChecked={this.ADMINISTRATION_CURRENCIES in administrationRole && administrationRole[this.ADMINISTRATION_CURRENCIES].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_CURRENCIES, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_CURRENCIES)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Events')}</td>
                                <td><Checkbox id={'administration-events-all'}
                                              isChecked={this.ADMINISTRATION_EVENTS in administrationRole && (administrationRole[this.ADMINISTRATION_EVENTS].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_EVENTS].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_EVENTS].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_EVENTS].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_EVENTS, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-events-view'}
                                              isChecked={this.ADMINISTRATION_EVENTS in administrationRole && administrationRole[this.ADMINISTRATION_EVENTS].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_EVENTS, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-events-create'}
                                              isChecked={this.ADMINISTRATION_EVENTS in administrationRole && administrationRole[this.ADMINISTRATION_EVENTS].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_EVENTS, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-events-edit'}
                                              isChecked={this.ADMINISTRATION_EVENTS in administrationRole && administrationRole[this.ADMINISTRATION_EVENTS].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_EVENTS, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-events-delete'}
                                              isChecked={this.ADMINISTRATION_EVENTS in administrationRole && administrationRole[this.ADMINISTRATION_EVENTS].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_EVENTS, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_EVENTS)}</td>
                            </tr>
                             <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Taxes')}</td>
                                <td><Checkbox id={'administration-taxes-all'}
                                              isChecked={this.ADMINISTRATION_TAXES in administrationRole && (administrationRole[this.ADMINISTRATION_TAXES].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_TAXES].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_TAXES].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_TAXES].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_TAXES, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-taxes-view'}
                                              isChecked={this.ADMINISTRATION_TAXES in administrationRole && administrationRole[this.ADMINISTRATION_TAXES].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_TAXES, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-taxes-create'}
                                              isChecked={this.ADMINISTRATION_TAXES in administrationRole && administrationRole[this.ADMINISTRATION_TAXES].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_TAXES, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-taxes-edit'}
                                              isChecked={this.ADMINISTRATION_TAXES in administrationRole && administrationRole[this.ADMINISTRATION_TAXES].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_TAXES, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-taxes-delete'}
                                              isChecked={this.ADMINISTRATION_TAXES in administrationRole && administrationRole[this.ADMINISTRATION_TAXES].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_TAXES, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_TAXES)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Items')}</td>
                                <td><Checkbox id={'administration-items-all'}
                                              isChecked={this.ADMINISTRATION_ITEMS in administrationRole && (administrationRole[this.ADMINISTRATION_ITEMS].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_ITEMS].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_ITEMS].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_ITEMS].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ITEMS, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-items-view'}
                                              isChecked={this.ADMINISTRATION_ITEMS in administrationRole && administrationRole[this.ADMINISTRATION_ITEMS].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ITEMS, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-items-create'}
                                              isChecked={this.ADMINISTRATION_ITEMS in administrationRole && administrationRole[this.ADMINISTRATION_ITEMS].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ITEMS, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-items-edit'}
                                              isChecked={this.ADMINISTRATION_ITEMS in administrationRole && administrationRole[this.ADMINISTRATION_ITEMS].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ITEMS, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-items-delete'}
                                              isChecked={this.ADMINISTRATION_ITEMS in administrationRole && administrationRole[this.ADMINISTRATION_ITEMS].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ITEMS, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_ITEMS)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Measures')}</td>
                                <td><Checkbox id={'administration-measures-all'}
                                              isChecked={this.ADMINISTRATION_MEASURES in administrationRole && (administrationRole[this.ADMINISTRATION_MEASURES].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_MEASURES].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_MEASURES].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_MEASURES].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_MEASURES, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-measures-view'}
                                              isChecked={this.ADMINISTRATION_MEASURES in administrationRole && administrationRole[this.ADMINISTRATION_MEASURES].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_MEASURES, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-measures-create'}
                                              isChecked={this.ADMINISTRATION_MEASURES in administrationRole && administrationRole[this.ADMINISTRATION_MEASURES].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_MEASURES, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-measures-edit'}
                                              isChecked={this.ADMINISTRATION_MEASURES in administrationRole && administrationRole[this.ADMINISTRATION_MEASURES].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_MEASURES, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-measures-delete'}
                                              isChecked={this.ADMINISTRATION_MEASURES in administrationRole && administrationRole[this.ADMINISTRATION_MEASURES].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_MEASURES, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_MEASURES)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Customers')}</td>
                                <td><Checkbox id={'administration-customers-all'}
                                              isChecked={this.ADMINISTRATION_CUSTOMERS in administrationRole && (administrationRole[this.ADMINISTRATION_CUSTOMERS].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_CUSTOMERS].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_CUSTOMERS].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_CUSTOMERS].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_CUSTOMERS, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-customers-view'}
                                              isChecked={this.ADMINISTRATION_CUSTOMERS in administrationRole && administrationRole[this.ADMINISTRATION_CUSTOMERS].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_CUSTOMERS, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-customers-create'}
                                              isChecked={this.ADMINISTRATION_CUSTOMERS in administrationRole && administrationRole[this.ADMINISTRATION_CUSTOMERS].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_CUSTOMERS, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-customers-edit'}
                                              isChecked={this.ADMINISTRATION_CUSTOMERS in administrationRole && administrationRole[this.ADMINISTRATION_CUSTOMERS].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_CUSTOMERS, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-customers-delete'}
                                              isChecked={this.ADMINISTRATION_CUSTOMERS in administrationRole && administrationRole[this.ADMINISTRATION_CUSTOMERS].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_CUSTOMERS, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_CUSTOMERS)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Locations')}</td>
                                <td><Checkbox id={'administration-locations-all'}
                                              isChecked={this.ADMINISTRATION_LOCATIONS in administrationRole && (administrationRole[this.ADMINISTRATION_LOCATIONS].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_LOCATIONS].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_LOCATIONS].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_LOCATIONS].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_LOCATIONS, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-locations-view'}
                                              isChecked={this.ADMINISTRATION_LOCATIONS in administrationRole && administrationRole[this.ADMINISTRATION_LOCATIONS].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_LOCATIONS, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-locations-create'}
                                              isChecked={this.ADMINISTRATION_LOCATIONS in administrationRole && administrationRole[this.ADMINISTRATION_LOCATIONS].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_LOCATIONS, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-locations-edit'}
                                              isChecked={this.ADMINISTRATION_LOCATIONS in administrationRole && administrationRole[this.ADMINISTRATION_LOCATIONS].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_LOCATIONS, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-locations-delete'}
                                              isChecked={this.ADMINISTRATION_LOCATIONS in administrationRole && administrationRole[this.ADMINISTRATION_LOCATIONS].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_LOCATIONS, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_LOCATIONS)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Payment Methods')}</td>
                                <td><Checkbox id={'administration-payment-methods-all'}
                                              isChecked={this.ADMINISTRATION_PAYMENT_METHODS in administrationRole && (administrationRole[this.ADMINISTRATION_PAYMENT_METHODS].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_PAYMENT_METHODS].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_PAYMENT_METHODS].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_PAYMENT_METHODS].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PAYMENT_METHODS, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-payment-methods-view'}
                                              isChecked={this.ADMINISTRATION_PAYMENT_METHODS in administrationRole && administrationRole[this.ADMINISTRATION_PAYMENT_METHODS].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PAYMENT_METHODS, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-payment-methods-create'}
                                              isChecked={this.ADMINISTRATION_PAYMENT_METHODS in administrationRole && administrationRole[this.ADMINISTRATION_PAYMENT_METHODS].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PAYMENT_METHODS, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-payment-methods-edit'}
                                              isChecked={this.ADMINISTRATION_PAYMENT_METHODS in administrationRole && administrationRole[this.ADMINISTRATION_PAYMENT_METHODS].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PAYMENT_METHODS, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-payment-methods-delete'}
                                              isChecked={this.ADMINISTRATION_PAYMENT_METHODS in administrationRole && administrationRole[this.ADMINISTRATION_PAYMENT_METHODS].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PAYMENT_METHODS, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_PAYMENT_METHODS)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Templates')}</td>
                                <td><Checkbox id={'administration-templates-all'}
                                              isChecked={this.ADMINISTRATION_TEMPLATES in administrationRole && (administrationRole[this.ADMINISTRATION_TEMPLATES].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_TEMPLATES].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_TEMPLATES].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_TEMPLATES].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_TEMPLATES, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-templates-view'}
                                              isChecked={this.ADMINISTRATION_TEMPLATES in administrationRole && administrationRole[this.ADMINISTRATION_TEMPLATES].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_TEMPLATES, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-templates-create'}
                                              isChecked={this.ADMINISTRATION_TEMPLATES in administrationRole && administrationRole[this.ADMINISTRATION_TEMPLATES].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_TEMPLATES, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-templates-edit'}
                                              isChecked={this.ADMINISTRATION_TEMPLATES in administrationRole && administrationRole[this.ADMINISTRATION_TEMPLATES].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_TEMPLATES, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-templates-delete'}
                                              isChecked={this.ADMINISTRATION_TEMPLATES in administrationRole && administrationRole[this.ADMINISTRATION_TEMPLATES].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_TEMPLATES, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_TEMPLATES)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Localizaton')}</td>
                                <td><Checkbox id={'administration-localization-all'}
                                              isChecked={this.ADMINISTRATION_LOCALIZATION in administrationRole && (administrationRole[this.ADMINISTRATION_LOCALIZATION].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_LOCALIZATION].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_LOCALIZATION].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_LOCALIZATION].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_LOCALIZATION, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-localization-view'}
                                              isChecked={this.ADMINISTRATION_LOCALIZATION in administrationRole && administrationRole[this.ADMINISTRATION_LOCALIZATION].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_LOCALIZATION, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-localization-create'}
                                              isChecked={this.ADMINISTRATION_LOCALIZATION in administrationRole && administrationRole[this.ADMINISTRATION_LOCALIZATION].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_LOCALIZATION, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-localization-edit'}
                                              isChecked={this.ADMINISTRATION_LOCALIZATION in administrationRole && administrationRole[this.ADMINISTRATION_LOCALIZATION].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_LOCALIZATION, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-localization-delete'}
                                              isChecked={this.ADMINISTRATION_LOCALIZATION in administrationRole && administrationRole[this.ADMINISTRATION_LOCALIZATION].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_LOCALIZATION, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_LOCALIZATION)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Other')}</td>
                                <td><Checkbox id={'administration-other-all'}
                                              isChecked={this.ADMINISTRATION_OTHER in administrationRole && (administrationRole[this.ADMINISTRATION_OTHER].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_OTHER].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_OTHER].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_OTHER].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_OTHER, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-other-view'}
                                              isChecked={this.ADMINISTRATION_OTHER in administrationRole && administrationRole[this.ADMINISTRATION_OTHER].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_OTHER, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-other-create'}
                                              isChecked={this.ADMINISTRATION_OTHER in administrationRole && administrationRole[this.ADMINISTRATION_OTHER].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_OTHER, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-other-edit'}
                                              isChecked={this.ADMINISTRATION_OTHER in administrationRole && administrationRole[this.ADMINISTRATION_OTHER].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_OTHER, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-other-delete'}
                                              isChecked={this.ADMINISTRATION_OTHER in administrationRole && administrationRole[this.ADMINISTRATION_OTHER].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_OTHER, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_OTHER)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Pricing')}</td>
                                <td><Checkbox id={'administration-pricing-all'}
                                              isChecked={this.ADMINISTRATION_PRICING in administrationRole && (administrationRole[this.ADMINISTRATION_PRICING].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_PRICING].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_PRICING].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_PRICING].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PRICING, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-pricing-view'}
                                              isChecked={this.ADMINISTRATION_PRICING in administrationRole && administrationRole[this.ADMINISTRATION_PRICING].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PRICING, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-pricing-create'}
                                              isChecked={this.ADMINISTRATION_PRICING in administrationRole && administrationRole[this.ADMINISTRATION_PRICING].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PRICING, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-pricing-edit'}
                                              isChecked={this.ADMINISTRATION_PRICING in administrationRole && administrationRole[this.ADMINISTRATION_PRICING].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PRICING, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-pricing-delete'}
                                              isChecked={this.ADMINISTRATION_PRICING in administrationRole && administrationRole[this.ADMINISTRATION_PRICING].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PRICING, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_PRICING)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Online users')}</td>
                                <td><Checkbox id={'administration-online-users-all'}
                                              isChecked={this.ADMINISTRATION_ONLINE_USERS in administrationRole && (administrationRole[this.ADMINISTRATION_ONLINE_USERS].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_ONLINE_USERS].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_ONLINE_USERS].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_ONLINE_USERS].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ONLINE_USERS, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-online-users-view'}
                                              isChecked={this.ADMINISTRATION_ONLINE_USERS in administrationRole && administrationRole[this.ADMINISTRATION_ONLINE_USERS].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ONLINE_USERS, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-online-users-create'}
                                              isChecked={this.ADMINISTRATION_ONLINE_USERS in administrationRole && administrationRole[this.ADMINISTRATION_ONLINE_USERS].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ONLINE_USERS, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-online-users-edit'}
                                              isChecked={this.ADMINISTRATION_ONLINE_USERS in administrationRole && administrationRole[this.ADMINISTRATION_ONLINE_USERS].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ONLINE_USERS, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-online-users-delete'}
                                              isChecked={this.ADMINISTRATION_ONLINE_USERS in administrationRole && administrationRole[this.ADMINISTRATION_ONLINE_USERS].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_ONLINE_USERS, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_ONLINE_USERS)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Portal users')}</td>
                                <td><Checkbox id={'administration-portal-users-all'}
                                              isChecked={this.ADMINISTRATION_PORTAL_USERS in administrationRole && (administrationRole[this.ADMINISTRATION_PORTAL_USERS].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.ADMINISTRATION_PORTAL_USERS].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.ADMINISTRATION_PORTAL_USERS].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.ADMINISTRATION_PORTAL_USERS].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PORTAL_USERS, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-portal-users-view'}
                                              isChecked={this.ADMINISTRATION_PORTAL_USERS in administrationRole && administrationRole[this.ADMINISTRATION_PORTAL_USERS].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PORTAL_USERS, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-portale-users-create'}
                                              isChecked={this.ADMINISTRATION_PORTAL_USERS in administrationRole && administrationRole[this.ADMINISTRATION_PORTAL_USERS].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PORTAL_USERS, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-portal-users-edit'}
                                              isChecked={this.ADMINISTRATION_PORTAL_USERS in administrationRole && administrationRole[this.ADMINISTRATION_PORTAL_USERS].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PORTAL_USERS, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-portal-users-delete'}
                                              isChecked={this.ADMINISTRATION_PORTAL_USERS in administrationRole && administrationRole[this.ADMINISTRATION_PORTAL_USERS].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.ADMINISTRATION_PORTAL_USERS, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.ADMINISTRATION_PORTAL_USERS)}</td>
                            </tr>
                            <tr>
                                <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Location Places')}</td>
                                <td><Checkbox id={'administration-location-places-all'}
                                              isChecked={this.LOCATION_PLACES in administrationRole && (administrationRole[this.LOCATION_PLACES].includes(this.ACTIONS.VIEW)
                                                  || administrationRole[this.LOCATION_PLACES].includes(this.ACTIONS.CREATE)
                                                  || administrationRole[this.LOCATION_PLACES].includes(this.ACTIONS.EDIT)
                                                  || administrationRole[this.LOCATION_PLACES].includes(this.ACTIONS.DELETE))}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.LOCATION_PLACES, 'ALL', value)}/>
                                </td>
                                <td><Checkbox id={'administration-location-places-view'}
                                              isChecked={this.LOCATION_PLACES in administrationRole && administrationRole[this.LOCATION_PLACES].includes(this.ACTIONS.VIEW)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.LOCATION_PLACES, this.ACTIONS.VIEW, value)}/>
                                </td>
                                <td><Checkbox id={'administration-location-places-create'}
                                              isChecked={this.LOCATION_PLACES in administrationRole && administrationRole[this.LOCATION_PLACES].includes(this.ACTIONS.CREATE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.LOCATION_PLACES, this.ACTIONS.CREATE, value)}/>
                                </td>
                                <td><Checkbox id={'administration-location-places-edit'}
                                              isChecked={this.LOCATION_PLACES in administrationRole && administrationRole[this.LOCATION_PLACES].includes(this.ACTIONS.EDIT)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.LOCATION_PLACES, this.ACTIONS.EDIT, value)}/>
                                </td>
                                <td><Checkbox id={'administration-location-places-delete'}
                                              isChecked={this.LOCATION_PLACES in administrationRole && administrationRole[this.LOCATION_PLACES].includes(this.ACTIONS.DELETE)}
                                              onChange={(value) => this.updateRow(this.ADMINISTRATION, this.LOCATION_PLACES, this.ACTIONS.DELETE, value)}/>
                                </td>
                                <td style={{width: 100}}>{this.getCurrentActions(this.ADMINISTRATION, this.LOCATION_PLACES)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><Translate locale={this.props.settings.locale} value="ACCESS"/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.VIEW}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.CREATE}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.EDIT}/></th>
                            <th><Translate locale={this.props.settings.locale} value={this.ACTIONS.DELETE}/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={{backgroundColor: '#f8f9fc'}}>
                            <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale, 'Reports')}</td>
                            <td><Checkbox id={'reports-all'} isChecked={this.isMainChecked(this.REPORTS)}
                                          onChange={(value) => this.updateMain(this.REPORTS, value)}/></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        {
                            reports.map((report, key) => {
                                if(report.id == null){
                                    return (
                                        <tr key={key} style={{backgroundColor:"#f3f3f3"}}>
                                            <td className="heading" style={{width: 130}}><strong>{plainTranslate(this.props.settings.locale,report.name)}</strong></td>
                                            <td style={{width: 110}}></td>
                                            <td style={{width: 110}}></td>
                                            <td style={{width: 110}}></td>
                                            <td style={{width: 110}}></td>
                                            <td style={{width: 110}}></td>
                                            <td style={{width: 110}}></td>
                                        </tr>
                                    )
                                }else{
                                    return (
                                        <tr key={key}>
                                        <td className="heading" style={{width: 130}}>{plainTranslate(this.props.settings.locale,report.name)}</td>
                                        <td style={{width: 110}}><Checkbox id={'report-' + report.id + '-all'}
                                                      isChecked={report.id in reportsRoles && (reportsRoles[report.id].includes(this.ACTIONS.VIEW))}
                                                      onChange={(value) => this.updateRow(this.REPORTS, report.id, 'ALL', value)}/>
                                        </td>
                                        <td style={{width: 110}}><Checkbox id={'report-' + report.id + '-view'}
                                                      isChecked={report.id in reportsRoles && reportsRoles[report.id].includes(this.ACTIONS.VIEW)}
                                                      onChange={(value) => this.updateRow(this.REPORTS, report.id, this.ACTIONS.VIEW, value)}/>
                                        </td>
                                        <td style={{width: 110}}></td>
                                        <td style={{width: 110}}></td>
                                        <td style={{width: 110}}></td>
                                        <td style={{width: 110}}>{this.getCurrentActions(this.REPORTS, report.id)}</td>
                                    </tr>
                                    )
                                }
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RolesTable);
