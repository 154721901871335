import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from "ag-grid-react";
import 'ag-grid/src/styles/ag-grid.scss';
import 'ag-grid/src/styles/ag-theme-balham.scss';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ModalConfirm from '../common/ModalConfirm';
import Translate, { plainTranslate } from '../common/translate';
import { DatePicker as DatePickerComponent, Modal, Select } from 'antd';
import showAToast from '../common/showAToast';
import {
    GRID_MOUNT,
    GRID_CONFIRM_DIALOG_SHOW,
    GRID_DATA_LOADED,
    GRID_HEADER_SHOW_LOADER
} from "../../constants/actionTypes";
import {
    onGridMount,
    onGridFetchData,
    onCustomGridFetchData,
    onReportApplyFilters,
    onReportFetchData,
    onGridEmptyData,
    onGridApiLoad,
    onQuickFilterChange,
    onDatesChange,
    onRowSelection,
    onConfirmActionTrigger,
    onHeaderOptionSelected,
    onClickCalculateButtonClick,
    onGridExportClick,
    onColumnUpdate,
    onReportExportClick,
    openViewHandler
} from "../../actions/actions";
import Header from "./header";
import BulkActions from "./bulkActions";
import Columns from './columns';
import BooleanRenderer from "./boolean-renderer";
import DateRenderer from "./date-renderer";
import HtmlRenderer from "./html-renderer";
import InOutRenderer from "./in-out-renderer";
import CustomClassRenderer from "./custom-class-renderer";
import CustomNewRenderer from "./custom-new-renderer";
import CustomColorRenderer from "./custom-color-renderer";
import CustomCellRenderer from './custom-cell-renderer';
import SingleLinkRenderer from './single-link-renderer';
import ImageRenderer from './image-renderer';
import ButtonRenderer from './button-renderer';
import ItemRequestRenderer from './item-request-renderer';
import LoadingOverlay from './loading-overlay';
import GridLoader from "./grid-loader";
import SlideModal from '../common/SlideModal';
import SimpleForm from "../redux-forms/form";
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import axios from "axios";
import ViewRenderer from './custom-view-renderer'
import client from '../../actions/client';

const { RangePicker } = DatePickerComponent;
require('./grid-style.css');

class Grid extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            type: '',
            error: false,
            paginationPageSize: 100,
            currentPage: 1,
            first: true,
            prev: true,
            quickFilter: '',
            reportFilters: [],
            sortedCols: [],
            selectedRows: [],
            selectedRowsData: [],
            deSelectedRows: [],
            deSelectedRowsData: [],
            selectAll: false,
            openRangePicker: false,
            dates: [],
            startDate: null,
            endDate: null,
            selectLoading: false
        };

        this.apiLoad = false;
        this.gridLoad = false;
        this.dataLoaded = false;
this.end = null;
        this.onGridReady = this.onGridReady.bind(this);
        this.quickFilterChange = this.quickFilterChange.bind(this);
        this.handleDatesChange = this.handleDatesChange.bind(this);
        this.handleSingleDateChange = this.handleSingleDateChange.bind(this);
        this.openConfirmDialog = this.openConfirmDialog.bind(this);
        this.acceptConfirmDialog = this.acceptConfirmDialog.bind(this);
        this.updateGrid = this.updateGrid.bind(this);
        this.setDefaultFilter = this.setDefaultFilter.bind(this);
        this.clearInput = this.clearInput.bind(this);
        this.onFilterModified = this.onFilterModified.bind(this);
        this.calculateTotals = this.calculateTotals.bind(this);
        this.onSortChanged = this.onSortChanged.bind(this);
        this.onCalculateClick = this.onCalculateClick.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleShowExportOptions = this.handleShowExportOptions.bind(this);
        this.monthToComparableNumber = this.monthToComparableNumber.bind(this);
        this.dateComparator = this.dateComparator.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.onColumnMoved = this.onColumnMoved.bind(this);
        this.onColumnResized = this.onColumnResized.bind(this);
        this.updateGridColumns = this.updateGridColumns.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.searchData = this.searchData.bind(this);
        this.popUpClose = this.popUpClose.bind(this);
        this.controller = new AbortController();
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.source = axios.CancelToken.source();
        this.customPopupOpen = this.customPopupOpen.bind(this);
        this.saveCart = this.saveCart.bind(this);
    };



    componentDidMount() {
        this.props.onQuickFilterChange(null);
        this.updateGrid();
    }

    componentWillUnmount() {
        //this.controller.abort();
        this.source.cancel("grid unmounted");
    }

    updateDimensions(width) {
        let pinned = this.columnApi.getDisplayedLeftColumns();
        if (width < 767) {
            this.columnApi.setColumnsPinned(pinned, false);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id || prevProps.location.search !== this.props.location.search) {
            this.setState({ reportFilters: [] });
            this.updateGrid();
        }
        if (prevState.quickFilter !== this.state.quickFilter || prevState.filterOptions !== this.state.filterOptions) {
            this.searchData(this.state.quickFilter);
        }
    }

    updateGrid() {
        this.gridLoad = false;
        this.dataLoaded = false;
        this.props.onLoader(true);
        this.props.onEmptyData();
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }

        let gridRoute = '/'+this.props.match.params.db + this.props.gridRoute;

        let gridDataRoute = '';

        if(this.props.gridDataRoute){
            gridDataRoute = '/'+this.props.match.params.db + this.props.gridDataRoute;
        }

        const id = this.props.match.params.id;
        if (id) {
            gridRoute = gridRoute + id;
            gridDataRoute = gridDataRoute + id;
        }

        if (this.props.isReport === true) {
            if (this.props.location) {
                const urlParams = new URLSearchParams(this.props.location.search);
                const key = urlParams.get('type');
                if (key !== null) {
                    gridRoute = gridRoute + '?type=' + key;
                }
            }
        }

        this.props.onMount({ gridRoute, gridDataRoute });
        this.props.onGridMount(gridRoute)
            .then(() => {
                this.gridLoad = true;
                if (this.gridLoad && this.apiLoad) {
                    this.setDefaultFilter();
                }
                if (this.gridLoad && this.dataLoaded) {
                    this.props.onLoader(false);
                }
            });

        if (gridDataRoute) {
            let dates = "";

            //let start = moment().subtract(31, 'd');
            //let end = moment().add(1, 'd');
            let start;
            let end;

            if (localStorage.getItem('grid_startDate')) {
                start = moment(localStorage.getItem('grid_startDate'));
            }
            else if (localStorage.getItem('grid_range')) {
                start = moment().subtract(localStorage.getItem('grid_range'), 'days');
            } else {
                start = moment();
            }
            if (localStorage.getItem('grid_endDate')) {
                end = moment(localStorage.getItem('grid_endDate'));
            } else {
                end = moment();
            }

            this.props.onDatesChange(start, end);

            //end = moment().add('day', 1);
            let startDate = start.year() + '-' + (start.month() + 1) + '-' + start.date();
            let endDate = end.year() + '-' + (end.month() + 1) + '-' + end.date() + ' 23:59:59';
            dates = '?startDate=' + startDate + '&endDate=' + endDate;

            if (this.props.customPagination) {
                //this.setState({showLoader: true});
                let filters = {};
                let sort = {};
                if (this.state.sortCol && this.state.sortType) {
                    sort.col = this.state.sortCol;
                    sort.type = this.state.sortType;
                }

                //filters.tab = this.gridApi.getFilterModel();
                if (this.state.quickFilter) {
                    filters.q = this.state.quickFilter;
                }

                this.props.onCustomGridFetchData(gridDataRoute, filters, startDate, endDate, sort, 1, this.state.paginationPageSize, this.source.token).then(() => {
                    //this.setState({showLoader: false,currentPage: 1});
                    this.props.onLoader(false);
                    this.setState({ totalRecords: this.props.totalRecords, pages: this.props.pages, selectedRows: [], selectedRowsData: [], selectAll: false, pinnedRows: this.props.pinnedRows });
                });

            } else {
                this.props.onGridFetchData(gridDataRoute + dates, this.source.token).then(() => {
                    this.dataLoaded = true;
                    if (this.gridLoad && this.dataLoaded) {
                        this.props.onLoader(false);
                    }
                });
                this.setState({ startDate: start, endDate: end });
            }
        }

        if (this.props.isReport === true) {
            let start;
            let end;

            /*            if (localStorage.getItem('grid_range')) {
                            start = moment().subtract(localStorage.getItem('grid_range'), 'days');
                        } else {
                            start = moment();
                        }
                        end = moment();*/

            if (localStorage.getItem('grid_startDate')) {
                start = moment(localStorage.getItem('grid_startDate'));
            }
            else if (localStorage.getItem('grid_range')) {
                start = moment().subtract(localStorage.getItem('grid_range'), 'days');
            } else {
                start = moment();
            }
            if (localStorage.getItem('grid_endDate')) {
                end = moment(localStorage.getItem('grid_endDate'));
            } else {
                end = moment();
            }

            start = start.year() + '-' + (start.month() + 1) + '-' + start.date();
            end = end.year() + '-' + (end.month() + 1) + '-' + end.date() + ' 23:59:59';

            let route = '/' + this.props.match.params.db + this.props.filtersDataRoute;
            if (this.props.location) {
                const urlParams = new URLSearchParams(this.props.location.search);
                const key = urlParams.get('type');
                if (key !== null) {
                    route = route + '?type=' + key;
                }
                this.setState({ reportId: key });
            }
            this.setState({ startDate: start, endDate: end });

            this.props.onReportFetchData(route, [], start, end, this.state.currentPage, this.state.sortedCols, false, this.source.token).then(() => {
                if (this.props.data.redirectUrl) {
                    this.props.history.push(this.props.data.redirectUrl);
                }
                this.setState({ totalRecords: this.props.totalRecords, pages: this.props.pages, pinnedRows: this.props.pinnedRows });
                this.props.onLoader(false);
            });
        }

    }

    setDefaultFilter(selectedFilter = false) {
        const header = this.props.gridProperties.header;
        let toCheck = selectedFilter ? selectedFilter : header ? header.selectedFilter : null;
        if (header && toCheck) {
            header.options.forEach(option => {
                if (option.label === toCheck) {
                    this.props.onHeaderOptionSelected(option);
                }
            });
        }
    }

    onGridReady(params) {
        //        var defaultColumns =this.props.gridProperties.columns;
        //        var flattenCols = [];
        //
        //        for(var i=0; i<defaultColumns.length; i++){
        //            flattenCols.push(defaultColumns[i]);
        //            if ('children' in defaultColumns[i]) {
        //                for (var ind = 0; ind < defaultColumns[i].children.length; ind++){
        //                    flattenCols.push(defaultColumns[i].children[ind]);
        //                }
        //           }
        //        }
        //        this.setState({'columnsDefault' : flattenCols});
        //        this.props.onGetColumns(this.props.gridProperties.options.enableColumns)
        //                .then(() => {
        //                    this.setState({'columns' : params.columnApi.getColumnState()});
        //                });
        this.props.onGridApiLoad(params.api, params.columnApi, this.props.gridProperties.header)
            .then(() => {
                this.apiLoad = true;
                if (this.gridLoad && this.apiLoad) {
                    this.setDefaultFilter();
                }
            });
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.setDefaultFilter();
        
        if (this.props.isReport === true) {
            document.title = this.props.gridProperties.header.title;
        }
        this.setState({ data: this.props.data });
        this.setState({ pinnedRows: this.props.pinnedRows });
        this.quickFilterChange('');
        this.updateDimensions(window.innerWidth);
        this.setState({ 'columns': params.columnApi.getColumnState() });
        //this.props.onGetColumns(this.props.gridProperties.options.enableColumns);
    }

    updateGridColumns() {
        this.setState({ 'columns': this.columnApi.getColumnState() })
    }

    searchData(val) {
        if (this.props.customPagination) {
            let params = "";
            let start = this.props.gridProperties.startDate;
            let end = this.props.gridProperties.endDate;

            start = start.year() + '-' + (start.month() + 1) + '-' + start.date();
            end = end.year() + '-' + (end.month() + 1) + '-' + end.date() + ' 23:59:59';

            this.setState({ showLoader: true });
            let filters = {};
            let sort = {};
            if (this.state.sortCol && this.state.sortType) {
                sort.col = this.state.sortCol;
                sort.type = this.state.sortType;
            }

            //filters.tab = this.gridApi.getFilterModel();
            filters.q = this.state.quickFilter;
            if (this.state.filterOptions) {
                filters.columnFilters = this.state.filterOptions;
            }

            this.props.onCustomGridFetchData(this.props.gridProperties.gridDataRoute, filters, start, end, sort, 1, this.state.paginationPageSize, this.source.token).then(() => {
                this.setState({ showLoader: false, currentPage: 1, totalRecords: this.props.totalRecords, pages: this.props.pages, selectedRows: [], selectedRowsData: [], selectAll: false, pinnedRows: this.props.pinnedRows });
                this.gridApi.setFilterModel(this.state.selectedFilters);
            });
        }
    }

    quickFilterChange(val) {
        if (this.props.customPagination) {

            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(() => {
                this.setState({
                    quickFilter: val
                });
            }, 2000);
        } else {

            this.props.onQuickFilterChange(val);
        }

    }

    onSortChanged() {

        let sortedCols = [];
        this.columnApi.columnController.gridColumns.map(col => {
            if (col.sort) {
                sortedCols.push(col.colId);
                sortedCols.push(col.sort);
            }
        });

        if (this.props.isReport) {
            this.setState({ showLoader: true });
            let route = '/' + this.props.match.params.db + this.props.filtersDataRoute;
            if (this.props.location) {
                const urlParams = new URLSearchParams(this.props.location.search);
                const key = urlParams.get('type');
                if (key !== null) {
                    route = route + '?type=' + key;
                }
            }

            this.props.onReportFetchData(route, this.state.reportFilters, this.state.startDate, this.state.endDate, this.state.currentPage, sortedCols, true, this.source.token).then(() => {
                this.setState({ showLoader: false });
            });

        } else if (this.props.customPagination) {

            this.setState({ showLoader: true });
            let params = "";
            let start = this.props.gridProperties.startDate;
            let end = this.props.gridProperties.endDate;
            let sortCol = null;
            let sortType = null;

            start = start.year() + '-' + (start.month() + 1) + '-' + start.date();
            end = end.year() + '-' + (end.month() + 1) + '-' + end.date() + ' 23:59:59';

            let sortedCols = [];
            this.columnApi.columnController.gridColumns.map(col => {
                if (col.sort) {
                    sortCol = col.colId;
                    sortType = col.sort;
                }
            });

            this.setState({ sortCol: sortCol, sortType: sortType });

            this.setState({ showLoader: true });

            let filters = {};
            let sort = {};

            if (sortCol && sortType) {
                sort.col = sortCol;
                sort.type = sortType;
            }

            //filters.tab = this.gridApi.getFilterModel();
            if (this.state.quickFilter) {
                filters.q = this.state.quickFilter;
            }
            if (this.state.filterOptions) {
                filters.columnFilters = this.state.filterOptions;
            }
            this.props.onCustomGridFetchData(this.props.gridProperties.gridDataRoute, filters, start, end, sort, this.state.currentPage, this.state.paginationPageSize, this.source.token, false).then(() => {
                this.setState({ showLoader: false, currentPage: 1 });

            });
        }
        this.setState({ sortedCols: sortedCols });
    }

    onColumnMoved(params) {
        var columnState = JSON.stringify(params.columnApi.getColumnState());

        clearTimeout(this.inputTimer);
        this.inputTimer = setTimeout(() => {
            this.props.onColumnUpdate(this.props.gridProperties.options.enableColumns, columnState).then(() => {
                showAToast(plainTranslate(this.props.settings.locale, 'Columns reordered successfully!'), 'succSettings', 'success');
            });
        }, 2000);
    }

    onColumnResized(params) {
        var columnState = JSON.stringify(params.columnApi.getColumnState());

        clearTimeout(this.inputTimer);
        this.inputTimer = setTimeout(() => {
            this.props.onColumnUpdate(this.props.gridProperties.options.enableColumns, columnState).then(() => {
                showAToast(plainTranslate(this.props.settings.locale, 'Columns resized successfully!'), 'succSettings', 'success');
            });
        }, 2000);
    }

    onCalendarOpen = (open) => { 
        this.setState({
            openRangePicker: open
        })
        setTimeout(() => {
            if(this.state.dateChanged === true && open === false && this.state.startDate && this.state.endDate){
                this.handleDatesChange([this.state.startDate, this.state.endDate]);
                this.setState({dateChanged: false});
            }
        }, 1000)
    }

    onCalendarChange = (dates, dateStrings, range) => {
        let start = dates[0] ? dates[0] : null;
        let end = dates[1] ? dates[1] : null;
        let daysDiff = (moment(end).diff(moment(start), 'days'));

        if (daysDiff < 31) {
            localStorage.setItem('grid_range', daysDiff);
            localStorage.setItem('grid_startDate', moment(start));
            localStorage.setItem('grid_endDate', moment(end));
            this.props.onDatesChange(start, end);
        }

        if(start){            
            start = dates[0].format('YYYY-MM-DD')//start.year() + '-' + (start.month() + 1) + '-' + start.date();
           
            this.setState({
                startDate:start
            })
        }
        if(end){            
            end = dates[1].format('YYYY-MM-DD')+ ' 23:59:59'//end.year() + '-' + (end.month() + 1) + '-' + end.date() + ' 23:59:59';
            
            this.end = end
            this.setState({
                endDate: end
            })
        }  
        this.setState({dateChanged: true});
    }

    handleDatesChange(event) {       
        let start = event[0];
        let end = event[1];

        let selectedFilter = this.props.gridProperties.header.selectedFilter;

        let params = '?startDate=' + start + '&endDate=' + end;
        if (this.props.customPagination) {
            params += '&pageSize=' + this.state.paginationPageSize + '&page=1';
            if (this.state.sortCol && this.state.sortCol !== null && this.state.sortType && this.state.sortType !== null) {
                params += '&sortCol=' + this.state.sortCol + '&sortType=' + this.state.sortType
            }
        }
        if (this.props.isReport) {
            this.setState({ showLoader: true });
            let route = '/' + this.props.match.params.db + this.props.filtersDataRoute;
            if (this.props.location) {
                const urlParams = new URLSearchParams(this.props.location.search);
                const key = urlParams.get('type');
                if (key !== null) {
                    route = route + '?type=' + key;
                }
                this.setState({ reportId: key });
            }

            let filters = this.state.reportFilters;
            filters.startDate = start;
            filters.endDate = end;

            this.setState({ startDate: start, endDate: end, reportFilters: filters });

            this.source.cancel("cancel");
            this.source = axios.CancelToken.source();

            let isSorted = false;

            let cols = this.columnApi ? this.columnApi.getAllDisplayedColumns() : [];
            cols.map((value) => {
                if (value.colId === this.state.sortedCols[0]) {
                    isSorted = true;
                }
            });

            if (isSorted === false) {
                this.setState({ sortedCols: [] });
            }

            this.props.onReportFetchData(route, this.state.reportFilters, start, end, this.state.currentPage, isSorted === true ? this.state.sortedCols : [], false, this.source.token).then(() => {
                if (this.props.data.redirectUrl) {
                    this.props.history.push(this.props.data.redirectUrl);
                }
                this.setState({ totalRecords: this.props.totalRecords, pages: this.props.pages, pinnedRows: this.props.pinnedRows, currentPage: 1 });
                this.setState({ showLoader: false });
                if (this.props.gridProperties.showLoader) {
                    this.props.onLoader(false);
                }
            });
        } else {
            if (this.props.customPagination) {

                this.setState({ showLoader: true });
                let filters = {};
                let sort = {};
                if (this.state.sortCol && this.state.sortType) {
                    sort.col = this.state.sortCol;
                    sort.type = this.state.sortType;
                }

                //filters.tab = this.gridApi.getFilterModel();
                filters.q = this.state.quickFilter;
                if (this.state.filterOptions) {
                    filters.columnFilters = this.state.filterOptions;
                }
                this.props.onCustomGridFetchData(this.props.gridProperties.gridDataRoute, filters, start, end, sort, 1, this.state.paginationPageSize, this.source.token).then(() => {
                    this.setState({ showLoader: false, currentPage: 1, totalRecords: this.props.totalRecords, pages: this.props.pages, selectedRows: [], selectedRowsData: [], selectAll: false, pinnedRows: this.props.pinnedRows });

                });
            } else {
                this.source.cancel("cancel");
                this.source = axios.CancelToken.source();
                this.props.onGridFetchData(this.props.gridProperties.gridDataRoute + params, this.source.token)
                    .then(() => {
                        this.setDefaultFilter(selectedFilter);
                        this.setState({ currentPage: 1 });
                    });
                this.setState({ startDate: start, endDate: end, totalRecords: this.props.totalRecords, pages: this.props.pages, pinnedRows: this.props.pinnedRows });
            }
        }
    }

    onCalculateClick(event) {
        let cols = this.columnApi.getAllDisplayedColumns();
        let visibleColumns = {};
        let i = 0;
        cols.map((value) => {
            if (value.colDef.isDimension === true) {
                visibleColumns[i] = [];
                visibleColumns[i].push(value.colDef.field);
                visibleColumns[i].push(value.colDef.headerName);
                visibleColumns[i].push(value.colDef.type);

                i++;
            }
        });
        let selectedFilters = this.gridApi.getFilterModel();
        let start = moment(this.state.startDate);
        let end = moment(this.state.endDate);

        start = start.year() + '-' + (start.month() + 1) + '-' + start.date();
        end = end.year() + '-' + (end.month() + 1) + '-' + end.date() + ' 23:59:59';

        let route = '/' + this.props.match.params.db + this.props.gridDataRoute + this.props.match.params.id + '/calculateTotal';
        this.props.onCalculateButtonClick(route, this.state.filterOptions, start, end, this.props.gridProperties.quickFilter, this.state.sortedCols, visibleColumns).then(() => {
            this.gridApi.setFilterModel(selectedFilters);
        });
    }

    calculateTotals(data) {
        this.setState({ pinnedRows: data });
    }

    handleSingleDateChange(event) {

        let date = event;

        localStorage.setItem('grid_endDate', moment(date));

        date = date.year() + '-' + (date.month() + 1) + '-' + date.date() + ' 23:59:59';;
        if (this.props.isReport) {
            this.setState({ showLoader: true });
            let route = '/' + this.props.match.params.db + this.props.filtersDataRoute;
            if (this.props.location) {
                const urlParams = new URLSearchParams(this.props.location.search);
                const key = urlParams.get('type');
                if (key !== null) {
                    route = route + '?type=' + key;
                }
                this.setState({ reportId: key });
            }

            let filters = this.state.reportFilters;
            filters.endDate = date;

            this.setState({ endDate: date, reportFilters: filters });

            this.props.onReportFetchData(route, this.state.reportFilters, null, date, this.state.currentPage, this.state.sortedCols, false, this.source.token).then(() => {
                if (this.props.data.redirectUrl) {
                    this.props.history.push(this.props.data.redirectUrl);
                }
                this.setState({ totalRecords: this.props.totalRecords, pages: this.props.pages, pinnedRows: this.props.pinnedRows });
                this.setState({ showLoader: false });
            });
        } else {
            let selectedFilter = this.props.gridProperties.header.selectedFilter;
            this.props.onGridFetchData(this.props.gridProperties.gridDataRoute + '?endDate=' + date, this.source.token)
                .then(() => {
                    this.setDefaultFilter(selectedFilter);
                });
            this.setState({ endDate: date, totalRecords: this.props.totalRecords, pages: this.props.pages });
        }

    }

    openConfirmDialog() {
        this.props.onConfirmDialogShow();
    }

    acceptConfirmDialog() {
        this.props.onConfirmActionTrigger(this.props.gridProperties.confirm.payload)
            .then((response) => {
                if('updateGridData' in response){
                    this.updateGrid();
                }
               /* if ('errors' in response) {
                    this.props.showMessage(response.errors, true);
                    setTimeout(() => this.props.hideMessage(), 5000);
                }
                else {
                    this.props.showMessage(response.success, false);
                    setTimeout(() => this.props.hideMessage(), 5000);
                }*/
            })
    }

    onFilterModified() {
        let selectedFilters = this.gridApi.getFilterModel();
        //this.setState({filterOptions: false});
        var gridFilters = this.gridApi.filterManager.allFilters;
        let filters = Object.entries(gridFilters);

        var filterOptions = [];

        filters.map((value) => {
            var items = { 'id': value[0], 'filter': this.gridApi.getFilterInstance(value[0]).filter, 'filterText': this.gridApi.getFilterInstance(value[0]).filterText };
            filterOptions.push(items);
        })

        if (this.props.customPagination) {
            let params = "";
            let start = this.props.gridProperties.startDate;
            let end = this.props.gridProperties.endDate;

            start = start.year() + '-' + (start.month() + 1) + '-' + start.date();
            end = end.year() + '-' + (end.month() + 1) + '-' + end.date() + ' 23:59:59';

            let filters = {};
            let sort = {};
            if (this.state.sortCol && this.state.sortType) {
                sort.col = this.state.sortCol;
                sort.type = this.state.sortType;
            }

            //filters.tab = this.gridApi.getFilterModel();
            filters.q = this.state.quickFilter;
            if (filterOptions) {
                filters.columnFilters = filterOptions;
            }

        }
        clearTimeout(this.inputTimer);
        this.inputTimer = setTimeout(() => {
            this.setState({ filterOptions: filterOptions, selectedFilters: selectedFilters });
        }, 2000);

    }

    clearInput() {
        this.refs.searchString.value = '';
        this.quickFilterChange('');
    }

    stringComparator(string1, string2) {

        if (string1) {
            string1 = string1.toLowerCase();
        }
        if (string2) {
            string2 = string2.toLowerCase();
        }

        if (string1 < string2)
            return -1;
        if (string1 > string2)
            return 1;
        return 0;
    }

    numberComparator(number1, number2) {

        if (number1 === null && number2 === null) {
            return 0;
        }
        if (number1 === null) {
            return -1;
        }
        if (number2 === null) {
            return 1;
        }
        return number1 - number2;
    }

    dateComparator(date1, date2) {
        var date1Number = this.monthToComparableNumber(date1);
        var date2Number = this.monthToComparableNumber(date2);

        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }

    monthToComparableNumber(date) {
        if (date === undefined || date === null || date.length !== 10) {
            return null;
        }
        var yearNumber = date.substring(6, 10);
        var monthNumber = date.substring(3, 5);
        var dayNumber = date.substring(0, 2);
        var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
        return result;
    }

    handleShowExportOptions() {
        this.setState({ showExportOptions: true });
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ showExportOptions: false });
        }
    }

    handleExport(type, template = null) {
        let exportAction = null;

        if (this.props.isReport) {
            let route = '/' + this.props.match.params.db + this.props.gridExportRoute;
            if (this.props.location) {
                const urlParams = new URLSearchParams(this.props.location.search);
                const key = urlParams.get('type');
                if (key !== null) {
                    route = route + '?type=' + key;
                }
            }

            this.props.onReportExportClick(route, this.state.reportFilters, this.state.startDate, this.state.endDate, this.state.sortedCols, type);
        } else {
            let cols = this.columnApi.getAllDisplayedColumns();
            let visibleColumns = {};
            let i = 0;
            cols.map((value) => {
                if (value.colDef.field !== 'id' && value.colDef.field !== '0' && value.colDef.field && value.colDef.field.length > 0) {
                    visibleColumns[i] = [];
                    visibleColumns[i].push(value.colDef.field);
                    visibleColumns[i].push(value.colDef.headerName);

                    i++;
                }
            });

            let gridData = [];
            var count = this.gridApi.getDisplayedRowCount();
            for (i = 0; i < count; i++) {
                var rowNode = this.gridApi.getDisplayedRowAtIndex(i);
                let rowData = rowNode.data;
                delete rowData.id;
                gridData[i] = rowData;
            }
            let route = "/" + this.props.settings.db + "/api/grid/export/" + type;

            let exportData = gridData;

             if(type === "importTemplate"){
                route = "/" + this.props.settings.db + this.props.exportTemplateRoute;

                exportData = this.state.selectedRowsData;
                exportAction = "in";

                if(this.state.selectAll === true){
                    exportData = this.state.deSelectedRowsData;
                    exportAction = "not in";
                }

                if(this.state.selectAll === false && exportData.length === 0){
                    exportData = null;
                }
            }

            let filters = {};
            let sort = {};

            //filters.tab = this.gridApi.getFilterModel();
            filters.q = this.state.quickFilter;
            if (this.state.filterOptions) {
                filters.columnFilters = this.state.filterOptions;
            }

            //this.props.onGridExportClick(route, exportData, visibleColumns, template, exportAction, filters);

            if(this.props.gridExportRoute){
                route = "/" + this.props.settings.db + this.props.gridExportRoute + this.props.match.params.id + '/'+ type;

                let params = "";
                let start = this.props.gridProperties.startDate;
                let end = this.props.gridProperties.endDate;

                start = start.year() + '-' + (start.month() + 1) + '-' + start.date();
                end = end.year() + '-' + (end.month() + 1) + '-' + end.date() + ' 23:59:59';

                let filters = {};
                let sort = {};
                if (this.state.sortCol && this.state.sortType) {
                    sort.col = this.state.sortCol;
                    sort.type = this.state.sortType;
                }

                //filters.tab = this.gridApi.getFilterModel();
                filters.q = this.state.quickFilter;
                filters.columns = visibleColumns;
                if (this.state.filterOptions) {
                    filters.columnFilters = this.state.filterOptions;
                }

                this.props.onReportExportClick(route, filters, start, end, sort, type);
            }else{
                this.props.onGridExportClick(route, exportData, visibleColumns, template, exportAction, filters);
            }

        }
    }

    onPageChange(response = null) {
        let route = '/' + this.props.match.params.db + this.props.filtersDataRoute;
        if (this.props.isReport) {
            this.setState({ showLoader: true });
            if (this.props.location) {
                const urlParams = new URLSearchParams(this.props.location.search);
                const key = urlParams.get('type');
                if (key !== null) {
                    route = route + '?type=' + key;
                }
            }

            this.props.onReportFetchData(route, this.state.reportFilters, this.state.startDate, this.state.endDate, this.state.currentPage, this.state.sortedCols, true, this.source.token).then(() => {
                this.setState({ showLoader: false });
            });

        } else {
            let params = "";
            let start = this.props.gridProperties.startDate;
            let end = this.props.gridProperties.endDate;

            start = start.year() + '-' + (start.month() + 1) + '-' + start.date();
            end = end.year() + '-' + (end.month() + 1) + '-' + end.date() + ' 23:59:59';

            this.setState({ showLoader: true });
            let filters = {};
            let sort = {};
            if (this.state.sortCol && this.state.sortType) {
                sort.col = this.state.sortCol;
                sort.type = this.state.sortType;
            }

            //filters.tab = this.gridApi.getFilterModel();
            filters.q = this.state.quickFilter;
            if (this.state.filterOptions) {
                filters.columnFilters = this.state.filterOptions;
            }
            this.props.onCustomGridFetchData(this.props.gridProperties.gridDataRoute, filters, start, end, sort, this.state.currentPage, this.state.paginationPageSize, this.source.token, false).then(() => {
                this.setState({ showLoader: false, currentPage: this.state.currentPage });
                if (this.state.selectAll) {
                    this.gridApi.selectAll();
                    this.state.deSelectedRows.map(row => {
                        let index = row;
                        if (this.state.currentPage > 1) {
                            index = row - (this.gridApi.getDisplayedRowCount() * (this.state.currentPage - 1));
                        }

                        if (index >= 0 && index < this.gridApi.getDisplayedRowCount()) {
                            let node = this.gridApi.getDisplayedRowAtIndex(index);
                            node.setSelected(false);
                        }

                    })
                } else {
                    this.gridApi.deselectAll();
                    this.state.selectedRows.map(row => {
                        let index = row;
                        if (this.state.currentPage > 1) {
                            index = row - (this.gridApi.getDisplayedRowCount() * (this.state.currentPage - 1));
                        }

                        if (index >= 0 && index < this.gridApi.getDisplayedRowCount()) {
                            let node = this.gridApi.getDisplayedRowAtIndex(index);
                            node.setSelected(true);
                        }

                    })
                }
            });
        }

    }

    firstPage(tabChanged = false) {
        if (this.props.gridProperties.options.customPagination) {
            this.setState({ currentPage: 1 }, () => {
                if (tabChanged !== true) this.onPageChange();
                this.disablePaginationButton('#btnPrev');
                this.disablePaginationButton('#btnFirst');
                this.enablePaginationButton('#btnLast');
                this.enablePaginationButton('#btnNext');
            });
        }
    }
    lastPage() {
        let pages = this.props.pages ? this.props.pages : this.state.pages;
        this.setState({ currentPage: pages }, () => {
            this.onPageChange();
            this.disablePaginationButton('#btnLast');
            this.disablePaginationButton('#btnNext');
            this.enablePaginationButton('#btnFirst');
            this.enablePaginationButton('#btnPrev');
        });
    }
    nextPage(e) {
        let currentPage = this.state.currentPage;
        let pages = this.props.pages ? this.props.pages : this.state.pages;
        if (currentPage === pages) {
            this.disablePaginationButton('#' + e.target.id);
        } else {
            currentPage++;
            this.setState({ currentPage: currentPage }, () => {
                this.onPageChange();
            });
        }
        this.enablePaginationButton('#btnPrev');
        this.enablePaginationButton('#btnFirst');
    }
    prevPage(e) {
        let currentPage = this.state.currentPage;
        if (currentPage > 1) {
            currentPage--;
            this.setState({ currentPage: currentPage }, () => {
                this.onPageChange();
            });
        }
        else {
            this.disablePaginationButton('#' + e.target.id);
        }
        this.enablePaginationButton('#btnNext');
        this.enablePaginationButton('#btnLast');
    }

    disablePaginationButton(id) {
        if (document.querySelector(id)) {
            document.querySelector(id).disabled = true;
        }
    }

    enablePaginationButton(id) {
        if (document.querySelector(id)) {
            document.querySelector(id).disabled = false;
        }
    }

    setText(selector, text) {
        document.querySelector(selector).innerHTML = text;
    }

    popUpClose(response) {
        let cols = this.columnApi.getAllColumns();
        if (response.columns) {
            cols.map((value) => {
                if (!response.columns.includes(value.colDef.field)) {
                    this.columnApi.setColumnVisible(value.colDef.field, false);
                } else {
                    this.columnApi.setColumnVisible(value.colDef.field, true);
                }
            });
        }
        response.startDate = this.state.startDate;
        response.endDate = this.state.endDate;

        if (response.success) {
            this.setState({ showFilters: false });
        } else {
            this.props.onReportApplyFilters(response, this.state.startDate, this.state.endDate);
            this.setState({ totalRecords: response.totalRecords, pages: response.pages, pinnedRows: response.pinnedRows, 'columns': response.columns, 'reportFilters': response.filters, currentPage: 1, showFilters: false });
        }

    }

    onSelectionChanged(event) {
        if (this.props.customSelection) {
            let selectedRows = this.state.selectedRows;
            let selectedRowsData = this.state.selectedRowsData;

            let deSelectedRows = this.state.deSelectedRows;
            let deSelectedRowsData = this.state.deSelectedRowsData;

            let nodes = event.api.selectionController.selectedNodes;

            let selectAll = this.state.selectAll;

            /*        if(this.gridApi.getDisplayedRowCount() === this.gridApi.getSelectedRows().length){
                        selectAll = true;
                    }*/
            let checkedAll = document.getElementsByClassName("ag-header-select-all")[0].getElementsByTagName('span')[0].classList.contains('ag-hidden');
            let unCheckedAll = document.getElementsByClassName("ag-header-select-all")[0].getElementsByTagName('span')[2].classList.contains('ag-hidden');

            if (!checkedAll) {
                selectAll = true;
                selectedRowsData = [];
                selectedRows = [];
            }
            if (!unCheckedAll) {
                selectAll = false;
            }

            for (var index in nodes) {
                let rowIndex = parseInt(index);

                if (this.state.currentPage > 1) {
                    rowIndex = (this.gridApi.getDisplayedRowCount() * (this.state.currentPage - 1)) + parseInt(rowIndex);
                }
                if (!selectAll) {
                    if (nodes[index] !== undefined) {
                        if (!selectedRows.includes(rowIndex)) {
                            selectedRows.push(rowIndex);
                            selectedRowsData.push(nodes[index].data);
                        }
                    } else {
                        let removeRow = selectedRows.indexOf(rowIndex);

                        if (removeRow > -1) {
                            selectedRows.splice(removeRow, 1);
                            selectedRowsData.splice(removeRow, 1);
                        }
                    }
                }
                if (selectAll) {
                    if (nodes[index] === undefined) {
                        if (!deSelectedRows.includes(rowIndex)) {
                            deSelectedRows.push(rowIndex);
                            deSelectedRowsData.push(this.gridApi.getDisplayedRowAtIndex(index).data);
                        }
                    } else {
                        let removeRow = deSelectedRows.indexOf(rowIndex);

                        if (removeRow > -1) {
                            deSelectedRows.splice(removeRow, 1);
                            deSelectedRowsData.splice(removeRow, 1);
                        }
                    }
                }
            }

            this.setState({ selectedRows: selectedRows, selectedRowsData: selectedRowsData, deSelectedRows: deSelectedRows, deSelectedRowsData: deSelectedRowsData, selectAll: selectAll });
        } else {
            this.props.onRowSelection();
        }
    }

    customPopupOpen(type, value){
        if(type === 'location-places'){
            this.setState({openLocationPlacesPopup: true, customPopupSelectedRow: value});
        }
    }

    getSelectOptions(){
        if(!this.state.locationPlacesOptions){
            client.get(this.props.match.params.db + "/api/inventory-places/get-active/carts").then(response => {
                this.setState({locationPlacesOptions: response.data});
            });    
        }
    }

    selectOptionsSearch(val){
        clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(() => {
                this.setState({selectLoading: true});
                client.get(this.props.match.params.db + "/api/inventory-places/get-active/carts?q="+val).then(response => {
                    this.setState({locationPlacesOptions: response.data});
                    this.setState({selectLoading: false});
                });   
            }, 1000);
    }

    saveCart(){
        if(this.state.selectedCart){
            client.post(this.props.match.params.db + "/api/inventory-places/"+this.state.selectedCart+"/save/receipt", {'body': this.state.customPopupSelectedRow}).then(response => {
                this.setState({selectedCart: null, customPopupSelectedRow: null, openLocationPlacesPopup: false, locationPlacesOptions: null});
                 showAToast(plainTranslate(this.props.settings.locale, response.data.success), 'succSettings', 'success');
            });
        }
    }

    render() {
        const customRenderers = {
            booleanRenderer: BooleanRenderer,
            dateRenderer: DateRenderer,
            htmlRenderer: HtmlRenderer,
            inOutRenderer: InOutRenderer,
            customClassRenderer: CustomClassRenderer,
            customNewRenderer: CustomNewRenderer,
            customColorRenderer: CustomColorRenderer,
            linksRenderer: CustomCellRenderer,
            linksRendererParams: {
                history: this.props.history,
                openNewPopup: this.customPopupOpen
            },
            singleLinkRenderer: SingleLinkRenderer,
            imageRenderer: ImageRenderer,
            buttonRenderer: ButtonRenderer,
            itemRequestRenderer: ItemRequestRenderer,
            customLoadingOverlay: LoadingOverlay,
            viewRenderer: ViewRenderer,
        };

        let btnPrev = document.querySelector('#btnPrev');
        let btnFirst = document.querySelector('#btnFirst');
        let btnNext = document.querySelector('#btnNext');
        let btnLast = document.querySelector('#btnLast');

        if (this.props.customPagination) {
            if (this.state.currentPage === 1) {
                if (btnPrev) {
                    btnPrev.disabled = true;
                }
                if (btnFirst) {
                    btnFirst.disabled = true;
                }
                //document.querySelector('#btnPrev').disabled = false;
            }
            if (this.state.currentPage === this.props.pages) {
                if (btnLast) {
                    btnLast.disabled = true;
                }
                if (btnNext) {
                    btnNext.disabled = true;
                }
            }
            if (this.state.currentPage < this.props.pages) {
                if (btnLast) {
                    btnLast.disabled = false;
                }
                if (btnNext) {
                    btnNext.disabled = false;
                }
            }
        }

        const gridProperties = this.props.gridProperties;

        const columns = gridProperties.columns ? gridProperties.columns.map(col => {
            if (col.comparator === 'date') {
                col.comparator = this.dateComparator;
            }
            if (col.comparator === 'number') {
                col.comparator = this.numberComparator;
            }
            if (col.comparator === 'string') {
                col.comparator = this.stringComparator;
            }

            return col;
        }) : [];

        let start;
        let end;

        if (this.state.startDate) {
            start = this.state.startDate;
        } else if (this.props.gridProperties.startDate) {
            start = this.props.gridProperties.startDate;
        } else {
            if (localStorage.getItem('grid_startDate')) {
                start = moment(localStorage.getItem('grid_startDate'));
            }
            else if (localStorage.getItem('grid_range')) {
                start = moment().subtract(localStorage.getItem('grid_range'), 'days');
            } else {
                start = moment();
            }
        }

        if (this.state.endDate) {
            end = this.state.endDate;
        } else if (this.props.gridProperties.endDate) {
            end = this.props.gridProperties.endDate;
        } else {
            if (localStorage.getItem('grid_endDate')) {
                end = moment(localStorage.getItem('grid_endDate'));
            } else {
                end = moment();
            }
        }

        let startDate = moment(start);
        let endDate = moment(end);
        let showReportLoader = null;

        if (this.props.isReport && this.state.showLoader !== undefined) {
            //showReportLoader = this.state.showLoader;
        }

        const settings = this.props.settings;
        return <div id="ag-grid-div">

            {
                gridProperties.confirm && gridProperties.confirm.show &&
                <ModalConfirm onClose={this.openConfirmDialog}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <div className="text-center">
                                    <h3><strong>
                                        <Translate locale={settings.locale} value="Are you sure?" />
                                    </strong>
                                    </h3>
                                    <div className="buttons">
                                        <button className="button-outline grey text-center"
                                            onClick={this.openConfirmDialog}>
                                            <Translate locale={settings.locale} value="Cancel" />
                                        </button>
                                        <button className="button-primary text-center btn-ok"
                                            onClick={this.acceptConfirmDialog}>
                                            <Translate locale={settings.locale} value="Yes" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </ModalConfirm>
            }
            {
                gridProperties.openView && gridProperties.contentView &&
                <SlideModal title={plainTranslate(this.props.settings.locale, 'Message view')} 
                onClose={() => {this.props.openViewHandler(false, '')}}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <div dangerouslySetInnerHTML={{ __html: gridProperties.contentView }} />
                            </div>
                        </div>
                    </section>
                </SlideModal>}

            {this.state.openLocationPlacesPopup === true &&
                <SlideModal title={plainTranslate(this.props.settings.locale, 'Carts')} onClose={() => this.setState({ 'openLocationPlacesPopup': false, locationPlacesOptions: null })}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <div><span style={{marginRight: 20}}>{plainTranslate(this.props.locale, 'Select cart')}</span>
                                <Select showSearch 
                                    filterOption={false}
                                    loading={this.state.selectLoading}
                                    onSearch={(value) => this.selectOptionsSearch(value)}
                                    onSelect={(value) =>this.setState({selectedCart: value})} style={{ width: 240 }} 
                                    options={this.state.locationPlacesOptions} 
                                    onDropdownVisibleChange={this.getSelectOptions()}
                                >
                                </Select>
                                </div>
                                <div>
                                <button className="button-primary" type="button" id="saveCart" disabled = {this.state.isClicked ? 'disabled' :  '' } className={"button-primary " + (this.state.isClicked ? 'disabled' : '')}
                                    onClick={this.saveCart}>
                                <Translate locale={this.props.settings.locale} value="Save"/>
                            </button></div>
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }

            {/*show header*/}
            <Header history={this.props.history} onTabChange={this.firstPage} onFilterChange={this.onFilterModified} />

            {/*show grid*/}
            <div>
                <div className="grid-panel col-md-12 col-xs-12">
                    <div className="col-md-12 col-xs-12" style={{ 'height': '100%', 'width': '100%' }}>
                        {
                            gridProperties.bulkActions &&
                            gridProperties.bulkActions.map((bulk, key) => {
                                if(bulk.icon){
                                    return <div className="col-md-2 col-xs-2 col-lg-1 bulk-container" key={key}
                                    >
                                        <BulkActions customPopupOpen={this.customPopupOpen} updateGrid={this.updateGrid} gridApi={this.gridApi} pinnedRows={this.state.pinnedRows} columnApi={this.columnApi} selectedRowsData={this.props.customSelection ? this.state.selectedRowsData : null} deSelectedRowsData={this.props.customSelection ? this.state.deSelectedRowsData : null} selectAll={this.state.selectAll} handleExport={this.handleExport} bulk={bulk} sortedCols={this.state.sortedCols} filterOptions={this.state.filterOptions} quickFilter={this.state.quickFilter} />
                                    </div>
                                }
                            })
                        }

                        <div className="col-md-5 col-xs-6 col-lg-2" style={{ marginTop: 50, marginLeft: 15 }}>
                            {
                                this.props.gridProperties.selectedRowsCount > 0 &&
                                <div className="col-md-12 no-gutter">
                                    <span className={this.props.gridProperties.selectedRowsCount > 0 ? "selected-rows-count" : ''}
                                        onClick={() => this.props.onRowSelection(true)}>
                                        {this.props.gridProperties.selectedRowsCount}&nbsp;
                                            <Translate locale={settings.locale}
                                            value={(this.props.gridProperties.selectedRowsCount == 1 ? 'record' : 'records') + ' selected'} />
                                        {
                                            this.props.gridProperties.selectedRowsCount > 0 ? <sup>X</sup> : ''
                                        }
                                    </span>
                                </div>
                            }
                        </div>

                        {/*show datepicker*/}
                        {
                            gridProperties.options && gridProperties.options.enableDatepicker &&
                            <div className="date-range col-md-3 col-xs-12">
                                
                                <RangePicker
                                    bordered={false}
                                    defaultValue={[startDate, endDate]}
                                    open={this.state.openRangePicker}
                                    onOpenChange={this.onCalendarOpen}
                                    onCalendarChange={this.onCalendarChange}
                                    format={this.props.settings.dateFormat}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={(focusedInput) => {
                                        this.setState({ focusedInput })
                                    }}
                                    disabledDate={ d => {
                                        return false;
                                    }}
                                    separator={<span style={{display: "flex", fontSize: "9px", left: "-10px", position: "relative"}}><LeftOutlined /><RightOutlined /></span>}
                                    ranges={{
                                        'Today': [moment(), moment()],
                                        'This week': [moment().subtract(1, 'weeks'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(30, 'days'), moment()],
                                    }}
                                />
                            </div>
                        }
                        {
                            gridProperties.options && gridProperties.options.enableSingleDatepicker &&
                            <div className="date col-md-3 col-xs-12">
                                <DatePickerComponent
                                    bordered={false}
                                    defaultValue={endDate}
                                    onChange={this.handleSingleDateChange}
                                    format={this.props.settings.dateFormat}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={(focusedInput) => {
                                        this.setState({ focusedInput })
                                    }}
                                />
                            </div>
                        }

                        {
                            !this.props.isReport && gridProperties.options && gridProperties.columns && gridProperties.options && gridProperties.options.enableColumns &&
                            <div className="pull-right columns-container">
                                <Columns columns={this.state.columns}
                                    cols={this.columnApi}
                                    columnsDefault={gridProperties.columns}
                                    updateGridColumns={this.updateGridColumns}
                                />
                            </div>
                        }

                        {gridProperties.options && gridProperties.options.showCalculateButton &&
                            <div className="pull-right columns-container">
                                <div className="columns-button">
                                    <div className="columns-button-open">
                                        <a href="#">
                                            <i className="fa fa-refresh"
                                                aria-hidden="true" onClick={this.onCalculateClick}></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }
                        {!this.props.isReport && (gridProperties.options && !gridProperties.options.showCalculateButton) &&
                            <div className="pull-right columns-container">
                                <div className="columns-button">
                                    <div className="columns-button-open">
                                        <a href="#" title={plainTranslate(this.props.locale, 'Refresh grid data')}>
                                            <i className="fa fa-undo"
                                                aria-hidden="true" onClick={this.updateGrid}></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }
                        {gridProperties.options && gridProperties.options.showExportOptions !== false &&
                            <div className="pull-right col-md-1 col-xs-2">
                                <div className="pull-right columns-button-open" ref={this.setWrapperRef} className="pull-right downloadIcon" onClick={this.showColumnsModal}>
                                    {this.props.exportTemplateRoute && 
                                        <a href="#" title={plainTranslate(this.props.locale, 'Export import template')} style={{marginRight: 20}} onClick={(e) => this.handleExport('importTemplate')}>
                                            <i class="fa fa-database" aria-hidden="true"></i>
                                        </a>
                                    }
                                    <a href="#" title={plainTranslate(this.props.locale, 'Export as')}>
                                        <i className="fa fa-download" aria-hidden="true" onClick={this.handleShowExportOptions}></i>
                                    </a>
                                    {this.state.showExportOptions &&
                                        <nav role="navigation" className="nav">
                                            <ul className="nav-items">
                                                <li className="nav-item dropdown">
                                                    <nav className="submenus">
                                                        <ul className="submenus-items">
                                                            <li className='submenus-item' style={{ marginLeft: 0 }} key='0'>
                                                                <a href="#" onClick={(e) => this.handleExport('csv')} className='submenus-link'>
                                                                    <Translate locale={this.props.settings.locale} value='CSV' />
                                                                </a>
                                                            </li>
                                                            <li className='submenus-item' style={{ marginLeft: 0 }} key='1'>
                                                                <a href="#" onClick={(e) => this.handleExport('excel')} className='submenus-link'>
                                                                    <Translate locale={this.props.settings.locale} value='Excel' />
                                                                </a>
                                                            </li>
                                                            {gridProperties.options.exportTemplates && gridProperties.options.exportTemplates.map((action, key) => {
                                                                return <li className='submenus-item' style={{ marginLeft: 0 }} key={key + 2}>
                                                                    <a href="#" onClick={(e) => this.handleExport(action.value)} className='submenus-link'>
                                                                        <Translate locale={this.props.settings.locale} value={action.label} />
                                                                    </a>
                                                                </li>
                                                            })
                                                            }
                                                        </ul>
                                                    </nav>
                                                </li>
                                            </ul>
                                        </nav>
                                    }
                                </div>
                            </div>
                        }
                        {
                            gridProperties.options && gridProperties.options.showCustomFilters === true &&
                            <div className="pull-right col-md-1 col-xs-2">
                                <div className="pull-right columns-button-open filterIcon" onClick={this.showColumnsModal}>
                                    <a href="#" title={plainTranslate(this.props.locale, 'Filters')}>
                                        <img src='/images/filter.svg' onClick={() => this.setState({ showFilters: true })} alt="filters" />
                                    </a>
                                    {this.state.showFilters === true &&
                                        <SlideModal title={plainTranslate(this.props.settings.locale, 'Filters')} onClose={() => this.setState({ 'showFilters': false })}>
                                            <section className="newPanel">
                                                <div className="panel-body">
                                                    <div className="row m-bot15">
                                                        <SimpleForm
                                                            saveRoute={this.props.match.params.db + this.props.filtersSaveRoute}
                                                            filtersSaveRoute={this.props.match.params.db + this.props.filtersDataRoute}
                                                            formRoute={this.props.match.params.db + this.props.filtersRoute}
                                                            match={{ params: { id: 'stockOnHand' } }}
                                                            onSuccessfulSave={(response) => this.popUpClose(response)}
                                                            applyButton={true}
                                                            saveReport={true}
                                                            applyFilters={true}
                                                            startDate={this.state.startDate}
                                                            endDate={this.state.endDate}
                                                            importData={this.state.reportFilters}
                                                            popup={true}
                                                            isNew={true}
                                                            isReport={true}
                                                            type={this.state.reportId}
                                                            form={'initializeFromState2'}
                                                            onCancel={() => this.setState({ 'showFilters': false })}
                                                        />
                                                    </div>
                                                </div>
                                            </section>
                                        </SlideModal>
                                    }
                                </div>
                            </div>
                        }

                        {/*show quick filter*/}
                        {
                            gridProperties.options && gridProperties.options.enableQuickFilter &&

                            <div className="search-field-container col-md-3 col-xs-4 pull-right">
                                <div>
                                    <div className="search-field pull-left">
                                        <i className="fa fa-search"
                                            aria-hidden="true"></i>
                                        <input type="text"
                                            ref="searchString"
                                            id="quickFilter"
                                            onChange={(e) => this.quickFilterChange(e.target.value)}
                                            className="search-field-input" />
                                        <i className="fa fa-times" aria-hidden="true"
                                            onClick={this.clearInput}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>

                    {
                        this.props.data && !this.props.gridProperties.showLoader &&
                        <div><div className="ag-theme-balham table-inbox" style={{ overflowY: 'scroll', minWidth: '100%', display: this.state.showLoader ? "none" : "block" }}>
                            <AgGridReact

                                columnDefs={columns ? columns : []}
                                rowData={this.props.data}
                                rowSelection={'multiple'}
                                enableSorting={true}
                                onSortChanged={this.onSortChanged}
                                enableFilter={this.props.isReport === true ? false : true}
                                pinnedBottomRowData={this.props.pinnedRows ? this.props.pinnedRows : this.state.pinnedRows}
                                pagination={true}
                                suppressPaginationPanel={(this.props.customPagination || this.props.isReport) ? true : false}
                                paginationPageSize={this.state.paginationPageSize}
                                enableColResize={true}
                                suppressRowClickSelection={true}
                                frameworkComponents={customRenderers}
                                onColumnMoved={this.onColumnMoved}
                                onColumnResized={this.onColumnResized}
                                //onPaginationChanged={this.onPageChange}
                                loadingOverlayComponent={'customLoadingOverlay'}
                                loadingOverlayComponentParams={{ color: this.props.settings.firstColor }}
                                onGridReady={this.onGridReady}
                                //onSelectionChanged={() => this.props.onRowSelection()}
                                onSelectionChanged={(event) => this.onSelectionChanged(event)}
                                quickFilterText={gridProperties.quickFilter}
                                onFilterModified={this.onFilterModified}
                                localeText={{
                                    page: plainTranslate(this.props.settings.locale, 'Page'),
                                    to: plainTranslate(this.props.settings.locale, 'to'),
                                    of: plainTranslate(this.props.settings.locale, 'of'),
                                }}
                            >
                            </AgGridReact>
                        </div>
                            {this.state.showLoader && <GridLoader />}
                            {(this.props.customPagination || this.props.isReport) &&
                                <div className="col-md-12" style={{ padding: "0px 20px" }}>

                                    <div className="pagination col-md-6" style={{ marginTop: "10px" }}>
                                        <span style={{ marginRight: 5 }}>Records:</span>
                                        {/*
                                    <span style={{marginRight:5}} className="value" id="lbPageSize">{1+(this.state.currentPage-1)*this.state.paginationPageSize}</span>
                                    <span style={{marginRight:5}}>to</span>
                                    {this.state.currentPage === this.props.pages ? <span style={{marginRight:5}} className="value" id="lbPageSize">{this.props.totalRecords}</span> : <span style={{marginRight:5}} className="value" id="lbPageSize">{this.state.currentPage*this.state.paginationPageSize}</span>}
                                    <span style={{marginRight:5}}>of</span>
                                    */}
                                        <span style={{ marginRight: 5 }}>{this.props.totalRecords ? this.props.totalRecords : this.state.totalRecords}</span>
                                    </div>
                                    <div className="pagination col-md-6">
                                        <div className="pull-right" style={{ display: "flex", alignItems: "center" }}>
                                            <button onClick={this.firstPage} id="btnFirst" className="page-item">First</button>
                                            <button onClick={this.prevPage} id="btnPrev" className="page-item" style={{ marginRight: 10 }}><i className="ion-chevron-left" aria-hidden="true"></i></button>
                                            <div>
                                                <span style={{ marginRight: 5 }} className="value" id="lbCurrentPage">{this.state.currentPage}</span>
                                                <span style={{ marginRight: 5 }}>of</span>
                                                <span style={{ marginRight: 5 }} className="value" id="lbTotalPages">{this.props.pages ? this.props.pages : this.state.pages}</span>
                                                <span style={{ marginRight: 5 }}>pages</span>
                                            </div>
                                            <button onClick={(e) => this.nextPage(e)} id="btnNext" className="page-item"  ><i className="ion-chevron-right" aria-hidden="true"></i></button>
                                            <button onClick={(e) => this.lastPage(e)} id="btnLast" className="page-item">Last</button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {
                        (showReportLoader !== null ? showReportLoader : this.props.gridProperties.showLoader) && <GridLoader />
                    }

                </div>
                <div style={{ position: "absolute", top: "0px", left: "0px" }}>

                </div>
            </div>
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    gridProperties: state.gridProperties,
    data: state.gridData.data,
    pinnedRows: state.gridData.pinnedRows,
    pages: state.gridData.pages,
    totalRecords: state.gridData.totalRecords
});

const mapDispatchToProps = dispatch => ({
    onMount: (payload) => dispatch({ type: GRID_MOUNT, payload }),
    onGridMount: (route) => dispatch(onGridMount(route)),
    onQuickFilterChange: (value) => dispatch(onQuickFilterChange(value)),
    onDatesChange: (startDate, endDate, route) => dispatch(onDatesChange(startDate, endDate, route)),
    onRowSelection: (clear) => dispatch(onRowSelection(clear)),
    onGridApiLoad: (gridApi, gridColumnApi, header) => dispatch(onGridApiLoad(gridApi, gridColumnApi, header)),
    onConfirmDialogShow: () => dispatch({ type: GRID_CONFIRM_DIALOG_SHOW }),
    onConfirmActionTrigger: (action) => dispatch(onConfirmActionTrigger(action)),
    onGridFetchData: (route, signal) => dispatch(onGridFetchData(route, signal)),
    onCustomGridFetchData: (route, filters, start, end, sort, currentPage, pageSize, signal, total) => dispatch(onCustomGridFetchData(route, filters, start, end, sort, currentPage, pageSize, signal, total)),
    onReportFetchData: (route, filters, start, end, currentPage, sort, limit, signal) => dispatch(onReportFetchData(route, filters, start, end, currentPage, sort, limit, signal)),
    onReportApplyFilters: (route, startDate, endDate) => dispatch(onReportApplyFilters(route, startDate, endDate)),
    onHeaderOptionSelected: (option) => dispatch(onHeaderOptionSelected(option)),
    onEmptyData: () => dispatch(onGridEmptyData()),
    onLoader: (value) => dispatch({
        type: GRID_HEADER_SHOW_LOADER,
        payload: value
    }),
    onCalculateButtonClick: (route, filter, startDate, endDate, quickFilter, sortedCols, visibleColumns) => dispatch(onClickCalculateButtonClick(route, filter, startDate, endDate, quickFilter, sortedCols, visibleColumns)),
    onGridExportClick: (route, data, cols, template, action, filters) => dispatch(onGridExportClick(route, data, cols, template, action, filters)),
    onReportExportClick: (route, filters, startDate, endDate, sortedCols, templ) => dispatch(onReportExportClick(route, filters, startDate, endDate, sortedCols, templ)),
    onColumnUpdate: (route, cols) => dispatch(onColumnUpdate(route, cols)),
    openViewHandler: (open, content) => dispatch(openViewHandler(open, content))
});

export default connect(mapStateToProps, mapDispatchToProps)(Grid);
