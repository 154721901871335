import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../common/translate';
import SingleCheckbox from './singleCheckbox.jsx';
import { Form, Input, Button } from 'antd';

class CompanySettings extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
              value: null
        };
  }
 
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.state.value == null) {
            this.setState({
                value: this.props.value
            }, function () {
                this.formRef.current.setFieldsValue(this.state.value)
            });
        }
    }

  onFinish = (values) => {
          this.props.onSave(values);        
  }  

  onSubmit = () => {
    this.formRef.current.submit()
  }

    render() {
        
        return (
                <React.Fragment>
                    <div className="row m-bot15">
                        <div className="col-lg-12">
                                <Form 
                                  layout="vertical"
                                 ref={this.formRef}
                                 onFinish={this.onFinish}
                                 className="company-settings-form">
                                    <div className="col-lg-12"> 
                                        <br />
                                        <div className="col-lg-6">
                                            <Form.Item 
                                            name="name"
                                            label={plainTranslate(this.props.settings.locale, 'Name')}>
                                              <Input placeholder={plainTranslate(this.props.settings.locale, 'Name')}/>                                           
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Item 
                                            name="postCode"
                                            label={plainTranslate(this.props.settings.locale, 'Postcode')}>
                                                      <Input placeholder={plainTranslate(this.props.settings.locale, 'Postcode')}/>
                                               </Form.Item>
                                        </div>
                                        <div className="col-lg-6">
                                          <Form.Item 
                                            name="identifierNationalId"
                                            label={plainTranslate(this.props.settings.locale, 'Company Id')}>
                                           <Input placeholder={plainTranslate(this.props.settings.locale, 'Company Id')}/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Item 
                                            name="address1"
                                            label={plainTranslate(this.props.settings.locale, 'Address Line 1')}>
                                           <Input placeholder={plainTranslate(this.props.settings.locale, 'Address Line 1')}/>
                                              </Form.Item>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Item 
                                            name="identifierTaxId"
                                            label={plainTranslate(this.props.settings.locale, 'Company VAT')}>
                                                    <Input placeholder={plainTranslate(this.props.settings.locale, 'Company VAT')}/>
                                              </Form.Item>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Item 
                                            name="address2"
                                            label={plainTranslate(this.props.settings.locale, 'Address Line 2')}>
                                                     <Input placeholder={plainTranslate(this.props.settings.locale, 'Address Line 2')}/>
                                              </Form.Item>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Item 
                                            name="country"
                                            label={plainTranslate(this.props.settings.locale, 'Country')}>
                                                    <Input placeholder={plainTranslate(this.props.settings.locale, 'Country')}/>
                                                </Form.Item>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Item 
                                            name="responsiblePerson"
                                            label={plainTranslate(this.props.settings.locale, 'Responsible person')}>
                                                      <Input placeholder={plainTranslate(this.props.settings.locale, 'Responsible person')}/>
                                              </Form.Item>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Item 
                                            name="city"
                                            label={plainTranslate(this.props.settings.locale, 'City')}>
                                                    <Input placeholder={plainTranslate(this.props.settings.locale, 'City')}/>
                                               </Form.Item>
                                        </div>
                                    </div>
                                    <Button
                                    type="primary" 
                                    htmlType="button" 
                                    onClick={this.onSubmit}
                                    className="button-primary">
                                    {plainTranslate(this.props.settings.locale, 'Save')}
                                   </Button>
                                    
                                   </Form>
                        </div>
                    </div>
                </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

//const CompanySettingsForm = Form.create({ name: 'CompanySettingsForm' })(CompanySettings);
    
export default connect(mapStateToProps, mapDispatchToProps)(CompanySettings);