import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import SlideModal from '../common/SlideModal';
import Translate, {plainTranslate} from '../common/translate';

class ItemRequestRenderer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            popup: false, 
            rowData: []
        };
        this.handleClickButton = this.handleClickButton.bind(this);
    }

    handleClickButton() {
        this.setState({popup: true});
        this.setState({rowData: this.props.data.docs});
    }
    
    render() 
    {
        return (
            <React.Fragment>
                    <span>
                        <a className="marg" onClick={this.handleClickButton}><i className="fa fa-list-alt" aria-hidden="true"></i></a> 
                    </span>
                {
                this.state.popup &&
                <SlideModal onClose={() => this.setState({popup: false})} title={plainTranslate(this.props.settings.locale,'Additional data')} customClass={"grid-modal"}>
                    <section className="newPanel">
                        <div className="panel-body">
                                <div className="row m-bot15">
                                <table  className="table">
                                <thead>
                                    <tr>
                                        <th>{plainTranslate(this.props.settings.locale, 'Type')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, '#')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Status')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Quantity')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Location from')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Location to')}</th>
                                     </tr> 
                                 </thead>
                                 <tbody>
                                    {this.state.rowData && this.state.rowData.length> 0 && this.state.rowData.map((data, key) => (
                                    <tr>
                                        <td>{plainTranslate(this.props.settings.locale, data.type)}</td>
                                        <td>{data.no}</td>
                                        <td>{plainTranslate(this.props.settings.locale, data.status)}</td>
                                        <td>{data.qty}</td>
                                        <td>{data.locationFrom}</td>
                                        <td>{data.locationTo ? data.locationTo : data.location}</td>        
                                    </tr>
                                    ))}
                                </tbody>
                        </table>
                        </div>
                        </div>
                    </section>
                </SlideModal>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

export default connect(mapStateToProps) (ItemRequestRenderer);

