import React from 'react';
import {connect} from 'react-redux';
import brace from 'brace';
import AceEditor from 'react-ace';

import 'brace/mode/java';
import 'brace/theme/github';

class Ace extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : ''
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value ? this.props.value : ''
            });
        }
    }

    onChange(value) {
        // this.setState({value});
        this.props.onChange(this.props.id, value);
    }

    render() {
        return <AceEditor
            mode="javascript"
            value={this.state.value}
            onChange={this.onChange}
            name={this.props.id}
            fontSize={14}
            showPrintMargin={true}
            width={"100%"}
            showGutter={true}
            editorProps={{$blockScrolling: true}}
            setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,}}
            tabIndex = {this.props.tabindex ? this.props.tabindex : ''}
           />
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Ace);
