import React, {Component} from 'react';
import {render} from "react-dom";
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../common/translate';
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

class ReversedDocs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    render() {
        return (
            <div>
                <div className="account-profile-other-information-more">
                    <h4>{plainTranslate(this.props.settings.locale, this.props.title)}</h4>
                    {this.props.reversedDocs && this.props.reversedDocs.length > 0 &&
                        <a className="account-profile-show" id="reversed-sales" href="javascript:void(0)"
                           onClick={() => this.setState({show: !this.state.show})}>
                            {this.state.show ? <i className="glyphicon glyphicon-chevron-up"></i> :
                                <i className="glyphicon glyphicon-chevron-down"></i>}
                        </a>  
                    }
                    {
                        this.state.show &&
                        <div className="line">
                            <table  className="table">
                                <thead>
                                    <tr>
                                        <th>{plainTranslate(this.props.settings.locale, '#')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Date')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Status')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Amount')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.reversedDocs.map(doc=>
                                        <tr>
                                            <td>
                                                {this.props.roles && this.props.roles.hasEdit == true && 
                                                    <Link to={'/' + this.props.match.params.db +'/' + this.props.type + '/'+doc.secureId+'/profile'}>{doc.no}</Link>
                                                }
                                                {this.props.roles && this.props.roles.hasEdit == false && 
                                                    doc.no
                                                }
                                            </td>
                                            <td><td>{doc.date ? moment((doc.date).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</td></td>
                                            <td>{doc.status}</td>
                                            <td>{parseFloat(doc.amount).toFixed(2)}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReversedDocs));
