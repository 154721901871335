import React from 'react';
import { connect } from 'react-redux';
import Translate, { plainTranslate } from '../common/translate';
import { Form, Input, Tooltip, Select } from 'antd';
import 'antd/lib/form/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/button/style/index.css';
import { withRouter } from 'react-router-dom'

const { Option } = Select;

class AccountNameSettings extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            value: null
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.state.value == null) {
            this.setState({
                value: JSON.parse(this.props.value)
            }, function () {
                this.formRef.current.setFieldsValue(this.state.value)
            });
        }
    }

    onChange = (e) => {
        let values = this.state.value;
        if (!values) {
            values = {};
        }
        values.pattern = e.target.value;
        this.setState({
            value: values
        }, function () {
                    this.props.onSave(JSON.stringify(values));
            });
    }

    handleChange = (value) => {
        let values = this.state.value;
        if (!values) {
            values = {};
        }
        values.fields = value;
        this.setState({
            value: values
        }, function () {
            this.props.onSave(JSON.stringify(values));
        });
    }

    render() {
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 10 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 14 },
            },
          };
        return (
            <React.Fragment>
                <div className="row m-bot15">
                    <div className="col-lg-12" style={{marginLeft: '-30px'}}>
                        <Form className="my-pos-form" ref={this.formRef} >
                            <div className="col-lg-12">
                                <Form.Item {...formItemLayout} labelAlign="left" label={<Tooltip placement="bottom" title={plainTranslate(this.props.settings.locale, 'Valid pattern is in format %s-%s-%s')}>{plainTranslate(this.props.settings.locale, 'Account Name Pattern')} <i class="fa fa-question-circle"></i></Tooltip>} name="pattern">
                                    <Input
                                        placeholder={plainTranslate(this.props.settings.locale, 'Account Name Pattern')}
                                        onChange={this.onChange}
                                    />
                                </Form.Item>
                                <Form.Item {...formItemLayout} labelAlign="left" label={plainTranslate(this.props.settings.locale, 'Account Name Fields')} name="fields">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        size={'large'}
                                        style={{ width: '100%'}}
                                        placeholder={plainTranslate(this.props.settings.locale, 'Choose Account Name Fields')}
                                        onChange={this.handleChange}
                                        >
                                            {this.props.options && this.props.options.map((item, key) => {
                                                return <Option key={key} value={item}>{item}</Option>
                                            })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountNameSettings));

