import React, { Component } from 'react';
import { render } from "react-dom";
import Translate, { plainTranslate } from '../../../common/translate';
import { connect } from 'react-redux';
import { Route } from "react-router-dom";
import Popup from '../../../common/popup';
import ContactForm from './contactForm';
import { Card, Row, Col } from 'antd';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import SlideModal from '../../../common/SlideModal';
import Form from "../../../redux-forms/form";
import MessageContent from './message';

const types = [
    { value: '1', label: 'Email' },
    { value: '2', label: 'Phone' },
    {
        value: '3',
        label: 'Mobile'
    },
    {
        value: '4',
        label: 'Social. Google+'
    },
    {
        value: '5',
        label: 'Social. Facebook',
    },
    {
        value: '6',
        label: 'Social. LinkedIn'
    },
    {
        value: '7',
        label: 'Social. Twitter'
    },
    {
        value: '8',
        label: 'Skype'
    },
    {
        value: '9',
        label: 'Fax',
    },
    {
        value: '10',
        label: 'Website'
    },
    {
        value: '11',
        label: 'Personal number'
    },
    {
        value: '12',
        label: 'other'
    },
];

class Contacts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contacts: [],
            open: false
        };
    }

    componentDidMount() {
        this.setState({
            contacts: this.props.contacts ? this.props.contacts : [],
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contacts !== this.props.contacts) {
            this.setState({
                contacts: this.props.contacts
            });
        }
    }

    addContacts = (contacts) => {
        this.setState({
            contacts: contacts
        });
    }

    openEdit = () => {
        const { open } = this.state;
        this.setState({
            open: !open
        });
    }

    render() {
        return (
            <>
                {(this.state.contacts && this.state.contacts.length > 0) ?
                    <Col span={24}><EditOutlined onClick={this.openEdit} /></Col>
                    :
                    <Col span={24}><PlusCircleOutlined onClick={this.openEdit} /></Col>
                }
                {this.state.contacts && this.state.contacts.map((contact, key) => (
                    <>
                        {contact.value == 1 &&
                            <div className="line col-lg-12 col-xs-12 col-md-12 no-gutter">
                                <div className='col-lg-9 col-xs-8 col-md-9 no-gutter inlineHover'>
                                    <h4>
                                        <div className="icon-before-input pull-left">
                                            <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                        </div>
                                        <p style={{ color: 'black' }}>{contact.valueString}</p>
                                    </h4>
                                </div>
                                <div className='col-lg-3 col-xs-4 col-md-3 m-top10 no-gutter'>
                                    <div className="callIcons pull-right">
                                        {this.props.hasCreateEmail &&
                                            <span className="circle" onClick={() => this.setState({ emailPopup: true })}>
                                                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                            </span>
                                        }
                                        {this.state.emailPopup === true &&
                                            <SlideModal onClose={() => this.setState({ emailPopup: false })} title={plainTranslate(this.props.settings.locale, 'New Email')}>
                                                <section className="newPanel">
                                                    <div className="panel-body">
                                                        <div className="row m-bot15">
                                                            {this.props.emailSettings && this.props.emailSettings.length > 0 && this.props.account.email &&
                                                                <Form
                                                                    formRoute={this.props.db + '/api/message-email/'}
                                                                    saveRoute={this.props.db + '/api/message-email/new'}
                                                                    popup={true}
                                                                    onSuccessfulSave={() => this.setState({ emailPopup: false })}
                                                                    onCancel={() => this.setState({ emailPopup: false })}
                                                                    refs={this.props.account.email ? this.props.account.email : ' '}
                                                                    form={'initializeFromState'}
                                                                    match={{ params: { id: 0 } }}
                                                                    isNew={true}
                                                                />
                                                            }
                                                            {
                                                                this.props.emailSettings && this.props.emailSettings.length == 0 &&
                                                                <div><p>{plainTranslate(this.props.settings.locale, 'Send email directly from an Account profile with the Email service integration. Configure Email integration-related settings on the Composity platform from Account -> Email.')}
                                                                </p>
                                                                    {this.props.roles.includes('Admin') && <a className="rectangular_button green"
                                                                        href={'/' + this.props.db + '/settings/integrations'}>
                                                                        <Translate locale={this.props.settings.locale} value="Setup now" /></a>}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            </SlideModal>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {(contact.value == 2 || contact.value == 3) &&
                            <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                                <div className='col-lg-9 col-xs-8 col-md-9 no-gutter inlineHover'>
                                    <h4>
                                        <div className="icon-before-input pull-left">
                                            <i className="fa fa-phone" aria-hidden="true"></i>
                                        </div>
                                        <p style={{ color: 'black' }}>{contact.valueString}</p>
                                    </h4>
                                </div>
                                <div className='col-lg-3 col-xs-4 col-md-3 m-top10 no-gutter'>
                                    <div className="callIcons pull-right">
                                        {contact.valueString &&
                                            <span className="circle">
                                                <a href={"tel:" + contact.valueString}>
                                                    <i className="fa fa-volume-control-phone" aria-hidden="true"></i>

                                                </a>
                                            </span>}
                                        {contact.value == 8 &&
                                            <a href={"skype:" + contact.valueString + "?call"}>
                                                <i className="fa fa-skype" aria-hidden="true"></i>
                                                {plainTranslate(this.props.settings.locale, 'Skype')}
                                            </a>}
                                        <span className="circle" onClick={() => this.setState({ messagePopup: true })}>
                                            <i className="fa fa-commenting" aria-hidden="true"></i>
                                        </span>
                                        {this.state.messagePopup === true &&
                                            <SlideModal onClose={() => this.setState({ messagePopup: false })} title={plainTranslate(this.props.settings.locale, 'New Message')}>
                                                <section className="newPanel">
                                                    <div className="panel-body">
                                                        <div className="row m-bot15">
                                                            {this.props.smsSettings && this.props.smsSettings.length > 0 &&
                                                                <MessageContent account={this.props.account} templates={this.props.smsTemplates} smsSettingOptions={this.props.smsSettings} onCancel={() => this.setState({ messagePopup: false })} type="sms" />
                                                            }
                                                            {
                                                                this.props.smsSettings && this.props.smsSettings.length == 0 &&
                                                                <div><p>{plainTranslate(this.props.settings.locale, 'Send messages directly from an Account profile with the Mobica integration. Mobica integration-related settings on the Composity platform from Account -> SMS.')}</p>
                                                                    <a className="rectangular_button green"
                                                                        href={'/' + this.props.db + '/profile/settings/sms'}>
                                                                        <Translate locale={this.props.settings.locale} value="Setup now" /></a></div>
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            </SlideModal>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {(contact.value == 4 || contact.value == 5 || contact.value == 6 || contact.value == 7 || contact.value == 8 || contact.value == 9 ||
                            contact.value == 10 || contact.value == 11 || contact.value == 12) &&
                            <div className="line col-lg-12 col-xs-12 col-md-12 no-gutter">
                                <div className='col-lg-9 col-xs-8 col-md-9 no-gutter inlineHover'>
                                    <h4>
                                        <div className="icon-before-input pull-left">
                                            {contact.value == 4 &&
                                                <i className="fa fa-google-plus-official" aria-hidden="true"></i>
                                            }
                                            {contact.value == 5 &&
                                                <i className="fa fa-facebook-square" aria-hidden="true"></i>
                                            }
                                            {contact.value == 6 &&
                                                <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                                            }
                                            {contact.value == 7 &&
                                                <i className="fa fa-twitter-square" aria-hidden="true"></i>
                                            }
                                            {contact.value == 8 &&
                                                <i className="fa fa-skype" aria-hidden="true"></i>
                                            }
                                            {contact.value == 9 &&
                                                <i className="fa fa-fax" aria-hidden="true"></i>
                                            }
                                            {contact.value == 10 &&
                                                <i className="fa fa-map-o" aria-hidden="true"></i>
                                            }
                                            {contact.value == 11 &&
                                               <i className="fa fa-male" aria-hidden="true"></i>
                                            }
                                            {contact.value == 12 &&
                                                <i className="fa fa-info" aria-hidden="true"></i>
                                            }
                                        </div>
                                        <p style={{ color: 'black' }}>{contact.valueString}</p>
                                    </h4>
                                </div>
                            </div>
                        }
                    </>
                ))}
                {this.state.contacts.length < 1 && <div className="div-inline m-left15">
                    <span className="text-italic" style={{ color: '#a0a0a0' }}>
                        {plainTranslate(this.props.settings.locale, 'No contacts added.')}</span>
                </div>}

                {this.state.open && <Popup locale={this.props.settings.locale}
                    title={plainTranslate(this.props.settings.locale, 'Edit contacts')}
                    onClose={() => this.setState({ open: false })}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <ContactForm
                                contacts={this.state.contacts}
                                website={this.props.website}
                                accountId={this.props.accountId}
                                settings={this.props.settings}
                                db={this.props.db}
                                addContacts={this.addContacts}
                                listFields={this.props.listFields}
                                types={types}
                                openEdit={this.openEdit} />
                        </div>
                    </section>
                </Popup>}
            </>
        )
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
