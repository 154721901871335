import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import client from '../../actions/client';

//export default () => {
class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    render(){
      let db =   window.location.pathname.split('/')[1];

      let isAuthenticated = false;

      if(localStorage.jwtToken && localStorage.jwtToken !== 'undefined'){
        isAuthenticated = true;
      }

      if(db !== 'workspace' && !isAuthenticated){
          client
          .post('/saas/checks/workspace', {workspace_saas: db})
          .then(res => {
              window.location = '/' + res.data.db + '/user/signin';
          })
          .catch(err => {
              window.location = '/workspace/signin';
          });
      }else if(db !== 'workspace' && isAuthenticated){
        return <Redirect to={'/'+db+ '/dashboard'} /> 
      }
    }
};

const mapStateToProps = (state) => ({
    settings: state.settings,
    errors: state.errors
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps)(NotFound);
