import React from 'react';
import { connect } from 'react-redux';
import { plainTranslate } from "../../common/translate";
import ReactDOM from "react-dom";
import BarcodeViewer from 'react-barcode';
import { Menu, Dropdown, Button, message } from 'antd';
import { withRouter } from 'react-router-dom'
import client from '../../../actions/client'

import 'brace/mode/java';
import 'brace/theme/github';

class Barcode extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            displayValue: false
        };

        this.print = this.print.bind(this);
    }

    print(tempId = null) {
        this.setState({
            displayValue: true
        }, () => {
            let printContents = document.getElementById('barcode' + this.props.field.id).innerHTML;

            client.post('/' + this.props.settings.db + '/api/items/print/barcode', {
                template: tempId,
                barcode: printContents,
                item: this.props.formData.id
            })
                .then(response => response.data)
                .then(response => {
                    var printWindow = window.open('', 'PRINT', 'height=800,width=800');
                    printWindow.document.write(response);

                    printWindow.document.close(); // necessary for IE >= 10
                    printWindow.focus(); // necessary for IE >= 10
                    setTimeout(
                        function () {
                            printWindow.print();
                            printWindow.close();
                        }, (1000)
                    );
                    this.setState({ displayValue: false });
                })
        });

    }

    render() {
        return <div>
            <div id={'barcode' + this.props.field.id} style={{ float: 'left', padding: 10 }}>
                <BarcodeViewer value={this.props.input.value ? this.props.input.value : '0'}
                    width={2}
                    height={50}
                    displayValue={this.state.displayValue}
                    margin={0}
                />
            </div>
            {(!this.props.field.options || this.props.field.options.length === 0) &&
                <button type="button"
                    className="button-primary "
                    onClick={() => this.print()}>
                    {plainTranslate(this.props.locale, 'Print')}
                </button>
            }
            {this.props.field.options && this.props.field.options.length > 0 &&
                <Dropdown overlay={
                    <Menu>
                        {this.props.field.options.map((tab, key) => {
                            return <Menu.Item key={key} onClick={() => this.print(tab.value)}>
                                {plainTranslate(this.props.locale, tab.label)}
                            </Menu.Item>
                        })}
                    </Menu>
                }
                >
                    <button type="button"
                        className={"button-primary "}>
                        {plainTranslate(this.props.locale, 'Print')}
                        <span className="caret"></span>
                    </button>
                </Dropdown>
            }
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Barcode);
