import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";

class SingleCheckbox extends React.Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.props.input.onChange(e.target.checked ? '1' : '0');
    }
    componentDidMount() {
        if (this.props.input.value == "") {
            this.props.input.onChange("0");
        } 
    }

    render() {
        return <div className="col-xs-12 col-sm-12 no-gutter" style={{width: this.props.field.width ? this.props.field.width : 'unset'}} >
           <input style={{width: "100%", height: 16}} type="checkbox" value={this.props.input.value} defaultChecked={this.props.input.value == '1' ? true : false} onChange={this.onChange} />
        </div>
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SingleCheckbox);
