import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";
import SaveCancel from '../../common/saveCancelButtons';
import {searchItem} from "../../../actions/actions";
import FormLoader from "../../forms/form-loader";
import SlideModal from '../../common/SlideModal';
import SimpleForm from "../../redux-forms/form";
import TreeView from './treeView';
import { Pagination, Select } from 'antd';
import 'antd/lib/pagination/style/index.css';
import { withRouter } from 'react-router-dom';
import client from '../../../actions/client';
import Popup from "../../common/popup";
import showAToast from '../../common/showAToast';

const { Option } = Select;

class SearchItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            batchTableData: [],
            quantities: [],
            prices: [],
            ordered: [],
            showLoader: false,
            previewTable: false,
            batchTable: false,
            stockAvailability: false,
            page: 1,
            orderPopup: false,
            locations: [],
            loading: false
        };
        this.search = this.search.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.handleEnterClick = this.handleEnterClick.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.addBatch = this.addBatch.bind(this);
        this.closeBatchPopup = this.closeBatchPopup.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.changePage = this.changePage.bind(this);
        this.orderItem = this.orderItem.bind(this);
        this.onOrder = this.onOrder.bind(this);
        this.orderPopup = this.orderPopup.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.onPriceChange = this.onPriceChange.bind(this);
    }

    componentDidMount() {
        client.post('/' + this.props.settings.db + '/api/items/settings/stockAvailability')
        .then(response => response.data)
        .then(response => {
            this.setState({stockAvailability: response});
            if(this.props.searchValue){
                this.setState({value: this.props.searchValue});
                this.search(this.props.search);
            }
            if(this.props.selectedItem){
                this.onItemClick(this.props.selectedItem);
                this.setState({selectedItem: this.props.selectedItem});
            }
        })
        client.get('/' + this.props.settings.db + '/api/locations/settings/locations-search')
        .then(response => response.data)
        .then(response => {
            this.setState({locations: response});
        })
    }

    search(page = 1){
        if(!this.props.checkLocation || (this.props.formData.locId && this.props.formData.locId.length > 0)){
            let value = this.state.value ? this.state.value : document.getElementById('search').value;

            let docDate = null;
            if(this.props.formData.documentDate){
                docDate = this.props.formData.documentDate;
            }else if(this.props.formData.date){
                docDate = this.props.formData.date;
            }

            this.setState({previewTable: true, batchTable: false, showLoader: true, items: null, loading: true});

            let locId = this.props.formData.locId;

            if(this.props.formData.documentType == 3){
                locId = this.props.formData.locIdFrom;
            }

            if(this.props.formData.isProduction && this.props.formData.locConsumption){
                locId = this.props.formData.locConsumption;
            }

            client.post(this.props.searchUrl, {
                    'page' : page,
                    'value': value,
                    'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
                    'accId': this.props.formData.accId ? this.props.formData.accId : null,
                    'locId' : locId,
                    'stockAvailability': this.props.showAvailableItems ? this.state.stockAvailability : false,
                    'documentDate' : docDate,
                    'docType': this.props.formData.documentType ? this.props.formData.documentType : null,
                    'channel': this.props.formData.channel ? this.props.formData.channel : null,
                    'company': this.props.formData.compId ? this.props.formData.compId : null,
                    'paymentMethod': this.props.formData.payMethodId ? this.props.formData.payMethodId : null,
                })
            .then(response => response.data)
            .then(response => {
               this.setState({items: response, showLoader: false, loading: false});
            })
        }else{
            showAToast(plainTranslate(this.props.settings.locale, 'Please choose location!'), 'errSettings', 'error');
        }
        this.setState({isNew: false});
    }

    onItemClick(item){
        //this.setState({showLoader: true});

        client.post(this.props.searchBatchUrl, {
                'locId' : this.props.formData.documentType == 3 ? this.props.formData.locIdFrom : this.props.formData.locId,
                'item': item,
                'stockAvailability': this.props.showAvailableItems ? this.state.stockAvailability : false,
                'documentDate' : this.props.formData.documentDate ? this.props.formData.documentDate : null,
                'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
                'accId': this.props.formData.accId ? this.props.formData.accId : null,
                'stageId': this.props.formData.stageId,
                'channel': this.props.formData.channel ? this.props.formData.channel : null
            })
        .then(response => response.data)
        .then(response => {
            if(response.items){
                if(((Array.isArray(this.props.disabledAvailabilityStage) && this.props.disabledAvailabilityStage.includes(this.props.formData.stageId))) || (this.props.checkAvailabilityForm && item.stockAvailability > 0) || (this.props.checkAvailabilityForm && item.negativeQty == 1) || !this.props.checkAvailabilityForm){
                    this.setState({selectedItem: item, previewTable: false, batchTable: true, batchTableData: response.items, sum: response.sum}, function() {
                    });
                }
            }else{
                this.selectItem(item);
            }
        })

    }
    
    orderItem(item) {
        let itemQty = this.state.ordered.find(el => el.id === 'line_' + item.value);
        item.quantity = itemQty ? itemQty.value : 1;
        item.quantityOrdered = itemQty ? itemQty.value : 1;
        item.locOrdered = itemQty ? itemQty.location : null;
        this.props.onAddItemRow(item);
        this.props.options.map(line => {
            if (line.id === 'itId' && line.type === 'select2') {
                let value = item.value;
                let label = item.code + '/' + item.name;
                line.options.push({value, label});
            }
        })
        this.setState({orderItem: false});
    }

    orderPopup(item) {
       this.setState({orderItem: item});
    }
    
    selectItem(item, hasBatch = true, refresh = true, qty=null) {
        let availableQty = qty !== null ? qty : item.availableQuantity;
        if((this.props.checkAvailabilityForm && item.stockAvailability > 0 && availableQty === null) || (this.props.checkAvailabilityForm && availableQty > 0) || (this.props.checkAvailabilityForm && item.negativeQty == 1) || !this.props.checkAvailabilityForm || ((Array.isArray(this.props.disabledAvailabilityStage) && this.props.disabledAvailabilityStage.includes(this.props.formData.stageId)))){
            let action = this.props;
            let newLines;
            let itemQty = null;
            let itemPrice = null;
            if (hasBatch && item.batchId) {
                itemQty = this.state.quantities.find(el => el.id === 'batchLine_' + item.batchId);
            } else {
                itemQty = this.state.quantities.find(el => el.id === 'line_' + item.value);
            }

            itemPrice = this.state.prices.find(el => el.id === 'line_price_' + item.value);
            this.props.options.map(line => {
                if (line.id === 'itId' && line.type === 'select2') {
                    let value = item.value;
                    let label = item.code + '/' + item.name;
                    line.options.push({value, label});
                }
                if (line.id === 'batch' && line.type === 'select2') {
                    let value = item.batchId;
                    let label = item.batchLabel;
                    line.options.push({value, label});
                }
            })

            if (this.props.quantityUrl) {
                let docDate = null;

                if(this.props.formData.documentDate){
                    docDate = this.props.formData.documentDate;
                }else if(this.props.formData.date){
                    docDate = this.props.formData.date;
                }

                client.post(this.props.quantityUrl, {
                        'qty': itemQty ? itemQty.value : 1,
                        'itId': item.value,
                        'locId' : this.props.formData.locId,
                        'item': item,
                        'stockAvailability': this.props.showAvailableItems ? this.state.stockAvailability : false,
                        'documentDate' : docDate,
                        'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
                        'accId': this.props.formData.accId ? this.props.formData.accId : null,
                        'channel': this.props.formData.channel ? this.props.formData.channel : null
                    })
                .then(response => response.data)
                .then(response => {
                    var newItem = JSON.parse(JSON.stringify( item ));
                    response.forEach(function (arrayItem, i) {
                        newItem.measId2 = arrayItem.measId2;
                        newItem.quantity2 = arrayItem.qty2;
                        newItem.quantity = arrayItem.qty;
                        newItem.quantityOrdered = '';
                        newItem.discountRate= arrayItem.discountRate;
                        if(arrayItem.hasOwnProperty('currId')){
                            newItem.currId = arrayItem.currId;
                        }
                        if(item.batchId){
                            if(hasBatch===true){
                                newItem.batch = item.batchId;
                            }else{
                                newItem.batch = '';
                            }
                        }else{
                            newItem.batch = '';
                        }
                        newItem.batchName = item.batchLabel;
                        if (arrayItem.hasOwnProperty("price")) {
                            newItem.unitPriceOriginal = arrayItem.price;
                        }
                        action.onAddItemRow(newItem);
                    });
                });
            } else {
                var newItem = JSON.parse(JSON.stringify( item ));
                newItem.quantity = itemQty ? itemQty.value : 1;
                newItem.minQty = itemQty ? itemQty.value : 0;

                if(itemPrice){
                    newItem.price = itemPrice.value;
                }
                
                if (hasBatch === false) {
                    newItem.batch = '';
                    newItem.batchId = '';
                    newItem.batchLabel = '';
                    newItem.batchName = '';
                    newItem.availableQuantity = newItem.stockAvailability-this.state.sum;
                } else {
                    newItem.batch = newItem.batchId ? newItem.batchId : null;
                    newItem.batchId = newItem.batchId;
                    newItem.batchLabel = newItem.batchLabel;
                    newItem.batchName = newItem.batchName;
                }
                action.onAddItemRow(newItem);
            }
            if (refresh === true) {
                this.props.closeSearch();
                this.setState({items: null, previewTable: true, batchTable: false});
                document.getElementById('search').value = '';
            }
        }
    }

    handleEnterClick(e) {
        if (e.key === 'Enter') {
            this.search();
        }
    }

     handleButtonClick() {
        this.search();
    }

    handleCheckboxChange(e){
        let stockAvailability = this.state.stockAvailability;
        if(e.target.name === "stockAvailability"){
          stockAvailability = e.target.checked;
        }
        this.setState({
          stockAvailability: stockAvailability,
        });

        client.post('/' + this.props.settings.db + '/api/items/save/settings/stockAvailability', {
                value: stockAvailability
            })
    }

    onOrder(e) {
        let qty = e.target.value;
        let ordered = this.state.ordered;
        let item = ordered.find(el => el.id === e.target.id);

        if (item) {
            item.value = qty;
        } else {
            ordered.push({
                location: this.state.locations.length > 0 ? this.state.locations[0].id : '',
                value: qty,
                id: e.target.id
            });
        }

        this.setState({ordered: ordered});
    }

    onLocationChange(loc, itemId) {
        let ordered = this.state.ordered;
        let item = ordered.find(el => el.id === itemId);

        if (item) {
            item.location = loc;
        } else {
            ordered.push({
                location: loc,
                id: itemId
            });
        }

        this.setState({ordered: ordered});
    }

    onChange(e, item = false, quantity=null) {
        let qty = e.target.value;
        if (!item && e.target.id !== 'search') {
            let itemId = e.target.id.split("_").pop();
            item = this.state.items.find(o => o.value === itemId);
        }

        let availableQty = quantity ? quantity : Number(item.stockAvailability);

        if (Array.isArray(this.props.disabledAvailabilityStage) && !this.props.disabledAvailabilityStage.includes(this.props.formData.stageId)) {
            if (this.props.checkAvailabilityForm) {
                if (item.checkQuantity) {
                    if(item.hasOwnProperty('batchName') && item.batchName !== undefined){
                        if ((e.target.value > Number(item.availableQuantity) || e.target.value > Number(item.stockAvailability)) && (item.negativeQty == 0)) {
                            if(Number(item.availableQuantity) < Number(item.stockAvailability)){
                                qty = Number(item.availableQuantity);
                            } else {
                                qty = Number(item.stockAvailability);
                            }
                            if (qty < 0) {
                                qty = 0;
                            }
                            showAToast(plainTranslate(this.props.settings.locale, 'Insufficient quantity'), 'errSettings', 'error');
                        }
                    } else {
                        if (e.target.value > availableQty && (item.negativeQty == 0)) {
                            qty = availableQty;
                            if (qty < 0) {
                                qty = 0;
                            }
                            showAToast(plainTranslate(this.props.settings.locale, 'Insufficient quantity'), 'errSettings', 'error');
                        }
                    }
                }
            }
        }
        
        if (e.target.id === 'search') {
            this.setState({value: e.target.value});
        } else {
            let quantities = this.state.quantities;

            let item = quantities.find(el => el.id === e.target.id);

            if(item){
                item.value = qty;
            } else {
                quantities.push({
                    value: qty,
                    id:  e.target.id
                });
            }

            this.setState({quantities: quantities});
        }
    }

    onPriceChange(e){
        let price = e.target.value;
        let prices = this.state.prices;

        let item = prices.find(el => el.id === e.target.id);

        if(item){
            item.value = price;
        } else {
            prices.push({
                value: price,
                id:  e.target.id
            });
        }

        this.setState({prices: prices});
    }

    addBatch(item=false) {
        if(item){
            this.setState({addBatchItem: item});
        }
        this.setState({batchForm: true});
    }

    closeBatchPopup(){
        if(this.state.addBatchItem){
            this.search();
        }

        if(this.state.selectedItem){
            this.onItemClick(this.state.selectedItem);
        }
        this.setState({batchForm: false});
    }

    handleFocus(event){
        event.target.select();
    }

    changePage(page, pageSize) {
        this.search(page);
        this.setState({page:page});
    }

    render() {
        let taxRate = this.props.formData.taxRate ? this.props.formData.taxRate : this.props.formData.vatPercent;
        let quantities = this.state.quantities;
        let prices = this.state.prices;
        let ordered = this.state.ordered;
        let items = this.state.items ? this.state.items : null;

        if (items) {
            items.forEach(function (item) {
                if (!item.hasOwnProperty('batch')) {
                    item.batch = [];
                }
            });
        }
        let selectedItem = this.state.selectedItem ? this.state.selectedItem : null;
        let sum = this.state.sum;
        let location = this.state.locations.length > 0 ? this.state.locations[0].id : '';

        let hasBatch = this.props.options.find(o => o.id === 'batch');
        if(!hasBatch){
            hasBatch = this.props.options.find(o => o.id === 'batchName');
        }else if(!hasBatch){
            hasBatch = false;
        }

        //let custFormData = {item: selectedItem, this.props.formData.locId};
        return <div>
                <div className="searchBar">
                    <input onChange={this.onChange} value={this.state.value} onKeyDown={this.state.loading === false ? this.handleEnterClick : null} type="text" id="search" placeholder={plainTranslate(this.props.settings.locale, 'Type to search item')} autoComplete="off"></input><button type="button" className={"button-primary " + (this.state.loading ? 'disabled' : '')} onClick={this.state.loading === false ? this.handleButtonClick : null}>{plainTranslate(this.props.settings.locale, 'Search')}</button>
                    {this.props.showAvailableItems &&
                        <span>
                            <input type="checkbox" id="stockAvailability" name="stockAvailability" onChange={this.handleCheckboxChange} value="stockAvailability" checked={this.state.stockAvailability || false} className="stockAvailability"/>
                            <label style={{marginLeft: 5}} htmlFor="stockAvailability">{plainTranslate(this.props.settings.locale, 'Show Available Items')}</label>
                        </span>
                    }
                </div>
                <div className="table">
                    {this.state.previewTable === false && this.state.batchTable === false &&
                        <TreeView 
                                checkAvailabilityForm={this.props.checkAvailabilityForm} 
                                disabledAvailabilityStage={this.props.disabledAvailabilityStage}
                                onAddItemRow={this.props.onAddItemRow} 
                                quantityUrl={this.props.quantityUrl} 
                                docType={this.props.formData.documentType} 
                                stockAvailability={this.props.showAvailableItems ? this.state.stockAvailability : false} 
                                treeFindItem={this.props.treeFindItem} 
                                line={this.props.line} 
                                change={this.props.change} 
                                options={this.props.options} 
                                treeUrl={this.props.treeUrl} 
                                searchUrl={this.props.searchUrl} formData={this.props.formData}
                        />
                    }
                    {this.state.previewTable && items && items.length > 0 &&
                        <React.Fragment>
                        <table className="searchItem-table">
                            <thead style={{borderLeft: 0, borderTop: 0, borderBottom: 0}}>
                                <tr>
                                    <td style={{borderTop: "1px solid #ccc", borderLeft: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Item Code')}</td>
                                    <td style={{borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Name')}</td>
                                    <td style={{borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Category')}</td>
                                    <td style={{borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Measure')}</td>
                                    {!this.props.formData.hasOwnProperty('priority') && !this.props.formData.hasOwnProperty('isProduction') &&
                                        <td style={{borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Stock Availability')}</td>
                                    }
                                    <td style={{borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Price')}</td>
                                    {taxRate &&
                                        <td style={{borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Price with VAT')}</td>
                                    }
                                    <td style={{borderTop: "1px solid #ccc", width: 40}}>{plainTranslate(this.props.settings.locale, this.props.formData.hasOwnProperty('priority') ? 'Minimum Quantity' : 'Quantity')}</td>
                                    {this.props.orderQty &&
                                        <td style={{width: 80}} className="right tableHeader white"></td>
                                    }
                                    <td style={{width: 80}} className="right tableHeader white"></td>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map(item => {
                                    let price = item.price
                                    if(taxRate){
                                        price = parseFloat(item.price) + parseFloat(item.price)*(taxRate/100);
                                    }

                                    return <tr>
                                        {((hasBatch && hasBatch.hide === true) || (item.batch === null || (item.batch !== null && item.batch.length == 0)) || (this.props.formData.hasOwnProperty('priority') && !this.props.formData.hasOwnProperty('isProduction'))) &&
                                            <td data-title={plainTranslate(this.props.settings.locale, 'Item Code')} title={plainTranslate(this.props.settings.locale, `Add ${item.code}`)}
                                            className={(this.props.checkAvailabilityForm && item.stockAvailability > 0) || (this.props.checkAvailabilityForm && item.negativeQty == 1) || !this.props.checkAvailabilityForm ? "cursorSelect" : ""} onClick={() => this.selectItem(item)}>{item.code}
                                            </td>
                                        }

                                        {hasBatch && ((hasBatch.hasOwnProperty('hide') && hasBatch.hide === false) || !hasBatch.hasOwnProperty('hide')) && (item.batch !== null && item.batch.length > 0) && !this.props.formData.hasOwnProperty('priority') && !this.props.formData.hasOwnProperty('isProduction') &&
                                            <td 
                                                data-title={plainTranslate(this.props.settings.locale, 'Item Code')} 
                                                title={plainTranslate(this.props.settings.locale, `Add ${item.code}`)} 
                                                className={((Array.isArray(this.props.disabledAvailabilityStage) && this.props.disabledAvailabilityStage.includes(this.props.formData.stageId))) || (this.props.checkAvailabilityForm && item.stockAvailability > 0) || (this.props.checkAvailabilityForm && item.negativeQty == 1) || !this.props.checkAvailabilityForm ? "cursorSelect" : ""} 
                                                onClick={() => this.onItemClick(item)}>{item.code}
                                            </td>
                                        }
                                        {((hasBatch && hasBatch.hide === true) || (item.batch === null || (item.batch !== null && item.batch.length == 0)) || this.props.formData.hasOwnProperty('priority') && !this.props.formData.hasOwnProperty('isProduction')) &&
                                            <td 
                                                data-title={plainTranslate(this.props.settings.locale, 'Name')} 
                                                title={plainTranslate(this.props.settings.locale, `Add ${item.code}`)} 
                                                className={(this.props.checkAvailabilityForm && item.stockAvailability > 0) || (this.props.checkAvailabilityForm && item.negativeQty == 1) ||  !this.props.checkAvailabilityForm ? "cursorSelect" : ""} 
                                                onClick={() => this.selectItem(item)}>{item.name}
                                            </td>
                                        }
                                        {hasBatch && ((hasBatch.hasOwnProperty('hide') && hasBatch.hide === false) || !hasBatch.hasOwnProperty('hide')) && (item.batch !== null && item.batch.length > 0) && !this.props.formData.hasOwnProperty('priority') && !this.props.formData.hasOwnProperty('isProduction') &&
                                            <td data-title={plainTranslate(this.props.settings.locale, 'Name')} title={plainTranslate(this.props.settings.locale, `Add ${item.code}`)} className={((Array.isArray(this.props.disabledAvailabilityStage) && this.props.disabledAvailabilityStage.includes(this.props.formData.stageId))) || (this.props.checkAvailabilityForm && item.stockAvailability > 0) || (this.props.checkAvailabilityForm && item.negativeQty == 1) || !this.props.checkAvailabilityForm ? "cursorSelect" : ""} onClick={() => this.onItemClick(item)}>{item.name}</td>
                                        }
                                        <td data-title={plainTranslate(this.props.settings.locale, 'Category')} >{item.category}</td>
                                        <td data-title={plainTranslate(this.props.settings.locale, 'Measure')} >{item.measName}</td>
                                        {!this.props.formData.hasOwnProperty('priority') && !this.props.formData.hasOwnProperty('isProduction') &&
                                            <td data-title={plainTranslate(this.props.settings.locale, 'Stock Availability')}>{item.stockAvailability}</td>
                                        }
                                        <td data-title={plainTranslate(this.props.settings.locale, 'Price')}>
                                            {this.props.editPrice &&
                                                <span><input type="number" onFocus={this.handleFocus} className="col-md-7" onChange={this.onPriceChange} value={prices.find(el => el.id === 'line_price_'+item.value) ? prices.find(el => el.id === 'line_price_'+item.value).value : item.price} id={'line_price_'+item.value}/></span>
                                            }
                                            {!this.props.editPrice &&
                                                <span>{item.price}</span>
                                            }
                                        </td>
                                        {taxRate &&
                                            <td data-title={plainTranslate(this.props.settings.locale, 'Price with VAT')}>{price.toFixed(5)}</td>
                                        }
                                        <td data-title={plainTranslate(this.props.settings.locale, this.props.formData.hasOwnProperty('priority') ? 'Minimum Quantity' : 'Quantity')} className="searchLine-quantity">
                                            {(
                                                (this.props.checkAvailabilityForm && item.stockAvailability > 0) || 
                                                (this.props.checkAvailabilityForm && item.negativeQty == 1) || 
                                                !this.props.checkAvailabilityForm ||
                                                ((Array.isArray(this.props.disabledAvailabilityStage) && this.props.disabledAvailabilityStage.includes(this.props.formData.stageId)))
                                                ) && 
                                                ((hasBatch && hasBatch.hide === true) || (item.batch === null || (item.batch !== null && item.batch.length == 0)) || this.props.formData.hasOwnProperty('priority') && !this.props.formData.hasOwnProperty('isProduction')) &&
                                                <span><input type="number" onFocus={this.handleFocus} className="col-md-7" onChange={this.onChange} value={quantities.find(el => el.id === 'line_'+item.value) ? quantities.find(el => el.id === 'line_'+item.value).value : this.props.formData.hasOwnProperty('priority') ? 0 : 1} id={'line_'+item.value}/>
                                                <i className="fa fa-check-circle" title={plainTranslate(this.props.settings.locale, `Add ${item.code}`)} style={{marginLeft: 15}} onClick={() => this.selectItem(item, true, false)}></i></span>
                                            }
                                            {(Array.isArray(this.props.disabledAvailabilityStage) && !this.props.disabledAvailabilityStage.includes(this.props.formData.stageId)) && this.props.checkAvailabilityForm && item.stockAvailability <= 0 && item.negativeQty == 0 &&
                                                <span><h4>{plainTranslate(this.props.settings.locale, 'Out of stock')}</h4></span>
                                            }
                                        </td>
                                        {!this.props.formData.isPos && hasBatch && ((hasBatch.hasOwnProperty('hide') && hasBatch.hide === false) || !hasBatch.hasOwnProperty('hide')) && (item.batch === null || (item.batch !== null && item.batch.length == 0)) && !this.props.formData.hasOwnProperty('priority') && !this.props.formData.hasOwnProperty('isProduction') &&
                                            <td style={{border: 0}}>
                                                <span title={plainTranslate(this.props.settings.locale, `Create new batch`)} >
                                                    <a className="add-customer-label" style={{display: 'flex'}} onClick={() => this.addBatch(item)}>
                                                        <i className="fa fa-plus-circle" style={{float: 'none'}}></i>
                                                        <p>{plainTranslate(this.props.settings.locale, 'Batch')}</p>
                                                    </a>
                                                </span>
                                            </td>
                                        }
                                        {this.props.orderQty &&
                                            <td style={{border: 0}}>
                                                <a className="add-customer-label" 
                                                style={{display: "flex"}} onClick={() => this.orderPopup(item)} 
                                                title={plainTranslate(this.props.settings.locale, 'Order')}>
                                                    <i className="fa fa-plus-circle" style={{float: 'none'}}></i>
                                                    <p>{plainTranslate(this.props.settings.locale, 'Order')}</p></a>
                                            </td>
                                        }
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        {items[0].count > 20 &&
                            <Pagination 
                            defaultCurrent={this.state.page} 
                            total={items[0].count} 
                            defaultPageSize={20} 
                            onChange={this.changePage}
                            showSizeChanger={false}/>
                        }
                        </React.Fragment>
                    }
                    {items && items.length === 0 &&
                        <span>0 results found</span>
                    }
                    {this.state.batchTable &&
                        <div>
                        <span className="cursorSelect" onClick={() => this.setState({previewTable: true, batchTable: false, selectedItem: null})}><i className="ion-ios-arrow-back"></i> {plainTranslate(this.props.settings.locale, 'Back')}</span>
                        {!this.props.formData.isPos && hasBatch && ((hasBatch.hasOwnProperty('hide') && hasBatch.hide === false) || !hasBatch.hasOwnProperty('hide')) &&
                            <span style={{marginLeft: 50}} className="pull-right">
                                <a className="add-customer-label" onClick={this.addBatch}>
                                    <i className="fa fa-plus-circle" style={{float: 'none'}}></i>
                                    <p>{plainTranslate(this.props.settings.locale, 'New batch')}</p>
                                </a>
                            </span>
                        }
                        <table className="searchItem-table">
                            <thead style={{borderLeft: 0, borderTop: 0, borderBottom: 0}}>
                                <tr>
                                    <td data-title={plainTranslate(this.props.settings.locale, 'Item Code')} style={{borderTop: "1px solid #ccc", borderLeft: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Item Code')}</td>
                                    <td data-title={plainTranslate(this.props.settings.locale, 'Name')} style={{borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Name')}</td>
                                    <td data-title={plainTranslate(this.props.settings.locale, 'Batch')} style={{borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Batch')}</td>
                                    <td data-title={plainTranslate(this.props.settings.locale, 'Category')} style={{borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Category')}</td>
                                    <td data-title={plainTranslate(this.props.settings.locale, 'Measure')} style={{borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Measure')}</td>
                                    <td data-title={plainTranslate(this.props.settings.locale, 'Batch availability')} style={{maxWidth: 100, borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Batch availability')}</td>
                                    <td data-title={plainTranslate(this.props.settings.locale, 'Price')} style={{minWidth: 80, borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Price')}</td>
                                    {taxRate &&
                                        <td data-title={plainTranslate(this.props.settings.locale, 'Price with VAT')} style={{borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Price with VAT')}</td>
                                    }
                                    <td data-title={plainTranslate(this.props.settings.locale, 'Date Expire')} style={{minWidth: 100, borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Date Expire')}</td>
                                    <td data-title={plainTranslate(this.props.settings.locale, 'Quantity')} style={{minWidth: 100, borderTop: "1px solid #ccc"}}>{plainTranslate(this.props.settings.locale, 'Quantity')}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.batchTableData.length > 0 &&
                                <tr style={{fontSize: 15}}>
                                    <td className={(this.props.checkAvailabilityForm && (this.state.batchTableData[0].stockAvailability-sum) > 0) || (this.props.checkAvailabilityForm && this.state.batchTableData[0].negativeQty == 1) || !this.props.checkAvailabilityForm || ((Array.isArray(this.props.disabledAvailabilityStage) && this.props.disabledAvailabilityStage.includes(this.props.formData.stageId))) ? "cursorSelect" : ""} onClick={() => this.selectItem(this.state.batchTableData[0],false, true,(this.state.batchTableData[0].stockAvailability-sum))}>{this.state.batchTableData[0].code}</td>
                                    <td className={(this.props.checkAvailabilityForm && (this.state.batchTableData[0].stockAvailability-sum) > 0) || (this.props.checkAvailabilityForm && this.state.batchTableData[0].negativeQty == 1) || !this.props.checkAvailabilityForm || ((Array.isArray(this.props.disabledAvailabilityStage) && this.props.disabledAvailabilityStage.includes(this.props.formData.stageId))) ? "cursorSelect" : ""} onClick={() => this.selectItem(this.state.batchTableData[0],false, true,(this.state.batchTableData[0].stockAvailability-sum))}>{this.state.batchTableData[0].name}</td>
                                    <td >{plainTranslate(this.props.settings.locale, 'Without batch')}</td>
                                    <td>{this.state.batchTableData[0].category}</td>
                                    <td>{this.state.batchTableData[0].measName}</td>
                                    <td>{this.state.batchTableData[0].stockAvailability-sum}</td>
                                    <td>{this.state.batchTableData[0].price}</td>
                                    {taxRate &&
                                        <td>{parseFloat(parseFloat(this.state.batchTableData[0].price) + parseFloat(this.state.batchTableData[0].price*(taxRate/100))).toFixed(5)}</td>
                                    }
                                    <td></td>
                                    <td className="searchLine-quantity">
                                    {((this.props.checkAvailabilityForm && this.state.batchTableData[0].stockAvailability-sum > 0) || 
                                     (this.props.checkAvailabilityForm && this.state.batchTableData[0].negativeQty == 1) || 
                                     !this.props.checkAvailabilityForm || 
                                     ((Array.isArray(this.props.disabledAvailabilityStage) && this.props.disabledAvailabilityStage.includes(this.props.formData.stageId)))) &&
                                        <span><input type="number" onFocus={this.handleFocus} className="col-md-7" onChange={(e)=> this.onChange(e, null, (this.state.batchTableData[0].stockAvailability-sum))} value={quantities.find(el => el.id === 'line_'+this.state.batchTableData[0].value) ? quantities.find(el => el.id === 'line_'+this.state.batchTableData[0].value).value : 1} id={'line_'+this.state.batchTableData[0].value}/>
                                        <i className="fa fa-check-circle" title={plainTranslate(this.props.settings.locale, `Add item`)}  style={{marginLeft: 15}} onClick={() => this.selectItem(this.state.batchTableData[0],false, false,(this.state.batchTableData[0].stockAvailability-sum))}></i></span>
                                    }
                                    {(Array.isArray(this.props.disabledAvailabilityStage) && !this.props.disabledAvailabilityStage.includes(this.props.formData.stageId)) && this.props.checkAvailabilityForm && this.state.batchTableData[0].stockAvailability-sum <= 0 && this.state.batchTableData[0].negativeQty == 0 &&
                                        <span><h4>{plainTranslate(this.props.settings.locale, 'Out of stock')}</h4></span>
                                    }
                                    </td>
                                </tr>
                            }
                                {this.state.batchTableData.map(data =>{
                                    return <tr style={{fontSize: 15}}>
                                        <td className={(this.props.checkAvailabilityForm && data.availableQuantity > 0) || (this.props.checkAvailabilityForm && data.negativeQty == 1) || !this.props.checkAvailabilityForm || ((Array.isArray(this.props.disabledAvailabilityStage) && this.props.disabledAvailabilityStage.includes(this.props.formData.stageId))) ? "cursorSelect" : ""} onClick={() => this.selectItem(data)}>{data.code}</td>
                                        <td className={(this.props.checkAvailabilityForm && data.availableQuantity > 0) || (this.props.checkAvailabilityForm && data.negativeQty == 1) || !this.props.checkAvailabilityForm || ((Array.isArray(this.props.disabledAvailabilityStage) && this.props.disabledAvailabilityStage.includes(this.props.formData.stageId))) ? "cursorSelect" : ""} onClick={() => this.selectItem(data)}>{data.name}</td>
                                        <td>{data.batchLabel}</td>
                                        <td>{data.category}</td>
                                        <td>{data.measName}</td>
                                        <td>{data.availableQuantity}</td>
                                        <td>{data.price}</td>
                                        {taxRate &&
                                            <td>{parseFloat(parseFloat(data.price) + parseFloat(data.price*(taxRate/100))).toFixed(5)} </td>
                                        }
                                        <td>{data.dateExpire}</td>
                                        <td className="searchLine-quantity">
                                        {((this.props.checkAvailabilityForm && data.availableQuantity > 0) || 
                                         (this.props.checkAvailabilityForm && data.negativeQty == 1) || 
                                         !this.props.checkAvailabilityForm || 
                                         ((Array.isArray(this.props.disabledAvailabilityStage) && this.props.disabledAvailabilityStage.includes(this.props.formData.stageId)))) &&
                                            <span><input type="number" onFocus={this.handleFocus} className="col-md-7" onChange={(e)=>this.onChange(e, data)} value={quantities.find(el => el.id === 'batchLine_'+data.batchId) ? quantities.find(el => el.id === 'batchLine_'+data.batchId).value : 1} id={'batchLine_'+data.batchId}/>
                                            <i className="fa fa-check-circle" style={{marginLeft: 15}} onClick={() => this.selectItem(data,true, false)}></i></span>
                                        }
                                        {(Array.isArray(this.props.disabledAvailabilityStage) && !this.props.disabledAvailabilityStage.includes(this.props.formData.stageId)) && this.props.checkAvailabilityForm && data.availableQuantity <= 0 && data.negativeQty == 0 &&
                                        <span><h4>{plainTranslate(this.props.settings.locale, 'Out of stock')}</h4></span>
                                    }
                                        </td>
                                    </tr>
                               })}

                            </tbody>
                        </table>
                        </div>
                    }
                    {
                        this.state.showLoader &&
                        <FormLoader/>
                    }
                    {
                        this.state.batchForm &&
                        <SlideModal onClose={this.closeBatchPopup} title={plainTranslate(this.props.settings.locale, 'Batch')}>
                            <section className="newPanel">
                                <div className="panel-body">
                                    <div className="row m-bot15">
                                        <SimpleForm
                                            saveRoute={this.props.settings.db + '/api/batches'}
                                            formRoute={this.props.settings.db + '/api/batches/'}
                                            custFormData={{locId:this.props.formData.locId}}
                                            //match={{params: {id: null}}}
                                            //location={{refs: this.state.selectedItem.value}}
                                            match={{params: {id: 0}}}
                                            onSuccessfulSave={this.closeBatchPopup}
                                            isNew={true}
                                            popup={true}
                                            onCancel={this.closeBatchPopup}
                                            //account={this.state.selectedItem.value}
                                            refs={this.state.selectedItem ? this.state.selectedItem.value : this.state.addBatchItem.value}
                                            //refs={this.state.selectedItem.value ? this.state.selectedItem.value : null}
                                            form={'initializeFromState'}
                                        />

                                    </div>
                                </div>
                            </section>
                        </SlideModal>
                    }
                </div>
                {this.state.orderItem &&
                 <Popup locale={this.props.settings.locale}
                       title={plainTranslate(this.props.settings.locale, 'Order')}
                       onClose={() => this.setState({orderItem: false})}>
                  <section className="newPanel">
                    <div className="panel-body col-md-12" style={{width: "100%", display: "grid"}}>
                      <div className="col-md-12" style={{marginBottom: 10}}>
                          <label style={{marginRight: 5}}>{plainTranslate(this.props.settings.locale, 'Item')}:</label>
                          <p style={{fontSize: 15}}>{this.state.orderItem.name}</p>
                      </div>
                        <div className="col-md-6" style={{marginBottom: 30}}>
                            <label className="col-md-12 no-gutter" style={{marginRight: 5}} for="quantity">{plainTranslate(this.props.settings.locale, 'Quantity')}</label>
                            <input autoFocus className="col-md-12" onChange={this.onOrder} type="number" value={ordered.find(el => el.id === 'line_'+this.state.orderItem.value) ? ordered.find(el => el.id === 'line_'+this.state.orderItem.value).value : ''} id={'line_'+this.state.orderItem.value}></input>
                        </div>
                          <div className="col-md-6 " style={{marginBottom: 30}}>
                              <label className="col-md-12 no-gutter" style={{marginRight: 5}} for="quantity">{plainTranslate(this.props.settings.locale, 'From')}</label>
                              <Select        
                              style={{ width: '100%' }}
                              size={'large'}                       
                              value={ordered.find(el => el.id === 'line_'+this.state.orderItem.value) ? ordered.find(el => el.id === 'line_'+this.state.orderItem.value).location : location} 
                              id={'line_'+this.state.orderItem.value}>
                                      {this.state.locations.map((location, key) => {
                                        return <Option 
                                        key={key} 
                                        value={location.id} 
                                        onClick={() => this.onLocationChange(location.id, 'line_'+this.state.orderItem.value)}>
                                            {plainTranslate(this.props.settings.locale, location.label)}</Option>
                                      }
                                        )}
                              </Select>
                          </div>
                        <div>
                        <button type="button" className="button-primary" onClick={() => this.orderItem(this.state.orderItem)}>{plainTranslate(this.props.settings.locale, 'Add')}</button>
                        </div>
                    </div>
                </section>
                </Popup>
                }
            </div>

    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
    item: state.importData
});

const mapDispatchToProps = dispatch => ({
    searchItem: (db, items, locId) => dispatch(searchItem(db, items, locId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchItem);
