import React from 'react';
import {connect} from 'react-redux';
import RichTextEditor from 'react-rte';

class Rte extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: RichTextEditor.createValueFromString(this.props.value ? this.props.value : '', 'html'),
            asHtml: null
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({value: RichTextEditor.createValueFromString(this.props.value ? this.props.value : '', 'html')});
        }
    }

    onChange(value) {
        this.setState({value});
    };

    render() {
        return <RichTextEditor
            value={this.state.value}
            onChange={this.onChange}
            tabIndex = {this.props.tabindex ? this.props.tabindex : ''}
            onBlur={() => this.props.onChange(this.props.id, this.state.value.toString('html'))}
        />
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Rte);
