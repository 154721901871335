import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSettings } from '../../actions/settingsActions'
import { withRouter } from 'react-router-dom'
import setFileApi from '../../utils/setFileApi'
import {setCustomTranslations} from '../common/translate';
import '../../css/layout.css';
import '../../assets/css/style.css';
import { onUserRolesGridLoad } from '../../actions/actions';

class Layout extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {    
        if (!this.props.settings.db) {   
            this.props.getSettings(this.props.match.params.db)  
        }      
    }
  
    static getDerivedStateFromProps(props){        
        if(props.settings.translations){
            setCustomTranslations(JSON.parse(props.settings.translations));
         }
         if(props.settings.storageToken && props.settings.storageUrl){           
             localStorage.setItem('storageUrl', props.settings.storageUrl)
             localStorage.setItem('storageToken', props.settings.storageToken)
            setFileApi(props.settings.storageUrl, props.settings.storageToken)
         }
         return {};
    }
   

    render() {
        return (
                <div>
                    <section id="main-content"
                             className={this.props.settings.sidebar === 'nav-collapse closed' ? 'merge-left' : ''}>
                        <section className='wrapper'>
                            <div>
                                {this.props.children}
                            </div>
                        </section>
                    </section>
                </div>
                );
    }
}

Layout.propTypes = {
    auth: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    children: PropTypes.node,
    getSettings: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
        auth: state.auth,
        settings: state.settings
    });

export default connect(mapStateToProps, {getSettings})(withRouter(Layout));
