import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import {connect} from 'react-redux';
import {onAccountFetchData, onFieldSave} from "../../../actions/actions";
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import {onSelect2InputChange, onAddSelectOption} from '../../../actions/actions';
import Collapsible from './collapsible';

class MultipleSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: [],
        };
        this.onChange = this.onChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
    }
    
    onChange(value) {
        let array = [];
        var i;
        for (i = 0; i < value.length; i++) {
            let newItem = {};
            newItem['value'] = value[i]['value'];
            if (value[i].hasOwnProperty('name')) {
                newItem['label'] = value[i]['name'];
            } else {
                newItem['label'] = value[i]['label'];
            }
            array.push(newItem);
        }

        this.setState({value: array});
        this.props.change(this.props.field.id, JSON.stringify(array));
    }
    
    componentDidMount() {
        if (this.props.input.value && this.props.input.value !== '' && this.props.input.value.length !== 0) {
            this.setState({
                value: JSON.parse(this.props.input.value)
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.input.value != prevProps.input.value) {
            if (this.props.input.value && this.props.input.value !== '' && this.props.input.value.length !== 0) {
                this.setState({
                    value: JSON.parse(this.props.input.value)
                });
            }
        }
    }
    
    loadOptions(inputValue, callback) {
        if (!inputValue) inputValue = -1;
        let hasSettings = this.props.field.settings && this.props.field.settings.optionsUrl;
    
        clearTimeout(this.inputTimer);
        this.inputTimer = setTimeout(()=>{
            if(hasSettings){
                this.props.onInputChange('initializeFromState2', this.props.field.id, inputValue, this.props.field.settings.optionsUrl)
                .then(response => {
                    let options = [];
                    if (response.payload && response.payload.options && response.payload.options.length) {
                        options = response.payload.options.map(option => {
                            return {
                                ...option,
                                'value': option.value,
                                'label': option.label
                            };
                        });
                    }
                    this.options = options;
                    this.setState({options: this.options});
                    if(options.length > 0) this.props.onNewOptions(options);
                    else if(this.props.field.options.length > 0) this.props.onNewOptions(this.props.field.options);

                    
                    callback(options ? options : this.props.field.options);
                });
            }else{
                let ops = this.props.field.options;
                ops = ops.filter(item => item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);
                callback(ops);
            }
        }, 1000);
    }

    render() {
        const customStyles = {
            valueContainer: (base, state) => ({
                ...base,
                height: '65px',
                overflowY: 'auto'
            }),
        };
        let isDisabled = this.props.field.isDisabled ? this.props.field.isDisabled : false;
        const element = <div className="inlineHover input-select2">
                                <div className={this.props.field.collapsible ? "col-lg-8 col-md-8 col-xs-8 no-gutter" : "col-lg-12 col-md-12 col-xs-12 no-gutter"}>
                                    <AsyncSelect
                                        isMulti={true}
                                        value={this.state.value}
                                        onChange={this.onChange}
                                        options={this.props.field.options}
                                        isDisabled={isDisabled}
                                        styles={customStyles}
                                        loadOptions={this.loadOptions}
                                        defaultOptions={this.props.field.options}
                                        isClearable={true}
                                    />
                                </div>  
                        </div>;

        return (
            <React.Fragment>
                {this.props.field.collapsible ?
                    <Collapsible
                      locale={this.props.locale}
                      title={false}
                      subtitle={this.props.field.description}> 
                      {element}
                    </Collapsible>
                    : <React.Fragment>{element}</React.Fragment>
                }
            </React.Fragment>
        );
    }
}

MultipleSelect.defaultProps = {
    onNewOptions: () => {},
};

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData)),
    onInputChange: (formId, id, value, route) => dispatch(onSelect2InputChange(formId, id, value, route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleSelect);
