import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from "../../common/translate";
import SingleCheckbox from './singleCheckbox';
import Collapsible from './collapsible';

class SelectableArrayFields extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ''
        };  
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
       this.setState({
            value: this.props.input.value ? this.props.input.value : []
        });
    }

    onChange(name) {
        const settings = this.state.value ? this.state.value : [];
        for (var i = 0; i < settings.length; i++) {
            if (settings[i] == name) {
                var index = i;
            }
        }
        if (index != undefined) {
            settings.splice(index, 1);
        } else {
            let obj = {};
            obj = name;
            settings.push(obj);
        }

        this.setState({
            settings,
        }, function () {
            this.props.change(this.props.field.id, this.state.value);
        });
    }

     render() {
         const element = <div className="col-md-12 no-gutter">
                            <div className="panel panel-default" style={{marginTop: 0}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                         {this.props.field.fieldNames && this.props.field.fieldNames.map((label, key) => (
                                            <th key={key}><Translate locale={this.props.locale} value={label}/></th>
                                        ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.fields && this.props.fields.map((item, key) => (
                                            <SingleCheckbox
                                                locale={this.props.locale}                                                   
                                                key={key}                                      
                                                name={item.label}
                                                type={item.type}
                                                label={item.name}
                                                isChecked={this.state.value && this.state.value.find(cat => cat==[item.label]) ? true:false}
                                                handleCheckboxChange={this.onChange}
                                                />
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                    </div>;

         return <React.Fragment>
                {this.props.field.collapsible ?
                    <Collapsible
                      locale={this.props.locale}
                      title={false}
                      subtitle={this.props.field.description}> 
                      {element}
                    </Collapsible>
                    : {element}
                }
                </React.Fragment>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SelectableArrayFields);

