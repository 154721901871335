import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../common/translate';
import Select from 'react-select';

class DoubleSelectLine extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
           
        };
        this.onChange = this.onChange.bind(this); 
        this.removeLine = this.removeLine.bind(this);
    }
    
    onChange(option, type) {
        this.props.onChange(option.value, type);
    }
    
    removeLine() {
        this.props.removeLine();
    }

    render() {
        return (
                <React.Fragment>
                        <div className={'row'}>
                            <div className={'col-md-5'}>
                                <div className="form-group">
                                    <label
                                        className="required col-md-12 text-left">
                                        {plainTranslate(this.props.settings.locale, 'Stage')}
                                    </label>
                                    <div className={'col-md-12'}>  
                                        <Select
                                            key={`stage`+this.props.key}
                                            value={this.props.setting ? this.props.setting.find(o => o.value === this.props.value.stage) : null}
                                            options={this.props.setting}
                                            onChange={(value) => this.onChange(value, 'stage')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-5'}>
                                <div className="form-group">
                                    <label
                                        className="required col-md-12 text-left">
                                        {plainTranslate(this.props.settings.locale, 'Template')}
                                    </label>
                                    <div className={'col-md-12'}>
                                        <Select
                                            key={`template`+this.props.key}
                                            value={this.props.secondSetting ? this.props.secondSetting.find(o => o.value === this.props.value.template) : null}
                                            options={this.props.secondSetting}
                                            onChange={(value) => this.onChange(value, 'template')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-2'}>
                                <button type="button" className="button-outline grey pull-right" style={{borderColor: 'red', color: 'red', marginTop: '30px'}} onClick={this.removeLine}>
                                    {plainTranslate(this.props.settings.locale, 'Delete')}
                                </button>
                            </div>
                        </div>
                </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DoubleSelectLine);


