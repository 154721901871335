import React from 'react';
import PropTypes from 'prop-types';
import translations from './translations';

let customTranslations = [];

class Translate extends React.Component {
    constructor(props) {
        super(props);
        this.translate = this.translate.bind(this);
    }

    translate() {       
        if(this.props.locale in customTranslations) {
            if(this.props.value in customTranslations[this.props.locale]) {
                return customTranslations[this.props.locale][this.props.value];
            }
        }

        if(this.props.locale in translations) {
            if(this.props.value in translations[this.props.locale]) {
                return translations[this.props.locale][this.props.value];
            }
        }

        return this.props.value;
    }

    render() {
        return this.translate();
    }
}

Translate.propTypes = {
    locale: PropTypes.string,
    value: PropTypes.string,
};

Translate.defaultProps = {
    locale: 'en',
    value: ''
};

export default Translate;

export function plainTranslate(locale, value) {
   
    if(locale in customTranslations) {
        if(value in customTranslations[locale]) {
            return customTranslations[locale][value];
        }
    }

    if(locale in translations) {
        if(value in translations[locale]) {
            return translations[locale][value];
        }
    }

    return value;
}

export function setCustomTranslations(translations) {
    customTranslations = translations;
}
