import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';
import {onSelectLineInputChange, onAddSelectOption} from '../../../../actions/actions';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';
import {plainTranslate} from "../../../common/translate";
import { onSelect2InputChange } from '../../../../actions/actions';

class SelectLine extends React.Component {

    constructor(props) {
        super(props);
         this.state = {
             value: null,
             menuIsOpen: false,
             isExist: false,
             clicked: null
         };
        this.loadOptions = this.loadOptions.bind(this);
        this.onChange = this.onChange.bind(this);     
        this.handleBlur = this.handleBlur.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {       
        if(this.props.data){
             this.setState({
              value: this.props.data
            })
          
        }
       
        if ((!this.props.data && this.state.value !== 0 && this.state.value !== false)&& (!this.props.field.settings || !this.props.field.settings.emptyOption) && this.props.field.options.length > 0) {
            
            this.setState({
              value: this.props.field.options[0].value
            })
          
         this.props.handleChangeLine(this.props.field.id, this.props.field.options[0].value)
     }
    }
   
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data && nextProps.data !== prevState.value) {         
            return {value: nextProps.data}        
        }else{
            return null;
        }
    }
    
    handleBlur(event){  
        event.preventDefault()
        this.setState({
          clicked: null
       }); 
    }
    
     handleClick(event){ 
        this.setState({
          clicked: event.target.id
       }); 
    }
    
    onChange(newValue, actionMeta) {
        let enableChange = true;
        if(this.props.field.id === 'stage' && this.props.isEdit && this.props.field.hasOwnProperty('connectedField')){
            let ind = (this.props.name).indexOf('.'+this.props.field.id);
            let line = (this.props.name).substring(0, ind);
            let key = line.replace(/[^0-9]/g,'');

            if(this.props.formData.lines[key] && newValue.position < this.props.formData.lines[key].stagePosition){
                enableChange = false;

                showAToast(plainTranslate(this.props.settings.locale, 'Cannot change document Status backward.'), 'errSettings', 'error');
            }else{
                enableChange = true;
            } 
        }

        if (newValue && enableChange === true) { 
            
        this.props.handleChangeLine(this.props.field.id, newValue.value)     
      
           this.setState({
                value: newValue.value
            })
        
        if (this.props.field.hasOwnProperty('connectedField')) {
          
            client.post(this.props.field.connectedField.optionsUrl,{
                body: newValue.value
            })
                    .then(response => response.data)
                    .then(response => {
                        
                        if (this.props.field.connectedField.hasOwnProperty('dependency')) {
                         
                            let fieldVal = 0;
                            let line;
                            let key;
                            let depVal = 1;
                            if (this.props.field.connectedField.isLine) {
                                let ind = (this.props.name).indexOf('.'+this.props.field.id);
                                line = (this.props.name).substring(0, ind);
                                key = line.replace(/[^0-9]/g,'');
                                depVal = this.props.formData.lines[key][this.props.field.connectedField.dependency];
                            } else {
                                 depVal = this.props.formData[this.props.field.connectedField.dependency];
                            }
                            if (this.props.field.connectedField.calculation == 'divide') {
                                fieldVal = depVal / response;
                            } else if (this.props.field.connectedField.calculation == 'multiply') {
                                fieldVal = depVal * response;
                            } else if (this.props.field.connectedField.calculation == 'add') {
                                fieldVal = depVal + response;
                            } else if (this.props.field.connectedField.calculation == 'subtract') {
                                fieldVal = depVal - response;
                            }
                            if (line) {
                                this.props.handleChange(this.props.formData['lines'][key][this.props.field.connectedField.field], fieldVal);
                            } else {
                                this.props.handleChange(this.props.field.connectedField.field, fieldVal);
                            }

                      } else {
                         
                            if (response.id) {
                                this.props.handleChange(this.props.field.connectedField.field, response.id ? response.id : null);
                                this.props.onChangeMainOptions(this.props.field.connectedField.field, {'value' : response.id, 'label' : response.label}, 'billingData');
                            } else {
                                if (this.props.field.connectedField.isLine) {
                                    let ind = (this.props.name).indexOf('.'+this.props.field.id);
                                    let line = (this.props.name).substring(0, ind);
                                    let key = line.replace(/[^0-9]/g,'');
                                    this.props.handleChange(this.props.formData['lines'][key][this.props.field.connectedField.field], response);
                                } 
                            }
                            if (this.props.field.connectedField.additionalValue) {
                                this.props.handleChange(this.props.field.connectedField.field + 'AdditionalValue', response);
                                this.props.onChangeMainOptions(this.props.field.connectedField.field, {'value' : response.id, 'label' : response.label}, 'billingData');
                            }
                        }
                    })
        }
        
        if (this.props.field.hasOwnProperty('connectedFields')) {
          
            let addVal = {};
            let jsonData = [];
            if (this.props.field.connectedFields.hasOwnProperty('dependency')) {
                this.props.field.connectedFields.dependency.map(field => {
                    addVal[field] = this.props.formData[field];
                })
            }
            
            if (this.props.field.connectedFields.hasOwnProperty('lineDependency')) {
                this.props.field.connectedFields.lineDependency.map(field => {
                    let numb = (this.props.name).match(/^(\D*)(\d+)/)[2];
                    addVal[field] = this.props.formData.lines[numb][field];
                })
            }           
                addVal['body'] = newValue.value;
                jsonData.push(JSON.stringify(addVal));

            let line='';
            if (this.props.field.connectedFields.isLine) {
                   let ind = (this.props.name).indexOf('.'+this.props.field.id);
                   line = (this.props.name).substring(0, ind);
                }

            let fields = this.props.field.connectedFields.fields;
            client.post(this.props.field.connectedFields.optionsUrl, addVal)
            .then(response => response.data)
            .then(response => {
                fields.map(field => {
                    if (typeof(response[field])== 'string') { 
                        this.props.handleChange(line+field, response[field] ? response[field] : null)
                    } else {    
                        if (this.props.field.connectedFields.isLine) {
                            this.props.onChangeOptions(field, response[field], this.props.field.connectedFields.isLine); 
                        } else {
                            this.props.handleChange(field, response[field] ? response[field] : null) ;
                        }
                        if (typeof response[field] !== 'undefined' && response[field] && response[field].length > 0) {
                            let i;
                            for (i = 0; i < response[field].length; i++) {
                              if (response[field][i].selected) {
                                  this.props.handleChange(line+field, response[field][i].value ? response[field][i].value : null);
                                  break;
                              }
                            }
                        }
                       
                    }  
                })
            })
        } 
        if (this.props.field.hasOwnProperty('changeOptionField') && this.props.field.changeOptionField !== null) {
          
            let addVal = {};
            let jsonData = [];
            if (this.props.field.changeOptionField.hasOwnProperty('dependency')) {
                this.props.field.changeOptionField.dependency.map(field => {
                    addVal[field] = this.props.formData[field];
                        })
            }
                addVal['body'] = newValue.value;
                jsonData.push(JSON.stringify(addVal));
            client.post(this.props.field.changeOptionField.optionsUrl, addVal)
                    .then(response => response.data)
                    .then(response => {
                        if (this.props.field.changeOptionField.group == 'lines') {
                            this.props.onChangeOptions(this.props.field.changeOptionField.field, response);
                        } else {
                            this.props.onChangeMainOptions(this.props.field.changeOptionField.field, response, this.props.field.changeOptionField.group);
                        }
                    })
        } 
        if (this.props.field.hasOwnProperty('changeOptionFields')) {
           
            this.props.field.changeOptionFields.map(item => {
                if (item) {
            let addVal = {};
            let jsonData = [];
            if (item.hasOwnProperty('dependency')) {
                item.dependency.map(field => {
                    addVal[field] = this.props.formData[field];
                        })
            }
                addVal['body'] = newValue.value;
                jsonData.push(JSON.stringify(addVal));
            client.post(item.optionsUrl, addVal)
                    .then(response => response.data)
                    .then(response => {
                        if (item.group == 'lines') {
                            this.props.onChangeOptions(item.field, response);
                        } else {
                            this.props.onChangeMainOptions(item.field, response, item.group);
                            let selected = response.find(o => o.selected == true); 
                            if (selected) {
                                this.props.handleChange(item.field, selected.value ? selected.value : null);
                            } else {
                                this.props.handleChange(item.field, null);
                            }
                        }
                    })
                }
                })
        } 
        if (this.props.field.options.length > 0) {
        
                this.props.onNewOptions(this.props.field.options, newValue);
            }

        if (this.props.field.hasOwnProperty('changeLines')) {
            
            if(this.props.formData.form && this.props.formData.lines){
                this.props.formData.lines.map((line,key) => {
                    this.props.handleChange('lines['+key+']['+this.props.field.changeLines+']', newValue.value);
                })
            }
            if(this.props.field.hasOwnProperty('lockFields')){
               
                this.props.field.lockFields.map(item => {
                    this.props.fields.map((val, key) =>{
                         if (val.id == item) {
                             this.props.fields[key].isDisabled = true;
                         }
                    })   
                })  
            } 
        }
        

            if (this.props.field.hasOwnProperty('lockFields') && !(newValue instanceof Array)) {
               
                this.props.field.lockFields.map(item => {
                    this.props.fields.map((val, key) =>{
                        if (val.id == item) {
                            this.props.fields[key].isDisabled = true;
                        }
                    })   
                })   
            }
        } else {
            if(this.props.field.id !== 'stage' && this.state.value !== null){
                
            //this.onChange('');
           // this.props.handleChange(this.props.name, '')
        }
        }
        
        
    }

    loadOptions(inputValue, callback) {
        if (!inputValue) inputValue = -1;
        if(this.props.field.id === 'reversalId' && this.props.formData.accId !== undefined){
            inputValue = inputValue + "&accId="+this.props.formData.accId;
        }

        let hasSettings = this.props.field.settings && this.props.field.settings.optionsUrl;
        this.props.onInputChange('initializeFromState2', this.props.field.id, inputValue, this.props.field.settings.optionsUrl)
        .then(response => response.payload)
        .then(response => { 
                let options = [];
                console.log(response.options)   
                if (response.options) {
                    options = response.options.map(option => {
                        return {
                            ...option,
                            'value': option.value,
                            'label': option.label
                        };
                    });
                }
                this.options = options;
                if(options.length > 0){                   
                    this.props.onNewOptions(options);
                }else if(this.props.field.options.length > 0){
                    this.props.onNewOptions(this.props.field.options);
                }

                if(!hasSettings) {
                    let ops = this.props.field.options;
                    ops = ops.filter(item => item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);                   
                    callback(options);
                }else {                    
                    callback(options ? options : this.props.field.options);
                }
            });
    }
    
  

    render() {
        if(this.props.fields){
            if(this.props.field.id === 'grpId'){
                this.props.fields.map(field => {
                    if(field.id === 'accId' && this.state.value.length > 0){
                        field.isDisabled = true;
                    }else if(field.id === 'accId' && this.state.value.length === 0){
                        field.isDisabled = false;
                    }
                })
            }
            if(this.props.field.id === 'accId'){
                this.props.fields.map(field => {
                    if(field.id === 'grpId' && this.state.value.length > 0){
                        field.isDisabled = true;
                    }else if(field.id === 'grpId' && this.state.value.length === 0){
                        field.isDisabled = false;
                    }
                })
            }
            if(this.props.field.id === 'itmId'){
                this.props.fields.map(field => {
                    if(field.id === 'catId' && this.state.value.length > 0){
                        field.isDisabled = true;
                    }else if(field.id === 'catId' && this.state.value.length === 0){
                        field.isDisabled = false;
                    }
                })
            }
            if(this.props.field.id === 'catId'){
                this.props.fields.map(field => {
                    if(field.id === 'itmId' && this.state.value.length > 0){
                        field.isDisabled = true;
                    }else if(field.id === 'itmId' && this.state.value.length === 0){
                        field.isDisabled = false;
                    }
                })
            }
            if(this.props.field.id === 'locConsumption'){
                let indexes = null;
                let disableField = false;
                let val = null;

                if(this.props.formData.form && this.props.formData.lines){
                    this.props.formData.lines.map(line => {
                        indexes = this.props.formData.lines.map((o, i) => line['itId'] === undefined || line['locConsumption'] === undefined || o.locConsumption === line['locConsumption'] ? i : '').filter(String);
                        if(indexes.length !== this.props.formData.lines.length){
                            disableField = true;
                        }
                        else{
                            if(line.hasOwnProperty('locConsumption') && line['locConsumption'] !== null){    
                                val = line['locConsumption'];
                            }else{
                                val = false;
                            }
                        }
                    })

                    let stages = this.props.formData.lines.map((o, i) => (o.stageStatus != 4 && o.stageStatus != 6 && o.stageStatus != 8) ? i : '').filter(String);

                   if(stages.length !== this.props.formData.lines.length){
                        disableField = true;
                    }
                    if(val !== false){
                        this.props.formData.locConsumption = val;
                    }
                    this.props.field.isDisabled = disableField;
                }
            }
            if(this.props.field.id === 'locInProgress'){
                let indexes = null;
                let disableField = false;
                let val = null;

                if(this.props.formData.form && this.props.formData.lines){
                    this.props.formData.lines.map(line => {
                        indexes = this.props.formData.lines.map((o, i) => line['itId'] === undefined || line['locInProgress'] === undefined || o.locInProgress === line['locInProgress'] ? i : '').filter(String);
                        if(indexes.length !== this.props.formData.lines.length){
                            disableField = true;
                        }else{
                            if(line.hasOwnProperty('locInProgress') && line['locInProgress'] !== null){    
                                val = line['locInProgress'];
                            }else{
                                val = false;
                            }
                        }
                    })

                    let stages = this.props.formData.lines.map((o, i) => (o.stageStatus != 6 && o.stageStatus != 8) ? i : '').filter(String);

                    if(stages.length !== this.props.formData.lines.length){
                        disableField = true;
                    }

                    if(val !== false){
                        this.props.formData.locInProgress = val;
                    }

                    this.props.field.isDisabled = disableField;
                }
            }
            if(this.props.field.id === 'locProduction'){
                let indexes = null;
                let disableField = false;
                let val = null;

                if(this.props.formData.form && this.props.formData.lines){
                    this.props.formData.lines.map(line => {
                        indexes = this.props.formData.lines.map((o, i) => line['itId'] === undefined || line['locProduction'] === undefined || o.locProduction === line['locProduction'] ? i : '').filter(String);
                        if(indexes.length !== this.props.formData.lines.length){
                            disableField = true;
                        }else{
                            if(line.hasOwnProperty('locProduction') && line['locProduction'] !== null){    
                                val = line['locProduction'];
                            }else{
                                val = false;
                            }
                        }
                    })

                    let stages = this.props.formData.lines.map((o, i) => (o.stageStatus != 6 && o.stageStatus != 8) ? i : '').filter(String);

                    if(stages.length !== this.props.formData.lines.length){
                        disableField = true;
                    }

                    if(val !== false){
                        this.props.formData.locProduction = val;
                    }

                    this.props.field.isDisabled = disableField;
                }
            }
        }
        if(this.props.field.id === 'stornoReason'){
            if(this.props.formData.form && this.props.formData.isFiscalDevice === true && this.props.formData.reversalId !== null){
                this.props.field.hide = false;
            }else{
                this.props.field.hide = true;
            }
        }

        if(this.props.field.id === 'batch' && this.props.field.hide === false){
            if(this.props.fields){
                this.props.fields.map(field => {
                    if(field.id === 'lines'){
                        field.fields.map(line =>{
                            if(line.id === 'itName' && line.hide === false){
                                this.props.field.isDisabled = true;
                            }
                        }) 
                    }
                });
            }
        }
        if(!this.props.fields && this.props.field.id === 'locConsumption'){
            let ind = (this.props.field.id).indexOf('.'+this.props.field.id);
            let line = (this.props.field.id).substring(0, ind);
            let key = line.replace(/[^0-9]/g,'');

            let disableField = true;
            if(this.props.formData.form && this.props.formData.lines){
                let line = this.props.formData.lines[key];
                if(line && line['stageStatus'] !== null){
                    if(line['stageStatus'] != 4 && line['stageStatus'] != 6 && line['stageStatus'] != 8){
                        disableField = false;
                    }
                }
                if(line && (!line.hasOwnProperty('locConsumption') || line['locConsumption'] === null || line['locConsumption'] === undefined)){
                    this.props.formData.lines[key].locConsumption = this.props.formData.locConsumption;
                }
                this.props.field.isDisabled = disableField;
            }
        }
        if(!this.props.fields && this.props.field.id === 'locInProgress'){

            let ind = (this.props.field.id).indexOf('.'+this.props.field.id);
            let line = (this.props.field.id).substring(0, ind);
            let key = line.replace(/[^0-9]/g,'');

            let disableField = true;
            if(this.props.formData.form && this.props.formData.lines){
                let line = this.props.formData.lines[key];
                if(line && line['stageStatus'] !== null){
                    if(line['stageStatus'] != 6 && line['stageStatus'] != 8){
                        disableField = false;
                    }
                }
                if(line && (!line.hasOwnProperty('locInProgress') || line['locInProgress'] === null || line['locInProgress'] === undefined)){
                    this.props.formData.lines[key].locInProgress = this.props.formData.locInProgress;
                }
                this.props.field.isDisabled = disableField;
            }
        }
        if(!this.props.fields && this.props.field.id === 'locProduction'){

            let ind = (this.props.field.id).indexOf('.'+this.props.field.id);
            let line = (this.props.field.id).substring(0, ind);
            let key = line.replace(/[^0-9]/g,'');

            let disableField = true;
            if(this.props.formData.form && this.props.formData.lines){
                let line = this.props.formData.lines[key];
                if(line && line['stageStatus'] !== null){
                    if(line['stageStatus'] != 6 && line['stageStatus'] != 8){
                        disableField = false;
                    }
                }
                if(line && (!line.hasOwnProperty('locProduction') || line['locProduction'] === null || line['locProduction'] === undefined)){
                    this.props.formData.lines[key].locProduction = this.props.formData.locProduction;
                }
                this.props.field.isDisabled = disableField;
            }
        }

        if(!this.props.fields && this.props.field.id === 'stage'){
            
            let disableField = true;
            let val = null;
            this.props.formData.lines.map(line => {
                this.props.field.options.map(option =>{
                    if(line['stagePosition'] && option.position < line['stagePosition']){
                        option.disabled = true;
                    }else{
                        option.disabled = false;
                    }
                });
                let indexes = this.props.formData.lines.map((o, i) => o.stage === line['stage'] ? i : '').filter(String);
                if(indexes.length !== this.props.formData.lines.length){
                    disableField = true;
                }else{
                    disableField = false;
                    if(line['stage'] === undefined){
                        val = this.props.formData.stageId;
                    }else{
                        val = line['stage'];
                    }
                }
            })
//TO DO
       //???     this.props.formData.formProperties.initializeFromState2.form.breadcrumb.disabled = disableField;
            this.props.formData.stageId = val;
        }

        if(this.props.field.id === 'accId'){
            if(this.props.fields){
                this.props.fields.map(field => {
                    if(field.id === 'metaData'){
                        field.fields.map(line =>{
                            if((line.id === 'addrId' && this.state.value.length > 0) || (line.id === 'saleId' && this.state.value.length > 0)){
                                line.isDisabled = false;

                            }else if((line.id === 'addrId' && this.state.value.length === 0) || (line.id === 'saleId' && this.state.value.length === 0)){
                                line.isDisabled = true;
                                this.props.formData.addrId=null;
                                this.props.formData.formData.initializeFromState2.addrId = null;
                                this.props.formData.saleId=null;
                            }
                        }) 
                    }
                });
            }
        }
        if(this.props.field.id === 'countStock'){
            let currentValueField = this.props.fields.find(o => o.id === 'currentValue');
            if(this.state.value !== 8 && this.state.value !== 9){
                currentValueField.disabled = true;
            }else{
                currentValueField.disabled = false;
            }
        }

    let valueLabel = this.props.field.options.find(o => o.value === this.state.value);   

    if(this.state.clicked !== this.props.id){
      return <div 
      className={this.props.className + ' div-text-center'} 
      style={this.props.style} 
      onClick={this.handleClick}  
      id={this.props.id}>{valueLabel ? valueLabel.label : ''}</div>
    }else{
        if(this.props.field.settings && this.props.field.settings.optionsUrl) {          
         return  <div className={this.props.className+' '} style={this.props.style}>
               <AsyncSelect  
                     id={this.props.id}
                     menuPosition={'absolute'}
                     menuPlacement={"bottom"}                     
                     isClearable
                     cacheOptions
                     isSearchable
                     onChange={(value) => this.onChange(value)}
                     onBlurResetsInput={false}
                     onBlur={this.handleBlur}
                     onCloseResetsInput={false}
                     isDisabled={this.props.field.isDisabled && typeof this.props.field.isDisabled !== 'undefined' ? this.props.field.isDisabled : false}                
                     isOptionDisabled={(option) => option.disabled === true}
                     loadOptions={this.loadOptions}
                     defaultOptions={this.props.field.options}
                     value={this.state.value !== null ? (this.props.field.options.find(o => o.value === this.state.value)) : (this.props.field.options.find(o => o.selected === true) ? this.props.field.options.find(o => o.selected === true) : null)}
                     closeMenuOnScroll={false}
                     name={this.props.name}
                     className='border-green'
                     style={{height: 40}}
                /></div>;
             } else {                
                 return <div className={this.props.className+' '} style={this.props.style}>
                 <Select   
                     id={this.props.id}
                     menuPosition={'absolute'}
                     menuPlacement={"bottom"}                      
                     isClearable={this.props.field.hasOwnProperty('isClearable') ? this.props.isClearable : true}
                     onBlur={this.handleBlur}
                     value={this.state.value !== null ? this.props.field.options.find(o => o.value === this.state.value) : null}
                     onChange={(value) => this.onChange(value)}
                     onBlurResetsInput={false}
                     onCloseResetsInput={false}
                     isOptionDisabled={(option) => option.disabled === true}
                     isDisabled={this.props.field.isDisabled && typeof this.props.field.isDisabled !== 'undefined' ? this.props.field.isDisabled : false}                                  
                     options={this.props.field.options}
                     closeMenuOnScroll={false}
                     name={this.props.name}
                     className='border-green'
                     style={{height: 40}}
                 /></div>;
             }
         }
    }
}

SelectLine.defaultProps = {
    onNewOptions: () => {},
};

const mapStateToProps = state => ({
  //  formData: state,
});

const mapDispatchToProps = dispatch => ({
    onInputChange: (formId, id, value, route) => dispatch(onSelect2InputChange(formId, id, value, route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectLine);
