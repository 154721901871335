import React, {Component} from 'react';
import Translate, {plainTranslate} from '../common/translate';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import FormFields from './formFields.jsx';
import ModulesFields from './modulesFields.jsx';
import ConfigurationFields from './configurationFields.jsx';

class ListCheckboxes extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    render() {
        return (
            <React.Fragment>
                {this.props.id == "modules" &&
                <ModulesFields id={this.props.id}
                            value={this.props.value}
                            label={this.props.label}
                            settings={this.props.settings}
                            fields={this.props.fields}
                            saveRoute={this.props.saveRoute}
                            setting={this.props.setting}
                            type={this.props.type}
                /> }
                {this.props.id == "configuration" &&
                <ConfigurationFields id={this.props.id}
                            value={this.props.value}
                            label={this.props.label}
                            settings={this.props.settings}
                            fields={this.props.fields}
                            setting={this.props.setting}
                            type={this.props.type}
                            subsetting={this.props.subsetting}
                            row={this.props.row}
                            stageId={this.props.stageId}
                            stageStatus={this.props.stageStatus}
                            disabled = {this.props.disabled}
                            message = {this.props.message}
                /> }
                {this.props.id == "settings" &&
               <FormFields id={this.props.id}
                            value={this.props.value}
                            label={this.props.label}
                            settings={this.props.settings}
                            fields={this.props.fields}
                            saveRoute={this.props.saveRoute}
                            setting={this.props.setting}
                            type={this.props.type}
                />
                            }
            </React.Fragment>
        )
            ;
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ListCheckboxes);

