import React from 'react';
import { connect } from 'react-redux';
import { plainTranslate } from "../../common/translate";
import { Card } from 'antd';
import 'antd/lib/card/style/index.css';
import { Breadcrumb } from 'antd';
import Truncate from 'react-truncate';
import SlideModal from '../../common/SlideModal';
import SearchItem from './searchItem';
import FormLoader from "../../forms/form-loader";
import BarcodeReader from 'react-barcode-reader';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';

class Collection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showLoader: true,
        };
        this.categoriesSearch = this.categoriesSearch.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.handleEnterClick = this.handleEnterClick.bind(this);
        this.addItemRows = this.addItemRows.bind(this);
        this.closeSearchPopup = this.closeSearchPopup.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleScan = this.handleScan.bind(this);
        this.searchAddItem = this.searchAddItem.bind(this);
    }

    componentDidMount() {
        this.setState({ showLoader: true });
        client
            .post('/' + this.props.match.params.db + '/api/item-categories/search/categories', {
                parent: null,
                locId: this.props.formData.locIdFrom ? this.props.formData.locIdFrom : this.props.formData.locId
            })
            .then(response => response.data)
            .then(response => {
                let breadcrumbs = [];
                breadcrumbs.push({
                    label: 'Categories',
                    value: null
                });
                this.setState({ categories: response.data, isItems: response.isItems ? true : false, breadcrumbs: breadcrumbs, showLoader: false })
            })
    }

    categoriesSearch(id, label) {
        this.setState({ showLoader: true });
        client.post('/' + this.props.match.params.db + '/api/item-categories/search/categories', {
            parent: id,
            locId: this.props.formData.locIdFrom ? this.props.formData.locIdFrom : this.props.formData.locId
        })
            .then(response => response.data)
            .then(response => {
                let breadcrumbs = this.state.breadcrumbs;
                let index = breadcrumbs.findIndex(x => x.value === id);

                if (index > -1) {
                    breadcrumbs.splice(index + 1, breadcrumbs.length);
                    //breadcrumbs.length(index);
                } else {
                    breadcrumbs.push({
                        label: label,
                        value: id
                    })
                }
                this.setState({ categories: response.data, isItems: response.isItems ? true : false, breadcrumbs: breadcrumbs, showLoader: false })
            })
    }

    handleEnterClick(e) {
        if (e.key === 'Enter') {
            this.setState({ searchItem: true });
        }
    }

    handleScan(data, url) {
        let lines = this.props.lines ? this.props.lines : [];
        client.post(url, {
            'barcode': data,
            'documentDate': this.props.formData.documentDate,
            'locId': this.props.formData.locId,
            'documentDate': this.props.formData.documentDate ? this.props.formData.documentDate : null,
            'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
            'accId': this.props.formData.accId ? this.props.formData.accId : null,
            'channel': this.props.formData.channel ? this.props.formData.channel : null
        })
            .then(response => response.data)
            .then(response => {
                let line = response;
                let lines = this.props.formData.lines ? this.props.formData.lines : [];
                if (this.props.formData.custIdAdditionalValue) {
                    client.post('/' + this.props.match.params.db + '/api/items/item/item-search-price-by-quantity', {
                        'isPos': true,
                        'isBarcodeScanner': true,
                        'qty': line.quantity,
                        'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
                        'accId': this.props.formData.accId ? this.props.formData.accId : null,
                        'documentDate': this.props.formData.documentDate,
                        'itId': line.itId,
                        'lines': lines,
                        'line': line,
                        'locId': this.props.formData.locIdFrom ? this.props.formData.locIdFrom : this.props.formData.locId,
                        'channel': this.props.formData.channel ? this.props.formData.channel : null

                    })
                        .then(response => response.data)
                        .then(response => {
                            line['unitPriceOriginal'] = response['unitPriceOriginal'];
                            line['currId'] = response['currId'];
                            //line['discountRate'] = response['discountRate'];
                            line['totalDiscount'] = response['totalDiscount'];
                            line['batch'] = response['batch'];
                            line['batchName'] = response['batchName'];
                            line['availableQuantity'] = response['availableQuantity'];
                            line['discountRate'] = response['discountRate'];

                            this.props.change('totalDiscountRate', response['totalDiscount']);
                            this.addItemRows(line);
                        })
                } else {
                    this.addItemRows(line);
                }
            })
    }

    searchAddItem(line) {
        let lines = this.props.formData.lines ? this.props.formData.lines : [];

        if (this.props.formData.custIdAdditionalValue) {
            client.post('/' + this.props.match.params.db + '/api/items/item/item-search-price-by-quantity', {
                'isPos': true,
                'qty': line.quantity,
                'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
                'accId': this.props.formData.accId ? this.props.formData.accId : null,
                'documentDate': this.props.formData.documentDate,
                'itId': line.itId,
                'lines': lines,
                'line': line,
                'locId': this.props.formData.locIdFrom ? this.props.formData.locIdFrom : this.props.formData.locId,
                'channel': this.props.formData.channel ? this.props.formData.channel : null
            })
                .then(response => response.data)
                .then(response => {
                    line['unitPriceOriginal'] = response['unitPriceOriginal'];
                    line['currId'] = response['currId'];
                    //line['discountRate'] = response['discountRate'];
                    line['totalDiscount'] = response['totalDiscount'];
                    //line['batch'] = response['batch'];
                    //line['batchName'] = response['batchName'];
                    line['availableQuantity'] = response['availableQuantity'];
                    line['discountRate'] = response['discountRate'];

                    this.props.change('totalDiscountRate', response['totalDiscount']);
                    this.addItemRows(line);
                })
        } else {
            this.addItemRows(line);
        }
    }
    addItemRows(line) {
        let lines = this.props.formData.lines ? this.props.formData.lines : [];

        if (line.checkQuantity && Number(line.quantity) > Number(line.availableQuantity === null ? line.stockAvailability : line.availableQuantity) && line.negativeQty == 0) {
            showAToast(plainTranslate(this.props.settings.locale, 'Insufficient quantity'), 'errSettings', 'error');
        } else {
            if (lines.length > 0 && lines[0].hasOwnProperty('itId')) {
             
                if (lines[lines.length - 1].hasOwnProperty('itId')) {
                  
                    let index = lines.findIndex(o => o.itId === line['itId'] && o.batch === line['batch']);
                    var newItem = JSON.parse(JSON.stringify(line));
                    let indexes = lines.map((elm, idx) => elm.itId === line['itId'] ? idx : '').filter(String);

                    indexes.forEach(element => lines[element]['discountRate'] = line['discountRate']);
                    indexes.forEach(element => lines[element]['unitPriceOriginal'] = line['unitPriceOriginal']);

                    if (index >= 0) {
                        let qty = 0;
                        //if(line.hasOwnProperty('batch') && (line.batch !== null && line.batch !== '')){
                        qty = Number(lines[index]['availableQuantity'] === null ? lines[index]['stockAvailability'] : lines[index]['availableQuantity']);
                        //}
                        lines[index]['discountRate'] = line['discountRate'];
                        lines[index]['unitPriceOriginal'] = line['unitPriceOriginal'];
                        if (line.negativeQty == 1 || (Number(lines[index]['quantity']) + Number(line['quantity']) <= qty)) {
                            lines[index]['quantity'] = Number(lines[index]['quantity']) + Number(line['quantity']);
                        } else {
                            showAToast(plainTranslate(this.props.settings.locale, 'Insufficient quantity'), 'errSettings', 'error');
                        }
                    } else {
                        lines.push(line);
                    }

                } else {
                    lines.splice(lines.length - 1, 0, line);
                    if (!lines[lines.length - 1].hasOwnProperty['value']) {
                        lines.splice(lines.length - 1, 1);
                    }
                }
            } else {
                this.props.change('lines', []);
                lines.shift();
                lines.push(line);
            }
           
            this.setState({
                lines: lines
            }, function () {
                this.props.change('lines', this.state.lines);
            });
        }
    }

    selectItem(value) {
        
        client.post(this.props.searchUrl, {
            value: value,
            locId: this.props.formData.locIdFrom ? this.props.formData.locIdFrom : this.props.formData.locId,
            custId: this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
            accId: this.props.formData.accId ? this.props.formData.accId : null,
            documentDate: this.props.formData.documentDate ? this.props.formData.documentDate : null,
            isPos: true,
            //accId: this.props.formData.accIdAdditionalValue ? this.props.formData.accIdAdditionalValue.value : null,
            stockAvailability: false,
            channel: this.props.formData.channel ? this.props.formData.channel : null
        })
            .then(response => response.data)
            .then(response => {
                if (response[0].batch && response[0].batch.length > 0) {
                    this.setState({ searchItem: true, searchValue: response[0].code, item: response[0] });
                } else {
                    response[0]['quantity'] = 1;
                    response[0]['batch'] = null;
                    let line = response[0];
                    let lines = this.props.formData.lines ? this.props.formData.lines : [];
                    if (this.props.formData.custIdAdditionalValue) {
                        client.post('/' + this.props.match.params.db + '/api/items/item/item-search-price-by-quantity', {
                            'isPos': true,
                            'qty': line.quantity,
                            'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
                            'accId': this.props.formData.accId ? this.props.formData.accId : null,
                            'documentDate': this.props.formData.documentDate,
                            'itId': line.itId,
                            'lines': lines,
                            'line': line,
                            'locId': this.props.formData.locIdFrom ? this.props.formData.locIdFrom : this.props.formData.locId,
                            'channel': this.props.formData.channel ? this.props.formData.channel : null
                        })
                            .then(response => response.data)
                            .then(response => {
                                line['unitPriceOriginal'] = response['unitPriceOriginal'];
                                line['currId'] = response['currId'];
                                //line['discountRate'] = response['discountRate'];
                                line['totalDiscount'] = response['totalDiscount'];
                                //line['batch'] = response['batch'];
                                //line['batchName'] = response['batchName'];
                                line['availableQuantity'] = response['availableQuantity'];
                                line['discountRate'] = response['discountRate'];

                                this.props.change('totalDiscountRate', response['totalDiscount']);
                                this.addItemRows(line);
                            })
                    } else {
                        response[0]['quantity'] = 1;
                        response[0]['batch'] = null;
                       
                        this.addItemRows(response[0]);
                    }
                }
            })

    }

    onChange(e) {
        this.setState({ searchValue: e.target.value });
    }

    closeSearchPopup() {
        let value = document.getElementById('search').value;
        this.setState({ searchItem: false, searchValue: value, item: null });
    }
    render() {
        let lineFields = this.props.fields.find(o => o.id === 'lines');
        return <div>
            <div className="pos breadcrumbs">
                <Breadcrumb separator=">">
                    {this.state.breadcrumbs && this.state.breadcrumbs.map(item => {
                        return <Breadcrumb.Item onClick={() => this.categoriesSearch(item.value, item.label)}>{item.label}</Breadcrumb.Item>
                    })}
                </Breadcrumb>
            </div>
            <div className="search" style={{ width: '100%' }}>
                <i className="fa fa-search" style={{ position: 'relative', top: 29, left: 10 }}></i><input onChange={this.onChange} style={{ width: '100%' }} onKeyDown={this.handleEnterClick} type="text" id="search" placeholder={plainTranslate(this.props.settings.locale, 'Scan a barcode or start typing the item name or code')} autoComplete="off" style={{ borderRadius: 3, backgroundColor: 'white', marginBottom: 20 }}></input>
            </div>
            <div className="pos-categories">
                {!this.state.showLoader && this.state.categories && this.state.categories.map(category =>
                    <div 
                    className="col-lg-3 col-md-4 col-xs-6 col-sm-6 no-gutter posCategory" 
                    onClick={() => category.type === 'item' ? this.selectItem(category.value) : this.categoriesSearch(category.value, category.label)}>
                        <Card hoverable cover={(category.type === 'category' || (category.type === 'item' && category.image)) &&
                            <img alt={category.label} title={category.label} className="posCategory-img" src={category.image.url} />} className="posCategory-card">
                            <span className="posCategory-sku">{category.no}</span>
                            <p className="text-bold"><Truncate lines={2} ellipsis={(
                                <span>... </span>
                            )}>
                                {category.label}
                            </Truncate></p>
                            <p className="posCategory-price">{category.priceWithVat}</p>
                        </Card>
                    </div>
                )}
            </div>
            {
                this.state.searchItem &&
                <SlideModal onClose={this.closeSearchPopup} title={plainTranslate(this.props.settings.locale, 'Search item')}>
                    <section className="newPanel" ref={this.setWrapperRef}>
                        <div className="tableCollection panel-body">
                            <div className="row m-bot15">
                                <SearchItem
                                    onAddItemRow={this.searchAddItem}
                                    searchUrl={this.props.searchUrl}
                                    quantityUrl={this.props.quantityUrl}
                                    closeSearch={this.closeSearchPopup}
                                    searchValue={this.state.searchValue}
                                    change={this.props.change}
                                    formData={this.props.formData}
                                    showAvailableItems={true}
                                    checkAvailabilityForm={true}
                                    selectedItem={this.state.item}
                                    options={lineFields.fields}
                                />
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }
            <BarcodeReader
                onError={this.handleError}
                onScan={(data) => this.handleScan(data, this.props.barcodeUrl)}
            />

            {
                this.state.showLoader &&
                <FormLoader />
            }
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
