import React from 'react';
import {plainTranslate} from "../common/translate";

export default class ErrorMessage extends React.Component {
    constructor(props) {
        super(props);

        this.errorMessages = {
            'required': 'Field is required',
            'minLength': 'Min length error',
            'maxLength': 'Max length error',
            'sameAs': 'Passwords do not match',
            'dateRange': 'Date not in open dates range',
            'phone': 'Field must be valid phone.',
            'email': 'Field must be valid email.'
        };

        this.getError = this.getError.bind(this);
    }

    getError() {
        if(!(this.props.error in this.errorMessages) && this.props.message) return this.props.message;
        return this.props.error in this.errorMessages ? this.errorMessages[this.props.error] : 'Field is required.';
    }

    render() {
        return plainTranslate(this.props.locale, this.getError());
    }
}
