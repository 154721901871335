import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import SettingsMenu from '../../sidebar/settings-menu';
import { client} from "../../../actions/actions";
import OpenSection from './../openSection.jsx';
import Stages from './../stages.jsx';
import SingleCheckbox from './../singleCheckbox.jsx';
import { fetchSettings} from "../../../actions/actions";
import { Skeleton } from 'antd';

class PaymentSettingsPage extends Component {
 
    constructor(props) {
        super(props);
        this.state = {
            showMessage: false,
            ready: false
        };
        this.updateStages = this.updateStages.bind(this);
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/payments/settings/payments-settings', 'paymentSettings')
        .then(res => {            
            this.setState({ready: true})
        })
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }
    
    updateStages() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/payments/settings/payments-settings', 'paymentSettings')
        
    }

    render() {

        return (
                <div>
                    <SettingsMenu
                        location = {this.props.location.pathname} db = {this.props.match.params.db}/>
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                        <div className="row m-bot15">
                            <div className="col-lg-12">
                                <section className="panel">
                                    <div className="panel-body">
                                        <div className="settings">
                                            <OpenSection
                                                label={plainTranslate(this.props.settings.locale, 'Payment form type')}
                                                description={plainTranslate(this.props.settings.locale, "Specify different types of documents for payment")}
                                                link={"/" + this.props.match.params.db + "/form-types-v2"}
                                            />
                                            <hr/>
                                            <OpenSection
                                                label={plainTranslate(this.props.settings.locale, 'Payment Sequential No')}
                                                description={plainTranslate(this.props.settings.locale, "Set the format of the sequential number of payments")}
                                                component={'input'}
                                                type={'paymentSettings'}
                                                value ={this.props.settings.paymentSettings}
                                                saveRoute={'/' + this.props.match.params.db + '/api/payments/save/settings/individual'}
                                                setting={'seqNo'}
                                                />
                                            <hr/>
                                            <OpenSection
                                                label={plainTranslate(this.props.settings.locale, 'Transfer Payment Sequential No')}
                                                description={plainTranslate(this.props.settings.locale, "Set the format of the sequential number of transfer payments")}
                                                component={'input'}
                                                type={'paymentSettings'}
                                                value ={this.props.settings.paymentSettings}
                                                saveRoute={'/' + this.props.match.params.db + '/api/payments/save/settings/individual'}
                                                setting={'seqNoTransferIn'}
                                                />
                                            <hr/>
                                            <OpenSection
                                                label={plainTranslate(this.props.settings.locale, 'Payment Revision Sequential No')}
                                                description={plainTranslate(this.props.settings.locale, "Set the format of the sequential number of revision payments")}
                                                component={'input'}
                                                type={'paymentSettings'}
                                                value ={this.props.settings.paymentSettings}
                                                saveRoute={'/' + this.props.match.params.db + '/api/payments/save/settings/individual'}
                                                setting={'seqNoRevision'}
                                                />
                                            <hr/>
                                            <OpenSection
                                                label={plainTranslate(this.props.settings.locale, 'Payment Template')}
                                                description={plainTranslate(this.props.settings.locale, 'Set the default template for printing payment receipts')}
                                                component={'select'}
                                                value ={this.props.settings.paymentSettings}
                                                type={'paymentSettings'}
                                                saveRoute={'/' + this.props.match.params.db + '/api/payments/save/settings/individual'}
                                                setting={'template'}
                                                />
                                            <hr/>
                                            <SingleCheckbox
                                                    value={this.props.settings.paymentSettings}
                                                    label={plainTranslate(this.props.settings.locale, 'Edit control')}
                                                    description={plainTranslate(this.props.settings.locale, "Does not allow editing the total amount of paid documents.")}
                                                    settings={this.props.settings}
                                                    type={'paymentSettings'}
                                                    saveRoute={'/' + this.props.match.params.db + '/api/payments/save/settings/individual'}
                                                    setting = {'editControl'}
                                            />
                                            <div className='clearfix'></div>
                                            <hr/>
                                            <div className="col-sm-12 column-centered">
                                               {this.state.ready ? <Stages
                                                    saveRoute={'/' + this.props.match.params.db + '/api/stages/save/settings/data'}
                                                    type={'paymentSettings'}
                                                    roles={'roles'}
                                                    workflow={"payment"}
                                                    updateStages={this.updateStages}
                                                    setting = {'stages'}
                                                    />
                                                    :
                                                    <Skeleton active />}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </form>
                </div>
                );
    }
}

const mapStateToProps = state => ({
        settings: state.settings,
    });
const mapDispatchToProps = dispatch => ({
        getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
    });
export default connect(mapStateToProps, mapDispatchToProps)(PaymentSettingsPage);
