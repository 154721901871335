import React from 'react';
import { connect } from 'react-redux';
import client from '../../../actions/client';
import moment from 'moment';
import RteEditor from '../../common/rteEditor';

class Tinymce extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.input.value ?? '',
    }
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.input.value !== prevProps.input.value) {
      this.setState({ value: this.props.input.value ?? '' })
    }
  }

  componentDidMount() {
    if (this.props.field.evaluateToField) {
      client
        .get(this.props.input.value)
        .then((resp) => {
          this.setState({ value: resp.data })
        })
    }
  }

  onChange(value, editor) {
    this.setState({ value });
    this.props.input.onChange(value);
  };

  render() {
    return <RteEditor 
      value={this.state.value} 
      onChange={this.onChange}
    />
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Tinymce);
