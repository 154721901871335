import {
    FETCH_LINKS_BEGIN, 
    FETCH_LINKS_SUCCESS, 
    FETCH_LINKS_FAILURE,
    UPDATE_LINK_FIELD,
    FETCH_CATEGORIES_BEGIN, 
    FETCH_CATEGORIES_SUCCESS, 
    FETCH_CATEGORIES_FAILURE,
} from "../constants/actionTypes";


export default (state = [], action) => {
    let links = null;
    let categories = null;
    switch (action.type) {
                    
        case FETCH_LINKS_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_LINKS_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        links: action.payload.links
      };
      
      case FETCH_CATEGORIES_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };
    
    case FETCH_CATEGORIES_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        categories: action.payload.categories
      };

    case FETCH_LINKS_FAILURE:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      // This is up to you and your app though: maybe you want to keep the items
      // around! Do whatever seems right.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        links: []
      };
      
      case FETCH_CATEGORIES_FAILURE:
      
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        categories: []
      };
      
      case UPDATE_LINK_FIELD:
            categories = state.categories;
            links = state.links;
            let remove = action.payload.remove;
            let ind = null;
            let catInd = null;
            let foundCategory = null;
            let id = action.payload.link ? action.payload.link.id : null;
            let category = action.payload.link ? action.payload.link.category : null;
            let link = action.payload.link;
            link.linkImage = action.payload.link.linkImage ? action.payload.link.linkImage.full : '';
            link.favicon = 'http://www.google.com/s2/favicons?domain=' + action.payload.link.url;
            
            if (id) {
                links.forEach(function (item, key) {
                    if (item.id === id){
                        ind = key;
                    }
                    if (item.category === category) {
                        foundCategory = key;
                    }
                });
                categories.forEach(function (cat, i) {
                    if (cat === category)
                        catInd = i;
                });
                if (catInd==null && remove==false) {
                    categories.push(action.payload.link.category);
                }
                if (ind != null) {
                    if (remove) {
                        links.splice(ind, 1);
                        links.forEach(function (item, key) {
                            if (item.category === category) {
                                foundCategory = key;
                            }
                        });
                        if (!foundCategory) {
                            categories.splice(categories.indexOf(category), 1);
                        }
                    } else {
                        links[ind] = link;
                    }
                } else {
                    links.push(link);
                }
            } 
                
            return {
                ...state,
                links
            };

      
        default:
            return state;
    }
};
