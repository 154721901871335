import React, { Component } from 'react';
import Translate, { plainTranslate } from '../common/translate';
import { connect } from 'react-redux';
import './invoiceProfile.css';
import client from '../../actions/client';
import showAToast from '../common/showAToast';

class DeviceProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.printZReport = this.printZReport.bind(this);
        this.printXReport = this.printXReport.bind(this);
        this.syncTime = this.syncTime.bind(this);
    }

    componentDidMount() {
        client.get('/' + this.props.match.params.db + '/api/devices/' + this.props.match.params.id)
            .then(response => response.data)
            .then(response => {
                this.setState({ deviceUrl: response.url, deviceName: response.name });
                client.get(response.url)
                    .then(response => response.data)
                    .then(response => {
                        this.setState({ deviceInfo: response });
                    });
            })
    }

    printZReport() {
        client.post(this.state.deviceUrl + '/zreport')
            .then(response => response.data)
            .then(response => {
                if (response.ok === true) {
                    showAToast(plainTranslate(this.props.settings.locale, 'Successful!'), 'succSettings', 'success');
                } else {
                    showAToast(plainTranslate(this.props.settings.locale, 'Printer is not connected'), 'errSettings', 'error');
                }
            });
    }

    printXReport() {
        client.post(this.state.deviceUrl + '/xreport')
            .then(response => response.data)
            .then(response => {
                if (response.ok === true) {
                    showAToast(plainTranslate(this.props.settings.locale, 'Successful!'), 'succSettings', 'success');
                } else {
                    showAToast(plainTranslate(this.props.settings.locale, 'Printer is not connected'), 'errSettings', 'error');
                }
            });
    }

    syncTime() {
        let date = new Date().toJSON();

        let body = '{"datetime":"' + date + '"}';

        client
            .post(this.state.deviceUrl + '/datetime', { datetime: date })
            .then(response => response.data)
            .then(response => {
                if (response.ok === true) {
                    this.props.showMessage("Sync Successful", false);
                    setTimeout(() => this.props.hideMessage(), 5000);
                } else {
                    this.props.showMessage('Sync failed', true);
                    setTimeout(() => this.props.hideMessage(), 5000);
                }
            });
    }

    render() {
        let deviceInfo = this.state.deviceInfo ? this.state.deviceInfo : {};
        return (
            <div className="col-lg-6 col-md-6 col-xs-12 information">
                <h2>{this.state.deviceName}</h2>
                <section className="panel" style={{ margin: "15px 0" }}>
                    <div className="panel-body">
                        <div>
                            <label>{plainTranslate(this.props.settings.locale, 'Manufacturer')}:</label>
                            <span className="text-bold"> {deviceInfo.manufacturer}</span>
                        </div>
                        <div>
                            <label>{plainTranslate(this.props.settings.locale, 'Model')}:</label>
                            <span className="text-bold"> {deviceInfo.model}</span>
                        </div>
                        <div>
                            <label>{plainTranslate(this.props.settings.locale, 'Serial Number')}:</label>
                            <span className="text-bold"> {deviceInfo.serialNumber}</span>
                        </div>
                        <div>
                            <button className="button-primary text-center" style={{ marginLeft: 0 }} onClick={() => this.printZReport()}>
                                <Translate locale={this.props.settings.locale} value='Z-report' />
                            </button>
                            <button className="button-primary text-center" onClick={() => this.printXReport()}>
                                <Translate locale={this.props.settings.locale} value='X-report' />
                            </button>
                            <button className="button-primary text-center" onClick={() => this.syncTime()}>
                                <Translate locale={this.props.settings.locale} value='Sync Time' />
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceProfile);
