import React, { Component } from 'react';
import {connect} from 'react-redux';
import ImagePicker from 'react-image-picker'
import 'react-image-picker/dist/index.css';
import { Button, Modal, Tabs, Skeleton, Card, Tooltip, Col, Row, Image, Breadcrumb, Upload, Input } from 'antd';
import Translate, { plainTranslate } from '../common/translate';
import client from '../../actions/client';
import { withRouter } from 'react-router-dom';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import showAToast from '../../components/common/showAToast';
 
//import images from local
const { Meta } = Card;
 
class MediaLibrary extends Component {
    constructor(props) {
        super(props)
        this.state = {
          fileList: [],
          currentDir: "all",
          loading: false,
          pickedFiles: []
        }
        this.onPick = this.onPick.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.openModal = this.openModal.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.chooseImage = this.chooseImage.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidMount(){
        this.setState({'fileList': this.props.fileList});
    }
 
    onPick(image,selectedImage, key) {
        if(image.length === 0){
            let filteredArray = this.state.pickedFiles.filter(item => item.value !== key);
            //let fileList = filteredArray.map(v => ({...v, url: this.state.documents.files[v.value].url}))
            this.setState({pickedFiles: filteredArray});
           
            //this.setState({'pickedFiles': [...image]})
        }else{
            selectedImage.value = key;
            this.setState({ pickedFiles: [...this.state.pickedFiles, selectedImage]});
        }
       // this.setState({'pickedFiles': fileList})
    }

    openModal(){
        this.setState({showModal: true});
        if(!this.props.hideMediaLibrary){
            this.getFiles();
        }
    }

    handleOk() {
        let fileList = this.state.fileList;

        if(this.state.pickedFiles){
            fileList = this.state.fileList.concat(this.state.pickedFiles);
        }
        this.setState({showModal: false, fileList: fileList, pickedFiles: [], searchValue: null});
    };

    handleCancel(){
        this.setState({showModal: false, fileList: this.state.fileList, pickedFiles: [], searchValue: null});
    };

    getFiles(path=null, searchValue=null){
        if(path && path.substring(0, 1) === "/"){
            path = path.substring(1);
        }
        this.setState({loading: true});
        client
        .post('/'+this.props.match.params.db+'/api/get/files/list', {'location': path ? path : this.state.currentDir, searchValue: searchValue})
        .then(response => {
          if(response.data && Object.keys(response.data).length > 0){
            this.setState({
              documents: response.data,
              loading: false
            })
          }
         })
        .catch(error => {

        })
    }

    chooseImage() {
        document.getElementById('imageUpload').click();
    }

    uploadFile(e) {
        const file = e.target.files[0];

        this.setState({loading: true});

        var body = new FormData();
        body.append('files[]', file);

        let name = this.props.data && this.props.data.name ? this.props.data.name : null;

        let type = this.props.type ? this.props.type +"s" : null;

        body.append('name', name);
        body.append('type', type);
        body.append('isPublicFile', this.props.isPublicFile);

        client
        .post('/'+this.props.match.params.db+(this.props.isPublic ? "/web/public/settings/images/upload" : "/api/settings/images/upload"), body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.data)
        .then(response => {
             showAToast('Upload success!', 'successUpload', 'success');
             let fileList = this.state.fileList;
             fileList.push(response);
             this.setState({fileList: fileList, loading: false, showModal: false});
        })
        .catch(err => {
            showAToast("Error!", 'baseError', 'error')
        });
    }

    onRemove(file){
        let filteredArray = this.state.fileList.filter(item => item.url !== file.url);
        this.setState({fileList: filteredArray});
    }

    handleUpload(){
        if(this.state.fileList){

            this.setState({uploadLoading: true});

            client
            .post(this.props.uploadUrl, { 'data': this.state.fileList})
            .then(res => {
                 showAToast('Upload success!', 'successUpload', 'success');

                this.setState({fileList: [], uploadModal: false, uploadLoading: false});
                this.props.onClose();
            })
            .catch(err => {
                showAToast("Error!", 'baseError', 'error')
            });
        }
     }

 
    render() {
        let files = this.state.documents && this.state.documents.files ? this.state.documents.files : [];

        if(files){
             files = files.filter(item => (item.url.split('.').pop() === 'png' || item.url.split('.').pop() === 'jpg' || item.url.split('.').pop() === 'jpeg' || item.url.split('.').pop() === 'svg' || item.url.split('.').pop() === 'pdf'));
        }
        return (
        <div>
            <div style={{marginBottom: 5}}>
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={this.state.fileList}
                    multiple={true}
                    style={{ display: "flex" }}
                    onRemove={this.onRemove}
                    onPreview={this.props.onPreview}
                    accept="application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/plain, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text,  image/png, image/jpeg, image/svg+xml, image/gif, image/vnd.microsoft.icon, application/json, text/html, text/css"
                  >
                  </Upload>
                    <Button className="button-outline grey pull-left" onClick={this.openModal}>{plainTranslate(this.props.settings.locale, 'Browse')}</Button>
                    <Button
                        type="button"
                        className="button-outline grey pull-left"
                        onClick={this.handleUpload}
                        loading={this.state.uploadLoading}
                        disabled={this.state.fileList.length === 0}
                        style = {{marginLeft: '0'}}
                    >
                        {this.state.uploadLoading ? <Translate locale={this.props.settings.locale} value="Uploading" /> : <Translate locale={this.props.settings.locale} value="Save" />}
                    </Button>
            </div>
            
            <Modal okButtonProps={{loading: this.state.loading}} width={1000} title={plainTranslate(this.props.settings.locale, 'Upload Images')} visible={this.state.showModal} onOk={this.handleOk} onCancel={this.handleCancel}>
                <Tabs defaultActiveKey="2">
                    <Tabs.TabPane tab={plainTranslate(this.props.settings.locale, 'Upload')} key="1">
                        <div className="row m-bot15">
                            <div className="text-center">
                                {this.state.loading && 'Loading...'}
                                {!this.state.loading && 'Drop your image here'}
                            </div>
                            <button 
                            type="button" 
                            className={'upload-btn btn btn' + ' upload-button'}
                            onClick={this.chooseImage}>
                                <i className="fa fa-upload"></i>
                                Choose
                            </button>
                            <input id={'imageUpload'} type="file" style={{visibility: 'hidden'}}
                                   onChange={this.uploadFile}
                                   />
                        </div>
                    </Tabs.TabPane>
                    {!this.props.hideMediaLibrary &&
                        <Tabs.TabPane tab={plainTranslate(this.props.settings.locale, 'Library')} key="2">
                            <Skeleton loading={this.state.loading} />
                            {!this.state.loading && this.state.documents &&
                                <div>
                                    <Row>
                                        <Col span={16}>
                                        {this.state.documents.path &&
                                            <Breadcrumb>
                                                <Breadcrumb.Item style={{cursor: 'pointer'}} onClick={() => this.getFiles()}>Home</Breadcrumb.Item>
                                                {this.state.documents.path.map(path => {
                                                    if(path.name !== 'all'){
                                                        return <Breadcrumb.Item style={{cursor: 'pointer'}} onClick={() => this.getFiles(path.path)}>{path.name}</Breadcrumb.Item>
                                                    }
                                                })}
                                            </Breadcrumb>
                                        }
                                        </Col>
                                        <Col span={8}>
                                            <Input.Search type="search" value={this.state.searchValue} allowClear placeholder={plainTranslate(this.props.settings.locale, "Enter file name")} onChange={(e) => this.setState({searchValue: e.target.value})} onSearch={(value) => this.getFiles(null, value)}/>
                                        </Col>
                                    </Row>
                                    <Row type="flex" justify="start" className="dir_row">
                                        {this.state.documents.files && this.state.documents.folders.map((folder, key) => {
                                            return <Col xs={24} sm={12} md={8} lg={6} xl={6} key={1} className="dir" style={{marginBottom: 60}}>
                                                <Card
                                                    hoverable
                                                    className="dir_folder"
                                                    onClick={() => this.getFiles(folder.path)}
                                                    cover={<i className="ion-ios-folder-outline" style={{fontSize: 38, textAlign: "center"}}></i>}>
                                                        <Meta className="dir_element text-center" title={<Tooltip title={folder.name}>
                                                        <h4 className="text-center">{folder.name.slice(0, 20)}{folder.name.length > 20 && <span>...</span>}</h4>
                                                        </Tooltip>} style={{textAlign: 'center', height: 14}}/>
                                                </Card>
                                            </Col>
                                        })}
                                    </Row>
                                    <div style={{display: "flex", flexWrap: "wrap"}}>{files && files.map((image,i) =>{
                                        return <div style={{textAlign: "center"}}>
                                            <Tooltip placement="bottom" title={image.full.split(this.props.match.params.db+"/")[1]}><ImagePicker 
                                              images={[{src: image.full, value: i}]}
                                              onPick={(val) => this.onPick(val,image,i)}
                                              multiple
                                            />
                                            {image.name}</Tooltip></div>
                                        })}
                                    </div>
                                </div>
                            }
                        </Tabs.TabPane>
                    }
                </Tabs>
            </Modal>
          </div>
        )
    }
}
 
const mapStateToProps = (state, ownProps) => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MediaLibrary));