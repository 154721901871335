import React, {Component} from 'react';
import Translate, {plainTranslate} from '../../common/translate';
import { connect } from 'react-redux';
import TreeNode from './node';
import {Link} from 'react-router-dom';
import { fetchData } from "../../../actions/actions";
import { withRouter } from 'react-router-dom';

class RootNode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: true,
        };  
        this.toggleShow = this.toggleShow.bind(this);
    }
    
    toggleShow() {
        this.setState({
                show:!this.state.show
            });
    }

    render() {
        return (
                <li key={this.props.root.id} className="root">
                        <div className="node">
                            {this.props.frontend ? (
                                <h4>
                                <Link to={"/" + this.props.match.params.db + "/org_chart/" + this.props.root.secureId}>  
                                    {this.props.root.name}
                                </Link>
                                </h4>
                                        ) : (
                                <h4>
                                <Link to={"/" + this.props.match.params.db + "/hr/organizational/structure/" + this.props.root.secureId}>  
                                    {this.props.root.name}
                                </Link>
                                </h4>
                            )}
                                {this.props.root.manager.name &&
                                    <div className={"item-employee " + (this.props.showManagers ? '' : 'hidden')}> 
                                    <p><Translate locale={this.props.settings.locale} value="Manager:"/></p>
                                    <h5 className={this.props.showManagers ? '' : 'hidden'}>
                                        {this.props.frontend ? (
                                        <Link to={"/" + this.props.match.params.db + "/employee/" + this.props.root.manager.secureId + "/profile"}>  
                                            {this.props.root.manager.name}
                                        </Link>
                                        ) : (
                                        <Link to={"/" + this.props.match.params.db + "/hr/employee/" + this.props.root.manager.secureId + "/profile"}>  
                                            {this.props.root.manager.name}
                                        </Link>
                                        )}
                                    </h5>
                                    </div>
                                }
                                {this.props.root.employees && 
                                <div className={"item-employee " + (this.props.showEmployees ? '' : 'hidden')}> 
                                
                                {this.props.root.employees.map((employee, key) =>
                                <p  key={key} className={this.props.showEmployees ? '' : 'hidden'}>
                                    {this.props.frontend ? (
                                    <Link to={"/" + this.props.match.params.db + "/employee/" + employee.secureId + "/profile"}>  
                                        {employee.name}
                                    </Link>
                                    ) :(
                                    <Link to={"/" + this.props.match.params.db + "/hr/employee/" + employee.secureId + "/profile"}>  
                                        {employee.name}
                                    </Link>
                                    )}
                                </p> 
                                )}
                                </div>
                                }
                                {this.props.root.__children && this.props.root.__children.length > 0 &&
                                    <button className="show-hide-nodes" title="Show/hide"
                                        onClick = {this.toggleShow}>
                                        {this.state.show ? (<i className="fa fa-minus" title="Hide" onClick = {this.toggleShow}></i>) : (<i className="fa fa-plus" title="Show" onClick = {this.toggleShow}></i>)}
                                    </button>
                                }
                                
                        </div>
                            {this.props.root.__children && this.props.root.__children.length > 0 &&
                            <ul className={this.state.show ? 'ul-vertical ' : 'hidden-node'}>
                                {this.props.root.__children.map(node =>
                                        <TreeNode 
                                            db = {this.props.match.params.db}
                                            locale = {this.props.settings.locale}
                                            showEmployees ={this.props.showEmployees}
                                            showManagers ={this.props.showManagers}
                                            key = {node.id}
                                            node = {node}
                                            frontend = {this.props.frontend}
                                            paramsId = {this.props.paramsId}
                                            children={node.__children}/>
                                        )        
                                }
                            </ul>
                            }
                          </li>
                );
    }
}

const mapStateToProps = state => ({
        settings: state.settings,
        treeData: state.treeData
    });
const mapDispatchToProps = dispatch => ({
        loadData: (id, db) =>
            dispatch(fetchData(id, db)),
    });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RootNode));
