import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';

class LineButton extends React.Component {

    constructor(props) {
        super(props);
        this.clickButton = this.clickButton.bind(this);
    }
    
    clickButton() {
//        console.log(this.props.field.linkUrl);  линк към където трябва да отиде потребителя след действието
//        console.log(this.props.field.actionUrl); линк на действието
//        console.log(this.props.formData.ids); ид нужно за действията
        let addVal = {};
        let jsonData = [];
        if (this.props.field.hasOwnProperty('lineDependency')) {
                this.props.field.lineDependency.map(field => {
                    let numb = (this.props.input.name).match(/^(\D*)(\d+)/)[2];
                    addVal[field] = this.props.formData.form.initializeFromState2.values.lines[numb][field];
                })
            } 
        if (this.props.field.hasOwnProperty('dependency')) {
            this.props.field.dependency.map(field => {
              
                addVal[field] = this.props.formData.form.initializeFromState2.values[field];
            })
        } 

        jsonData.push(JSON.stringify(addVal));
        client.post(this.props.field.actionUrl, addVal)
                        .then(response => response.data)
                        .then(response => {
                           if (response['success']) {
                                showAToast(response.success, 'succSettings', 'success');
                                this.props.history.push(this.props.field.linkUrl);
                           } else {
                                showAToast(response.errors, 'errSettings', 'error');
                           }
                })
        
    }

    render() {
        return <React.Fragment>
                <button type="button" name={this.props.field.id} onClick={this.clickButton}>{plainTranslate(this.props.locale, this.props.field.buttonText)}</button>
             </React.Fragment>
    }
}
    
const mapStateToProps = state => ({
    formData: state,
});

const mapDispatchToProps = dispatch => ({

    });

export default connect(mapStateToProps, mapDispatchToProps)(LineButton);

