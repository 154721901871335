import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Drawer, Switch, Button, Input, Dropdown, Menu } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import {connect} from 'react-redux';
import Translate, { plainTranslate } from '../../common/translate';
import {Link} from 'react-router-dom';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';
import { withRouter } from 'react-router-dom';
import ModalConfirm from '../../common/ModalConfirm';

const SaveViewDrawer = (props, state) => {
  const [openSaveAs, setOpenSaveAs] = useState(false);
  const [viewName, setViewName] = useState(null);
  const [isPublic, setIsPublic] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  useEffect(() => {

  }, []);

  function saveView(name,isPublic){
    setIsLoading(true);
    client.post(props.gridSaveRoute, {
      name: name,
      isPublic: isPublic,
      columns: props.columns,
      params: props.params,
      isEdit: isEdit
    })
    .then(response => response.data)
    .then(response => {
      if ('errors' in response) {
        showAToast(plainTranslate(props.settings.locale, response.errors), 'errSettings', 'error');
      }
      else {
        showAToast(plainTranslate(props.settings.locale, response.success), 'succSettings', 'success');
        if(response.gridId){
          props.history.push(props.location.pathname+'?grid='+response.gridId);
        }
      }
      setIsLoading(false);
      closePopup();
    });
  }

  function changeFieldValue(id, value) {
    if(id === 'viewName'){
      setViewName(value);
    }
    if(id === 'isPublic'){
      setIsPublic(value);
    }
  }

  function onEditView(gridId){
    if(gridId){
      client.get('/'+ props.match.params.db + '/api/grid/' + gridId + '/get-view')
      .then(response => response.data)
      .then(response => {
        if ('error' in response) {
          showAToast(plainTranslate(props.settings.locale, response.error), 'errSettings', 'error');
        }else{
          setOpenSaveAs(true);
          if(response){
            setViewName(response.gridName);
            setIsPublic(response.isPublic);
            setIsEdit(true);
          }
        }
      });
    }
  }

  function deleteView(){
    client.get('/'+ props.match.params.db + '/api/grid/' + props.gridId + '/delete')
    .then(response => response.data)
    .then(response => {
      if ('error' in response) {
        showAToast(plainTranslate(props.settings.locale, response.error), 'errSettings', 'error');
      }else{
        setShowConfirmDialog(false);
        showAToast(plainTranslate(props.settings.locale, response.success), 'succSettings', 'success');
        props.history.push(props.location.pathname);
      }
    });
  }

  function closePopup(){
    setOpenSaveAs(false);
    setViewName(null);
    setIsEdit(false);
  }

  const menu = (
    <Menu>
       <Menu.Item key="0" onClick={() => setOpenSaveAs(true)}>{plainTranslate(props.settings.locale, 'Save new view')}</Menu.Item>
       <Menu.Item key="1" onClick={() => onEditView(props.gridId)}>{plainTranslate(props.settings.locale, 'Edit current view')}</Menu.Item>
       <Menu.Item key="2" onClick={() => setShowConfirmDialog(true)}>{plainTranslate(props.settings.locale, 'Delete current view')}</Menu.Item>
    </Menu>
  );

  return (
    <>
      {props.gridId ? (<Dropdown overlay={menu}><SaveOutlined/></Dropdown>) : <SaveOutlined onClick={() => setOpenSaveAs(true)}/>}
      <Drawer className={"grid-save-view-drawer"} maskClosable={false} width={700} title={plainTranslate(props.settings.locale, 'Save as')} placement="right" visible={openSaveAs} onClose={closePopup}>
        <div className="col-md-12 col-xs-12 content">
          <div className="col-md-6 col-xs-6">
              <label for="viewName">{plainTranslate(props.settings.locale, 'View name:')}</label>
              <Input value={viewName} style={{ border: "1px solid #ccc" }} id="viewName" name="viewName" type="text" onChange={(e) => changeFieldValue('viewName', e.target.value)}/>
          </div>
          {props.settings.roles.includes('ROLE_SUPER_ADMIN') &&
              <div className="col-md-3 col-xs-6 isPublic">
                  <label style={{marginRight: 5}}>{plainTranslate(props.settings.locale, 'Is Public?')}</label>
                  <Switch checked={isPublic} onChange={(e) => changeFieldValue('isPublic', e)}/>
              </div>
          }
          <div className="col-md-3 col-xs-12" style={{paddingTop: 9}}>
            <Button type="text" className="grid-button-new" onClick={() => saveView(viewName,isPublic)} loading={isLoading}>
              {plainTranslate(props.settings.locale, "Save")}
            </Button>
          </div>
        </div>
      </Drawer>
          {showConfirmDialog &&
            <ModalConfirm onClose={() => setShowConfirmDialog(false)}>
              <section className="newPanel">
                  <div className="panel-body">
                      <div className="row m-bot15">
                          <div className="text-center">
                              <h3><strong>
                                 {plainTranslate(props.settings.locale, "Are you sure?")}
                              </strong>
                              </h3>
                              <div className="buttons">
                                  <button className="button-outline grey text-center"
                                      onClick={() => setShowConfirmDialog(false)}>
                                      {plainTranslate(props.settings.locale, "Cancel")}
                                  </button>
                                  <button className="button-primary text-center btn-ok"
                                      onClick={deleteView}>
                                      {plainTranslate(props.settings.locale, "Yes")}
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </ModalConfirm>
        }
    </>
  );
};

const mapStateToProps = state => ({
  settings: state.settings,
  gridProperties: state.gridProperties,
  data: state.gridData.data,
  pinnedRows: state.gridData.pinnedRows,
  pages: state.gridData.pages,
  totalRecords: state.gridData.totalRecords
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SaveViewDrawer));