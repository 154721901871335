import React, { useState, useEffect } from 'react';
import Translate, { plainTranslate } from '../common/translate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NewForm from "../redux-forms/form";
import { Button, Dropdown, Menu } from 'antd';
import Popup from "../common/popup";
import client from '../../actions/client';
import showAToast from '../common/showAToast';
import { updateLocale } from 'moment';
import FormLoader from '../forms/form-loader';

/**
* @author Nevyana Mihaleva
* @function PaymentLedger
**/

const PaymentLedger = (props) => {
  const [fetching, setFetching] = useState(false);
  const [isAccounting, setIsAccounting] = useState(false);
  const [ledger, setLedger] = useState(null);
  const [accountingOptions, setAccountingOptions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [accountingTemplate, setAccountingTemplate] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    setFetching(true);
    if (props.match.params && props.match.params.id) {
        client
            .get('/' + props.match.params.db + '/api/payment/' + props.match.params.id + '/ledger-info')
            .then((response) => {
              setIsAccounting(response.data ? response.data.isAccounting : false);
              setLedger(response.data ? response.data.ledger : false);
              setPaymentId(response.data ? response.data.paymentId : false);
              setAccountingOptions(response.data ? response.data.accOptions : []);
              setRoles(response.data ? response.data.roles : [])
              setAccountingOptions((
                <Menu>
                  {response.data && response.data.accOptions.map((option, i) => {
                    return <Menu.Item key={i}>
                      <a onClick={() => setAccountingTemplate(option['id'])}>
                        <Translate locale={props.settings.locale} value={option['name']} />
                      </a>
                    </Menu.Item>
                  })}
                </Menu>
              ));
            })
            .catch((error) => {
                console.error(error);
            });
    }
}, [props.match.params.id]);

  const update = () => {
    client
      .get('/' + props.match.params.db + '/api/payment/' + props.match.params.id + '/ledger-info')
      .then((response) => {
        setLedger(response.data ? response.data.ledger : false);
        setDisableButton(false);
      });
  }

  const createAccounting = (id) => {
    setDisableButton(true);
    let addVal = {};
    addVal['body'] = id;
    client.post('/' + props.match.params.db + '/api/payments/transfer/to/accounting/' + accountingTemplate, addVal)
      .then(response => response.data)
      .then(response => {
        if (response['success']) {
          showAToast(response.success, 'baseSuccess', 'success');
        } else {
          showAToast(response.errors, 'baseError', 'error');
        }
        setAccountingTemplate(false);
        update();
      })
  }
  return (
    <div>
      {fetching &&
        <>
          <div className="invoice-profile item-profile">
            <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
            <div className="dashboard">
              <div className="moduleSubMenu backMenu">
                <h3 className="pull-left">
                  <Link to={"/" + props.match.params.db + "/payments-v2"}>
                    <i className="ion-ios-arrow-back"></i>  {plainTranslate(props.settings.locale, 'Payments')}
                  </Link>
                </h3>
                <ul className="nav navbar-nav pull-right">
                  <li className="nav-item">
                    <Link to={"/" + props.match.params.db + "/payment/" + props.match.params.id + '/edit'}>
                      {plainTranslate(props.settings.locale, 'Payment')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/" + props.match.params.db + "/payment/" + ledger + "/ledger"} className="active">
                      {plainTranslate(props.settings.locale, 'Ledger entry')}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
              <br></br>
              <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                {isAccounting && roles.ledger && roles.ledger.hasView === true &&
                  <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                    {ledger && !disableButton ?
                      <>
                        <NewForm
                          formRoute={'/' + props.match.params.db + '/api/new-ledger/'}
                          saveRoute={'/' + props.match.params.db + '/api/new-ledger/'}
                          match={{ params: { id: ledger } }}
                          isNew={true}
                          popup={true}
                          form={'initializeFromState2'}
                        />
                      </>
                      :
                      <>
                        {accountingOptions  && !disableButton &&
                          <>
                            <Dropdown overlay={accountingOptions} >
                              <Button style={{ margin: '30px' }}>{plainTranslate(props.settings.locale, 'Add ledger')}</Button>
                            </Dropdown>
                          </>
                        }
                        {!accountingOptions && !disableButton &&
                          <><Link style={{ fontSize: '20px', margin: '30px' }} to={'/' + props.match.params.db + '/form-types-v2'}>{plainTranslate(props.settings.locale, 'No accounting templates. Click here to add some')}</Link></>
                        }
                      {disableButton &&
                        <FormLoader />
                      }
                      </>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
          {accountingTemplate &&
            <Popup locale={props.settings.locale}
              title={plainTranslate(props.settings.locale, 'Create ledger')}
              onClose={() => setAccountingTemplate(false)}>
              <section className="newPanel">
                <div className="panel-body">
                  <div className="row m-bot15">
                    <div className="text-center">
                      <h3>
                        <Translate locale={props.settings.locale} value="Are you sure you want to create accounting ledger?" />
                      </h3>
                      <button className="button-outlines green text-center"
                        onClick={() => setAccountingTemplate(false)}>
                        <Translate locale={props.settings.locale} value="No" />
                      </button>
                      <button className="button-outlines text-center btn-ok"
                        onClick={() => createAccounting(paymentId)}>
                        <Translate locale={props.settings.locale} value="Yes" />
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </Popup>
          }
        </>
      }
    </div>
  )
}

const mapStateToProps = state => ({
  settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentLedger);
