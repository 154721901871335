import React, {Component} from 'react';
import {render} from "react-dom";
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../common/translate';
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

class OrdersInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    render() {
        return (
            <div>
                <div className="account-profile-other-information-more">
                    <h4>{plainTranslate(this.props.settings.locale, 'Sales')}</h4>
                    {this.props.orders && this.props.orders.length > 0 &&
                        <a className="account-profile-show" id="orders" href="javascript:void(0)"
                           onClick={() => this.setState({show: !this.state.show})}>
                            {this.state.show ? <i className="glyphicon glyphicon-chevron-up"></i> :
                                <i className="glyphicon glyphicon-chevron-down"></i>}
                        </a>  
                    }
                    {
                        this.state.show &&
                        <div className="line">
                            <table  className="table">
                                <thead>
                                    <tr>
                                        <th>{plainTranslate(this.props.settings.locale, '#')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Date')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Location')}</th>
                                        <th>{plainTranslate(this.props.settings.locale, 'Amount')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.orders.map(order=>
                                        <tr>
                                            <td>
                                                {order.hasView 
                                                    ? <Link to={'/' + this.props.match.params.db +'/sale/'+order.secureId+'/profile'}>{order.no}</Link>
                                                    : order.no
                                                }
                                            </td>
                                            <td>{order.date ? moment((order.date).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</td>
                                            <td>{order.location}</td>
                                            <td>{parseFloat(order.amount).toFixed(2)}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <div className="separate"></div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrdersInfo));
