import React, { useState } from 'react';
import QRCode from "react-qr-code";
import {Input} from 'antd';

const QrCode = (props) => {
    const [value, setValue] = useState('');
    const onChange = (value) => {
        setValue(value);
        props.input.onChange(value);
      };

    return (<div>
            <Input defaultValue={props.input.value ? props.input.value : null} onChange={(e) => onChange(e.target.value)} />
            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "20%", width: "20%" }}
                value={value}
                viewBox={`0 0 256 256`}
            />
</div>
    );
}

export default QrCode;