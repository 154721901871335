import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';

class InsertTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        };
        
        this.invoice = `<!DOCTYPE HTML>
<html>
    <head>
        <meta http-equiv="Content-Type" content="charset=utf-8" />
        <style>
            * {font-family: "Times New Roman";font-size:17px}
            .invoice-title{width:48%;padding:0}
            .invoice-title > h1{font-size:32px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px}
            .invoice-title > p{font-weight:400; margin:10px 0; font-size:21px; text-transform:uppercase; color:#ccc}
            .invoice{width:48%;font-size:28px;letter-spacing:1px;font-weight:400;text-align:left}
            .first{margin:0px}                
            .first > table tr > td, .last > table tr > td{color:#666; line-height:25px;background:#f5f5f5; padding:0 10px}
            #details{border:1px solid #ddd;border-bottom:0px}
            #details > thead > tr{background:#dedede}
            #details > thead > tr > th{text-align: left; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 0 0px; border-color:#fff; padding:0 10px; font-size:14px}
            #details > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd #fff #ddd #fff}
            #details > tbody > tr > td.red, #total > tbody > tr > td.red{color:#ed1b2f}
            #details > tbody > tr > td.price, #total > tbody > tr > td.price{text-align:right}
            #total{border:1px solid #ddd; background:#f5f5f5; border-bottom:0px}
            #total > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd}
            .footer > span{font-style:normal;font-size:17px;line-height:25px;margin:0 5px;color:#444}
            .footer > span > strong{font-size:19px}
            .footer{text-align:right}
        </style>
    </head>
    <body style="width:1024px; margin:0 auto;" >
        <table width="100%"  border="0px">
            <thead>
                <tr>
                    <th align="left" width="45%">
                    </th>
                    <th align="right" width="45%">  
                        <img src="{{ share_image(company.logo, null, null, db) }}" height="120px"/>
                    </th>
                </tr> 
            </thead>
        </table>
        <table width="100%"  border="0px">
            <thead>
                <tr>
                    <th align="left" width="49%">
                    </th>
                    <th align="left" width="2%">
                    </th>
                </tr> 
            </thead>
        </table>
        <table width="100%"  border="0px">
            <thead>
                <tr>
                    <th class="invoice-title" align="left">
                        <h1 style="">
                            {% if invoice.type == 'invoice' %}
                                {{ 'Invoice'|trans }}
                            {% elseif invoice.type == 'credit note' %}
                                {{ 'Credit Note'|trans }}
                            {% elseif invoice.type == 'debit note' %}
                                {{ 'Debit Note'|trans }}
                            {% else %} {{ 'Undefined'|trans }}
                            {% endif %}
                        </h1>
                        <p style="">
                            {% if invoice.getStatusIssued == invoice.status %}{{ 'Original'|trans }}{% else %}<span style="color:red;">{{ 'Cancelled'|trans }}</span>{% endif %}
                        </p>
                    </th>
                    <th class="invoice">
                        <table border="0px" style="padding-left:15px;">
                            <tr>
                                <td><p>№</p></td>
                                <td><p>{{ invoice.no }}</p></td>
                            </tr>
                            <tr>
                                <td><p>{{ 'Date'|trans }}:</p></td>
                                <td><p>
                                        {% set day = invoice.date|date('d') %}
                                        {% set month = invoice.date|date('F') %}
                                        {% set year = invoice.date|date('Y') %}
                                        {{ '%s %s %s'|format(day, month|trans, year) }}</p></td>
                            </tr>
                        </table>
                </tr>
            </thead>
        </table>
        <div class="body">
            <table width="100%" cellspacing="0" cellpadding="0" border="0px" >
                <tbody>
                    <tr>
                        <td colspan="2">
                            <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="info" >
                                <thead>
                                    <tr>
                                        <th width="40%" align="center" class="first"></th>
                                        <th width="40%" align="center" class="last"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="last">
                                        <td class="first" >
                                            <table border="0px" style="padding:35px 0 15px 0; padding-left: 20px;" width="95%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td style="border-bottom:1px solid #fff;" valign="middle"><span style="padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Customer'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ financialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" style="width:100%;" valign="middle"><strong>{{ financialInformation.country }}, {{ financialInformation.city }}</strong></td>
                                                </tr> 
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ financialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ financialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td class="last">
                                            <table border="0px" style="padding:35px 0 15px 0; padding-left: 20px;" width="95%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td style="border-bottom:1px solid #fff;" valign="middle"><span style="padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Supplier'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ vendorFinancialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" style="width:100%;" valign="middle"><strong>{{ vendorFinancialInformation.country }}, {{ vendorFinancialInformation.city }}</strong></td>
                                                </tr> 
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ vendorFinancialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ vendorFinancialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br><br><br>
            <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="details" >
                <thead>
                    <tr>
                        <th width="5%" style="border-width:0 1px 0 0;">№</th>
                        <th width="35%" style="">{{ 'Items'|trans }}</th>
                        <th width="10%">{{ 'Qty'|trans }}</th>
                        <th width="10%" style="text-align:right;">{{ 'Unit price'|trans }}</th>
                        <th width="10%" style="text-align:right;">{{ 'Discount'|trans }}</th>
                        <!--th width="10%" style="text-align:right;">{{ 'Amount'|trans }}</th-->
                        <!--th width="10%" style="text-align:right;">{{ 'VAT'|trans }}</th-->
                        <th width="10%" style="border-width:0 0 0 0px; text-align:right;">{{ 'Amount'|trans }}</th>
                    </tr>
                </thead>
                <tbody>
                    {% for line in invoice.lines %}
                        <tr{% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                            <td class="red" style="text-align: center;">{{ loop.index }}</td>
                            <td>{{ line.description }}</td>
                            <td class="red" style="text-align: center;">{{ line.quantity|number_format(0, '.', '') }}</td>
                            <!--td class="red price">{{ (line.unitPrice/1.95583)|number_format(2, '.', '') }}</td-->
                            <td class="price">{{ (line.Price)|number_format(2, '.', '') }} </td>
                            <!--td class="red price">{{ line.baseAmount|number_format(2, '.', '') }} лв</td>
                            <td class="red price">{{ line.vat|number_format(2, '.', '') }} лв</td-->
                            <td class="price">{{ (line.Discount)|number_format(2, '.', '') }} </td>
                            <td class="red price">{{ ((line.Price)*(line.quantity))-(line.Discount)|number_format(2, '.', '') }}</td>
                        </tr>
                    {% endfor %}
                </tbody>
            </table>
            <br><br>
            <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="total" >
                <tbody>
                    <tr>
                        <td width="60%" style="">{{ 'Other Comments'|trans }}:</td>
                        <td width="20%" style="">{{ 'Tax Base'|trans }}:</td>
                        <td width="20%" align="right" class="price red">{{ ((invoice.amountSum))|number_format(2, '.', ' ') }}</td>
                    </tr>
                    <tr>
                        <td style="background:#fff;"></td>
                        <td width="20%" style="">{{ 'Discount Amount'|trans }}:</td>
                        <td width="20%" align="right" class="price red">{{ ((invoice.Discount))|number_format(2, '.', ' ') }}</td>
                    </tr>
                    <tr>
                        <td style="background:#fff;"></td>
                        <td style="">{{ 'VAT'|trans }}:</td>
                        <td align="right" class="price red">{{ invoice.vat|number_format(2, '.', ' ') }} </td>
                    </tr>
                    <tr>
                        <!--td  style="background:#fff;"><span>Словом:</span> {{ widget_numbers_in_words(invoice.amountTotal) }}</td-->
                        <td style="background:#fff; border-left:1px solid #eee;"><!--Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.--></td>
                        <td style="background:#eee" ><b>{{ 'Total Amount Due'|trans }}:</b></td>
                        <td align="right" style="background:#eee" class="price red"><b> {{ ((invoice.amountTotal))|number_format(2, '.', ' ') }} </b></td>
                    </tr>
                </tbody>
            </table>
            <br><br>
            <table style="background:#f5f5f5;" width="100%" cellspacing="0" cellpadding="0" border="0px" class="more-info">
                <tbody>
                    <tr><td><br></td></tr>
                    <tr>
                        <td colspan="2">
                            <table border="0px" width="100%" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <table border="0px" width="100%" style="padding:10px; color:#666; font-size:16px;">
                                                <tr><td style="font-size:21px;line-height:25px; color:#000; font-weight:300; text-transform:uppercase;" width="100" valign="middle"><b>{{ 'Payment method'|trans }}</b></td></tr>
                                                <tr><td width="100" valign="middle">{% if invoice.paymentMethodId %}{{ invoice.paymentMethod.name }}{% else %}-{% endif %}</td></tr>
                                                <tr><td width="100" valign="middle">{% if invoice.paymentMethodId %}{{ invoice.paymentMethod.description|raw}}{% else %}-{% endif %}</td></tr>
                                                <tr><td width="100" valign="middle"></td></tr>
                                            </table>
                                        </td>
                                        <td>							
                                            <table border="0px" width="100%" style="padding:10px; color:#666; font-size:16px;">
                                                <tr><td width="100" valign="middle"><b>{{ 'Issued by'|trans }}:</b></td></tr>
                                                <tr><td width="100" valign="middle">{{ invoice.createdBy.names|default('-') }}</td></tr>
                                                <tr><td width="100" valign="middle"><b>{{ 'Taxation date'|trans }}:</b></td></tr>
                                                <tr><td width="100" valign="middle"> {{ invoice.date|date("d/m/Y") }}</td></tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr><td><br></td></tr>
                </tbody>
            </table>
        </div>
        <br><br>
    </body>
</html>`;
    this.quote = `<!DOCTYPE HTML>
<html>
    <head>
        <meta http-equiv="Content-Type" content="charset=utf-8" />
        <link rel="stylesheet" href="{{ asset('bundles/uiweb/css/ionicons.css') }}" media="screen" type="text/css">        
        <style>
            * {font-family: "Times New Roman";font-size:17px}
            .invoice-title{width:48%;padding:0}
            .invoice-title > h1{font-size:32px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px}
            .invoice-title > p{font-weight:400; margin:10px 0; font-size:21px; text-transform:uppercase; color:#ccc}
            .invoice{width:48%;font-size:28px;letter-spacing:1px;font-weight:400;text-align:left}
            .first{margin:0px}                
            .first > table tr > td, .last > table tr > td{color:#666; line-height:25px;background:#f5f5f5; padding:0 10px}
            #details{border:1px solid #ddd;border-bottom:0px}
            #details > thead > tr{background:#dedede}
            #details > thead > tr > th{text-align: left; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 0 0px; border-color:#fff; padding:0 10px; font-size:14px}
            #details > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd #fff #ddd #fff}
            #details > tbody > tr > td.red, #total > tbody > tr > td.red{color:#ed1b2f}
            #details > tbody > tr > td.price, #total > tbody > tr > td.price{text-align:right}
            #total{border:1px solid #ddd; background:#f5f5f5; border-bottom:0px}
            #total > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd}
            .footer > span{font-style:normal;font-size:17px;line-height:25px;margin:0 5px;color:#444}
            .footer > span > strong{font-size:19px}
            .footer{text-align:right}
        </style>
    </head>
    <body style="width:1024px; margin:0 auto;" >
        <table width="100%" border="0px">
            <thead>
                <tr>
                    <th align="left" width="45%">
                    </th>
                    <th align="right" width="45%">   
                        <img src="{{ share_image(sales.company.logo, null, null, db) }}" height="120px;"/>
                    </th>
                </tr> 
            </thead>
        </table>
        <table border="0px" width="100%">
            <thead>
                <tr>
                    <th class="invoice-title" align="left">
                        <h1 style="">{{ 'Quote'|trans }}</h1>
                    </th>
                    <th class="invoice" style="">
                        № {{ sales.code }} <br>
                        {{ 'Date'|trans }}: 
			{% set day = sales.date|date('d') %}
			{% set month = sales.date|date('F') %}
			{% set year = sales.date|date('Y') %}
			{{ '%s %s %s'|format(day, month|trans, year) }}
                    </th>
                </tr>
            </thead>
        </table>
        <div class="body">
            <table border="0px" width="100%" cellspacing="0" cellpadding="0">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="info">
                                <thead>
                                    <tr>
                                        <th width="40%" align="center" class="first"></th>
                                        <th width="40%" align="center" class="last"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="last">
                                        <td class="first" style="">
                                            <table border="0px" style="padding:5px 2.5% 15px 0;" width="97.5%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td style="background: #f5f5f5;border-bottom:2px solid #fff;" valign="middle" width="50"><span style="font-size: 20px;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Customer'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px;">{{ financialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.city }}</strong></td>
                                                </tr>   
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ financialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px; height:65px;" valign="middle"><strong>{{ financialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td class="last">
                                            <table border="0px" style="padding:5px 2.5% 15px 0;" width="97.5%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td style="background: #f5f5f5;border-bottom:2px solid #fff;" valign="middle" ><span style="font-size: 20px;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Supplier'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ vendorFinancialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" style="width:100%;" valign="middle"><strong>{{ vendorFinancialInformation.city }}</strong></td>
                                                </tr>   
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ vendorFinancialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px; height:65px;" valign="middle"><strong>{{ vendorFinancialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br><br><br>
            <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="details">
                <thead>
                    <tr>
                        <th width="5%" style="border-width:0 1px 0 0;">No</th>
			<th width="20%" style="">{{ 'Image'|trans }}</th>
                        <th width="45%" style="">{{ 'Description'|trans }}</th>
                        <th width="5%">{{ 'Qty'|trans }}</th>
                        <th width="5%">{{ 'Unit price'|trans }}</th>
                        <th width="5%">{{ 'Amount'|trans }}</th>
                        <th width="5%">{{ 'VAT'|trans }}</th>
                        <th width="10%" style="border-width:0 0 0 0px;">{{ 'Total'|trans }}</th>
                    </tr>
                </thead>
                <tbody>
                    {% for line in sales.lines %}
                        <tr {% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                            <td class="red price">{{ loop.index }}</td>
                            {%if line.item.images[0].document%}
                            <td><img src="{{ share_image(line.item.images[0].document, null, null, db) }}" height="100px;"></td>
                            {%else%}
                            <td>{{'No item image'|trans}}</td>
                            {%endif%}
                            <td>{{ line.description }}</td>
                            <td class="red price">{{ line.quantity|number_format }}</td>
                            <td class="red price">{{ line.unitPrice|number_format(2, '.', '') }}</td>
                            <td class="red price">{{ line.baseAmount|number_format(2, '.', '') }}</td>
                            <td class="red price">{{ line.vatAmount|number_format(2, '.', '') }}</td>
                            <td class="red price">{{ line.totalAmount|number_format(2, '.', '') }}</td>
                        </tr>
                    {% endfor %}
                </tbody>
            </table>
            <br><br>
            <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="total">
                <tbody>
                    <tr>
                        <td width="60%" style=""><strong>{{ 'Terms and conditions'|trans }}</strong></td>
                        <td width="20%" style="">{{ 'Tax Base'|trans }}:</td>
						<td width="20%" align="right" class="price red">{{ ((sales.amountSum))|number_format(2, '.', ' ') }}</td>
                    </tr>
                    <tr>
                        <td width="60%" style="background:#fff">{{'This quote is valid through'|trans}}: ............</td>
                        <td width="20%" style="">{{ 'Discount Amount'|trans }}:</td>
			<td width="20%" align="right" class="price red">{{ ((sales.discountAmount))|number_format(2, '.', ' ') }}</td>
			</tr>
			<tr>
			<td width="60%" style="background:#fff">{{'Payment will be due prior to delivery of service and goods.'|trans}}</td>
                        <td style="">{{ 'VAT'|trans }}:</td>
                        <td align="right" class="price red">{{ sales.vat|number_format(2, '.', ' ') }} </td>
                    </tr>
                    <tr>
			<td style="background:#fff; border-left:1px solid #eee;"></td>
                        <td style="background:#eee"><strong>{{ 'Total'|trans }}:</strong></td>
                        <td align="right" style="background:#eee" class="price red"><strong>{{ ((sales.amountTotal))|number_format(2, '.', ' ') }}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br><br>
        <div class="footer">
		<p style="float:left;text-align:center">{{'If you have any questions regarding this quote, please contact'|trans}}:
		<br>
		{{ sales.createdBy.names|default('-') }}
		<br>
		{{ sales.createdBy.email|default('-') }}
		<br>
		</p>
        </div>
    </body>
</html>`;
    this.receipt = `<!DOCTYPE HTML>
<html>
    <head>
        <meta http-equiv="Content-Type" content="charset=utf-8" />
        <style>

            * {
                font-family: "Times New Roman";
                font-size:17px;
            }

            .invoice-title{
                width:48%;
                padding:0
            }
            .invoice-title > h1{
                font-size:32px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px;
            }
            .invoice-title > p{
                font-weight:400; margin:10px 0; font-size:21px; text-transform:uppercase; color:#ccc;
            }
            .invoice{
                width:48%;font-size:28px;letter-spacing:1px;font-weight:400;text-align:left;
            }
            .invoice p{
                font-size:25px;
                line-height:25px;
                margin:0px;
                padding:0px;
            }
            .first{
                margin:0px;
            }
            .first > table tr > td,
            .last > table tr > td{
                color:#666; line-height:25px;background:#f5f5f5; padding:0 10px;
            }

            #details{
                border:1px solid #ddd;border-bottom:0px;
            }
            #details > thead > tr{
                background:#dedede;
            }
            #details > thead > tr > th{
                text-align: left; font-weight:400; line-height:25px; border-style:solid;
                border-width:0 1px 0 0px; border-color:#fff; padding:0 10px; font-size:14px;
            }
            #details > tbody > tr > td{
                padding:0 10px; font-weight:400; line-height:25px; border-style:solid;
                border-width:0 1px 1px 0; border-color:#ddd #fff #ddd #fff;
            }
            #details > tbody > tr > td.red,
            #total > tbody > tr > td.red{
                color:#ed1b2f;
            }
            #details > tbody > tr > td.price,
            #total > tbody > tr > td.price{
                text-align:right;
            }
            #total{
                border:1px solid #ddd; background:#f5f5f5; border-bottom:0px;
            }
            #total > tbody > tr > td{
                padding:0 10px; font-weight:400; line-height:25px; border-style:solid;
                border-width:0 1px 1px 0; border-color:#ddd
            }
            .footer > span{
                font-style:normal;
                font-size:17px;
                line-height:25px;
                margin:0 5px;
                color:#444;
            }
            .footer > span > strong{
                font-size:19px;
            }
            .footer{
                text-align:left;
                position: absolute;
                bottom:25px;
            }

        </style>
    </head>
    <body style="width:1024px; margin:0 auto;" >
        <table  width="100%" border="0px">
            <thead>
                <tr>
                    <th align="left" width="49%">
                    </th>
                        <th align="left" width="2%">
                    </th>
                    <th align="right" width="45%">
                        <img src=".{{ share_image(company.logo, null, null, db) }}" width="180px"/>
                    </th>
                </tr>
            </thead>
        </table>
        {% if receipt.form.type  == 'purchase' or  receipt.form.type  == 'release' %}

        <table  width="100%" border="0px">
            <thead>
                <tr>
                    <th class="invoice-title" align="left">
                        <h1 style="">
                            {% if receipt.form.type  == 'purchase' %}
                            {{ 'Purchase receipt'|trans }}
                            {% elseif receipt.form.type  == 'release' %}
                            {{ 'Release receipt'|trans }}
                            {% endif %}
                        </h1>
                        <p style="">
                            {{ receipt.stage.name |trans }}
                        </p>
                    </th>
                    <th class="invoice">
                        <table border="0px" style="padding-left:15px;">
                            <tr>
                                <td><p>№</p></td>
                                <td><p>{{ receipt.documentNo }}</p></td>
                            </tr>
                            <tr>
                                <td><p>{{ 'Date'|trans }}</p></td>
                                <td><p>{% set day = receipt.date|date('d') %}
                                      {% set month = receipt.date|date('F') %}
                                      {% set year = receipt.date|date('Y') %}
                                      {{ '%s %s %s'|format(day, month|trans, year) }}</p></td>
                            </tr>
                        </table>
                </tr>
            </thead>
        </table>
        <div class="body">
          <table width="100%" cellspacing="0" cellpadding="0" border="0">
              <tbody>
                  <tr>
                      <td colspan="2">
                          <table width="100%" cellspacing="0" cellpadding="0" id="info">
                              <thead>
                                  <tr>
                                      <th width="40%" align="center" class="first"></th>

                                      <th width="40%" align="center" class="last"></th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr class="last">
                                      <td class="first" >
                                          <table style="padding:5px 5% 15px 0" width="95%" cellspacing="0" cellpadding="0">
                                              <tr>
                                                  <td style="background: #fff;" valign="middle"><span style="border-bottom:1px solid #fff;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">
                                                    {% if receipt.form.type  == 'purchase' %}
                                                    {{ 'Supplier'|trans }}
                                                    {% elseif receipt.form.type  == 'release' %}
                                                    {{ 'Client'|trans }}
                                                    {% endif %}
                                                  </span></td>
                                                  <td style="background: #fff;" valign="middle"></td>
                                                  <td style="background: #fff;" valign="middle"></td>
                                              </tr>
                                              <tr>
                                                  <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px;">{{ financialInformation.name }}</strong></td>

                                              </tr>
                                              <tr>
                                                  <td valign="middle">Адрес:</td>
                                                  <td colspan="2" valign="middle"><strong>{{ financialInformation.city }}</strong></td>
                                              </tr>
                                              <tr>
                                                  <td valign="middle"></td>
                                                  <td colspan="2" valign="top" style="height:65px;"><strong>{{ financialInformation.address1 }}</strong></td>
                                              </tr>
                                              <tr>
                                                  <td valign="middle">ЕИК / ЕГН:</td>
                                                  <td colspan="2" valign="middle"><strong>{{ financialInformation.uic }}</strong></td>
                                              </tr>
                                              <tr>
                                                  <td valign="middle">ДДС №:</td>
                                                  <td colspan="2" valign="middle"><strong>{{ financialInformation.vat }}</strong></td>
                                              </tr>
                                              <tr>
                                                  <td style="padding-bottom:25px" valign="middle">МОЛ:</td>
                                                  <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ financialInformation.responsiblePerson }}</strong></td>
                                              </tr>

                                          </table>
                                      </td>
                                      <td class="last">
                                          <table style="padding:5px 0 15px 2.5%;" width="100%" cellspacing="0" cellpadding="0">
                                              <tr>
                                                  <td style="background: #fff;" valign="middle"><span style="border-bottom:1px solid #fff;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">
                                                    {% if receipt.form.type  == 'purchase' %}
                                                    {{ 'Client'|trans }}
                                                    {% elseif receipt.form.type  == 'release' %}
                                                    {{ 'Supplier'|trans }}
                                                    {% endif %}
                                                  </span></td>
                                                  <td style="background: #fff;" valign="middle"></td>
                                                  <td style="background: #fff;" valign="middle"></td>
                                              </tr>
                                              <tr>
                                                  <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ vendorFinancialInformation.name }}</strong></td>

                                              </tr>
                                              <tr>
                                                  <td valign="middle">Адрес:</td>
                                                  <td colspan="2" style="width:100%;" valign="middle"><strong>{{ vendorFinancialInformation.city }}</strong></td>
                                              </tr>
                                              <tr>
                                                  <td valign="middle"></td>
                                                  <td colspan="2" valign="top" style="height:65px;"><strong>{{ vendorFinancialInformation.address1 }}</strong></td>
                                              </tr>
                                              <tr>
                                                  <td valign="middle">ЕИК / ЕГН:</td>
                                                  <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.uic }}</strong></td>
                                              </tr>
                                              <tr>
                                                  <td valign="middle">ДДС №:</td>
                                                  <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.vat }}</strong></td>
                                              </tr>
                                              <tr>
                                                  <td style="padding-bottom:25px" valign="middle">МОЛ:</td>
                                                  <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ vendorFinancialInformation.responsiblePerson }}</strong></td>
                                              </tr>
                                          </table>

                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </td>
                  </tr>
              </tbody>
          </table><br><br>
            <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="details">
                <thead>
                    <tr>
                        <th width="5%" style="border-width:0 1px 0 0;">№</th>
                        <th width="25%">{{ 'Items'|trans }}</th>
                        <th width="25%">{{ 'Description'|trans }}</th>
                        <th width="10%" style="text-align:right;">{{ 'Qty'|trans }}</th>
                        <th width="15%" style="text-align:right;">{{ 'Unit price'|trans }}</th>
                        <th width="15%">{{ 'Currency'|trans }}</th>
                        <th width="15%" style="text-align:right;">{{ 'Currency rate'|trans }}</th>
                        <th width="15%" style="border-width:0 0 0 0px; text-align:right;">{{ 'Amount'|trans }}</th>
                    </tr>
                </thead>
                <tbody>
                    {% for line in receipt.lines %}
                    <tr{% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                        <td style="text-align: center;">{{ loop.index }}</td>
                        <td>{{ line.item.no }}</td>
                        <td>{{ line.itemDescription }}</td>
                        <td  style="text-align:right;">{{ line.itemQuantity|abs|number_format(0, '.', '') }}</td>
                            <td class="price">{{ (line.unitPriceOriginal)|number_format(2, '.', '') }} </td>
                            <td>{{ line.currencyOriginal.name }}</td>
                            <td class="price">{{ line.currencyRate|number_format(5, '.', '') }}</td>
                        <td class="price">{{ ((line.unitPriceOriginal)*(line.itemQuantity))|abs|number_format(2, '.', '') }}</td>
                    </tr>
                    {% endfor %}
                </tbody>
            </table>
            <br><br>
            <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="total">
                <tbody>
                    <tr>
                        <td width="60%" style="">{{ 'Other Comments'|trans }}:</td>
                        <td width="20%" style="">{{ 'Tax Base'|trans }}:</td>
                        <td width="20%" align="right" class="price">{{ ((receipt.amountBeforeTax))|abs|number_format(2, '.', ' ') }}</td>
                    </tr>

                    <tr>
                        <td style="background:#fff;"></td>
                        <td style="">{{ 'TAX'|trans }}:</td>
                        <td align="right" class="price">{{ receipt.amountVat|abs|number_format(2, '.', ' ') }} </td>
                    </tr>
                    <tr>

                        <td style="background:#fff; border-left:1px solid #eee;"></td>
                        <td style="background:#eee" ><strong>{{ 'Total Amount Due'|trans }}:</strong></td>
                        <td align="right" style="background:#eee" class="price"><strong>{{ ((receipt.amountSum))|abs|number_format(2, '.', ' ') }} </strong></td>
                    </tr>
                </tbody>
            </table>
            <br><br>
            <table  width="100%" style="color:#666; font-size:16px;background:#f5f5f5;">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <table border="0px" width="100%" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                      <td></td>
                                        <td>
                                            <table border="0px" width="100%"  style="padding:10px; color:#666; font-size:16px;">
                                                <tr><td width="100" valign="middle"><strong>{{ 'Issued by'|trans }}:</strong></td></tr>
                                                <tr><td width="100" valign="middle">{{ receipt.createdBy.names|default('-') }}</td></tr>
                                                <tr><td width="100" valign="middle"><strong>{{ 'Date'|trans }}:</strong></td></tr>
                                                <tr><td width="100" valign="middle"> {{ receipt.date|date("d/m/Y") }}</td></tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr><td><br></td></tr>
                </tbody>
            </table>
            {% endif %}
            {% if receipt.form.type  == 'transfer' %}
            <table width="100%" cellspacing="0" cellpadding="0" border="0px" class="more-info">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <table border="0px" width="100%" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                          <th class="invoice-title" align="left">
                                              <h1 style="">
                                                {% if receipt.form.type  == 'transfer' %}
                                                {{ 'Transfer order'|trans }}
                                                {% endif %}
                                              </h1>
                                              <p style="">
                                                {{ receipt.stage.name |trans }}
                                              </p>
                                          </th>
                                          <th class="invoice">
                                              <table border="0px" style="padding-left:15px;">
                                                  <tr>
                                                      <td><p>№</p></td>
                                                      <td><p>{{ receipt.documentNo }}</p></td>
                                                  </tr>
                                                  <tr>
                                                      <td><p>{{ 'Date'|trans }}: </p></td>
                                                      <td><p>{% set day = receipt.date|date('d') %}
                                                                 {% set month = receipt.date|date('F') %}
                                                                 {% set year = receipt.date|date('Y') %}
                                                                 {{ '%s %s %s'|format(day, month|trans, year) }}</p></td>
                                                    </tr>
                                              </table>
                                      </tr>
                                  </tbody>
                              </table>
                              <div class="body">
                                <table  width="100%" cellspacing="0" cellpadding="0" border="0px">
                                      <tbody>
                                          <tr>
                                              <td colspan="2">
                                                  <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="info">
                                                      <thead>
                                                          <tr>
                                                              <th width="40%" align="center" class="first"></th>
                                                              <th width="40%" align="center" class="last"></th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr class="last">
                                                              <td class="first" >
                                                                  <table border="0px" style="padding:35px 0 15px 0;" width="100%" cellspacing="0" cellpadding="0">
                                                                      <tr>
                                                                          <td style="background: #fff;" valign="middle"><span style="border-bottom:1px solid #fff;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Location From'|trans }}</span></td>
                                                                          <td style="background: #fff;" valign="middle"></td>
                                                                          <td style="background: #fff;" valign="middle"></td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ receipt.parent.location.name }}</strong></td>
                                                                      </tr>
                                                                  </table>
                                                              </td>
                                                              <td class="last">
                                                                  <table border="0px" style="padding:35px 0 15px 0; padding-left: 20px;" width="100%" cellspacing="0" cellpadding="0">
                                                                      <tr>
                                                                          <td style="background: #fff;" valign="middle"><span style="border-bottom:1px solid #fff;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Location To'|trans }}</span></td>
                                                                          <td style="background: #fff;" valign="middle"></td>
                                                                          <td style="background: #fff;" valign="middle"></td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ receipt.location.name }}</strong></td>
                                                                      </tr>
                                                                  </table>
                                                              </td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                                  <br><br>
                                  <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="details"  style="background:#f5f5f5;">
                                      <thead>
                                          <tr>
                                              <th width="5%" style="border-width:0 1px 0 0;">№</th>
                                              <th width="30%">{{ 'Items'|trans }}</th>
                                              <th width="30%">{{ 'Description'|trans }}</th>
                                              <th width="20%" style="text-align:right;">{{ 'Qty'|trans }}</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {% for line in receipt.lines %}
                                          <tr{% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                                              <td  style="text-align: center;">{{ loop.index }}</td>
                                              <td>{{ line.item.no }}</td>
                                              <td>{{ line.itemDescription }}</td>
                                              <td  style="text-align:right;">{{ line.itemQuantity|abs|number_format(0, '.', '') }}</td>
                                          </tr>
                                          {% endfor %}
                                      </tbody>
                                  </table>
                                  <br><br>
                                  <table  width="100%" style="color:#666; font-size:16px;">
                                      <tbody>
                                          <tr>
                                              <td colspan="2">
                                                  <table border="0px" width="100%" cellspacing="0" cellpadding="0">
                                                      <tbody>
                                                          <tr>
                                                              <td>
                                                                  <table border="0px" width="100%"  style="padding:10px; color:#666; font-size:16px; background:#f5f5f5;">
                                                                      <tr><td width="100" valign="middle"><strong>{{ 'Issued by'|trans }}:</strong></td></tr>
                                                                      <tr><td width="100" valign="middle">{{ receipt.createdBy.names|default('-') }}</td></tr>
                                                                      <tr><td width="100" valign="middle"><strong>{{ 'Date'|trans }}:</strong></td></tr>
                                                                      <tr><td width="100" valign="middle"> {{ receipt.date|date("d/m/Y") }}</td></tr>
                                                                  </table>
                                                              </td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </td>
                                          </tr>
                                          <tr><td><br></td></tr>
                                      </tbody>
                                  </table>
                          {% endif %}

                          {% if receipt.form.type  == 'revision full' or  receipt.form.type  == 'revision part' %}
                          <table width="100%" cellspacing="0" cellpadding="0" border="0px" class="more-info">
                              <tbody>
                                  <tr>
                                      <td colspan="2">
                                          <table border="0px" width="100%" cellspacing="0" cellpadding="0">
                                              <tbody>
                                                  <tr>
                                                        <th class="invoice-title" align="left">
                                                            <h1 style="">
                                                              {% if receipt.form.type == 'revision part' %}
                                                              {{ 'Revision order part'|trans }}
                                                              {% elseif receipt.form.type == 'revision full' %}
                                                              {{ 'Revision order full'|trans }}
                                                              {% endif %}
                                                            </h1>
                                                            <p style="">
                                                              {{ receipt.stage.name |trans }}
                                                            </p>
                                                        </th>
                                                        <th class="invoice">
                                                            <table border="0px" style="padding-left:15px;">
                                                                <tr>
                                                                    <td><p>№</p></td>
                                                                    <td><p>{{ receipt.documentNo }}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><p>{{ 'Date'|trans }}: </p></td>
                                                                    <td><p>{% set day = receipt.date|date('d') %}
                                                                           {% set month = receipt.date|date('F') %}
                                                                           {% set year = receipt.date|date('Y') %}
                                                                           {{ '%s %s %s'|format(day, month|trans, year) }}</p></td>
                                                                  </tr>
                                                            </table>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="body">
                                              <table  width="100%" cellspacing="0" cellpadding="0" border="0px">
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="2">
                                                                <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="info">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="40%" align="center" class="first"></th>
                                                                            <th width="40%" align="center" class="last"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr class="last">
                                                                            <td class="first" >
                                                                                <table border="0px" style="padding:35px 0 15px 0;" width="100%" cellspacing="0" cellpadding="0">
                                                                                    <tr>
                                                                                        <td style="background: #fff;" valign="middle"><span style="border-bottom:1px solid #fff;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Location'|trans }}</span></td>
                                                                                        <td style="background: #fff;" valign="middle"></td>
                                                                                        <td style="background: #fff;" valign="middle"></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ receipt.location.name }}</strong></td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br><br>
                                                <table style="background:#f5f5f5;" border="0px" width="100%" cellspacing="0" cellpadding="0" id="details">
                                                    <thead>
                                                        <tr>
                                                            <th width="5%" style="border-width:0 1px 0 0;">№</th>
                                                            <th width="30%">{{ 'Items'|trans }}</th>
                                                            <th width="30%">{{ 'Description'|trans }}</th>
                                                            <th width="20%" style="text-align:right;">{{ 'Qty'|trans }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {% for line in receipt.lines %}
                                                        <tr{% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                                                            <td  style="text-align: center;">{{ loop.index }}</td>
                                                            <td>{{ line.item.no }}</td>
                                                            <td>{{ line.itemDescription }}</td>
                                                            <td  style="text-align:right;">{{ line.itemQuantity|abs|number_format(0, '.', '') }}</td>
                                                        </tr>
                                                        {% endfor %}
                                                    </tbody>
                                                </table>
                                                <br><br>
                                                <table style="background:#f5f5f5; " width="100%" cellspacing="0" cellpadding="0" border="0px" class="more-info">
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="2">
                                                                <table border="0px" width="100%" cellspacing="0" cellpadding="0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <table border="0px" width="100%" style="padding:10px; color:#666; font-size:16px;">
                                                                                    <tr><td width="100" valign="middle"><strong>{{ 'Issued by'|trans }}:</strong></td></tr>
                                                                                    <tr><td width="100" valign="middle">{{ receipt.createdBy.names|default('-') }}</td></tr>
                                                                                    <tr><td width="100" valign="middle"><strong>{{ 'Date'|trans }}:</strong></td></tr>
                                                                                    <tr><td width="100" valign="middle"> {{ receipt.date|date("d/m/Y") }}</td></tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr><td><br></td></tr>
                                                    </tbody>
                                                </table>
                                        {% endif %}
                  </div>
                  <div class="footer">
                           <p></p>
                         </div>
    </body>
</html>
`;
    this.purchase =`<!DOCTYPE HTML>
<html>
    <head>
        <meta http-equiv="Content-Type" content="charset=utf-8" />
        <link rel="stylesheet" href="{{ asset('bundles/uiweb/css/ionicons.css') }}" media="screen" type="text/css">        
        <style>
            * {font-family: "Times New Roman";font-size:17px}
            .invoice-title{width:48%;padding:0}
            .invoice-title > h1{font-size:32px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px}
            .invoice-title > p{font-weight:400; margin:10px 0; font-size:21px; text-transform:uppercase; color:#ccc}
            .invoice{width:48%;font-size:28px;letter-spacing:1px;font-weight:400;text-align:left}
            .first{margin:0px}                
            .first > table tr > td, .last > table tr > td{color:#666; line-height:25px;background:#f5f5f5; padding:0 10px}
            #details{border:1px solid #ddd;border-bottom:0px}
            #details > thead > tr{background:#dedede}
            #details > thead > tr > th{text-align: left; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 0 0px; border-color:#fff; padding:0 10px; font-size:14px}
            #details > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd #fff #ddd #fff}
            #details > tbody > tr > td.red, #total > tbody > tr > td.red{color:#ed1b2f}
            #details > tbody > tr > td.price, #total > tbody > tr > td.price{text-align:right}
            #total{background:#f5f5f5; border:0px}
            #total > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border:0}
            .footer > span{font-style:normal;font-size:17px;line-height:25px;margin:0 5px;color:#444}
            .footer > span > strong{font-size:19px}
            .footer{text-align:right}
        </style>
    </head>
    <body style="width:1024px; margin:0 auto;" >
        <table width="100%" border="0">
            <thead>
                <tr>
                    <th align="left" width="45%">
                    </th>
                    <th align="right" width="45%">   
                        <img src="{{ share_image(company.logo, null, null, db) }}" height="120px"/>
                    </th>
                </tr> 
            </thead>
        </table>
        <table width="100%" border="0">
            <thead>
                <tr>
                    <th class="invoice-title" align="left">
                        <h1 style="">{{ 'Purchase #'|trans }}</h1>
                    </th>
                    <th class="invoice" style="">
                        {{ purchase.documentNo }} <br>
                        {{ 'Date'|trans }}: 
                        {% set day = purchase.date|date('d') %}
                        {% set month = purchase.date|date('F') %}
                        {% set year = purchase.date|date('Y') %}
                        {{ '%s %s %s'|format(day, month|trans, year) }}
                    </th>
                </tr>
            </thead>
        </table>
        <div class="body">
            <table width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <table width="100%" cellspacing="0" cellpadding="0" id="info">
                                <thead>
                                    <tr>
                                        <th width="40%" align="center" class="first"></th>
                                        <th width="40%" align="center" class="last"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="last">
                                        <td class="first" style="">
                                            <table style="padding:5px 2.5% 15px 0" width="97.5%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td style="background: #f5f5f5;border-bottom:2px solid #fff;" valign="middle" width="50"><span style="font-size: 20px;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Customer'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px;">{{ financialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.city }}</strong></td>
                                                </tr>   
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ financialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px; height:65px;" valign="middle"><strong>{{ financialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td class="last">
                                            <table style="padding:5px 2.5% 15px 0" width="97.5%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td style="background: #f5f5f5;border-bottom:2px solid #fff;" valign="middle" ><span style="font-size: 20px;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Supplier'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ vendorFinancialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" style="width:100%;" valign="middle"><strong>{{ vendorFinancialInformation.city }}</strong></td>
                                                </tr>   
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ vendorFinancialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px; height:65px;" valign="middle"><strong>{{ vendorFinancialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
            <br>
            <br>
            <table width="100%" cellspacing="0" cellpadding="0" id="details">
                <thead>
                    <tr>
                        <th width="5%" style="border-width:0 1px 0 0;">No</th>
                        <th width="50%" style="">{{ 'Description'|trans }}</th>
                        <th width="5%">{{ 'Qty'|trans }}</th>
                        <th width="10%">{{ 'Unit price'|trans }}</th>
                        <th width="10%">{{ 'Amount'|trans }}</th>
                        <th width="10%">{{ 'VAT'|trans }}</th>
                        <th width="10%" style="border-width:0 0 0 0px;">{{ 'Total'|trans }}</th>
                    </tr>
                </thead>
                <tbody>
                    {% for line in purchase.lines %}
                        <tr {% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                            <td class="red price">{{ loop.index }}</td>
                            <td>{{ line.description }}</td>
                            <td class="red price">{{ line.quantity|number_format }}</td>
                            <td class="red price">{{ line.unitPrice|number_format(2, '.', '') }}</td>
                            <td class="red price">{{ line.baseAmount|number_format(2, '.', '') }}</td>
                            <td class="red price">{{ line.vatAmount|number_format(2, '.', '') }}</td>
                            <td class="red price">{{ line.totalAmount|number_format(2, '.', '') }}</td>
                        </tr>
                    {% endfor %}
                </tbody>
            </table>
            <br>
            <br>
            <table  width="100%" cellspacing="0" cellpadding="0" id="total" style="border:0">
                <tbody>
                    <tr>
                        <td width="60%" style="background:#fff;border:0"> </td>
                        <td width="20%" style="">{{ 'Tax Base'|trans }}:</td>
                        <td width="20%" align="right" class="price red">{{ ((purchase.amountSubtotal))|number_format(2, '.', ' ') }}</td>
                    </tr>
                    <tr>
                        <td width="60%" style="background:#fff;border:0"></td>
                        <td style="">{{ 'VAT'|trans }}:</td>
                        <td align="right" class="price red">{{ purchase.vat|number_format(2, '.', ' ') }} </td>
                    </tr>
                    <tr>
                        <td style="background:#fff;border:0"></td>
                        <td style="background:#eee"><strong>{{ 'Total'|trans }}:</strong></td>
                        <td align="right" style="background:#eee" class="price red"><strong>{{ ((purchase.amountTotal))|number_format(2, '.', ' ') }}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br><br>
        <div class="footer">
        </div>
    </body>
</html>`;
    this.doc = `<p>Some text here</p>`;
    this.sales = `<!DOCTYPE HTML>
<html>
    <head>
        <meta http-equiv="Content-Type" content="charset=utf-8" />
        <link rel="stylesheet" href="{{ asset('bundles/uiweb/css/ionicons.css') }}" media="screen" type="text/css">
        <style>
            * {font-family: "Times New Roman";font-size:17px}
            .invoice-title{width:48%;padding:0}
            .invoice-title > h1{font-size:32px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px}
            .invoice-title > p{font-weight:400; margin:10px 0; font-size:16px; text-transform:uppercase; color:#ccc}
            .invoice{width:48%;font-size:28px;letter-spacing:1px;font-weight:400;text-align:left}
            .invoice > p{font-weight:400; margin:10px 0; font-size:16px;}
            .first{margin:0px}
            .first > table tr > td, .last > table tr > td{color:#666; line-height:25px;background:#f5f5f5; padding:0 10px}
            #details{border:1px solid #ddd;border-bottom:0px}
            #details > thead > tr{background:#dedede}
            #details > thead > tr > th{text-align: left; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 0 0px; border-color:#fff; padding:0 10px; font-size:14px}
            #details > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd #fff #ddd #fff}
            #details > tbody > tr > td.red, #total > tbody > tr > td.red{color:#ed1b2f}
            #details > tbody > tr > td.price, #total > tbody > tr > td.price{text-align:right}
            #total{border:1px solid #ddd; background:#f5f5f5; border-bottom:0px}
            #total > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd}
            .footer {
                    position: absolute;
                    bottom:-20;
                    right:0;
                float: right;
            }

        </style>
    </head>
    <header page="1" paper="A4" paper-orientation="portrait" paper-margin="2cm"></header>
    <body style="width:1024px; margin:0 auto;" >
        <table width="100%" border="0px">
            <thead>
                <tr>
                    <th align="left" width="45%">
                    </th>
                    <th align="right" width="45%">
                        <img src=".{{ share_image(company.logo, null, null, db) }}" height="80px"/>
                    </th>
                </tr>
            </thead>
        </table>
        <table border="0px" width="100%">
            <thead>
                <tr>
                    <th class="invoice-title" align="left">
                        <h1 style="">
                            {% if sales.reversal.documentNo %}
                            <h1>{{ 'Reversal document' | trans }}</h1>
                            <p>{{'To sale'| trans }}  №: {{ sales.reversal.documentNo }}</p>
                            <p>{{'Date' | trans }}: {{ sales.reversal.documentDate|date("d/m/Y") }}</p>
                            {% else %}<h1>{{'Sale' | trans}}</h1>
                            {% endif %}
                        </h1>
                        <p style="">
                            {{ 'Stage' |trans }}: {{ sales.stage.name |trans }}
                        </p>
                    </th>
                    <th class="invoice">
                        <table border="0px" style="padding-left:15px;">
                            <tr>
                                <td><h1>№</h1></td>
                                <td><h1>{{ sales.documentNo }}</h1></td>
                            </tr>
                            <tr>
                                <td>{{ 'Date'|trans }}</td>
                                <td><p>{% set day = sales.documentDate|date('d') %}
                                    {% set month = sales.documentDate|date('F') %}
                                    {% set year = sales.documentDate|date('Y') %}
                                    {{ '%s %s %s'|format(day, month|trans, year) }}</p></td>
                            </tr>
                        </table>
                </tr>
            </thead>
        </table>
        <div class="body">
            <table width="100%" cellspacing="0" cellpadding="0" border="0px" >
                <tbody>
                    <tr>
                        <td colspan="2">
                            <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="info" >
                                <thead>
                                    <tr>
                                        <th width="40%" align="center" class="first"></th>
                                        <th width="40%" align="center" class="last"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="last">
                                        <td class="first" >
                                            <table border="0px" style="padding:35px 0 15px 0; padding-right: 10px;" width="100%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td style="border-bottom:1px solid #fff;" valign="middle"><span style="padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Customer'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ financialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" style="width:100%;" valign="middle"><strong>{{ financialInformation.country }}, {{ financialInformation.city }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ financialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ financialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td class="last">
                                            <table border="0px" style="padding:35px 0 15px 0; padding-left: 10px;" width="100%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td style="border-bottom:1px solid #fff;" valign="middle"><span style="padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Supplier'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ vendorFinancialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" style="width:100%;" valign="middle"><strong>{{ vendorFinancialInformation.country }}, {{ vendorFinancialInformation.city }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ vendorFinancialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ vendorFinancialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
            <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="details">
                <thead>
                    <tr>
                        <th width="5%" style="border-width:0 1px 0 0;">№</th>
                        <th width="25%">{{'Title' | trans }}</th>
                        <th width="10%">{{'Qty' | trans }}</th>
                        <th width="15%" style="text-align:right;">{{'Unit Price' | trans }}</th>
                        <th width="15%" style="text-align:right;">{{'Amount' | trans }}</th>
                        <th width="15%" style="text-align:right;">{{'Tax Amount' | trans }}</th>
                        <th width="15%" style="border-width:0 0 0 0px;text-align:right;">{{'Total Amount' | trans }}</th>
                    </tr>
                </thead>
                <tbody>
                    {% for line in sales.lines %}
                    {%if not line.deleted%}
                    {%set no=no+1%}
                        <tr{% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                            <td class="price">{{ no }}</td>
                            <td>  {% if line.item.title %}
                                {{ line.item.title }}
                                {% else %}
                                {{ line.itemDescription }}
                                {% endif %}</td>
                            <td style="text-align:center;">{{ line.quantity }} {{line.measure.name | trans}}</td>
                            <td class="price">{{ line.unitPrice|number_format(2, '.', '') }} {{line.currency.code | trans}}</td>
                            <td class="price">{{ line.amountBase|number_format(2, '.', '') }} {{line.currency.code | trans}}</td>
                            <td class="price">{{ line.amountTax|number_format(2, '.', '') }} {{line.currency.code | trans}}</td>
                            <td class="price">{{ line.amountTotal|number_format(2, '.', '') }} {{line.currency.code | trans}}</td>
                        </tr>
                    {%endif%}
                    {% endfor %}
                </tbody>
            </table>
            <br><br>
            <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="total">
                <tbody>
                    <tr>
                        <td width="60%" style=""><strong>{{ 'Terms and conditions'|trans }}</strong></td>
                        <td width="20%" style="">{{ 'Tax Base'|trans }}:</td>
                        <td width="20%" align="right" class="price">{{ ((sales.amount))|number_format(2, '.', ' ') }} </td>
                    </tr>
                    {% if sales.Discount %}
                    <tr>
                        <td style="background:#fff;"></td>
                        <td width="20%" style="">{{ 'Discount Amount'|trans }}:</td>
                        <td width="20%" align="right" class="price">{{ ((sales.Discount))|number_format(2, '.', ' ') }}</td>
                    </tr>
                    {% endif %}
                    <tr>
                        <td width="60%" style="background:#fff"></td>
                        <td style="">{{ 'Tax'|trans }} {{ sales.taxRate|number_format(0, '.', ' ') }}% :</td>
                        <td align="right" class="price">{{ sales.amountTax|number_format(2, '.', ' ') }}</td>
                    </tr>
                    <tr>
                        <td style="background:#fff; border-left:1px solid #eee;">{{ sales.comment }}</td>
                        <td style="background:#eee"><strong>{{ 'Total'|trans }}:</strong></td>
                        <td align="right" style="background:#eee" class="price"><strong>{{ ((sales.amountTotal))|number_format(2, '.', ' ') }}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br><br>
            <table style="background:#f5f5f5;" width="100%" cellspacing="0" cellpadding="0" border="0px" class="more-info">
                <tbody>
                    <tr><td><br></td></tr>
                    <tr>
                        <td colspan="2">
                            <table border="0px" width="100%" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <table width="100%" style="padding:10px; color:#666; font-size:16px;">
                                                <tr><td style="font-size:21px;line-height:25px; color:#000; font-weight:300; text-transform:uppercase;" width="100" valign="middle"><b>Начин на плащане</b></td></tr>
                                                <tr><td width="100" valign="middle">{% if sales.paymentMethod.name %}
                                                    {{ sales.paymentMethod.parent.name  }} › {{ sales.paymentMethod.name }}
                                                {% elseif sales.paymentMethod.parent.name %}
                                                    {{ sales.paymentMethod.parent.name  }}
                                                                        {% endif %}</td></tr>
                                                <tr><td width="100" valign="middle">{% if sales.paymentMethod.name %}{{  sales.paymentMethod.description|raw}} {% endif %}</td></tr>

                                            </table>
                                        </td>
                                        <td>
                                            <table border="0px" width="100%" style="padding:10px; color:#666; font-size:16px;margin-left: 80px;">
                                                <tr><td width="100" valign="middle"><b>{{ 'Created by'|trans }}:</b></td></tr>
                                                <tr><td width="100" valign="middle">{{ sales.createdBy.names|default('-') }}</td></tr>
                                                <tr><td width="100" valign="middle"><b>{{ 'Created date'|trans }}:</b></td></tr>
                                                <tr><td width="100" valign="middle">
                                                {% set day = sales.created|date('d') %}
                                                {% set month = sales.created|date('F') %}
                                                {% set year = sales.created|date('Y') %}
                                                {{ '%s %s %s'|format(day, month|trans, year) }}</td></tr>
                                                {% if sales.dueDate %}
                                                <tr><td width="100" valign="middle"><b>{{ 'Due date'|trans }}:</b></td></tr>
                                                <tr><td width="100" valign="middle">
                                                    {% set day = sales.dueDate|date('d') %}
                                                    {% set month = sales.dueDate|date('F') %}
                                                    {% set year = sales.dueDate|date('Y') %}
                                                    {{ '%s %s %s'|format(day, month|trans, year) }}</td></tr>
                                                {% endif %}
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br><br>
    </body>
</html>`;
    this.payment = `<!DOCTYPE HTML>
<html>
    <head>
        <meta http-equiv="Content-Type" content="charset=utf-8" />
        <style>

            * {
                font-family: "Times New Roman";
                font-size:17px;
            }

            .invoice-title{
                width:48%;
                padding:0
            }
            .invoice-title > h1{
                font-size:32px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px;
            }
            .invoice-title > p{
                font-weight:400; margin:10px 0; font-size:21px; text-transform:uppercase; color:#ccc;
            }
            .invoice{
                width:48%;font-size:28px;letter-spacing:1px;font-weight:400;text-align:left;
            }
            .invoice p{
                font-size:25px;
                line-height:25px;
                margin:0px;
                padding:0px;
            }
            .first{
                margin:0px;
            }
            .first > table tr > td,
            .last > table tr > td{
                color:#666; line-height:25px;background:#f5f5f5; padding:0 10px;
            }

            #details{
                border:1px solid #ddd;border-bottom:0px;
            }
            #details > thead > tr{
                background:#dedede;
            }
            #details > thead > tr > th{
                text-align: left; font-weight:400; line-height:25px; border-style:solid;
                border-width:0 1px 0 0px; border-color:#fff; padding:0 10px; font-size:14px;
            }
            #details > tbody > tr > td{
                padding:0 10px; font-weight:400; line-height:25px; border-style:solid;
                border-width:0 1px 1px 0; border-color:#ddd #fff #ddd #fff;
            }
            #details > tbody > tr > td.red,
            #total > tbody > tr > td.red{
                color:#ed1b2f;
            }
            #details > tbody > tr > td.price,
            #total > tbody > tr > td.price{
                text-align:right;
            }
            #total{
                border:1px solid #ddd; background:#f5f5f5; border-bottom:0px;
            }
            #total > tbody > tr > td{
                padding:0 10px; font-weight:400; line-height:25px; border-style:solid;
                border-width:0 1px 1px 0; border-color:#ddd
            }
            .footer > span{
                font-style:normal;
                font-size:17px;
                line-height:25px;
                margin:0 5px;
                color:#444;
            }
            .footer > span > strong{
                font-size:19px;
            }
            .footer{
                text-align:left;
                position: absolute;
                bottom:25px;
            }

        </style>
    </head>
    <body style="width:1024px; margin:0 auto;" >
        <table  width="100%" border="0px">
            <thead>
                <tr>
                    <th align="left" width="49%">
                    </th>
                    <th align="left" width="2%">
                    </th>
                    <th align="right" width="45%">
                        <img src=".{{ share_image(company.logo, null, null, db) }}" width="180px"/>
                    </th>
                </tr>
            </thead>
        </table>

{% if payment.form.type  == 'payment transfer'%}
    <table width="100%" cellspacing="0" cellpadding="0" border="0px" class="more-info">
        <tbody>
            <tr>
                <td colspan="2">
                    <table border="0px" width="100%" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr>
                                <th class="invoice-title" align="left">
                                    <h1 style="">
                                        {% if payment.form.type  == 'payment transfer' %}
                                            {{ 'Transfer order'|trans }}
                                        {% endif %}
                                    </h1>
                                    <p style="">
                                        {{ payment.stage.name |trans }}
                                    </p>
                                </th>
                                <th class="invoice">
                                    <table border="0px" style="padding-left:15px;">
                                        <tr>
                                            <td><p>№</p></td>
                                            <td><p>{{ payment.documentNo }}</p></td>
                                        </tr>
                                        <tr>
                                            <td><p>{{ 'Date'|trans }}: </p></td>
                                            <td><p>{% set day = payment.date|date('d') %}
                                                    {% set month = payment.date|date('F') %}
                                                    {% set year = payment.date|date('Y') %}
                                                    {{ '%s %s %s'|format(day, month|trans, year) }}</p></td>
                                        </tr>
                                    </table>
                            </tr>
                        </tbody>
                    </table>
                    <div class="body">
                        <table  width="100%" cellspacing="0" cellpadding="0" border="0px">
                            <tbody>
                                <tr>
                                    <td colspan="2">
                                        <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="info">
                                            <thead>
                                                <tr>
                                                    <th width="40%" align="center" class="first"></th>
                                                    <th width="40%" align="center" class="last"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="last">
                                                    <td class="first" >
                                                        <table border="0px" style="padding:35px 0 15px 0;" width="100%" cellspacing="0" cellpadding="0">
                                                            <tr>
                                                                <td style="background: #fff;" valign="middle"><span style="border-bottom:1px solid #fff;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'From'|trans }}</span></td>
                                                                <td style="background: #fff;" valign="middle"></td>
                                                                <td style="background: #fff;" valign="middle"></td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ payment.parent.paymentMethod.name }}</strong></td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td class="last">
                                                        <table border="0px" style="padding:35px 0 15px 0; padding-left: 20px;" width="100%" cellspacing="0" cellpadding="0">
                                                            <tr>
                                                                <td style="background: #fff;" valign="middle"><span style="border-bottom:1px solid #fff;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'To'|trans }}</span></td>
                                                                <td style="background: #fff;" valign="middle"></td>
                                                                <td style="background: #fff;" valign="middle"></td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ payment.paymentMethod.name }}</strong></td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br><br>
                        <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="details"  style="background:#f5f5f5;">
                            <thead>
                                <tr>
                                    <th width="30%">{{ 'Comment'|trans }}</th>
                                    <th width="20%">{{ 'Amount'|trans }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {% for line in payment.lines %}
                                    <tr{% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                                        <td>{{ line.comment }}</td>
                                        <td>{{ line.amount|number_format(0, '.', '') }}</td>
                                    </tr>
                                {% endfor %}
                            </tbody>
                        </table>
                        <br><br>
                        <table  width="100%" style="color:#666; font-size:16px;">
                            <tbody>
                                <tr>
                                    <td colspan="2">
                                        <table border="0px" width="100%" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <table border="0px" width="100%"  style="padding:10px; color:#666; font-size:16px; background:#f5f5f5;">
                                                            <tr><td width="100" valign="middle"><strong>{{ 'Issued by'|trans }}:</strong></td></tr>
                                                            <tr><td width="100" valign="middle">{{ payment.createdBy.names|default('-') }}</td></tr>
                                                            <tr><td width="100" valign="middle"><strong>{{ 'Date'|trans }}:</strong></td></tr>
                                                            <tr><td width="100" valign="middle"> {{ payment.date|date("d/m/Y") }}</td></tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr><td><br></td></tr>
                            </tbody>
                        </table>

                    {% elseif payment.form.type  == 'payment revision'  %}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0px" class="more-info">
                            <tbody>
                                <tr>
                                    <td colspan="2">
                                        <table border="0px" width="100%" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <th class="invoice-title" align="left">
                                                        <h1 style="">
                                                            {% if payment.form.type == 'payment revision' %}
                                                                {{ 'Revision order'|trans }}
                                                            {% endif %}
                                                        </h1>
                                                        <p style="">
                                                            {{ payment.stage.name |trans }}
                                                        </p>
                                                    </th>
                                                    <th class="invoice">
                                                        <table border="0px" style="padding-left:15px;">
                                                            <tr>
                                                                <td><p>№</p></td>
                                                                <td><p>{{ payment.documentNo }}</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td><p>{{ 'Date'|trans }}: </p></td>
                                                                <td><p>{% set day = payment.date|date('d') %}
                                                                        {% set month = payment.date|date('F') %}
                                                                        {% set year = payment.date|date('Y') %}
                                                                        {{ '%s %s %s'|format(day, month|trans, year) }}</p></td>
                                                            </tr>
                                                        </table>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="details"  style="background:#f5f5f5;">
                                            <thead>
                                                <tr>
                                                    <th width="30%">{{ 'Comment'|trans }}</th>
                                                    <th width="20%">{{ 'Amount'|trans }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {% for line in payment.lines %}
                                                    <tr{% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                                                        <td>{{ line.comment }}</td>
                                                        <td>{{ line.amount|number_format(0, '.', '') }}</td>
                                                    </tr>
                                                {% endfor %}
                                            </tbody>
                                        </table>
                                        <table style="background:#f5f5f5;" width="100%" cellspacing="0" cellpadding="0" border="0px" class="more-info">
                                            <tbody>
                                                <tr><td><br></td></tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <table border="0px" width="100%" cellspacing="0" cellpadding="0">
                                                            <tbody>
                                                                <tr>
                                                            <table border="0px" width="100%" style="padding:10px; color:#666; font-size:16px;">
                                                                <td>
                                                                <tr><td style="font-size:21px;line-height:25px; color:#000; font-weight:300; text-transform:uppercase;" width="100" valign="middle"><b>Начин на плащане</b></td></tr>
                                                                <tr><td width="100" valign="middle"><b>{{payment.paymentMethod.name | trans}}</b></td></tr>
                                                                </td>
                                                            </table>
                                                            <td>
                                                                <table width="100%" style="padding:10px; color:#666; font-size:16px;">
                                                                    <tr><td width="100" valign="middle"><b>Съставител:</b></td></tr>
                                                                    <tr><td width="100" valign="middle">{{ payment.createdBy.names|default('-') }}</td></tr>
                                                                    <tr><td width="100" valign="middle"><b>Дата:</b></td></tr>
                                                                    <tr><td width="100" valign="middle">
                                                                            {% set day = payment.date|date('d') %}
                                                                            {% set month = payment.date|date('F') %}
                                                                            {% set year = payment.date|date('Y') %}
                                                                            {{ '%s %s %s'|format(day, month|trans, year) }}</td></tr>
                                                                </table>
                                                            </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr><td><br></td></tr>
                            </tbody>
                        </table>
                        {% else %}

                            <table  width="100%" border="0px">
                                <thead>
                                    <tr>
                                        <th class="invoice-title" align="left">
                                            <h1 style="">
                                                {{ 'Payment'|trans }}
                                            </h1>
                                            <p style="">
                                                {{ payment.stage.name |trans }}
                                            </p>
                                        </th>
                                        <th class="invoice">
                                            <table border="0px" style="padding-left:15px;">
                                                <tr>
                                                    <td><p>№</p></td>
                                                    <td><p>{{ payment.documentNo }}</p></td>
                                                </tr>
                                                <tr>
                                                    <td><p>{{ 'Date'|trans }}</p></td>
                                                    <td><p>{% set day = payment.date|date('d') %}
                                                            {% set month = payment.date|date('F') %}
                                                            {% set year = payment.date|date('Y') %}
                                                            {{ '%s %s %s'|format(day, month|trans, year) }}</p></td>
                                                </tr>
                                            </table>
                                    </tr>
                                </thead>
                            </table>
                            <div class="body">
                                <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td colspan="2">
                                                <table width="100%" cellspacing="0" cellpadding="0" id="info">
                                                    <thead>
                                                        <tr>
                                                            <th width="40%" align="center" class="first"></th>

                                                            <th width="40%" align="center" class="last"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class="last">
                                                            <td class="first" >
                                                                <table style="padding:5px 5% 15px 0" width="95%" cellspacing="0" cellpadding="0">
                                                                    <tr>
                                                                        <td style="background: #fff;" valign="middle"><span style="border-bottom:1px solid #fff;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">
                                                                                {% if payment.amount < 0 %}
                                                                                    {{ 'Supplier'|trans }}
                                                                                {% elseif payment.amount  >= 0 %}
                                                                                    {{ 'Client'|trans }}
                                                                                {% endif %}
                                                                            </span></td>
                                                                        <td style="background: #fff;" valign="middle"></td>
                                                                        <td style="background: #fff;" valign="middle"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px;">{{ financialInformation.name }}</strong></td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="middle">{{ 'Address'|trans }}:</td>
                                                                        <td colspan="2" valign="middle"><strong>{{ financialInformation.city }}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="middle"></td>
                                                                        <td colspan="2" valign="top" style="height:65px;"><strong>{{ financialInformation.address1 }}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="middle">{{ 'Company Id'|trans }}</td>
                                                                        <td colspan="2" valign="middle"><strong>{{ financialInformation.uic }}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="middle">{{ 'Company VAT' |trans }}:</td>
                                                                        <td colspan="2" valign="middle"><strong>{{ financialInformation.vat }}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="padding-bottom:25px" valign="middle">{{ 'Responsible Person'|trans }}:</td>
                                                                        <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ financialInformation.responsiblePerson }}</strong></td>
                                                                    </tr>

                                                                </table>
                                                            </td>
                                                            <td class="last">
                                                                <table style="padding:5px 0 15px 2.5%;" width="100%" cellspacing="0" cellpadding="0">
                                                                    <tr>
                                                                        <td style="background: #fff;" valign="middle"><span style="border-bottom:1px solid #fff;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">
                                                                          {% if payment.amount >= 0 %}
                                                                              {{ 'Supplier'|trans }}
                                                                          {% elseif payment.amount  < 0  %}
                                                                              {{ 'Client'|trans }}
                                                                          {% endif %}
                                                                            </span></td>
                                                                        <td style="background: #fff;" valign="middle"></td>
                                                                        <td style="background: #fff;" valign="middle"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ vendorFinancialInformation.name }}</strong></td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="middle">{{ 'Address'|trans }}:</td>
                                                                        <td colspan="2" style="width:100%;" valign="middle"><strong>{{ vendorFinancialInformation.city }}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="middle"></td>
                                                                        <td colspan="2" valign="top" style="height:65px;"><strong>{{ vendorFinancialInformation.address1 }}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="middle">{{ 'Company Id'|trans }}:</td>
                                                                        <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.uic }}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="middle">{{ 'Company VAT' |trans }}:</td>
                                                                        <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.vat }}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="padding-bottom:25px" valign="middle">{{ 'Responsible Person'|trans }}:</td>
                                                                        <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ vendorFinancialInformation.responsiblePerson }}</strong></td>
                                                                    </tr>
                                                                </table>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table><br><br>
                                <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="details">
                                    <thead>
                                        <tr>
                                            <th width="10%" style="border-width:0 1px 0 0;">№</th>
                                            <th width="30%" style="border-width:0 0 0 0px;text-align:left;">{{'Document' |trans}}</th>
                                            <th width="30%" style="border-width:0 0 0 0px;text-align:left;">{{'Comment' |trans}}</th>
                                            <th width="30%" style="border-width:0 0 0 0px;text-align:right;">{{ 'Amount'|trans }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {%set no=0%}
                                        {% for line in payment.lines %}
                                            {%if not line.deleted%}
                                                {%set no=no+1%}
                                                <tr{% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                                                    <td class="price">{{ no }}</td>
                                                    <td style="text-align:left;">
                                                        {% if line.invoice %}
                                                            {{ 'Invoice' |trans }} {{ line.invoice.documentNo }}
                                                        {% elseif line.sale%}
                                                            {{ 'Sale' |trans }} {{ line.sale.documentNo }}
                                                        {% elseif line.expense %}
                                                            {{ 'Expense' |trans }} {{ line.expense.documentNo }}
                                                        {% endif %}
                                                    </td>
                                                    <td style="text-align:left;">{{ line.comment }}</td>
                                                    <td style="text-align:right;">{{ line.amount|number_format(2, '.', ' ') }}</td>
                                                </tr>
                                            {%endif%}
                                        {% endfor %}

                                    </tbody>
                                </table>
                                <br><br>
                                <table  width="100%" cellspacing="0" cellpadding="0" id="total">
                                    <tbody>
                                        <tr>
                                            <td width="20%" style="">{{'Amount' | trans}}:</td>
                                            <td width="20%" align="right">{{ payment.amount|number_format(2, '.', ' ') }} {{payment.currency.name}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br><br>
                                <table style="background:#f5f5f5;" width="100%" cellspacing="0" cellpadding="0" border="0px" class="more-info">
                                    <tbody>
                                        <tr><td><br></td></tr>
                                        <tr>
                                            <td colspan="2">
                                                <table border="0px" width="100%" cellspacing="0" cellpadding="0">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <table border="0px" width="100%" style="padding:10px; color:#666; font-size:16px;">
                                                                    <td>
                                                                    <tr><td style="font-size:21px;line-height:25px; color:#000; font-weight:300; text-transform:uppercase;" width="100" valign="middle"><b>{{'Payment Method' | trans}}</b></td></tr>
                                                                    <tr><td width="100" valign="middle"><b>{{payment.paymentMethod.name | trans}}</b></td></tr>
                                                            </td>
                                                </table>
                                            </td>

                                            <td>
                                                <table width="100%" style="padding:10px; color:#666; font-size:16px;">
                                                    <tr><td width="100" valign="middle"><b>Съставител:</b></td></tr>
                                                    <tr><td width="100" valign="middle">{{ payment.createdBy.names|default('-') }}</td></tr>
                                                    <tr><td width="100" valign="middle"><b>Дата:</b></td></tr>
                                                    <tr><td width="100" valign="middle">
                                                            {% set day = payment.date|date('d') %}
                                                            {% set month = payment.date|date('F') %}
                                                            {% set year = payment.date|date('Y') %}
                                                            {{ '%s %s %s'|format(day, month|trans, year) }}</td></tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                        <tr><td><br></td></tr>
                      <br><br>
                      {% endif %}


                <div class="footer">
                    <p></p>
                </div>
                </body>
                </html>`;

    this.report = `<!DOCTYPE HTML>
<html>
    <head>
        <meta http-equiv="Content-Type" content="charset=utf-8" />
    </head>
    <table>
        <tr>
            {%for header in headers%}
                <th>
                    {{header}}
                </th>
            {%endfor%}
        </tr>
        {%for row in rows%}
            <tr>
                {%for value in row%}
                    <td>
                        {{value}}
                    </td>
                {%endfor%}
            </tr>
        {%endfor%}
    </table>
</html>`
    
        this.onClick = this.onClick.bind(this);
    }
    
    onClick() {
        let type = this.props.formData.form.initializeFromState2.values.type;
        let template = '';
        if (type=='template_document'){
                template = this.doc;
            } else if (type=='template_invoice') {
                template = this.invoice;
            } else if (type=='template_sales') {
                template = this.sales;
            } else if (type=='template_contract') {
                template = this.quote;
            } else if (type=='template_inventory') {
                template = this.receipt;
            } else if (type=='template_payment') {
                template = this.payment;
            } else if (type=='template_purchase') {
                template = this.purchase;
            }else if(type=='template_report'){
                template = this.report;
            } else {
                template = `<p>Please, select template type first</p>`;
            }
        
        //this.props.formData.form.initializeFromState2.values.body = template;
        
        this.props.change('body', template);
    }

    render() {
        return (
            <div>
                <input id="templates-add" 
                title="CAUTION: this button will remove all your changes and add a sample template in the body area below!" 
                type="button" 
                value="</>"
                onClick={this.onClick}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    formData: state
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(InsertTemplate);
