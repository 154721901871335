import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import connect from "react-redux/es/connect/connect";
import {plainTranslate} from "../common/translate";
import {Link} from 'react-router-dom';
import MenuLoader from './menu-loader';
import MenuMobile from '../sidebar/menu-mobile';
import { Menu } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import "./sidebarV2.css";
import { withRouter } from 'react-router-dom'
import {
    onSidebarMount,
    onSidebarChange
} from '../../actions/actions';

const { SubMenu } = Menu;

const SidebarV2 = (props, state) => {
  function handleClick(value) {
    props.history.push(value);
  }
    
  function toggleCollapsed(){
    props.onSidebarChange(props.db, props.settings.sidebar === 'nav-collapse' ? 'nav-collapse closed' : 'nav-collapse');
  };

  useEffect(() => {
    props.onSidebarMount('/'+props.db+'/api/settings/sidebar').then(response =>{
      let sidebar = document.getElementById('sidebar');
      let menu = document.getElementById('sidebar-menu');
      if(sidebar && menu){
        if(sidebar.offsetHeight > menu.offsetHeight){
          menu.style.height = "100%";
          menu.lastChild.style.position = "absolute";
          menu.lastChild.style.bottom = "15px";
        }else{
          sidebar.style.height = "92vh";
        }
      }
    })}, []);

  let menuIconsTheme = {};
  let closeMenuTheme = {};

  if (props.settings.firstColor) {

      menuIconsTheme = {
          background: '-webkit-gradient(linear, left top, right top, from(' + props.settings.firstColor + '), to(' + props.settings.secondColor + '))',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
      };

      closeMenuTheme = {
          background: '-webkit-gradient(linear, left top, right top, from(' + props.settings.firstColor + '), to(' + props.settings.secondColor + '))',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
      };
  }
  let collapsed = false;
  if(props.settings.sidebar === 'nav-collapse closed'){
    collapsed = true;
  }
  //let collapsed = props.settings.sidebar === 'nav-collapse' ? false : true;
  let json = props.settings.customMenu ? props.settings.customMenu : [];
  return (<div className={'sidebar' + (collapsed ? ' sidebar-collapsed' : '')} id="sidebar">
        {props.settings.sidebar &&
          <Menu className={'purple-menu'} id={'sidebar-menu'} mode="vertical" inlineCollapsed={collapsed}>
          {json.map((option,key) => {
            if(option.children && option.children.length > 0){
              return <SubMenu popupClassName="submenu-ui" key={"sub"+key} title={plainTranslate(props.settings.locale, option.label)} icon={option.icon ? <i style={menuIconsTheme} className={option.icon}></i> : null}>
                {option.tags && 
                  <div className="tags" style={{marginTop: 20, width: 220, display: 'flex',flexWrap: 'wrap'}}>
                    {option.tags.map(tag => {
                      return <Link className="draggable" id="Dashboard" to={tag.value} title={plainTranslate(props.settings.locale, tag.label)} style={{marginRight: 5}}>{plainTranslate(props.settings.locale, tag.label)}</Link>
                    })}
                  </div>
                }
                {option.children.map((subcategory,key2) =>{
                  if(subcategory.children && subcategory.children.length > 0){
                    return <SubMenu popupClassName="submenu-ui" key={"sub-3-"+subcategory.label} title={plainTranslate(props.settings.locale, subcategory.label)} icon={subcategory.icon ? <i style={menuIconsTheme} className={subcategory.icon}></i> : null}>
                      {subcategory.children.map((subcategory2) => {
                       return <Menu.ItemGroup className={subcategory2.category ? '' : 'hidе-group-label'} title={plainTranslate(props.settings.locale, subcategory2.label)}><Menu.Item key={"sub-4-"+key+" "+subcategory2.value} icon={subcategory2.icon ? <i style={menuIconsTheme} className={subcategory2.icon}></i> : null}><Link to={subcategory2.value}>{plainTranslate(props.settings.locale, subcategory2.label)}</Link>{subcategory2.isPrintable && <Link to={subcategory2.isPrintable} style={{position: "absolute", width: "20%"}}><i className="fa fa-print" style={{marginLeft: 20}}></i></Link>}</Menu.Item></Menu.ItemGroup>
                      })}
                    </SubMenu>
                  }
                  else{
                    return <Menu.Item key={"sub-2-"+ option.label+'-'+subcategory.label} icon={subcategory.icon ? <i style={menuIconsTheme} className={subcategory.icon}></i> : null}><Link to={subcategory.value}>{plainTranslate(props.settings.locale, subcategory.label)}</Link></Menu.Item>
                  }
                })}
              </SubMenu>
            }else{
              return <Menu.Item key={key} className={option.className} icon={option.icon ? <i style={menuIconsTheme} className={option.icon}></i> : null}><Link to={option.value}>{plainTranslate(props.settings.locale, option.label)}</Link></Menu.Item>
            }
          })}
          <Menu.Item key="collapse" className="menu-expand" onClick={toggleCollapsed}>{collapsed ? <MenuUnfoldOutlined /> : <span className="navbar-logo-collapse"><img src={"https://composity.bg/images/main_logo.png"}></img><MenuFoldOutlined style={{marginLeft: 35}}/></span>}</Menu.Item>
          </Menu>
        }
        
        {
          !props.settings.sidebar &&
          <div style={{marginTop: '0px', marginLeft: '0px', textAlign: 'center'}}>
              <MenuLoader image={'/bundles/base/images/sidebar_dark.png'}/>
          </div>
        }
    </div>
  );
}


const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
  onSidebarMount: (route) => dispatch(onSidebarMount(route)),
  onSidebarChange: (db, sidebar) => dispatch(onSidebarChange(db, sidebar))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarV2));