import React, { useEffect, useState } from 'react';
import Translate, { plainTranslate } from '../common/translate';
import { connect } from 'react-redux';
import client from "../../actions/client";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table } from 'antd';

/**
* @author Nevyana Mihaleva
* @function Asset Profile
**/

const AssetProfile = (props) => {
    const [fetching, setFetching] = useState(false);
    const [asset, setAsset] = useState(null);
    const [ledgerLinesDeprecations, setLedgerLinesDeprecations] = useState(null);
    const [ledgerLinesOther, setLedgerLinesOther] = useState(null);
    const [dateFormat, setDateFormat] = useState('DD.M.YYYY');
    const [currency, setCurrency] = useState(null);
    const [active, setActive] = useState('deprecations');
    const [columns, setColumns] = useState([
        {
            key: 'actions',
            dataIndex: 'actions',
            title: '',
            render: (text, record) => {                                 
                return (
                    <span>
                        <Link to={record.editURL} title={plainTranslate(props.settings.locale, 'Edit')}><i className="fa fa-pencil-square-o"></i></Link>
                    </span>
                )
            },
            width: 30,
            align: 'center',
        },
        {
            'dataIndex': 'ledger',
            'title': plainTranslate(props.settings.locale, 'Ledger'),
            'width': 160,
        },
        {
            'dataIndex': 'no',
            'title': plainTranslate(props.settings.locale, 'No'),
            'width': 100,
        },
        {
            'dataIndex': 'reference',
            'title': plainTranslate(props.settings.locale, 'Reference'),
            'width': 150,
        },
        {
            'dataIndex': 'date',
            'title': plainTranslate(props.settings.locale, 'Date'),
            'width': 120,
        },

        {
            'dataIndex': 'chartOfAccount',
            'title': plainTranslate(props.settings.locale, 'Chart Of Account'),
            'width': 150,
        },
        {
            'dataIndex': 'amountOriginal',
            'title': plainTranslate(props.settings.locale, 'Amount Original'),
            'width': 120,
        },
        {
            'dataIndex': 'amountLocal',
            'title': plainTranslate(props.settings.locale, 'Amount Local'),
            'width': 120,
        },
        {
            'dataIndex': 'debitCredit',
            'title': plainTranslate(props.settings.locale, 'Debit/Credit'),
            'width': 120,
        },
        {
            'dataIndex': 'description',
            'title': plainTranslate(props.settings.locale, 'Description'),
            'width': 200,
        },
    ])

    useEffect(() => {
        setActive(localStorage.getItem('activeAssetProfileTab'));
    }, [])

    useEffect(() => {
        setFetching(true);
        if (props.match.params && props.match.params.id) {
            client
                .get(`/${props.match.params.db}/api/assets/${props.match.params.id}/profile`)
                .then((response) => {
                    setAsset(response.data.asset);
                    setCurrency(response.data.currency);
                    setLedgerLinesDeprecations(response.data.ledgerLinesDeprecations);
                    setLedgerLinesOther(response.data.ledgerLinesOther);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        setDateFormat(props.settings.dateFormat);
    }, [props.match.params.id]);

    const changeTab = (tab) => {
        localStorage.setItem('activeAssetProfileTab', tab);
        setActive(tab);
    }

    return (
        <div>
            {fetching && asset &&
                <>
                    <div className="invoice-profile item-profile">
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                            <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb">
                                <Link to={"/" + props.match.params.db + "/accounting/asset-v2"}>
                                    <i className="ion-ios-arrow-back"></i>{plainTranslate(props.settings.locale, 'Assets')}
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-4 col-xs-12 no-gutter">
                                <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information">
                                    <div className="account-profile-information-general">
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            {asset.deleted &&
                                                <React.Fragment>
                                                    <div className="col-lg-10 col-md-10 col-xs-10 no-gutter">
                                                        <h5 className="col-md-12 col-xs-12 infoMessage text-red"><i className="fa fa-ban" aria-hidden="true"></i> {plainTranslate(props.settings.locale, "Document is deleted!")}</h5>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </React.Fragment>
                                            }
                                            {asset.type &&
                                                <h3 className="invoice-no">{plainTranslate(props.settings.locale, asset.type)}</h3>
                                            }
                                            {asset.code &&
                                                <h3 className="invoice-no">{asset.code}</h3>
                                            }
                                            {asset.name &&
                                                <h3 className="invoice-no">{asset.name}</h3>
                                            }
                                        </div>
                                        {asset.location &&
                                            <div className="line col-md-12 col-xs-12 no-gutter">
                                                <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Location')}:</span></div>
                                                <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                    <div>
                                                        <span style={{ textTransform: 'capitalize' }}>{plainTranslate(props.settings.locale, asset.location)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <h3>{plainTranslate(props.settings.locale, 'Accounting Depreciations')}</h3>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Acquisition Date')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.assetDate ? moment((asset.assetDate).replaceAll('.', '/'), 'DD/MM/YYYY').format(dateFormat) : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title">
                                                <span>{plainTranslate(props.settings.locale, 'Acquisition Value')}:</span>
                                            </div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="amount">
                                                    <span className="font-weight-bold">{parseFloat(asset.valueAcquisition).toFixed(2)} {currency}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Depreciation Value Initial')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.valueDepreciationInitial ? parseFloat(asset.valueDepreciationInitial).toFixed(2) : 0.00} {currency}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Depreciation Value')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.valueDepreciation ? parseFloat(asset.valueDepreciation).toFixed(2) : 0.00} {currency}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Remaining Value')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.valueRemaining ? parseFloat(asset.valueRemaining).toFixed(2) : 0} {currency}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Depreciation Rate (Monthly)')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.depreciationRate ? parseFloat(asset.depreciationRate/12).toFixed(2) : 0} %
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Depreciation Value (Monthly)')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.valueDepreciationMonthly ? parseFloat(asset.valueDepreciationMonthly).toFixed(2) : 0} {currency}
                                                </div>
                                            </div>
                                        </div>           
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Start Period')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.periodStart}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'End Period')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.periodEnd}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Last Depreciation Period')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.periodLastDepreciation}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Next Depreciation Period')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.periodNextDepreciation}
                                                </div>
                                            </div>
                                        </div>
                                                                                
                                        <h3>{plainTranslate(props.settings.locale, 'Таx Depreciations')}</h3>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Depreciation Rate (Monthly)')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.depreciationRateTax ? parseFloat(asset.depreciationRateTax).toFixed(2) : 0} %
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Depreciation Amount')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.valueDepreciationTax ? parseFloat(asset.valueDepreciationTax).toFixed(2) : 0} {currency}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Tax Start Period')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.periodStartTax}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Tax End Period')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.periodEndTax}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Tax Last Depreciation Period')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.periodLastTaxDepreciation}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Tax Next Depreciation Period')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.periodNextTaxDepreciation}
                                                </div>
                                            </div>
                                        </div>
                                        <h3>{plainTranslate(props.settings.locale, 'Chart of account')}</h3>
                                        {asset.account &&
                                            <div className="line col-md-12 col-xs-12 no-gutter">
                                                <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Chart of account Asset')}:</span></div>
                                                <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                    <div className="created">
                                                        {asset.account}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {asset.accountAcquisition &&
                                            <div className="line col-md-12 col-xs-12 no-gutter">
                                                <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Chart of account Acquisition')}:</span></div>
                                                <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                    <div className="created">
                                                        {asset.accountAcquisition}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {asset.accountExpense &&
                                            <div className="line col-md-12 col-xs-12 no-gutter">
                                                <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Chart of account Expense')}:</span></div>
                                                <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                    <div className="created">
                                                        {asset.accountExpense}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {asset.accountExpenseDistributed &&
                                            <div className="line col-md-12 col-xs-12 no-gutter">
                                                <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Chart of account Expense Distributed')}:</span></div>
                                                <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                    <div className="created">
                                                        {asset.accountExpenseDistributed}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {asset.accountExpense &&
                                            <div className="line col-md-12 col-xs-12 no-gutter">
                                                <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Chart of account Deprecation')}:</span></div>
                                                <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                    <div className="created">
                                                        {asset.accountDeprecation}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Created')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {asset.created && moment((asset.created).replaceAll('.', '/'), 'DD/MM/YYYY').format(dateFormat)} by {asset.createdBy}
                                                </div>
                                            </div>
                                        </div>
                                        {(asset.modified) &&
                                            <div className="line col-md-12 col-xs-12 no-gutter">
                                                <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Modified')}:</span></div>
                                                <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                    <div className="created">
                                                        {asset.modified && moment((asset.modified).replaceAll('.', '/'), 'DD/MM/YYYY').format(dateFormat)} by {asset.modifiedBy}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {asset.user &&
                                            <div className="line col-md-12 col-xs-12 no-gutter">
                                                <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(props.settings.locale, 'Owner')}:</span></div>
                                                <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                    <span>{asset.user}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-xs-12 no-gutter-m no-gutter-r">
                                <div className="col-lg-12 col-md-12 col-xs-12 account-profile-tabs">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutter sale-tabs">
                                        {window.innerWidth > 780 &&
                                            <button className={active == 'other' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => changeTab('other')}>
                                                {plainTranslate(props.settings.locale, 'Expenses')}
                                            </button>
                                        }
                                        <button className={active == 'deprecations' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => changeTab('deprecations')}>
                                            {plainTranslate(props.settings.locale, 'Value & Deprecations')}
                                        </button>
                                    </div>
                                    {active === "deprecations" &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            {ledgerLinesDeprecations && ledgerLinesDeprecations.length ?
                                                <Table
                                                    rowKey={record => record.id}
                                                    columns={columns}
                                                    dataSource={ledgerLinesDeprecations}
                                                    scroll={{ x: 1500, y: 650 }}
                                                    bordered={true}
                                                    style={{ border: 'none' }} />
                                                : <p>{plainTranslate(props.settings.locale, 'No deprecation')}</p>
                                            }
                                        </div>
                                    }
                                    {active === "other" &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            {ledgerLinesOther && ledgerLinesOther.length ?
                                                <Table
                                                    rowKey={record => record.id}
                                                    columns={columns}
                                                    dataSource={ledgerLinesOther}
                                                    scroll={{ x: 1500, y: 650 }}
                                                    bordered={true}
                                                    style={{ border: 'none' }} />
                                                : <p>{plainTranslate(props.settings.locale, 'No deprecation')}</p>
                                            }
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AssetProfile);
