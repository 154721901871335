import React from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';
import { onSelect2InputChange  } from '../../../actions/actions';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';
import Translate, {plainTranslate} from "../../common/translate";

class Select2 extends React.Component {

    constructor(props) {
        super(props);

        this.loadOptions = this.loadOptions.bind(this);
    }

    componentDidMount() {
        if ((!this.props.input.value && this.props.input.value !== 0 && this.props.input.value !== false) && ((!this.props.field.settings || !this.props.field.settings.emptyOption) || (this.props.field.settings && !this.props.field.settings.emptyOption)) && this.props.field.options.length > 0) {
            this.props.input.onChange(this.props.field.options[0].value);
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.input.value) !== JSON.stringify(this.props.input.value) && this.props.input.value === '') {
            this.props.input.onChange('');
            if(this.props.field.settings && !this.props.field.settings.emptyOption){
                this.props.input.onChange(this.props.field.options[0].value);
            }
        }
    }

    onChange(newValue, actionMeta) {

        let enableChange = true;

        if (this.props.field.id === 'stage' && this.props.formData.formProperties.initializeFromState2.isEdit && this.props.field.hasOwnProperty('connectedField')) {
            let ind = (this.props.input.name).indexOf('.' + this.props.field.id);
            let line = (this.props.input.name).substring(0, ind);
            let key = line.replace(/[^0-9]/g, '');

            if (this.props.formData.form.initializeFromState2.initial.lines[key] && newValue.position < this.props.formData.form.initializeFromState2.initial.lines[key].stagePosition) {
                enableChange = false;

                this.props.showMessage('Cannot change document Status backward.', true);
                setTimeout(() => this.props.hideMessage(), 7000);
            } else {
                enableChange = true;
            }

        }

        if (this.props.field.hasOwnProperty('disableField')) {
            this.props.fields.map((val, key) => {
                if (val.id == this.props.field.disableField && (newValue && newValue.value)) {
                    this.props.fields[key].isDisabled = true;
                }else{
                    this.props.fields[key].isDisabled = false;
                }
            })
        }

        if (newValue && enableChange === true) {
            this.props.input.onChange(newValue.value);

            if (this.props.field.hasOwnProperty('connectedField')) {
                client.post(this.props.field.connectedField.optionsUrl, {
                    body: newValue.value
                })
                    .then(response => response.data)
                    .then(response => {
                        if (this.props.field.connectedField.hasOwnProperty('dependency')) {
                            let fieldVal = 0;
                            let line;
                            let key;
                            let depVal = 1;
                            if (this.props.field.connectedField.isLine) {
                                let ind = (this.props.input.name).indexOf('.' + this.props.field.id);
                                line = (this.props.input.name).substring(0, ind);
                                key = line.replace(/[^0-9]/g, '');
                                depVal = this.props.formData.form.initializeFromState2.values.lines[key][this.props.field.connectedField.dependency];
                            } else {
                                depVal = this.props.formData.form.initializeFromState2.values[this.props.field.connectedField.dependency];
                            }
                            if (this.props.field.connectedField.calculation == 'divide') {
                                fieldVal = depVal / response;
                            } else if (this.props.field.connectedField.calculation == 'multiply') {
                                fieldVal = depVal * response;
                            } else if (this.props.field.connectedField.calculation == 'add') {
                                fieldVal = depVal + response;
                            } else if (this.props.field.connectedField.calculation == 'subtract') {
                                fieldVal = depVal - response;
                            }
                            if (line) {
                                this.props.change('lines[' + key + '][' + this.props.field.connectedField.field + ']', fieldVal);
                            } else {
                                this.props.change(this.props.field.connectedField.field, fieldVal);
                            }
                        } else {
                            if (response.id) {
                                this.props.change(this.props.field.connectedField.field, response.id ? response.id : null);
                                this.props.onChangeMainOptions(this.props.field.connectedField.field, { 'value': response.id, 'label': response.label }, this.props.field.connectedField.field);
                            } else {
                                if (this.props.field.connectedField.isLine) {
                                    let ind = (this.props.input.name).indexOf('.' + this.props.field.id);
                                    let line = (this.props.input.name).substring(0, ind);
                                    let key = line.replace(/[^0-9]/g, '');
                                    this.props.change('lines[' + key + '][' + this.props.field.connectedField.field + ']', response);
                                }
                            }
                            if (this.props.field.connectedField.additionalValue) {
                                this.props.change(this.props.field.connectedField.field + 'AdditionalValue', response);
                                if (response.id) {
                                    this.props.onChangeMainOptions(this.props.field.connectedField.field, { 'value': response.id, 'label': response.label }, 'billingData');
                                } else {
                                    this.props.change(this.props.field.connectedField.field, null);
                                    this.props.onChangeMainOptions(this.props.field.connectedField.field, null);
                                }

                            }
                        }
                    })
            }
            if (this.props.field.hasOwnProperty('connectedFields')) {
                let addVal = {};
                let jsonData = [];
                if (this.props.field.connectedFields.hasOwnProperty('dependency')) {
                    this.props.field.connectedFields.dependency.map(field => {
                        addVal[field] = this.props.formData.form.initializeFromState2.values[field];
                    })
                }

                if (this.props.field.connectedFields.hasOwnProperty('lineDependency')) {
                    this.props.field.connectedFields.lineDependency.map(field => {
                        let numb = (this.props.input.name).match(/^(\D*)(\d+)/)[2];
                        addVal[field] = this.props.formData.form.initializeFromState2.values.lines[numb][field];
                    })
                }
                addVal['body'] = newValue.value;
                jsonData.push(JSON.stringify(addVal));

                let line = '';
                if (this.props.field.connectedFields.isLine) {
                    let ind = (this.props.input.name).indexOf('.' + this.props.field.id);
                    line = (this.props.input.name).substring(0, ind);
                }

                let fields = this.props.field.connectedFields.fields;
                client.post(this.props.field.connectedFields.optionsUrl, addVal)
                    .then(response => response.data)
                    .then(response => {
                        fields.map(field => {
                            if (typeof (response[field]) == 'string') {
                                this.props.change(line + field, response[field] ? response[field] : null);
                                if (this.props.field.connectedFields.changeLineField) {
                                    if (this.props.formData.form && this.props.formData.form.initializeFromState2.values.lines) {
                                        this.props.formData.form.initializeFromState2.values.lines.map((line, key) => {
                                            this.props.change('lines[' + key + '][' + this.props.field.connectedFields.changeLineField + ']', response[this.props.field.connectedFields.changeLineField]);
                                        })
                                    }
                                }
                            } else {
                                if (this.props.field.connectedFields.isLine) {
                                    this.props.onChangeOptions(field, response[field], this.props.field.connectedFields.isLine);
                                } else if (response.id) {
                                    this.props.change(field, response.id ? response.id : null);
                                    this.props.onChangeMainOptions(field, { 'value': response.id, 'label': response.label }, field);
                                } else {
                                    this.props.change(field, response[field] ? response[field] : null);
                                }
                                if (typeof response[field] !== 'undefined' && response[field] && response[field].length > 0) {
                                    let i;
                                    for (i = 0; i < response[field].length; i++) {
                                        if (response[field][i].selected) {
                                            this.props.change(line + field, response[field][i].value ? response[field][i].value : null);
                                            break;
                                        }
                                    }
                                }
                                if (this.props.field.connectedFields.showHideField) {
                                    for (let i = 0; i < this.props.fields.length; i++) {
                                        if (this.props.fields[i]['id'] == this.props.field.connectedFields.showHideField) {
                                            if (this.props.field.connectedFields.hasOwnProperty('showHideAction')) {
                                                if (this.props.field.connectedFields.showHideAction == 'show') {
                                                    if (this.props.fields[i].hasOwnProperty('hide')) {
                                                        this.props.fields[i]['hide'] = response[this.props.field.connectedFields.showHideValue];
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if (this.props.field.id === "payId" && this.props.formData.form.initializeFromState2.values.hasOwnProperty("paymentMethodType")) {
                                showAToast(plainTranslate(this.props.settings.locale, 'Payment Method changed'), 'succSettings', 'success');
                            }
                        })
                    })
            }
            if (this.props.field.hasOwnProperty('changeOptionField') && this.props.field.changeOptionField !== null) {
                
                let addVal = {};
                let jsonData = [];
                if (this.props.field.changeOptionField.hasOwnProperty('dependency')) {
                    this.props.field.changeOptionField.dependency.map(field => {
                        addVal[field] = this.props.formData.form.initializeFromState2.values[field];
                    })
                }
                addVal['body'] = newValue.value;
                jsonData.push(JSON.stringify(addVal));
               
                client
                    .post(this.props.field.changeOptionField.optionsUrl, addVal)
                    .then(response => response.data)
                    .then(response => {
                        if (this.props.field.changeOptionField.group == 'lines') {
                            this.props.onChangeOptions(this.props.field.changeOptionField.field, response);
                        } else {
                            this.props.onChangeMainOptions(this.props.field.changeOptionField.field, response, this.props.field.changeOptionField.group);
                        }
                    })
            }
            if (this.props.field.hasOwnProperty('changeOptionFields')) {               
                this.props.field.changeOptionFields.map(item => {                  
                    if (item) {
                        let addVal = {};
                        let jsonData = [];
                        if (item.hasOwnProperty('dependency')) {
                            item.dependency.map(field => {
                                addVal[field] = this.props.formData.form.initializeFromState2.values[field];
                            })
                        }
                        addVal['body'] = newValue.value;
                        jsonData.push(JSON.stringify(addVal));
                        client.post(item.optionsUrl, addVal)
                        .then(response => response.data)
                        .then(response => {
                            if(Array.isArray(item.group)){
                                item.group.map(itemGroupField => {
                                    this.props.onChangeMainOptions(item.field, response, itemGroupField);
                                    let selected = response.find(o => o.selected == true);
                                   
                                    if (selected) {
                                        this.props.change(item.field, selected.value ? selected.value : null);
                                    } else {
                                        this.props.change(item.field, null);
                                    }
                                })
                            }else{
                                if (item.group == 'lines') {
                                    this.props.onChangeOptions(item.field, response);
                                } else {
                                    this.props.onChangeMainOptions(item.field, response, item.group);
                                    let selected = response.find(o => o.selected == true);
                                   
                                    if (selected) {
                                        this.props.change(item.field, selected.value ? selected.value : null);
                                    } else {
                                        this.props.change(item.field, null);
                                    }
                                }
                            }
                        })
                    }
                })
            }
            if (this.props.field.options.length > 0) {
                this.props.onNewOptions(this.props.field.options, newValue);
            }

            if (this.props.field.hasOwnProperty('changeLines')) {
                if (this.props.formData.form && this.props.formData.form.initializeFromState2.values.lines) {
                    this.props.formData.form.initializeFromState2.values.lines.map((line, key) => {
                        this.props.change('lines[' + key + '][' + this.props.field.changeLines + ']', newValue.value);
                    })
                }
                if (this.props.field.hasOwnProperty('lockFields')) {
                    this.props.field.lockFields.map(item => {
                        this.props.fields.map((val, key) => {
                            if (val.id == item) {
                                this.props.fields[key].isDisabled = true;
                            }
                        })
                    })
                }
            }

            if (this.props.field.hasOwnProperty('lockFields') && !(newValue instanceof Array)) {
                this.props.field.lockFields.map(item => {
                    this.props.fields.map((val, key) => {
                        if (val.id == item) {
                            this.props.fields[key].isDisabled = true;
                        }
                    })
                })
            }

        if (this.props.field.hasOwnProperty('changedFields')) {
            let addVal = {};

            addVal[this.props.input.name] = newValue.value;

            if (this.props.field.changedFields.hasOwnProperty('dependency')) {
                this.props.field.changedFields.dependency.map(field => {
                    addVal[field] = this.props.formData.form.initializeFromState2.values[field];
                })
            }

            addVal['field'] = this.props.input.name;

            let fields = this.props.field.changedFields.fields;
            client.post(this.props.field.changedFields.url, addVal)
                .then(response => response.data)
                .then(response => {
                    fields.map(field => {
                        let changeVal = response[field];

                        this.props.change(field, changeVal);
                    })
                })
        }

        } else {
            if (this.props.field.id !== 'stage') {
                this.props.input.onChange('');
            }
            if (this.props.field && this.props.field.connectedFields && this.props.field.connectedFields.fields) {
                this.props.field.connectedFields.fields.map(field => {
                    this.props.change(field, null);
                });
            }
        }

        if (this.props.field.hasOwnProperty('setDependencies')) {
            this.props.field.setDependencies.map(dependency => {
                this.props.change(dependency.field, newValue ? newValue[dependency.option] : null)
            });
        }

    }

    loadOptions(inputValue, callback) {
        if (!inputValue) inputValue = -1;
        if (this.props.field.id === 'reversalId' && this.props.formData.form.initializeFromState2?.values.accId !== undefined) {
            inputValue = inputValue + "&accId=" + this.props.formData.form.initializeFromState2.values.accId;
        }

        if (this.props.field.id === 'addrId') {
            if (this.props.formData.form.initializeFromState2?.values.accId !== undefined) {
                inputValue = inputValue + "&accId=" + this.props.formData.form.initializeFromState2.values.accId;
            }
            else if (this.props.formData.form.initializeFromState2?.values.custId !== undefined) {
                inputValue = inputValue + "&custId=" + this.props.formData.form.initializeFromState2.values.custId;
            }
        }

        let hasSettings = this.props.field.settings && this.props.field.settings.optionsUrl;

        clearTimeout(this.inputTimer);
        this.inputTimer = setTimeout(() => {
            this.props.onInputChange('initializeFromState2', this.props.field.id, inputValue, this.props.field.settings.optionsUrl)
                .then(response => {
                    let options = [];
                    if (response.payload && response.payload.options && response.payload.options.length) {
                        options = response.payload.options.map(option => {
                            return {
                                ...option,
                                'value': option.value,
                                'label': option.label
                            };
                        });
                    }
                    this.options = options;
                    if (options.length > 0) this.props.onNewOptions(options);
                    else if (this.props.field.options.length > 0) this.props.onNewOptions(this.props.field.options);

                    if (!hasSettings) {
                        let ops = this.props.field.options;
                        ops = ops.filter(item => item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);
                        callback(ops);
                    }
                    else callback(options ? options : this.props.field.options);
                });
        }, 1000);
    }

    render() {
        if (this.props.fields) {
            if (this.props.field.id === 'grpId') {
                this.props.fields.map(field => {
                    if (field.id === 'accId' && this.props.input.value.length > 0) {
                        field.isDisabled = true;
                    } else if (field.id === 'accId' && this.props.input.value.length === 0) {
                        field.isDisabled = false;
                    }
                })
            }
            if (this.props.field.id === 'accId') {
                this.props.fields.map(field => {
                    if (field.id === 'grpId' && this.props.input.value.length > 0) {
                        field.isDisabled = true;
                    } else if (field.id === 'grpId' && this.props.input.value.length === 0) {
                        field.isDisabled = false;
                    }
                })
            }
            if (this.props.field.id === 'itmId') {
                this.props.fields.map(field => {
                    if (field.id === 'catId' && this.props.input.value.length > 0) {
                        field.isDisabled = true;
                    } else if (field.id === 'catId' && this.props.input.value.length === 0) {
                        field.isDisabled = false;
                    }
                })
            }
            if (this.props.field.id === 'catId') {
                this.props.fields.map(field => {
                    if (field.id === 'itmId' && this.props.input.value.length > 0) {
                        field.isDisabled = true;
                    } else if (field.id === 'itmId' && this.props.input.value.length === 0) {
                        field.isDisabled = false;
                    }
                })
            }
            if (!this.props.field.isLine && this.props.field.id === 'locConsumption') {
                let indexes = null;
                let disableField = false;
                let val = null;

                if (this.props.formData.form && this.props.formData.form.initializeFromState2.values.lines) {
                    this.props.formData.form.initializeFromState2.values.lines.map(line => {
                        indexes = this.props.formData.form.initializeFromState2.values.lines.map((o, i) => line['itId'] === undefined || line['locConsumption'] === undefined || o.locConsumption === line['locConsumption'] ? i : '').filter(String);
                        if (indexes.length !== this.props.formData.form.initializeFromState2.values.lines.length) {
                            disableField = true;
                            this.props.input.value = null;
                        }
                        else {
                            if (line.hasOwnProperty('locConsumption') && line['locConsumption'] !== null && line['locConsumption'] !== '') {
                                val = line['locConsumption'];
                            } else {
                                val = false;
                            }
                        }
                    })

                    let stages = this.props.formData.form.initializeFromState2.values.lines.map((o, i) => (o.stageStatus != 4 && o.stageStatus != 6 && o.stageStatus != 8) ? i : '').filter(String);

                    if (stages.length !== this.props.formData.form.initializeFromState2.values.lines.length) {
                        disableField = true;
                    }
                    if (val !== false && val !== null) {
                        this.props.formData.form.initializeFromState2.values.locConsumption = val;
                    }
                    this.props.field.isDisabled = disableField;
                }
            }
            if (!this.props.field.isLine && this.props.field.id === 'locInProgress') {
                let indexes = null;
                let disableField = false;
                let val = null;

                if (this.props.formData.form && this.props.formData.form.initializeFromState2.values.lines) {
                    this.props.formData.form.initializeFromState2.values.lines.map(line => {
                        indexes = this.props.formData.form.initializeFromState2.values.lines.map((o, i) => line['itId'] === undefined || line['locInProgress'] === undefined || o.locInProgress === line['locInProgress'] ? i : '').filter(String);
                        if (indexes.length !== this.props.formData.form.initializeFromState2.values.lines.length) {
                            disableField = true;
                            this.props.input.value = null;
                        } else {
                            if (line.hasOwnProperty('locInProgress') && line['locInProgress'] !== null && line['locInProgress'] !== '') {
                                val = line['locInProgress'];
                            } else {
                                val = false;
                            }
                        }
                    })

                    let stages = this.props.formData.form.initializeFromState2.values.lines.map((o, i) => (o.stageStatus != 6 && o.stageStatus != 8) ? i : '').filter(String);

                    if (stages.length !== this.props.formData.form.initializeFromState2.values.lines.length) {
                        disableField = true;
                    }

                    if (val !== false && val !== null) {
                        this.props.formData.form.initializeFromState2.values.locInProgress = val;
                    }

                    this.props.field.isDisabled = disableField;
                }
            }
            if (!this.props.field.isLine && this.props.field.id === 'locProduction') {
                let indexes = null;
                let disableField = false;
                let val = null;

                if (this.props.formData.form && this.props.formData.form.initializeFromState2.values.lines) {
                    this.props.formData.form.initializeFromState2.values.lines.map(line => {
                        indexes = this.props.formData.form.initializeFromState2.values.lines.map((o, i) => line['itId'] === undefined || line['locProduction'] === undefined || o.locProduction === line['locProduction'] ? i : '').filter(String);
                        if (indexes.length !== this.props.formData.form.initializeFromState2.values.lines.length) {
                            disableField = true;
                            this.props.input.value = null;
                        } else {
                            if (line.hasOwnProperty('locProduction') && line['locProduction'] !== null && line['locProduction'] !== '') {
                                val = line['locProduction'];
                            } else {
                                val = false;
                            }
                        }
                    })

                    let stages = this.props.formData.form.initializeFromState2.values.lines.map((o, i) => (o.stageStatus != 6 && o.stageStatus != 8) ? i : '').filter(String);

                    if (stages.length !== this.props.formData.form.initializeFromState2.values.lines.length) {
                        disableField = true;
                    }

                    if (val !== false && val !== null) {
                        this.props.formData.form.initializeFromState2.values.locProduction = val;
                    }

                    this.props.field.isDisabled = disableField;
                }
            }
        }
        if (this.props.field.id === 'stornoReason') {
            if (this.props.formData.form && this.props.formData.form.initializeFromState2.values.isFiscalDevice === true && this.props.formData.form.initializeFromState2.values.reversalId !== null) {
                this.props.field.hide = false;
            } else {
                this.props.field.hide = true;
            }
        }

        if (this.props.field.id === 'batch' && this.props.field.hide === false) {
            if (this.props.formData.formProperties && this.props.formData.formProperties.initializeFromState2) {
                this.props.formData.formProperties.initializeFromState2.form.fields.map(field => {
                    if (field.id === 'lines') {
                        field.fields.map(line => {
                            if (line.id === 'itName' && line.hide === false) {
                                this.props.field.isDisabled = true;
                            }
                        })
                    }
                });
            }
        }
        if (this.props.field.isLine && this.props.field.id === 'locConsumption') {
            let ind = (this.props.input.name).indexOf('.' + this.props.field.id);
            let line = (this.props.input.name).substring(0, ind);
            let key = line.replace(/[^0-9]/g, '');

            let disableField = true;
            if (this.props.formData.form && this.props.formData.form.initializeFromState2.values.lines) {
                let line = this.props.formData.form.initializeFromState2.values.lines[key];
                if (line && line['stageStatus'] !== null) {
                    if (line['stageStatus'] != 4 && line['stageStatus'] != 6 && line['stageStatus'] != 8) {
                        disableField = false;
                    }
                }
                if (line && (!line.hasOwnProperty('locConsumption') || line['locConsumption'] === null || line['locConsumption'] === undefined)) {
                    this.props.formData.form.initializeFromState2.values.lines[key].locConsumption = this.props.formData.form.initializeFromState2.values.locConsumption;
                }
                this.props.field.isDisabled = disableField;
            }
        }
        if (this.props.field.isLine && this.props.field.id === 'locInProgress') {

            let ind = (this.props.input.name).indexOf('.' + this.props.field.id);
            let line = (this.props.input.name).substring(0, ind);
            let key = line.replace(/[^0-9]/g, '');

            let disableField = true;
            if (this.props.formData.form && this.props.formData.form.initializeFromState2.values.lines) {
                let line = this.props.formData.form.initializeFromState2.values.lines[key];
                if (line && line['stageStatus'] !== null) {
                    if (line['stageStatus'] != 6 && line['stageStatus'] != 8) {
                        disableField = false;
                    }
                }
                if (line && (!line.hasOwnProperty('locInProgress') || line['locInProgress'] === null || line['locInProgress'] === undefined)) {
                    this.props.formData.form.initializeFromState2.values.lines[key].locInProgress = this.props.formData.form.initializeFromState2.values.locInProgress;
                }
                this.props.field.isDisabled = disableField;
            }
        }
        if (this.props.field.isLine && this.props.field.id === 'locProduction') {

            let ind = (this.props.input.name).indexOf('.' + this.props.field.id);
            let line = (this.props.input.name).substring(0, ind);
            let key = line.replace(/[^0-9]/g, '');

            let disableField = true;
            if (this.props.formData.form && this.props.formData.form.initializeFromState2.values.lines) {
                let line = this.props.formData.form.initializeFromState2.values.lines[key];
                if (line && line['stageStatus'] !== null) {
                    if (line['stageStatus'] != 6 && line['stageStatus'] != 8) {
                        disableField = false;
                    }
                }
                if (line && (!line.hasOwnProperty('locProduction') || line['locProduction'] === null || line['locProduction'] === undefined)) {
                    this.props.formData.form.initializeFromState2.values.lines[key].locProduction = this.props.formData.form.initializeFromState2.values.locProduction;
                }
                this.props.field.isDisabled = disableField;
            }
        }

        if (this.props.field.isLine && this.props.field.id === 'stage') {
            let disableField = true;
            let val = null;

            let ind = (this.props.input.name).indexOf('.' + this.props.field.id);
            let key = (this.props.input.name).substring(0, ind).replace(/[^0-9]/g, '');
            let line = this.props.formData.form.initializeFromState2.values.lines[key];

            this.props.field.options.map(option => {
                if (line['stagePosition'] && option.position < line['stagePosition'] || option.forbiddenAccess) {
                    option.disabled = true;
                } else {
                    option.disabled = false;
                }
            });
            let indexes = this.props.formData.form.initializeFromState2.values.lines.map((o, i) => o.stage === line['stage'] ? i : '').filter(String);
            if (indexes.length !== this.props.formData.form.initializeFromState2.values.lines.length) {
                disableField = true;
            } else {
                disableField = false;
                if (line['stage'] === undefined) {
                    val = this.props.formData.form.initializeFromState2.values.stageId;
                } else {
                    val = line['stage'];
                }
            }

            this.props.formData.formProperties.initializeFromState2.form.breadcrumb.disabled = disableField;
            this.props.formData.form.initializeFromState2.values.stageId = val;
        }

        if (this.props.field.id === 'accId') {
            if (this.props.formData.formProperties && this.props.formData.formProperties.initializeFromState2) {
                this.props.formData.formProperties.initializeFromState2.form.fields.map(field => {
                    if (field.id === 'metaData') {
                        field.fields.map(line => {
                            if ((line.id === 'addrId' && this.props.input.value.length > 0) || (line.id === 'saleId' && this.props.input.value.length > 0)) {
                                line.isDisabled = false;

                            } else if ((line.id === 'addrId' && this.props.input.value.length === 0) || (line.id === 'saleId' && this.props.input.value.length === 0)) {
                                line.isDisabled = true;
                                this.props.formData.form.initializeFromState2.values.addrId = null;
                                this.props.formData.formData.initializeFromState2.addrId = null;
                                this.props.formData.form.initializeFromState2.values.saleId = null;
                            }
                        })
                    }
                });
            }
        }
      
        if (this.props.field.id === 'countStock') {
            let currentValueField = this.props.fields.find(o => o.id === 'currentValue');
            if (this.props.input.value !== 8 && this.props.input.value !== 9 && typeof this.props.formData.form.initializeFromState2.values.id !== 'undefined') {
                currentValueField.disabled = true;
            } else {
                currentValueField.disabled = false;
            }
        }

        if(this.props.field.id === 'periodFrom'){
            if(this.props.input.value.length > 0){
                this.props.fields.map(field => {
                    if (field.id === 'dateFrom' || field.id === 'dateTo') {
                        field.disabled = true;
                    }
                })
            }else{
                this.props.fields.map(field => {
                    if (field.id === 'dateFrom' || field.id === 'dateTo') {
                        if(!this.props.formData.form.initializeFromState2.values.periodTo || (this.props.formData.form.initializeFromState2.values.periodTo && this.props.formData.form.initializeFromState2.values.periodTo.length ===0)){
                            field.disabled = false;
                        }
                    }
                })
                if((this.props.formData.form.initializeFromState2.values.dateFrom && this.props.formData.form.initializeFromState2.values.dateFrom.length > 0) || (this.props.formData.form.initializeFromState2.values.dateTo && this.props.formData.form.initializeFromState2.values.dateTo.length > 0)){
                    this.props.field.isDisabled = true;
                }else{
                    this.props.field.isDisabled = false;
                }
            }
        }

        if(this.props.field.id === 'periodTo'){
            if(this.props.input.value.length > 0){
                this.props.fields.map(field => {
                    if (field.id === 'dateFrom' || field.id === 'dateTo') {
                        field.disabled = true;
                    }
                })
            }else{
                this.props.fields.map(field => {
                    if (field.id === 'dateFrom' || field.id === 'dateTo') {
                        if(!this.props.formData.form.initializeFromState2.values.periodFrom || (this.props.formData.form.initializeFromState2.values.periodFrom && this.props.formData.form.initializeFromState2.values.periodFrom.length ===0)){
                            field.disabled = false;
                        }
                    }
                })
                if((this.props.formData.form.initializeFromState2.values.dateFrom && this.props.formData.form.initializeFromState2.values.dateFrom.length > 0) || (this.props.formData.form.initializeFromState2.values.dateTo && this.props.formData.form.initializeFromState2.values.dateTo.length > 0)){
                    this.props.field.isDisabled = true;
                }else{
                    this.props.field.isDisabled = false;
                }
            }
        }

        if (this.props.field.settings && this.props.field.settings.optionsUrl) {
            return <AsyncSelect
                isClearable
                cacheOptions
                isSearchable
                style={{minWidth: 300}}
                onChange={(value) => this.onChange(value)}
                onBlurResetsInput={false}
                onBlur={event => event.preventDefault()}
                onCloseResetsInput={false}
                isDisabled={this.props.field.isDisabled ? this.props.field.isDisabled : false}
                onFocus={this.props.input.onFocus}
                menuPosition={this.props.field.position}
                menuPlacement={"auto"}
                isOptionDisabled={(option) => option.disabled === true}
                loadOptions={this.loadOptions}
                defaultOptions={this.props.field.options}
                value={this.props.input.value !== null ? (this.props.field.options.find(o => o.value === this.props.input.value)) : (this.props.field.options.find(o => o.selected === true) ? this.props.field.options.find(o => o.selected === true) : null)}
            />
        } else {
            return <Select
                isClearable={this.props.field.hasOwnProperty('isClearable') ? this.props.isClearable : true}
                onBlur={event => event.preventDefault()}
                value={this.props.input.value !== null && this.props.field.options && this.props.field.options.length ? this.props.field.options.find(o => o.value === this.props.input.value) : null} 
                onChange={(value) => this.onChange(value)}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                isOptionDisabled={(option) => option.disabled === true}
                isDisabled={this.props.field.isDisabled ? this.props.field.isDisabled : false}
                menuPosition={this.props.field.position}
                menuPlacement={"auto"}
                onFocus={this.props.input.onFocus}
                options={this.props.field.options}
            />
        }
    }
}

Select2.defaultProps = {
    onNewOptions: () => { },
};

const mapStateToProps = state => ({
    formData: state,
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({
    onInputChange: (formId, id, value, route) => dispatch(onSelect2InputChange(formId, id, value, route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Select2);
