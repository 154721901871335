import React  from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import Documents from './Documents'
import {plainTranslate} from '../common/translate';

class Folder extends React.Component {

    constructor(props) {
        super(props);
    }
    
    componentDidMount() {  
        document.title = plainTranslate(this.props.settings.locale, 'Documents');
    }
    
    render() {       
     return (<Documents type="folder" id={this.props.match.params.id}/>);
 }
}


const mapStateToProps = state => ({
  settings: state.settings,
  documents: state.documents
});

export default connect( mapStateToProps, { })(withRouter(Folder));