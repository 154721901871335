import React, { Component } from 'react';
import { connect } from 'react-redux';
import { plainTranslate } from '../common/translate';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
//import 'react-dates/lib/css/_datepicker.css';
//import 'antd/lib/date-picker/style/index.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import createPlotlyComponent from 'react-plotly.js/factory';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
//import DatePicker from 'react-datepicker';
import {DatePicker as DatePickerComponent, Select} from 'antd';
import {DateRangePicker} from 'react-dates';
import client from '../../actions/client'
import moment from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

require('./pivotTable.css');

// create Plotly React component via dependency injection
const Plot = createPlotlyComponent(window.Plotly);

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);
const { RangePicker } = DatePickerComponent;
const { Option } = Select;

class Pivot extends React.Component {
    constructor(props) {
        super(props);
        this.db = this.props.match.params.db;
    
        this.state = props;
        this.state = {     
            unusedOrientationCutoff: 0,
            isLoaded: true, 
            data: [],
            startDate: moment().subtract(30, 'd'),
            endDate: moment(),
            sourceName: '/' + this.db + '/api/report/pivot/report.json',
            viewName: '',
            lists: [],
            sources: [],
            disabled: true
            //sources:JSON.parse(this.props.reports)
        };

        this.loadSource = this.loadSource.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleChangeSource = this.handleChangeSource.bind(this);
        this.handleLoadSource = this.handleLoadSource.bind(this);
        this.handleDatesChange = this.handleDatesChange.bind(this);
    }

    componentDidMount() {
        client
        .get('/' + this.props.match.params.db + '/api/pivoting')
        .then(response => response.data)
        .then(response => {
            this.setState({ showLoader: false, sources: response });
        });
    }
    
    handleLoadSource(event){
         event.preventDefault();
         if(!this.state.disabled){
             this.setState({
                  isLoaded: false,
             });
             this.loadSource();
         }
    }
   
    handleChangeSource(value) {
        var obj = this.state.sources.find( o => o.name === value);
        this.setState({
            viewName: value,
            sourceName: obj.path,
            disabled: false
        });
     
    }

    handleDatesChange(event) {
        let start = event[0];
        let end = event[1];

        this.setState({
            startDate: start,
            endDate: end
        })
    }

    loadSource(){
        if(this.state.sourceName){   
            client
            .post(this.state.sourceName, {
                startDate: this.state.startDate.format('YYYY-MM-DD'),
                endDate: this.state.endDate.format('YYYY-MM-DD'),
                view: this.state.viewName
            })
            .then(response => response.data)
            .then(response => {
                this.setState({
                    data: response,
                    isLoaded: true,
                });
            });
        }
    
    }
    
    handleChangeStart(date){
        this.setState({startDate: date}); 
    }
    
    handleChangeEnd(date){
        this.setState({endDate: date}); 
    }

    render() {
      
        return (
    <div className=" sectionBackground">
        <div className="col-md-3">

        <Select size="large" style={{ width: 400, marginTop: 8, marginLeft: 15 }} onChange={this.handleChangeSource}>
           {this.state.sources && this.state.sources.map(source => (                         
                <Option value={source.name}>{source.name}</Option> 
              ))
            }   
        </Select>
        </div>
                 
        <div className="date-range col-md-6 col-xs-12">
            <RangePicker
                separator={<span style={{display: "flex", fontSize: "9px", left: "-10px", position: "relative"}}><LeftOutlined /><RightOutlined /></span>}
                style={{backgroundColor: "white"}}
                defaultValue={[this.state.startDate, this.state.endDate]}
                onChange={this.handleDatesChange}
                format = {this.props.settings.dateFormat}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) => {
                    this.setState({focusedInput})
                }}

            />
        </div>
                            
        <div className="col-md-3">
        <span className="text-center" >
                <a className={"button-primary"+ (this.state.disabled ? " disabled" : "")} href="#" onClick={this.handleLoadSource}>
                  <i className="fa fa-caret-right fa-lg"></i><span className="load">Load</span>
                </a>
        </span> 
        </div>
   {!this.state.isLoaded  &&
       <div className="col-md-12 loading">Loading...</div>
    } 
        <div className="col-md-12">
      <section  className="pivoting">
      <div className=" table-inbox" style={{'overflow-y': 'auto', minHeight: "500px"}}>
            <PivotTableUI
                data={this.state.data}
                onChange={s => this.setState(s)}
                renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                {...this.state}
            />
            </div>
        </section>    
        </div>
    </div>        
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Pivot);