import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import SlideModal from '../../common/SlideModal';
import RichTextEditor from 'react-rte';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';

class EmailMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
             emailPopup: false,
        };
        this.sendMessage = this.sendMessage.bind(this);
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.onBodyChange = this.onBodyChange.bind(this);
    }
    
    handleTemplateChange(value) {
        if(value){
            var body = value.template ? value.template.body : value.body;
            if (this.props.field.fields) {      
                var i;
                for (i = 0; i < this.props.field.fields.length; i++) {
                  body = body.replace('{' + this.props.field.fields[i]['id'] + '}',  this.props.formData.form.initializeFromState2.values[this.props.field.fields[i]['id']]);
                }
            }
            this.setState({body: body, template: value ? value.template : null});
        } else {
            this.setState({template: null, body: null})
        }  
    }
    
    handleSelectChange(val, type) {
        if (type == 'from') {
            this.setState({from: val});
        } 
        if (type == 'to') {
            this.setState({to: val ? val.value : null});
        } 
    }
    
    onBodyChange(event) {
        this.setState({body:event.target.value});
    }
    
    sendMessage() {
            client.post('/' + this.props.match.params.db + '/api/email/send', {
                'template': this.state.template,
                'body': this.state.body,
                'setting': this.state.from,
                'receiver': this.state.to,
                'topic': document.getElementById('topic').value
            })
            .then(response => response.data)
            .then((response) => {
                if ('errors' in response) {
                    showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
                }
                else {
                    showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
                }
            })
    }
   
    render() {
        let enableSave = false;
        if (this.state.from && this.state.to) {
            enableSave = true;
        }
        
        return (
            <React.Fragment>
                <a className="btn btn-crm" onClick={() => this.setState({emailPopup: true})}>{plainTranslate(this.props.settings.locale, this.props.field.title)}</a>
                {this.state.emailPopup === true &&
                  <SlideModal onClose={() => this.setState({emailPopup: false})} title={plainTranslate(this.props.settings.locale, 'New Email')}>
                    <section className="newPanel">
                      <div className="panel-body">
                        <div className="row m-bot15">
                        
                        {this.props.field.emailSettings && this.props.field.emailSettings.length > 0 && 
                            <React.Fragment>
                                <div className='head-line'>
                                    <div className="col-md-4 template" style={{paddingLeft: 0, marginBottom: 20}}>
                                        <label>{plainTranslate(this.props.settings.locale, 'Template')}</label>
                                        <Select
                                            isClearable
                                            options={this.props.field.templates}
                                            onChange={this.handleTemplateChange}
                                        />
                                    </div>
                                    <div className="col-md-4 settings" style={{paddingLeft: 0, marginBottom: 20}}>
                                         <label>{plainTranslate(this.props.settings.locale, 'From')}</label>
                                            <Select
                                                isClearable
                                                options={this.props.field.emailSettings}
                                                onChange={(val) => this.handleSelectChange(val, 'from')}
                                            />
                                    </div>
                                    <div className="col-md-4 phones" style={{paddingLeft: 0, marginBottom: 20}}>
                                        <label>{plainTranslate(this.props.settings.locale, 'To')}</label>
                                            <Select
                                                isClearable
                                                options={this.props.field.userEmail}
                                                onChange={(val) => this.handleSelectChange(val, 'to')}
                                            />
                                    </div>
                                    <div className="col-md-12 topic"  style={{paddingLeft: 0}}>
                                        <label>{plainTranslate(this.props.settings.locale, 'Topic')}</label>
                                            {!this.state.template &&
                                                <input id="topic" type="text"></input>
                                            }
                                            {this.state.template && this.state.template.id.length > 0  &&
                                                <input id="topic" type="text" disabled></input>
                                            }
                                    </div>
                                    <React.Fragment>
                                        <textarea id="message-content" className="form-control" value={this.state.body} onChange={this.onBodyChange}>
                                        </textarea>
                                    </React.Fragment>
                                </div> 
                                
                                <button type="button" disabled = {enableSave ? '' : 'disabled'} className={"button-primary " + (enableSave ? '' : 'disabled')} onClick={this.sendMessage}>{plainTranslate(this.props.settings.locale, 'Send')}</button>
                                <button type="button" className="button-outline grey" onClick={() => this.setState({emailPopup: false})}>{plainTranslate(this.props.settings.locale, 'Cancel')}</button>
                            </React.Fragment>
                            }
                            {this.props.field.emailSettings && this.props.field.emailSettings.length == 0 && 
                                <div>
                                    <p>{plainTranslate(this.props.settings.locale,'Send email directly from an Account profile with the Email service integration. Configure Email integration-related settings on the Composity platform from Account -> Email.')}</p>
                                    <a className="rectangular_button green" href={'/'+this.props.match.params.db + '/profile/settings/email'}><Translate locale={this.props.settings.locale} value="Setup now"/></a>
                                </div>
                            }  
                        </div>
                      </div>
                    </section>
                  </SlideModal>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
                settings: state.settings,
                formData: state,
            });

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EmailMessage));
