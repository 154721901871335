import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../common/translate";
import ErrorMessage from './errorMessage';
import Text from './types/text';
import Select2 from './types/select2';
import Number from './types/number';
import DatePicker from "./types/datepicker";
import GroupBox from './types/groupBox';
import InformationText from './types/informationText';

class Field extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if(this.props.field.type === 'switch') {
            this.props.input.onChange(this.props.input.value === true || this.props.input.value === 1);
        }
    }

    render() {
        return <React.Fragment>{this.props.field.type === 'clearfix' ?
        <div className='clearfix'></div>
        :
        <div className={this.props.field.outerClass ? this.props.field.outerClass  : this.props.field.hide ? 'hidden' : ''}>
            <div className={this.props.field.type === 'hidden' ? 'hide-field' : ''}>
                <div className={this.props.field.width ? this.props.field.width : "field col-md-6 col-xs-12 mobileSelect"}
                     style={{
                         height: 'height' in this.props.field ? this.props.field.height : '',
                        
                     }}>
                    <div
                        className={"row " + ('fieldClass' in this.props.field ? this.props.field.fieldClass : 'm-bot15')}>
                        <div className="form-group"
                             style={{
                                 marginBottom: 'marginBottom' in this.props.field ? this.props.field.marginBottom : ''
                             }}>
                            <label
                                className={"required " + (this.props.field.inlineLabel ? "col-md-8 col-xs-12" : "col-md-12 col-xs-12 no-gutter-m") + " text-left"}
                                style={{visibility: this.props.field.type === 'hidden' ? 'hidden' : 'visible'}}>
                                {plainTranslate(this.props.locale, this.props.field.label)}
                                {this.props.field.required && <span>*</span>}
                            </label>
                            <div className={this.props.field.inlineLabel ? 'col-md-4 col-xs-12 no-gutter' : this.props.field.type=="tableCollection" ? 'col-md-12 col-xs-12 no-gutter-m no-gutter custom-table' : 'col-md-12 col-xs-12 no-gutter-m'}>
                                {
                                    this.props.field.type === 'text' &&
                                    <Text {...this.props} />
                                }

                                {
                                    this.props.field.type === 'informationText' &&
                                    <InformationText {...this.props} />
                                }
                                
                                {
                                    this.props.field.type === 'select2' &&
                                    <Select2 {...this.props} />
                                }

                                {
                                    this.props.field.type === 'number' &&
                                    <Number {...this.props} />
                                }

                                {
                                    this.props.field.type === 'datepicker' &&
                                    <DatePicker {...this.props} />
                                }
                                
                                {
                                    this.props.field.type === 'groupBox' &&
                                    <GroupBox
                                        {...this.props}
                                        fields={this.props.field.fields}
                                        options={this.props.field.options ? this.props.field.options : {}}
                                        mainFormData={this.props.formData}
                                        formData={this.props.formData}
                                        change={this.props.change}
                                    />
                                }
                                {
                                    this.props.meta.touched && this.props.meta.error &&
                                    <div>
                                        <div className="forms-error-msg">
                                            <ErrorMessage
                                                locale={this.props.locale}
                                                error={this.props.meta.error}
                                                message={'errorMessage' in this.props.field ? this.props.field.errorMessage : false}
                                            />
                                            <br/>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        </React.Fragment>
    }
}

export default Field;
