let apiUrl = "REACT_APP_API1_URL";

if(window.location.hostname === 'localhost'){
	apiUrl = 'REACT_APP_LOCALHOST_API_URL';
}else if(window.location.hostname === 'dev1.compositylabs.com'){
	apiUrl = 'REACT_APP_DEVAPI1_URL';
}else if(window.location.hostname === 'dev2.compositylabs.com'){
	apiUrl = 'REACT_APP_DEVAPI2_URL';
}else if(window.location.hostname === 'dev3.compositylabs.com'){
	apiUrl = 'REACT_APP_DEVAPI3_URL';
}else if(window.location.hostname === 'dev4.compositylabs.com'){
	apiUrl = 'REACT_APP_DEVAPI4_URL';
}else if(window.location.hostname === 'composity.cloud'){
	apiUrl = 'REACT_APP_API6_URL';
}else if(window.location.hostname === 'elimex.composity.cloud'){
	apiUrl = 'REACT_APP_API4_URL';
}
//apiUrl = 'REACT_APP_API6_URL';
export const config = apiUrl;
