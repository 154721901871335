import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";
import SlideModal from '../../common/SlideModal';
import Field from '../field';
import Form from "../form";
import {onFieldChange, onAddSelectOption} from "../../../actions/actions";
import { withRouter } from 'react-router-dom'

class TabBox extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tabs: [],
            billingDataForm: false
        };
        this.calculateOperation = this.calculateOperation.bind(this);
        this.newBillingData = this.newBillingData.bind(this);
    }

    componentDidMount() {
        this.setState({
            tabs: this.props.tabs ? this.props.tabs : '',
            currentTab: 0
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.tabs) !== JSON.stringify(this.props.tabs)) {
            this.setState({
                tabs: this.props.tabs ? this.props.tabs : ''
            });
        }
    }

    calculateOperation(operation, item) {
        if (operation.type === 'sumList') {
            let sum = 0;
            if (operation.list in this.props.formData) {
                this.props.formData[operation.list].map(item => {
                    if ('operation' in operation) {
                        sum += this.calculateOperation(operation.operation, item);
                    }
                });
            }

            if ('decimalPoint' in operation)
                return sum.toFixed(operation.decimalPoint);
            return sum;
        }
        if (operation.type === 'multiply') {
            let field1 = operation.field1;
            let field2 = operation.field2;
            if (field1.type === 'additionalValue') field1 = field1.populateFrom in item && field1.name in item[field1.populateFrom] ? item[field1.populateFrom][field1.name] ? item[field1.populateFrom][field1.name] : 0 : 0;
            else if (field1.type === 'formValue') field1 = field1.name in item ? item[field1.name] : 0;
            if (field2.type === 'additionalValue') field2 = field2.populateFrom in item && field2.name in item[field2.populateFrom] ? item[field2.populateFrom][field2.name] ? item[field2.populateFrom][field2.name] : 0 : 0;
            else if (field2.type === 'formValue') field2 = field2.name in item ? item[field2.name] : 0;

            if ('decimalPoint' in operation)
                return (field1 * field2).toFixed(operation.decimalPoint);

            return field1 * field2;
        }
    }

    getContent() {
        const currentTab = this.state.currentTab;
        let content = 'no content';
        if (currentTab in this.state.tabs && 'fields' in this.state.tabs[currentTab]) {
            content = this.state.tabs[currentTab].fields.map((field, key) => {
                if (field.type === 'label') {
                    return <div className={'col-md-12 col-xs-12'} key={key}>
                        {
                            'label' in field &&
                            <div className="col-xs-4 col-sm-4"
                                 style={{borderBottom: field.underline ? '1px solid #ccc' : ''}}>
                                <h5>
                                    {plainTranslate(this.props.settings.locale, field.label)}
                                </h5>
                            </div>
                        }
                        <div
                            className={'label' in field ? "col-xs-8 col-sm-8" : "col-xs-12 col-sm-12"}
                            style={{borderBottom: field.underline ? '1px solid #ccc' : ''}}>
                            <h5
                                className={('label' in field ? 'text-left ' : '') + "text-bold"}>
                                {'operation' in field && this.calculateOperation(field.operation)}
                                {'populateFrom' in field && field.populateFrom in this.props.formData && this.props.formData[field.populateFrom] && field.id in this.props.formData[field.populateFrom] ? this.props.formData[field.populateFrom][field.id] : null}

                                {'localValue' in field
                                && this.props.additionalData
                                && field.localValue in this.props.additionalData
                                && field.id in this.props.additionalData[field.localValue]
                                    ?
                                    this.props.additionalData[field.localValue][field.id]
                                    :
                                    null}
                            </h5>
                        </div>
                    </div>
                } else {
                    return <Field
                        formId={this.props.formId}
                        formData={this.props.formData}
                        onCustChange={this.props.onCustChange}
                        formIsValid={this.props.formIsValid}
                        formProperties={this.props.formProperties}
                        field={field}
                        key={key}
                        fieldKey={key}/>
                }
            });
        }
        return content;
    }

    changeTab(key) {
        this.setState({currentTab: key});
    }

    buttonAction(action) {
        if (!action) return;

        if (action.reactMethod) {
            if(action.reactMethod === 'newCustomer') {
                this.setState({billingDataForm: this.state.billingDataForm ? false : action});
            }
        }
    }

    newBillingData(data) {
        data = data.entity;
        this.setState({billingDataForm: false});
        let fproperties = this.props.formProperties;
        fproperties.form.fields[0].tabs[0].fields[0].options.push(data);
        this.props.onAddSelectOption(this.props.formId, fproperties)
            .then(() => this.props.onUpdateField(this.props.formId, 'custId', data.id));
    }

    render() {
        return <div className="panel total" style={{marginTop: '0px'}}>
            {
                this.state.billingDataForm &&
                <SlideModal onClose={() => this.buttonAction(this.state.billingDataForm)}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <Form
                                    saveRoute={'/' + this.props.match.params.db + '/api/billing-data/new'}
                                    formRoute={'/' + this.props.match.params.db + '/api/billing-datas/form/'}
                                    match={{params: {id: 0}}}
                                    onSuccessfulSave={(data) => this.newBillingData(data)}
                                    popup={true}
                                    onCancel={() => this.buttonAction({reactMethod: 'newCustomer'})}
                                />
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }

            <header className="panel-heading" style={{display: 'block', marginBottom: '10px'}}>
                {
                    this.state.tabs && this.state.tabs.map((tab, key) => {
                        return <span className={this.state.currentTab != key ? 'text-grey':''} key={key}
                                     onClick={() => this.changeTab(key)}>{plainTranslate(this.props.settings.locale, tab.label)} {key !== this.state.tabs.length - 1 ? <span> | </span> : ''}</span>
                    })
                }
                {
                    this.props.tabs && this.state.currentTab in this.state.tabs && 'button' in this.state.tabs[this.state.currentTab] &&
                    <a className={'pull-right add-customer-label'} 
                          onClick={() => this.buttonAction(this.state.tabs[this.state.currentTab].button.action)}>
                            {'icon' in this.state.tabs[this.state.currentTab].button &&
                            <i className={this.state.tabs[this.state.currentTab].button.icon}></i>}
                        {'label' in this.state.tabs[this.state.currentTab].button &&
                        <p>{plainTranslate(this.props.settings.locale, this.state.tabs[this.state.currentTab].button.label)}</p>}
                        </a>
                }
            </header>
            {this.getContent()}
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onUpdateField: (formId, key, value) => dispatch(onFieldChange(formId, key, value)),
    onAddSelectOption: (formId, properties) => dispatch(onAddSelectOption(formId, properties))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TabBox));
