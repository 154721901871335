import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import {connect} from 'react-redux';
import {onAccountFetchData, onFieldSave} from "../../../actions/actions";
import AsyncSelect from 'react-select/lib/Async';
import {onSelect2InputChange, onAddSelectOption} from '../../../actions/actions';
import { List } from 'antd';
import { Table, Input, Button, Tabs, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import 'antd/lib/table/style/index.css';
import 'antd/lib/list/style/index.css';
import Highlighter from 'react-highlight-words';

class MultipleSelectList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: [],
            selectedOption: null,
        };
        this.onChange = this.onChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }
    
    onChange(value) {
        this.setState({value: value});
        this.props.change(this.props.field.id, JSON.stringify(value));
    }
    
    onRemove(value) {
        let selected = [...this.state.value];
        
        this.setState({value: selected.filter(item => item.value !== value)});
        this.props.change(this.props.field.id, JSON.stringify(selected.filter(item => item.value !== value)));
    }
    
    componentDidMount() {
        if (this.props.input.value !== '') {
            this.setState({
                value: JSON.parse(this.props.input.value)
            });
        }
    }
    
    loadOptions(inputValue, callback) {
        if (!inputValue) inputValue = -1;
        let hasSettings = this.props.field.settings && this.props.field.settings.optionsUrl;
        this.props.onInputChange('initializeFromState2', this.props.field.id, inputValue, this.props.field.settings.optionsUrl)
        .then(response => {
                let options = [];
                if (response) {
                    options = response.payload.options.map(option => {
                        return {
                            ...option,
                            'value': option.value,
                            'label': option.label
                        };
                    });
                }
                this.options = options;
                if(options.length > 0) this.props.onNewOptions(options);
                else if(this.props.field.options.length > 0) this.props.onNewOptions(this.props.field.options);

                if(!hasSettings) {
                    let ops = this.props.field.options;
                    ops = ops.filter(item => item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);
                    callback(ops);
                }
                else callback(options ? options : this.props.field.options);
            });
    }

    render() {
        let isDisabled = this.props.field.isDisabled ? this.props.field.isDisabled : false;
        const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`${plainTranslate(this.props.settings.locale, 'Search')} ${plainTranslate(this.props.settings.locale, dataIndex)}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block', height: 30 }}
            />
            <div>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8}}
            >
              {plainTranslate(this.props.settings.locale, 'Search')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              {plainTranslate(this.props.settings.locale, 'Reset')}
            </Button>
            </div>
          </div>
        ),
        filterIcon: filtered => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };

    const handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
      const columns = [
        {
            'key': 'label',
            'dataIndex' : 'label',
            'title' : plainTranslate(this.props.settings.locale, 'Name'),
            sorter: (a, b) => {
                return a.label.localeCompare(b.label)
            },
            ...getColumnSearchProps('label'),
            render: (text, record) => 
            <div><p>{text}
                    <span style={{float: 'right'}}><a><i onClick={() => this.onRemove(record.value)} className="fa fa-trash-o" aria-hidden="true"></i></a></span>
                </p>
            </div>
        },
    ];
        return (
            <React.Fragment>
                <div className="inlineHover input-select2">
                    <React.Fragment>
                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                            <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                                <AsyncSelect
                                    isMulti={true}
                                    value={this.props.input.value !== '' ? JSON.parse(this.props.input.value) : ''}
                                    onChange={this.onChange}
                                    options={this.props.field.options}
                                    isDisabled={isDisabled}
                                    loadOptions={this.loadOptions}
                                    defaultOptions={this.props.field.options}
                                    isClearable={false}
                                />
                                <Table columns={columns} dataSource={this.state.value} />
                            </div>
                        </div>    
                    </React.Fragment>
                </div>
            </React.Fragment>
        );
    }
}

MultipleSelectList.defaultProps = {
    onNewOptions: () => {},
};

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData)),
    onInputChange: (formId, id, value, route) => dispatch(onSelect2InputChange(formId, id, value, route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleSelectList);
