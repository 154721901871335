import axiosFileApi from '../actions/axiosFileApi';

const setFileApi = (url, token) => {
    axiosFileApi.defaults.baseURL = url;
  if (token) {
    // Apply to every request
    axiosFileApi.defaults.headers.common['X-AUTH-TOKEN'] = token;
  } else {
    // Delete auth header
    delete axiosFileApi.defaults.headers.common['X-AUTH-TOKEN'];
  }
};

export default setFileApi;
