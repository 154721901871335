import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import { connect } from 'react-redux';
import reactCSS from 'reactcss';
import { Button, Modal } from 'antd';

const ColorPicker = props => {
    const [color, setColor] = useState(props.input.value);
    const [prevColor, setPrevColor] = useState(props.input.value);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setPrevColor(props.input.value);
    }, [props.input.value])

    const handleClick = () => {
        setVisible(!visible);
        setPrevColor(color);
        props.input.onChange(color);
    }

    const handleChange = (color) => {
        setColor(color.hex);
    }

    const handleClose = () => {
        setVisible(false);
        setColor(prevColor);
    };

    const handleOpen = () => {
      setVisible(true);
  };

    const styles = reactCSS({
        'default': {
          color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${ prevColor }`,
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          }
        },
      });

    return (
        <>
            <div>
                <div style={styles.swatch} onClick={handleOpen}>
                    <div style={styles.color} />
                </div>
                <Modal bodyStyle={{height: 310}} title="Color picker" visible={visible} onOk={handleClick} onCancel={handleClose}>     
                    <SketchPicker disableAlpha color={color} onChange={handleChange} presetColors={props.field.colors}/>
                </Modal>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    settings: state.settings,
    formData: state,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ColorPicker);
