import React, { useEffect, useState } from 'react';
import SectionContents from "./sectionContents";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Translate, { plainTranslate } from "../../common/translate";

const Sections = props => {
    const [value, setValue] = useState(null);

    useEffect(() => {
        let menu = props.input.value ? JSON.parse(props.input.value) : null;
        if (menu) {
            setValue(menu['sections']);
        } else {
            setValue([]);
        }
        
    }, []);

    const onDragEnd = (val) => {
        let items = value;
        let droppable = val.destination.droppableId;
        let destinationIndex = val.destination.index;
        let sourceIndex = val.source.index;
        let updatedItems = null;

        if (droppable == 'main') {
            updatedItems = moveInArray(items, sourceIndex, destinationIndex);
        } else {
            let index = null;
            let updatedSubItems = null;
            items.find((item, key) => {
                if (item.name === droppable) {
                    index = key;
                }
            });

            if (index !== null) {
                updatedSubItems = moveInArray(items[index][props.field.settings.subLevel], sourceIndex, destinationIndex);
                items[index][props.field.settings.subLevel] = updatedSubItems;
                updatedItems = items;
            } else {
                updatedItems = items;
            }
        }
        setValue(updatedItems);
        change();
    };

    const moveInArray = (arr, from, to) => {
        if (Object.prototype.toString.call(arr) !== '[object Array]') {
            throw new Error('Please provide a valid array');
        }
        var item = arr.splice(from, 1);
        if (!item.length) {
            throw new Error('There is no item in the array at index ' + from);
        }
        arr.splice(to, 0, item[0]);
        return arr;
    };

    const addNewItem = () => {
        let menu = value ? value : [];
        let newItem = { 'id': 'New section' };
        menu.push(newItem);
        change();
        setValue(menu);
    }

    const change = () => {
        let menu = new Object();
        menu['sections'] = value;
        props.input.onChange(JSON.stringify(menu));
    }

    const remove = (item) => {
        let array = value;
        let index;
        array.find((val, key) => {
            if (val === item) {
                index = key;
                return true;
            }
        });
        if (index > -1) {
            array.splice(index, 1);
        }

        setValue(array);
        change();
    }

    return (<div>
        <React.Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="main">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {value && value.length > 0 && value.map((item, key) =>
                                <Draggable key={item.id} draggableId={item.id} index={key}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <SectionContents type={item.id} item={item} key={key} change={change} remove={remove} />
                                        </div>
                                    )}
                                </Draggable>
                            )}
                            <a className="button-outline grey" onClick={addNewItem}>{plainTranslate(props.locale, 'Add section')}</a>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </React.Fragment>
    </div>
    );
}

export default Sections;
