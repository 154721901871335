import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import SettingsMenu from '../../sidebar/settings-menu';
import SingleCheckbox from './../singleCheckbox.jsx';
import { client} from "../../../actions/actions";
import OpenSection from './../openSection.jsx';
import Stages from './../stages.jsx';
import { fetchSettings} from "../../../actions/actions";
import { Skeleton } from 'antd';

class SaleSettingsPage extends Component {

    constructor(props) {
    super(props);
        this.state = {
            showMessage: false,
            ready: false
            };
        this.allSettings = [
            { value: "turnOffCheckQty", name: 'Turn off check for available quantity?', text: 'Select whether you turn off check for available quantity'},
            { value: "reserved", name: 'Quantity reserved?', text: 'Select whether you reserve quantity on stock'},
            { value: "processed", name: 'Quantity full processed?', text: 'Select whether you process full quantity on stock'},
            { value: "part_processed", name: 'Quantity part processed?', text: 'Select whether you process part quantity on stock'},
        ];
        this.updateStages = this.updateStages.bind(this);
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/sales/settings/sale-settings', 'saleSettings')
        .then(res => {            
            this.setState({ready: true})
        })
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }
    
    updateStages() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/sales/settings/sale-settings', 'saleSettings');
    }

     render() {

        return (
                <div>
                    <SettingsMenu
                       location = {this.props.location.pathname} db = {this.props.match.params.db}/>
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                   <div className="settings">
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Sales form type')}
                                            description={plainTranslate(this.props.settings.locale, "Specify different types of documents for sales")}
                                            link={"/" + this.props.match.params.db + "/form-types-v2"}
                                         />
                                         <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Sales Sequential No')}
                                            description={plainTranslate(this.props.settings.locale, "Set the format of the sequential number of sales documents")}
                                            component={'input'}
                                            value ={this.props.settings.saleSettings}
                                            type={'saleSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/sales/save/settings/individual'}
                                            setting={'seqNo'}
                                         />
                                         <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale,'Sale Template')}
                                            description={plainTranslate(this.props.settings.locale,'Set the default template for printing sales')}
                                            component={'select'}
                                            value ={this.props.settings.saleSettings}
                                            type={'saleSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/sales/save/settings/individual'}
                                            setting={'salesTemplate'}
                                         />
                                        <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale,'Sales Sorting')}
                                            description={plainTranslate(this.props.settings.locale, "Set the way a document's lines to be sorted.")}
                                            component={'select'}
                                            value ={this.props.settings.saleSettings}
                                            type={'saleSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/sales/save/settings/individual'}
                                            setting={'salesSorting'}
                                         />
                                         <hr/>
                                        <SingleCheckbox
                                            value={this.props.settings.saleSettings}
                                            label={plainTranslate(this.props.settings.locale, 'Check Available Quantity')}
                                            description={plainTranslate(this.props.settings.locale, 'Disable dispatching of higher quantities of stock than available in the warehouse, except if expressly stated')}
                                            settings={this.props.settings}
                                            type={'saleSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/sales/save/settings/individual'}
                                            setting = {'checkQuantity'}
                                        />
                                        <hr/>
                                        <SingleCheckbox
                                            value={this.props.settings.saleSettings}
                                            label={plainTranslate(this.props.settings.locale, 'Аllow import')}
                                            description={plainTranslate(this.props.settings.locale, 'Allow sale import')}
                                            settings={this.props.settings}
                                            type={'saleSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/sales/save/settings/individual'}
                                            setting = {'allowImport'}
                                        />
                                        <div className='clearfix'></div>
                                        <hr/>
                                        <div className="col-sm-12 column-centered">
                                         {this.state.ready ?
                                           <Stages
                                                saveRoute={'/' + this.props.match.params.db + '/api/stages/save/settings/data'}
                                                type={'saleSettings'}
                                                workflow={"sale"}
                                                configuration = {true}
                                                roles={'roles'}
                                                value={this.props.settings.saleSettings}
                                                settings={this.props.settings}
                                                fields={this.allSettings}
                                                setting = {'stages'}
                                                updateStages={this.updateStages}
                                            />
                                            :
                                            <Skeleton active />}
                                        </div>
                                   </div>
                                </div>
                             </section>
                          </div>
                       </div>
                    </form>
                </div>
                );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
   getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SaleSettingsPage);
