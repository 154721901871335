import React, { useEffect, useState, useRef } from "react";
import SimpleForm from "../../../redux-forms/form";

const GeneralInfoForm = (props) => {
  const [account, setAccount] = useState(props.account);

  useEffect(() => {
    if (props.account) {
      setAccount(props.account);
    }
  }, [account]);

  const onSuccessfulSave = () => {
    props.onAccountFetchData(props.db, props.account.id, false);
    props.openEditInfoHandle();
    if(props.gridPopup){
      props.updateGrid();
    }
  };

  return (
    <span>
      <SimpleForm
        formRoute={props.db + "/api/account/general-form/"}
        saveRoute={"/" + props.db + "/api/accounts/edit/" + props.account.id}
        popup={true}
        onCancel={props.openEditInfoHandle}
        match={{ params: { id: props.account.id } }}
        onSuccessfulSave={onSuccessfulSave}
        isAdditionalId={true}
        form={"initializeFromState2"}
      />
    </span>
  );
};

export default GeneralInfoForm;
