import React from 'react';
import {connect} from "react-redux";
import {plainTranslate} from "../common/translate";

class ErrorMessage extends React.Component {
    constructor(props) {
        super(props);

        this.errorMessages = {
            'required': 'Field is required',
            'minLength': 'Min length error',
            'maxLength': 'Max length error',
            'sameAs': 'Passwords do not match',
            'dateRange': 'Date not in open dates range'
        };

        this.getError = this.getError.bind(this);
    }

    getError() {
        if(!(this.props.error in this.errorMessages) && this.props.message) return this.props.message;
        return this.props.error in this.errorMessages ? this.errorMessages[this.props.error] : 'undefined error';
    }

    render() {
        return plainTranslate(this.props.settings.locale, this.getError());
    }
}


const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);
