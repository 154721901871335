export const SETTINGS_LOAD = 'SETTINGS_LOAD';
export const SETTINGS_SIDEBAR_CHANGE = 'SETTINGS_SIDEBAR_CHANGE';
export const SETTINGS_CONFIRM_DIALOG_TOGGLE = 'SETTINGS_CONFIRM_DIALOG_TOGGLE';

// GRID ACTION TYPES
export const GRID_MOUNT = 'GRID_MOUNT';
export const GRID_PROPERTIES_LOADED = 'GRID_PROPERTIES_LOADED';
export const GRID_API_LOAD = 'GRID_API_LOAD';
export const GRID_DATA_LOADED = 'GRID_DATA_LOADED';
export const GRID_HEADER_SHOW_LOADER = 'GRID_HEADER_SHOW_LOADER';

export const GRID_ROWS_SELECTED = 'GRID_ROWS_SELECTED';
export const GRID_ROWS_SELECTED_CLEAR = 'GRID_ROWS_SELECTED_CLEAR';

export const GRID_HEADER_SHOW_OPTIONS = 'GRID_HEADER_SHOW_OPTIONS';
export const GRID_HEADER_OPTION_SELECTED = 'GRID_HEADER_OPTION_SELECTED';
export const GRID_HEADER_OPTION_LABEL_CHANGED = 'GRID_HEADER_OPTION_LABEL_CHANGED';

export const GRID_BULK_ACTION_OPEN = 'GRID_BULK_ACTION_OPEN';
export const GRID_BULK_OPEN = 'GRID_BULK_OPEN';
export const GRID_BULK_ACTION_CLICK = 'GRID_BULK_ACTION_CLICK';

export const GRID_QUICK_FILTER_CHANGE = 'GRID_QUICK_FILTER_CHANGE';
export const GRID_DATES_CHANGE = 'GRID_DATES_CHANGE';

export const GRID_COLUMNS_SHOW = 'GRID_COLUMNS_SHOW';
export const GRID_COLUMNS_CHECK = 'GRID_COLUMNS_CHECK';
export const GRID_COLUMNS_UPDATE = 'GRID_COLUMNS_UPDATE';

export const GRID_CONFIRM_DIALOG_SHOW = 'GRID_CONFIRM_DIALOG_SHOW';

export const GRID_VIEW_DIALOG_OPEN = 'GRID_VIEW_DIALOG_OPEN';
export const GRID_VIEW_DIALOG_CONTENT = 'GRID_VIEW_DIALOG_CONTENT';
// END GRID ACTION TYPES

// START FORM ACTION TYPES
export const FORM_MOUNT = 'FORM_MOUNT';
export const FORM_LOADED = 'FORM_LOADED';
export const FORM_SHOW_LOADER = 'FORM_SHOW_LOADER';
export const FORM_INITIAL_VALIDATION = 'FORM_INITIAL_VALIDATION';
export const FORM_SAVE_VALIDATE = 'FORM_SAVE_VALIDATE';
export const FORM_EMPTY_VALID = 'FORM_EMPTY_VALID';

export const FORM_DATA_LOAD = 'FORM_DATA_LOAD';
export const FORM_DATA_VALIDATION = 'FORM_DATA_VALIDATION';

export const FORM_FIELD_UPDATE = 'FORM_FIELD_UPDATE';
export const FORM_FIELD_VALIDATE = 'FORM_FIELD_VALIDATE';
export const FORM_FIELD_LOSE_FOCUS = 'FORM_FIELD_LOSE_FOCUS';

export const FORM_FIELD_SELECT2_OPTIONS = 'FORM_FIELD_SELECT2_OPTIONS';
export const FORM_FIELD_SELECT2_ADD_OPTIONS = 'FORM_FIELD_SELECT2_ADD_OPTIONS';
export const IMAGE_UPLOAD_SUCCESSFUL = 'IMAGE_UPLOAD_SUCCESSFUL';
export const IMAGES_UPLOAD_SUCCESSFUL = 'IMAGES_UPLOAD_SUCCESSFUL';
export const UPLOAD_GET_COLUMNS = 'UPLOAD_GET_COLUMNS';
export const FILE_UPLOAD_SUCCESSFUL = 'FILE_UPLOAD_SUCCESSFUL';
export const FORM_REMOVE_IMAGE = 'FORM_REMOVE_IMAGE';
// END FORM ACTION TYPES

// START POPUP MESSAGE ACTIONS
export const POPUP_MESSAGE_SHOW = 'POPUP_MESSAGE_SHOW';
export const POPUP_MESSAGE_HIDE = 'POPUP_MESSAGE_HIDE';
// END POPUP MESSAGE ACTIONS

export const FORM_SAVE_DISABLE = 'FORM_SAVE_DISABLE';
export const FORM_SAVE_ENABLE = 'FORM_SAVE_ENABLE';

// START ACCOUNT PROFILE ACTIONS
export const ACCOUNT_PROFILE_DATA_LOADED = 'ACCOUNT_PROFILE_DATA_LOADED';
export const ACCOUNT_PROFILE_UPDATE_FIELD = 'ACCOUNT_PROFILE_UPDATE_FIELD';
export const ACCOUNT_PROFILE_EDIT_OPTIONS = 'ACCOUNT_PROFILE_EDIT_OPTIONS';
export const ACCOUNT_PROFILE_DATA_LOADING = 'ACCOUNT_PROFILE_DATA_LOADING';
export const ACCOUNT_ADD_NEW_ACTIVITY = 'ACCOUNT_ADD_NEW_ACTIVITY';
export const ACCOUNT_EDIT_ACTIVITY = 'ACCOUNT_EDIT_ACTIVITY';
export const LOAD_CUSTOM_BLOCKS = 'LOAD_CUSTOM_BLOCKS';
export const UPDATE_BLOCK_RECORDS = 'UPDATE_BLOCK_RECORDS';
export const SAVE_BLOCK_RECORDS = 'SAVE_BLOCK_RECORDS';
export const ACCOUNT_ADD_NEW_DATA_ACCESS = 'ACCOUNT_ADD_NEW_DATA_ACCESS';
export const ACCOUNT_ADD_NEW_CONSENT = 'ACCOUNT_ADD_NEW_CONSENT';
export const ADD_CONTACT_FIELD = 'ADD_CONTACT_FIELD';
export const DELETE_CONTACT_FIELD = 'DELETE_CONTACT_FIELD';
export const DELETE_BILLING_DATA = 'DELETE_BILLING_DATA';
export const ACCOUNT_ADD_NEW_FILE = 'ACCOUNT_ADD_NEW_FILE';
export const ACCOUNT_PROFILE_DELETE_TABLE_DATA = 'ACCOUNT_PROFILE_DELETE_TABLE_DATA';
export const ACCOUNT_ADD_NEW_PROJECT = 'ACCOUNT_ADD_NEW_PROJECT';
export const ACCOUNT_ADD_NEW_TASK = 'ACCOUNT_ADD_NEW_TASK';
export const ACCOUNT_EDIT_TASK = 'ACCOUNT_EDIT_TASK';
export const SALE_CHANGE_STAGE = 'SALE_CHANGE_STAGE';
export const UPDATE_CONTENT_RECORDS = 'UPDATE_CONTENT_RECORDS';
// END ACCOUNT PROFILE ACTIONS

// START ACCOUNT PROFILE COMMENTS
export const COMMENTS_WIDGET_UNLOADED = 'COMMENTS_WIDGET_UNLOADED';
export const ADD_COMMENT = 'ADD_COMMENT';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
// END ACCOUNT PROFILE COMMENTS

//START EMPLOYEE PROFILE
export const FETCH_EMPLOYEE_BEGIN   = 'FETCH_EMPLOYEE_BEGIN';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_EMPLOYEE_FAILURE = 'FETCH_EMPLOYEE_FAILURE';
export const EMPLOYEE_PROFILE_UPDATE_FIELD = 'EMPLOYEE_PROFILE_UPDATE_FIELD';

//START ORG STRUCTURE
export const FETCH_STRUCTURE_BEGIN   = 'FETCH_STRUCTURE_BEGIN';
export const FETCH_STRUCTURE_SUCCESS = 'FETCH_STRUCTURE_SUCCESS';
export const FETCH_STRUCTURE_FAILURE = 'FETCH_STRUCTURE_FAILURE';

//START MAX LVL
export const FETCH_MAX_LVL   = 'FETCH_MAX_LVL';
export const FETCH_DEPARTMENT_NAMES = 'FETCH_DEPARTMENT_NAMES';
export const DEPARTMENT_NAME_FIELD_UPDATE = 'DEPARTMENT_NAME_FIELD_UPDATE';

//START LINKS PROFILE
export const FETCH_LINKS_BEGIN   = 'FETCH_LINKS_BEGIN';
export const FETCH_LINKS_SUCCESS = 'FETCH_LINKS_SUCCESS';
export const FETCH_LINKS_FAILURE = 'FETCH_LINKS_FAILURE';
export const FETCH_CATEGORIES_BEGIN   = 'FETCH_CATEGORIES_BEGIN';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const UPDATE_LINK_FIELD = 'UPDATE_LINK_FIELD';

export const USER_ROLES_GRID_LOAD = 'USER_ROLES_GRID_LOAD';
export const USER_ROLES_GRID_REMOVE = 'USER_ROLES_GRID_REMOVE';

//START MODULES PAGE
export const MODULES_CHANGE = 'MODULES_CHANGE';
export const SETTINGS_CHANGE = 'SETTINGS_CHANGE';
export const CONFIGURATIONS_CHANGE = 'CONFIGURATIONS_CHANGE';

//START SETTINGS
export const FETCH_SETTINGS = 'FETCH_SETTINGS';

//CALENDAR ACTIONS
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
//END CALENDAR ACTIONS

//START INVOICE PROFILE ACTIONS
export const INVOICE_PROFILE_DATA_LOADING = 'ACCOUNT_PROFILE_DATA_LOADING';
export const INVOICE_PROFILE_DATA_LOADED = 'INVOICE_PROFILE_DATA_LOADED';

export const GET_ITEM = 'GET_ITEM';

export const GENERATE_REPORT_HTML = 'GENERATE_REPORT_HTML';

//START MAKE PAYMENT ACTIONS
export const МАКЕ_PAYMENT_DATA_LOADED = 'МАКЕ_PAYMENT_DATA_LOADED';
//END MAKE PAYMENT ACTIONS

//START ITEM PROFILE ACTIONS
export const ITEM_PROFILE_DATA_LOADING = 'ITEM_PROFILE_DATA_LOADING';

export const ITEM_PROFILE_LOADING = 'ITEM_PROFILE_LOADING';
export const ITEM_PROFILE_DATA_LOADED = 'ITEM_PROFILE_DATA_LOADED';

//START ITEM REQUESTS ACTIONS
export const ITEM_REQUEST_DATA_LOADING = 'ITEM_REQUEST_DATA_LOADING';
export const ITEM_REQUESTS_DATA_LOADED = 'ITEM_REQUESTS_DATA_LOADED';
export const SINGLE_ITEM_REQUEST_DATA_LOADED = 'SINGLE_ITEM_REQUEST_DATA_LOADED';
export const GET_ITEM_REQUESTS_STAGES = 'GET_ITEM_REQUESTS_STAGES';
export const UPDATE_ITEM_REQUESTS_DATA = 'UPDATE_ITEM_REQUESTS_DATA';
export const ITEM_REQUEST_SINGLE_VIEW = 'ITEM_REQUEST_SINGLE_VIEW';

//documents
export const GET_DOCUMENTS = 'GET_DOCUMENTS'
export const GET_DOCUMENT = 'GET_DOCUMENT'
export const LOAD_UPLOAD = 'LOAD_UPLOAD'
export const GET_FOLDER_OPTIONS = 'GET_FOLDER_OPTIONS'

//notifications
export const ADD_NOTIFICATION_TO_LIST = 'ADD_NOTIFICATION_TO_LIST';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const GET_CURRENT_NOTIFICATIONS = 'GET_CURRENT_NOTIFICATIONS';
export const COUNT_UNREAD = 'COUNT_UNREAD';


//START AUTH
export const SET_CURRENT_USER='SET_CURRENT_USER';
export const GET_ERRORS='GET_REGISTER_DATA';
export const CLEAR_ERRORS='CLEAR_ERRORS';
export const CLEAR_CURRENT_PROFILE ='CLEAR_CURRENT_PROFILE';
export const GET_REGISTER_DATA = 'GET_REGISTER_DATA';
export const GET_ERRORS_REGISTRATION = 'GET_ERRORS_REGISTRATION';
//END AUTH