import client from './client';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import showAToast from '../components/common/showAToast'
import {  
    SET_CURRENT_USER,
    GET_ERRORS_REGISTRATION,
    GET_ERRORS,
    CLEAR_ERRORS,
    CLEAR_CURRENT_PROFILE
} from '../constants/actionTypes';


// Register User
export const registerUser = (db, userData, history) => dispatch => {
     return   client
                .post('/' + db + '/register', userData)
                .then(res => {                  
                    if (res.data.token) {
                        const {token} = res.data;    
                        // Decode token to get user data
                        const decoded = jwt_decode(token);  
                        if(decoded['status'] !== 3){
                           // window.location = '/'+db+ '/user/signin'
                            showAToast('Your account is not verified.', 'errLogin', 'error')
                        }else{               
                            // Set token to ls
                            localStorage.setItem('jwtToken', token);
                            localStorage.setItem('db', db);
                            // Set token to Auth header
                            setAuthToken(token);                    
                            // Set current user
                            dispatch(setCurrentUser(decoded));
                            //history.push('/'+db+ '/dashboard')
                            window.location = '/'+db+ '/dashboard';
                        }
                  }else{
                    history.push('/' + db + '/user/confirmation')
                    showAToast(res.data.success, 'successReg', 'success') 
                  }     
                  return res;                   
                })
                .catch(err => {console.log(err.response)
                    var error;
                    if (err.response && err.response.data) {
                        error = err.response.data;

                        if (typeof error != 'undefined' && error.message) {
                            showAToast(error.message, 'errregister', 'error') 
                        }
                               
                    }
                    return err.response;
                });
    };
    
// Login - Get User Token
export const loginUser = (db, userData, history) => dispatch => {
            client
                .post('/' + db + '/secured/login/authentification', userData)
                .then(res => {   
                    const {token} = res.data;    
                    // Decode token to get user data
                    const decoded = jwt_decode(token);  
                    if(decoded['status'] !== 3){
                       // window.location = '/'+db+ '/user/signin'
                        showAToast('Your account is not verified.', 'errLogin', 'error')
                    }else{               
                        // Set token to ls
                        localStorage.setItem('jwtToken', token);
                        localStorage.setItem('db', db);
                        // Set token to Auth header
                        setAuthToken(token);                    
                        // Set current user
                        dispatch(setCurrentUser(decoded));
                        //history.push('/'+db+ '/dashboard')
                        window.location = '/'+db+ '/dashboard';
                    }
                })
                .catch(err => {
                    showAToast('Invalid credentials!', 'errLogin', 'error')                  
                });
    };

// Set logged in user
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

// Log user out
export const logoutUser = (db=null, redirect = true) => dispatch => {
        // Remove token from localStorage
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('db');
        localStorage.removeItem("storageToken");
        localStorage.removeItem("storageUrl");
        // Remove auth header for future requests
        setAuthToken(false);
        // Set current user to {} which will set isAuthenticated to false
        dispatch(setCurrentUser({}));
        if(redirect){
            if(db){
                window.location = '/'+db+'/user/signin'
            }else{
                window.location = '/workspace/signin'
            } 
        }        
       // window.location = '/workspace/signin'
    };


export const clearErrors = () => dispatch => {
        dispatch({
            type: CLEAR_ERRORS,
            payload: {}
        })
}

export const clearCurrentProfile = () => {
  return {
    type: CLEAR_CURRENT_PROFILE
  };
};

export const findWorkplace = (workplace, history) => dispatch => {
        client
                .post('/saas/find/workspace', workplace)
                .then(res => {
                window.location = '/' + res.data.db + '/user/signin';
                    //history.push('/' + res.data.db + '/login')
                })
                .catch(err => {
                    if(err.response && err.response.data && err.response.data.message){
                        showAToast(err.response.data.message, 'successReg', 'error') 
                    }
                });
    };


