import React from 'react';
import { connect } from 'react-redux';
import SlideModal from '../common/SlideModal';
import SimpleForm from "../forms/form";
//import { ACCOUNT_ADD_NEW_FILE } from "../../constants/actionTypes";
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Translate, { plainTranslate } from '../common/translate';
import { Table, Dropdown, Upload, Menu, Select, Row, Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import client from '../../actions/client';
import { getFolderOptions, getDocuments, uploadFiles, createFolder, loadUpload } from '../../actions/documentsActions';
//import { onTabsLoad } from '../../../../actions/actions';
import showAToast from '../common/showAToast';
import MediaLibrary from './mediaLibrary';

const { Option } = Select;

class Documents extends React.Component {
    constructor() {
        super();
        this.state = {
            isNew: false,
            isFile: false,
            fileList: [],
            folder: 'all',
            pageFiles: 1,
            pageFolders: 1,
            uploadModal: false, 
            uploadLoading: false
        }
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
    }

    dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };

    onClose(){
        this.setState({uploadModal: false, isPublicFile: false});
        this.props.onUploadFile();
    }

    render() {
        const { fileList } = this.state;
        const uploadButton = (
            <div>
                <i className="fa fa-file-o" aria-hidden="true"></i>
                <div style={{ marginTop: 8 }}>{plainTranslate(this.props.settings.locale, 'New file')}</div>
            </div>
        );
        let menuOptions = [];
        menuOptions = (
            <Menu>
                <Menu.Item key='file'>
                    <a to="#" onClick={() => this.setState({ isFile: true, isNew: 'undefined' })}>
                        <Translate locale={this.props.settings.locale} value="Save file link" />
                    </a>
                </Menu.Item>
                <Menu.Item key='uploadPublic'>
                    <a to="#" onClick={() => this.setState({ uploadModal: true, isPublicFile: true })}>
                        <Translate locale={this.props.settings.locale} value="Upload public file" />
                    </a>
                </Menu.Item>
                <Menu.Item key='uploadPrivate'>
                    <a to="#" onClick={() => this.setState({ uploadModal: true })}>
                        <Translate locale={this.props.settings.locale} value="Upload private file" />
                    </a>
                </Menu.Item>
            </Menu>
        );
        return (
            <div>
                {
                    this.props.dataDocuments &&
                    <React.Fragment>
                        <div className="btn-round btn-blue">
                            <Dropdown overlay={menuOptions} placement="bottomCenter" >
                                <PlusOutlined />
                            </Dropdown>
                        </div>
                        <Table
                            rowKey={record => record.id}
                            columns={this.props.columnsDocuments}
                            dataSource={this.props.dataDocuments}
                            pagination={false}
                            scroll={{ x: 'max-content' }}
                            rowClassName={"account-profile-tabTable"}
                            size="small"
                            loading={this.props.loading}
                        />
                    </React.Fragment>
                }
                {this.state.uploadModal &&
                    <SlideModal onClose={() => this.setState({ uploadModal: false , isPublicFile: false})} title={plainTranslate(this.props.settings.locale, 'File upload')}>
                        <div id="reactForm">
                            <div class="row m-bot15 cmxform form-horizontal bucket-form ng-pristine ng-valid">
                                <div class="col-lg-12">
                                    <section class="panel">
                                    <div class="panel-body">
                                        <Row>
                                            <MediaLibrary 
                                                fileList={[]}
                                                uploadUrl={'/'+this.props.match.params.db+(this.props.isPublic ? "/web/public/" : "/api/")+ this.props.type + "/"+ this.props.data.id +"/upload-files"}
                                                onPreview={this.handlePreview}
                                                onChange={this.handleChange}
                                                onUploadFile={this.props.onUploadFile}
                                                onClose={this.onClose}
                                                hideMediaLibrary={this.props.settings.storageIntegration ? false : true}
                                                isPublic={this.props.isPublic}
                                                type={this.props.type}
                                                data={this.props.data}
                                                isPublicFile={this.state.isPublicFile}
                                            />
                                        </Row>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </SlideModal>
                }
                {
                    this.state.isFile &&
                    <SlideModal onClose={() => this.setState({ isFile: false, isNew: false })} title={plainTranslate(this.props.settings.locale, 'Save file link')}>
                        <section className="newPanel">
                            <SimpleForm
                                formRoute={this.props.match.params.db + (this.props.isPublic ? '/web/public/files/form' : '/api/files/form')}
                                saveRoute={this.state.isNew !== 'undefined' ? this.props.match.params.db + '/api/files/edit' : this.props.match.params.db + (this.props.isPublic ? "/web/public/files?" : "/api/files?") +(this.props.type === 'project' ? 'projectId=' : this.props.type === 'item' ? 'itemId=' : 'taskId=')+ this.props.data.id}
                                updateParent={() => this.props.onUploadFile()}
                                match={{ params: { id: 0 } }}
                                onCancel={() => this.setState({ isFile: false, isNew: false })}
                                popup={true}
                                isNew={true}
                            />
                        </section>
                    </SlideModal>
                }
                <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancelPreview}>
                  <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    settings: state.settings,
    documents: state.documents
});
const mapDispatchToProps = dispatch => ({
    //onAddNewFile: (data) => dispatch({ type: ACCOUNT_ADD_NEW_FILE, payload: { dataFile: data } }),
    getFolderOptions: () => dispatch(getFolderOptions()),
    getDocuments: (type, pageFiles, pageFolders, id) => dispatch(getDocuments(type, pageFiles, pageFolders, id)),
    uploadFiles: (form) => dispatch(uploadFiles(form)),
    createFolder: (db, name, parentId) => dispatch(createFolder(db, name, parentId)),
    loadUpload: () => dispatch(loadUpload()),
    //onTabsLoad: (route, page, requiredTotal) => dispatch(onTabsLoad(route, page, requiredTotal))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Documents));
