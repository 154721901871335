import React, { useEffect, useState } from 'react';
import Translate, { plainTranslate } from '../common/translate';
import { connect } from 'react-redux';
import client from "../../actions/client";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table, Menu, Dropdown, Select, Progress } from 'antd';
import showAToast from '../common/showAToast';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import GridV2 from '../../components/gridV2/grid.jsx';
import SlideModal from '../common/SlideModal';
import FiltersSelect from '../../components/booking/select';
import NewForm from "../redux-forms/form";

const { Option } = Select;

const ProjectBoardProfile = (props) => {
	const [loading, setLoading] = useState(false);
	const [activeTab, setActiveTab] = useState(null);
    const [showCalendarFilters, setShowCalendarFilters] = useState(null);
    const [calendarFilters, setCalendarFilters] = useState({});
    const [calendarTasks, setCalendarTasks] = useState(null);
    const [data, setData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [openedTask, setOpenedTask] = useState(null);
    const [editPopup, setEditPopup] = useState(null);

	useEffect(() => {
		setActiveTab('table');
        getProfileData();
    }, []);

	useEffect(() => {
        getProfileData();
    }, [props.match.params.boardId]);

    function changeTab(tab) {
    	setActiveTab(tab);
    }

    function getProfileData(){
        setLoading(true);
        client.get('/' + props.match.params.db + '/api/project-board/' + props.match.params.boardId + '/profile-info')
        .then(response => response.data)
        .then(response => {
            setData(response);
            if(openedTask){
                let index = response.tasks.findIndex(o => o.id === openedTask.id);
                if(index >=0){
                    setOpenedTask(response.tasks[index]);
                }
            }
        });
    }

	const style = {
	    margin: '0 5px',
	};

	const formats = {
      eventTimeRangeFormat: () => { 
        return "";
      },
    };

    const eventStyleGetter = (event, start, end, isSelected) => {
      return {
        style: {
          opacity: isSelected ? 1 : 0.9,
          backgroundColor: getColor(event.projectName),
          color: 'black'
        },
      };
    };

    function getColor(val){
        var hash = 0;
        if (val.length === 0) return hash;
        for (var i = 0; i < val.length; i++) {
            hash = val.charCodeAt(i) + ((hash << 5) - hash);
            hash = hash & hash;
        }
        var color = '#';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 255;
            color += ('00' + value.toString(16)).substr(-2);
        }
        return color;
    }

    function handleFilterChange(value, type, arr){
        calendarFilters[type] = value;
        setCalendarFilters(calendarFilters);
    }

    function applyFilters(){
        client
        .post('/' + props.match.params.db + '/api/project-board/' + props.match.params.boardId + '/find-tasks', {calendarFilters})
        .then(response => response.data)
        .then(response => {
            setCalendarTasks(response);
        });

        setShowCalendarFilters(false);
    }

    function cancelFilters(){
      //setCalendarFilters({});
      setShowCalendarFilters(false);
    }

    function handleChange(activity){
        setIsOpen(true);
        setOpenedTask(activity);
    }

    function handleClose() {
        setIsOpen(false);
        setOpenedTask(null);
    }

    function closeEditPopup() {
        getProfileData();
        setEditPopup(false);
    }

    const monthNames = [
      plainTranslate(props.settings.locale, 'January'),
      plainTranslate(props.settings.locale, 'February'),
      plainTranslate(props.settings.locale, 'March'),
      plainTranslate(props.settings.locale, 'April'),
      plainTranslate(props.settings.locale, 'May'),
      plainTranslate(props.settings.locale, 'June'),
      plainTranslate(props.settings.locale, 'July'),
      plainTranslate(props.settings.locale, 'August'),
      plainTranslate(props.settings.locale, 'September'),
      plainTranslate(props.settings.locale, 'October'),
      plainTranslate(props.settings.locale, 'November'),
      plainTranslate(props.settings.locale, 'December')
    ];

    moment.locale(props.settings.locale);
    const localizer = momentLocalizer(moment);
    const messages = {
      today: plainTranslate(props.settings.locale, 'Today'),
      month: plainTranslate(props.settings.locale, 'Month'),
      week: plainTranslate(props.settings.locale, 'Week'),
      day: plainTranslate(props.settings.locale, 'Day'),
      monthNames: monthNames
    };

	let projectTasks = data ? data.tasks : [];
	let stages = data ? data.taskStages : [];
	let users = data ? data.users : [];
    let accounts = data ? data.accounts : [];
    let projects = data ? data.projects : [];
    let color = data ? data.color : "#f0eae7";

    if(calendarTasks){
        projectTasks = calendarTasks;
    }
    
    {projectTasks && projectTasks.map(task =>(
/*      task.start = new Date(moment(task.start).format('DD.MM.YYYY')),
      task.end = new Date(moment(task.end).format('DD.MM.YYYY')),console.log(task)*/
        moment.defaultFormat = "YYYY.MM.DD",
        task.start = moment(task.start, moment.defaultFormat).toDate(),
        task.end = moment(task.end, moment.defaultFormat).toDate()
      
      ))}

    props.match.params.refs = props.match.params.boardId;

	return (
        <div className="project-board" style={{paddingTop: 100, paddingLeft: 20, height: "100vh", backgroundColor: color }}>
	        <div className="nav nav-tabs" id="tabBtn" style={{backgroundColor: "white"}}>
	            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 basic no-gutter">
	                <span style={{marginRight: 10, cursor: "pointer"}} onClick={() => changeTab('table')}>{plainTranslate(props.settings.locale, 'Table View')}</span>
	                <span style={{marginRight: 10, cursor: "pointer"}} onClick={() => changeTab('calendar')}>{plainTranslate(props.settings.locale, 'Calendar View')}</span>
	            </div>
	        </div>

	        {activeTab === 'table' && 
            <GridV2 
                gridRoute={'/api/grid/settings/grid-properties/board-tasks'}
                gridDataRoute={'/api/grid/settings/grid-data/board-tasks'}
                gridExportRoute={'/api/grid/board-tasks/export/'}
                gridSaveRoute={'/api/grid/board-tasks/save'}
                title={"Tasks"}
                {...props}
            />}
	        {activeTab === 'calendar' &&
                <div className="activity-calendar" style={{height: "100%"}}>
                <span style={{position:"absolute", fontSize:20,margin: "0 43%", whiteSpace:"noWrap"}}>{data && data.boardName}</span> 
                <span className="filters" onClick={() => setShowCalendarFilters(true)}><img src='/images/filter.svg' alt="email settings"/>{plainTranslate(props.settings.locale, 'Filter')}</span>          
    	        <Calendar      
                    onSelectEvent={(activity) => {
                        handleChange(activity)
                    }}
                    messages={messages}
                    localizer={localizer}
                    events={projectTasks}
                    settings={props.settings}
                    defaultView={localStorage.getItem("calendarView") ? localStorage.getItem("calendarView") : window.innerWidth > 780 ? Views.MONTH : Views.DAY}
                    defaultDate={new Date()}
                    eventPropGetter={eventStyleGetter}
                    formats={formats}
                    selectable
                    style={{backgroundColor: "white", paddingTop: 40}}
                />
                </div>}
            {showCalendarFilters === true &&
                <SlideModal onClose={() => setShowCalendarFilters(false)} title={plainTranslate(props.settings.locale, 'Filters')}>
                  <section className="newPanel">
                    <div className="panel-body">
                      <div className="col-md-10 col-sm-12 row m-bot15">
                        <div className="col-md-12 filter-type" style={{marginTop: 20}}>
                            <h3>{plainTranslate(props.settings.locale, 'Accounts')}</h3>
                            <FiltersSelect 
                                    //updateRoute={this.updateRoute}
                                    id={'accounts'}
                                    isMulti={true}
                                    change={(value, arr) => handleFilterChange(value, 'accounts', arr)}
                                    options={accounts}
                                     //isDisabled={this.state.isDisabledEvents}
                                    url={props.match.params.db +"/api/billing-data-accounts-filter"}
                                    value={calendarFilters.accounts}/>
                        </div>     
                        <div className="clearfix"></div>    
                        <div className="col-md-12 filter-type" style={{marginTop: 20}}>
                            <h3>{plainTranslate(props.settings.locale, 'Projects')}</h3>
                            <FiltersSelect 
                                    //updateRoute={this.updateRoute}
                                    id={'projects'}
                                    isMulti={true}
                                    change={(value, arr) => handleFilterChange(value, 'projects', arr)}
                                    options={projects}
                                    url={props.match.params.db + "/api/projects"}
                                     //isDisabled={this.state.isDisabledEvents}
                                    value={calendarFilters.projects}/>
                        </div>
                        <div className="clearfix"></div>         
                        <div className="col-md-12 filter-type" style={{marginTop: 20}}>
                            <h3>{plainTranslate(props.settings.locale, 'Users')}</h3>
                                <FiltersSelect 
                                    //updateRoute={this.updateRoute}
                                    id={'users'}
                                    isMulti={true}
                                    change={(value, arr) => handleFilterChange(value, 'users', arr)}
                                    options={users}
                                     //isDisabled={this.state.isDisabledEvents}
                                    url={props.match.params.db + "/api/find/users/search"}
                                    value={calendarFilters.users}/>
                        </div>
                        <div className="clearfix"></div>    
                        <div className="col-md-12 filter-type" style={{marginTop: 20}}>
                            <h3>{plainTranslate(props.settings.locale, 'Task Stages')}</h3>
                            <FiltersSelect 
                                    //updateRoute={this.updateRoute}
                                    id={'stages'}
                                    isMulti={true}
                                    change={(value, arr) => handleFilterChange(value, 'stages', arr)}
                                    options={stages}
                                     //isDisabled={this.state.isDisabledEvents}
                                    value={calendarFilters.stages}/>
                        </div>                 
                        <div style={{display: "table"}}>
                          <button type="button" className="button-primary" onClick={applyFilters} id="apply">{plainTranslate(props.settings.locale, 'Apply Filters')}</button>
                          <button type="button" className="button-outline grey" onClick={cancelFilters} id="cancel">{plainTranslate(props.settings.locale, 'Cancel')}</button>
                        </div>
                      </div>
                    </div>
                  </section>
                </SlideModal>
            }
            {isOpen &&
              <SlideModal onClose={() => handleClose()}
                title={plainTranslate(props.settings.locale, 'Calendar Event')}>
                <section className="newPanel">
                  <div className="panel-body">
                    <div className="row col-md-12 m-bot15">
                        <div className="row">
                            {openedTask.projectName &&
                                <div className="col-md-12">
                                    <p className="taskTitle">
                                      <strong>{plainTranslate(props.settings.locale, 'Project Name')}</strong>: {openedTask.projectName}</p>
                                </div>}
                        </div>
                        <div className="row">
                        {openedTask.projectStatus &&
                            <div className="col-md-12">
                                <p className="resultsLabelDate">
                                    <strong>{plainTranslate(props.settings.locale, 'Project Status')}</strong>: {plainTranslate(props.settings.locale, openedTask.projectStatus)}</p>
                            </div>}
                        </div>
                        {openedTask.projectStartDate && openedTask.projectEndDate &&
                            <div className="row">
                              <div className="col-md-12">
                                <p className="resultsLabelDate">
                                    <strong>{plainTranslate(props.settings.locale, 'Project Start')}</strong>: {moment((openedTask.projectStartDate), 'DD/MM/YYYY').format(props.settings.dateFormat)}
                                </p>
                              </div>
                                <div className="col-md-12">
                                    <p className="resultsLabelDate">
                                        <strong>{plainTranslate(props.settings.locale, 'Project End')}</strong>: {moment((openedTask.projectEndDate), 'DD/MM/YYYY').format(props.settings.dateFormat)}
                                    </p>
                                </div>
                            </div>
                          }
                        <hr/>
                        <div className="row">
                            {openedTask.name &&
                              <div className="col-md-12">
                                <p className="taskTitle">
                                    <strong>{plainTranslate(props.settings.locale, 'Task Name')}</strong>: {openedTask.name}</p>
                              </div>}
                        </div>
                        <div className="row">
                        {openedTask.status &&
                            <div className="col-md-12">
                                <p className="resultsLabelDate">
                                    <strong>{plainTranslate(props.settings.locale, 'Task Status')}</strong>: {plainTranslate(props.settings.locale, openedTask.status)}</p>
                            </div>}
                        </div>
                        <div className="row">
                            {openedTask.user &&
                                <div className="col-md-12">
                                    <p className="resultsLabelDate">
                                        <strong>{plainTranslate(props.settings.locale, 'Task User')}</strong>: {plainTranslate(props.settings.locale, openedTask.user)}</p>
                                </div>
                            }
                        </div>
                        <div className="row">
                            {openedTask.completion !== null &&
                                <div className="col-md-12">
                                    <p className="resultsLabelDate">
                                        <strong>{plainTranslate(props.settings.locale, 'Task Completion')}</strong>: {plainTranslate(props.settings.locale, openedTask.completion)}</p>
                                </div>
                            }
                        </div>
                      {openedTask.start && openedTask.end &&
                        <div className="row">
                          <div className="col-md-12">
                            <p className="resultsLabelDate">
                                <strong>{plainTranslate(props.settings.locale, 'Task Start')}</strong>: {moment((openedTask.start), 'DD/MM/YYYY').format(props.settings.dateFormat)}
                            </p>
                          </div>
                            <div className="col-md-12">
                                <p className="resultsLabelDate">
                                    <strong>{plainTranslate(props.settings.locale, 'Task End')}</strong>: {moment((openedTask.end), 'DD/MM/YYYY').format(props.settings.dateFormat)}
                                </p>
                            </div>
                        </div>
                      }
                        <div className="row">
                            <div className="col-md-2">
                              <a className="button-primary" href='javascript:void(0)'
                                onClick={() => setEditPopup(true)}>
                                {plainTranslate(props.settings.locale, 'Edit')}
                              </a>
                            </div>
                        </div>
                    </div>
                  </div>
                </section>
              </SlideModal>
            }
            {editPopup &&
                <SlideModal onClose={() => setEditPopup(false)} title={plainTranslate(props.settings.locale, 'Edit Task')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <NewForm
                                    formRoute={props.match.params.db + '/api/tasks/'}
                                    saveRoute={props.match.params.db + '/api/tasks/'}
                                    match={{params: {id: openedTask.id}}}
                                    onSuccessfulSave={closeEditPopup}
                                    popup={true}
                                    isNew={true}
                                    //type={formId}
                                    form={'initializeFromState2'}
                                    onCancel={() => setEditPopup(false)}
                                />
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    settings: state.settings
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectBoardProfile);