import {
	GET_ACTIVITIES,
	GET_EMPLOYEES
} from "../constants/actionTypes";

export default (state = [], action) => {

    switch (action.type) {
                    
        case GET_ACTIVITIES:
	      return {
	        ...state,
	        events: action.payload.data.events,
	        activities: action.payload.data.activities,
	        formTypes: action.payload.data.formTypes,
                taskStages: action.payload.data.taskStages
	      };

	    case GET_EMPLOYEES:
	      return {
	        ...state,
	        employees: action.payload.data,
	      };
        default:
            return state;
    }
};