import React from 'react';
import {plainTranslate} from "../common/translate";
import { Modal, Button } from 'antd';

function ModalAntd(props){
    
    return (
            <Modal title={props.title} 
                visible={props.visible} 
                onOk={props.onOk} 
                onCancel={props.onCancel}
                footer={[
                <Button key="back" onClick={props.onCancel}>
                  {plainTranslate(props.locale, 'No')}
                </Button>,
                <Button key="submit" type="primary" onClick={props.onOk}>
                  {plainTranslate(props.locale, 'Yes')}
                </Button>,
              ]}
             >
                <p style={{marginBottom: 30}}>{plainTranslate(props.locale, 'Are you sure?')}</p>      
              </Modal>
            );
}

export default ModalAntd;