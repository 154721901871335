import {
    FORM_LOADED,
    FORM_DATA_LOAD,
    FORM_FIELD_UPDATE,
    FORM_FIELD_LOSE_FOCUS,
    FORM_DATA_VALIDATION,
    FORM_INITIAL_VALIDATION,
    FORM_FIELD_SELECT2_OPTIONS,
    FORM_FIELD_SELECT2_ADD_OPTIONS,
    IMAGE_UPLOAD_SUCCESSFUL
} from "../constants/actionTypes";
import client from './client';
import saveAs from 'file-saver';

// START FORM ACTIONS
export const onLoadForm = (formId, route) => dispatch => {
    
            let url = route;

            if(window.innerWidth < 780 && formId === 'initializeFromState2'){
                if(url.includes('?')){
                    url = url+'&mobile';
                }else{
                    url = url+'?mobile';
                }
            }

            client
            .get(url)
                .then(response => response.data)
                .then(response => {
                        dispatch({
                            'type': FORM_DATA_LOAD,
                            payload: {
                                formId,
                                data: response.data,
                            }
                        });
                        dispatch({
                            'type': FORM_LOADED,
                            payload: {
                                formId,
                                form: response.form,
                                schema: response.schema,
                                isEdit: response.isEdit,
                                hideSaveNew: response.hideSaveNew
                            }
                        });
                    
                    }
                );
       
}

export const  onImportLoadForm = (formId, db, importData,formUrl=null) => dispatch => {
   
            let url = '/'+db+'/api/sales/import/data';
            if(formUrl){
                url = formUrl;
            }

            if(window.innerWidth < 780 && formId === 'initializeFromState2'){
                if(url.includes('?')){
                    url = url+'&mobile';
                }else{
                    url = url+'?mobile';
                }
            }

            client
            .post(url, {body: importData})
                .then(response => response.data)
                .then(response => {
                        dispatch({
                            'type': FORM_DATA_LOAD,
                            payload: {
                                formId,
                                data: response.data,
                            }
                        });
                        dispatch({
                            'type': FORM_LOADED,
                            payload: {
                                formId,
                                form: response.form,
                                schema: response.schema,
                                isEdit: response.isEdit
                            }
                        });
                    
                    }
                );
       
}

export const onSave = (formId, route, data) => dispatch => {
 
         return   client
            .post(route, data)
                .then(response => response.data)
                .then(response => {
                    return response;
                })
                .catch(err =>{
                    console.log(err)
                })
       
}

export const onSelect2InputChange = (formId, id, value, route = null) => dispatch => {
   
            if (route) {
            return client
                .get(route + '?q=' + value)
                    .then(response => response.data)
                    .then(response => {
                        dispatch({
                            type: FORM_FIELD_SELECT2_OPTIONS,
                            payload: {
                                formId,
                                options: response,
                                id: id,
                                value: value
                            }
                        });
                       return response;
                    })
                    .catch(err =>{
                        console.log(err)
                    })
            }
            else {
                dispatch({
                    type: FORM_FIELD_SELECT2_OPTIONS,
                    payload: {
                        formId,
                        id: id,
                        options: null,
                        value: value
                    }
                });
              
            }
       
}

export function onAddSelectOption(formId, properties) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: FORM_FIELD_SELECT2_ADD_OPTIONS,
                payload: {formId, properties}
            });

            resolve();
        })
    }
}

export const onUploadImage = (formId, db, id, file) => dispatch => {
  
            const formData = new FormData();
            formData.append('file', file);

         return   client
            .post('/' + db + '/api/settings/image/upload', formData)
                .then(response => response.data)
                .then(response => {

                    dispatch({
                        type: IMAGE_UPLOAD_SUCCESSFUL,
                        payload: {
                            formId,
                            image: response,
                            id: id
                        }
                    });

                   return response;
                })
                .catch(err =>{
                    console.log(err)
                })
}

// END FORM ACTIONS