import React from 'react';    
    
class TextLine extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
        this.handleEnterClick = this.handleEnterClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.triggerChange = this.triggerChange.bind(this);
    }

    componentDidMount() {            
        if(this.state.value === '' && typeof this.props.data !== 'undefined'){    
            this.setState({
                value: this.props.data ? this.props.data : ''
            });
        }
    }    

    componentDidUpdate(prevProps, prevState){
   
        if(this.props.formData && this.props.formData[this.props.field.id] && this.props.formData[this.props.field.id] !== this.state.value){    
            this.setState({
               value: this.props.formData[this.props.field.id] ? this.props.formData[this.props.field.id] : ''
          });
        }
    }
 
 /*  shouldComponentUpdate(nextProps, nextState) {    
  
    if(nextState.value !== this.state.value){
return true;
    }else if(nextProps.data !== this.state.value ){
        return true;
    }
    }*/
    
    onChange(event){       
        this.setState({
          value: event.target.value
       }); 
      
       this.props.change(this.props.field.id, event.target.value)
       
     
    }
    
       triggerChange() {
        const { value } = this.state;
     //   this.props.change(this.props.field.id, value)

        if(this.props.field.hasOwnProperty('linkedField')){
            if(!this.props.formData[this.props.field.linkedField]){        
                this.props.change(this.props.field.linkedField, value);
            }
        }
        let lines;
   /*      if(this.props.line){
           
         if(!this.props.lines && this.props.index === 0){
             lines = [];
             let jsonObj = {};
             jsonObj[this.props.field.id] = value;
             lines.push(jsonObj);
         }else{
             lines = this.props.lines;
             lines[this.props.index][this.props.field.id] = value; 
         }                  
            this.props.change('lines', lines)
            this.props.change(this.props.name, value)

        }else{
            this.props.change(this.props.field.id, value)
        }   */
    }
    
    handleEnterClick(e) {
    	if(this.props.field.id === 'itName'){
    		if (e.key === 'Enter'){
                this.props.search(e.target.value, this.props.input.name);

            }else if(e.target.id === 'searchButton') {
                let value = e.target.nextElementSibling.value;

		    	this.props.search(value, this.props.input.name);
			}
    	}
    }

 render() { 

        return  <React.Fragment> 
        {(this.props.clicked === this.props.index  && this.props.line) || !this.props.line ?
        <span className="form-input-field" style={{position: 'relative'}}>   
        <input 
            id={"lineText"}
            onKeyDown={(e) => this.handleEnterClick(e)} 
            value={this.state.value}
            type="text" 
            name={this.props.name}
            className={"form-controls lines"} 
            style={{
                minWidth: this.props.field.width ? this.props.field.width : 150,
                maxWidth: this.props.field.width ? this.props.field.width : 150,
                fontSize: 14,
                float: 'center' }} 
            disabled={this.props.field.disabled} 
            onChange={this.onChange}
            onBlur={this.triggerChange}
            placeholder={this.props.field.placeholder}/>
    </span>
    :
    <span className={'tableSpan'} >
    {this.state.value}</span>}
     </React.Fragment> 
    
                }
}

export default TextLine;
