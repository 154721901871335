import {
	ITEM_PROFILE_DATA_LOADED,
	ITEM_PROFILE_DATA_LOADING,
        ITEM_PROFILE_LOADING
} from "../constants/actionTypes";

export default (state = [], action) => {
    let loading;
    switch (action.type) {
        case ITEM_PROFILE_DATA_LOADING:
            return {
                ...state,
                loading: true,
              };
              
        case ITEM_PROFILE_LOADING:
            return {
                ...state,
                itemLoading: true,
              };

        case ITEM_PROFILE_DATA_LOADED:
            if (action.payload.type == 'item') {
                loading = 'itemLoading';
            } else {
                loading = 'loading';
            }
	      return {
	        ...state,
	        [loading]: false,
                [action.payload.type]: action.payload.data
	      };
	      
        default:
            return state;
    }
};