import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import Popup from '../../common/popup';
import showAToast from '../../common/showAToast';
import client from '../../../actions/client'

class Switch extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            popup: false
        };

        this.onChange = this.onChange.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
     }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.input.value
            });
        }

        if(this.props.input.value === undefined) {
            this.props.input.onChange(false);
        }
    }
    
    componentDidMount() {
        if (this.props.input.value) {
            if (this.props.input.value == '1') {
                this.setState({
                    value: true
                }, function() {
                    this.props.input.onChange(this.state.value);
                });
            } else if (this.props.input.value == '0') {
                this.setState({
                    value: false
                }, function() {
                    this.props.input.onChange(this.state.value);
                });
            } else {
                this.setState({
                    value: this.props.input.value
                });
            }      
        } else {
            this.setState({
                value: false
            });
        }

    }

    onChange(e) {
        if (this.props.field.hasOwnProperty('check')) {
            if (this.props.formData[this.props.field.check]) {
                this.setState({
                    value: !this.state.value
                }, function () {
                    this.props.input.onChange(this.state.value);
                });
            } else {
                this.setState({
                    value: this.state.value
                }, function () {
                    this.props.input.onChange(this.state.value);
                    showAToast(plainTranslate(this.props.settings.locale, 'Please, select payment method'), 'errSettings', 'error');
                });
            }
        } else {
            this.setState({
                value: !this.state.value
            }, function () {
                this.props.input.onChange(this.state.value);
            });
        }
        if (this.props.field.hasOwnProperty('connectedFields')) {
            for (let i = 0; i < this.props.fields.length; i++) {
                for (let n = 0; n < this.props.field['connectedFields'].length; n++) {
                if (this.props.fields[i]['id'] == this.props.field['connectedFields'][n]) {
                    if (this.props.field.hasOwnProperty('connectedFieldAction')) {
                        if (this.props.field.connectedFieldAction == 'show') {
                            if (this.state.value == true) {
                                if (this.props.fields[i].hasOwnProperty('hide')) {
                                    this.props.fields[i]['hide'] = true;
                                }
                            } else {
                                if (this.props.fields[i].hasOwnProperty('hide')) {
                                    this.props.fields[i]['hide'] = false;
                                }
                            }
                        }
                    } else {
                        if (this.state.value == true) {
                            if (this.props.fields[i].hasOwnProperty('isDisabled')) {
                                this.props.fields[i]['isDisabled'] = false;
                            }
                            if (this.props.fields[i].hasOwnProperty('disabled')) {
                                this.props.fields[i]['disabled'] = false;
                            }
                        } else {
                            if (this.props.fields[i].hasOwnProperty('isDisabled')) {
                                this.props.fields[i]['isDisabled'] = true;
                            }
                            if (this.props.fields[i].hasOwnProperty('disabled')) {
                                this.props.fields[i]['disabled'] = true;
                            }
                        }
                    }
                }
            }
        }
        }

        if (this.props.field.hasOwnProperty('confirm') && this.state.value == false) {
            this.setState({popup: true}, function () {
                this.setState({
                    value: false
                }, function () {
                    this.props.input.onChange(this.state.value);
                });
            });
        }

        if (this.props.field.hasOwnProperty('unique') && this.state.value == false) {
            this.props.disableSave(true);
            client.post(this.props.field.unique.checkAction, { 'id': this.props.formData.id ? this.props.formData.id : null })
                .then(response => response.data)
                .then(response => {
                    this.props.disableSave(false);
                    if (response.check) {
                        this.setState({
                            value: !this.state.value
                        }, function () {
                            this.props.input.onChange(this.state.value);
                            showAToast(plainTranslate(this.props.settings.locale, response.message), 'errSettings', 'error');
                        });
                    }
                })
        }
    }
    
    onConfirm() {
        this.setState({
            popup: false,
            value: true
        }, function () {
            this.props.input.onChange(this.state.value);
        })
    }

    render() {
        return <React.Fragment>
                <div className={(this.props.formData && this.props.formData.hasOwnProperty('disableSave') && this.props.formData.disableSave === true) ? "switchComponent text-left switch-disabled" : "switchComponent text-left"}>
                    <input
                        checked={this.state.value}
                        onChange={this.onChange}
                        type="checkbox"
                        disabled={this.props.field.disabled || (this.props.formData && this.props.formData.hasOwnProperty('disableSave') && this.props.formData.disableSave === true)}
                        id={'check-status' + this.props.field.id}
                        label='status'
                    />
                    <label htmlFor={'check-status' + this.props.field.id}></label>
                </div>
                {
                    this.state.popup &&
                    <Popup locale={this.props.settings.locale}
                           title={plainTranslate(this.props.settings.locale, 'Confirm')}
                           onClose={() => this.setState({popup: false})}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <div className="text-center">

                                        <h3>
                                            <Translate locale={this.props.settings.locale} value={this.props.field.confirm}/>
                                        </h3>
                                        <button className="button-outlines green text-center"
                                                onClick={() => this.setState({popup: false})}>
                                            <Translate locale={this.props.settings.locale} value="No"/>
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                                onClick={this.onConfirm}>
                                            <Translate locale={this.props.settings.locale} value="Yes"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Popup>
                }
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Switch);
