    import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import {connect} from 'react-redux';
import {onAccountFetchData, onFieldSave} from "../../../actions/actions";
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import {onSelect2InputChange, onAddSelectOption} from '../../../actions/actions';
import 'antd/lib/radio/style/index.css';
import { Radio } from 'antd';
import client from '../../../actions/client';

class MultiSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: [],
            totals: [],
            isEdit: false,
            isChanged: false,
            selectAll: this.props.field.settings.checkbox ? this.props.field.settings.checkbox.value : null,
        };

        this.openEditField = this.openEditField.bind(this);
        this.closeEditField = this.closeEditField.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.saveField = this.saveField.bind(this);
        this.closeUnchangedField = this.closeUnchangedField.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.onRadioButtonChange = this.onRadioButtonChange.bind(this);
    }

    componentDidMount() {
        let totals = this.state.totals ? this.state.totals : [];
        if(this.props.formData.visibleColumns){
            this.props.formData.visibleColumns.map(column => {
                if(column.isExpression === true){
                    totals.push({
                        field: column.value,
                        value:  column.action ? column.action : null
                    });
                }
            })
        }
        this.setState({
            value: this.props.data === 'selectAll' ? '' : this.props.data,
            totals: totals
        });
        
        if(this.state.selectAll){
            this.props.change(this.props.field.id, 'selectAll');
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) && this.props.data === '') {
            this.setState({
                value: null
            });
        }
        else if(JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) && this.props.data.length === 0){
            this.setState({
                value: null
            });
        }
    }
    handleCheckboxChange(e){
        if(e.target.checked === true){
            this.props.field.isDisabled = true;
            this.props.change(this.props.field.id, 'selectAll');
        }else{
            this.props.field.isDisabled = false;
            if(this.state.value){
                this.props.change(this.props.field.id, this.state.value);
            }else{
                this.props.change(this.props.field.id, null);
            }

        }

        this.setState({
          selectAll: e.target.checked,
        });
    }

    onRadioButtonChange(e) {
        let totals = this.state.totals ? this.state.totals : [];
        let index = totals.findIndex(o => o.field === e.target.name);
        if(index > -1){
            totals[index]['value'] = e.target.value;
        }else{
            totals.push({
                field: e.target.name,
                value:  e.target.value
            });
        }

        this.setState({totals: totals});
        this.props.change('totals', totals);
    }

    openEditField() {
        this.setState({
            isEdit: !this.state.isEdit
        });
    }

    closeEditField() {
        this.setState({
            value: this.props.data
        });

        this.openEditField();
    }

    saveField() {
        this.props.onFieldSave(this.props.updateRoute, this.props.id, this.state.value, this.props.additionalData);
        this.openEditField();
    }

    changeValue(value) {
        let arr = [];
        let totals = this.state.totals;

        value.map(val => {
            if(this.props.formData.visibleColumns){
                arr.push(val);
                if(val.isExpression === true){
                    let key = totals.find(o => o.field === val.value);
                    if(key === undefined){
                        totals.push({
                            field: val.value,
                            value:  val.action ? val.action : null
                        });
                    }
                }
            }else{
                arr.push(val);
            }
        })

        this.props.change(this.props.field.id, arr);
        this.setState({value: arr, isChanged: true,});
    }

    closeUnchangedField() {
        if (!this.state.isChanged) {
            this.openEditField();
            this.setState({isChanged: false});
        }
    }

    loadOptions(inputValue, callback) {
        if (!inputValue) inputValue = -1;
        let hasSettings = this.props.field.settings && this.props.field.settings.optionsUrl;
      //  this.props.onInputChange('initializeFromState2', this.props.field.id, inputValue, this.props.field.settings.optionsUrl)
      if(this.props.field.settings.optionsUrl) {
      client
        .get(this.props.field.settings.optionsUrl + '?q=' + inputValue)
        .then(response => response.data)
        .then(response => {
                let options = [];
                if (response) {
                    options = response.map(option => {
                        return {
                            ...option,
                            'value': option.value,
                            'label': option.label
                        };
                    });
                }
                this.options = options;
                if(options.length > 0) this.props.onNewOptions(options);
                else if(this.props.field.options.length > 0) this.props.onNewOptions(this.props.field.options);

                if(!hasSettings) {
                    let ops = this.props.field.options;
                    ops = ops.filter(item => item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);
                    callback(ops);
                }
                else callback(options ? options : this.props.field.options);
            });
        }else{
            let ops = this.props.field.options;
            ops = ops.filter(item => item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);
            callback(ops);
        }
    }

    render() {
        const customStyles = {
            valueContainer: (base, state) => ({
                ...base,
                height: this.props.field.height ? this.props.field.height : '65px',
                overflowY: 'auto'
            }),
        };
        let isDisabled = this.props.field.isDisabled ? this.props.field.isDisabled : false;


        if(this.props.field.id === 'locIds'){
            if(this.state.selectAll){
               isDisabled = true;
           }else{
                if(this.props.formData.paymentMethods && this.props.formData.paymentMethods.length > 0){
                    isDisabled = true;
                }else {
                    isDisabled = false;
                }
            }
        }

        if(this.props.field.id === 'paymentMethods'){
             if(this.state.selectAll){
                isDisabled = true;
            }else{
                if(this.props.formData.locIds && this.props.formData.locIds.length > 0){
                    isDisabled = true;
                }else {
                    isDisabled = false;
                }
            }
        }

        if(this.props.field.id === 'custIds'){
            if(this.state.selectAll){
                isDisabled = true;
            }else{
                if(this.props.formData.accIds && this.props.formData.accIds.length > 0){
                    isDisabled = true;
                }else {
                    isDisabled = false;
                }
            }
        }
        if(this.props.field.id === 'accIds'){
            if(this.state.selectAll){
                isDisabled = true;
            }else{
                if(this.props.formData.grpIds && this.props.formData.grpIds.length > 0){
                    isDisabled = true;
                }else if(this.props.formData.custIds && this.props.formData.custIds.length > 0){
                    isDisabled = true;
                }else{
                    isDisabled = false;
                }
            }
        }

        if(this.props.field.id === 'grpIds'){
            if(this.props.formData.accIds && this.props.formData.accIds.length > 0){
                isDisabled = true;
            }else {
                isDisabled = false;
            }
        }

        if(this.props.field.id === 'catIds'){
            if(this.state.selectAll){
                isDisabled = true;
            }else{
                if((this.props.formData.itmIds && this.props.formData.itmIds.length > 0) || (this.props.formData.itemSuppliers && this.props.formData.itemSuppliers.length > 0)){
                    isDisabled = true;
                }else{
                    isDisabled = false;
                }
            }
        }
        if(this.props.field.id === 'itmIds'){
            if(this.state.selectAll){
                isDisabled = true;
            }else{
                if((this.props.formData.catIds && this.props.formData.catIds.length > 0) || (this.props.formData.itemSuppliers && this.props.formData.itemSuppliers.length > 0)){
                    isDisabled = true;
                }else{
                    isDisabled = false;
                }
            }
        }
        if(this.props.field.id === 'itemSuppliers'){
            if(this.state.selectAll){
                isDisabled = true;
            }else{
                if((this.props.formData.itmIds && this.props.formData.itmIds.length > 0) || (this.props.formData.catIds && this.props.formData.catIds.length > 0)){
                    isDisabled = true;
                }else{
                    isDisabled = false;
                }
            }
        }
        if(this.props.field.id === 'inventoryType'){
            if(this.state.selectAll){
                isDisabled = true;
            }else{
                isDisabled = false;
            }
        }
        if(this.props.field.id === 'addrIds'){
            if(this.state.selectAll){
                isDisabled = true;
            }else{
                isDisabled = false;
            }
        }

        if(this.props.field.id === 'visibleColumns'){
            if(this.state.selectAll){
                isDisabled = true;
            }else{
                isDisabled = false;
            }
        }

        return (
            <React.Fragment>
                <div className="inlineHover input-select2">
                    <div className={this.props.field.id === 'visibleColumns' ? "col-lg-6 col-md-6 col-xs-12 no-gutter" : "col-lg-12 col-md-12 col-xs-12 no-gutter"}>
                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter" onMouseLeave={this.closeUnchangedField} onClick={()=>this.setState({isChanged:true})}>
                            <AsyncSelect
                                isMulti={true}
                                isSearchable
                                closeMenuOnSelect={false}
                                value={this.state.value}
                                onChange={this.changeValue}
                                options={this.props.field.options}
                                isDisabled={isDisabled}
                                styles={customStyles}
                                loadOptions={this.loadOptions}
                                defaultOptions={this.props.field.options}
                                isClearable={true}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        this.saveField()
                                    }
                                }}
                            />
                        </div>
                        {this.props.field.settings.checkbox && this.props.field.settings.checkbox.show &&
                            <div className="col-lg-5 col-md-5 col-xs-5 no-gutter" style={{ display:"flex"}}>
                                <input type="checkbox" id="selectAll" onChange={this.handleCheckboxChange} value="selectAll" checked={this.state.selectAll} className="selectAll" style={{marginRight: 5}}/>
                                <label>{plainTranslate(this.props.settings.locale, 'All')}</label>
                            </div>
                        }
                    </div>
                <div className="col-lg-4 col-md-4 col-xs-12 no-gutter" style={{marginLeft:40}}>
                    {this.props.field.id === 'visibleColumns' && this.props.input.value.length > 0 && this.props.input.value !== 'selectAll' && this.props.input.value.map(value => {
                        let colName = null;
                        if(value.hasOwnProperty('value')){
                            colName = value.value;
                        }else{
                            colName = value;
                        }

                        let selectedAction = this.state.totals.find(o => o.field === colName);
                        if(selectedAction){
                            return <div className="col-lg-12 col-md-12 col-xs-12 no-gutter" style={{marginBottom: 20}}>
                                <span style={{marginRight: 20}}>{plainTranslate(this.props.settings.locale, selectedAction.field)}</span>
                                <Radio.Group style={{float: "right"}} name={selectedAction.field} defaultValue={selectedAction.value} onChange={this.onRadioButtonChange}>
                                    <Radio.Button value="sum">SUM</Radio.Button>
                                    <Radio.Button value="avg">AVG</Radio.Button>
                                </Radio.Group>
                            </div>
                        }
                    })}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

MultiSelect.defaultProps = {
    onNewOptions: () => {},
};

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelect);
