import React from 'react';
import {connect} from 'react-redux';
import showAToast from '../../common/showAToast';
import client from '../../../actions/client';
import {plainTranslate} from "../../common/translate";

class Number extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ''
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
    
        this.setState({
            value: this.props.data !== '' && this.props.data && this.props.data !== undefined ? this.props.data : ''
        });

        var allElements = document.getElementsByTagName("label");
        let price = null;
        let discountPercent= null;
        let minAmount = null;
        let minQty = null;
       
        for(let i = 0; i < allElements.length; i++){
            if(allElements[i].textContent == 'Price'){
                price = allElements[i];
            }
            if(allElements[i].textContent == 'Discount Percent'){
                discountPercent = allElements[i];
            }
            if(allElements[i].textContent == 'Minimum Quantity'){
                minQty = allElements[i];
            }
            if(allElements[i].textContent == 'Minimum Amount'){
                minAmount = allElements[i];
            }
        }
        
        if(price && discountPercent && minQty && minAmount){
            let priceInput = price.nextSibling.getElementsByTagName("input");
            let discountPercentInput = discountPercent.nextSibling.getElementsByTagName("input");
            let minAmountInput = minAmount.nextSibling.getElementsByTagName("input");
            let minQtyInput = minQty.nextSibling.getElementsByTagName("input");

            if(priceInput[0].value.length > 0){
                discountPercentInput[0].disabled=true;
            }else{
                discountPercentInput[0].disabled=false;
            }
            if(discountPercentInput[0].value.length > 0){
                priceInput[0].disabled=true;
            }else{
                 priceInput[0].disabled=false;
            }
            if(minAmountInput[0].value.length > 0){
                minQtyInput[0].disabled=true;
            }else{
                 minQtyInput[0].disabled=false;
            }
            if(minQtyInput[0].value.length > 0){
                minAmountInput[0].disabled=true;
            }else{
                 minAmountInput[0].disabled=false;
            }

        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data && prevProps.data !== this.state.value) {
         //   this.setState({
              //  value: this.props.data !== undefined ? this.props.data : ''
          //  });

        }
    }
    
    numberScale(number, scale) {
        let float = parseFloat(number);
        float = float.toFixed(scale ? scale : 2);
        
        return float;
    }

    onChange(e) {
        var allElements = document.getElementsByTagName("label");
        let price = null;
        let discountPercent= null;
        let minAmount = null;
        let minQty = null;

        for(let i = 0; i < allElements.length; i++){
            if(allElements[i].textContent == 'Price'){
                price = allElements[i];
            }
            if(allElements[i].textContent == 'Discount Percent'){
                discountPercent = allElements[i];
            }
            if(allElements[i].textContent == 'Minimum Quantity'){
                minQty = allElements[i];
            }
            if(allElements[i].textContent == 'Minimum Amount'){
                minAmount = allElements[i];
            }
        }

        if(price && discountPercent && minQty && minAmount){
            let priceInput = price.nextSibling.getElementsByTagName("input");
            let discountPercentInput = discountPercent.nextSibling.getElementsByTagName("input");
            let minAmountInput = minAmount.nextSibling.getElementsByTagName("input");
            let minQtyInput = minQty.nextSibling.getElementsByTagName("input");

            if(priceInput[0].value.length > 0){
                discountPercentInput[0].disabled=true;
            }else{
                discountPercentInput[0].disabled=false;
            }
            if(discountPercentInput[0].value.length > 0){
                priceInput[0].disabled=true;
            }else{
                 priceInput[0].disabled=false;
            }
            if(minAmountInput[0].value.length > 0){
                minQtyInput[0].disabled=true;
            }else{
                 minQtyInput[0].disabled=false;
            }
            if(minQtyInput[0].value.length > 0){
                minAmountInput[0].disabled=true;
            }else{
                 minAmountInput[0].disabled=false;
            }

        }

        //if(price && discountPercent && minQty && minAmount){
            if(e.target.value === '') {
                if(this.state.value.toString().length === 1 && this.state.value !== 0) {
                    this.setState({value: null});
                    if(this.props.line && this.props.formData.lines){
                let lines = this.props.formData.lines;
                lines[this.props.index][this.props.field.id] = null;
                this.props.change('lines', lines)
            }else{
                    this.props.change(this.props.field.id, null);
                }
                }
                //return;
            }
/*        }else{
            if(e.target.value === '') {
                if(this.state.value.toString().length === 1 && this.state.value !== 0) {
                    this.setState({value: 0});
                    this.props.input.onChange(0);
                }
                //return;
            }
        }*/
        let value = null;

        if(e.target.value === ''){
             value = null;
        }else{
            value = parseFloat(e.target.value);

            if (isNaN(value)) {
                return;
            }
        }

        this.setState({
            value: value
        });
        if(this.props.line && this.props.formData.lines){
                let lines = this.props.formData.lines;
                lines[this.props.index][this.props.field.id] = value;
                this.props.change('lines', lines)
            }else{
        this.props.change(this.props.name, value);
    }
     //   this.props.change(value);
        
        if (this.props.field.hasOwnProperty('connectedFields')) {
            let addVal = {};
            let jsonData = [];
            if (this.props.field.connectedFields.hasOwnProperty('dependency')) {
                this.props.field.connectedFields.dependency.map(field => {
                    if (field == 'itId') {
                        let numb = (this.props.name).match(/\d/g);  
                        addVal['itId'] = this.props.formData.lines[numb]['itId'];
                    } else {
                        addVal[field] = this.props.formData[field];
                    }
                })
            }
               

            let line='';
            if (this.props.field.connectedFields.isLine) {
                   let ind = (this.props.name).indexOf('.'+this.props.field.id);
                   line = (this.props.name).substring(0, ind);
                   addVal['pos'] = line;
                   if (this.props.field.connectedFields.hasOwnProperty('lineDependency')) {
                        let numb = (this.props.name).match(/^(\D*)(\d+)/)[2];
                        this.props.field.connectedFields.lineDependency.map(field => {
                             addVal[field] = this.props.formData.lines[numb][field];
                     })
                   }
                }

            addVal['body'] = value;
            jsonData.push(JSON.stringify(addVal));

            let fields = this.props.field.connectedFields.fields;
            client.post(this.props.field.connectedFields.optionsUrl, addVal)
                    .then(response => response.data)
                    .then(response => {
                        fields.map(field => {
                            if (typeof(response[field])=== 'string') {
                                if(field === 'totalDiscount'){
                                    if(response[field] !== this.props.formData.totalDiscountRate){
                                        showAToast('Total discount changed to '+response[field]+'%', 'success2', 'success')                                        
                                    }
                                    this.props.change('totalDiscountRate', response[field] ? response[field] : null)
                                }else{
                                    this.props.change(line+field, response[field] ? response[field] : null)
                                }
                            } 
                        })
                    })
        }

        if (this.props.field.hasOwnProperty('checkStockQuantity') && this.props.field.hasOwnProperty('disabledAvailabilityStage') && 
                ((Array.isArray(this.props.field.disabledAvailabilityStage)
                && !this.props.field.disabledAvailabilityStage.includes(this.props.formData['stageId'])))) {
            if (this.props.field.checkStockQuantity !== false) {
                let addVal = {};
                let jsonData = [];
                if (this.props.field.checkStockQuantity.hasOwnProperty('dependency')) {
                    this.props.field.checkStockQuantity.dependency.map(field => {
                        if (field == 'itId') {
                            let numb = (this.props.name).match(/^(\D*)(\d+)/)[2];
                            addVal['itId'] = this.props.formData.lines[numb]['itId'];
                        } else {
                            addVal[field] = this.props.formData[field];
                        }
                    })
                }
                addVal['body'] = value;
                jsonData.push(JSON.stringify(addVal));

                let line = '';
                if (this.props.field.checkStockQuantity.isLine) {
                    let ind = (this.props.name).indexOf('.' + this.props.field.id);
                    line = (this.props.name).substring(0, ind);

                }
                let field = this.props.field.checkStockQuantity.field;
                let numb = (this.props.name).match(/^(\D*)(\d+)/)[2];
                let maxQuantity = (this.props.formData.lines[numb][this.props.field.checkStockQuantity.field]);

                if(this.props.formData.lines[numb]['batch'] !== null && this.props.formData.lines[numb]['batch'] !== ""){
                    maxQuantity = this.props.formData.lines[numb]['availableQuantity'];
                }
                if(this.props.formData.isPos === true && this.props.formData.lines[numb]['batch'] !== null){
                    maxQuantity = this.props.formData.lines[numb]['availableQuantity'];
                }

                let negativeQty = (this.props.formData.lines[numb]['negativeQty']);
                let orderedQty = (this.props.formData.lines[numb]['quantityOrdered']) ? true : false;

                if (typeof maxQuantity == 'undefined' ||  maxQuantity == null) {
                    client.post(this.props.field.checkStockQuantity.optionsUrl, addVal)
                            .then(response => response.data)
                            .then(response => {
                                if (response) {
                                    this.props.change(line + field, response['response'] ? response['response'] : null);
                                    this.props.change(line + 'negativeQty',  response['negative'] ? response['negative'] : false);
                                    this.checkQuantity(value, parseFloat(response['response']), response['negative']);
                                    this.checkQuantity(value, response['availableQuantity'] ? parseFloat(response['availableQuantity']) : parseFloat(response['response']), response['negative'], orderedQty);
                                }
                            })
                            .catch(err => {
                                showAToast('Error!', 'success2', 'error') 
                            })
                } else {
                    this.checkQuantity(value, maxQuantity, negativeQty, orderedQty);
                }
            }
        }
        
        if (this.props.field.hasOwnProperty('calculateQty')) {
            let addVal = {};
            let jsonData = [];
            let line='';
            
            if (this.props.field.calculateQty.isLine) {
                   let ind = (this.props.name).indexOf('.'+this.props.field.id);                   
                   line = (this.props.name).substring(0, ind);
                   addVal['pos'] = line;
                }
                
            if (this.props.field.calculateQty.hasOwnProperty('dependency')) {
                this.props.field.calculateQty.dependency.map(field => {
                    let numb = line.match(/\d/g);
                    
                    if (this.props.field.calculateQty.isLine){
                     //   addVal[field] = this.props.formData.lines[numb][field];
                    } else {
                        addVal[field] = this.props.formData[field];
                    }
                })
            }

            addVal['body'] = value;
            jsonData.push(JSON.stringify(addVal));

            let fields = this.props.field.calculateQty.fields;
            client.post(this.props.field.calculateQty.optionsUrl, addVal)
                    .then(response => response.data)
                    .then(response => {
                        fields.map(field => {
                                    this.props.change(line+field, response ? response : null)
                        })
                    })
        }
    }
    
     
    checkQuantity(value, maxQuantity, negativeQty, orderedQty = false) {
        if (!orderedQty && value > maxQuantity && negativeQty == 0) {
    
                if (isNaN(value)) {
                    return;
                }
                if (maxQuantity < 0) {
                    maxQuantity = 0;
                }
                this.setState({
                    value: maxQuantity
                });

               // this.props.input.onChange(maxQuantity);
               showAToast(plainTranslate(this.props.settings.locale, 'Insufficient quantity'), 'errSettings', 'error');
            }
    }

    render() {
        let min = '';
        let max = '';
        const rules = this.props.field.rules;
        if (rules) {
            min = 'min' in rules ? rules.min : '';
            max = 'max' in rules ? rules.max : '';
        }
       
        return <React.Fragment> 
        {(this.props.clicked === this.props.index  && this.props.line) || !this.props.line ?
                <span className="form-input-field">
                    <input type="number"
                           step="any"
                           className={"form-control " + (this.props.highlightField ? this.props.highlightField : '')}
                           value={this.state.value}
                           onChange={this.onChange}
                           min={min}
                           max={max}
                           style={{minWidth: this.props.field.width ? this.props.field.width : 'unset'}}
                           disabled={this.props.field.disabled}
                           tabIndex={this.props.field.tabindex ? this.props.field.tabindex : ''}
                           name={this.props.name}
                    />  
                </span>
                :
           <span className={'tableSpan'}>
    {this.state.value}</span>}
     </React.Fragment>              
        
    }
}

const mapStateToProps = state => ({
   // formData: state,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Number);

