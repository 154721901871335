import React, {Component} from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';
import { Table, InputNumber, Button } from 'antd';
import FormLoader from "../../forms/form-loader";
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';

class MinQtyTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            isClicked: false,
            color: null
        };

        this.onChange = this.onChange.bind(this);
        this.generateTransfer = this.generateTransfer.bind(this);
    }

    componentDidMount() {
    }

    onChange(val,record){
        record['Quantity To Be Processed'] = val;
        let color = Number(record['availabilityFrom']) < Number(record['Quantity To Be Processed']) ? '#ff000047' : '#6ce5bb80';
        this.setState({color: color});
    }

    generateTransfer(){
        this.setState({isClicked: true});
        let action = this.props.actions.find(o => o.popup == "minQuantity");

        client
        .post(action.link, {
            'body': this.props.data,
            'selectAll': false,
            'isEditTransfer': true
        })
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
            }
            if ('success' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
                this.props.update();
                this.props.onClose();
            
                if (response.redirectUrl) {
                    if(response.newTab){
                        window.open(response.redirectUrl, '_blank');
                    }else{
                        window.location = response.redirectUrl;
                    }
                }
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    render() {

        const cols = [
            {
                'key': 'itemNo',
                'dataIndex' : 'itemNo',
                'title' : plainTranslate(this.props.settings.locale, 'Item No'),
            },
            {
                'key': 'itemTitle',
                'dataIndex' : 'itemTitle',
                'title' : plainTranslate(this.props.settings.locale, 'Item Title'),
            },
            {
                'key': 'availabilityFrom',
                'dataIndex' : 'availabilityFrom',
                'align': 'right',
                'title' : plainTranslate(this.props.settings.locale, 'Availability From'),
            },
            {
                'key': 'availabilityTo',
                'dataIndex' : 'availabilityTo',
                'align': 'right',
                'title' : plainTranslate(this.props.settings.locale, 'Availability To'),
            },
            {
                'key': 'minQtyLocationFrom',
                'dataIndex' : 'minQtyLocationFrom',
                'align': 'right',
                'title' : plainTranslate(this.props.settings.locale, 'Min Qty Location From'),
            },
            {
                'key': 'OrderQuantity',
                'dataIndex' : 'OrderQuantity',
                'align': 'right',
                'title' : plainTranslate(this.props.settings.locale, 'Requested Qty'),
            },
            {
                'key': 'transferedQty',
                'dataIndex' : 'transferedQty',
                'align': 'right',
                'title' : plainTranslate(this.props.settings.locale, 'Transfered Qty'),
            },
            {
                'key': 'Quantity To Be Processed',
                'dataIndex' : 'Quantity To Be Processed',
                'align': 'right',
                'title' : plainTranslate(this.props.settings.locale, 'Quantity To Be Processed'),
                render: (text, record) => {
                    let color = Number(record['availabilityFrom']) < Number(record['Quantity To Be Processed']) ? '#ff000047' : '#6ce5bb80';
                    return(<InputNumber style={{backgroundColor: this.state.color ? this.state.color : color}} onChange={(val) => this.onChange(val,record)} defaultValue={record['Quantity To Be Processed']}/>)
                }
            },
        ];

        return(
                <section className="newPanel">
                    {!this.state.showLoader &&
                        <div>
                            <Table 
                                rowKey={record => record.id} 
                                columns={cols} 
                                dataSource={this.props.data} 
                                pagination={{
                                  pageSize: 20,
                                  showSizeChanger:false
                                }}
                            />
                            <button className="button-primary" type="button" id="generateTransfer" disabled = {this.state.isClicked ? 'disabled' :  '' } className={"button-primary " + (this.state.isClicked ? 'disabled' : '')}
                                    onClick={this.generateTransfer} dis>
                                <Translate locale={this.props.settings.locale} value="Generate transfer"/>
                            </button>
                        </div>
                    }
                    {this.state.showLoader &&
                        <FormLoader/>
                    }
                </section>
        )
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MinQtyTable);