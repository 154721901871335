import {GRID_API_LOAD, GRID_DATA_LOADED, GRID_HEADER_OPTION_SELECTED} from "../constants/actionTypes";

export default (state = [], action) => {
  
    switch (action.type) {
        case GRID_DATA_LOADED:
            return {
                ...state,
                data: action.payload.data,
                pinnedRows: action.payload.pinnedRows,
                pages: action.payload.pages,
                totalRecords: action.payload.totalRecords
            };


        default:
            return state;
    }
};