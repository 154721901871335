import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import ListCheckboxes from './../listCheckboxes.jsx';

class ModulesPage extends Component {

    constructor(props) {
    super(props);
            this.state = {
            showMessage: false,
            };
       this.allModules = [
            { value: "deals", name: 'Deals', text: 'Organize, track and manage your deals'},
            { value: "sales", name: 'Sales', text: 'Organize, track and manage your sales process'},
            { value: "invoice", name: 'Invoices', text: 'Оnline billing and invoicing tools'},
            { value: "expense", name: 'Expenses', text: 'Organize, track and manage your expenses'},
            { value: "payments", name: 'Payments', text: 'Process, manage and track payments from your customers'},
            { value: "inventory", name: 'Inventory', text: 'Control and run your inventory, in one place'},
            { value: "accounting", name: 'Accounting', text: 'Easily manage business finances'},
            { value: "pos", name: 'Point of Sale', text: 'Powerful Point of Sale system'},
            { value: "personnel", name: 'Personnel', text: 'Enables you to organize business processes for employee management and administration'},
            { value: "project", name: 'Projects and Tasks', text: 'Plan, track and assign project tasks with your team'},
            { value: "itemRequests", name: 'Item requests', text: 'Streamlines the purchasing process by linking to other modules'},
            { value: "production", name: 'Production', text: 'Enables you to improve planning, production operations and production'},
            { value: "custom_data", name: 'Custom Data', text: 'Custom data allows customization. Add additional information to an Account Profile in a custom layout.'},
            { value: "content", name: 'Content', text: 'Content'},
            { value: "documents", name: 'Documents', text: 'Your documents securely stored in the cloud.'},
            { value: "messages", name: 'Messages', text: 'Organize, track and manage your messages.'},
        ];
    }

    render() {
    return (
        <React.Fragment>
            <div className="moduleSubMenu">
                <div className="col-sm-12">
                    <h3 className="module-title pull-left">{plainTranslate(this.props.settings.locale, 'Modules')}</h3>
                </div>
            </div>
            <div className="col-sm-12 noPadding">
                <div className="col-sm-8">
                    <h3 id="paid">{plainTranslate(this.props.settings.locale, 'Choose modules to activate')}</h3>
                </div>
                <div className="col-sm-4">
                    <h4>{plainTranslate(this.props.settings.locale, 'Want to upgrade your plan?')}</h4>
                    <p className="inline">{plainTranslate(this.props.settings.locale, 'To see all plans, click')}</p><a style={{"color":"red"}} href={'/'+ this.props.match.params.db + '/subscription-details'}>{plainTranslate(this.props.settings.locale, ' here')}</a>
                </div>
                <div className="col-sm-6 column-centered noPadding">
                        <ListCheckboxes
                            id={'modules'}
                            value={this.props.settings.plan}
                            label={'Modules'}
                            settings={this.props.settings}
                            fields={this.allModules}
                            saveRoute={'/' + this.props.match.params.db + '/api/onboarding/save/modules/data'}
                            setting = {'plan'}
                        />
                </div>
            </div>
        </React.Fragment>
            );
        }
    }

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(ModulesPage);
