import React, {useState, useEffect} from 'react'
import reactCSS from 'reactcss'
import { ChromePicker, TwitterPicker, BlockPicker, SketchPicker } from 'react-color'
import { CloseCircleOutlined } from '@ant-design/icons'
const ColorPicker = (props) => {
    const [open, setOpen] = useState(false);
    const [color, setColor] = useState("#fff");
    useEffect(() => { 
        if(props.value){
            setColor(props.value)
        }
        
    }, [props.value])

  const handleClick = () => {
    setOpen(!open)
  };

  const handleClose = () => {
    setOpen(false)
  };

  const handleChange = (color, event) => {
    setColor(color.hex)
    props.onHandleChange(color.hex, props.number)
  };
  
  const pickerStyles = {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    overflow: 'visible',

  };

  const  colorStyle = {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: color,
        }
        const swatch = {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        };
        const  popover = {
          position: 'absolute',
          zIndex: '2',
          transform: 'translate(259px, 0px)'
        };
        const cover = {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        };
     
    return (
      <div>
        <div style={swatch } onClick={handleClick }>
          <div style={colorStyle }/>
        </div>
        {open ? <div className='color_picker'>
          <div style={popover}><CloseCircleOutlined onClick={handleClose}/></div>
          <TwitterPicker 
          positionCSS={ pickerStyles } 
          colors={['#ffff66', '#ffff00', '#ff6600', '#ff4d4d', '#ff0000', '#5cd65c', '#29a329', '#66a3ff', '#0052cc', '#c2c2d6']}
          color={color} 
          onChange={(color, event) => handleChange(color, event)} />
        </div> : null }

      </div>
    )
  }


export default ColorPicker