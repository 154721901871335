import React, {Component} from 'react';
import Translate, {plainTranslate} from '../common/translate';
import {connect} from 'react-redux';
import {SETTINGS_CHANGE} from "../../constants/actionTypes";
import showAToast from '../common/showAToast';
import client from '../../actions/client';

class Attributes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showButton: true
        };
        this.addNewRow = this.addNewRow.bind(this);
        this.save = this.save.bind(this);
        this.onChange = this.onChange.bind(this);
        this.removeRow = this.removeRow.bind(this);
    }
    
    addNewRow() {
        let attributes = this.props.settings[this.props.type];
        let count = this.props.count;
        let missing = [];
        let l = this.props.settings[this.props.type].length;
        let a = [];
        let pos;
        for(let i=0; i < l; i++) {
            pos = (attributes[i].position).replace(/[^0-9]/g,'');
            a.push(parseInt(pos));
        }

        for(let i=1; i<=count; i++) {
            if(a.indexOf(i) == -1){
                missing.push(i);
            }
        }
        
        let newAttr = {position: 'attribute'+(missing[0]), text: ''};
        attributes.splice((missing[0]-1), 0, newAttr);

        this.setState({
            attributes
        });
        if(l === (count - 1)){
            this.setState({
                showButton: false
            });

            showAToast(plainTranslate(this.props.settings.locale, 'You have reached the maximum allowed number of attributes.'), 'succSettings', 'success');
        }
    }
    
    removeRow(key) {
        let attributes = this.props.settings[this.props.type];
        attributes.splice(key,1);

        
        this.setState({
            attributes
        });

        if(attributes.filter(o=>o).length < 8){
            this.setState({
                showButton: true
            });
        }
    }
    
    onChange(e, type, key) {
        let attributes = this.props.settings[this.props.type];
        attributes[key][type] = e.target.value;
        this.props.onChangeSettings(this.props.type, attributes, this.props.type);
    }
    
    save() {
        let attributes = this.props.settings[this.props.type];
            client.post(this.props.saveRoute, {
                    'attributes': attributes,
                })
            .then(response => response.data)
            .then(response => {
                showAToast(plainTranslate(this.props.settings.locale, 'Attributes saved successfully!'), 'succSettings', 'success');
                }
            );
    }

    render() {
        return (
                <div>
                    <div className="col-md-10 setting-item" style={{marginLeft: "-10px"}}>
                        <label>
                            <Translate locale={this.props.settings.locale} value={this.props.label}/>
                        </label>
                        <p>
                        <Translate locale={this.props.settings.locale} value={this.props.text}/>
                        </p>
                    </div>
                    <div className='col-md-4 col-xs-12 no-gutter-m no-gutter custom-table' style={{marginTop: 30, marginLeft: 20}}>
                        <span className="form-input-field tableCollection stages">
                
                            <table>
                                <thead>
                                    <tr>
                                        <td className="text-left">
                                            {plainTranslate(this.props.settings.locale, "Attribute")}
                                        </td>
                                        <td className="text-left">
                                            {plainTranslate(this.props.settings.locale, "Custom title")}
                                        </td>
                                        <td className={'right tableHeader'}></td>
                                    </tr>
                                </thead>
                                <tbody> 
                                    {this.props.settings && this.props.settings[this.props.type] && this.props.settings[this.props.type].map((attribute, key) =>            
                                    <tr key={key}>
                                        <td className={'rowNumber'}>{plainTranslate(this.props.settings.locale, "Attribute")} {key+1}</td>   
                                        <td>
                                            <input className="form-control form-stage" value={attribute.text} onChange={(e) => this.onChange(e, 'text', key)}/>
                                        </td>
                                        <td className={'right'}>
                                            <span onClick = {() => this.removeRow(key)}>
                                                <i className='ion-android-close' title={plainTranslate(this.props.settings.locale, 'Remove row')}></i></span>
                                        </td>
                                    </tr> 
                                    )}
                                </tbody>
                                <tfoot>
                                {this.state.showButton && 
                                    <tr className="text-center">
                                        <th colSpan="2">
                                            <input className="button-outline grey text-uppercase" id="add_new_row" type="button" onClick = {this.addNewRow} value={plainTranslate(this.props.settings.locale, 'New row')}/>
                                        </th>
                                    </tr>
                                }
                                </tfoot>
                            </table>
                
                            <div className="pull-right">
                                <input className="button-primary grey text-uppercase" id="add_new_row" type="button" onClick = {this.save} value={plainTranslate(this.props.settings.locale, 'Save attributes')}/>
                            </div>
                        </span>
                    </div>
                </div>
                                );
                    }
                }

        const mapStateToProps = state => ({
                settings: state.settings,
            });
        const mapDispatchToProps = dispatch => ({
                onChangeSettings: (setting, value, type) => dispatch({type: SETTINGS_CHANGE, payload: {setting: setting, value: value, type: type}}),
            });

        export default connect(mapStateToProps, mapDispatchToProps)(Attributes);

