import React, { Component } from 'react';
import { plainTranslate } from '../common/translate';
import { connect } from 'react-redux';
import FormLoader from "../forms/form-loader";
import { Table, Statistic, Card ,Tabs, Input, Button, Select,  Dropdown, Menu,Space, Drawer, Pagination} from 'antd';
import { SearchOutlined, HistoryOutlined, UserOutlined, ScheduleOutlined, HddOutlined } from '@ant-design/icons'
import { XYPlot, makeWidthFlexible, AreaSeries, LineSeries, XAxis, YAxis, Crosshair } from 'react-vis';
import moment from 'moment';
import { DatePicker as DatePickerComponent } from 'antd';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import 'react-vis/dist/style.css';
import 'antd/lib/date-picker/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/icon/style/index.css';
import 'antd/lib/spin/style/index.css';
import './dashboard.css';
import Onboarding from "../onboarding/onboarding.jsx";
import { onDatesChange } from '../../actions/actions';
import { Spin } from 'antd';
import client from '../../actions/client';
import showAToast from '../common/showAToast';
import { LeftOutlined, RightOutlined, DownOutlined } from '@ant-design/icons';
import { logoutUser } from "../../actions/authActions";
import SlideModal from '../common/SlideModal';
import SimpleForm from "../redux-forms/form";
import TaskProfile from "../profiles/taskProfile";

const { Option } = Select;

const { SubMenu } = Menu;

const { RangePicker } = DatePickerComponent;

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // startDate: localStorage.getItem('grid_range') ? moment().subtract(localStorage.getItem('grid_range'), 'days') : moment().subtract(1, 'weeks'),
            // endDate: moment(),
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            profitloss: null,
            saleRevenue: null,
            paymentBalance: null,
            saleRevenueReady: true,
            saleRevenueTotalReady: true,
            profitLossReady: true,
            paymentBalanceReady: true,
            tasksReady: true,
            watchingReady: true,
            activitiesReady: true,
            salesCountReady: true,
            salesReady: true,
            inventoriesReady: true,
            invoicesReady: true,
            paidCount: 0,
            salesCount: 0,
            activeTab: 'Sales',
            tasksTab: 'My tasks',
            currentPage: 1
        };

        this.handleDatesChange = this.handleDatesChange.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.updateData = this.updateData.bind(this);
        this.onTaskTabChange = this.onTaskTabChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    componentDidMount() {
        document.title = 'Dashboard';
        if (this.props.settings.reports) {
            this.updateData();
        }

        let web = this.props.auth.user.roles ? this.props.auth.user.roles.find(o => o === 'ROLE_WEB') : null;
        let admin = this.props.auth.user.roles ? this.props.auth.user.roles.find(o => o === 'ROLE_ADMIN') : null;
        let superAdmin = this.props.auth.user.roles ? this.props.auth.user.roles.find(o => o === 'ROLE_SUPER_ADMIN') : null;
        let user = this.props.auth.user.roles ? this.props.auth.user.roles.find(o => o === 'ROLE_USER') : null;

        if (web || user) {
            if (!admin && !superAdmin) {
                this.props.logoutUser(this.props.match.params.db);
            }
        }

        this.setState({ value: false });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.settings.reports !== this.props.settings.reports) {
            this.updateData();
        }

    }

    updateData(refresh = false) {
        if (this.props.settings.reports.predefinedReports.find(o => o.id === 'profitAndLoss') !== undefined) {
            this.fetchProfitLoss(this.state.startDate.format('DD/M/YYYY'), this.state.endDate.format('DD/M/YYYY'), refresh);
        }
        if (this.props.settings.reports.predefinedReports.find(o => o.id === 'saleRevenue') !== undefined) {
            this.fetchSaleRevenue(this.state.startDate.format('DD/M/YYYY'), this.state.endDate.format('DD/M/YYYY'), refresh);
        }
        if (this.props.settings.reports.predefinedReports.find(o => o.id === 'paymentBalance') !== undefined) {
            this.fetchPaymentBalance(this.state.startDate.format('DD/M/YYYY'), this.state.endDate.format('DD/M/YYYY'), refresh);
        }
        if (!this.props.settings.roles.includes('ROLE_SUPER_ADMIN')) {
            this.setState({ profitLossReady: true });
        }

        if (this.props.settings.bundles.salesbundle && this.props.settings.plan.sales) {
            this.fetchSales(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'), refresh);
        }

        this.fetchReminders(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'));

        if (this.props.settings.plan.project) {
            this.fetchTasks(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'));
        }
        if (this.props.settings.reports.predefinedReports.find(o => o.id === 'minQuantityInStock') !== undefined) {
            this.fetchMinQuantity();
        }
    }

    fetchProfitLoss(start, end, refresh) {
        this.setState({ profitLossReady: false });

        let visibleColumns = [];
        visibleColumns.push({ value: 'Amount Net', isExpression: true, action: 'sum' });
        visibleColumns.push({ value: 'Type', isExpression: false, action: null });

        client
            .post('/' + this.props.match.params.db + '/api/reports/profitLoss/dashboard', {
                body: {
                    startDate: start,
                    endDate: end,
                    locIds: 'selectAll',
                    visibleColumns: visibleColumns,
                    hasLimit: 'no-limit',
                    isDashboard: true,
                    name: 'DashboardPL',
                    refresh: refresh
                }
            })
            .then(response => response.data)
            .then(response => {
                this.setState({ profitloss: response.data, rounding: response.rounding, profitLossReady: true, updatedAt: response.refreshed });
            })
            .catch(error => { this.setState({ showLoader: false }) });

    }

    fetchSaleRevenue(start, end, refresh) {
        this.setState({ saleRevenueReady: false, salesCountReady: false });

        let visibleColumns = [];
        visibleColumns.push({ value: 'Location', isExpression: false, action: null });
        visibleColumns.push({ value: 'Amount Net', isExpression: true, action: 'sum' });
        visibleColumns.push({ value: 'Amount Gross', isExpression: true, action: 'sum' });

        client
            .post('/' + this.props.match.params.db + '/api/reports/revenue/dashboard-locations', {
                body: {
                    startDate: start,
                    endDate: end,
                    accIds: 'selectAll',
                    addrIds: 'selectAll',
                    itmIds: 'selectAll',
                    locIds: 'selectAll',
                    catIds: null,
                    visibleColumns: visibleColumns,
                    hasLimit: 'no-limit',
                    showNonItem: false,
                    name: 'DashboardSR1',
                    refresh: refresh
                },
                hasLimit: 'no-limit'
            })
            .then(response => response.data)
            .then(response => { this.setState({ saleRevenue: response.data, saleRevenueReady: true, updatedAt: response.refreshed }); })
            .catch(error => { this.setState({ showLoader: false }) });

        let totalVisibleColumns = [];
        totalVisibleColumns.push({ value: 'Location', isExpression: false, action: null });
        totalVisibleColumns.push({ value: 'Document No', isExpression: false, action: null });
        totalVisibleColumns.push({ value: 'Amount Net', isExpression: true, action: 'sum' });
        totalVisibleColumns.push({ value: 'Amount Gross', isExpression: true, action: 'sum' });

        client
            .post('/' + this.props.match.params.db + '/api/reports/revenue/dashboard-locations-count', {
                body: {
                    startDate: start,
                    endDate: end,
                    accIds: 'selectAll',
                    addrIds: 'selectAll',
                    itmIds: 'selectAll',
                    locIds: 'selectAll',
                    catIds: null,
                    visibleColumns: totalVisibleColumns,
                    hasLines: false,
                    showNonItem: false,
                    name: 'DashboardSR2',
                    refresh: refresh
                }
            })
            .then(response => response.data)
            .then(response => { this.setState({ salesCount: response.totalRecords, salesCountReady: true }); })
            .catch(error => { this.setState({ showLoader: false }) });

        client
            .post('/' + this.props.match.params.db + '/api/reports/revenue/dashboard-paid-count', {
                body: {
                    startDate: start,
                    endDate: end,
                    accIds: 'selectAll',
                    itmIds: 'selectAll',
                    addrIds: 'selectAll',
                    locIds: 'selectAll',
                    catIds: null,
                    visibleColumns: totalVisibleColumns,
                    hasLines: false,
                    showNonItem: false,
                    isDashboard: true,
                    name: 'DashboardSR3',
                    refresh: refresh
                }
            })
            .then(response => response.data)
            .then(response => { this.setState({ paidCount: response.totalRecords }); })
            .catch(error => { this.setState({ showLoader: false }) });
    }

    fetchPaymentBalance(start, end, refresh) {
        this.setState({ paymentBalanceReady: false });

        client
            .post('/' + this.props.match.params.db + '/api/reports/payment-balance/dataSource', {
                body: {
                    startDate: start,
                    endDate: end,
                    paymentMethods: 'selectAll',
                    visibleColumns: 'selectAll',
                    isDashboard: true,
                    name: 'DashboardPB',
                    refresh: refresh
                }
            })
            .then(response => response.data)
            .then(response => { this.setState({ paymentBalance: response, paymentBalanceReady: true, updatedAt: response.refreshed }); })
            .catch(error => { this.setState({ showLoader: false }) });
    }

    fetchSales(start, end, refresh) {
        let route = '/' + this.props.match.params.db + '/api/sales?startDate=' + start + '&endDate=' + end + ' 23:59:59&limit=10&&name=DashboardSales'
        this.setState({ salesReady: false });
        client
            .get(route)
            .then(response => response.data)
            .then(response => { this.setState({ sales: response, salesReady: true }) })
    }

    fetchInvoices(start, end) {
        let route = '/' + this.props.match.params.db + '/api/invoices?startDate=' + start + '&endDate=' + end + ' 23:59:59&limit=10';
        this.setState({ invoicesReady: false });
        client
            .get(route)
            .then(response => response.data)
            .then(response => { this.setState({ invoices: response, invoicesReady: true }) })
    }

    fetchInventories(start, end) {
        let route = '/' + this.props.match.params.db + '/api/inventories?startDate=' + start + '&endDate=' + end + ' 23:59:59&limit=10';
        this.setState({ inventoriesReady: false });
        client
            .get(route)
            .then(response => response.data)
            .then(response => { this.setState({ inventories: response, inventoriesReady: true }) })
    }

    fetchTasks(start, end) {
        let route = '/' + this.props.match.params.db + '/api/tasks?startDate=' + start + '&endDate=' + end + '&limit=10&isDashboard=true';
        this.setState({ tasksReady: false });

        client
            .get(route)
            .then(response => response.data)
            .then(response => { this.setState({ tasksData: response.data, tasksStages: response.stages, users: response.users, tasksReady: true }) })
            .catch(error => { this.setState({ showLoader: false }) });
    }

    fetchWatchingTasks(start, end) {
        let route = '/' + this.props.match.params.db + '/api/find/watching-tasks';
        this.setState({ watchingReady: false });

        client
            .get(route)
            .then(response => response.data)
            .then(response => { this.setState({ watchingData: response.data, tasksStages: response.stages, users: response.users, watchingReady: true }) })
            .catch(error => { this.setState({ showLoader: false }) });
    }

    fetchTaskActivities(start, end) {
        let route = '/' + this.props.match.params.db + '/api/dashboard/tasks/activities?limit=5&page='+this.state.currentPage;
        this.setState({ activitiesReady: false });

        client
            .get(route)
            .then(response => response.data)
            .then(response => { this.setState({ activitiesCount: response.count, activitiesData: response.data, tasksStages: response.stages, users: response.users, activitiesReady: true }) })
            .catch(error => { this.setState({ showLoader: false }) });
    }

    fetchReminders(start, end) {
        let route = '/' + this.props.match.params.db + '/api/dashboard/reminders?startDate=' + start + '&endDate=' + end + ' 23:59:59';
        this.setState({ remindersReady: false });
        client
            .get(route)
            .then(response => response.data)
            .then(response => { this.setState({ remindersData: response, remindersReady: true }) })
    }

    handleDatesChange(event) {
        let start = event[0];
        let end = event[1];

        this.setState({ startDate: start, endDate: end });

        let daysDiff = (moment(end).diff(moment(start), 'days'));
        if (daysDiff < 31) {
            localStorage.setItem('grid_range', daysDiff);
            this.props.onDatesChange(start, end);
        }

        start = start.format('DD/M/YYYY');
        end = end.format('DD/M/YYYY');

        if (this.props.settings.reports.predefinedReports.find(o => o.id === 'profitAndLoss') !== undefined) {
            this.fetchProfitLoss(start, end);
        }
        if (this.props.settings.reports.predefinedReports.find(o => o.id === 'saleRevenue') !== undefined) {
            this.fetchSaleRevenue(start, end);
        }
        if (this.props.settings.reports.predefinedReports.find(o => o.id === 'paymentBalance') !== undefined) {
            this.fetchPaymentBalance(start, end);
        }

        if (this.state.activeTab === 'Sales') {
            this.fetchSales(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'));
        }

        if (this.props.settings.bundles.invoicebundle && this.props.settings.plan.invoice) {
            this.fetchInvoices(event[0].format('YYYY-M-DD'), event[1].format('YYYY-M-DD'));
        }

        if (this.state.activeTab === 'Inventory') {
            this.fetchInventories(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'));
        }

        this.fetchTasks(event[0].format('YYYY-M-DD'), event[1].format('YYYY-M-DD'));
        this.fetchReminders(event[0].format('YYYY-M-DD'), event[1].format('YYYY-M-DD'));
    }

    onTabChange(val) {
        this.setState({ activeTab: val, sales: [], invoices: [], inventories: [], salesReady: false, invoicesReady: false, inventoriesReady: false });
        if (val === 'Sales') {
            this.fetchSales(moment(this.state.startDate).format('YYYY-M-DD'), moment(this.state.endDate).format('YYYY-M-DD'));
        }

        if (val === 'Invoice') {
            this.fetchInvoices(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'));
        }

        if (val === 'Inventory') {
            this.fetchInventories(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'));
        }
    }

    onTaskTabChange(val) {
        if(val !== this.state.tasksTab){
            this.setState({currentPage:1});
        }

        this.setState({ tasksTab: val, tasksData: [], watchingData: [], activitiesData: [], tasksReady: false, watchingReady: false, activitiesReady: false});

        if (val === 'My tasks') {
            this.fetchTasks(moment(this.state.startDate).format('YYYY-M-DD'), moment(this.state.endDate).format('YYYY-M-DD'));
        }

        if (val === 'Watched') {
            this.fetchWatchingTasks(moment(this.state.startDate).format('YYYY-M-DD'), moment(this.state.endDate).format('YYYY-M-DD'));
        }

        if (val === 'Task activities') {
            this.fetchTaskActivities(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'));
        }
    }

    handleChangeField(task, val, field) {
        client
            .post('/' + this.props.match.params.db + '/api/task/' + task + '/update-field', {
                id: field,
                value: val
            })
            .then(response => response.data)
            .then(response => {
                if ('errors' in response) {
                    showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
                }
                else {
                    // this.updateData();
                    if(this.state.tasksTab === 'My tasks'){
                        this.fetchTasks(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'));
                    }
                    else if(this.state.tasksTab === 'Watched'){
                        this.fetchWatchingTasks(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'));   
                    }
                    else if(this.state.tasksTab === 'Task activities'){
                        this.fetchTaskActivities(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'));   
                    }
                    showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
                }
            });
    }

    subTaskLogTime(id) {
        this.setState({ subTaskLogTime: true, taskId: id });
    }

    onSaveTask() {
        // this.updateData();
        this.fetchTasks(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'))
        this.setState({ isOpenTask: false, openCopyTask: false, editTask: false, editTaskId: null });
    }

    onPageChange(page) {
        this.setState({currentPage: page}, function() {
            this.onTaskTabChange(this.state.tasksTab);
        });
    }

    fetchMinQuantity() {
        let route = '/' + this.props.match.params.db + '/api/item/min-quantity-data'
        this.setState({ minQtyReady: false });
        client
            .get(route)
            .then(response => response.data)
            .then(response => { this.setState({ minQtyData: response.data, minQtyReady: true }) })
    }

    changeStatus(id) {
      client.get('/' + this.props.match.params.db + '/api/activity/notification/' + id + '/change-status')
      .then(response => response.data)
      .then(response => {
          this.fetchReminders(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'));
      })
      .catch(err => {
          console.log('error')
      });
    }

    snoozeReminder = (e, activity, value) => {
        if (e) {
            e.preventDefault();
        }

        client
        .post('/' + this.props.match.params.db + '/api/activity/notification/snooze', {
            value: value,
            id: activity.id
        })
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
            } else {
                showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
                this.fetchReminders(this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD'));
            }
        });
    }

    render() {
        // Extract unique locations
        var isInArray = (array, value) => {
            return !array.find(item => { return item.DocumentNo == value });
        }

        // Profit and Loss Statistics
        const profitloss = this.state.profitloss ? this.state.profitloss : [];
        const income_amount = profitloss ? profitloss.map(line => line.Type === '1. Income' ? line['Amount Net'] : 0).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) : 0;
        const expense_amount = profitloss ? profitloss.map(line => line.Type === '2. Cost of Goods Sold' || line.Type === '3. Expense' ? -('amountCOGS' in line ? Number(line['amountCOGS']) : Number(line['Amount Net'])) : 0).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) : 0;
        //const profit_amount = profitloss ? profitloss.map(line => line['Amount Net']).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) : 0;
        const profit_amount = income_amount - expense_amount;
        // Sale Revenue Statistics
        const saleRevenue = this.state.saleRevenue ? this.state.saleRevenue : [];
        const sales_amount = saleRevenue ? saleRevenue.map(line => line['Amount Gross']).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) : 0;
        var sales_count = this.state.salesCount ? this.state.salesCount : 0;

        // Payment Balance Statistics
        const paymentBalance = this.state.paymentBalance ? this.state.paymentBalance.data : null;
        const payment_amount = paymentBalance ? paymentBalance.map(line => line['Amount Gross']).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) : 0;
        const amount_due = sales_amount - payment_amount;

        // Roundings
        const rounding = this.state.rounding ? this.state.rounding : 2;

        const FlexibleWidthXYPlot = makeWidthFlexible(XYPlot);
        const { TabPane } = Tabs;

        const stats = [
            {
                title: 'Income',
                value: Number(income_amount).toFixed(2),
            },
            {
                title: 'Expense',
                value: Number(expense_amount).toFixed(2),
            },
            {
                title: 'Profit',
                value: Number(profit_amount).toFixed(2),
            },
            {
                title: 'Sales count',
                value: Number(sales_count).toFixed(0),
            },
            {
                title: 'Paid',
                value: Number(this.state.paidCount).toFixed(0),
            },
            {
                title: 'Unpaid',
                value: Number(sales_count - this.state.paidCount).toFixed(0),
            },
        ];

        // Table data
        const dataSource = saleRevenue ? saleRevenue.map((line, index) => {
            return {
                id: index,
                location: line['Location'],
                amountBase: line['Amount Net'],
                amountTotal: line['Amount Gross'],
            }
        }) : [];

        // Extract unique locations
        isInArray = (array, value) => {
            return !array.find(item => { return item.location == value });
        }

        var dataSource_keys = [];
        var dataSource_copy = [...dataSource];

        dataSource.forEach(line => {
            dataSource_copy.shift();
            if (isInArray(dataSource_copy, line.location)) {
                dataSource_keys.push(line.location);
            }
        });

        // Reduce plot data using unique plot dates
        var reduced_dataSource = dataSource_keys.map(key => {
            return dataSource.filter(line => line.location === key)
                .reduce((a, b) => {
                    return {
                        id: key,
                        location: key,
                        amountBase: parseFloat(a.amountBase) + parseFloat(b.amountBase),
                        amountTotal: parseFloat(a.amountTotal) + parseFloat(b.amountTotal),
                    }
                }, {
                    id: key,
                    location: key,
                    amountBase: 0,
                    amountTotal: 0,
                })
        });

        // Fix amounts
        reduced_dataSource = reduced_dataSource.map(line => {
            return {
                id: line.id,
                location: line.location,
                amountBase: line.amountBase.toLocaleString(undefined, { minimumFractionDigits: rounding }),
                amountTotal: line.amountTotal.toLocaleString(undefined, { minimumFractionDigits: rounding })
            }
        });

        // Plot data
        let income_plot = profitloss ? profitloss.filter(line => line.Type === '1. Income').map(line => {
            return {
                x: new Date(moment(line['Document Date']).format('YYYY/MM/DD')),
                y: Number(line['Amount Net'])
            }
        }) : [];

        let expense_plot = profitloss ? profitloss.filter(line => line.Type === '2. Cost of Goods Sold' || line.Type === '3. Expense').map(line => {
            return {
                x: new Date(moment(line['Document Date']).format('MM/DD/YYYY')),
                y: -('amountCOGS' in line ? Number(line['amountCOGS']) : Number(line['Amount Net']))
            }
        }) : [];

        let profit_plot = profitloss ? profitloss.map(line => {
            let docDate = new Date(moment(line['Document Date']).format('MM/DD/YYYY'));
            let amount = Number(line['Amount Net']);

            if ((line.Type === '2. Cost of Goods Sold' || line.Type === '3. Expense') && 'amountCOGS' in line) {
                amount = Number(line['amountCOGS']);
            }
            return {
                x: docDate,
                y: amount
            }
        }) : [];

        var x = income_plot.map(function (item, index) {

            return item.y - (expense_plot[index] ? expense_plot[index].y : 0);
        })

        var reduced_profit_plot = profit_plot.map(key => {
            return profit_plot.filter(line => line.x.getTime() === key.x.getTime())
                .reduce((a, b) => ({ x: key.x, y: (parseFloat(a.y) + parseFloat(b.y)).toFixed(rounding) }))
        });

        var reduced_profit_plot = reduced_profit_plot.reduce((unique, o) => {
            if (!unique.some(obj => obj.x.getTime() === o.x.getTime())) {
                unique.push(o);
            }
            return unique;
        }, []);

        // Tab data
        const tabData = [
            {
                name: 'Income',
                data: income_plot,
            },
            {
                name: 'Expense',
                data: expense_plot,
            },
            {
                name: 'Profit',
                data: reduced_profit_plot,
            },
        ];


        // Search function for table columns
        const getColumnSearchProps = dataIndex => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`${plainTranslate(this.props.settings.locale, 'Search')} ${plainTranslate(this.props.settings.locale, dataIndex)}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block', height: 30 }}
                    />
                    <div>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon="search"
                            size="small"
                            style={{ width: 90, marginRight: 8 }}
                        >
                            {plainTranslate(this.props.settings.locale, 'Search')}
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            {plainTranslate(this.props.settings.locale, 'Reset')}
                        </Button>
                    </div>
                </div>
            ),
            filterIcon: filtered => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            onFilter: (value, record) =>
                record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select());
                }
            },
            render: text => this.state.searchedColumn === dataIndex ?
                (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[this.state.searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : text
        });

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();
            this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
            });
        };

        const handleReset = clearFilters => {
            clearFilters();
            this.setState({ searchText: '' });
        };

        const documentsTabData = [
        ];

        const tasksTabData = [{name: 'My tasks'}, {name: 'Watched'},{name: 'Task activities'}];

        if (this.props.settings.bundles.salesbundle && this.props.settings.plan.sales) {
            documentsTabData.push({ name: 'Sales' })
        }

        if (this.props.settings.bundles.invoicebundle && this.props.settings.plan.invoice) {
            documentsTabData.push({ name: 'Invoice' })
        }

        if (this.props.settings.bundles.inventorybundle && this.props.settings.plan.inventory) {
            documentsTabData.push({ name: 'Inventory' })
        }

        const menu = (<div>
            <Menu mode="inline" style={{boxShadow: "0 0 8px rgb(0 0 0 / 30%)"}}>
                {this.props.settings.bundles.dealbundle && this.props.settings.plan.deals &&
                    <SubMenu key="deals" title={plainTranslate(this.props.settings.locale, 'Deals')}>
                        {this.props.settings.formLinks.deals.map((link) => {
                            return <Menu.Item>
                                <Link to={link.link}><span>{plainTranslate(this.props.settings.locale, link.label)}</span>
                                </Link>
                            </Menu.Item>
                            })    
                        }
                    </SubMenu>
                }
                {this.props.settings.bundles.salesbundle && this.props.settings.plan.sales &&
                    <SubMenu key="sales" title={plainTranslate(this.props.settings.locale, 'Sales')}>
                        {this.props.settings.formLinks.sales.map((link) => {
                            return <Menu.Item>
                                <Link to={link.link}><span>{plainTranslate(this.props.settings.locale, link.label)}</span>
                                </Link>
                            </Menu.Item>
                            })    
                        }
                    </SubMenu>
                }
                {this.props.settings.bundles.expensebundle && this.props.settings.plan.expense &&
                    <SubMenu key="expenses" title={plainTranslate(this.props.settings.locale, 'Expenses')}>
                        {this.props.settings.formLinks.expenses.map((link) => {
                            return <Menu.Item>
                                <Link to={link.link}><span>{plainTranslate(this.props.settings.locale, link.label)}</span>
                                </Link>
                            </Menu.Item>
                            })    
                        }
                    </SubMenu>
                }
                {this.props.settings.bundles.paymentbundle && this.props.settings.plan.payments &&
                    <SubMenu key="payments" title={plainTranslate(this.props.settings.locale, 'Payments')}>
                        {this.props.settings.formLinks.payments.map((link) => {
                            return <Menu.Item>
                                <Link to={link.link}><span>{plainTranslate(this.props.settings.locale, link.label)}</span>
                                </Link>
                            </Menu.Item>
                            })    
                        }
                    </SubMenu>
                }
                {this.props.settings.bundles.inventorybundle && this.props.settings.plan.inventory &&
                    <SubMenu key="inventory" title={plainTranslate(this.props.settings.locale, 'Inventory')}>
                        {this.props.settings.formLinks.inventory.map((link) => {
                            return <Menu.Item>
                                <Link to={link.link}><span>{plainTranslate(this.props.settings.locale, link.label)}</span>
                                </Link>
                            </Menu.Item>
                            })    
                        }
                    </SubMenu>
                }
                {this.props.settings.bundles.inventorybundle && this.props.settings.plan.inventory &&
                    <SubMenu key="items" title={plainTranslate(this.props.settings.locale, 'Items')}>
                        {this.props.settings.formLinks.items.map((link) => {
                            return <Menu.Item>
                                <Link to={link.link}><span>{plainTranslate(this.props.settings.locale, link.label)}</span>
                                </Link>
                            </Menu.Item>
                            })    
                        }
                    </SubMenu>
                }
                {this.props.settings.plan.project &&
                    <SubMenu key="projectsAndTasks" title={plainTranslate(this.props.settings.locale, 'Projects and tasks')}>
                        {this.props.settings.formLinks.projects.map((link) => {
                            return <Menu.Item>
                                <Link to={link.link}><span>{plainTranslate(this.props.settings.locale, "Project")}: {plainTranslate(this.props.settings.locale, link.label)}</span>
                                </Link>
                            </Menu.Item>
                            })    
                        }
                        {this.props.settings.formLinks.tasks.map((link) => {
                            return <Menu.Item>
                                <Link to={link.link}><span>{plainTranslate(this.props.settings.locale, "Task")}: {plainTranslate(this.props.settings.locale, link.label)}</span>
                                </Link>
                            </Menu.Item>
                            })    
                        }
                    </SubMenu>
                }
                <SubMenu key="account" title={plainTranslate(this.props.settings.locale, 'Account')}>
                    {this.props.settings.lists && this.props.settings.lists.map((list) => {
                        return <Menu.Item>
                            <Link to={'/' + this.props.settings.db + '/account/form/' + list.id + '/new'}><span>{plainTranslate(this.props.settings.locale, list.label)}</span>
                            </Link>
                        </Menu.Item>
                        })    
                    }
                </SubMenu>
                {this.props.settings.bundles.purchasebundle && this.props.settings.plan.purchasing &&
                    <Menu.Item>
                        <Link to={'/' + this.props.settings.db + '/item-requests/new'}><span>{plainTranslate(this.props.settings.locale, 'Item request')}</span>
                        </Link>
                    </Menu.Item>
                }
            </Menu></div>         
        );

        // Table columns
        const columns = [
            {
                title: plainTranslate(this.props.settings.locale, 'Location'),
                dataIndex: 'location',
                key: 'location',
                sorter: (a, b) => {
                    return a.location.localeCompare(b.location)
                },
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Base Amount'),
                dataIndex: 'amountBase',
                key: 'amountBase',
                sorter: (a, b) => {
                    return a.amountBase.localeCompare(b.amountBase)
                },
                align: 'right',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Total Amount'),
                dataIndex: 'amountTotal',
                key: 'amountTotal',
                sorter: (a, b) => {
                    return a.amountTotal.localeCompare(b.amountTotal)
                },
                align: 'right',
            },
        ];

        const tasksColumns = [
            {
                'key': 'actions',
                'dataIndex': 'actions',
                'title': plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    return (
                        !record.type ? <span className="table-actions">
                            {/* <Link to={'/' + this.props.match.params.db + '/task/' + record.id + '/profile'} title={plainTranslate(this.props.settings.locale, 'Profile')}><i className="fa fa-eye"></i></Link> */}
                            <i title={plainTranslate(this.props.settings.locale, 'Profile')} style={{ margin: '5px', cursor: "pointer" }} onClick={() => this.setState({ taskProfileId: record.id })} className="fa fa-eye"></i>
                            <i title={plainTranslate(this.props.settings.locale, 'Edit')} style={{ margin: '5px', cursor: "pointer" }} onClick={() => this.setState({ editTask: true, editTaskId: record.id })} className="fa fa-pencil-square-o"></i>
                            <i title={plainTranslate(this.props.settings.locale, 'Log time')} style={{ cursor: "pointer" }} onClick={() => this.subTaskLogTime(record.id)} className="ion-android-time"></i>
                        </span> : <span></span>
                    )
                },
                'width': 100,
                'fixed': 'left'
            },
            {
                'dataIndex': 'name',
                'title': plainTranslate(this.props.settings.locale, 'Name'),
                'width': 100,
                'fixed': 'left',
                render: (text, record) => <Link to={"/" + this.props.match.params.db + "/task/" + record.id + "/profile"}>{text}</Link>
            },
            {
                'dataIndex': 'stage',
                'title': plainTranslate(this.props.settings.locale, 'Stage'),
                'width': 150,
                sorter: (a, b) => {
                    if(a.stage){
                        return a.stage.localeCompare(b.stage)
                    }
                },
                render: (text, record) => {
                    const dropdownOptions = (
                        <Menu>
                            {this.state.tasksStages && this.state.tasksStages.map((option, i) => (
                                <Menu.Item key={i} disabled={option.forbiddenAccess} onClick={() => this.handleChangeField(record.id, option.id, 'stage')}>
                                    {option.label}
                                </Menu.Item>
                            ))
                            }
                        </Menu>
                    );
                    return (<span style={{ background: record.stageStatus && this.state.tasksStages.filter((item) => item.label == text).length ? this.state.tasksStages.filter((item) => item.label == text)[0]['color'] : 'initial', border: record.stageStatus && this.state.tasksStages.filter((item) => item.label == text).length ? this.state.tasksStages.filter((item) => item.label == text)[0]['color'] : 'initial', borderRadius: '4px', padding: '3px 15px' }} >
                        <span style={{ whiteSpace: "nowrap" }}>{text}</span>
                        <Dropdown overlay={dropdownOptions}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <DownOutlined />
                            </a>
                        </Dropdown>
                    </span>)
                }
            },
            {
                'dataIndex': 'account',
                'title': plainTranslate(this.props.settings.locale, 'Account'),
                'width': 150,
            },
            {
                'key': 'dueDate',
                'dataIndex': 'dueDate',
                'title': plainTranslate(this.props.settings.locale, 'Due Date'),
                'width': 150,
                sorter: (a, b) => {
                    return moment(a.dueDate, 'DD.MM.YYYY') - moment(b.dueDate, 'DD.MM.YYYY')
                },
                },
            {
                'dataIndex': 'user',
                'title': plainTranslate(this.props.settings.locale, 'User'),
                'width': 100,
                render: (text, record) => (
                    <Select
                        onChange={(val) => this.handleChangeField(record.id, val, 'user')}
                        defaultValue={record.user} style={{ width: 120 }}>
                        {
                            this.state.users && this.state.users.map(option => (
                                <Option value={option.id}>{option.label}</Option>
                            ))
                        }
                    </Select>
                ),
            },
            {
                'dataIndex': 'completion',
                'title': plainTranslate(this.props.settings.locale, 'Completion'),
                'width': 100,
                render: (text, record) => (
                    <Select
                        onChange={(val) => this.handleChangeField(record.id, val, 'completion')}
                        defaultValue={record.completion} style={{ width: 90 }}>
                        <Option value={0}>0%</Option>
                        <Option value={10}>10%</Option>
                        <Option value={20}>20%</Option>
                        <Option value={30}>30%</Option>
                        <Option value={40}>40%</Option>
                        <Option value={50}>50%</Option>
                        <Option value={60}>60%</Option>
                        <Option value={70}>70%</Option>
                        <Option value={80}>80%</Option>
                        <Option value={90}>90%</Option>
                        <Option value={100}>100%</Option>
                    </Select>
                ),
            },
        ];

        const activitiesCols = [
            {
                'dataIndex': 'date',
                'title': plainTranslate(this.props.settings.locale, 'Date'),
                'width': 50,
                render: (text, record) => (
                    <div>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY H:m').format(this.props.settings.dateFormat+ ' H:m') : ''}
                    </div>
                ),
            },
            {
                'dataIndex': 'task',
                'title': plainTranslate(this.props.settings.locale, 'Task'),
                'width': 100,
                render: (text, record) => <Link to={"/" + this.props.match.params.db + "/task/" + record.taskId + "/profile"}>{text}</Link>
            },
            {
                'dataIndex': 'action',
                'title': plainTranslate(this.props.settings.locale, 'Action'),
                'width': 50,
            },
            {
                'dataIndex': 'loggedTime',
                'title': plainTranslate(this.props.settings.locale, 'Logged time'),
                'width': 50,
            },
            {
                'dataIndex': 'user',
                'title': plainTranslate(this.props.settings.locale, 'User'),
                'width': 100,
            },
        ];

        const salesColumns = [
            {
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                dataIndex: 'documentNo',
                key: 'documentNo',
                render: (text, record) => <a target="_blank" href={"/" + this.props.match.params.db + "/sale/" + record.id + "/profile"}>{text}</a>
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Document Date'),
                dataIndex: 'documentDate',
                key: 'documentDate',
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Stage'),
                dataIndex: 'stage',
                key: 'stage',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Location'),
                dataIndex: 'location',
                key: 'location',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Account'),
                dataIndex: 'accountName',
                key: 'accountName',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Billing Data'),
                dataIndex: 'billdataName',
                key: 'billdataName',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Paid/Unpaid'),
                dataIndex: 'paymentStatus',
                key: 'paymentStatus',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Amount Gross'),
                dataIndex: 'amountTotal',
                key: 'amountTotal',
            },
        ];

        const invoiceColumns = [
            {
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                dataIndex: 'no',
                key: 'no',
                render: (text, record) => <a target="_blank" href={"/" + this.props.match.params.db + "/invoice/" + record.id + "/profile"}>{text}</a>
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Document Date'),
                dataIndex: 'date',
                key: 'date',
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Status'),
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Location'),
                dataIndex: 'location',
                key: 'location',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Account'),
                dataIndex: 'accountName',
                key: 'accountName',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Billing Data'),
                dataIndex: 'companyName',
                key: 'companyName',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Paid/Unpaid'),
                dataIndex: 'paid',
                key: 'paid',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Amount Gross'),
                dataIndex: 'total',
                key: 'total',
            },
        ];

        const inventoryColumns = [
            {
                title: plainTranslate(this.props.settings.locale, 'Document No'),
                dataIndex: 'no',
                key: 'no',
                render: (text, record) => <a target="_blank" href={"/" + this.props.match.params.db + "/receipt/" + record.id + "/profile"}>{text}</a>
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Document Date'),
                dataIndex: 'date',
                key: 'date',
                render: (text, record) => {
                    return <span>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</span>
                }
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Stage'),
                dataIndex: 'stage',
                key: 'stage',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Form'),
                dataIndex: 'form',
                key: 'paid',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Location'),
                dataIndex: 'location',
                key: 'location',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Billing Data'),
                dataIndex: 'company',
                key: 'company',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Amount Gross'),
                dataIndex: 'amountSum',
                key: 'amountSum',
            },
        ];

        const minQtyColumns = [
            {
                title: plainTranslate(this.props.settings.locale, 'Item Code'),
                dataIndex: 'Item Code',
                key: 'Item Code',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Item Name'),
                dataIndex: 'Item Name',
                key: 'Item Name',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Location Name'),
                dataIndex: 'Location Name',
                key: 'Location Name',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Item Min Quantity'),
                dataIndex: 'Item Min Quantity',
                key: 'Item Min Quantity',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Available Quantity'),
                dataIndex: 'Available Quantity',
                key: 'Available Quantity',
            },
            {
                title: plainTranslate(this.props.settings.locale, 'Ordered Quantity'),
                dataIndex: 'Ordered Quantity',
                key: 'Ordered Quantity',
            },
        ];

        const remindersCols = [
            {
                'key': 'actions',
                'dataIndex': 'actions',
                'title': plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {

                    const snoozeOptions = (
                        <Menu>
                            <Menu.Item key={1}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.snoozeReminder(e, record, 300)}>
                                    {plainTranslate(this.props.settings.locale, '5 minutes')}
                                </a>
                            </Menu.Item>
                            <Menu.Item key={2}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.snoozeReminder(e, record, 600)}>
                                    {plainTranslate(this.props.settings.locale, '10 minutes')}
                                </a>
                            </Menu.Item>
                            <Menu.Item key={3}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.snoozeReminder(e, record, 900)}>
                                    {plainTranslate(this.props.settings.locale, '15 minutes')}
                                </a>
                            </Menu.Item>
                            <Menu.Item key={4}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.snoozeReminder(e, record, 1800)}>
                                    {plainTranslate(this.props.settings.locale, '30 minutes')}
                                </a>
                            </Menu.Item>
                            <Menu.Item key={5}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.snoozeReminder(e, record, 3600)}>
                                    {plainTranslate(this.props.settings.locale, '1 hour')}
                                </a>
                            </Menu.Item>
                            <Menu.Item key={6}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.snoozeReminder(e, record, 86400)}>
                                    {plainTranslate(this.props.settings.locale, '1 day')}
                                </a>
                            </Menu.Item>
                        </Menu>
                    );
                    return (<span className="table-actions">
                            {/*<i title={plainTranslate(this.props.settings.locale, 'Snooze')} style={{ margin: '5px', cursor: "pointer" }} onClick={() => this.setState({ taskProfileId: record.id })} className="fa fa-eye"></i>*/}
                            <i title={plainTranslate(this.props.settings.locale, 'Change Status')} style={{ cursor: "pointer", marginRight: 10 }} onClick={() => this.changeStatus(record.id)} className="fa fa-flag-o"></i>
                            <Dropdown overlay={snoozeOptions}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <HistoryOutlined title={plainTranslate(this.props.settings.locale, 'Snooze')} style={{ cursor: "pointer" }}/>
                            </a>
                        </Dropdown>
                        </span>
                    )
                },
                'width': 100,
                'fixed': 'left'
            },
            {
                'dataIndex': 'name',
                'title': plainTranslate(this.props.settings.locale, 'Name'),
                'width': 300,
                render: (text, record) => {
                    return(<div style={{display: "flex", flexDirection: "column"}}><span className={record.isActive ? '' : 'text-line-through'}>{record.name}</span>
                            <span>
                                {record.account && 
                                  <Link style={{marginRight: 10}} to={'/' + this.props.match.params.db + '/account/' + record.accountId + '/profile'}><UserOutlined style={{marginRight: 5}}/>{record.account}</Link>
                                }
                                {record.project && 
                                  <Link style={{marginRight: 10}} to={'/' + this.props.match.params.db + '/project/' + record.projectId + '/profile'}><HddOutlined style={{marginRight: 5}}/>{record.project}</Link>
                                }
                                {record.task && 
                                  <Link style={{marginRight: 10}} to={'/' + this.props.match.params.db + '/task/' + record.taskId + '/profile'}><ScheduleOutlined style={{marginRight: 5}}/>{record.task}</Link>
                                }
                            </span>
                        </div>)
                }
            },
            {
                'dataIndex': 'activityDate',
                'title': plainTranslate(this.props.settings.locale, 'Date'),
                'width': 50
            },
            {
                'dataIndex': 'link',
                'title': plainTranslate(this.props.settings.locale, 'Link'),
                'width': 100,
            },
        ];

        let showStatsLoader = true;
        if (this.state.saleRevenueReady && this.state.paymentBalanceReady && this.state.profitLossReady && this.state.salesCountReady) {
            showStatsLoader = false;
        }

        let updatedAt = null;
        if (this.state.updatedAt) {
            var date = new Date(this.state.updatedAt * 1000).toLocaleDateString("en-GB");
            var time = new Date(this.state.updatedAt * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
            updatedAt = date + " " + time;
        } else {
            var date = new Date().toLocaleDateString("en-GB");
            var time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
            updatedAt = date + " " + time;
        }

        return (
            <div>
            <Onboarding isOpen={this.props.settings.onboarding} db={this.props.match.params.db} locale={this.props.settings.locale} user={this.props.settings.user ? this.props.settings.user.firstName ? this.props.settings.user.firstName : 'User' : 'User'} />
                <div className="dashboard dashboard-page">
                    {window.innerWidth < 768 &&
                        <Dropdown overlayStyle={{width: 300}} overlay={menu} placement="bottomCenter" className="rounded-button-primary" visible={this.state.visible}>
                            <Button style={{ width: "80px!important" }} onClick={() => this.setState({visible: !this.state.visible})}>
                                <i className="ion-plus-round"></i>
                            </Button>
                        </Dropdown>
                    }
                    <div className="moduleSubMenu">
                        <div className="col-sm-12 no-gutter">

                            {/* Subtitle */}
                            <h3 className="module-title pull-left">{plainTranslate(this.props.settings.locale, "Dashboard")}</h3>
                            {/* DatePicker */}
                            <div style={{ float: "right" }}>
                                <RangePicker
                                    separator={<span style={{ display: "flex", fontSize: "9px", left: "-10px", position: "relative" }}><LeftOutlined /><RightOutlined /></span>}
                                    className="dashboardNew-datepicker"
                                    defaultValue={[this.state.startDate, this.state.endDate]}
                                    onChange={this.handleDatesChange}
                                    format={this.props.settings.dateFormat}
                                    ranges={{
                                        'Today': [moment(), moment()],
                                        'This week': [moment().subtract(1, 'weeks'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(30, 'days'), moment()],
                                    }}
                                />
                                {updatedAt && <div style={{ margin: 6 }}>
                                {plainTranslate(this.props.settings.locale, "Updated at")}: {moment(updatedAt, 'DD/MM/YYYY H:mm').format(this.props.settings.dateFormat + ' H:mm')}
                                    <a href="#"
                                        style={{ marginLeft: 15 }}
                                        title={plainTranslate(this.props.locale, 'Refresh dashboard data')}>
                                        <i className="fa fa-refresh" aria-hidden="true"
                                            onClick={() => this.updateData(true)}></i></a>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {/* Sales Dashboard */}
                    {this.props.settings.roles.includes('ROLE_SUPER_ADMIN') &&
                        <div className="col-md-6 col-sm-12 col-xs-12 " style={{ padding: 15, marginBottom: 35 }}>
                            <div><h4>{plainTranslate(this.props.settings.locale, "Sales Activity")}</h4></div>
                            <div className="col-md-12 col-sm-12 no-gutter ">
                                {stats.map((stat, i) => {
                                    return <div id={i} className="col-sm-4" style={{ padding: 0 }}>
                                        <Card loading={showStatsLoader}>
                                            <Statistic
                                                title={plainTranslate(this.props.settings.locale, stat.title)}
                                                value={stat.value}
                                                style={{ height: 80 }}
                                            />
                                        </Card>
                                    </div>
                                })}
                            </div>
                        </div>
                    }

                    {tasksTabData.length > 0 &&
                        <div className="col-sm-6" style={{ padding: 15, marginBottom: 35 }}>
                            <Tabs className="newFont" defaultActiveKey="0" onChange={this.onTaskTabChange} style={{ marginBottom: 35, paddingLeft: 10 }}>
                                {tasksTabData.map((tab, index) => {
                                    //let link = tab.name === "Sales" ? "/" + this.props.match.params.db + "/sales" : tab.name === "Invoice" ? "/" + this.props.match.params.db + "/invoices" : "/" + this.props.match.params.db + "/inventory";
                                    let showLoader = true;
                                    let cols = [];
                                    let data = [];

                                    if (this.state.tasksTab === 'My tasks') {
                                        showLoader = !this.state.tasksReady;
                                        data = this.state.tasksData;
                                        cols = tasksColumns;
                                    } else if (this.state.tasksTab === 'Watched') {
                                        showLoader = !this.state.watchingReady;
                                        data = this.state.watchingData;
                                        cols = tasksColumns;
                                    } else if (this.state.tasksTab === 'Task activities') {
                                        showLoader = !this.state.activitiesReady;
                                        data = this.state.activitiesData;
                                        cols = activitiesCols;
                                    }
                                    return (
                                        <TabPane tab={plainTranslate(this.props.settings.locale, tab.name)} key={tab.name} style={{backgroundColor: "white"}}>
                                            <Table
                                                className="itemHistory-table newFont"
                                                rowKey={record => record.id}
                                                dataSource={data}
                                                columns={cols}
                                                loading={showLoader}
                                                size="small"
                                                pagination={this.state.tasksTab === 'Task activities' ? false : true}
                                                scroll={{ x: this.state.tasksTab === "Task activities" ? 600 : 1300 }}
                                            />
                                             {this.state.tasksTab === 'Task activities' && 
                                                 <Pagination
                                                    total={this.state.activitiesCount}
                                                    showSizeChanger={false}
                                                    onChange={this.onPageChange}
                                                    current={this.state.currentPage}
                                                    pageSize={5}
                                                    style={{float: "right", marginTop: 15}}
                                                    size="small"
                                                />
                                            }
                                        </TabPane>
                                    );
                                })}
                            </Tabs>
                        </div>
                    }

                    <div className="col-sm-6" style={{ padding: 15, marginBottom: 35 }}>
                        <div><h4>{plainTranslate(this.props.settings.locale, "Reminders")}</h4></div>
                        <Table
                            className="itemHistory-table newFont"
                            rowKey={record => record.id}
                            dataSource={this.state.remindersData}
                            columns={remindersCols}
                            size="small"
                            loading={!this.state.remindersReady}
                            pagination={{ pageSize: 10 }}
                        />
                    </div>

                    {/* Table */}
                    <div className="col-sm-6" style={{ padding: 15, marginBottom: 35 }}>
                        <div><h4>{plainTranslate(this.props.settings.locale, "Sales Summary")}</h4></div>
                        <Table
                            className="itemHistory-table newFont"
                            rowKey={record => record.id}
                            dataSource={reduced_dataSource}
                            columns={columns}
                            size="small"
                            loading={!this.state.saleRevenueReady}
                            pagination={{ pageSize: 10 }}
                        />
                    </div>
                    {this.props.settings && this.props.settings.reports && this.props.settings.reports.predefinedReports.find(o => o.id === 'minQuantityInStock') !== undefined &&
                        <div className="col-sm-6" style={{ padding: 15, marginBottom: 35 }}>
                            <div><h4>{plainTranslate(this.props.settings.locale, "Minimal Quantity")}</h4></div>
                            <Table
                                className="itemHistory-table newFont"
                                rowKey={record => record.id}
                                dataSource={this.state.minQtyData}
                                columns={minQtyColumns}
                                size="small"
                                loading={!this.state.minQtyReady}
                                pagination={{ pageSize: 10 }}
                            />
                        </div>
                    }

                    {/* Tabs */}
                    {this.props.settings.roles.includes('ROLE_SUPER_ADMIN') &&
                        <div className="col-sm-12" style={{ marginBottom: 50 }}>
                            {!this.state.profitLossReady &&
                                <div className="dashboard-spinner">
                                    <Spin size="large" style={{ margin: "auto" }} />
                                </div>
                            }
                            <Tabs className="itemHistory-table newFont" defaultActiveKey="0" style={{ backgroundColor: 'white', paddingLeft: 10 }}>
                                {tabData.map((tab, index) => {
                                    return (
                                        <TabPane tab={plainTranslate(this.props.settings.locale, tab.name)} key={index}>
                                            {tab.data.length > 1 &&
                                                <FlexibleWidthXYPlot
                                                    xType="time"
                                                    margin={{ left: 70 }}
                                                    height={300}
                                                    onMouseLeave={() => this.setState({ value: false })}
                                                >
                                                    <XAxis />
                                                    <YAxis />
                                                    <AreaSeries
                                                        onNearestX={(d) => this.setState({ value: d })}
                                                        curve="curveBasis"
                                                        data={tab.data}
                                                        opacity={0.25}
                                                        stroke="transparent"
                                                        dontCheckIfEmpty
                                                    />
                                                    <LineSeries
                                                        curve='curveBasis'
                                                        data={tab.data}
                                                        opacity={1}
                                                        stroke="#12939a"
                                                        strokeStyle="solid"
                                                    />
                                                    {this.state.value && <Crosshair values={[this.state.value]} />}
                                                </FlexibleWidthXYPlot>

                                            }
                                            {
                                                tab.data.length <= 1 &&
                                                <div style={{ width: "100%", height: 200, textAlign: "center" }}><h3>Not enough data</h3></div>
                                            }
                                        </TabPane>
                                    );
                                })}
                            </Tabs>
                        </div>
                    }
                    {documentsTabData.length > 0 &&
                        <div className="col-sm-12">
                            <div><h4>{plainTranslate(this.props.settings.locale, "Latest Documents")}</h4></div>
                            <Tabs className="itemHistory-table newFont" defaultActiveKey="0" onChange={this.onTabChange} style={{ backgroundColor: 'white', marginBottom: 35, paddingLeft: 10 }}>
                                {documentsTabData.map((tab, index) => {
                                    let link = tab.name === "Sales" ? "/" + this.props.match.params.db + "/sales-v2" : tab.name === "Invoice" ? "/" + this.props.match.params.db + "/sales-v2" : "/" + this.props.match.params.db + "/inventory-v2";
                                    let showLoader = true;
                                    let cols = [];
                                    let data = [];

                                    if (this.state.activeTab === 'Sales') {
                                        showLoader = !this.state.salesReady;
                                        data = this.state.sales;
                                        cols = salesColumns;
                                    } else if (this.state.activeTab === 'Invoice') {
                                        showLoader = !this.state.invoicesReady;
                                        data = this.state.invoices;
                                        cols = invoiceColumns;
                                    } else if (this.state.activeTab === 'Inventory') {
                                        showLoader = !this.state.inventoriesReady;
                                        data = this.state.inventories;
                                        cols = inventoryColumns;
                                    }
                                    return (
                                        <TabPane tab={plainTranslate(this.props.settings.locale, tab.name)} key={tab.name}>
                                            <Table
                                                rowKey={record => record.id}
                                                dataSource={data}
                                                columns={cols}
                                                size="small"
                                                loading={showLoader}
                                                //style={{height: '420px'}}
                                                pagination={false}
                                                footer={() => <Link to={link} title={plainTranslate(this.props.settings.locale, 'See All')}><span>{plainTranslate(this.props.settings.locale, 'See all')}</span></Link>}
                                            />
                                        </TabPane>
                                    );
                                })}
                            </Tabs>
                        </div>
                    }
                    <Drawer onClose={() => this.setState({ taskProfileId: false })} title={plainTranslate(this.props.settings.locale, 'Task profile')} className="grid-popup-form" placement="right" visible={this.state.taskProfileId} width={'90%'}>  
                            <TaskProfile id={this.state.taskProfileId} db={this.props.match.params.db} popup={true} update={() => this.fetchTasks((this.state.startDate.format('YYYY-M-DD'), this.state.endDate.format('YYYY-M-DD')))} gridPopup={true}/>             
                    </Drawer> 
                    {
                        this.state.subTaskLogTime &&
                        <SlideModal width={'90%'} onClose={() => this.setState({ subTaskLogTime: false })} title={plainTranslate(this.props.settings.locale, 'Log time')}>
                            <section className="newPanel">
                                <div className="panel-body">
                                    <div className="row m-bot15">
                                        <SimpleForm
                                            saveRoute={this.props.match.params.db + '/api/log-time'}
                                            formRoute={this.props.match.params.db + '/api/log-time/'}
                                            match={{ params: { id: 0 } }}
                                            onSuccessfulSave={this.closeLogTimePopup}
                                            isNew={true}
                                            popup={true}
                                            onCancel={() => this.setState({ subTaskLogTime: false })}
                                            type={'task'}
                                            refs={this.state.taskId}
                                            parent={this.props.match.params.id}
                                            form={'initializeFromState'}
                                        />
                                    </div>
                                </div>
                            </section>
                        </SlideModal>
                    }
                    {
                        this.state.editTask &&
                        <SlideModal width={'90%'} onClose={() => this.setState({ editTask: false, editTaskId: null })} title={plainTranslate(this.props.settings.locale, 'Task')}>
                            <section className="newPanel">
                                <div className="panel-body">
                                    <div className="row m-bot15">
                                        <SimpleForm
                                            formRoute={this.props.match.params.db + '/api/tasks/'}
                                            saveRoute={this.props.match.params.db + '/api/tasks/'}
                                            match={{ params: { id: this.state.editTaskId ? this.state.editTaskId : this.props.match.params.id } }}
                                            onSuccessfulSave={() => this.onSaveTask()}
                                            onCancel={() => this.setState({ editTask: false, editTaskId: null })}
                                            isNew={true}
                                            popup={true}
                                            form={'initializeFromState'}
                                        />
                                    </div>
                                </div>
                            </section>
                        </SlideModal>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    gridProperties: state.gridProperties,
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
    onDatesChange: (startDate, endDate, route) => dispatch(onDatesChange(startDate, endDate, route)),
    logoutUser: (db) => dispatch(logoutUser(db))
});


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
